var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "tippy",
          rawName: "v-tippy",
          value: { position: "bottom", arrow: true },
          expression: "{ position: 'bottom', arrow: true }",
        },
      ],
      staticStyle: { border: "none", "background-color": "inherit" },
      style: { color: _vm.iconColor },
      attrs: { type: "button", title: _vm.detail },
    },
    [
      _c("mdb-icon", {
        staticStyle: { cursor: "pointer" },
        style: { color: _vm.iconColor },
        attrs: { icon: _vm.iconName, fas: "", size: _vm.iconSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }