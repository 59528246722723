<!--
TODO: The algorithm is working well but can be adjusted further so that
overlapping Booking Entries can appear side-by-side. This can be done
using a similar approach to the groups. We have columns of entries for
bookings that are side by side. If a booking can't go onto an itinerary
without overlapping another booking then it is added to a new column. The
next booking is added to the column that minimises the gap between the 
last booking's end date and this booking's start date. If it can't be
added without an overlap then another column is created.
-->

<template>
  <div>
    <div class="d-flex flex-row flex-wrap ml-auto mr-0 align-items-center mb-2">
      <div class="d-flex flex-row flex-nowrap ml-auto mr-0 align-items-center">
        <div>Zoom</div>
        <asoft-slider
          class="mx-2"
          :min="20"
          :max="70"
          v-model="timeCellHeight"
          wrapperClassName
        ></asoft-slider>

        <awgt-std-button
          type="button"
          style="width: 120px"
          @click="timeCellHeight = 60"
          class="command-button mr-2"
        >
          <mdb-icon icon="undo-alt" class="mr-1" />Reset
        </awgt-std-button>
      </div>
      <div class="d-flex flex-row flex-nowrap ml-0 mr-0">
        <awgt-std-button
          type="button"
          style="width: 120px"
          class="command-button ml-auto"
          @click="addItineraryEntry()"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
    </div>

    <div>
      <div
        @click="showDayNotes = !showDayNotes"
        style="cursor: pointer"
        class="mb-2"
      >
        <span class="h4">
          Itinerary Day Notes ({{ itinerary.ItineraryDayNotes.length }})
        </span>
        <span class="ml-3">
          <mdb-icon
            v-if="showDayNotes == true"
            icon="angle-up"
            size="2x"
            color="primary"
          />
          <mdb-icon v-else icon="angle-down" size="2x" color="primary" />
        </span>
      </div>
      <expansion-region :toggle="showDayNotes == true">
        <div
          v-for="(dayNote, index) in itinerary.ItineraryDayNotes"
          :key="index"
        >
          <div>
            <div class="font-weight-bold">
              <span>{{
                convertDateFormat(
                  dayNote.Date,
                  "YYYY-MM-DDTHH:mm:ss",
                  "D MMM YYYY"
                )
              }}</span>
              <span class="ml-3"
                >{{
                  getItineraryDayNoteTypesByCode(dayNote.ItineraryDayNoteType)
                }}
                Note</span
              >
            </div>
            <div v-html="dayNote.Note" class="ml-3"></div>
          </div>
        </div>
        <awgt-std-button
          type="button"
          title="Edit"
          class="command-button float-right"
          @click="onEditDayNotes()"
        >
          <mdb-icon icon="edit" class="mr-1" />Edit
        </awgt-std-button>
        <br />
        <br />
      </expansion-region>
    </div>
    <div>
      <vue-cal
        ref="vuecal"
        class="vuecal--blue-theme vuecal--rounded-theme"
        :events="calendarEntries"
        :timeStep="timeStep"
        :timeFrom="calendarTimeFrom"
        :timeTo="calendarTimeTo"
        :minDate="calendarMinDate"
        :maxDate="calendarMaxDate"
        :selectedDate="calendarMinDate"
        :activeView="activeView"
        :minEventWidth="minEventWidth"
        :timeCellHeight="timeCellHeight"
        :twelveHour="twelveHour"
        :disableViews="['years', 'year', 'month']"
        @view-change="onViewChange"
      >
        <template v-slot:event="{ event }">
          <span @click="onBackward" style="cursor: pointer">
            <mdb-icon
              class="float-right mr-2 mt-2"
              style="
                border: none;
                background-color: transparent;
                color: grey;
                pointer: cursor;
              "
              icon="send-backward"
              fas
              size="1x"
            />
          </span>
          <div style="clear: both"></div>
          <!-- <v-icon>{{ event.icon }}</v-icon> -->
          <div
            :style="
              'height:' +
              oneMinuteHeight * event.preEventMargin +
              'px;border:none;display:inline-block;'
            "
            class="vuecal__event external_event"
          ></div>

          <div
            :style="
              'margin-top:' + oneMinuteHeight * event.preEventMargin + 'px;'
            "
          >
            <div>
              <span class="float-left">
                <mdb-icon
                  :icon="
                    getItineraryIcon(
                      event.itineraryEntryTypeCd,
                      event.itineraryEntrySubTypeCd
                    )
                  "
                  class="ml-1 mt-1"
                  fas
                  style="color: grey"
                  size="2x"
                />
              </span>
              <span class="float-left">
                <button
                  v-if="event.boundaryType == 'BoundaryStart'"
                  type="button"
                  :style="'border:none; background-color:inherit;color:grey;'"
                  :title="getEventDateRange(event)"
                  v-tippy="{ position: 'bottom', arrow: true }"
                >
                  <mdb-icon
                    icon="arrow-from-top"
                    class="ml-1 mt-1"
                    fas
                    style="color: grey; cursor: pointer"
                    size="2x"
                  />
                </button>
                <button
                  v-if="event.boundaryType == 'BoundaryEnd'"
                  type="button"
                  :style="'border:none; background-color:inherit;color:grey;'"
                  :title="getEventDateRange(event)"
                  v-tippy="{ position: 'bottom', arrow: true }"
                >
                  <mdb-icon
                    icon="arrow-from-bottom"
                    class="ml-1 mt-1"
                    fas
                    style="color: grey; cursor: pointer"
                    size="2x"
                  />
                </button>
              </span>
              <span
                v-if="event.groupName.length > 0"
                style="font-weight: bold; font-size: 12px"
                >Group: {{ event.groupName }} ({{ event.groupSize }})</span
              >

              <div class="float-right mt-1">
                <mdb-icon class="mr-1" size="1x" />
                <button
                  type="button"
                  :style="'border:none; background-color:inherit;color:grey;'"
                  @click="editItineraryEntry(event)"
                >
                  <mdb-icon icon="ellipsis-v" class="mr-1" size="1x" />
                </button>
              </div>
            </div>
            <div class="vuecal__event-title">
              {{ event.title }}
              <button
                type="button"
                :style="'border:none; background-color:inherit;color:grey;'"
                :title="getEventInformation(event)"
                v-tippy="{ position: 'bottom', arrow: true }"
              >
                <mdb-icon
                  icon="info-circle"
                  fas
                  style="color: grey; cursor: pointer"
                  size="1x"
                />
              </button>
            </div>
          </div>
          <div
            :style="
              'height:' +
              oneMinuteHeight * event.postEventMargin +
              'px;border:none;top:' +
              calculatePostEventTopPosition(event) +
              'px;'
            "
            class="vuecal__event external_event"
          ></div>
        </template>
      </vue-cal>
    </div>
    <itinerary-entry-dialog
      v-model="selectedItineraryEntry"
      :itineraryReference="itineraryReference"
      :travelBookingParticipantCount="30"
      :itineraryStartDate="itineraryStartDate"
      :itineraryEndDate="itineraryEndDate"
      :dialogVisible="itineraryEntryDialogVisible"
      :orders="orders"
      @close="onClose"
      @save="onItineraryEntrySave"
      @delete="onItineraryEntryDelete"
    ></itinerary-entry-dialog>
    <itinerary-day-note-edit
      v-model="itinerary"
      :dialogVisible="itineraryDayNoteDialogVisible"
      @close="onItineraryDayNoteDialogClose"
    >
    </itinerary-day-note-edit>
  </div>
</template>

<script>
import { mdbIcon } from "mdbvue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import ItineraryEntryDialog from "./ItineraryEntryDialog";
import itineraryApi from "@/api/ItineraryApi.js";
import itineraryEntryApi from "@/api/ItineraryEntryApi.js";
import asoftSlider from "@/components/AtomSoftware/asoftSlider";
import { mapGetters } from "vuex";
import { sharedMethods } from "@/shared/shared";
import ItineraryDayNoteEdit from "./itineraryDayNoteEdit";
import ExpansionRegion from "@/components/ExpansionRegion";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    AwgtStdButton,
    mdbIcon,
    asoftSlider,
    VueCal,
    ItineraryEntryDialog,
    ItineraryDayNoteEdit,
    ExpansionRegion,
  },

  data() {
    return {
      showDayNotes: false,
      selectedItineraryEntry: null,
      itinerary: null,
      itineraryEntryDialogVisible: false,
      itineraryDayNoteDialogVisible: false,
      travelBookingParticipantCount: 60, // Needs to change to a prop
      itineraryTimeFrom: 7 * 60, //Default time from set for the itinerary
      itineraryTimeTo: 22 * 60, //Default time to set for the itinerary
      timeStep: 15,
      calendarTimeFrom: 7 * 60,
      calendarTimeTo: 22 * 60,
      itineraryStartDate: this.$moment("1 Jan 2020").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      itineraryEndDate: this.$moment("31 Dec 2099").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      calendarMinDate: this.$moment("1 Jan 2020").format("YYYY-MM-DD HH:mm:ss"),
      calendarMaxDate: this.$moment("31 Dec 2099").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      calendarCurrentStartDate: null,
      activeView: "day",
      minEventWidth: 10,
      timeCellHeight: 60,
      twelveHour: true,
      calendarEntries: [],
      bookingReference: null,
      itineraryReference: null,
      orders: [],
    };
  },

  props: {
    productOrders: {
      type: Array,
      required: true,
    },
  },

  watch: {
    productOrders: {
      handler(to) {
        this.orders = to;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters([
      "getEmptyItineraryEntry",
      "getItineraryDayNoteTypesByCode",
      "getEmptyItinerary",
    ]),

    oneMinuteHeight: function () {
      return this.timeCellHeight / 15.15;
    },
  },

  methods: {
    ...sharedMethods,

    onItineraryDayNoteDialogClose() {
      this.itineraryDayNoteDialogVisible = false;
    },

    onEditDayNotes() {
      this.itineraryDayNoteDialogVisible = true;
    },

    onItineraryEntryDelete(itineraryEntry) {
      this.$log.info("Delete the itinerary entry...");

      let itineraryEntryIdx = this.itinerary.ItineraryEntries.findIndex(
        (ie) => ie.ItineraryEntryId == itineraryEntry.ItineraryEntryId
      );
      this.itinerary.ItineraryEntries.splice(itineraryEntryIdx, 1);

      this.deleteItineraryEntry(this.itineraryReference, itineraryEntry).catch(
        () => {
          // Errors have been logged in the api file
        }
      );

      this.layoutItineraryEntriesInCalendar();
      this.adjustDayCalendarTimeline(this.calendarEntries);

      /*       this.saveItineraryEntry(this.itineraryReference, itineraryEntry)
        .catch((error) => {
          this.formSubmissionErrors = error.message.split(",");
          window.scroll({ top: 50, behavior: "smooth" });
        })
        .then(() => {
          this.saveAttempted = true;
        })
        .then(
          () => {
            // only close on success
            //TODO: only emit input and close on Save success.
            //this.$emit("input", this.itinerary);
            //this.$emit("close");
          },
          () => {} //Null function on rejected.
        ); */
    },

    async deleteItineraryEntry(itineraryReference, itineraryEntry) {
      await itineraryEntryApi.deleteItineraryEntryAsync(
        itineraryReference,
        itineraryEntry
      );
    },

    onViewChange(payload) {
      /*
        If this is a day view then determine the start time of the event
        that first starts on this day and the end time of the event that
        last ends on this day. Note: These are returned in "time minutes"
        from the start of the day (midnight).

        If these times are outside the default from and to times then 
        adjust the from and to times of the calendar.
      */
      if (payload && payload.view == "day") {
        this.calendarCurrentStartDate = payload.startDate;

        this.adjustDayCalendarTimeline(payload.events);
      }
    },

    /*
      Calculates the calendar timeline for the current day based
      on calendar events.

      Unfortunately when this algorithm is run after editing an event
      it is run across all events for the itinerary rather than just
      the events for the day being edited. This is because the events
      property if vuecal is not updated until after the calendar is
      updated which is not while the dialog is still open. There are
      workarounds for this to improve the performance but this is not
      a major issue.
    */
    adjustDayCalendarTimeline(dayEvents) {
      let payloadDate = new Date(this.calendarCurrentStartDate);

      //Find the minimum start date
      let minStart = this.$lodash.minBy(dayEvents, (e) => {
        let startDate = new Date(e.start);
        startDate.setHours(0, 0, 0, 0);
        //Different date objects, compare by subtraction...
        if (payloadDate - startDate == 0) {
          return this.$moment(e.start).diff(startDate, "minutes"); // e.startTimeMinutes;
        } else return null;
      })?.start;

      if (minStart == null) {
        this.calendarTimeFrom = this.itineraryTimeFrom;
      } else {
        let minStartTimeInMinutes = this.$moment(minStart).diff(
          payloadDate,
          "minutes"
        );

        this.calendarTimeFrom =
          minStartTimeInMinutes < this.itineraryTimeFrom
            ? minStartTimeInMinutes
            : this.itineraryTimeFrom;
      }

      //Find the maximum end date
      let maxEnd = this.$lodash.maxBy(dayEvents, (e) => {
        let endDate = new Date(e.end);
        endDate.setHours(0, 0, 0, 0);
        //Different date objects, compare by subtraction...
        if (payloadDate - endDate == 0)
          return this.$moment(e.end).diff(endDate, "minutes");
        //return e.endTimeMinutes;
        else return null;
      })?.end;

      if (maxEnd == null) {
        this.calendarTimeTo = this.itineraryTimeTo;
      } else {
        let maxEndTimeInMinutes = this.$moment(maxEnd).diff(
          payloadDate,
          "minutes"
        );

        this.calendarTimeTo =
          maxEndTimeInMinutes > this.itineraryTimeTo
            ? maxEndTimeInMinutes
            : this.itineraryTimeTo;
      }
    },

    /*     generateCalendarEntriesFromItineraryEntry(itineraryEntry) {
      let entryClasses = "";
      let entryStart = null;
      let entryEnd = null;
      let calendarEntry = null;
      let calendarEntries = [];

      switch (itineraryEntry.ItineraryEntryType) {
        case "IET_Tr":
          entryClasses = "transport";
          break;
        case "IET_An":
          entryClasses = "accommodation";
          break;
        case "IET_Ay":
          entryClasses = "activity";
          break;
      }

      
      //An accommodation block will be displayed as 15 minute
      //start and end blocks.
          
      //Calculate the first block based on itineraryEntry.StartDT
      if (itineraryEntry.ItineraryEntryType == "IET_An") {
        entryStart = this.$moment(itineraryEntry.StartDT)
          .subtract(itineraryEntry.PreEntryMarginInMinutes, "m")
          .format("YYYY-MM-DD HH:mm");
        entryEnd = this.$moment(itineraryEntry.StartDT)
          .add(15, "m")
          .format("YYYY-MM-DD HH:mm");

        calendarEntry = {
          itineraryEntryId: itineraryEntry.ItineraryEntryId,
          start: entryStart,
          end: entryEnd,
          title: itineraryEntry.Name + " (Arrival)",
          class: entryClasses,
          preEventMargin: itineraryEntry.PreEntryMarginInMinutes,
          postEventMargin: 0, //No postEventMargin on arrival
          groupName: itineraryEntry.GroupName,
          groupSize: itineraryEntry.GroupSize,
          itineraryEntryTypeCd: itineraryEntry.ItineraryEntryType,
        };

        calendarEntry.class +=
          " calendar-entry-" + itineraryEntry.ItineraryEntryId;

        calendarEntries.push(calendarEntry);

        //Calculate the second block based on itineraryEntry.EndDT
        entryStart = this.$moment(itineraryEntry.EndDT)
          .subtract(15, "m")
          .format("YYYY-MM-DD HH:mm");
        entryEnd = this.$moment(itineraryEntry.EndDT)
          .add(itineraryEntry.PostEntryMarginInMinutes, "m")
          .format("YYYY-MM-DD HH:mm");

        calendarEntry = {
          itineraryEntryId: itineraryEntry.ItineraryEntryId,
          start: entryStart,
          end: entryEnd,
          title: itineraryEntry.Name + " (Departure)",
          class: entryClasses,
          preEventMargin: 0, //No preEventMargin on departure
          postEventMargin: itineraryEntry.PostEntryMarginInMinutes,
          groupName: itineraryEntry.GroupName,
          groupSize: itineraryEntry.GroupSize,
          itineraryEntryTypeCd: itineraryEntry.ItineraryEntryType,
        };

        calendarEntry.class +=
          " calendar-entry-" + itineraryEntry.ItineraryEntryId;

        calendarEntries.push(calendarEntry);
      } else {
        let entryStart = this.$moment(itineraryEntry.StartDT)
          .subtract(itineraryEntry.PreEntryMarginInMinutes, "m")
          .format("YYYY-MM-DD HH:mm");
        let entryEnd = this.$moment(itineraryEntry.EndDT)
          .add(itineraryEntry.PostEntryMarginInMinutes, "m")
          .format("YYYY-MM-DD HH:mm");

        let calendarEntry = {
          itineraryEntryId: itineraryEntry.ItineraryEntryId,
          start: entryStart,
          end: entryEnd,
          title: itineraryEntry.Name,
          class: entryClasses,
          preEventMargin: itineraryEntry.PreEntryMarginInMinutes,
          postEventMargin: itineraryEntry.PostEntryMarginInMinutes,
          groupName: itineraryEntry.GroupName,
          groupSize: itineraryEntry.GroupSize,
          itineraryEntryTypeCd: itineraryEntry.ItineraryEntryType,
        };

        calendarEntry.class +=
          " calendar-entry-" + itineraryEntry.ItineraryEntryId;

        calendarEntries.push(calendarEntry);
      }

      return calendarEntries;
    },
 */
    /*     generateCalendarEntriesFromItineraryEntries() {
      this.calendarEntries = [];

      for (let itineraryEntry of this.itinerary.ItineraryEntries) {
        let calendarEntries = this.generateCalendarEntriesFromItineraryEntry(
          itineraryEntry
        );

        for (let calendarEntry of calendarEntries)
          this.calendarEntries.push(calendarEntry);
      }
    }, */

    addItineraryEntry() {
      this.selectedItineraryEntry = this.$lodash.cloneDeep(
        this.getEmptyItineraryEntry
      );

      let startDT = new Date(this.calendarCurrentStartDate);
      startDT.setHours(9);
      this.selectedItineraryEntry.StartDT = this.$moment(startDT).format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      let endDT = new Date(this.calendarCurrentStartDate);
      endDT.setHours(10);
      this.selectedItineraryEntry.EndDT = this.$moment(endDT).format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      this.itineraryEntryDialogVisible = true;
    },

    editItineraryEntry(calendarEntry) {
      this.selectedItineraryEntry = this.itinerary.ItineraryEntries.find(
        (ie) => ie.ItineraryEntryId == calendarEntry.itineraryEntryId
      );
      this.itineraryEntryDialogVisible = true;
    },

    /*     getMaxIdFromObjectArray(array, idName) {
      let MaxId = 0;

      for (let elem of array) if (elem[idName] > MaxId) MaxId = elem[idName];

      return MaxId;
    }, */

    calculatePostEventTopPosition(event) {
      let startDate = new Date(event.start);
      let endDate = new Date(event.end);
      let eventDurationMS = endDate - startDate;
      let eventDurationMin = Math.round(eventDurationMS / 60000);
      let eventHeight = eventDurationMin * this.oneMinuteHeight;
      let postEventTopPosition =
        eventHeight - this.oneMinuteHeight * event.postEventMargin;

      return postEventTopPosition;
    },

    layoutItineraryEntriesInCalendar() {
      /*
        1. Go through the array of itinerary entries, copying the entries to another array,
          if any of the entries have DisplayBoundariesOnlyInd set to true then split the
          entry into a 15 minute start block and a 15 minute end block and mark the entry
          as the start and end of an entry. 
        2. For each day, order the events by start date and within that end date.
        3. Create the Itinerary Calendar Entry Layout Structure. The structure will
          contain Booking Entries and Group Entry Bundles. A Group Entry Bundle will
          contain an array of groups. Each group will contain an array of Group Entries.
        4. Loop through the Itinerary Calendar Entry Layout Structure in order of
          start date and insert the entries into the calendar.
      */

      //Step 1...
      let self = this;
      let itineraryEntries = [];

      this.itinerary.ItineraryEntries.forEach(function (itineraryEntry) {
        if (itineraryEntry.DisplayBoundariesOnly == true) {
          let ie = self.$lodash.cloneDeep(itineraryEntry);
          ie.EndDT = self.$moment(ie.StartDT).add(15, "m");
          self.$set(ie, "BoundaryType", "BoundaryStart");
          ie.PostEntryMarginInMinutes = 0; //No post-event margin on boundary start.
          switch (ie.ItineraryEntryType) {
            case "IET_Ay":
              ie.Name += " (Start)";
              break;
            case "IET_An":
              ie.Name += " (Arrival)";
              break;
            case "IET_Tr":
              ie.Name += " (Departure)";
              break;
          }
          itineraryEntries.push(ie);

          ie = self.$lodash.cloneDeep(itineraryEntry);
          ie.StartDT = self.$moment(ie.EndDT).subtract(15, "m");
          self.$set(ie, "BoundaryType", "BoundaryEnd");
          ie.PreEntryMarginInMinutes = 0; //No pre-event margin on boundary end.
          switch (ie.ItineraryEntryType) {
            case "IET_Ay":
              ie.Name += " (Finish)";
              break;
            case "IET_An":
              ie.Name += " (Departure)";
              break;
            case "IET_Tr":
              ie.Name += " (Arrival)";
              break;
          }
          itineraryEntries.push(ie);
        } else {
          let ie = self.$lodash.cloneDeep(itineraryEntry);
          self.$set(ie, "BoundaryType", "FullEntry");
          itineraryEntries.push(ie);
        }
      });

      this.$log.info("Step 1 result...");
      for (let ie of itineraryEntries)
        this.$log.info(
          `${ie.Name}: ${ie.StartDT}, ${ie.EndDT}, ${ie.BoundaryType}`
        );

      //Step 2...
      itineraryEntries.sort(function (t1, t2) {
        let diffInMinutes = self.$moment(t1.StartDT).diff(t2.StartDT, "m");
        if (diffInMinutes != 0) return diffInMinutes;
        else return self.$moment(t1.EndDT).diff(t2.EndDT, "m");
      });

      this.$log.info("Step 2 result...");
      for (let ie of itineraryEntries)
        this.$log.info(`${ie.Name}: ${ie.StartDT}, ${ie.EndDT}`);

      //Step 3...
      let itineraryCalendar = new ItineraryCalendar();

      for (let i = 0; i < itineraryEntries.length; i++) {
        let itineraryEntry = itineraryEntries[i];

        if (itineraryEntry.GroupName.length == 0) {
          //No group name, so this is a booking entry
          let bookingEntry = new BookingEntry(
            itineraryEntry.ItineraryEntryId,
            itineraryEntry.Name,
            itineraryEntry.ItineraryEntryType,
            itineraryEntry.ItineraryEntrySubType,
            itineraryEntry.BoundaryType,
            itineraryEntry.StartDT,
            itineraryEntry.EndDT,
            itineraryEntry.PreEntryMarginInMinutes,
            itineraryEntry.PostEntryMarginInMinutes
          );
          itineraryCalendar.itineraryEntries_add(bookingEntry);
        } else {
          //Has a group name, so this is a group entry
          //Create the group entry bundle
          let groupEntryBundle = new GroupEntryBundle(itineraryEntry.StartDT);

          do {
            let groupName = itineraryEntry.GroupName;
            let group = groupEntryBundle.groups.find(
              (g) => g.name == groupName
            );
            if (group == null) {
              //Need to create the group
              group = new Group(groupName);
              groupEntryBundle.groups_add(group);
            }
            let groupEntry = new GroupEntry(
              itineraryEntry.ItineraryEntryId,
              itineraryEntry.Name,
              itineraryEntry.ItineraryEntryType,
              itineraryEntry.ItineraryEntrySubType,
              itineraryEntry.BoundaryType,
              itineraryEntry.StartDT,
              itineraryEntry.EndDT,
              itineraryEntry.PreEntryMarginInMinutes,
              itineraryEntry.PostEntryMarginInMinutes,
              itineraryEntry.GroupName,
              itineraryEntry.GroupSize
            );
            group.groupEntries_add(groupEntry);

            //Read the next itinerary entry record.
            itineraryEntry = itineraryEntries[++i];
          } while (
            i < itineraryEntries.length &&
            itineraryEntry.GroupName.length > 0
          );
          i--; //The end of the do-while advanced to the next entry, just backtrack

          //Sort the groups in the group entry bundle
          groupEntryBundle.groups.sort(function (g1, g2) {
            return ("" + g1.name).localeCompare(g2.name);
          });

          //Determine the number of groups in the group entry bundle
          let groupCount = groupEntryBundle.groups.length;

          let groupWidth = 100 / groupCount;
          //loop through the groups setting start positions and widths;
          for (let idx = 0; idx < groupCount; idx++) {
            let g = groupEntryBundle.groups[idx];
            g.percentageLeftPos = groupWidth * idx;
            g.percentageWidth = groupWidth;
          }

          //Add the group entry bundle to the itineraryCalendar object
          itineraryCalendar.itineraryEntries_add(groupEntryBundle);
        }
      }

      this.$log.info("Step 3 result...");
      this.$log.info(
        `itineraryCalendarString: ${JSON.stringify(itineraryCalendar)}`
      );

      //Step 4...
      this.calendarEntries = [];
      for (let ie of itineraryCalendar.itineraryEntries) {
        if (ie.type == "BookingEntry") {
          this.createCalendarEntry(ie, 0, 100);
        } else {
          //GroupEntryBundle...
          for (let g of ie.groups) {
            for (let ge of g.groupEntries) {
              this.createCalendarEntry(
                ge,
                g.percentageLeftPos,
                g.percentageWidth
              );
            }
          }
        }
      }
    },

    createCalendarEntry(entry, entryPercentageLeftPos, entryPercentageWidth) {
      let entryClasses = "";

      switch (entry.itineraryEntryType) {
        case "IET_Tr":
          entryClasses = "transport";
          break;
        case "IET_An":
          entryClasses = "accommodation";
          break;
        case "IET_Ay":
          entryClasses = "activity";
          break;
      }

      let calendarEntry = {
        itineraryEntryId: entry.itineraryEntryId,
        start: this.$moment(entry.startDT)
          .subtract(entry.preEventMargin, "m")
          .format("YYYY-MM-DD HH:mm"),
        end: this.$moment(entry.endDT)
          .add(entry.postEventMargin, "m")
          .format("YYYY-MM-DD HH:mm"),
        title: entry.name,
        class: entryClasses,
        preEventMargin: entry.preEventMargin,
        postEventMargin: entry.postEventMargin,
        groupName: entry.groupName,
        groupSize: entry.groupSize,
        itineraryEntryTypeCd: entry.itineraryEntryType,
        itineraryEntrySubTypeCd: entry.itineraryEntrySubType,
        boundaryType: entry.boundaryType,
      };

      if (entry.boundaryType != "BoundaryEnd") {
        //CSS Class would have been created on BoundaryStart already
        this.createCSSClass(
          `.vuecal__event.calendar-entry-${entry.itineraryEntryId}`,
          `width:${entryPercentageWidth}% !important; left:${entryPercentageLeftPos}% !important;`
        );
      }

      calendarEntry.class += " calendar-entry-" + entry.itineraryEntryId;

      this.calendarEntries.push(calendarEntry);
    },

    // determineEventWidthAndPosition() {
    //   /*
    //     1. For each day, order the events by start date and within that end date.
    //     Events that span multiple days will start at midnight of the current day.
    //     2. Iterate through the events. For each event count the events
    //     that span the start date and the end date. Push these into an array called
    //     itineraryEntryOverlaps against the associated time with the count.
    //     3. For each itinerary entry, find the maximum number of itinerary entry
    //     overlaps during the timespan of the itinerary entry.
    //     4. Base the width on the number of itinerary entry overlaps for each
    //     itinerary entry, e.g. 1/2, 1/3, 1/4 etc.
    //     5. Need to work out the position!
    //   */
    //   //Step 1...
    //   let self = this;
    //   let itineraryEntryOverlaps = [];

    //   this.itinerary.ItineraryEntries.sort(function (t1, t2) {
    //     let diffInMinutes = self.$moment(t1.StartDT).diff(t2.StartDT, "m");
    //     if (diffInMinutes != 0) return diffInMinutes;
    //     else return self.$moment(t1.EndDT).diff(t2.EndDT, "m");
    //   });

    //   this.$log.info("Step 1 result...");
    //   for (let ie of this.itinerary.ItineraryEntries)
    //     this.$log.info(`${ie.Name}: ${ie.StartDT}, ${ie.EndDT}`);

    //   //Step 2...
    //   for (let i = 0; i < this.itinerary.ItineraryEntries.length; i++) {
    //     let startOverlapCount = 0;
    //     let endOverlapCount = 0;

    //     //Initialise the counters held in the itinerary entries themselves.
    //     this.itinerary.ItineraryEntries[i].overlappingEntries = [];
    //     this.itinerary.ItineraryEntries[i].overlappingEntriesCount = 0;
    //     this.itinerary.ItineraryEntries[i].leftPosInPercent = 0;
    //     this.itinerary.ItineraryEntries[i].rightPosInPercent = 0;

    //     for (let j = 0; j < this.itinerary.ItineraryEntries.length; j++) {
    //       let overlappingEntryAddedInd = false;

    //       if (
    //         //End of J after start of I && start of J before end of I
    //         this.$moment(this.itinerary.ItineraryEntries[i].StartDT).diff(
    //           this.itinerary.ItineraryEntries[j].StartDT
    //         ) >= 0 &&
    //         this.$moment(this.itinerary.ItineraryEntries[i].StartDT).diff(
    //           this.itinerary.ItineraryEntries[j].EndDT
    //         ) < 0
    //       ) {
    //         if (i != j) {
    //           this.itinerary.ItineraryEntries[i].overlappingEntries.push(
    //             this.itinerary.ItineraryEntries[j]
    //           );
    //           overlappingEntryAddedInd = true;
    //         }
    //         startOverlapCount++;
    //       }
    //       if (
    //         this.$moment(this.itinerary.ItineraryEntries[i].EndDT).diff(
    //           this.itinerary.ItineraryEntries[j].StartDT
    //         ) > 0 &&
    //         this.$moment(this.itinerary.ItineraryEntries[i].EndDT).diff(
    //           this.itinerary.ItineraryEntries[j].EndDT
    //         ) <= 0
    //       ) {
    //         if (overlappingEntryAddedInd == false && i != j)
    //           this.itinerary.ItineraryEntries[i].overlappingEntries.push(
    //             this.itinerary.ItineraryEntries[j]
    //           );
    //         endOverlapCount++;
    //       }
    //     }
    //     itineraryEntryOverlaps.push({
    //       time: this.itinerary.ItineraryEntries[i].StartDT,
    //       count: startOverlapCount,
    //     });
    //     itineraryEntryOverlaps.push({
    //       time: this.itinerary.ItineraryEntries[i].EndDT,
    //       count: endOverlapCount,
    //     });
    //   }

    //   this.$log.info("Itinerary entry overlaps...");
    //   for (let ieo of itineraryEntryOverlaps)
    //     this.$log.info(`${ieo.time} => ${ieo.count}`);

    //   //Step 3...
    //   for (let ie of this.itinerary.ItineraryEntries) {
    //     ie.overlappingEntriesCount = this.$lodash.max(
    //       itineraryEntryOverlaps.map(function (ieo) {
    //         if (
    //           self.$moment(ieo.time).diff(ie.StartDT, "m") >= 0 &&
    //           self.$moment(ieo.time).diff(ie.EndDT, "m") <= 0
    //         ) {
    //           return ieo.count;
    //         }
    //         return 0;
    //       })
    //     );
    //   }

    //   this.$log.info("Final counts...");
    //   for (let ie of this.itinerary.ItineraryEntries)
    //     this.$log.info(
    //       ie.Name +
    //         ": " +
    //         ie.StartDT +
    //         ", " +
    //         ie.EndDT +
    //         " => " +
    //         ie.overlappingEntriesCount +
    //         " => " +
    //         ie.overlappingEntries.length
    //     );

    //   //Step 4...
    //   for (let ie of this.itinerary.ItineraryEntries) {
    //     //Create a CSS class to set the width and position of this
    //     //new element.
    //     let widthInPercent = 100 / ie.overlappingEntriesCount;

    //     // if (leftPosInPercent + widthInPercent > 100) leftPosInPercent = 0;
    //     ie.leftPosInPercent = 0;
    //     ie.rightPosInPercent = ie.leftPosInPercent + widthInPercent;

    //     //The system wants to position this entry at the leftPos, but is
    //     //anything else in the leftPos. This can happen if an entry is quite long
    //     //and is occupying the leftPos.
    //     //Note: If this is buggy, then check that the ordering of overlapping
    //     //entries is correct.
    //     for (let oe of ie.overlappingEntries) {
    //       if (oe.rightPosInPercent > 0) {
    //         if (ie.leftPosInPercent == oe.leftPosInPercent) {
    //           //This overlapping entry is on the itinerary and is in the way of
    //           //our new entry, so move the new entry.
    //           ie.leftPosInPercent = oe.rightPosInPercent;
    //           ie.rightPosInPercent = ie.leftPosInPercent + widthInPercent;
    //         }

    //         if (
    //           ie.leftPosInPercent < oe.leftPosInPercent &&
    //           ie.rightPosInPercent > oe.leftPosInPercent
    //         )
    //           ie.rightPosInPercent = oe.leftPosInPercent;
    //       }
    //     }

    //     this.createCSSClass(
    //       `.vuecal__event.calendar-entry-${ie.ItineraryEntryId}`,
    //       `width:${
    //         ie.rightPosInPercent - ie.leftPosInPercent
    //       }% !important; left:${ie.leftPosInPercent}% !important;`
    //     );
    //   }
    // },

    getEventInformation(calendarEntry) {
      let ie = this.itinerary.ItineraryEntries.find(
        (ie) => ie.ItineraryEntryId == calendarEntry.itineraryEntryId
      );

      return `<div>${ie.Name}</div>
                <ul style="text-align: left; padding-left: 20px;">
                  <li>${ie.ChildParticipantCount} children</li>
                  <li>${ie.AdultParticipantCount} adults</li>
                </ul>
              </div>
      `;
    },

    getEventDateRange(calendarEntry) {
      let ie = this.itinerary.ItineraryEntries.find(
        (ie) => ie.ItineraryEntryId == calendarEntry.itineraryEntryId
      );

      return `${this.$moment(ie.StartDT).format(
        "D MMM YYYY H:mma"
      )} - ${this.$moment(ie.EndDT).format("D MMM YYYY H:mma")}`;
    },

    onClose() {
      this.itineraryEntryDialogVisible = false;
    },

    onItineraryEntrySave() {
      let idx = this.itinerary.ItineraryEntries.findIndex(
        (ie) =>
          ie.ItineraryEntryId == this.selectedItineraryEntry.ItineraryEntryId
      );

      if (idx == -1) {
        // Item doesn't exist, so it's a new record...
        this.itinerary.ItineraryEntries.push(this.selectedItineraryEntry);
      } else {
        this.itinerary.ItineraryEntries[idx] = this.selectedItineraryEntry;
      }

      this.layoutItineraryEntriesInCalendar();
      this.adjustDayCalendarTimeline(this.calendarEntries);

      //this.determineEventWidthAndPosition();
      //this.generateCalendarEntriesFromItineraryEntries();
    },

    /*     countElementMatches(arr1, arr2) {
      let count = 0;

      for (let a1 of arr1)
        for (let a2 of arr2) {
          if (a1 == a2) count++;
        }

      return count;
    }, */

    createCSSClass(name, rules) {
      let styleElement = document.querySelector("head style[type='text/css']");
      if (styleElement == null) {
        styleElement = document.createElement("style");
        styleElement.type = "text/css";
        document.getElementsByTagName("head")[0].appendChild(styleElement);
      }

      let cssClasses = styleElement.sheet.cssRules;
      for (let i = 0; i < cssClasses.length; i++)
        if (cssClasses[i].selectorText == name) {
          styleElement.sheet.deleteRule(i);
          break;
        }

      if (!(styleElement.sheet || {}).insertRule)
        (styleElement.styleSheet || styleElement.sheet).addRule(name, rules);
      else styleElement.sheet.insertRule(name + "{" + rules + "}", 0);
    },

    async loadFormData() {
      this.itinerary = await itineraryApi.getItineraryByReferenceAsync(
        this.itineraryReference
      );

      this.itineraryStartDate = this.itinerary.StartDt;
      let itineraryEndDate = new Date(this.itinerary.StartDt);
      this.itineraryEndDate = itineraryEndDate
        .addDays(
          this.itinerary.DurationInDays - 1 /* StartDt == EndDt is 1 day */
        )
        .format("YYYY-MM-DD HH:mm:ss");

      this.calendarMinDate = this.$moment(this.itinerary.StartDt).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.calendarMaxDate = this.$moment(this.itineraryEndDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.layoutItineraryEntriesInCalendar();
      //this.determineEventWidthAndPosition();
      //this.generateCalendarEntriesFromItineraryEntries();
    },

    onBackward(event) {
      event.target.parentNode.parentNode.style.zIndex = "0";
    },
  },

  /* watch: {
    timeCellHeight(ValTo) {
      this.oneMinuteHeight = ValTo / this.timeStep;
    }
  },*/
  // watch: {
  //   "itinerary.ItineraryEntries": {
  //     handler() {
  //       this.determineEventWidthAndPosition();
  //       this.generateCalendarEntriesFromItineraryEntries();
  //     },
  //     deep: true
  //   }
  // },

  mounted() {
    // this.createCSSClass(
    //   ".vuecal__event.test",
    //   "border-width:100px !important;"
    // );
    // this.itineraries = this.itinerariesByTravelBookingId(this.travelBookingId);
    // this.$log.info("Itineraries found:", this.itineraries);
    this.loadFormData();
  },

  created() {
    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIItinerary)
      .info("RouteParams: %s", routeParams);
    this.bookingReference = routeParams.bookingReference;
    this.itineraryReference = routeParams.itineraryReference;
    /*
      Populate the itinerary with an empty entry so that the vue rendering doesn't
      fail because the structure of an itinerary is missing.
    */
    this.itinerary = this.$lodash.cloneDeep(this.getEmptyItinerary);
  },
};

class ItineraryCalendar {
  constructor() {
    this._itineraryEntries = new Array();
  }

  itineraryEntries_add(itineraryEntry) {
    this._itineraryEntries.push(itineraryEntry);
  }
  itineraryEntries_delete(index) {
    this._itineraryEntries.splice(index, 1);
  }
  get itineraryEntries() {
    return this._itineraryEntries;
  }
}

class ItineraryEntry {
  constructor(type) {
    this._type = type;
  }

  set type(value) {
    this._type = value;
  }
  get type() {
    return this._type;
  }
}

class BookingEntry extends ItineraryEntry {
  constructor(
    itineraryEntryId,
    name,
    itineraryEntryType,
    itineraryEntrySubType,
    boundaryType,
    startDT,
    endDT,
    preEventMargin,
    postEventMargin
  ) {
    super("BookingEntry"); //Call the constructor of the base class
    this._itineraryEntryId = itineraryEntryId;
    this._name = name;
    this._itineraryEntryType = itineraryEntryType;
    this._itineraryEntrySubType = itineraryEntrySubType;
    this._boundaryType = boundaryType;
    this._startDT = startDT;
    this._endDT = endDT;
    this._preEventMargin = preEventMargin;
    this._postEventMargin = postEventMargin;
    this._groupName = "";
    this._groupSize = 0;
  }

  set itineraryEntryId(value) {
    this._itineraryEntryId = value;
  }
  get itineraryEntryId() {
    return this._itineraryEntryId;
  }

  set itineraryEntryType(value) {
    this._itineraryEntryType = value;
  }
  get itineraryEntryType() {
    return this._itineraryEntryType;
  }

  set itineraryEntrySubType(value) {
    this._itineraryEntrySubType = value;
  }
  get itineraryEntrySubType() {
    return this._itineraryEntrySubType;
  }

  set startDT(value) {
    this._startDT = value;
  }
  get startDT() {
    return this._startDT;
  }

  set endDT(value) {
    this._endDT = value;
  }
  get endDT() {
    return this._endDT;
  }

  set name(value) {
    this._name = value;
  }
  get name() {
    return this._name;
  }

  set boundaryType(value) {
    this._boundaryType = value;
  }
  get boundaryType() {
    return this._boundaryType;
  }

  set preEventMargin(value) {
    this._preEventMargin = value;
  }
  get preEventMargin() {
    return this._preEventMargin;
  }

  set postEventMargin(value) {
    this._postEventMargin = value;
  }
  get postEventMargin() {
    return this._postEventMargin;
  }

  get groupName() {
    return this._groupName;
  }

  get groupSize() {
    return this._groupSize;
  }
}

class GroupEntryBundle extends ItineraryEntry {
  constructor(startDT) {
    super("GroupEntryBundle"); //Call the constructor of the base class
    this._startDT = startDT;
    this._groups = new Array();
  }

  set startDT(value) {
    this._startDT = value;
  }
  get startDT() {
    return this._startDT;
  }

  groups_add(group) {
    this._groups.push(group);
  }
  groups_delete(index) {
    this._groups.splice(index, 1);
  }
  get groups() {
    return this._groups;
  }
}

class Group {
  constructor(name) {
    this._name = name;
    this._groupEntries = new Array();
  }

  set name(value) {
    this._name = value;
  }
  get name() {
    return this._name;
  }

  groupEntries_add(groupEntry) {
    this._groupEntries.push(groupEntry);
  }
  groupEntries_delete(index) {
    this._groupEntries.splice(index, 1);
  }
  get groupEntries() {
    return this._groupEntries;
  }
}

class GroupEntry {
  constructor(
    itineraryEntryId,
    name,
    itineraryEntryType,
    itineraryEntrySubType,
    boundaryType,
    startDT,
    endDT,
    preEventMargin,
    postEventMargin,
    groupName,
    groupSize
  ) {
    this._itineraryEntryId = itineraryEntryId;
    this._name = name;
    this._itineraryEntryType = itineraryEntryType;
    this._itineraryEntrySubType = itineraryEntrySubType;
    this._boundaryType = boundaryType;
    this._startDT = startDT;
    this._endDT = endDT;
    this._preEventMargin = preEventMargin;
    this._postEventMargin = postEventMargin;
    this._groupName = groupName;
    this._groupSize = groupSize;
  }

  set itineraryEntryId(value) {
    this._itineraryEntryId = value;
  }
  get itineraryEntryId() {
    return this._itineraryEntryId;
  }

  set itineraryEntryType(value) {
    this._itineraryEntryType = value;
  }
  get itineraryEntryType() {
    return this._itineraryEntryType;
  }

  set itineraryEntrySubType(value) {
    this._itineraryEntrySubType = value;
  }
  get itineraryEntrySubType() {
    return this._itineraryEntrySubType;
  }

  set startDT(value) {
    this._startDT = value;
  }
  get startDT() {
    return this._startDT;
  }

  set endDT(value) {
    this._endDT = value;
  }
  get endDT() {
    return this._endDT;
  }

  set name(value) {
    this._name = value;
  }
  get name() {
    return this._name;
  }

  set boundaryType(value) {
    this._boundaryType = value;
  }
  get boundaryType() {
    return this._boundaryType;
  }

  set preEventMargin(value) {
    this._preEventMargin = value;
  }
  get preEventMargin() {
    return this._preEventMargin;
  }

  set postEventMargin(value) {
    this._postEventMargin = value;
  }
  get postEventMargin() {
    return this._postEventMargin;
  }

  set groupName(value) {
    this._groupName = value;
  }
  get groupName() {
    return this._groupName;
  }

  set groupSize(value) {
    this._groupSize = value;
  }
  get groupSize() {
    return this._groupSize;
  }
}
</script>

<style lang="scss" src="@/styles/common.scss"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/* Different color for different event types. */
.vuecal__event.transport {
  background-color: rgba(255, 193, 136, 0.25);
  border: 2px solid rgb(255, 127, 8);
  border-radius: 10px;
  color: black;
}
.vuecal__event.accommodation {
  background-color: rgba(137, 124, 255, 0.25);
  border: 2px solid rgb(66, 45, 255);
  border-radius: 10px;
  color: black;
}
.vuecal__event.activity {
  background-color: rgba(255, 151, 151, 0.25);
  border: 2px solid rgb(255, 48, 48);
  border-radius: 10px;
  color: black;
}

.vuecal__event.external_event {
  background: repeating-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 10px,
    rgba(255, 255, 255, 0.7) 10px,
    rgba(255, 255, 255, 0.7) 20px
  );
  color: #fff;
}

// .vuecal__event {
//   width: 250px !important;
// }

.vuecal__cell.disabled {
  text-decoration: line-through;
}

/*
  Set the disabled regions to have a background color of
  light grey.
*/
.vuecal__cell.disabled.before-min {
  color: lightgrey !important;
  background-color: lightgrey !important;
}
.vuecal__cell.disabled.after-max {
  color: lightgrey !important;
  background-color: lightgrey !important;
}

/*
  Clear "No Event" within the disabled regions by setting
  the text color to the same as the background color.
*/
.vuecal__cell.disabled.before-min .vuecal__no-event {
  color: lightgrey !important;
}
.vuecal__cell.disabled.after-max .vuecal__no-event {
  color: lightgrey !important;
}
</style>
