/*
 * See https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
 * for this repository pattern
 */

import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index.js";
import securityApi from "@/api/securityApi.js";
import tokenManager from "@/shared/securityTokenManager.js";

const SETTINGS_Domain = process.env.VUE_APP_WebApiURL;
const SETTINGS_BaseURL = `${SETTINGS_Domain}/`;
const _Bearer = "Bearer ";

let $Vue = null;
function init(vue) {
  $Vue = vue;
}

logger
  .get(loggingSource.UIConfig)
  .info("Using the WebAPI URL %s", SETTINGS_BaseURL);

const axiosClient = axios.create({
  baseURL: SETTINGS_BaseURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  /*
   * withCredentials is required for the browser to pass credentials (such as the refresh token) from
   * response to request but it is only required in cross-site environments. In non-cross-site environments
   * the setting relaxes security. Development is a cross-site environment as the port number changes,
   * higher environments are not cross-site.
   */
  withCredentials: process.env.NODE_ENV == "development" ? true : false, //To enable cookies stored in browser and sent to server, this must be set up.
});

axiosClient.interceptors.request.use(
  async (req) => {
    store.commit("mutAxiosCallLoaderStatus", true);

    if (
      req.headers.Authorization == undefined ||
      req.headers.Authorization.length == 0
    ) {
      req.headers["Authorization"] =
        _Bearer + (await tokenManager.getAccessTokenAsync());
    }

    return req;
  },
  (error) => {
    store.commit("mutAxiosCallLoaderStatus", false);

    let err = getError(error);

    $Vue.$message.Message = Array.isArray(err) ? err : err.split(",");
    $Vue.$message.isSuccessInd = false;
    return Promise.reject(err);
  }
);

axiosClient.interceptors.response.use(
  (res) => {
    store.commit("mutAxiosCallLoaderStatus", false);
    return res;
  },
  async (error) => {
    store.commit("mutAxiosCallLoaderStatus", false);
    let err;
    if (
      error.config &&
      error.response &&
      error.response.status === 401 &&
      error.response.data == "" && //Differentiates between 401 when access token expiry and refresh token expiry
      (await tokenManager.doesAccessTokenExistAsync()) == true
    ) {
      return updateToken()
        .then(async (token) => {
          await tokenManager.setAccessTokenAsync(token.AccessToken);
          error.config.headers["Authorization"] = _Bearer + token.AccessToken;
          store.commit(
            "mutRefreshTokenExpTime",
            new Date().setMinutes(
              new Date().getMinutes() +
                store.getters.getRefreshTokenExpiryInMinutesFromReference
            )
          );

          return axiosClient.request(error.config);
        })
        .catch(async () => {
          store.commit("mutClearCurrentUser");
          await tokenManager.setAccessTokenAsync("");

          location.reload();
        });
    } else {
      err = getError(error);
      if (!err) $Vue.$message.Message = ["Unknown errors occurred"];
      else $Vue.$message.Message = Array.isArray(err) ? err : err.split(",");
      $Vue.$message.isSuccessInd = false;
    }
    return Promise.reject(err);
  }
);

function getError(error) {
  let err;
  if (error.response == null) err = error.message;
  else {
    if (error.response.data.Messages != null) {
      err = error.response.data.Messages;
    } else {
      err = error.response.data.Comment;
    }
  }

  return err;
}

function updateToken() {
  return new Promise((resolve, reject) => {
    tokenManager.getAccessTokenAsync().then((accessToken) => {
      securityApi
        .updateToken({ Token: accessToken })
        .then((val) => {
          resolve(val);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

//TODO: This is not exponential.
//Axios Retry will retry axios calls in the event of network issues.
//The following implements an exponential delay... 0.5 second, 1 second, 1.5 seconds.
axiosRetry(axiosClient, {
  retries: 1,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
});

export default { client: axiosClient, init: init };
