<template>
  <mdb-footer :color="backgroundColor" class="fixed-bottom">
    <mdb-row class="w-100 mx-0">
      <mdb-col col="8">
        <slot name="left">
          <h5 class="tourdox-font text-left">tourdox</h5>
        </slot>
      </mdb-col>
      <mdb-col col="4">
        <slot name="right">
          <p class="white-text text-right my-1">&copy; 2019</p>
        </slot>
      </mdb-col>
    </mdb-row>
  </mdb-footer>
</template>

<script>
import { mdbFooter, mdbRow, mdbCol } from "mdbvue";
import variables from "@/styles/sharedVariables.scss";
export default {
  props: {
    backgroundColor: {
      type: String,
      default: variables.awgtFooterBackgroundColor,
    },
  },
  components: {
    mdbFooter,
    mdbRow,
    mdbCol,
  },
};
</script>
