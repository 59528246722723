var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c(
        "mdb-modal",
        {
          attrs: { show: _vm.visible, size: "sm" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("mdb-card", [
            _c("h3", { staticClass: "py-1 pl-2 header primary-color" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("hr", { staticClass: "my-0" }),
            _c(
              "div",
              {
                staticStyle: {
                  height: "200px",
                  "overflow-y": "scroll",
                  display: "block",
                },
                attrs: { id: "timeList", size: "8" },
              },
              _vm._l(_vm.timeArray, function (time, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "my-1 mx-2 timeListItem",
                    on: {
                      click: function ($event) {
                        return _vm.onSelect(i)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(" " + _vm._s(time.displayTime) + " ")]),
                    _c("i", {
                      staticClass: "fas fa-times-circle deleteIcon",
                      attrs: { size: "md" },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(i)
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("mdb-input", {
                  staticClass: "mx-2 mt-4 mb-4",
                  attrs: { label: "Start Time", maxlength: "4", type: "time" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.addButtonOptions[0].callback.apply(
                        null,
                        arguments
                      )
                    },
                  },
                  model: {
                    value: _vm.tmpTime.dataTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmpTime, "dataTime", $$v)
                    },
                    expression: "tmpTime.dataTime",
                  },
                }),
                _c("span", { staticClass: "errormessage ml-2" }, [
                  _vm._v(_vm._s(_vm.errormessage)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-end align-center mb-2",
                  },
                  [
                    _c("split-button", {
                      attrs: { items: _vm.addButtonOptions },
                    }),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: { click: _vm.onClose },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }