<template>
  <div>
    <asoft-modal-header :label="title" @close="() => onCancel()">
    </asoft-modal-header>
    <mdb-modal-body class="px-2">
      <div class="d-flex flex-row flex-nowrap mx-2 align-items-center">
        <awgt-std-dropdown
          :items="getPriceAdjustmentTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Adjustment Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="editEntity.PriceAdjustmentTypeCd"
          bg
        ></awgt-std-dropdown>
        <awgt-std-dropdown
          v-if="editEntity.PriceAdjustmentTypeCd != 'PAT_P'"
          :items="getOrderPriceChangeTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Change Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="editEntity.OrderPriceChangeTypeCd"
          bg
        ></awgt-std-dropdown>
        <mdb-icon
          :icon="
            editEntity.OrderPriceChangeTypeCd == 'OPCT_P'
              ? 'percentage'
              : 'dollar-sign'
          "
          color="primary"
          class="grey-text ml-2 mr-1"
          size="2x"
        />
        <awgt-input
          v-model.number="editEntity.AdjustAmt"
          maxlength="7"
          :label="
            editEntity.OrderPriceChangeTypeCd === 'OPCT_D'
              ? '$ Amount'
              : '% Amount'
          "
          type="text"
          bg
          v-numeric="{
            precision: 10,
            scale: 2,
            posOnly: true,
          }"
        ></awgt-input>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-4"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 34px; text-align: center"
        />
        <div class="ml-2">Pricing adjustment reason</div>
      </div>
      <awgt-input
        id="pricingAdjustmentReason"
        v-model="editEntity.PriceAdjustReason"
        name="PricingAdjustmentReason"
        bg
        class="flex-grow-1 mx-2 input-field"
        type="textarea"
        label="Reason"
      />
      <div class="my-2 float-right">
        <awgt-std-button
          type="button"
          class="command-button mr-2 ml-auto"
          :disabled="
            $lodash.isEmpty(editEntity.PriceAdjustReason) ||
            editEntity.AdjustAmt == 0
          "
          @click="onSave"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2 ml-auto"
          :disabled="
            $lodash.isEmpty(editEntity.PriceAdjustReason) ||
            editEntity.AdjustAmt == 0
          "
          @click="onDelete"
        >
          <mdb-icon icon="trash-alt" class="mr-1" />Delete
        </awgt-std-button>
      </div>
    </mdb-modal-body>
  </div>
</template>
<script>
import { mdbModalBody, mdbIcon } from "mdbvue";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mapGetters } from "vuex";

export default {
  components: {
    mdbModalBody,
    asoftModalHeader,
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    AwgtStdDropdown,
  },

  data() {
    return {
      editEntity: {},
    };
  },

  props: {
    title: {
      Type: String,
    },

    adjustingEntity: {
      Type: Object,
    },
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getPriceChangeTypes",
      "getOrderPriceChangeTypes",
      "getPriceAdjustmentTypes",
    ]),
  },

  watch: {
    adjustingEntity: {
      deep: true,
      handler(to) {
        let update = this.$lodash.cloneDeep(to);
        update.AdjustAmt = to.AdjustAmt || 0.0;
        this.editEntity = update;
      },
      immediate: true,
    },

    /*
      If price adjust is "New Price" then set Order Price Change Type to "Dollar"
    */
    "editEntity.PriceAdjustmentTypeCd": {
      handler(to) {
        if (to == "PAT_P") this.editEntity.OrderPriceChangeTypeCd = "OPCT_D";
      },
    },
  },

  methods: {
    onCancel() {
      this.$emit("close");
    },

    onSave() {
      this.$emit("save", this.editEntity);
    },

    onDelete() {
      this.$emit("delete");
    },
  },
};
</script>
<style scoped>
.warning-msg {
  color: red;
}
</style>
