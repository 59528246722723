<template>
  <div>
    <div
      v-for="pc in components"
      style="border: solid"
      class="px-2 py-3 my-1"
      :key="pc.commercialProductLineId"
    >
      <h3>{{ pc.commercialProductLineName }}</h3>
      <div v-html="pc.description"></div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
        <awgt-input
          v-model.number="pc.quantity"
          label="Quantity"
          style="width: 40px"
          class="mb-0"
          maxlength="3"
          v-numeric="{ precision: 3, scale: 0, posOnly: true }"
        />
        <awgt-std-button
          class="py-2 mt-4 px-2 ml-4"
          style="height: 40px; width: 40px"
          @click="pc.quantity < 999 ? (pc.quantity += 1) : true"
        >
          <mdb-icon icon="plus" color="white" fas size="2x" />
        </awgt-std-button>
        <awgt-std-button
          class="py-2 mt-4 px-2 ml-2"
          style="height: 40px; width: 40px"
          @click="pc.quantity > 0 ? (pc.quantity -= 1) : true"
        >
          <mdb-icon icon="minus" color="white" fas size="2x" />
        </awgt-std-button>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="dollar-sign"
            color="primary"
            class="mr-2 mt-4"
            size="2x"
          />
          <awgt-input
            v-model.number="pc.exGSTPrice"
            :counterMaxValue="7"
            maxlength="7"
            counter
            label="Ex-GST Amt"
            bg
            class="flex-grow-1 mt-3 mb-0"
            style="width: 50px"
          />
          <mdb-icon
            icon="dollar-sign"
            color="primary"
            class="mx-2 mt-4"
            size="2x"
          />
          <awgt-input
            v-model.number="pc.gSTPrice"
            :counterMaxValue="7"
            maxlength="7"
            counter
            label="GST Amt"
            bg
            class="flex-grow-1 mt-3 mb-0"
            style="width: 50px"
          />
        </div>
        <div class="d-flex flex-row flex-nowrap">
          <awgt-input
            id="isIndicativePriceInd"
            type="checkbox"
            v-model="pc.isIndicativePriceInd"
            name="deleteComponentPrice"
            label="Is Indicative Price"
            class="my-3 pl-2 pt-md-3"
            style="height: 10px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbIcon } from "mdbvue";
import awgtInput from "@/components/AWGT/AwgtInput";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbIcon,

    awgtInput,
    AwgtStdButton,
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    components: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
