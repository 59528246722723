<template>
  <mdb-container fluid>
    <div
      style="top: 50px; position: -webkit- sticky; position: sticky; z-index: 1"
    ></div>
    <h1 class="text-center">Itinerary Edit</h1>

    <h4 v-if="itinerary.Reference != null" class="mx-2">
      Reference: {{ itinerary.Reference }}
    </h4>

    <div class="d-flex flex-row flex-nowrap flex-grow-1">
      <!--       <mdb-icon
        :icon="client.ClientType == 'CT_O' ? 'building' : 'school'"
        color="primary"
        class="mx-2 mt-4"
        size="2x"
      />
 -->
      <awgt-input
        v-model="itinerary.Name"
        counter
        :counterMaxValue="100"
        maxlength="100"
        label="Name"
        bg
        class="flex-grow-1 mx-2"
      />
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="itinerary.Description"
        counter
        :counterMaxValue="1000"
        maxlength="1000"
        label="Description"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      />
    </div>
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <asoftDatePicker
          v-model="itinerary.StartDt"
          label="Start Date"
          autoHide
          displayDateFormat="D MMM YYYY"
          dataDateFormat="YYYY-MM-DDTHH:mm:ss"
          setTime="00:00:00.000"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
          :width="300"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <awgt-input
          v-model.number="itinerary.DurationInDays"
          :counterMaxValue="3"
          maxlength="3"
          counter
          label="Duration (days)"
          style="min-width: 40px"
          bg
          class="flex-grow-1 mx-2"
          type="number"
        />
      </div>
    </div>
    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <mdb-icon
        icon="sitemap"
        color="primary"
        class="mt-4 mx-2"
        style="width: 30px; font-size: 1.5em"
      />
      <awgt-std-dropdown
        :items="getItineraryStatuses(false)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Itinerary Status"
        class="flex-grow-1"
        style="width: 180px"
        v-model="itinerary.ItineraryStatus"
        bg
      ></awgt-std-dropdown>
      <awgt-input
        id="itinerary_IsActive"
        type="checkbox"
        v-model="itinerary.IsActive"
        name="isActive"
        label="Active Itinerary"
        class="mx-2"
        style="min-width: 50px"
      ></awgt-input>
    </div>

    <div class="float-right">
      <awgt-std-button
        type="button"
        title="Products"
        class="command-button mx-2"
        v-if="itinerary.Reference != '' && !isFormDirty()"
        @click="onProducts()"
      >
        <mdb-icon icon="comments" class="mr-1" />Products
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onSave()"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />Save
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onClose()"
      >
        <mdb-icon icon="times" class="mr-1" />Close
      </awgt-std-button>
    </div>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbContainer, mdbIcon } from "mdbvue";
import { mapGetters } from "vuex";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import itineraryApi from "@/api/ItineraryApi.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { sharedMethods } from "@/shared/shared";
import bookingApi from "@/api/bookingApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    asoftDatePicker,
    awgtInput,
    AwgtStdDropdown,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      bookingReference: null,
      itinerary: {},
      itinerarySnapshot: {},
      autocomplete: null,
      formSubmissionErrors: [],
      isItineraryDirtyInd: false,
    };
  },
  computed: {
    ...mapGetters(["getEmptyItinerary", "getItineraryStatuses"]),
  },
  methods: {
    ...sharedMethods,

    async onSave() {
      this.$log.info("onSave...");

      //TODO: Check why these conversions are necessary.
      //TODO: Move conversions to the API if they are necessary.
      this.itinerary.DurationInDays = parseInt(this.itinerary.DurationInDays);

      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the itinerary...");
        await this.saveItinerary(this.itinerary)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [`The itinerary ${this.itinerary.Name} has been saved.`],
            });
            this.snapshotItinerary(this.itinerary);
          })
          .catch(() => {
            // Errors have been logged in the itineraryEntryDialog.js
          });
      }
    },

    onProducts() {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/${this.itinerary.Reference}/productselection`,
      });
    },

    onClose() {
      this.$router.go(-1);
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    validateForm() {
      this.formSubmissionErrors = [];
      if (this.itinerary.Name.length == 0) {
        this.formSubmissionErrors.push("Name is required.");
      }
      if (this.itinerary.StartDt == null) {
        this.formSubmissionErrors.push("Start date is required.");
      }
      if (this.itinerary.DurationInDays == 0) {
        this.formSubmissionErrors.push("Duration is required.");
      }

      return this.formSubmissionErrors.length == 0;
    },

    async saveItinerary(itinerary) {
      if (itinerary.Reference == null)
        this.itinerary = await itineraryApi.postItineraryAsync(itinerary);
      else this.itinerary = await itineraryApi.putItineraryAsync(itinerary);

      //TODO:Need to take a fresh snapshot after a SUCCESSFUL save.
      this.itinerarySnapshot = this.$lodash.cloneDeep(this.itinerary);
    },

    async loadFormData(reference) {
      if (reference == null) {
        let booking = await bookingApi.getBookingByReferenceAsync(
          this.bookingReference
        );
        this.itinerary = this.$lodash.cloneDeep(this.getEmptyItinerary);
        this.itinerary.Booking.Reference = this.bookingReference;
        this.itinerary.StartDt = booking.DepartureDt;
        this.itinerary.DurationInDays =
          this.$moment(booking.ReturnDt).diff(
            this.$moment(booking.DepartureDt),
            "days"
          ) + 1;
      } else {
        this.itinerary = await itineraryApi.getItineraryByReferenceAsync(
          reference
        );
      }
      this.itinerarySnapshot = this.$lodash.cloneDeep(this.itinerary);
    },
    snapshotItinerary(itinerary) {
      this.itinerarySnapshot = this.$lodash.cloneDeep(itinerary);

      this.isItineraryDirtyInd = false;
    },
    isFormDirty() {
      if (this.isItineraryDirtyInd == true) return true;

      this.isItineraryDirtyInd =
        this.$lodash.isEqual(this.itinerary, this.itinerarySnapshot) == false;
      return this.isItineraryDirtyInd;
    },
  },
  mounted() {
    this.itinerary = this.$lodash.cloneDeep(this.getEmptyItinerary);

    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIItinerary)
      .info("RouteParams: %s", routeParams);
    this.bookingReference = routeParams.bookingReference;
    let itineraryReference = routeParams.itineraryReference;
    this.itinerary.Booking.Reference = this.bookingReference;

    this.loadFormData(itineraryReference)
      .then(() => {
        this.snapshotItinerary(this.itinerary);
      })
      .catch(() => {
        // Suppress the error here
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSave().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
