var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "expansion-region",
    {
      style: { backgroundColor: _vm.backgroundColor, color: _vm.textColor },
      attrs: { toggle: _vm.toggle },
    },
    [_c("mdb-card-body", [_vm._t("default"), _vm._t("footer")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }