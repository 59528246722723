var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
        },
        [
          _vm._t("default"),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button",
              staticStyle: { "min-width": "100px" },
              attrs: { type: "button" },
              on: { click: () => (this.showCategoryEditor = true) },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
              _vm._v(" Add "),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _vm._l(
            _vm.SupplierFileCategories,
            function (category, categoryIndex) {
              return [
                (category.RecordStatus != "Deleted" &&
                  _vm.supplierFileCategoryType == "Any") ||
                category.SupplierFileCategoryTypeCd ==
                  _vm.supplierFileCategoryType
                  ? _c(
                      "div",
                      { key: categoryIndex, staticClass: "file-box ml-3 my-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "box-top d-flex align-items-center" },
                          [
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(category.Name) + " "),
                            ]),
                            !category.IsMandatoryInd
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEditCategory(categoryIndex)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "ml-2",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        color: "primary",
                                        icon: "edit",
                                        far: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "flex-grow-1" }),
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mr-4",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAddFileToCategory(
                                      categoryIndex
                                    )
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  attrs: { icon: "plus", far: "" },
                                }),
                                _vm._v(" Add "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        category.IsMandatoryInd == false
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cross-icon d-flex flex-row justify-content-center",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeleteCategory(categoryIndex)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mx-2 mb-2",
                                  attrs: {
                                    icon: "times",
                                    far: "",
                                    color: "black",
                                    size: "2x",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          category.SupplierFiles,
                          function (file, fileIndex) {
                            return _c(
                              "div",
                              { key: fileIndex, staticClass: "mx-2" },
                              [
                                file.RecordStatus != "Deleted"
                                  ? [
                                      file.RecordStatus == "Inserted"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(file.DisplayName))]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "file-link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDownload(file)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(file.DisplayName))]
                                          ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onEditFile(
                                                categoryIndex,
                                                fileIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mx-2 mb-2",
                                            attrs: {
                                              icon: "pencil",
                                              far: "",
                                              color: "black",
                                              size: "1x",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteFile(
                                                category,
                                                fileIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mx-2 mb-2",
                                            attrs: {
                                              icon: "times",
                                              far: "",
                                              color: "black",
                                              size: "1x",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        _c("div", { staticClass: "mt-4" }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            }
          ),
        ],
        2
      ),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.showFileEntryEditor } },
        [
          _c("asoft-modal-header", {
            attrs: { label: "File Details" },
            on: {
              close: function ($event) {
                return _vm.onCancelFileEntryEditor()
              },
            },
          }),
          _c(
            "mdb-modal-body",
            [
              _c("awgt-input", {
                attrs: {
                  bg: "",
                  label: "Display Name",
                  maxlength: "50",
                  counterMaxValue: 50,
                  counter: "",
                },
                model: {
                  value: _vm.fileDisplayName,
                  callback: function ($$v) {
                    _vm.fileDisplayName = $$v
                  },
                  expression: "fileDisplayName",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-2 mt-4",
                    attrs: {
                      icon: "calendar-alt",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("asoftDatePicker", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "140px" },
                    attrs: {
                      label: "Valid From",
                      autoHide: "",
                      displayDateFormat:
                        _vm.$constants.DATETIMEFORMAT_Presentation,
                      dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                      setTime: "00:00:00.000",
                      bg: "",
                      width: 300,
                    },
                    model: {
                      value: _vm.fileValidFrom,
                      callback: function ($$v) {
                        _vm.fileValidFrom = $$v
                      },
                      expression: "fileValidFrom",
                    },
                  }),
                  _c("asoftDatePicker", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "140px" },
                    attrs: {
                      label: "Valid Until",
                      autoHide: "",
                      displayDateFormat:
                        _vm.$constants.DATETIMEFORMAT_Presentation,
                      dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                      setTime: "00:00:00.000",
                      bg: "",
                      width: 300,
                    },
                    model: {
                      value: _vm.fileValidTo,
                      callback: function ($$v) {
                        _vm.fileValidTo = $$v
                      },
                      expression: "fileValidTo",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "my-2" }, [
                _c("label", { attrs: { for: "inputFile" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-row mr-2" }, [
                        _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c("mdb-icon", {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "file-search",
                                far: "",
                                color: "primary",
                                size: "2x",
                              },
                            }),
                            _c("tdoxTooltip", {
                              staticStyle: {
                                position: "absolute",
                                left: "24px",
                                top: "-8px",
                              },
                              attrs: { detail: "Choose file" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", [
                        _vm.fileName == ""
                          ? _c("span", [_c("i", [_vm._v("Choose file")])])
                          : _c("span", [_vm._v(_vm._s(_vm.fileName))]),
                      ]),
                    ]
                  ),
                ]),
                _c("input", {
                  staticStyle: { display: "none" },
                  attrs: { id: "inputFile", type: "file", multiple: false },
                  on: { change: (event) => _vm.onFileInput(event) },
                }),
              ]),
              _c(
                "div",
                [
                  _c("awgt-input", {
                    staticStyle: { "min-width": "50px" },
                    attrs: {
                      id: "fileIsPubliclyAccessibleInd",
                      type: "checkbox",
                      name: "fileIsPubliclyAccessibleInd",
                      label: "Publicly Accessible",
                    },
                    model: {
                      value: _vm.fileIsPubliclyAccessibleInd,
                      callback: function ($$v) {
                        _vm.fileIsPubliclyAccessibleInd = $$v
                      },
                      expression: "fileIsPubliclyAccessibleInd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.SaveFileEntry()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onCancelFileEntryEditor()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.showCategoryEditor } },
        [
          _c("asoft-modal-header", {
            attrs: { label: _vm.title },
            on: {
              close: function ($event) {
                return _vm.onCancelCategoryEditor()
              },
            },
          }),
          _c(
            "mdb-modal-body",
            [
              _c("awgt-input", {
                attrs: {
                  bg: "",
                  label: _vm.categoryInputPlaceholder,
                  maxlength: "50",
                  counterMaxValue: 50,
                  counter: "",
                },
                model: {
                  value: _vm.newCategoryName,
                  callback: function ($$v) {
                    _vm.newCategoryName = $$v
                  },
                  expression: "newCategoryName",
                },
              }),
              _c("awgt-std-dropdown", {
                staticStyle: { width: "250px" },
                attrs: {
                  items: _vm.getSupplierFileCategoryTypes(false),
                  itemValueProperty: "Code",
                  itemTextProperty: "Name",
                  label: "Category Type",
                  bg: "",
                  tooltip: _vm.getTooltipsTextByCode(54),
                },
                model: {
                  value: _vm.newCategoryType,
                  callback: function ($$v) {
                    _vm.newCategoryType = $$v
                  },
                  expression: "newCategoryType",
                },
              }),
            ],
            1
          ),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.SaveNewCategory()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onCancelCategoryEditor()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ok-prompt", { ref: "alertPrompt" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }