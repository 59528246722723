<template>
  <div class="mx-2">
    <h1 class="text-center">Impersonation</h1>
    <div>Workgroups:</div>
    <awgt-std-dropdown
      multiple
      selectAll
      label="Select"
      itemValueProperty="Code"
      :items="workgroups"
      itemTextProperty="Name"
      v-model="filterWorkgroups"
      @input="filterUsers"
      bg
    ></awgt-std-dropdown>
    <form novalidate @submit.prevent="onSave">
      <div class="d-flex flex-grow-1">
        <!-- <div class="d-flex flex-fill"> -->
        <span
          class="flex-fill heading sorting-header"
          style="cursor: pointer; margin-left: 1px"
          @click="onToggleFilter"
          >User:<mdb-icon
            v-if="onToggleFilterInOrder == true"
            style="cursor: pointer"
            icon="caret-down"
          /><mdb-icon
            v-if="onToggleFilterInOrder == false"
            style="cursor: pointer"
            icon="caret-up"
          />
        </span>
        <span class="flex-fill heading sm-not-display"> Role: </span>
        <span class="flex-fill-workgroup heading sm-not-display">
          Workgroups:
        </span>
        <span class="flex-fill heading sm-not-display"> Impersonated By: </span>

        <span class="flex-fill-end heading sm-not-display"> Validity: </span>
      </div>
      <template v-for="(user, userIndex) in users">
        <div v-show="user.isDisplayed == true" v-bind:key="userIndex">
          <div
            class="d-flex flex-wrap flex-grow-1 align-items-center my-2"
            v-for="(impersonator, impersonatorIndex) in user.Impersonators"
            :key="impersonatorIndex"
          >
            <div class="d-flex flex-fill flex-nowrap">
              <span
                v-if="impersonatorIndex == 0"
                class="flex-fill row-font bold"
                :data-role="
                  impersonatorIndex == 0
                    ? ` - ${getConsultancyRoleNameById(
                        user.ConsultancyRole.ConsultancyRoleId
                      )}`
                    : null
                "
                >{{ user.FirstName + " " + user.Surname }}
              </span>
              <span v-else class="flex-fill row-font"></span>
            </div>
            <span class="flex-fill row-font sm-not-display">
              {{
                impersonatorIndex == 0
                  ? getConsultancyRoleNameById(
                      user.ConsultancyRole.ConsultancyRoleId
                    )
                  : null
              }}</span
            >
            <span class="flex-fill-workgroup row-font sm-not-display">
              {{
                impersonatorIndex == 0
                  ? displayWorkgroups(user.Workgroups)
                  : null
              }}</span
            >
            <span style="margin-left: 2px" class="flex-fill row-font sm-display"
              >Workgroups:
              {{
                impersonatorIndex == 0
                  ? displayWorkgroups(user.Workgroups)
                  : null
              }}</span
            >
            <span v-if="impersonator.Edit == true" class="flex-fill">
              <span
                v-if="impersonatorIndex == 0"
                style="margin-left: 2px"
                class="sm-display"
                >Impersonated By:<br />
              </span>
              <awgt-std-dropdown
                label="Impersonator"
                class="identation"
                itemValueProperty="Code"
                :items="populateImpersonatorsList(user)"
                itemTextProperty="Name"
                v-model="impersonator.Reference"
                bg
              ></awgt-std-dropdown>
            </span>
            <span
              v-if="
                user.Impersonators.length === 1 &&
                Object.keys(user.Impersonators[0]).length === 1 &&
                impersonator.Edit == false
              "
              style="margin-left: 2px"
              class="flex-fill"
              >User is not impersonated</span
            ><span
              v-if="
                Object.keys(user.Impersonators[0]).length !== 1 &&
                impersonator.Edit == false
              "
              class="flex-fill"
              ><span
                v-if="impersonatorIndex == 0"
                style="margin-left: 2px"
                class="sm-display"
              >
                Impersonated By: <br /></span
              ><span class="identation">
                {{ impersonator.FirstName + " " + impersonator.Surname }}
              </span>
            </span>
            <span
              class="d-flex flex-fill-end datepicker-block-end flex-row flex-wrap"
            >
              <div
                class="d-flex flex-row flex-nowrap identation align-items-center"
                style="min-width: 250px"
              >
                <mdb-icon
                  v-if="impersonator.Edit == true"
                  icon="calendar-alt"
                  far
                  color="primary"
                  class="mx-2"
                  size="2x"
                />
                <asoftDatePicker
                  v-if="impersonator.Edit == true"
                  v-model="impersonator.FromDt"
                  label="Start"
                  autoHide
                  :dataDateFormat="dataDateFormat"
                  :displayDateFormat="displayDateFormat"
                  setTime="00:00:00.000"
                  bg
                  class="flex-grow-1 mx-2"
                  style="max-width: 110px"
                  @change="(fromDt) => onFromDtChange(fromDt, impersonator)"
                ></asoftDatePicker>
                <mdb-icon
                  v-if="impersonator.Edit == true"
                  icon="calendar-alt"
                  far
                  color="primary"
                  class="mx-2"
                  size="2x"
                />
                <asoftDatePicker
                  v-if="impersonator.Edit == true"
                  v-model="impersonator.ToDt"
                  label="End"
                  autoHide
                  :dataDateFormat="dataDateFormat"
                  :displayDateFormat="displayDateFormat"
                  setTime="00:00:00.000"
                  bg
                  class="flex-grow-1 mx-2"
                  style="max-width: 110px"
                ></asoftDatePicker>
                <span
                  v-if="
                    !(
                      user.Impersonators.length === 1 &&
                      Object.keys(user.Impersonators[0]).length === 1
                    ) && impersonator.Edit == false
                  "
                  style="margin-left: 2px"
                  >{{ validityDatePeriod(impersonator) }}
                </span>
              </div>
              <div
                class="d-flex flex-row flex-nowrap identation align-items-center"
                style="min-width: 200px"
              >
                <awgt-std-button
                  v-if="
                    impersonator.Edit == false &&
                    impersonatorIndex == user.Impersonators.length - 1 &&
                    checkPermission('CP_ImC') &&
                    checkPermissionScopeForImperosnationCreate(user)
                  "
                  type="button"
                  title="Add"
                  class="row-button mr-2"
                  @click="
                    onAddImpersonator(user, impersonator, impersonatorIndex)
                  "
                >
                  <mdb-icon class="mr-1" icon="plus" />Add
                </awgt-std-button>
                <awgt-std-button
                  v-if="
                    !(
                      user.Impersonators.length === 1 &&
                      Object.keys(user.Impersonators[0]).length === 1
                    ) &&
                    impersonator.Edit == false &&
                    checkPermission('CP_ImU') &&
                    checkPermissionScopeForImpersonationEdit(user)
                  "
                  type="button"
                  title="Edit"
                  class="row-button mr-2"
                  @click="onEditImpersonator(user, impersonator)"
                >
                  <mdb-icon class="mr-1" icon="edit" />Edit
                </awgt-std-button>
                <awgt-std-button
                  v-if="
                    !(
                      user.Impersonators.length === 1 &&
                      Object.keys(user.Impersonators[0]).length === 1
                    ) &&
                    impersonator.Edit == false &&
                    checkPermission('CP_ImD') &&
                    checkPermissionScopeForImpersonationDelete(user)
                  "
                  type="button"
                  title="Delete"
                  class="row-button mr-2"
                  @click="onDeleteImpersonator(user, impersonatorIndex)"
                >
                  <mdb-icon class="mr-1" icon="trash-alt" />Delete
                </awgt-std-button>
                <awgt-std-button
                  v-if="impersonator.Edit == true"
                  type="button"
                  title="Save"
                  class="row-button mr-2"
                  @click="onSaveImpersonator(user, impersonator)"
                >
                  <mdb-icon class="mr-1" icon="check" />Save
                </awgt-std-button>
                <awgt-std-button
                  v-if="impersonator.Edit == true"
                  type="button"
                  title="Cancel"
                  class="row-button mr-2"
                  @click="
                    onCancelImpersonator(user, impersonator, impersonatorIndex)
                  "
                >
                  <mdb-icon class="mr-1" icon="times" />Cancel
                </awgt-std-button>
              </div>
            </span>
          </div>
          <div class="section-break"></div>
        </div>
      </template>
      <div class="float-right mt-2 mb-5">
        <awgt-std-button
          type="button"
          title="history"
          class="command-button mx-2"
        >
          <mdb-icon class="mr-1" icon="check" />History
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Update/Add"
          class="command-button"
          v-on:click="onClose()"
        >
          <mdb-icon class="mr-1" icon="times" />Close
        </awgt-std-button>
      </div>
    </form>
  </div>
</template>
<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbIcon } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import impersonationApi from "@/api/impersonationApi.js";
import workgroupApi from "@/api/workgroupApi.js";
import { mapGetters } from "vuex";
import tokenManager from "@/shared/securityTokenManager.js";
import userApi from "@/api/userApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return {
      formSubmissionErrors: [],
      onToggleFilterInOrder: true,
      users: [],
      dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
      displayDateFormat: "D MMM YYYY",
      workgroups: [],
      filterWorkgroups: [],
      currentUserLevel: 0,
      currentUserReference: null,
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForImpersonationCreate: null,
      currentUserPermissionScopeForImpersonationUpdate: null,
      currentUserPermissionScopeForImpersonationDelete: null,
    };
  },
  components: {
    mdbIcon,

    AwgtStdButton,
    asoftDatePicker,
    AwgtStdDropdown,
  },
  computed: {
    ...mapGetters([
      "getConsultancyRoleNameById",
      "getEmptyImpersonator",
      "checkPermission",
    ]),

    checkPermissionScopeForImperosnationCreate() {
      return function (user) {
        if (!this.currentUserPermissionScopeForImpersonationCreate)
          return false;

        if (this.currentUserPermissionScopeForImpersonationCreate == "CPS_NA") {
          if (user.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationCreate == "CPS_BW"
        ) {
          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level > this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationCreate == "CPS_NW"
        ) {
          if (this.currentUserReference == user.Reference) return true; //Current user himself

          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level >= this.currentUserLevel
          )
            return false;
        }

        return true;
      };
    },

    checkPermissionScopeForImpersonationDelete() {
      return function (user) {
        if (!this.currentUserPermissionScopeForImpersonationDelete)
          return false;

        if (this.currentUserPermissionScopeForImpersonationDelete == "CPS_NA") {
          if (user.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationDelete == "CPS_BW"
        ) {
          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level > this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationDelete == "CPS_NW"
        ) {
          if (this.currentUserReference == user.Reference) return true; //Current user himself

          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level >= this.currentUserLevel
          )
            return false;
        }

        return true;
      };
    },

    checkPermissionScopeForImpersonationEdit() {
      return function (user) {
        if (!this.currentUserPermissionScopeForImpersonationUpdate)
          return false;

        if (this.currentUserPermissionScopeForImpersonationUpdate == "CPS_NA") {
          if (user.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationUpdate == "CPS_BW"
        ) {
          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level > this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForImpersonationUpdate == "CPS_NW"
        ) {
          if (this.currentUserReference == user.Reference) return true; //Current user himself

          if (
            (user.Workgroups.length != 0 &&
              user.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              )) ||
            user.ConsultancyRole.Level >= this.currentUserLevel
          )
            return false;
        }

        return true;
      };
    },

    displayWorkgroups() {
      return function (workgroups) {
        return workgroups
          ?.map((w) => {
            return w.Name;
          })
          ?.join(", ");
      };
    },
  },

  methods: {
    filterUsers(val) {
      this.users.forEach((u) => (u.isDisplayed = false));
      if (val.length == 0) {
        this.users.forEach((u) => (u.isDisplayed = true));
        return;
      }
      for (var i = 0; i < val.length; i++) {
        for (let user of this.users) {
          for (let w of user.Workgroups) {
            if (w.Reference == val[i]) {
              user.isDisplayed = true;
            }
          }
        }
      }
    },

    onFromDtChange(fromDt, impersonator) {
      if (impersonator.ToDt == null) impersonator.ToDt = fromDt;
    },

    onToggleFilter() {
      this.onToggleFilterInOrder = !this.onToggleFilterInOrder;
      if (this.onToggleFilterInOrder == true)
        this.onSortUsersInAlphabeticalOrder();
      else this.onSortUsersInReverseAlphabeticalOrder();
    },

    validityDatePeriod(imp) {
      return `From ${this.$moment(imp.FromDt).format("D MMM YYYY")} ${
        imp.ToDt != null
          ? `to ${this.$moment(imp.ToDt).format("D MMM YYYY")}`
          : ""
      }`;
    },

    validateForm(user, impersonator) {
      this.formSubmissionErrors = [];
      if (impersonator.FromDt == null || impersonator.FromDt.length == 0)
        this.formSubmissionErrors.push("From Date cannot be null.");
      if (impersonator.Reference == null)
        this.formSubmissionErrors.push("Impersonator cannot be null.");
      if (impersonator.ToDt != null) {
        if (new Date(impersonator.ToDt) < new Date(impersonator.FromDt))
          this.formSubmissionErrors.push(
            "To date must be on or after from date."
          );
      }
      var valueArr = user.Impersonators.map((item) => {
        return item.Reference;
      });
      var duplicateReference = 0;
      if (
        valueArr.some((r, idx) => {
          if (valueArr.indexOf(r) != idx) duplicateReference = r;
          return valueArr.indexOf(r) != idx;
        })
      ) {
        var impArr = user.Impersonators.filter((item) => {
          return item.Reference == impersonator.Reference;
        });
        impArr.splice(valueArr.indexOf(impersonator), 1);
        for (let imp of impArr) {
          if (
            (new Date(imp.FromDt) <= new Date(impersonator.FromDt) &&
              new Date(impersonator.FromDt) <= new Date(imp.ToDt)) ||
            (new Date(imp.FromDt) <= new Date(impersonator.ToDt) &&
              new Date(impersonator.ToDt) <= new Date(imp.ToDt)) ||
            (new Date(impersonator.FromDt) <= new Date(imp.FromDt) &&
              (impersonator.ToDt == null ||
                (imp.ToDt != null &&
                  new Date(impersonator.ToDt) >= new Date(imp.ToDt)))) ||
            (imp.ToDt == null && impersonator.ToDt == null) ||
            (imp.ToDt == null &&
              impersonator.ToDt != null &&
              new Date(impersonator.ToDt) >= new Date(imp.FromDt)) ||
            (imp.ToDt != null &&
              impersonator.ToDt == null &&
              new Date(imp.ToDt) >= new Date(impersonator.FromDt))
          ) {
            this.formSubmissionErrors.push(
              `Cannot select duplicate impersonator ${
                this.users.find((user) => user.Reference == duplicateReference)
                  .FirstName +
                " " +
                this.users.find((user) => user.Reference == duplicateReference)
                  .Surname
              } with overlapping period.`
            );
          }
        }
      }
      return this.formSubmissionErrors.length == 0;
    },

    onClose() {
      this.$router.push("/");
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    onSortUsersInAlphabeticalOrder() {
      this.users.sort(function (a, b) {
        var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    },

    onSortUsersInReverseAlphabeticalOrder() {
      this.users.sort(function (a, b) {
        var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    },

    onDeleteImpersonator(user, impersonatorIndex) {
      this.formSubmissionErrors = [];

      this.deleteImpersonator(user.Impersonators[impersonatorIndex])
        .then(() => {
          user.Impersonators.splice(impersonatorIndex, 1);

          this.$emit("header-message", {
            isSuccessInd: true,
            Message: ["The impersonation information has been saved."],
          });
        })
        .catch(() => {
          // Errors have been logged
        });
      if (user.Impersonators.length == 0) {
        this.$set(user.Impersonators, 0, { Edit: false });
      }
    },

    onAddImpersonator(user, impersonator, index) {
      if (index == 0 && Object.keys(user.Impersonators[0]).length === 1) {
        this.$set(
          user.Impersonators,
          0,
          this.$lodash.clone(this.getEmptyImpersonator)
        );
      } else {
        user.Impersonators.push(this.$lodash.clone(this.getEmptyImpersonator));
      }
    },

    onSaveImpersonator(user, impersonator) {
      if (impersonator.ToDt == null || impersonator.ToDt.length == 0)
        impersonator.ToDt = null;
      if (this.validateForm(user, impersonator) == true) {
        this.saveImpersonator(user, impersonator)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: ["The User Impersoantion information has been updated."],
            });
          })
          .catch(() => {
            // Errors have been logged
          });
      } else {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      }
    },

    onEditImpersonator(user, impersonator) {
      impersonator.Edit = true;
      this.$set(impersonator, "IsAdded", false);
    },

    onCancelImpersonator(user, impersonator, index) {
      impersonator.Edit = false;
      if (impersonator.IsAdded == true) {
        if (index == 0) {
          this.$set(user.Impersonators, 0, { Edit: false });
        } else {
          user.Impersonators.splice(-1, 1);
        }
      }
    },

    populateImpersonatorsList(user) {
      let availableImpersonators = new Array();

      for (let impersonatorUser of this.users) {
        if (
          impersonatorUser.ConsultancyRole.Level >=
            user.ConsultancyRole.Level &&
          impersonatorUser.Reference != user.Reference
        )
          availableImpersonators.push({
            Code: impersonatorUser.Reference,
            Name: impersonatorUser.FirstName + " " + impersonatorUser.Surname,
          });
      }
      return availableImpersonators;
    },

    async deleteImpersonator(impersonator) {
      await impersonationApi.deleteImpersonator(impersonator);
    },

    async saveImpersonator(user, impersonator) {
      let returnUser;
      if (impersonator.IsAdded == true)
        returnUser = await impersonationApi.postImpersonator(
          user.Reference,
          impersonator
        );
      else
        returnUser = await impersonationApi.putImpersonator(
          user.Reference,
          impersonator
        );
      for (let impersonator of returnUser.Impersonators) {
        impersonator.FromDt = this.$moment(impersonator.FromDt).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        impersonator.ToDt =
          impersonator.ToDt == null
            ? null
            : this.$moment(impersonator.ToDt).format("YYYY-MM-DDTHH:mm:ss");
        this.$set(impersonator, "Edit", false);
      }
      this.$set(returnUser, "isDisplayed", true);
      if (returnUser.Impersonators.length === 0) {
        this.$set(returnUser.Impersonators, 0, { Edit: false });
      }
      let index = this.users.findIndex((u) => {
        return u.Reference == returnUser.Reference;
      });

      this.$set(this.users, index, returnUser);
    },
    async loadFormData() {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;
      this.currentUserReference = currentUser.Reference;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForImpersonationCreate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ImC";
        })?.PermissionScope;

      this.currentUserPermissionScopeForImpersonationUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ImU";
        })?.PermissionScope;

      this.currentUserPermissionScopeForImpersonationDelete =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ImD";
        })?.PermissionScope;

      this.users = await impersonationApi.getUserImpersonation();
      const allworkgroups = await workgroupApi.getWorkgroups();
      this.workgroups = allworkgroups.map((workgroup) => {
        return {
          Name: workgroup.Name,
          Code: workgroup.Reference,
        };
      });
      for (let user of this.users) {
        this.$set(user, "isDisplayed", true);
        for (let impersonator of user.Impersonators) {
          impersonator.FromDt = this.$moment(impersonator.FromDt).format(
            "YYYY-MM-DDTHH:mm:ss"
          );
          impersonator.ToDt =
            impersonator.ToDt == null
              ? null
              : this.$moment(impersonator.ToDt).format("YYYY-MM-DDTHH:mm:ss");
          this.$set(impersonator, "Edit", false);
        }
        if (user.Impersonators.length === 0) {
          this.$set(user.Impersonators, 0, { Edit: false });
        }
      }
    },
  },
  mounted() {
    this.loadFormData().catch(() => {
      // Suppress errrors, which have been logged.
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/sharedVariables.scss";
.heading {
  font-weight: bold;
  color: $headingColor;
  // text-transform: uppercase !important;
}

.sorting-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.flex-fill {
  flex-basis: 0 !important;
  flex-grow: 1;
  padding-left: 2px;
}

.flex-fill-end {
  flex-basis: 35vw !important;
}

.flex-fill-workgroup {
  flex-basis: 23vw !important;
}

.bold {
  font-weight: bold;
}

.row-font {
  font-size: 1rem;
}
.row {
  padding: 5px 5%;
}

.row-button {
  min-width: 70px;
  height: 30px;
  padding: 0px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.sm-display {
  display: none;
}

@media (min-width: 700px) {
  .section-break {
    display: none;
  }
}
@media (max-width: 700px) {
  .flex-fill {
    flex-basis: 80vw !important;
  }

  .datepicker-block-end {
    flex-basis: 80vw !important;
  }

  .sm-display {
    display: inline;
  }
  .sm-not-display {
    display: none;
  }
  span[data-role]::after {
    content: attr(data-role);
  }

  .identation {
    padding-left: 1cm;
  }
}
</style>
