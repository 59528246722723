var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value != undefined &&
    _vm.templateArray.length > 0 &&
    Object.keys(_vm.value).length > 0
    ? _c("div", [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _vm._l(_vm.templateArray, function (template, index) {
              return [
                _vm._t("default", null, {
                  template: template,
                  phone: _vm.items.PhoneNumberStorageTemplate.PhoneNumbers[
                    index
                  ]
                    ? _vm.items.PhoneNumberStorageTemplate.PhoneNumbers[index]
                    : { Name: "", Value: "" },
                }),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }