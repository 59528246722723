<template>
  <mdb-container fluid>
    <h1 class="text-center">Product Search</h1>
    <product-search
      :isTravelBookingProductSearch="false"
      @header-message="displayHeaderMessage"
    />
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import ProductSearch from "@/components/ProductSearch";
import { mdbContainer } from "mdbvue";

export default {
  components: {
    ProductSearch,
    mdbContainer,
  },
  methods: {
    displayHeaderMessage(e) {
      this.$emit("header-message", e);
    },
  },
};
</script>
