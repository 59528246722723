<template>
  <div>
    <h5>
      <mdb-icon
        far
        class="icon-in-views"
        fas
        icon="dolly"
        size="1x"
        color="primary"
      />
      <span>{{ value.Name }} </span>
      <span style="font-size: 75%"
        ><i>({{ value.Reference }})</i></span
      >
    </h5>
    <div v-if="value.ContactPersonNm.length > 0">
      <mdb-icon
        far
        class="icon-in-views"
        icon="user"
        color="primary"
        size="1x"
      />
      <span>{{ value.ContactPersonNm }}</span>
    </div>
    <div
      v-if="
        physicalAddress.Street.length +
          physicalAddress.Locality.length +
          (physicalAddress.Subdivision != null
            ? physicalAddress.Subdivision.length
            : 0) +
          physicalAddress.Postcode.length >
        0
      "
    >
      <mdb-icon
        far
        class="icon-in-views"
        icon="map-marker-alt"
        color="primary"
        size="1x"
      />
      <template v-if="physicalAddress.Street.length > 0">
        <span>{{ physicalAddress.Street }},</span>
      </template>
      <template v-if="physicalAddress.Locality.length > 0">
        {{ physicalAddress.Locality }}
      </template>
      <template
        v-if="
          physicalAddress.Subdivision != null &&
          physicalAddress.Subdivision.length > 0
        "
      >
        {{ physicalAddress.Subdivision }}
      </template>
      <template v-if="physicalAddress.Postcode.length > 0">
        {{ physicalAddress.Postcode }}
      </template>
    </div>
    <div
      v-if="
        postalAddress.Street.length +
          postalAddress.Locality.length +
          (postalAddress.Subdivision != null
            ? postalAddress.Subdivision.length
            : 0) +
          postalAddress.Postcode.length >
        0
      "
    >
      <mdb-icon
        far
        class="icon-in-views"
        icon="envelope"
        color="primary"
        size="1x"
      />
      <template v-if="postalAddress.Street.length > 0">
        <span>{{ postalAddress.Street }},</span>
      </template>
      <template v-if="postalAddress.Locality.length > 0">
        {{ postalAddress.Locality }}
      </template>
      <template
        v-if="
          postalAddress.Subdivision != null &&
          postalAddress.Subdivision.length > 0
        "
      >
        {{ postalAddress.Subdivision }}
      </template>
      <template v-if="postalAddress.Postcode.length > 0">
        {{ postalAddress.Postcode }}
      </template>
    </div>
    <div v-if="value.Phone.length > 0">
      <span class="mr-3">
        <mdb-icon
          far
          class="icon-in-views"
          icon="phone"
          color="primary"
          size="1x"
        />
        <a :href="'tel:' + value.Phone"
          ><asoft-field-formatter fieldType="PhoneNumber">{{
            value.Phone
          }}</asoft-field-formatter></a
        >
      </span>
      <span v-if="value.EmailAddress.length > 0">
        <mdb-icon
          far
          class="icon-in-views"
          icon="at"
          color="primary"
          size="1x"
        />
        <a :href="'mailto:' + value.EmailAddress">{{ value.EmailAddress }}</a>
      </span>
    </div>
    <template v-if="value.DepositAmt > 0">
      <div v-if="value.DepositType == 'DT_PP' || value.DepositType == 'DT_FA'">
        <mdb-icon
          far
          class="icon-in-views"
          icon="dollar-sign"
          color="primary"
          size="1x"
        />
        <span
          >Deposit: ${{ value.DepositAmt }} ({{
            getDepositTypeNameByCode(value.DepositType)
          }})</span
        >
      </div>
      <div v-else-if="value.DepositType == 'DT_Pe'">
        <mdb-icon
          far
          class="icon-in-views"
          icon="dollar-sign"
          color="primary"
          size="1x"
        />
        <span
          >Deposit: {{ value.DepositAmt }}% ({{
            getDepositTypeNameByCode(value.DepositType)
          }})</span
        >
      </div>
    </template>
    <div v-if="value.PaymentTypes.length > 0">
      <mdb-icon
        far
        color="primary"
        icon="money-check-edit-alt"
        class="icon-in-views"
        size="1x"
      />
      <span>Payment Types:</span><br />
      <div class="html-in-views">
        <ul style="margin: 0px">
          <li v-for="(pt, idx) in value.PaymentTypes" :key="idx">
            <!-- {{ getPaymentTypeNameByCode(pt) }} -->
            {{ getPaymentTypeFromCode(pt) }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="value.BookingMethod.length > 0">
      <mdb-icon
        far
        color="primary"
        icon="phone-square-alt"
        class="icon-in-views"
        size="1x"
      />
      <span
        >Booking Method:
        {{ getBookingMethodNameByCode(value.BookingMethod) }}</span
      >
    </div>
    <div v-if="value.ItineraryNotes.length > 0">
      <mdb-icon
        far
        class="icon-in-views"
        icon="clipboard"
        color="primary"
        size="1x"
      />
      <span>Itinerary Notes:</span><br />
      <div class="html-in-views">
        <span v-html="value.ItineraryNotes"></span>
      </div>
    </div>
    <div v-if="value.AWGTNotes.length > 0">
      <mdb-icon
        far
        class="icon-in-views"
        icon="clipboard"
        color="primary"
        size="1x"
      />
      <span>Agency Notes:</span><br />
      <div class="html-in-views">
        <span v-html="value.AWGTNotes"></span>
      </div>
    </div>

    <div v-if="value.SupplierNotes.length > 0">
      <mdb-icon
        far
        class="icon-in-views"
        icon="clipboard"
        color="primary"
        size="1x"
      />
      <span>AWGT Supplier Notes:</span><br />
      <ul
        v-for="(note, index) in value.SupplierNotes"
        :key="index"
        class="mb-0"
      >
        <li v-if="note.Note.length > 0" class="html-in-views">
          <span
            ><b>{{ note.Subject }}:</b></span
          >
          {{ note.Note }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdbIcon } from "mdbvue";
import asoftFieldFormatter from "@/components/AtomSoftware/asoftFieldFormatter";

export default {
  components: {
    mdbIcon,
    asoftFieldFormatter,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "getDepositTypeNameByCode",
      "getPaymentTypeNameByCode",
      "getBookingMethodNameByCode",
    ]),
    postalAddress: function () {
      return this.value.Addresses.find((a) => {
        return a.Type == "AT_Po";
      });
    },
    physicalAddress: function () {
      return this.value.Addresses.find((a) => {
        return a.Type == "AT_Ph";
      });
    },
  },
  methods: {
    getPaymentTypeFromCode(code) {
      switch (code) {
        case "PY_PP":
          return "Pre-Paid";
        case "PY_PO":
          return "Purchase Order";
        case "PY_Vo":
          return "Voucher";
        default:
          "";
      }
    },
  },
};
</script>

<style></style>
