var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent" }, [
    _c("div", [
      _vm.success == false
        ? _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("h1", [_vm._v(_vm._s(_vm.message))]),
          ])
        : _vm._e(),
      _vm.success == true
        ? _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "150px" } },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm.success == false
        ? _c("div", [
            _c("div", { staticClass: "user-input" }, [
              _c("h4", [_vm._v("Please Reset Your Password")]),
              _vm._m(1),
              _vm.passwordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Enter your password",
                      maxlength: "100",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.password)
                        ? _vm._i(_vm.password, null) > -1
                        : _vm.password,
                    },
                    on: {
                      keyup: _vm.onChangePassword,
                      change: function ($event) {
                        var $$a = _vm.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.password = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.password = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.password = $$c
                        }
                      },
                    },
                  })
                : _vm.passwordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Enter your password",
                      maxlength: "100",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.password, null) },
                    on: {
                      keyup: _vm.onChangePassword,
                      change: function ($event) {
                        _vm.password = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Enter your password",
                      maxlength: "100",
                      type: _vm.passwordType,
                    },
                    domProps: { value: _vm.password },
                    on: {
                      keyup: _vm.onChangePassword,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-eye": _vm.passwordType == "text",
                  "fa-eye-slash": _vm.passwordType == "password",
                },
                on: {
                  click: () =>
                    _vm.passwordType == "password"
                      ? (_vm.passwordType = "text")
                      : (_vm.passwordType = "password"),
                },
              }),
              _c("br"),
              _c("div", { staticClass: "progress-bar_wrap" }, [
                _c("div", {
                  staticClass: "progress-bar_item",
                  class: {
                    "progress-bar_item-1": _vm.password.length > 0,
                  },
                }),
                _c("div", {
                  staticClass: "progress-bar_item",
                  class: {
                    "progress-bar_item-2":
                      _vm.checkPassword(_vm.password) == 3 ||
                      _vm.checkPassword(_vm.password) == 4,
                  },
                }),
                _c("div", {
                  staticClass: "progress-bar_item",
                  class: {
                    "progress-bar_item-3": _vm.checkPassword(_vm.password) == 4,
                  },
                }),
              ]),
              _c("span", { staticClass: "progress-bar_text" }, [
                _vm._v(_vm._s(_vm.PasswordText)),
              ]),
              _c("br"),
              _vm.confirmedPasswordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmedPassword,
                        expression: "confirmedPassword",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "30px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Confirm your password",
                      maxlength: "100",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.confirmedPassword)
                        ? _vm._i(_vm.confirmedPassword, null) > -1
                        : _vm.confirmedPassword,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.confirmedPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.confirmedPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.confirmedPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.confirmedPassword = $$c
                        }
                      },
                    },
                  })
                : _vm.confirmedPasswordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmedPassword,
                        expression: "confirmedPassword",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "30px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Confirm your password",
                      maxlength: "100",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.confirmedPassword, null) },
                    on: {
                      change: function ($event) {
                        _vm.confirmedPassword = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmedPassword,
                        expression: "confirmedPassword",
                      },
                    ],
                    staticClass: "password-input field-input",
                    staticStyle: { "margin-top": "30px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Confirm your password",
                      maxlength: "100",
                      type: _vm.confirmedPasswordType,
                    },
                    domProps: { value: _vm.confirmedPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.confirmedPassword = $event.target.value
                      },
                    },
                  }),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-eye": _vm.confirmedPasswordType == "text",
                  "fa-eye-slash": _vm.confirmedPasswordType == "password",
                },
                on: {
                  click: () =>
                    _vm.confirmedPasswordType == "password"
                      ? (_vm.confirmedPasswordType = "text")
                      : (_vm.confirmedPasswordType = "password"),
                },
              }),
              _c("br"),
              _c(
                "button",
                { staticClass: "btn", on: { click: _vm.onConfirm } },
                [_vm._v("Confirm Password")]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "success-message" }, [
      _c("span", [_vm._v("✓ ")]),
      _vm._v(
        "The password has been reset, you be will redirected to login page soon"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "text-align": "left !important",
          color: "grey",
          "margin-left": "25%",
          padding: "0px 10px",
          "margin-top": "20px",
          "font-size": "14px",
        },
      },
      [
        _c("span", [_vm._v("Passwords must be:")]),
        _c("ul", [
          _c("li", [_vm._v("between 8 and 100 characters in length")]),
          _c("li", [
            _vm._v("contain a mix of uppercase and lowercase letters"),
          ]),
          _c("li", [
            _vm._v("contain at least one number or special character"),
          ]),
          _c("li", [_vm._v("password phrases are encouraged")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }