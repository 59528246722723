import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getTravelBookingsReport(
    workgroups,
    consultants,
    bookingStatuses,
    year,
    terms,
    startDate,
    toDate,
    isAdvanced = false
  ) {
    try {
      logger.get(loggingSource.UIReport).info("getTravelBookingsReport...");

      logger
        .get(loggingSource.UIReport)
        .debug(
          "Parameters => workgroups: %s, consultants: %s,bookingStatuses: %s,year: %s, terms: %s, startDate: %s, toDate: %s, isAdvanced: %s",
          workgroups,
          consultants,
          bookingStatuses,
          year,
          terms,
          startDate,
          toDate,
          isAdvanced
        );

      logger.get(loggingSource.UIReport).time("getTravelBookingsReport call");
      const response = await api.client
        .get("report/travelBookingsReport", {
          params: {
            Workgroups: workgroups,
            Consultants: consultants,
            BookingStatuses: bookingStatuses,
            Year: year.toString(),
            Terms: terms,
            StartDate: startDate,
            ToDate: toDate,
            isAdvanced: isAdvanced,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getTravelBookingsReport call");
      const result = response.data;
      logger
        .get(loggingSource.UIReport)
        .debug("getTravelBookingsReport result: %s", result);

      return result;
    } catch (error) {
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getTravelBookingsReport call");
      logger.get(loggingSource.UIReport).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIReport).info("...getUserByReference");
    }
  },

  async getItineraryEntriesReport(
    workgroups,
    consultants,
    productTypes,
    suppliers,
    year,
    terms,
    startDate,
    toDate
  ) {
    try {
      logger.get(loggingSource.UIReport).info("getItineraryEntriesReport...");

      logger
        .get(loggingSource.UIReport)
        .debug(
          "Parameters => workgroups: %s, consultants: %s,productTypes: %s, suppliers: %s, year: %s, terms: %s, startDate: %s, toDate: %s",
          workgroups,
          consultants,
          productTypes,
          suppliers,
          year,
          terms,
          startDate,
          toDate
        );

      logger.get(loggingSource.UIReport).time("getItineraryEntriesReport call");
      const response = await api.client
        .get("report/itineraryEntriesReport", {
          params: {
            Workgroups: workgroups,
            Consultants: consultants,
            Types: productTypes,
            Suppliers: suppliers,
            Year: year.toString(),
            Terms: terms,
            StartDate: startDate,
            ToDate: toDate,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getItineraryEntriesReport call");
      const result = response.data;
      logger
        .get(loggingSource.UIReport)
        .debug("getItineraryEntriesReport result: %s", result);

      return result;
    } catch (error) {
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getItineraryEntriesReport call");
      logger.get(loggingSource.UIReport).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIReport).info("...getItineraryEntriesReport");
    }
  },

  async getSupplierProductInformationReport(
    productType,
    region,
    supplier,
    products,
    yearGroups,
    years,
    supplierType
  ) {
    try {
      logger
        .get(loggingSource.UIReport)
        .info("getSupplierProductInformationReport...");

      logger
        .get(loggingSource.UIReport)
        .debug(
          "Parameters => productType: %s, region: %s, supplier: %s, products: %s, yearGroups: %s, years: %s, supplierType: %s",
          productType,
          region,
          supplier,
          products,
          yearGroups,
          years,
          supplierType
        );

      logger
        .get(loggingSource.UIReport)
        .time("getSupplierProductInformationReport call");
      const response = await api.client
        .get("report/supplierProductInformationReport", {
          params: {
            productType: productType,
            region: region,
            supplier: supplier,
            products: products,
            yearGroups: yearGroups,
            years: years,
            supplierType: supplierType,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getSupplierProductInformationReport call");
      const result = response.data;
      logger
        .get(loggingSource.UIReport)
        .debug("getSupplierProductInformationReport result: %s", result);

      return result;
    } catch (error) {
      logger
        .get(loggingSource.UIReport)
        .timeEnd("getSupplierProductInformationReport call");
      logger.get(loggingSource.UIReport).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIReport)
        .info("...getSupplierProductInformationReport");
    }
  },
};
