<template>
  <mdb-container fluid>
    <h1 class="text-center">Yearly Matrix Report</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        label="Company Section"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        s
        itemValueProperty="Code"
        :items="getCompnaySection"
        itemTextProperty="Name"
        v-model="companySection"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Year"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getYear"
        itemTextProperty="Name"
        v-model="companySection"
        bg
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <asoftDatePicker
        v-model="date"
        label="Date"
        autoHide
        displayDateFormat="D MMM YYYY"
        dataDateFormat="YYYY-MM-DDTHH:mm:ss"
        setTime="00:00:00.000"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 90px"
      />
    </div>
    <mdb-form-inline class="flex-wrap border mx-1 my-2 px-2 py-3 mb-2">
      <awgt-input
        id="orderType_OrderedByTravelDate"
        v-model="orderType"
        type="radio"
        name="OrderType"
        label="Ordered by travel date"
        radioValue="O_TD"
        class="d-flex label-margin-0"
      />
      <awgt-input
        id="orderedType_OrderedByAlphabetically"
        v-model="orderType"
        type="radio"
        name="OrderType"
        label="ordered alphabetically"
        radioValue="O_AL"
        class="d-flex label-margin-0"
      />
    </mdb-form-inline>
  </mdb-container>
</template>
<script>
import { mdbContainer, mdbFormInline } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
export default {
  data() {
    return {
      date: null,
      companySection: "",
      orderType: "",
      getCompnaySection: [{ Code: 1, Name: "AWGT" }],
      getYear: [{ Code: 1, Name: "2020" }],
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
    asoftDatePicker,
    awgtInput,
    mdbFormInline,
  },
};
</script>
