<template>
  <mdb-container fluid>
    <div class="mx-2">
      <h1 class="text-center">Itinerary List</h1>

      <p>
        Showing itineraries for booking
        {{ booking == null ? "loading..." : booking.Reference }} for client
        {{
          booking == null || booking.Client == null
            ? "loading..."
            : booking.Client.DisplayName
        }}.
      </p>
      <div class="float-right">
        <awgt-std-button
          v-if="
            checkPermission('CP_ItC') && checkPermissionScopeForItineraryCreate
          "
          type="button"
          title="Add"
          class="command-button mx-2"
          @click="onAdd()"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
      <div style="clear: both"></div>

      <div
        v-if="itineraries == null || itineraries.length == 0"
        class="text-center"
      >
        There are currently no itineraries for this booking. Press Add to create
        the first itinerary.
      </div>
      <mdb-card
        v-else
        v-for="(itinerary, index) in itineraries"
        :key="index"
        class="mb-3 mx-2"
      >
        <mdb-card-header
          @click.native="onToggleItineraryDetailExpansion(index)"
          tag="button"
        >
          <template v-if="itinerary.pdf">
            <span class="float-right">
              <mdb-icon
                v-if="itineraryExpansion[index] == true"
                icon="angle-up"
                size="2x"
                class="pt-2"
                color="primary"
              />
              <!-- comment -->
              <mdb-icon
                v-else
                icon="angle-down"
                size="2x"
                class="pt-2"
                color="primary"
              />
            </span>
          </template>
          <span class="float-left" v-if="itinerary.IsActive == true">
            <tdoxTooltip
              class="mt-2 mr-auto"
              iconName="star"
              iconColor="gold"
              iconSize="3x"
              detail="Active Itinerary"
            />
          </span>
          <h4 class="mb-1">{{ itinerary.Name }}</h4>
          <div>
            <div>Description: {{ itinerary.Description }}</div>
            <div>
              Start Date:
              {{
                $moment(itinerary.StartDt, "YYYY-MM-DDTHH:mm:ss").format(
                  "D MMM YYYY"
                )
              }}
            </div>
            <div>Duration: {{ itinerary.DurationInDays }} days</div>
          </div>
          <div class="my-2 float-right">
            <awgt-std-button
              v-if="
                checkPermission('CP_ItC') &&
                checkPermissionScopeForItineraryCreate
              "
              type="button"
              title="Duplicate"
              class="command-button mx-2"
              @click="onDuplicate(itinerary)"
            >
              <mdb-icon class="mr-1" icon="copy" />Duplicate
            </awgt-std-button>
            <awgt-std-button
              type="button"
              title="Products"
              class="command-button mx-2"
              @click="onGeneratePDF(itinerary, index)"
              :disabled="itinerary.pdf != undefined"
            >
              <mdb-icon icon="file-pdf" class="mr-1" />Generate PDF
            </awgt-std-button>
            <awgt-std-button
              type="button"
              title="Entries"
              class="command-button mx-2"
              v-on:click="onEditEntries(itinerary)"
            >
              <mdb-icon class="mr-1" icon="calendar-alt" far />Entries
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_ItU') &&
                checkPermissionScopeForItineraryUpdate
              "
              type="button"
              title="Edit"
              class="command-button mx-2"
              v-on:click="onEdit(itinerary)"
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_ItD') &&
                checkPermissionScopeForItineraryDelete
              "
              type="button"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onDelete(itinerary)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
        </mdb-card-header>

        <transition name="slide">
          <expansion-region :toggle="itineraryExpansion[index]">
            <mdb-card-body v-if="itinerary.pdf">
              <div style="height: 100%; margin: 0 auto; width: 100%">
                <pdf-preview
                  width="100%"
                  height="700px"
                  :url="itinerary.pdf"
                ></pdf-preview>
              </div>
            </mdb-card-body>
          </expansion-region>
        </transition>
      </mdb-card>
    </div>
    <yes-or-no-prompt ref="handleItineraryPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>
<style scoped>
.slide-enter-active {
  transition-duration: 1s;
}

.slide-enter-to,
.slide-leave {
  max-height: 700px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbCardHeader,
  mdbCard,
  mdbCardBody,
} from "mdbvue";
import ExpansionRegion from "@/components/ExpansionRegion";
import itineraryApi from "@/api/ItineraryApi.js";
import bookingApi from "@/api/bookingApi.js";
import userApi from "@/api/userApi.js";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import { mapGetters } from "vuex";
import pdfPreview from "@/components/pdfPreview";
import tokenManager from "@/shared/securityTokenManager.js";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbContainer,
    AwgtStdButton,
    mdbIcon,
    mdbCardHeader,
    mdbCard,
    mdbCardBody,
    ExpansionRegion,
    yesOrNoPrompt,
    pdfPreview,
    tdoxTooltip,
  },
  data() {
    return {
      itineraryExpansion: [],
      bookingReference: "",
      includeTemplates: false,
      booking: null,
      itineraries: null,
      formSubmissionErrors: [],
      duplicateItienraryError: [],
      ownerUserOfCurrentBooking: null,
      currentUserLevel: 0,
      workgroupsOfCurrentUser: null,
      currentUserReference: null,
      currentUserPermissionScopeForItineraryCreate: null,
      currentUserPermissionScopeForItineraryUpdate: null,
      currentUserPermissionScopeForItineraryDelete: null,
    };
  },
  computed: {
    ...mapGetters(["getEmptyBooking", "checkPermission"]),

    checkPermissionScopeForItineraryCreate() {
      if (this.ownerUserOfCurrentBooking) {
        if (!this.currentUserPermissionScopeForItineraryCreate) return false;

        if (this.currentUserPermissionScopeForItineraryCreate == "CPS_NA") {
          if (this.ownerUserOfCurrentBooking.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryCreate == "CPS_BW"
        ) {
          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >
              this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryCreate == "CPS_NW"
        ) {
          if (
            this.currentUserReference ==
            this.ownerUserOfCurrentBooking.Reference
          )
            return true; //cuurrent user himself

          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >=
              this.currentUserLevel
          )
            return false;
        }

        return true;
      } else return false;
    },

    checkPermissionScopeForItineraryUpdate() {
      if (this.ownerUserOfCurrentBooking) {
        if (!this.currentUserPermissionScopeForItineraryUpdate) return false;

        if (this.currentUserPermissionScopeForItineraryUpdate == "CPS_NA") {
          if (this.ownerUserOfCurrentBooking.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryUpdate == "CPS_BW"
        ) {
          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >
              this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryUpdate == "CPS_NW"
        ) {
          if (
            this.currentUserReference ==
            this.ownerUserOfCurrentBooking.Reference
          )
            return true; //cuurrent user himself

          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >=
              this.currentUserLevel
          )
            return false;
        }

        return true;
      } else return false;
    },

    checkPermissionScopeForItineraryDelete() {
      if (this.ownerUserOfCurrentBooking) {
        if (!this.currentUserPermissionScopeForItineraryDelete) return false;

        if (this.currentUserPermissionScopeForItineraryDelete == "CPS_NA") {
          if (this.ownerUserOfCurrentBooking.ConsultancyRole.Level > 400) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryDelete == "CPS_BW"
        ) {
          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >
              this.currentUserLevel
          ) {
            return false;
          }
        } else if (
          this.currentUserPermissionScopeForItineraryDelete == "CPS_NW"
        ) {
          if (
            this.currentUserReference ==
            this.ownerUserOfCurrentBooking.Reference
          )
            return true; //cuurrent user himself

          if (
            this.ownerUserOfCurrentBooking.Workgroups.every(
              (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
            ) ||
            this.ownerUserOfCurrentBooking.ConsultancyRole.Level >=
              this.currentUserLevel
          )
            return false;
        }

        return true;
      } else return false;
    },
  },
  methods: {
    onAdd() {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/add`,
      });
    },

    onEdit(itinerary) {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/${itinerary.Reference}/edit`,
      });
    },

    onEditEntries(itinerary) {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/${itinerary.Reference}/itineraryentries`,
      });
    },

    onDelete(itinerary) {
      //TODO: Implement me!
      // this.$log
      //   .get(this.$loggingSource.UIItinerary)
      //   .info("DELETE IS NOT YET IMPLEMENTED: %s", itinerary)
      this.$refs.handleItineraryPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure you wish to delete this itinerary?"
        )
        .then((option) => {
          if (option == true) {
            this.deleteItineraryAsync(itinerary);
          }
        });
    },

    onDuplicate(itinerary) {
      var indexOfItinerary = this.itineraries
        .map(function (i) {
          return i.Reference;
        })
        .indexOf(itinerary.Reference);

      this.onSaveDuplicateItinerary(itinerary).then((result) => {
        this.itineraries.splice(indexOfItinerary + 1, 0, result);
        this.$emit("header-message", {
          isSuccessInd: true,
          Message: [`The product ${result.Name} has been duplicated.`],
        }).catch(() => {
          // Erros have been logged
        });
      });
    },

    async onSaveDuplicateItinerary(itinerary) {
      let duplicateItinerary = await itineraryApi.duplicateItineraryAsync(
        itinerary.Reference
      );

      return duplicateItinerary;
    },

    onGeneratePDF(itinerary, index) {
      this.formSubmissionErrors = [];
      this.GeneratePDF(itinerary)
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [`The pdf file is generated.`],
          });
          this.$set(this.itineraryExpansion, index, true);
        })
        .catch(() => {
          // Erros have been logged
        });
    },

    async GeneratePDF(itinerary) {
      let pdfBlob = await itineraryApi.generatePDFAsync(itinerary.Reference);
      //Build a URL from the file
      let newBlob = new Blob([pdfBlob], {
        type: "application/pdf",
      });
      let fileURL = window.URL.createObjectURL(newBlob);
      this.$log.info(`PDF fileURL: ${fileURL}`);
      this.$set(itinerary, "pdf", fileURL);
    },

    async deleteItineraryAsync(itinerary) {
      try {
        await itineraryApi.deleteItineraryAsync(itinerary);
        this.itineraries.splice(
          this.itineraries.findIndex((i) => i.Reference == itinerary.Reference),
          1
        );
      } catch (error) {
        // Erros have been logged in the itinerary api, so we suppress them here.
      }
    },

    onToggleItineraryDetailExpansion(index) {
      /*       if (!this.itineraries[index].pdf) {
        await this.onGeneratePDF(this.itineraries[index]);
      } */

      if (this.itineraries[index].pdf) {
        this.$set(
          this.itineraryExpansion,
          index,
          !this.itineraryExpansion[index]
        );
      }
    },

    async loadFormData() {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;
      this.currentUserReference = currentUser.Reference;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForItineraryCreate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ItC";
        })?.PermissionScope;

      this.currentUserPermissionScopeForItineraryUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ItU";
        })?.PermissionScope;

      this.currentUserPermissionScopeForItineraryDelete =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ItD";
        })?.PermissionScope;
      /*       this.possibleImpersonators = await bookingApi.getUsers();

      this.workgroups.push({ Reference: "", Name: "" });
      let workgroups = await workgroupApi.getWorkgroups();
      for (let wg of workgroups) {
        this.workgroups.push({ Reference: wg.Reference, Name: wg.Name });
      }
      */
      this.booking = await bookingApi.getBookingByReferenceAsync(
        this.bookingReference
      );

      this.ownerUserOfCurrentBooking = await userApi.getUserByReference(
        this.booking.OwnerUserReference
      );

      this.itineraries =
        await itineraryApi.listItinerariesByBookingReferenceAsync(
          this.bookingReference,
          this.includeTemplates
        );
    },
  },

  created() {
    this.booking = this.$lodash.cloneDeep(this.getEmptyBooking);

    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIItinerary)
      .info("RouteParams: %s", routeParams);
    this.bookingReference = routeParams.bookingReference;

    this.loadFormData().catch(() => {
      // Errors haven been logged, so we suppress it here
    });
  },
};
</script>
