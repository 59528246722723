<template>
  <div>
    <asoftPrompt
      v-model="showPrompt"
      ref="handlePrompt"
      headerClasses="p-3 PromptHeader"
      bodyClasses="px-3 pt-3"
      footerClasses="px-3 pb-3"
    >
      <template v-slot:header> {{ promptHeader }} </template>
      <template v-slot:body>
        {{ promptMessage }}
      </template>
      <template v-slot:footer="{ onClick }">
        <hr />
        <div class="d-flex justify-content-end align-center">
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onClick('Yes')"
          >
            <mdb-icon icon="check" class="mr-1" />Yes
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onClick('No')"
          >
            <mdb-icon icon="times" class="mr-1" />No
          </awgt-std-button>
        </div>
      </template>
    </asoftPrompt>
  </div>
</template>

<script>
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import { mdbIcon } from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return { showPrompt: false, promptHeader: "", promptMessage: "" };
  },
  components: {
    asoftPrompt,
    mdbIcon,
    AwgtStdButton,
  },
  methods: {
    onHandlePrompt(header, message) {
      this.promptHeader = header;
      this.promptMessage = message;
      var self = this;
      return new Promise((resolve) => {
        this.showPrompt = true;
        self.$refs.handlePrompt.$once("click", (e) => {
          let event = null;
          if (e == "Yes") event = true;
          else event = false;
          resolve(event);
        });
      });
    },
  },
};
</script>
