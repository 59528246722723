<template>
  <asoft-input v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import asoftInput from "@/components/AtomSoftware/asoftInput";
import variables from "@/styles/sharedVariables.scss";

export default {
  inheritAttrs: false,
  components: { asoftInput },
  props: {
    labelColor: { type: String, default: variables.awgtInputLabelColor },
    activeColor: { type: String, default: variables.awgtInputActiveColor },
  },

  mounted() {
    /*
      The .select-active css class needs to be changed on the stylesheet
      as it only exists on controls briefly when a control is selected.
    */
    if (this.activeColor) {
      var stylesheets = document.styleSheets;
      for (let i = 0; i < stylesheets.length; i++) {
        var stylesheet = stylesheets[i];
        //Only read stylesheets from this domain to avoid a cross site scripting security error.
        if (stylesheet.href == null) {
          for (let j = 0; j < stylesheet.cssRules.length; j++) {
            var cssRule = stylesheet.cssRules[j];
            //console.log(cssRule.selectorText);
            if (
              cssRule.selectorText ===
              '.md-form.md-bg input[type="text"], .md-form.md-bg input[type="password"], .md-form.md-bg input[type="email"], .md-form.md-bg input[type="url"], .md-form.md-bg input[type="time"], .md-form.md-bg input[type="date"], .md-form.md-bg input[type="datetime-local"], .md-form.md-bg input[type="tel"], .md-form.md-bg input[type="number"], .md-form.md-bg input[type="search-md"], .md-form.md-bg input[type="search"], .md-form.md-bg textarea.md-textarea'
            ) {
              //console.log(cssRule);

              cssRule.style.setProperty(
                "background",
                `linear-gradient(${this.activeColor},${this.activeColor}) 50% 100% / 0px 2px no-repeat, linear-gradient(rgb(245, 245, 245),rgb(245, 245, 245)) 50% 100% / 100% 1px rgb(245, 245, 245)`
              );

              cssRule.style.setProperty(
                "backgroundImage",
                `linear-gradient(${this.activeColor},${this.activeColor}), linear-gradient(rgb(206, 212, 218), rgb(206, 212, 218))`
              );
            }
          }
        }
      }
    }
  },
};
</script>
