<template>
  <div class="form">
    <awgt-input
      id="flight-number"
      v-model="additionalDetails.FlightNumber"
      counter
      :counterMaxValue="10"
      maxlength="10"
      style="min-width: 300px"
      label="Flight Number"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="carrier"
      v-model="additionalDetails.Carrier"
      counter
      :counterMaxValue="20"
      maxlength="20"
      style="min-width: 300px"
      label="Carrier"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="PNR"
      v-model="additionalDetails.PNR"
      counter
      :counterMaxValue="80"
      maxlength="80"
      style="min-width: 300px"
      label="PNR"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="departure-airport-code"
      v-model="additionalDetails.DepartureAirportCode"
      counter
      :counterMaxValue="3"
      maxlength="3"
      style="min-width: 300px"
      label="Departure Airport Code"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="departure-airport-name"
      v-model="additionalDetails.DepartureAirportName"
      counter
      :counterMaxValue="50"
      maxlength="50"
      style="min-width: 300px"
      label="Departure Airport Name"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="arrival-airport-code"
      v-model="additionalDetails.ArrivalAirportCode"
      counter
      :counterMaxValue="3"
      maxlength="3"
      style="min-width: 300px"
      label="Arrival Airport Code"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="arrival-airport-name"
      v-model="additionalDetails.ArrivalAirportName"
      counter
      :counterMaxValue="50"
      maxlength="50"
      style="min-width: 300px"
      label="Arrival Airport Name"
      bg
      class="flex-grow-1 mr-2s"
    ></awgt-input>
  </div>
</template>
<style>
.form .md-form {
  margin-bottom: 2rem !important;
}
</style>
}
<script>
import awgtInput from "@/components/AWGT/AwgtInput";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additionalDetails: {
        FlightNumber: "",
        Carrier: "",
        PNR: "",
        DepartureAirportCode: "",
        DepartureAirportName: "",
        ArrivalAirportCode: "",
        ArrivalAirportName: "",
      },
    };
  },
  components: {
    awgtInput,
  },
  watch: {
    additionalDetails: {
      deep: true,
      handler() {
        this.$emit("input", this.additionalDetails);
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(to, from) {
        if (to != from) {
          if (to && from)
            if (
              to.FlightNumber == from.FlightNumber &&
              to.Carrier == from.Carrier &&
              to.PNR == from.PNR &&
              to.DepartureAirportCode == from.DepartureAirportCode &&
              to.DepartureAirportName == from.DepartureAirportName &&
              to.ArrivalAirportCode == from.ArrivalAirportCode &&
              to.ArrivalAirportName == from.ArrivalAirportName
            )
              return;
          //Determine the keys of incoming object differs from this.additionalDetails
          this.additionalDetails = to;
        }
      },
    },
  },
};
</script>
