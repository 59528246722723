<template>
  <div>
    <div
      @click="onClick(treeviewData)"
      @dblclick="onDblClick(treeviewData)"
      :class="[
        {
          treenode:
            (treeviewData[childrenPropertyName].length > 0 &&
              currentDepth < maxDepth) ||
            selectableLeafNodes == true,
        },
      ]"
      ref="tvitem"
    >
      <div
        class="rotatingicon"
        :class="[{ rotate: treeviewData.state == 'expanded' }]"
      >
        <slot
          v-if="
            treeviewData[childrenPropertyName].length > 0 &&
            currentDepth < maxDepth
          "
          name="rotating_icon"
        />
      </div>
      <slot
        v-if="
          treeviewData[childrenPropertyName].length > 0 &&
          currentDepth < maxDepth &&
          treeviewData.state == 'collapsed'
        "
        name="collapsed_icon"
      />
      <slot
        v-if="
          treeviewData[childrenPropertyName].length > 0 &&
          currentDepth < maxDepth &&
          treeviewData.state == 'expanded'
        "
        name="expanded_icon"
      />
      <slot
        :name="
          treeviewData[slotNamePropertyName]
            ? `${treeviewData[slotNamePropertyName]}`
            : 'default'
        "
        v-bind="treeviewData"
      />
    </div>
    <template
      v-if="
        currentDepth <= maxDepth &&
        (isCollapsible == false ||
          treeviewData.state == 'expanded' ||
          (treeviewData.isCollapsible != undefined &&
            treeviewData.isCollapsible == false))
      "
    >
      <div
        v-for="(ti, index) in treeviewData[childrenPropertyName]"
        :key="index"
        :style="`margin-left:${childIndent};`"
      >
        <asoftTreeviewItem
          :value="ti"
          :childIndent="childIndent"
          :selectableLeafNodes="selectableLeafNodes"
          :singleClickExpand="singleClickExpand"
          :childrenPropertyName="childrenPropertyName"
          :slotNamePropertyName="slotNamePropertyName"
          @tree-item-click="onTreeItemClick"
          @tree-item-dbl-click="onTreeItemDblClick"
          :currentDepth="currentDepth + 1"
          :maxDepth="maxDepth"
          :isCollapsible="isCollapsible"
        >
          <template
            v-for="slot in Object.keys($scopedSlots)"
            v-slot:[slot]="scope"
          >
            <slot :name="slot" v-bind="scope" />
          </template>
        </asoftTreeviewItem>
      </div>
    </template>
  </div>
</template>

<style scoped>
.treenode {
  cursor: pointer;
}

.rotatingicon {
  display: inline-block;
  transition: all 0.15s;
}

.rotate {
  transform: rotate(90deg);
}
</style>

<script>
import asoftTreeviewItem from "./asoftTreeviewItem";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  name: "asoftTreeviewItem",
  components: {
    asoftTreeviewItem,
  },
  data() {
    return {
      treeviewData: null,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    childIndent: {
      type: String,
      required: true,
    },
    selectableLeafNodes: {
      type: Boolean,
      required: true,
    },
    singleClickExpand: {
      type: Boolean,
      required: true,
    },
    childrenPropertyName: {
      type: String,
      required: true,
    },
    slotNamePropertyName: {
      type: String,
      required: true,
    },
    currentDepth: {
      type: Number,
      required: true,
    },
    maxDepth: {
      type: Number,
      required: true,
    },
    isCollapsible: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        if (to != from) {
          this.treeviewData = to;
          logger
            .get(loggingSource.VCASoftTreeView)
            .info("asoftTreeviewItem value watch: %s", to);
        }
      },
    },
  },

  methods: {
    onClick(currentNode) {
      if (this.singleClickExpand == true) this.expandOrCollapseNode();

      if (
        (this.treeviewData[this.childrenPropertyName].length > 0 &&
          this.currentDepth < this.maxDepth) ||
        this.selectableLeafNodes == true
      )
        this.$emit("tree-item-click", currentNode);
    },
    onDblClick(currentNode) {
      if (this.singleClickExpand == false) this.expandOrCollapseNode();
      this.$emit("tree-item-dbl-click", currentNode);
    },
    onTreeItemClick(e) {
      this.$emit("tree-item-click", e);
    },
    onTreeItemDblClick(e) {
      this.$emit("tree-item-dbl-click", e);
    },
    expandOrCollapseNode() {
      if (
        this.treeviewData[this.childrenPropertyName].length == 0 ||
        this.currentDepth == this.maxDepth
      ) {
        this.treeviewData.state = "collapsed";
      } else {
        switch (this.treeviewData.state) {
          case "expanded":
            if (
              this.isCollapsible == true &&
              (this.treeviewData.isCollapsible == undefined ||
                this.treeviewData.isCollapsible == true)
            )
              this.treeviewData.state = "collapsed";
            break;
          case "collapsed":
            this.treeviewData.state = "expanded";
            break;
          default:
            //TODO: need to return an error.
            break;
        }
      }
    },
  },
  mounted() {
    if (
      this.isCollapsible == true &&
      (this.treeviewData.isCollapsible == undefined ||
        this.treeviewData.isCollapsible == true)
    )
      this.$set(this.treeviewData, "state", "collapsed");
    else this.$set(this.treeviewData, "state", "expanded");
  },
};
</script>
