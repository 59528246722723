var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-navbar",
    _vm._g(
      _vm._b({ attrs: { color: _vm.color } }, "mdb-navbar", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _vm.$slots.default !== undefined
        ? [_vm._t("default")]
        : [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }