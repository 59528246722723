var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3",
            },
            [
              _c("div", { staticStyle: { width: "100px" } }, [
                _vm._v("Pricing:"),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-grow-1 justify-content-end pr-1",
                },
                [
                  _c("awgt-input", {
                    staticClass: "pl0-important",
                    staticStyle: { "margin-left": "18px" },
                    attrs: {
                      type: "checkbox",
                      id: "IndicativePricing",
                      name: "IndicativePricing",
                      label: "Indicative Pricing",
                    },
                    model: {
                      value: _vm.IsIndicativePricingInd,
                      callback: function ($$v) {
                        _vm.IsIndicativePricingInd = $$v
                      },
                      expression: "IsIndicativePricingInd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: {
                    type: "button",
                    disabled: _vm.enableAddButtonInd == false,
                  },
                  on: { click: _vm.AddPrice },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._l(_vm.pricesArray, function (price, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
              staticStyle: { "margin-bottom": "-10px" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _vm._l(
                    price.PricingScales.filter(
                      (i) => i.PricingScaleCd != "PS_U"
                    ),
                    function (pricingScale, psindex) {
                      return _c("div", { key: psindex }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-nowrap mx-2 align-items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getScaleHint(
                                    pricingScale.QuantityUnitCd,
                                    pricingScale.PricingScaleCd,
                                    psindex == 0
                                  )
                                ) +
                                " "
                            ),
                            _c("mdb-icon", {
                              staticClass: "mx-2",
                              staticStyle: { width: "28px" },
                              attrs: {
                                icon: "calendar-alt",
                                color: "primary",
                                size: "2x",
                              },
                            }),
                            pricingScale.PricingScaleCd == "PS_M" ||
                            pricingScale.PricingScaleCd == "PS_R"
                              ? _c("awgt-input", {
                                  directives: [
                                    {
                                      name: "numeric",
                                      rawName: "v-numeric",
                                      value: {
                                        precision: 4,
                                        scale: 0,
                                        posOnly: true,
                                        trailingZeroScale: 0,
                                        allowEmptyString: true,
                                      },
                                      expression:
                                        "{\n                precision: 4,\n                scale: 0,\n                posOnly: true,\n                trailingZeroScale: 0,\n                allowEmptyString: true,\n              }",
                                    },
                                  ],
                                  staticStyle: { width: "110px" },
                                  attrs: {
                                    maxlength: "4",
                                    label: _vm.getRangeLimitLabel(
                                      "min",
                                      pricingScale.QuantityUnitCd
                                    ),
                                    bg: "",
                                    type: "text",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onGSTPriceInput(price)
                                    },
                                    focus: function ($event) {
                                      return _vm.onFocus("GSTPrice", price)
                                    },
                                  },
                                  model: {
                                    value: pricingScale.MinQuantity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        pricingScale,
                                        "MinQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "pricingScale.MinQuantity",
                                  },
                                })
                              : _vm._e(),
                            pricingScale.PricingScaleCd == "PS_R"
                              ? _c("span", { staticClass: "mx-2" }, [
                                  _vm._v(" and "),
                                ])
                              : _vm._e(),
                            pricingScale.PricingScaleCd == "PS_X" ||
                            pricingScale.PricingScaleCd == "PS_R"
                              ? _c("awgt-input", {
                                  directives: [
                                    {
                                      name: "numeric",
                                      rawName: "v-numeric",
                                      value: {
                                        precision: 4,
                                        scale: 0,
                                        posOnly: true,
                                        trailingZeroScale: 0,
                                        allowEmptyString: true,
                                      },
                                      expression:
                                        "{\n                precision: 4,\n                scale: 0,\n                posOnly: true,\n                trailingZeroScale: 0,\n                allowEmptyString: true,\n              }",
                                    },
                                  ],
                                  staticStyle: { width: "110px" },
                                  attrs: {
                                    maxlength: "4",
                                    label: _vm.getRangeLimitLabel(
                                      "max",
                                      pricingScale.QuantityUnitCd
                                    ),
                                    bg: "",
                                    type: "text",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onGSTPriceInput(price)
                                    },
                                    focus: function ($event) {
                                      return _vm.onFocus("GSTPrice", price)
                                    },
                                  },
                                  model: {
                                    value: pricingScale.MaxQuantity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        pricingScale,
                                        "MaxQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "pricingScale.MaxQuantity",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    }
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row flex-nowrap flex-grow-1 ml-2 align-items-center",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.hasScaledPricingInd
                              ? "then the price "
                              : "The price "
                          ) +
                            " " +
                            _vm._s(_vm.getPerQuantityUnitQualifier(_vm.value)) +
                            " is"
                        ),
                      ]),
                      _c("mdb-icon", {
                        staticClass: "grey-text ml-2 mr-1",
                        attrs: {
                          icon: "dollar-sign",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _vm.value.PriceTypeCd != "PrT_E" &&
                      _vm.value.PriceTypeCd != "PrT_Z"
                        ? _c("awgt-input", {
                            directives: [
                              {
                                name: "numeric",
                                rawName: "v-numeric",
                                value: {
                                  precision: 6,
                                  scale: 2,
                                  posOnly: false,
                                  trailingZeroScale: 2,
                                },
                                expression:
                                  "{\n              precision: 6,\n              scale: 2,\n              posOnly: false,\n              trailingZeroScale: 2,\n            }",
                              },
                            ],
                            staticClass: "ml-2",
                            staticStyle: { width: "140px" },
                            attrs: {
                              maxlength: "7",
                              label:
                                _vm.value.PriceTypeCd == "PrT_F"
                                  ? "Price"
                                  : "Incl-GST Price",
                              bg: "",
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onTotalPriceInput(price)
                              },
                              focus: function ($event) {
                                return _vm.onFocus("TotalPrice", price)
                              },
                            },
                            model: {
                              value: price.TotalPrice,
                              callback: function ($$v) {
                                _vm.$set(price, "TotalPrice", _vm._n($$v))
                              },
                              expression: "price.TotalPrice",
                            },
                          })
                        : _vm._e(),
                      _vm.value.PriceTypeCd != "PrT_F" &&
                      _vm.value.PriceTypeCd != "PrT_Z"
                        ? _c("awgt-input", {
                            directives: [
                              {
                                name: "numeric",
                                rawName: "v-numeric",
                                value: {
                                  precision: 6,
                                  scale: 2,
                                  posOnly: false,
                                  trailingZeroScale: 2,
                                },
                                expression:
                                  "{\n              precision: 6,\n              scale: 2,\n              posOnly: false,\n              trailingZeroScale: 2,\n            }",
                              },
                            ],
                            staticClass: "ml-2",
                            staticStyle: { width: "140px" },
                            attrs: {
                              maxlength: "7",
                              label: "Ex-GST Price",
                              bg: "",
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onExGSTPriceInput(price)
                              },
                              focus: function ($event) {
                                return _vm.onFocus("ExGSTPrice", price)
                              },
                            },
                            model: {
                              value: price.ExGSTUnitAmt,
                              callback: function ($$v) {
                                _vm.$set(price, "ExGSTUnitAmt", _vm._n($$v))
                              },
                              expression: "price.ExGSTUnitAmt",
                            },
                          })
                        : _vm._e(),
                      _vm.value.PriceTypeCd != "PrT_F" &&
                      _vm.value.PriceTypeCd != "PrT_Z"
                        ? _c("awgt-input", {
                            directives: [
                              {
                                name: "numeric",
                                rawName: "v-numeric",
                                value: {
                                  precision: 6,
                                  scale: 2,
                                  posOnly: false,
                                  trailingZeroScale: 2,
                                },
                                expression:
                                  "{\n              precision: 6,\n              scale: 2,\n              posOnly: false,\n              trailingZeroScale: 2,\n            }",
                              },
                            ],
                            staticClass: "ml-2",
                            staticStyle: { width: "140px" },
                            attrs: {
                              maxlength: "7",
                              label: "GST Price",
                              bg: "",
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onGSTPriceInput(price)
                              },
                              focus: function ($event) {
                                return _vm.onFocus("GSTPrice", price)
                              },
                            },
                            model: {
                              value: price.GSTUnitAmt,
                              callback: function ($$v) {
                                _vm.$set(price, "GSTUnitAmt", _vm._n($$v))
                              },
                              expression: "price.GSTUnitAmt",
                            },
                          })
                        : _vm._e(),
                      _vm.value.PriceTypeCd == "PrT_E"
                        ? [
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v("$" + _vm._s(price.TotalPrice)),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.value.PriceTypeCd == "PrT_Z"
                        ? [
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v("Price: $0"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.value.PriceTypeCd == "PrT_F" ||
                      _vm.value.PriceTypeCd == "PrT_Z"
                        ? [
                            _c("span", { staticClass: "mx-3" }, [
                              _vm._v("ex-GST: $" + _vm._s(price.ExGSTUnitAmt)),
                            ]),
                            _c("span", [
                              _vm._v("GST: $" + _vm._s(price.GSTUnitAmt)),
                            ]),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end mr-2",
                        },
                        [
                          _c(
                            "awgt-std-button",
                            {
                              staticClass: "command-button ml-3",
                              staticStyle: { "min-width": "63px" },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDeletePrice(index)
                                },
                              },
                            },
                            [
                              _c("mdb-icon", {
                                staticClass: "mr-1",
                                attrs: { icon: "trash-alt" },
                              }),
                              _vm._v("Delete"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                2
              ),
            ]
          ),
          _c("hr"),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }