<template>
  <div style="height: 100%">
    <div class="logon-form">
      <div class="diolog_logo_container text-center">
        <h2 class="tourdox-font-dark">tourdox</h2>
      </div>
      <div
        class="diolog_content"
        v-if="forgetPassword == false && forgetUsername == false"
      >
        <div class="logon-form-header">Log in to your account</div>
        <transition name="fade">
          <div
            v-if="doesReceiveUsername == true"
            style="
              top: 0px;
              margin: 10px 0px;
              width: 100%;
              z-index: 2000;
              position: -webkit-sticky;
              position: sticky;
            "
            @click="onPaneClose()"
          >
            <div class="savemessage-success">
              <span class="float-right color-success">
                <mdb-icon
                  @click.native="onPaneClose()"
                  far
                  size="1x"
                  class="p-2 mr-0"
                  icon="times-circle"
                  style="cursor: pointer"
                />
              </span>
              <template>
                <div style="margin-top: 15px; font-size: 15px">
                  <mdb-icon icon="check-circle" class="color-success" />
                  {{ `Your username is "${username}".` }}
                </div>
              </template>
            </div>
          </div>
        </transition>
        <span
          v-if="error.length > 0"
          style="color: red; font-size: 13px"
          id="logon-error-msg"
          >{{ error }}</span
        >
        <input
          autocomplete="off"
          id="Username"
          class="logon-form-header-input"
          type="text"
          name="username"
          v-model="username"
          placeholder="Username"
          @keyup.enter="(e) => OnSubmit(e, 'log-on')"
        />
        <input
          autocomplete="off"
          id="Password"
          class="logon-form-header-input"
          type="password"
          name="password"
          v-model="password"
          placeholder="Password"
          @keyup.enter="(e) => OnSubmit(e, 'log-on')"
        />
        <awgt-std-button
          id="login-submit-btn"
          class="logon-btn m-0"
          type="submit"
          @click="(e) => OnSubmit(e, 'log-on')"
        >
          Log In
        </awgt-std-button>
        <mdb-row class="mt-4 mx-auto">
          <mdb-col col="6" class="text-center" href="./">
            <a
              style="color: #425664; text-decoration: none"
              v-on:click="onClickForgetPassword()"
              >Forgot password?</a
            >
          </mdb-col>
          <mdb-col col="6" class="text-center" href="./">
            <a
              style="color: #425664; text-decoration: none"
              v-on:click="onClickForgetUsername()"
              >Forgot username?</a
            >
          </mdb-col>
        </mdb-row>
      </div>
      <div class="diolog_content" v-else>
        <div v-if="forgetPassword == true" class="logon-form-header">
          Reset your password
        </div>
        <div v-if="forgetUsername == true" class="logon-form-header">
          Retrieve your username
        </div>
        <span v-if="sent == true" style="color: green"
          >&#10003;&nbsp;The email has been sent.</span
        ><br /><span v-if="sent == true" style="color: green">
          &#10003;&nbsp;Do it again if you don't receive it in 5 minutes</span
        >
        <span v-else style="color: red; font-size: 13px" id="logon-error-msg">{{
          error
        }}</span>
        <input
          autocomplete="off"
          v-if="forgetPassword == true"
          class="logon-form-header-input"
          type="text"
          name="Username"
          placeholder="Username"
          v-model="requestUsername"
        />
        <input
          autocomplete="off"
          v-if="forgetPassword == true || forgetUsername == true"
          class="logon-form-header-input"
          type="text"
          name="Email Address"
          placeholder="Email Address"
          v-model="requestEmail"
        />
        <awgt-std-button
          class="send-btn m-0"
          type="submit"
          :disabled="second > 0"
          v-on:click="(e) => OnSubmit(e, 'send-link')"
        >
          Continue
        </awgt-std-button>
        <div class="logon-link" href="./">
          <a style="color: #668318; text-decoration: none" v-on:click="onCancel"
            >Cancel</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import securityApi from "@/api/securityApi.js";
import { mdbIcon, mdbCol, mdbRow } from "mdbvue";

import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return {
      forgetPassword: false,
      forgetUsername: false,
      requestUsername: "",
      requestEmail: "",
      sent: false,
      second: 0,
      username: "",
      password: "",
      error: "",
      doesReceiveUsername: false,
    };
  },
  props: {
    message: {
      type: String,
    },
    retrievedUsername: {
      type: String,
    },
  },
  components: {
    mdbIcon,
    mdbCol,
    mdbRow,
    AwgtStdButton,
  },
  methods: {
    async OnSubmit(e, val) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      this.error = "";
      if (val == "log-on") {
        if (this.username.length == 0 || this.password.length == 0) {
          this.error = "Please enter both username and password";
          return;
        }
        try {
          let result = await securityApi.Login({
            Username: this.username,
            Password: this.password,
            Device: this.getDevice(),
          });
          this.$emit("input", result.User);
          this.$store.commit("mutStoreCurrentUser", result.User, {
            root: true,
          });
        } catch (error) {
          this.$log.error(error);
        }
      } else {
        if (this.forgetPassword == true) {
          if (this.requestUsername.length == 0) {
            this.error = "Username must be not empty";
            return;
          }
          if (this.requestEmail.length == 0) {
            this.error = "Email address must be not empty";
            return;
          }
          securityApi
            .requestPasswordReset(this.requestUsername, this.requestEmail)
            .then(() => {
              if (this.error.length == 0) {
                this.CountDown();
                this.sent = true;
              }
            })
            .catch((error) => {
              this.error = error.message;
            });
        } else if (this.forgetUsername == true) {
          if (this.requestEmail.length == 0) {
            this.error = "Email address must be not empty";
            return;
          }
          securityApi
            .requestUsernameRetrieval(this.requestEmail)
            .then(() => {
              if (this.error.length == 0) {
                this.CountDown();
                this.sent = true;
              }
            })
            .catch((error) => {
              this.error = error.message;
            });
        }
      }
    },
    onClickForgetPassword() {
      this.error = "";
      this.forgetPassword = true;
      this.sent = false;
      this.requestUsername = "";
      this.second = 0;
    },
    onClickForgetUsername() {
      this.error = "";
      this.forgetUsername = true;
      this.sent = false;
      this.requestEmail = "";
      this.second = 0;
    },
    CountDown() {
      this.second = 10;
      var timeinterval = setInterval(() => {
        this.second = this.second - 1;
        if (this.second == 0) {
          clearInterval(timeinterval);
        }
      }, 1000);
    },
    onCancel() {
      this.error = "";
      this.forgetPassword = false;
      this.forgetUsername = false;
      this.requestUsername = "";
      this.requestEmail = "";
    },
    onPaneClose() {
      this.doesReceiveUsername = false;
    },
    getDevice() {
      let userAgentInfo = navigator.userAgent;
      if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgentInfo)
      ) {
        return "Tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgentInfo
        )
      ) {
        return "Mobile";
      }
      return "Desktop Web";
    },
  },
  watch: {
    message: {
      deep: false,
      immediate: true,
      handler(to, from) {
        if (to != from && to.length != 0) {
          this.error = to;
        }
      },
    },
    retrievedUsername(to, from) {
      if (to != from && to.length != 0) {
        this.username = to;
        this.doesReceiveUsername = true;
      }
    },
  },
};
</script>
<style scoped>
.logon-form {
  width: 500px;
  /*border: 1px solid black;*/
  margin: 30px 30px;
  /*border-radius: 5px;*/
  overflow: hidden;
  background: white;
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);*/
}

.logon-form *:not(i) {
  box-sizing: border-box;
  font-family: sans-serif;
}

.diolog_logo_container {
  padding: 10px 10px;
  /*background: #9dbe44;*/
}

.logo {
  display: block;
  margin: 0 auto;
}

.diolog_content {
  padding: 20px 20px;
  /*background: #eeeeee;*/
}

.logon-form-header {
  margin-bottom: 10px;
  text-align: center;
}

.logon-form-header-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
}

.logon-form-header-input:focus {
  border-color: #f9ae35;
}

.logon-btn {
  width: 100%;

  color: white;
  font-weight: bold;
  background: #9dbe44;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-btn {
  width: 100%;
  padding: 10px;
  color: white;
  font-weight: bold;
  background: #9dbe44;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-btn:disabled {
  opacity: 0.5;
}

.logon-btn:active {
  background: #8eac3e;
}

.logon-link {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
