/* eslint-disable vue/no-unused-components */
<template>
  <div id="App">
    <div>
      <div v-if="logonDialog == true" class="LogOnForm">
        <asoft-logOn
          @input="onLogonComplete"
          class="d-flex justify-content-center align-items-center"
          :message="logonDialogMessage"
          :retrievedUsername="retrievedUsername"
        ></asoft-logOn>
      </div>
      <!-- header -->
      <header>
        <!--Navbar-->
        <awgt-nav-bar
          dark
          position="top"
          :toggler="false"
          style="height: 48px; padding-top: 3px; padding-bottom: 3px"
        >
          <div v-if="logonDialog == false" class="d-flex w-100">
            <div class="d-flex flex-row flex-nowrap align-items-center">
              <!-- Top Nav-->
              <div
                class="d-flex flex-row flex-nowrap align-items-center display-when-wide"
              >
                <mdbNavbarNav>
                  <template v-for="menuItem in menuItems">
                    <awgt-menu-item
                      :menuItem="menuItem"
                      :key="menuItem.title"
                    ></awgt-menu-item>
                  </template>
                </mdbNavbarNav>
              </div>
              <!-- Side Nav -->
              <div
                class="d-flex flex-row flex-nowrap align-items-center display-when-narrow"
              >
                <div class="float-left">
                  <a role="button" id="nav-toggle">
                    <mdb-icon
                      @click.native="toggleLeftSideNav"
                      fas
                      icon="bars"
                      size="lg"
                      color="white"
                    />
                  </a>
                  <span class="ml-4 navbar-text">Travel Manager</span>
                </div>
                <mdb-side-nav
                  :OpenedFromOutside.sync="toggleMenu"
                  hidden
                  waves
                  sideNavStyle="top: 48px;"
                >
                  <mdbSideNavNav>
                    <template v-for="menuItem in menuItems">
                      <mdb-side-nav-item
                        :id="'nav-' + menuItem.title"
                        v-if="menuItem.menuItems.length == 0"
                        :key="menuItem.title"
                        :to="{ path: menuItem.routePath }"
                        :icon="menuItem.icon"
                        >{{ menuItem.title }}</mdb-side-nav-item
                      >
                      <mdb-side-nav-cat
                        :id="'nav-' + menuItem.title"
                        v-else
                        :key="menuItem.title"
                        :icon="menuItem.icon"
                        :name="menuItem.title"
                      >
                        <mdb-side-nav-item
                          :id="
                            'nav-' + menuItem.title + '-' + subMenuItem.title
                          "
                          v-for="subMenuItem in menuItem.menuItems"
                          :key="subMenuItem.title"
                          :to="{ path: subMenuItem.routePath }"
                          header
                          >{{ subMenuItem.title }}</mdb-side-nav-item
                        >
                      </mdb-side-nav-cat>
                    </template>
                  </mdbSideNavNav>
                </mdb-side-nav>
              </div>
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center ml-auto">
              <div class="mx-2 tip">
                <input
                  ref="referenceSearch"
                  class="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  @keydown.enter.stop="onKeyDownBarSearch"
                />
                <transition name="fade">
                  <span v-if="isSearchBarError" class="tiptext">{{
                    searchBarErrorMessage
                  }}</span>
                </transition>
              </div>

              <mdb-dropdown tag="li" class="nav-item avatar" anchorClass="p-0">
                <mdb-dropdown-toggle
                  tag="a"
                  navLink
                  color="stylish"
                  slot="toggle"
                  waves-fixed
                >
                  <div
                    @click="onToggleUserProfileDropDown"
                    v-if="currentUser"
                    class="userCircle"
                  >
                    {{ `${currentUser.FirstName[0]}${currentUser.Surname[0]}` }}
                  </div>
                </mdb-dropdown-toggle>
                <mdb-dropdown-menu class="dropdown-menu-right">
                  <!--                 <mdb-dropdown-item @click="onEditProfile">
                  Edit Profile
                </mdb-dropdown-item> -->
                  <mdb-dropdown-item @click="onLogout"
                    ><span> Logout</span>
                  </mdb-dropdown-item>
                </mdb-dropdown-menu>
              </mdb-dropdown>
            </div>
          </div>
        </awgt-nav-bar>
        <mdb-breadcrumb
          style="
            height: 30px;
            top: 48px;
            width: 100%;
            position: fixed;
            z-index: 1000;
            padding-bottom: 0;
            margin-bottom: 0;
            border-radius: 0;
          "
        >
          <template v-for="(breadcrumb, index) in breadcrumbs">
            <mdb-breadcrumb-item
              style="margin-top: -5px"
              :key="index"
              :active="index + 1 == breadcrumbs.length ? true : false"
            >
              <span v-if="index + 1 == breadcrumbs.length">{{
                breadcrumb.text
              }}</span>
              <router-link v-else :to="breadcrumb.href">{{
                breadcrumb.text
              }}</router-link>
            </mdb-breadcrumb-item>
            <i
              :key="'i' + index"
              v-if="index + 1 != breadcrumbs.length"
              class="fas fa-caret-right mx-2"
              style="margin-top: -2px"
              aria-hidden="true"
            ></i>
          </template>
        </mdb-breadcrumb>
      </header>

      <!-- main -->
      <main style="margin-top: 80px">
        <transition name="fade">
          <div
            class="top-message"
            style="z-index: 1000"
            v-if="
              (remainMinutes > 0 && remainMinutes <= 5) ||
              (remainMinutes == 0 && remainSeconds != 0)
            "
          >
            <div class="savemessage-success">
              <template>
                <div style="margin-top: 15px">
                  <mdb-icon icon="far fa-stopwatch" class="color-success" />
                  {{
                    `Your system will close automatically in ${$moment({
                      minutes: remainMinutes,
                      seconds: remainSeconds,
                    }).format("mm:ss")}`
                  }}
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="headerMessage.Message.length > 0"
            class="top-message"
            @click="onSuccessErrorPaneClose()"
          >
            <div
              :class="
                headerMessage.isSuccessInd == true
                  ? 'savemessage-success'
                  : 'savemessage-error'
              "
            >
              <span
                class="float-right"
                :class="
                  headerMessage.isSuccessInd == true
                    ? 'color-success'
                    : 'color-error'
                "
              >
                {{ headerMessage.isSuccessInd == true ? `${timer}` : null }}
                <mdb-icon
                  @click.native="onSuccessErrorPaneClose()"
                  far
                  size="1x"
                  class="p-2 mr-0"
                  icon="times-circle"
                  style="cursor: pointer"
                />
              </span>
              <template v-if="headerMessage.isSuccessInd == true">
                <div style="margin-top: 15px">
                  <mdb-icon icon="check-circle" class="color-success" />
                  <span v-html="this.headerMessage.Message[0]"></span>
                </div>
              </template>
              <template v-else>
                <div style="margin-top: 15px">
                  <mdb-icon icon="exclamation-triangle" class="color-error" />
                  Please correct the following errors:
                  <ul style="margin: 0px">
                    <li v-for="(e, idx) in headerMessage.Message" :key="idx">
                      {{ e }}
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </transition>
        <router-view
          style="margin-top: 100px"
          @header-message="displayHeaderMessage"
          :onSavePromptExit="onSavePromptHandler"
        >
        </router-view>
      </main>

      <!-- footer -->

      <awgt-footer> </awgt-footer>

      <!-- Exit Before Save Prompt -->
      <asoftPrompt
        v-model="showExitBeforeSavePromptInd"
        ref="exitBeforeSavePrompt"
        headerClasses="p-3 showExitBeforeSavePromptHeader"
        bodyClasses="px-3 pt-3"
        footerClasses="px-3 pb-3"
      >
        <template v-slot:header> Exit without saving? </template>
        <template v-slot:body>
          You have unsaved changes.
          <br /><br />
          Exit?
        </template>
        <template v-slot:footer="{ onClick }">
          <hr />
          <div class="d-flex justify-content-end align-center">
            <awgt-std-button
              type="button"
              class="command-button mx-2"
              @click="onClick('Save')"
            >
              <mdb-icon icon="check" class="mr-1" />Save
            </awgt-std-button>
            <awgt-std-button
              type="button"
              class="command-button mx-2"
              @click="onClick('Exit')"
            >
              <mdb-icon icon="ban" class="mr-1" />Don't Save
            </awgt-std-button>
            <awgt-std-button
              type="button"
              class="command-button mx-2"
              @click="onClick('Stay')"
            >
              <mdb-icon icon="times" class="mr-1" />Cancel
            </awgt-std-button>
          </div>
        </template>
      </asoftPrompt>
    </div>
    <div v-if="getAxiosCallLoaderStatus" class="loader-screen">
      <div class="loader">
        <mdb-spinner big color="green" /><br />
        Loading...
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/sharedVariables.scss";
.PromptHeader {
  background-color: $primary-color;
  color: white;
  font-weight: bold;
}

.LogOnForm {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 3000;
}

.userCircle {
  position: relative;
  border: 2px solid white;
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #425664;
  background: #be7746;
  cursor: pointer;
}

.userCircle:hover {
  background: #c5d890;
}

.userDropDown {
  width: 100px;
  margin-right: 50px;
  position: absolute;
  top: 100%;
  padding-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);

  .user-selection-menu {
    background: white;
    padding: 0;
    list-style-type: none;
    color: black !important;
    padding: 10px;

    li:hover {
      color: lightgreen;
    }

    li {
      transition: 0.4s;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: rgba(149, 165, 166, 0.3);
}

.showExitBeforeSavePromptHeader {
  background-color: #be7746;
  color: white;
  font-weight: bold;
}

.nav-header-options {
  order: 0 !important;
}

.search-btn {
  background: rgb(182, 179, 179);
}

.search-btn:hover {
  background: grey;
}

.tip {
  position: relative;
  display: inline-block;
}

.tip .tiptext {
  visibility: visible;
  border: 2px solid red;
  width: 200px;
  background-color: #fff9f9;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -120%;
  left: 50%;
  margin-left: -100px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tip .tiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  // border-color: transparent transparent #fff9f9 transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid red;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

$image-path: "/mdbvue/lib/img"; // image path variable update
@import "mdbvue/lib/scss/mdb-pro.scss"; // main mdb scss pro file\
//@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); // font import
.tourdox-font {
  font-family: "Hachi Maru Pop", cursive !important;
  font-weight: bold;
  color: white;
}

.tourdox-font-dark {
  font-family: "Hachi Maru Pop", cursive !important;
  font-weight: bold;
}
</style>

<style scoped>
.breadcrumb-item + .breadcrumb-item::before {
  content: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
const SUCCESSDIALOG_TimeoutInSeconds = 5;

import {
  mdbSideNavNav,
  mdbSideNavCat,
  mdbSideNavItem,
  mdbSideNav,
  mdbIcon,
  waves,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  //mdbNavbarToggler,
  mdbNavbarNav,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbSpinner,
} from "mdbvue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import securityApi from "@/api/securityApi.js";
import tokenManager from "@/shared/securityTokenManager.js";
import asoftLogOn from "@/components/AtomSoftware/asoftLogOn.vue";
import permission from "@/shared/permission.js";
import AwgtNavBar from "@/components/AWGT/AwgtNavBar.vue";
import AwgtMenuItem from "@/components/AWGT/AwgtMenuItem.vue";
import AwgtFooter from "@/components/AWGT/AwgtFooter.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "App",
  components: {
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavCat,
    mdbSideNavItem,
    mdbIcon,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    //mdbNavbarToggler,
    mdbNavbarNav,

    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    asoftPrompt,

    mdbSpinner,

    asoftLogOn,
    AwgtNavBar,
    AwgtMenuItem,
    AwgtFooter,
    AwgtStdButton,
  },

  props: {
    source: String,
  },

  computed: {
    ...mapGetters([
      "getAxiosCallLoaderStatus",
      "getRefreshTokenExpTime",
      "getRefreshTokenExpiryInMinutesFromReference",
    ]),
  },

  methods: {
    ...mapActions(["actLoadReferenceData"]),
    ...mapMutations(["mutClearCurrentUser"]),

    onKeyDownBarSearch(e) {
      let searchString = e.target.value.trim();

      if (searchString.startsWith("IL"))
        this.onSearchBarInput("/admin/issue", searchString);
      else if (searchString.startsWith("AC"))
        this.onSearchBarInput("/admin/systemaction", searchString);
      else if (searchString.startsWith("U"))
        this.onSearchBarInput("/admin/user", searchString);
      else if (searchString.startsWith("W"))
        this.onSearchBarInput("/admin/workgroup", searchString);
      else if (searchString.startsWith("S"))
        this.onSearchBarInput("/supplier", searchString);
      else if (searchString.startsWith("P"))
        this.onSearchBarInput("/product", searchString);
      else if (searchString.startsWith("CL"))
        this.onSearchBarInput("/client", searchString);
      else if (searchString.startsWith("TB"))
        this.onSearchBarInput("/booking", searchString);
      else {
        this.searchBarErrorMessage = "Invalid reference number";
        this.isSearchBarError = true;
      }
    },

    async onSearchBarInput(path, searchString) {
      let name = null;
      if (path.includes("user")) name = "user";
      else if (path.includes("workgroup")) name = "workgroup";
      else name = null;
      //If it goes to User or Workgroup page, where we directly call loadFormData() in mounted, we need to check params
      //if it is from bar search, we only listen on event but not executing the loadFormData() function.
      if (!(this.$route.path === path))
        await this.$router.push({
          path: path,
          params:
            path.includes("user") || path.includes("workgroup")
              ? { isBarSearch: true }
              : {},
          name: name,
        });
      this.$bus.$emit("search-bar-search", searchString);
    },

    async onSearchBarResult(e) {
      if (e.successInd == true) this.$refs["referenceSearch"].value = "";
      else {
        this.searchBarErrorMessage = e.errorMessage;
        this.isSearchBarError = true;
        this.$message.Message = [];
      }
    },

    onToggleUserProfileDropDown() {
      this.userProfileDropDown = !this.userProfileDropDown;
    },

    toggleLeftSideNav() {
      this.toggleMenu = !this.toggleMenu;
    },

    displayHeaderMessage(e) {
      this.headerMessage = e;
    },

    onSuccessErrorPaneClose() {
      this.headerMessage.Message = [];
      this.headerMessage.isSuccessInd = false;
    },

    async onLogonComplete(user) {
      this.currentUser = user;
      this.logonDialog = false;
      let self = this;
      this.$store.dispatch("actLoadReferenceData").then(() => {
        self.$store.commit(
          "mutRefreshTokenExpTime",
          new Date().setMinutes(
            new Date().getMinutes() +
              this.getRefreshTokenExpiryInMinutesFromReference
          )
        );
        self.onRefreshTokenCountDown();
      });
    },

    onSavePromptHandler() {
      return new Promise((resolve) => {
        this.showExitBeforeSavePromptInd = true;
        this.$refs.exitBeforeSavePrompt.$once("click", (e) => {
          let event = null;
          event = e;
          resolve(event);
        });
      });
    },

    doesSessionPersistCookieExist(name) {
      /*
      Do not use document.cookie.indexOf() to check if a cookie exists. It works for most of cases. However, it is not 100% accurate.
      recommend to use regular expression.
      https://stackoverflow.com/questions/5968196/how-do-i-check-if-a-cookie-exists
      */
      var match = document.cookie.match(
        RegExp("(?:^|;\\s*)" + name + "=([^;]*)")
      );
      return match ? true : false;
    },

    onRefreshTokenCountDown() {
      let self = this;
      self.timeinterval = setInterval(function () {
        var now = new Date().getTime();
        var timeleft;
        var currentTokenExpTime = new Date(
          Number.parseInt(localStorage.getItem("refreshTokenExpTime"))
        ).getTime();

        if (
          currentTokenExpTime &&
          self.getRefreshTokenExpTime != currentTokenExpTime
        ) {
          self.$store.commit("mutRefreshTokenExpTime", currentTokenExpTime, {
            root: true,
          });
          timeleft = currentTokenExpTime - now;
        } else timeleft = self.getRefreshTokenExpTime - now;

        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        self.remainMinutes = minutes;
        self.remainSeconds = seconds % 60;

        if (minutes == 0 && seconds == 0) {
          clearInterval(self.timeinterval);
          self.remainMinutes = 0;
          self.remainSeconds = 0;
          self.onLogout();
        }
      }, 1000);
    },

    onEditProfile() {},

    async onLogout() {
      await securityApi.Logout();
      this.currentUser = null;
      this.$store.commit("mutClearCurrentUser", null, { root: true });

      location.reload();
    },
  },

  created() {
    this.$bus.$on("search-bar-result", this.onSearchBarResult);
    this.systemErrorMessage = this.$message;
  },

  beforeDestroy() {
    clearInterval(this.timeinterval);
  },

  mixins: [waves],

  watch: {
    systemErrorMessage: {
      deep: true,
      handler(to) {
        if (
          to.Message.length > 0 &&
          to.isSuccessInd == false &&
          this.currentUser != null
        ) {
          this.headerMessage.Message = this.$lodash.clone(to.Message);
          this.headerMessage.isSuccessInd = to.isSuccessInd;
        } else {
          this.headerMessage.Message = this.$lodash.clone(to.Message);
        }
      },
    },

    "headerMessage.isSuccessInd"(to) {
      if (to == true) {
        var timerInterval = setInterval(() => {
          if (this.timer == 0) {
            clearInterval(timerInterval);
            this.onSuccessErrorPaneClose();
            this.timer = SUCCESSDIALOG_TimeoutInSeconds;
          } else this.timer -= 1;
        }, 1000);
      }
    },

    isSearchBarError(to) {
      if (to == true) {
        setTimeout(() => {
          this.isSearchBarError = false;
        }, 2500);
      }
    },

    /* Control the display of the "Home" breadcrumbs. Hide on logon display,
     * display on logon hide.
     */
    logonDialog(to, from) {
      if (to != from) this.breadcrumbs.length = 0;

      if (to == false)
        this.breadcrumbs.push({
          text: "Home",
          disabled: false,
          href: "/",
        });
    },

    currentUser(to, from) {
      if (to != from) {
        if (to == null) {
          this.logonDialog = true;
        } else {
          tokenManager.getUserPermissionAsync().then((UserPermission) => {
            this.$store.commit(
              "mutStoreCurrentUserPermission",
              UserPermission,
              { root: true }
            );
            this.menuItems = permission.filterMenu(
              this.menuItems,
              UserPermission
            );
            this.logonDialog = false;
          });
        }
      }
    },

    $route(to, from) {
      if (this.$route.fullPath.includes("/user/resetpassword")) {
        this.logonDialog = false;
        this.breadcrumbs.push({
          text: "Password Reset",
          disabled: true,
        });
        return;
      }
      if (this.currentUser == null) {
        if (this.$route.fullPath.includes("/user/retrieveusername")) {
          //This is a retrieveUsername request.
          let self = this;
          this.logonDialog = true;
          const token = to.path.split("/")[3];
          securityApi
            .retrieveUsername(token)
            .then((val) => {
              if (self.logonDialogMessage.length == 0) {
                self.retrievedUsername = val.Username;
              }
            })
            .catch((err) => {
              self.logonDialogMessage = err.message;
            });
        } else {
          //This is not a retrieveUsername request... it is a standard operation.
          tokenManager.doesAccessTokenExistAsync().then((result) => {
            if (result == false) {
              if (
                this.doesSessionPersistCookieExist("SessionPersistenceFlag") ==
                true
              ) {
                //Can't check for a refresh token as it's in an HTTP only cookie
                let self = this;
                securityApi
                  .updateToken()
                  .then(async (val) => {
                    self.onLogonComplete(val.User);
                    self.logonDialog = false;
                  })
                  .catch(() => {
                    self.logonDialog = true;
                  });
              } else this.logonDialog = true;
            } else {
              //Fetch user info here.
              tokenManager.getCurrentUserContextAsync().then((u) => {
                this.currentUser = u;
                this.$store.commit("mutStoreCurrentUser", u);
                this.logonDialog = false;
                let self = this;
                this.$store.dispatch("actLoadReferenceData").then(() => {
                  self.$store.commit(
                    "mutRefreshTokenExpTime",
                    new Date(
                      Number.parseInt(
                        localStorage.getItem("refreshTokenExpTime")
                      )
                    ).getTime(),
                    { root: true }
                  );

                  self.onRefreshTokenCountDown();
                });
              });
            }
          });
        }
      }

      this.onSuccessErrorPaneClose();

      if (to.fullPath == "/" && from.fullPath == "/") {
        return;
      }
      /*
        If the to path starts with the from path then must be continuing on the same path, so add to the breadcrumbs.

        If the from path starts with the to path then must have pressed the back button, so pop off the last breadcrumb.

        Else am starting up a new breadcrumb, so clear the breadcrumbs and start again (starting at the base breadcrumb).
      */

      if (to.fullPath.includes("/user/retrieveusername")) {
        //pass
      } else {
        if (to.fullPath.startsWith(from.fullPath)) {
          this.breadcrumbs.push({
            text: to.meta.breadcrumb,
            disabled: false,
            href: to.fullPath,
          });
        } else if (from.fullPath.startsWith(to.fullPath)) {
          while (
            this.breadcrumbs[this.breadcrumbs.length - 1].href != to.fullPath &&
            this.breadcrumbs.length > 1
          )
            this.breadcrumbs.pop();
        } else {
          //Following is a valid way of truncating an array
          //(https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/length)
          this.breadcrumbs.length = 1;

          this.breadcrumbs.push({
            text: to.meta.breadcrumb,
            disabled: false,
            href: to.fullPath,
          });
        }
      }
    },
  },

  data: () => ({
    systemErrorMessage: null,
    toggleMenu: false,
    currentUser: null,
    timer: SUCCESSDIALOG_TimeoutInSeconds,
    headerMessage: {
      isSuccessInd: false,
      Message: [],
    },
    isSearchBarError: false,
    userProfileDropDown: false,
    showExitBeforeSavePromptInd: false,
    searchBarErrorMessage: "",
    logonDialogMessage: "",
    retrievedUsername: "",
    logonDialog: true,
    remainMinutes: null,
    remainSeconds: null,
    timeinterval: null,
    // waves: true,
    menuItems: [],
    breadcrumbs: [],
  }),
};
</script>

<style scoped>
@media all and (min-width: 1290px) {
  .display-when-narrow {
    display: none !important;
  }
}

@media all and (max-width: 1290px) {
  .display-when-wide {
    display: none !important;
  }
}

.top-message {
  top: 0px;
  margin-bottom: -80px;
  width: 100%;
  z-index: 2000;
  position: -webkit-sticky;
  position: sticky;
}
</style>
