var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "md-form" },
    [
      _c("mdb-input", {
        class: `timepicker ${
          _vm.pickerDialogOpen ? "picker__input picker__input--active" : ""
        }`,
        attrs: {
          type: "text",
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          id: _vm.id,
          value: _vm.innerValue,
          icon: _vm.icon,
          iconClass: _vm.iconClass,
          label: _vm.label,
          far: _vm.far,
          bg: _vm.bg,
          fal: _vm.fal,
          fab: _vm.fab,
          outline: _vm.outline,
          "read-only": "",
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          focus: function ($event) {
            return _vm.$emit("focus", $event)
          },
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handlePickerDialogToggle.apply(null, arguments)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handlePickerDialogToggle.apply(null, arguments)
          },
        },
      }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.pickerDialogOpen
          ? _c("div", { staticClass: "clockpicker picker picker--opened" }, [
              _c(
                "div",
                {
                  staticClass: "picker__holder",
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.away.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "picker__frame" }, [
                    _c("div", { staticClass: "picker__wrap" }, [
                      _c(
                        "div",
                        { staticClass: "picker__box" },
                        [
                          _c("time-display", {
                            attrs: {
                              hours: _vm.computedHours,
                              color: _vm.color,
                              minutes: _vm.computedMinutes,
                              dayTime: _vm.dayTime,
                              unitsMode: _vm.unitsMode,
                              hoursFormat: _vm.hoursFormat,
                            },
                            on: { "units-mode-change": _vm.handleModeChange },
                          }),
                          _c("clockpicker-plate", {
                            attrs: {
                              hours: _vm.hours,
                              minutes: _vm.minutes,
                              unitsMode: _vm.computedMode,
                              color: _vm.color,
                              hoursFormat: _vm.hoursFormat,
                            },
                            on: {
                              minutes: _vm.handleMinutesChange,
                              hours: _vm.handleHoursChange,
                              change: _vm.handleModeChange,
                            },
                          }),
                          _vm.hoursFormat === 12
                            ? _c("clockpicker-am-pm-block", {
                                on: {
                                  "day-time-change": _vm.handleDayTimeChange,
                                },
                              })
                            : _vm._e(),
                          _c("clockpicker-footer", {
                            attrs: {
                              done: _vm.doneLabel,
                              clear: _vm.clearLabel,
                              cancel: _vm.cancelLabel,
                            },
                            on: {
                              "done-clicked": _vm.handleDoneClicked,
                              "clear-clicked": _vm.handleClearClicked,
                              "cancel-clicked": _vm.handleCancelClicked,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }