var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onAdd },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.orders.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No orders found")]),
          ])
        : _vm._l(_vm.orders, function (order, orderIndex) {
            return _c(
              "mdb-card",
              {
                key: "order-list-" + orderIndex,
                staticClass: "mb-3",
                style: order.IsActiveInd ? "opacity:1" : "opacity:0.5",
              },
              [
                _c(
                  "mdb-card-header",
                  {
                    attrs: { tag: "button" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onToggleOrderExpansion(order)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "float-right" },
                        [
                          order.Expanded == true
                            ? _c("mdb-icon", {
                                staticClass: "pt-2",
                                attrs: {
                                  icon: "angle-up",
                                  size: "2x",
                                  color: "primary",
                                },
                              })
                            : _c("mdb-icon", {
                                staticClass: "pt-2",
                                attrs: {
                                  icon: "angle-down",
                                  size: "2x",
                                  color: "primary",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm._v(
                            " Reseller: " +
                              _vm._s(order.ResellerSupplierName) +
                              " "
                          ),
                          !order.IsActiveInd
                            ? [
                                _c("span", { staticClass: "alert-text" }, [
                                  _vm._v("(Deleted)"),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "font-size": "75%" },
                          },
                          [
                            _c("i", [
                              _vm._v("(" + _vm._s(order.Reference) + ")"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._l(
                      order.PrimaryOrderProducts,
                      function (primaryProduct, index) {
                        return _c(
                          "div",
                          {
                            key: "primary-product-view-" + index,
                            staticClass:
                              "my-2 mx-1 flex row align-items-center justify-content-left",
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "pr-3",
                              staticStyle: { width: "45px" },
                              attrs: {
                                slot: "reference",
                                icon: _vm.getProductIcon(
                                  primaryProduct.ProductTypeCd,
                                  primaryProduct.ProductSubTypeCd
                                ),
                                size: "2x",
                                color: "primary",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "h5",
                              {
                                staticClass: "d-flex flex-column",
                                staticStyle: { "text-align": "left" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      primaryProduct.ProviderSupplierName
                                    ) +
                                      " - " +
                                      _vm._s(primaryProduct.Name)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "expansion-region",
                  { attrs: { toggle: order.Expanded } },
                  [
                    _c(
                      "mdb-card-body",
                      [
                        _c("h3", [_vm._v("Products:")]),
                        _vm._l(
                          order.PrimaryOrderProducts,
                          function (orderProduct, orderProductIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  "primary-product-details-" +
                                  orderProductIndex,
                                staticClass: "my-3",
                              },
                              [
                                orderProduct.Product
                                  ? [
                                      _c("h5", [
                                        _vm._v(
                                          "Name: " + _vm._s(orderProduct.Name)
                                        ),
                                      ]),
                                      _c("h5", [
                                        _vm._v(
                                          " Priced for date: " +
                                            _vm._s(
                                              _vm
                                                .$moment(
                                                  orderProduct.PricedForDt
                                                )
                                                .format("DD/MM/YYYY")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("h5", [_vm._v("Product lines:")]),
                                      _vm._l(
                                        orderProduct.OrderProductLines,
                                        function (orderProductLine, oplIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "order-product-line" + oplIndex,
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(orderProductLine.Name)
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      orderProduct.GroupOrderProducts.length ===
                                      0
                                        ? _c("div", { staticClass: "my-2" }, [
                                            _c("h5", [
                                              _vm._v("No group products"),
                                            ]),
                                          ])
                                        : [
                                            _c("h5", { staticClass: "my-2" }, [
                                              _vm._v("Group Products:"),
                                            ]),
                                            _vm._l(
                                              orderProduct.GroupOrderProducts,
                                              function (
                                                groupOrderProduct,
                                                ropIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "group-order-product" +
                                                      ropIndex,
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          groupOrderProduct.Name
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                      _c("div", {
                                        staticClass: "section-break",
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        order.IsActiveInd
                          ? _c(
                              "div",
                              { staticClass: "float-right mb-3" },
                              [
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mr-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(orderIndex)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "edit" },
                                    }),
                                    _vm._v("Edit "),
                                  ],
                                  1
                                ),
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDelete(orderIndex)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Delete "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "float-right mb-3" },
                              [
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: () => _vm.onUndelete(orderIndex),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Undelete "),
                                  ],
                                  1
                                ),
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: () =>
                                        _vm.onPermanentlyDelete(orderIndex),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Permanently delete "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
      _c("yes-or-no-prompt", { ref: "handleOrderPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }