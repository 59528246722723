<template>
  <asoft-box-display
    v-bind="$attrs"
    v-on="$listeners"
    :titleColor="titleColor"
    :iconColor="iconColor"
    :borderColor="borderColor"
    :contentColor="contentColor"
  ></asoft-box-display>
</template>

<script>
import variables from "@/styles/sharedVariables.scss";
import asoftBoxDisplay from "../AtomSoftware/asoftBoxDisplay.vue";

export default {
  components: {
    asoftBoxDisplay,
  },
  props: {
    titleColor: {
      type: String,
      default: variables.awgtBoxDisplayTitleColor,
    },
    iconColor: {
      type: String,
      default: variables.awgtBoxDisplayIconColor,
    },
    borderColor: {
      type: String,
      default: variables.awgtBoxDisplayBorderColor,
    },
    contentColor: {
      type: String,
      default: variables.awgtBoxDisplayContentColor,
    },
  },
};
</script>
