<template>
  <div>
    <asoft-modal-header label="Price Adjustments" @close="() => onCancel()">
    </asoft-modal-header>
    <mdb-modal-body class="px-2">
      <div class="mb-4">
        <!-- {{ workingPriceAdjustments }} -->
        <div v-if="workingPriceAdjustments.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="font-weight: bold">Name</th>
                <th scope="col" style="font-weight: bold">Adjustment Type</th>
                <th scope="col" style="font-weight: bold">Change Type</th>
                <th scope="col" style="font-weight: bold">Amount</th>
                <th scope="col" class="float-right py-2">
                  <awgt-std-button
                    type="button"
                    class="command-button mr-2 ml-auto"
                    @click="onPriceAdjustmentsAdd"
                  >
                    <mdb-icon icon="plus" class="mr-1" />Add
                  </awgt-std-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(priceAdjustment, index) in $lodash.orderBy(
                  workingPriceAdjustments,
                  'CalculationOrder'
                )"
                :key="index"
              >
                <td>
                  {{ priceAdjustment.Name }}
                </td>
                <td>
                  {{
                    getPriceAdjustmentTypeNameByCode(
                      priceAdjustment.PriceAdjustmentTypeCd
                    )
                  }}
                </td>
                <td>
                  {{
                    getPriceChangeTypeNameByCode(
                      priceAdjustment.PriceChangeTypeCd
                    )
                  }}
                </td>
                <td>
                  {{ priceAdjustment.Amount }}
                </td>
                <td class="float-right py-2">
                  <awgt-std-button
                    type="button"
                    class="mr-2 ml-auto p-0"
                    style="
                      width: 30px;
                      height: 30px;
                      background-color: transparent !important;
                      background: none !important;
                      border: none !important;
                    "
                    @click="onEdit(priceAdjustment.CalculationOrder)"
                  >
                    <mdb-icon icon="edit" class="text-secondary" />
                  </awgt-std-button>
                  <awgt-std-button
                    type="button"
                    class="mr-2 ml-auto p-0"
                    style="
                      width: 30px;
                      height: 30px;
                      background-color: transparent !important;
                      background: none !important;
                      border: none !important;
                    "
                    @click="onDelete(priceAdjustment.CalculationOrder)"
                  >
                    <mdb-icon icon="trash" class="text-secondary" />
                  </awgt-std-button>
                  <span v-if="workingPriceAdjustments.length > 1">
                    <awgt-std-button
                      type="button"
                      class="mr-2 ml-auto p-0"
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: transparent !important;
                        background: none !important;
                        border: none !important;
                      "
                      @click="onUp(priceAdjustment.CalculationOrder)"
                    >
                      <mdb-icon icon="arrow-circle-up" class="text-secondary" />
                    </awgt-std-button>
                    <awgt-std-button
                      type="button"
                      class="mr-2 ml-auto p-0"
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: transparent !important;
                        background: none !important;
                        border: none !important;
                      "
                      @click="onDown(priceAdjustment.CalculationOrder)"
                    >
                      <mdb-icon
                        icon="arrow-circle-down"
                        class="text-secondary"
                      />
                    </awgt-std-button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="d-flex flex-row flex-nowrap align-items-center">
          <div class="ml-2">
            <i>Press "Add" to create a price adjustment</i>
          </div>
          <div class="ml-auto">
            <awgt-std-button
              type="button"
              class="command-button mr-2 ml-auto"
              @click="onPriceAdjustmentsAdd"
            >
              <mdb-icon icon="plus" class="mr-1" />Add
            </awgt-std-button>
          </div>
        </div>

        <div v-if="addAdjustmentExpanded == true">
          <price-adjustments-edit
            :allowedPriceAdjustments="allowedPriceAdjustments"
            :keepDollarOnItemInd="keepDollarOnItemInd"
            :value="currentPriceAdjustment"
            @input="onSavePriceAdjustment"
            @close="onClosePriceAdjustment"
          >
          </price-adjustments-edit>
        </div>
        <div v-else>
          <hr class="mt-4" />
          <div class="float-right my-2">
            <awgt-std-button
              type="button"
              class="command-button mr-2 ml-auto"
              @click="onSave"
            >
              <mdb-icon icon="check" class="mr-1" />Save
            </awgt-std-button>
            <awgt-std-button
              type="button"
              class="command-button mr-2 ml-auto"
              @click="onCancel"
            >
              <mdb-icon icon="times" class="mr-1" />Cancel
            </awgt-std-button>
          </div>
        </div>
      </div>
    </mdb-modal-body>
  </div>
</template>
<script>
import { mdbModalBody, mdbIcon } from "mdbvue";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mapGetters } from "vuex";
import priceAdjustmentsEdit from "./priceAdjustmentsEdit";

export default {
  components: {
    mdbModalBody,
    asoftModalHeader,
    mdbIcon,
    AwgtStdButton,
    priceAdjustmentsEdit,
  },

  data() {
    return {
      workingPriceAdjustments: null,
      addAdjustmentExpanded: false,
      currentPriceAdjustment: null,
    };
  },

  props: {
    //Pass in an array of price adjustments
    value: {
      Type: Array,
    },

    //Pass in an array of allowed price adjustments for this product (line).
    allowedPriceAdjustments: {
      Type: Array,
    },

    keepDollarOnItemInd: {
      Type: Boolean,
    },

    visible: {
      Type: Boolean,
    },
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getPriceChangeTypes",
      "getPriceAdjustmentTypes",
      "getPriceChangeTypeNameByCode",
      "getPriceAdjustmentTypeNameByCode",
    ]),
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(to) {
        this.workingPriceAdjustments = this.$lodash.cloneDeep(to);
      },
    },
  },

  methods: {
    onCancel() {
      this.$emit("close");
    },

    onSavePriceAdjustment(priceAdjustment) {
      let itemToSave = this.workingPriceAdjustments.find(
        (x) => x.CalculationOrder == priceAdjustment.CalculationOrder
      );

      if (itemToSave == null)
        this.workingPriceAdjustments.push(priceAdjustment);
      else {
        itemToSave = priceAdjustment;
        itemToSave.Amount = priceAdjustment.Amount;
        itemToSave.Comment = priceAdjustment.Comment;
        itemToSave.Name = priceAdjustment.Name;
        itemToSave.PriceAdjustmentTypeCd =
          priceAdjustment.PriceAdjustmentTypeCd;
        itemToSave.PriceChangeTypeCd = priceAdjustment.PriceChangeTypeCd;
      }

      this.addAdjustmentExpanded = false;
    },

    onEdit(calculationOrder) {
      let priceAdjustment = this.workingPriceAdjustments.find(
        (x) => x.CalculationOrder == calculationOrder
      );

      this.currentPriceAdjustment = priceAdjustment;
      this.addAdjustmentExpanded = true;
    },

    onUp(calculationOrder) {
      let itemToMove = this.workingPriceAdjustments.find(
        (x) => x.CalculationOrder == calculationOrder
      );

      if (calculationOrder == 1) {
        this.workingPriceAdjustments.find(
          (x) => x.CalculationOrder == this.workingPriceAdjustments.length
        ).CalculationOrder = 1;
        itemToMove.CalculationOrder = this.workingPriceAdjustments.length;
      }

      if (calculationOrder > 1) {
        this.workingPriceAdjustments.find(
          (x) => x.CalculationOrder == calculationOrder - 1
        ).CalculationOrder = calculationOrder;
        itemToMove.CalculationOrder = calculationOrder - 1;
      }
    },

    onDown(calculationOrder) {
      let itemToMove = this.workingPriceAdjustments.find(
        (x) => x.CalculationOrder == calculationOrder
      );

      if (calculationOrder == this.workingPriceAdjustments.length) {
        this.workingPriceAdjustments.find(
          (x) => x.CalculationOrder == 1
        ).CalculationOrder = this.workingPriceAdjustments.length;
        itemToMove.CalculationOrder = 1;
      }

      if (calculationOrder < this.workingPriceAdjustments.length) {
        this.workingPriceAdjustments.find(
          (x) => x.CalculationOrder == calculationOrder + 1
        ).CalculationOrder = calculationOrder;
        itemToMove.CalculationOrder = calculationOrder + 1;
      }
    },

    onDelete(calculationOrder) {
      let indexToDelete = this.workingPriceAdjustments.findIndex(
        (x) => x.CalculationOrder == calculationOrder
      );

      this.workingPriceAdjustments.splice(indexToDelete, 1);
      //Need to fix the hole in the calculation order
      if (this.workingPriceAdjustments.length > 0) {
        for (
          let i = calculationOrder + 1;
          i++;
          i <= this.workingPriceAdjustments.length
        ) {
          this.workingPriceAdjustments.find(
            (x) => x.CalculationOrder == i
          ).CalculationOrder = i - 1;
        }
      }
    },

    onClosePriceAdjustment() {
      this.addAdjustmentExpanded = false;
    },

    onPriceAdjustmentsAdd() {
      this.currentPriceAdjustment = {
        Name: "",
        Amount: 0.0,
        Comment: "",
        PriceChangeTypeCd: null,
        PriceAdjustmentTypeCd: null,
        OrderProductPriceAdjustmentId: null,
        CalculationOrder:
          Math.max(
            ...this.workingPriceAdjustments.map((oppa) => oppa.CalculationOrder)
          ) + 1,
      };

      this.addAdjustmentExpanded = true;
    },

    onPriceAdjustmentsRemove(editProduct, index) {
      this.workingPriceAdjustments.splice(index, 1);
      /**
       * Always maintain the CalculationOrder tallied from 0.
       */
      this.reorderIndex(editProduct);
    },

    reorderIndex() {
      this.workingPriceAdjustments = this.workingPriceAdjustments.map(
        (adjustment, index) => {
          return {
            ...adjustment,
            CalculationOrder: index,
          };
        }
      );
    },

    onSave() {
      this.$emit("input", this.workingPriceAdjustments);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.warning-msg {
  color: red;
}
</style>
