var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Travel Booking")]),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "span",
            { staticClass: "ml-2 mr-3", staticStyle: { "min-width": "60px" } },
            [_vm._v("Client Type")]
          ),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_SchoolClients",
              type: "checkbox",
              name: "searchScope",
              label: "Schools",
            },
            model: {
              value: _vm.includeSchoolClientsInd,
              callback: function ($$v) {
                _vm.includeSchoolClientsInd = $$v
              },
              expression: "includeSchoolClientsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_IndividualClients",
              type: "checkbox",
              name: "searchScope",
              label: "Individuals",
            },
            model: {
              value: _vm.includeIndividualClientsInd,
              callback: function ($$v) {
                _vm.includeIndividualClientsInd = $$v
              },
              expression: "includeIndividualClientsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_OrganisationClients",
              type: "checkbox",
              name: "searchScope",
              label: "Organisations",
            },
            model: {
              value: _vm.includeOrganisationClientsInd,
              callback: function ($$v) {
                _vm.includeOrganisationClientsInd = $$v
              },
              expression: "includeOrganisationClientsInd",
            },
          }),
        ],
        1
      ),
      _c("awgt-std-switch", {
        staticClass: "mt-3",
        attrs: {
          checked: "",
          offLabel: "",
          onLabel: "Match all searching criteria",
        },
        on: {
          getValue: (value) => {
            _vm.matchAllSearchingCriteriaInd = value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Reference",
              bg: "",
            },
            model: {
              value: _vm.searchReference,
              callback: function ($$v) {
                _vm.searchReference = $$v
              },
              expression: "searchReference",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              multiple: "",
              selectAll: "",
              maxlength: "100",
              items: _vm.workgroupsList,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Workgroups",
              bg: "",
            },
            model: {
              value: _vm.workgroups,
              callback: function ($$v) {
                _vm.workgroups = $$v
              },
              expression: "workgroups",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              multiple: "",
              selectAll: "",
              maxlength: "100",
              items: _vm.consultantsList,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Consultants",
              bg: "",
            },
            model: {
              value: _vm.consultants,
              callback: function ($$v) {
                _vm.consultants = $$v
              },
              expression: "consultants",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              multiple: "",
              selectAll: "",
              maxlength: "100",
              items: _vm.getBookingStatuses(false),
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Status",
              bg: "",
            },
            model: {
              value: _vm.bookingStatus,
              callback: function ($$v) {
                _vm.bookingStatus = $$v
              },
              expression: "bookingStatus",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              multiple: "",
              selectAll: "",
              maxlength: "100",
              items: _vm.getDestinations,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Destination",
              bg: "",
            },
            model: {
              value: _vm.destination,
              callback: function ($$v) {
                _vm.destination = $$v
              },
              expression: "destination",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              maxlength: "100",
              items: _vm.getClients,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Clients",
              bg: "",
            },
            model: {
              value: _vm.client,
              callback: function ($$v) {
                _vm.client = $$v
              },
              expression: "client",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              maxlength: "100",
              items: _vm.getYears(2017),
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Year",
              bg: "",
            },
            model: {
              value: _vm.bookingYear,
              callback: function ($$v) {
                _vm.bookingYear = $$v
              },
              expression: "bookingYear",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              multiple: "",
              selectAll: "",
              label: "Term",
              itemValueProperty: "Code",
              items: _vm.getSchoolTerms(false),
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.terms,
              callback: function ($$v) {
                _vm.terms = $$v
              },
              expression: "terms",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c("awgt-std-switch", {
            attrs: { offLabel: "", onLabel: "Include deleted travel bookings" },
            model: {
              value: _vm.includeDeletedTravelBookingsInd,
              callback: function ($$v) {
                _vm.includeDeletedTravelBookingsInd = $$v
              },
              expression: "includeDeletedTravelBookingsInd",
            },
          }),
          _c("awgt-std-switch", {
            attrs: { checked: "", offLabel: "", onLabel: "Include templates" },
            on: {
              getValue: (value) => {
                _vm.includeTemplatesInd = value
              },
            },
          }),
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onClearSearchCriteria },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "eraser" },
                  }),
                  _vm._v("Clear "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onSearchBooking },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "search" },
                  }),
                  _vm._v("Search "),
                ],
                1
              ),
              _vm.checkPermission("CP_TBC")
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button" },
                      on: { click: _vm.onAddBooking },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "sortingDropDown" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("awgt-std-dropdown", {
              staticClass: "mx-2 flex-grow-1",
              attrs: {
                label: "Sorting Selection",
                itemValueProperty: "Code",
                items: _vm.sortingSelection,
                itemTextProperty: "Name",
                bg: "",
              },
              model: {
                value: _vm.selectedSortingCondition,
                callback: function ($$v) {
                  _vm.selectedSortingCondition = $$v
                },
                expression: "selectedSortingCondition",
              },
            }),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button float-right",
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  type: "button",
                  disabled: _vm.selectedSortingCondition === null,
                },
                on: { click: _vm.onClickSort },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "sort" },
                }),
                _vm._v("Sort "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.travelBookings.length == 0 && _vm.isSearched
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._e(),
      _vm.travelBookings.length > 0
        ? _c("div", { staticClass: "d-flex flex-grow-1 table-headers" }, [
            _c("div", { staticClass: "flex-fill-term" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByTerm },
                },
                [
                  _vm._v("Term "),
                  _vm.sortByTermInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByTermInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex-fill-client" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByClient },
                },
                [
                  _vm._v(" Client"),
                  _vm.sortByClientInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByClientInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex-fill" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByID },
                },
                [
                  _vm._v(" Reference"),
                  _vm.sortByIDInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByIDInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex-fill-des" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByDescription },
                },
                [
                  _vm._v(" Description"),
                  _vm.sortByDescriptionInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByDescriptionInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex-fill" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByDate },
                },
                [
                  _vm._v(" Start Date"),
                  _vm.sortByDateInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByDateInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex-fill" }, [
              _c(
                "span",
                {
                  staticClass: "headers-frame-header",
                  on: { click: _vm.onSortByStatus },
                },
                [
                  _vm._v("Status"),
                  _vm.sortByStatusInd == true
                    ? _c("mdb-icon", { attrs: { icon: "caret-down" } })
                    : _vm._e(),
                  _vm.sortByStatusInd == false
                    ? _c("mdb-icon", { attrs: { icon: "caret-up" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("br"),
      _vm._l(_vm.travelBookings, function (travelBooking, index) {
        return _c(
          "mdb-card",
          {
            key: index,
            staticClass: "mb-1 mx-2",
            style: travelBooking.IsActive ? "opacity:1" : "opacity: 0.5",
          },
          [
            _c(
              "mdb-card-header",
              {
                staticClass: "flex-nowrap card-header",
                attrs: { tag: "button" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onToggletravelBookingDetailExpansion(index)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "float-right" },
                  [
                    _vm.travelBookingDetailExpansion[index] == true
                      ? _c("mdb-icon", {
                          attrs: {
                            icon: "angle-up",
                            size: "2x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          attrs: {
                            icon: "angle-down",
                            size: "2x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-grow-1 table-column" }, [
                  _c("div", { staticClass: "flex-fill-term table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Term"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "booking-term",
                        style: {
                          backgroundColor:
                            _vm
                              .getSchoolTermColorFromCode(
                                travelBooking.SchoolTerm
                              )
                              .trim().length > 0
                              ? "#" +
                                _vm.getSchoolTermColorFromCode(
                                  travelBooking.SchoolTerm
                                )
                              : null,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getSchoolTermNameFromCode(
                                travelBooking.SchoolTerm
                              ) != undefined
                                ? _vm
                                    .getSchoolTermNameFromCode(
                                      travelBooking.SchoolTerm
                                    )
                                    .slice(-1)
                                : null
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "flex-fill-client table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Client"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(travelBooking.Client.DisplayName) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-fill table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Reference"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c("span", [_vm._v(_vm._s(travelBooking.Reference) + " ")]),
                  ]),
                  _c("div", { staticClass: "flex-fill-des table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Description"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c("span", [_vm._v(_vm._s(travelBooking.Description))]),
                  ]),
                  _c("div", { staticClass: "flex-fill table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Start Date"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            travelBooking.DepartureDt == null
                              ? ""
                              : _vm.convertDateFormat(
                                  travelBooking.DepartureDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-fill table-element" }, [
                    _c("span", { staticClass: "heading" }, [
                      _vm._v("Status"),
                      _c("span", { staticClass: "header-colon" }, [
                        _vm._v(": "),
                      ]),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getBookingStatusNameByCode(
                            travelBooking.BookingStatus
                          )
                        ) + " "
                      ),
                    ]),
                    _c("span", {
                      staticClass: "status_indicator",
                      staticStyle: { "margin-top": "5px" },
                      style: {
                        backgroundColor:
                          "#" +
                          _vm.getBookingStatusColorFromCode(
                            travelBooking.BookingStatus
                          ),
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c(
              "expansion-region",
              { attrs: { toggle: _vm.travelBookingDetailExpansion[index] } },
              [
                _c(
                  "mdb-card-body",
                  [
                    !travelBooking.IsActive
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "alert-text",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v(" Deleted ")]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(
                      "Requested: " +
                        _vm._s(
                          _vm.convertDateFormat(
                            travelBooking.RequestedDt,
                            _vm.$constants.DATETIMEFORMAT_Json,
                            _vm.$constants.DATETIMEFORMAT_Presentation
                          )
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      "Is Template: " + _vm._s(travelBooking.IsTemplate) + " "
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: travelBooking.IsActive == false,
                            expression: "travelBooking.IsActive == false",
                          },
                        ],
                        staticClass: "my-2 float-right",
                      },
                      [
                        _vm.checkPermission("CP_TBD") &&
                        _vm.checkPermissionScopeForBookingDelete(index)
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Products" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUndeleteBooking(travelBooking)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "plus" },
                                }),
                                _vm._v("Undelete "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkPermission("CP_TBD") &&
                        _vm.checkPermissionScopeForBookingDelete(index)
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Products" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPermanentlyDelete(
                                      travelBooking
                                    )
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "trash-alt" },
                                }),
                                _vm._v("Permanently delete "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: travelBooking.IsActive == true,
                            expression: "travelBooking.IsActive == true",
                          },
                        ],
                        staticClass: "my-2 float-right",
                      },
                      [
                        _vm.checkPermission("CP_CoV")
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: {
                                  type: "button",
                                  title: "Communications",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCommunications(travelBooking)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "comments" },
                                }),
                                _vm._v("Communications "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkPermission("CP_ItV") &&
                        _vm.checkPermissionScopeForItineraryView(index)
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Itineraries" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onItineraries(travelBooking)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { far: "", icon: "calendar-alt" },
                                }),
                                _vm._v("Itineraries "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkPermission("CP_TBU") &&
                        _vm.checkPermissionScopeForBookingUpdate(index)
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditBooking(travelBooking)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "edit" },
                                }),
                                _vm._v("Edit "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkPermission("CP_TBD") &&
                        _vm.checkPermissionScopeForBookingDelete(index)
                          ? _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeleteBooking(travelBooking)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "trash-alt" },
                                }),
                                _vm._v("Delete "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("yes-or-no-prompt", { ref: "handleBookingPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }