var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "h1",
        {
          staticClass: "text-center",
          staticStyle: { "margin-bottom": "50px" },
        },
        [_vm._v("Manage Workgroups")]
      ),
      _c(
        "div",
        { staticClass: "float-right mb-2" },
        [
          _vm.checkPermission("CP_WgC")
            ? _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { id: "add-workgroup", type: "button", title: "Add" },
                  on: { click: _vm.onAddWorkgroup },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "user-plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticStyle: { clear: "both" } }),
      _vm.workgroups.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._l(_vm.workgroups, function (workgroup, index) {
            return _c(
              "mdb-card",
              { key: index, staticClass: "mb-3 mx-2" },
              [
                _c(
                  "mdb-card-header",
                  {
                    attrs: { tag: "button" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onToggleWorkGroupDetailExpansion(index)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "float-right" },
                      [
                        _vm.workgroupExpansion[index] == true
                          ? _c("mdb-icon", {
                              staticClass: "pt-2",
                              attrs: {
                                icon: "angle-up",
                                size: "2x",
                                color: "primary",
                              },
                            })
                          : _c("mdb-icon", {
                              staticClass: "pt-2",
                              attrs: {
                                icon: "angle-down",
                                size: "2x",
                                color: "primary",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap align-items-center",
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass: "mb-1",
                            attrs: { id: workgroup.Name },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "users",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(_vm._s(workgroup.Name) + " "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "expansion-region",
                  { attrs: { toggle: _vm.workgroupExpansion[index] } },
                  [
                    _c("mdb-card-body", [
                      _c(
                        "div",
                        [
                          _c("asoftTreeview", {
                            staticClass: "mx-2",
                            attrs: {
                              value: workgroup.TreeData,
                              childIndent: "20px",
                              childrenPropertyName: "Children",
                              isCollapsible: false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (item) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            {
                                              selecteditem:
                                                item.selected == true,
                                            },
                                          ],
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-male",
                                          }),
                                          _vm._v(" " + _vm._s(item.Text)),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "my-2 float-right" },
                        [
                          _vm.checkPermission("CP_WgU")
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Edit" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onEditWorkgroup(workgroup) &&
                                        _vm.checkPermissionForUpdate(workgroup)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "edit" },
                                  }),
                                  _vm._v("Edit "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.checkPermission("CP_WgD")
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Delete" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDeleteWorkgroup(workgroup)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "trash-alt" },
                                  }),
                                  _vm._v("Delete "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
      _c("yes-or-no-prompt", { ref: "handleWorkgroupPrompt" }),
      _c("ok-prompt", { ref: "alertPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }