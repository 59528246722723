import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getBriefSystemActions(
    userId = null,
    description = null,
    actionContext = null,
    actionType = null,
    startDate = null,
    endDate = null,
    currentPage = 1,
    sortOrder = "ASC"
  ) {
    try {
      logger.get(loggingSource.UISystemAction).info("getBriefSystemActions...");
      logger
        .get(loggingSource.UISystemAction)
        .debug(
          "Parameters => userId: %s, description: %s, actionContext: %s, actionType: %s, startDate: %s, endDate: %s, currentPage: %s, sortOrder: %s",
          userId,
          description,
          actionContext,
          actionType,
          startDate,
          endDate,
          currentPage,
          sortOrder
        );

      logger
        .get(loggingSource.UISystemAction)
        .time("getBriefSystemActions call");

      const response = await api.client
        .get("systemAction/SystemActionList", {
          params: {
            userId: userId,
            description: description,
            contextCd: actionContext,
            typeCd: actionType,
            startDate: startDate,
            endDate: endDate,
            currentPage,
            sortOrder,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UISystemAction)
        .timeEnd("getBriefSystemActions call");
      const systemActions = response.data;

      logger
        .get(loggingSource.UISystemAction)
        .trace("getBriefSystemActions response: %s", response);
      logger
        .get(loggingSource.UISystemAction)
        .debug("getBriefSystemActions UISystemAction: %s", response);

      return systemActions;
    } catch (error) {
      logger
        .get(loggingSource.UISystemAction)
        .timeEnd("getBriefSystemActions call");
      logger.get(loggingSource.UISystemAction).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISystemAction).info("...getBriefSystemActions");
    }
  },

  async getSystemActions(
    userId = null,
    description = null,
    actionContext = null,
    actionType = null,
    startDate = null,
    endDate = null
  ) {
    try {
      logger.get(loggingSource.UISystemAction).info("getSystemActions...");
      logger
        .get(loggingSource.UISystemAction)
        .debug(
          "Parameters => userId: %s, description: %s, actionContext: %s, actionType: %s, startDate: %s, endDate: %s",
          userId,
          description,
          actionContext,
          actionType,
          startDate,
          endDate
        );

      logger.get(loggingSource.UISystemAction).time("getSystemActions call");
      const response = await api.client
        .get("systemAction", {
          params: {
            userId: userId,
            description: description,
            contextCd: actionContext,
            typeCd: actionType,
            startDate: startDate,
            endDate: endDate,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISystemAction).timeEnd("getSystemActions call");
      const systemActions = response.data;

      logger
        .get(loggingSource.UISystemAction)
        .trace("getSystemActions response: %s", response);
      logger
        .get(loggingSource.UISystemAction)
        .debug("getSystemActions UISystemAction: %s", response);

      return systemActions;
    } catch (error) {
      logger.get(loggingSource.UISystemAction).timeEnd("getSystemActions call");
      logger.get(loggingSource.UISystemAction).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISystemAction).info("...getSystemActions");
    }
  },

  async getSystemActionByReference(reference) {
    try {
      logger
        .get(loggingSource.UISystemAction)
        .info("getSystemActionByReference...");
      logger
        .get(loggingSource.UISystemAction)
        .debug("Parameters => reference: %s", reference);

      logger
        .get(loggingSource.UISystemAction)
        .time("getSystemActionByReference call");
      const response = await api.client
        .get(`systemAction/${reference}`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UISystemAction)
        .timeEnd("getSystemActionByReference call");
      const systemAction = response.data;

      return systemAction;
    } catch (error) {
      logger
        .get(loggingSource.UISystemAction)
        .timeEnd("getSystemActionByReference call");
      logger.get(loggingSource.UISystemAction).error(error.response);
      throw error;
    } finally {
      logger
        .get(loggingSource.UISystemAction)
        .info("...getSystemActionByReference");
    }
  },
};
