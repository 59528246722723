var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-range-input", {
    attrs: {
      className: _vm.className,
      secondInputClass: _vm.secondInputClass,
      min: _vm.min,
      max: _vm.max,
      value: _vm.value,
      secondValue: _vm.secondValue,
      wrapperClass: _vm.wrapperClass,
      multi: _vm.multi,
    },
    on: { getValue: _vm.onChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }