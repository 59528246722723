<template>
  <div
    :class="textareaClass"
    contenteditable="true"
    ref="content"
    @input="contentModified($refs.content.innerHTML)"
  ></div>
</template>

<script>
const mdbWysiwygTextarea = {
  name: "mdbWysiwygTextarea",
  props: {
    activeView: {
      type: String,
      default: "html",
    },
    value: {
      type: String,
    },
  },
  computed: {
    textareaClass() {
      return ["mdb-wysiwyg-textarea", this.view !== "html" && "show-raw"];
    },
  },
  methods: {
    getState() {
      document.queryCommandState("bold") === true
        ? this.$emit("state", "bold", true)
        : this.$emit("state", "bold", false);
      document.queryCommandState("italic") === true
        ? this.$emit("state", "italic", true)
        : this.$emit("state", "italic", false);
      document.queryCommandState("strikeThrough") === true
        ? this.$emit("state", "strikeThrough", true)
        : this.$emit("state", "strikeThrough", false);
      document.queryCommandState("underline") === true
        ? this.$emit("state", "underline", true)
        : this.$emit("state", "underline", false);
      document.queryCommandState("justifyLeft") === true
        ? this.$emit("state", "justifyLeft", true)
        : this.$emit("state", "justifyLeft", false);
      document.queryCommandState("justifyCenter") === true
        ? this.$emit("state", "justifyCenter", true)
        : this.$emit("state", "justifyCenter", false);
      document.queryCommandState("justifyRight") === true
        ? this.$emit("state", "justifyRight", true)
        : this.$emit("state", "justifyRight", false);
      document.queryCommandState("justifyFull") === true
        ? this.$emit("state", "justifyFull", true)
        : this.$emit("state", "justifyFull", false);
      this.$emit("text-state", document.queryCommandValue("formatBlock"));
    },
    contentModified(value) {
      this.$emit("input", value);
    },
  },
  data() {
    return {
      view: this.activeView,
    };
  },
  watch: {
    value(to) {
      if (to != this.$refs.content.innerHTML) this.$refs.content.innerHTML = to;
    },
  },
  mounted() {
    this.$refs.content.innerHTML = this.value;
  },
};

export default mdbWysiwygTextarea;
export { mdbWysiwygTextarea };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdb-wysiwyg-textarea {
  display: block;
  padding: 15px;
  width: 100%;
  border: 1;
  outline: none;
  resize: none;
  background: #fff;
  overflow-y: auto;
  height: calc(100% - 40px);
}

.mdb-wysiwyg-textarea.show-raw {
  white-space: pre-line;
}
</style>
