/*
  v-numeric Directive

  The v-numeric directive allows you to enter a number into a field and constrain the precision and
  scale of the number. The precision is the total number of digits in the number (integer and fractional
  parts combined). The scale is the number of digits in the fractional part.

  The value passed to the directive represents a set of options and must be in the form:
  {
    precision: <integer, total number of digits in the number - optional defaults to 12>,
    scale: <integer, total number of fractional digits in the number - optional defaults to 6>,
    posOnly: <boolean, is the number positive only - optional defaults to false>
    trailingZeroScale: <integer, the scale of the number with trailing zeros - optional defaults to scale>
    allowEmptyString: <boolean, allows the number field to contain an empty string, otherwise empty string becomes zero>
  }

  If the scale is set to zero then only integers can be entered into the control.

  Note: If you want the control to return a numeric then ensure the .number modifier is set on the v-model
  directive.
*/

export const numericDirective = {
  bind(el, binding, vnode) {
    /*
          Find the first input element in the control that this directive is attached to. In most
          cases the control will be an input element, but in some cases the control's input
          element will be wrapped (e.g. by a div element).
        */
    //console.log("v-numeric bind");

    let inputElement = null;

    if (el.tagName == "INPUT") inputElement = el;
    else inputElement = el.getElementsByTagName("INPUT")[0];

    if (inputElement == null) {
      console.error("v-numeric: Unable to find input element");
      return;
    }

    setBindingDefaults(binding);

    bind(inputElement, binding, vnode);
  },

  /*
        The update statement reinitialises the control if either the mask or value changes. The
        value will change if the associated control is reinitialised with a new value.
      */
  update(el, binding, vnode) {
    /*
          Find the first input element in the control that this directive is attached to. In most
          cases the control will be an input element, but in some cases the control's input
          element will be wrapped (e.g. by a div element).
        */
    //console.log("v-numeric update");

    let inputElement = null;

    if (el.tagName == "INPUT") inputElement = el;
    else inputElement = el.getElementsByTagName("INPUT")[0];

    if (inputElement == null) {
      console.error("v-numeric: Unable to find input element");
      return;
    }

    setBindingDefaults(binding);

    //If nothing has changed then leave.
    if (deepEqual(binding.value, binding.oldValue) == true) return;

    /*     console.log(
        `Binding value: ${JSON.stringify(
          binding.value
        )}, OldValue: ${JSON.stringify(binding.oldValue)}`
      ); */

    //console.log("v-numeric update continuing");

    inputElement.removeEventListener(
      "keypress",
      vnode.context.asoftVNumericKeyPressFunc
    );
    inputElement.removeEventListener(
      "blur",
      vnode.context.asoftVNumericBlurFunc
    );

    bind(inputElement, binding, vnode);
  },

  /*
        componentUpdated fires whenever the component is changed. This is required
        because data resyncs caused by external events cause the text in the
        input control to be resynced with the data consequently changing the
        text in the input control. Fortunately componentUpdated events occur
        at the time and the data can be reset.
      */
  componentUpdated(el, binding) {
    /*
          Find the first input element in the control that this directive is attached to. In most
          cases the control will be an input element, but in some cases the control's input
          element will be wrapped (e.g. by a div element).
        */
    //console.log("v-numeric componentUpdated");

    let inputElement = null;

    if (el.tagName == "INPUT") inputElement = el;
    else inputElement = el.getElementsByTagName("INPUT")[0];

    if (inputElement == null) {
      console.error("v-numeric: Unable to find input element");
      return;
    }

    setBindingDefaults(binding);

    //If the input element has the focus then leave componentUpdated.
    if (document.activeElement === inputElement) return;

    //console.log("v-numeric componentUpdated - continuing");

    let parsedNum = null;

    if (binding.value.allowEmptyString == true && inputElement.value == "")
      parsedNum = "";
    else
      parsedNum = parseFloat(inputElement.value).toFixed(
        binding.value.trailingZeroScale
      );

    //Reset the data in the input element.
    inputElement.value = parsedNum;
  },
};

function setBindingDefaults(binding) {
  binding.value.precision = binding.value.precision ?? 12;
  binding.value.scale = binding.value.scale ?? 6;
  binding.value.posOnly = binding.value.posOnly ?? false;
  binding.value.trailingZeroScale = binding.value.scale ?? 0;
  binding.value.allowEmptyString = binding.value.allowEmptyString ?? false;

  /*   console.log(
      `Precision: ${binding.value.precision}, Scale: ${binding.value.scale}, PosOnly: ${binding.value.posOnly}, TrailingZeroScale: ${binding.value.trailingZeroScale}`
    ); */
}

function bind(inputElement, binding, vnode) {
  //console.log(binding);

  if (
    typeof binding.value.precision !== "number" ||
    Number.isInteger(binding.value.precision) == false ||
    binding.value.precision <= 0
  ) {
    console.error("v-numeric: precision must be a positive integer");
    return;
  }

  if (
    typeof binding.value.scale !== "number" ||
    Number.isInteger(binding.value.scale) == false ||
    binding.value.scale < 0
  ) {
    console.error("v-numeric: scale must be non-negative");
    return;
  }

  if (binding.value.scale > binding.value.precision) {
    console.error("v-numeric: scale must be less than or equal to precision");
    return;
  }

  if (typeof binding.value.posOnly !== "boolean") {
    console.error("v-numeric: posOnly must be either true or false");
    return;
  }

  if (typeof binding.value.allowEmptyString !== "boolean") {
    console.error("v-numeric: allowEmptyString must be either true or false");
    return;
  }

  if (
    typeof binding.value.trailingZeroScale !== "number" ||
    Number.isInteger(binding.value.trailingZeroScale) == false ||
    binding.value.trailingZeroScale < 0
  ) {
    console.error("v-numeric: trailingZeroScale must be non-negative");
    return;
  }

  if (binding.value.trailingZeroScale < binding.value.scale) {
    console.error(
      "v-numeric: trailingZeroScale must be greater than or equal to scale"
    );
    return;
  }

  /*
        Need to wait until the next tick to set the formatting on the input
        element. The reason is that when loading a page the content of the
        input element may not be determined until the control loads.
      */
  vnode.context.$nextTick(() => {
    let parsedNum = null;

    if (binding.value.allowEmptyString == true && inputElement.value == "")
      parsedNum = "";
    else
      parsedNum = parseFloat(inputElement.value).toFixed(
        binding.value.trailingZeroScale
      );

    inputElement.value = parsedNum;
    inputElement.dispatchEvent(new InputEvent("input"));
  });

  let onKeyPressFunc = onKeyPress(inputElement, binding, vnode);
  inputElement.addEventListener("keypress", onKeyPressFunc);
  //Save event in context variable incase it is removed in the update hook
  vnode.context.asoftVNumericKeyPressFunc = onKeyPressFunc;

  let onBlurFunc = onBlur(inputElement, binding, vnode);
  inputElement.addEventListener("blur", onBlurFunc);
  //Save event in context variable incase it is removed in the update hook
  vnode.context.asoftVNumericBlurFunc = onBlurFunc;

  /*
      Use a Mutation Observer to look for changes to the value
      property from external sources. If the value property
      changes then the control's output will need to be formatted.
  
      See:
      - https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
      - https://stackoverflow.com/questions/44503173/how-to-pause-observing-in-callback-of-a-mutationobserver
    */

  // Options for the observer (which mutations to observe)
  const mutationObserverConfig = {
    attributes: true,
    childList: false,
    subtree: false,
  };

  var cObserver = new customObserver(
    inputElement,
    mutationObserverConfig,
    function (mutations) {
      for (const mutation of mutations) {
        /*
            If the value property is being changed
            and it's not on the control that has the
            focus then format the value.
          */
        if (
          mutation.type === "attributes" &&
          mutation.attributeName == "value" &&
          mutation.target !== document.activeElement
        ) {
          let parsedNum = null;

          if (
            binding.value.allowEmptyString == true &&
            mutation.target.value == ""
          )
            parsedNum = "";
          else
            parsedNum = parseFloat(mutation.target.value).toFixed(
              binding.value.trailingZeroScale
            );

          cObserver.disconnect();
          mutation.target.value = parsedNum;
          cObserver.connect();
        }
      }
    }
  );

  cObserver.connect();
}

function customObserver(target, config, callback) {
  let self = this;
  this.target = target;
  this.config = config;
  this.ob = new MutationObserver(function (mutations, observer) {
    callback.call(self, mutations, observer);
  });
}

customObserver.prototype = {
  connect: function () {
    this.ob.observe(this.target, this.config);
  },
  disconnect: function () {
    this.ob.disconnect();
  },
};

/*
      onBlur occurs when the control loses focus. When this happens set the
      control to display the data in the desired format.
    */
function onBlur(el, binding, vnode) {
  return function () {
    //Turn an empty string into an empty string or a zero.
    if (!el.value || el.value === "NaN") {
      if (binding.value.allowEmptyString == true) el.value = "";
      else el.value = 0;
      //Tell the control you just set it to zero.
      //el.dispatchEvent(new InputEvent("input"));
    }

    vnode.context.$nextTick(() => {
      let parsedNum = null;

      if (binding.value.allowEmptyString == true && el.value == "")
        parsedNum = "";
      else
        parsedNum = parseFloat(el.value).toFixed(
          binding.value.trailingZeroScale
        );

      el.value = parsedNum;
      el.dispatchEvent(new InputEvent("input"));
    });
  };
}

/*
      onKeyPress occurs whenever a key is pressed. For our purposes this is
      better than the keydown event because onKeyPress only occurs on characters
      it doesn't occur for esc, ctrl, right, left, etc... so there's less
      work to do in allowing/disallowing keypresses.
    
      onKeyPress we don't format the input just exclude what characters
      are allowed to form the input.
    */
function onKeyPress(el, binding) {
  return function (val) {
    /*
          Reject keypresses that are not numeric.
        */
    if (/[0-9.-]/.test(val.key) == false) {
      val.preventDefault();
      return;
    }

    let cursorPos = getCursorPos(el);
    let isNegativeNumber = el.value[0] == "-";
    let integerPartLen =
      binding.value.precision -
      binding.value.scale +
      (isNegativeNumber ? 1 : 0);
    let fractionalPartLen = binding.value.scale;

    //console.log(`cursorPos: ${cursorPos}, isNegativeNumber: ${isNegativeNumber}, integerPartLen: ${integerPartLen}, fractionalPartLen: ${fractionalPartLen}`);

    //Prevent a '-' anywhere but the first character
    if (val.key == "-") {
      //if posOnly is set then don't allow the '-' key.
      if (binding.value.posOnly) {
        val.preventDefault();
      }

      //If not the first character then can't enter a '-'
      else if (el.selectionStart != 0) {
        val.preventDefault();
      }

      //if already a negative number and not typing over the '-' then prevent input.
      else if (isNegativeNumber == true && el.selectionEnd == 0) {
        val.preventDefault();
      }

      return;
    }

    //Prevent anything being typed before the '-'
    if (isNegativeNumber && cursorPos == 0) {
      val.preventDefault();
      return;
    }

    let periodPos = el.value.indexOf(".");
    //console.log(`PeriodPos: ${periodPos}, cursorPos: ${cursorPos}`);

    //Prevent a '.' if one already exists
    if (val.key == ".") {
      //If scale is zero then prevent a '.'
      if (binding.value.scale == 0) {
        val.preventDefault();
      }

      //If a period exists and is not be overwritten
      else if (
        periodPos != -1 &&
        (el.selectionStart >= periodPos || el.selectionEnd <= periodPos)
      ) {
        val.preventDefault();
      }

      return;
    }

    if (periodPos != -1) {
      //Prevent more than four characters before the '.'...
      if (
        cursorPos <= periodPos &&
        periodPos >= integerPartLen &&
        el.selectionStart == el.selectionEnd
      ) {
        val.preventDefault();
        return;
      }

      //Prevent more than two characters after the '.'...
      if (
        cursorPos > periodPos &&
        el.value.length - periodPos > fractionalPartLen &&
        el.selectionStart == el.selectionEnd
      ) {
        val.preventDefault();
        return;
      }
    } else {
      //Prevent more than four character whole number
      if (
        el.value.length >= integerPartLen &&
        val.key != "." &&
        el.selectionStart == el.selectionEnd
      ) {
        val.preventDefault();
        return;
      }
    }
  };
}

// Get the cursor position
function getCursorPos(input) {
  var CursorPos = 0;
  if (input.selectionStart || input.selectionStart == 0) {
    // Standard
    CursorPos = input.selectionStart;
  } else if (document.selection) {
    // Legacy IE
    var Sel = document.selection.createRange();
    Sel.moveStart("character", -input.value.length);
    CursorPos = Sel.text.length;
  }
  return CursorPos;
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}
