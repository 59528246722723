import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import tokenManager from "@/shared/securityTokenManager.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getUserByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIUser).info("getUserByReference...");

      if (reference == null) {
        reference = await tokenManager.getCurrentUserContextAsync().Reference;
      }

      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger.get(loggingSource.UIUser).time("getUserByReference call");
      const response = await api.client
        .get(`user/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getUserByReference call");
      const user = response.data;

      logger
        .get(loggingSource.UIUser)
        .debug("getUserByReference user: %s", user);

      return user;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getUserByReference call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getUserByReference");
    }
  },

  async getUserDropdown(workgroupsReference = null, searcherUserLevel = null) {
    try {
      logger.get(loggingSource.UIUser).info("getUserDropdown...");

      logger
        .get(loggingSource.UIUser)
        .debug(
          "Parameters =>workgroupsReference: %s, searching user level: %s",
          workgroupsReference,
          searcherUserLevel
        );

      logger.get(loggingSource.UIUser).time("getUserDropdown call");
      const response = await api.client
        .get("user/Dropdown", {
          params: {
            WorkgroupsReference: workgroupsReference,
            searcherUserLevel: searcherUserLevel,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getUserDropdown call");
      const users = response.data;
      logger.get(loggingSource.UIUser).debug("getUserDropdown user: %s", users);

      return users;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getUserDropdown call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getUserDropdown");
    }
  },

  //Get brief product list for product search screen.
  async getUsersList(
    consultancyRolesIds = null,
    includeInactiveUsers = true,
    includeUsersInWorkgroups = true,
    includeUsersNotInWorkgroups = true,
    workgroupsReference = null,
    searchName = null
  ) {
    try {
      logger.get(loggingSource.UIUser).info("getUsersList...");

      logger
        .get(loggingSource.UIUser)
        .debug(
          "Parameters => consultancyRoles: %s, includeInactiveUsers: %s,includeUsersInWorkgroups: %s,includeUsersNotInWorkgroups: %s, workgroupsReference: %s, searchName: %s",
          consultancyRolesIds,
          includeInactiveUsers,
          includeUsersInWorkgroups,
          includeUsersNotInWorkgroups,
          workgroupsReference,
          searchName
        );

      logger.get(loggingSource.UIUser).time("getUsersList call");
      const response = await api.client
        .get("user/UserList", {
          params: {
            ConsultancyRoleIds: consultancyRolesIds,
            IncludeInactiveUsers: includeInactiveUsers,
            IncludeUsersInWorkgroups: includeUsersInWorkgroups,
            IncludeUsersNotInWorkgroups: includeUsersNotInWorkgroups,
            WorkgroupsReference: workgroupsReference,
            SearchName: searchName,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getUsersList call");
      const users = response.data;
      logger.get(loggingSource.UIUser).debug("getUsersList users: %s", users);

      return users;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getUsersList call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getUsersList");
    }
  },

  async getUsers(
    consultancyRolesIds = null,
    includeInactiveUsers = true,
    includeUsersInWorkgroups = true,
    includeUsersNotInWorkgroups = true,
    workgroupsReference = null
  ) {
    try {
      logger.get(loggingSource.UIUser).info("getUsers...");

      logger
        .get(loggingSource.UIUser)
        .debug(
          "Parameters => consultancyRoles: %s, includeInactiveUsers: %s,includeUsersInWorkgroups: %s,includeUsersNotInWorkgroups: %s, workgroupsReference: %s",
          consultancyRolesIds,
          includeInactiveUsers,
          includeUsersInWorkgroups,
          includeUsersNotInWorkgroups,
          workgroupsReference
        );

      logger.get(loggingSource.UIUser).time("getUsers call");
      const response = await api.client
        .get("user", {
          params: {
            ConsultancyRoleIds: consultancyRolesIds,
            IncludeInactiveUsers: includeInactiveUsers,
            IncludeUsersInWorkgroups: includeUsersInWorkgroups,
            IncludeUsersNotInWorkgroups: includeUsersNotInWorkgroups,
            WorkgroupsReference: workgroupsReference,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getUsers call");
      const users = response.data;
      logger.get(loggingSource.UIUser).debug("getUsers user: %s", users);

      return users;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getUsers call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getUsers");
    }
  },

  async postUser(userPayload) {
    try {
      logger.get(loggingSource.UIUser).info("postUser...");
      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => userPayload: %s", userPayload);

      logger.get(loggingSource.UIUser).time("postUser call");
      const response = await api.client
        .post("user", userPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("postUser call");
      const user = response.data;
      logger.get(loggingSource.UIUser).debug("postUser user: %s", user);

      return user;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("postUser call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...postUser");
    }
  },

  async putUser(userPayload) {
    try {
      logger.get(loggingSource.UIUser).info("putUser...");
      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => userPayload: %s", userPayload);

      logger.get(loggingSource.UIUser).time("putUser call");
      const response = await api.client
        .put("user", userPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("putUser call");
      const user = response.data;

      logger.get(loggingSource.UIUser).debug("putUser user: %s", user);

      return user;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("putUser call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...putUser");
    }
  },

  async deleteUser(userPayload) {
    try {
      logger.get(loggingSource.UIUser).info("deleteUser...");

      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => userPayload: %s", userPayload);

      logger.get(loggingSource.UIUser).time("deleteUser call");
      await api.client
        .delete(
          `user/${userPayload.Reference}?concurrencyRV=${encodeURIComponent(
            userPayload.ConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("deleteUser call");
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("deleteUser call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...deleteUser");
    }
  },
};
