var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.value.ClientType == "CT_I"
      ? _c(
          "h5",
          [
            _c("mdb-icon", {
              staticClass: "icon-in-views",
              attrs: { fas: "", icon: "user", size: "1x", color: "primary" },
            }),
            _vm._v(
              _vm._s(_vm.value.GivenNames) +
                " " +
                _vm._s(_vm.value.Surname) +
                " "
            ),
            _c("span", { staticStyle: { "font-size": "75%" } }, [
              _c("i", [_vm._v("(" + _vm._s(_vm.value.Reference) + ")")]),
            ]),
          ],
          1
        )
      : _c(
          "h5",
          [
            _c("mdb-icon", {
              staticClass: "icon-in-views",
              attrs: {
                fas: "",
                icon: _vm.value.ClientType == "CT_O" ? "building" : "school",
                size: "1x",
                color: "primary",
              },
            }),
            _vm._v(_vm._s(_vm.value.Name) + " "),
            _c("span", { staticStyle: { "font-size": "75%" } }, [
              _c("i", [_vm._v("(" + _vm._s(_vm.value.Reference) + ")")]),
            ]),
          ],
          1
        ),
    _c(
      "div",
      [
        _c("mdb-icon", {
          staticClass: "icon-in-views",
          attrs: { icon: "hashtag", color: "primary", size: "1x" },
        }),
        _vm._v(" ABN: " + _vm._s(_vm.value.ABN) + " "),
      ],
      1
    ),
    _vm.physicalAddress.Street.length +
      _vm.physicalAddress.Locality.length +
      (_vm.physicalAddress.Subdivision != null
        ? _vm.physicalAddress.Subdivision.length
        : 0) +
      _vm.physicalAddress.Postcode.length >
    0
      ? _c(
          "div",
          [
            _c("mdb-icon", {
              staticClass: "icon-in-views",
              attrs: { icon: "map-marker-alt", color: "primary", size: "1x" },
            }),
            _vm.physicalAddress.Street.length > 0
              ? [_c("span", [_vm._v(_vm._s(_vm.physicalAddress.Street) + ",")])]
              : _vm._e(),
            _vm.physicalAddress.Locality.length > 0
              ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Locality) + " ")]
              : _vm._e(),
            _vm.physicalAddress.Subdivision != null &&
            _vm.physicalAddress.Subdivision.length > 0
              ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Subdivision) + " ")]
              : _vm._e(),
            _vm.physicalAddress.Postcode.length > 0
              ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Postcode) + " ")]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.postalAddress.Street.length +
      _vm.postalAddress.Locality.length +
      (_vm.postalAddress.Subdivision != null
        ? _vm.postalAddress.Subdivision.length
        : 0) +
      _vm.postalAddress.Postcode.length >
    0
      ? _c(
          "div",
          [
            _c("mdb-icon", {
              staticClass: "icon-in-views",
              attrs: { icon: "envelope", color: "primary", size: "1x" },
            }),
            _vm.postalAddress.Street.length > 0
              ? [_c("span", [_vm._v(_vm._s(_vm.postalAddress.Street) + ",")])]
              : _vm._e(),
            _vm.postalAddress.Locality.length > 0
              ? [_vm._v(" " + _vm._s(_vm.postalAddress.Locality) + " ")]
              : _vm._e(),
            _vm.postalAddress.Subdivision != null &&
            _vm.postalAddress.Subdivision.length > 0
              ? [_vm._v(" " + _vm._s(_vm.postalAddress.Subdivision) + " ")]
              : _vm._e(),
            _vm.postalAddress.Postcode.length > 0
              ? [_vm._v(" " + _vm._s(_vm.postalAddress.Postcode) + " ")]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.value.Phone.length > 0
      ? _c("div", [
          _c(
            "span",
            { staticClass: "mr-3" },
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { icon: "phone", color: "primary", size: "1x" },
              }),
              _c(
                "awgt-link-text",
                { attrs: { href: "tel:" + _vm.value.Phone } },
                [_vm._v(_vm._s(_vm.formatPhoneNumber(_vm.value.Phone)))]
              ),
            ],
            1
          ),
          _vm.value.EmailAddress.length > 0
            ? _c(
                "span",
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: { icon: "at", color: "primary", size: "1x" },
                  }),
                  _c(
                    "awgt-link-text",
                    { attrs: { href: "mailto:" + _vm.value.EmailAddress } },
                    [_vm._v(_vm._s(_vm.value.EmailAddress))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }