var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
      },
      [
        _c("mdb-icon", {
          staticClass: "mx-2 mb-2",
          attrs: { icon: "clipboard", far: "", color: "primary", size: "2x" },
        }),
        _c("div", { staticClass: "ml-2" }, [_vm._v("Files")]),
      ],
      1
    ),
    _c(
      "div",
      _vm._l(_vm.fileInfo, function (file, index) {
        return _c(
          "div",
          { key: index, staticClass: "mx-2" },
          [
            file.RecordStatus != "Deleted"
              ? [
                  file.RecordStatus == "Inserted"
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(file.Name)),
                      ])
                    : _c(
                        "span",
                        {
                          staticClass: "file-link",
                          on: {
                            click: function ($event) {
                              return _vm.onDownload(file)
                            },
                          },
                        },
                        [_vm._v(_vm._s(file.Name))]
                      ),
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.onDeleteFile(index)
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-2 mb-2",
                        attrs: {
                          icon: "times",
                          far: "",
                          color: "black",
                          size: "1x",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "plus-icon float-right",
        staticStyle: { "margin-left": "auto" },
      },
      [
        _c(
          "label",
          { attrs: { for: "file-input" } },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-2",
              staticStyle: { cursor: "pointer" },
              attrs: { icon: "plus", far: "", color: "primary", size: "7x" },
            }),
          ],
          1
        ),
        _c("input", {
          attrs: { id: "file-input", type: "file", multiple: "" },
          on: { change: (event) => _vm.onFilesInput(event) },
        }),
      ]
    ),
    _c("div", { staticStyle: { clear: "both" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }