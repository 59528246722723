<template>
  <div class="mx-3">
    <h1 class="text-center mb-3">Edit Order Product Line</h1>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        :icon="getProductIcon(product.ProductType, product.ProductSubType)"
        size="2x"
        class="mr-2"
        color="primary"
        style="width: 36px"
      />
      <h5 class="my-0">
        {{ orderProduct.ProviderSupplierName }} - {{ orderProduct.Name }} -
        {{ currentProductLineChronology.Name }}
      </h5>
    </div>

    <div v-html="currentProductLineChronology.Description"></div>

    <div class="mt-3">
      <h5>Pricing Scales</h5>
      <div v-if="currentProductLineChronology.isIndicativePricingInd == true">
        <i
          >Prices displayed are indicative for
          {{ formatDate(orderProduct.PricedForDt) }}.</i
        >
      </div>
      <div v-else>
        <i
          >Prices displayed are actual expected prices for
          {{ formatDate(orderProduct.PricedForDt) }}.</i
        >
      </div>
      <table class="pricing-table">
        <tr class="pricing-table">
          <td
            class="pricing-table text-center"
            colspan="2"
            v-for="(
              pst, index
            ) of currentProductLineChronology.PricingScaleTypes"
            :key="index"
          >
            {{
              getPluralQuantityUnit(
                currentProductLineChronology,
                pst.QuantityUnitCd
              )
            }}
          </td>
          <td colspan="3" class="pricing-table text-center">Pricing</td>
        </tr>
        <tr class="pricing-table">
          <template
            v-for="(ps, index) of currentProductLineChronology
              .ProductLinePrices[0].PricingScales"
          >
            <td class="pricing-table" :key="'min' + index">Min</td>
            <td class="pricing-table" :key="'max' + index">Max</td>
          </template>
          <td class="pricing-table">ExGST</td>
          <td class="pricing-table">GST</td>
          <td class="pricing-table">Price</td>
        </tr>

        <tr
          v-for="(plp, index) of currentProductLineChronology.ProductLinePrices"
          :key="index"
        >
          <template v-for="(ps, index2) of plp.PricingScales">
            <td class="pricing-table" :key="'min' + index2">
              {{ ps.MinQuantity }}
            </td>
            <td class="pricing-table" :key="'max' + index2">
              {{ ps.MaxQuantity }}
            </td>
          </template>
          <td class="pricing-table">
            <span v-html="formatPricingString(plp.ExGSTUnitAmt)"></span>
          </td>
          <td class="pricing-table">
            <span v-html="formatPricingString(plp.GSTUnitAmt)"></span>
          </td>
          <td class="pricing-table">
            <span
              v-html="formatPricingString(plp.GSTUnitAmt + plp.ExGSTUnitAmt)"
            ></span>
          </td>
        </tr>
      </table>
    </div>

    <div class="mt-3">
      <h5>Market Categories</h5>
      This product line will be distributed across the following market
      categories:
      <div
        v-if="currentProductLineChronology.MarketCategories.length == 0"
        class="text-center"
      >
        <i>This product line has no market categories defined.</i>
      </div>
      <div
        v-for="(
          marketCategory, index
        ) in workingOrderProductLine.OrderProductLineMarketCategories"
        :key="index"
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        {{ marketCategory.Name }}
        <awgt-input
          v-model.number="marketCategory.ConsumerNum"
          counter
          :counterMaxValue="5"
          maxlength="5"
          label="Quantity"
          bg
          type="text"
          class="ml-2"
          style="width: 80px"
          v-numeric="{
            precision: 3,
            scale: 0,
            posOnly: true,
            trailingZeroScale: 0,
          }"
        />
      </div>
    </div>

    <div class="mt-3">
      <h5>This Purchase</h5>
      <div>
        Minimum Total Price:
        <span
          v-html="
            formatPricingString(currentProductLineChronology.MinTotalPriceAmt)
          "
        ></span>
      </div>

      <div>
        {{ getProductsSoldByStatement() }}
      </div>
      <table class="pricing-table">
        <tr
          class="pricing-table"
          colspan="2"
          v-for="(ps, index) of currentProductLineChronology
            .ProductLinePrices[0].PricingScales"
          :key="index"
        >
          <td class="pricing-table">
            # of
            {{
              getPluralQuantityUnit(
                currentProductLineChronology,
                ps.QuantityUnitCd
              )
            }}
          </td>
          <td colspan="3" class="pricing-table py-0">
            <awgt-input
              v-model.number="
                workingOrderProductLine.OrderProductLineQuantities[index]
                  .Quantity
              "
              maxlength="4"
              label="#"
              bg
              class="flex-grow-1 ml-2"
              type="text"
              v-numeric="{
                precision: 4,
                scale: 0,
                posOnly: true,
                allowEmptyString: true,
              }"
            ></awgt-input>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">Ex-GST Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.exGST)"></span>
            </template>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">GST Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.gst)"></span>
            </template>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">Total Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.total)"></span>
              <tdoxTooltip
                v-if="CalculatedPrice.comment.length > 0"
                :detail="CalculatedPrice.comment"
              />
            </template>
          </td>
        </tr>
      </table>
    </div>

    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3 mb-n2"
    >
      <h5>Price Adjustments</h5>
      <awgt-std-button
        type="button"
        class="command-button ml-auto"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onOpenPriceAdjustmentsDialog"
      >
        <mdb-icon icon="dollar" class="mr-1" />Adjust Price
      </awgt-std-button>
    </div>
    <div>
      The following price adjustments will be applied to this product line:
    </div>
    <div
      v-if="
        workingOrderProductLine.OrderProductLinePriceAdjustments.length == 0
      "
      class="text-center"
    >
      <i>Price adjustments are yet to be applied to this product line.</i>
    </div>
    <div v-else>
      <table class="pricing-table">
        <tr class="pricing-table">
          <td class="pricing-table">Name</td>
          <td class="pricing-table">Adjustment Type</td>
          <td class="pricing-table">Change Type</td>
          <td class="pricing-table">Amount</td>
        </tr>
        <tr
          v-for="(pa, index) of $lodash.orderBy(
            workingOrderProductLine.OrderProductLinePriceAdjustments,
            'CalculationOrder'
          )"
          :key="index"
          class="pricing-table"
        >
          <td class="pricing-table">{{ pa.Name }}</td>
          <td class="pricing-table">
            {{ getPriceAdjustmentTypeNameByCode(pa.PriceAdjustmentTypeCd) }}
          </td>
          <td class="pricing-table">
            {{ getPriceChangeTypeNameByCode(pa.PriceChangeTypeCd) }}
          </td>
          <td class="pricing-table">
            {{
              pa.PriceChangeTypeCd == "PCT_Pe"
                ? pa.Amount + "%"
                : formatPricingString(pa.Amount)
            }}
          </td>
        </tr>
      </table>

      <div class="mt-2">
        Adjusted Pricing
        <table class="pricing-table">
          <tr class="pricing-table">
            <td class="pricing-table">Ex-GST Price</td>
            <td class="pricing-table">
              <template v-if="CalculatedPrice == null"
                ><i style="color: red">Price not found</i></template
              >
              <template v-else>
                <span
                  v-html="formatPricingString(CalculatedPrice.adjExGST)"
                ></span>
              </template>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">GST Price</td>
            <td class="pricing-table">
              <template v-if="CalculatedPrice == null"
                ><i style="color: red">Price not found</i></template
              >
              <template v-else>
                <span
                  v-html="formatPricingString(CalculatedPrice.adjGST)"
                ></span>
              </template>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">Total Price</td>
            <td class="pricing-table">
              <template v-if="CalculatedPrice == null"
                ><i style="color: red">Price not found</i></template
              >
              <template v-else>
                <span
                  v-html="formatPricingString(CalculatedPrice.adjTotal)"
                ></span>
              </template>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="section-break" style="margin-bottom: 0.8rem"></div>

    <div class="float-right">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onOK"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />OK
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onCancel"
      >
        <mdb-icon icon="times" class="mr-1" />Cancel
      </awgt-std-button>
    </div>

    <mdb-modal
      size="lg"
      v-if="showPriceAdjustmentsDialog"
      :show="showPriceAdjustmentsDialog"
    >
      <price-adjustments-dialog
        v-if="showPriceAdjustmentsDialog == true"
        v-model="workingOrderProductLine.OrderProductLinePriceAdjustments"
        :allowedPriceAdjustments="
          currentProductLineChronology.CommercialProductLinePriceAdjustments
        "
        :keepDollarOnItemInd="PricingScalesIncludesItems"
        @close="onPriceAdjustmentsDialogClose"
        :visible="showPriceAdjustmentsDialog"
      />
    </mdb-modal>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
.md-form label {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.command-button {
  min-width: 100px;
  width: 100px;
}

@media screen and (max-width: 800px) {
  .command-button {
    min-width: 70px;
    width: 70px;
  }
}
</style>
<script>
import { mdbIcon, mdbModal } from "mdbvue";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtStdButton from "@/components/AWGT/AwgtStdButton";
import { sharedMethods } from "@/shared/shared";
import awgtInput from "@/components/AWGT/AwgtInput";
import priceAdjustmentsDialog from "./priceAdjustmentsDialog";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import { mapMutations } from "vuex/dist/vuex.common.js";

export default {
  components: {
    mdbIcon,
    awgtStdButton,
    awgtInput,
    mdbModal,
    priceAdjustmentsDialog,
    tdoxTooltip,
  },

  data() {
    return {
      workingOrderProductLine: null,
      currentProductLine: null,
      orderProductLineSnapshot: null,
      currentProductLineChronology: null,
      showPriceAdjustmentsDialog: false,
      independentTotalPrice: 0,
      orderProduct: {},
      activeOrderProductLine: {},
    };
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getOrderStatuses",
      "order/getEmptyOrder",
      "order/getEmptyOrderProduct",
      "getQuantityUnitNameByCode",
      "order/getEmptyOrderProductLineQuantity",
      "getPriceAdjustmentTypeNameByCode",
      "getPriceChangeTypeNameByCode",
      "order/getActiveOrderProductLine",
      "order/getActiveOrderProductLinePrice",
    ]),

    CalculatedPrice: function () {
      this.$store.commit(
        "order/mutStoreOrderProductLine",
        {
          orderProductIdx: this.orderProduct.OrderProductIdx,
          orderProductLine: this.workingOrderProductLine,
        },
        { root: true }
      );

      return this.$lodash.cloneDeep(
        this.$store.getters["order/getActiveOrderProductLinePrice"](
          this.currentProductLineChronology,
          this.orderProductIdx,
          this.orderProductLineIdx
        )
      );
    },

    PricingScalesIncludesItems: function () {
      return (
        this.currentProductLineChronology.ProductLinePrices[0].PricingScales.findIndex(
          (ps) => ps.QuantityUnitCd == "QU_I"
        ) != -1
      );
    },
  },

  props: {
    orderProductIdx: {
      type: Number,
    },

    orderProductLineIdx: {
      type: Number,
    },

    product: {
      type: Object,
    },

    booking: {
      type: Object,
    },
  },

  watch: {
    orderProductLineIdx: {
      deep: false,
      immediate: true,
      handler(to) {
        this.orderProduct = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProduct"](
            this.orderProductIdx
          )
        );
        //The original order product line is saved incase it needs
        //to be restored on Cancel.
        this.activeOrderProductLine = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProductLine"](
            this.orderProductIdx,
            to
          )
        );
        this.workingOrderProductLine = this.$lodash.cloneDeep(
          this.activeOrderProductLine
        );
        //Find the current product line chronology
        this.currentProductLine = this.product.CommercialProductLines.find(
          (pl) => pl.Reference == this.workingOrderProductLine.Reference
        );
        this.currentProductLineChronology =
          this.currentProductLine.Chronology.find(
            (c) =>
              new Date(c.ActiveFromDt) <=
                new Date(this.orderProduct.PricedForDt) &&
              (c.ActiveToDt == null ||
                new Date(c.ActiveToDt) >=
                  new Date(this.orderProduct.PricedForDt))
          );
        //Add Order Product Line Quantity records if they don't exist
        if (
          this.workingOrderProductLine.OrderProductLineQuantities.length == 0
        ) {
          for (let pst of this.currentProductLineChronology.PricingScaleTypes) {
            let newOrderProductLineQuantity = this.$lodash.cloneDeep(
              this.$store.getters["order/getEmptyOrderProductLineQuantity"]
            );
            newOrderProductLineQuantity.QuantityUnitCd = pst.QuantityUnitCd;
            this.workingOrderProductLine.OrderProductLineQuantities.push(
              newOrderProductLineQuantity
            );
          }
        }
        //Default any person quantities from the booking.
        for (let oplq of this.workingOrderProductLine
          .OrderProductLineQuantities) {
          if (
            oplq.QuantityUnitCd == "QU_P" &&
            (oplq.Quantity === undefined || oplq.Quantity == 0)
          )
            oplq.Quantity =
              this.booking.StudentsCount + this.booking.AdultsCount;
        }

        //Add Market Category records if they don't exist
        if (
          this.workingOrderProductLine.OrderProductLineMarketCategories
            .length == 0
        ) {
          for (let mc of this.currentProductLineChronology.MarketCategories) {
            if (mc.ConsumerNum === undefined) {
              if (mc.Name == "Adult") mc.ConsumerNum = this.booking.AdultsCount;
              if (mc.Name == "Student")
                mc.ConsumerNum = this.booking.StudentsCount;
              this.workingOrderProductLine.OrderProductLineMarketCategories.push(
                mc
              );
            }
          }
        }
      },
    },
  },

  methods: {
    ...sharedMethods,
    ...mapMutations([
      "order/mutStoreOrderProductLine",
      "order/mutDeleteOrderProductLine",
    ]),

    isFormDirty() {
      return true;
    },

    getProductsSoldByStatement() {
      let productsAreSoldBy = "";
      let pricingIsScaledBy = "";
      let statement = "";

      for (let pricingScaleType of this.currentProductLineChronology
        .PricingScaleTypes) {
        if (pricingScaleType.QuantityUnitPurposeCd == "QUP_P")
          //Priced Per
          productsAreSoldBy +=
            this.getPluralQuantityUnit(
              this.currentProductLineChronology,
              pricingScaleType.QuantityUnitCd
            ) + ", ";

        if (pricingScaleType.PricingScaleCd != "PS_U")
          //Unscaled
          pricingIsScaledBy +=
            this.getPluralQuantityUnit(
              this.currentProductLineChronology,
              pricingScaleType.QuantityUnitCd
            ) + ", ";
      }
      if (productsAreSoldBy.length >= 2) {
        productsAreSoldBy = productsAreSoldBy.substring(
          0,
          productsAreSoldBy.length - 2
        );
        statement =
          "Products are sold by the number of " + productsAreSoldBy + ". ";
      }
      if (pricingIsScaledBy.length >= 2) {
        pricingIsScaledBy = pricingIsScaledBy.substring(
          0,
          pricingIsScaledBy.length - 2
        );
        statement +=
          "Pricing is scaled by the number of " + pricingIsScaledBy + ".";
      }

      return statement;
    },

    onTotalPriceInput() {
      //if (price.PriceControlWithFocus == "TotalPrice") {
      switch (this.currentProductLineChronology.PriceTypeCd) {
        case "PrT_I":
          // If price is given Incl GST then need to calculate the price, assume GST is 10%
          this.workingOrderProductLine.ExGSTTotalAmt = Number.parseFloat(
            ((this.independentTotalPrice / 11) * 10).toFixed(2)
          );
          this.workingOrderProductLine.GSTTotalAmt = Number.parseFloat(
            (this.independentTotalPrice / 11).toFixed(2)
          );
          break;
        case "PrT_E":
          // If price is Ex GST then you won't be filling out this field it is calculated
          break;
        case "PrT_F":
          // If GST Free, then ExGSTUnitAmt = price and GST = zero.
          this.workingOrderProductLine.ExGSTTotalAmt =
            this.independentTotalPrice;
          this.workingOrderProductLine.GSTTotalAmt = 0;
          break;
      }
      //}
    },

    onGSTPriceInput() {
      //if (price.PriceControlWithFocus == "GSTPrice") {
      switch (this.currentProductLineChronology.PriceTypeCd) {
        case "PrT_I":
          // If price is Inc GST then if the GST Amount changes the ExGST will be
          // GSTIncPrice - GSTUnitAmt
          this.workingOrderProductLine.ExGSTTotalAmt =
            this.independentTotalPrice -
            this.workingOrderProductLine.GSTTotalAmt;
          break;
        case "PrT_E":
          // If price is given Ex GST then need to calculate the GST... assume 10%
          this.independentTotalPrice =
            this.workingOrderProductLine.ExGSTTotalAmt +
            this.workingOrderProductLine.GSTTotalAmt;
          break;
        case "PrT_F":
          // If GST Free you don't change the GST Amount
          break;
      }
      //}
    },

    onExGSTPriceInput() {
      //if (price.PriceControlWithFocus == "ExGSTPrice") {
      switch (this.currentProductLineChronology.PriceTypeCd) {
        case "PrT_I":
          // If price is Inc GST then if the Ex GST Amount changes the GST will be
          // GSTIncPrice - ExGSTUnitAmt
          this.workingOrderProductLine.GSTTotalAmt =
            this.independentTotalPrice -
            this.workingOrderProductLine.ExGSTTotalAmt;
          break;
        case "PrT_E":
          // If price is given Ex GST then need to calculate the GST... assume 10%
          this.workingOrderProductLine.GSTTotalAmt = Number(
            this.workingOrderProductLine.ExGSTTotalAmt * 0.1
          );
          this.independentTotalPrice = Number(
            this.workingOrderProductLine.ExGSTTotalAmt * 1.1
          );
          break;
        case "PrT_F":
          // If GST Free you don't change the ExGST Amount
          break;
      }
      //}
    },

    onOpenPriceAdjustmentsDialog() {
      this.showPriceAdjustmentsDialog = true;
    },

    onPriceAdjustmentsDialogClose() {
      this.showPriceAdjustmentsDialog = false;
    },

    onOK() {
      //Update the order product line to the VueX store.
      this.$store.commit(
        "order/mutStoreOrderProductLine",
        {
          orderProductIdx: this.orderProduct.OrderProductIdx,
          orderProductLine: this.workingOrderProductLine,
        },
        { root: true }
      );
      this.$emit("close");
    },

    onCancel() {
      //If the working order product line has a RecordStatus of Inserted
      //then a order product line has been added and needs to be
      //removed. Otherwise replace the WorkingOrderProductLine
      //with the activeOrderProductLine it was cloned from.

      if (this.workingOrderProductLine.RecordStatus == "Inserted") {
        this.$store.commit(
          "order/mutDeleteOrderProductLine",
          {
            orderProductIdx: this.orderProduct.OrderProductIdx,
            orderProductLineIdx: this.orderProductLineIdx,
          },
          { root: true }
        );
      } else {
        this.$store.commit(
          "order/mutStoreOrderProductLine",
          {
            orderProductIdx: this.orderProduct.OrderProductIdx,
            orderProductLine: this.activeOrderProductLine,
          },
          { root: true }
        );
      }

      this.$emit("close");
    },
  },
};
</script>
