var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c("div", { staticClass: "logon-form" }, [
      _vm._m(0),
      _vm.forgetPassword == false && _vm.forgetUsername == false
        ? _c(
            "div",
            { staticClass: "diolog_content" },
            [
              _c("div", { staticClass: "logon-form-header" }, [
                _vm._v("Log in to your account"),
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.doesReceiveUsername == true
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          top: "0px",
                          margin: "10px 0px",
                          width: "100%",
                          "z-index": "2000",
                          position: "sticky",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onPaneClose()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "savemessage-success" },
                          [
                            _c(
                              "span",
                              { staticClass: "float-right color-success" },
                              [
                                _c("mdb-icon", {
                                  staticClass: "p-2 mr-0",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    far: "",
                                    size: "1x",
                                    icon: "times-circle",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onPaneClose()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "15px",
                                    "font-size": "15px",
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "color-success",
                                    attrs: { icon: "check-circle" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `Your username is "${_vm.username}".`
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm.error.length > 0
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "red", "font-size": "13px" },
                      attrs: { id: "logon-error-msg" },
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username",
                  },
                ],
                staticClass: "logon-form-header-input",
                attrs: {
                  autocomplete: "off",
                  id: "Username",
                  type: "text",
                  name: "username",
                  placeholder: "Username",
                },
                domProps: { value: _vm.username },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return ((e) => _vm.OnSubmit(e, "log-on")).apply(
                      null,
                      arguments
                    )
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.username = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "logon-form-header-input",
                attrs: {
                  autocomplete: "off",
                  id: "Password",
                  type: "password",
                  name: "password",
                  placeholder: "Password",
                },
                domProps: { value: _vm.password },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return ((e) => _vm.OnSubmit(e, "log-on")).apply(
                      null,
                      arguments
                    )
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c(
                "awgt-std-button",
                {
                  staticClass: "logon-btn m-0",
                  attrs: { id: "login-submit-btn", type: "submit" },
                  on: { click: (e) => _vm.OnSubmit(e, "log-on") },
                },
                [_vm._v(" Log In ")]
              ),
              _c(
                "mdb-row",
                { staticClass: "mt-4 mx-auto" },
                [
                  _c(
                    "mdb-col",
                    {
                      staticClass: "text-center",
                      attrs: { col: "6", href: "./" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "#425664",
                            "text-decoration": "none",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickForgetPassword()
                            },
                          },
                        },
                        [_vm._v("Forgot password?")]
                      ),
                    ]
                  ),
                  _c(
                    "mdb-col",
                    {
                      staticClass: "text-center",
                      attrs: { col: "6", href: "./" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "#425664",
                            "text-decoration": "none",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickForgetUsername()
                            },
                          },
                        },
                        [_vm._v("Forgot username?")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "diolog_content" },
            [
              _vm.forgetPassword == true
                ? _c("div", { staticClass: "logon-form-header" }, [
                    _vm._v(" Reset your password "),
                  ])
                : _vm._e(),
              _vm.forgetUsername == true
                ? _c("div", { staticClass: "logon-form-header" }, [
                    _vm._v(" Retrieve your username "),
                  ])
                : _vm._e(),
              _vm.sent == true
                ? _c("span", { staticStyle: { color: "green" } }, [
                    _vm._v("✓ The email has been sent."),
                  ])
                : _vm._e(),
              _c("br"),
              _vm.sent == true
                ? _c("span", { staticStyle: { color: "green" } }, [
                    _vm._v(
                      " ✓ Do it again if you don't receive it in 5 minutes"
                    ),
                  ])
                : _c(
                    "span",
                    {
                      staticStyle: { color: "red", "font-size": "13px" },
                      attrs: { id: "logon-error-msg" },
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  ),
              _vm.forgetPassword == true
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.requestUsername,
                        expression: "requestUsername",
                      },
                    ],
                    staticClass: "logon-form-header-input",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      name: "Username",
                      placeholder: "Username",
                    },
                    domProps: { value: _vm.requestUsername },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.requestUsername = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _vm.forgetPassword == true || _vm.forgetUsername == true
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.requestEmail,
                        expression: "requestEmail",
                      },
                    ],
                    staticClass: "logon-form-header-input",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      name: "Email Address",
                      placeholder: "Email Address",
                    },
                    domProps: { value: _vm.requestEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.requestEmail = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "awgt-std-button",
                {
                  staticClass: "send-btn m-0",
                  attrs: { type: "submit", disabled: _vm.second > 0 },
                  on: { click: (e) => _vm.OnSubmit(e, "send-link") },
                },
                [_vm._v(" Continue ")]
              ),
              _c("div", { staticClass: "logon-link", attrs: { href: "./" } }, [
                _c(
                  "a",
                  {
                    staticStyle: {
                      color: "#668318",
                      "text-decoration": "none",
                    },
                    on: { click: _vm.onCancel },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "diolog_logo_container text-center" }, [
      _c("h2", { staticClass: "tourdox-font-dark" }, [_vm._v("tourdox")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }