var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.treeviewData, function (ti, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("asoftTreeviewItem", {
            attrs: {
              value: ti,
              childIndent: _vm.childIndent,
              selectableLeafNodes: _vm.selectableLeafNodes,
              singleClickExpand: _vm.singleClickExpand,
              childrenPropertyName: _vm.childrenPropertyName,
              slotNamePropertyName: _vm.slotNamePropertyName,
              maxDepth: _vm.maxDepth,
              currentDepth: _vm.currentDepth,
              isCollapsible: _vm.isCollapsible,
            },
            on: {
              "tree-item-click": _vm.onTreeItemClick,
              "tree-item-dbl-click": _vm.onTreeItemDblClick,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                  return {
                    key: slot,
                    fn: function (scope) {
                      return [_vm._t(slot, null, null, scope)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }