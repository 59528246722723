var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("Supplier Product Information"),
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            search: "",
            label: "Product Type",
            itemValueProperty: "Code",
            items: _vm.getProductTypes(false),
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.productType,
            callback: function ($$v) {
              _vm.productType = $$v
            },
            expression: "productType",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            search: "",
            label: "Region",
            itemValueProperty: "Code",
            items: _vm.getRegion,
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.region,
            callback: function ($$v) {
              _vm.region = $$v
            },
            expression: "region",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            search: "",
            label: "Supplier",
            itemValueProperty: "Code",
            items: _vm.supplierList,
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.supplier,
            callback: function ($$v) {
              _vm.supplier = $$v
            },
            expression: "supplier",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            search: "",
            label: "Products",
            itemValueProperty: "Code",
            items: _vm.productList,
            itemTextProperty: "Name",
            bg: "",
            multiple: "",
            selectAll: "",
          },
          on: { focus: _vm.onFocusProductDropdown },
          model: {
            value: _vm.products,
            callback: function ($$v) {
              _vm.products = $$v
            },
            expression: "products",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Year Groups",
            itemValueProperty: "Code",
            items: _vm.getYearGroups,
            itemTextProperty: "Name",
            bg: "",
            multiple: "",
            selectAll: "",
          },
          model: {
            value: _vm.yearGroups,
            callback: function ($$v) {
              _vm.yearGroups = $$v
            },
            expression: "yearGroups",
          },
        }),
        _c(
          "mdb-form-inline",
          { staticClass: "flex- mx-2 px-3", staticStyle: { height: "45px" } },
          [
            _c(
              "span",
              { staticClass: "mr-3", staticStyle: { width: "120px" } },
              [_vm._v("Audience")]
            ),
            _c("awgt-input", {
              staticClass: "mr-3",
              attrs: {
                id: "audience_client",
                type: "radio",
                name: "AudienceClient",
                label: "Client",
                radioValue: "Client",
              },
              model: {
                value: _vm.audience,
                callback: function ($$v) {
                  _vm.audience = $$v
                },
                expression: "audience",
              },
            }),
            _c("awgt-input", {
              staticClass: "mr-3",
              attrs: {
                id: "audience_internal",
                type: "radio",
                name: "AudienceInternal",
                label: "Internal",
                radioValue: "Internal",
              },
              model: {
                value: _vm.audience,
                callback: function ($$v) {
                  _vm.audience = $$v
                },
                expression: "audience",
              },
            }),
          ],
          1
        ),
        _vm.audience == "Internal"
          ? _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "90px" },
              attrs: {
                maxlength: "100",
                items: _vm.getYears(2017),
                itemValueProperty: "Code",
                itemTextProperty: "Name",
                label: "Year (pricing purposes)",
                bg: "",
                multiple: "",
                selectAll: "",
              },
              model: {
                value: _vm.year,
                callback: function ($$v) {
                  _vm.year = $$v
                },
                expression: "year",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
      },
      [
        _c("awgt-std-switch", {
          staticClass: "mx-2 my-2",
          attrs: {
            checked: "",
            offLabel: "Reseller Supplier",
            onLabel: "Provider Supplier",
          },
          on: {
            getValue: (value) => {
              _vm.supplierType = value
            },
          },
        }),
        _c(
          "mdb-form-inline",
          { staticClass: "d-flex justify-content-end flex-grow-1" },
          [
            Object.keys(_vm.reportData).length > 0
              ? _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2 float-right",
                    attrs: { type: "button" },
                    on: { click: _vm.onExport },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "file-pdf" },
                    }),
                    _vm._v("Export "),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onClearGenerateCriteria },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "eraser" },
                }),
                _vm._v("Clear "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onGenerate },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "search" },
                }),
                _vm._v("Generate "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    Object.keys(_vm.reportData).length === 0 && _vm.isGenerated
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", [_vm._v("No result found")]),
        ])
      : _vm._e(),
    _vm.isGenerated && Object.keys(_vm.reportData).length > 0
      ? _c("div", { staticClass: "mx-2", attrs: { id: "infoContent" } }, [
          _vm.audienceCache == "Client"
            ? _c(
                "div",
                [
                  _c("div", [
                    _c("img", {
                      staticClass: "mx-4",
                      attrs: {
                        src: require("../../assets/AWGT Logo.png"),
                        width: "300",
                      },
                    }),
                    _c("div", { staticStyle: { color: "#923f82" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            float: "right",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              `${
                                this.supplierType == true
                                  ? "Provider Supplier"
                                  : "ResellerSupplier"
                              }/Product Information Report`
                            )
                          ),
                        ]
                      ),
                      _c("br"),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-style": "italic",
                            float: "right",
                          },
                        },
                        [_vm._v(_vm._s(_vm.audience))]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c("div", [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Criteria"),
                        ]),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "mx-3" },
                        [
                          _vm.productTypeCache &&
                          _vm.productTypeCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getProductTypes(false)
                                          .find((pt) => {
                                            return (
                                              pt.Code == _vm.productTypeCache
                                            )
                                          }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.productTypeCache &&
                          _vm.productTypeCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getProductTypes(false)
                                          .find((pt) => {
                                            return (
                                              pt.Code == _vm.productTypeCache
                                            )
                                          }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.regionCache && _vm.regionCache.length > 0
                            ? [_c("li", [_vm._v(_vm._s(_vm.regionCache))])]
                            : _vm._e(),
                          _vm.suppliersCache && _vm.suppliersCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.supplierList.find((s) => {
                                          return s.Code == _vm.suppliersCache
                                        }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.yearGroupsCache && _vm.yearGroupsCache.length > 0
                            ? [
                                _vm.yearGroupsCache.length ==
                                _vm.getYearGroups.length
                                  ? _c("li", [_vm._v(" All Year Groups ")])
                                  : _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.yearGroupsCache.join()) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          _c("li", [_vm._v(_vm._s(_vm.audienceCache))]),
                          _vm.audienceCache == "Client" &&
                          _vm.yearCache &&
                          _vm.yearCache.length > 0
                            ? [_c("li", [_vm._v(_vm._s(_vm.yearCache))])]
                            : _vm._e(),
                          _vm.productsCache.length == _vm.productList.length
                            ? [_c("li", [_vm._v("All products")])]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.productsCache.length > 0 &&
                      _vm.productsCache.length != _vm.productList.length
                        ? [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "product-name-search-criteria",
                                  },
                                  [
                                    _vm._l(
                                      _vm.getSelectProductNameSet(
                                        _vm.productsCache
                                      ),
                                      function (subArr, index) {
                                        return [
                                          _c(
                                            "tr",
                                            { key: index },
                                            [
                                              _vm._l(
                                                subArr,
                                                function (item, indexItem) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      { key: indexItem },
                                                      [_vm._v(_vm._s(item))]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("supplier-product-report-view-client", {
                    model: {
                      value: _vm.reportData,
                      callback: function ($$v) {
                        _vm.reportData = $$v
                      },
                      expression: "reportData",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.audienceCache == "Internal"
            ? _c(
                "div",
                [
                  _c("div", [
                    _c("span", { staticStyle: { "font-size": "25px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${
                              this.supplierType == true
                                ? "Provider Supplier"
                                : "ResellerSupplier"
                            }/Product Information Report - Internal`
                          )
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c("div", [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Criteria"),
                        ]),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "mx-3" },
                        [
                          _vm.productTypeCache &&
                          _vm.productTypeCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getProductTypes(false)
                                          .find((pt) => {
                                            return (
                                              pt.Code == _vm.productTypeCache
                                            )
                                          }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.productTypeCache &&
                          _vm.productTypeCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getProductTypes(false)
                                          .find((pt) => {
                                            return (
                                              pt.Code == _vm.productTypeCache
                                            )
                                          }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.regionCache && _vm.regionCache.length > 0
                            ? [_c("li", [_vm._v(_vm._s(_vm.regionCache))])]
                            : _vm._e(),
                          _vm.suppliersCache && _vm.suppliersCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.supplierList.find((s) => {
                                          return s.Code == _vm.suppliersCache
                                        }).Name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.yearGroupsCache && _vm.yearGroupsCache.length > 0
                            ? [
                                _vm.yearGroupsCache.length ==
                                _vm.getYearGroups.length
                                  ? _c("li", [_vm._v(" All Year Groups ")])
                                  : _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.yearGroupsCache.join()) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          _c("li", [_vm._v(_vm._s(_vm.audienceCache))]),
                          _vm.productsCache.length == _vm.productList.length
                            ? [_c("li", [_vm._v("All products")])]
                            : _vm._e(),
                          _vm.audienceCache == "Internal" &&
                          _vm.yearCache &&
                          _vm.yearCache.length > 0
                            ? [
                                _c("li", [
                                  _vm._v(_vm._s(_vm.yearCache.join(", "))),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.productsCache.length > 0 &&
                      _vm.productsCache.length != _vm.productList.length
                        ? [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "product-name-search-criteria",
                                  },
                                  [
                                    _vm._l(
                                      _vm.getSelectProductNameSet(
                                        _vm.productsCache
                                      ),
                                      function (subArr, index) {
                                        return [
                                          _c(
                                            "tr",
                                            { key: index },
                                            [
                                              _vm._l(
                                                subArr,
                                                function (item, indexItem) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      { key: indexItem },
                                                      [_vm._v(_vm._s(item))]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("supplier-product-report-view-internal", {
                    attrs: { supplierType: _vm.supplierTypeCache },
                    model: {
                      value: _vm.reportData,
                      callback: function ($$v) {
                        _vm.reportData = $$v
                      },
                      expression: "reportData",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }