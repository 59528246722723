<template>
  <mdb-modal class="p-0" :show="showInd">
    <div :class="headerClasses">
      <slot name="header" :onClick="this.onClick"></slot>
    </div>
    <div :class="bodyClasses">
      <slot name="body" :onClick="this.onClick"></slot>
    </div>
    <div :class="footerClasses">
      <slot name="footer" :onClick="this.onClick"></slot>
    </div>
  </mdb-modal>
</template>

<script>
import { mdbModal } from "mdbvue";

export default {
  components: {
    mdbModal,
  },
  data() {
    return {
      showInd: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    headerClasses: {
      type: String,
      default: "",
    },
    bodyClasses: {
      type: String,
      default: "",
    },
    footerClasses: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(to, from) {
      if (to != from) {
        this.showInd = to;
      }
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
      this.$emit("input", false); //Close the form
    },
  },
};
</script>
