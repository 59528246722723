<template>
  <mdb-container fluid>
    <h1 class="text-center" style="margin-bottom: 50px">Manage Workgroups</h1>
    <div class="float-right mb-2">
      <awgt-std-button
        v-if="checkPermission('CP_WgC')"
        id="add-workgroup"
        type="button"
        title="Add"
        class="command-button mx-2"
        v-on:click="onAddWorkgroup"
      >
        <mdb-icon class="mr-1" icon="user-plus" />Add
      </awgt-std-button>
    </div>
    <div style="clear: both"></div>
    <div v-if="workgroups.length == 0" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-else
      v-for="(workgroup, index) in workgroups"
      :key="index"
      class="mb-3 mx-2"
    >
      <mdb-card-header
        @click.native="onToggleWorkGroupDetailExpansion(index)"
        tag="button"
      >
        <span class="float-right">
          <mdb-icon
            v-if="workgroupExpansion[index] == true"
            icon="angle-up"
            size="2x"
            class="pt-2"
            color="primary"
          />
          <!-- comment -->
          <mdb-icon
            v-else
            icon="angle-down"
            size="2x"
            class="pt-2"
            color="primary"
          />
        </span>
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <h4 class="mb-1" :id="workgroup.Name">
            <mdb-icon icon="users" color="primary" class="mr-2" size="1x" />{{
              workgroup.Name
            }}
          </h4>
        </div>
      </mdb-card-header>

      <expansion-region :toggle="workgroupExpansion[index]">
        <mdb-card-body>
          <div>
            <asoftTreeview
              :value="workgroup.TreeData"
              childIndent="20px"
              childrenPropertyName="Children"
              :isCollapsible="false"
              class="mx-2"
            >
              <template v-slot:default="item">
                <span :class="[{ selecteditem: item.selected == true }]">
                  <i class="fas fa-male"></i>
                  {{ item.Text }}</span
                >
              </template>
            </asoftTreeview>
          </div>
          <div class="my-2 float-right">
            <awgt-std-button
              v-if="checkPermission('CP_WgU')"
              type="button"
              title="Edit"
              class="command-button mx-2"
              v-on:click="
                onEditWorkgroup(workgroup) &&
                  checkPermissionForUpdate(workgroup)
              "
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_WgD')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onDeleteWorkgroup(workgroup)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <yes-or-no-prompt ref="handleWorkgroupPrompt"> </yes-or-no-prompt>
    <ok-prompt ref="alertPrompt"></ok-prompt>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import ExpansionRegion from "@/components/ExpansionRegion";
import workgroupApi from "@/api/workgroupApi.js";
import asoftTreeview from "@/components/AtomSoftware/asoftTreeview";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import okPrompt from "@/components/OkPrompt";
import { sharedMethods } from "@/shared/shared";
import { mapGetters } from "vuex";
import tokenManager from "@/shared/securityTokenManager.js";
import userApi from "@/api/userApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

import {
  mdbContainer,
  mdbCardHeader,
  mdbCard,
  mdbIcon,
  mdbCardBody,
} from "mdbvue";
export default {
  data() {
    return {
      workgroups: [],
      workgroupExpansion: [],
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForWorkgroupUpdate: null,
    };
  },
  components: {
    mdbContainer,
    mdbCardHeader,
    mdbCard,
    mdbIcon,
    mdbCardBody,
    ExpansionRegion,
    AwgtStdButton,
    asoftTreeview,
    yesOrNoPrompt,
    okPrompt,
  },

  computed: {
    ...mapGetters(["checkPermission"]),

    checkPermissionForUpdate() {
      return function (workgroup) {
        if (!this.currentUserPermissionScopeForWorkgroupUpdate) return false;

        if (this.currentUserPermissionScopeForWorkgroupUpdate == "CPS_AA")
          return true;

        if (this.currentUserPermissionScopeForWorkgroupUpdate == "CPS_Me") {
          if (this.workgroupsOfCurrentUser.indexOf(workgroup.Reference) == -1)
            return false;
        }

        return true;
      };
    },
  },

  methods: {
    onToggleWorkGroupDetailExpansion(index) {
      this.$set(
        this.workgroupExpansion,
        index,
        !this.workgroupExpansion[index]
      );
    },

    showConsultants(value) {
      if (value.ConsultantsInGroup != undefined) {
        if (value.ConsultantsInGroup.length == 0) {
          return "No consultants in this group";
        } else {
          var consultantsList = value.ConsultantsInGroup.map((item) => {
            return item.FirstName + " " + item.Surname;
          });
          var string = "";
          for (var i = 0; i < consultantsList.length; i++) {
            string = string + consultantsList[i] + " ";
          }
          return string;
        }
      }
    },

    onAddWorkgroup() {
      this.$router.push({ path: "/admin/workgroup/add" });
    },

    onEditWorkgroup(workgroup) {
      this.$router.push({
        path: `/admin/workgroup/${workgroup.Reference}/edit`,
      });
    },

    onDeleteWorkgroup(workgroup) {
      this.$refs.handleWorkgroupPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure you wish to delete this workgroup?"
        )
        .then((option) => {
          if (option == true) {
            if (workgroup.TreeData.length > 0) {
              this.$refs.alertPrompt.onFirePrompt(
                "Warning",
                "This workgroup is associated with users, so it cannot be deleted."
              );
            } else {
              this.deleteWorkgroup(workgroup);
            }
          }
        });
    },

    async loadWorkgroups() {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForWorkgroupUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_WgU";
        })?.PermissionScope;

      this.workgroups = await workgroupApi.getWorkgroups(true);
    },

    async deleteWorkgroup(workgroup) {
      try {
        await workgroupApi.deleteWorkgroup(workgroup);
        this.workgroups.splice(
          this.workgroups.findIndex((w) => w.Reference == workgroup.Reference),
          1
        );
      } catch (err) {
        this.$log.info(err);
        throw new Error(err);
      }
    },

    async getWorkgroup(reference) {
      let successInd = true;
      let errorMessage = "";

      this.workgroups = [];

      await workgroupApi.getWorkgroupByReference(reference, true).then(
        (workgroup) => {
          this.workgroups.push(workgroup);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });

      this.isSearched = true;
    },
  },

  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.loadWorkgroups);
    if (
      Object.keys(this.$route.params).length > 0 &&
      this.$route.params.isBarSearch
    ) {
      this.$bus.$off("search-bar-search");
      this.$bus.$on("search-bar-search", (searchString) => {
        this.getWorkgroup(searchString);
      });
    } else this.loadWorkgroups();
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>

<style scoped></style>
