var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mdb-icon", {
        staticClass: "grey-text ml-2 mr-1",
        attrs: { icon: "dollar-sign", color: "primary", size: "2x" },
      }),
      _vm.PriceTypeCd != "PrT_F"
        ? _c("awgt-input", {
            directives: [
              {
                name: "numeric",
                rawName: "v-numeric",
                value: {
                  precision: 6,
                  scale: 2,
                  posOnly: false,
                  trailingZeroScale: 2,
                },
                expression:
                  "{\n      precision: 6,\n      scale: 2,\n      posOnly: false,\n      trailingZeroScale: 2,\n    }",
              },
            ],
            staticClass: "ml-2",
            staticStyle: { width: "140px" },
            attrs: {
              label: "Incl-GST Price",
              maxlength: "7",
              bg: "",
              type: "text",
            },
            on: {
              input: function ($event) {
                return _vm.onTotalPriceInput()
              },
              focus: function ($event) {
                return _vm.onFocus("totalPrice")
              },
            },
            model: {
              value: _vm.totalPrice,
              callback: function ($$v) {
                _vm.totalPrice = _vm._n($$v)
              },
              expression: "totalPrice",
            },
          })
        : _vm._e(),
      _c("awgt-input", {
        directives: [
          {
            name: "numeric",
            rawName: "v-numeric",
            value: {
              precision: 6,
              scale: 2,
              posOnly: false,
              trailingZeroScale: 2,
            },
            expression:
              "{\n      precision: 6,\n      scale: 2,\n      posOnly: false,\n      trailingZeroScale: 2,\n    }",
          },
        ],
        staticClass: "ml-2",
        staticStyle: { width: "140px" },
        attrs: {
          label: _vm.PriceTypeCd == "PrT_F" ? "Price" : "Ex-GST Price",
          maxlength: "7",
          bg: "",
          type: "text",
        },
        on: {
          input: function ($event) {
            return _vm.onExGSTUnitAmtInput()
          },
          focus: function ($event) {
            return _vm.onFocus("ExGSTUnitAmt")
          },
        },
        model: {
          value: _vm.exGSTUnitAmt,
          callback: function ($$v) {
            _vm.exGSTUnitAmt = _vm._n($$v)
          },
          expression: "exGSTUnitAmt",
        },
      }),
      _vm.PriceTypeCd != "PrT_F"
        ? _c("awgt-input", {
            directives: [
              {
                name: "numeric",
                rawName: "v-numeric",
                value: {
                  precision: 6,
                  scale: 2,
                  posOnly: false,
                  trailingZeroScale: 2,
                },
                expression:
                  "{\n      precision: 6,\n      scale: 2,\n      posOnly: false,\n      trailingZeroScale: 2,\n    }",
              },
            ],
            staticClass: "ml-2",
            staticStyle: { width: "140px" },
            attrs: { label: "GST Price", maxlength: "7", bg: "", type: "text" },
            on: {
              input: function ($event) {
                return _vm.onGSTUnitAmtInput()
              },
              focus: function ($event) {
                return _vm.onFocus("GSTUnitAmt")
              },
            },
            model: {
              value: _vm.gSTUnitAmt,
              callback: function ($$v) {
                _vm.gSTUnitAmt = _vm._n($$v)
              },
              expression: "gSTUnitAmt",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }