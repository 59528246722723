var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "clockpicker-am-pm-block" }, [
    _c(
      "button",
      {
        class: `btn-floating btn-flat clockpicker-button am-button ${
          _vm.dayTime === "am" ? "active" : ""
        }`,
        attrs: { tabindex: 0, type: "button" },
        on: {
          click: function ($event) {
            return _vm.changeDayTime("am")
          },
        },
      },
      [_vm._v(" AM ")]
    ),
    _c(
      "button",
      {
        class: `btn-floating btn-flat clockpicker-button pm-button ${
          _vm.dayTime === "pm" ? "active" : ""
        }`,
        attrs: { tabindex: 0, type: "button" },
        on: {
          click: function ($event) {
            return _vm.changeDayTime("pm")
          },
        },
      },
      [_vm._v(" PM ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }