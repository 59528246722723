<template>
  <div id="main">
    <h2>Oops!The access is forbidden</h2>
    <p>Sorry, you don't have access for this page.</p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0px;
}

p {
  font-family: Lucida Sans Unicode;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
}
</style>
