import Logger from "js-logger";
import sprintf from "sprintf-js";

const { v1: uuidv1 } = require("uuid");
//A unique identifier for this session.
const uuid = uuidv1(); // ⇨ '2c5ea4c0-4067-11e9-8bad-9b1deb4d3b7d'

/*
 * Predefined logging levels.
Logger.TRACE = Logger.defineLogLevel(1, "TRACE");
Logger.DEBUG = Logger.defineLogLevel(2, "DEBUG");
Logger.INFO = Logger.defineLogLevel(3, "INFO");
Logger.TIME = Logger.defineLogLevel(4, "TIME");
Logger.WARN = Logger.defineLogLevel(5, "WARN");
Logger.ERROR = Logger.defineLogLevel(8, "ERROR");
Logger.OFF = Logger.defineLogLevel(99, "OFF");
 */

const LOGGING_Separator = " | ";

let consoleLoggingHandler = Logger.createDefaultHandler({
  formatter: function (messages, context) {
    for (let i = 0; i < messages.length; i++) {
      if (typeof messages[i] == "object") {
        messages[i] = JSON.stringify(messages[i]);
      }
    }

    let args = messages.splice(1);
    messages[0] = sprintf.vsprintf(messages[0], args);

    // prefix each log message with a timestamp.
    messages.unshift(LOGGING_Separator);
    messages.unshift(context.level.name);
    messages.unshift(LOGGING_Separator);
    messages.unshift(uuid);
    messages.unshift(LOGGING_Separator);
    messages.unshift(context.name);
    messages.unshift(LOGGING_Separator);
    messages.unshift(new Date().toUTCString());
  },
});

//let databaseLoggingHander = ....
//Pass: Log Message, context Level Name, UUID, Context.Name, Date/time
/*

Doco for js-logger: https://www.npmjs.com/package/js-logger



function databaseLoggingHandler(messages, context){

  System Monitor API Post call passing in Log Message, context Level Name, UUID, Context.Name, Date/time.
}


To a "SystemMonitor" webapi post call.
The data will be saved into tblSystemMonitorEntry database table.

Logger.setHandler(function (messages, context) {
    // Send messages to a custom logging endpoint for analysis.
    // TODO: Add some security? (nah, you worry too much! :P)
    jQuery.post('/logs', { message: messages[0], level: context.level });
});
*/

Logger.useDefaults();
Logger.setHandler(consoleLoggingHandler);
Logger.setLevel(Logger.DEBUG);
//Logger.setLevel(Logger.OFF);
//export default Logger;

//SMES = System Monitor Entry Source
//UI = User Interface
//VC = Vue Component
//Str = Store
export const loggingSource = {
  UIConfig: "SMES_UI_Cfg",
  UIBooking: "SMES_UI_Bok",
  UIClient: "SMES_UI_Cli",
  UIUser: "SMES_UI_Usr",
  UIWorkgroup: "SMES_UI_Wkg",
  UISupplier: "SMES_UI_Sup",
  UISystemIssue: "SMES_UI_SIs",
  UIItinerary: "SMES_UI_Itn",
  UIItineraryEntry: "SMES_UI_ItE",
  UIProduct: "SMES_UI_Prd",
  UIReferenceData: "SMES_UI_Ref",
  UISystemMonitor: "SMES_UI_SMo",
  UIAsoftSelect: "SMES_UI_ASS",
  UISystemAction: "SMES_UI_SAc",
  UICountryRegion: "SMES_UI_TOr",
  UIProductBundle: "SMES_UI_PrB",
  UICommunication: "SMES_UI_Cmc",
  UIReport: "SMES_UI_Rep",
  UIResetPassword: "SMES_UI_RPw",
  VCASoftTreeView: "SMES_VC_ATV",
  UITooltips: "SMES_UI_Ttp",
  IPInfo: "SMES_VC_IPInfo",
  UISec: "SMES_UI_Sec",
  UIOrder: "SMES_VC_Order",
  StrOrder: "SMES_Str_Order",
  StrReferenceData: "SMES_Str_Ref",
};

Logger.get(loggingSource.UIConfig);
Logger.get(loggingSource.UIBooking);
Logger.get(loggingSource.UIClient);
Logger.get(loggingSource.UIItinerary);
Logger.get(loggingSource.UIItineraryEntry);
Logger.get(loggingSource.UIProduct);
Logger.get(loggingSource.UIReferenceData);
Logger.get(loggingSource.UISupplier);
Logger.get(loggingSource.UISystemIssue);
Logger.get(loggingSource.UIUser);
Logger.get(loggingSource.UIWorkgroup);
Logger.get(loggingSource.UISystemMonitor);
Logger.get(loggingSource.UIAsoftSelect);
Logger.get(loggingSource.UISystemAction);
Logger.get(loggingSource.UICountryRegion);
Logger.get(loggingSource.UIProductBundle);
Logger.get(loggingSource.UICommunication);
Logger.get(loggingSource.UIReport);
Logger.get(loggingSource.UIResetPassword);
Logger.get(loggingSource.VCASoftTreeView);
Logger.get(loggingSource.UITooltips);
Logger.get(loggingSource.IPInfo);
Logger.get(loggingSource.IPSec);
Logger.get(loggingSource.UIOrder);
Logger.get(loggingSource.StrOrder);
Logger.get(loggingSource.StrReferenceData);

Logger.get(loggingSource.UIConfig).info(
  "Welcome... Logging is enabled - level %s... initialising VueJS for the %s environment.",
  Logger.getLevel().name,
  process.env.NODE_ENV
);

export default Logger;
