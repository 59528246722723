var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "main" } }, [
      _c("h2", [_vm._v("Oops!The access is forbidden")]),
      _c("p", [_vm._v("Sorry, you don't have access for this page.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }