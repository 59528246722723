import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getUserImpersonation() {
    try {
      logger.get(loggingSource.UIUser).info("getUserImpersonation...");

      logger.get(loggingSource.UIUser).time("getUserImpersonation call");
      const response = await api.client
        .get("user/impersonation")
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getUserImpersonation call");
      const users = response.data;
      logger
        .get(loggingSource.UIUser)
        .debug("getUserImpersonation users: %s", users);

      return users;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getUserImpersonation call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getUserImpersonation");
    }
  },

  async postImpersonator(userReference, impersonatorPayload) {
    try {
      logger.get(loggingSource.UIUser).info("postImpersonator...");
      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => impersonatorPayload: %s", impersonatorPayload);

      logger.get(loggingSource.UIUser).time("postUser call");
      const response = await api.client
        .post(`user/impersonationCreate/${userReference}`, impersonatorPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("postImpersonator call");
      const user = response.data;
      logger.get(loggingSource.UIUser).debug("postImpersonator user: %s", user);

      return user;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("postImpersonator call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...postImpersonator");
    }
  },

  async putImpersonator(userReference, impersonatorPayload) {
    try {
      logger.get(loggingSource.UIUser).info("putImpersonator...");
      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => impersonatorPayload: %s", impersonatorPayload);

      logger.get(loggingSource.UIUser).time("putImpersonator call");
      const response = await api.client
        .put(`user/impersonationUpdate/${userReference}`, impersonatorPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("putImpersonator call");
      const user = response.data;
      logger.get(loggingSource.UIUser).debug("putImpersonator user: %s", user);

      return user;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("putImpersonator call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...putImpersonator");
    }
  },

  async deleteImpersonator(impersonatorPayload) {
    try {
      logger.get(loggingSource.UIUser).info("deleteImpersonator...");

      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => impersonatorPayload: %s", impersonatorPayload);

      logger.get(loggingSource.UIUser).time("deleteImpersonator call");
      await api.client
        .delete(
          `user/impersonationDelete/${
            impersonatorPayload.UserImpersonationId
          }?concurrencyRV=${encodeURIComponent(
            impersonatorPayload.ImpersonationConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("deleteImpersonator call");
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("deleteImpersonator call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...deleteImpersonator");
    }
  },
};
