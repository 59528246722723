<template>
  <mdb-container fluid>
    <h1 class="text-center">Travel Booking Edit</h1>

    <h4 v-if="booking.Reference != ''" class="mx-2">
      Reference: {{ booking.Reference }}
    </h4>
    <!--
        Owner
    -->
    <h4 class="mx-2">Owner</h4>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        v-model="booking.OwnerWorkgroupReference"
        maxlength="100"
        style="min-width: 300px"
        :items="ownerWorkgroups"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Owner Workgroup"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        v-model="booking.OwnerUserReference"
        maxlength="100"
        style="min-width: 300px"
        :items="ownerUsersForWorkgroup"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Owner User"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
    </div>

    <!--
        Client Detail
    -->
    <div class="section-break"></div>

    <h4 class="mx-2">Client Detail</h4>

    <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
      <span class="mr-5" style="min-width: 60px">Client Type</span>
      <div class="d-flex flex-row flex-wrap min-width:120px;">
        <awgt-input
          id="clientType_School"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="School"
          radioValue="CT_S"
          class="mx-2"
          style="min-width: 60px"
        />
        <awgt-input
          id="clientType_Individual"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="Individual"
          radioValue="CT_I"
          class="mx-2"
          style="min-width: 60px"
        />
        <awgt-input
          id="clientType_Organisation"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="Organisation"
          radioValue="CT_O"
          class="mx-2"
          style="min-width: 60px"
        />
      </div>
    </mdb-form-inline>
    <awgt-std-switch
      class="flex-grow-1 mx-2"
      offLabel="Existing Client"
      onLabel="New Client"
      v-model="isNewClientInd"
      @getValue="onNewOrExistingClientToggle"
    ></awgt-std-switch>
    <div
      v-if="isNewClientInd == false"
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
    >
      <awgt-std-dropdown
        v-model="selectedClientReference"
        :items="clients"
        itemValueProperty="Reference"
        itemTextProperty="DisplayName"
        label="Client Name"
        bg
        class="flex-grow-1 mx-2"
        search
      /><br />
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onShowClientDetail"
        :disabled="selectedClientReference == null"
      >
        <mdb-icon icon="info" class="mr-1" />Detail
      </awgt-std-button>
    </div>
    <div
      class="d-flex flex-row flex-wrap mx-3"
      v-for="(address, index) in client.Addresses"
      :key="index"
    >
      <div
        v-if="
          address.Type === 'AT_Ph' &&
          (address.Street.length > 0 ||
            address.Locality.length > 0 ||
            address.Subdivision != null ||
            address.Postcode.length > 0 ||
            address.Country.length > 0)
        "
      >
        <span style="font-size: 10pt">{{
          (address.Street.length > 0 ? address.Street + ", " : "") +
          (address.Locality.length > 0 ? address.Locality + ", " : "") +
          (address.Subdivision != null ? address.Subdivision + ", " : "") +
          (address.Postcode.length > 0 ? address.Postcode + ", " : "") +
          (address.Country.length > 0 ? address.Country : "")
        }}</span>
      </div>
    </div>
    <expansion-region
      useVShowConditional
      :toggle="isNewClientInd == true || showClientDetailInd"
    >
      <div
        v-if="client.ClientType == 'CT_I'"
        class="d-flex flex-row flex-nowrap flex-grow-1"
      >
        <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-input
          v-model="client.GivenNames"
          counter
          :counterMaxValue="60"
          maxlength="60"
          label="Given Names"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 300px"
        />
        <awgt-input
          v-model="client.Surname"
          counter
          :counterMaxValue="40"
          maxlength="40"
          label="Surname"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 300px"
        />
      </div>
      <div v-else class="d-flex flex-row flex-wrap flex-grow-1">
        <mdb-icon
          :icon="client.ClientType == 'CT_O' ? 'building' : 'school'"
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <awgt-input
          v-model="client.Name"
          counter
          :counterMaxValue="200"
          maxlength="200"
          label="Name"
          bg
          class="flex-grow-1 mx-2"
        />
        <awgt-input
          id="client-abn"
          v-model="client.ABN"
          counter
          :counterMaxValue="11"
          maxlength="11"
          label="ABN"
          bg
          class="flex-grow-1 mx-2"
        />
      </div>
      <asoftAddressesCapture
        :template="getSystemTemplateFromCode('STT_C_Ad')"
        v-model="client.Addresses"
      ></asoftAddressesCapture>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon icon="phone" color="primary" class="mx-2 mt-4" size="2x" />
        <asoft-phone-input
          v-model="client.Phone"
          maxlength="20"
          label="Phone"
          style="width: 180px"
          bg
          class="mx-2"
        />
        <mdb-icon icon="at" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-input
          v-model="client.EmailAddress"
          :counterMaxValue="254"
          maxlength="254"
          counter
          label="Email"
          style="min-width: 120px"
          bg
          class="flex-grow-1 mx-2"
        />
      </div>
    </expansion-region>

    <!--
        Booking Detail
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">Booking Detail</h4>
    <div class="d-flex flex-row flex-nowrap flex-grow-1">
      <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
      <awgt-input
        v-model="booking.Description"
        counter
        :counterMaxValue="50"
        maxlength="50"
        label="Booking Description"
        bg
        class="flex-grow-1 mx-2"
      />
    </div>
    <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
      <span class="mr-5" style="min-width: 60px">Booking Type</span>
      <div class="d-flex flex-row flex-wrap min-width:120px;">
        <awgt-input
          id="bookingType_School"
          type="radio"
          v-model="booking.BookingType"
          name="bookingType"
          label="School"
          radioValue="BT_S"
          class="mx-2"
          style="min-width: 60px"
        />
      </div>
    </mdb-form-inline>

    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <asoftDatePicker
          v-model="booking.RequestedDt"
          label="Date Requested"
          autoHide
          :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
          :dataDateFormat="$constants.DATETIMEFORMAT_Json"
          setTime="00:00:00.000"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
          :width="300"
          :tooltip="getTooltipsTextByCode(13)"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <asoftDatePicker
          v-model="booking.DepartureDt"
          label="Departure Date"
          autoHide
          :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
          :dataDateFormat="$constants.DATETIMEFORMAT_Json"
          setTime="00:00:00.000"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
          :width="300"
        />
      </div>

      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <asoftDatePicker
          v-model="booking.ReturnDt"
          label="Return Date"
          autoHide
          displayDateFormat="D MMM YYYY"
          dataDateFormat="YYYY-MM-DDTHH:mm:ss"
          setTime="00:00:00.000"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
          :width="300"
        />
      </div>
      <div
        v-if="booking.DepartureDt != null && booking.ReturnDt != null"
        class="my-4 mx-2"
        style="color: purple"
      >
        {{ TravelDuration }}
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-std-dropdown
        label="School Term"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getSchoolTerms(false)"
        itemTextProperty="Name"
        v-model="booking.SchoolTerm"
        bg
        :tooltip="getTooltipsTextByCode(14)"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Booking Status"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getBookingStatuses(false)"
        itemTextProperty="Name"
        v-model="booking.BookingStatus"
        bg
        :tooltip="getTooltipsTextByCode(15)"
      ></awgt-std-dropdown>
      <awgt-std-switch
        class="flex-grow-1 mx-2"
        offLabel="Advanced Booking "
        onLabel="From Proposal"
        v-model="booking.IsFromProposal"
      ></awgt-std-switch>
    </div>
    <!--
        School Group Detail
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">School Group Detail</h4>

    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon icon="hashtag" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-std-dropdown
          itemValueProperty="Code"
          :items="getYearGroups"
          itemTextProperty="Name"
          v-model="yearGroups"
          label="Year Groups"
          bg
          :maxSelectedOptions="13"
          multiple
          selectAll
          class="flex-grow-1 mx-2"
        ></awgt-std-dropdown>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon icon="hashtag" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-input
          v-model.number="booking.StudentsCount"
          maxlength="4"
          label="Number of Students"
          bg
          class="flex-grow-1 mx-2"
          type="text"
          v-numeric="{ precision: 4, scale: 0, posOnly: true }"
          style="width: 200px"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon icon="hashtag" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-input
          v-model.number="booking.FreeOfChargeAdultsCount"
          maxlength="4"
          label="Free-of-Charge Adults"
          bg
          class="flex-grow-1 mx-2"
          type="text"
          v-numeric="{ precision: 4, scale: 0, posOnly: true }"
          style="width: 200px"
          :tooltip="getTooltipsTextByCode(23)"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon icon="hashtag" color="primary" class="mx-2 mt-4" size="2x" />
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <awgt-input
            v-model.number="NotFreeOfChargeAdultsCount"
            maxlength="4"
            label="Not Free-of-Charge Adults"
            bg
            class="flex-grow-1 mx-2"
            type="text"
            v-numeric="{ precision: 4, scale: 0, posOnly: true }"
            style="width: 200px"
            :tooltip="getTooltipsTextByCode(24)"
          />
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
        <mdb-icon icon="hashtag" color="primary" class="mx-2" size="2x" />
        <span class="mx-2" style="font-size: 18px"
          >Group of {{ groupSize }}</span
        >
      </div>
    </div>
    <div class="section-break"></div>
    <div class="d-flex flex-row align-items-center">
      <h4 class="mx-2">Booking Contacts</h4>
      <tdoxTooltip class="mb-1 mr-auto" :detail="getTooltipsTextByCode(29)" />
      <!--       <button
        type="button"
        class="tooltip-button mb-1 mr-auto"
        :title="getTooltipsTextByCode(29)"
        v-tippy="{ position: 'bottom', arrow: true }"
      >
        <mdb-icon
          fas
          icon="question-circle"
          style="color: grey; cursor: pointer"
          size="1x"
        />
      </button> -->
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="$refs.bookingContactsDialog.addBookingContact()"
      >
        <mdb-icon icon="plus" class="mr-1" />New
      </awgt-std-button>
    </div>
    <travel-contacts
      class="mx-2"
      ref="bookingContactsDialog"
      v-model="booking.BookingContacts"
    />

    <!--
        Booking Notes
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">Booking Notes</h4>

    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <mdb-icon
        icon="clipboard"
        far
        color="primary"
        class="mx-2 mb-2"
        size="2x"
      />
      <div class="ml-2">Travel Booking Notes</div>
    </div>
    <mdb-wysiwyg
      v-model="booking.BookingNotes"
      class="mx-2"
      style="height: 200px"
    ></mdb-wysiwyg>
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row flex-nowrap">
        <mdb-icon
          icon="dollar-sign"
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <awgt-input
          v-model.number="booking.TripProfit"
          maxlength="11"
          label="Trip Profit"
          bg
          class="mx-2"
          style="width: 140px"
          type="text"
          v-numeric="{ precision: 10, scale: 2, posOnly: true }"
        />
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <awgt-std-dropdown
          label="Additional Loading"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getAdditionalLoadingTypes(booking.BookingType, false)"
          itemTextProperty="Name"
          v-model="booking.AdditionalLoadingType"
          bg
          style="width: 210px"
          :tooltip="getTooltipsTextByCode(25)"
        ></awgt-std-dropdown>

        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="dollar-sign"
            color="primary"
            class="mx-2 mt-4"
            size="2x"
          />
          <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <awgt-input
              v-model.number="booking.AdditionalLoadingAmt"
              maxlength="7"
              bg
              :label="
                booking.AdditionalLoadingType == 'AL_No'
                  ? `Additional Loading Amount (Disabled)`
                  : 'Additional Loading Amount'
              "
              class="flex-grow-1 mx-2"
              style="width: 210px"
              :disabled="booking.AdditionalLoadingType == 'AL_No'"
              type="text"
              v-numeric="{ precision: 6, scale: 2, posOnly: true }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section-break"></div>
    <rebates-and-discounts
      v-model="booking.RebatesAndDiscounts"
      :isSchoolBooking="booking.BookingType == 'BT_S' ? true : false"
    ></rebates-and-discounts>
    <div class="section-break"></div>
    <additional-charges
      v-model="booking.AdditionalCharges"
      :isSchoolBooking="booking.BookingType == 'BT_S' ? true : false"
    ></additional-charges>
    <div class="float-right">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onSave()"
        :disabled="!isBookingDirty() && !isClientDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />Save
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onClose()"
      >
        <mdb-icon icon="times" class="mr-1" />Close
      </awgt-std-button>
    </div>
    <!-- For asking if changing the ower user and owner workgroup for future bookings -->
    <asoftPrompt
      v-model="changingFutureBookingPromptInd"
      ref="changingFutureBookingsPrompt"
      headerClasses="p-3 changingFutureBookingsPromptHeader"
      bodyClasses="px-3 pt-3"
      footerClasses="px-3 pb-3"
    >
      <template v-slot:header> Change Booking Ownership </template>
      <template v-slot:body>
        There are future bookings for this client that are owned by:
        <br /><br />
        <ul style="margin: 0px">
          <li v-for="(e, idx) in otherOwners" :key="idx">
            {{ e.OwnerUserName }} - {{ e.OwnerWorkgroupName }}
          </li>
        </ul>
        <br />
        Would you like these future bookings to be owned by {{ bookingOwner }}?
        <br />
      </template>
      <template v-slot:footer="{ onClick }">
        <hr />
        <div class="d-flex justify-content-end align-center">
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onClick('Yes')"
          >
            <mdb-icon icon="check" class="mr-1" />Yes
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onClick('No')"
          >
            <mdb-icon icon="times" class="mr-1" />No
          </awgt-std-button>
        </div>
      </template>
    </asoftPrompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
.changingFutureBookingsPromptHeader {
  background-color: #923f82;
  color: white;
  font-weight: bold;
}

.md-form label {
  margin-right: 0px !important;
}
</style>

<script>
import { mdbContainer, mdbIcon, mdbFormInline } from "mdbvue";

import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import ExpansionRegion from "@/components/ExpansionRegion";
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput";
import TravelContacts from "./edit-TravelContacts";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import bookingApi from "@/api/bookingApi.js";
import workgroupApi from "@/api/workgroupApi.js";
import clientApi from "@/api/clientApi.js";
import { mapGetters } from "vuex";
import asoftAddressesCapture from "@/components/AtomSoftware/asoftAddressesCapture.vue";
import rebatesAndDiscounts from "@/components/RebatesAndDiscounts";
import additionalCharges from "@/components/AdditionalCharges";
import tokenManager from "@/shared/securityTokenManager.js";
import userApi from "@/api/userApi.js";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    AwgtStdDropdown,
    asoftDatePicker,
    awgtInput,
    asoftPhoneInput,
    AwgtStdSwitch,
    mdbWysiwyg,
    ExpansionRegion,
    TravelContacts,
    asoftPrompt,
    asoftAddressesCapture,
    rebatesAndDiscounts,
    additionalCharges,
    tdoxTooltip,
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      booking: {},
      client: {},
      bookingSnapshot: {},
      clientSnapshot: {},
      clients: [],
      selectedClientReference: null,
      subdivisions: [],
      datePickerOptions: { format: "D MMM YYYY" },
      isNewClientInd: false,
      showClientDetailInd: false,
      showEditTravelContactModal: false,
      travelContactModal_travelContactId: 0,
      autocomplete: null,
      formSubmissionErrors: [],
      saveAttempted: false,
      isBookingDirtyInd: false,
      isClientDirtyInd: false,
      workgroups: [],
      otherOwners: [],
      changingFutureBookingPromptInd: false,
      yearGroups: [],
      NotFreeOfChargeAdultsCount: 0,
      currentUserLevel: 0,
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForBookingCreate: null,
      currentUserPermissionScopeForBookingUpdate: null,
    };
  },
  computed: {
    ...mapGetters([
      "getEmptyBooking",
      "getEmptyClient",
      "getBookingStatuses",
      "getAdditionalLoadingTypes",
      "getSchoolTerms",
      "getYearGroups",
      "getSystemTemplateFromCode",
      "getTooltipsTextByCode",
    ]),

    bookingOwner: function () {
      let ownerUsersForWorkgroup = this.ownerUsersForWorkgroup;
      let ownerUser = ownerUsersForWorkgroup.find((ou) => {
        return ou.Code == this.booking.OwnerUserReference;
      });

      return ownerUser + (ownerUser != null && this.getOwnerWorkgroup != null)
        ? " - "
        : "" + this.getOwnerWorkgroup;
    },

    groupSize() {
      return (
        parseInt(this.booking.StudentsCount, 10) +
        parseInt(this.booking.FreeOfChargeAdultsCount, 10) +
        parseInt(this.NotFreeOfChargeAdultsCount, 10)
      );
    },

    TravelDuration() {
      if (this.booking.DepartureDt != null && this.booking.ReturnDt != null) {
        let departureDate = this.$moment(this.booking.DepartureDt);
        let returnDate = this.$moment(this.booking.ReturnDt);

        let duration = returnDate.diff(departureDate, "days");
        return `${duration + 1} Days / ${duration} Nights`;
      } else return "";
    },

    ownerWorkgroups() {
      if (
        this.currentUserPermissionScopeForBookingCreate != null &&
        (this.currentUserPermissionScopeForBookingCreate == "CPS_BW" ||
          this.currentUserPermissionScopeForBookingCreate == "CPS_NW")
      ) {
        return this.workgroups
          .filter((w) => {
            return this.workgroupsOfCurrentUser.includes(w.Reference);
          })
          .map((w) => {
            return {
              Code: w.Reference,
              Name: w.Name,
            };
          });
      } else if (
        this.currentUserPermissionScopeForBookingUpdate != null &&
        (this.currentUserPermissionScopeForBookingUpdate == "CPS_BW" ||
          this.currentUserPermissionScopeForBookingUpdate == "CPS_NW")
      ) {
        return this.workgroups
          .filter((w) => {
            return (
              this.workgroupsOfCurrentUser.includes(w.Reference) ||
              w.Reference == this.booking?.OwnerWorkgroupReference
            );
          })
          .map((w) => {
            return {
              Code: w.Reference,
              Name: w.Name,
            };
          });
      } else
        return this.workgroups.map((w) => {
          return {
            Code: w.Reference,
            Name: w.Name,
          };
        });
    },

    ownerUsersForWorkgroup() {
      if (this.getOwnerWorkgroup != null) {
        let ownerUsersForWorkgroup = this.traverseWorkgroupUsers(
          this.getOwnerWorkgroup.TreeData
        );
        if (this.booking.OwnerUserReference != null) {
          //If the owneruser is no longer in the list of workgroup users (because they have moved workgroups)
          //then they need to be added to this list as they are still the current owner of the booking.
          if (
            !ownerUsersForWorkgroup.find(
              (u) => u.Code == this.booking.OwnerUserReference
            )
          ) {
            let ownerUser = this.findUserFromAllWorkgroups(
              this.booking.OwnerUserReference
            );
            if (ownerUser) ownerUsersForWorkgroup.push(ownerUser);
            else
              this.$log.error(
                `This booking's owner user ${this.bookingOwner.OwnerUserReference} can't be found to add to Owner User dropdown!`
              );
          }
        }
        return ownerUsersForWorkgroup;
      } else return [];
    },

    getOwnerWorkgroup() {
      if (this.booking?.OwnerWorkgroupReference)
        return this.workgroups.find((w) => {
          return w.Reference == this.booking.OwnerWorkgroupReference;
        });
      else return null;
    },
  },
  watch: {
    yearGroups(to, from) {
      if (to != from) {
        this.booking.YearGroups = this.yearGroups.join();
      }
    },

    isNewClientInd(to) {
      this.$log.info("WATCHER => isNewClientInd... %s", to);
      if (to == true) {
        this.selectedClientReference = null;
        this.client = this.$lodash.cloneDeep(this.getEmptyClient);
      }
      this.showClientDetailInd = false;
      this.$log.info("WATCHER => ...isNewClientInd");
    },

    selectedClientReference(to) {
      this.$log.info(
        "WATCHER => selectedClientReference... [%s], [%s]",
        to,
        this.clients
      );
      if (to != null) {
        let client = this.clients.find((c) => c.Reference == to);
        if (client != null) {
          this.client = this.$lodash.cloneDeep(client);
          this.clientSnapshot = this.$lodash.cloneDeep(this.client);
        }
      }
      this.$log.info("WATCHER => ...selectedClientReference");
    },

    "client.ClientType"(to, from) {
      this.$log.info("WATCHER => clientType...");
      if (to != null && to != from) {
        let clientType = this.client.ClientType;
        this.client = this.$lodash.cloneDeep(this.getEmptyClient);
        this.client.ClientType = clientType;
        this.loadClientsForClientType();
      }
      this.$log.info("WATCHER => ...clientType");
    },

    clients(to) {
      this.$log.info("WATCHER => clients...");
      if (to != null) {
        if (this.booking.Client != null)
          this.selectedClientReference = this.booking.Client.Reference;
        else this.selectedClientReference = null;
      }
      this.$log.info("WATCHER => ...clients");
    },

    "booking.AdditionalLoadingType"(to) {
      if (to == "AL_No") {
        this.booking.AdditionalLoadingAmt = 0;
      }
    },

    "booking.FreeOfChargeAdultsCount"(to) {
      this.booking.AdultsCount = to + this.NotFreeOfChargeAdultsCount;
    },

    NotFreeOfChargeAdultsCount(to) {
      this.booking.AdultsCount = to + this.booking.FreeOfChargeAdultsCount;
    },
    /*     "booking.OwnerWorkgroupReference"(to, from) {
      this.$log.info("WATCHER => booking.OwnerWorkgroupReference...");
      if (to != null && to != from) {
        this.ownerUsers = new Array();
        this.loadUsersForOwnerWorkgroup(
          this.workgroups.find((w) => {
            return w.Reference == to;
          })?.Reference
        );
      }
      this.$log.info("WATCHER => ...booking.OwnerWorkgroupReference");
    }, */
    "booking.DepartureDt"(to) {
      if (to != null && this.booking.ReturnDt == null) {
        this.booking.ReturnDt = to;
      }
    },

    "booking.ReturnDt"(to) {
      if (to != null && this.booking.DepartureDt == null) {
        this.booking.DepartureDt = to;
      }
    },
  },

  methods: {
    async onSave() {
      this.$log.info("onSave...");
      this.client.SearchAddressString = this.client.Addresses.map((address) => {
        return Object.values(address).join(" ");
      }).join(" ");
      this.booking.Client = this.client;
      //TODO: Travel booking status needs to come from the UI...
      //this.booking.BookingStatus = "BS_Dr";
      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the travel booking...");

        await this.saveBooking(this.booking, this.client)
          .then(() => {
            if (this.otherOwners.length == 0) {
              this.showClientDetailInd = false;
              this.isNewClientInd = false;
            }

            if (this.otherOwners.length > 0) {
              this.onChangingFutureBookingsPrompt().then((option) => {
                this.changingFutureBookingPromptInd = false;
                this.saveBookingWithOptionForFutureBookings(option)
                  .then(() => {
                    this.showClientDetailInd = false;
                    this.isNewClientInd = false;
                    this.otherOwners = [];
                    this.$emit("header-message", {
                      isSuccessInd: true,
                      Message: [
                        `The booking ${this.booking.Description} has been saved.`,
                      ],
                    });
                    this.snapshotBookingAndClient(this.booking, this.client);
                  })
                  .catch((error) => {
                    this.$log.error(error);
                  });
              });
            } else {
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: [
                  `The booking ${this.booking.Description} has been saved.`,
                ],
              });
              this.snapshotBookingAndClient(this.booking, this.client);
            }
          })
          .catch((error) => {
            this.$log.error(error);
          });
      }
    },

    async saveBookingWithOptionForFutureBookings(changeFutureBookings) {
      if (this.booking.Reference == "") {
        this.booking = await bookingApi.postBookingAsync(
          this.booking,
          changeFutureBookings,
          false
        );
      } else {
        this.booking = await bookingApi.putBookingAsync(
          this.booking,
          changeFutureBookings,
          false
        );
      }
    },

    validateForm() {
      this.formSubmissionErrors = [];
      if (this.booking.OwnerWorkgroupReference == null) {
        this.formSubmissionErrors.push(
          "A booking owner workgroup must be selected."
        );
      }
      if (this.booking.OwnerUserReference == null) {
        this.formSubmissionErrors.push(
          "A booking owner user must be selected."
        );
      }
      if (this.isNewClientInd == false && this.booking.Client.Reference == "")
        this.formSubmissionErrors.push("A client must be selected.");

      if (this.booking.RequestedDt == "")
        this.formSubmissionErrors.push("A requested date must be provided.");

      if (this.booking.Description == "")
        this.formSubmissionErrors.push(
          "A booking description must be provided."
        );

      if (
        this.booking.DepartureDt != null &&
        this.booking.ReturnDt != null &&
        this.booking.DepartureDt > this.booking.ReturnDt
      )
        this.formSubmissionErrors.push(
          "The return date must be on or after the departure date"
        );

      if (this.client.Phone.length == 0)
        this.formSubmissionErrors.push("Client phone number must be provided.");

      if (
        (this.client.Phone.length == 0 ||
          (this.client.Phone.length == 6 &&
            this.client.Phone.substring(0, 2) == "13") ||
          (this.client.Phone.length == 10 &&
            (this.client.Phone.substring(0, 4) == "1300" ||
              this.client.Phone.substring(0, 4) == "1800" ||
              this.client.Phone.substring(0, 1) == "0")) ||
          (this.client.Phone.length >= 11 && this.client.Phone.length <= 15)) == //International Numbers
        false
      ) {
        this.formSubmissionErrors.push(
          `Client phone number ${this.client.Phone} is not recognised.`
        );
      }

      if (
        this.booking.DepartureDt != null &&
        this.booking.DepartureDt < this.booking.RequestedDt
      )
        this.formSubmissionErrors.push(
          "The departure date must be on or after the requested date."
        );

      for (let bc of this.booking.BookingContacts) {
        let foundError = false;
        if (bc.Name.trim().length == 0) {
          this.formSubmissionErrors.push(
            "All travel contacts must have a name."
          );
          foundError = true;
        }
        if (bc.Phone.trim().length == 0 && bc.EmailAddress.trim().length == 0) {
          this.formSubmissionErrors.push(
            "All travel contacts must have a phone number or email address."
          );
          foundError = true;
        }

        if (
          (bc.Phone.length == 0 ||
            (bc.Phone.length == 6 && bc.Phone.substring(0, 2) == "13") ||
            (bc.Phone.length == 10 &&
              (bc.Phone.substring(0, 4) == "1300" ||
                bc.Phone.substring(0, 4) == "1800" ||
                bc.Phone.substring(0, 1) == "0")) ||
            (bc.Phone.length >= 11 && bc.Phone.length <= 15)) == //International Numbers
          false
        ) {
          this.formSubmissionErrors.push(
            `Travel contact phone number ${bc.Phone} is not recognised.`
          );
          foundError = true;
        }

        if (foundError == true) break; //Don't repeat the error message for other travel contacts.
      }

      return this.formSubmissionErrors.length == 0;
    },

    onClose() {
      if (this.isBookingDirty() || this.isClientDirty()) {
        this.$log.info("Dirty");
      } else {
        this.$log.info("Clean");
      }
      this.$router.go(-1);
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    onNewOrExistingClientToggle(value) {
      this.showClientDetailInd = false;
      this.isNewClientInd = value;
    },

    onShowClientDetail() {
      this.showClientDetailInd = !this.showClientDetailInd;
    },

    async saveBooking(booking, client) {
      let isClientDirty = this.isClientDirty();
      let isBookingDirty = this.isBookingDirty();
      if (isClientDirty == false) this.client.ConcurrencyRV = null;

      if (isBookingDirty == true) {
        if (booking.Reference == "") {
          let returnVal = await bookingApi.postBookingAsync(
            booking,
            false,
            true
          );
          if (Array.isArray(returnVal) == true) this.otherOwners = returnVal;
          else this.booking = returnVal;
        } else {
          let returnVal = await bookingApi.putBookingAsync(
            booking,
            false,
            true
          );
          if (Array.isArray(returnVal) == true) this.otherOwners = returnVal;
          else this.booking = returnVal;
        }
      } else if (isClientDirty == true) {
        this.client = await clientApi.putClient(client);
      }
      //Refresh the client list.
      await this.loadClientsForClientType(this.client.ClientType);
    },

    isBookingDirty() {
      //Need to cache the isBookingDirtyInd test as it's expensive. It is reset in snapshotBookingAndClient.
      if (this.isBookingDirtyInd == true) return true;

      this.isBookingDirtyInd =
        this.$lodash.isEqual(this.booking, this.bookingSnapshot) == false;
      return this.isBookingDirtyInd;
    },

    isClientDirty() {
      //Need to cache the isClientDirtyInd test as it's expensive. It is reset in snapshotBookingAndClient.
      if (this.isClientDirtyInd == true) return true;

      this.isClientDirtyInd =
        this.$lodash.isEqual(this.client, this.clientSnapshot) == false;

      return this.isClientDirtyInd;
    },

    async loadClientsForClientType() {
      this.clients = await clientApi.getClients(this.client.ClientType);
    },

    traverseWorkgroupUsers(children) {
      let ownerUsers = [];

      for (let child of children) {
        if (
          this.currentUserPermissionScopeForBookingCreate == "CPS_AA" ||
          this.currentUserPermissionScopeForBookingUpdate == "CPS_AA"
        )
          ownerUsers.push({ Code: child.Reference, Name: child.Text });
        else if (
          this.currentUserPermissionScopeForBookingCreate == "CPS_NA" ||
          this.currentUserPermissionScopeForBookingUpdate == "CPS_NA"
        ) {
          if (child.Level <= 400)
            ownerUsers.push({ Code: child.Reference, Name: child.Text });
        } else if (
          this.currentUserPermissionScopeForBookingCreate == "CPS_BW" ||
          this.currentUserPermissionScopeForBookingUpdate == "CPS_BW"
        ) {
          if (child.Level <= this.currentUserLevel)
            ownerUsers.push({ Code: child.Reference, Name: child.Text });
        } else if (
          this.currentUserPermissionScopeForBookingCreate == "CPS_NW" ||
          this.currentUserPermissionScopeForBookingUpdate == "CPS_NW"
        ) {
          if (
            child.Level < this.currentUserLevel ||
            child.Reference == this.currentUser
          )
            ownerUsers.push({ Code: child.Reference, Name: child.Text });
        }

        if (child.Children.length > 0)
          ownerUsers = ownerUsers.concat(
            this.traverseWorkgroupUsers(child.Children)
          );
      }

      return ownerUsers;
    },

    findUserInUserHierarchy(children, userReference) {
      for (let child of children) {
        if (child.Reference == userReference)
          return { Code: child.Reference, Name: child.Text };

        if (child.Children.length > 0)
          return this.findUserInUserHierarchy(child.Children, userReference);
      }

      return null;
    },

    findUserFromAllWorkgroups(userReference) {
      for (let workgroup of this.workgroups) {
        let user = this.findUserInUserHierarchy(
          workgroup.TreeData,
          userReference
        );
        if (user != null) return user;
      }

      return null;
    },

    onChangingFutureBookingsPrompt() {
      return new Promise((resolve) => {
        this.changingFutureBookingPromptInd = true;
        this.$refs.changingFutureBookingsPrompt.$once("click", (e) => {
          let event = null;
          if (e == "Yes") event = true;
          else event = false;
          resolve(event);
        });
      });
    },

    snapshotBookingAndClient(booking, client) {
      this.bookingSnapshot = this.$lodash.cloneDeep(booking);
      this.clientSnapshot = this.$lodash.cloneDeep(client);

      this.isClientDirtyInd = false;
      this.isBookingDirtyInd = false;
    },

    async loadFormData(reference) {
      this.workgroups = await workgroupApi.getWorkgroups();

      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      if (reference == null)
        this.currentUserPermissionScopeForBookingCreate =
          currentUser.UserPermission.find((cp) => {
            return cp.Permission == "CP_TBC";
          })?.PermissionScope;

      if (reference != null) {
        this.currentUserPermissionScopeForBookingUpdate =
          currentUser.UserPermission.find((cp) => {
            return cp.Permission == "CP_TBU";
          })?.PermissionScope;

        let booking = await bookingApi.getBookingByReferenceAsync(reference);
        /*
          Set booking after getting owner workgroup information and populating the Owner User dropdown
          as the OwnerUserReference needs to be set on the dropdown.
        */
        //FreeOfChargeAdultsCount might be greater than Adults count if AdultsCount has not been
        //populated in tblParticipantCount.
        if (booking.FreeOfChargeAdultsCount > booking.AdultsCount)
          booking.AdultsCount = booking.FreeOfChargeAdultsCount;
        this.NotFreeOfChargeAdultsCount =
          booking.AdultsCount - booking.FreeOfChargeAdultsCount;
        this.booking = booking;
        this.clients = await clientApi.getClients(
          this.booking.Client.ClientType
        );
        this.client = this.booking.Client;
        this.yearGroups = this.booking.YearGroups.split(",");
      }
    },
  },

  mounted() {
    //Getter provides actual object so clone is required...
    this.booking = this.$lodash.cloneDeep(this.getEmptyBooking);
    this.client = this.$lodash.cloneDeep(this.getEmptyClient);

    let routeParams = this.$route.params;
    this.$log.info("RouteParams:", routeParams);
    let bookingReference = routeParams.bookingReference;
    this.loadFormData(bookingReference).then(() => {
      this.snapshotBookingAndClient(this.booking, this.client);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isBookingDirty() == true || this.isClientDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSave().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
