var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.value).length > 0
    ? _c(
        "div",
        [
          _c("table", { attrs: { id: "supplier-table" } }, [
            _vm._m(0),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.value.Name))]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.value.ContactPersonNm == ""
                        ? " - "
                        : _vm.value.ContactPersonNm
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getPhysicalAddress(_vm.value.Addresses) == ""
                        ? " - "
                        : _vm.getPhysicalAddress(_vm.value.Addresses)
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getPostalAddress(_vm.value.Addresses) == ""
                        ? " - "
                        : _vm.getPostalAddress(_vm.value.Addresses)
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.value.Phone))]),
              _c("td", [_vm._v(_vm._s(_vm.value.EmailAddress))]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.value.PaymentTypes.map((pt) => {
                        return _vm.getPaymentTypeFromCode(pt)
                      }).join(", ") == ""
                        ? " - "
                        : _vm.value.PaymentTypes.map((pt) => {
                            return _vm.getPaymentTypeFromCode(pt)
                          }).join(", ")
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDepositTypes(false).find((dt) => {
                        return dt.Code == _vm.value.DepositType
                      }).Name
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getBookingMethodNameByCode(_vm.value.BookingMethod)
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _vm.value.ItineraryNotes && _vm.value.ItineraryNotes != ""
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(1),
                _c("div", { staticClass: "html-in-views" }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.value.ItineraryNotes) },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm.value.PreSupplierItineraryComment &&
          _vm.value.PreSupplierItineraryComment != ""
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(2),
                _c("span", [
                  _vm._v(_vm._s(_vm.value.PreSupplierItineraryComment)),
                ]),
              ])
            : _vm._e(),
          _vm.value.PostSupplierItineraryComment &&
          _vm.value.PostSupplierItineraryComment != ""
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(3),
                _c("span", [
                  _vm._v(_vm._s(_vm.value.PostSupplierItineraryComment)),
                ]),
              ])
            : _vm._e(),
          _vm.value.AWGTNotes.length > 0
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(4),
                _c("span", {
                  staticClass: "html-in-views",
                  domProps: { innerHTML: _vm._s(_vm.value.AWGTNotes) },
                }),
              ])
            : _vm._e(),
          _vm.value.SupplierNotes && _vm.value.SupplierNotes.length > 0
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(5),
                _c(
                  "ul",
                  _vm._l(_vm.value.SupplierNotes, function (itemisedNote, ind) {
                    return _c("li", { key: ind }, [
                      _vm._v(
                        " " +
                          _vm._s(itemisedNote.Subject) +
                          ": " +
                          _vm._s(itemisedNote.Note) +
                          " "
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.value.SupplierFiles && _vm.value.SupplierFiles.length > 0
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._m(6),
                _c("div", [
                  _c(
                    "ul",
                    _vm._l(
                      _vm.value.SupplierFiles,
                      function (category, indOfCat) {
                        return _c("li", { key: indOfCat }, [
                          _vm._v(" " + _vm._s(category.CategoryName) + " "),
                          category.Files && category.Files.length > 0
                            ? _c(
                                "ul",
                                _vm._l(
                                  category.Files,
                                  function (file, indOfFile) {
                                    return _c("li", { key: indOfFile }, [
                                      _vm._v(" " + _vm._s(file.FileName) + " "),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "section-break" }),
          _vm._l(_vm.value.Products, function (product, indOfInfoList) {
            return [
              _c("div", { key: indOfInfoList }, [
                _c("div", { staticClass: "html2pdf__page-break" }),
                _c(
                  "div",
                  { staticClass: "mx-2 my-4 avoid-page-break" },
                  [
                    _c("div", { staticStyle: { "font-weight": "bold" } }, [
                      _c("span", [_vm._v("Product Name")]),
                      _vm._v(" : "),
                      _c("span", { staticStyle: { color: "#923f82" } }, [
                        _vm._v(_vm._s(product.Name)),
                      ]),
                    ]),
                    product.DisplayName && product.DisplayName != ""
                      ? _c(
                          "div",
                          {
                            staticClass: "mt-2",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            _c("span", [_vm._v("Product Display Name")]),
                            _vm._v(" : "),
                            _c("span", { staticStyle: { color: "#923f82" } }, [
                              _vm._v(_vm._s(product.DisplayName)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "mt-4" }, [
                      product.InformationURL && product.InformationURL != ""
                        ? _c("div", [
                            _vm._v(" Information URL: "),
                            _c(
                              "a",
                              { attrs: { href: product.InformationURL } },
                              [_vm._v(_vm._s(product.InformationURL))]
                            ),
                          ])
                        : _vm._e(),
                      product.BookingURL && product.BookingURL != ""
                        ? _c("div", [
                            _vm._v(" Booking URL: "),
                            _c("a", { attrs: { href: product.BookingURL } }, [
                              _vm._v(_vm._s(product.BookingURL)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    product.Description && product.Description != ""
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("Product Description")]
                          ),
                          _vm._v(" : "),
                          _c("div", { staticClass: "html-in-views" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(product.Description),
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    product.SpecialComment && product.SpecialComment != ""
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("SpecialComment")]
                          ),
                          _vm._v(" : "),
                          _c("span", [_vm._v(_vm._s(product.SpecialComment))]),
                        ])
                      : _vm._e(),
                    product.AWGTNotes && product.AWGTNotes != ""
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("AWGT Notes")]
                          ),
                          _vm._v(" : "),
                          _c("span", {
                            staticClass: "html-in-views",
                            domProps: { innerHTML: _vm._s(product.AWGTNotes) },
                          }),
                        ])
                      : _vm._e(),
                    _vm.supplierType == true &&
                    product.ResellerSuppliers &&
                    product.ResellerSuppliers.length > 0
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("Reseller Suppliers")]
                          ),
                          _vm._v(" : "),
                          _c(
                            "ul",
                            _vm._l(product.ResellerSuppliers, function (s, i) {
                              return _c("li", { key: i }, [
                                _vm._v(" " + _vm._s(s.Name) + " "),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.supplierType == false &&
                    product.ProviderSuppliers &&
                    product.ProviderSuppliers.length > 0
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("Provider Suppliers")]
                          ),
                          _vm._v(" : "),
                          _c(
                            "ul",
                            _vm._l(product.ProviderSuppliers, function (s, i) {
                              return _c("li", { key: i }, [
                                _vm._v(" " + _vm._s(s.Name) + " "),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._m(7, true),
                    _c("div", { staticClass: "html2pdf__page-break" }),
                    product.ProductType == "PT_Ay" &&
                    product.AvailableTimes.length > 0
                      ? [
                          _vm._m(8, true),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row flex-nowrap" },
                            [
                              _c("div", { staticStyle: { width: "50%" } }, [
                                _vm._m(9, true),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "available-time-tb" },
                                      [
                                        _vm._l(
                                          _vm.leftColOfStartTimes(product),
                                          function (timeRow, indexRow) {
                                            return [
                                              _c(
                                                "tr",
                                                { key: indexRow },
                                                [
                                                  _vm._l(
                                                    timeRow,
                                                    function (item, indexItem) {
                                                      return [
                                                        _c(
                                                          "td",
                                                          { key: indexItem },
                                                          [_vm._v(_vm._s(item))]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "vertical-devider" }),
                              _c("div", { staticStyle: { width: "50%" } }, [
                                _vm._m(10, true),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "available-time-tb" },
                                      [
                                        _vm._l(
                                          _vm.rightColOfStartTimes(product),
                                          function (timeRow, indexRow) {
                                            return [
                                              _c(
                                                "tr",
                                                { key: indexRow },
                                                [
                                                  _vm._l(
                                                    timeRow,
                                                    function (item, indexItem) {
                                                      return [
                                                        _c(
                                                          "td",
                                                          { key: indexItem },
                                                          [_vm._v(_vm._s(item))]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "section-break" }),
                        ]
                      : _vm._e(),
                    _vm._m(11, true),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap avoid-page-break",
                      },
                      [
                        _c("div", { staticStyle: { width: "50%" } }, [
                          _vm._m(12, true),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "30px" } },
                                _vm._l(
                                  product.CommercialProductLines,
                                  function (pc, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { display: "none" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (_vm.c =
                                                    _vm.currentCommercialProductLineChronology(
                                                      pc
                                                    ))
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.c != null
                                            ? [
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#923f82",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(_vm.c.Name))]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#f48247",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatCurrency(
                                                              _vm.c
                                                                .ExGSTAmount +
                                                                _vm.c.GSTAmount
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getPriceTypeText(
                                                            _vm.c.PriceTypeCd
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ml-4 italics-font",
                                                    staticStyle: {
                                                      "margin-top": "-5px",
                                                    },
                                                  },
                                                  [
                                                    _vm.c.ActiveToDt == null
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " From " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm.c
                                                                    .ActiveFromDt
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            " From " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm.c
                                                                    .ActiveFromDt
                                                                )
                                                              ) +
                                                              " to " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm.c
                                                                    .ActiveToDt
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                    _vm.c.Description != ""
                                                      ? _c("div", {
                                                          staticClass:
                                                            "html-in-views",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-5px",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.c.Description
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c("div", [
                                                      _vm.c
                                                        .isIndicativePricingInd ==
                                                      true
                                                        ? _c("span", [
                                                            _c("i", [
                                                              _vm._v(
                                                                "Indicative Price"
                                                              ),
                                                            ]),
                                                          ])
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "vertical-devider" }),
                        _c("div", { staticStyle: { width: "50%" } }, [
                          _vm._m(13, true),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "30px" } },
                                _vm._l(
                                  product.CommercialProductLines,
                                  function (pc, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { display: "none" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (_vm.f =
                                                    _vm.futureCommercialProductLineChronology(
                                                      pc
                                                    ))
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._l(_vm.f, function (c, idx) {
                                            return _c("div", { key: idx }, [
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#923f82",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(c.Name))]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#f48247",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            c.ExGSTAmount +
                                                              c.GSTAmount
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getPriceTypeText(
                                                          c.PriceTypeCd
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-4 italics-font",
                                                  staticStyle: {
                                                    "margin-top": "-5px",
                                                  },
                                                },
                                                [
                                                  c.ActiveToDt == null
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " From " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                c.ActiveFromDt
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          " From " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                c.ActiveFromDt
                                                              )
                                                            ) +
                                                            " to " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                c.ActiveToDt
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                  c.Description != ""
                                                    ? _c("div", {
                                                        staticClass:
                                                          "html-in-views",
                                                        staticStyle: {
                                                          "margin-top": "-5px",
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            c.Description
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c("div", [
                                                    c.isIndicativePricingInd ==
                                                    true
                                                      ? _c("span", [
                                                          _c("i", [
                                                            _vm._v(
                                                              "Indicative Price"
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          }),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "section-break" }),
                    _vm._m(14, true),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap avoid-page-break",
                      },
                      [
                        _c("div", { staticStyle: { width: "50%" } }, [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "30px" } },
                                _vm._l(
                                  product.CommercialProductLines,
                                  function (pc, index) {
                                    return _c("div", { key: index }, [
                                      index <=
                                      ~~(
                                        product.CommercialProductLines.length /
                                        2
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      (_vm.p =
                                                        _vm.pastCommercialProductLineChronology(
                                                          pc
                                                        ))
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._l(_vm.p, function (c, idx) {
                                                return _c("div", { key: idx }, [
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-4" },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#923f82",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(c.Name)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f48247",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatCurrency(
                                                                    c.ExGSTAmount +
                                                                      c.GSTAmount
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getPriceTypeText(
                                                                  c.PriceTypeCd
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-4 italics-font",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-5px",
                                                          },
                                                        },
                                                        [
                                                          c.ActiveToDt == null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " From " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveFromDt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  " From " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveFromDt
                                                                      )
                                                                    ) +
                                                                    " to " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveToDt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                          c.Description != ""
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "html-in-views",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "-5px",
                                                                },
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      c.Description
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _c("div", [
                                                            c.isIndicativePricingInd ==
                                                            true
                                                              ? _c("span", [
                                                                  _c("i", [
                                                                    _vm._v(
                                                                      "Indicative Price"
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticStyle: { width: "50%" } }, [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "30px" } },
                                _vm._l(
                                  product.CommercialProductLines,
                                  function (pc, index) {
                                    return _c("div", { key: index }, [
                                      index >
                                      ~~(
                                        product.CommercialProductLines.length /
                                        2
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      (_vm.p =
                                                        _vm.pastCommercialProductLineChronology(
                                                          pc
                                                        ))
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._l(_vm.p, function (c, idx) {
                                                return _c("div", { key: idx }, [
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-4" },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#923f82",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(c.Name)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f48247",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatCurrency(
                                                                    c.ExGSTAmount +
                                                                      c.GSTAmount
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getPriceTypeText(
                                                                  c.PriceTypeCd
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-4 italics-font",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-5px",
                                                          },
                                                        },
                                                        [
                                                          c.ActiveToDt == null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " From " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveFromDt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  " From " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveFromDt
                                                                      )
                                                                    ) +
                                                                    " to " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        c.ActiveToDt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                          c.Description != ""
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "html-in-views",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "-5px",
                                                                },
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      c.Description
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _c("div", [
                                                            c.isIndicativePricingInd ==
                                                            true
                                                              ? _c("span", [
                                                                  _c("i", [
                                                                    _vm._v(
                                                                      "Indicative Price"
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { attrs: { id: "table-header" } }, [
      _c("td", [_vm._v("Supplier")]),
      _c("td", [_vm._v("Contact Name")]),
      _c("td", [_vm._v("Physical Address")]),
      _c("td", [_vm._v("Postal Address")]),
      _c("td", [_vm._v("Phone")]),
      _c("td", [_vm._v("Email")]),
      _c("td", [_vm._v("PaymentTypes")]),
      _c("td", [_vm._v("Deposit")]),
      _c("td", [_vm._v("BookingMethod")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("Itinerary Notes"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("Pre-supplier Itinerary Comment"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("Post-supplier Itinerary Comment"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("AWGT Notes"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("AWGT Supplier Notes"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [_vm._v("Files")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4" }, [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("Product Pricing"),
      ]),
      _vm._v(" : "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("Start Times"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("AM"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("PM"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("Product Pricing"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("CURRENT"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", { staticStyle: { color: "#923f82", "font-size": "1.2rem" } }, [
        _vm._v("FUTURE"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("span", [
        _c(
          "span",
          { staticStyle: { color: "#923f82", "font-size": "1.3rem" } },
          [_vm._v("PAST")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }