var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap ml-auto mr-0 align-items-center mb-2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap ml-auto mr-0 align-items-center",
            },
            [
              _c("div", [_vm._v("Zoom")]),
              _c("asoft-slider", {
                staticClass: "mx-2",
                attrs: { min: 20, max: 70, wrapperClassName: "" },
                model: {
                  value: _vm.timeCellHeight,
                  callback: function ($$v) {
                    _vm.timeCellHeight = $$v
                  },
                  expression: "timeCellHeight",
                },
              }),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  staticStyle: { width: "120px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.timeCellHeight = 60
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "undo-alt" },
                  }),
                  _vm._v("Reset "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap ml-0 mr-0" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button ml-auto",
                  staticStyle: { width: "120px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addItineraryEntry()
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "mb-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  _vm.showDayNotes = !_vm.showDayNotes
                },
              },
            },
            [
              _c("span", { staticClass: "h4" }, [
                _vm._v(
                  " Itinerary Day Notes (" +
                    _vm._s(_vm.itinerary.ItineraryDayNotes.length) +
                    ") "
                ),
              ]),
              _c(
                "span",
                { staticClass: "ml-3" },
                [
                  _vm.showDayNotes == true
                    ? _c("mdb-icon", {
                        attrs: {
                          icon: "angle-up",
                          size: "2x",
                          color: "primary",
                        },
                      })
                    : _c("mdb-icon", {
                        attrs: {
                          icon: "angle-down",
                          size: "2x",
                          color: "primary",
                        },
                      }),
                ],
                1
              ),
            ]
          ),
          _c(
            "expansion-region",
            { attrs: { toggle: _vm.showDayNotes == true } },
            [
              _vm._l(
                _vm.itinerary.ItineraryDayNotes,
                function (dayNote, index) {
                  return _c("div", { key: index }, [
                    _c("div", [
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.convertDateFormat(
                                dayNote.Date,
                                "YYYY-MM-DDTHH:mm:ss",
                                "D MMM YYYY"
                              )
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getItineraryDayNoteTypesByCode(
                                dayNote.ItineraryDayNoteType
                              )
                            ) + " Note"
                          ),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "ml-3",
                        domProps: { innerHTML: _vm._s(dayNote.Note) },
                      }),
                    ]),
                  ])
                }
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button float-right",
                  attrs: { type: "button", title: "Edit" },
                  on: {
                    click: function ($event) {
                      return _vm.onEditDayNotes()
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "edit" },
                  }),
                  _vm._v("Edit "),
                ],
                1
              ),
              _c("br"),
              _c("br"),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("vue-cal", {
            ref: "vuecal",
            staticClass: "vuecal--blue-theme vuecal--rounded-theme",
            attrs: {
              events: _vm.calendarEntries,
              timeStep: _vm.timeStep,
              timeFrom: _vm.calendarTimeFrom,
              timeTo: _vm.calendarTimeTo,
              minDate: _vm.calendarMinDate,
              maxDate: _vm.calendarMaxDate,
              selectedDate: _vm.calendarMinDate,
              activeView: _vm.activeView,
              minEventWidth: _vm.minEventWidth,
              timeCellHeight: _vm.timeCellHeight,
              twelveHour: _vm.twelveHour,
              disableViews: ["years", "year", "month"],
            },
            on: { "view-change": _vm.onViewChange },
            scopedSlots: _vm._u([
              {
                key: "event",
                fn: function ({ event }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.onBackward },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "float-right mr-2 mt-2",
                          staticStyle: {
                            border: "none",
                            "background-color": "transparent",
                            color: "grey",
                            pointer: "cursor",
                          },
                          attrs: { icon: "send-backward", fas: "", size: "1x" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _c("div", {
                      staticClass: "vuecal__event external_event",
                      style:
                        "height:" +
                        _vm.oneMinuteHeight * event.preEventMargin +
                        "px;border:none;display:inline-block;",
                    }),
                    _c(
                      "div",
                      {
                        style:
                          "margin-top:" +
                          _vm.oneMinuteHeight * event.preEventMargin +
                          "px;",
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "float-left" },
                            [
                              _c("mdb-icon", {
                                staticClass: "ml-1 mt-1",
                                staticStyle: { color: "grey" },
                                attrs: {
                                  icon: _vm.getItineraryIcon(
                                    event.itineraryEntryTypeCd,
                                    event.itineraryEntrySubTypeCd
                                  ),
                                  fas: "",
                                  size: "2x",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "float-left" }, [
                            event.boundaryType == "BoundaryStart"
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          position: "bottom",
                                          arrow: true,
                                        },
                                        expression:
                                          "{ position: 'bottom', arrow: true }",
                                      },
                                    ],
                                    style:
                                      "border:none; background-color:inherit;color:grey;",
                                    attrs: {
                                      type: "button",
                                      title: _vm.getEventDateRange(event),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "ml-1 mt-1",
                                      staticStyle: {
                                        color: "grey",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        icon: "arrow-from-top",
                                        fas: "",
                                        size: "2x",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            event.boundaryType == "BoundaryEnd"
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          position: "bottom",
                                          arrow: true,
                                        },
                                        expression:
                                          "{ position: 'bottom', arrow: true }",
                                      },
                                    ],
                                    style:
                                      "border:none; background-color:inherit;color:grey;",
                                    attrs: {
                                      type: "button",
                                      title: _vm.getEventDateRange(event),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "ml-1 mt-1",
                                      staticStyle: {
                                        color: "grey",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        icon: "arrow-from-bottom",
                                        fas: "",
                                        size: "2x",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          event.groupName.length > 0
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Group: " +
                                      _vm._s(event.groupName) +
                                      " (" +
                                      _vm._s(event.groupSize) +
                                      ")"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "float-right mt-1" },
                            [
                              _c("mdb-icon", {
                                staticClass: "mr-1",
                                attrs: { size: "1x" },
                              }),
                              _c(
                                "button",
                                {
                                  style:
                                    "border:none; background-color:inherit;color:grey;",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItineraryEntry(event)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "ellipsis-v", size: "1x" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "vuecal__event-title" }, [
                          _vm._v(" " + _vm._s(event.title) + " "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { position: "bottom", arrow: true },
                                  expression:
                                    "{ position: 'bottom', arrow: true }",
                                },
                              ],
                              style:
                                "border:none; background-color:inherit;color:grey;",
                              attrs: {
                                type: "button",
                                title: _vm.getEventInformation(event),
                              },
                            },
                            [
                              _c("mdb-icon", {
                                staticStyle: {
                                  color: "grey",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  icon: "info-circle",
                                  fas: "",
                                  size: "1x",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticClass: "vuecal__event external_event",
                      style:
                        "height:" +
                        _vm.oneMinuteHeight * event.postEventMargin +
                        "px;border:none;top:" +
                        _vm.calculatePostEventTopPosition(event) +
                        "px;",
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("itinerary-entry-dialog", {
        attrs: {
          itineraryReference: _vm.itineraryReference,
          travelBookingParticipantCount: 30,
          itineraryStartDate: _vm.itineraryStartDate,
          itineraryEndDate: _vm.itineraryEndDate,
          dialogVisible: _vm.itineraryEntryDialogVisible,
          orders: _vm.orders,
        },
        on: {
          close: _vm.onClose,
          save: _vm.onItineraryEntrySave,
          delete: _vm.onItineraryEntryDelete,
        },
        model: {
          value: _vm.selectedItineraryEntry,
          callback: function ($$v) {
            _vm.selectedItineraryEntry = $$v
          },
          expression: "selectedItineraryEntry",
        },
      }),
      _c("itinerary-day-note-edit", {
        attrs: { dialogVisible: _vm.itineraryDayNoteDialogVisible },
        on: { close: _vm.onItineraryDayNoteDialogClose },
        model: {
          value: _vm.itinerary,
          callback: function ($$v) {
            _vm.itinerary = $$v
          },
          expression: "itinerary",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }