var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asoft-duration-picker",
    _vm._g(
      _vm._b(
        {
          attrs: {
            backgroundColor: _vm.backgroundColor,
            labelColor: _vm.labelColor,
            activeLabelColor: _vm.activeLabelColor,
            activeColor: _vm.activeColor,
          },
        },
        "asoft-duration-picker",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }