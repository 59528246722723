<template>
  <mdb-nav-item
    :id="'nav-' + menuItem.title"
    v-if="menuItem.menuItems.length == 0"
    :to="{ path: menuItem.routePath }"
    :icon="menuItem.icon"
    :style="{ backgroundColor }"
    >{{ menuItem.title }}</mdb-nav-item
  >
  <mdb-dropdown tag="li" class="nav-item" v-else :id="'nav-' + menuItem.title">
    <mdb-dropdown-toggle
      tag="a"
      navLink
      slot="toggle"
      waves-fixed
      :icon="menuItem.icon"
      :color="backgroundColor"
      >{{ menuItem.title }}</mdb-dropdown-toggle
    >
    <mdb-dropdown-menu>
      <template v-for="subMenuItem in menuItem.menuItems">
        <mdb-dropdown-item
          :id="'nav-' + menuItem.title + '-' + subMenuItem.title"
          :key="subMenuItem.title"
          :to="{ path: subMenuItem.routePath }"
          header
        >
          {{ subMenuItem.title }}
        </mdb-dropdown-item>
      </template>
    </mdb-dropdown-menu>
  </mdb-dropdown>
</template>

<script>
import {
  mdbNavItem,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownMenu,
  mdbDropdownItem,
} from "mdbvue";

import variables from "@/styles/sharedVariables.scss";

export default {
  components: {
    mdbNavItem,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
  },
  computed: {
    isMenuItem: function () {
      return this.menuItem.menuItems.length == 0;
    },
  },
  props: {
    menuItem: { type: Object, required: true },
    backgroundColor: { type: String },
    textColor: { type: String, default: variables.awgtMenuLinkTextColor },
    listBackgroundColor: {
      type: String,
      default: variables.awgtMenuDropdownListColor,
    },
    listTextColor: {
      type: String,
      default: variables.awgtMenuDropdownTextColor,
    },
  },
  mounted() {
    if (this.textColor) {
      let el = this.isMenuItem
        ? this.$el.querySelector(".nav-item > a")
        : this.$el.querySelector(".dropdown-toggler > a");

      el.style.color = this.textColor;
    }
    if (!this.isMenuItem && this.listBackgroundColor) {
      let list = this.$el.querySelector(".dropdown-menu");

      list.style.backgroundColor = this.listBackgroundColor;
    }
    if (!this.isMenuItem && this.listTextColor) {
      let items = this.$el.querySelectorAll(".dropdown-menu > a");
      items.forEach((item) => (item.style.color = this.listTextColor));
    }
  },
};
</script>
