<template>
  <mdb-container fluid>
    <h1 class="text-center mb-3">Communication Edit</h1>
    <form novalidate @submit.prevent="onSubmit">
      <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
        <mdb-form-inline
          class="flex-wrap border flex-grow-1 mx-2 px-3 py-3 mb-2"
        >
          <span class="mr-3" style="min-width: 40px">Contact With</span>
          <awgt-input
            id="contactType_Client"
            v-model="communication.CommunicationWith"
            type="radio"
            name="contactTypeGroup"
            label="Client"
            class="mx-2"
            radioValue="CW_C"
          />
          <awgt-input
            id="contactType_Supplier"
            v-model="communication.CommunicationWith"
            type="radio"
            name="contactTypeGroup"
            label="Supplier"
            class="mx-2"
            radioValue="CW_S"
          />
          <awgt-input
            id="contactType_Other"
            v-model="communication.CommunicationWith"
            type="radio"
            name="contactTypeGroup"
            label="Other"
            class="mx-2"
            radioValue="CW_O"
          />
        </mdb-form-inline>
        <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="calendar-alt"
              far
              color="primary"
              class="mx-2"
              size="2x"
              style="width: 34px"
            />
            <asoft-date-picker
              v-model="communication.OccurrenceDT"
              label="Occurrence Date"
              autoHide
              displayDateFormat="D MMM YYYY"
              dataDateFormat="YYYY-MM-DDTHH:mm:ss"
              setTime="00:00:00.000"
              bg
              class="flex-grow-1 mx-2"
              style="min-width: 140px"
              :width="300"
            />
          </div>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="clock"
              far
              color="primary"
              class="mx-2"
              size="2x"
              style="width: 34px"
            />
            <asoft-time-picker
              v-model="startTime"
              label="Occurrence Time"
              bg
              class="flex-grow-1 my-0 mr-2"
              style="width: 180px"
              displayFormat="h:mma"
              outputFormat="HH:mm"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div
          v-if="communication.CommunicationWith == 'CW_C'"
          class="d-flex flex-row flex-wrap flex-grow-1"
        >
          <awgt-std-dropdown
            search
            label="Client"
            style="min-width: 90px"
            class="flex-grow-1 mx-2"
            itemValueProperty="Code"
            :items="clients"
            itemTextProperty="Name"
            v-model="communication.RelatedClientReference"
            bg
          ></awgt-std-dropdown>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="user"
              color="primary"
              class="mx-2"
              size="2x"
              style="width: 34px; text-align: center"
            />
            <awgt-input
              id="communication-person-name-input"
              v-model="communication.ContactNm"
              counter
              :counterMaxValue="100"
              maxlength="100"
              style="min-width: 300px"
              label="Contact Name"
              bg
              class="flex-grow-1 mr-2"
            ></awgt-input>
          </div>
          <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <awgt-std-dropdown
              :disabled="
                this.communication.RelatedClientReference != null &&
                this.communication.RelatedClientReference.length > 0
                  ? false
                  : true
              "
              :label="
                this.communication.RelatedClientReference != null &&
                this.communication.RelatedClientReference.length > 0
                  ? 'Booking'
                  : 'Booking (Disabled)'
              "
              style="min-width: 90px"
              class="flex-grow-1 mx-2"
              itemValueProperty="Code"
              :items="getBookings"
              itemTextProperty="Name"
              v-model="communication.RelatedBookingReference"
              bg
              @focus="onGetBookingsDropdown"
            ></awgt-std-dropdown>
          </div>
        </div>
        <div
          v-if="communication.CommunicationWith == 'CW_S'"
          class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
        >
          <mdb-icon
            icon="user"
            color="primary"
            class="mx-2"
            size="2x"
            style="width: 34px; text-align: center"
          />
          <awgt-std-dropdown
            search
            label="Supplier"
            style="min-width: 90px"
            class="flex-grow-1 mr-2"
            itemValueProperty="Code"
            :items="suppliers"
            itemTextProperty="Name"
            v-model="communication.RelatedSupplierReference"
            bg
          ></awgt-std-dropdown>
          <mdb-icon
            icon="user"
            color="primary"
            class="mx-2"
            size="2x"
            style="width: 34px; text-align: center"
          />
          <awgt-input
            id="communication-person-name-input"
            v-model="communication.ContactNm"
            counter
            :counterMaxValue="100"
            maxlength="100"
            style="min-width: 300px"
            label="Contact Name"
            bg
            class="flex-grow-1 mr-2"
          ></awgt-input>
        </div>
        <div
          v-if="communication.CommunicationWith == 'CW_O'"
          class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
        >
          <awgt-std-dropdown
            search
            label="Related Client"
            style="min-width: 90px"
            class="flex-grow-1 mx-2"
            itemValueProperty="Code"
            :items="clients"
            itemTextProperty="Name"
            v-model="communication.RelatedClientReference"
            bg
          ></awgt-std-dropdown>
          <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <awgt-std-dropdown
              :disabled="
                this.communication.RelatedClientReference != null &&
                this.communication.RelatedClientReference.length > 0
                  ? false
                  : true
              "
              :label="
                this.communication.RelatedClientReference != null &&
                this.communication.RelatedClientReference.length > 0
                  ? 'Related Booking'
                  : 'Related Booking (Disabled)'
              "
              style="min-width: 90px"
              class="flex-grow-1 mx-2"
              itemValueProperty="Code"
              :items="getBookings"
              itemTextProperty="Name"
              v-model="communication.RelatedBookingReference"
              bg
              @focus="onGetBookingsDropdown"
            ></awgt-std-dropdown>
          </div>
          <awgt-std-dropdown
            search
            label="Related Supplier"
            style="min-width: 90px"
            class="flex-grow-1 mx-2"
            itemValueProperty="Code"
            :items="suppliers"
            itemTextProperty="Name"
            v-model="communication.RelatedSupplierReference"
            bg
          ></awgt-std-dropdown>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="user"
              color="primary"
              class="mx-2"
              size="2x"
              style="width: 34px; text-align: center"
            />
            <awgt-input
              id="communication-person-name-input"
              v-model="communication.ContactNm"
              counter
              :counterMaxValue="100"
              maxlength="100"
              style="min-width: 300px"
              label="Contact Name"
              bg
              class="flex-grow-1 mr-2"
            ></awgt-input>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="phone-volume"
            color="primary"
            class="mx-2"
            size="2x"
            style="width: 34px; text-align: center"
          />
          <awgt-std-dropdown
            label="Communication Medium"
            style="min-width: 90px"
            class="flex-grow-1 mr-2"
            itemValueProperty="Code"
            :items="getCommunicationMediums()"
            itemTextProperty="Name"
            v-model="communication.CommunicationMedium"
            bg
          ></awgt-std-dropdown>
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="people-arrows"
            color="primary"
            class="mx-2"
            size="2x"
            style="width: 34px; text-align: center"
          />
          <awgt-std-dropdown
            label="Communication Direction"
            style="min-width: 90px"
            class="flex-grow-1 mr-2"
            itemValueProperty="Code"
            :items="getCommunicationDirections()"
            itemTextProperty="Name"
            v-model="communication.CommunicationDirection"
            bg
          ></awgt-std-dropdown>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
        <mdb-icon
          icon="user"
          color="primary"
          class="mx-2"
          size="2x"
          style="width: 34px; text-align: center"
        />
        <awgt-std-dropdown
          label="Consultant Name"
          style="min-width: 90px"
          class="flex-grow-1 mr-2"
          itemValueProperty="Code"
          :items="users"
          itemTextProperty="Name"
          v-model="communication.ConsultantUserReference"
          bg
        ></awgt-std-dropdown>
        <div class="d-flex flex-row flex-wrap flex-grow-1">
          <awgt-input
            v-model="communication.Subject"
            counter
            :counterMaxValue="256"
            maxlength="256"
            style="min-width: 300px"
            label="Communication Subject"
            bg
            class="flex-grow-1 mx-2"
          ></awgt-input>
        </div>
        <div
          v-if="communication.CommunicationWith == 'CW_C'"
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
        >
          <awgt-std-dropdown
            search
            label="Related Supplier"
            style="min-width: 90px"
            class="flex-grow-1 mx-2"
            itemValueProperty="Code"
            :items="suppliers"
            itemTextProperty="Name"
            v-model="communication.RelatedSupplierReference"
            bg
          ></awgt-std-dropdown>
        </div>
      </div>
      <div
        v-if="communication.CommunicationWith == 'CW_S'"
        class="d-flex flex-row flex-wrap flex-grow-1"
      >
        <awgt-std-dropdown
          search
          label="Related Client"
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="clients"
          itemTextProperty="Name"
          v-model="communication.RelatedClientReference"
          bg
        ></awgt-std-dropdown>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <awgt-std-dropdown
            :disabled="
              this.communication.RelatedClientReference != null &&
              this.communication.RelatedClientReference.length > 0
                ? false
                : true
            "
            :label="
              this.communication.RelatedClientReference != null &&
              this.communication.RelatedClientReference.length > 0
                ? 'Related Booking'
                : 'Related Booking (Disabled)'
            "
            style="min-width: 90px"
            class="flex-grow-1 mx-2"
            itemValueProperty="Code"
            :items="getBookings"
            itemTextProperty="Name"
            v-model="communication.RelatedBookingReference"
            bg
            @focus="onGetBookingsDropdown"
          ></awgt-std-dropdown>
        </div>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center my-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mx-2 mb-1"
          size="2x"
          style="width: 34px"
        />
        <div>Communication Notes</div>
      </div>
      <mdb-wysiwyg
        v-model="communication.Notes"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <files-upload-without-categories
        v-model="communication.CommunicationFiles"
        @on-files-input="onFilesInput"
        @files-input-error="onFilesInputError"
        @header-message="onFileDownloadMessage"
        ref="fileUpload"
      >
      </files-upload-without-categories>
      <div class="float-right mt-2">
        <awgt-std-button
          type="submit"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          :disabled="!isFormDirty()"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          @click="onCancel()"
        >
          <mdb-icon icon="times" class="mr-1" />Close
        </awgt-std-button>
      </div>
      <ok-prompt ref="alertPrompt"></ok-prompt>
    </form>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbContainer, mdbIcon, mdbFormInline } from "mdbvue";
import { mapGetters } from "vuex";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import asoftTimePicker from "@/components/AtomSoftware/asoftTimePicker/asoftTimePicker";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import bookingApi from "@/api/bookingApi.js";
import clientApi from "@/api/clientApi.js";
import supplierApi from "@/api/supplierApi.js";
import userApi from "@/api/userApi.js";
import okPrompt from "@/components/OkPrompt";
import communicationApi from "@/api/communicationApi.js";
import filesUploadWithoutCategories from "@/components/FilesUploadWithoutCategories.vue";
import tokenManager from "@/shared/securityTokenManager.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";

export default {
  components: {
    mdbContainer,
    awgtInput,
    mdbIcon,
    mdbWysiwyg,
    AwgtStdDropdown,

    asoftDatePicker,
    asoftTimePicker,
    mdbFormInline,
    filesUploadWithoutCategories,
    okPrompt,
    AwgtStdButton,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      formSubmissionErrors: [],
      communication: {},
      communicationSnapshot: {},
      isCommunicationDirtyInd: false,
      suppliers: [],
      clients: [],
      bookings: [],
      users: [],
      files: [],
      startTime: "",
      startTimeSnapshot: "",
      action: "Create",
    };
  },
  computed: {
    ...mapGetters([
      "getEmptyCommunication",
      "getCommunicationMediums",
      "getCommunicationDirections",
      "getValidDateTimeFormats",
    ]),
    getBookings() {
      if (
        this.communication.RelatedClientReference != null &&
        this.communication.RelatedClientReference.length > 0
      ) {
        return this.bookings.map((bookingEntity) => {
          return {
            Name:
              bookingEntity.Description +
              " (" +
              this.$moment(bookingEntity.DepartureDt).format("YYYY") +
              ") " +
              bookingEntity.Reference,
            Code: bookingEntity.Reference,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    "communication.CommunicationWith"() {
      this.communication.RelatedClientReference = null;
      this.communication.RelatedBookingReference = null;
      this.communication.RelatedSupplierReference = null;
    },
  },
  methods: {
    async onGetBookingsDropdown() {
      this.bookings = await bookingApi.getBookingsDropdownForCommunicationAsync(
        this.communication.RelatedClientReference,
        this.action
      );
    },

    async onSubmit() {
      this.$log.info("onSave...");
      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the communication...");
        await this.saveCommunciation(this.communication)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [
                `The communication has been saved. ${
                  this.communication.FileStorageMessages.length > 0
                    ? "However, " +
                      this.communication.FileStorageMessages.join()
                    : ""
                }`,
              ],
            });
            this.$refs.fileUpload.onCleanFiles();
            this.files = [];
            this.snapshotCommunication(this.communication);
          })
          .catch(() => {
            // This has already been logged in communicationApi.js
          });
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
    validateForm() {
      this.formSubmissionErrors = [];
      if (this.communication.OccurrenceDT == null) {
        this.formSubmissionErrors.push("You must enter an occurence date.");
      }
      if (this.startTime.length == 0) {
        this.formSubmissionErrors.push("You must enter an occurence time.");
      }
      if (this.communication.ConsultantUserReference == null) {
        this.formSubmissionErrors.push("You must select a consultant user.");
      }
      if (
        !this.communication.ContactNm ||
        this.communication.ContactNm.trim().length == 0
      ) {
        this.formSubmissionErrors.push("You must enter a contact name.");
      }
      if (
        !this.communication.ContactNm ||
        this.communication.ContactNm.trim().length == 0
      ) {
        this.formSubmissionErrors.push("You must enter a contact name.");
      }
      if (
        !this.communication.CommunicationMedium ||
        this.communication.CommunicationMedium.trim().length == 0
      ) {
        this.formSubmissionErrors.push(
          "You must select a communication medium."
        );
      }
      if (
        !this.communication.CommunicationDirection ||
        this.communication.CommunicationDirection.trim().length == 0
      ) {
        this.formSubmissionErrors.push(
          "You must select a communication direction."
        );
      }
      if (this.communication.CommunicationWith == "CW_C") {
        if (this.communication.RelatedClientReference == null) {
          this.formSubmissionErrors.push(
            "You must select a client if you contact with a client."
          );
        }
      }
      if (this.communication.CommunicationWith == "CW_S") {
        if (this.communication.RelatedSupplierReference == null) {
          this.formSubmissionErrors.push(
            "You must select a supplier if you contact with a supplier."
          );
        }
      }

      return this.formSubmissionErrors.length == 0;
    },
    async saveCommunciation(communication) {
      communication.OccurrenceDT = this.$moment(
        communication.OccurrenceDT,
        this.getValidDateTimeFormats
      )
        .hour(
          this.$moment(
            "01 jan 2020 " + this.startTime,
            "DD MMM YYYY HH:mm"
          ).hour()
        )
        .minute(
          this.$moment(
            "01 jan 2020 " + this.startTime,
            "DD MMM YYYY HH:mm"
          ).minute()
        )
        .format("YYYY-MM-DDTHH:mm:ss");

      let formData = new FormData();
      var hasFiles = false;
      if (this.files.length > 0) {
        hasFiles = true;
        formData.append("communicationString", JSON.stringify(communication));
        for (var i = 0; i < this.files.length; i++) {
          formData.append("files", this.files[i]);
        }
      }
      if (communication.Reference == "")
        this.communication = await communicationApi.postCommunication(
          hasFiles ? formData : communication,
          hasFiles
        );
      else {
        this.communication = await communicationApi.putCommunication(
          hasFiles ? formData : communication,
          hasFiles
        );
      }
      this.startTime = this.$moment(
        this.communication.OccurrenceDT,
        this.getValidDateTimeFormats
      ).format("HH:mm");
    },
    isFormDirty() {
      if (this.isCommunicationDirtyInd == true) return true;

      this.isCommunicationDirtyInd =
        this.$lodash.isEqual(this.communication, this.communicationSnapshot) ==
          false ||
        this.$lodash.isEqual(this.startTime, this.startTimeSnapshot) == false;

      return this.isCommunicationDirtyInd;
    },
    snapshotCommunication(communication) {
      this.communicationSnapshot = this.$lodash.cloneDeep(communication);
      this.startTimeSnapshot = this.$lodash.cloneDeep(this.startTime);

      this.isCommunicationDirtyInd = false;
    },

    async loadFormData(reference) {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      let workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      let currentUserPermissionScopeForCommunication =
        currentUser.UserPermission.find((cp) => {
          if (reference != null) return cp.Permission == "CP_CoU";
          else return cp.Permission == "CP_CoC";
        });

      this.clients = await clientApi.getClientDropdown();
      this.suppliers = await supplierApi.getSupplierDropdown(); //Pull back Code and name Properties only.

      if (currentUserPermissionScopeForCommunication) {
        let permissionScope =
          currentUserPermissionScopeForCommunication.PermissionScope;

        if (permissionScope == "CPS_AA")
          this.users = await userApi.getUserDropdown();
        else {
          let usersDropdown = await userApi.getUserDropdown(
            workgroupsOfCurrentUser.join(),
            currentUser.ConsultancyRole.Level
          );

          if (permissionScope == "CPS_BW") this.users = usersDropdown;
          if (permissionScope == "CPS_NW") {
            this.users = usersDropdown.filter((ud) => {
              return (
                ud.Level < currentUser.ConsultancyRole.Level ||
                ud.Code == currentUser.Reference
              );
            });
          }
        }
      }

      if (reference != null) {
        this.action = "Update";
        let communication = await communicationApi.getCommunicationByReference(
          reference
        );

        if (communication.RelatedBookingReference) {
          let booking = await bookingApi.getBookingByReferenceAsync(
            communication.RelatedBookingReference
          );

          this.bookings = [booking];
        }

        this.communication = communication;

        this.startTime = this.$moment(
          this.communication.OccurrenceDT,
          this.getValidDateTimeFormats
        ).format("HH:mm");
      }
    },
    onFilesInput(files) {
      this.files = files;
    },
    onFilesInputError(errors) {
      this.$refs.alertPrompt.onFirePrompt(
        "Warning",
        `${errors.join(", ")} ${
          errors.length > 1 ? "are" : "is"
        } larger than 10MB so   ${
          errors.length > 1 ? "they" : "it"
        } cannot be added.`
      );
    },
    onFileDownloadMessage(e) {
      this.$emit("header-message", e);
    },
  },
  mounted() {
    this.communication = this.$lodash.cloneDeep(this.getEmptyCommunication);
    let routeParams = this.$route.params;
    this.$log.info("RouteParams:", routeParams);
    let communicationReference = routeParams.communicationReference;

    this.loadFormData(communicationReference)
      .then(() => {
        this.snapshotCommunication(this.communication);
      })
      .catch(() => {
        // Errors have been logged
      });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSubmit().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
