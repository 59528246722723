<template>
  <div class="mb-5">
    <h1 class="text-center">Issue Log Search</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1" style="margin-top: 40px">
      <awgt-input
        v-model="issueName"
        counter
        :counterMaxValue="100"
        maxlength="100"
        style="min-width: 300px"
        label="Issue Name"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-input
        v-model="issueDescription"
        counter
        :counterMaxValue="100"
        maxlength="100"
        style="min-width: 300px"
        label="Description"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-std-dropdown
        label="Issue Status"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getIssueStatuses(true)"
        itemTextProperty="Name"
        v-model="issueStatus"
        bg
      ></awgt-std-dropdown>
    </div>

    <div class="d-flex flex-row flex-wrap" style="margin-top: 10px">
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <mdb-date-picker
          v-model="dateStart"
          label="Start Date"
          autoHide
          :option="datePickerOptions"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
        />
      </div>

      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="mx-2 mt-4"
          size="2x"
        />
        <mdb-date-picker
          v-model="dateEnd"
          label="End Date"
          autoHide
          :option="datePickerOptions"
          bg
          class="flex-grow-1 mx-2"
          style="width: 180px"
        />
      </div>
    </div>
    <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        v-on:click="onSearch()"
      >
        <mdb-icon icon="search" class="mr-1" />Search
      </awgt-std-button>
      <awgt-std-button
        v-if="checkPermission('CP_IsC')"
        type="button"
        class="command-button mx-2"
        v-on:click="onAdd()"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </mdb-form-inline>
    <div v-show="showControl" class="mx-2">
      <div class="d-flex flex-row justify-content-start">
        <div style="width: 100%">Title</div>
        <div style="width: 200px">Status</div>
        <div style="width: 200px">Raised Date</div>
      </div>

      <div
        v-for="(issue, index) in issues"
        :key="index"
        @click="onToggleUserDetailExpansion(index)"
        style="border: 1px solid lightgrey"
      >
        <div class="d-flex flex-row rowstyle">
          <div id="titleID" style="font-size: 20px; width: 100%">
            {{ issue.Title }}
          </div>
          <div style="width: 200px">
            {{ getIssueStatusNameFromCode(issue.SystemIssueStatus) }}
          </div>
          <div style="width: 200px">
            {{
              $moment(issue.RaisedDT, "YYYY-MM-DDTHH:mm:ss").format(
                "D MMM YYYY h:mma"
              )
            }}
          </div>
        </div>
        <expansion-region :toggle="userDetailExpansion[index]">
          <div class="d-flex flex-row">
            <div v-html="issue.Description"></div>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex justify-content-end flex-grow-1">
              <awgt-std-button
                v-if="checkPermission('CP_IsU')"
                type="button"
                title="Products"
                class="command-button mx-2"
                v-on:click="onEdit(issue)"
              >
                <mdb-icon class="mr-1" icon="edit" />Edit
              </awgt-std-button>
              <awgt-std-button
                v-if="checkPermission('CP_IsD')"
                type="button"
                title="Products"
                class="command-button mx-2"
                v-on:click="onDelete(issue)"
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Delete
              </awgt-std-button>
            </div>
          </div>
        </expansion-region>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbDatePicker, mdbIcon, mdbFormInline } from "mdbvue";
import ExpansionRegion from "@/components/ExpansionRegion";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import issueApi from "@/api/systemIssueApi.js";
import { mapGetters } from "vuex";
import { sharedMethods } from "@/shared/shared";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    ExpansionRegion,
    AwgtStdDropdown,
    mdbDatePicker,
    mdbIcon,
    mdbFormInline,
    AwgtStdButton,
    awgtInput,
  },
  computed: {
    ...mapGetters([
      "getIssueStatuses",
      "getIssueStatusNameFromCode",
      "checkPermission",
    ]),
  },
  data() {
    return {
      datePickerOptions: { format: "D MMM YYYY" },
      issues: [],
      userDetailExpansion: [],
      showControl: false,
      issueName: "",
      issueDescription: "",
      issueStatus: "",
      dateStart: "",
      dateEnd: "",
    };
  },
  methods: {
    onSearch() {
      this.showControl = true;
      this.Search().catch(() => {
        // Erros have been logged
      });
    },
    onToggleUserDetailExpansion(index) {
      this.$set(
        this.userDetailExpansion,
        index,
        !this.userDetailExpansion[index]
      );
    },
    onAdd() {
      this.$router.push({
        path: "/admin/issue/add",
      });
    },
    onEdit(issueEntity) {
      this.$router.push({
        path: `/admin/issue/${issueEntity.Reference}/edit`,
      });
    },
    onDelete(issue) {
      let index = this.issues.findIndex((i) => i.Reference == issue.Reference);
      this.issues.splice(index, 1);
      this.Delete(issue);
    },
    async Delete(issue) {
      await issueApi.deleteIssue(issue);
    },
    async Search() {
      this.issues = await issueApi.getIssues(
        this.issueStatus,
        this.issueName,
        this.issueDescription,
        this.dateStart,
        this.dateEnd
      );
    },
  },
  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.onSearch);
  },
  beforeDestroy() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>
<style scoped>
.rowstyle:hover {
  background-color: #923f8244;
}
</style>
