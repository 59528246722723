<template>
  <mdb-navbar v-bind="$attrs" v-on="$listeners" :color="color">
    <template v-if="$slots.default !== undefined">
      <slot />
    </template>
    <!-- Apply a dummy div section to avoid forEach not defined errror in mdb-navbar -->
    <template v-else>
      <div v-show="false"></div>
    </template>
  </mdb-navbar>
</template>

<script>
import { mdbNavbar } from "mdbvue";
import variables from "@/styles/sharedVariables.scss";

export default {
  props: {
    color: { type: String, default: variables.awgtMenuBarBackgroundColor },
  },
  components: { mdbNavbar },
};
</script>
