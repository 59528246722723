<template>
  <div class="mb-3">
    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <slot name="default"></slot>
      <awgt-std-button
        type="button"
        class="command-button"
        style="min-width: 100px"
        @click="ModifyGroups()"
      >
        <mdb-icon icon="edit" class="mr-1" />Modify
      </awgt-std-button>
    </div>
    This product is in the following product groups:
    <div
      v-if="resellerProductGroupsWithOtherProducts.length > 0"
      class="pl-3 py-2"
    >
      <ul
        v-for="(group, index) in resellerProductGroupsWithOtherProducts"
        :key="index"
      >
        <li>
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
              <div>
                {{ group.Name }}
              </div>
              <div
                v-if="
                  group.OtherProductsInGroup != undefined &&
                  group.OtherProductsInGroup.length > 0
                "
              >
                Other products in this group are:
                <ul
                  v-for="(inGroup, index2) in group.OtherProductsInGroup"
                  :key="index2"
                >
                  <li>{{ inGroup.Name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <i>This product is not in any Reseller Product Groups</i>
    </div>
    <mdb-modal :show="showEditor">
      <asoft-modal-header label="Reseller Product Groups" @close="CloseEditor">
      </asoft-modal-header>
      <mdb-modal-body>
        <div v-if="resellerSupplierGroups.length > 0">
          <div class="mb-2">
            Following are the product groups defined for this product's reseller
            supplier. This product is in the selected groups.
          </div>
          <table class="table table-bordered">
            <tr v-for="(group, index) in resellerSupplierGroups" :key="index">
              <td>
                <awgt-input
                  :id="'group' + index"
                  type="checkbox"
                  name="resellerSupplierGroup"
                  :label="group.Name"
                  v-model="group.IsSelectedInd"
                ></awgt-input>
                <template v-if="group.OtherProductsInGroup.length > 0">
                  Also containing:
                  <ul
                    v-for="(
                      productInGroup, index2
                    ) in group.OtherProductsInGroup"
                    :key="index2"
                  >
                    <li>{{ productInGroup.Name }}</li>
                  </ul>
                </template>
                <div
                  style="color: darkred"
                  v-if="groupHasSeparatelySoldProducts(group) == false"
                >
                  Warning: This group requires a product that can be sold
                  separately.
                </div>
              </td>
              <td style="width: 120px">
                <awgt-std-button
                  class="command-button"
                  type="button"
                  @click="OpenRenameGroup(group)"
                >
                  <mdb-icon icon="edit" class="mr-1" />Rename
                </awgt-std-button>
              </td>
              <td style="width: 120px">
                <awgt-std-button
                  class="command-button"
                  type="button"
                  @click="DeleteGroup(index)"
                  :disabled="
                    group.OtherProductsInGroup.length > 0 ||
                    group.IsSelectedInd == true
                  "
                >
                  <mdb-icon icon="trash-alt" class="mr-1" />Delete
                </awgt-std-button>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="mb-2">
          This supplier has no product groups defined. Press "Add" to create a
          product group.
        </div>

        <div v-if="mode == 'add' || mode == 'rename'">
          <awgt-input
            v-model="groupName"
            counter
            :counterMaxValue="50"
            maxlength="50"
            label="Group Name"
            bg
          ></awgt-input>
          <div class="float-right">
            <awgt-std-button
              class="command-button mr-2"
              type="button"
              @click="SaveGroupName()"
              :disabled="groupName.trim().length == 0"
            >
              Save
            </awgt-std-button>
            <awgt-std-button
              type="button"
              class="command-button"
              @click="CloseEdit()"
            >
              Cancel
            </awgt-std-button>
          </div>
        </div>
        <div v-else class="float-right">
          <awgt-std-button
            class="command-button"
            type="button"
            @click="OpenAddGroup()"
            :disabled="mode != 'select'"
          >
            <mdb-icon icon="plus" class="mr-1" />Add
          </awgt-std-button>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="CloseEditor()"
        >
          Close
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import { mapGetters } from "vuex";
import { mdbIcon, mdbModal, mdbModalBody, mdbModalFooter } from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    mdbModal,
    asoftModalHeader,
    mdbModalBody,
    mdbModalFooter,
  },

  data() {
    return {
      resellerSupplierGroups: [],
      selectedGroup: null,
      groupName: "",
      showEditor: false,
      mode: "select",
    };
  },

  props: {
    value: {
      type: Array,
    },

    parentProduct: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters(["getTooltipsTextByCode"]),

    resellerProductGroupsWithOtherProducts() {
      return this.resellerSupplierGroups.filter(
        (rsg) => rsg.IsSelectedInd == true
      );
    },
  },

  methods: {
    OpenAddGroup() {
      this.mode = "add";
    },

    SaveGroupName() {
      if (this.mode == "add") {
        let group = {
          Name: this.groupName.trim(),
          IsSelectedInd: false,
          OtherProductsInGroup: [],
        };
        this.resellerSupplierGroups.push(group);
      } else {
        //Rename
        this.selectedGroup.Name = this.groupName.trim();
      }
      this.CloseEdit();
      this.groupName = "";
    },

    groupHasSeparatelySoldProducts(group) {
      //If the parent product is in this group and
      //can be sold separately then return true
      if (
        group.IsSelectedInd == true &&
        this.parentProduct.CanBeSoldSeparatelyInd == true
      )
        return true;

      if (
        group.OtherProductsInGroup &&
        group.OtherProductsInGroup.some((p) => p.CanBeSoldSeparatelyInd == true)
      )
        return true;

      return false;
    },

    ModifyGroups() {
      this.mode = "select";
      this.groupName = "";
      this.showEditor = true;
    },

    OpenRenameGroup(group) {
      this.groupName = group.Name;
      this.selectedGroup = group;
      this.mode = "rename";
    },

    CloseEdit() {
      this.mode = "select";
      this.selectedGroup = null;
    },

    CloseEditor() {
      this.showEditor = false;
      this.$emit("input", this.resellerSupplierGroups);
    },

    DeleteGroup(idx) {
      this.resellerSupplierGroups.splice(idx, 1);
    },
  },

  watch: {
    value(to) {
      this.resellerSupplierGroups = this.$lodash.cloneDeep(to);
    },
  },
};
</script>
