function filterMenu(menus, UserPermission) {
  menus.push({
    title: "Home",
    icon: "home",
    routePath: "/",
    menuItems: [],
  });

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_TBV";
    }) >= 0
  ) {
    menus.push({
      title: "Travel Bookings",
      icon: "luggage-cart",
      menuItems: [{ title: "Search", routePath: "/booking" }],
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_CIV";
    }) >= 0
  ) {
    menus.push({
      title: "Clients",
      icon: "user-friends",
      menuItems: [{ title: "Search", routePath: "/client" }],
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_PrV";
    }) >= 0
  ) {
    menus.push({
      title: "Products",
      icon: "umbrella-beach",
      menuItems: [{ title: "Products", routePath: "/product" }],
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_SuV";
    }) >= 0
  ) {
    menus.push({
      title: "Suppliers",
      icon: "dolly",
      menuItems: [{ title: "Supplier", routePath: "/supplier" }],
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_CoV";
    }) >= 0
  ) {
    menus.push({
      title: "Communications",
      icon: "comments",
      menuItems: [{ title: "Search", routePath: "/communication" }],
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_ReV";
    }) >= 0
  ) {
    menus.push({
      title: "Reports",
      icon: "file-pdf",
      menuItems: [
        {
          title: "Travel Bookings Report",
          routePath: "/report/TravelBookingsReport",
        },
        {
          title: "Itinerary Entries Report",
          routePath: "/report/ItineraryEntriesReport",
        },
        {
          title: "Supplier Product Information",
          routePath: "/report/SupplierProductInformation",
        },
        {
          title: "User Activity Report",
          routePath: "/report/UserActivityReport",
        },
      ],
    });
  }

  menus.push({
    title: "Admin",
    icon: "user-tie ",
    menuItems: [],
  });

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_UsV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Manage Users",
      routePath: "/admin/user",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_WgV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Manage Workgroups",
      routePath: "/admin/workgroup",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_ImV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Impersonation",
      routePath: "/admin/userimpersonation",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_SAV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "System Actions",
      routePath: "/admin/systemaction",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_CRV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Manage Country Regions",
      routePath: "/admin/countryRegions",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_IsV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Issue Log",
      routePath: "/admin/issue",
    });
  }

  if (
    UserPermission.findIndex((up) => {
      return up.Permission == "CP_TtV";
    }) >= 0
  ) {
    menus[menus.length - 1].menuItems.push({
      title: "Tooltips",
      routePath: "/admin/tooltips",
    });
  }

  return menus;
}

function checkPermission(userPermission, toPath) {
  //Check booking permission
  if (checkBookingRouter(userPermission, toPath)) return false;
  //Check itinerary permission
  if (checkItineraryRouter(userPermission, toPath)) return false;
  //Check client permission
  if (checkClientRouter(userPermission, toPath)) return false;
  //Check product permission
  if (checkProductRouter(userPermission, toPath)) return false;
  //Check supplier permission
  if (checkSupplierRouter(userPermission, toPath)) return false;
  //Check communication permission
  if (checkCommunicationRouter(userPermission, toPath)) return false;
  //Check report permission
  if (checkReportRouter(userPermission, toPath)) return false;
  //Check user impersonation permission
  if (checkUserImpersonationRouter(userPermission, toPath)) return false;
  //Check manage user permission
  if (checkManagerUserRouter(userPermission, toPath)) return false;
  //Check manage workgroup permission
  if (checkManagerWorkgroupRouter(userPermission, toPath)) return false;
  //Check country region permission
  if (checkCountryRegionRouter(userPermission, toPath)) return false;
  //Check system action permission
  if (checkSystemActionRouter(userPermission, toPath)) return false;
  //Check issue permission
  if (checkIssueRouter(userPermission, toPath)) return false;
  //Check tooltip permission
  if (checkTooltipRouter(userPermission, toPath)) return false;

  return true;
}

function checkClientRouter(permission, toPath) {
  var patt = new RegExp("/client/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_CIV";
    }) == -1 &&
      toPath.fullPath.includes("/client")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_CIC";
    }) == -1 &&
      toPath.fullPath.includes("/client/Add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_CIU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkIssueRouter(permission, toPath) {
  var patt = new RegExp("/admin/issue/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_IsV";
    }) == -1 &&
      toPath.fullPath.includes("/admin/issue")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_IsC";
    }) == -1 &&
      toPath.fullPath.includes("/admin/issue/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_IsU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkBookingRouter(permission, toPath) {
  var patt = new RegExp("/booking/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_TBV";
    }) == -1 &&
      toPath.fullPath.includes("/booking")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_TBC";
    }) == -1 &&
      toPath.fullPath.includes("/booking/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_TBU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkItineraryRouter(permission, toPath) {
  var pattList = new RegExp("/booking/(.*)/itinerary/(.*)/list(.*)");
  var pattAdd = new RegExp("/booking/(.*)/itinerary/(.*)/add(.*)");
  var pattEdit = new RegExp("/booking/(.*)/itinerary/(.*)/edit(.*)");
  var pattEntries = new RegExp(
    "/booking/(.*)/itinerary/(.*)/itineraryentries(.*)"
  );
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_ItU";
    }) == -1 &&
      pattEntries.test(toPath.fullPath)) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_ItV";
    }) == -1 &&
      pattList.test(toPath.fullPath)) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_ItC";
    }) == -1 &&
      pattAdd.test(toPath.fullPath)) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_ItU";
    }) == -1 &&
      pattEdit.test(toPath.fullPath))
  );
}

function checkProductRouter(permission, toPath) {
  var patt = new RegExp("/product/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_PrV";
    }) == -1 &&
      toPath.fullPath.includes("/product")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_PrC";
    }) == -1 &&
      toPath.fullPath.includes("/product/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_PrU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkSupplierRouter(permission, toPath) {
  var patt = new RegExp("/supplier/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_SuV";
    }) == -1 &&
      toPath.fullPath.includes("/supplier")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_SuC";
    }) == -1 &&
      toPath.fullPath.includes("/supplier/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_SuU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkCommunicationRouter(permission, toPath) {
  var patt = new RegExp("/communication/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_CoV";
    }) == -1 &&
      toPath.fullPath.includes("/communciation")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_CoC";
    }) == -1 &&
      toPath.fullPath.includes("/communciation/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_CoU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkReportRouter(permission, toPath) {
  return (
    permission.findIndex((p) => {
      return p.Permission == "CP_ReV";
    }) == -1 && toPath.fullPath.includes("/report")
  );
}

function checkUserImpersonationRouter(permission, toPath) {
  return (
    permission.findIndex((p) => {
      return p.Permission == "CP_ImV";
    }) == -1 && toPath.fullPath.includes("/admin/userimpersonation")
  );
}

function checkManagerUserRouter(permission, toPath) {
  var patt = new RegExp("/admin/user/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_UsV";
    }) == -1 &&
      toPath.fullPath.includes("/admin/user")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_UsC";
    }) == -1 &&
      toPath.fullPath.includes("/admin/user/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_UsU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkManagerWorkgroupRouter(permission, toPath) {
  var patt = new RegExp("/admin/workgroup/(.*)/edit(.*)");
  return (
    (permission.findIndex((p) => {
      return p.Permission == "CP_WgV";
    }) == -1 &&
      toPath.fullPath.includes("/admin/workgroup")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_WgC";
    }) == -1 &&
      toPath.fullPath.includes("/admin/workgroup/add")) ||
    (permission.findIndex((p) => {
      return p.Permission == "CP_WgU";
    }) == -1 &&
      patt.test(toPath.fullPath))
  );
}

function checkCountryRegionRouter(permission, toPath) {
  return (
    permission.findIndex((p) => {
      return p.Permission == "CP_CRV";
    }) == -1 && toPath.fullPath.includes("/admin/countryregions")
  );
}

function checkSystemActionRouter(permission, toPath) {
  return (
    permission.findIndex((p) => {
      return p.Permission == "CP_SAV";
    }) == -1 && toPath.fullPath.includes("/admin/systemaction")
  );
}

function checkTooltipRouter(permission, toPath) {
  return (
    permission.findIndex((p) => {
      return p.Permission == "CP_TtV";
    }) == -1 && toPath.fullPath.includes("/admin/tooltips")
  );
}

export default { filterMenu: filterMenu, checkPermission: checkPermission };
