var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-3" },
    [
      _c("h1", { staticClass: "text-center mb-3" }, [
        _vm._v("Edit Order Product Line"),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
        },
        [
          _c("mdb-icon", {
            staticClass: "mr-2",
            staticStyle: { width: "36px" },
            attrs: {
              icon: _vm.getProductIcon(
                _vm.product.ProductType,
                _vm.product.ProductSubType
              ),
              size: "2x",
              color: "primary",
            },
          }),
          _c("h5", { staticClass: "my-0" }, [
            _vm._v(
              " " +
                _vm._s(_vm.orderProduct.ProviderSupplierName) +
                " - " +
                _vm._s(_vm.orderProduct.Name) +
                " - " +
                _vm._s(_vm.currentProductLineChronology.Name) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("div", {
        domProps: {
          innerHTML: _vm._s(_vm.currentProductLineChronology.Description),
        },
      }),
      _c("div", { staticClass: "mt-3" }, [
        _c("h5", [_vm._v("Pricing Scales")]),
        _vm.currentProductLineChronology.isIndicativePricingInd == true
          ? _c("div", [
              _c("i", [
                _vm._v(
                  "Prices displayed are indicative for " +
                    _vm._s(_vm.formatDate(_vm.orderProduct.PricedForDt)) +
                    "."
                ),
              ]),
            ])
          : _c("div", [
              _c("i", [
                _vm._v(
                  "Prices displayed are actual expected prices for " +
                    _vm._s(_vm.formatDate(_vm.orderProduct.PricedForDt)) +
                    "."
                ),
              ]),
            ]),
        _c(
          "table",
          { staticClass: "pricing-table" },
          [
            _c(
              "tr",
              { staticClass: "pricing-table" },
              [
                _vm._l(
                  _vm.currentProductLineChronology.PricingScaleTypes,
                  function (pst, index) {
                    return _c(
                      "td",
                      {
                        key: index,
                        staticClass: "pricing-table text-center",
                        attrs: { colspan: "2" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getPluralQuantityUnit(
                                pst.QuantityUnitCd,
                                _vm.currentProductLineChronology.PluralItemAlias
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }
                ),
                _c(
                  "td",
                  {
                    staticClass: "pricing-table text-center",
                    attrs: { colspan: "3" },
                  },
                  [_vm._v("Pricing")]
                ),
              ],
              2
            ),
            _c(
              "tr",
              { staticClass: "pricing-table" },
              [
                _vm._l(
                  _vm.currentProductLineChronology.ProductLinePrices[0]
                    .PricingScales,
                  function (ps, index) {
                    return [
                      _c(
                        "td",
                        { key: "min" + index, staticClass: "pricing-table" },
                        [_vm._v("Min")]
                      ),
                      _c(
                        "td",
                        { key: "max" + index, staticClass: "pricing-table" },
                        [_vm._v("Max")]
                      ),
                    ]
                  }
                ),
                _c("td", { staticClass: "pricing-table" }, [_vm._v("ExGST")]),
                _c("td", { staticClass: "pricing-table" }, [_vm._v("GST")]),
                _c("td", { staticClass: "pricing-table" }, [_vm._v("Price")]),
              ],
              2
            ),
            _vm._l(
              _vm.currentProductLineChronology.ProductLinePrices,
              function (plp, index) {
                return _c(
                  "tr",
                  { key: index },
                  [
                    _vm._l(plp.PricingScales, function (ps, index2) {
                      return [
                        _c(
                          "td",
                          { key: "min" + index2, staticClass: "pricing-table" },
                          [_vm._v(" " + _vm._s(ps.MinQuantity) + " ")]
                        ),
                        _c(
                          "td",
                          { key: "max" + index2, staticClass: "pricing-table" },
                          [_vm._v(" " + _vm._s(ps.MaxQuantity) + " ")]
                        ),
                      ]
                    }),
                    _c("td", { staticClass: "pricing-table" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPricingString(plp.ExGSTUnitAmt)
                          ),
                        },
                      }),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPricingString(plp.GSTUnitAmt)
                          ),
                        },
                      }),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPricingString(
                              plp.GSTUnitAmt + plp.ExGSTUnitAmt
                            )
                          ),
                        },
                      }),
                    ]),
                  ],
                  2
                )
              }
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("h5", [_vm._v("Market Categories")]),
          _vm._v(
            " This product line will be distributed across the following market categories: "
          ),
          _vm.currentProductLineChronology.MarketCategories.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", [
                  _vm._v("This product line has no market categories defined."),
                ]),
              ])
            : _vm._e(),
          _vm._l(
            _vm.workingOrderProductLine.OrderProductLineMarketCategories,
            function (marketCategory, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                },
                [
                  _vm._v(" " + _vm._s(marketCategory.Name) + " "),
                  _c("awgt-input", {
                    directives: [
                      {
                        name: "numeric",
                        rawName: "v-numeric",
                        value: {
                          precision: 3,
                          scale: 0,
                          posOnly: true,
                          trailingZeroScale: 0,
                        },
                        expression:
                          "{\n          precision: 3,\n          scale: 0,\n          posOnly: true,\n          trailingZeroScale: 0,\n        }",
                      },
                    ],
                    staticClass: "ml-2",
                    staticStyle: { width: "80px" },
                    attrs: {
                      counter: "",
                      counterMaxValue: 5,
                      maxlength: "5",
                      label: "Quantity",
                      bg: "",
                      type: "text",
                    },
                    model: {
                      value: marketCategory.ConsumerNum,
                      callback: function ($$v) {
                        _vm.$set(marketCategory, "ConsumerNum", _vm._n($$v))
                      },
                      expression: "marketCategory.ConsumerNum",
                    },
                  }),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
      _c("div", { staticClass: "mt-3" }, [
        _c("h5", [_vm._v("This Purchase")]),
        _c("div", [
          _vm._v(" Minimum Total Price: "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.formatPricingString(
                  _vm.currentProductLineChronology.MinTotalPriceAmt
                )
              ),
            },
          }),
        ]),
        _vm.currentProductLineChronology.PriceTypeCd == "PrT_Q"
          ? _c(
              "div",
              [
                _vm._v(
                  " This product line is priced by quote. Please provided the quoted price: "
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                  [
                    _c("span", { staticStyle: { width: "100px" } }, [
                      _vm._v("Price Type:"),
                    ]),
                    _c("awgt-input", {
                      attrs: {
                        id: "price_GSTIn",
                        type: "radio",
                        name: "PriceTypeGroup",
                        label: "GST Incl.",
                        radioValue: "PrT_I",
                      },
                      model: {
                        value: _vm.quotedPriceTypeCd,
                        callback: function ($$v) {
                          _vm.quotedPriceTypeCd = $$v
                        },
                        expression: "quotedPriceTypeCd",
                      },
                    }),
                    _c("awgt-input", {
                      attrs: {
                        id: "price_GSTEx",
                        type: "radio",
                        name: "PriceTypeGroup",
                        label: "GST Excl.",
                        radioValue: "PrT_E",
                      },
                      model: {
                        value: _vm.quotedPriceTypeCd,
                        callback: function ($$v) {
                          _vm.quotedPriceTypeCd = $$v
                        },
                        expression: "quotedPriceTypeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c("price-breakdown-control", {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 ml-2 align-items-center",
                  attrs: {
                    PriceTypeCd: _vm.quotedPriceTypeCd,
                    ExGSTUnitAmt: 0,
                    GSTUnitAmt: 0,
                  },
                  on: { change: _vm.quotedPriceChange },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", [
          _vm._v(" " + _vm._s(_vm.getProductsSoldByStatement()) + " "),
        ]),
        _c(
          "table",
          { staticClass: "pricing-table" },
          [
            _vm._l(
              _vm.currentProductLineChronology.ProductLinePrices[0]
                .PricingScales,
              function (ps, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    staticClass: "pricing-table",
                    attrs: { colspan: "2" },
                  },
                  [
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v(
                        " # of " +
                          _vm._s(
                            _vm.getPluralQuantityUnit(
                              ps.QuantityUnitCd,
                              _vm.currentProductLineChronology.PluralItemAlias
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "pricing-table py-0",
                        attrs: { colspan: "3" },
                      },
                      [
                        _c("awgt-input", {
                          directives: [
                            {
                              name: "numeric",
                              rawName: "v-numeric",
                              value: {
                                precision: 4,
                                scale: 0,
                                posOnly: true,
                                allowEmptyString: true,
                              },
                              expression:
                                "{\n              precision: 4,\n              scale: 0,\n              posOnly: true,\n              allowEmptyString: true,\n            }",
                            },
                          ],
                          staticClass: "flex-grow-1 ml-2",
                          attrs: {
                            maxlength: "4",
                            label: "#",
                            bg: "",
                            type: "text",
                          },
                          model: {
                            value:
                              _vm.workingOrderProductLine
                                .OrderProductLineQuantities[index].Quantity,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.workingOrderProductLine
                                  .OrderProductLineQuantities[index],
                                "Quantity",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "\n              workingOrderProductLine.OrderProductLineQuantities[index]\n                .Quantity\n            ",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }
            ),
            _c("tr", { staticClass: "pricing-table" }, [
              _c("td", { staticClass: "pricing-table" }, [
                _vm._v("Ex-GST Price"),
              ]),
              _c(
                "td",
                { staticClass: "pricing-table" },
                [
                  _vm.CalculatedPrice == null
                    ? [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("Price not found"),
                        ]),
                      ]
                    : [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(_vm.CalculatedPrice.exGST)
                            ),
                          },
                        }),
                      ],
                ],
                2
              ),
            ]),
            _c("tr", { staticClass: "pricing-table" }, [
              _c("td", { staticClass: "pricing-table" }, [_vm._v("GST Price")]),
              _c(
                "td",
                { staticClass: "pricing-table" },
                [
                  _vm.CalculatedPrice == null
                    ? [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("Price not found"),
                        ]),
                      ]
                    : [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(_vm.CalculatedPrice.gst)
                            ),
                          },
                        }),
                      ],
                ],
                2
              ),
            ]),
            _c("tr", { staticClass: "pricing-table" }, [
              _c("td", { staticClass: "pricing-table" }, [
                _vm._v("Total Price"),
              ]),
              _c(
                "td",
                { staticClass: "pricing-table" },
                [
                  _vm.CalculatedPrice == null
                    ? [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("Price not found"),
                        ]),
                      ]
                    : [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(_vm.CalculatedPrice.total)
                            ),
                          },
                        }),
                        _vm.CalculatedPrice.comment.length > 0
                          ? _c("tdoxTooltip", {
                              attrs: { detail: _vm.CalculatedPrice.comment },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]),
          ],
          2
        ),
      ]),
      _vm.currentProductLineChronology.PriceTypeCd != "PrT_Z" &&
      _vm.currentProductLineChronology.PriceTypeCd != "PrT_Q"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3 mb-n2",
              },
              [
                _c("h5", [_vm._v("Price Adjustments")]),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button ml-auto",
                    staticStyle: {
                      width: "120px",
                      "padding-left": "0",
                      "padding-right": "0",
                    },
                    attrs: { type: "button" },
                    on: { click: _vm.onOpenPriceAdjustmentsDialog },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "dollar" },
                    }),
                    _vm._v("Adjust Price "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", [
              _vm._v(
                " The following price adjustments will be applied to this product line: "
              ),
            ]),
            _vm.workingOrderProductLine.OrderProductLinePriceAdjustments
              .length == 0
              ? _c("div", { staticClass: "text-center" }, [
                  _c("i", [
                    _vm._v(
                      "Price adjustments are yet to be applied to this product line."
                    ),
                  ]),
                ])
              : _c("div", [
                  _c(
                    "table",
                    { staticClass: "pricing-table" },
                    [
                      _vm._m(0),
                      _vm._l(
                        _vm.$lodash.orderBy(
                          _vm.workingOrderProductLine
                            .OrderProductLinePriceAdjustments,
                          "CalculationOrder"
                        ),
                        function (pa, index) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "pricing-table" },
                            [
                              _c("td", { staticClass: "pricing-table" }, [
                                _vm._v(_vm._s(pa.Name)),
                              ]),
                              _c("td", { staticClass: "pricing-table" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getPriceAdjustmentTypeNameByCode(
                                        pa.PriceAdjustmentTypeCd
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "pricing-table" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getPriceChangeTypeNameByCode(
                                        pa.PriceChangeTypeCd
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "pricing-table" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      pa.PriceChangeTypeCd == "PCT_Pe"
                                        ? pa.Amount + "%"
                                        : _vm.formatPricingString(pa.Amount)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "mt-2" }, [
                    _vm._v(" Adjusted Pricing "),
                    _c("table", { staticClass: "pricing-table" }, [
                      _c("tr", { staticClass: "pricing-table" }, [
                        _c("td", { staticClass: "pricing-table" }, [
                          _vm._v("Ex-GST Price"),
                        ]),
                        _c(
                          "td",
                          { staticClass: "pricing-table" },
                          [
                            _vm.CalculatedPrice == null
                              ? [
                                  _c("i", { staticStyle: { color: "red" } }, [
                                    _vm._v("Price not found"),
                                  ]),
                                ]
                              : [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatPricingString(
                                          _vm.CalculatedPrice.adjExGST
                                        )
                                      ),
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _c("tr", { staticClass: "pricing-table" }, [
                        _c("td", { staticClass: "pricing-table" }, [
                          _vm._v("GST Price"),
                        ]),
                        _c(
                          "td",
                          { staticClass: "pricing-table" },
                          [
                            _vm.CalculatedPrice == null
                              ? [
                                  _c("i", { staticStyle: { color: "red" } }, [
                                    _vm._v("Price not found"),
                                  ]),
                                ]
                              : [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatPricingString(
                                          _vm.CalculatedPrice.adjGST
                                        )
                                      ),
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _c("tr", { staticClass: "pricing-table" }, [
                        _c("td", { staticClass: "pricing-table" }, [
                          _vm._v("Total Price"),
                        ]),
                        _c(
                          "td",
                          { staticClass: "pricing-table" },
                          [
                            _vm.CalculatedPrice == null
                              ? [
                                  _c("i", { staticStyle: { color: "red" } }, [
                                    _vm._v("Price not found"),
                                  ]),
                                ]
                              : [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatPricingString(
                                          _vm.CalculatedPrice.adjTotal
                                        )
                                      ),
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
          ])
        : _vm._e(),
      _c("div", {
        staticClass: "section-break",
        staticStyle: { "margin-bottom": "0.8rem" },
      }),
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              staticStyle: {
                width: "120px",
                "padding-left": "0",
                "padding-right": "0",
              },
              attrs: { type: "button", disabled: !_vm.isFormDirty() },
              on: { click: _vm.onOK },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "check" } }),
              _vm._v("OK "),
            ],
            1
          ),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              staticStyle: {
                width: "120px",
                "padding-left": "0",
                "padding-right": "0",
              },
              attrs: { type: "button" },
              on: { click: _vm.onCancel },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
              _vm._v("Cancel "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showPriceAdjustmentsDialog
        ? _c(
            "mdb-modal",
            { attrs: { size: "lg", show: _vm.showPriceAdjustmentsDialog } },
            [
              _vm.showPriceAdjustmentsDialog == true
                ? _c("price-adjustments-dialog", {
                    attrs: {
                      allowedPriceAdjustments:
                        _vm.currentProductLineChronology
                          .CommercialProductLinePriceAdjustments,
                      keepDollarOnItemInd: _vm.PricingScalesIncludesItems,
                      visible: _vm.showPriceAdjustmentsDialog,
                    },
                    on: { close: _vm.onPriceAdjustmentsDialogClose },
                    model: {
                      value:
                        _vm.workingOrderProductLine
                          .OrderProductLinePriceAdjustments,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.workingOrderProductLine,
                          "OrderProductLinePriceAdjustments",
                          $$v
                        )
                      },
                      expression:
                        "workingOrderProductLine.OrderProductLinePriceAdjustments",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "pricing-table" }, [
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Name")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Adjustment Type")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Change Type")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Amount")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }