<template>
  <div class="mdb-wysiwyg-toolbar">
    <div class="mdb-wysiwyg-toolbar-group py-1">
      <button
        @click="showList = !showList"
        :disabled="view !== 'html'"
        v-on-clickaway="away"
        class="mdb-wysiwyg-list-button"
        type="button"
      >
        {{ textStyles[textStyle] }} <i class="fas fa-chevron-down fa-sm pl-2" />
      </button>
      <ul v-if="showList" class="mdb-wysiwyg-list">
        <li>
          <a href="#" @click.prevent="changeTextStyle('p')">{{
            translations.paragraph
          }}</a>
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h1')"
            >{{ translations.heading }} 1</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h2')"
            >{{ translations.heading }} 2</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h3')"
            >{{ translations.heading }} 3</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h4')"
            >{{ translations.heading }} 4</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h5')"
            >{{ translations.heading }} 5</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('h6')"
            >{{ translations.heading }} 6</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changeTextStyle('pre')">{{
            translations.preformatted
          }}</a>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-if="tooltips">
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="false"
            :disabled="view !== 'html'"
            @click="removeCssStyling"
            :title="translations.removeCssStyling"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-remove-format" />
          </button>
        </li>
      </ul>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="false"
            :disabled="view !== 'html'"
            @click="removeHtmlMarkup"
            :title="translations.removeHtmlMarkup"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <span class="fa-stack m-auto" style="height: 20px; width: 20px">
              <i class="fas fa-stack-1x fa-code" style="top: -7px" />
              <i class="fas fa-stack-1x fa-slash" style="top: -7px" />
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-else>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="false"
            :disabled="view !== 'html'"
            @click="removeCssStyling"
            :title="translations.removeCssStyling"
            type="button"
          >
            <i class="fas fa-remove-format" />
          </button>
        </li>
      </ul>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="false"
            :disabled="view !== 'html'"
            @click="removeHtmlMarkup"
            :title="translations.removeHtmlMarkup"
            type="button"
          >
            <span class="fa-stack m-auto" style="height: 20px; width: 20px">
              <i class="fas fa-stack-1x fa-code" style="top: -7px" />
              <i class="fas fa-stack-1x fa-slash" style="top: -7px" />
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-if="tooltips">
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="state.bold"
            :disabled="view !== 'html'"
            @click="modify('bold')"
            :title="translations.bold"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-bold" />
          </button>
        </li>
        <li>
          <button
            :active="state.italic"
            :disabled="view !== 'html'"
            @click="modify('italic')"
            :title="translations.italic"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-italic" />
          </button>
        </li>
        <li>
          <button
            :active="state.strikeThrough"
            :disabled="view !== 'html'"
            @click="modify('strikeThrough')"
            :title="translations.strikethrough"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-strikethrough" />
          </button>
        </li>
        <li>
          <button
            :active="state.underline"
            :disabled="view !== 'html'"
            @click="modify('underline')"
            :title="translations.underline"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-underline" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="showColorsList = !showColorsList"
            v-on-clickaway="awayColors"
            class="mdb-wysiwyg-list-button"
            :title="translations.textcolor"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-font" /><i
              class="fas fa-chevron-down fa-sm pl-1"
            />
          </button>
          <ul
            v-if="showColorsList"
            class="mdb-wysiwyg-list mdb-wysiwyg-toolbar-color-palette"
          >
            <li v-for="(color, index) in colors" :key="index">
              <button
                @click="changeColor(color)"
                :style="{ backgroundColor: color }"
                type="button"
              ></button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-else>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="state.bold"
            :disabled="view !== 'html'"
            @click="modify('bold')"
            :title="translations.bold"
            type="button"
          >
            <i class="fas fa-bold" />
          </button>
        </li>
        <li>
          <button
            :active="state.italic"
            :disabled="view !== 'html'"
            @click="modify('italic')"
            :title="translations.italic"
            type="button"
          >
            <i class="fas fa-italic" />
          </button>
        </li>
        <li>
          <button
            :active="state.strikeThrough"
            :disabled="view !== 'html'"
            @click="modify('strikeThrough')"
            :title="translations.strikethrough"
            type="button"
          >
            <i class="fas fa-strikethrough" />
          </button>
        </li>
        <li>
          <button
            :active="state.underline"
            :disabled="view !== 'html'"
            @click="modify('underline')"
            :title="translations.underline"
            type="button"
          >
            <i class="fas fa-underline" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="showColorsList = !showColorsList"
            v-on-clickaway="awayColors"
            class="mdb-wysiwyg-list-button"
            :title="translations.textcolor"
            type="button"
          >
            <i class="fas fa-font" /><i
              class="fas fa-chevron-down fa-sm pl-1"
            />
          </button>
          <ul
            v-if="showColorsList"
            class="mdb-wysiwyg-list mdb-wysiwyg-toolbar-color-palette"
          >
            <li v-for="(color, index) in colors" :key="index">
              <button
                @click="changeColor(color)"
                :style="{ backgroundColor: color }"
                type="button"
              ></button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-if="tooltips">
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="state.justifyLeft"
            :disabled="view !== 'html'"
            @click="modify('justifyLeft')"
            :title="translations.alignleft"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-align-left" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyCenter"
            :disabled="view !== 'html'"
            @click="modify('justifyCenter')"
            :title="translations.aligncenter"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-align-center" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyRight"
            :disabled="view !== 'html'"
            @click="modify('justifyRight')"
            :title="translations.alignright"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-align-right" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyFull"
            :disabled="view !== 'html'"
            @click="modify('justifyFull')"
            :title="translations.alignjustify"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-align-justify" />
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-else>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :active="state.justifyLeft"
            :disabled="view !== 'html'"
            @click="modify('justifyLeft')"
            :title="translations.alignleft"
            type="button"
          >
            <i class="fas fa-align-left" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyCenter"
            :disabled="view !== 'html'"
            @click="modify('justifyCenter')"
            :title="translations.aligncenter"
            type="button"
          >
            <i class="fas fa-align-center" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyRight"
            :disabled="view !== 'html'"
            @click="modify('justifyRight')"
            :title="translations.alignright"
            type="button"
          >
            <i class="fas fa-align-right" />
          </button>
        </li>
        <li>
          <button
            :active="state.justifyFull"
            :disabled="view !== 'html'"
            @click="modify('justifyFull')"
            :title="translations.alignjustify"
            type="button"
          >
            <i class="fas fa-align-justify" />
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-if="tooltips">
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :disabled="view !== 'html'"
            @click="createLink"
            :title="translations.insertlink"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-paperclip" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="insertImage"
            :title="translations.insertpicture"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-image" />
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-else>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :disabled="view !== 'html'"
            @click="createLink"
            :title="translations.insertlink"
            type="button"
          >
            <i class="fas fa-paperclip" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="insertImage"
            :title="translations.insertpicture"
            type="button"
          >
            <i class="fas fa-image" />
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-if="tooltips">
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :disabled="view !== 'html'"
            @click="create('insertUnorderedList')"
            :title="translations.bulletlist"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-list-ul" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="create('insertOrderedList')"
            :title="translations.numberedlist"
            v-tippy="{ position: 'bottom', arrow: true }"
            type="button"
          >
            <i class="fas fa-list-ol" />
          </button>
        </li>
      </ul>
    </div>
    <div class="mdb-wysiwyg-toolbar-group" v-else>
      <ul class="mdb-wysiwyg-toolbar-options">
        <li>
          <button
            :disabled="view !== 'html'"
            @click="create('insertUnorderedList')"
            :title="translations.bulletlist"
            type="button"
          >
            <i class="fas fa-list-ul" />
          </button>
        </li>
        <li>
          <button
            :disabled="view !== 'html'"
            @click="create('insertOrderedList')"
            :title="translations.numberedlist"
            type="button"
          >
            <i class="fas fa-list-ol" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

const mdbWysiwygToolbar = {
  name: "mdbWysiwygToolbar",
  props: {
    commandState: {
      type: Object,
    },
    textState: {
      type: String,
    },
    colors: {
      type: Array,
    },
    translations: {
      type: Object,
    },
    tooltips: {
      type: Boolean,
    },
  },
  mixins: [clickaway],
  data() {
    return {
      view: "html",
      showList: false,
      textStyle: this.textState,
      textStyles: {
        p: this.translations.paragraph,
        h1: this.translations.heading + " 1",
        h2: this.translations.heading + " 2",
        h3: this.translations.heading + " 3",
        h4: this.translations.heading + " 4",
        h5: this.translations.heading + " 5",
        h6: this.translations.heading + " 6",
        pre: this.translations.preformatted,
      },
      showColorsList: false,
    };
  },
  computed: {
    state() {
      return this.commandState;
    },
  },
  methods: {
    modify(command) {
      document.execCommand(command, false, "");
      if (
        command === "justifyLeft" ||
        command === "justifyCenter" ||
        command === "justifyRight" ||
        command === "justifyFull"
      )
        this.state.justifyLeft =
          this.state.justifyCenter =
          this.state.justifyRight =
          this.state.justifyFull =
            false;
      document.queryCommandState(command) === true
        ? (this.state[command] = true)
        : (this.state[command] = false);
    },
    changeColor(color) {
      document.execCommand("foreColor", false, color);
    },
    createLink() {
      let link = prompt(this.translations.enterurl, "https://");
      if (link !== null) {
        document.execCommand("createLink", false, link);
      }
    },
    insertImage() {
      let image = prompt(this.translations.imageurl, "https://");
      if (image !== null) {
        document.execCommand("insertImage", false, image);
      }
    },
    create(command) {
      document.execCommand(command, false, "");
    },
    away() {
      this.showList = false;
    },
    awayColors() {
      this.showColorsList = false;
    },
    changeTextStyle(val) {
      this.textStyle = val;
      document.execCommand("formatBlock", false, "<" + val + ">");
    },
    removeCssStyling() {
      const wysiwygEditor = document.activeElement.closest(
        "div.mdb-wysiwyg-container"
      );

      const wysiwygTextarea = wysiwygEditor.getElementsByClassName(
        "mdb-wysiwyg-textarea"
      )[0];

      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(wysiwygTextarea);
      selection.addRange(range);

      //document.execCommand("selectAll", false, "");
      document.execCommand("removeFormat", false);

      selection.removeAllRanges();
    },
    removeHtmlMarkup() {
      let wysiwygEditor = document.activeElement.closest(
        "div.mdb-wysiwyg-container"
      );

      let wysiwygTextarea = wysiwygEditor.getElementsByClassName(
        "mdb-wysiwyg-textarea"
      )[0];

      wysiwygTextarea.innerHTML = wysiwygTextarea.innerText;
    },
    changeview() {
      this.view === "html" ? (this.view = "raw") : (this.view = "html");
      this.$emit("change-view", this.view);
    },
  },
  watch: {
    textState(val) {
      let options = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "pre"];
      if (options.some((el) => val.includes(el))) this.textStyle = val;
    },
  },
};

export default mdbWysiwygToolbar;
export { mdbWysiwygToolbar };
</script>

<style scoped>
.mdb-wysiwyg-toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #d9dad9;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  background: #f4f4f4;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f4f4f4),
    to(#efeeee)
  );
  background: -webkit-linear-gradient(#f4f4f4, #efeeee);
  background: -o-linear-gradient(#f4f4f4, #efeeee);
  background: linear-gradient(#f4f4f4, #efeeee);
}

.mdb-wysiwyg-toolbar-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.mdb-wysiwyg-toolbar-options {
  list-style: none;
  margin: 5px 0;
  padding-left: 0;
}

.mdb-wysiwyg-toolbar-group li {
  display: inline-block;
  padding-left: 0;
  position: relative;
}

.mdb-wysiwyg-toolbar-group button {
  color: #555;
  background: 0 0;
  border: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
}

.mdb-wysiwyg-toolbar-group button[active] {
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 0 13px #00000025;
  box-shadow: inset 0 0 13px #00000025;
}

.mdb-wysiwyg-toolbar-group button[disabled] {
  color: #00000025;
  cursor: default;
}

.mdb-wysiwyg-toolbar-group button:not([disabled]):hover {
  border: 1px solid #ccc;
}

.mdb-wysiwyg-select {
  background: none;
  border: none;
  font-size: 0.8rem;
  outline: none;
  cursor: pointer;
}

.mdb-wysiwyg-list-button {
  font-size: 0.8rem;
}

.mdb-wysiwyg-list {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 0.8rem;
  list-style: none;
  margin: 5px 0;
  padding-left: 0;
  background: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.mdb-wysiwyg-list li {
  width: 100%;
}

.mdb-wysiwyg-list a {
  display: block;
  padding: 4px 10px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.mdb-wysiwyg-list a:hover {
  background-color: #f4f4f4;
}

.mdb-wysiwyg-toolbar-color-palette {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3px;
  padding: 3px;
}

.mdb-wysiwyg-toolbar-color-palette button {
  display: block;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0;
  border-radius: 0;
}
</style>
