var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asoft-modal-header", {
        attrs: { label: "Add a Product Line to this Order" },
        on: { close: () => _vm.onCancel() },
      }),
      _c("mdb-modal-body", [
        _c(
          "div",
          [
            _c("mdb-container", { attrs: { fluid: "" } }, [
              _vm.filteredProductLines.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.filteredProductLines,
                      function (productLine, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "mb-3 mx-2 d-flex flex-row",
                          },
                          [
                            _c("awgt-input", {
                              staticStyle: { "min-width": "50px" },
                              attrs: {
                                id: "productLineSelectionRadiobutton" + index,
                                type: "radio",
                                name: "productLineSelectionRadiobutton",
                                label: " ",
                                radioValue: productLine.Reference,
                              },
                              model: {
                                value: _vm.selectedProductLineReference,
                                callback: function ($$v) {
                                  _vm.selectedProductLineReference = $$v
                                },
                                expression: "selectedProductLineReference",
                              },
                            }),
                            _c("h5", { staticClass: "my-0" }, [
                              _vm._v(_vm._s(productLine.Name)),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _c("div", { staticClass: "text-center" }, [
                    _c("i", [
                      _vm._v(
                        "No product lines were found for the given Priced For Date"
                      ),
                    ]),
                  ]),
            ]),
          ],
          1
        ),
      ]),
      _c("mdb-modal-footer", [
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onSave },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onCancel },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Cancel "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }