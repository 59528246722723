<template>
  <div class="mx-3" v-if="pageMode == 'EditOrderProduct'">
    <h1 class="text-center mb-3">Edit Order - Product</h1>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        :icon="
          getProductIcon(
            workingOrderProduct.ProductTypeCd,
            workingOrderProduct.ProductSubTypeCd
          )
        "
        size="2x"
        class="mr-2"
        color="primary"
        style="width: 36px"
      />
      <div class="d-flex flex-column" style="text-align: left">
        <h5 class="my-0">{{ workingOrderProduct.Name }}</h5>
      </div>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        icon="calendar-alt"
        far
        color="primary"
        class="mr-2"
        size="2x"
        style="width: 34px"
      />
      <asoft-date-picker
        v-model="workingOrderProduct.PricedForDt"
        label="Priced For"
        autoHide
        displayDateFormat="D MMM YYYY"
        dataDateFormat="YYYY-MM-DDTHH:mm:ss"
        bg
        class="mx-2"
        style="width: 140px"
      />
    </div>

    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        icon="eye"
        far
        color="primary"
        class="mr-2"
        size="2x"
        style="width: 34px"
      />
      <awgt-std-switch
        offLabel
        class="mt-2"
        onLabel="Displayed on the itinerary"
        v-model="workingOrderProduct.IsDisplayedOnItineraryInd"
      ></awgt-std-switch>
      <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(55)" />
    </div>
    <h5>Additional Details</h5>
    <div>
      <div>
        <additional-details-of-coach-charter
          v-if="workingOrderProduct.ProductSubTypeCd === 'PST_TrCC'"
          v-model="workingOrderProduct.AdditionalDetails"
        ></additional-details-of-coach-charter>
        <additional-details-of-flight
          v-else-if="workingOrderProduct.ProductSubTypeCd === 'PST_TrFl'"
          v-model="workingOrderProduct.AdditionalDetails"
        ></additional-details-of-flight>
        <additional-details-of-ferry
          v-else-if="workingOrderProduct.ProductSubTypeCd === 'PST_TrFe'"
          v-model="workingOrderProduct.AdditionalDetails"
        ></additional-details-of-ferry>
        <additional-details-of-activity-product-bundle
          v-else-if="workingOrderProduct.ProductSubTypeCd === 'PST_AyAy'"
          v-model="workingOrderProduct.AdditionalDetails"
        ></additional-details-of-activity-product-bundle>
        <additional-details-of-others
          v-else-if="workingOrderProduct.ProductSubTypeCd != null"
          v-model="workingOrderProduct.AdditionalDetails"
        ></additional-details-of-others>
        <div v-else class="text-center">
          <i>There are no additional details for this product</i>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-2"
    >
      <h5>Order Product Lines</h5>
      <awgt-std-button
        type="button"
        class="command-button ml-auto"
        @click="onOrderProductLinesAdd"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <div
      v-if="workingOrderProduct.OrderProductLines.length == 0"
      class="text-center"
    >
      <i>Press "Add" to add an order product line to this product</i>
    </div>

    <mdb-card
      v-for="(orderProductLine, index) in workingOrderProduct.OrderProductLines"
      :key="index"
      class="py-1 mb-3"
    >
      <mdb-card-body>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <div
            v-if="
              getOrderProductLineChronology(
                orderProductLine.Reference,
                workingOrderProduct.PricedForDt
              ) == undefined
            "
            class="mt-2"
          >
            <i>No Order Product Line can be found at this Priced For date.</i>
          </div>
          <div v-else>
            <div>
              {{
                getOrderProductLineChronology(
                  orderProductLine.Reference,
                  workingOrderProduct.PricedForDt
                )?.Name
              }}
            </div>
            <div
              v-for="(
                orderProductLineQuantity, index
              ) in orderProductLine.OrderProductLineQuantities"
              :key="index"
            >
              <span
                >{{ orderProductLineQuantity.Quantity }}
                {{
                  getQuantityUnit(
                    getOrderProductLineChronology(
                      orderProductLine.Reference,
                      workingOrderProduct.PricedForDt
                    ),
                    orderProductLineQuantity.QuantityUnitCd,
                    orderProductLineQuantity.Quantity
                  )
                }}</span
              >
            </div>
            <div>
              <table class="pricing-table">
                <tr class="pricing-table">
                  <td class="pricing-table">Ex-GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjExGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">Total Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjTotal)
                        "
                      ></span>

                      <tdoxTooltip
                        v-if="orderProductLine?.Price?.comment.length > 0"
                        :detail="orderProductLine?.Price?.comment"
                      />
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="ml-auto">
            <awgt-std-button
              type="button"
              class="command-button mr-2"
              @click="
                onOrderProductLineDelete(
                  orderProductIdx,
                  orderProductLine.OrderProductLineIdx
                )
              "
            >
              <mdb-icon icon="trash-alt" class="mr-1" />Delete
            </awgt-std-button>

            <awgt-std-button
              type="button"
              class="command-button"
              @click="
                onOrderProductLineEdit(orderProductLine.OrderProductLineIdx)
              "
            >
              <mdb-icon icon="edit" class="mr-1" />Edit
            </awgt-std-button>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>

    <h5 class="font-weight-bold">
      Product Price:
      <span
        v-html="formatPricingString(workingOrderProduct.Price?.total)"
      ></span>
    </h5>

    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
    >
      <h5>Product Price Adjustments</h5>

      <awgt-std-button
        type="button"
        class="command-button ml-auto"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onOpenPriceAdjustmentsDialog"
      >
        <mdb-icon icon="dollar" class="mr-1" />Adjust Price
      </awgt-std-button>
    </div>
    <div>
      The following price adjustments will be applied to this product line:
    </div>
    <div
      v-if="workingOrderProduct.OrderProductPriceAdjustments.length == 0"
      class="text-center"
    >
      <i>Price adjustments are yet to be applied to this product line.</i>
    </div>
    <div v-else>
      <table class="pricing-table">
        <tr class="pricing-table">
          <td class="pricing-table">Name</td>
          <td class="pricing-table">Adjustment Type</td>
          <td class="pricing-table">Change Type</td>
          <td class="pricing-table">Amount</td>
        </tr>
        <tr
          v-for="(pa, index) of $lodash.orderBy(
            workingOrderProduct.OrderProductPriceAdjustments,
            'CalculationOrder'
          )"
          :key="index"
          class="pricing-table"
        >
          <td class="pricing-table">{{ pa.Name }}</td>
          <td class="pricing-table">
            {{ getPriceAdjustmentTypeNameByCode(pa.PriceAdjustmentTypeCd) }}
          </td>
          <td class="pricing-table">
            {{ getPriceChangeTypeNameByCode(pa.PriceChangeTypeCd) }}
          </td>
          <td class="pricing-table">
            <span v-html="getPriceChangeTypeAmount(pa)"></span>
          </td>
        </tr>
      </table>

      <div class="mt-2">
        Adjusted Pricing
        <table class="pricing-table">
          <tr class="pricing-table">
            <td class="pricing-table">Ex-GST Price</td>
            <td class="pricing-table">
              <span
                v-html="
                  formatPricingString(this.workingOrderProduct?.Price?.adjExGST)
                "
              ></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">GST Price</td>
            <td class="pricing-table">
              <span
                v-html="
                  formatPricingString(this.workingOrderProduct?.Price?.adjGST)
                "
              ></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">Total Price</td>
            <td class="pricing-table">
              <span
                v-html="
                  formatPricingString(this.workingOrderProduct?.Price?.adjTotal)
                "
              ></span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="section-break" style="margin-bottom: 0.8rem"></div>

    <div class="float-right">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onOK"
        :disabled="!isFormDirty() || !isFormValid"
      >
        <mdb-icon icon="check" class="mr-1" />OK
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onCancel"
      >
        <mdb-icon icon="times" class="mr-1" />Cancel
      </awgt-std-button>
    </div>
    <mdb-modal
      size="lg"
      v-if="showAddOrderProductLineDialog"
      :show="showAddOrderProductLineDialog"
    >
      <add-order-product-line-dialog
        :productLines="product.CommercialProductLines"
        :pricedForDt="new Date(workingOrderProduct.PricedForDt)"
        @productLineSelected="onProductLineSelectedForOrderProductLineAdd"
        @close="() => (showAddOrderProductLineDialog = false)"
      />
    </mdb-modal>
    <mdb-modal
      size="lg"
      v-if="showPriceAdjustmentsDialog"
      :show="showPriceAdjustmentsDialog"
    >
      <price-adjustments-dialog
        v-if="showPriceAdjustmentsDialog == true"
        v-model="workingOrderProduct.OrderProductPriceAdjustments"
        :allowedPriceAdjustments="product.PriceAdjustments"
        :keepDollarOnItemInd="false"
        @close="onPriceAdjustmentsDialogClose"
        :visible="showPriceAdjustmentsDialog"
      />
    </mdb-modal>
  </div>
  <div v-else-if="pageMode == 'EditOrderProductLine'">
    <edit-order-product-line
      :orderProductIdx="orderProductIdx"
      :orderProductLineIdx="orderProductLineIdx"
      :product="product"
      :booking="booking"
      @close="onEditOrderProductLineClose"
    />
  </div>
  <div v-else>Unknown pageMode</div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
.md-form label {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.command-button {
  min-width: 100px;
  width: 100px;
}

@media screen and (max-width: 800px) {
  .command-button {
    min-width: 70px;
    width: 70px;
  }
}
</style>
<script>
import { mdbIcon, mdbModal, mdbCard, mdbCardBody } from "mdbvue";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";
import awgtStdButton from "@/components/AWGT/AwgtStdButton";
import { sharedMethods } from "@/shared/shared";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import additionalDetailsOfCoachCharter from "@/views/itinerary/additionalDetailsOfCoachCharter.vue";
import additionalDetailsOfFlight from "@/views/itinerary/additionalDetailsOfFlight.vue";
import additionalDetailsOfFerry from "@/views/itinerary/additionalDetailsOfFerry.vue";
import additionalDetailsOfActivityProductBundle from "@/views/itinerary/additionalDetailsOfActivityProductBundle.vue";
import additionalDetailsOfOthers from "@/views/itinerary/additionalDetailsOfOthers.vue";
import addOrderProductLineDialog from "./addOrderProductLineDialog";
import editOrderProductLine from "./editOrderProductLine";
import priceAdjustmentsDialog from "./priceAdjustmentsDialog";
import productApi from "@/api/productApi.js";
import { mapMutations } from "vuex/dist/vuex.common.js";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";

export default {
  components: {
    mdbIcon,
    mdbModal,
    mdbCard,
    mdbCardBody,
    asoftDatePicker,
    awgtStdSwitch,
    awgtStdButton,
    additionalDetailsOfCoachCharter,
    additionalDetailsOfFlight,
    additionalDetailsOfFerry,
    additionalDetailsOfActivityProductBundle,
    additionalDetailsOfOthers,
    addOrderProductLineDialog,
    editOrderProductLine,
    priceAdjustmentsDialog,
    tdoxTooltip,
  },

  data() {
    return {
      product: null,
      workingOrderProduct: null,
      showAddOrderProductLineDialog: false,
      currentOrderProductLine: null,
      showPriceAdjustmentsDialog: false,
      orderProductLineExpansion: [],
      pageMode: "EditOrderProduct",
      activeOrderProduct: {},
    };
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getOrderStatuses",
      "order/getEmptyOrder",
      "order/getEmptyOrderProduct",
      "order/getEmptyOrderProductLine",
      "order/getActiveOrderProduct",
      "order/getActiveOrderProductLinePrice",
      "order/getActiveOrderProductPrice",
      "getPriceAdjustmentTypeNameByCode",
      "getPriceChangeTypeNameByCode",
      "getTooltipsTextByCode",
    ]),

    getPriceChangeTypeAmount(pa) {
      return pa.PriceChangeTypeCd == "PCT_Pe"
        ? pa.Amount + "%"
        : this.formatPricingString(pa.Amount);
    },

    isFormValid() {
      return this.workingOrderProduct.PricedForDt != null;
    },

    CalculatedPrice: function () {
      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );

      return this.$lodash.cloneDeep(
        this.$store.getters["order/getActiveOrderProductPrice"](
          this.orderProductIdx
        )
      );
    },
  },

  props: {
    orderProductIdx: {
      type: Number,
    },

    booking: {
      type: Object,
    },
  },

  watch: {
    orderProductIdx: {
      deep: false,
      immediate: true,
      handler(to) {
        //The original order product is saved incase it needs
        //to be restored on Cancel.
        this.activeOrderProduct = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProduct"](to)
        );
        this.workingOrderProduct = this.$lodash.cloneDeep(
          this.activeOrderProduct
        );
        if (this.workingOrderProduct.PricedForDt == null)
          this.workingOrderProduct.PricedForDt = this.booking.DepartureDt;
        this.onComponentVisibleAsync();
      },
    },

    "workingOrderProduct.PricedForDt": {
      deep: false,
      immediate: false,
      handler(to, from) {
        if (to != from) this.recalculatePrices();
      },
    },
  },

  methods: {
    ...mapMutations([
      "order/mutStoreOrderProduct",
      "order/mutStoreOrderProductLine",
    ]),
    ...sharedMethods,

    isFormDirty() {
      return true;
    },

    calculateAndStoreOrderProductLinePrices() {
      for (let orderProductLine of this.workingOrderProduct.OrderProductLines) {
        let commercialProductLine = this.product.CommercialProductLines.find(
          (cpl) => cpl.Reference == orderProductLine.Reference
        );

        let chronology = commercialProductLine.Chronology.find(
          (c) =>
            new Date(c.ActiveFromDt) <=
              new Date(this.workingOrderProduct.PricedForDt) &&
            (c.ActiveToDt == null ||
              new Date(c.ActiveToDt) >=
                new Date(this.workingOrderProduct.PricedForDt))
        );

        let orderProductLinePrice = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProductLinePrice"](
            chronology,
            this.orderProductIdx,
            orderProductLine.OrderProductLineIdx
          )
        );

        orderProductLine.Price = orderProductLinePrice;

        //Update the order product line to the VueX store.
        this.$store.commit(
          "order/mutStoreOrderProductLine",
          {
            orderProductIdx: this.orderProductIdx,
            orderProductLine: orderProductLine,
          },
          { root: true }
        );

        const orderProductLineIndex =
          this.workingOrderProduct.OrderProductLines.findIndex(
            (opl) =>
              opl.OrderProductLineIdx == orderProductLine.OrderProductLineIdx
          );

        this.workingOrderProduct.OrderProductLines[orderProductLineIndex] =
          orderProductLine;
      }
    },

    calculateAndStoreOrderProductPrices() {
      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );

      let orderProductPrice = this.$lodash.cloneDeep(
        this.$store.getters["order/getActiveOrderProductPrice"](
          this.orderProductIdx
        )
      );

      this.workingOrderProduct.Price = orderProductPrice;

      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );
    },

    async onComponentVisibleAsync() {
      await this.loadProduct();
      this.recalculatePrices();
    },

    recalculatePrices() {
      this.calculateAndStoreOrderProductLinePrices();
      this.calculateAndStoreOrderProductPrices();
      this.$forceUpdate();
    },

    async loadProduct() {
      this.product = await productApi.getProductByReference(
        this.workingOrderProduct.Reference,
        false
      );
    },

    onComponentHidden() {
      this.workingOrderProduct = null;
    },

    onOK() {
      //Update the order product to the VueX store.
      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );
      this.$emit("close");
      this.onComponentHidden();
    },

    onCancel() {
      //If the working order product line has a RecordStatus of Inserted
      //then a order product line has been added and needs to be
      //removed. Otherwise replace the WorkingOrderProductLine
      //with the activeOrderProductLine it was cloned from.

      if (this.workingOrderProduct.RecordStatus == "Inserted") {
        this.$store.commit(
          "order/mutDeleteOrderProduct",
          { orderProductIdx: this.orderProductIdx },
          { root: true }
        );
      } else {
        this.$store.commit(
          "order/mutStoreOrderProduct",
          this.activeOrderProduct,
          { root: true }
        );
      }

      this.$emit("close");
      this.onComponentHidden();
    },

    toggleOrderProductLineExpansion(index) {
      this.$set(
        this.orderProductLineExpansion,
        index,
        !this.orderProductLineExpansion[index]
      );
    },

    onOpenPriceAdjustmentsDialog() {
      this.showPriceAdjustmentsDialog = true;
    },

    onEditOrderProductLineClose() {
      this.recalculatePrices();
      this.pageMode = "EditOrderProduct";
    },

    onOrderProductLinesAdd() {
      this.showAddOrderProductLineDialog = true;
    },

    onOrderProductLineEdit(orderProductLineIdx) {
      //Save the current order product to the VueX store.
      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );

      this.orderProductLineEdit(orderProductLineIdx);
    },

    onOrderProductLineDelete(orderProductIdx, orderProductLineIdx) {
      this.$store.commit(
        "order/mutDeleteOrderProductLine",
        {
          orderProductIdx: orderProductIdx,
          orderProductLineIdx: orderProductLineIdx,
        },
        { root: true }
      );

      this.recalculatePrices();
    },

    onProductLineSelectedForOrderProductLineAdd(productLineReference) {
      this.$log.info(
        "The following product line was selected: %s",
        productLineReference
      );

      let currentOrderProductLine =
        this.createOrderProductLine(productLineReference);

      //Add a record status of "Inserted" to the order product line
      //so it can be deleted later if "Cancel" is pressed.
      currentOrderProductLine.RecordStatus = "Inserted";

      //Save the current order product to the VueX store.
      //This must be done before saving the order product line.
      this.$store.commit(
        "order/mutStoreOrderProduct",
        this.workingOrderProduct,
        { root: true }
      );

      //Add the new order product line to the VueX store.
      this.$store.commit(
        "order/mutStoreOrderProductLine",
        {
          orderProductIdx: this.orderProductIdx,
          orderProductLine: currentOrderProductLine,
        },
        { root: true }
      );

      this.orderProductLineEdit(currentOrderProductLine.OrderProductLineIdx);
    },

    createOrderProductLine: function (productLineReference) {
      let orderProductLine = this.$lodash.cloneDeep(
        this.$store.getters["order/getEmptyOrderProductLine"]
      );
      orderProductLine.Reference = productLineReference;

      return orderProductLine;
    },

    orderProductLineEdit(orderProductLineIdx) {
      this.orderProductLineIdx = orderProductLineIdx;
      this.pageMode = "EditOrderProductLine";
    },

    onPriceAdjustmentsDialogClose() {
      this.showPriceAdjustmentsDialog = false;
      this.recalculatePrices();
    },
  },
};
</script>
