import Vue from "vue";
import Vuex from "vuex";
import lodash from "lodash";
import logger from "@/shared/asoftLogger.js";
import referenceApi from "@/api/referenceApi.js";
import orderApi from "@/api/orderApi.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import { constants } from "@/shared/constants.js";
import moment from "moment";

Vue.use(Vuex);

const order = {
  namespaced: true,

  state: {
    emptyOrder: {
      Expanded: false,
      Reference: "",
      BookingReference: "",
      ResellerSupplierReference: null,
      PrimaryOrderProducts: [],
      ResellerSupplierName: "",
      OrderStatusCd: null,
      Comment: "",
      ExGSTTotalAmt: null,
      GSTTotalAmt: null,
      AdjustAmt: null,
      PriceChangeTypeCd: null,
      PriceAdjustmentTypeCd: null,
      PriceAdjustReason: "",
      DepositAmt: 0.0,
      DepositRequiredByDt: null,
      DepositAdjustReason: "",
      RecordStatus: "Inserted",
      IsActiveInd: true,
    },

    emptyOrderProduct: {
      Reference: null,
      ResellerSupplierReference: null,
      Name: "",
      Description: "",
      OrderProductIdx: 0,
      ProductTypeCd: null,
      ProductSubTypeCd: null,
      ProviderSupplierName: "",
      PricedForDt: null,
      AdditionalDetails: {},
      ExGSTTotalAmt: 0.0,
      GSTTotalAmt: 0.0,
      PriceAdjustReason: "", //TODO: To be removed.
      IsDisplayedOnItineraryInd: false,
      AvailableTimes: [],
      AvailableCommercialProductLines: [],
      OrderProductPriceAdjustments: [],
      OrderProductLines: [],
      GroupOrderProducts: [],
    },

    emptyOrderProductLine: {
      Reference: null,
      OrderProductLineIdx: 0,
      ExGSTTotalAmt: 0.0,
      GSTTotalAmt: 0.0,
      OrderProductLineQuantities: [],
      OrderProductLinePriceAdjustments: [],
      OrderProductLineMarketCategories: [],
    },

    emptyOrderProductLineQuantity: {
      QuantityUnitCd: "",
      Quantity: 0,
    },

    activeOrder: {},
  },

  getters: {
    getEmptyOrder: (state) => state.emptyOrder,
    getEmptyOrderProduct: (state) => state.emptyOrderProduct,
    getEmptyOrderProductLine: (state) => state.emptyOrderProductLine,
    getEmptyOrderProductLineQuantity: (state) =>
      state.emptyOrderProductLineQuantity,
    getActiveOrder: (state) => state.activeOrder,
    getActiveOrderProduct: (state) => (orderProductIdx) => {
      let orderProduct = state.activeOrder?.PrimaryOrderProducts.find(
        (op) => op.OrderProductIdx == orderProductIdx
      );
      return orderProduct;
    },
    getActiveOrderProductLine:
      (state) => (orderProductIdx, orderProductLineIdx) => {
        const orderProduct = state.activeOrder?.PrimaryOrderProducts.find(
          (op) => op.OrderProductIdx == orderProductIdx
        );
        if (orderProduct == null) return null;
        return orderProduct?.OrderProductLines.find(
          (opl) => opl.OrderProductLineIdx == orderProductLineIdx
        );
      },

    getActiveOrderPrice: (state) => {
      const nullPrice = {
        exGST: null,
        gst: null,
        total: null,
        comment: "",
        adjExGST: null,
        adjGST: null,
        adjTotal: null,
      };

      let price = {
        exGST: 0,
        gst: 0,
        total: 0,
        comment: "",
        adjExGST: 0,
        adjGST: 0,
        adjTotal: 0,
      };

      for (let orderProduct of state.activeOrder.PrimaryOrderProducts) {
        if (orderProduct.Price == undefined) return nullPrice;
        else {
          price.exGST =
            price.exGST == null || orderProduct.Price.adjExGST == null
              ? null
              : price.exGST + orderProduct.Price.adjExGST;

          price.gst =
            price.gst == null || orderProduct.Price.adjGST == null
              ? null
              : price.gst + orderProduct.Price.adjGST;

          price.total =
            price.total == null || orderProduct.Price.adjTotal == null
              ? null
              : price.total + orderProduct.Price.adjTotal;

          price.comment = orderProduct.Price.comment;

          price.adjExGST = price.exGST;
          price.adjGST = price.gst;
          price.adjTotal = price.total;
        }
      }

      return price;
    },

    getActiveOrderProductPrice: (state) => (orderProductIdx) => {
      const orderProductIndex =
        state.activeOrder.PrimaryOrderProducts.findIndex(
          (op) => op.OrderProductIdx == orderProductIdx
        );

      const nullPrice = {
        exGST: null,
        gst: null,
        total: null,
        comment: "",
        adjExGST: null,
        adjGST: null,
        adjTotal: null,
      };

      let price = {
        exGST: 0,
        gst: 0,
        total: 0,
        comment: "",
        adjExGST: 0,
        adjGST: 0,
        adjTotal: 0,
      };

      let orderProduct =
        state.activeOrder.PrimaryOrderProducts[orderProductIndex];

      for (let orderProductLine of orderProduct.OrderProductLines) {
        if (orderProductLine.Price == undefined) return nullPrice;
        else {
          price.exGST =
            price.exGST == null || orderProductLine.Price.adjExGST == null
              ? null
              : price.exGST + orderProductLine.Price.adjExGST;

          price.gst =
            price.gst == null || orderProductLine.Price.adjGST == null
              ? null
              : price.gst + orderProductLine.Price.adjGST;

          price.total =
            price.total == null || orderProductLine.Price.adjTotal == null
              ? null
              : price.total + orderProductLine.Price.adjTotal;

          price.comment = orderProductLine.Price.comment;

          price.adjExGST = price.exGST;
          price.adjGST = price.gst;
          price.adjTotal = price.total;
        }
        for (let adjustment of lodash.orderBy(
          orderProduct.OrderProductPriceAdjustments,
          "CalculationOrder"
        )) {
          let adjval = 0;

          switch (adjustment.PriceChangeTypeCd) {
            case "PCT_DT":
              adjval = adjustment.Amount;
              switch (adjustment.PriceAdjustmentTypeCd) {
                case "PAT_D":
                  adjval *= -1;
                  price.adjTotal += adjval;
                  if (price.adjTotal < 0) price.adjTotal = 0;
                  break;
                case "PAT_S":
                  price.adjTotal += adjval;
                  break;
                case "PAT_P":
                  price.adjTotal = adjval;
                  break;
              }
              break;
            case "PCT_Pe":
              if (adjustment.PriceAdjustmentTypeCd == "PAT_D")
                adjval = (100 - adjustment.Amount) / 100;
              else adjval = (100 + adjustment.Amount) / 100;
              price.adjTotal *= adjval;

              break;
          }

          if (price.adjTotal > 0) {
            price.adjExGST = price.adjTotal * (price.exGST / price.total);
            price.adjGST = price.adjTotal * (price.gst / price.total);
          }
        }
      }

      return price;
    },

    getActiveOrderProductLinePrice:
      (state) =>
      (currentProductLineChronology, orderProductIdx, orderProductLineIdx) => {
        const nullPrice = {
          exGST: null,
          gst: null,
          total: null,
          comment: "",
          adjExGST: null,
          adjGST: null,
          adjTotal: null,
        };

        if (currentProductLineChronology == undefined) return nullPrice;

        const orderProductIndex =
          state.activeOrder.PrimaryOrderProducts.findIndex(
            (op) => op.OrderProductIdx == orderProductIdx
          );

        let orderProduct =
          state.activeOrder.PrimaryOrderProducts[orderProductIndex];

        const orderProductLineIndex = orderProduct.OrderProductLines.findIndex(
          (opl) => opl.OrderProductLineIdx == orderProductLineIdx
        );

        let orderProductLine =
          orderProduct.OrderProductLines[orderProductLineIndex];

        for (let productLinePrice of currentProductLineChronology.ProductLinePrices) {
          let isFound = true;
          let amount = 1;
          let numOfItems = 0;

          for (let quantity of orderProductLine.OrderProductLineQuantities) {
            if (
              productLinePrice.PricingScales.find(
                (ps) =>
                  ps.QuantityUnitCd == quantity.QuantityUnitCd &&
                  (ps.MinQuantity == null ||
                    ps.MinQuantity <= quantity.Quantity) &&
                  (ps.MaxQuantity == null ||
                    ps.MaxQuantity >= quantity.Quantity)
              ) == null
            ) {
              isFound = false;
              break;
            }

            if (quantity.QuantityUnitCd == "QU_I")
              numOfItems = quantity.Quantity;

            amount *= quantity.Quantity;
          }

          if (isFound == true) {
            let price = {};
            let incGSTUnitAmt =
              productLinePrice.ExGSTUnitAmt + productLinePrice.GSTUnitAmt;

            //Account for the MinTotalPriceAmt based on the incGST calculated amount.
            if (
              currentProductLineChronology.MinTotalPriceAmt >
              amount * incGSTUnitAmt
            ) {
              let exGSTCumulativeAmt =
                (currentProductLineChronology.MinTotalPriceAmt *
                  productLinePrice.ExGSTUnitAmt) /
                incGSTUnitAmt;

              let gstCumulativeAmt =
                incGSTUnitAmt == 0
                  ? 0
                  : (currentProductLineChronology.MinTotalPriceAmt *
                      productLinePrice.GSTUnitAmt) /
                    incGSTUnitAmt;

              price = {
                exGST: exGSTCumulativeAmt,
                gst: gstCumulativeAmt,
                total: currentProductLineChronology.MinTotalPriceAmt,
                comment: "This is the minimum total price",
                adjExGST: exGSTCumulativeAmt,
                adjGST: gstCumulativeAmt,
                adjTotal: currentProductLineChronology.MinTotalPriceAmt,
              };
            } else {
              price = {
                exGST: amount * productLinePrice.ExGSTUnitAmt,
                gst: amount * productLinePrice.GSTUnitAmt,
                total: amount * incGSTUnitAmt,
                comment: "",
                adjExGST: amount * productLinePrice.ExGSTUnitAmt,
                adjGST: amount * productLinePrice.GSTUnitAmt,
                adjTotal: amount * incGSTUnitAmt,
              };
            }
            for (let adjustment of lodash.orderBy(
              orderProductLine.OrderProductLinePriceAdjustments,
              "CalculationOrder"
            )) {
              let adjval = 0;

              switch (adjustment.PriceChangeTypeCd) {
                case "PCT_DT":
                  adjval = adjustment.Amount;
                  switch (adjustment.PriceAdjustmentTypeCd) {
                    case "PAT_D":
                      adjval *= -1;
                      price.adjTotal += adjval;
                      if (price.adjTotal < 0) price.adjTotal = 0;
                      break;
                    case "PAT_S":
                      price.adjTotal += adjval;
                      break;
                    case "PAT_P":
                      price.adjTotal = adjval;
                      break;
                  }
                  break;
                case "PCT_DI":
                  adjval = adjustment.Amount * numOfItems;
                  if (adjustment.PriceAdjustmentTypeCd == "PAT_D") adjval *= -1;
                  price.adjTotal += adjval;
                  if (price.adjTotal < 0) price.adjTotal = 0;
                  break;
                case "PCT_Pe":
                  if (adjustment.PriceAdjustmentTypeCd == "PAT_D")
                    adjval = (100 - adjustment.Amount) / 100;
                  else adjval = (100 + adjustment.Amount) / 100;
                  price.adjTotal *= adjval;

                  break;
              }

              if (price.adjTotal > 0) {
                price.adjExGST = price.adjTotal * (price.exGST / price.total);
                price.adjGST = price.adjTotal * (price.gst / price.total);
              }
            }

            return price;
          }
        }

        return nullPrice;
      },
  },

  mutations: {
    mutStoreOrder(state, order) {
      logger.get(loggingSource.StrOrder).info("mutStoreOrder...");
      logger
        .get(loggingSource.StrOrder)
        .debug("Parameters => order: %s", order);

      state.activeOrder = order;

      logger.get(loggingSource.StrOrder).info("...mutStoreOrder");
    },

    /*
        Adds/Updates an order product in the VueX store. It also
        assigns the OrderProductIdx.
    */
    mutStoreOrderProduct: (state, orderProduct) => {
      logger.get(loggingSource.StrOrder).info("mutStoreOrderProduct...");
      logger
        .get(loggingSource.StrOrder)
        .debug("Parameters => orderProduct: %s", orderProduct);
      if (
        orderProduct?.OrderProductIdx == null ||
        orderProduct.OrderProductIdx == 0
      ) {
        let nextOrderProductIdx = getNextOrderProductIdx(
          state.activeOrder.PrimaryOrderProducts
        );
        orderProduct.OrderProductIdx = nextOrderProductIdx;
        state.activeOrder.PrimaryOrderProducts.push(orderProduct);
      } else {
        const orderProductIndex =
          state.activeOrder.PrimaryOrderProducts.findIndex(
            (op) => op.OrderProductIdx == orderProduct.OrderProductIdx
          );
        if (orderProductIndex == null) {
          const error = `Unable to find order product with index ${orderProduct.OrderProductIdx}`;
          logger.get(loggingSource.StrOrder).debug(error);
          throw error;
        }
        state.activeOrder.PrimaryOrderProducts[orderProductIndex] =
          orderProduct;
      }
      logger.get(loggingSource.StrOrder).info("...mutStoreOrderProduct");
    },

    /*
        Removes an order's order product in the VueX store.
        Payload:
        {
          orderProductIdx
        }
    */
    mutDeleteOrderProduct: (state, payload) => {
      logger.get(loggingSource.StrOrder).info("mutDeleteOrderProduct...");
      logger
        .get(loggingSource.StrOrder)
        .debug("Parameters => orderProductIdx: %i", payload.orderProductIdx);
      const orderProductIndex =
        state.activeOrder.PrimaryOrderProducts.findIndex(
          (op) => op.OrderProductIdx == payload.orderProductIdx
        );
      if (orderProductIndex == null) {
        const error = `Unable to find order product with index ${payload.orderProductIdx}`;
        logger.get(loggingSource.StrOrder).debug(error);
        throw error;
      }
      state.activeOrder.PrimaryOrderProducts.splice(orderProductIndex, 1);
      logger.get(loggingSource.StrOrder).info("...mutDeleteOrderProduct");
    },

    /*
        Adds/Updates an order product line in the VueX store. It also
        assigns the OrderProductLineIdx.
        Payload:
        {
          orderProductIdx,
          orderProductLine
        }
    */
    mutStoreOrderProductLine: (state, payload) => {
      logger.get(loggingSource.StrOrder).info("mutStoreOrderProductLine...");
      logger
        .get(loggingSource.StrOrder)
        .debug(
          "Parameters => orderProductidx: %i, orderProductLine: %s",
          payload.orderProductIdx,
          payload.orderProductLine
        );

      const orderProductIndex =
        state.activeOrder.PrimaryOrderProducts.findIndex(
          (op) => op.OrderProductIdx == payload.orderProductIdx
        );
      if (orderProductIndex == null) {
        const error = `Unable to find order product with index ${payload.orderProductIdx}`;
        logger.get(loggingSource.StrOrder).debug(error);
        throw error;
      }

      if (
        payload.orderProductLine?.OrderProductLineIdx == null ||
        payload.orderProductLine.OrderProductLineIdx == 0
      ) {
        let nextOrderProductLineIdx = getNextOrderProductLineIdx(
          state.activeOrder.PrimaryOrderProducts[orderProductIndex]
            .OrderProductLines
        );
        payload.orderProductLine.OrderProductLineIdx = nextOrderProductLineIdx;
        state.activeOrder.PrimaryOrderProducts[
          orderProductIndex
        ].OrderProductLines.push(payload.orderProductLine);
      } else {
        const orderProductLineIndex = state.activeOrder.PrimaryOrderProducts[
          orderProductIndex
        ].OrderProductLines.findIndex(
          (opl) =>
            opl.OrderProductLineIdx ==
            payload.orderProductLine.OrderProductLineIdx
        );
        if (orderProductLineIndex == null) {
          const error = `Unable to find order product line with index ${payload.orderProductLine.OrderProductLineIdx}`;
          logger.get(loggingSource.StrOrder).debug(error);
          throw error;
        }
        state.activeOrder.PrimaryOrderProducts[
          orderProductIndex
        ].OrderProductLines[orderProductLineIndex] = payload.orderProductLine;
      }
      logger.get(loggingSource.StrOrder).info("...mutStoreOrderProductLine");
    },

    /*
        Removes an order product's order product line in the VueX store.
        Payload:
        {
          orderProductIdx,
          orderProductLineIdx
        }
    */
    mutDeleteOrderProductLine: (state, payload) => {
      logger.get(loggingSource.StrOrder).info("mutDeleteOrderProductLine...");
      logger
        .get(loggingSource.StrOrder)
        .debug(
          "Parameters => orderProductidx: %i, orderProductLineIdx: %i",
          payload.orderProductIdx,
          payload.orderProductLineIdx
        );
      const orderProductIndex =
        state.activeOrder.PrimaryOrderProducts.findIndex(
          (op) => op.OrderProductIdx == payload.orderProductIdx
        );
      if (orderProductIndex == null) {
        const error = `Unable to find order product with index ${payload.orderProductIdx}`;
        logger.get(loggingSource.StrOrder).debug(error);
        throw error;
      }
      const orderProductLineIndex = state.activeOrder.PrimaryOrderProducts[
        orderProductIndex
      ].OrderProductLines.findIndex(
        (opl) => opl.OrderProductLineIdx == payload.orderProductLineIdx
      );
      if (orderProductLineIndex == null) {
        const error = `Unable to find order product line with index ${payload.orderProductLineIdx}`;
        logger.get(loggingSource.StrOrder).debug(error);
        throw error;
      }
      state.activeOrder.PrimaryOrderProducts[
        orderProductIndex
      ].OrderProductLines.splice(orderProductLineIndex, 1);
      logger.get(loggingSource.StrOrder).info("...mutDeleteOrderProductLine");
    },
  },

  actions: {
    /*
      Loads into state and returns an order based on the orderReference,
      or if the orderReference is null it loads and returns an empty order.
      Payload structure is:
      {
          Reference //Order Reference
      }
    */
    async actLoadOrder({ commit }, payload) {
      try {
        logger.get(loggingSource.StrOrder).info("actLoadOrder...");

        let orderData = "";
        if (payload.Reference == null)
          orderData = lodash.clone(this.getters["order/getEmptyOrder"]);
        else orderData = await orderApi.getOrderAsync(payload.Reference);

        logger
          .get(loggingSource.StrOrder)
          .debug(
            loggingSource.StrOrder,
            "actLoadOrder orderData: %s",
            orderData
          );
        commit("mutStoreOrder", orderData);
        return orderData;
      } catch (err) {
        logger.get(loggingSource.StrOrder).error(err.response);
        throw new Error(err.response);
      } finally {
        logger.get(loggingSource.StrOrder).info("...actLoadOrder");
      }
    },

    /*
      Saves an order to the database and store and returns the
      saved order.

      The payload is an order.
    */
    async actSaveOrder({ commit }, payload) {
      try {
        logger.get(loggingSource.StrOrder).info("actLoadOrder...");

        logger
          .get(loggingSource.StrOrder)
          .debug(loggingSource.StrOrder, "actLoadOrder orderData: %s", payload);

        if (payload.Reference == null || payload.Reference == "")
          payload = await orderApi.postOrder(payload);
        else payload = await orderApi.putOrder(payload);

        commit("mutStoreOrder", payload);
        return payload;
      } catch (err) {
        logger.get(loggingSource.StrOrder).error(err.response);
        throw new Error(err.response);
      } finally {
        logger.get(loggingSource.StrOrder).info("...actLoadOrder");
      }
    },
  },
};

const store = new Vuex.Store({
  modules: {
    order,
  },

  state: {
    currentUserPermission: [],
    referenceData: null,
    refreshTokenExpTime: null,
    axiosCallLoader: false,
    currentUser: null,

    emptyClient: {
      Reference: "",
      ClientType: "CT_S",
      Surname: "",
      GivenNames: "",
      Name: "",
      DisplayName: "",
      ABN: "",
      Addresses: [],
      SearchAddressString: "",
      Phone: "",
      EmailAddress: "",
      IsActive: true,
      RecordStatus: "inserted",
    },

    emptyUser: {
      Reference: "",
      ConsultancyRole: {
        ConsultancyRoleId: null,
        Name: "",
        Level: "",
        MaxWorkgroupAssociations: 1,
        CanBeImpersonated: true,
        CanImpersonateSelf: true,
      },
      Username: "",
      FirstName: "",
      Surname: "",
      PhoneJSON:
        '{"PhoneNumberStorageTemplate": {"PhoneNumbers": [],"SearchString": ""}}',
      EmailAddress: "",
      IsActive: true,
      Managers: [],
      Workgroups: [],
      Impersonators: [],
      Impersonates: [],
      UserPermission: [],
    },

    emptyImpersonator: {
      FromDt: "",
      ToDt: null,
      Reference: "",
      Edit: true,
      IsAdded: true,
    },

    emptyWorkgroup: {
      Reference: "",
      Name: "",
      TreeData: [],
    },

    emptyIssue: {
      Reference: "",
      Title: "",
      Description: "",
      IssueLogStatus: "ILS_D",
      RaisedDT: null,
    },

    emptySupplier: {
      Reference: "",
      Name: "",
      ContactPersonNm: "",
      Addresses: [],
      SearchAddressString: "",
      Phone: "",
      EmailAddress: "",
      BookingURL: "",
      ItineraryNotes: "",
      PreSupplierItineraryComment: "",
      PostSupplierItineraryComment: "",
      AWGTNotes: "",
      BookingMethod: "BM_P",
      SupplierNotes: [],
      DepositType: "DT_No",
      DepositAmt: 0,
      PaymentTypes: ["PY_PP", "PY_PO", "PY_Vo"],
      IsActive: true,
      FileStorageMessages: [],
      SupplierFileCategories: [],
      EditStatus: "ES_D",
    },

    emptyBooking: {
      Name: "",
      Description: "",
      BookingType: "BT_S",
      BookingStatus: "BS_Dr",
      AdditionalLoadingType: "AL_No",
      AdditionalLoadingAmt: 0,
      Reference: "",
      Client: null,
      OwnerUserReference: null,
      OwnerWorkgroupReference: null,
      BookingContacts: [],
      RequestedDt: moment(new Date().setHours(0, 0, 0, 0)).format(
        constants.DATETIMEFORMAT_Json
      ),
      DepartureDt: null,
      ReturnDt: null,
      BookingNotes: "",
      ItineraryNotes: "",
      IsTemplate: false,
      IsDeleted: false,
      VersionNum: 0,
      TripProfit: 0,
      AWGTNotes: "",
      DocumentationRoot: "",
      ConcurrencyRV: "",
      StudentsCount: 0,
      FreeOfChargeAdultsCount: 0,
      AdultsCount: 0,
      YearGroups: "",
      RecordStatus: "NoChange",
      SchoolTerm: "ST_X",
      IsFromProposal: false,
      AdditionalCharges: [],
      RebatesAndDiscounts: [],
    },

    emptyBookingContact: {
      BookingContactId: null,
      Name: "",
      Phone: "",
      EmailAddress: "",
      IsBookingContact: false,
      IsTripContact: false,
      SortOrder: 0,
    },

    emptyItinerary: {
      ItineraryStatus: "IES_D",
      Reference: null,
      Booking: {},
      Name: "",
      Description: "",
      StartDt: moment(new Date().setHours(0, 0, 0, 0)).format(
        constants.DATETIMEFORMAT_Json
      ),
      DurationInDays: 0,
      StartLocation: 0,
      EndLocation: 0,
      IsTemplate: false,
      IsActive: false,
      ItineraryDayNotes: [],
      ItineraryEntries: [],
    },

    emptyItineraryEntry: {
      ItineraryEntryId: 0,
      StartDT: "2000-01-01T11:00:00",
      EndDT: "2000-01-01T12:00:00",
      RestrictToProductTimes: true,
      Name: "",
      Description: "",
      IsIndicativeTime: false,
      Notes: "",
      PreEntryMarginInMinutes: 0,
      PostEntryMarginInMinutes: 0,
      ShowPreSupplierItineraryComment: true,
      ShowPostSupplierItineraryComment: true,
      GroupName: "",
      DisplayBoundariesOnly: false,
      GroupSize: 0,
      ChildParticipantCount: 0,
      AdultParticipantCount: 0,
      ItineraryEntryType: "",
      ItineraryEntrySubType: "",
      isSelectedInd: false,
      PrimaryProductReference: null,
      OrderProductLines: [],
    },

    emptyItineraryDayNote: {
      Date: "",
      ItineraryDayNoteType: "",
      Note: "",
      Edit: true,
    },

    emptyProduct: {
      ProductType: "PT_Ay",
      ProductSubType: "PST_AyAy",
      Reference: "",
      ResellerSupplier: {},
      ProviderSupplier: {},
      Name: "",
      DisplayName: "",
      Description: "",
      CanBeSoldSeparatelyInd: true,
      PreProductMarginInMinutes: 0,
      PostProductMarginInMinutes: 0,
      PreProductItineraryComment: "",
      PostProductItineraryComment: "",
      YearGroups: "",
      ProductAvailability: "Mo,Tu,We,Th,Fr,Sa,Su",
      BookingFeeDetail: "",
      PaymentTypes: ["PY_PP", "PY_PO", "PY_Vo"],
      FreeOfChargeRatio: "None",
      ContactPersonNm: "",
      Phone: "",
      EmailAddress: "",
      InformationURL: "",
      BookingURL: "",
      SpecialComment: "",
      AWGTNotes: "",
      BookingMethod: "BM_P",
      DepositType: "DT_No",
      DepositAmt: 0,
      IsActive: true,
      CommercialProductLines: [],
      ProductNotes: [],
      //Activity properties
      MinParticipantsPerGroup: 0,
      MaxParticipantsPerGroup: 0,
      MaxParticipantsPerTimeslot: null,
      AvailableTimes: [],
      MinDurationInMinutes: 0,
      MaxDurationInMinutes: 0,
      UseProvidersLocation: false,
      Addresses: [],
      //Accommodation properties
      MinBedsNum: null,
      MaxBedsNum: null,
      //Transportation properties
      MinSeatsNum: null,
      MaxSeatsNum: null,
      TransportationOrigins: [],
      ProductRelations: [],
      ProductGroups: [],
      ResellerSupplierProductGroups: [],
      PriceAdjustments: [],
      EditStatus: "ES_D",
    },

    emptyCountryRegion: {
      CountryRegionId: null,
      CountryRegionParentId: null,
      Name: "",
      CountryRegionTypeCd: "",
      Children: [],
    },

    emptyCommunication: {
      CommunicationDirection: "",
      ConsultantUserReference: "",
      CommunicationMedium: "",
      CommunicationWith: "CW_C",
      RelatedClientReference: "",
      RelatedBookingReference: "",
      RelatedSupplierReference: "",
      SystemActionFiles: [],
      FileStorageMessages: [],
      Reference: "",
      ContactNm: "",
      Subject: "",
      Notes: "",
      OccurrenceDT: moment(new Date().setHours(0, 0, 0, 0)).format(
        constants.DATETIMEFORMAT_Json
      ),
      ConcurrencyRV: "",
    },

    emptyProductBundle: {
      Expanded: false,
      Reference: "",
      BookingReference: "",
      ResellerSupplierReference: "",
      Comment: "",
      ExGSTAmount: 0,
      GSTAmount: 0,
      isIndicativePricingInd: false,
      DepositAmt: 0,
      DepositRequiredByDt: null,
      PrimaryBundledCommercialProducts: [],
      ItineraryEntries: [],
      RecordStatus: "Inserted",
    },

    emptyPrimaryBundledCommercialProduct: {
      Reference: null,
      ResellerSupplierReference: null,
      Name: "",
      Description: "",
      ProductType: null,
      ProductSubType: null,
      ProviderSupplierName: "",
      AdditionalDetails: {},
      AvailableTimes: [],
      DurationInMinutes: 0,
      BundledCommercialProductLines: [], //The commercial product lines selected fro this product
      RelatedBundledCommercialProducts: [],
      Product: {}, //Storage location for the complete product json
      AvailableCommercialProductLines: [], //Details of currently valid commercial product lines for the chosen product
      ExpandedProduct: false, //Determines whether the product's card is expanded.
      ExpandedProductDetail: false, //Determins whether product detail is shown.
    },

    emptyRelatedBundledCommercialProduct: {
      Reference: null,
      ResellerSupplierReference: null,
      Name: "",
      Description: "",
      ProductType: null,
      ProductSubType: null,
      ProviderSupplierName: "",
      AdditionalDetails: {},
      AvailableTimes: [],
      DurationInMinutes: 0,
      BundledCommercialProductLines: [], //The commercial product lines selected fro this product
      Product: {}, //Storage location for the complete product json
      AvailableCommercialProductLines: [], //Details of currently valid commercial product lines for the chosen product
      ExpandedProduct: false, //Determines whether the product's card is expanded.
      ExpandedProductDetail: false, //Determins whether product detail is shown.
    },

    emptyProposedCommercialProductLine: {
      Index: 0,
      Reference: null,
      SortOrder: 1,
      PriceTypeCd: null,
      Name: null,
      Description: null,
      PurchasingReminder: null,
      ActiveFromDt: null,
      ActiveToDt: null,
      MinTotalPriceAmt: 0,
      IsIndicativePricingInd: false,
      DisplayBoundariesOnly: false,
      Quantity: 0, //Price bracket from Commercial Product Line Price
      UnitQuantity: 0, //How many are being purchased
      PriceUnit: null,
      CommercialProductLinePriceId: null,
      ExGSTAmount: 0,
      GSTAmount: 0,
      PeriodSize: 0,
      MarketCategoryName: "",
      MarketCategoryId: 0,
      Entry: null,
      PurchaseProposalProductLinePriceAdjustments: [],
      CommercialProductLinePriceAdjustments: [],
    },

    emptyProductLinePrice: {
      CommercialProductLinePriceId: 0,
      ExGSTUnitAmt: 0,
      GSTUnitAmt: 0,
      TotalPrice: 0, //Not saved to the database, but is required on-screen.
      PricingScales: [],
      RecordStatus: "Unknown",
    },

    emptyCommercialProductLineChronology: {
      CommercialProductLineChronologyId: 0,
      PriceTypeCd: "PrT_I",
      Name: "",
      Description: "",
      PurchasingReminder: "",
      SingularItemAlias: "item",
      PluralItemAlias: "items",
      ActiveFromDt: null,
      ActiveToDt: null,
      MinTotalPriceAmt: 0,
      IsIndicativePricingInd: false,
      DisplayBoundariesOnly: false,
      RecordStatus: "inserted",
      MarketCategories: [],
      PurchaseProposalProductLinePriceAdjustments: [],
      CommercialProductLinePriceAdjustments: [],
      ProductLinePrices: [],
      PricingScaleTypes: [],
    },

    emptyPricingScaleType: {
      QuantityUnitCd: null,
      QuantityUnitPurposeCd: null,
      PricingScaleCd: null,
      RecordStatus: "Unknown",
      SortOrder: 0,
    },

    emptyPricingScale: {
      CommercialProductLinePricingScaleId: 0,
      QuantityUnitCd: null,
      QuantityUnitPurposeCd: null,
      PricingScaleCd: null,
      MinQuantity: null,
      MaxQuantity: null,
      SortOrder: 0,
    },

    emptyPriceAdjustment: {
      Name: "",
      Amount: 0,
      PriceChangeType: "PCT_D",
      PriceAdjustmentType: "PAT_D",
    },

    emptyPurchaseProposalPriceAdjustment: {
      Name: "",
      UnitQuantity: 0,
      PeriodSize: 0,
      PriceAdjustmentType: "",
      Amount: 0,
    },

    validDateTimeFormats: [
      "D MMM YYYY h:m A",
      "D MMM YYYY h:mA",
      "D MMM YYYY HHmm",
      "YYYY-MM-DD HH:mm",
      "YYYY-MM-DDTHH:mm:ss",
    ],

    yearGroups: [
      { Code: "K", Name: "K" },
      { Code: "1", Name: "1" },
      { Code: "2", Name: "2" },
      { Code: "3", Name: "3" },
      { Code: "4", Name: "4" },
      { Code: "5", Name: "5" },
      { Code: "6", Name: "6" },
      { Code: "7", Name: "7" },
      { Code: "8", Name: "8" },
      { Code: "9", Name: "9" },
      { Code: "10", Name: "10" },
      { Code: "11", Name: "11" },
      { Code: "12", Name: "12" },
    ],

    productAvailability: [
      { Code: "Mo", Name: "Monday" },
      { Code: "Tu", Name: "Tuesday" },
      { Code: "We", Name: "Wednesday" },
      { Code: "Th", Name: "Thursday" },
      { Code: "Fr", Name: "Friday" },
      { Code: "Sa", Name: "Saturday" },
      { Code: "Su", Name: "Sunday" },
    ],

    productMargins: [
      {
        productMarginInMinutes: 0,
        name: "0 minutes",
      },
      {
        productMarginInMinutes: 5,
        name: "5 minutes",
      },
      {
        productMarginInMinutes: 10,
        name: "10 minutes",
      },
      {
        productMarginInMinutes: 15,
        name: "15 minutes",
      },
      {
        productMarginInMinutes: 20,
        name: "20 minutes",
      },
      {
        productMarginInMinutes: 30,
        name: "30 minutes",
      },
      {
        productMarginInMinutes: 45,
        name: "45 minutes",
      },
      {
        productMarginInMinutes: 60,
        name: "1 hour",
      },
      {
        productMarginInMinutes: 75,
        name: "75 minutes",
      },
      {
        productMarginInMinutes: 90,
        name: "1 1/2 hours",
      },
      {
        productMarginInMinutes: 120,
        name: "2 hours",
      },
    ],

    currentOrder: {},
  },

  getters: {
    getOrderStatusNameFromCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getOrderStatusNameFromCode...");
        return getReferenceDataNameFromCode(
          state.referenceData.OrderStatuses,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getOrderStatusNameFromCode");
      }
    },
    getOrderStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getOrderStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.OrderStatuses,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getOrderStatuses");
        }
      },
    getUserPermissionsGroup: (state) =>
      state.referenceData?.UserPermissionsGroup,
    getRefreshTokenExpTime: (state) => state.refreshTokenExpTime,
    getRefreshTokenExpiryInMinutesFromReference: (state) => {
      return state.referenceData?.RefreshTokenExpiryInMinutes;
    },
    //The following is reference data retrieved from the database
    getProductMargins: (state) => state.productMargins,

    getYearGroups: (state) => state.yearGroups,

    getProductAvailability: (state) => state.productAvailability,

    getValidDateTimeFormats: (state) => state.validDateTimeFormats,

    checkPermission: (state) => (targetPermission) => {
      if (
        state.currentUserPermission.findIndex((cp) => {
          return cp.Permission == targetPermission;
        }) >= 0
      )
        return true;
      else false;
    },

    getSystemScreens:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getSystemScreen...");

          if (state.referenceData == null)
            return [{ Name: "Loading getSystemScreen wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SystemScreens,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getSystemScreen");
        }
      },

    getTooltipsTextByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getTooltipsTextByCode...");

        let text = state.referenceData?.Tooltips?.find(
          (rd) => rd.Code == code
        )?.Name;

        if (text != undefined) return text;
        else {
          logger
            .get(loggingSource.StrReferenceData)
            .error(`Tooltip id ${code} does not exist`);
          return "";
        }
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getTooltipsTextByCode");
      }
    },

    getPrimaryOrderProductSubTypesForItineraryEntrySubType:
      (state) => (itineraryEntrySubType) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getPrimaryOrderProductSubTypesForItineraryEntrySubType...");
          return state.referenceData.ItineraryEntryTypeToProductTypeMap.filter(
            (pbst) =>
              pbst.ProductTypeCd != "PT_Me" &&
              pbst.ItineraryEntrySubTypeCd == itineraryEntrySubType
          ).map((pb) => {
            return pb.ProductSubTypeCd;
          });
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getPrimaryOrderProductSubTypesForItineraryEntrySubType");
        }
      },

    getMerchandiseProductBundleSubTypesForItineraryEntrySubType:
      (state) => (itineraryEntrySubType) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info(
              "getMerchandiseProductBundleSubTypesForItineraryEntrySubType..."
            );
          return state.referenceData.ItineraryEntryTypeToProductTypeMap.filter(
            (pbst) =>
              pbst.ProductTypeCd == "PT_Me" &&
              pbst.ItineraryEntrySubTypeCd == itineraryEntrySubType
          ).map((pb) => {
            return pb.ProductSubTypeCd;
          });
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info(
              "...getMerchandiseProductBundleSubTypesForItineraryEntrySubType"
            );
        }
      },

    getSchoolTermColorFromCode: (state) => (term) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSchoolTermColor...");
        return state.referenceData.SchoolTerms.find(
          (schoolTerm) => schoolTerm.Code == term
        )?.ColourCode;
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSchoolTermColor");
      }
    },

    getSchoolTermNameFromCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSchoolTermNameFromCode...");
        return getReferenceDataNameFromCode(
          state.referenceData.SchoolTerms,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSchoolTermNameFromCode");
      }
    },

    getBookingStatusColorFromCode: (state) => (bookingStatus) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingStatusColorFromCode...");
        return state.referenceData.BookingStatuses.find(
          (bs) => bs.Code == bookingStatus
        )?.ColourCode;
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingStatusColorFromCode");
      }
    },

    getIssueStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getIssueStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.IssueStatuses,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getIssueStatuses");
        }
      },

    getSystemTemplate:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getSystemTemplate...");

          if (state.referenceData == null)
            return [{ Template: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SystemTemplate,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getSystemTemplate");
        }
      },

    getFileCategories: (state) => () => {
      try {
        logger.get(loggingSource.StrReferenceData).info("getFileCategories...");

        if (state.referenceData == null) return [];

        return getReferenceData(state.referenceData.FileCategories, false);
      } finally {
        logger.get(loggingSource.StrReferenceData).info("...getFileCategories");
      }
    },

    getDefaultSupplierNotes: (state) => () => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getDefaultSupplierNotes...");

        if (state.referenceData == null) return [];

        console.log(
          "DefaultSupplierNotes: ",
          getReferenceData(state.referenceData.DefaultSupplierNotes, false)
        );
        return getReferenceData(
          state.referenceData.DefaultSupplierNotes,
          false
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getDefaultSupplierNotes");
      }
    },

    getSystemTemplateFromCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSystemTemplateFromCode...");

        if (!state.referenceData) return null;
        else
          return state.referenceData.SystemTemplate.find(
            (st) => st.Code == code
          ).Template;
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSystemTemplateFromCode");
      }
    },

    getConsultancyRoleLevelFromCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getConsultancyRoleLevelFromCode...");
        return state.referenceData?.ConsultancyRoles.find(
          (st) => st.ConsultancyRoleId == code
        ).Level;
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getConsultancyRoleLevelFromCode");
      }
    },

    getIssueStatusNameFromCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getIssueStatusNameFromCode...");
        return getReferenceDataNameFromCode(
          state.referenceData.IssueStatuses,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getIssueStatusNameFromCode");
      }
    },

    getConsultancyRoles:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getConsultancyRoles...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          var data = state.referenceData.ConsultancyRoles;
          if (addEmptyEntry == true) {
            data.unshift({
              Name: "",
              Code: "",
              Level: 0,
            });
          }
          return data.map((item) => {
            return {
              Name: item.Name,
              Code: item.ConsultancyRoleId,
              Level: item.Level,
            };
          });
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getConsultancyRoles");
        }
      },

    getAdditionalLoadingTypes:
      (state) =>
      (bookingTypeCd, addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getAdditionalLoading...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          //If bookingTypeCd = BT_S then remove AL_PP
          //If bookingTypeCd != BT_S then remove AL_St
          var data = state.referenceData.AdditionalLoadingTypes.filter(
            (e) => e.Code != (bookingTypeCd == "BT_S" ? "AL_PP" : "AL_St")
          );
          if (addEmptyEntry == true) {
            data.unshift({
              Name: "",
              Code: "",
            });
          }
          return data;
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getAdditionalLoading");
        }
      },

    getConsultancyRoleNameById: (state) => (id) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getConsultancyRoleNameById...");
        if (id != null) {
          return state.referenceData.ConsultancyRoles.find(
            (rd) => rd.ConsultancyRoleId == id
          ).Name;
        } else {
          return "";
        }
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getConsultancyRoleNameById");
      }
    },

    getAddressSubdivisions:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getAddressSubdivisions...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.AddressSubdivisions,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getAddressSubdivisions");
        }
      },

    getAddressSubdivisionNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getAddressSubdivisionNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.AddressSubdivisions,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getAddressSubdivisionNameByCode");
      }
    },

    getDepositTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getDepositTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.DepositTypes,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getDepositTypes");
        }
      },

    getDepositTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getDepositTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.DepositTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getDepositTypeNameByCode");
      }
    },

    getPaymentTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getPaymentTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.PaymentTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getPaymentTypeNameByCode");
      }
    },

    getBookingMethodNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingMethodNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.BookingMethods,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingMethodNameByCode");
      }
    },

    getClientTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getClientTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ClientTypes,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getClientTypes");
        }
      },

    getClientTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getClientTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ClientTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getClientTypeNameByCode");
      }
    },

    getSchoolTerms:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getSchoolTerms...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SchoolTerms,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getSchoolTerms");
        }
      },

    getSchoolTermNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSchoolTermNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.SchoolTerms,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSchoolTermNameByCode");
      }
    },

    getBookingStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getBookingStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.BookingStatuses,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getBookingStatuses");
        }
      },

    getBookingStatusNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingStatusNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.BookingStatuses,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingStatusNameByCode");
      }
    },

    getBookingAdjustmentAppliesToNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingAdjustmentAppliesToNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.BookingAdjustmentAppliesToNames,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingAdjustmentAppliesToNameByCode");
      }
    },

    /*getBookingAdjustmentTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingAdjustmentTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.BookingAdjustmentTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingAdjustmentTypeNameByCode");
      }
    },*/

    getPriceAdjustmentTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getPriceAdjustmentTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.PriceAdjustmentTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getPriceAdjustmentTypes");
        }
      },

    getPriceAdjustmentTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getPriceAdjustmentTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.PriceAdjustmentTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getPriceAdjustmentTypeNameByCode");
      }
    },

    getPriceChangeTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getPriceChangeTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.PriceChangeTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getPriceChangeTypes");
        }
      },

    getPriceChangeTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getPriceChangeTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.PriceChangeTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getPriceChangeTypeNameByCode");
      }
    },

    getBookingTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getBookingTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.BookingTypes,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getBookingTypes");
        }
      },

    getBookingTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getBookingTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.BookingTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getBookingTypeNameByCode");
      }
    },

    getCommunicationDirections:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getCommunicationDirections...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.CommunicationDirections,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getCommunicationDirections");
        }
      },

    getCommunicationDirectionNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getCommunicationDirectionNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.CommunicationDirections,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getCommunicationDirectionNameByCode");
      }
    },

    getCommunicationMediums:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getCommunicationMediums...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.CommunicationMediums,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getCommunicationMediums");
        }
      },

    getCommunicationMediumNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getCommunicationMediumNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.CommunicationMediums,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getCommunicationMediumNameByCode");
      }
    },

    getSystemActionSubTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getSystemActionSubTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SystemActionSubTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getSystemActionSubTypes");
        }
      },
    getSystemActionSubTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSystemActionSubTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.SystemActionSubTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSystemActionSubTypeNameByCode");
      }
    },
    getEmailAddressTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getEmailAddressTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.EmailAddressTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getEmailAddressTypes");
        }
      },

    getEmailAddressTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getEmailAddressTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.EmailAddressTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getEmailAddressTypeNameByCode");
      }
    },

    getEmailStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getEmailStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.EmailStatuses,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getEmailStatuses");
        }
      },

    getEmailStatuseNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getEmailStatuseNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.EmailStatuses,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getEmailStatuseNameByCode");
      }
    },

    getItineraryEntryTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getItineraryEntryTypes...");

          if (state.referenceData == null) {
            return [{ Name: "Loading please wait...", Code: null }];
          }

          return getReferenceData(
            state.referenceData.ItineraryEntryTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getItineraryEntryTypes");
        }
      },

    getItineraryEntryTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getItineraryEntryTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ItineraryEntryTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getItineraryEntryTypeNameByCode");
      }
    },

    getItineraryEntrySubTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getItineraryEntrySubTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ItineraryEntrySubTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getItineraryEntrySubTypes");
        }
      },

    getItineraryStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getItineraryEntryStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ItineraryStatuses,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getItineraryEntryStatuses");
        }
      },

    getItineraryEntrySubTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getItineraryEntrySubTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ItineraryEntrySubTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getItineraryEntrySubTypeNameByCode");
      }
    },

    getItineraryEntrySubTypesByItineraryEntryType:
      (state) => (itineraryEntryType) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getItineraryEntrySubTypesByItineraryEntryType...");

          return state.referenceData.ItineraryEntrySubTypes.filter(
            (ies) => ies.ItineraryEntryTypeCd == itineraryEntryType
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getItineraryEntrySubTypesByItineraryEntryType");
        }
      },

    getOSFileTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getOSFileTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.OSFileTypes,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getOSFileTypes");
        }
      },

    getOSFileTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getOSFileTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.OSFileTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getOSFileTypeNameByCode");
      }
    },

    getProductBookingMethods:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getProductBookingMethods...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ProductBookingMethods,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getProductBookingMethods");
        }
      },

    getProductBookingMethodNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getProductBookingMethodNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ProductBookingMethods,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getProductBookingMethodNameByCode");
      }
    },

    getProductRelationTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getProductRelationTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ProductRelationTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getProductRelationTypes");
        }
      },

    getProductRelationTypesByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getProductRelationTypesByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ProductRelationTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getProductRelationTypesByCode");
      }
    },

    getItineraryDayNoteTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getItineraryDayNoteTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ItineraryDayNoteTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getItineraryDayNoteTypes");
        }
      },

    getItineraryDayNoteTypesByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getItineraryDayNoteTypesByCode...");

        if (state.referenceData == null)
          return [{ Name: "Loading please wait...", Code: null }];

        return getReferenceDataNameFromCode(
          state.referenceData.ItineraryDayNoteTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getItineraryDayNoteTypesByCode");
      }
    },

    getProductTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getProductTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.ProductTypes,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getProductTypes");
        }
      },

    getProductTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getProductTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.ProductTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getProductTypeNameByCode");
      }
    },

    getEditStatuses:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getEditStatuses...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.EditStatuses,
            addEmptyEntry
          );
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getEditStatuses");
        }
      },

    getEditStatusNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getProductTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.EditStatuses,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getProductTypeNameByCode");
      }
    },

    getSystemActionContexts:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getSystemActionContexts...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SystemActionContexts,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getSystemActionContexts");
        }
      },

    getSystemActionContextNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSystemActionContextNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.SystemActionContexts,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSystemActionContextNameByCode");
      }
    },

    getSystemActionTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getSystemActionTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SystemActionTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getSystemActionTypes");
        }
      },

    getSystemActionTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSystemActionTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.SystemActionTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSystemActionTypeNameByCode");
      }
    },

    getCountryRegionTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getCountryRegionTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.CountryRegionTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getCountryRegionTypes");
        }
      },

    getCountryRegionTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getCountryRegionTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.CountryRegionTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getCountryRegionTypeNameByCode");
      }
    },

    getQuantityUnits:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getQuantityUnits...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.QuantityUnits,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getQuantityUnits");
        }
      },

    getQuantityUnitPurposes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getQuantityUnitPurposes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.QuantityUnitPurposes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getQuantityUnitPurposes");
        }
      },

    getQuantityUnitNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getQuantityUnitNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.QuantityUnits,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getQuantityUnitNameByCode");
      }
    },

    getPricingScales:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getPricingScales...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.PricingScales,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getPricingScales");
        }
      },

    getPricingScaleNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getPricingScaleNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.PricingScales,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getPricingScaleNameByCode");
      }
    },

    getNoteTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger.get(loggingSource.StrReferenceData).info("getNoteTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(state.referenceData.NoteTypes, addEmptyEntry);
        } finally {
          logger.get(loggingSource.StrReferenceData).info("...getNoteTypes");
        }
      },

    getNoteTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getNoteTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.NoteTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getNoteTypeNameByCode");
      }
    },

    getSupplierFileCategoryTypes:
      (state) =>
      (addEmptyEntry = false) => {
        try {
          logger
            .get(loggingSource.StrReferenceData)
            .info("getSupplierFileCategoryTypes...");

          if (state.referenceData == null)
            return [{ Name: "Loading please wait...", Code: null }];

          return getReferenceData(
            state.referenceData.SupplierFileCategoryTypes,
            addEmptyEntry
          );
        } finally {
          logger
            .get(loggingSource.StrReferenceData)
            .info("...getSupplierFileCategoryTypes");
        }
      },

    getSupplierFileCategoryTypeNameByCode: (state) => (code) => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getSupplierFileCategoryTypeNameByCode...");

        return getReferenceDataNameFromCode(
          state.referenceData.SupplierFileCategoryTypes,
          code
        );
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getSupplierFileCategoryTypeNameByCode");
      }
    },

    getMarketCategories: (state) => () => {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("getMarketCategoriess...");

        if (state.referenceData == null)
          return [{ Name: "Loading please wait...", Code: null }];

        return getReferenceData(state.referenceData.MarketCategories, false);
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...getMarketCategoriess");
      }
    },

    //The following are empty entity templates
    getEmptyUser: (state) => state.emptyUser,
    getEmptyImpersonator: (state) => state.emptyImpersonator,
    getEmptyIssue: (state) => state.emptyIssue,
    getEmptyWorkgroup: (state) => state.emptyWorkgroup,
    getEmptyBooking: (state) => state.emptyBooking,
    getEmptySupplier: (state) => {
      try {
        logger.get(loggingSource.StrReferenceData).info("getEmptySupplier...");

        let emptySupplier = state.emptySupplier;

        if (state.referenceData) {
          let addressTemplate = state.referenceData.SystemTemplate.find(
            (t) => t.Code == "STT_S_Ad"
          ).Template;
          emptySupplier.Addresses = addressTemplate.Addresses;
        }

        return emptySupplier;
      } finally {
        logger.get(loggingSource.StrReferenceData).info("...getEmptySupplier");
      }
    },
    getEmptyClient: (state) => {
      try {
        logger.get(loggingSource.StrReferenceData).info("getEmptyClient...");

        let emptyClient = state.emptyClient;

        if (state.referenceData) {
          let addressTemplate = state.referenceData.SystemTemplate.find(
            (t) => t.Code == "STT_S_Ad"
          ).Template;
          emptyClient.Addresses = addressTemplate.Addresses;
        }

        return emptyClient;
      } finally {
        logger.get(loggingSource.StrReferenceData).info("...getEmptyClient");
      }
    },
    getEmptyItinerary: (state) => state.emptyItinerary,
    getEmptyItineraryEntry: (state) => state.emptyItineraryEntry,
    getEmptyItineraryDayNote: (state) => state.emptyItineraryDayNote,
    getEmptyProductBundle: (state) => state.emptyProductBundle,
    getEmptyPrimaryBundledCommercialProduct: (state) =>
      state.emptyPrimaryBundledCommercialProduct,
    getEmptyRelatedBundledCommercialProduct: (state) =>
      state.emptyRelatedBundledCommercialProduct,
    getEmptyProposedCommercialProductLine: (state) =>
      state.emptyProposedCommercialProductLine,
    getEmptyCommercialProductLineChronology: (state) =>
      state.emptyCommercialProductLineChronology,
    getEmptyPricingScaleType: (state) => state.emptyPricingScaleType,
    getEmptyPricingScale: (state) => state.emptyPricingScale,
    getEmptyProductLinePrice: (state) => state.emptyProductLinePrice,
    getEmptyPriceAdjustment: (state) => state.emptyPriceAdjustment,
    getEmptyPurchaseProposalPriceAdjustment: (state) =>
      state.emptyPurchaseProposalPriceAdjustment,
    getEmptyProduct: (state) => {
      try {
        logger.get(loggingSource.StrReferenceData).info("getEmptyProduct...");

        let emptyProduct = state.emptyProduct;

        if (state.referenceData) {
          let addressTemplate = state.referenceData.SystemTemplate.find(
            (t) => t.Code == "STT_S_AP"
          ).Template;
          emptyProduct.Addresses = addressTemplate.Addresses;
        }

        return emptyProduct;
      } finally {
        logger.get(loggingSource.StrReferenceData).info("...getEmptyProduct");
      }
    },
    getEmptyCountryRegion: (state) => state.emptyCountryRegion,
    getEmptyBookingContact: (state) => state.emptyBookingContact,
    getAxiosCallLoaderStatus: (state) => state.axiosCallLoader,

    //The following is for retrieving the current user from local storage.
    getEmptyCommunication: (state) => state.emptyCommunication,

    //TODO: The following are to be replaced...
    //Product Management
    //getProducts: state => state.products,
    //getProductByProductId: state => productId =>
    //  state.products.find(p => p.productId == productId)
  },

  mutations: {
    mutAxiosCallLoaderStatus(state, payload) {
      logger
        .get(loggingSource.StrReferenceData)
        .info("mutAxiosCallLoaderStatus...");
      logger
        .get(loggingSource.StrReferenceData)
        .debug("mutAxiosCallLoaderStatus => payload: %s", payload);

      state.axiosCallLoader = payload;

      logger
        .get(loggingSource.StrReferenceData)
        .info("...mutAxiosCallLoaderStatus");
    },

    mutRefreshTokenExpTime(state, payload) {
      logger
        .get(loggingSource.StrReferenceData)
        .info("mutRefreshTokenExpTime...");
      logger
        .get(loggingSource.StrReferenceData)
        .debug("mutRefreshTokenExpTime => payload: %s", payload);

      state.refreshTokenExpTime = payload;
      localStorage.setItem("refreshTokenExpTime", payload);

      logger
        .get(loggingSource.StrReferenceData)
        .info("...mutRefreshTokenExpTime");
    },

    //Store reference data
    mutStoreReferenceData(state, payload) {
      logger
        .get(loggingSource.StrReferenceData)
        .info("mutStoreReferenceData...");
      logger
        .get(loggingSource.StrReferenceData)
        .debug("Parameters => payload: %s", payload);

      state.referenceData = payload;

      logger
        .get(loggingSource.StrReferenceData)
        .info("...mutStoreReferenceData");
    },

    mutStoreCurrentUser(state, payload) {
      logger.get(loggingSource.StrReferenceData).info("mutStoreCurrentUser...");
      logger
        .get(loggingSource.StrReferenceData)
        .debug("Parameters => payload: %s", payload);

      state.currentUser = payload;

      logger.get(loggingSource.StrReferenceData).info("...mutStoreCurrentUser");
    },

    mutClearCurrentUser(state) {
      logger.get(loggingSource.StrReferenceData).info("mutClearCurrentUser...");

      state.currentUser = null;

      logger.get(loggingSource.StrReferenceData).info("...mutClearCurrentUser");
    },

    mutStoreCurrentUserPermission(state, payload) {
      logger
        .get(loggingSource.StrReferenceData)
        .info("mutStoreCurrentUserPermission...");
      logger
        .get(loggingSource.StrReferenceData)
        .debug("Parameters => payload: %s", payload);

      state.currentUserPermission = payload;

      logger
        .get(loggingSource.StrReferenceData)
        .info("...mutStoreCurrentUserPermission");
    },
  },

  actions: {
    async actLoadReferenceData({ commit }) {
      try {
        logger
          .get(loggingSource.StrReferenceData)
          .info("actLoadReferenceData...");

        const response = await referenceApi.getReferenceData();
        let referenceData = response;

        logger
          .get(loggingSource.StrReferenceData)
          .trace(
            loggingSource.StrReferenceData,
            "actLoadReferenceData response: %s",
            response
          );
        logger
          .get(loggingSource.StrReferenceData)
          .debug(
            loggingSource.StrReferenceData,
            "actLoadReferenceData referencedata: %s",
            referenceData
          );
        commit("mutStoreReferenceData", response);
      } catch (err) {
        logger.get(loggingSource.StrReferenceData).error(err.response);
        throw new Error(err.response);
      } finally {
        logger
          .get(loggingSource.StrReferenceData)
          .info("...actLoadReferenceData");
      }
    },
  },
});

function getReferenceData(referenceData, addEmptyEntry) {
  try {
    logger.get(loggingSource.StrReferenceData).info("getReferenceData...");

    let data = lodash.clone(referenceData);

    if (addEmptyEntry == true) {
      data.unshift({
        Name: "",
        Code: "",
      });
    }

    logger
      .get(loggingSource.StrReferenceData)
      .debug("Reference data: %s", data);

    return data;
  } catch (err) {
    logger.get(loggingSource.StrReferenceData).error(err);
    throw new Error(err);
  } finally {
    logger.get(loggingSource.StrReferenceData).info("...getReferenceData");
  }
}

function getReferenceDataNameFromCode(referenceData, code) {
  return referenceData.find((rd) => rd.Code == code).Name;
}

function getNextOrderProductIdx(orderProducts) {
  return Math.max(...orderProducts.map((op) => op.OrderProductIdx), 0) + 1;
}

function getNextOrderProductLineIdx(orderProductLines) {
  return (
    Math.max(...orderProductLines.map((opl) => opl.OrderProductLineIdx), 0) + 1
  );
}

export default store;
