var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
            },
            [
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "pricingComponents_Past",
                  type: "checkbox",
                  name: "pricingComponents",
                  label: "Past",
                },
                model: {
                  value: _vm.pricingComponents_Past,
                  callback: function ($$v) {
                    _vm.pricingComponents_Past = $$v
                  },
                  expression: "pricingComponents_Past",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "pricingComponents_Current",
                  type: "checkbox",
                  name: "pricingComponents",
                  label: "Current",
                },
                model: {
                  value: _vm.pricingComponents_Current,
                  callback: function ($$v) {
                    _vm.pricingComponents_Current = $$v
                  },
                  expression: "pricingComponents_Current",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "pricingComponents_Future",
                  type: "checkbox",
                  name: "pricingComponents",
                  label: "Future",
                },
                model: {
                  value: _vm.pricingComponents_Future,
                  callback: function ($$v) {
                    _vm.pricingComponents_Future = $$v
                  },
                  expression: "pricingComponents_Future",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3",
            },
            [
              _c("awgt-std-switch", {
                staticClass: "mr-3",
                attrs: { offLabel: "edit", onLabel: "reorder" },
                on: {
                  getValue: (value) => {
                    _vm.reorderItemsInd = value
                  },
                },
              }),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onAddCommercialProductLine },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._m(0),
      _vm._l(_vm.filteredData, function (pc, index) {
        return _c(
          "mdb-card",
          {
            key: index,
            staticClass: "py-1 mb-1",
            style: [
              { "background-color": "#f5f5f5" },
              pc.priceCurrency == "C"
                ? { color: "inherit" }
                : { color: "grey" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  border: "none",
                  "background-color": "inherit",
                  "text-align": "left",
                  color: "inherit",
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleCommercialProductLineExpansion(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "flex-fill" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", { staticClass: "flex-fill ml-2" }, [
                      _vm._v(
                        " " + _vm._s(pc.Chronology[pc.ChronologyHeaderIdx].Name)
                      ),
                    ]),
                    _c("span", { staticClass: "flex-fill ml-2" }, [
                      _vm._v(
                        _vm._s(
                          pc.Chronology[
                            pc.ChronologyHeaderIdx
                          ].MarketCategories.map((mt) => {
                            return mt.Name
                          }).join(", ")
                        ) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "flex-fill ml-2" },
                      _vm._l(
                        pc.Chronology[pc.ChronologyHeaderIdx].ProductLinePrices,
                        function (p, ipl) {
                          return _c("div", { key: ipl }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPricing(
                                    pc.Chronology[pc.ChronologyHeaderIdx],
                                    p
                                  )
                                ) + " "
                              ),
                              pc.Chronology[pc.ChronologyHeaderIdx]
                                .IsIndicativePricingInd == true
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            position: "bottom",
                                            arrow: true,
                                          },
                                          expression:
                                            "{ position: 'bottom', arrow: true }",
                                        },
                                      ],
                                      staticStyle: {
                                        border: "none",
                                        "background-color": "inherit",
                                        color: "grey",
                                      },
                                      attrs: {
                                        type: "button",
                                        title: _vm.getTooltipsTextByCode(11),
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticStyle: { cursor: "pointer" },
                                        style: {
                                          color: "orange",
                                        },
                                        attrs: {
                                          far: "",
                                          icon: "exclamation-circle",
                                          size: "1x",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("br"),
                          ])
                        }
                      ),
                      0
                    ),
                    pc.Chronology[pc.ChronologyHeaderIdx].ActiveToDt == null
                      ? _c("span", { staticClass: "flex-fill ml-2" }, [
                          _vm._v(
                            " From " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  pc.Chronology[pc.ChronologyHeaderIdx]
                                    .ActiveFromDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", { staticClass: "flex-fill ml-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  pc.Chronology[pc.ChronologyHeaderIdx]
                                    .ActiveFromDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  pc.Chronology[pc.ChronologyHeaderIdx]
                                    .ActiveToDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " "
                          ),
                        ]),
                  ]),
                ]),
                _vm.reorderItemsInd == true
                  ? _c("span", { staticClass: "float-right mr-0" }, [
                      pc.SortOrder > 0
                        ? _c(
                            "button",
                            {
                              staticStyle: {
                                border: "none",
                                "background-color": "inherit",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onReorderUp(pc)
                                },
                              },
                            },
                            [
                              _c("mdb-icon", {
                                staticClass: "py-auto px-2",
                                attrs: {
                                  icon: "arrow-up",
                                  size: "2x",
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", {
                            staticStyle: {
                              "min-width": "56px",
                              display: "inline-block",
                            },
                          }),
                      pc.SortOrder < _vm.filteredData.length - 1
                        ? _c(
                            "button",
                            {
                              staticStyle: {
                                border: "none",
                                "background-color": "inherit",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onReorderDown(pc)
                                },
                              },
                            },
                            [
                              _c("mdb-icon", {
                                staticClass: "py-auto px-2",
                                attrs: {
                                  icon: "arrow-down",
                                  size: "2x",
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", {
                            staticStyle: {
                              "min-width": "56px",
                              display: "inline-block",
                            },
                          }),
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "float-right mr-1",
                        staticStyle: { width: "80px" },
                      },
                      [
                        _c(
                          "span",
                          [
                            _vm.commercialProductLineExpansion[index] == true
                              ? _c("mdb-icon", {
                                  staticClass: "py-auto",
                                  attrs: {
                                    icon: "angle-up",
                                    size: "2x",
                                    color: "primary",
                                  },
                                })
                              : _c("mdb-icon", {
                                  staticClass: "py-auto",
                                  attrs: {
                                    icon: "angle-down",
                                    size: "2x",
                                    color: "primary",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticStyle: {
                              border: "none",
                              "background-color": "inherit",
                              width: "43px",
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.initiateEdit(
                                  pc.CommercialProductLineId
                                )
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              attrs: {
                                color: "primary",
                                icon: "edit",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            ),
            _c(
              "expansion-region",
              {
                staticClass: "mx-3",
                attrs: {
                  toggle: _vm.commercialProductLineExpansion[index] == true,
                  useVShowConditional: "",
                  tag: "div",
                },
              },
              [
                _c(
                  "mdb-card-body",
                  { staticClass: "pt-1" },
                  [
                    pc.Chronology[pc.ChronologyHeaderIdx].Description.length > 0
                      ? _c("div", {
                          staticClass: "mb-3",
                          domProps: {
                            innerHTML: _vm._s(
                              pc.Chronology[pc.ChronologyHeaderIdx].Description
                            ),
                          },
                        })
                      : _vm._e(),
                    _c("mdb-card-title", { staticClass: "mt-0 mb-0" }, [
                      _vm._v("Price History"),
                    ]),
                    _c(
                      "mdb-card-text",
                      { staticStyle: { color: "inherit" } },
                      [
                        _c("div", { staticClass: "d-flex flex-grow-1 mx-2" }, [
                          _c(
                            "span",
                            { staticClass: "flex-fill font-weight-bolder" },
                            [_vm._v("Component")]
                          ),
                          _c(
                            "span",
                            { staticClass: "flex-fill font-weight-bolder" },
                            [_vm._v("Market Category")]
                          ),
                          _c(
                            "span",
                            { staticClass: "flex-fill font-weight-bolder" },
                            [_vm._v("Pricing")]
                          ),
                          _c(
                            "span",
                            { staticClass: "flex-fill font-weight-bolder" },
                            [_vm._v("Validity")]
                          ),
                        ]),
                        _vm._l(
                          _vm.sortedChronology(pc),
                          function (pcChronology) {
                            return _c(
                              "div",
                              {
                                key: pcChronology.CommercialProductLineChronologyId,
                                staticClass:
                                  "d-flex mx-2 mb-2 align-items-center",
                                class: [
                                  {
                                    bold:
                                      new Date(pcChronology.ActiveFromDt) <=
                                        new Date() &&
                                      (pcChronology.ActiveToDt == null ||
                                        new Date(pcChronology.ActiveToDt) >=
                                          new Date()),
                                  },
                                ],
                              },
                              [
                                _c("span", { staticClass: "flex-fill" }, [
                                  _vm._v(_vm._s(pcChronology.Name)),
                                ]),
                                _c("span", { staticClass: "flex-fill" }, [
                                  _vm._v(
                                    _vm._s(
                                      pcChronology.MarketCategories.map(
                                        (mt) => {
                                          return mt.Name
                                        }
                                      ).join(", ")
                                    ) + " "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "flex-fill" },
                                  _vm._l(
                                    pcChronology.ProductLinePrices,
                                    function (p, ipl) {
                                      return _c("div", { key: ipl }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPricing(pcChronology, p)
                                            ) + " "
                                          ),
                                          pcChronology.IsIndicativePricingInd ==
                                          true
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        position: "bottom",
                                                        arrow: true,
                                                      },
                                                      expression:
                                                        "{ position: 'bottom', arrow: true }",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    border: "none",
                                                    "background-color":
                                                      "inherit",
                                                    color: "grey",
                                                  },
                                                  attrs: {
                                                    type: "button",
                                                    title:
                                                      _vm.getTooltipsTextByCode(
                                                        11
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    style: {
                                                      color: "orange",
                                                    },
                                                    attrs: {
                                                      far: "",
                                                      icon: "exclamation-circle",
                                                      size: "1x",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                pcChronology.ActiveToDt == null
                                  ? _c("span", { staticClass: "flex-fill" }, [
                                      _vm._v(
                                        " From " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              pcChronology.ActiveFromDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "flex-fill" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              pcChronology.ActiveFromDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              pcChronology.ActiveToDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.showCommercialProductLineEditDialog == true
        ? _c(
            "mdb-modal",
            {
              attrs: {
                size: "lg",
                show: _vm.showCommercialProductLineEditDialog,
              },
            },
            [
              _c("commercial-product-line-edit-dialog", {
                attrs: { priceAdjustments: _vm.priceAdjustments },
                on: { close: _vm.onEditDialogClose, input: _vm.onInput },
                model: {
                  value: _vm.editItem,
                  callback: function ($$v) {
                    _vm.editItem = $$v
                  },
                  expression: "editItem",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-grow-1" }, [
      _c("span", { staticClass: "flex-fill font-weight-bolder pl-2" }, [
        _vm._v("Component"),
      ]),
      _c("span", { staticClass: "flex-fill font-weight-bolder" }, [
        _vm._v("Market Category"),
      ]),
      _c("span", { staticClass: "flex-fill font-weight-bolder" }, [
        _vm._v("Pricing"),
      ]),
      _c("span", { staticClass: "flex-fill font-weight-bolder" }, [
        _vm._v("Validity"),
      ]),
      _c("span", { staticStyle: { width: "60px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }