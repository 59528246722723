var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center mb-3" }, [_vm._v("Supplier Edit")]),
    _c(
      "div",
      {
        staticClass: "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c("div", [
          _vm.supplier.Reference != ""
            ? _c("h4", { staticClass: "mx-2" }, [
                _vm._v(" Reference: " + _vm._s(_vm.supplier.Reference) + " "),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _c("awgt-std-dropdown", {
              staticClass: "mx-2",
              staticStyle: { "max-width": "110px" },
              attrs: {
                items: _vm.getEditStatuses(false),
                itemValueProperty: "Code",
                itemTextProperty: "Name",
                label: "Edit Status",
                bg: "",
              },
              model: {
                value: _vm.supplier.EditStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "EditStatus", $$v)
                },
                expression: "supplier.EditStatus",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "300px" },
              attrs: {
                counter: "",
                counterMaxValue: 200,
                maxlength: "200",
                label: "Supplier Name",
                bg: "",
              },
              model: {
                value: _vm.supplier.Name,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "Name", $$v)
                },
                expression: "supplier.Name",
              },
            }),
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "300px" },
              attrs: {
                counter: "",
                counterMaxValue: 85,
                maxlength: "85",
                label: "Contact Name",
                bg: "",
              },
              model: {
                value: _vm.supplier.ContactPersonNm,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "ContactPersonNm", $$v)
                },
                expression: "supplier.ContactPersonNm",
              },
            }),
          ],
          1
        ),
        _c("asoftAddressesCapture", {
          attrs: { template: _vm.getSystemTemplateFromCode("STT_C_Ad") },
          model: {
            value: _vm.supplier.Addresses,
            callback: function ($$v) {
              _vm.$set(_vm.supplier, "Addresses", $$v)
            },
            expression: "supplier.Addresses",
          },
        }),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "phone", color: "primary", size: "2x" },
            }),
            _c("asoft-phone-input", {
              staticClass: "mx-2",
              staticStyle: { width: "180px" },
              attrs: {
                id: "client-phone",
                maxlength: "20",
                label: "Phone",
                bg: "",
              },
              model: {
                value: _vm.supplier.Phone,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "Phone", $$v)
                },
                expression: "supplier.Phone",
              },
            }),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "at", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    counter: "",
                    counterMaxValue: 254,
                    maxlength: "254",
                    label: "Email",
                    bg: "",
                  },
                  model: {
                    value: _vm.supplier.EmailAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.supplier, "EmailAddress", $$v)
                    },
                    expression: "supplier.EmailAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "link", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    counter: "",
                    counterMaxValue: 254,
                    maxlength: "254",
                    label: "Booking URL",
                    bg: "",
                  },
                  model: {
                    value: _vm.supplier.BookingURL,
                    callback: function ($$v) {
                      _vm.$set(_vm.supplier, "BookingURL", $$v)
                    },
                    expression: "supplier.BookingURL",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "mdb-form-inline",
          { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
          [
            _c(
              "span",
              { staticClass: "mr-5", staticStyle: { "min-width": "40px" } },
              [_vm._v("Booking Method")]
            ),
            _c("awgt-input", {
              staticClass: "mx-3",
              attrs: {
                id: "bookingMethod_Phone",
                type: "radio",
                name: "BookingMethodGroup",
                label: "Phone",
                radioValue: "BM_P",
              },
              model: {
                value: _vm.supplier.BookingMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "BookingMethod", $$v)
                },
                expression: "supplier.BookingMethod",
              },
            }),
            _c("awgt-input", {
              staticClass: "mx-3",
              attrs: {
                id: "bookingMethod_Email",
                type: "radio",
                name: "BookingMethodGroup",
                label: "Email",
                radioValue: "BM_E",
              },
              model: {
                value: _vm.supplier.BookingMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "BookingMethod", $$v)
                },
                expression: "supplier.BookingMethod",
              },
            }),
            _c("awgt-input", {
              staticClass: "mx-3",
              attrs: {
                id: "bookingMethod_URL",
                type: "radio",
                name: "BookingMethodGroup",
                label: "URL",
                radioValue: "BM_U",
              },
              model: {
                value: _vm.supplier.BookingMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "BookingMethod", $$v)
                },
                expression: "supplier.BookingMethod",
              },
            }),
            _c("awgt-input", {
              staticClass: "mx-3",
              attrs: {
                id: "bookingMethod_No",
                type: "radio",
                name: "BookingMethodGroup",
                label: "Not Required",
                radioValue: "BM_N",
              },
              model: {
                value: _vm.supplier.BookingMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "BookingMethod", $$v)
                },
                expression: "supplier.BookingMethod",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap align-items-center" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2",
              attrs: { color: "primary", icon: "dollar-sign", size: "2x" },
            }),
            _c(
              "span",
              {
                staticClass: "ml-2 mr-3",
                staticStyle: { "min-width": "60px" },
              },
              [_vm._v("Payment Types")]
            ),
            _c("awgt-input", {
              staticClass: "mx-2",
              staticStyle: { "min-width": "50px" },
              attrs: {
                id: "paymentType_PrePaid",
                type: "checkbox",
                name: "paymentType",
                label: "Pre-Paid",
              },
              model: {
                value: _vm.paymentType_PrePaid,
                callback: function ($$v) {
                  _vm.paymentType_PrePaid = $$v
                },
                expression: "paymentType_PrePaid",
              },
            }),
            _c("awgt-input", {
              staticClass: "mx-2",
              staticStyle: { "min-width": "50px" },
              attrs: {
                id: "paymentType_PurchaseOrder",
                type: "checkbox",
                name: "paymentType",
                label: "Purchase Order",
              },
              model: {
                value: _vm.paymentType_PurchaseOrder,
                callback: function ($$v) {
                  _vm.paymentType_PurchaseOrder = $$v
                },
                expression: "paymentType_PurchaseOrder",
              },
            }),
            _c("awgt-input", {
              staticClass: "mx-2",
              staticStyle: { "min-width": "50px" },
              attrs: {
                id: "paymentType_Voucher",
                type: "checkbox",
                name: "paymentType",
                label: "Voucher",
              },
              model: {
                value: _vm.paymentType_Voucher,
                callback: function ($$v) {
                  _vm.paymentType_Voucher = $$v
                },
                expression: "paymentType_Voucher",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("awgt-std-dropdown", {
              ref: "depositTypesSelect",
              staticClass: "flex-grow-1 mx-2 mr-3",
              staticStyle: { "min-width": "90px", "max-width": "200px" },
              attrs: {
                items: _vm.getDepositTypes(false),
                itemValueProperty: "Code",
                itemTextProperty: "Name",
                label: "Deposit Type",
                wrapperClass: "md-bg",
                bg: "",
              },
              model: {
                value: _vm.supplier.DepositType,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "DepositType", $$v)
                },
                expression: "supplier.DepositType",
              },
            }),
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: {
                color: "primary",
                icon:
                  _vm.supplier.DepositType == "DT_No"
                    ? ""
                    : _vm.supplier.DepositType == "DT_Pe"
                    ? "percentage"
                    : "dollar-sign",
                size: "2x",
              },
            }),
            _c("awgt-input", {
              directives: [
                {
                  name: "numeric",
                  rawName: "v-numeric",
                  value: {
                    precision: 6,
                    scale: 2,
                    posOnly: true,
                    trailingZeroScale: 2,
                  },
                  expression:
                    "{\n          precision: 6,\n          scale: 2,\n          posOnly: true,\n          trailingZeroScale: 2,\n        }",
                },
              ],
              attrs: {
                maxlength: "7",
                label: "Amount",
                disabled: _vm.supplier.DepositType == "DT_No",
                type: "text",
                bg: "",
              },
              model: {
                value: _vm.supplier.DepositAmt,
                callback: function ($$v) {
                  _vm.$set(_vm.supplier, "DepositAmt", _vm._n($$v))
                },
                expression: "supplier.DepositAmt",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-2",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-2",
              attrs: {
                icon: "clipboard",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("div", { staticClass: "ml-2" }, [_vm._v("Itinerary Notes")]),
            _c("tdoxTooltip", {
              staticClass: "mb-1",
              attrs: { detail: _vm.getTooltipsTextByCode(47) },
            }),
          ],
          1
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          model: {
            value: _vm.supplier.ItineraryNotes,
            callback: function ($$v) {
              _vm.$set(_vm.supplier, "ItineraryNotes", $$v)
            },
            expression: "supplier.ItineraryNotes",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-2",
              attrs: {
                icon: "clipboard",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("div", { staticClass: "ml-2" }, [
              _vm._v("Pre-supplier Itinerary Comment"),
            ]),
            _c("tdoxTooltip", {
              staticClass: "mb-1",
              attrs: { detail: _vm.getTooltipsTextByCode(48) },
            }),
          ],
          1
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          model: {
            value: _vm.supplier.PreSupplierItineraryComment,
            callback: function ($$v) {
              _vm.$set(_vm.supplier, "PreSupplierItineraryComment", $$v)
            },
            expression: "supplier.PreSupplierItineraryComment",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-2",
              attrs: {
                icon: "clipboard",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("div", { staticClass: "ml-2" }, [
              _vm._v("Post-supplier Itinerary Comment"),
            ]),
            _c("tdoxTooltip", {
              staticClass: "mb-1",
              attrs: { detail: _vm.getTooltipsTextByCode(49) },
            }),
          ],
          1
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          model: {
            value: _vm.supplier.PostSupplierItineraryComment,
            callback: function ($$v) {
              _vm.$set(_vm.supplier, "PostSupplierItineraryComment", $$v)
            },
            expression: "supplier.PostSupplierItineraryComment",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-2",
              attrs: {
                icon: "clipboard",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("div", { staticClass: "ml-2" }, [_vm._v("Agency Notes")]),
            _c("tdoxTooltip", {
              staticClass: "mb-1",
              attrs: { detail: _vm.getTooltipsTextByCode(50) },
            }),
          ],
          1
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          model: {
            value: _vm.supplier.AWGTNotes,
            callback: function ($$v) {
              _vm.$set(_vm.supplier, "AWGTNotes", $$v)
            },
            expression: "supplier.AWGTNotes",
          },
        }),
        _c(
          "div",
          { staticClass: "mt-3 mx-2" },
          [
            _c(
              "itemised-notes",
              {
                attrs: {
                  noteTitleProperty: "Subject",
                  noteDetailProperty: "Note",
                  noteType: "NT_C",
                },
                model: {
                  value: _vm.supplier.SupplierNotes,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "SupplierNotes", $$v)
                  },
                  expression: "supplier.SupplierNotes",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mb-2 mr-3",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        icon: "clipboard",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("div", [_vm._v("Client Supplier Notes")]),
                    _c("tdoxTooltip", {
                      staticClass: "mb-1",
                      attrs: { detail: _vm.getTooltipsTextByCode(51) },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-3 mx-2" },
          [
            _c(
              "itemised-notes",
              {
                attrs: {
                  noteTitleProperty: "Subject",
                  noteDetailProperty: "Note",
                  noteType: "NT_A",
                },
                model: {
                  value: _vm.supplier.SupplierNotes,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "SupplierNotes", $$v)
                  },
                  expression: "supplier.SupplierNotes",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mb-2 mr-3",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        icon: "clipboard",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("div", [_vm._v("Agency Supplier Notes")]),
                    _c("tdoxTooltip", {
                      staticClass: "mb-1",
                      attrs: { detail: _vm.getTooltipsTextByCode(44) },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "files-upload",
          {
            ref: "fileUpload",
            attrs: { supplierFileCategoryType: "SFCT_C" },
            on: {
              "on-files-input": _vm.onFilesInput,
              "files-input-error": _vm.onFilesInputError,
              "header-message": _vm.onFileDownloadMessage,
            },
            model: {
              value: _vm.supplier.SupplierFileCategories,
              callback: function ($$v) {
                _vm.$set(_vm.supplier, "SupplierFileCategories", $$v)
              },
              expression: "supplier.SupplierFileCategories",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2",
                  attrs: {
                    icon: "clipboard",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("div", { staticClass: "ml-2 mt-2" }, [
                  _vm._v("Client Files"),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "files-upload",
          {
            ref: "fileUpload",
            attrs: { supplierFileCategoryType: "SFCT_A" },
            on: {
              "on-files-input": _vm.onFilesInput,
              "files-input-error": _vm.onFilesInputError,
              "header-message": _vm.onFileDownloadMessage,
            },
            model: {
              value: _vm.supplier.SupplierFileCategories,
              callback: function ($$v) {
                _vm.$set(_vm.supplier, "SupplierFileCategories", $$v)
              },
              expression: "supplier.SupplierFileCategories",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2",
                  attrs: {
                    icon: "clipboard",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("div", { staticClass: "ml-2 mt-2" }, [
                  _vm._v("Agency Files"),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "float-right mt-2" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                staticStyle: {
                  width: "120px",
                  "padding-left": "0",
                  "padding-right": "0",
                },
                attrs: { type: "button", disabled: !_vm.isFormDirty() },
                on: { click: _vm.onSubmit },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                staticStyle: {
                  width: "120px",
                  "padding-left": "0",
                  "padding-right": "0",
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onCancel()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Close "),
              ],
              1
            ),
          ],
          1
        ),
        _c("ok-prompt", { ref: "alertPrompt" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }