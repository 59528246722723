<!--
  This control is a wrapper around the mdbInput control.

  This control strips out all non-numeric characters from a phone number. It just returns
  a string of numbers to the back-end and displays a formatted phone number on the
  front-end. The phone number formats are as follows:
    - Mobile format is: 0417 043 977
    - 1800, 1300 format is: 1300 123 123, 1800 123 123
    - Landline format is: 02 6286 1237
    - International format is: 61 2 6286 1237
    - 13 number format is: 13 21 23

  International phone numbers can be upto 15 characters long. This control will trim all
  numerical numbers to 15 characters. The field on the screen can be say 20 characters to
  allow for spaces, but only 11 character international numbers will be formatted as per
  the table above. All other international numbers will just appear as a block of numbers.
  Details on the international phone number format is as follows:
  
  https://www.cm.com/blog/how-to-format-international-telephone-numbers/

  https://en.wikipedia.org/wiki/E.164
  
  -->

<template>
  <div style="position: relative">
    <!--
      Checked and CheckboxValue have been removed as they do not work in the
      MDB control, use v-model instead.
    -->
    <mdb-input
      ref="mdbInput"
      :value="formattedPhoneNum"
      :active="active"
      :tag="tag"
      :basic="basic"
      :bg="bg"
      :disabled="disabled"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      type="text"
      :autofocus="autofocus"
      :validation="validation"
      :customValidation="customValidation"
      :isValid="isValid"
      :validFeedback="validFeedback"
      :invalidFeedback="invalidFeedback"
      :outline="outline"
      :small="small"
      :navInput="navInput"
      :waves="waves"
      :wrapperClass="wrapperClass"
      :noWrapper="noWrapper"
      :inputClass="inputClass"
      :maxlength="maxlength"
      :readOnly="readOnly"
      :autocomplete="autocomplete"
      :counterMaxValue="0"
      :counter="false"
      :iconClass="iconClass"
      :far="far"
      :regular="regular"
      :fal="fal"
      :light="light"
      :fab="fab"
      :brands="brands"
      :label="label"
      :labelColor="labelColor"
      :labelClass="labelClass"
      :min="min"
      :max="max"
      :step="step"
      :gap="gap"
      :radioValue="String(radioValue)"
      :filled="filled"
      :rows="rows"
      :ariaLabel="ariaLabel"
      :ariaDescribedBy="ariaDescribedBy"
      :ariaLabelledBy="ariaLabelledBy"
      :ariaMultiselectable="ariaMultiselectable"
      :ariaDisabled="ariaDisabled"
      :ariaRequired="ariaRequired"
      :ariaHaspopup="ariaHaspopup"
      :role="role"
      @change="$emit('change', $event)"
      @get-default-value="$emit('get-default-value', $event)"
      @focus="$emit('focus', $event)"
      @blur="onBlur"
      @input="onInput"
    />
    <button
      v-if="tooltip.length > 0"
      type="button"
      :class="{
        tipsButtonInputFiled: isInputFiled,
        tipsButtonRadionButton: isRadioButton,
      }"
      :title="tooltip"
      v-tippy="{ position: 'bottom', arrow: true }"
      style="position: absolute"
      :style="{ top: type == 'checkbox' || type == 'radio' ? '0px' : 'auto' }"
    >
      <mdb-icon
        :icon="getTooltipIconName(tooltipType)"
        fas
        style="cursor: pointer"
        :style="{ color: tooltipType == 'warning' ? '#ffcc00' : 'grey' }"
        size="1x"
      />
    </button>
  </div>
</template>

<script>
import { mdbInput, mdbIcon } from "mdbvue";
import { formatPhoneNumber } from "@/shared/shared.js";

export default {
  components: {
    mdbInput,
    mdbIcon,
  },

  data() {
    return {
      rawPhoneNum: "",
      formattedPhoneNum: "",
    };
  },

  props: {
    tooltip: {
      type: String,
      default: "",
    },
    tooltipType: {
      type: String,
      default: "information",
    },
    value: {
      type: [String, Number, Boolean],
    },
    active: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "input",
    },
    basic: {
      type: Boolean,
      default: false,
    },
    bg: { type: Boolean, default: false },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    customValidation: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    validFeedback: {
      type: [String, Boolean],
      default: false,
    },
    invalidFeedback: {
      type: [String, Boolean],
      default: false,
    },
    outline: {
      type: Boolean,
    },
    small: {
      type: String,
    },
    size: {
      type: String,
    },
    navInput: {
      type: Boolean,
      default: false,
    },
    waves: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
    },
    noWrapper: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
    },
    maxlength: {
      type: [String, Number],
    },
    readOnly: {
      type: Boolean,
    },
    autocomplete: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    far: {
      type: String,
    },
    regular: {
      type: String,
    },
    fal: {
      type: String,
    },
    light: {
      type: String,
    },
    fab: {
      type: String,
    },
    brands: {
      type: String,
    },
    label: {
      type: String,
    },
    labelColor: {
      type: String,
    },
    labelClass: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
    },
    gap: {
      type: Boolean,
      default: false,
    },
    radioValue: {
      //Allows string and true/false values for radio buttons
      type: [String, Boolean],
    },
    filled: {
      type: Boolean,
    },
    rows: {
      type: Number,
    },
    ariaLabel: {
      type: String,
    },
    ariaDescribedBy: {
      type: String,
    },
    ariaLabelledBy: {
      type: String,
    },
    ariaMultiselectable: {
      type: Boolean,
    },
    ariaDisabled: {
      type: Boolean,
    },
    ariaRequired: {
      type: Boolean,
    },
    ariaHaspopup: {
      type: Boolean,
    },
    role: {
      type: String,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (to) {
          this.rawPhoneNum = to;
          //The value of the formatted phone number needs to change, so set it to empty string first.
          this.formattedPhoneNum = "";
          this.$nextTick(() => {
            this.formattedPhoneNum = this.formatRawPhoneNum(this.rawPhoneNum);
          });
        }
      },
    },
  },

  computed: {
    getTooltipIconName() {
      return (tooltipType) => {
        switch (tooltipType) {
          case "information":
            return "info-circle";
          case "question":
            return "question-circle";
          case "warning":
            return "exclamation-triangle";
          default:
            return "info-circle";
        }
      };
    },
  },

  methods: {
    onBlur(e) {
      let formattedPhoneNum = e.target.value;
      let rawPhoneNum = formattedPhoneNum.replace(/\D/g, "").substring(0, 15);
      //console.log(rawPhoneNum);
      this.$emit("blur", e);
      this.$emit("input", rawPhoneNum);
    },

    onInput() {
      //Fire the input event but don't send through the new event target
      this.$emit("input");
    },

    formatRawPhoneNum(rawPhoneNum) {
      //Assume a raw phone number
      return formatPhoneNumber(rawPhoneNum);
    },
  },
};
</script>
<style scoped>
.tipsButtonInputFiled {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: absolute;
  bottom: 22px;
  right: 10px;
}

.tipsButtonRadionButton {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: absolute;
  bottom: -1px;
  right: -30px;
}
</style>
