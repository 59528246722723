var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asoft-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            color: _vm.selectorColor,
            icon: _vm.icon,
            tooltipIconColor: _vm.tooltipIconColor,
            value: _vm.value,
          },
        },
        "asoft-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }