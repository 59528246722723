var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: "picker__date-display " + _vm.color }, [
    _c("div", { staticClass: "clockpicker-display" }, [
      _c("div", { staticClass: "clockpicker-display-column" }, [
        _c(
          "span",
          {
            class: `clockpicker-span-hours ${
              _vm.unitsMode === "h" ? "text-primary" : ""
            }`,
            on: {
              click: function ($event) {
                return _vm.changeMode("h")
              },
            },
          },
          [_vm._v(_vm._s(_vm.hours))]
        ),
        _vm._v(":"),
        _c(
          "span",
          {
            class: `clockpicker-span-minutes ${
              _vm.unitsMode === "m" ? "text-primary" : ""
            }`,
            on: {
              click: function ($event) {
                return _vm.changeMode("m")
              },
            },
          },
          [_vm._v(_vm._s(_vm.minutes))]
        ),
      ]),
      _vm.hoursFormat === 12
        ? _c(
            "div",
            {
              staticClass:
                "clockpicker-display-column clockpicker-display-am-pm",
            },
            [
              _c("div", { staticClass: "clockpicker-span-am-pm" }, [
                _vm._v(_vm._s(_vm.computedDayTime)),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }