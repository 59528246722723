<template>
  <div>
    <h5 v-if="value.ClientType == 'CT_I'">
      <mdb-icon
        class="icon-in-views"
        fas
        icon="user"
        size="1x"
        color="primary"
      />{{ value.GivenNames }} {{ value.Surname }}
      <span style="font-size: 75%">
        <i>({{ value.Reference }})</i></span
      >
    </h5>
    <h5 v-else>
      <mdb-icon
        class="icon-in-views"
        fas
        :icon="value.ClientType == 'CT_O' ? 'building' : 'school'"
        size="1x"
        color="primary"
      />{{ value.Name }}
      <span style="font-size: 75%">
        <i>({{ value.Reference }})</i></span
      >
    </h5>
    <div>
      <mdb-icon
        class="icon-in-views"
        icon="hashtag"
        color="primary"
        size="1x"
      />

      ABN: {{ value.ABN }}
    </div>
    <div
      v-if="
        physicalAddress.Street.length +
          physicalAddress.Locality.length +
          (physicalAddress.Subdivision != null
            ? physicalAddress.Subdivision.length
            : 0) +
          physicalAddress.Postcode.length >
        0
      "
    >
      <mdb-icon
        class="icon-in-views"
        icon="map-marker-alt"
        color="primary"
        size="1x"
      />
      <template v-if="physicalAddress.Street.length > 0">
        <span>{{ physicalAddress.Street }},</span>
      </template>
      <template v-if="physicalAddress.Locality.length > 0">
        {{ physicalAddress.Locality }}
      </template>
      <template
        v-if="
          physicalAddress.Subdivision != null &&
          physicalAddress.Subdivision.length > 0
        "
      >
        {{ physicalAddress.Subdivision }}
      </template>
      <template v-if="physicalAddress.Postcode.length > 0">
        {{ physicalAddress.Postcode }}
      </template>
    </div>
    <div
      v-if="
        postalAddress.Street.length +
          postalAddress.Locality.length +
          (postalAddress.Subdivision != null
            ? postalAddress.Subdivision.length
            : 0) +
          postalAddress.Postcode.length >
        0
      "
    >
      <mdb-icon
        class="icon-in-views"
        icon="envelope"
        color="primary"
        size="1x"
      />
      <template v-if="postalAddress.Street.length > 0">
        <span>{{ postalAddress.Street }},</span>
      </template>
      <template v-if="postalAddress.Locality.length > 0">
        {{ postalAddress.Locality }}
      </template>
      <template
        v-if="
          postalAddress.Subdivision != null &&
          postalAddress.Subdivision.length > 0
        "
      >
        {{ postalAddress.Subdivision }}
      </template>
      <template v-if="postalAddress.Postcode.length > 0">
        {{ postalAddress.Postcode }}
      </template>
    </div>
    <div v-if="value.Phone.length > 0">
      <span class="mr-3">
        <mdb-icon
          class="icon-in-views"
          icon="phone"
          color="primary"
          size="1x"
        />
        <awgt-link-text :href="'tel:' + value.Phone">{{
          formatPhoneNumber(value.Phone)
        }}</awgt-link-text>
      </span>
      <span v-if="value.EmailAddress.length > 0">
        <mdb-icon class="icon-in-views" icon="at" color="primary" size="1x" />
        <awgt-link-text :href="'mailto:' + value.EmailAddress">{{
          value.EmailAddress
        }}</awgt-link-text>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdbIcon } from "mdbvue";
import AwgtLinkText from "@/components/AWGT/AwgtLinkText";
import { formatPhoneNumber } from "@/shared/shared.js";

export default {
  components: {
    mdbIcon,
    AwgtLinkText,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: { formatPhoneNumber },
  computed: {
    ...mapGetters(["getDepositTypeNameByCode"]),
    postalAddress: function () {
      return this.value.Addresses.find((a) => {
        return a.Type == "AT_Po";
      });
    },
    physicalAddress: function () {
      return this.value.Addresses.find((a) => {
        return a.Type == "AT_Ph";
      });
    },
  },
};
</script>

<style></style>
