import api from "./api.js";
import { sharedMethods } from "@/shared/shared";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getSuppliers(
    reference = null,
    searchName = null,
    regionId = null,
    subdivision = null,
    includeTransportSuppliersInd = true,
    includeAccommodationSuppliersInd = true,
    includeActivitySuppliersInd = true,
    includeMerchandiseSuppliersInd = true,
    includeNoProductSuppliersInd = true,
    includeDraftSuppliersInd = true,
    includePublishedSuppliersInd = true,
    matchAllSearchingCriteriaInd = true,
    includeDeletedSuppliersInd = false
  ) {
    try {
      logger.get(loggingSource.UISupplier).info("getSuppliers...");

      logger
        .get(loggingSource.UISupplier)
        .debug(
          "Parameters => reference: %s, searchName: %s, regionId: %s, subdivision: %s, includeTransportSuppliersInd: %s, includeAccommodationSuppliersInd: %s, includeActivitySuppliersInd: %s, includeMerchandiseSuppliersInd: %s, includeNoProductSuppliersInd: %s, includeDraftSuppliersInd: %s, " +
            "includePublishedSuppliersInd: %s, matchAllSearchingCriteriaInd: %s, includeDeletedSuppliersInd: %s, ",
          reference,
          searchName,
          regionId,
          subdivision,
          includeTransportSuppliersInd,
          includeAccommodationSuppliersInd,
          includeActivitySuppliersInd,
          includeMerchandiseSuppliersInd,
          includeNoProductSuppliersInd,
          includeDraftSuppliersInd,
          includePublishedSuppliersInd,
          matchAllSearchingCriteriaInd,
          includeDeletedSuppliersInd
        );

      logger.get(loggingSource.UISupplier).time("getSuppliers call");
      const response = await api.client
        .get("supplier", {
          params: {
            reference: reference,
            searchName: searchName,
            regionId: regionId,
            subdivision: subdivision,
            includeTransportSuppliersInd: includeTransportSuppliersInd,
            includeAccommodationSuppliersInd: includeAccommodationSuppliersInd,
            includeActivitySuppliersInd: includeActivitySuppliersInd,
            includeMerchandiseSuppliersInd: includeMerchandiseSuppliersInd,
            includeNoProductSuppliersInd: includeNoProductSuppliersInd,
            includeDraftSuppliersInd: includeDraftSuppliersInd,
            includePublishedSuppliersInd: includePublishedSuppliersInd,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
            includeDeletedItemsInd: includeDeletedSuppliersInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("getSuppliers call");
      const suppliers = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("getSuppliers (before sanitisation) response: %s", response);

      //Html Sanitise itinerary notes and AWGTNotes.
      for (let supplier of suppliers) {
        supplier.ItineraryNotes = sharedMethods.htmlSanitize(
          supplier.ItineraryNotes
        );
        supplier.AWGTNotes = sharedMethods.htmlSanitize(supplier.AWGTNotes);
        supplier.PrePreSupplierItineraryComment = sharedMethods.htmlSanitize(
          supplier.PrePreSupplierItineraryComment
        );
        supplier.PostSupplierItineraryComment = sharedMethods.htmlSanitize(
          supplier.PostSupplierItineraryComment
        );
      }

      logger
        .get(loggingSource.UISupplier)
        .trace("getSuppliers (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("getSuppliers (after sanitisation) suppliers: %s", suppliers);

      return suppliers;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("getSuppliers call");
      logger.get(loggingSource.UISupplier).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...getSuppliers");
    }
  },

  //Get suppliers list with only Code and Name
  async getSupplierDropdown(
    includeDraftSuppliersInd = false,
    includePublishedSuppliersInd = true,
    includeDeletedSuppliersInd = false
  ) {
    try {
      logger.get(loggingSource.UISupplier).info("getSupplierDropdown...");

      logger
        .get(loggingSource.UISupplier)
        .debug(
          "Parameters => includeDraftSuppliersInd: %s, includePublishedSuppliersInd: %s, includeDeletedSuppliersInd: %s",
          includeDraftSuppliersInd,
          includePublishedSuppliersInd,
          includeDeletedSuppliersInd
        );

      logger.get(loggingSource.UISupplier).time("getSupplierDropdown call");
      const response = await api.client
        .get("supplier/Dropdown", {
          params: {
            includeDraftSuppliersInd: includeDraftSuppliersInd,
            includePublishedSuppliersInd: includePublishedSuppliersInd,
            includeDeletedItemsInd: includeDeletedSuppliersInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("getSupplierDropdown call");
      const suppliers = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("getSupplierDropdown response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("getSupplierDropdown suppliers: %s", suppliers);

      return suppliers;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("getSupplierDropdown call");
      logger.get(loggingSource.UISupplier).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...getSupplierDropdown");
    }
  },

  //Get brief supplier list for supplier search screen.
  async getSupplierList(
    reference = null,
    searchName = null,
    regionId = null,
    subdivision = null,
    includeTransportSuppliersInd = true,
    includeAccommodationSuppliersInd = true,
    includeActivitySuppliersInd = true,
    includeMerchandiseSuppliersInd = true,
    includeNoProductSuppliersInd = true,
    includeDraftSuppliersInd = false,
    includePublishedSuppliersInd = true,
    matchAllSearchingCriteriaInd = true,
    includeDeletedSuppliersInd = false
  ) {
    try {
      logger.get(loggingSource.UISupplier).info("getSupplierList...");

      logger
        .get(loggingSource.UISupplier)
        .debug(
          "Parameters => reference: %s, searchName: %s, regionId: %s, subdivision: %s, includeTransportSuppliersInd: %s, includeAccommodationSuppliersInd: %s, includeActivitySuppliersInd: %s, includeMerchandiseSuppliersInd: %s, includeNoProductSuppliersInd: %s, includeDraftSuppliersInd: %s, " +
            "includePublishedSuppliersInd: %s, matchAllSearchingCriteriaInd: %s, includeDeletedSuppliersInd: %s, ",
          reference,
          searchName,
          regionId,
          subdivision,
          includeTransportSuppliersInd,
          includeAccommodationSuppliersInd,
          includeActivitySuppliersInd,
          includeMerchandiseSuppliersInd,
          includeNoProductSuppliersInd,
          includeDraftSuppliersInd,
          includePublishedSuppliersInd,
          matchAllSearchingCriteriaInd,
          includeDeletedSuppliersInd
        );

      logger.get(loggingSource.UISupplier).time("getSupplierList call");
      const response = await api.client
        .get("supplier/SupplierList", {
          params: {
            reference: reference,
            searchName: searchName,
            regionId: regionId,
            subdivision: subdivision,
            includeTransportSuppliersInd: includeTransportSuppliersInd,
            includeAccommodationSuppliersInd: includeAccommodationSuppliersInd,
            includeActivitySuppliersInd: includeActivitySuppliersInd,
            includeMerchandiseSuppliersInd: includeMerchandiseSuppliersInd,
            includeNoProductSuppliersInd: includeNoProductSuppliersInd,
            includeDraftSuppliersInd: includeDraftSuppliersInd,
            includePublishedSuppliersInd: includePublishedSuppliersInd,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
            includeDeletedItemsInd: includeDeletedSuppliersInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("getSupplierList call");
      const suppliers = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("getSupplierList response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("getSupplierList suppliers: %s", suppliers);

      return suppliers;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("getSupplierList call");
      logger.get(loggingSource.UISupplier).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...getSupplierList");
    }
  },

  async getSupplierByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UISupplier).info("getSupplierByReference...");

      logger
        .get(loggingSource.UISupplier)
        .debug("Parameters => reference: %s", reference);

      logger.get(loggingSource.UISupplier).time("getSupplierByReference call");
      const response = await api.client
        .get(`Supplier/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UISupplier)
        .timeEnd("getSupplierByReference call");
      const supplier = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace(
          "getSupplierByReference (before sanitisation) response: %s",
          response
        );

      //Html Sanitise itinerary notes and AWGTNotes.
      supplier.ItineraryNotes = sharedMethods.htmlSanitize(
        supplier.ItineraryNotes
      );
      supplier.AWGTNotes = sharedMethods.htmlSanitize(supplier.AWGTNotes);
      supplier.PrePreSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PrePreSupplierItineraryComment
      );
      supplier.PostSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PostSupplierItineraryComment
      );

      logger
        .get(loggingSource.UISupplier)
        .trace(
          "getSupplierByReference (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UISupplier)
        .debug(
          "getSupplierByReference (after sanitisation) supplier: %s",
          supplier
        );
      return supplier;
    } catch (error) {
      logger
        .get(loggingSource.UISupplier)
        .timeEnd("getSupplierByReference call");
      logger.get(loggingSource.UISupplier).error(error);

      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...getSupplierByReference");
    }
  },

  async postSupplier(supplierPayload, withFiles = false) {
    const config = withFiles
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        };
    try {
      logger.get(loggingSource.UISupplier).info("postSupplier...");

      logger
        .get(loggingSource.UISupplier)
        .debug(
          "Parameters => supplierPayload: %s, withFiles: %s",
          supplierPayload,
          withFiles
        );

      logger.get(loggingSource.UISupplier).time("postSupplier call");
      const response = await api.client
        .post(
          "Supplier" + (withFiles ? "/withFiles" : ""),
          supplierPayload,
          config
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("postSupplier call");
      const supplier = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("postSupplier (before sanitisation) response: %s", response);

      //Html Sanitise itinerary notes and AWGTNotes.
      supplier.ItineraryNotes = sharedMethods.htmlSanitize(
        supplier.ItineraryNotes
      );
      supplier.AWGTNotes = sharedMethods.htmlSanitize(supplier.AWGTNotes);
      supplier.PrePreSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PrePreSupplierItineraryComment
      );
      supplier.PostSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PostSupplierItineraryComment
      );

      logger
        .get(loggingSource.UISupplier)
        .trace("postSupplier (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("postSupplier (after sanitisation) supplier: %s", supplier);

      return supplier;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("postSupplier call");
      logger.get(loggingSource.UISupplier).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...postSupplier");
    }
  },

  async putSupplier(supplierPayload, withFiles = false) {
    const config = withFiles
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        };
    try {
      logger.get(loggingSource.UISupplier).info("putSupplier...");

      logger
        .get(loggingSource.UISupplier)
        .debug(
          "Parameters => supplierPayload: %s, withFiles: %s",
          supplierPayload,
          withFiles
        );

      logger.get(loggingSource.UISupplier).time("putSupplier call");
      const response = await api.client
        .put(
          "Supplier" + (withFiles ? "/withFiles" : ""),
          supplierPayload,
          config
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("putSupplier call");
      const supplier = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("putSupplier (before sanitisation) response: %s", response);

      //Html Sanitise itinerary notes and AWGTNotes.
      supplier.ItineraryNotes = sharedMethods.htmlSanitize(
        supplier.ItineraryNotes
      );
      supplier.AWGTNotes = sharedMethods.htmlSanitize(supplier.AWGTNotes);
      supplier.PrePreSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PrePreSupplierItineraryComment
      );
      supplier.PostSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PostSupplierItineraryComment
      );

      logger
        .get(loggingSource.UISupplier)
        .trace("putSupplier (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("putSupplier (after sanitisation) supplier: %s", supplier);

      return supplier;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("putSupplier call");
      logger.get(loggingSource.UISupplier).error(error);

      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...putSupplier");
    }
  },

  async deleteSupplier(supplierPayload) {
    try {
      logger.get(loggingSource.UISupplier).info("deleteSupplier...");

      logger
        .get(loggingSource.UISupplier)
        .debug("Parameters => supplierPayload: %s", supplierPayload);

      logger.get(loggingSource.UISupplier).time("deleteSupplier call");
      await api.client
        .delete(
          `Supplier/${
            supplierPayload.Reference
          }?concurrencyRV=${encodeURIComponent(supplierPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("deleteSupplier call");
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("deleteSupplier call");
      logger.get(loggingSource.UISupplier).error(error);

      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...deleteSupplier");
    }
  },

  async publishSupplier(supplierPayload) {
    try {
      logger.get(loggingSource.UISupplier).info("publishSupplier...");

      logger
        .get(loggingSource.UISupplier)
        .debug("Parameters => supplierPayload: %s", supplierPayload);

      logger.get(loggingSource.UISupplier).time("publishSupplier call");
      const response = await api.client
        .put(
          `Supplier/publishSupplier/${
            supplierPayload.Reference
          }?concurrencyRV=${encodeURIComponent(supplierPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISupplier).timeEnd("publishSupplier call");
      const supplier = response.data;

      logger
        .get(loggingSource.UISupplier)
        .trace("publishSupplier (before sanitisation) response: %s", response);

      //Html Sanitise itinerary notes and AWGTNotes.
      supplier.ItineraryNotes = sharedMethods.htmlSanitize(
        supplier.ItineraryNotes
      );
      supplier.AWGTNotes = sharedMethods.htmlSanitize(supplier.AWGTNotes);
      supplier.PrePreSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PrePreSupplierItineraryComment
      );
      supplier.PostSupplierItineraryComment = sharedMethods.htmlSanitize(
        supplier.PostSupplierItineraryComment
      );

      logger
        .get(loggingSource.UISupplier)
        .trace("publishSupplier (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UISupplier)
        .debug("publishSupplier (after sanitisation) supplier: %s", supplier);

      return supplier;
    } catch (error) {
      logger.get(loggingSource.UISupplier).timeEnd("publishSupplier call");
      logger.get(loggingSource.UISupplier).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISupplier).info("...publishSupplier");
    }
  },
};
