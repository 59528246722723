var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    ref: "content",
    class: _vm.textareaClass,
    attrs: { contenteditable: "true" },
    on: {
      input: function ($event) {
        return _vm.contentModified(_vm.$refs.content.innerHTML)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }