<template>
  <div v-if="Object.keys(value).length > 0">
    <template>
      <div>
        <span style="font-weight: bold">{{ value.Name }}</span>
      </div>
      <div>
        <mdb-icon
          far
          class="icon-in-views"
          icon="map-marker-alt"
          color="primary"
          size="1x"
        />
        <span>{{ getPhysicalAddress(value.Addresses) }}</span>
      </div>
      <div v-for="(note, ind) in value.SupplierNotes" v-bind:key="ind">
        <mdb-icon
          far
          class="icon-in-views"
          icon="clipboard"
          color="primary"
          size="1x"
        />
        <span>
          {{ note.Subject }}:
          {{ note.Note }}
        </span>
      </div>
      <div v-if="value.SupplierFiles">
        <mdb-icon
          far
          class="icon-in-views"
          icon="file-alt"
          color="primary"
          size="1x"
        />
        <span>
          File categories:
          {{ getSupplierFilesCategories(value.SupplierFiles) }}
        </span>
      </div>
    </template>
    <template v-for="(product, indOfInfoList) in value.Products">
      <div v-bind:key="indOfInfoList" class="mt-4 html2pdf__page-break">
        <template>
          <div class="mt-2">
            <span style="font-weight: bold; color: #923f82">{{
              (product.DisplayName == ""
                ? product.Name + " - " + product.Name
                : product.DisplayName) +
              (product.SpecialComment == ""
                ? ""
                : " - " + product.SpecialComment)
            }}</span>
          </div>
          <div v-html="product.Description" class="my-2 html-in-views"></div>
          <div class="my-2">
            Information:
            <a :href="product.InformationURL">{{ product.InformationURL }}</a>
          </div>
          <div
            class="d-flex flex-row flex-wrap box-area"
            style="justify-content: center"
          >
            <div
              class="box-margin"
              v-if="product.YearGroups != null && product.YearGroups.length > 0"
            >
              <asoft-box-display
                :title="'Year Groups'"
                :content="product.YearGroups"
                :icon="'users-class'"
                :shadow="false"
              >
              </asoft-box-display>
            </div>
            <template v-if="product.ProductType == 'PT_Ay'">
              <div class="box-margin">
                <asoft-box-display
                  :title="'Minimum participants per activity'"
                  :content="product.MinParticipantsPerGroup"
                  :icon="'hashtag'"
                  :shadow="false"
                >
                </asoft-box-display>
              </div>
              <div class="box-margin">
                <asoft-box-display
                  :title="'Maximum participants per activity'"
                  :content="product.MaxParticipantsPerGroup"
                  :icon="'hashtag'"
                  :shadow="false"
                >
                </asoft-box-display>
              </div>
              <div class="box-margin">
                <asoft-box-display
                  :title="'Participants per timeslot'"
                  :content="product.MaxParticipantsPerTimeslot"
                  :icon="'hashtag'"
                  :shadow="false"
                >
                </asoft-box-display>
              </div>
              <div class="box-margin">
                <asoft-box-display
                  :title="'Duration In Minutes'"
                  :content="product.DurationInMinutes"
                  :icon="'hashtag'"
                  :shadow="false"
                >
                </asoft-box-display>
              </div>
            </template>
            <div
              class="box-margin"
              v-if="
                product.ProductType == 'PT_An' && product.MinBedsNum != null
              "
            >
              <asoft-box-display
                :title="'Minimum Number of Beds'"
                :content="product.MinBedsNum"
                :icon="'bed-bunk'"
                :shadow="false"
              >
              </asoft-box-display>
            </div>
            <div
              class="box-margin"
              v-if="
                product.ProductType == 'PT_An' && product.MaxBedsNum != null
              "
            >
              <asoft-box-display
                :title="'Maximum Number of Beds'"
                :content="product.MaxBedsNum"
                :icon="'bed-bunk'"
                :shadow="false"
              >
              </asoft-box-display>
            </div>
            <div class="box-margin" v-if="product.ProductType == 'PT_Tr'">
              <asoft-box-display
                :title="'Minimum Number of Seats'"
                :content="`Minimum Seats: ${product.MinSeatsNum}`"
                :icon="'car-bus'"
                :shadow="false"
              >
              </asoft-box-display>
              <asoft-box-display
                :title="'Maximum Number of Seats'"
                :content="`Maximum Seats: ${product.MaxSeatsNum}`"
                :icon="'car-bus'"
                :shadow="false"
              >
              </asoft-box-display>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { mdbIcon } from "mdbvue";
import asoftBoxDisplay from "@/components/AtomSoftware/asoftBoxDisplay";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    mdbIcon,
    asoftBoxDisplay,
  },
  computed: {
    getPhysicalAddress() {
      return function (address) {
        let physicalAddress = address.find((a) => {
          return a.Type == "AT_Ph";
        });

        return (
          (physicalAddress.Street.length > 0
            ? physicalAddress.Street + ", "
            : "") +
          (physicalAddress.Locality.length > 0
            ? physicalAddress.Locality + ", "
            : "") +
          (physicalAddress.Subdivision != null &&
          physicalAddress.Subdivision.length > 0
            ? physicalAddress.Subdivision + ", "
            : "") +
          (physicalAddress.Postcode.length > 0 ? physicalAddress.Postcode : "")
        );
      };
    },
    getSupplierFilesCategories() {
      return function (files) {
        if (!files || files.length == 0) {
          return "";
        } else {
          return files
            .map((f) => {
              return f.CategoryName;
            })
            .join(", ");
        }
      };
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: underline;
}

.box-margin {
  margin: 5px 10px 10px 10px;
}

.section-break {
  display: block;
  height: 2px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: linear-gradient(40deg, #d3f180, #9dbe44) !important;
}

.box-area {
  page-break-inside: avoid !important;
}
</style>
