var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asoft-input",
    _vm._g(_vm._b({}, "asoft-input", _vm.$attrs, false), _vm.$listeners)
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }