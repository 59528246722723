<template>
  <asoft-duration-picker
    v-bind="$attrs"
    v-on="$listeners"
    :backgroundColor="backgroundColor"
    :labelColor="labelColor"
    :activeLabelColor="activeLabelColor"
    :activeColor="activeColor"
  />
</template>

<script>
import asoftDurationPicker from "@/components/AtomSoftware/asoftDurationPicker";
import variables from "@/styles/sharedVariables.scss";

export default {
  inheritAttrs: true,
  components: { asoftDurationPicker },
  props: {
    backgroundColor: {
      type: String,
      default: variables.awgtDurationPickerBackgroundColor,
    },
    labelColor: {
      type: String,
      default: variables.awgtDurationPickerLabelColor,
    },
    activeLabelColor: {
      type: String,
      default: variables.awgtDurationPickerActiveLabelColor,
    },
    activeColor: {
      type: String,
      default: variables.awgtDurationPickerActiveColor,
    },
  },
};
</script>
