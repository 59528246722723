import Vue from "vue";

import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/mdbvue.css"; // light css styles associated only with the Vue library
import "mdbvue/lib/css/mdb.min.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import App from "@/app.vue";
import router from "@/router";
import store from "@/store/index";
import moment from "moment";
import lodash from "lodash";
//TODO: Check whether vue-the-mask is still required
import VueTheMask from "vue-the-mask";
import VueMask from "v-mask";
import { constants } from "@/shared/constants.js";
import api from "@/api/api.js";

//Turn off Vue debugging tools in non-development environments.
if (process.env.NODE_ENV != "development") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(VueMask);

Vue.use(VueTheMask);

Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, "$message", {
  value: {
    Message: [],
    isSuccessInd: true,
  },
});
Object.defineProperty(Vue.prototype, "$constants", { value: constants });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$lodash", { value: lodash });
Object.defineProperty(Vue.prototype, "$log", { value: logger });
Object.defineProperty(Vue.prototype, "$bus", { value: new Vue() });
Object.defineProperty(Vue.prototype, "$loggingSource", {
  value: loggingSource,
});

//The following is required for the wysiwyg editor
import Tippy from "v-tippy";
import "v-tippy/dist/tippy.css";
Vue.use(Tippy, {
  position: "bottom",
  arrow: true,
});

//The following is required for the logger
/* const isProduction = process.env.NODE_ENV === "production";

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "debug" : "debug",
  stringifyArguments: false, 
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

Vue.use(VueLogger, loggerOptions);
*/

//Now load Vue
let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
});
api.init(vue);
vue.$mount("#app");
