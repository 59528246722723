<template>
  <mdb-container fluid>
    <h1 class="text-center mb-3">Supplier Edit</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div>
        <h4 v-if="supplier.Reference != ''" class="mx-2">
          Reference: {{ supplier.Reference }}
        </h4>
      </div>
      <div class="ml-auto">
        <awgt-std-dropdown
          v-model="supplier.EditStatus"
          :items="getEditStatuses(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Edit Status"
          bg
          class="mx-2"
          style="max-width: 110px"
        />
      </div>
    </div>
    <div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <awgt-input
          v-model="supplier.Name"
          counter
          :counterMaxValue="200"
          maxlength="200"
          label="Supplier Name"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 300px"
        ></awgt-input>
        <awgt-input
          v-model="supplier.ContactPersonNm"
          counter
          :counterMaxValue="85"
          maxlength="85"
          label="Contact Name"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 300px"
        ></awgt-input>
      </div>
      <asoftAddressesCapture
        :template="getSystemTemplateFromCode('STT_C_Ad')"
        v-model="supplier.Addresses"
      ></asoftAddressesCapture>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <mdb-icon icon="phone" color="primary" class="mx-2 mt-4" size="2x" />
        <asoft-phone-input
          id="client-phone"
          v-model="supplier.Phone"
          maxlength="20"
          label="Phone"
          style="width: 180px"
          bg
          class="mx-2"
        />
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon icon="at" color="primary" class="mx-2 mt-4" size="2x" />
          <awgt-input
            v-model="supplier.EmailAddress"
            counter
            :counterMaxValue="254"
            maxlength="254"
            label="Email"
            style="min-width: 300px"
            bg
            class="flex-grow-1 mx-2"
          />
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon icon="link" color="primary" class="mx-2 mt-4" size="2x" />
          <awgt-input
            v-model="supplier.BookingURL"
            counter
            :counterMaxValue="254"
            maxlength="254"
            label="Booking URL"
            style="min-width: 300px"
            bg
            class="flex-grow-1 mx-2"
          />
        </div>
      </div>
      <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
        <span class="mr-5" style="min-width: 40px">Booking Method</span>
        <awgt-input
          id="bookingMethod_Phone"
          v-model="supplier.BookingMethod"
          type="radio"
          name="BookingMethodGroup"
          label="Phone"
          radioValue="BM_P"
          class="mx-3"
        />
        <awgt-input
          id="bookingMethod_Email"
          v-model="supplier.BookingMethod"
          type="radio"
          name="BookingMethodGroup"
          label="Email"
          radioValue="BM_E"
          class="mx-3"
        />
        <awgt-input
          id="bookingMethod_URL"
          v-model="supplier.BookingMethod"
          type="radio"
          name="BookingMethodGroup"
          label="URL"
          radioValue="BM_U"
          class="mx-3"
        />
        <awgt-input
          id="bookingMethod_No"
          v-model="supplier.BookingMethod"
          type="radio"
          name="BookingMethodGroup"
          label="Not Required"
          radioValue="BM_N"
          class="mx-3"
        />
      </mdb-form-inline>
      <div class="d-flex flex-row flex-wrap align-items-center">
        <mdb-icon color="primary" icon="dollar-sign" class="mx-2" size="2x" />
        <span class="ml-2 mr-3" style="min-width: 60px">Payment Types</span>
        <awgt-input
          id="paymentType_PrePaid"
          type="checkbox"
          v-model="paymentType_PrePaid"
          name="paymentType"
          label="Pre-Paid"
          class="mx-2"
          style="min-width: 50px"
        ></awgt-input>
        <awgt-input
          id="paymentType_PurchaseOrder"
          type="checkbox"
          v-model="paymentType_PurchaseOrder"
          name="paymentType"
          label="Purchase Order"
          class="mx-2"
          style="min-width: 50px"
        ></awgt-input>
        <awgt-input
          id="paymentType_Voucher"
          type="checkbox"
          v-model="paymentType_Voucher"
          name="paymentType"
          label="Voucher"
          class="mx-2"
          style="min-width: 50px"
        ></awgt-input>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <awgt-std-dropdown
          ref="depositTypesSelect"
          :items="getDepositTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Deposit Type"
          wrapperClass="md-bg"
          style="min-width: 90px; max-width: 200px"
          class="flex-grow-1 mx-2 mr-3"
          v-model="supplier.DepositType"
          bg
        ></awgt-std-dropdown>
        <mdb-icon
          color="primary"
          :icon="
            supplier.DepositType == 'DT_No'
              ? ''
              : supplier.DepositType == 'DT_Pe'
              ? 'percentage'
              : 'dollar-sign'
          "
          class="mx-2 mt-4"
          size="2x"
        />
        <awgt-input
          v-model.number="supplier.DepositAmt"
          maxlength="7"
          label="Amount"
          :disabled="supplier.DepositType == 'DT_No'"
          type="text"
          bg
          v-numeric="{
            precision: 6,
            scale: 2,
            posOnly: true,
            trailingZeroScale: 2,
          }"
        ></awgt-input>
      </div>

      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mx-2 mb-2"
          size="2x"
        />
        <div class="ml-2">Itinerary Notes</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(47)" />
      </div>
      <mdb-wysiwyg
        v-model="supplier.ItineraryNotes"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mx-2 mb-2"
          size="2x"
        />
        <div class="ml-2">Pre-supplier Itinerary Comment</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(48)" />
      </div>
      <mdb-wysiwyg
        v-model="supplier.PreSupplierItineraryComment"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mx-2 mb-2"
          size="2x"
        />
        <div class="ml-2">Post-supplier Itinerary Comment</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(49)" />
      </div>
      <mdb-wysiwyg
        v-model="supplier.PostSupplierItineraryComment"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mx-2 mb-2"
          size="2x"
        />
        <div class="ml-2">Agency Notes</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(50)" />
      </div>
      <mdb-wysiwyg
        v-model="supplier.AWGTNotes"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>

      <div class="mt-3 mx-2">
        <itemised-notes
          v-model="supplier.SupplierNotes"
          noteTitleProperty="Subject"
          noteDetailProperty="Note"
          noteType="NT_C"
        >
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
          >
            <mdb-icon
              icon="clipboard"
              far
              color="primary"
              class="mb-2 mr-3"
              size="2x"
              style="text-align: center"
            />
            <div>Client Supplier Notes</div>
            <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(51)" />
          </div>
        </itemised-notes>
      </div>

      <div class="mt-3 mx-2">
        <itemised-notes
          v-model="supplier.SupplierNotes"
          noteTitleProperty="Subject"
          noteDetailProperty="Note"
          noteType="NT_A"
        >
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
          >
            <mdb-icon
              icon="clipboard"
              far
              color="primary"
              class="mb-2 mr-3"
              size="2x"
              style="text-align: center"
            />
            <div>Agency Supplier Notes</div>
            <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(44)" />
          </div>
        </itemised-notes>
      </div>

      <files-upload
        v-model="supplier.SupplierFileCategories"
        @on-files-input="onFilesInput"
        @files-input-error="onFilesInputError"
        @header-message="onFileDownloadMessage"
        ref="fileUpload"
        supplierFileCategoryType="SFCT_C"
      >
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
        >
          <mdb-icon
            icon="clipboard"
            far
            color="primary"
            class="mx-2"
            size="2x"
          />
          <div class="ml-2 mt-2">Client Files</div>
        </div>
      </files-upload>

      <files-upload
        v-model="supplier.SupplierFileCategories"
        @on-files-input="onFilesInput"
        @files-input-error="onFilesInputError"
        @header-message="onFileDownloadMessage"
        ref="fileUpload"
        supplierFileCategoryType="SFCT_A"
      >
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
        >
          <mdb-icon
            icon="clipboard"
            far
            color="primary"
            class="mx-2"
            size="2x"
          />
          <div class="ml-2 mt-2">Agency Files</div>
        </div>
      </files-upload>

      <div class="float-right mt-2">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          :disabled="!isFormDirty()"
          @click="onSubmit"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          @click="onCancel()"
        >
          <mdb-icon icon="times" class="mr-1" />Close
        </awgt-std-button>
      </div>
      <ok-prompt ref="alertPrompt"></ok-prompt>
    </div>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbContainer, mdbIcon, mdbFormInline } from "mdbvue";
import itemisedNotes from "@/components/ItemisedNotes";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput.vue";
import supplierApi from "@/api/supplierApi.js";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import asoftAddressesCapture from "@/components/AtomSoftware/asoftAddressesCapture.vue";
import okPrompt from "@/components/OkPrompt";
import filesUpload from "@/components/FilesUpload";
import referenceApi from "@/api/referenceApi.js";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    AwgtStdDropdown,
    mdbWysiwyg,
    awgtInput,
    asoftPhoneInput,
    asoftAddressesCapture,
    itemisedNotes,
    mdbFormInline,
    okPrompt,
    filesUpload,
    tdoxTooltip,
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      autocomplete: null,
      formSubmissionErrors: [],
      supplier: {},
      toggle: false,
      supplierSnapshot: {},
      isSupplierDirtyInd: false,
      paymentType_PrePaid: false,
      paymentType_PurchaseOrder: false,
      paymentType_Voucher: false,
      files: [],
    };
  },
  watch: {
    paymentType_PrePaid(to) {
      this.updatePaymentType("PY_PP", to);
    },

    paymentType_PurchaseOrder(to) {
      this.updatePaymentType("PY_PO", to);
    },

    paymentType_Voucher(to) {
      this.updatePaymentType("PY_Vo", to);
    },

    "supplier.DepositType"(to) {
      if (to == "DT_No") this.supplier.DepositAmt = 0;
    },
  },
  computed: {
    ...mapGetters([
      "getDepositTypes",
      "getEmptySupplier",
      "getSystemTemplateFromCode",
      "getFileCategories",
      "getEditStatuses",
      "getTooltipsTextByCode",
      "getDefaultSupplierNotes",
    ]),
  },
  methods: {
    setPaymentTypeIndicators(paymentTypesArray) {
      this.paymentType_PrePaid = paymentTypesArray.indexOf("PY_PP") != -1;
      this.paymentType_PurchaseOrder = paymentTypesArray.indexOf("PY_PO") != -1;
      this.paymentType_Voucher = paymentTypesArray.indexOf("PY_Vo") != -1;
    },

    updatePaymentType(paymentTypeCode, setToTrueInd) {
      let idx = this.supplier.PaymentTypes.indexOf(paymentTypeCode);

      if (setToTrueInd) {
        if (idx == -1) this.supplier.PaymentTypes.push(paymentTypeCode);
      } else {
        if (idx != -1) this.supplier.PaymentTypes.splice(idx, 1);
      }
    },

    async onSubmit() {
      this.$log.info("onSave...");
      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the supplier...");
        (this.supplier.SearchAddressString = this.supplier.Addresses.map(
          (address) => {
            return Object.values(address).join(" ");
          }
        ).join(" ")),
          await this.saveSupplier(this.supplier)
            .then(() => {
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: [
                  `The supplier ${this.supplier.Name} has been saved. ${
                    this.supplier.FileStorageMessages.length > 0
                      ? "<br />" +
                        this.supplier.FileStorageMessages.join("<br />")
                      : ""
                  }`,
                ],
              });
              this.$refs.fileUpload.onCleanFiles();
              this.files = [];
              this.snapshotSupplier(this.supplier);
            })
            .catch((error) => {
              this.$log.error(error);
            });
      }
    },

    onCancel() {
      this.$router.go(-1);
    },

    validateForm() {
      this.formSubmissionErrors = [];
      if (this.supplier.Name.length == 0) {
        this.formSubmissionErrors.push("A name is required.");
      }
      if (this.supplier.Name.length != 0 && this.supplier.Name.length > 200) {
        this.formSubmissionErrors.push(
          "A name must be no more than 200 character(s) in length."
        );
      }
      for (let address of this.supplier.Addresses) {
        if (address.Street.length > 90)
          this.formSubmissionErrors.push(
            `${address.Name} street must be no more than 90 character(s) in length.`
          );

        if (address.Locality.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} locality must be no more than 50 character(s) in length.`
          );

        if (address.Subdivision != null && address.Subdivision.length > 3)
          this.formSubmissionErrors.push(
            `${address.Name} subdivision must be no more than 3 character(s) in length.`
          );
        if (
          address.Postcode.length != 0 &&
          (address.Postcode.length < 3 || address.Postcode.length > 12)
        )
          this.formSubmissionErrors.push(
            `${address.Name} postcode must be at least 3 characters in lengths and no more than 12 characters in length.`
          );
        if (address.Country.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} country must be no more than 50 character(s) in length..`
          );
      }
      if (
        this.supplier.EmailAddress.length > 0 &&
        this.supplier.EmailAddress.length < 6
      ) {
        this.formSubmissionErrors.push(
          "An email address must be at least 6 character(s) in length."
        );
      }
      if (this.supplier.EmailAddress.length > 256) {
        this.formSubmissionErrors.push(
          "An email address must be no more than 256 character(s) in length."
        );
      }

      if (this.supplier.Phone.length == 0)
        this.formSubmissionErrors.push(
          "Supplier phone number must be provided."
        );

      if (
        (this.supplier.Phone.length == 0 ||
          (this.supplier.Phone.length == 6 &&
            this.supplier.Phone.substring(0, 2) == "13") ||
          (this.supplier.Phone.length == 10 &&
            (this.supplier.Phone.substring(0, 4) == "1300" ||
              this.supplier.Phone.substring(0, 4) == "1800" ||
              this.supplier.Phone.substring(0, 1) == "0")) ||
          (this.supplier.Phone.length >= 11 &&
            this.supplier.Phone.length <= 15)) == //International Numbers
        false
      ) {
        this.formSubmissionErrors.push(
          `Supplier phone number ${this.supplier.Phone} is not recognised.`
        );
      }

      if (this.supplier.ContactPersonNm.length > 85) {
        this.formSubmissionErrors.push(
          "A contact name must be no more than 85 charactor(s) in length."
        );
      }
      if (this.supplier.DepositType != "DT_No" && this.supplier.DepositAmt == 0)
        this.formSubmissionErrors.push(
          "You must enter a deposit amount or set the deposit type to 'No Deposit'."
        );

      return this.formSubmissionErrors.length == 0;
    },

    async loadFormData(reference) {
      if (
        Array.isArray(this.getFileCategories()) &&
        this.getFileCategories().length > 0 &&
        Array.isArray(this.getDefaultSupplierNotes()) &&
        this.getDefaultSupplierNotes().length > 0
      ) {
        this.supplier.SupplierFileCategories = this.getFileCategories();
        this.supplier.SupplierNotes = this.getDefaultSupplierNotes();
      } else {
        let data = await referenceApi.getReferenceData();
        console.log("ReferenceData: ", data);
        this.supplier.SupplierFileCategories = data.FileCategories;
        this.supplier.SupplierNotes = data.DefaultSupplierNote;
      }

      if (reference != null) {
        this.supplier = await supplierApi.getSupplierByReference(reference);
      }
      this.setPaymentTypeIndicators(this.supplier.PaymentTypes);
    },

    async saveSupplier(supplier) {
      if (typeof supplier.DepositAmt === "string") {
        supplier.DepositAmt = parseFloat(supplier.DepositAmt);
      }
      let formData = new FormData();
      var hasFiles = false;
      if (this.files.length > 0) {
        hasFiles = true;
        formData.append("supplierString", JSON.stringify(supplier));
        for (var i = 0; i < this.files.length; i++) {
          formData.append("files", this.files[i]);
        }
      }
      if (supplier.Reference == "")
        this.supplier = await supplierApi.postSupplier(
          hasFiles ? formData : supplier,
          hasFiles
        );
      else
        this.supplier = await supplierApi.putSupplier(
          hasFiles ? formData : supplier,
          hasFiles
        );
    },

    snapshotSupplier(supplier) {
      this.supplierSnapshot = this.$lodash.cloneDeep(supplier);

      this.isSupplierDirtyInd = false;
    },

    isFormDirty() {
      if (this.isSupplierDirtyInd == true) return true;

      this.isSupplierDirtyInd =
        this.$lodash.isEqual(this.supplier, this.supplierSnapshot) == false;

      return this.isSupplierDirtyInd;
    },

    onFilesInput(files) {
      this.files = files;
    },

    onFilesInputError(errors) {
      this.$refs.alertPrompt.onFirePrompt(
        "Warning",
        `${errors.join(", ")} ${
          errors.length > 1 ? "are" : "is"
        } larger than 10MB so   ${
          errors.length > 1 ? "they" : "it"
        } cannot be added.`
      );
    },

    onFileDownloadMessage(e) {
      this.$emit("header-message", e);
    },
  },

  mounted() {
    this.supplier = this.$lodash.cloneDeep(this.getEmptySupplier);
    let routeParams = this.$route.params;
    this.$log.info("RouteParams:", routeParams);
    let supplierReference = routeParams.supplierReference;

    this.loadFormData(supplierReference)
      .then(() => {
        this.snapshotSupplier(this.supplier);
      })
      .catch(() => {
        // Suppress errors here because they have been logged
      });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSubmit().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
