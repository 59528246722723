var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("awgt-std-dropdown", {
        staticClass: "mx-2 flex-grow-1",
        attrs: {
          label: "Free of Charge Ratio",
          itemValueProperty: "Code",
          items: _vm.selection,
          itemTextProperty: "Name",
          bg: "",
        },
        model: {
          value: _vm.selectFOC,
          callback: function ($$v) {
            _vm.selectFOC = $$v
          },
          expression: "selectFOC",
        },
      }),
      _vm.selectFOC == "Ratio"
        ? _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2 input-field",
            attrs: {
              label: "Free of Charge Ratio",
              maxlength: "4",
              bg: "",
              type: "text",
              mask: "/\\d{1,1}:\\d{1,2}/",
              maskedValueType: "formatted",
            },
            model: {
              value: _vm.inputFOC,
              callback: function ($$v) {
                _vm.inputFOC = $$v
              },
              expression: "inputFOC",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }