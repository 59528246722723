var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
        },
        [
          _vm._t("default"),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button",
              staticStyle: { "min-width": "100px" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.OpenEditor(-1)
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
              _vm._v("Add "),
            ],
            1
          ),
        ],
        2
      ),
      _vm.notesArray &&
      _vm.notesArray.filter((x) => {
        return _vm.noteType == "Any" || x.NoteTypeCd == _vm.noteType
      }).length > 0
        ? _c(
            "div",
            {
              staticClass: "pl-3 pr-2 py-2",
              staticStyle: {
                "border-style": "solid",
                "border-width": "0.5px",
                "border-color": "#e8e8e8",
              },
            },
            _vm._l(_vm.notesArray, function (note, index) {
              return _c("div", { key: index }, [
                _vm.noteType == "Any" || note.NoteTypeCd == _vm.noteType
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center py-1",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap flex-grow-1",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex flex-row flex-nowrap flex-grow-1 mr-3",
                                staticStyle: {
                                  "min-width": "100px",
                                  "max-width": "200px",
                                  "font-weight": "bold",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(note[_vm.noteTitleProperty]) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex flex-row flex-nowrap flex-grow-1",
                                staticStyle: { "min-width": "100px" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(note[_vm.noteDetailProperty]) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button mx-2",
                            staticStyle: { "min-width": "100px" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.OpenEditor(index)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "edit" },
                            }),
                            _vm._v("Edit "),
                          ],
                          1
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button ml-2",
                            staticStyle: { "min-width": "100px" },
                            attrs: {
                              disabled: note.IsMandatoryInd,
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.DeleteNote(index)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "trash-alt" },
                            }),
                            _vm._v("Delete "),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.showEditor } },
        [
          _c("mdb-modal-header", [
            _c("h1", { staticClass: "text-center" }, [_vm._v("Edit Item")]),
          ]),
          _c(
            "mdb-modal-body",
            [
              _vm.editItem != null
                ? [
                    _c("awgt-input", {
                      attrs: {
                        bg: "",
                        label: "Title",
                        disabled: _vm.editItem.IsMandatoryInd,
                      },
                      model: {
                        value: _vm.editItem[_vm.noteTitleProperty],
                        callback: function ($$v) {
                          _vm.$set(_vm.editItem, _vm.noteTitleProperty, $$v)
                        },
                        expression: "editItem[noteTitleProperty]",
                      },
                    }),
                    _c("mdb-input", {
                      staticClass: "textarea-input",
                      attrs: {
                        bg: "",
                        outline: "",
                        label: "Detail",
                        type: "textarea",
                        rows: 5,
                      },
                      model: {
                        value: _vm.editItem[_vm.noteDetailProperty],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.editItem,
                            _vm.noteDetailProperty,
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editItem[noteDetailProperty]",
                      },
                    }),
                    _c("awgt-std-dropdown", {
                      staticStyle: { width: "250px" },
                      attrs: {
                        items: _vm.getNoteTypes(false),
                        itemValueProperty: "Code",
                        itemTextProperty: "Name",
                        label: "Note Type",
                        bg: "",
                        tooltip: _vm.getTooltipsTextByCode(52),
                      },
                      model: {
                        value: _vm.editItem.NoteTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.editItem, "NoteTypeCd", $$v)
                        },
                        expression: "editItem.NoteTypeCd",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.SaveAndCloseEditor()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.CloseEditor()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }