// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".scrollbar-primary::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);\n  background-color: #f5f5f5;\n  border-radius: 10px;\n}\n.splitbutton {\n  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);\n  border-radius: 5px;\n}\n.dropdown-toggle::after {\n  margin-left: 0;\n}\n\n/* .command-button:hover {\n  background-color: #5a95f5 !important;\n} */\n.btn {\n  -webkit-box-shadow: none !important;\n  box-shadow: none !important;\n}\n.scrollbar-primary::-webkit-scrollbar {\n  width: 12px;\n  background-color: #f5f5f5;\n}\n.scrollbar-primary::-webkit-scrollbar-thumb {\n  border-radius: 10px;\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);\n  background-color: #BE7746;\n}\n.scrollbar-primary {\n  position: relative;\n  overflow-y: scroll;\n  height: 200px;\n}", ""]);
// Exports
exports.locals = {
	"awgtStdButtonBackgroundColor": "secondary",
	"awgtStdButtonTextColor": "#eeeeee",
	"awgtStdDropdownHeaderBackgroundColor": "#eeeeee",
	"awgtStdDropdownHeaderSelectedTextColor": "#425664",
	"awgtStdDropdownHeaderLabelColor": "#425664",
	"awgtStdDropdownListBackgroundColor": "#eeeeee",
	"awgtStdDropdownListTextColor": "primary",
	"awgtStdDropdownSelectorBackgroundColor": "primary",
	"awgtStdDropdownTooltipIconColor": "#425664",
	"awgtStdDropdownActiveColor": "#BE7746",
	"awgtInputLabelColor": "secondary",
	"awgtInputActiveColor": "#BE7746",
	"awgtExpansionRegionHeaderBackgroundColor": "grey lighten-3",
	"awgtExpansionRegionHeaderTextColor": "#425664",
	"awgtExpansionRegionBodyColor": "#fafafa",
	"awgtExpansionRegionBodyTextColor": "#425664",
	"awgtExpansionRegionArrowColor": "primary",
	"awgtMenuBarBackgroundColor": "secondary",
	"awgtMenuLinkTextColor": "#f6f4f2",
	"awgtMenuDropdownTextColor": "#425664",
	"awgtMenuDropdownListColor": "#fafafa",
	"awgtDurationPickerBackgroundColor": "\"#f5f5f5\"",
	"awgtDurationPickerLabelColor": "#425664",
	"awgtDurationPickerActiveLabelColor": "#BE7746",
	"awgtDurationPickerActiveColor": "#BE7746",
	"awgtBoxDisplayTitleColor": "#BE7746",
	"awgtBoxDisplayIconColor": "primary",
	"awgtBoxDisplayBorderColor": "#425664",
	"awgtBoxDisplayContentColor": "black",
	"awgtProductViewHeadingColour": "#BE7746",
	"awgtFooterBackgroundColor": "secondary-color"
};
module.exports = exports;
