<template>
  <div>
    <mdb-icon
      icon="dollar-sign"
      color="primary"
      class="grey-text ml-2 mr-1"
      size="2x"
    />
    <awgt-input
      v-if="PriceTypeCd != 'PrT_F'"
      v-model.number="totalPrice"
      label="Incl-GST Price"
      maxlength="7"
      class="ml-2"
      bg
      type="text"
      @input="onTotalPriceInput()"
      @focus="onFocus('totalPrice')"
      style="width: 140px"
      v-numeric="{
        precision: 6,
        scale: 2,
        posOnly: false,
        trailingZeroScale: 2,
      }"
    />
    <awgt-input
      v-model.number="exGSTUnitAmt"
      :label="PriceTypeCd == 'PrT_F' ? 'Price' : 'Ex-GST Price'"
      maxlength="7"
      class="ml-2"
      bg
      type="text"
      @input="onExGSTUnitAmtInput()"
      @focus="onFocus('ExGSTUnitAmt')"
      style="width: 140px"
      v-numeric="{
        precision: 6,
        scale: 2,
        posOnly: false,
        trailingZeroScale: 2,
      }"
    />
    <awgt-input
      v-if="PriceTypeCd != 'PrT_F'"
      v-model.number="gSTUnitAmt"
      label="GST Price"
      maxlength="7"
      class="ml-2"
      bg
      type="text"
      @input="onGSTUnitAmtInput()"
      @focus="onFocus('GSTUnitAmt')"
      style="width: 140px"
      v-numeric="{
        precision: 6,
        scale: 2,
        posOnly: false,
        trailingZeroScale: 2,
      }"
    />
  </div>
</template>

<script>
import awgtInput from "@/components/AWGT/AwgtInput";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mdbIcon } from "mdbvue";

export default {
  components: {
    awgtInput,
    mdbIcon,
  },

  data() {
    return {
      price: {},
      priceControlWithFocus: "",
      totalPrice: 0,
      exGSTUnitAmt: 0,
      gSTUnitAmt: 0,
    };
  },

  directives: {
    numeric: numericDirective,
  },

  props: {
    PriceTypeCd: {
      type: String,
    },
    ExGSTUnitAmt: {
      type: Number,
    },
    GSTUnitAmt: {
      type: Number,
    },

    /*
        PrT_I: Including GST
        PrT_E: Excluding GST
        PrT_F: GST Free
        PrT_Z: No Charge
        PrT_Q: By Quote
    */
  },

  watch: {
    ExGSTUnitAmt: {
      handler(to) {
        this.exGSTUnitAmt = to;
      },
    },
    GSTUnitAmt: {
      handler(to) {
        this.gSTUnitAmt = to;
      },
    },
    exGSTUnitAmt: {
      handler(to, from) {
        if (to != from)
          this.$emit("change", {
            ExGSTUnitAmt: this.exGSTUnitAmt,
            GSTUnitAmt: this.gSTUnitAmt,
          });
      },
    },
    gSTUnitAmt: {
      handler(to, from) {
        if (to != from)
          this.$emit("change", {
            ExGSTUnitAmt: this.exGSTUnitAmt,
            GSTUnitAmt: this.gSTUnitAmt,
          });
      },
    },
  },

  methods: {
    onFocus(element) {
      this.priceControlWithFocus = element;
    },

    onTotalPriceInput() {
      if (this.priceControlWithFocus == "totalPrice") {
        switch (this.PriceTypeCd) {
          case "PrT_I":
            // If price is given Incl GST then need to calculate the price, assume GST is 10%
            this.exGSTUnitAmt = Number.parseFloat(
              ((this.totalPrice / 11) * 10).toFixed(2)
            );
            this.gSTUnitAmt = Number.parseFloat(
              (this.totalPrice / 11).toFixed(2)
            );
            break;
          case "PrT_E":
            this.gSTUnitAmt = this.totalPrice - this.exGSTUnitAmt;
            // If price is Ex GST then you won't be filling out this field it is calculated
            break;
          case "PrT_F":
            // If GST Free you don't change the Total Amount
            break;
        }
      }
    },

    onExGSTUnitAmtInput() {
      if (this.priceControlWithFocus == "ExGSTUnitAmt") {
        switch (this.PriceTypeCd) {
          case "PrT_I":
            // If price is Inc GST then if the Ex GST Amount changes the GST will be
            // GSTIncPrice - ExGSTUnitAmt
            this.gSTUnitAmt = this.totalPrice - this.exGSTUnitAmt;
            break;
          case "PrT_E":
            // If price is given Ex GST then need to calculate the GST... assume 10%
            this.gSTUnitAmt = Number(this.exGSTUnitAmt * 0.1);
            this.totalPrice = Number(this.exGSTUnitAmt * 1.1);
            break;
          case "PrT_F":
            // If GST Free, then ExGSTUnitAmt = price and GST = zero.
            this.totalPrice = this.exGSTUnitAmt;
            this.gSTUnitAmt = 0;
            break;
        }
      }
    },

    onGSTUnitAmtInput() {
      if (this.priceControlWithFocus == "GSTUnitAmt") {
        switch (this.PriceTypeCd) {
          case "PrT_I":
            // If price is Inc GST then if the GST Amount changes the ExGST will be
            // GSTIncPrice - GSTUnitAmt
            this.exGSTUnitAmt = this.totalPrice - this.gSTUnitAmt;
            break;
          case "PrT_E":
            // If price is given Ex GST then need to calculate the GST... assume 10%
            this.totalPrice = this.exGSTUnitAmt + this.gSTUnitAmt;
            break;
          case "PrT_F":
            // If GST Free you don't change the GST Amount
            break;
        }
      }
    },
  },
};
</script>
