<template>
  <mdb-container fluid>
    <h1 class="text-center">User Entry</h1>
    <form novalidate @submit.prevent="onSave">
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
          <awgt-input
            id="firstname-input"
            v-model="user.FirstName"
            counter
            :counterMaxValue="50"
            maxlength="50"
            style="min-width: 300px"
            label="First Name"
            bg
            class="flex-grow-1 mx-2"
          ></awgt-input>
        </div>
        <awgt-input
          id="surname-input"
          v-model="user.Surname"
          counter
          :counterMaxValue="50"
          maxlength="50"
          style="min-width: 300px"
          label="Surname"
          bg
          class="flex-grow-1 mx-2"
        ></awgt-input>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
          <awgt-input
            id="username-input"
            v-model="user.Username"
            counter
            :counterMaxValue="254"
            maxlength="254"
            style="min-width: 20px"
            label="Username"
            bg
            class="flex-grow-1 mx-2"
          ></awgt-input>
        </div>
        <asoftPhoneNumberCapture
          :template="getSystemTemplateFromCode('STT_C_UP')"
          v-model="PhoneJSON"
          class="d-flex flex-row flex-nowrap flex-grow-1"
        >
          <template #default="{ template, phone }">
            <div class="d-flex flex-row flex-nowrap flex-grow-1">
              <mdb-icon
                icon="phone"
                color="primary"
                class="mx-2 mt-4"
                size="2x"
              />
              <!-- Apply input control here -->
              <!-- <awgt-input
                :id="template.Label + 'phone'"
                v-model="phone.Value"
                :counterMaxValue="template.MaxLength"
                counter
                :maxlength="template.MaxLength"
                style="min-width: 20px"
                :label="(phone.Name = template.Label)"
                bg
                type="text"
                class="flex-grow-1 mx-2"
              /> -->
              <asoft-phone-input
                :id="template.Label + 'phone'"
                v-model="phone.Value"
                :maxlength="template.MaxLength"
                style="width: 180px"
                bg
                class="mx-2"
                :label="(phone.Name = template.Label)"
              ></asoft-phone-input>
            </div>
          </template>
        </asoftPhoneNumberCapture>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <mdb-icon icon="at" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-input
          id="email-input"
          v-model="user.EmailAddress"
          counter
          :counterMaxValue="254"
          maxlength="254"
          style="min-width: 300px"
          label="Email"
          bg
          class="flex-grow-1 mx-2"
        ></awgt-input>
      </div>
      <div class="d-flex flex-row flex-nowrap align-items-center">
        <awgt-std-dropdown
          id="consultany-role-select"
          :label="
            user.Workgroups != undefined && user.Workgroups.length > 0
              ? 'Consultancy Role (Disabled)'
              : 'Consultancy Role'
          "
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getRoles"
          itemTextProperty="Name"
          v-model="user.ConsultancyRole.ConsultancyRoleId"
          bg
          :disabled="user.Workgroups != undefined && user.Workgroups.length > 0"
        ></awgt-std-dropdown>
        <awgt-std-button
          v-if="checkPermission('CP_UPU')"
          title="DefaultPermission"
          class="command-button mx-2"
          @click="
            () => {
              onSetDefaultUserPermission(
                user.ConsultancyRole.ConsultancyRoleId
              );
            }
          "
        >
          <mdb-icon class="mr-1" icon="check" />Set Default Permission
        </awgt-std-button>
      </div>
      <div class="d-flex flex-row flex-nowrap align-items-center">
        <awgt-std-dropdown
          id="workgroup-select"
          :label="
            user.ConsultancyRole.MaxWorkgroupAssociations > 1
              ? user.Managers != undefined && user.Managers.length > 0
                ? 'Workgroups (Disabled)'
                : 'Workgroups'
              : user.Managers != undefined && user.Managers.length > 0
              ? 'Workgroup (Disabled)'
              : 'Workgroup'
          "
          :multiple="user.ConsultancyRole.MaxWorkgroupAssociations > 1"
          :selectAll="user.ConsultancyRole.MaxWorkgroupAssociations > 1"
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Reference"
          :items="getWorkgroups"
          itemTextProperty="Name"
          v-model="selectedWorkgroups"
          bg
          :disabled="user.Managers != undefined && user.Managers.length > 0"
        >
        </awgt-std-dropdown>
        <awgt-std-button
          v-if="user.ConsultancyRole.MaxWorkgroupAssociations <= 1"
          class="command-button mx-2"
          @click="selectedWorkgroups = null"
        >
          Reset
        </awgt-std-button>
      </div>

      <template v-if="!inCreateMode">
        <user-permission-edit
          ref="editUserPermission"
          v-model="currentUserPermissions"
          :permissions="permissionSet"
        ></user-permission-edit>
      </template>
      <div class="my-2 float-right">
        <awgt-std-button
          type="submit"
          title="Save"
          class="command-button mx-2"
          icon="check"
          :disabled="!isFormDirty()"
        >
          <mdb-icon class="mr-1" icon="check" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Close"
          class="command-button mx-2"
          icon="times"
          v-on:click="onClose()"
        >
          <mdb-icon class="mr-1" icon="times" />Close
        </awgt-std-button>
      </div>
    </form>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbIcon, mdbContainer } from "mdbvue";
import { mapGetters } from "vuex";
import userPermissionEdit from "./UserPermissionEdit.vue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import workgroupApi from "@/api/workgroupApi.js";
import userApi from "@/api/userApi.js";
import asoftPhoneNumberCapture from "@/components/AtomSoftware/asoftPhoneNumberCapture.vue";
import tokenManager from "@/shared/securityTokenManager.js";
import referenceApi from "@/api/referenceApi.js";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";

export default {
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      formSubmissionErrors: [],
      user: { ConsultancyRole: {} },
      workgroups: [],
      possibleImpersonators: [],
      selectedImpersonators: [],
      PhoneJSON: {},
      selectedWorkgroups: null,
      selectedWorkgroupsSnapshot: [],
      selectedManagedUsersSnapshot: [],
      userSnapshot: {},
      phoneJSONSnapshot: {},
      isUserDirtyInd: false,
      currentUserLevel: 0,
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForUserCreate: null,
      currentUserPermissionScopeForUserUpdate: null,
      currentUserPermissions: [],
      currentUserPermissionsSnapshot: [],
      permissionSet: [],
      inCreateMode: true,
    };
  },
  components: {
    mdbIcon,
    mdbContainer,
    AwgtStdDropdown,
    asoftPhoneNumberCapture,
    awgtInput,
    userPermissionEdit,
    asoftPhoneInput,
    AwgtStdButton,
  },

  computed: {
    ...mapGetters([
      "getEmptyUser",
      "getConsultancyRoles",
      "getSystemTemplateFromCode",
      "getConsultancyRoleLevelFromCode",
      "getUserPermissionsGroup",
      "checkPermission",
    ]),

    getRoles() {
      let roles = this.getConsultancyRoles(false);

      if (
        (this.currentUserPermissionScopeForUserCreate != null &&
          this.currentUserPermissionScopeForBookingCreate == "CPS_NA") ||
        (this.currentUserPermissionScopeForUserUpdate != null &&
          this.currentUserPermissionScopeForUserUpdate == "CPS_NA")
      ) {
        roles = roles.filter((r) => {
          return r.Level <= 400;
        });

        return roles;
      }

      if (
        this.currentUserPermissionScopeForBookingCreate == "CPS_BW" ||
        this.currentUserPermissionScopeForBookingUpdate == "CPS_BW"
      ) {
        roles = roles.filter((r) => {
          return r.Level <= this.currentUserLevel;
        });

        return roles;
      }

      if (
        this.currentUserPermissionScopeForBookingCreate == "CPS_NW" ||
        this.currentUserPermissionScopeForBookingUpdate == "CPS_NW"
      ) {
        roles = roles.filter((r) => {
          return r.Level < this.currentUserLevel;
        });

        return roles;
      }

      return roles;
    },

    getWorkgroups() {
      if (
        this.currentUserPermissionScopeForUserCreate != null &&
        (this.currentUserPermissionScopeForUserCreate == "CPS_BW" ||
          this.currentUserPermissionScopeForUserCreate == "CPS_NW")
      ) {
        return this.workgroups.filter((w) => {
          return (
            this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0 ||
            this.selectedWorkgroups.includes(w.Reference)
          );
        });
      } else if (
        this.currentUserPermissionScopeForUserUpdate != null &&
        (this.currentUserPermissionScopeForUserUpdate == "CPS_BW" ||
          this.currentUserPermissionScopeForUserUpdate == "CPS_NW")
      ) {
        return this.workgroups.filter((w) => {
          return (
            this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0 ||
            this.selectedWorkgroups.includes(w.Reference)
          );
        });
      } else {
        return this.workgroups;
      }
    },
  },
  methods: {
    async onSave() {
      this.$log.info("onSave...");
      this.user.Password = "awgt";
      this.user.PhoneJSON = JSON.stringify(this.PhoneJSON);
      this.user.Workgroups =
        typeof this.selectedWorkgroups == "string" ||
        this.selectedWorkgroups == null
          ? [{ Reference: this.selectedWorkgroups }]
          : this.selectedWorkgroups.map((val) => {
              return { Reference: val };
            });
      if (this.validateForm() == false) {
        this.user.Workgroups = new Array();
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the user...");
        await this.saveUser(this.user)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [
                `The user ${
                  this.user.FirstName + " " + this.user.Surname
                } has been saved.`,
              ],
            });

            this.currentUserPermissions = this.$lodash.cloneDeep(
              this.user.UserPermission
            );
            this.snapshotUser(this.user);
          })
          .catch(() => {
            // Errors have been logged
          });
      }
    },

    onClose() {
      this.$router.go(-1);
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    validateForm() {
      this.formSubmissionErrors = [];
      if (this.user.Username.length == 0) {
        this.formSubmissionErrors.push("A username is required.");
      }
      if (this.user.Username.length != 0 && this.user.Username.length > 254) {
        this.formSubmissionErrors.push(
          "A username must be no more than 254 character(s) in length."
        );
      }
      if (this.user.FirstName.length == 0) {
        this.formSubmissionErrors.push("A first name is required.");
      }
      if (this.user.FirstName.length != 0 && this.user.FirstName.length > 50) {
        this.formSubmissionErrors.push(
          "A first name must be no more than 50 character(s) in length."
        );
      }
      if (this.user.Surname.length == 0) {
        this.formSubmissionErrors.push("A surname is required.");
      }
      if (this.user.Surname.length != 0 && this.user.Surname.length > 50) {
        this.formSubmissionErrors.push(
          "A surname must be no more than 50 character(s) in length."
        );
      }
      for (let phone of this.PhoneJSON.PhoneNumberStorageTemplate
        .PhoneNumbers) {
        if (phone.Value.length != 0 && phone.Value.length != 10) {
          this.formSubmissionErrors.push(
            `${phone.Name} Phone number should be either empty or exactly 10 digits.`
          );
        }
      }
      if (this.user.ConsultancyRole.ConsultancyRoleId == null) {
        this.formSubmissionErrors.push("A consultancy role is required.");
      }
      if (this.user.EmailAddress.length == 0) {
        this.formSubmissionErrors.push("An email address is required.");
      }
      if (
        this.user.EmailAddress.length != 0 &&
        this.user.EmailAddress.length > 254
      ) {
        this.formSubmissionErrors.push(
          "A email must be no more than 254 character(s) in length."
        );
      }
      return this.formSubmissionErrors.length == 0;
    },

    async loadFormData(reference) {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      if (reference == null) {
        this.user = this.$lodash.cloneDeep(this.getEmptyUser);
        this.currentUserPermissionScopeForUserCreate =
          currentUser.UserPermission.find((cp) => {
            return cp.Permission == "CP_UsC";
          })?.PermissionScope;
      } else {
        this.inCreateMode = false;
        this.user = await userApi.getUserByReference(reference);
        this.currentUserPermissionScopeForUserUpdate =
          currentUser.UserPermission.find((cp) => {
            return cp.Permission == "CP_UsU";
          })?.PermissionScope;
      }

      this.PhoneJSON = JSON.parse(this.user.PhoneJSON);
      this.currentUserPermissions = this.$lodash.cloneDeep(
        this.user.UserPermission
      );

      let workgroups = await workgroupApi.getWorkgroups();
      for (let wg of workgroups) {
        this.workgroups.push({ Reference: wg.Reference, Name: wg.Name });
      }
      if (this.user.ConsultancyRole.MaxWorkgroupAssociations > 1) {
        this.selectedWorkgroups = this.user.Workgroups.map(
          (item) => item.Reference
        );
      } else {
        this.selectedWorkgroups =
          this.user.Workgroups.length > 0
            ? this.user.Workgroups[0].Reference
            : new Array();
      }
    },

    async saveUser(user) {
      let isFormDirty = this.isFormDirty();
      this.user.UserPermission = this.currentUserPermissions;

      if (isFormDirty == true) {
        if (user.Reference == "") this.user = await userApi.postUser(user);
        else this.user = await userApi.putUser(user);
      } else this.$log.info("User is not dirty so was not saved.");
    },

    async onSetDefaultUserPermission(role) {
      this.user.UserPermission =
        await referenceApi.getDefaultPermissionForConsultancyRole(role);
      this.$refs.editUserPermission.onResetDefaultUserPermission([
        ...this.user.UserPermission,
      ]);

      this.$emit("header-message", {
        isSuccessInd: true,
        Message: [
          "The consultancy role permission is set to default value. Please save this user.",
        ],
      });
    },

    snapshotUser(user) {
      this.userSnapshot = this.$lodash.cloneDeep(user);
      this.phoneJSONSnapshot = this.$lodash.cloneDeep(this.PhoneJSON);
      this.currentUserPermissionsSnapshot = this.$lodash.cloneDeep(
        user.UserPermission
      );
      this.selectedWorkgroupsSnapshot = this.$lodash.cloneDeep(
        this.selectedWorkgroups
      );

      this.isUserDirtyInd = false;
    },

    isFormDirty() {
      if (this.isUserDirtyInd == true) return true;
      this.isUserDirtyInd =
        this.$lodash.isEqual(this.user, this.userSnapshot) == false ||
        this.$lodash.isEqual(this.PhoneJSON, this.phoneJSONSnapshot) == false ||
        this.$lodash.isEqual(
          [...this.currentUserPermissions].sort(),
          [...this.currentUserPermissionsSnapshot].sort()
        ) == false ||
        this.$lodash.isEqual(
          this.selectedWorkgroups,
          this.selectedWorkgroupsSnapshot
        ) == false;

      return this.isUserDirtyInd;
    },

    async dealWithPermissionSet() {
      if (this.getUserPermissionsGroup)
        this.permissionSet = this.getUserPermissionsGroup;
      else
        this.permissionSet = (
          await referenceApi.getReferenceData()
        ).UserPermissionsGroup;
    },
  },
  created() {},
  mounted() {
    this.user = this.$lodash.cloneDeep(this.getEmptyUser);
    let routeParams = this.$route.params;
    this.$log.info("RouteParams:", routeParams);
    let userReference = routeParams.userReference;
    this.loadFormData(userReference).then(async () => {
      await this.dealWithPermissionSet();
      this.snapshotUser(this.user);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSave().then(() => {
            next();
          });
        }
      });
    } else next();
  },
  watch: {
    "user.ConsultancyRole.ConsultancyRoleId": {
      handler(newValue) {
        if (newValue != null) {
          this.user.ConsultancyRole.Level =
            this.getConsultancyRoleLevelFromCode(newValue);
        }
      },
    },
  },
};
</script>
<style scoped></style>
