var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [_vm._v("User Activity Report")]),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Workgroup",
            itemValueProperty: "Code",
            items: _vm.workgroupsList,
            itemTextProperty: "Name",
            bg: "",
            search: "",
            multiple: "",
            selectAll: "",
          },
          model: {
            value: _vm.workgroups,
            callback: function ($$v) {
              _vm.workgroups = $$v
            },
            expression: "workgroups",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Consultants",
            itemValueProperty: "Code",
            items: _vm.getConsultants,
            itemTextProperty: "Name",
            bg: "",
            search: "",
            multiple: "",
            selectAll: "",
          },
          model: {
            value: _vm.consultants,
            callback: function ($$v) {
              _vm.consultants = $$v
            },
            expression: "consultants",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }