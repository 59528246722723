<template>
  <button
    type="button"
    style="border: none; background-color: inherit"
    :style="{ color: iconColor }"
    :title="detail"
    v-tippy="{ position: 'bottom', arrow: true }"
  >
    <mdb-icon
      :icon="iconName"
      fas
      :style="{ color: iconColor }"
      style="cursor: pointer"
      :size="iconSize"
    />
  </button>
</template>

<script>
import { mdbIcon } from "mdbvue";

export default {
  components: {
    mdbIcon,
  },

  props: {
    detail: {
      type: String,
      required: true,
    },

    iconName: {
      type: String,
      default: "info-circle",
    },

    iconColor: {
      type: String,
      default: "grey",
    },
    iconSize: {
      type: String,
      default: "1x",
    },
  },
};
</script>

<style></style>
