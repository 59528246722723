<!-- Source: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/ -->

<!----------------------------------------------
                    Template
----------------------------------------------->
<template>
  <div>
    <div :class="[{ ontop: onTop == true }]">
      <transition
        name="expansionregion"
        @enter="onEnter"
        @after-enter="onAfterEnter"
        @leave="onLeave"
      >
        <template v-if="useVShowConditional == true">
          <component v-show="toggle" :is="tag">
            <slot />
          </component>
        </template>
        <template v-else>
          <component v-if="toggle" :is="tag">
            <slot />
          </component>
        </template>
      </transition>
    </div>
  </div>
</template>

<!----------------------------------------------
                    Style
----------------------------------------------->
<style scoped>
.expansionregion-enter-active,
.expansionregion-leave-active {
  transition-property: opacity, height;
  overflow: hidden;
  transition-property: height;
}

.expansionregion-enter,
.expansionregion-leave-to {
  height: 0;
  opacity: 0;
}

.ontop {
  position: absolute;
  width: 100%;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<!----------------------------------------------
                    Script
----------------------------------------------->
<script>
export default {
  name: "ExpansionRegion",
  props: {
    toggle: { type: Boolean, default: false },
    tag: { type: String, default: "div" },
    useVShowConditional: { type: Boolean, default: false },
    onTop: { type: Boolean, default: false },
    openDuration: { type: Number, default: 300 },
    closeDuration: { type: Number, default: 300 },
    expectedHeight: { type: String, default: "1000px" },
  },
  methods: {
    onEnter(element) {
      element.style.transitionDuration = `${this.openDuration}ms`;

      //Calculation of height using getComputedStyle does not work
      //as the element does not exist in the DOM.
      /*    
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      */

      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      let height = getComputedStyle(element).height;
      if (height == "0px") height = this.expectedHeight;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      //setTimeout(() => {
      element.style.height = height;
      //});
    },
    onAfterEnter(element) {
      element.style.height = "auto";
    },
    onLeave(element) {
      element.style.transitionDuration = `${this.closeDuration}ms`;
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
