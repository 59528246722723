var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible == true
    ? _c(
        "mdb-modal",
        {
          attrs: {
            size: "lg",
            show: _vm.dialogVisible,
            id: "itineraryEntryDialog",
          },
          on: { close: _vm.onCancel },
        },
        [
          _c("mdb-modal-header", [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v("Itinerary Day Note Edit"),
            ]),
          ]),
          _c(
            "mdb-modal-body",
            [
              _vm._l(
                _vm.itinerary.ItineraryDayNotes,
                function (dayNote, index) {
                  return _c("div", { key: index }, [
                    dayNote.Edit == true
                      ? _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row flex-nowrap" },
                              [
                                _c("asoftDatePicker", {
                                  staticClass: "flex-grow-1 mr-2",
                                  staticStyle: { "min-width": "140px" },
                                  attrs: {
                                    label: "Date",
                                    autoHide: "",
                                    displayDateFormat: "D MMM YYYY",
                                    dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                                    setTime: "00:00:00.000",
                                    bg: "",
                                    width: 300,
                                  },
                                  model: {
                                    value: dayNote.Date,
                                    callback: function ($$v) {
                                      _vm.$set(dayNote, "Date", $$v)
                                    },
                                    expression: "dayNote.Date",
                                  },
                                }),
                                _c("awgt-std-dropdown", {
                                  staticClass: "flex-grow-1",
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    items: _vm.getItineraryDayNoteTypes(false),
                                    itemValueProperty: "Code",
                                    itemTextProperty: "Name",
                                    label: "Itinerary Day Note Type",
                                    bg: "",
                                  },
                                  model: {
                                    value: dayNote.ItineraryDayNoteType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        dayNote,
                                        "ItineraryDayNoteType",
                                        $$v
                                      )
                                    },
                                    expression: "dayNote.ItineraryDayNoteType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("mdb-wysiwyg", {
                              staticStyle: { height: "200px" },
                              model: {
                                value: dayNote.Note,
                                callback: function ($$v) {
                                  _vm.$set(dayNote, "Note", $$v)
                                },
                                expression: "dayNote.Note",
                              },
                            }),
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mt-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDayNoteEditClose(dayNote)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "check" },
                                }),
                                _vm._v("Close "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _c("span", { staticClass: "mr-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.convertDateFormat(
                                    dayNote.Date,
                                    "YYYY-MM-DDTHH:mm:ss",
                                    "D MMM YYYY"
                                  )
                                )
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getItineraryDayNoteTypesByCode(
                                    dayNote.ItineraryDayNoteType
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mr-2",
                                    attrs: { type: "button", title: "Edit" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDayNoteEdit(dayNote)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "edit" },
                                    }),
                                    _vm._v("Edit "),
                                  ],
                                  1
                                ),
                                _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDayNoteDelete(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Delete "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", {
                            staticClass: "ml-2",
                            domProps: { innerHTML: _vm._s(dayNote.Note) },
                          }),
                        ]),
                  ])
                }
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button",
                      attrs: { type: "button", title: "Add" },
                      on: {
                        click: function ($event) {
                          return _vm.onDayNoteAdd()
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("mdb-modal-footer", [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onSave()
                      },
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "check" },
                    }),
                    _vm._v("Save "),
                  ],
                  1
                ),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onCancel()
                      },
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "times" },
                    }),
                    _vm._v("Cancel "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }