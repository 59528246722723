var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "mdb-tbl",
        { attrs: { striped: "" } },
        [
          _c(
            "mdb-tbl-body",
            _vm._l(_vm.contactsArray, function (tc) {
              return _c("tr", { key: tc.BookingContactId }, [
                _c("td", [
                  _c("div", { staticClass: "d-flex flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "flex-nowrap mr-3" },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "user", color: "primary", size: "1x" },
                        }),
                        _vm._v(" " + _vm._s(tc.Name) + " "),
                      ],
                      1
                    ),
                    tc.Phone.length > 0
                      ? _c(
                          "div",
                          { staticClass: "flex-nowrap mr-3" },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                icon: "phone",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(" " + _vm._s(tc.Phone) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                    tc.EmailAddress.length > 0
                      ? _c(
                          "div",
                          { staticClass: "flex-nowrap mr-3" },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                icon: "at",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(" " + _vm._s(tc.EmailAddress) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                    tc.IsBookingContact == true
                      ? _c(
                          "div",
                          { staticClass: "flex-nowrap mr-3" },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                icon: "calendar-alt",
                                far: "",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(" Booking Contact "),
                          ],
                          1
                        )
                      : _vm._e(),
                    tc.IsTripContact == true
                      ? _c(
                          "div",
                          { staticClass: "flex-nowrap mr-3" },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                icon: "plane",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(" Travel Contact "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("td", { staticStyle: { "vertical-align": "middle" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-nowrap float-right" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "mx-2",
                          staticStyle: {
                            border: "none",
                            "background-color": "inherit",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.initiateEditBookingContact(
                                tc.BookingContactId
                              )
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            attrs: {
                              color: "primary",
                              icon: "edit",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "mx-2",
                          staticStyle: {
                            border: "none",
                            "background-color": "inherit",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.initiateDeleteBookingContact(
                                tc.BookingContactId
                              )
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            attrs: {
                              color: "primary",
                              icon: "trash-alt",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        attrs: {
                          icon: "arrow-up",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        attrs: {
                          icon: "arrow-down",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.showEditBookingContactModal == true
            ? _c(
                "mdb-modal",
                {
                  attrs: { size: "md", show: _vm.showEditBookingContactModal },
                },
                [
                  _c(
                    "mdb-modal-header",
                    [_c("mdb-modal-title", [_vm._v("Edit Travel Contact")])],
                    1
                  ),
                  _c("mdb-modal-body", [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-nowrap flex-row" },
                      [
                        _c("mdb-icon", {
                          staticClass: "mt-4",
                          attrs: { icon: "user", color: "primary", size: "2x" },
                        }),
                        _c("awgt-input", {
                          staticClass: "flex-grow-1 mx-2",
                          attrs: {
                            counter: "",
                            counterMaxValue: 85,
                            maxlength: "85",
                            label: "Name",
                            bg: "",
                          },
                          model: {
                            value: _vm.bookingContact.Name,
                            callback: function ($$v) {
                              _vm.$set(_vm.bookingContact, "Name", $$v)
                            },
                            expression: "bookingContact.Name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-nowrap flex-row" },
                      [
                        _c("mdb-icon", {
                          staticClass: "mt-4",
                          attrs: {
                            icon: "phone",
                            color: "primary",
                            size: "2x",
                          },
                        }),
                        _c("asoft-phone-input", {
                          staticClass: "flex-grow-1 mx-2",
                          staticStyle: { width: "180px" },
                          attrs: { maxlength: "20", label: "Phone", bg: "" },
                          model: {
                            value: _vm.bookingContact.Phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.bookingContact, "Phone", $$v)
                            },
                            expression: "bookingContact.Phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-nowrap flex-row" },
                      [
                        _c("mdb-icon", {
                          staticClass: "mt-4",
                          attrs: { icon: "at", color: "primary", size: "2x" },
                        }),
                        _c("awgt-input", {
                          staticClass: "flex-grow-1 mx-2",
                          attrs: {
                            counter: "",
                            counterMaxValue: 254,
                            maxlength: "254",
                            label: "Email",
                            bg: "",
                          },
                          model: {
                            value: _vm.bookingContact.EmailAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.bookingContact, "EmailAddress", $$v)
                            },
                            expression: "bookingContact.EmailAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap min-width:120px;",
                      },
                      [
                        _c("awgt-input", {
                          staticClass: "mx-2",
                          staticStyle: { "min-width": "210px", width: "210px" },
                          attrs: {
                            id: "bookingContactType_IsBookingContact",
                            type: "checkbox",
                            name: "bookingContactType",
                            label: "Booking Contact",
                            tooltip: _vm.getTooltipsTextByCode(26),
                          },
                          model: {
                            value: _vm.bookingContact.IsBookingContact,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.bookingContact,
                                "IsBookingContact",
                                $$v
                              )
                            },
                            expression: "bookingContact.IsBookingContact",
                          },
                        }),
                        _c("awgt-input", {
                          staticClass: "mx-2",
                          staticStyle: { "min-width": "195px", width: "195px" },
                          attrs: {
                            id: "bookingContactType_IsTravelContact",
                            type: "checkbox",
                            name: "travelContactType",
                            label: "Travel Contact",
                            tooltip: _vm.getTooltipsTextByCode(27),
                          },
                          model: {
                            value: _vm.bookingContact.IsTripContact,
                            callback: function ($$v) {
                              _vm.$set(_vm.bookingContact, "IsTripContact", $$v)
                            },
                            expression: "bookingContact.IsTripContact",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "mdb-modal-footer",
                    [
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.saveBookingContact()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showEditBookingContactModal = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.showDeletionConfirmationModal == true
            ? _c(
                "mdb-modal",
                {
                  attrs: {
                    size: "sm",
                    show: _vm.showDeletionConfirmationModal,
                  },
                },
                [
                  _c(
                    "mdb-modal-header",
                    [_c("mdb-modal-title", [_vm._v("Confirm Delete")])],
                    1
                  ),
                  _c("mdb-modal-body", [
                    _vm._v(
                      'Press "Confirm" to delete contact "' +
                        _vm._s(
                          this.contactsArray[this.currentBookingContactIdx].Name
                        ) +
                        '"? '
                    ),
                  ]),
                  _c(
                    "mdb-modal-footer",
                    [
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.confirmDeleteBookingContact()
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "check" },
                          }),
                          _vm._v(" Confirm "),
                        ],
                        1
                      ),
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showDeletionConfirmationModal = false
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "times" },
                          }),
                          _vm._v("Cancel "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }