<template>
  <!--
        Component Chronology Dialog
    -->
  <!-- The v-on below allows you to run a method when the dialog becomes visible -->
  <div v-on="onVisible()">
    <asoft-modal-header
      label="Product Pricing"
      @close="
        () => {
          if (showEditPane == true) onCancel();
          else onClose();
        }
      "
    >
    </asoft-modal-header>
    <mdb-modal-body class="px-2">
      <div v-if="isDateOverlap()" class="error-msg mb-2">
        <b>Warning: overlapping dates.</b>
      </div>
      <div
        v-for="(chronology, index) in commercialProductLine.Chronology"
        :key="chronology.CommercialProductLineChronologyId"
        class="px-2 py-2 border d-flex"
        :class="[
          {
            currentComponentChronology:
              new Date(chronology.ActiveFromDt) <= new Date() &&
              (chronology.ActiveToDt == null ||
                new Date(chronology.ActiveToDt) >= new Date()),
          },
        ]"
      >
        <div>
          <h4>{{ chronology.Name }}</h4>
          <div v-if="chronology.ActiveToDt == null" class="flex-fill">
            From
            {{
              convertDateFormat(
                chronology.ActiveFromDt,
                $constants.DATETIMEFORMAT_Json,
                $constants.DATETIMEFORMAT_Presentation
              )
            }}
          </div>
          <div v-else class="flex-fill">
            {{
              convertDateFormat(
                chronology.ActiveFromDt,
                $constants.DATETIMEFORMAT_Json,
                $constants.DATETIMEFORMAT_Presentation
              )
            }}
            -
            {{
              convertDateFormat(
                chronology.ActiveToDt,
                $constants.DATETIMEFORMAT_Json,
                $constants.DATETIMEFORMAT_Presentation
              )
            }}
          </div>
          <div>
            <template v-if="chronology.ProductLinePrices.length == 0">
              <div class="d-flex flex-row justify-content-center">
                <i>No Pricing has been provided</i>
              </div>
            </template>
            <template>
              <div
                v-for="(price, priceIndex) in chronology.ProductLinePrices"
                :key="priceIndex"
              >
                <span>
                  {{ formatPriceString(chronology, price) }}
                  <button
                    v-if="chronology.IsIndicativePricingInd == true"
                    type="button"
                    style="border: none; background-color: inherit; color: grey"
                    :title="getTooltipsTextByCode(11)"
                    v-tippy="{ position: 'bottom', arrow: true }"
                  >
                    <mdb-icon
                      far
                      icon="exclamation-circle"
                      style="cursor: pointer"
                      :style="{
                        color: 'orange',
                      }"
                      size="1x"
                    />
                  </button>
                </span>
              </div>
            </template>
          </div>
        </div>
        <div class="float-right ml-auto my-auto">
          <button
            @click="onEdit(index)"
            style="border: none; background-color: inherit"
            type="button"
            class="p-2"
            :disabled="showEditPane == true"
          >
            <mdb-icon
              :color="showEditPane ? 'lightgrey' : 'primary'"
              icon="edit"
              size="2x"
            />
          </button>
          <button
            @click="onDelete(index)"
            style="border: none; background-color: inherit"
            type="button"
            class="p-2"
            :disabled="showEditPane == true"
          >
            <mdb-icon
              :color="showEditPane ? 'lightgrey' : 'primary'"
              far
              icon="trash-alt"
              size="2x"
            />
          </button>
        </div>
      </div>
      <div v-if="showEditPane == false" class="mt-2">
        <div class="d-flex">
          <awgt-std-button
            class="mr-2 ml-auto command-button"
            type="button"
            @click="onAdd()"
          >
            <mdb-icon icon="plus" class="mr-1" />Add
          </awgt-std-button>
          <awgt-std-button
            type="button"
            @click="onClose()"
            class="command-button"
            >Close</awgt-std-button
          >
        </div>
      </div>
      <template
        v-if="
          commercialProductLine.Chronology.length > 0 && showEditPane == true
        "
      >
        <hr />
      </template>

      <expansion-region :toggle="showEditPane">
        <h5 v-if="currentItemIdx == -1" ref="ErrorMessages">Add Item</h5>
        <h5 v-else ref="ErrorMessages">Edit Item</h5>

        <div v-if="formValidationMessages.length > 0" class="errorbox">
          <li v-for="m in formValidationMessages" :key="m" class="error-msg">
            {{ m }}
          </li>
        </div>

        <div>
          <div class="d-flex flex-row flex-wrap flex-grow-1">
            <template v-for="(category, index) in getMarketCategories()">
              <awgt-input
                v-bind:key="index"
                type="checkbox"
                :id="'is' + category.Name"
                :name="'is' + category.Name"
                v-model="marketCategories[index]"
                :label="category.Name"
              />
            </template>
          </div>
          <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <awgt-input
              v-model="commercialProductLineChronology.Name"
              :counterMaxValue="100"
              maxlength="100"
              counter
              label="Name"
              bg
              required
              class="flex-grow-1"
            />
          </div>
          <div class="mb-2">
            <div
              class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
            >
              <mdb-icon icon="clipboard" far color="primary" size="2x" />
              <div class="ml-2">Description</div>
            </div>
            <div>
              <mdb-wysiwyg
                v-model="commercialProductLineChronology.Description"
                style="height: 150px"
              ></mdb-wysiwyg>
            </div>
          </div>
          <div class="mb-2">
            <div
              class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
            >
              <mdb-icon icon="clipboard" far color="primary" size="2x" />
              <div class="ml-2">Purchasing Reminder</div>
            </div>
            <div>
              <mdb-wysiwyg
                v-model="commercialProductLineChronology.PurchasingReminder"
                style="height: 150px"
              ></mdb-wysiwyg>
            </div>
          </div>
          <mdb-form-inline class="flex-wrap border px-2 py-3 mb-2">
            <div class="d-flex flex-row flex-wrap flex-grow-1">
              <span style="width: 100px">Price Type:</span>
              <awgt-input
                id="price_GSTIn"
                v-model="commercialProductLineChronology.PriceTypeCd"
                type="radio"
                name="PriceTypeGroup"
                label="GST Incl."
                radioValue="PrT_I"
              />
              <awgt-input
                id="price_GSTEx"
                v-model="commercialProductLineChronology.PriceTypeCd"
                type="radio"
                name="PriceTypeGroup"
                label="GST Excl."
                radioValue="PrT_E"
              />
              <awgt-input
                id="price_GSTFr"
                v-model="commercialProductLineChronology.PriceTypeCd"
                type="radio"
                name="PriceTypeGroup"
                label="GST Free"
                radioValue="PrT_F"
              />
              <awgt-input
                id="price_GSTNo"
                v-model="commercialProductLineChronology.PriceTypeCd"
                type="radio"
                name="PriceTypeGroup"
                label="No Charge"
                radioValue="PrT_Z"
              />
              <awgt-input
                id="price_Quote"
                v-model="commercialProductLineChronology.PriceTypeCd"
                type="radio"
                name="PriceTypeGroup"
                label="By Quote"
                radioValue="PrT_Q"
              />
            </div>
          </mdb-form-inline>
          <div class="flex-wrap border px-2 py-3 my-2">
            <div
              class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
            >
              <div
                v-if="
                  commercialProductLineChronology.ProductLinePrices.length == 0
                "
              >
                Pricing for this product line is...
              </div>
              <awgt-std-button
                v-if="
                  commercialProductLineChronology.ProductLinePrices.length == 0
                "
                class="ml-auto command-button"
                type="button"
                @click="onAddPricingScaleType()"
                :disabled="arePricingScaleTypesDisabled"
              >
                <mdb-icon icon="plus" class="mr-1" />Add
              </awgt-std-button>
            </div>
            <template
              v-if="
                commercialProductLineChronology.ProductLinePrices.length == 0
              "
            >
              <template
                v-if="
                  commercialProductLineChronology.PricingScaleTypes.length == 0
                "
              >
                <div class="d-flex flex-row justify-content-center">
                  <i>Press Add to specify how pricing is determined</i>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(
                    pricingScaleType, index
                  ) in commercialProductLineChronology.PricingScaleTypes"
                  :key="index"
                >
                  <div class="d-flex flex-row flex-grow-1 align-items-center">
                    <div
                      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
                    >
                      <template
                        v-if="
                          commercialProductLineChronology.PriceTypeCd ==
                            'PrT_Z' ||
                          commercialProductLineChronology.PriceTypeCd == 'PrT_Q'
                        "
                      >
                        Priced per
                      </template>
                      <template v-else>
                        <awgt-std-dropdown
                          v-model="pricingScaleType.QuantityUnitPurposeCd"
                          :items="getQuantityUnitPurposes()"
                          itemValueProperty="Code"
                          itemTextProperty="Name"
                          label="Purpose"
                          class="flex-grow-1 mr-2"
                          bg
                          :disabled="arePricingScaleTypesDisabled"
                        ></awgt-std-dropdown>
                      </template>
                    </div>
                    <div
                      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
                    >
                      <awgt-std-dropdown
                        v-model="pricingScaleType.QuantityUnitCd"
                        :items="
                          getFilteredQuantityUnits(
                            commercialProductLineChronology.PricingScaleTypes,
                            pricingScaleType.QuantityUnitCd
                          )
                        "
                        itemValueProperty="Code"
                        itemTextProperty="Name"
                        label="Quantity Unit"
                        class="flex-grow-1 mr-2"
                        bg
                        :disabled="arePricingScaleTypesDisabled"
                        @get-value="
                          onPricingScaleTypeQuantityUnitSelected(
                            pricingScaleType,
                            commercialProductLineChronology
                          )
                        "
                      ></awgt-std-dropdown>
                    </div>

                    <div
                      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
                    >
                      <template
                        v-if="
                          commercialProductLineChronology.PriceTypeCd ==
                            'PrT_Z' ||
                          commercialProductLineChronology.PriceTypeCd == 'PrT_Q'
                        "
                      >
                        Unscaled
                      </template>
                      <template v-else>
                        <div style="width: 100px">with scaling</div>
                        <awgt-std-dropdown
                          v-model="pricingScaleType.PricingScaleCd"
                          :items="
                            getFilteredPricingScales(
                              pricingScaleType.QuantityUnitPurposeCd
                            )
                          "
                          itemValueProperty="Code"
                          itemTextProperty="Name"
                          label="Pricing Scale"
                          class="flex-grow-1"
                          bg
                          :disabled="arePricingScaleTypesDisabled"
                        ></awgt-std-dropdown>
                      </template>
                    </div>
                    <awgt-std-button
                      type="button"
                      class="command-button ml-2"
                      style="min-width: 63px; margin-left: auto"
                      @click="onDeletePricingScaleType(index)"
                      :disabled="arePricingScaleTypesDisabled"
                      ><mdb-icon
                        icon="trash-alt"
                        class="mr-1"
                      />Delete</awgt-std-button
                    >
                  </div>
                  <div
                    v-if="pricingScaleType.QuantityUnitCd == 'QU_I'"
                    class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
                  >
                    <span class="mr-2">where "item" is named</span>
                    <awgt-input
                      v-model="
                        commercialProductLineChronology.SingularItemAlias
                      "
                      :counterMaxValue="15"
                      maxlength="15"
                      style="width: 100px"
                      counter
                      label="Alias"
                      bg
                      class="flex-grow-1 mr-2"
                    />
                    <span class="mr-2">and "items" is named</span>
                    <awgt-input
                      v-model="commercialProductLineChronology.PluralItemAlias"
                      :counterMaxValue="15"
                      maxlength="15"
                      style="width: 100px"
                      counter
                      label="Plural Alias"
                      bg
                      class="flex-grow-1"
                    />
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div
                v-if="
                  hasPerPricing(
                    commercialProductLineChronology.PricingScaleTypes
                  ) == true
                "
              >
                Pricing is
                {{
                  getPerQuantityUnitQualifier(commercialProductLineChronology)
                }}.
              </div>
              <div
                v-if="
                  hasAPricingScale(
                    commercialProductLineChronology.PricingScaleTypes
                  ) == true
                "
              >
                Pricing is scaled
                {{ getScaledByQualifier(commercialProductLineChronology) }}.
              </div>
            </template>
          </div>

          <template
            v-if="
              commercialProductLineChronology.PriceTypeCd == 'PrT_Q' ||
              commercialProductLineChronology.PriceTypeCd == 'PrT_Z'
            "
          >
            <div style="border: 1px solid #dee2e6">
              <div
                class="d-flex flex-wrap flex-grow-1 align-items-center mx-2 py-2"
              >
                <span
                  >Pricing:
                  {{
                    getPriceTypeStr(commercialProductLineChronology.PriceTypeCd)
                  }}</span
                >
              </div>
            </div>
          </template>
          <template v-else>
            <template
              v-if="
                commercialProductLineChronology.PricingScaleTypes.length > 0
              "
            >
              <div style="border: 1px solid #dee2e6">
                <div>
                  <commercial-product-line-price-list
                    v-model="commercialProductLineChronology"
                  >
                  </commercial-product-line-price-list>
                </div>
                <div class="mt-2 ml-2">
                  Minimum Total Price
                  {{
                    getPriceTypeStr(
                      commercialProductLineChronology.PriceTypeCd
                    )
                  }}:
                </div>
                <div class="d-flex flex-row align-items-center ml-2">
                  <mdb-icon
                    icon="dollar-sign"
                    color="primary"
                    class="ml-1"
                    size="2x"
                    style="width: 28px"
                  />
                  <awgt-input
                    v-model.number="
                      commercialProductLineChronology.MinTotalPriceAmt
                    "
                    maxlength="7"
                    label="Minimum Total Price Amount"
                    class="ml-1"
                    bg
                    type="text"
                    style="width: 220px"
                    v-numeric="{
                      precision: 6,
                      scale: 2,
                      posOnly: false,
                    }"
                    tooltip="The minimum product price charged regardless of the quantity purchased"
                  />
                </div>
                <div style="margin-top: -30px">
                  <div style="position: relative; top: 40px" class="ml-2">
                    Surcharges &amp; Discounts:
                  </div>
                  <product-price-adjustments
                    class="mx-2 my-2"
                    v-model="
                      commercialProductLineChronology.CommercialProductLinePriceAdjustments
                    "
                  />
                </div>
              </div>
            </template>
          </template>

          <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <mdb-icon
              icon="calendar-alt"
              far
              color="primary"
              class="mt-3"
              size="2x"
              style="width: 28px"
            />
            <asoftDatePicker
              v-model="commercialProductLineChronology.ActiveFromDt"
              label="Valid From"
              autoHide
              :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
              :dataDateFormat="$constants.DATETIMEFORMAT_Json"
              setTime="00:00:00.000"
              bg
              class="ml-2"
            />
            <asoftDatePicker
              v-model="commercialProductLineChronology.ActiveToDt"
              @change="doCommercialProductLineChronologyValidToDate_input"
              label="Valid To"
              autoHide
              :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
              :dataDateFormat="$constants.DATETIMEFORMAT_Json"
              setTime="23:59:59.000"
              bg
              class="ml-2"
            />
          </div>
          <div class="d-flex flex-row flex-wrap flex-grow-1">
            <awgt-input
              type="checkbox"
              id="DisplayBoundariesOnly"
              name="DisplayBoundariesOnly"
              v-model="commercialProductLineChronology.DisplayBoundariesOnly"
              label="Display Boundaries Only?"
              class="mt-1 pl0-important"
              style="margin-left: 18px"
            />
          </div>
          <div class="d-flex flex-row flex-nowrap align-items-center">
            <awgt-std-button
              type="button"
              class="ml-auto mr-2 command-button"
              @click="onSave()"
              >Save</awgt-std-button
            >
            <awgt-std-button
              type="button"
              class="mr-0 command-button"
              @click="onCancel()"
              >Cancel</awgt-std-button
            >
          </div>
        </div>
      </expansion-region>

      <!--
        Deletion Confirmation Dialog
    -->
      <mdb-modal
        size="sm"
        v-if="showDeletionConfirmationModal == true"
        :show="showDeletionConfirmationModal"
      >
        <mdb-modal-header>
          <mdb-modal-title>Confirm Delete</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body
          >Press "Confirm" to delete the pricing component.
          <p v-if="commercialProductLine.Chronology.length == 1">
            <br />
            This will also delete this component and close the edit dialog.
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="onConfirmDeletePricingComponent"
          >
            <mdb-icon icon="check" class="mr-1" />Confirm
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="onCancelDeletePricingComponent"
          >
            <mdb-icon icon="times" class="mr-1" />Cancel</awgt-std-button
          >
        </mdb-modal-footer>
      </mdb-modal>
    </mdb-modal-body>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<!-- Unfortunately I can't use "scoped" on the following since
scoped kills the style. Have narrowed the style to the radio
button group only :-) -->
<style>
input[name="PriceTypeGroup"] + label.form-check-label {
  margin-right: 1rem !important;
}
</style>

<style scoped>
.errorbox {
  border: 1.2px solid red;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 10px 10px 10px;
}
.error-msg {
  color: red;
  font-size: 12px;
}

.currentComponentChronology {
  background-color: #e6efd1;
}
</style>
<style>
.label-margin-0 label {
  margin-right: 0 !important;
}

.tooltip-listalignment {
  text-align: left;
  padding-left: 15px;
}

.tooltip-linepadding {
  padding-top: 5px;
}
</style>

<script>
import {
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbFormInline,
} from "mdbvue";

import ExpansionRegion from "@/components/ExpansionRegion";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import { mapGetters } from "vuex";
import awgtInput from "@/components/AWGT/AwgtInput";
import { sharedMethods } from "@/shared/shared.js";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import commercialProductLinePriceList from "./CommercialProductLinePriceList";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import productPriceAdjustments from "./ProductPriceAdjustments";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "CommercialProductLineEditDialog", //product-component-pricing-edit-dialog",
  components: {
    mdbIcon,
    ExpansionRegion,
    AwgtStdButton,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    awgtInput,
    AwgtStdDropdown,
    mdbWysiwyg,
    asoftDatePicker,
    mdbFormInline,
    asoftModalHeader,
    commercialProductLinePriceList,
    productPriceAdjustments,
  },

  data() {
    return {
      isDialogVisible: false,
      priceControlWithFocus: "",
      showEditPane: false,
      currentItemIdx: -1,
      showDeletionConfirmationModal: false,
      formValidationMessages: [],
      commercialProductLineChronology: {}, //Simply a pointer to commercialProductLine.Chronology[index]
      commercialProductLine: {},
      marketCategories: [],
    };
  },

  directives: {
    numeric: numericDirective,
  },

  props: {
    //Pass in the product line to be edited.
    value: {
      type: Object,
      required: true,
    },
    priceAdjustments: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      "getMarketCategories",
      "getEmptyCommercialProductLineChronology",
      "getEmptyPricingScaleType",
      "getTooltipsTextByCode",
      "getPricingScales",
      "getPricingScaleNameByCode",
      "getQuantityUnits",
      "getQuantityUnitPurposes",
      "getEmptyPricingScale",
      "getEmptyProductLinePrice",
    ]),

    getItemPricingScaleType() {
      return (pricingScaleTypes) => {
        for (let pst of pricingScaleTypes) {
          if (pst.QuantityUnitCd == "QU_I") {
            return pst;
          }
        }

        return null;
      };
    },

    /*
      Remove the unscaled pricing scale for 'number of' quantity units as they must have a scale.
    */
    getFilteredPricingScales() {
      return (quantityUnitPurpose) => {
        if (
          quantityUnitPurpose != undefined &&
          quantityUnitPurpose == "QUP_S"
        ) {
          let ps = this.$lodash.clone(this.getPricingScales());
          ps = ps.filter((s) => s.Code != "PS_U");
          return ps;
        } else return this.getPricingScales();
      };
    },

    getFilteredQuantityUnits() {
      return (pricingScaleTypes, currentQuantityUnit) => {
        let qu = this.$lodash.clone(this.getQuantityUnits());

        for (let pricingScaleType of pricingScaleTypes) {
          if (
            pricingScaleType.QuantityUnitCd != currentQuantityUnit &&
            pricingScaleType.QuantityUnitCd != null
          ) {
            //If a period quantity unit has been chosen in the past then
            //remove all period quantity units from the selection;
            //If a person/group quantity unit has been chosen in the past
            //then remove all person/group quantity units from the selection;
            //otherwise just remove the currently seelcted quantity unit.
            let quType = pricingScaleType.QuantityUnitCd.substr(3, 1);
            if (quType == "N" || quType == "D" || quType == "H")
              qu = qu.filter(
                (i) =>
                  i.Code.substr(3, 1) != "N" &&
                  i.Code.substr(3, 1) != "D" &&
                  i.Code.substr(3, 1) != "H"
              );
            else if (quType == "P" || quType == "G")
              qu = qu.filter(
                (i) => i.Code.substr(3, 1) != "P" && i.Code.substr(3, 1) != "G"
              );
            else
              qu = qu.filter(
                (i) =>
                  i.Code.substr(3, 1) !=
                  pricingScaleType.QuantityUnitCd.substr(3, 1)
              );
          }
        }

        return qu;
      };
    },

    /*
      This is common between edit-CommercialProductLineEditDialog and CommercialProductLinePriceList. Work out how to share computed methods.
    */
    getScaledPriceEntryTypeTooltip() {
      return (
        `<ul class='tooltip-listalignment'><li>Minimum Quantity: Minimum (from) quantity</li>` +
        `<li class='tooltip-linepadding'>Maximum Quantity: Maximum (up to) quantity</li>` +
        `<li class='tooltip-linepadding'>Range: Minimum (from) quantity to maximum (up to) quantity</li></ul>`
      );
    },

    arePricingScaleTypesDisabled() {
      return this.commercialProductLineChronology.ProductLinePrices.length > 0;
    },

    hasAPricingScale() {
      return (pricingScaleTypes) => {
        for (let pricingScaleType of pricingScaleTypes)
          if (pricingScaleType.PricingScaleCd != "PS_U") return true;

        return false;
      };
    },

    hasPerPricing() {
      return (pricingScaleTypes) => {
        for (let pricingScaleType of pricingScaleTypes)
          if (pricingScaleType.QuantityUnitPurposeCd == "QUP_P") return true;

        return false;
      };
    },

    /*     hasAnItemPriceDeterminant() {
      for (let priceDeterminant of this.PriceDeterminants)
        if (
          priceDeterminant.QuantityUnitCd == "QU_PI" ||
          priceDeterminant.QuantityUnitCd == "QU_NI"
        )
          return true;

      return false;
    }, */

    // getPricingMethodTooltip() {
    //   let singleQuantityUnitName = "";
    //   let singleUnitName = "";
    //   let multiUnitName = "";

    //   switch (this.commercialProductLineChronology.UnitType) {
    //     case "UT_I":
    //       singleUnitName = "item";
    //       multiUnitName = "items";
    //       break;
    //     case "UT_P":
    //       singleUnitName = "person";
    //       multiUnitName = "people";
    //       break;
    //     case "UT_G":
    //       singleUnitName = "group";
    //       multiUnitName = "groups";
    //       break;
    //     default:
    //       return (
    //         "getPricingMethodTooltip error (" +
    //         this.commercialProductLineChronology.UnitType +
    //         ")."
    //       );
    //   }

    //   switch (this.commercialProductLineChronology.QuantityUnitCd) {
    //     case "QU_I":
    //       singleQuantityUnitName = "item";
    //       break;
    //     case "QU_B":
    //       singleQuantityUnitName = "booking";
    //       break;
    //     case "QU_N":
    //       singleQuantityUnitName = "night";
    //       break;
    //     case "QU_D":
    //       singleQuantityUnitName = "day";
    //       break;
    //     case "QU_H":
    //       singleQuantityUnitName = "hour";
    //       break;
    //     default:
    //       return (
    //         "getPricingMethodTooltip error (" +
    //         this.commercialProductLineChronology.QuantityUnitCd +
    //         ")."
    //       );
    //   }

    //   if (this.commercialProductLineChronology.QuantityUnitCd == "QU_I") {
    //     return (
    //       `<ul class='tooltip-listalignment'><li>Singular Price: one price for a single ${singleUnitName}</li>` +
    //       `<li class='tooltip-linepadding'>Scaled Price by Unit: per ${singleUnitName} pricing, scaled by a minimum or maximum number of ${multiUnitName}</li>` +
    //       `<li class='tooltip-linepadding'>Bulk Price by Unit: prices for specified numbers of ${multiUnitName}</li></ul>`
    //     );
    //   } else if (this.commercialProductLineChronology.QuantityUnitCd == "QU_B") {
    //     return `<ul class='tooltip-listalignment'><li>Singular Price: one price for a single ${singleQuantityUnitName}</li>`;
    //   } else {
    //     return (
    //       `<ul class='tooltip-listalignment'><li>Singular Price: one price for a single ${singleQuantityUnitName}</li>` +
    //       `<li class='tooltip-linepadding'>Scaled Price by Unit: per ${singleUnitName} pricing, scaled by a minimum or maximum number of ${multiUnitName}</li>` +
    //       `<li class='tooltip-linepadding'>Scaled Price by Period: per ${singleQuantityUnitName} pricing, scaled by a minimum or maximum number of ${singleQuantityUnitName}s</li>` +
    //       `<li class='tooltip-linepadding'>Bulk Price by Unit: per ${singleQuantityUnitName} pricing for a specified number of ${multiUnitName}</li>` +
    //       `<li class='tooltip-linepadding'>Bulk Price by Period: per ${singleUnitName} pricing for a specified number of ${singleQuantityUnitName}s</li></ul>`
    //     );
    //   }
    // },
  },

  filters: {
    toDateString(value) {
      if (value == null) return "";
      return value;
    },
  },

  watch: {
    "commercialProductLineChronology.PriceTypeCd"(to, from) {
      if (to != from) {
        if (to == "PrT_Q" || to == "PrT_Z") {
          this.commercialProductLineChronology.CommercialProductLinePriceAdjustments.length = 0; //Clear the array
        }
      }
    },

    value: {
      immediate: true,
      handler: function (to) {
        //if (to != from) {
        this.commercialProductLine = this.$lodash.cloneDeep(to);
        this.commercialProductLineChronology =
          this.commercialProductLine.Chronology[0];
        //}
      },
    },
  },

  methods: {
    ...sharedMethods,

    onPricingScaleTypeQuantityUnitSelected(
      pricingScaleType,
      commercialProductLineChronology
    ) {
      if (pricingScaleType.QuantityUnitCd == "QU_I") {
        commercialProductLineChronology.SingularItemAlias = "item";
        commercialProductLineChronology.PluralItemAlias = "items";
      }
    },

    getPricingMethodTooltip(quantityUnit) {
      return sharedMethods.getPricingMethodTooltip(quantityUnit);
    },

    getPriceTypeStr(priceType) {
      switch (priceType) {
        case "PrT_I":
          return "(GST Incl)";
        case "PrT_E":
          return "(GST Excl)";
        case "PrT_F":
          return "(GST Free)";
        case "PrT_Q":
          return "Pricing is determined when ordering";
        case "PrT_Z":
          return "This item is free of charge";
        default:
          return "(GST Status Unknown!)";
      }
    },

    onDeletePricingScaleType(idx) {
      this.commercialProductLineChronology.PricingScaleTypes.splice(idx, 1);
    },

    onVisible() {
      /*
        When the dialog becomes visible, if their are no chronology items and the
        edit pane is not visible then run onAdd() which will create the first
        chronolgy item and open up the edit pane for it to be created.
      */
      if (
        this.isDialogVisible == false &&
        this.commercialProductLine.Chronology.length == 0 &&
        this.showEditPane == false
      ) {
        this.isDialogVisible = true;
        this.onAdd();
      }
    },

    onDelete(index) {
      this.currentItemIdx = index;

      this.showDeletionConfirmationModal = true;
    },

    InitFormContentAfterLoad(currentChronologyItem) {
      this.marketCategories = [];
      for (let i = 0; i < currentChronologyItem.MarketCategories.length; i++) {
        let categoryInd = this.getMarketCategories().findIndex((item) => {
          return (
            item.Code ==
            currentChronologyItem.MarketCategories[i].MarketCategoryId
          );
        });
        this.$set(this.marketCategories, categoryInd, true);
      }
    },

    onEdit(index) {
      this.currentItemIdx = index;
      this.formValidationMessages = [];

      this.commercialProductLineChronology = this.$lodash.cloneDeep(
        this.commercialProductLine.Chronology[this.currentItemIdx]
      );

      this.InitFormContentAfterLoad(
        this.commercialProductLine.Chronology[this.currentItemIdx]
      );

      this.showEditPane = true;
    },

    onAddPricingScaleType() {
      let pricingScaleType = this.$lodash.cloneDeep(
        this.getEmptyPricingScaleType
      );

      /*
        If price type is "no charge" or "by quote" then set
        Quantity Unit Purpose to "priced per" and Pricing Scale is "unscaled"
      */
      if (
        this.commercialProductLineChronology.PriceTypeCd == "PrT_Z" || //No Charge
        this.commercialProductLineChronology.PriceTypeCd == "PrT_Q" //By Quote
      ) {
        pricingScaleType.QuantityUnitPurposeCd = "QUP_P"; //Priced Per
        pricingScaleType.PricingScaleCd = "PS_U"; //Unscaled
      }

      pricingScaleType.SortOrder =
        this.commercialProductLineChronology.ProductLinePrices.length; // SortOrder is indexed from zero.
      this.commercialProductLineChronology.PricingScaleTypes.push(
        pricingScaleType
      );
    },

    onAdd() {
      this.currentItemIdx = -1;
      this.formValidationMessages = [];

      if (this.commercialProductLine.Chronology.length == 0) {
        this.commercialProductLineChronology = this.$lodash.cloneDeep(
          this.getEmptyCommercialProductLineChronology
        );

        //Initialise the price determinants with item and unscaled
        //These are the default settings on an empty price determinant
        /*         this.commercialProductLineChronology.PriceDeterminants.push(
          this.$lodash.cloneDeep(this.getEmptyProductLinePrice)
        ); */

        //Copy across the product price adjustments as default product line price adjustments
        for (let pa of this.priceAdjustments) {
          this.commercialProductLineChronology.CommercialProductLinePriceAdjustments.push(
            this.$lodash.cloneDeep(pa)
          );
        }
      } else {
        //Clone the last CommercialProductLineChronology record for the new record.
        let lastCommercialProductLineChronology = this.$lodash.maxBy(
          this.commercialProductLine.Chronology,
          (pcd) => {
            return pcd.CommercialProductLineChronologyId;
          }
        );

        this.commercialProductLineChronology = this.$lodash.cloneDeep(
          lastCommercialProductLineChronology
        );

        /*
          Provide the new record with its own CommercialProductLineChronologyId. It will be a temporary Id until
          the database allocates one. The Id needs to be higher than the last maximum id (so it is cloned next).
          As the Id may clash with other existing ProductComponentChronology records in existance, the RecordStatus
          must also be set to identify this record as a new record to be added to the table.
        */

        this.commercialProductLineChronology.CommercialProductLineChronologyId += 1;
        this.commercialProductLineChronology.RecordStatus = "Inserted";

        this.InitFormContentAfterLoad(lastCommercialProductLineChronology);
      }
      this.commercialProductLineChronology.ActiveFromDt = this.$moment(
        new Date().setHours(0, 0, 0, 0)
      ).format("YYYY-MM-DDTHH:mm:ss");
      this.commercialProductLineChronology.ActiveToDt = null;

      this.showEditPane = true;
    },

    doCommercialProductLineChronologyValidToDate_input(e) {
      //If e is valid then use it, else set it to null
      if (e) this.commercialProductLineChronology.ActiveToDt = e;
      else this.commercialProductLineChronology.ActiveToDt = null;
    },

    formatPriceString(chronology, price) {
      return sharedMethods.formatPriceWithPriceType(chronology, price);
    },

    CreateZeroProductLinePrice(productLinePrices) {
      if (productLinePrices.length == 0) {
        //Add a new record
        let productLinePrice = this.$lodash.cloneDeep(
          this.getEmptyProductLinePrice
        );
        let emptyPricingScale = this.$lodash.cloneDeep(
          this.getEmptyPricingScale
        );
        productLinePrice.PricingScales.push(emptyPricingScale);
        productLinePrices.push(productLinePrice);
      }

      if (productLinePrices.length > 1) {
        productLinePrices.splice(1, 999);
      }

      if (productLinePrices[0].PricingScales[0].length > 1) {
        productLinePrices[0].PricingScales.splice(1, 999);
      }

      let productLinePrice = productLinePrices[0];
      let pricingScale = productLinePrice.PricingScales[0];

      productLinePrice.ExGSTUnitAmt = 0;
      productLinePrice.GSTUnitAmt = 0;
      productLinePrice.TotalPrice = 0;

      pricingScale.QuantityUnitCd =
        this.commercialProductLineChronology.PricingScaleTypes[0].QuantityUnitCd;
      pricingScale.PricingScaleCd =
        this.commercialProductLineChronology.PricingScaleTypes[0].PricingScaleCd;
      pricingScale.QuantityUnitPurposeCd =
        this.commercialProductLineChronology.PricingScaleTypes[0].QuantityUnitPurposeCd;
      pricingScale.SortOrder =
        this.commercialProductLineChronology.PricingScaleTypes[0].SortOrder;
    },

    saveForm() {
      /*
        Setup zero pricing if the pricing is "no charge" or "by quote".
      */
      if (
        this.commercialProductLineChronology.PriceTypeCd == "PrT_Z" || //No Charge
        this.commercialProductLineChronology.PriceTypeCd == "PrT_Q" //By Quote
      ) {
        this.CreateZeroProductLinePrice(
          this.commercialProductLineChronology.ProductLinePrices
        );
      }
      /*
        Initialise the Market Categories structure
      */
      this.commercialProductLineChronology.MarketCategories = [];
      for (let i = 0; i < this.marketCategories.length; i++) {
        if (
          this.marketCategories[i] != undefined &&
          this.marketCategories[i] != false
        ) {
          this.commercialProductLineChronology.MarketCategories.push({
            MarketCategoryId: this.getMarketCategories()[i]?.Code,
            Name: this.getMarketCategories()[i]?.Name,
          });
        }
      }

      //Empty Max Quantities are returned as an empty string from the control, these
      //need to be converted to null on Save...
      for (let price of this.commercialProductLineChronology.ProductLinePrices)
        for (let scale of price.PricingScales)
          if (scale.MaxQuantity == "") scale.MaxQuantity = null;

      //If the index is -1 then this is a new value.
      if (this.currentItemIdx == -1) {
        let idx = this.$lodash.findIndex(
          this.commercialProductLine.Chronology,
          (pc) =>
            this.commercialProductLineChronology.ActiveToDt ??
            new Date("2999-12-31") < pc.ActiveFromDt /* max date */
        );
        if (idx == -1)
          this.commercialProductLine.Chronology.push(
            this.commercialProductLineChronology
          );
        else
          this.commercialProductLine.Chronology.splice(
            idx,
            0,
            this.commercialProductLineChronology
          );
      } else
        this.$set(
          this.commercialProductLine.Chronology,
          this.currentItemIdx,
          this.commercialProductLineChronology
        );
      this.showEditPane = false;
    },

    validate() {
      let messages = [];
      for (let pricingScaleType of this.commercialProductLineChronology
        .PricingScaleTypes)
        if (
          pricingScaleType.QuantityUnitPurposeCd == null ||
          pricingScaleType.QuantityUnitCd == null ||
          pricingScaleType.PricingScaleCd == null
        )
          messages.push(
            "Quantity Unit Purpose, Quantity Unit, and Pricing Scale must be provided."
          );

      for (let price of this.commercialProductLineChronology
        .ProductLinePrices) {
        if (price.ExGSTUnitAmt == null || price.GSTUnitAmt == null)
          messages.push("Pricing must be provided.");

        for (let scale of price.PricingScales) {
          if (scale.PricingScaleCd == "PS_R" || scale.PricingScaleCd == "PS_M")
            if (scale.MinQuantity == null || scale.MinQuantity.length == 0)
              messages.push("Min Quantity must be provided.");

          if (scale.PricingScaleCd == "PS_X")
            if (scale.MaxQuantity == null || scale.MaxQuantity.length == 0)
              messages.push("Max Quantity must be provided.");
        }
      }

      if (this.marketCategories.length == 0)
        messages.push("At least one item should be selected.");

      if (this.commercialProductLineChronology.Name.trim().length == 0)
        messages.push("A name must be supplied.");

      if (this.commercialProductLineChronology.ActiveFromDt == null)
        messages.push("A from date must be supplied.");

      if (
        this.commercialProductLineChronology.ActiveToDt <
        this.commercialProductLineChronology.ActiveFromDt
      )
        messages.push("To date must be on or after from date.");

      if (
        this.checkForDuplicateQuantities(this.commercialProductLineChronology)
      ) {
        messages.push("Quantity range overlap in pricing.");
      }

      return messages;
    },

    checkForDuplicateQuantities(commercialProductLineChronology) {
      let valuesAlreadySeen = new Array();
      if (
        commercialProductLineChronology.PricingScaleTypes.length == 1 &&
        commercialProductLineChronology.PricingScaleTypes[0].PricingScaleCd ==
          "PS_U"
      )
        return false;

      for (
        let i = 0;
        i < commercialProductLineChronology.ProductLinePrices.length;
        i++
      ) {
        let valuesOfPricingScales = new Array();

        for (const pricingScale of commercialProductLineChronology.ProductLinePrices[
          i
        ].PricingScales.sort((psOne, psTwo) =>
          psOne.QuantityUnitCd.localeCompare(psTwo.QuantityUnitCd)
        )) {
          valuesOfPricingScales.push({
            quantityUnit: pricingScale.QuantityUnitCd,
            quantityUnitPurpose: pricingScale.QuantityUnitPurposeCd,
            pricingScale: pricingScale.PricingScaleCd,
            min: pricingScale.MinQuantity,
            max: pricingScale.MaxQuantity,
          });
        }

        if (
          this.checkOverlapInValueAgainstValuesAlreadySeen(
            valuesOfPricingScales,
            valuesAlreadySeen
          ) == true
        )
          return true;
      }
      return false;
    },

    checkOverlapInValueAgainstValuesAlreadySeen(
      valuesOfPricingScales,
      valuesAlreadySeen
    ) {
      for (let i = 0; i < valuesOfPricingScales.length; i++) {
        let pricingScaleForTesting = valuesOfPricingScales[i];

        for (let valueAlreadySeen of valuesAlreadySeen) {
          let IsMatch = true;

          for (let j = 0; j < valuesOfPricingScales.length; j++) {
            if (i == j) continue;

            if (
              valuesOfPricingScales[j].min != valueAlreadySeen[j].min ||
              valuesOfPricingScales[j].max != valueAlreadySeen[j].max
            ) {
              IsMatch = false;
              break; //There is no match
            }
          }

          if (IsMatch == true) {
            if (
              this.checkOverlapInPricingScaleForTestingAndPricingScaleAlreadySeen(
                pricingScaleForTesting,
                valueAlreadySeen[i]
              ) == true
            )
              return true;
          }
        }
      }

      valuesAlreadySeen.push(valuesOfPricingScales);

      return false;
    },

    checkOverlapInPricingScaleForTestingAndPricingScaleAlreadySeen(
      pricingScaleForTesting,
      pricingScaleAlreadySeen
    ) {
      switch (pricingScaleForTesting.pricingScale) {
        case "PS_M": {
          if (pricingScaleAlreadySeen.min == pricingScaleForTesting.min)
            return true;

          break;
        }

        case "PS_X": {
          if (pricingScaleAlreadySeen.max == pricingScaleForTesting.max)
            return true;

          break;
        }

        case "PS_R": {
          //A minimum quantity must be specified for range pricing.

          if (pricingScaleForTesting.min == pricingScaleAlreadySeen.min)
            return true; //Duplicate value, this is an error!

          if (pricingScaleForTesting.min < pricingScaleAlreadySeen.min) {
            if (
              (pricingScaleForTesting.max != undefined &&
                pricingScaleForTesting.max >= pricingScaleAlreadySeen.min) ||
              pricingScaleForTesting.max == undefined
            )
              return true; //Overlap
          }

          if (pricingScaleForTesting.min > pricingScaleAlreadySeen.min) {
            if (
              (pricingScaleAlreadySeen.max != undefined &&
                pricingScaleAlreadySeen.max >= pricingScaleForTesting.min) ||
              pricingScaleAlreadySeen.max == undefined
            )
              return true; //Overlap
          }
          break;
        }
      }
      return false;
    },

    isDateOverlap() {
      let c = this.commercialProductLine.Chronology;
      let chronologyLength = c.length;

      for (let i = 0; i < chronologyLength; i++) {
        for (let j = i + 1; j < chronologyLength; j++) {
          if (
            (c[i].ActiveFromDt >= c[j].ActiveFromDt &&
              c[i].ActiveFromDt <= (c[j].ActiveToDt ?? "31 Dec 2999")) ||
            ((c[i].ActiveToDt ?? "31 Dec 2999") >= c[j].ActiveFromDt &&
              (c[i].ActiveToDt ?? "31 Dec 2999") <=
                (c[j].ActiveToDt ?? "31 Dec 2999"))
          )
            return true;
        }
      }
      return false;
    },

    onSave() {
      this.formValidationMessages = this.validate();

      //Only save if the form is valid
      if (this.formValidationMessages.length == 0) this.saveForm();
      else {
        let errorMessagesElement = this.$refs.ErrorMessages;
        errorMessagesElement.scrollIntoView(true);
      }
    },

    onClose() {
      if (this.commercialProductLine.Chronology.length == 0) {
        this.commercialProductLine.RecordStatus = "deleted";
      }
      this.closeDialog();
    },

    onCancel() {
      if (this.commercialProductLine.Chronology.length == 0) this.closeDialog();
      else this.showEditPane = false;
    },

    onConfirmDeletePricingComponent() {
      this.commercialProductLine.Chronology.splice(this.currentItemIdx, 1);
      this.currentItemIdx = -1;
      this.showDeletionConfirmationModal = false;
      this.showEditPane = false;
      if (this.commercialProductLine.Chronology.length == 0) {
        this.commercialProductLine.RecordStatus = "deleted";
        this.closeDialog();
      }
    },

    closeDialog() {
      this.$emit("input", this.commercialProductLine);
      this.$emit("close");
      this.isDialogVisible = false;
    },

    onCancelDeletePricingComponent() {
      this.showDeletionConfirmationModal = false;
    },
  },
  created() {
    this.marketCategories = [];
  },
};
</script>
