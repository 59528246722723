import Vue from "vue";
import Router from "vue-router";
import Home from "./views/home.vue";
import tokenManager from "@/shared/securityTokenManager.js";
import permission from "@/shared/permission.js";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: [
    //Home
    {
      path: "/",
      component: Home,
      meta: {
        breadcrumb: "Home",
        requiresAuth: false,
      },
    },

    //Travel Bookings
    {
      path: "/booking",
      component: () => import("./views/booking/search.vue"),
      meta: {
        //git test
        breadcrumb: "Travel Booking Search",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/add",
      component: () => import("./views/booking/edit.vue"),
      meta: {
        breadcrumb: "Travel Booking Add",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/:bookingReference/edit",
      component: () => import("./views/booking/edit.vue"),
      meta: {
        breadcrumb: "Travel Booking Edit",
        requiresAuth: true,
      },
    },

    //Itineraries
    {
      path: "/booking/:bookingReference/itinerary/list",
      component: () => import("./views/itinerary/list.vue"),
      meta: {
        breadcrumb: "Itinerary List",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/:bookingReference/itinerary/add",
      component: () => import("./views/itinerary/edit.vue"),
      meta: {
        breadcrumb: "Itinerary Add",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/:bookingReference/itinerary/:itineraryReference/edit",
      component: () => import("./views/itinerary/edit.vue"),
      meta: {
        breadcrumb: "Itinerary Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/:bookingReference/itinerary/:itineraryReference/itineraryentries",
      component: () => import("./views/itinerary/editEntries.vue"),
      meta: {
        breadcrumb: "Itinerary Entries",
        requiresAuth: true,
      },
    },

    //Itinerary Orders
    {
      path: "/booking/:bookingReference/itinerary/:itineraryReference/itineraryentries/orders/add",
      component: () => import("./views/itinerary/order/orderEntry.vue"),
      meta: {
        breadcrumb: "Itinerary Entries",
        requiresAuth: true,
      },
    },
    {
      path: "/booking/:bookingReference/itinerary/:itineraryReference/itineraryentries/orders/:orderReference/edit",
      component: () => import("./views/itinerary/order/orderEntry.vue"),
      meta: {
        breadcrumb: "Itinerary Entries",
        requiresAuth: true,
      },
    },

    //Products
    {
      path: "/product",
      component: () => import("./views/product/search.vue"),
      meta: {
        breadcrumb: "Product search",
        requiresAuth: true,
      },
    },
    {
      path: "/product/:productReference/edit",
      component: () => import("./views/product/edit.vue"),
      meta: {
        breadcrumb: "Product Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/product/add",
      component: () => import("./views/product/edit.vue"),
      meta: {
        breadcrumb: "Product Add",
        requiresAuth: true,
      },
    },

    //Suppliers
    {
      path: "/supplier",
      component: () => import("./views/supplier/search.vue"),
      meta: {
        breadcrumb: "Supplier Search",
        requiresAuth: true,
      },
    },
    {
      path: "/supplier/:supplierReference/edit",
      component: () => import("./views/supplier/edit.vue"),
      meta: {
        breadcrumb: "Supplier Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/supplier/add",
      component: () => import("./views/supplier/edit.vue"),
      meta: {
        breadcrumb: "Supplier Add",
        requiresAuth: true,
      },
    },

    //Clients
    {
      path: "/client/:clientReference/edit",
      component: () => import("./views/client/edit.vue"),
      meta: {
        breadcrumb: "Client Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/client/add",
      component: () => import("./views/client/edit.vue"),
      meta: {
        breadcrumb: "Client Add",
        requiresAuth: true,
      },
    },
    {
      path: "/client",
      component: () => import("./views/client/search.vue"),
      meta: {
        breadcrumb: "Client Search",
        requiresAuth: true,
      },
    },

    //Country Regions
    {
      path: "/admin/countryregions",
      component: () => import("./views/admin/countryRegions/edit.vue"),
      meta: {
        breadcrumb: "Manage Country Regions",
        requiresAuth: true,
      },
    },

    //Issues
    {
      path: "/admin/issue",
      component: () => import("./views/admin/issue/search.vue"),
      meta: {
        breadcrumb: "Issue Search",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/issue/:issueReference/edit",
      name: "toedit",
      component: () => import("./views/admin/issue/edit.vue"),
      props: true,
      meta: {
        breadcrumb: "Issue Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/issue/add",
      component: () => import("./views/admin/issue/edit.vue"),
      props: true,
      meta: {
        breadcrumb: "Issue Add",
        requiresAuth: true,
      },
    },

    //Users
    {
      path: "/admin/user",
      name: "user",
      component: () => import("./views/admin/user/list.vue"),
      meta: {
        breadcrumb: "User List",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/user/add",
      component: () => import("./views/admin/user/edit.vue"),
      meta: {
        breadcrumb: "User Add",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/user/:userReference/edit",
      component: () => import("./views/admin/user/edit.vue"),
      meta: {
        breadcrumb: "User Edit",
        requiresAuth: true,
      },
    },

    //Workgoup
    {
      path: "/admin/workgroup",
      name: "workgroup",
      component: () => import("./views/admin/workgroup/list.vue"),
      meta: {
        breadcrumb: "Manage Workgroup",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/workgroup/:workgroupReference/edit",
      component: () => import("./views/admin/workgroup/edit.vue"),
      meta: {
        breadcrumb: "Edit Workgroup",
        requiresAuth: true,
      },
    },
    {
      path: "/admin/workgroup/add",
      component: () => import("./views/admin/workgroup/edit.vue"),
      meta: {
        breadcrumb: "Add Workgroup",
        requiresAuth: true,
      },
    },
    {
      path: "/user/resetpassword/:token",
      component: () => import("./views/resetPassword.vue"),
      meta: {
        breadcrumb: "Reset Password",
        requiresAuth: false,
      },
    },
    {
      path: "/user/retrieveusername/:token",
      component: Home,
      meta: {
        requiresAuth: false,
      },
    },

    //System Action
    {
      path: "/admin/systemaction",
      component: () => import("./views/admin/systemAction/search.vue"),
      meta: {
        breadcrumb: "System Action Search",
        requiresAuth: true,
      },
    },
    //UserImpersonation
    {
      path: "/admin/userimpersonation",
      component: () =>
        import("./views/admin/UserImpersonation/UserImpersonation.vue"),
      meta: {
        breadcrumb: "User Impersonation",
        requiresAuth: true,
      },
    },

    //System Reports
    {
      path: "/report/SchoolStatisticsAndDetailsReport",
      component: () =>
        import("./views/report/SchoolStatisticsAndDetailsReport.vue"),
      meta: {
        breadcrumb: "School Statistics and Details Report",
        requiresAuth: true,
      },
    },
    {
      path: "/report/TravelBookingsReport",
      component: () => import("./views/report/TravelBookingsReport.vue"),
      meta: {
        breadcrumb: "Supplier/Travel Bookings Report",
        requiresAuth: true,
      },
    },
    {
      path: "/report/SupplierProductInformation",
      component: () => import("./views/report/SupplierProductInformation.vue"),
      meta: {
        breadcrumb: "Supplier/Product Information",
        requiresAuth: true,
      },
    },
    {
      path: "/report/UserActivityReport",
      component: () => import("./views/report/UserActivityReport.vue"),
      meta: {
        breadcrumb: "User Activity Report",
        requiresAuth: true,
      },
    },
    {
      path: "/report/YearlyMatrix",
      component: () => import("./views/report/YearlyMatrix.vue"),
      meta: {
        breadcrumb: "Yearly Matrix",
        requiresAuth: true,
      },
    },
    {
      path: "/report/ItineraryEntriesReport",
      component: () => import("./views/report/ItineraryEntriesReport.vue"),
      meta: {
        breadcrumb: "Itinerary Entries Report",
        requiresAuth: true,
      },
    },
    //Communication
    {
      path: "/communication",
      component: () => import("./views/communication/search.vue"),
      meta: {
        breadcrumb: "Communication Search",
        requiresAuth: true,
      },
    },
    {
      path: "/communication/add",
      component: () => import("./views/communication/edit.vue"),
      meta: {
        breadcrumb: "Communication Add",
        requiresAuth: true,
      },
    },
    {
      path: "/communication/:communicationReference/edit",
      component: () => import("./views/communication/edit.vue"),
      meta: {
        breadcrumb: "Communication Edit",
        requiresAuth: true,
      },
    },
    //Tooltips
    {
      path: "/admin/tooltips",
      component: () => import("./views/admin/tooltips/list.vue"),
      meta: {
        breadcrumb: "Tooltips",
        requiresAuth: true,
      },
    },
    //Forbidden
    {
      path: "/forbidden",
      component: () => import("./components/ForbiddenPage.vue"),
      meta: {
        breadcrumb: "Forbidden access",
        requiresAuth: true,
      },
    },
    //Everything else, a generic catch
    {
      path: "*",
      redirect: { path: "/" },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let tokenExistsInd = await tokenManager.doesAccessTokenExistAsync();
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (tokenExistsInd == true) {
      let userPermission = await tokenManager.getUserPermissionAsync();

      if (permission.checkPermission(userPermission, to) == false)
        return next("/forbidden");

      return next();
    } else return next("/");
  }

  return next();
});

export default router;
