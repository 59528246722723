/*
  This is a Vue Directive mask used for providing mask functionality to input controls suchas
  the awgtInput control. The mask is derived from the Vue-R-Mask found here:
  https://github.com/raidan00/vue-r-mask. The mask has been extended to allow both
  raw (unformatted), formatted and masked (formatted with mask '_') output. The result of the
  mask (both raw, formatted and masked strings) are returned via the mask-input event.

  The mask can be initiated with raw and formatted output. Once set the input event
  is called directly on the input control to initiate the formatting loop, this converts raw
  output to formatted output, it does not destroy formatted output. From that point the mask
  directly updates the control's value and the raw, formatted and masked results are returned
  via the event.
*/

/*
  Setup the directive, binding the mask to the input event of the input control. The input event
  fires whenever a character is changed.
*/
export default {
  bind(el, bindings, vnode) {
    /*
        Find the first input element in the control that this directive is attached to. In most
        cases the control will be an input element, but in some cases the control's input
        element will be wrapped (e.g. by a div element).
      */
    let inputElement = el.getElementsByTagName("INPUT")[0];
    bind(inputElement, bindings, vnode);
  },

  /*
      The update statement reinitialises the control if either the mask or value changes. The
      value will change if the associated control is reinitialised with a new value.
    */
  update(el, bindings, vnode) {
    //If nothing has changed then leave.
    if (
      bindings.value.mask.toString() == bindings.oldValue.mask.toString() &&
      bindings.value.value === bindings.oldValue.value
    )
      return;
    // Find the first input element in the control this directive is attached to.
    let inputElement = el.getElementsByTagName("INPUT")[0];
    inputElement.removeEventListener("input", vnode.context.asoftmask);
    bind(inputElement, bindings, vnode);
  },
};

// Get the caret position
function getCaret(input) {
  var CaretPos = 0;
  if (input.selectionStart || input.selectionStart == 0) {
    // Standard
    CaretPos = input.selectionStart;
  } else if (document.selection) {
    // Legacy IE
    var Sel = document.selection.createRange();
    Sel.moveStart("character", -input.value.length);
    CaretPos = Sel.text.length;
  }
  return CaretPos;
}

// Set the caret position
function setCaret(input, pos) {
  if (input && input.setSelectionRange) {
    // Standard
    input.setSelectionRange(pos, pos);
  } else if (input.createTextRange) {
    // Legacy IE
    var range = input.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
}

// Bind the input event to the initMask function that initialises the mask formatting
// function and formats the input value whenever the input changes.
function bind(el, bindings, vnode) {
  if (vnode.data.on && vnode.data.on.input && vnode.data.on.input._wrapper) {
    el.removeEventListener("input", vnode.data.on.input._wrapper);
  }
  if (bindings.value == undefined || bindings.value.mask == "") return;
  el.value = bindings.value.value;
  let maskFunc = initMask(el, bindings, vnode);
  el.addEventListener("input", maskFunc);
  vnode.context.asoftmask = maskFunc;
  if (vnode.data.on && vnode.data.on.input && vnode.data.on.input._wrapper) {
    el.addEventListener("input", vnode.data.on.input._wrapper);
  }

  // Binding of the directive happens at control creation time, i.e. before the control
  // is rendered to the screen. Wait for the next tick to occur, which will allow the
  // control to be rendered, before initialising it's mask and value.
  vnode.context.$nextTick(() => {
    el.dispatchEvent(new Event("input"));
  });
}

function initMask(el, bindings, vnode) {
  let frame = [];
  let str = bindings.value.mask.toString().slice(1, -1);
  let reg = /(?:((?:\\.)|(?:\[.+?\]))\{[\d,]+\})|\\.|./g,
    match;
  while ((match = reg.exec(str))) {
    let toPush = {};
    if (match[0].length <= 2) {
      toPush.minLen = 1;
      toPush.maxLen = 1;
      toPush.type = "single";
      toPush.char = match[0][match[0].length - 1];
      toPush.reg = new RegExp(match[0] + "+");
    } else {
      toPush.minLen = +/\{(\d+)/.exec(match[0])[1];
      toPush.maxLen = +/(\d+)\}/.exec(match[0])[1];
      toPush.type = "input";
      toPush.reg = new RegExp("(_|" + match[1] + ")+");
    }
    frame.push(toPush);
  }
  return function () {
    //let forTests = { before: this.value.slice(0, caret.get(this)) + '|' + this.value.slice( caret.get(this) )};

    if (this.value == undefined || (this.value === "" && this.placeholder)) {
      return;
    }

    let arr = this.value.split("").map((e) => {
      return { char: e, type: "char" };
    });
    let pos = { char: "", type: "pos" };
    arr.splice(getCaret(this), 0, pos);
    let newVal = { raw: [], formatted: [], masked: [] };
    mainLoop: for (let i = 0; i < frame.length; i++) {
      for (let k = 0; k < frame[i].maxLen; k++) {
        //Ignore the position marker
        if (arr[0] && arr[0].type == "pos") {
          //arr.shift();
          let c = arr.shift();
          newVal.masked.push(c);
          //newVal.formatted.push(c);
          k--;
          continue;
        }

        if (arr[0] == undefined) {
          if (frame[i].type == "single") {
            newVal.masked.push({ char: frame[i].char });
            newVal.formatted.push({ char: frame[i].char });
          } else {
            if (k >= frame[i].minLen) {
              continue mainLoop;
            }
            newVal.masked.push({ char: "_" });
          }
          continue;
        }

        if (frame[i].reg.exec(arr[0].char)) {
          //TODO: Remove this statement, I don't think it does anything!
          // if (k >= frame[i].minLen && arr[0].char == "_") {
          //   continue mainLoop;
          // }
          let c = arr.shift();
          newVal.masked.push(c);
          newVal.formatted.push(c.char == "_" ? { char: " " } : c);
          if (frame[i].type == "input") {
            if (c.char != "_") {
              newVal.raw.push(c);
            }
          }
        } else {
          if (frame[i].type == "single") {
            newVal.masked.push({ char: frame[i].char });
            newVal.formatted.push({ char: frame[i].char });
          } else {
            if (k >= frame[i].minLen) {
              continue mainLoop;
            }
            arr.shift();
            k--;
          }
        }
      }
    }

    this.value = newVal.masked.map((e) => e.char).join("");
    setCaret(this, newVal.masked.indexOf(pos));

    vnode.context.$emit("mask-input", {
      raw: newVal.raw.map((e) => e.char).join(""),
      masked: newVal.masked.map((e) => e.char).join(""),
      formatted: newVal.formatted
        .map((e) => e.char)
        .join("")
        .replace(/\s+$/, ""), //remove trailing space
    });
    //console.log(newVal);

    //forTests.after = this.value.slice(0, caret.get(this)) + '|' + this.value.slice( caret.get(this) );
    //console.log(forTests);
  };
}
