var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "App" } }, [
    _c(
      "div",
      [
        _vm.logonDialog == true
          ? _c(
              "div",
              { staticClass: "LogOnForm" },
              [
                _c("asoft-logOn", {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                  attrs: {
                    message: _vm.logonDialogMessage,
                    retrievedUsername: _vm.retrievedUsername,
                  },
                  on: { input: _vm.onLogonComplete },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "header",
          [
            _c(
              "awgt-nav-bar",
              {
                staticStyle: {
                  height: "48px",
                  "padding-top": "3px",
                  "padding-bottom": "3px",
                },
                attrs: { dark: "", position: "top", toggler: false },
              },
              [
                _vm.logonDialog == false
                  ? _c("div", { staticClass: "d-flex w-100" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-nowrap align-items-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-nowrap align-items-center display-when-wide",
                            },
                            [
                              _c(
                                "mdbNavbarNav",
                                [
                                  _vm._l(_vm.menuItems, function (menuItem) {
                                    return [
                                      _c("awgt-menu-item", {
                                        key: menuItem.title,
                                        attrs: { menuItem: menuItem },
                                      }),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-nowrap align-items-center display-when-narrow",
                            },
                            [
                              _c("div", { staticClass: "float-left" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { role: "button", id: "nav-toggle" },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: {
                                        fas: "",
                                        icon: "bars",
                                        size: "lg",
                                        color: "white",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.toggleLeftSideNav.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "ml-4 navbar-text" },
                                  [_vm._v("Travel Manager")]
                                ),
                              ]),
                              _c(
                                "mdb-side-nav",
                                {
                                  attrs: {
                                    OpenedFromOutside: _vm.toggleMenu,
                                    hidden: "",
                                    waves: "",
                                    sideNavStyle: "top: 48px;",
                                  },
                                  on: {
                                    "update:OpenedFromOutside": function (
                                      $event
                                    ) {
                                      _vm.toggleMenu = $event
                                    },
                                    "update:opened-from-outside": function (
                                      $event
                                    ) {
                                      _vm.toggleMenu = $event
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "mdbSideNavNav",
                                    [
                                      _vm._l(
                                        _vm.menuItems,
                                        function (menuItem) {
                                          return [
                                            menuItem.menuItems.length == 0
                                              ? _c(
                                                  "mdb-side-nav-item",
                                                  {
                                                    key: menuItem.title,
                                                    attrs: {
                                                      id:
                                                        "nav-" + menuItem.title,
                                                      to: {
                                                        path: menuItem.routePath,
                                                      },
                                                      icon: menuItem.icon,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(menuItem.title)
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "mdb-side-nav-cat",
                                                  {
                                                    key: menuItem.title,
                                                    attrs: {
                                                      id:
                                                        "nav-" + menuItem.title,
                                                      icon: menuItem.icon,
                                                      name: menuItem.title,
                                                    },
                                                  },
                                                  _vm._l(
                                                    menuItem.menuItems,
                                                    function (subMenuItem) {
                                                      return _c(
                                                        "mdb-side-nav-item",
                                                        {
                                                          key: subMenuItem.title,
                                                          attrs: {
                                                            id:
                                                              "nav-" +
                                                              menuItem.title +
                                                              "-" +
                                                              subMenuItem.title,
                                                            to: {
                                                              path: subMenuItem.routePath,
                                                            },
                                                            header: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subMenuItem.title
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-nowrap align-items-center ml-auto",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-2 tip" },
                            [
                              _c("input", {
                                ref: "referenceSearch",
                                staticClass: "form-control",
                                attrs: {
                                  type: "search",
                                  placeholder: "Search",
                                  "aria-label": "Search",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                    return _vm.onKeyDownBarSearch.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _c("transition", { attrs: { name: "fade" } }, [
                                _vm.isSearchBarError
                                  ? _c("span", { staticClass: "tiptext" }, [
                                      _vm._v(_vm._s(_vm.searchBarErrorMessage)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "mdb-dropdown",
                            {
                              staticClass: "nav-item avatar",
                              attrs: { tag: "li", anchorClass: "p-0" },
                            },
                            [
                              _c(
                                "mdb-dropdown-toggle",
                                {
                                  attrs: {
                                    slot: "toggle",
                                    tag: "a",
                                    navLink: "",
                                    color: "stylish",
                                    "waves-fixed": "",
                                  },
                                  slot: "toggle",
                                },
                                [
                                  _vm.currentUser
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "userCircle",
                                          on: {
                                            click:
                                              _vm.onToggleUserProfileDropDown,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.currentUser.FirstName[0]}${_vm.currentUser.Surname[0]}`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "mdb-dropdown-menu",
                                { staticClass: "dropdown-menu-right" },
                                [
                                  _c(
                                    "mdb-dropdown-item",
                                    { on: { click: _vm.onLogout } },
                                    [_c("span", [_vm._v(" Logout")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "mdb-breadcrumb",
              {
                staticStyle: {
                  height: "30px",
                  top: "48px",
                  width: "100%",
                  position: "fixed",
                  "z-index": "1000",
                  "padding-bottom": "0",
                  "margin-bottom": "0",
                  "border-radius": "0",
                },
              },
              [
                _vm._l(_vm.breadcrumbs, function (breadcrumb, index) {
                  return [
                    _c(
                      "mdb-breadcrumb-item",
                      {
                        key: index,
                        staticStyle: { "margin-top": "-5px" },
                        attrs: {
                          active:
                            index + 1 == _vm.breadcrumbs.length ? true : false,
                        },
                      },
                      [
                        index + 1 == _vm.breadcrumbs.length
                          ? _c("span", [_vm._v(_vm._s(breadcrumb.text))])
                          : _c(
                              "router-link",
                              { attrs: { to: breadcrumb.href } },
                              [_vm._v(_vm._s(breadcrumb.text))]
                            ),
                      ],
                      1
                    ),
                    index + 1 != _vm.breadcrumbs.length
                      ? _c("i", {
                          key: "i" + index,
                          staticClass: "fas fa-caret-right mx-2",
                          staticStyle: { "margin-top": "-2px" },
                          attrs: { "aria-hidden": "true" },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "main",
          { staticStyle: { "margin-top": "80px" } },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              (_vm.remainMinutes > 0 && _vm.remainMinutes <= 5) ||
              (_vm.remainMinutes == 0 && _vm.remainSeconds != 0)
                ? _c(
                    "div",
                    {
                      staticClass: "top-message",
                      staticStyle: { "z-index": "1000" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "savemessage-success" },
                        [
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "15px" } },
                              [
                                _c("mdb-icon", {
                                  staticClass: "color-success",
                                  attrs: { icon: "far fa-stopwatch" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `Your system will close automatically in ${_vm
                                        .$moment({
                                          minutes: _vm.remainMinutes,
                                          seconds: _vm.remainSeconds,
                                        })
                                        .format("mm:ss")}`
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.headerMessage.Message.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "top-message",
                      on: {
                        click: function ($event) {
                          return _vm.onSuccessErrorPaneClose()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.headerMessage.isSuccessInd == true
                              ? "savemessage-success"
                              : "savemessage-error",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "float-right",
                              class:
                                _vm.headerMessage.isSuccessInd == true
                                  ? "color-success"
                                  : "color-error",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.headerMessage.isSuccessInd == true
                                      ? `${_vm.timer}`
                                      : null
                                  ) +
                                  " "
                              ),
                              _c("mdb-icon", {
                                staticClass: "p-2 mr-0",
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  far: "",
                                  size: "1x",
                                  icon: "times-circle",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onSuccessErrorPaneClose()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.headerMessage.isSuccessInd == true
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "15px" } },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "color-success",
                                      attrs: { icon: "check-circle" },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.headerMessage.Message[0]
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "15px" } },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "color-error",
                                      attrs: { icon: "exclamation-triangle" },
                                    }),
                                    _vm._v(
                                      " Please correct the following errors: "
                                    ),
                                    _c(
                                      "ul",
                                      { staticStyle: { margin: "0px" } },
                                      _vm._l(
                                        _vm.headerMessage.Message,
                                        function (e, idx) {
                                          return _c("li", { key: idx }, [
                                            _vm._v(" " + _vm._s(e) + " "),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("router-view", {
              staticStyle: { "margin-top": "100px" },
              attrs: { onSavePromptExit: _vm.onSavePromptHandler },
              on: { "header-message": _vm.displayHeaderMessage },
            }),
          ],
          1
        ),
        _c("awgt-footer"),
        _c("asoftPrompt", {
          ref: "exitBeforeSavePrompt",
          attrs: {
            headerClasses: "p-3 showExitBeforeSavePromptHeader",
            bodyClasses: "px-3 pt-3",
            footerClasses: "px-3 pb-3",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(" Exit without saving? ")]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm._v(" You have unsaved changes. "),
                  _c("br"),
                  _c("br"),
                  _vm._v(" Exit? "),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function ({ onClick }) {
                return [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end align-center" },
                    [
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return onClick("Save")
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "check" },
                          }),
                          _vm._v("Save "),
                        ],
                        1
                      ),
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return onClick("Exit")
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "ban" },
                          }),
                          _vm._v("Don't Save "),
                        ],
                        1
                      ),
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return onClick("Stay")
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "times" },
                          }),
                          _vm._v("Cancel "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showExitBeforeSavePromptInd,
            callback: function ($$v) {
              _vm.showExitBeforeSavePromptInd = $$v
            },
            expression: "showExitBeforeSavePromptInd",
          },
        }),
      ],
      1
    ),
    _vm.getAxiosCallLoaderStatus
      ? _c("div", { staticClass: "loader-screen" }, [
          _c(
            "div",
            { staticClass: "loader" },
            [
              _c("mdb-spinner", { attrs: { big: "", color: "green" } }),
              _c("br"),
              _vm._v(" Loading... "),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }