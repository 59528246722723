<template>
  <div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
    >
      <slot name="default"></slot>
      <awgt-std-button
        type="button"
        class="command-button"
        style="min-width: 100px"
        @click="() => (this.showCategoryEditor = true)"
      >
        <mdb-icon icon="plus" class="mr-1" /> Add
      </awgt-std-button>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <template v-for="(category, categoryIndex) in SupplierFileCategories">
        <div
          v-if="
            (category.RecordStatus != 'Deleted' &&
              supplierFileCategoryType == 'Any') ||
            category.SupplierFileCategoryTypeCd == supplierFileCategoryType
          "
          class="file-box ml-3 my-2"
          v-bind:key="categoryIndex"
        >
          <div class="box-top d-flex align-items-center">
            <span class="ml-2">{{ category.Name }} </span>
            <span
              v-if="!category.IsMandatoryInd"
              @click="onEditCategory(categoryIndex)"
            >
              <mdb-icon
                color="primary"
                class="ml-2"
                style="cursor: pointer"
                icon="edit"
                far
              />
            </span>

            <div class="flex-grow-1"></div>
            <awgt-std-button
              @click="onAddFileToCategory(categoryIndex)"
              class="command-button mr-4"
            >
              <mdb-icon icon="plus" far />
              Add
            </awgt-std-button>
          </div>

          <div
            v-if="category.IsMandatoryInd == false"
            class="cross-icon d-flex flex-row justify-content-center"
            style="cursor: pointer"
            @click="onDeleteCategory(categoryIndex)"
          >
            <mdb-icon
              icon="times"
              far
              color="black"
              class="mx-2 mb-2"
              size="2x"
            />
          </div>

          <div
            v-for="(file, fileIndex) in category.SupplierFiles"
            v-bind:key="fileIndex"
            class="mx-2"
          >
            <template v-if="file.RecordStatus != 'Deleted'">
              <span v-if="file.RecordStatus == 'Inserted'" style="color: red">{{
                file.DisplayName
              }}</span>
              <span v-else @click="onDownload(file)" class="file-link">{{
                file.DisplayName
              }}</span>
              <span
                style="cursor: pointer"
                @click="onEditFile(categoryIndex, fileIndex)"
                ><mdb-icon
                  icon="pencil"
                  far
                  color="black"
                  class="mx-2 mb-2"
                  size="1x"
              /></span>
              <span
                style="cursor: pointer"
                @click="onDeleteFile(category, fileIndex)"
                ><mdb-icon
                  icon="times"
                  far
                  color="black"
                  class="mx-2 mb-2"
                  size="1x"
              /></span>
            </template>
          </div>
          <div class="mt-4"></div>
        </div>
      </template>
    </div>

    <!-- File Entry Editor -->
    <mdb-modal :show="showFileEntryEditor">
      <asoft-modal-header
        label="File Details"
        @close="onCancelFileEntryEditor()"
      >
      </asoft-modal-header>
      <mdb-modal-body>
        <awgt-input
          bg
          label="Display Name"
          v-model="fileDisplayName"
          maxlength="50"
          :counterMaxValue="50"
          counter
        ></awgt-input>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mx-2 mt-4"
            size="2x"
          />
          <asoftDatePicker
            v-model="fileValidFrom"
            label="Valid From"
            autoHide
            :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
            :dataDateFormat="$constants.DATETIMEFORMAT_Json"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 140px"
            :width="300"
          />
          <asoftDatePicker
            v-model="fileValidTo"
            label="Valid Until"
            autoHide
            :displayDateFormat="$constants.DATETIMEFORMAT_Presentation"
            :dataDateFormat="$constants.DATETIMEFORMAT_Json"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 140px"
            :width="300"
          />
        </div>
        <div class="my-2">
          <label for="inputFile">
            <div class="d-flex flex-row" style="cursor: pointer">
              <div class="d-flex flex-row mr-2">
                <div style="position: relative">
                  <mdb-icon
                    icon="file-search"
                    far
                    color="primary"
                    class="ml-1"
                    size="2x"
                  />
                  <tdoxTooltip
                    detail="Choose file"
                    style="position: absolute; left: 24px; top: -8px"
                  />
                </div>
              </div>

              <div>
                <span v-if="fileName == ''"><i>Choose file</i></span>
                <span v-else>{{ fileName }}</span>
              </div>
            </div>
          </label>
          <input
            id="inputFile"
            type="file"
            style="display: none"
            :multiple="false"
            @change="(event) => onFileInput(event)"
          />
        </div>
        <div>
          <awgt-input
            id="fileIsPubliclyAccessibleInd"
            type="checkbox"
            v-model="fileIsPubliclyAccessibleInd"
            name="fileIsPubliclyAccessibleInd"
            label="Publicly Accessible"
            style="min-width: 50px"
          ></awgt-input>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveFileEntry()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="onCancelFileEntryEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>

    <!-- Category Editor -->
    <mdb-modal :show="showCategoryEditor">
      <asoft-modal-header :label="title" @close="onCancelCategoryEditor()">
      </asoft-modal-header>
      <mdb-modal-body>
        <awgt-input
          bg
          :label="categoryInputPlaceholder"
          v-model="newCategoryName"
          maxlength="50"
          :counterMaxValue="50"
          counter
        ></awgt-input>
        <awgt-std-dropdown
          v-model="newCategoryType"
          :items="getSupplierFileCategoryTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Category Type"
          bg
          style="width: 250px"
          :tooltip="getTooltipsTextByCode(54)"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveNewCategory()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="onCancelCategoryEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>

    <!-- Alert Prompt -->
    <ok-prompt ref="alertPrompt"></ok-prompt>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbIcon, mdbModal, mdbModalBody, mdbModalFooter } from "mdbvue";
import awgtInput from "@/components/AWGT/AwgtInput";
import okPrompt from "@/components/OkPrompt";
import api from "@/api/api.js";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import fileDownloadApi from "@/api/fileDownloadApi.js";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import { mapGetters } from "vuex";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";

export default {
  components: {
    mdbIcon,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    awgtInput,
    AwgtStdButton,
    okPrompt,
    asoftModalHeader,
    asoftDatePicker,
    tdoxTooltip,
    AwgtStdDropdown,
  },

  data() {
    return {
      SupplierFileCategories: [],
      newCategoryName: "",
      newCategoryType: "",
      fileCategoryIndex: 0,
      fileIndex: 0,
      fileDisplayName: "",
      fileValidFrom: this.$moment(new Date().setHours(0, 0, 0, 0)).format(
        this.$constants.DATETIMEFORMAT_Json
      ),
      fileValidTo: this.$moment(new Date().setHours(0, 0, 0, 0)).format(
        this.$constants.DATETIMEFORMAT_Json
      ),
      fileIsPubliclyAccessibleInd: false,
      fileName: "",
      showCategoryEditor: false,
      showFileEntryEditor: false,
      files: [],
      api: api,
      title: "Add new file category",
      categoryIndexToUpdate: null,
    };
  },

  computed: {
    ...mapGetters(["getTooltipsTextByCode", "getSupplierFileCategoryTypes"]),
    categoryInputPlaceholder() {
      return this.categoryIndexToUpdate > 0 ? "Edit Category" : "New Category";
    },
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    supplierFileCategoryType: {
      type: String,
      default: "Any",
    },
  },

  watch: {
    value(to) {
      if (to != null || to.length > 0) {
        this.SupplierFileCategories = to;
      }
    },

    SupplierFileCategories: {
      deep: true,
      handler(to) {
        this.$emit("input", to);
        this.$emit("on-files-input", this.files);
      },
    },
  },

  methods: {
    /* There will only be one file at a time */
    onFileInput(event) {
      let files = event.target.files;
      let errors = [];
      if (files[0].size > 10485760) {
        errors.push(`${files[0].name}`);
      } else {
        this.files.push(files[0]);
        this.fileName = files[0].name;
      }

      if (errors.length > 0) this.$emit("files-input-error", errors);
    },

    onEditCategory(index) {
      this.showCategoryEditor = true;
      this.categoryIndexToUpdate = index;

      let supplierFileCategory = this.SupplierFileCategories[index];
      this.newCategoryName = supplierFileCategory.Name;
      this.newCategoryType = supplierFileCategory.SupplierFileCategoryTypeCd;
    },

    SaveNewCategory() {
      this.newCategoryName = this.newCategoryName.trim();

      if (this.newCategoryName.length == 0) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "Category name must be provided"
        );
      } else if (this.newCategoryType == null) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "Category type must be provided"
        );
      } else if (this.categoryIndexToUpdate) {
        this.SupplierFileCategories[this.categoryIndexToUpdate].Name =
          this.newCategoryName;
        this.SupplierFileCategories[
          this.categoryIndexToUpdate
        ].SupplierFileCategoryTypeCd = this.newCategoryType;
        this.categoryIndexToUpdate = null;
        this.newCategoryName = "";
        this.newCategoryType = "";
        this.showCategoryEditor = false;
      } else {
        let existingCategoryIdx = this.SupplierFileCategories.findIndex(
          (c) => c.Name.toUpperCase() == this.newCategoryName.toUpperCase() //Case insensitive search.
        );
        if (existingCategoryIdx >= 0) {
          if (
            this.SupplierFileCategories[existingCategoryIdx].RecordStatus ==
            "Deleted"
          ) {
            this.SupplierFileCategories[existingCategoryIdx].RecordStatus =
              "Inserted";
            this.SupplierFileCategories[existingCategoryIdx].Name =
              this.newCategoryName; //Just in case changing the case of the name.
            this.SupplierFileCategories[
              existingCategoryIdx
            ].SupplierFileCategoryTypeCd = this.newCategoryType;

            this.newCategoryName = "";
            this.newCategoryType = "";
            this.showCategoryEditor = false;
          } else {
            this.$refs.alertPrompt.onFirePrompt(
              "Warning",
              "Category name cannot be duplicated"
            );
          }
        } else {
          this.SupplierFileCategories.push({
            Name: this.newCategoryName,
            SupplierFileCategoryTypeCd: this.newCategoryType,
            IsMandatoryInd: false,
            SupplierFiles: [],
            RecordStatus: "Inserted",
          });

          this.newCategoryName = "";
          this.newCategoryType = "";
          this.showCategoryEditor = false;
        }
      }
    },

    onCancelCategoryEditor() {
      this.showCategoryEditor = false;
      this.newCategoryName = "";
      this.newCategoryType = "";
      this.categoryIndexToUpdate = null;
    },

    onAddFileToCategory(categoryIndex) {
      this.fileCategoryIndex = categoryIndex;
      this.fileIndex = null;
      this.showFileEntryEditor = true;
    },

    onEditFile(categoryIndex, fileIndex) {
      this.fileCategoryIndex = categoryIndex;
      this.fileIndex = fileIndex;

      let fileCategory = this.SupplierFileCategories[this.fileCategoryIndex];
      let supplierFile = fileCategory.SupplierFiles[fileIndex];

      this.fileName = supplierFile.FileName;
      this.fileDisplayName = supplierFile.DisplayName;
      this.fileValidFrom = supplierFile.ActiveFromDt;
      this.fileValidTo = supplierFile.ActiveToDt;
      this.fileIsPubliclyAccessibleInd = supplierFile.IsPubliclyAccessibleInd;

      this.showFileEntryEditor = true;
    },

    validateFileEntry() {
      if (this.fileDisplayName.length == 0) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "A display name must be provided"
        );
        return false;
      }

      if (this.fileName.length == 0) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "A file must be provided"
        );
        return false;
      }

      if (
        this.fileValidFrom &&
        this.fileValidTo &&
        this.fileValidFrom > this.fileValidTo
      ) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "Valid From date must not be greater than Valid Until date"
        );
        return false;
      }

      return true;
    },

    SaveFileEntry() {
      let category = this.SupplierFileCategories[this.fileCategoryIndex];

      if (this.validateFileEntry() == false) return;

      if (this.fileIndex == null) {
        category.SupplierFiles.push({
          FileName: this.fileName,
          DisplayName: this.fileDisplayName,
          ActiveFromDt: this.fileValidFrom,
          ActiveToDt: this.fileValidTo,
          IsPubliclyAccessibleInd: this.fileIsPubliclyAccessibleInd,
          RecordStatus: "Inserted",
        });
      } else {
        let supplierFile = category.SupplierFiles[this.fileIndex];
        if (supplierFile.FileName != this.fileName) {
          //The filename has been updated
          supplierFile.RecordStatus = "Deleted"; //Mark the current (old) supplierFile as deleted
          supplierFile = new Object(); //Create a new supplierFile.
          supplierFile.FileName = this.fileName; //Set the filename
          supplierFile.RecordStatus = "Inserted"; //Set teh new object's status to inserted.
          this.SupplierFileCategories[
            this.fileCategoryIndex
          ].SupplierFiles.push(supplierFile); //Push the new supplierFile onto the array.
        }

        //Update the remaining properties.
        supplierFile.DisplayName = this.fileDisplayName;
        supplierFile.ActiveFromDt = this.fileValidFrom;
        supplierFile.ActiveToDt = this.fileValidTo;
        supplierFile.IsPubliclyAccessibleInd = this.fileIsPubliclyAccessibleInd;
      }

      this.closeFileEntryEditor();
    },

    onCancelFileEntryEditor() {
      this.closeFileEntryEditor();
    },

    closeFileEntryEditor() {
      this.showFileEntryEditor = false;
      this.fileDisplayName = "";
      this.fileValidFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).format(
        this.$constants.DATETIMEFORMAT_Json
      );
      this.fileValidTo = this.$moment(new Date().setHours(0, 0, 0, 0)).format(
        this.$constants.DATETIMEFORMAT_Json
      );
      this.fileIsPubliclyAccessibleInd = false;
      this.fileName = "";
    },

    onDeleteCategory(CategoryIndex) {
      if (
        Array.isArray(
          this.SupplierFileCategories[CategoryIndex]?.SupplierFiles
        ) &&
        this.SupplierFileCategories[CategoryIndex]?.SupplierFiles.length > 0 &&
        this.SupplierFileCategories[CategoryIndex]?.SupplierFiles.some((f) => {
          return f.RecordStatus != "Deleted";
        })
      ) {
        this.$refs.alertPrompt.onFirePrompt(
          "Warning",
          "This category contains files, please delete the files before deleting the category."
        );
      } else {
        if (
          this.SupplierFileCategories[CategoryIndex].RecordStatus == "Inserted"
        )
          this.SupplierFileCategories.splice(CategoryIndex, 1);
        else
          this.SupplierFileCategories[CategoryIndex].RecordStatus = "Deleted";
      }
    },

    onDeleteFile(category, index) {
      if (category.SupplierFiles[index].RecordStatus == "Inserted") {
        let fileIndex = this.files.findIndex((file) => {
          return file.name == category.SupplierFiles[index].FileName;
        });
        this.files.splice(fileIndex, 1);
        category.SupplierFiles.splice(index, 1);
      } else category.SupplierFiles[index].RecordStatus = "Deleted";
    },

    onDownload(file) {
      this.formSubmissionErrors = [];

      this.downloadFile(file)
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [`The file is downloaded.`],
          });
        })
        .catch(() => {
          // Errors have been logged
        });
    },

    onCleanFiles() {
      this.files = [];
    },

    async downloadFile(file) {
      let result = await fileDownloadApi.getFile(file.FullPath);
      //Build a URL from the file
      let newBlob = new Blob([result]);
      let fileURL = window.URL.createObjectURL(newBlob);
      this.$log.info(`fileURL: ${fileURL}`);

      var elink = document.createElement("a");
      elink.download = file.FileName;
      elink.style.display = "none";
      elink.href = fileURL;
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },
  },
};
</script>

<style scoped>
.file-box {
  width: 570px;
  min-height: 200px;
  border: 1px solid grey;
  position: relative;
}
.box-top {
  height: 50px;
  border-bottom: 1px solid grey;
  background: rgb(247, 244, 244);
  font-size: 20px;
}

.cross-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background: rgb(204, 47, 47);
}
.plus-icon {
  position: absolute;
  right: 0px;
  bottom: -5px;
}

.file-link:hover {
  text-decoration: underline;
  color: #0044ff;
}

.file-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
</style>
