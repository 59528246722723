var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "flight-number",
          counter: "",
          counterMaxValue: 10,
          maxlength: "10",
          label: "Flight Number",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.FlightNumber,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "FlightNumber", $$v)
          },
          expression: "additionalDetails.FlightNumber",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "carrier",
          counter: "",
          counterMaxValue: 20,
          maxlength: "20",
          label: "Carrier",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.Carrier,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "Carrier", $$v)
          },
          expression: "additionalDetails.Carrier",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "PNR",
          counter: "",
          counterMaxValue: 80,
          maxlength: "80",
          label: "PNR",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.PNR,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "PNR", $$v)
          },
          expression: "additionalDetails.PNR",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "departure-airport-code",
          counter: "",
          counterMaxValue: 3,
          maxlength: "3",
          label: "Departure Airport Code",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.DepartureAirportCode,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "DepartureAirportCode", $$v)
          },
          expression: "additionalDetails.DepartureAirportCode",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "departure-airport-name",
          counter: "",
          counterMaxValue: 50,
          maxlength: "50",
          label: "Departure Airport Name",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.DepartureAirportName,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "DepartureAirportName", $$v)
          },
          expression: "additionalDetails.DepartureAirportName",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "arrival-airport-code",
          counter: "",
          counterMaxValue: 3,
          maxlength: "3",
          label: "Arrival Airport Code",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.ArrivalAirportCode,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "ArrivalAirportCode", $$v)
          },
          expression: "additionalDetails.ArrivalAirportCode",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2s",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "arrival-airport-name",
          counter: "",
          counterMaxValue: 50,
          maxlength: "50",
          label: "Arrival Airport Name",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.ArrivalAirportName,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "ArrivalAirportName", $$v)
          },
          expression: "additionalDetails.ArrivalAirportName",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }