import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getFile(fullPath) {
    try {
      logger.get(loggingSource.UIUser).info("getFile...");
      logger
        .get(loggingSource.UIUser)
        .debug("Parameters => full path: %s", fullPath);

      logger.get(loggingSource.UIUser).time("getFile call");
      const response = await api.client
        .get("FileDownload", {
          responseType: "blob",
          params: {
            fullPath: fullPath,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIUser).timeEnd("getFile call");

      return response.data;
    } catch (error) {
      logger.get(loggingSource.UIUser).timeEnd("getFile call");
      logger.get(loggingSource.UIUser).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIUser).info("...getFile");
    }
  },
};
