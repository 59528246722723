<template>
  <div class="modal-header">
    <h5 class="modal-title">{{ label }}</h5>
    <a class="close" @click="onClose">×</a>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
</style>
