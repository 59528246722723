var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mdb-input", {
        attrs: {
          type: "textarea",
          rows: _vm.rows,
          label: _vm.label,
          color: _vm.color,
          value: _vm.value,
          "read-only": _vm.readonly,
          bg: "",
        },
        nativeOn: {
          input: function ($event) {
            return _vm.AdjustSize()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }