var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c(
        "mdb-modal",
        {
          attrs: { show: _vm.visible, size: "md" },
          on: { close: _vm.handleCloseModal },
        },
        [
          _c(
            "mdb-modal-header",
            { attrs: { color: "primary-color" } },
            [_c("mdb-modal-title", [_vm._v("Transport Origins")])],
            1
          ),
          _c("mdb-modal-body", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "my-2 w-100 form-control",
              attrs: {
                type: "text",
                placeholder: "Country",
                "aria-label": "Username",
                "aria-describedby": "filter-country",
              },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "mt-4 pl-1",
                staticStyle: {
                  height: "200px",
                  "overflow-y": "scroll",
                  display: "block",
                },
                attrs: { id: "regionList", size: "8" },
              },
              _vm._l(_vm.filteredOrigins, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "custom-control custom-checkbox",
                  },
                  [
                    _c("input", {
                      staticClass: "custom-control-input",
                      attrs: { type: "checkbox", id: item.id },
                      domProps: { checked: _vm.shouldBeChecked(item.id) },
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckboxChange(
                            $event.target.checked,
                            item.id
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: item.id },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "mr-1 command-button",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit(
                        "close-region-modal",
                        _vm.selectedOrigins
                      )
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "check" },
                  }),
                  _vm._v("Save "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleCloseModal.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "times" },
                  }),
                  _vm._v("Cancel "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }