var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageMode == "EditOrderProduct"
    ? _c(
        "div",
        { staticClass: "mx-3" },
        [
          _c("h1", { staticClass: "text-center mb-3" }, [
            _vm._v("Edit Order - Product"),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-2",
                staticStyle: { width: "36px" },
                attrs: {
                  icon: _vm.getProductIcon(
                    _vm.workingOrderProduct.ProductTypeCd,
                    _vm.workingOrderProduct.ProductSubTypeCd
                  ),
                  size: "2x",
                  color: "primary",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { "text-align": "left" },
                },
                [
                  _c("h5", { staticClass: "my-0" }, [
                    _vm._v(_vm._s(_vm.workingOrderProduct.Name)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-2",
                staticStyle: { width: "34px" },
                attrs: {
                  icon: "calendar-alt",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("asoft-date-picker", {
                staticClass: "mx-2",
                staticStyle: { width: "140px" },
                attrs: {
                  label: "Priced For",
                  autoHide: "",
                  displayDateFormat: "D MMM YYYY",
                  dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                  bg: "",
                },
                model: {
                  value: _vm.workingOrderProduct.PricedForDt,
                  callback: function ($$v) {
                    _vm.$set(_vm.workingOrderProduct, "PricedForDt", $$v)
                  },
                  expression: "workingOrderProduct.PricedForDt",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-2",
                staticStyle: { width: "34px" },
                attrs: { icon: "eye", far: "", color: "primary", size: "2x" },
              }),
              _c("awgt-std-switch", {
                staticClass: "mt-2",
                attrs: { offLabel: "", onLabel: "Displayed on the itinerary" },
                model: {
                  value: _vm.workingOrderProduct.IsDisplayedOnItineraryInd,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.workingOrderProduct,
                      "IsDisplayedOnItineraryInd",
                      $$v
                    )
                  },
                  expression: "workingOrderProduct.IsDisplayedOnItineraryInd",
                },
              }),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(55) },
              }),
            ],
            1
          ),
          _c("h5", [_vm._v("Additional Details")]),
          _c("div", [
            _c(
              "div",
              [
                _vm.workingOrderProduct.ProductSubTypeCd === "PST_TrCC"
                  ? _c("additional-details-of-coach-charter", {
                      model: {
                        value: _vm.workingOrderProduct.AdditionalDetails,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workingOrderProduct,
                            "AdditionalDetails",
                            $$v
                          )
                        },
                        expression: "workingOrderProduct.AdditionalDetails",
                      },
                    })
                  : _vm.workingOrderProduct.ProductSubTypeCd === "PST_TrFl"
                  ? _c("additional-details-of-flight", {
                      model: {
                        value: _vm.workingOrderProduct.AdditionalDetails,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workingOrderProduct,
                            "AdditionalDetails",
                            $$v
                          )
                        },
                        expression: "workingOrderProduct.AdditionalDetails",
                      },
                    })
                  : _vm.workingOrderProduct.ProductSubTypeCd === "PST_TrFe"
                  ? _c("additional-details-of-ferry", {
                      model: {
                        value: _vm.workingOrderProduct.AdditionalDetails,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workingOrderProduct,
                            "AdditionalDetails",
                            $$v
                          )
                        },
                        expression: "workingOrderProduct.AdditionalDetails",
                      },
                    })
                  : _vm.workingOrderProduct.ProductSubTypeCd === "PST_AyAy"
                  ? _c("additional-details-of-activity-product-bundle", {
                      model: {
                        value: _vm.workingOrderProduct.AdditionalDetails,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workingOrderProduct,
                            "AdditionalDetails",
                            $$v
                          )
                        },
                        expression: "workingOrderProduct.AdditionalDetails",
                      },
                    })
                  : _vm.workingOrderProduct.ProductSubTypeCd != null
                  ? _c("additional-details-of-others", {
                      model: {
                        value: _vm.workingOrderProduct.AdditionalDetails,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.workingOrderProduct,
                            "AdditionalDetails",
                            $$v
                          )
                        },
                        expression: "workingOrderProduct.AdditionalDetails",
                      },
                    })
                  : _c("div", { staticClass: "text-center" }, [
                      _c("i", [
                        _vm._v(
                          "There are no additional details for this product"
                        ),
                      ]),
                    ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-2",
            },
            [
              _c("h5", [_vm._v("Order Product Lines")]),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button ml-auto",
                  attrs: { type: "button" },
                  on: { click: _vm.onOrderProductLinesAdd },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              ),
            ],
            1
          ),
          _vm.workingOrderProduct.OrderProductLines.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", [
                  _vm._v(
                    'Press "Add" to add an order product line to this product'
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._l(
            _vm.workingOrderProduct.OrderProductLines,
            function (orderProductLine, index) {
              return _c(
                "mdb-card",
                { key: index, staticClass: "py-1 mb-3" },
                [
                  _c("mdb-card-body", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row flex-nowrap flex-grow-1",
                      },
                      [
                        _vm.getOrderProductLineChronology(
                          orderProductLine.Reference,
                          _vm.workingOrderProduct.PricedForDt
                        ) == undefined
                          ? _c("div", { staticClass: "mt-2" }, [
                              _c("i", [
                                _vm._v(
                                  "No Order Product Line can be found at this Priced For date."
                                ),
                              ]),
                            ])
                          : _c(
                              "div",
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getOrderProductLineChronology(
                                          orderProductLine.Reference,
                                          _vm.workingOrderProduct.PricedForDt
                                        )?.Name
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  orderProductLine.OrderProductLineQuantities,
                                  function (orderProductLineQuantity, index) {
                                    return _c("div", { key: index }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            orderProductLineQuantity.Quantity
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.getQuantityUnit(
                                                _vm.getOrderProductLineChronology(
                                                  orderProductLine.Reference,
                                                  _vm.workingOrderProduct
                                                    .PricedForDt
                                                ),
                                                orderProductLineQuantity.QuantityUnitCd,
                                                orderProductLineQuantity.Quantity
                                              )
                                            )
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                _c("div", [
                                  _c(
                                    "table",
                                    { staticClass: "pricing-table" },
                                    [
                                      _c(
                                        "tr",
                                        { staticClass: "pricing-table" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [_vm._v("Ex-GST Price")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formatPricingString(
                                                        orderProductLine?.Price
                                                          ?.adjExGST
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        { staticClass: "pricing-table" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [_vm._v("GST Price")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formatPricingString(
                                                        orderProductLine?.Price
                                                          ?.adjGST
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        { staticClass: "pricing-table" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [_vm._v("Total Price")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "pricing-table" },
                                            [
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formatPricingString(
                                                        orderProductLine?.Price
                                                          ?.adjTotal
                                                      )
                                                    ),
                                                  },
                                                }),
                                                orderProductLine?.Price?.comment
                                                  .length > 0
                                                  ? _c("tdoxTooltip", {
                                                      attrs: {
                                                        detail:
                                                          orderProductLine
                                                            ?.Price?.comment,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                        _c(
                          "div",
                          { staticClass: "ml-auto" },
                          [
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mr-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderProductLineDelete(
                                      _vm.orderProductIdx,
                                      orderProductLine.OrderProductLineIdx
                                    )
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "trash-alt" },
                                }),
                                _vm._v("Delete "),
                              ],
                              1
                            ),
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderProductLineEdit(
                                      orderProductLine.OrderProductLineIdx
                                    )
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "edit" },
                                }),
                                _vm._v("Edit "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            }
          ),
          _c("h5", { staticClass: "font-weight-bold" }, [
            _vm._v(" Product Price: "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.formatPricingString(_vm.workingOrderProduct.Price?.total)
                ),
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3",
            },
            [
              _c("h5", [_vm._v("Product Price Adjustments")]),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button ml-auto",
                  staticStyle: {
                    width: "120px",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                  attrs: { type: "button" },
                  on: { click: _vm.onOpenPriceAdjustmentsDialog },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "dollar" },
                  }),
                  _vm._v("Adjust Price "),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _vm._v(
              " The following price adjustments will be applied to this product line: "
            ),
          ]),
          _vm.workingOrderProduct.OrderProductPriceAdjustments.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", [
                  _vm._v(
                    "Price adjustments are yet to be applied to this product line."
                  ),
                ]),
              ])
            : _c("div", [
                _c(
                  "table",
                  { staticClass: "pricing-table" },
                  [
                    _vm._m(0),
                    _vm._l(
                      _vm.$lodash.orderBy(
                        _vm.workingOrderProduct.OrderProductPriceAdjustments,
                        "CalculationOrder"
                      ),
                      function (pa, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "pricing-table" },
                          [
                            _c("td", { staticClass: "pricing-table" }, [
                              _vm._v(_vm._s(pa.Name)),
                            ]),
                            _c("td", { staticClass: "pricing-table" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getPriceAdjustmentTypeNameByCode(
                                      pa.PriceAdjustmentTypeCd
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "pricing-table" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getPriceChangeTypeNameByCode(
                                      pa.PriceChangeTypeCd
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "pricing-table" }, [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getPriceChangeTypeAmount(pa)
                                  ),
                                },
                              }),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c("div", { staticClass: "mt-2" }, [
                  _vm._v(" Adjusted Pricing "),
                  _c("table", { staticClass: "pricing-table" }, [
                    _c("tr", { staticClass: "pricing-table" }, [
                      _c("td", { staticClass: "pricing-table" }, [
                        _vm._v("Ex-GST Price"),
                      ]),
                      _c("td", { staticClass: "pricing-table" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(
                                this.workingOrderProduct?.Price?.adjExGST
                              )
                            ),
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", { staticClass: "pricing-table" }, [
                      _c("td", { staticClass: "pricing-table" }, [
                        _vm._v("GST Price"),
                      ]),
                      _c("td", { staticClass: "pricing-table" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(
                                this.workingOrderProduct?.Price?.adjGST
                              )
                            ),
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", { staticClass: "pricing-table" }, [
                      _c("td", { staticClass: "pricing-table" }, [
                        _vm._v("Total Price"),
                      ]),
                      _c("td", { staticClass: "pricing-table" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPricingString(
                                this.workingOrderProduct?.Price?.adjTotal
                              )
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
          _c("div", {
            staticClass: "section-break",
            staticStyle: { "margin-bottom": "0.8rem" },
          }),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  staticStyle: {
                    width: "120px",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                  attrs: {
                    type: "button",
                    disabled: !_vm.isFormDirty() || !_vm.isFormValid,
                  },
                  on: { click: _vm.onOK },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "check" },
                  }),
                  _vm._v("OK "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  staticStyle: {
                    width: "120px",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                  attrs: { type: "button" },
                  on: { click: _vm.onCancel },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "times" },
                  }),
                  _vm._v("Cancel "),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showAddOrderProductLineDialog
            ? _c(
                "mdb-modal",
                {
                  attrs: {
                    size: "lg",
                    show: _vm.showAddOrderProductLineDialog,
                  },
                },
                [
                  _c("add-order-product-line-dialog", {
                    attrs: {
                      productLines: _vm.product.CommercialProductLines,
                      pricedForDt: new Date(
                        _vm.workingOrderProduct.PricedForDt
                      ),
                    },
                    on: {
                      productLineSelected:
                        _vm.onProductLineSelectedForOrderProductLineAdd,
                      close: () => (_vm.showAddOrderProductLineDialog = false),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showPriceAdjustmentsDialog
            ? _c(
                "mdb-modal",
                { attrs: { size: "lg", show: _vm.showPriceAdjustmentsDialog } },
                [
                  _vm.showPriceAdjustmentsDialog == true
                    ? _c("price-adjustments-dialog", {
                        attrs: {
                          allowedPriceAdjustments: _vm.product.PriceAdjustments,
                          keepDollarOnItemInd: false,
                          visible: _vm.showPriceAdjustmentsDialog,
                        },
                        on: { close: _vm.onPriceAdjustmentsDialogClose },
                        model: {
                          value:
                            _vm.workingOrderProduct
                              .OrderProductPriceAdjustments,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workingOrderProduct,
                              "OrderProductPriceAdjustments",
                              $$v
                            )
                          },
                          expression:
                            "workingOrderProduct.OrderProductPriceAdjustments",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm.pageMode == "EditOrderProductLine"
    ? _c(
        "div",
        [
          _c("edit-order-product-line", {
            attrs: {
              orderProductIdx: _vm.orderProductIdx,
              orderProductLineIdx: _vm.orderProductLineIdx,
              product: _vm.product,
              booking: _vm.booking,
            },
            on: { close: _vm.onEditOrderProductLineClose },
          }),
        ],
        1
      )
    : _c("div", [_vm._v("Unknown pageMode")])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "pricing-table" }, [
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Name")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Adjustment Type")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Change Type")]),
      _c("td", { staticClass: "pricing-table" }, [_vm._v("Amount")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }