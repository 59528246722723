<template>
  <div>
    <mdb-date-picker
      :value="dataToDisplayDateFormat(dateData)"
      :option="{
        format: displayDateFormat,
        placeholder: placeholder,
        week: week,
        buttons: buttons,
        color: color,
        inputStyle: inputStyle,
        wrapperClass: wrapperClass,
      }"
      :bg="bg"
      :label="label"
      :limit="limit"
      :disableOk="disableOk"
      :disableClear="disableClear"
      :disableToday="disableToday"
      :autoHide="autoHide"
      :validation="validation"
      :isValid="isValid"
      :validFeedback="validFeedback"
      :invalidFeedback="invalidFeedback"
      :defaultDate="defaultDate"
      :icon="icon"
      :iconClass="iconClass"
      :far="far"
      :fab="fab"
      :fa="fal"
      :outline="outline"
      @input="onInput"
      @getValue="onGetValue"
      class="datepicker-font"
    ></mdb-date-picker>
    <button
      v-if="tooltip.length > 0"
      type="button"
      class="tips-button"
      :title="tooltip"
      v-tippy="{ position: 'bottom', arrow: true }"
      style="position: relative; right: 15px"
    >
      <mdb-icon
        fas
        :icon="getTooltipIconName(tooltipType)"
        style="color: grey; cursor: pointer; position: absolute; right: -2px"
        size="1x"
      />
    </button>
  </div>
</template>

<script>
import { mdbDatePicker, mdbIcon } from "mdbvue";
export default {
  components: {
    mdbDatePicker,
    mdbIcon,
  },
  data() {
    return {
      dateData: null,
    };
  },
  props: {
    tooltip: {
      type: String,
      default: "",
    },
    tooltipType: {
      type: String,
      default: "information",
    },
    value: {
      type: String,
    },
    dataDateFormat: {
      type: String,
      required: true,
    },
    displayDateFormat: {
      type: String,
      required: true,
    },
    setTime: {
      type: String,
      required: false,
      validator: (v) => {
        return v.match(/^\d{1,2}:\d{1,2}:\d{1,2}\.\d{1,3}$/);
      },
    },
    label: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 80,
    },
    limit: {
      type: Object,
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
    disableClear: {
      type: Boolean,
      default: false,
    },
    disableToday: {
      type: Boolean,
      default: false,
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    validFeedback: {
      type: String,
    },
    invalidFeedback: {
      type: String,
    },
    defaultDate: {
      type: [String, Date],
    },
    bg: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    far: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    fal: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    week: {
      type: Array,
      default: null,
    },
    buttons: {
      type: Object,
      default: null,
    },
    color: {
      type: Object,
    },
    inputStyle: {
      type: Object,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
  },

  watch: {
    dateData(to) {
      this.$log.info("asoftDatePicker (date watch) date: %s", to);
      //The control will emit 'null' when the date is cleared :-)
      this.$emit("input", to);
    },
    value(to) {
      this.$log.info("asoftDatePicker (value watch) date: %s", to);
      this.dateData = to;
    },
  },

  computed: {
    getTooltipIconName() {
      return (tooltipType) => {
        switch (tooltipType) {
          case "information":
            return "info-circle";
          case "question":
            return "question-circle";
          case "warning":
            return "exclamation-triangle";
          default:
            return "info-circle";
        }
      };
    },
  },

  methods: {
    displayToDataDateFormat(s) {
      if (s == "" || s == null) return null;
      else {
        let d = new Date(s);

        if (this.setTime) {
          let t = this.setTime.split(new RegExp("[:|.]", "g"));
          d.setHours(t[0], t[1], t[2], t[3]);
        }

        const outputDate = this.$moment(d, this.displayDateFormat).format(
          this.dataDateFormat
        );

        return outputDate;
      }
    },
    dataToDisplayDateFormat(d) {
      if (d == null) return null;
      else {
        const outputString = this.$moment(d, this.dataDateFormat).format(
          this.displayDateFormat
        );
        return outputString;
      }
    },
    onInput(e) {
      this.dateData = this.displayToDataDateFormat(e);
      this.$emit("change", this.dateData);
    },
    onGetValue(e) {
      this.dateData = this.displayToDataDateFormat(e);
      this.$emit("change", this.dateData);
    },
  },
  mounted() {
    //this.value = "2020-10-01T11:00:00";
  },
  created() {
    this.dateData = !this.value || this.value.length == 0 ? null : this.value;
  },
};
</script>

<style>
/*
  This style needs to be global for the datepicker font to be set correctly.
*/
.datepicker-font {
  font-family: Calibri;
}

.datepicker-font * {
  font-family: inherit !important;
}
</style>

<style scoped>
.tips-button {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: relative;
  bottom: 44px;
  right: 15px;
}
</style>
