import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getReferenceData() {
    try {
      logger.get(loggingSource.UIReferenceData).info("getReferenceData...");

      logger.get(loggingSource.UIReferenceData).time("getReferenceData call");

      const response = await api.client.get("Reference");
      logger
        .get(loggingSource.UIReferenceData)
        .timeEnd("getReferenceData call");
      const references = response.data;

      logger
        .get(loggingSource.UIReferenceData)
        .debug("getReferenceData reference data: %s", references);

      return references;
    } catch (error) {
      logger
        .get(loggingSource.UIReferenceData)
        .timeEnd("getReferenceData call");
      logger.get(loggingSource.UIReferenceData).error(error.response);
      throw error;
    } finally {
      logger.get(loggingSource.UIReferenceData).info("...getReferenceData");
    }
  },

  async getDefaultPermissionForConsultancyRole(role) {
    try {
      logger
        .get(loggingSource.UIReferenceData)
        .info("getDefaultPermissionForConsultancyRole...");

      logger
        .get(loggingSource.UIReferenceData)
        .time("getDefaultPermissionForConsultancyRole call");

      const response = await api.client.get("Reference/DefaultRolePermission", {
        params: {
          role: role,
        },
      });
      logger
        .get(loggingSource.UIReferenceData)
        .timeEnd("getDefaultPermissionForConsultancyRole call");
      const result = response.data;

      logger
        .get(loggingSource.UIReferenceData)
        .debug(
          "getDefaultPermissionForConsultancyRole result data: %s",
          result
        );

      return result;
    } catch (error) {
      logger
        .get(loggingSource.UIReferenceData)
        .timeEnd("getDefaultPermissionForConsultancyRole call");
      logger.get(loggingSource.UIReferenceData).error(error.response);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIReferenceData)
        .info("...getDefaultPermissionForConsultancyRole");
    }
  },
};
