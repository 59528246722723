<template>
  <div>
    <div class="d-flex flex-row align-items-center">
      <h4 class="mx-2">Additional Charges</h4>
      <tdoxTooltip class="mb-1 mr-auto" :detail="getTooltipsTextByCode(28)" />
    </div>
    <div
      v-for="(additionalCharge, index) in additionalChargesArray"
      :key="index"
      class="d-flex flex-row flex-nowrap flex-grow-1 my-2"
    >
      <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1">
        <span
          class="d-flex flex-row flex-nowrap flex-grow-1 mr-3"
          style="min-width: 100px; max-width: 200px; font-weight: bold"
        >
          {{ additionalCharge.Name }}
        </span>
        <span
          class="d-flex flex-row flex-nowrap flex-grow-1"
          style="min-width: 100px"
        >
          {{
            additionalCharge.BookingAdjustmentCalculatedAs == "BACA_D"
              ? "$"
              : ""
          }}{{
            `${additionalCharge.Amount}${
              additionalCharge.BookingAdjustmentCalculatedAs == "BACA_P"
                ? "%"
                : ""
            } ${
              additionalCharge.BookingAdjustmentAppliesTo == "BAAT_C" &&
              isSchoolBooking
                ? "Per Student"
                : getBookingAdjustmentAppliesToNameByCode(
                    additionalCharge.BookingAdjustmentAppliesTo
                  )
            }`
          }}
        </span>
      </div>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="top: -10px; min-width: 63px"
        @click="OpenAdditionalChargeEditor(index)"
      >
        <mdb-icon icon="edit" class="mr-1" />Edit
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="top: -10px; min-width: 63px"
        @click="DeleteAdditionalChargeNote(index)"
      >
        <mdb-icon icon="trash-alt" class="mr-1" />Delete
      </awgt-std-button>
      <br />
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
    >
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="OpenAdditionalChargeEditor(-1)"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <mdb-modal :show="showAdditionalChargeEditor">
      <asoft-modal-header label="Additional Charge Edit" @close="CloseEditor">
      </asoft-modal-header>
      <mdb-modal-body>
        <template v-if="editAdditionalCharge != null">
          <span
            >The following amount will be added to this booking's trip
            costing.</span
          >
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="clipboard"
              far
              color="primary"
              style="width: 40px; text-align: center"
              size="2x"
            />
            <awgt-input
              bg
              counter
              :counterMaxValue="50"
              maxlength="50"
              label="Name"
              class="flex-grow-1"
              v-model="editAdditionalCharge.Name"
            ></awgt-input>
          </div>
          <div class="my-2">
            <div class="mx-2">
              This additional charge will be calculated as a
            </div>
            <div class="d-flex flex-row flex-wrap min-width:120px;">
              <awgt-input
                id="BookingAdjustmentCalculatedAsDollar"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentCalculatedAs"
                name="BookingAdjustmentCalculatedAs"
                label="Dollar Amount"
                radioValue="BACA_D"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentCalculatedAsPercentage"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentCalculatedAs"
                name="BookingAdjustmentCalculatedAsPercentage"
                label="Percentage"
                radioValue="BACA_P"
                class="mx-2"
              />
            </div>
          </div>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              :icon="
                getAmountIcon(
                  editAdditionalCharge.BookingAdjustmentCalculatedAs
                )
              "
              color="primary"
              style="width: 40px; text-align: center"
              size="2x"
            />
            <awgt-input
              v-model.number="editAdditionalCharge.Amount"
              maxlength="7"
              label="Amount"
              class="flex-grow-1"
              type="text"
              bg
              v-numeric="
                getPrecision(editAdditionalCharge.BookingAdjustmentCalculatedAs)
              "
            ></awgt-input>
          </div>
          <div class="my-2">
            <div class="mx-2" style="min-width: 60px">Applies To</div>
            <div class="d-flex flex-row flex-wrap min-width:120px;">
              <awgt-input
                id="BookingAdjustmentAppliesToPerChild"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                :label="isSchoolBooking ? 'Per Student' : 'Per Child'"
                radioValue="BAAT_C"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerAdult"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Adult"
                radioValue="BAAT_A"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerPerson"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Person"
                radioValue="BAAT_P"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerBooking"
                type="radio"
                v-model="editAdditionalCharge.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Booking"
                radioValue="BAAT_B"
                class="mx-2"
              />
            </div>
          </div>
        </template>
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveAndCloseEditor()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="CloseEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import lodash from "lodash";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import { mapGetters } from "vuex";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";

import { mdbIcon, mdbModal, mdbModalBody, mdbModalFooter } from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    awgtInput,
    mdbIcon,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    asoftModalHeader,
    tdoxTooltip,
    AwgtStdButton,
  },
  data() {
    return {
      currentAdditionalChargeIndex: 0,
      showAdditionalChargeEditor: false,
      editAdditionalCharge: null,
      additionalChargesArray: [],
    };
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    value: {
      type: Array,
    },
    isSchoolBooking: {
      value: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "getBookingAdjustmentAppliesToNameByCode",
      "getTooltipsTextByCode",
    ]),

    getAmountIcon() {
      return function (code) {
        let type;
        switch (code) {
          case "BACA_D":
            type = "dollar-sign";
            break;
          case "BACA_P":
            type = "percentage";
            break;
          default:
            type = "";
        }
        return type;
      };
    },

    getPrecision() {
      return function (code) {
        let obj;
        switch (code) {
          case "BACA_D":
            obj = {
              precision: 6,
              scale: 2,
              posOnly: true,
              trailingZeroScale: 2,
            };
            break;
          case "BACA_P":
            obj = {
              precision: 2,
              scale: 0,
              posOnly: true,
              trailingZeroScale: 0,
            };
            break;
          default:
            obj = {};
        }
        return obj;
      };
    },
  },
  methods: {
    OpenAdditionalChargeEditor(idx) {
      this.currentAdditionalChargeIndex = idx;

      if (idx > -1) {
        this.editAdditionalCharge = lodash.cloneDeep(
          this.additionalChargesArray[this.currentAdditionalChargeIndex]
        );
      } else {
        this.editAdditionalCharge = {};
        this.$set(this.editAdditionalCharge, "Name", "");
        this.$set(this.editAdditionalCharge, "Amount", 0);
        this.$set(this.editAdditionalCharge, "BookingAdjustmentType", "BAT_Ch");
        this.$set(
          this.editAdditionalCharge,
          "BookingAdjustmentAppliesTo",
          "BAAT_C"
        );
        this.$set(
          this.editAdditionalCharge,
          "BookingAdjustmentCalculatedAs",
          "BACA_D"
        );
      }

      this.showAdditionalChargeEditor = true;
    },
    SaveAndCloseEditor() {
      if (this.currentAdditionalChargeIndex > -1) {
        this.$set(
          this.additionalChargesArray,
          this.currentAdditionalChargeIndex,
          this.editAdditionalCharge
        );
      } else {
        this.additionalChargesArray.push(this.editAdditionalCharge);
      }
      this.CloseEditor();
    },
    DeleteAdditionalChargeNote(idx) {
      this.additionalChargesArray.splice(idx, 1);
    },
    CloseEditor() {
      this.showAdditionalChargeEditor = false;
    },
  },
  watch: {
    value(to) {
      this.additionalChargesArray = to;
    },
    additionalChargesArray(to) {
      this.$emit("input", to);
    },
    "editAdditionalCharge.BookingAdjustmentCalculatedAs"() {
      this.editAdditionalCharge.Amount = 0;
    },
  },
  mounted() {},
};
</script>
