var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mx-2", staticStyle: { "font-weight": "bold" } }, [
      _vm._v("User Permission:"),
    ]),
    _c(
      "div",
      { staticClass: "mx-2 d-flex flex-row flex-nowrap align-items-center" },
      [
        _vm._m(0),
        _vm._l(_vm.userPermissionEditGroup, function (permission, index) {
          return [
            _c("awgt-std-dropdown", {
              key: index,
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "max-width": "250px" },
              attrs: {
                id: "user-permission-control",
                label: "User permission edit",
                itemValueProperty: "PermissionScopeCode",
                items: permission.PermisionScopes,
                itemTextProperty: "Name",
                bg: "",
              },
              model: {
                value: _vm.userPermissionEditValue[index],
                callback: function ($$v) {
                  _vm.$set(_vm.userPermissionEditValue, index, $$v)
                },
                expression: "userPermissionEditValue[index]",
              },
            }),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Client:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Client Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.clientPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.clientPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.clientPermissionValue, index, $$v)
                      },
                      expression: "clientPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.clientPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Communication:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Communication Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.communicationPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.communicationPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.communicationPermissionValue, index, $$v)
                      },
                      expression: "communicationPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.communicationPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Country Region:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Country Region Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.countryRegionPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.countryRegionPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.countryRegionPermissionValue, index, $$v)
                      },
                      expression: "countryRegionPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            ).substr(
                              permission.PermissionName.substr(
                                permission.PermissionName.indexOf(" ") + 1
                              ).indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.countryRegionPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Impersonation:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Impersonation Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.impersonationPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.impersonationPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.impersonationPermissionValue, index, $$v)
                      },
                      expression: "impersonationPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.impersonationPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Issue:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Issue Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.issuePermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.issuePermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.issuePermissionValue, index, $$v)
                      },
                      expression: "issuePermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.issuePermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Itinerary:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Itinerary Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.itineraryPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.itineraryPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.itineraryPermissionValue, index, $$v)
                      },
                      expression: "itineraryPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.itineraryPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Product:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set PProduct Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.productPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.productPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.productPermissionValue, index, $$v)
                      },
                      expression: "productPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.productPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Product Proposal:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Product Proposal Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(
          _vm.productProposalPermissionGroup,
          function (permission, index) {
            return [
              _vm.checkPermission("CP_UsU")
                ? [
                    _c("awgt-std-dropdown", {
                      key: index,
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "max-width": "250px" },
                      attrs: {
                        id: permission.PermissionName,
                        label: permission.PermissionName,
                        itemValueProperty: "PermissionScopeCode",
                        items: permission.PermisionScopes,
                        itemTextProperty: "Name",
                        bg: "",
                        tooltip: _vm.PERMISSION_SCOPE,
                      },
                      model: {
                        value: _vm.productProposalPermissionValue[index],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.productProposalPermissionValue,
                            index,
                            $$v
                          )
                        },
                        expression: "productProposalPermissionValue[index]",
                      },
                    }),
                  ]
                : [
                    _c(
                      "div",
                      {
                        key: index,
                        staticClass: "flex-grow-1 mx-2 my-2",
                        staticStyle: { "max-width": "250px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              permission.PermissionName.substr(
                                permission.PermissionName.indexOf(" ") + 1
                              ).substr(
                                permission.PermissionName.substr(
                                  permission.PermissionName.indexOf(" ") + 1
                                ).indexOf(" ") + 1
                              )
                            ) +
                            ": " +
                            _vm._s(
                              permission.PermisionScopes.find((ps) => {
                                return (
                                  ps.PermissionScopeCode ==
                                  _vm.productProposalPermissionValue[index]
                                )
                              }).Name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
            ]
          }
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Report View:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Report View Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.reportViewPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.reportViewPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.reportViewPermissionValue, index, $$v)
                      },
                      expression: "reportViewPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.reportViewPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Supplier:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Supplier Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.supplierPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.supplierPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.supplierPermissionValue, index, $$v)
                      },
                      expression: "supplierPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.supplierPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" System Action View:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Systemm Action View Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(
          _vm.systemActionViewPermissionGroup,
          function (permission, index) {
            return [
              _vm.checkPermission("CP_UsU")
                ? [
                    _c("awgt-std-dropdown", {
                      key: index,
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "max-width": "250px" },
                      attrs: {
                        id: permission.PermissionName,
                        label: permission.PermissionName,
                        itemValueProperty: "PermissionScopeCode",
                        items: permission.PermisionScopes,
                        itemTextProperty: "Name",
                        bg: "",
                        tooltip: _vm.PERMISSION_SCOPE,
                      },
                      model: {
                        value: _vm.systemActionViewPermissionValue[index],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.systemActionViewPermissionValue,
                            index,
                            $$v
                          )
                        },
                        expression: "systemActionViewPermissionValue[index]",
                      },
                    }),
                  ]
                : [
                    _c(
                      "div",
                      {
                        key: index,
                        staticClass: "flex-grow-1 mx-2 my-2",
                        staticStyle: { "max-width": "250px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              permission.PermissionName.substr(
                                permission.PermissionName.indexOf(" ") + 1
                              )
                            ) +
                            ": " +
                            _vm._s(
                              permission.PermisionScopes.find((ps) => {
                                return (
                                  ps.PermissionScopeCode ==
                                  _vm.supplierPermissionValue[index]
                                )
                              }).Name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
            ]
          }
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Travel Booking:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Travel Booking Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.travelBookingPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.travelBookingPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.travelBookingPermissionValue, index, $$v)
                      },
                      expression: "travelBookingPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            ).substr(
                              permission.PermissionName.substr(
                                permission.PermissionName.indexOf(" ") + 1
                              ).indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.supplierPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" User:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set User Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.userPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.userPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.userPermissionValue, index, $$v)
                      },
                      expression: "userPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.supplierPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c(
          "div",
          { staticStyle: { width: "200px" } },
          [
            _vm._v(" Workgroup:"),
            _c("tdoxTooltip", {
              attrs: { detail: "Set Workgroup Edit permissions" },
            }),
          ],
          1
        ),
        _vm._l(_vm.workgroupPermissionGroup, function (permission, index) {
          return [
            _vm.checkPermission("CP_UsU")
              ? [
                  _c("awgt-std-dropdown", {
                    key: index,
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      id: permission.PermissionName,
                      label: permission.PermissionName,
                      itemValueProperty: "PermissionScopeCode",
                      items: permission.PermisionScopes,
                      itemTextProperty: "Name",
                      bg: "",
                      tooltip: _vm.PERMISSION_SCOPE,
                    },
                    model: {
                      value: _vm.workgroupPermissionValue[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.workgroupPermissionValue, index, $$v)
                      },
                      expression: "workgroupPermissionValue[index]",
                    },
                  }),
                ]
              : [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex-grow-1 mx-2 my-2",
                      staticStyle: { "max-width": "250px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            permission.PermissionName.substr(
                              permission.PermissionName.indexOf(" ") + 1
                            )
                          ) +
                          ": " +
                          _vm._s(
                            permission.PermisionScopes.find((ps) => {
                              return (
                                ps.PermissionScopeCode ==
                                _vm.supplierPermissionValue[index]
                              )
                            }).Name
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { width: "150px" } }, [
      _vm._v(" User Permission "),
      _c("br"),
      _vm._v(" (If this user is able to edit other user's permission): "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }