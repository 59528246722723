<template>
  <div class="splitbutton">
    <!-- <mdb-btn-group> -->
    <awgt-std-button
      class="mx-0 command-button"
      type="button"
      style="border-radius: 5px 0px 0px 5px"
      @click="items[selectedIndex].callback"
    >
      {{ items[selectedIndex].text }}
    </awgt-std-button>
    <mdb-dropdown>
      <mdb-dropdown-toggle
        color="secondary"
        class="px-2 py-1"
        slot="toggle"
        style="height: 35px; border-radius: 0px 5px 5px 0px"
      ></mdb-dropdown-toggle>
      <mdb-dropdown-menu color="primary" class="scrollbar-primary" right>
        <mdb-dropdown-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectedIndex = index"
          >{{ item.text }}</mdb-dropdown-item
        >
      </mdb-dropdown-menu>
    </mdb-dropdown>
    <!-- </mdb-btn-group> -->
  </div>
</template>

<!--
  The Split Button should be a control in its own right so the following styles link
  should be removed. It exists for the command-button style. Consider adding a class
  prop and passing in the command-button style via that prop.
-->
<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
@import "@/styles/sharedVariables.scss";

.scrollbar-primary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.splitbutton {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.dropdown-toggle::after {
  margin-left: 0;
}

/* .command-button:hover {
  background-color: #5a95f5 !important;
} */

.btn {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $primary-color;
}
.scrollbar-primary {
  position: relative;
  overflow-y: scroll;
  height: 200px;
}
</style>

<script>
import {
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownToggle,
  mdbDropdownMenu,
  //   mdbScrollbar
  //   mdbBtnGroup
} from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "SplitButton",
  components: {
    AwgtStdButton,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownToggle,
    mdbDropdownMenu,
    // mdbScrollbar
    // mdbBtnGroup
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  props: {
    items: { type: Array, required: true },
  },
};
</script>
