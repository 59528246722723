<template>
  <mdb-container fluid>
    <h1 class="text-center" style="margin-bottom: 50px">Manage Users</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        multiple
        selectAll
        label="Workgroup"
        itemValueProperty="Code"
        :items="workgroups"
        itemTextProperty="Name"
        v-model="filterWorkgroups"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-std-dropdown>
      <awgt-input
        v-model="searchConsultant"
        counter
        :counterMaxValue="100"
        label="Consultant"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-input>
    </div>
    <div class="d-flex justify-content-end flex-grow-1 mt-2">
      <awgt-std-button
        id="add-user"
        type="button"
        class="command-button mx-2 mb-2"
        title="Filter"
        icon="search"
        v-on:click="onFilterUser()"
      >
      </awgt-std-button>
      <awgt-std-button
        v-if="checkPermission('CP_UsC')"
        id="add-user"
        type="button"
        class="command-button mx-2 mb-2"
        v-on:click="onAddUser()"
        title="Add"
        icon="user-plus"
      >
      </awgt-std-button>
    </div>
    <div v-if="users.length == 0" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-else
      v-for="(user, index) in users"
      :key="index"
      class="mb-3 mx-2"
      :style="user.IsActive == true ? 'opacity:1' : 'opacity:0.5'"
    >
      <awgt-expansion-header
        @click.native="onToggleUserDetailExpansion(index)"
        tag="button"
        :user="user"
        :expanded="userDetailExpansion[index]"
      >
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <mdb-icon
            icon="user"
            size="2x"
            class="pr-3"
            color="primary"
            style="width: 75px"
          />
          <div class="d-flex flex-column">
            <h4 class="mb-1" :id="user.FirstName + user.Surname">
              {{ user.FirstName }}&nbsp;{{ user.Surname }}
            </h4>
          </div>
        </div>
      </awgt-expansion-header>

      <!-- <expansion-region :toggle="userDetailExpansion[index]">
        <mdb-card-body v-if="usersFullDataSet[index]">
          <user-view :value="usersFullDataSet[index]"></user-view>

          <div v-if="usersFullDataSet[index].IsActive" class="my-2 float-right">
            <mdb-btn
              v-if="
                checkPermission('CP_UsU') && checkPermissionScopeUpdate(index)
              "
              type="button"
              color="primary"
              title="Edit"
              class="command-button mx-2"
              v-on:click="onEditUser(user)"
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </mdb-btn>

            <mdb-btn
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              color="primary"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onDeleteUser(index)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </mdb-btn>
          </div>
          <div v-else class="my-2 float-right">
            <mdb-btn
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              color="primary"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onUndeleteUser(index)"
            >
              <mdb-icon class="mr-1" icon="user-plus" />Undelete
            </mdb-btn>
            <mdb-btn
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              color="primary"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onPermanentlyDeleteUser(index)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Permanently Delete
            </mdb-btn>
          </div>
        </mdb-card-body>
      </expansion-region> -->
      <awgt-expansion-body :toggle="userDetailExpansion[index]">
        <template>
          <user-view :value="usersFullDataSet[index]"></user-view>
        </template>
        <template #footer>
          <div
            v-if="usersFullDataSet[index] && usersFullDataSet[index].IsActive"
            class="my-2 float-right"
          >
            <awgt-std-button
              v-if="
                checkPermission('CP_UsU') && checkPermissionScopeUpdate(index)
              "
              type="button"
              class="command-button mx-2"
              v-on:click="onEditUser(user)"
              title="Edit"
              icon="edit"
            >
            </awgt-std-button>

            <awgt-std-button
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              class="command-button mx-2"
              v-on:click="onDeleteUser(index)"
              title="Delete"
              icon="trash-alt"
            >
            </awgt-std-button>
          </div>
          <div v-else class="my-2 float-right">
            <awgt-std-button
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              class="command-button mx-2"
              v-on:click="onUndeleteUser(index)"
              title="Undelete"
              icon="user-plus"
            >
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_UsD') && checkPermissionScopeDelete(index)
              "
              type="button"
              class="command-button mx-2"
              v-on:click="onPermanentlyDeleteUser(index)"
              title="Permanently Delete"
              icon="trash-alt"
            >
            </awgt-std-button>
          </div>
        </template>
      </awgt-expansion-body>
    </mdb-card>
    <yes-or-no-prompt ref="handleUserPrompt"> </yes-or-no-prompt>
    <ok-prompt ref="alertPrompt"></ok-prompt>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
//import asoftSelect from "@/components/AtomSoftware/asoftSelect.vue";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import userApi from "@/api/userApi.js";
import { mapGetters } from "vuex";
import workgroupApi from "@/api/workgroupApi.js";
import userView from "@/components/UserView";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import okPrompt from "@/components/OkPrompt";
import tokenManager from "@/shared/securityTokenManager.js";
import AwgtExpansionHeader from "@/components/AWGT/AwgtExpansionHeader.vue";
import AwgtExpansionBody from "@/components/AWGT/AwgtExpansionBody.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";

import { mdbContainer, mdbCard, mdbIcon } from "mdbvue";
import { sharedMethods } from "@/shared/shared";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown.vue";

export default {
  data() {
    return {
      users: [],
      userDetailExpansion: [],
      ConsultancyRole: "",
      returnData: [],
      workgroups: [],
      filterWorkgroups: [],
      searchConsultant: "",
      currentUserLevel: 0,
      currentUserReference: null,
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForUserUpdate: null,
      currentUserPermissionScopeForUserDelete: null,
      usersFullDataSet: [],
    };
  },
  components: {
    mdbContainer,

    mdbCard,
    mdbIcon,

    // mdbBtn,
    //asoftSelect,
    userView,
    yesOrNoPrompt,
    okPrompt,
    awgtInput,
    AwgtExpansionHeader,
    AwgtExpansionBody,
    AwgtStdButton,
    AwgtStdDropdown,
  },

  computed: {
    ...mapGetters([
      "getConsultancyRoleNameById",
      "getConsultancyRoles",
      "getSystemTemplateFromCode",
      "checkPermission",
    ]),

    checkPermissionScopeUpdate() {
      return function (index) {
        let user = this.usersFullDataSet[index];

        if (user) {
          if (!this.currentUserPermissionScopeForUserUpdate) return false;

          if (this.currentUserPermissionScopeForUserDelete == "CPS_NA") {
            if (user.ConsultancyRole.Level > 400) {
              return false;
            }
          }

          if (this.currentUserPermissionScopeForUserUpdate == "CPS_BW") {
            if (
              (user.Workgroups.length != 0 &&
                user.Workgroups.every(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
                )) ||
              user.ConsultancyRole.Level > this.currentUserLevel
            ) {
              return false;
            }
          }

          if (this.currentUserPermissionScopeForUserUpdate == "CPS_NW") {
            if (this.currentUserReference == user.Reference) return true;

            if (
              (user.Workgroups.length != 0 &&
                user.Workgroups.every(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
                )) ||
              user.ConsultancyRole.Level >= this.currentUserLevel
            ) {
              return false;
            }
          }

          return true;
        } else return false;
      };
    },

    checkPermissionScopeDelete() {
      return function (index) {
        let user = this.usersFullDataSet[index];

        if (user) {
          if (!this.currentUserPermissionScopeForUserDelete) return false;

          if (this.currentUserPermissionScopeForUserDelete == "CPS_NA") {
            if (user.ConsultancyRole.Level > 400) {
              return false;
            }
          }

          if (this.currentUserPermissionScopeForUserDelete == "CPS_BW") {
            if (
              (user.Workgroups.length != 0 &&
                user.Workgroups.every(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
                )) ||
              user.ConsultancyRole.Level > this.currentUserLevel
            ) {
              return false;
            }
          }

          if (this.currentUserPermissionScopeForUserDelete == "CPS_NW") {
            if (this.currentUserReference == user.Reference) return true;

            if (
              (user.Workgroups.length != 0 &&
                user.Workgroups.every(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
                )) ||
              user.ConsultancyRole.Level >= this.currentUserLevel
            ) {
              return false;
            }
          }

          return true;
        } else return false;
      };
    },
  },

  methods: {
    async onToggleUserDetailExpansion(index) {
      let user;
      if (
        (this.userDetailExpansion[index] == undefined ||
          this.userDetailExpansion[index] == false) &&
        this.usersFullDataSet[index] == undefined
      ) {
        user = await userApi.getUserByReference(this.users[index].Reference);
        user.PhoneJSON = JSON.parse(user.PhoneJSON);

        this.$set(this.usersFullDataSet, index, user);
      }

      this.$set(
        this.userDetailExpansion,
        index,
        !this.userDetailExpansion[index]
      );
    },
    async onFilterUser() {
      //TODO: filter
      this.usersFullDataSet = [];
      this.users = await userApi.getUsersList(
        null,
        true,
        true,
        true,
        this.filterWorkgroups.join(),
        this.searchConsultant
      );
    },
    onAddUser() {
      this.$router.push({ path: "/admin/user/add" });
    },

    onEditUser(user) {
      this.$router.push({
        path: `/admin/user/${user.Reference}/edit`,
      });
    },

    onDeleteUser(index) {
      if (
        this.getConsultancyRoleNameById(
          this.usersFullDataSet[index].ConsultancyRole.ConsultancyRoleId
        ) == "Consultant"
      ) {
        if (this.usersFullDataSet[index].Workgroups.length > 0) {
          this.$refs.alertPrompt.onFirePrompt(
            "Warning",
            `This consultant cannot be deleted as they are a member of workgroup ${this.usersFullDataSet[index].Workgroups[0].Name}.`
          );
          return;
        }
      } else {
        if (this.usersFullDataSet[index].Workgroups.length > 0) {
          this.$refs.alertPrompt.onFirePrompt(
            "Warning",
            `This user cannot be deleted as they are a member of workgroups ${this.usersFullDataSet[
              index
            ].Workgroups.map((w) => w.Name).join(",")}.`
          );
          return;
        }
      }

      this.usersFullDataSet[index].IsActive = false;
      this.saveUser(index);
    },

    onUndeleteUser(index) {
      this.$refs.handleUserPrompt
        .onHandlePrompt("Warning", "Are you sure to recover the user?")
        .then((option) => {
          if (option == true) {
            this.usersFullDataSet[index].IsActive = true;
            this.saveUser(index);
          }
        });
    },

    onPermanentlyDeleteUser(index) {
      //TODO: Lee - Why is the user being cloned before deletion?
      //Because I need to stringyfy the PhoneJSON to save it. Otherwise, if I don't clone, the "PhoneCapture" control will cause an error.
      this.$refs.handleUserPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure to permanently delete the user?"
        )
        .then((option) => {
          if (option == true) {
            this.permanentlyDelete(index, this.usersFullDataSet[index]);
          }
        });
    },

    async loadFormData() {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;
      this.currentUserReference = currentUser.Reference;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForUserUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_UsU";
        })?.PermissionScope;

      this.currentUserPermissionScopeForUserDelete =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_UsD";
        })?.PermissionScope;

      this.usersFullDataSet = [];
      this.users = await userApi.getUsersList();

      const allworkgroups = await workgroupApi.getWorkgroups();
      this.workgroups = allworkgroups.map((workgroup) => {
        return {
          Name: workgroup.Name,
          Code: workgroup.Reference,
        };
      });
    },

    async saveUser(index) {
      //PhoneJSON is a JSON, it is string in backend, so we need to stringify it first.
      //By avoiding the error on the control, we have to clone a user and stringfy the PhoneJSON property.
      let updateUser = this.$lodash.cloneDeep(this.usersFullDataSet[index]); //Have to clone first to avoid controll error.

      updateUser.PhoneJSON = JSON.stringify(updateUser.PhoneJSON);
      let val = await userApi.putUser(updateUser);

      let obj = this.$lodash.pick(val, [
        "Reference",
        "IntReference",
        "FirstName",
        "Surname",
        "IsActive",
      ]);

      val.PhoneJSON = JSON.parse(val.PhoneJSON);
      this.$set(this.usersFullDataSet, index, val);
      this.$set(this.users, index, obj);
    },

    async permanentlyDelete(index, user) {
      try {
        await userApi.deleteUser(user);
        this.users.splice(index, 1);
      } catch (err) {
        this.$log.info(err);
        throw err;
      }
    },

    async getUser(reference) {
      let successInd = true;
      let errorMessage = "";

      this.users = [];

      await userApi.getUserByReference(reference, true).then(
        (user) => {
          this.users.push(user);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });

      this.isSearched = true;
    },
  },

  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.loadFormData);
    if (
      Object.keys(this.$route.params).length > 0 &&
      this.$route.params.isBarSearch
    ) {
      this.$bus.$off("search-bar-search");
      this.$bus.$on("search-bar-search", (searchString) => {
        this.getUser(searchString);
      });
    } else this.loadFormData();
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>
