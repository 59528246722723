var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("School Statistics and Details Report"),
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Company Section",
            itemValueProperty: "Code",
            items: _vm.getCompnaySection,
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.companySection,
            callback: function ($$v) {
              _vm.companySection = $$v
            },
            expression: "companySection",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Booking Status",
            itemValueProperty: "Code",
            items: _vm.getBookingStatus,
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.bookingStatus,
            callback: function ($$v) {
              _vm.bookingStatus = $$v
            },
            expression: "bookingStatus",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Years",
            itemValueProperty: "Code",
            items: _vm.getYear,
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.year,
            callback: function ($$v) {
              _vm.year = $$v
            },
            expression: "year",
          },
        }),
        _c("asoftDatePicker", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Date",
            autoHide: "",
            displayDateFormat: "D MMM YYYY",
            dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
            setTime: "00:00:00.000",
            bg: "",
          },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v
            },
            expression: "date",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }