<template>
  <mdb-modal
    size="lg"
    v-if="dialogVisible == true"
    :show="dialogVisible"
    id="itineraryEntryDialog"
    @close="onCancel"
  >
    <mdb-modal-header>
      <h1 class="text-center">Itinerary Day Note Edit</h1>
    </mdb-modal-header>
    <mdb-modal-body>
      <div v-for="(dayNote, index) in itinerary.ItineraryDayNotes" :key="index">
        <div v-if="dayNote.Edit == true" class="mb-3">
          <div class="d-flex flex-row flex-nowrap">
            <asoftDatePicker
              v-model="dayNote.Date"
              label="Date"
              autoHide
              displayDateFormat="D MMM YYYY"
              dataDateFormat="YYYY-MM-DDTHH:mm:ss"
              setTime="00:00:00.000"
              bg
              class="flex-grow-1 mr-2"
              style="min-width: 140px"
              :width="300"
            />
            <awgt-std-dropdown
              :items="getItineraryDayNoteTypes(false)"
              itemValueProperty="Code"
              itemTextProperty="Name"
              label="Itinerary Day Note Type"
              class="flex-grow-1"
              style="width: 180px"
              v-model="dayNote.ItineraryDayNoteType"
              bg
            ></awgt-std-dropdown>
          </div>
          <mdb-wysiwyg
            v-model="dayNote.Note"
            style="height: 200px"
          ></mdb-wysiwyg>
          <awgt-std-button
            type="button"
            class="command-button mt-2"
            @click="onDayNoteEditClose(dayNote)"
          >
            <mdb-icon icon="check" class="mr-1" />Close
          </awgt-std-button>
        </div>
        <div v-else>
          <div class="font-weight-bold">
            <span class="mr-4">{{
              convertDateFormat(
                dayNote.Date,
                "YYYY-MM-DDTHH:mm:ss",
                "D MMM YYYY"
              )
            }}</span>
            <span>{{
              getItineraryDayNoteTypesByCode(dayNote.ItineraryDayNoteType)
            }}</span>
            <span class="float-right">
              <awgt-std-button
                type="button"
                title="Edit"
                class="command-button mr-2"
                @click="onDayNoteEdit(dayNote)"
              >
                <mdb-icon icon="edit" class="mr-1" />Edit
              </awgt-std-button>
              <awgt-std-button
                type="button"
                title="Delete"
                class="command-button"
                @click="onDayNoteDelete(index)"
              >
                <mdb-icon icon="trash-alt" class="mr-1" />Delete
              </awgt-std-button>
            </span>
          </div>
          <div v-html="dayNote.Note" class="ml-2"></div>
        </div>
      </div>
      <div class="float-right">
        <awgt-std-button
          type="button"
          title="Add"
          class="command-button"
          @click="onDayNoteAdd()"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="float-right">
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="onSave()"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button"
          @click="onCancel()"
        >
          <mdb-icon icon="times" class="mr-1" />Cancel
        </awgt-std-button>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import {
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

import { mapGetters } from "vuex";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import { sharedMethods } from "@/shared/shared";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import itineraryApi from "@/api/ItineraryApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";

export default {
  components: {
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    asoftDatePicker,
    AwgtStdDropdown,
    mdbWysiwyg,
    AwgtStdButton,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      itinerary: {},
    };
  },

  computed: {
    ...mapGetters([
      "getEmptyItineraryDayNote",
      "getItineraryDayNoteTypes",
      "getItineraryDayNoteTypesByCode",
    ]),
  },

  watch: {
    value: {
      handler(to) {
        this.itinerary = this.$lodash.cloneDeep(to);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...sharedMethods,

    onCancel() {
      this.$emit("close");
    },

    onDayNoteAdd() {
      this.itinerary.ItineraryDayNotes.push(
        this.$lodash.cloneDeep(this.getEmptyItineraryDayNote)
      );
    },

    onDayNoteEdit(dayNote) {
      this.$set(dayNote, "Edit", true);
    },

    onDayNoteEditClose(dayNote) {
      this.$set(dayNote, "Edit", false);
    },

    onDayNoteDelete(index) {
      this.itinerary.ItineraryDayNotes.splice(index, 1);
    },

    onSave() {
      this.saveItineraryDayNotes(this.itinerary).catch(() => {
        // Errors have been logged in the itinerary api, so we suppress errors here
      });
      this.$emit("input", this.itinerary);
      this.$emit("close");
    },

    async saveItineraryDayNotes(itinerary) {
      this.itinerary = await itineraryApi.putItineraryAsync(itinerary);

      //TODO:Need to take a fresh snapshot after a SUCCESSFUL save.
      //this.itinerarySnapshot = this.$lodash.cloneDeep(this.itinerary);
    },
  },
};
</script>

<style></style>
