<template>
  <timePicker
    v-model="time"
    :hoursFormat="12"
    :label="label"
    :style="{ width: width, height: height }"
    :color="color"
    :placeholder="placeholder"
    :id="id"
    :doneLabel="doneLabel"
    :clearLabel="clearLabel"
    :cancelLabel="cancelLabel"
    :icon="icon"
    :iconClass="iconClass"
    :far="far"
    :fab="fab"
    :fal="fal"
    :outline="outline"
    :bg="bg"
    :disabled="disabled"
  ></timePicker>
</template>
<script>
import timePicker from "./TimePicker";
export default {
  props: {
    value: {
      type: String,
    },
    outputFormat: {
      type: String,
      required: true,
    },
    displayFormat: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 80,
    },
    color: {
      type: String,
      default: "primary-color",
    },
    placeholder: {
      type: String,
    },
    id: {
      type: [String, Boolean],
      default: false,
    },
    doneLabel: {
      type: String,
      default: "Done",
    },
    clearLabel: {
      type: String,
      default: "Clear",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    icon: String,
    iconClass: String,
    far: Boolean,
    fab: Boolean,
    fal: Boolean,
    outline: Boolean,
    bg: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    timePicker,
  },
  data() {
    return {
      time: "",
    };
  },
  watch: {
    time() {
      let outputTime = "";

      if (this.time.length > 0)
        outputTime = this.$moment(this.time, this.displayFormat).format(
          this.outputFormat
        );

      //this.$log.info("time is %s", outputTime);
      this.$emit("input", outputTime);
    },
    value(to) {
      //this.$log.info("input time is %s", to);

      if (to == null) this.time = "";

      if (to.length > 0)
        this.time = this.$moment(to, this.outputFormat).format(
          this.displayFormat
        );
    },
  },
  created() {
    //this.$log.info("input time is %s", this.value);

    if (this.value == null) this.time = "";

    if (this.value.length > 0)
      this.time = this.$moment(this.value, this.outputFormat).format(
        this.displayFormat
      );
  },
};
</script>
