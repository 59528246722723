var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap mt-3 mb-2 pdf-button" },
      [
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: { click: _vm.scaleD },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
            _vm._v("Zoom In "),
          ],
          1
        ),
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: { click: _vm.scaleX },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "minus" } }),
            _vm._v("Zoom Out "),
          ],
          1
        ),
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: { click: _vm.pdfDownload },
          },
          [
            _c("mdb-icon", {
              staticClass: "mr-1",
              attrs: { icon: "download", far: "" },
            }),
            _vm._v("Download "),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.url.length > 0,
            expression: "url.length > 0",
          },
        ],
        staticClass: "pdfIn",
        staticStyle: { width: "100%", height: "700px" },
      },
      [
        _c(
          "div",
          _vm._l(_vm.pageTotalNum, function (i) {
            return _c("pdf", {
              key: i,
              ref: "pdf",
              refInFor: true,
              staticStyle: { width: "70%", margin: "0 auto" },
              attrs: { src: _vm.url, page: i },
              on: {
                error: function ($event) {
                  return _vm.pdfError($event)
                },
              },
            })
          }),
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }