import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

const url = "order";
const logSource = loggingSource.UIOrder;

export default {
  async getOrderAsync(orderReference) {
    try {
      logger.get(logSource).info("getOrderAsync...");

      if (!orderReference || orderReference.length == 0) {
        throw "Reference must not be null";
      }

      logger
        .get(logSource)
        .debug("Parameters => orderReference: %s", orderReference);
      logger.get(logSource).time("getOrderAsync call");

      const response = await api.client
        .get(`${url}/${orderReference}`)
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("getOrderAsync call");
      const order = response.data;

      logger.get(logSource).debug("getOrderAsync result: %s", order);

      return order;
    } catch (error) {
      logger.get(logSource).timeEnd("getOrderAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...getOrderAsync");
    }
  },

  async getByBookingReferenceAsync(bookingReference) {
    try {
      logger.get(logSource).info("getByBookingReferenceAsync...");

      logger
        .get(logSource)
        .debug("Parameters => bookingReference: %s", bookingReference);

      logger.get(logSource).time("getByBookingReferenceAsync call");

      const response = await api.client
        .get(`/booking/${bookingReference}/order`)
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("getByBookingReferenceAsync call");
      const orders = response.data;
      logger
        .get(logSource)
        .debug("getByBookingReferenceAsync result: %s", orders);
      return orders;
    } catch (error) {
      logger.get(logSource).error(error);
      logger.get(logSource).timeEnd("getByBookingReferenceAsync call");
      throw error;
    } finally {
      logger.get(logSource).info("...getByBookingReferenceAsync");
    }
  },

  async postOrder(orderPayload) {
    try {
      logger.get(logSource).info("postOrder...");
      logger
        .get(logSource)
        .debug("Parameters => orderPayload: %s", orderPayload);

      logger.get(loggingSource.UIClient).time("postOrder call");
      const response = await api.client
        .post(url, orderPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("postOrder call");
      /***
       * Special to server repsonse .data.Data
       */
      const order = response.data.Data;

      logger.get(logSource).trace("postOrder response: %s", response);
      logger.get(logSource).debug("postOrder client: %s", order);

      return order;
    } catch (error) {
      logger.get().timeEnd("postOrder call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...postOrder");
    }
  },

  async putOrder(orderPayload) {
    try {
      logger.get(logSource).info("putOrder...");
      logger
        .get(logSource)
        .debug("Parameters => orderPayload: %s", orderPayload);

      logger.get(loggingSource.UIClient).time("putOrder call");
      const response = await api.client
        .put(url, orderPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("putOrder call");
      /***
       * Special to server repsonse .data.Data
       */
      const order = response.data.Data;

      logger.get(logSource).trace("putOrder response: %s", response);
      logger.get(logSource).debug("putOrder client: %s", order);

      return order;
    } catch (error) {
      logger.get().timeEnd("putOrder call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...putOrder");
    }
  },

  async deleteAsync(payload) {
    try {
      logger.get(logSource).info("deleteAsync...");
      logger.get(logSource).debug("Parameters => payload: %s", payload);

      logger.get(logSource).time("deleteAsync call");
      await api.client
        .delete(
          `${url}/${payload.Reference}?concurrencyRV=${encodeURIComponent(
            payload.ConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("deleteAsync call");
    } catch (error) {
      logger.get(logSource).timeEnd("deleteAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...deleteAsync");
    }
  },
};
