<template>
  <div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center mb-3">
      <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
        <awgt-input
          id="pricingComponents_Past"
          type="checkbox"
          v-model="pricingComponents_Past"
          name="pricingComponents"
          label="Past"
          class="mx-2"
          style="min-width: 60px"
        ></awgt-input>
        <awgt-input
          id="pricingComponents_Current"
          type="checkbox"
          v-model="pricingComponents_Current"
          name="pricingComponents"
          label="Current"
          class="mx-2"
          style="min-width: 60px"
        ></awgt-input>
        <awgt-input
          id="pricingComponents_Future"
          type="checkbox"
          v-model="pricingComponents_Future"
          name="pricingComponents"
          label="Future"
          class="mx-2"
          style="min-width: 60px"
        ></awgt-input>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
      >
        <awgt-std-switch
          offLabel="edit"
          onLabel="reorder"
          @getValue="
            (value) => {
              reorderItemsInd = value;
            }
          "
          class="mr-3"
        ></awgt-std-switch>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onAddCommercialProductLine"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
    </div>

    <div class="d-flex flex-grow-1">
      <span class="flex-fill font-weight-bolder pl-2">Component</span>
      <span class="flex-fill font-weight-bolder">Market Category</span>
      <span class="flex-fill font-weight-bolder">Pricing</span>
      <span class="flex-fill font-weight-bolder">Validity</span>
      <span style="width: 60px"></span>
    </div>
    <mdb-card
      v-for="(pc, index) in filteredData"
      :key="index"
      class="py-1 mb-1"
      :style="[
        { 'background-color': '#f5f5f5' },
        pc.priceCurrency == 'C' ? { color: 'inherit' } : { color: 'grey' },
      ]"
    >
      <div
        class="d-flex align-items-center"
        @click="toggleCommercialProductLineExpansion(index)"
        style="
          border: none;
          background-color: inherit;
          text-align: left;
          color: inherit;
        "
      >
        <div class="flex-fill">
          <div class="d-flex">
            <span class="flex-fill ml-2">
              {{ pc.Chronology[pc.ChronologyHeaderIdx].Name }}</span
            >
            <span class="flex-fill ml-2"
              >{{
                pc.Chronology[pc.ChronologyHeaderIdx].MarketCategories.map(
                  (mt) => {
                    return mt.Name;
                  }
                ).join(", ")
              }}
            </span>
            <span class="flex-fill ml-2">
              <div
                v-for="(p, ipl) in pc.Chronology[pc.ChronologyHeaderIdx]
                  .ProductLinePrices"
                v-bind:key="ipl"
              >
                <span
                  >{{
                    formatPricing(pc.Chronology[pc.ChronologyHeaderIdx], p)
                  }}
                  <button
                    v-if="
                      pc.Chronology[pc.ChronologyHeaderIdx]
                        .IsIndicativePricingInd == true
                    "
                    type="button"
                    style="border: none; background-color: inherit; color: grey"
                    :title="getTooltipsTextByCode(11)"
                    v-tippy="{ position: 'bottom', arrow: true }"
                  >
                    <mdb-icon
                      far
                      icon="exclamation-circle"
                      style="cursor: pointer"
                      :style="{
                        color: 'orange',
                      }"
                      size="1x"
                    /></button></span
                ><br />
              </div>
            </span>
            <span
              v-if="pc.Chronology[pc.ChronologyHeaderIdx].ActiveToDt == null"
              class="flex-fill ml-2"
            >
              From
              {{
                convertDateFormat(
                  pc.Chronology[pc.ChronologyHeaderIdx].ActiveFromDt,
                  $constants.DATETIMEFORMAT_Json,
                  $constants.DATETIMEFORMAT_Presentation
                )
              }}
            </span>
            <span v-else class="flex-fill ml-2">
              {{
                convertDateFormat(
                  pc.Chronology[pc.ChronologyHeaderIdx].ActiveFromDt,
                  $constants.DATETIMEFORMAT_Json,
                  $constants.DATETIMEFORMAT_Presentation
                )
              }}
              -
              {{
                convertDateFormat(
                  pc.Chronology[pc.ChronologyHeaderIdx].ActiveToDt,
                  $constants.DATETIMEFORMAT_Json,
                  $constants.DATETIMEFORMAT_Presentation
                )
              }}
            </span>
          </div>
        </div>
        <span v-if="reorderItemsInd == true" class="float-right mr-0">
          <button
            v-if="pc.SortOrder > 0"
            @click.stop="onReorderUp(pc)"
            style="border: none; background-color: inherit"
            type="button"
          >
            <mdb-icon
              icon="arrow-up"
              size="2x"
              class="py-auto px-2"
              color="primary"
            />
          </button>
          <div v-else style="min-width: 56px; display: inline-block"></div>
          <button
            v-if="pc.SortOrder < filteredData.length - 1"
            @click.stop="onReorderDown(pc)"
            style="border: none; background-color: inherit"
            type="button"
          >
            <mdb-icon
              icon="arrow-down"
              size="2x"
              class="py-auto px-2"
              color="primary"
            />
          </button>
          <div v-else style="min-width: 56px; display: inline-block"></div>
        </span>
        <span v-else style="width: 80px" class="float-right mr-1">
          <span>
            <mdb-icon
              v-if="commercialProductLineExpansion[index] == true"
              icon="angle-up"
              size="2x"
              class="py-auto"
              color="primary"
            />
            <mdb-icon
              v-else
              icon="angle-down"
              size="2x"
              class="py-auto"
              color="primary"
            />
          </span>
          <button
            @click.stop="initiateEdit(pc.CommercialProductLineId)"
            style="border: none; background-color: inherit; width: 43px"
            type="button"
          >
            <mdb-icon color="primary" icon="edit" size="2x" />
          </button>
        </span>
      </div>
      <expansion-region
        :toggle="commercialProductLineExpansion[index] == true"
        useVShowConditional
        tag="div"
        class="mx-3"
      >
        <mdb-card-body class="pt-1">
          <div
            class="mb-3"
            v-if="pc.Chronology[pc.ChronologyHeaderIdx].Description.length > 0"
            v-html="pc.Chronology[pc.ChronologyHeaderIdx].Description"
          ></div>
          <mdb-card-title class="mt-0 mb-0">Price History</mdb-card-title>
          <mdb-card-text style="color: inherit">
            <div class="d-flex flex-grow-1 mx-2">
              <span class="flex-fill font-weight-bolder">Component</span>
              <span class="flex-fill font-weight-bolder">Market Category</span>
              <span class="flex-fill font-weight-bolder">Pricing</span>
              <span class="flex-fill font-weight-bolder">Validity</span>
            </div>
            <div
              v-for="pcChronology in sortedChronology(pc)"
              :key="pcChronology.CommercialProductLineChronologyId"
              class="d-flex mx-2 mb-2 align-items-center"
              :class="[
                {
                  bold:
                    new Date(pcChronology.ActiveFromDt) <= new Date() &&
                    (pcChronology.ActiveToDt == null ||
                      new Date(pcChronology.ActiveToDt) >= new Date()),
                },
              ]"
            >
              <span class="flex-fill">{{ pcChronology.Name }}</span>
              <span class="flex-fill"
                >{{
                  pcChronology.MarketCategories.map((mt) => {
                    return mt.Name;
                  }).join(", ")
                }}
              </span>
              <span class="flex-fill">
                <div
                  v-for="(p, ipl) in pcChronology.ProductLinePrices"
                  v-bind:key="ipl"
                >
                  <span
                    >{{ formatPricing(pcChronology, p) }}
                    <button
                      v-if="pcChronology.IsIndicativePricingInd == true"
                      type="button"
                      style="
                        border: none;
                        background-color: inherit;
                        color: grey;
                      "
                      :title="getTooltipsTextByCode(11)"
                      v-tippy="{ position: 'bottom', arrow: true }"
                    >
                      <mdb-icon
                        far
                        icon="exclamation-circle"
                        style="cursor: pointer"
                        :style="{
                          color: 'orange',
                        }"
                        size="1x"
                      /></button></span
                  ><br />
                </div>
              </span>
              <span v-if="pcChronology.ActiveToDt == null" class="flex-fill">
                From
                {{
                  convertDateFormat(
                    pcChronology.ActiveFromDt,
                    $constants.DATETIMEFORMAT_Json,
                    $constants.DATETIMEFORMAT_Presentation
                  )
                }}
              </span>
              <span v-else class="flex-fill">
                {{
                  convertDateFormat(
                    pcChronology.ActiveFromDt,
                    $constants.DATETIMEFORMAT_Json,
                    $constants.DATETIMEFORMAT_Presentation
                  )
                }}
                -
                {{
                  convertDateFormat(
                    pcChronology.ActiveToDt,
                    $constants.DATETIMEFORMAT_Json,
                    $constants.DATETIMEFORMAT_Presentation
                  )
                }}
              </span>
            </div>
          </mdb-card-text>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <mdb-modal
      size="lg"
      v-if="showCommercialProductLineEditDialog == true"
      :show="showCommercialProductLineEditDialog"
    >
      <commercial-product-line-edit-dialog
        v-model="editItem"
        @close="onEditDialogClose"
        @input="onInput"
        :priceAdjustments="priceAdjustments"
      ></commercial-product-line-edit-dialog>
    </mdb-modal>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss" scoped>
.flex-fill {
  flex-basis: 0 !important;
}
.bold {
  font-weight: bolder;
}
</style>

<script>
import {
  mdbIcon,
  mdbModal,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
} from "mdbvue";
import { mapGetters } from "vuex";

import ExpansionRegion from "@/components/ExpansionRegion";
import CommercialProductLineEditDialog from "./edit-CommercialProductLineEditDialog";
import { sharedMethods } from "@/shared/shared";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import { constants } from "@/shared/constants.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  // name: "product-component-pricing",
  components: {
    mdbIcon,
    AwgtStdButton,
    AwgtStdSwitch,
    ExpansionRegion,
    mdbModal,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    awgtInput,
    CommercialProductLineEditDialog,
  },
  data() {
    return {
      commercialProductLineExpansion: [],
      showCommercialProductLineEditDialog: false,
      pricingComponents_Past: false,
      pricingComponents_Current: true,
      pricingComponents_Future: true,
      commercialProductLineEditIdx: 0,
      reorderItemsInd: false,
      data: [],
      newCommercialProductLine: {
        CommercialProductLineId: 0,
        Reference: "",
        SortOrder: 0,
        Chronology: [],
        RecordStatus: "inserted",
      },
      editItem: {},
    };
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },

    priceAdjustments: {
      type: Array,
      required: true,
    },
  },

  watch: {
    value(to, from) {
      if (to != from) {
        this.data = to;
        this.findDetailHeader();
      }
    },
  },

  computed: {
    ...mapGetters(["getTooltipsTextByCode"]),

    filteredData: function () {
      let orderedData = this.data
        .slice()
        .filter(
          (v) =>
            v.RecordStatus != "deleted" &&
            v.Chronology.length > 0 &&
            ((this.pricingComponents_Past &&
              v.priceCurrency == constants.PRICECURRENCY_Past) ||
              (this.pricingComponents_Current &&
                v.priceCurrency == constants.PRICECURRENCY_Current) ||
              (this.pricingComponents_Future &&
                v.priceCurrency == constants.PRICECURRENCY_Future))
        )
        .sort((a, b) => a.SortOrder - b.SortOrder);

      //Realign the sort order with the correct order.
      for (let i = 0; i < orderedData.length; i++) {
        orderedData[i].SortOrder = i;
      }

      return orderedData;
    },
    sortedChronology: function () {
      return (pc) => this.$lodash.orderBy(pc.Chronology, "ActiveFromDt");
    },
  },
  methods: {
    ...sharedMethods,

    formatPricing(chronology, price) {
      return sharedMethods.formatPriceWithPriceType(chronology, price);
    },

    getPricingMethodTooltip(quantityUnit) {
      return sharedMethods.getPricingMethodTooltip(quantityUnit);
    },

    onReorderUp(pc) {
      //Find the item with the next lower sort order
      let idx = this.data.findIndex((o) => o.SortOrder == pc.SortOrder - 1);
      if (idx != -1) {
        // -1 indicates that the next lower item can't be found
        //Increment the item with the lower sort order
        this.data[idx].SortOrder += 1;
        //Decrement the selected item's sort order to swap them
        pc.SortOrder -= 1;
      }
    },

    onReorderDown(pc) {
      //Find the item with the next higher sort order
      let idx = this.data.findIndex((o) => o.SortOrder == pc.SortOrder + 1);
      if (idx != -1) {
        // -1 indicates that the next higher item can't be found
        //Decrement the item with the higher sort order
        this.data[idx].SortOrder -= 1;
        //Increment the selected item's sort order to swap them
        pc.SortOrder += 1;
      }
    },

    onAddCommercialProductLine() {
      this.editItem = this.$lodash.cloneDeep(this.newCommercialProductLine);
      if (this.data.length > 0) {
        this.editItem.CommercialProductLineId =
          this.$lodash.minBy(this.data, (obj) => obj.CommercialProductLineId)
            .CommercialProductLineId - 1;
      }
      let pcIndex = this.data.push(this.editItem) - 1;
      this.commercialProductLineEditIdx = pcIndex;
      this.showCommercialProductLineEditDialog = true;
    },

    initiateEdit(commercialProductLineId) {
      let pcIndex = this.$lodash.findIndex(
        this.data,
        (pc) => pc.CommercialProductLineId == commercialProductLineId
      );
      this.editItem = this.data[pcIndex];
      this.commercialProductLineEditIdx = pcIndex;
      this.showCommercialProductLineEditDialog = true;
    },

    toggleCommercialProductLineExpansion(index) {
      this.$set(
        this.commercialProductLineExpansion,
        index,
        !this.commercialProductLineExpansion[index]
      );
    },

    findDetailHeader() {
      for (let pc of this.data) {
        sharedMethods.findDetailHeaderForCommercialProductLine(pc);
      }
    },

    onEditDialogClose() {
      //If no details were added then pop the new record.
      //Delete product line if all chronology has been removed.
      if (
        this.editItem.RecordStatus == "deleted" &&
        this.editItem.Chronology.length == 0
      )
        this.data.splice(this.commercialProductLineEditIdx, 1);
      //Pop the last record off the product line list if it is inserted but not saved in the database.
      else if (
        this.editItem.RecordStatus == "inserted" &&
        this.editItem.Chronology.length == 0
      )
        this.data.pop();
      else
        sharedMethods.findDetailHeaderForCommercialProductLine(this.editItem);

      this.commercialProductLineEditIdx = 0;
      this.showCommercialProductLineEditDialog = false;
    },

    onInput(val) {
      this.$set(this.data, this.commercialProductLineEditIdx, val);
    },
  },

  mounted() {
    this.data = this.value;
    this.findDetailHeader();
  },
};
</script>
