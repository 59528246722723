var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "splitbutton" },
    [
      _c(
        "awgt-std-button",
        {
          staticClass: "mx-0 command-button",
          staticStyle: { "border-radius": "5px 0px 0px 5px" },
          attrs: { type: "button" },
          on: { click: _vm.items[_vm.selectedIndex].callback },
        },
        [_vm._v(" " + _vm._s(_vm.items[_vm.selectedIndex].text) + " ")]
      ),
      _c(
        "mdb-dropdown",
        [
          _c("mdb-dropdown-toggle", {
            staticClass: "px-2 py-1",
            staticStyle: { height: "35px", "border-radius": "0px 5px 5px 0px" },
            attrs: { slot: "toggle", color: "secondary" },
            slot: "toggle",
          }),
          _c(
            "mdb-dropdown-menu",
            {
              staticClass: "scrollbar-primary",
              attrs: { color: "primary", right: "" },
            },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "mdb-dropdown-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      _vm.selectedIndex = index
                    },
                  },
                },
                [_vm._v(_vm._s(item.text))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }