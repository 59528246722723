var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-card-header",
    _vm._g(
      _vm._b(
        { attrs: { color: _vm.backgroundColor } },
        "mdb-card-header",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        { staticClass: "float-right" },
        [
          _vm.expanded
            ? _c("mdb-icon", {
                staticClass: "pt-2",
                attrs: { icon: "angle-up", size: "2x", color: _vm.iconColor },
              })
            : _c("mdb-icon", {
                staticClass: "pt-2",
                attrs: { icon: "angle-down", size: "2x", color: _vm.iconColor },
              }),
        ],
        1
      ),
      _c("span", { style: { color: _vm.textColor } }, [_vm._t("default")], 2),
      !_vm.user.IsActive
        ? [
            _c(
              "span",
              { staticClass: "alert-text", attrs: { id: "text-alert" } },
              [_vm._v("Deleted")]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }