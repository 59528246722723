var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
        },
        [
          _vm._t("default"),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button",
              staticStyle: { "min-width": "100px" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.ModifyGroups()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "edit" } }),
              _vm._v("Modify "),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" This product is in the following product groups: "),
      _vm.resellerProductGroupsWithOtherProducts.length > 0
        ? _c(
            "div",
            { staticClass: "pl-3 py-2" },
            _vm._l(
              _vm.resellerProductGroupsWithOtherProducts,
              function (group, index) {
                return _c("ul", { key: index }, [
                  _c("li", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", [_vm._v(" " + _vm._s(group.Name) + " ")]),
                          group.OtherProductsInGroup != undefined &&
                          group.OtherProductsInGroup.length > 0
                            ? _c(
                                "div",
                                [
                                  _vm._v(" Other products in this group are: "),
                                  _vm._l(
                                    group.OtherProductsInGroup,
                                    function (inGroup, index2) {
                                      return _c("ul", { key: index2 }, [
                                        _c("li", [
                                          _vm._v(_vm._s(inGroup.Name)),
                                        ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ])
              }
            ),
            0
          )
        : _c("div", [
            _c("i", [
              _vm._v("This product is not in any Reseller Product Groups"),
            ]),
          ]),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.showEditor } },
        [
          _c("asoft-modal-header", {
            attrs: { label: "Reseller Product Groups" },
            on: { close: _vm.CloseEditor },
          }),
          _c("mdb-modal-body", [
            _vm.resellerSupplierGroups.length > 0
              ? _c("div", [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      " Following are the product groups defined for this product's reseller supplier. This product is in the selected groups. "
                    ),
                  ]),
                  _c(
                    "table",
                    { staticClass: "table table-bordered" },
                    _vm._l(_vm.resellerSupplierGroups, function (group, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          [
                            _c("awgt-input", {
                              attrs: {
                                id: "group" + index,
                                type: "checkbox",
                                name: "resellerSupplierGroup",
                                label: group.Name,
                              },
                              model: {
                                value: group.IsSelectedInd,
                                callback: function ($$v) {
                                  _vm.$set(group, "IsSelectedInd", $$v)
                                },
                                expression: "group.IsSelectedInd",
                              },
                            }),
                            group.OtherProductsInGroup.length > 0
                              ? [
                                  _vm._v(" Also containing: "),
                                  _vm._l(
                                    group.OtherProductsInGroup,
                                    function (productInGroup, index2) {
                                      return _c("ul", { key: index2 }, [
                                        _c("li", [
                                          _vm._v(_vm._s(productInGroup.Name)),
                                        ]),
                                      ])
                                    }
                                  ),
                                ]
                              : _vm._e(),
                            _vm.groupHasSeparatelySoldProducts(group) == false
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "darkred" } },
                                  [
                                    _vm._v(
                                      " Warning: This group requires a product that can be sold separately. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "td",
                          { staticStyle: { width: "120px" } },
                          [
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.OpenRenameGroup(group)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "edit" },
                                }),
                                _vm._v("Rename "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticStyle: { width: "120px" } },
                          [
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    group.OtherProductsInGroup.length > 0 ||
                                    group.IsSelectedInd == true,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.DeleteGroup(index)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "trash-alt" },
                                }),
                                _vm._v("Delete "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _c("div", { staticClass: "mb-2" }, [
                  _vm._v(
                    ' This supplier has no product groups defined. Press "Add" to create a product group. '
                  ),
                ]),
            _vm.mode == "add" || _vm.mode == "rename"
              ? _c(
                  "div",
                  [
                    _c("awgt-input", {
                      attrs: {
                        counter: "",
                        counterMaxValue: 50,
                        maxlength: "50",
                        label: "Group Name",
                        bg: "",
                      },
                      model: {
                        value: _vm.groupName,
                        callback: function ($$v) {
                          _vm.groupName = $$v
                        },
                        expression: "groupName",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button mr-2",
                            attrs: {
                              type: "button",
                              disabled: _vm.groupName.trim().length == 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.SaveGroupName()
                              },
                            },
                          },
                          [_vm._v(" Save ")]
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.CloseEdit()
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button",
                        attrs: {
                          type: "button",
                          disabled: _vm.mode != "select",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.OpenAddGroup()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "plus" },
                        }),
                        _vm._v("Add "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.CloseEditor()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }