var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row flex-wrap align-items-center" },
    [
      _c("awgt-input", {
        staticClass: "mr-2",
        staticStyle: { width: "300px" },
        attrs: {
          id: "booking-reference",
          counter: "",
          counterMaxValue: 20,
          maxlength: "20",
          label: "Booking Reference",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.BookingReference,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "BookingReference", $$v)
          },
          expression: "additionalDetails.BookingReference",
        },
      }),
      _c("awgt-input", {
        staticStyle: { width: "300px" },
        attrs: {
          id: "arrival-location",
          counter: "",
          counterMaxValue: 100,
          maxlength: "100",
          label: "Activity Location",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.ActivityLocation,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "ActivityLocation", $$v)
          },
          expression: "additionalDetails.ActivityLocation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }