<template>
  <mdb-container>
    <mdb-modal :show="visible" size="md" @close="handleCloseModal">
      <mdb-modal-header color="primary-color">
        <mdb-modal-title>Transport Origins</mdb-modal-title>
      </mdb-modal-header>

      <mdb-modal-body>
        <input
          type="text"
          placeholder="Country"
          aria-label="Username"
          aria-describedby="filter-country"
          v-model="searchTerm"
          class="my-2 w-100 form-control"
        />
        <div
          id="regionList"
          size="8"
          class="mt-4 pl-1"
          style="height: 200px; overflow-y: scroll; display: block"
        >
          <div
            class="custom-control custom-checkbox"
            v-for="item in filteredOrigins"
            :key="item.id"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="item.id"
              :checked="shouldBeChecked(item.id)"
              @change="handleCheckboxChange($event.target.checked, item.id)"
            />
            <label class="custom-control-label" :for="item.id">
              {{ item.name }}
            </label>
          </div>
        </div>
      </mdb-modal-body>

      <mdb-modal-footer>
        <awgt-std-button
          @click.native="$emit('close-region-modal', selectedOrigins)"
          class="mr-1 command-button"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          @click.native="handleCloseModal"
          class="command-button"
        >
          <mdb-icon icon="times" class="mr-1" />Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  created() {
    this.selectedOrigins = this.$lodash.cloneDeep(this.productOrigins);
  },
  components: {
    mdbContainer,
    AwgtStdButton,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  computed: {
    filteredOrigins() {
      if (this.searchTerm.length < 2)
        return this.mapTranportOrigins(this.transportOrigins);
      let searchTerm = this.searchTerm;
      return this.mapTranportOrigins(
        this.transportOrigins.filter((origin) =>
          origin.Name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    },
  },
  data() {
    return {
      selectedOrigins: [],
      searchTerm: "",
      checked: false,
    };
  },
  methods: {
    mapTranportOrigins(transportOrigins) {
      let result = [];
      for (let country of transportOrigins) {
        if (country.Children.length === 0)
          result.push({ name: country.Name, id: country.CountryRegionId });
        else {
          for (let region of country.Children) {
            result.push({
              name: `${country.Name} - ${region.Name}`,
              id: region.CountryRegionId,
            });
          }
        }
      }
      return result;
    },
    shouldBeChecked(regionId) {
      return (
        this.$lodash.find(this.selectedOrigins, {
          CountryRegionId: regionId,
        }) != undefined
      );
    },
    handleCheckboxChange(value, regionId) {
      if (value) {
        this.selectedOrigins.push({ CountryRegionId: regionId });
      } else {
        // Assumption: if unchecked the object is already in Array
        this.$lodash.remove(
          this.selectedOrigins,
          (region) => region.CountryRegionId == regionId
        );
      }
    },
    handleCloseModal() {
      this.selectedOrigins = this.$lodash.cloneDeep(this.productOrigins);
      this.$emit("close-region-modal");
    },
  },
  props: {
    productOrigins: {
      type: Array,
    },
    transportOrigins: {
      type: Array,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
