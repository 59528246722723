<template>
  <asoft-switch v-bind="$attrs" v-on="$listeners"> </asoft-switch>
</template>

<script>
import asoftSwitch from "@/components/AtomSoftware/asoftSwitch";

import variables from "@/styles/sharedVariables.scss";

export default {
  components: { asoftSwitch },
  props: {
    notchColor: { type: String, default: variables.awgtSwitchNotchColor },
  },
  mounted() {
    /**
     * JS does not allow select of pseudo elements
     * Created css class that select the state of the lever for checked
     * Then changed the css variable with JS
     *
     * notch style class is defined in common SCSS file since it cannot be scoped
     *
     * PS: Later realised it was already getting changed from common SCSS file
     */
    /* let notch = this.$el.querySelector(".lever");
     notch.classList.add("notch");
     notch.style.setProperty("--background", `${this.notchColor}`);
     */
  },
};
</script>

//
<style lang="scss">
// div.switch label input[type="checkbox"]:checked + .notch::after {
//   background-color: var(--background) !important;
// }
//
</style>
