<template>
  <mdb-container fluid>
    <h1 class="text-center">Edit Workgroup</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1 mb-2">
      <div
        v-if="workgroup.Edit == true"
        class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
      >
        <mdb-icon icon="users" color="primary" class="mx-2" size="2x" />
        <awgt-input
          id="workgroup-name-input"
          v-model="workgroupName"
          counter
          :counterMaxValue="50"
          maxlength="50"
          label="Workgroup name"
          bg
          style="min-width: 300px"
          class="flex-grow-1 mx-2 my-0"
        ></awgt-input>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <awgt-std-button
            type="button"
            title="Save"
            class="command-button mx-2"
            @click="onSaveWorkgroup"
          >
            <mdb-icon class="mr-1" icon="check" />Save
          </awgt-std-button>
          <awgt-std-button
            v-if="status != 'add'"
            type="button"
            title="Cancel"
            class="command-button mx-2"
            @click="onCancelWorkgroup"
          >
            <mdb-icon class="mr-1" icon="times" />Cancel
          </awgt-std-button>
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-row flex-wrap flex-grow-1 align-items-center my-2"
      >
        <mdb-icon icon="users" color="primary" class="mx-2" size="2x" />
        <span class="font-weight-bolder heading">{{ workgroup.Name }}</span>
        <awgt-std-button
          type="button"
          title="Edit"
          class="command-button mx-2"
          @click="onEditWorkgroup"
        >
          <mdb-icon class="mr-1" icon="edit" />Edit
        </awgt-std-button>
      </div>
    </div>
    <div class="d-flex flex-row flex-nowrap justify-content-start flex-grow-1">
      <div v-if="showDropDown" class="d-flex flex-row flex-wrap flex-grow-1">
        <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
        <awgt-std-dropdown
          label="Available user"
          style="min-width: 300px"
          class="flex-grow-1 mx-2"
          :items="availableUsers"
          itemValueProperty="Reference"
          :itemTextProperty="(user) => user.FirstName + ' ' + user.Surname"
          v-model="selectedUser"
          bg
        ></awgt-std-dropdown>
        <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
          <awgt-std-button
            type="button"
            title="Save"
            class="command-button mx-2"
            @click="onAddUser"
            :disabled="this.workgroup.Edit == true || selectedUser == null"
          >
            <mdb-icon class="mr-1" icon="check" />Save
          </awgt-std-button>
          <awgt-std-button
            type="button"
            title="Cancel"
            class="command-button mx-2"
            @click="onCancelAddMemebr"
            :disabled="this.workgroup.Edit == true"
          >
            <mdb-icon class="mr-1" icon="times" />Cancel
          </awgt-std-button>
        </div>
      </div>
    </div>
    <div>
      <asoftTreeview
        :value="treedata"
        childIndent="20px"
        :selectableLeafNodes="true"
        @click="onClick"
        @dblclick="onDblClick"
        :multiselectInd="true"
        childrenPropertyName="Children"
        :isCollapsible="false"
        class="mx-2"
      >
        <template v-slot:default="item">
          <span :class="[{ selecteditem: item.selected == true }]">
            <i class="fas fa-male"></i>
            {{ item.Text }}</span
          >
        </template>
      </asoftTreeview>
      <div
        class="d-flex flex-row flex-wrap justify-content-start flex-grow-1 mt-2"
      >
        <awgt-std-button
          id="add-user"
          type="button"
          title="Add"
          class="command-button mx-2"
          @click="onAddMemebr"
          :disabled="
            this.workgroup.Reference.length == 0 || this.workgroup.Edit == true
          "
        >
          <mdb-icon class="mr-1" icon="user-plus" />Add Members
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Associate"
          class="command-button mx-2"
          @click="onAssociateNodes"
          :disabled="
            this.associatedNodes.length < 2 || this.workgroup.Edit == true
          "
        >
          <mdb-icon class="mr-1" icon="link" />Associate
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Disassociate"
          class="command-button mx-2"
          @click="onDisassociate"
          :disabled="
            this.associatedNodes.length < 2 || this.workgroup.Edit == true
          "
        >
          <mdb-icon class="mr-1" icon="unlink" />Disassociate
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Delete"
          class="command-button mx-2"
          @click="onRemoveUser"
          :disabled="
            this.currentNode == null ||
            this.associatedNodes.length == 2 ||
            this.workgroup.Edit == true
          "
        >
          <mdb-icon class="mr-1" icon="user-minus" />Remove Member
        </awgt-std-button>
      </div>
    </div>

    <div class="my-2 float-right">
      <awgt-std-button
        type="button"
        title="Edit"
        class="command-button mx-2"
        @click="onClose"
      >
        <mdb-icon class="mr-1" icon="times" />Close
      </awgt-std-button>
    </div>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbIcon, mdbContainer } from "mdbvue";
import { mapGetters } from "vuex";
import workgroupApi from "@/api/workgroupApi.js";
import userApi from "@/api/userApi.js";
import asoftTreeview from "@/components/AtomSoftware/asoftTreeview.vue";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return {
      user: {},
      managers: [],
      supervisors: [],
      selectedManager: "",
      selectedSupervisor: "",
      workgroup: {
        Reference: "",
        Name: "",
        Supervisors: [],
        Managers: [],
        Consultants: [],
      },
      selectedManagers: [],
      availableManagers: [],
      selectedSupervisors: [],
      availableSupervisors: [],
      selectedConsultants: [],
      availableConsultants: [],
      seniorUsers: [],
      formSubmissionErrors: [],
      showDropDown: false,
      selectedUser: null,
      currentNode: null,
      availableUsers: [],
      treedata: [],
      associatedNodes: [],
      users: [],
      workgroupSnapshot: {},
      workgroupDirtyInd: false,
      workgroupName: "",
      status: null,
    };
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  components: {
    mdbIcon,
    AwgtStdButton,
    mdbContainer,
    asoftTreeview,
    AwgtStdDropdown,
    awgtInput,
  },
  computed: {
    ...mapGetters(["getEmptyWorkgroup", "getConsultancyRoleNameById"]),
  },
  methods: {
    onClick(currentNode) {
      if (currentNode.selected == true) {
        this.associatedNodes.push(currentNode);
        if (this.associatedNodes.length > 2) {
          this.associatedNodes = new Array();
          this.clearSelectedNodes(this.treedata);
        }
      } else {
        this.associatedNodes.splice(
          this.associatedNodes.findIndex(
            (i) => i.Reference == currentNode.Reference
          ),
          1
        );
      }
      this.currentNode =
        this.associatedNodes[0] == undefined ? null : this.associatedNodes[0];
    },
    onDblClick() {
      this.associatedNodes = new Array();
      this.clearSelectedNodes(this.treedata);
    },

    onEditWorkgroup() {
      this.workgroup.Edit = true;
      this.workgroup = this.$lodash.cloneDeep(this.workgroup);
    },

    async onSaveWorkgroup() {
      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.workgroup.Name = this.workgroupName;
        await this.saveWorkgroup(this.workgroup)
          .catch((error) => {
            this.formSubmissionErrors = error.message.split(",");
          })
          .then(() => {
            if (this.formSubmissionErrors.length == 0) {
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: [
                  `The workgroup ${this.workgroup.Name} has been saved.`,
                ],
              });
              this.status = null;
              this.workgroup.Edit = false;
              this.workgroup = this.$lodash.cloneDeep(this.workgroup);
              this.snapshotWorkgroup(this.workgroup);
            } else {
              this.$emit("header-message", {
                isSuccessInd: false,
                Message: this.formSubmissionErrors,
              });
            }
            this.onUpdateFormData();
          });
      }
    },
    onCancelWorkgroup() {
      this.workgroup.Edit = false;
      this.workgroupName = this.workgroup.Name;
      this.workgroup = this.$lodash.cloneDeep(this.workgroup);
    },
    onAddUser() {
      this.addUser(this.workgroup.Reference, this.selectedUser)
        .catch((error) => {
          this.formSubmissionErrors = error.message.split(",");
        })
        .then(() => {
          if (this.formSubmissionErrors.length == 0) {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [
                `The user ${
                  this.availableUsers.find(
                    (u) => u.Reference == this.selectedUser
                  ).FirstName
                } ${
                  this.availableUsers.find(
                    (u) => u.Reference == this.selectedUser
                  ).Surname
                } has been added.`,
              ],
            });
            this.availableUsers = this.availableUsers.filter(
              (item) => item.Reference != this.selectedUser
            );
            this.snapshotWorkgroup(this.workgroup);
          } else {
            this.$emit("header-message", {
              isSuccessInd: false,
              Message: this.formSubmissionErrors,
            });
          }
          this.onUpdateFormData();
        });
    },

    onRemoveUser() {
      if (
        this.currentNode.ParentUserId != undefined ||
        this.currentNode.Children.length > 0
      ) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: [
            "This user cannot be removed from this workgroup as they are being managed or are managing others.",
          ],
        });
        this.onUpdateFormData();
      } else {
        const userReference = this.users.find(
          (u) => u.Reference == this.currentNode.Reference
        ).Reference;
        const concurrencyRV = this.currentNode.WorkgroupUserConcurrencyRV;
        this.removeUser(this.workgroup.Reference, userReference, concurrencyRV)
          .catch((error) => {
            this.formSubmissionErrors = error.message.split(",");
          })
          .then(() => {
            if (this.formSubmissionErrors.length == 0) {
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: [
                  `The user ${this.currentNode.Text} has been removed.`,
                ],
              });
              this.availableUsers.push(
                this.users.find(
                  (u) => u.Reference == this.currentNode.Reference
                )
              );
              this.snapshotWorkgroup(this.workgroup);
            } else {
              this.$emit("header-message", {
                isSuccessInd: false,
                Message: this.formSubmissionErrors,
              });
            }
            this.onUpdateFormData();
          });
      }
    },

    async addUser(workgroupReference, userReference) {
      this.workgroup = await workgroupApi.postWorkgroupMember(
        workgroupReference,
        userReference
      );
    },

    async removeUser(workgroupReference, userReference, concurrencyRV) {
      this.workgroup = await workgroupApi.deleteWorkgroupMember(
        workgroupReference,
        userReference,
        concurrencyRV
      );
    },
    onCancelAddMemebr() {
      this.showDropDown = false;
    },
    onAddMemebr() {
      this.showDropDown = true;
    },
    onAssociateNodes() {
      if (this.associatedNodes[0].Level == this.associatedNodes[1].Level) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: ["These users have the same level so cannot be associated."],
        });
        this.onUpdateFormData();
      } else {
        let lowerLevelNode =
          this.associatedNodes[0].Level < this.associatedNodes[1].Level
            ? this.associatedNodes[0]
            : this.associatedNodes[1];

        if (lowerLevelNode.ParentUserId != undefined) {
          this.$emit("header-message", {
            isSuccessInd: false,
            Message: [
              "The more junior user is being managed already they cannot be associated again.",
            ],
          });
          this.onUpdateFormData();
        } else {
          const parentReference =
            this.associatedNodes[0].Level > this.associatedNodes[1].Level
              ? this.associatedNodes[0].Reference
              : this.associatedNodes[1].Reference;

          const childReference =
            this.associatedNodes[0].Level < this.associatedNodes[1].Level
              ? this.associatedNodes[0].Reference
              : this.associatedNodes[1].Reference;

          const parentUserReference = this.users.find(
            (u) => u.Reference == parentReference
          ).Reference;

          const childUserReference = this.users.find(
            (u) => u.Reference == childReference
          ).Reference;

          this.associateWorkgroupUsers(parentUserReference, childUserReference)
            .catch((error) => {
              this.formSubmissionErrors = error.message.split(",");
            })
            .then(() => {
              if (this.formSubmissionErrors.length == 0) {
                this.$emit("header-message", {
                  isSuccessInd: true,
                  Message: [`These users are associated.`],
                });
                this.snapshotWorkgroup(this.workgroup);
              } else {
                this.$emit("header-message", {
                  isSuccessInd: false,
                  Message: this.formSubmissionErrors,
                });
              }
              this.onUpdateFormData();
            });
        }
      }
    },
    onDisassociate() {
      if (
        this.associatedNodes[0].ParentUserId == this.associatedNodes[1].UserId
      ) {
        const parentReference = this.associatedNodes[1].Reference;
        const childReference = this.associatedNodes[0].Reference;

        const parentUserReference = this.users.find(
          (u) => u.Reference == parentReference
        ).Reference;

        const childUserReference = this.users.find(
          (u) => u.Reference == childReference
        ).Reference;

        this.disassociateWorkgroupUsers(
          this.workgroup.Reference,
          parentUserReference,
          childUserReference,
          this.associatedNodes[0].ChildWorkgroupUserToUserConcurrencyRV
        )
          .catch((error) => {
            this.formSubmissionErrors = error.message.split(",");
          })
          .then(() => {
            if (this.formSubmissionErrors.length == 0) {
              this.onUpdateFormData();
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: ["These users are disassociated."],
              });
              this.snapshotWorkgroup(this.workgroup);
            } else {
              this.$emit("header-message", {
                isSuccessInd: false,
                Message: this.formSubmissionErrors,
              });
            }
            this.onUpdateFormData();
          });
      } else if (
        this.associatedNodes[1].ParentUserId == this.associatedNodes[0].UserId
      ) {
        const parentReference = this.associatedNodes[0].Reference;
        const childReference = this.associatedNodes[1].Reference;

        const parentUserReference = this.users.find(
          (u) => u.Reference == parentReference
        ).Reference;

        const childUserReference = this.users.find(
          (u) => u.Reference == childReference
        ).Reference;
        this.disassociateWorkgroupUsers(
          this.workgroup.Reference,
          parentUserReference,
          childUserReference,
          this.associatedNodes[1].ChildWorkgroupUserToUserConcurrencyRV
        )
          .catch((error) => {
            this.formSubmissionErrors = error.message.split(",");
          })
          .then(() => {
            if (this.formSubmissionErrors.length == 0) {
              this.$emit("header-message", {
                isSuccessInd: true,
                Message: ["They are disassociated."],
              });
              this.snapshotWorkgroup(this.workgroup);
            } else {
              this.$emit("header-message", {
                isSuccessInd: false,
                Message: this.formSubmissionErrors,
              });
            }
            this.onUpdateFormData();
          });
      } else {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: [
            "These users are not associated so you cannot disassociated them.",
          ],
        });
        this.onUpdateFormData();
      }
    },
    async associateWorkgroupUsers(parentUserReference, childUserReference) {
      this.workgroup = await workgroupApi.postAssociateWorkgroupUsers(
        this.workgroup.Reference,
        parentUserReference,
        childUserReference
      );
    },
    async disassociateWorkgroupUsers(
      workgroupReference,
      parentUserReference,
      childUserReference,
      concurrencyRV
    ) {
      this.workgroup = await workgroupApi.deleteWorkgroupUserAssociation(
        workgroupReference,
        parentUserReference,
        childUserReference,
        concurrencyRV
      );
    },
    onUpdateFormData() {
      this.formSubmissionErrors = [];
      this.treedata = this.workgroup.TreeData;
      this.clearSelectedNodes(this.treedata);
      this.associatedNodes = new Array();
      this.currentNode = null;
      this.selectedUser = null;
    },
    clearSelectedNodes(nodeArray) {
      for (let n of nodeArray) {
        if (n.selected) n.selected = false;
        if (n.Children != undefined && n.Children.length > 0)
          this.clearSelectedNodes(n.Children);
      }
    },
    onClose() {
      this.$router.go(-1);
    },
    snapshotWorkgroup(workgroup) {
      this.workgroupSnapshot = this.$lodash.cloneDeep(workgroup);

      this.workgroupDirtyInd = false;
    },
    isFormDirty() {
      if (this.workgroupDirtyInd == true) return true;

      this.workgroupDirtyInd =
        this.$lodash.isEqual(
          this.workgroup.Name,
          this.workgroupSnapshot.Name
        ) == false;
      return this.workgroupDirtyInd;
    },
    validateForm() {
      this.formSubmissionErrors = [];
      if (this.workgroupName.length == 0) {
        this.formSubmissionErrors.push("Name is required.");
      }
      if (this.workgroupName.length != 0 && this.workgroupName.length > 50) {
        this.formSubmissionErrors.push(
          "Name must be no more than 50 character(s) in length."
        );
      }
      return this.formSubmissionErrors.length == 0;
    },
    async loadFormData(workgroupReference) {
      this.users = await userApi.getUsers();
      for (let u of this.users) {
        if (
          u.Workgroups.find((ele) => ele.Reference == workgroupReference) ==
            undefined &&
          u.Workgroups.length <= u.ConsultancyRole.MaxWorkgroupAssociations
        ) {
          this.availableUsers.push(u);
        }
      }
      if (workgroupReference == null) {
        this.workgroup = this.$lodash.cloneDeep(this.getEmptyWorkgroup);
        this.status = "add";
        this.$set(this.workgroup, "Edit", true);
      } else {
        this.workgroup = await workgroupApi.getWorkgroupByReference(
          workgroupReference
        );
        this.$set(this.workgroup, "Edit", false);
        this.treedata = this.workgroup.TreeData;
        this.workgroupName = this.workgroup.Name;
      }
    },
    async saveWorkgroup(workgroup) {
      if (workgroup.Reference == "") {
        this.workgroup = await workgroupApi.postWorkgroup(workgroup);
      } else this.workgroup = await workgroupApi.putWorkgroup(workgroup);
    },
  },

  mounted() {
    this.workgroup = this.$lodash.cloneDeep(this.getEmptyWorkgroup);
    let routeParams = this.$route.params;
    this.$log.info("RouteParams:", routeParams);
    let workgroupReference = routeParams.workgroupReference;
    this.loadFormData(workgroupReference).then(() => {
      this.snapshotWorkgroup(this.workgroup);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSaveWorkgroup().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.stateicon {
  width: 20px;
}
.typeicon {
  width: 20px;
}
.selecteditem {
  background-color: lightgrey;
}

@media (max-width: 620px) {
  .float-btn {
    margin-right: 0;
    margin-left: auto;
  }
}
</style>
