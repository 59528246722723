var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c("asoftTreeview", {
        attrs: {
          value: _vm.treeviewData,
          childIndent: "20px",
          childrenPropertyName: "Children",
          slotNamePropertyName: "CountryRegionTypeCd",
          singleClickExpand: true,
        },
        on: { click: _vm.onClick },
        scopedSlots: _vm._u([
          {
            key: "CRT_W",
            fn: function (item) {
              return [
                _c(
                  "span",
                  {
                    class: [
                      { selecteditem: item.selected == true },
                      { leafnode: item.Children.length == 0 },
                    ],
                  },
                  [
                    _c("i", { staticClass: "typeicon fas fa-globe-asia" }),
                    _vm._v(" " + _vm._s(item.Name) + " "),
                  ]
                ),
              ]
            },
          },
          {
            key: "CRT_C",
            fn: function (item) {
              return [
                _c(
                  "span",
                  {
                    class: [
                      { selecteditem: item.selected == true },
                      { leafnode: item.Children.length == 0 },
                    ],
                  },
                  [
                    _c("i", { staticClass: "typeicon far fa-flag" }),
                    _vm._v(" " + _vm._s(item.Name) + " "),
                  ]
                ),
              ]
            },
          },
          {
            key: "CRT_R",
            fn: function (item) {
              return [
                _c(
                  "span",
                  {
                    class: [
                      { selecteditem: item.selected == true },
                      { leafnode: item.Children.length == 0 },
                    ],
                  },
                  [
                    _c("i", { staticClass: "typeicon fas fa-map" }),
                    _vm._v(" " + _vm._s(item.Name)),
                  ]
                ),
              ]
            },
          },
          {
            key: "CRT_P",
            fn: function (item) {
              return [
                _c(
                  "span",
                  {
                    class: [
                      { selecteditem: item.selected == true },
                      { leafnode: item.Children.length == 0 },
                    ],
                  },
                  [
                    _c("i", { staticClass: "typeicon fas fa-map-marker-alt" }),
                    _vm._v(" " + _vm._s(item.Name)),
                  ]
                ),
              ]
            },
          },
          {
            key: "collapsed_icon",
            fn: function () {
              return [_c("i", { staticClass: "stateicon far fa-plus-square" })]
            },
            proxy: true,
          },
          {
            key: "expanded_icon",
            fn: function () {
              return [_c("i", { staticClass: "stateicon far fa-minus-square" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "mb-5 mt-2" },
        [
          _vm.checkPermission("CP_CRC")
            ? _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.currentNode == null ||
                      _vm.currentNode.CountryRegionTypeCd == "TOT_R",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onOpenModal("add")
                    },
                  },
                },
                [_vm._v(" Add ")]
              )
            : _vm._e(),
          _vm.checkPermission("CP_CRD")
            ? _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.currentNode == null ||
                      _vm.currentNode.CountryRegionTypeCd == "TOT_W",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onOpenModal("delete")
                    },
                  },
                },
                [_vm._v(" Delete ")]
              )
            : _vm._e(),
          _vm.checkPermission("CP_CRU")
            ? _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button", disabled: _vm.currentNode == null },
                  on: {
                    click: function ($event) {
                      return _vm.onOpenModal("rename")
                    },
                  },
                },
                [_vm._v(" Rename ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("asoftPrompt", {
            attrs: {
              refInitialFocus: "modalInput",
              headerClasses: "p-3 asoftPromptHeader",
              bodyClasses: "px-3 pt-3",
              footerClasses: "px-3 pb-3",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _vm.modalOperation == "add"
                      ? _c("span", [_vm._v(" Add Treenode? ")])
                      : _vm._e(),
                    _vm.modalOperation == "delete"
                      ? _c("span", [
                          _vm._v(
                            ' Delete "' +
                              _vm._s(
                                _vm.currentNode == null
                                  ? ""
                                  : _vm.currentNode.Name
                              ) +
                              '"? '
                          ),
                        ])
                      : _vm._e(),
                    _vm.modalOperation == "rename"
                      ? _c("span", [
                          _vm._v(
                            ' Rename "' +
                              _vm._s(
                                _vm.currentNode == null
                                  ? ""
                                  : _vm.currentNode.Name
                              ) +
                              '"? '
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _vm.modalOperation == "add"
                      ? _c("div", [_vm._v("Enter the new node name.")])
                      : _vm._e(),
                    _vm.modalOperation == "delete"
                      ? _c("div", [
                          _vm._v(
                            " Press 'Delete' to delete node '" +
                              _vm._s(_vm.currentNode.Name) +
                              "'. "
                          ),
                        ])
                      : _vm._e(),
                    _vm.modalOperation != "delete"
                      ? _c("awgt-input", {
                          ref: "modalInput",
                          attrs: { label: "Name", bg: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onSave()
                            },
                          },
                          model: {
                            value: _vm.newNodeName,
                            callback: function ($$v) {
                              _vm.newNodeName = $$v
                            },
                            expression: "newNodeName",
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("hr"),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onSave()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.modalOperation == "delete" ? "Delete" : "Save"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onCancel()
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.modal,
              callback: function ($$v) {
                _vm.modal = $$v
              },
              expression: "modal",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }