var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asoftPrompt", {
        ref: "handlePrompt",
        attrs: {
          headerClasses: "p-3 PromptHeader",
          bodyClasses: "px-3 pt-3",
          footerClasses: "px-3 pb-3",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.promptHeader) + " ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.promptMessage) + " ")]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function ({ onClick }) {
              return [
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end align-center" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return onClick("Ok")
                          },
                        },
                      },
                      [_vm._v(" Ok ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showPrompt,
          callback: function ($$v) {
            _vm.showPrompt = $$v
          },
          expression: "showPrompt",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }