<template>
  <mdb-container fluid>
    <h1 class="text-center">Travel Bookings Report</h1>
    <awgt-std-switch
      offLabel="Proposed"
      class="mx-3 my-2"
      onLabel="Advanced"
      @getValue="
        (value) => {
          isAdvancedReport = value;
        }
      "
    ></awgt-std-switch>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        multiple
        selectAll
        label="Workgroup"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        s
        itemValueProperty="Code"
        :items="workgroupList"
        itemTextProperty="Name"
        v-model="workgroups"
        bg
        search
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        label="Consultants"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="consultantsList"
        itemTextProperty="Name"
        v-model="consultants"
        bg
        search
        @focus="onFocusConsultantDropdown"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        label="Booking Status"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getBookingStatuses(false)"
        itemTextProperty="Name"
        v-model="bookingStatus"
        bg
        search
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-std-dropdown
        label="Year"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getYears(2017)"
        itemTextProperty="Name"
        v-model="year"
        bg
      ></awgt-std-dropdown>
      <mdb-form-inline class="flex- mx-2 px-3" style="height: 45px">
        <span class="mr-3" style="width: 120px">Time Scope</span>
        <awgt-input
          id="timeScope_Term"
          v-model="timeScope"
          type="radio"
          name="TimeScopeTerm"
          label="Term"
          radioValue="Term"
          class="mr-3"
        />
        <awgt-input
          id="timeScope_Date"
          v-model="timeScope"
          type="radio"
          name="TimeScopeDate"
          label="Date"
          radioValue="Date"
          class="mr-3"
        />
      </mdb-form-inline>
      <awgt-std-dropdown
        v-if="timeScope == 'Term'"
        multiple
        selectAll
        color="primary"
        label="Term"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getSchoolTerms(false)"
        itemTextProperty="Name"
        v-model="terms"
        bg
      ></awgt-std-dropdown>
      <div
        v-if="timeScope == 'Date'"
        class="d-flex flex-row flex-wrap flex-grow-1"
      >
        <div class="d-flex flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mx-2"
            size="2x"
          />
          <asoftDatePicker
            v-model="startDate"
            label="Start"
            autoHide
            :dataDateFormat="dataDateFormat"
            :displayDateFormat="displayDateFormat"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 110px"
            @change="(fromDt) => onFromDtChange(fromDt)"
          ></asoftDatePicker>
        </div>
        <div class="d-flex flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="calendar-alt"
            far
            class="mx-2"
            color="primary"
            size="2x"
          />
          <asoftDatePicker
            v-model="toDate"
            label="End"
            autoHide
            :dataDateFormat="dataDateFormat"
            :displayDateFormat="displayDateFormat"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 110px"
          ></asoftDatePicker>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          v-if="bookingTables.length > 0"
          type="button"
          class="command-button mx-2 float-right"
          @click="onExport"
        >
          <mdb-icon icon="file-pdf" class="mr-1" />Export
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearGenerateCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onGenerate"
        >
          <mdb-icon icon="search" class="mr-1" />Generate
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div class="mx-2" v-if="isAdvancedReportCache">
      <span>Show the following</span><br />
      <div
        class="d-flex flex-row flex-wrap mx-2"
        style="justify-content: space-between"
      >
        <awgt-input
          id="doesTermDisplayInd"
          type="checkbox"
          v-model="doesTermDisplay"
          name="termDisplay"
          label="Term"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesDestinationDisplayInd"
          type="checkbox"
          v-model="doesDestinationDisplay"
          name="destinationDisplay"
          label="Destination"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesCoachCompanyDisplayInd"
          type="checkbox"
          v-model="doesCoachCompanyDisplay"
          name="coachCompanyDisplay"
          label="Coach Company"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesStudentNumbersDisplayInd"
          type="checkbox"
          v-model="doesStudentNumbersDisplay"
          name="studentNumbersDisplay"
          label="Student Numbers"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesNotFOCAdultsDisplayInd"
          type="checkbox"
          v-model="doesNotFOCAdultsDisplay"
          name="notFOCAdultsDisplay"
          label="Not FOC Adults"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesTravelInsuranceDisplayInd"
          type="checkbox"
          v-model="doesTravelInsuranceDisplay"
          name="travelInsuranceDisplay"
          label="Travel Insurance"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesEmailDisplayInd"
          type="checkbox"
          v-model="doesEmailDisplay"
          name="emailDisplayInd"
          label="Email"
          class="my-3 pl-2 check-box-selection"
        />
      </div>
      <div
        class="d-flex flex-row flex-wrap mx-2"
        style="justify-content: space-between"
      >
        <awgt-input
          id="doesNumberOfDaysDisplayInd"
          type="checkbox"
          v-model="doesNumberOfDaysDisplay"
          name="numberOfDaysDisplay"
          label="Number of Days"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesAccommodationDisplayInd"
          type="checkbox"
          v-model="doesAccommodationDisplay"
          name="accommodationDisplay"
          label="Accommodation"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesYearGroupDisplayInd"
          type="checkbox"
          v-model="doesYearGroupDisplay"
          name="yearGroupDisplay"
          label="Year Group"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesFOCAdultsDisplayInd"
          type="checkbox"
          v-model="doesFOCAdultsDisplay"
          name="FOCAdultsDisplay"
          label="FOC Adults"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesFlightsDisplayInd"
          type="checkbox"
          v-model="doesFlightsDisplay"
          name="flightsDisplayInd"
          label="Flights"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesOrganizingTeacherDisplayInd"
          type="checkbox"
          v-model="doesOrganizingTeacherDisplay"
          name="organizingTeacherDisplayInd"
          label="Organizing Teacher"
          class="my-3 pl-2 check-box-selection"
        />
        <awgt-input
          id="doesPhoneDisplayInd"
          type="checkbox"
          v-model="doesPhoneDisplay"
          name="phoneDisplayInd"
          label="Phone"
          class="my-3 pl-2 check-box-selection"
        />
      </div>
    </div>
    <div v-if="bookingTables.length == 0 && isGenerated" class="text-center">
      <i>No travel booking result found</i>
    </div>
    <div id="tableContent">
      <div
        v-if="bookingTables.length > 0"
        class="mx-2"
        id="tableBorder"
        style="border: 1px solid black; padding: 10px 10px"
      >
        <div>
          <h4>Travel Bookings</h4>
        </div>

        <div>
          <template v-if="workgroupsCache.length > 0">
            <span class="mx-3">{{
              workgroupsCache
                .map((w) => {
                  return getWorkgroupName(w);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template v-if="consultantsCache.length > 0">
            <span class="mx-3">{{
              consultantsCache
                .map((c) => {
                  return getConsultantName(c);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template v-if="yearCache != null || yearCache != ''">
            <span class="mx-3">{{ yearCache }}</span
            ><br
          /></template>
          <template v-if="timeScopeCache == 'Term' && termsCache.length > 0">
            <span class="mx-3">{{
              termsCache
                .filter((t) => {
                  return t != "ST_X";
                })
                .map((t) => {
                  return getTermName(t);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template
            v-if="
              timeScopeCache == 'Date' &&
              (startDateCache != null || toDateCache != null)
            "
          >
            <span class="mx-3"
              >{{
                startDateCache != null
                  ? $moment(startDateCache).format("D MMM yyyy") + " to"
                  : ""
              }}
              {{
                toDateCache != null
                  ? $moment(toDateCache).format("D MMM yyyy")
                  : ""
              }}</span
            ><br
          /></template>
          <template v-if="bookingStatusCache.length > 0">
            <span class="mx-3">{{
              bookingStatusCache
                .map((bs) => {
                  return getBookingStatusName(bs);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
        </div>
        <template v-for="(groupBooking, indOfTable) in bookingTables">
          <div
            v-bind:key="indOfTable"
            style="margin: 25px 0px"
            class="html2pdf__page-break"
          >
            <div class="mt-2">
              <span class="mx-3" style="color: #923f82; font-weight: bold">{{
                getConsultantName(groupBooking.OwnerUser)
              }}</span>
            </div>
            <table class="table" :ref="'table' + indOfTable">
              <col span="1" style="width: 15%" />
              <tr style="font-weight: 10">
                <td
                  class="headers-frame-header"
                  @click="onSortByClientName(groupBooking, indOfTable)"
                >
                  Client<mdb-icon
                    v-if="sortByClientInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByClientInd[indOfTable] == false ||
                      !sortByClientInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByTBID(groupBooking, indOfTable)"
                >
                  TB ID<mdb-icon
                    v-if="sortByTBIDInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByTBIDInd[indOfTable] == false ||
                      !sortByTBIDInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  v-if="doesNumberOfDaysDisplay"
                  class="headers-frame-header"
                  @click="onSortByNoDays(groupBooking, indOfTable)"
                >
                  No Days<mdb-icon
                    v-if="sortByNoDayInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByNoDayInd == false || !sortByNoDayInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  v-if="doesDestinationDisplay"
                  class="headers-frame-header"
                  @click="onSortByDestination(groupBooking, indOfTable)"
                >
                  Destination<mdb-icon
                    v-if="sortByDestinationInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByDestinationInd[indOfTable] == false ||
                      !sortByDestinationInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  v-if="
                    (timeScope == 'Term' && doesTermDisplay) ||
                    timeScope == 'Date'
                  "
                  class="headers-frame-header"
                  @click="onSortByTimeScope(groupBooking, indOfTable)"
                >
                  Time Scope<mdb-icon
                    v-if="sortByTimeScopeInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByTimeScopeInd[indOfTable] == false ||
                      !sortByTimeScopeInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <template v-if="isAdvancedReportCache">
                  <td
                    v-if="doesAccommodationDisplay"
                    class="headers-frame-header"
                    @click="onSortByAccommodation(groupBooking, indOfTable)"
                  >
                    Accommodation<mdb-icon
                      v-if="sortByAccommodationInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByAccommodationInd[indOfTable] == false ||
                        !sortByAccommodationInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesCoachCompanyDisplay"
                    class="headers-frame-header"
                    @click="onSortByCoachCompany(groupBooking, indOfTable)"
                  >
                    Coach Company<mdb-icon
                      v-if="sortByCoachCompanyInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByCoachCompanyInd[indOfTable] == false ||
                        !sortByCoachCompanyInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesYearGroupDisplay"
                    class="headers-frame-header"
                    @click="onSortByYearGroup(groupBooking, indOfTable)"
                  >
                    Year Group<mdb-icon
                      v-if="sortByYearGroupInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByYearGroupInd[indOfTable] == false ||
                        !sortByYearGroupInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesStudentNumbersDisplay"
                    class="headers-frame-header"
                    @click="onSortByStudentCount(groupBooking, indOfTable)"
                  >
                    Student No.<mdb-icon
                      v-if="sortByStudentCountInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByStudentCountInd[indOfTable] == false ||
                        !sortByStudentCountInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesFOCAdultsDisplay"
                    class="headers-frame-header"
                    @click="onSortFOCAdults(groupBooking, indOfTable)"
                  >
                    FOC Adults<mdb-icon
                      v-if="sortByFOCAdultsInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByFOCAdultsInd[indOfTable] == false ||
                        !sortByFOCAdultsInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesNotFOCAdultsDisplay"
                    class="headers-frame-header"
                    @click="onSortNotFOCAdults(groupBooking, indOfTable)"
                  >
                    Not FOC Adults<mdb-icon
                      v-if="sortByNotFOCAdultsInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByNotFOCAdultsInd[indOfTable] == false ||
                        !sortByNotFOCAdultsInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesOrganizingTeacherDisplay"
                    class="headers-frame-header"
                    @click="
                      onSortByOrganizingTeachers(groupBooking, indOfTable)
                    "
                  >
                    Organizing Teacher<mdb-icon
                      v-if="sortByOrganizingTeacherInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByOrganizingTeacherInd[indOfTable] == false ||
                        !sortByOrganizingTeacherInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesEmailDisplay"
                    class="headers-frame-header"
                    @click="onSortByClientEmail(groupBooking, indOfTable)"
                  >
                    Email<mdb-icon
                      v-if="sortByClientEmailInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByClientEmailInd[indOfTable] == false ||
                        !sortByClientEmailInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                  <td
                    v-if="doesPhoneDisplay"
                    class="headers-frame-header"
                    @click="onSortByClientPhone(groupBooking, indOfTable)"
                  >
                    Phone<mdb-icon
                      v-if="sortByClientPhoneInd[indOfTable] == true"
                      icon="caret-down"
                    /><mdb-icon
                      v-if="
                        sortByClientPhoneInd[indOfTable] == false ||
                        !sortByClientPhoneInd[indOfTable]
                      "
                      icon="caret-up"
                    />
                  </td>
                </template>
                <td
                  class="headers-frame-header"
                  @click="onSortByStatus(groupBooking, indOfTable)"
                >
                  Booking Status<mdb-icon
                    v-if="sortByBookingStatusInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByBookingStatusInd[indOfTable] == false ||
                      !sortByBookingStatusInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
              </tr>
              <template v-for="(row, indOfRow) in groupBooking.Bookings">
                <tr v-bind:key="groupBooking.OwnerUser + indOfRow">
                  <td>
                    {{ row.Client.DisplayName }}
                  </td>
                  <td>{{ row.Reference }}</td>
                  <td v-if="doesNumberOfDaysDisplay">
                    {{
                      `${
                        $moment(row.ReturnDt).diff(
                          $moment(row.DepartureDt),
                          "days"
                        ) + 1
                      }`
                    }}
                  </td>
                  <td v-if="doesDestinationDisplay"></td>
                  <td
                    v-if="
                      (timeScopeCache == 'Term' && doesTermDisplay) ||
                      timeScopeCache == 'Date'
                    "
                  >
                    {{
                      timeScopeCache == "Term"
                        ? getTermName(row.SchoolTerm)
                        : `${$moment(row.DepartureDt).format(
                            "D MMM yyyy"
                          )} to ${$moment(row.ReturnDt).format("D MMM yyyy")}`
                    }}
                  </td>
                  <template v-if="isAdvancedReportCache">
                    <td v-if="doesAccommodationDisplay">
                      {{
                        row.Accommodation.join() === ""
                          ? " - "
                          : row.Accommodation.join()
                      }}
                    </td>
                    <td v-if="doesCoachCompanyDisplay">
                      {{
                        row.CoachCompany.join() === ""
                          ? " - "
                          : row.CoachCompany.join()
                      }}
                    </td>
                    <td v-if="doesYearGroupDisplay">{{ row.YearGroups }}</td>
                    <td v-if="doesStudentNumbersDisplay">
                      {{ row.StudentsCount }}
                    </td>
                    <td v-if="doesFOCAdultsDisplay">
                      {{ row.FreeOfChargeAdultsCount }}
                    </td>
                    <td v-if="doesNotFOCAdultsDisplay">
                      {{ row.AdultsCount - row.StudentsCount }}
                    </td>
                    <td v-if="doesOrganizingTeacherDisplay">
                      {{
                        row.BookingContacts
                          ? row.BookingContacts[0].Name
                          : " - "
                      }}
                    </td>
                    <td v-if="doesEmailDisplay">
                      {{ row.Client.EmailAddress }}
                    </td>
                    <td v-if="doesPhoneDisplay">{{ row.Client.Phone }}</td>
                  </template>
                  <td>{{ getBookingStatusName(row.BookingStatus) }}</td>
                </tr>
              </template>
              <tr class="total-row">
                <td>Total</td>
                <td :colspan="getMergedColumns">
                  {{ groupBooking.Bookings.length }}
                </td>
              </tr>
            </table>
          </div>
        </template>
        <div v-if="isAdvancedReportCache">
          <div>Based on the selected criteria:</div>
          <div class="d-flex flex-row flex-wrap flex-grow">
            <template v-if="doesTermDisplay">
              <div style="margin: 5px 15px">
                <div><span style="font-weight: bold">Term</span></div>
                <div>
                  {{
                    (
                      (calculateTerms(bookingTables, "ST_1") * 100) /
                      calculateTotalNumberOfBookings(bookingTables)
                    ).toFixed(2)
                  }}% travelled term 1 ({{
                    calculateTerms(bookingTables, "ST_1")
                  }}
                  bookings)
                </div>
                <div>
                  {{
                    (
                      (calculateTerms(bookingTables, "ST_2") * 100) /
                      calculateTotalNumberOfBookings(bookingTables)
                    ).toFixed(2)
                  }}% travelled term 2 ({{
                    calculateTerms(bookingTables, "ST_2")
                  }}
                  bookings)
                </div>
                <div>
                  {{
                    (
                      (calculateTerms(bookingTables, "ST_3") * 100) /
                      calculateTotalNumberOfBookings(bookingTables)
                    ).toFixed(2)
                  }}% travelled term 3 ({{
                    calculateTerms(bookingTables, "ST_3")
                  }}
                  bookings)
                </div>
                <div>
                  {{
                    (
                      (calculateTerms(bookingTables, "ST_4") * 100) /
                      calculateTotalNumberOfBookings(bookingTables)
                    ).toFixed(2)
                  }}% travelled term 4 ({{
                    calculateTerms(bookingTables, "ST_4")
                  }}
                  bookings)
                </div>
              </div>
              <div class="vertical-devider"></div>
            </template>
            <template v-if="doesNumberOfDaysDisplay">
              <div style="margin: 5px 15px">
                <div><span style="font-weight: bold">Days</span></div>
                <template v-for="(day, i) in calculateDays(bookingTables)">
                  <div v-bind:key="i">{{ day }}</div>
                </template>
              </div>
              <div class="vertical-devider"></div>
            </template>
            <template v-if="doesDestinationDisplay">
              <div style="margin: 5px 15px">
                <div>
                  <span style="font-weight: bold">Destinations</span>
                </div>
              </div>
              <div class="vertical-devider"></div>
            </template>
            <div v-if="doesYearGroupDisplay" style="margin: 5px 15px">
              <div><span style="font-weight: bold">Year Groups</span></div>
              <template v-for="(entry, i) in calculateYears(bookingTables)">
                <div v-bind:key="i">{{ entry }}</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mdb-container>
</template>
<script>
import { mdbContainer, mdbFormInline, mdbIcon } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import { mapGetters } from "vuex";
import workgroupApi from "@/api/workgroupApi.js";
import userApi from "@/api/userApi.js";
import reportApi from "@/api/reportApi.js";
import html2pdf from "html2pdf.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  data() {
    return {
      date: null,
      companySection: "",
      dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
      displayDateFormat: "D MMM YYYY",
      isAdvancedReport: false,
      isAdvancedReportCache: false,
      workgroups: [],
      workgroupsCache: [],
      consultants: [],
      consultantsCache: [],
      bookingStatus: [],
      bookingStatusCache: [],
      year: "",
      yearCache: "",
      timeScope: "Term",
      timeScopeCache: "",
      terms: [],
      termsCache: [],
      startDate: null,
      startDateCache: null,
      toDate: null,
      toDateCache: null,
      sortByClientInd: [],
      sortByTBIDInd: [],
      sortByNoDayInd: [],
      sortByDestinationInd: [],
      sortByAccommodationInd: [],
      sortByCoachCompanyInd: [],
      sortByTimeScopeInd: [],
      sortByBookingStatusInd: [],
      sortByYearGroupInd: [],
      sortByStudentCountInd: [],
      sortByFOCAdultsInd: [],
      sortByNotFOCAdultsInd: [],
      sortByOrganizingTeacherInd: [],
      sortByClientEmailInd: [],
      sortByClientPhoneInd: [],
      workgroupList: [],
      consultantsList: [],
      consultantsListCache: [],
      bookingTables: [],
      isGenerated: false,
      doesTermDisplay: true,
      doesDestinationDisplay: true,
      doesCoachCompanyDisplay: true,
      doesStudentNumbersDisplay: true,
      doesNotFOCAdultsDisplay: true,
      doesTravelInsuranceDisplay: true,
      doesEmailDisplay: true,
      doesNumberOfDaysDisplay: true,
      doesAccommodationDisplay: true,
      doesYearGroupDisplay: true,
      doesFOCAdultsDisplay: true,
      doesFlightsDisplay: true,
      doesOrganizingTeacherDisplay: true,
      doesPhoneDisplay: true,
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
    asoftDatePicker,

    mdbFormInline,
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    AwgtStdSwitch,
  },
  computed: {
    ...mapGetters(["getSchoolTerms", "getBookingStatuses"]),
    getYears() {
      return function (startYear) {
        const year = new Date().getFullYear();
        let yearsList = Array.from(
          { length: year - startYear + 1 },
          (v, i) => startYear + i
        ).map((item) => {
          return {
            Name: item,
            Code: item,
          };
        });
        yearsList.unshift({ Name: "", Code: "" });
        return yearsList;
      };
    },
    getWorkgroupName() {
      return function (reference) {
        return this.workgroupList.find((w) => {
          return w.Code == reference;
        })?.Name;
      };
    },
    getConsultantName() {
      return function (reference) {
        if (this.consultantsListCache.length == 0) return "";
        else
          return this.consultantsListCache.find((c) => {
            return c.Code == reference;
          }).Name;
      };
    },
    getBookingStatusName() {
      return function (reference) {
        return this.getBookingStatuses(false).find((bs) => {
          return bs.Code == reference;
        }).Name;
      };
    },
    getTermName() {
      return function (reference) {
        return this.getSchoolTerms(false).find((st) => {
          return st.Code == reference;
        }).Name;
      };
    },
    getMergedColumns() {
      if (this.isAdvancedReportCache) {
        let numOfCol =
          3 +
          (this.doesDestinationDisplay ? 1 : 0) +
          (this.doesCoachCompanyDisplay ? 1 : 0) +
          (this.doesStudentNumbersDisplay ? 1 : 0) +
          (this.doesNotFOCAdultsDisplay ? 1 : 0) +
          (this.doesOrganizingTeacherDisplay ? 1 : 0) +
          (this.doesEmailDisplay ? 1 : 0) +
          (this.doesNumberOfDaysDisplay ? 1 : 0) +
          (this.doesAccommodationDisplay ? 1 : 0) +
          (this.doesYearGroupDisplay ? 1 : 0) +
          (this.doesFOCAdultsDisplay ? 1 : 0) +
          (this.doesPhoneDisplay ? 1 : 0);
        if (this.timeScope == "Term") {
          return this.doesTermDisplay ? numOfCol : numOfCol - 1;
        } else {
          return numOfCol;
        }
      } else {
        return 5;
      }
    },
    calculateTerms() {
      return function (bookingTables, termCd) {
        let numOfTerms = 0;
        for (let i = 0; i < bookingTables.length; i++) {
          numOfTerms =
            numOfTerms +
            bookingTables[i].Bookings.filter((i) => {
              return i.SchoolTerm == termCd;
            })?.length;
        }

        return numOfTerms;
      };
    },
    calculateTotalNumberOfBookings() {
      return function (bookingTables) {
        let numOfBookings = 0;
        for (let i = 0; i < bookingTables.length; i++) {
          numOfBookings = numOfBookings + bookingTables[i].Bookings.length;
        }

        return numOfBookings;
      };
    },
    calculateDays() {
      return function (bookingTables) {
        const map = new Map();
        let self = this;

        for (let i = 0; i < bookingTables.length; i++) {
          bookingTables[i].Bookings.forEach((entry) => {
            const groupKey =
              self
                .$moment(entry.ReturnDt)
                .diff(self.$moment(entry.DepartureDt), "days") + 1;
            const list = map.get(groupKey);
            if (list == null) {
              map.set(groupKey, {
                Bookings: [entry],
              });
            } else {
              list.Bookings.push(entry);
            }
          });
        }

        let sortMap = new Map(
          [...map].sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (a[0] == b[0]) return 0;
            if (a[0] < b[0]) return -1;
          })
        );
        let result = [];
        sortMap.forEach((val, key) => {
          let lengthOfBookingsOfADay = val.Bookings.length;
          let ratio = (
            (lengthOfBookingsOfADay * 100) /
            this.calculateTotalNumberOfBookings(bookingTables)
          ).toFixed(2);
          result.push(
            `${ratio}% travelled ${key} days (${lengthOfBookingsOfADay} bookings)`
          );
        });

        return result;
      };
    },
    calculateYears() {
      return function (bookingTables) {
        let primary = 0;
        let secondary = 0;

        for (let len = 0; len < bookingTables.length; len++) {
          for (let i = 0; i < bookingTables[len].Bookings.length; i++) {
            let arrayOfYearGroups = bookingTables[len].Bookings[
              i
            ].YearGroups.split(",").map(function (item) {
              return parseInt(item, 10);
            });
            if (arrayOfYearGroups.find((num) => num <= 6)) {
              primary = primary + 1;
            }
            if (arrayOfYearGroups.find((num) => num >= 7)) {
              secondary = secondary + 1;
            }
          }
        }

        return [
          `${(
            (primary * 100) /
            this.calculateTotalNumberOfBookings(bookingTables)
          ).toFixed(2)}% includes primary (${primary} bookings)`,
          `${(
            (secondary * 100) /
            this.calculateTotalNumberOfBookings(bookingTables)
          ).toFixed(2)}% includes secondary (${secondary} bookings)`,
        ];
      };
    },
  },
  methods: {
    onFromDtChange(fromDt) {
      if (this.toDate == null) this.toDate = fromDt;
    },
    onSortByClientName(groupBooking, index) {
      this.sortByClientInd[index] = !this.sortByClientInd[index];
      if (this.sortByClientInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "Client");
      else this.onSortBookingInReverseOrder(groupBooking, "Client");
    },
    onSortByTBID(groupBooking, index) {
      this.sortByTBIDInd[index] = !this.sortByTBIDInd[index];
      if (this.sortByTBIDInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "Reference");
      else this.onSortBookingInReverseOrder(groupBooking, "Reference");
    },
    onSortByNoDays(groupBooking, index) {
      this.sortByNoDayInd[index] = !this.sortByNoDayInd[index];
      if (this.sortByNoDayInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "NoDays");
      else this.onSortBookingInReverseOrder(groupBooking, "NoDays");
    },
    onSortByDestination() {
      this.sortByDestinationInd = !this.sortByDestinationInd;
      // if (this.sortByDestinationInd == true)
      //   this.onSortBookingInOrder(groupBooking, "NoDays");
      // else this.onSortBookingInReverseOrder(groupBooking, "NoDays");
    },
    onSortByAccommodation(groupBooking, index) {
      this.sortByAccommodationInd[index] = !this.sortByAccommodationInd[index];
      if (this.sortByAccommodationInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "Accommodation");
      else this.onSortBookingInReverseOrder(groupBooking, "Accommodation");
    },
    onSortByCoachCompany(groupBooking, index) {
      this.sortByCoachCompanyInd[index] = !this.sortByCoachCompanyInd[index];
      if (this.sortByCoachCompanyInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "CoachCompany");
      else this.onSortBookingInReverseOrder(groupBooking, "CoachCompany");
    },
    onSortByYearGroup(groupBooking, index) {
      this.sortByYearGroupInd[index] = !this.sortByYearGroupInd[index];
      if (this.sortByYearGroupInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "YearGroups");
      else this.onSortBookingInReverseOrder(groupBooking, "YearGroups");
    },
    onSortByStudentCount(groupBooking, index) {
      this.sortByStudentCountInd[index] = !this.sortByStudentCountInd[index];
      if (this.sortByStudentCountInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "StudentsCount");
      else this.onSortBookingInReverseOrder(groupBooking, "StudentsCount");
    },
    onSortFOCAdults(groupBooking, index) {
      this.sortByFOCAdultsInd[index] = !this.sortByFOCAdultsInd[index];
      if (this.sortByFOCAdultsInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "FreeOfChargeAdultsCount");
      else
        this.onSortBookingInReverseOrder(
          groupBooking,
          "FreeOfChargeAdultsCount"
        );
    },
    onSortNotFOCAdults(groupBooking, index) {
      this.sortByNotFOCAdultsInd[index] = !this.sortByNotFOCAdultsInd[index];
      if (this.sortByNotFOCAdultsInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "NotFreeOfChargeAdultsCount");
      else
        this.onSortBookingInReverseOrder(
          groupBooking,
          "NotFreeOfChargeAdultsCount"
        );
    },
    onSortByOrganizingTeachers(groupBooking, index) {
      this.sortByOrganizingTeacherInd[index] =
        !this.sortByOrganizingTeacherInd[index];
      if (this.sortByOrganizingTeacherInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "BookingContacts");
      else this.onSortBookingInReverseOrder(groupBooking, "BookingContacts");
    },
    onSortByClientEmail(groupBooking, index) {
      this.sortByClientEmailInd[index] = !this.sortByClientEmailInd[index];
      if (this.sortByClientEmailInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "Client", false, true, false);
      else
        this.onSortBookingInReverseOrder(
          groupBooking,
          "Client",
          false,
          true,
          false
        );
    },
    onSortByClientPhone(groupBooking, index) {
      this.sortByClientPhoneInd[index] = !this.sortByClientPhoneInd[index];
      if (this.sortByClientPhoneInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "Client", false, false, true);
      else
        this.onSortBookingInReverseOrder(
          groupBooking,
          "Client",
          false,
          false,
          true
        );
    },
    onSortByTimeScope(groupBooking, index) {
      this.sortByTimeScopeInd[index] = !this.sortByTimeScopeInd[index];
      if (this.sortByTimeScopeInd[index] == true) {
        if (this.timeScope == "Term")
          this.onSortBookingInOrder(groupBooking, "SchoolTerm");
        else this.onSortBookingInOrder(groupBooking, "DepartureDt", true);
      } else {
        if (this.timeScope == "Term")
          this.onSortBookingInReverseOrder(groupBooking, "SchoolTerm");
        else
          this.onSortBookingInReverseOrder(groupBooking, "DepartureDt", true);
      }
    },
    onSortByStatus(groupBooking, index) {
      this.sortByBookingStatusInd[index] = !this.sortByBookingStatusInd[index];
      if (this.sortByBookingStatusInd[index] == true)
        this.onSortBookingInOrder(groupBooking, "BookingStatus");
      else this.onSortBookingInReverseOrder(groupBooking, "BookingStatus");
    },
    onSortBookingInOrder(
      groupBooking,
      attribute,
      isDate = false,
      isEmail = false,
      isPhone = false
    ) {
      //When the field is empty, it is set to 'z' because z is the last alphabet.
      if (attribute == "BookingContacts") {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          if (!a[attribute]) nameA = "z";
          else nameA = a[attribute][0]?.Name;
          if (!b[attribute]) nameB = "z";
          else nameB = b[attribute][0]?.Name;

          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      } else if (attribute == "NotFreeOfChargeAdultsCount") {
        groupBooking.Bookings.sort(function (a, b) {
          if (
            a.AdultsCount - a.FreeOfChargeAdultsCount >
            b.AdultsCount - b.FreeOfChargeAdultsCount
          ) {
            return 1;
          }
          if (
            a.AdultsCount - a.FreeOfChargeAdultsCount <
            b.AdultsCount - b.FreeOfChargeAdultsCount
          ) {
            return -1;
          }

          return 0;
        });
      } else if (attribute == "Accommodation" || attribute == "CoachCompany") {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = a[attribute].join();
          var nameB = b[attribute].join();

          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      } else if (attribute == "NoDays") {
        let self = this;
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          nameA =
            self.$moment(a.ReturnDt).diff(self.$moment(a.DepartureDt), "days") +
            1;
          nameB =
            self.$moment(b.ReturnDt).diff(self.$moment(b.DepartureDt), "days") +
            1;
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      } else {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          if (typeof a[attribute] === "object") {
            if (isEmail) {
              nameA =
                a[attribute]?.EmailAddress == null
                  ? "z"
                  : a[attribute].EmailAddress;
              nameB =
                b[attribute]?.EmailAddress == null
                  ? "z"
                  : b[attribute].EmailAddress;
            } else if (isPhone) {
              nameA =
                a[attribute]?.Phone == null ? "0000000000" : a[attribute].Phone;
              nameB =
                b[attribute]?.Phone == null ? "0000000000" : b[attribute].Phone;
            } else {
              nameA =
                a[attribute]?.DisplayName == null
                  ? "z"
                  : a[attribute].DisplayName;
              nameB =
                b[attribute]?.DisplayName == null
                  ? "z"
                  : b[attribute].DisplayName;
            }
          } else if (typeof a[attribute] === "string") {
            if (isDate) {
              nameA = new Date(a[attribute]);
              nameB = new Date(b[attribute]);
            } else {
              nameA = a[attribute].toUpperCase(); // ignore upper and lowercase
              nameB = b[attribute].toUpperCase(); // ignore upper and lowercase
            }
          } else {
            nameA = a[attribute];
            nameB = b[attribute];
          }
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      }
    },

    onSortBookingInReverseOrder(
      groupBooking,
      attribute,
      isDate = false,
      isEmail = false,
      isPhone = false
    ) {
      if (attribute == "BookingContacts") {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          if (!a[attribute]) nameA = "z";
          else nameA = a[attribute][0]?.Name;
          if (!b[attribute]) nameB = "z";
          else nameB = b[attribute][0]?.Name;

          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      } else if (attribute == "NotFreeOfChargeAdultsCount") {
        groupBooking.Bookings.sort(function (a, b) {
          if (
            a.AdultsCount - a.FreeOfChargeAdultsCount >
            b.AdultsCount - b.FreeOfChargeAdultsCount
          ) {
            return -1;
          }
          if (
            a.AdultsCount - a.FreeOfChargeAdultsCount <
            b.AdultsCount - b.FreeOfChargeAdultsCount
          ) {
            return 1;
          }

          return 0;
        });
      } else if (attribute == "Accommodation" || attribute == "CoachCompany") {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = a[attribute].join();
          var nameB = b[attribute].join();

          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      } else if (attribute == "NoDays") {
        let self = this;
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          nameA =
            self.$moment(a.ReturnDt).diff(self.$moment(a.DepartureDt), "days") +
            1;
          nameB =
            self.$moment(b.ReturnDt).diff(self.$moment(b.DepartureDt), "days") +
            1;
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      } else {
        groupBooking.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          if (typeof a[attribute] === "object") {
            if (isEmail) {
              nameA =
                a[attribute]?.EmailAddress == null
                  ? "z"
                  : a[attribute].EmailAddress;
              nameB =
                b[attribute]?.EmailAddress == null
                  ? "z"
                  : b[attribute].EmailAddress;
            } else if (isPhone) {
              nameA =
                a[attribute]?.Phone == null ? "0000000000" : a[attribute].Phone;
              nameB =
                b[attribute]?.Phone == null ? "0000000000" : b[attribute].Phone;
            } else {
              nameA =
                a[attribute]?.DisplayName == null
                  ? "z"
                  : a[attribute].DisplayName;
              nameB =
                b[attribute]?.DisplayName == null
                  ? "z"
                  : b[attribute].DisplayName;
            }
          } else if (typeof a[attribute] === "string") {
            if (isDate) {
              nameA = new Date(a[attribute]);
              nameB = new Date(b[attribute]);
            } else {
              nameA = a[attribute].toUpperCase(); // ignore upper and lowercase
              nameB = b[attribute].toUpperCase(); // ignore upper and lowercase
            }
          } else {
            nameA = a[attribute];
            nameB = b[attribute];
          }
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      }
    },
    async onGenerate() {
      let travelBookings;
      try {
        travelBookings = await reportApi.getTravelBookingsReport(
          this.workgroups.join(),
          this.consultants.join(),
          this.bookingStatus.join(),
          this.year,
          this.terms.join(),
          this.startDate,
          this.toDate,
          this.isAdvancedReport
        );
      } catch (err) {
        // Errors have been logged
      }

      this.workgroupsCache = this.$lodash.cloneDeep(this.workgroups);
      this.consultantsCache = this.$lodash.cloneDeep(this.consultants);
      this.yearCache = this.$lodash.clone(this.year);
      this.timeScopeCache = this.$lodash.cloneDeep(this.timeScope);
      this.termsCache = this.$lodash.cloneDeep(this.terms);
      this.startDateCache = this.$lodash.cloneDeep(this.startDate);
      this.toDateCache = this.$lodash.cloneDeep(this.toDate);
      this.bookingStatusCache = this.$lodash.cloneDeep(this.bookingStatus);
      this.isAdvancedReportCache = this.$lodash.clone(this.isAdvancedReport);
      this.consultantsListCache = this.$lodash.cloneDeep(this.consultantsList);

      this.bookingTables = this.arrayGroupBy(
        travelBookings,
        "OwnerUserReference"
      );
      this.isGenerated = true;
    },

    onClearGenerateCriteria() {
      this.workgroups = [];
      this.consultants = [];
      this.bookingStatus = [];
      this.year = "";
      this.timeScope = "Term";
      this.terms = [];
      this.startDate = null;
      this.toDate = null;
    },

    onExport() {
      const options = {
        margin: 0.5,
        filename: "Travel_Booking_Report",
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: {
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: this.isAdvancedReportCache ? "landscape" : "portrait",
        },
      };

      let content = document.getElementById("tableContent");
      let Node = content.cloneNode(true);
      let self = this;
      Node.children[0].style["border"] = "none";
      html2pdf()
        .set(options)
        .from(Node)
        .save()
        .then(() => {
          self.$emit("header-message", {
            isSuccessInd: true,
            Message: ["The PDF has been generated successfully."],
          });
        });
    },
    arrayGroupBy(data, groupingProp) {
      const map = new Map();
      data.forEach((entry) => {
        const groupKey = entry[groupingProp];
        const list = map.get(groupKey);
        if (list == null) {
          map.set(groupKey, {
            OwnerUser: entry.OwnerUserReference,
            Bookings: [entry],
          });
        } else {
          list.Bookings.push(entry);
        }
      });

      return [...map.values()];
    },

    async onFocusConsultantDropdown() {
      if (this.workgroups.length === 0) this.consultantsList = [];
      else
        this.consultantsList = await userApi.getUserDropdown(
          this.workgroups.join()
        );
    },

    async loadFormData() {
      this.workgroupList = await workgroupApi.getWorkgroupDropdown();
    },
  },
  mounted() {
    this.loadFormData();
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  margin-top: 10px;
}

table,
th,
td {
  table-layout: fixed;
  border: 2px solid darkgray;
  padding: 5px 5px !important;
  word-wrap: break-word;
  font-size: 12px;
}

.total-row {
  background-color: #f7ad7c;
  border-top: 3px solid black;
}

.headers-frame-header {
  color: purple;
}

.headers-frame-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.check-box-selection {
  width: 220px;
}

.vertical-devider {
  border-left: 3px solid lightgrey;
}
</style>
