var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { "z-index": { zIndex: _vm.zIndex } } },
    [
      _c(
        "button",
        {
          staticStyle: {
            "border-width": "0px 0px 0px 0px",
            "border-color": "lightgrey",
            "border-radius": "4.8px 4.8px 0px 0px",
            width: "100%",
            height: "48px",
            padding: "10px 10px 6.4px 12px",
            tabindex: "1",
            "text-align": "left",
          },
          style: {
            color: _vm.showList == true ? _vm.activeLabelColor : _vm.labelColor,
            "border-color":
              _vm.showList == true ? _vm.activeColor : _vm.backgroundColor,
            "background-color": _vm.backgroundColor,
          },
          attrs: { id: "labelBtn" },
          on: { click: _vm.onLabelClick, blur: _vm.onBlur },
        },
        [
          _c(
            "label",
            {
              class: [_vm.hour != 0 || _vm.minute != 0 ? "active" : ""],
              attrs: { id: "label" },
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          ),
          _c(
            "span",
            {
              staticStyle: {
                position: "absolute",
                color: "black",
                float: "left",
              },
            },
            [_vm._v(_vm._s(_vm.durationlabel))]
          ),
          _c(
            "span",
            {
              staticClass: "arrowbutton",
              staticStyle: {
                position: "relative",
                top: "-8px",
                float: "right",
                "font-size": "x-small",
                "margin-right": "-7px",
              },
            },
            [_vm.showList ? [_vm._v("▲")] : [_vm._v("▼")]],
            2
          ),
        ]
      ),
      _c(
        "expansionRegion",
        {
          attrs: {
            toggle: _vm.showList,
            tag: "div",
            onTop: true,
            openDuration: 250,
            closeDuration: 250,
            useVShowConditional: true,
            expectedHeight: "90px",
          },
        },
        [
          _c(
            "div",
            {
              style: {
                border: "1px",
                display: "block",
                position: "absolute",
                width: "100%",
                top: "0rem",
                padding: "1px",
                "-webkit-box-shadow":
                  "0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.12)",
                "box-shadow":
                  "0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.12)",
              },
            },
            [
              _c(
                "select",
                {
                  staticStyle: {
                    width: "50%",
                    tabindex: "2",
                    "margin-top": "3px",
                  },
                  attrs: { id: "hourList", size: "5" },
                  on: { blur: _vm.onBlur, change: _vm.onHourChange },
                },
                _vm._l(_vm.hourSelection, function (houritem, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      staticClass: "item",
                      domProps: { selected: _vm.hour == houritem },
                    },
                    [
                      _c("span", { staticStyle: { "padding-left": "75%" } }, [
                        _vm._v(_vm._s(houritem)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "select",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "50%",
                    width: "50%",
                    top: "3px",
                    tabindex: "3",
                  },
                  attrs: { id: "minuteList", size: "5" },
                  on: {
                    keydown: _vm.onMinuteKeyDown,
                    blur: _vm.onBlur,
                    change: _vm.onMinuteChange,
                  },
                },
                _vm._l(_vm.minuteSelection, function (minuteitem, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      staticClass: "item",
                      domProps: { selected: _vm.minute == minuteitem },
                    },
                    [
                      _c("span", { staticStyle: { "padding-left": "75%" } }, [
                        _vm._v(_vm._s(minuteitem)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }