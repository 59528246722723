var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.components, function (pc) {
      return _c(
        "div",
        {
          key: pc.commercialProductLineId,
          staticClass: "px-2 py-3 my-1",
          staticStyle: { border: "solid" },
        },
        [
          _c("h3", [_vm._v(_vm._s(pc.commercialProductLineName))]),
          _c("div", { domProps: { innerHTML: _vm._s(pc.description) } }),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
            },
            [
              _c("awgt-input", {
                directives: [
                  {
                    name: "numeric",
                    rawName: "v-numeric",
                    value: { precision: 3, scale: 0, posOnly: true },
                    expression: "{ precision: 3, scale: 0, posOnly: true }",
                  },
                ],
                staticClass: "mb-0",
                staticStyle: { width: "40px" },
                attrs: { label: "Quantity", maxlength: "3" },
                model: {
                  value: pc.quantity,
                  callback: function ($$v) {
                    _vm.$set(pc, "quantity", _vm._n($$v))
                  },
                  expression: "pc.quantity",
                },
              }),
              _c(
                "awgt-std-button",
                {
                  staticClass: "py-2 mt-4 px-2 ml-4",
                  staticStyle: { height: "40px", width: "40px" },
                  on: {
                    click: function ($event) {
                      pc.quantity < 999 ? (pc.quantity += 1) : true
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    attrs: {
                      icon: "plus",
                      color: "white",
                      fas: "",
                      size: "2x",
                    },
                  }),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "py-2 mt-4 px-2 ml-2",
                  staticStyle: { height: "40px", width: "40px" },
                  on: {
                    click: function ($event) {
                      pc.quantity > 0 ? (pc.quantity -= 1) : true
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    attrs: {
                      icon: "minus",
                      color: "white",
                      fas: "",
                      size: "2x",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mr-2 mt-4",
                  attrs: { icon: "dollar-sign", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mt-3 mb-0",
                  staticStyle: { width: "50px" },
                  attrs: {
                    counterMaxValue: 7,
                    maxlength: "7",
                    counter: "",
                    label: "Ex-GST Amt",
                    bg: "",
                  },
                  model: {
                    value: pc.exGSTPrice,
                    callback: function ($$v) {
                      _vm.$set(pc, "exGSTPrice", _vm._n($$v))
                    },
                    expression: "pc.exGSTPrice",
                  },
                }),
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "dollar-sign", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mt-3 mb-0",
                  staticStyle: { width: "50px" },
                  attrs: {
                    counterMaxValue: 7,
                    maxlength: "7",
                    counter: "",
                    label: "GST Amt",
                    bg: "",
                  },
                  model: {
                    value: pc.gSTPrice,
                    callback: function ($$v) {
                      _vm.$set(pc, "gSTPrice", _vm._n($$v))
                    },
                    expression: "pc.gSTPrice",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap" },
              [
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 pt-md-3",
                  staticStyle: { height: "10px" },
                  attrs: {
                    id: "isIndicativePriceInd",
                    type: "checkbox",
                    name: "deleteComponentPrice",
                    label: "Is Indicative Price",
                  },
                  model: {
                    value: pc.isIndicativePriceInd,
                    callback: function ($$v) {
                      _vm.$set(pc, "isIndicativePriceInd", $$v)
                    },
                    expression: "pc.isIndicativePriceInd",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }