var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "h1",
        {
          staticClass: "text-center",
          staticStyle: { "margin-bottom": "50px" },
        },
        [_vm._v("Manage Users")]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              multiple: "",
              selectAll: "",
              label: "Workgroup",
              itemValueProperty: "Code",
              items: _vm.workgroups,
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.filterWorkgroups,
              callback: function ($$v) {
                _vm.filterWorkgroups = $$v
              },
              expression: "filterWorkgroups",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Consultant",
              bg: "",
            },
            model: {
              value: _vm.searchConsultant,
              callback: function ($$v) {
                _vm.searchConsultant = $$v
              },
              expression: "searchConsultant",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end flex-grow-1 mt-2" },
        [
          _c("awgt-std-button", {
            staticClass: "command-button mx-2 mb-2",
            attrs: {
              id: "add-user",
              type: "button",
              title: "Filter",
              icon: "search",
            },
            on: {
              click: function ($event) {
                return _vm.onFilterUser()
              },
            },
          }),
          _vm.checkPermission("CP_UsC")
            ? _c("awgt-std-button", {
                staticClass: "command-button mx-2 mb-2",
                attrs: {
                  id: "add-user",
                  type: "button",
                  title: "Add",
                  icon: "user-plus",
                },
                on: {
                  click: function ($event) {
                    return _vm.onAddUser()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.users.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._l(_vm.users, function (user, index) {
            return _c(
              "mdb-card",
              {
                key: index,
                staticClass: "mb-3 mx-2",
                style: user.IsActive == true ? "opacity:1" : "opacity:0.5",
              },
              [
                _c(
                  "awgt-expansion-header",
                  {
                    attrs: {
                      tag: "button",
                      user: user,
                      expanded: _vm.userDetailExpansion[index],
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onToggleUserDetailExpansion(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap align-items-center",
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "pr-3",
                          staticStyle: { width: "75px" },
                          attrs: { icon: "user", size: "2x", color: "primary" },
                        }),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "h4",
                            {
                              staticClass: "mb-1",
                              attrs: { id: user.FirstName + user.Surname },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(user.FirstName) +
                                  " " +
                                  _vm._s(user.Surname) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "awgt-expansion-body",
                  {
                    attrs: { toggle: _vm.userDetailExpansion[index] },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _vm.usersFullDataSet[index] &&
                              _vm.usersFullDataSet[index].IsActive
                                ? _c(
                                    "div",
                                    { staticClass: "my-2 float-right" },
                                    [
                                      _vm.checkPermission("CP_UsU") &&
                                      _vm.checkPermissionScopeUpdate(index)
                                        ? _c("awgt-std-button", {
                                            staticClass: "command-button mx-2",
                                            attrs: {
                                              type: "button",
                                              title: "Edit",
                                              icon: "edit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onEditUser(user)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.checkPermission("CP_UsD") &&
                                      _vm.checkPermissionScopeDelete(index)
                                        ? _c("awgt-std-button", {
                                            staticClass: "command-button mx-2",
                                            attrs: {
                                              type: "button",
                                              title: "Delete",
                                              icon: "trash-alt",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDeleteUser(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "my-2 float-right" },
                                    [
                                      _vm.checkPermission("CP_UsD") &&
                                      _vm.checkPermissionScopeDelete(index)
                                        ? _c("awgt-std-button", {
                                            staticClass: "command-button mx-2",
                                            attrs: {
                                              type: "button",
                                              title: "Undelete",
                                              icon: "user-plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onUndeleteUser(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.checkPermission("CP_UsD") &&
                                      _vm.checkPermissionScopeDelete(index)
                                        ? _c("awgt-std-button", {
                                            staticClass: "command-button mx-2",
                                            attrs: {
                                              type: "button",
                                              title: "Permanently Delete",
                                              icon: "trash-alt",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onPermanentlyDeleteUser(
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    [
                      _c("user-view", {
                        attrs: { value: _vm.usersFullDataSet[index] },
                      }),
                    ],
                  ],
                  2
                ),
              ],
              1
            )
          }),
      _c("yes-or-no-prompt", { ref: "handleUserPrompt" }),
      _c("ok-prompt", { ref: "alertPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }