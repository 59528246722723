var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3",
          },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button",
                attrs: { type: "button" },
                on: { click: _vm.AddPriceAdjustment },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "plus" },
                }),
                _vm._v("Add "),
              ],
              1
            ),
          ],
          1
        ),
        _vm.priceAdjustmentsArray.length == 0
          ? _c("div", { staticClass: "d-flex flex-row justify-content-left" }, [
              _c("i", [
                _vm._v(
                  "Press Add to create additional surcharges or discounts"
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._l(_vm.priceAdjustmentsArray, function (priceAdjustment, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
            },
            [
              _c("awgt-input", {
                staticClass: "mr-2 flex-grow-1",
                attrs: {
                  maxlength: "50",
                  counter: "",
                  counterMaxValue: 50,
                  label: "Name",
                  bg: "",
                  type: "text",
                },
                model: {
                  value: priceAdjustment.Name,
                  callback: function ($$v) {
                    _vm.$set(priceAdjustment, "Name", _vm._n($$v))
                  },
                  expression: "priceAdjustment.Name",
                },
              }),
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mr-2",
                staticStyle: { "max-width": "150px" },
                attrs: {
                  items: _vm.getPriceAdjustmentTypes(false),
                  itemValueProperty: "Code",
                  itemTextProperty: "Name",
                  label: "Adjustment Type",
                  bg: "",
                },
                model: {
                  value: priceAdjustment.PriceAdjustmentTypeCd,
                  callback: function ($$v) {
                    _vm.$set(priceAdjustment, "PriceAdjustmentTypeCd", $$v)
                  },
                  expression: "priceAdjustment.PriceAdjustmentTypeCd",
                },
              }),
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mr-2",
                staticStyle: { "max-width": "150px" },
                attrs: {
                  items: _vm.getPriceChangeTypes(false),
                  itemValueProperty: "Code",
                  itemTextProperty: "Name",
                  label: "Change Type",
                  bg: "",
                },
                model: {
                  value: priceAdjustment.PriceChangeTypeCd,
                  callback: function ($$v) {
                    _vm.$set(priceAdjustment, "PriceChangeTypeCd", $$v)
                  },
                  expression: "priceAdjustment.PriceChangeTypeCd",
                },
              }),
              _c("mdb-icon", {
                staticClass: "grey-text ml-2",
                staticStyle: { width: "20px" },
                attrs: {
                  icon:
                    priceAdjustment.PriceChangeTypeCd == "PCT_Pe"
                      ? "percentage"
                      : "dollar-sign",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("awgt-input", {
                directives: [
                  {
                    name: "numeric",
                    rawName: "v-numeric",
                    value: {
                      precision: 8,
                      scale: 2,
                      posOnly: true,
                      trailingZeroScale: 2,
                      allowEmptyString: false,
                    },
                    expression:
                      "{\n          precision: 8,\n          scale: 2,\n          posOnly: true,\n          trailingZeroScale: 2,\n          allowEmptyString: false,\n        }",
                  },
                ],
                staticClass: "ml-2",
                staticStyle: { width: "100px" },
                attrs: {
                  maxlength: "9",
                  label: "Amount",
                  bg: "",
                  type: "text",
                },
                model: {
                  value: priceAdjustment.Amount,
                  callback: function ($$v) {
                    _vm.$set(priceAdjustment, "Amount", _vm._n($$v))
                  },
                  expression: "priceAdjustment.Amount",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap salign-items-center justify-content-end",
                },
                [
                  _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onDeletePriceAdjustment(index)
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "trash-alt" },
                      }),
                      _vm._v("Delete"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }