<template>
  <expansion-region
    :toggle="toggle"
    :style="{ backgroundColor: backgroundColor, color: textColor }"
  >
    <mdb-card-body>
      <slot />
      <slot name="footer" />
    </mdb-card-body>
  </expansion-region>
</template>

<script>
import ExpansionRegion from "@/components/ExpansionRegion";
import { mdbCardBody } from "mdbvue";
import variables from "@/styles/sharedVariables.scss";

export default {
  props: {
    toggle: { type: Boolean },
    backgroundColor: {
      type: String,
      default: variables.awgtExpansionRegionBodyColor,
    },
    textColor: {
      type: String,
      default: variables.awgtExpansionRegionBodyTextColor,
    },
  },
  components: { ExpansionRegion, mdbCardBody },
};
</script>
