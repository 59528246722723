import api from "./api.js";
import { sharedMethods } from "@/shared/shared";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getCommunications(
    clientReference,
    bookingReference,
    supplierReference,
    communicationMedium,
    communicationDirection,
    occurenceDate,
    matchAllSearchingCriteriaInd
  ) {
    try {
      logger.get(loggingSource.UICommunication).info("getCommunications...");
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "Parameters => clientReference: %s, bookingReference: %s, supplierReference: %s, communicationMedium: %s, communicationDirection: %s,occurenceDate: %s, matchAllSearchingCriteriaInd: %s",
          clientReference,
          bookingReference,
          supplierReference,
          communicationMedium,
          communicationDirection,
          occurenceDate,
          matchAllSearchingCriteriaInd
        );

      logger.get(loggingSource.UICommunication).time("getCommunications call");
      const response = await api.client
        .get("Communication", {
          params: {
            clientReference: clientReference,
            bookingReference: bookingReference,
            supplierReference: supplierReference,
            communicationMedium: communicationMedium,
            communicationDirection: communicationDirection,
            occurenceDate: occurenceDate,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunications call");
      const communications = response.data;

      logger
        .get(loggingSource.UICommunication)
        .trace(
          "getCommunications (before sanitisation) response: %s",
          response
        );

      //Html Sanitise Notes.
      for (let communication of communications) {
        communication.Notes = sharedMethods.htmlSanitize(communication.Notes);
      }

      logger
        .get(loggingSource.UICommunication)
        .trace("getCommunications (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "getCommunications (after sanitisation) communications: %s",
          communications
        );

      return communications;
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunications call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UICommunication).info("...getCommunications");
    }
  },

  //Get brief communication list for product search screen.
  async getCommunicationsList(
    clientReference,
    bookingReference,
    supplierReference,
    communicationMedium,
    communicationDirection,
    occurenceDate,
    matchAllSearchingCriteriaInd
  ) {
    try {
      logger
        .get(loggingSource.UICommunication)
        .info("getCommunicationsList...");
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "Parameters => clientReference: %s, bookingReference: %s, supplierReference: %s, communicationMedium: %s, communicationDirection: %s,occurenceDate: %s, matchAllSearchingCriteriaInd: %s",
          clientReference,
          bookingReference,
          supplierReference,
          communicationMedium,
          communicationDirection,
          occurenceDate,
          matchAllSearchingCriteriaInd
        );

      logger
        .get(loggingSource.UICommunication)
        .time("getCommunicationsList call");
      const response = await api.client
        .get("Communication/CommunicationList", {
          params: {
            clientReference: clientReference,
            bookingReference: bookingReference,
            supplierReference: supplierReference,
            communicationMedium: communicationMedium,
            communicationDirection: communicationDirection,
            occurenceDate: occurenceDate,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunicationsList call");
      const communications = response.data;

      logger
        .get(loggingSource.UICommunication)
        .debug("getCommunicationsList communications: %s", communications);

      return communications;
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunicationsList call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UICommunication)
        .info("...getCommunicationsList");
    }
  },

  async getCommunicationByReference(reference) {
    try {
      logger
        .get(loggingSource.UICommunication)
        .info("getCommunicationByReference...");

      logger
        .get(loggingSource.UICommunication)
        .debug("Parameters => reference: %s", reference);

      logger
        .get(loggingSource.UICommunication)
        .time("getCommunicationByReference call");
      const response = await api.client
        .get(`Communication/${reference}`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunicationByReference call");
      const communication = response.data;

      logger
        .get(loggingSource.UICommunication)
        .trace(
          "getCommunicationByReference (before sanitisation) response: %s",
          response
        );

      //Html Sanitise Notes.
      communication.Notes = sharedMethods.htmlSanitize(communication.Notes);

      logger
        .get(loggingSource.UICommunication)
        .trace(
          "getCommunicationByReference (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "getCommunicationByReference (after sanitisation) communication: %s",
          communication
        );
      return communication;
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("getCommunicationByReference call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UICommunication)
        .info("...getCommunicationByReference");
    }
  },

  async postCommunication(communicationPayload, withFiles = false) {
    const config = withFiles
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        };
    try {
      logger.get(loggingSource.UICommunication).info("postCommunication...");

      logger
        .get(loggingSource.UICommunication)
        .debug("Parameters => communicationPayload: %s", communicationPayload);

      logger.get(loggingSource.UICommunication).time("postCommunication call");
      const response = await api.client
        .post(
          "Communication" + (withFiles ? "/withFiles" : ""),
          communicationPayload,
          config
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("postCommunication call");
      const communication = response.data;

      logger
        .get(loggingSource.UICommunication)
        .trace(
          "postCommunication (before sanitisation) response: %s",
          response
        );

      //Html Sanitise Notes.
      communication.Notes = sharedMethods.htmlSanitize(communication.Notes);

      logger
        .get(loggingSource.UICommunication)
        .trace("postCommunication (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "postCommunication (after sanitisation) communication: %s",
          communication
        );

      return communication;
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("postCommunication call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UICommunication).info("...postCommunication");
    }
  },

  async putCommunication(communicationPayload, withFiles = false) {
    const config = withFiles
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        };
    try {
      logger.get(loggingSource.UICommunication).info("putCommunication...");

      logger
        .get(loggingSource.UICommunication)
        .debug("Parameters => CommunicationPayload: %s", communicationPayload);

      logger.get(loggingSource.UICommunication).time("putCommunication call");
      const response = await api.client
        .put(
          "Communication" + (withFiles ? "/withFiles" : ""),
          communicationPayload,
          config
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("putCommunication call");
      const communication = response.data;

      logger
        .get(loggingSource.UICommunication)
        .trace("putCommunication (before sanitisation) response: %s", response);

      //Html Sanitise Notes
      communication.Notes = sharedMethods.htmlSanitize(communication.Notes);

      logger
        .get(loggingSource.UICommunication)
        .trace("putCommunication (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UICommunication)
        .debug(
          "putCommunication (after sanitisation) communication: %s",
          communication
        );

      return communication;
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("putCommunication call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UICommunication).info("...putCommunication");
    }
  },

  async deleteCommunication(communicationPayload) {
    try {
      logger.get(loggingSource.UICommunication).info("deleteCommunication...");

      logger
        .get(loggingSource.UICommunication)
        .debug("Parameters => communicationPayload: %s", communicationPayload);

      logger
        .get(loggingSource.UICommunication)
        .time("deleteCommunication call");
      await api.client
        .delete(
          `Communication/${
            communicationPayload.Reference
          }?concurrencyRV=${encodeURIComponent(
            communicationPayload.ConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("deleteCommunication call");
    } catch (error) {
      logger
        .get(loggingSource.UICommunication)
        .timeEnd("deleteCommunication call");
      logger.get(loggingSource.UICommunication).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UICommunication).info("...deleteCommunication");
    }
  },
};
