import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

const url = "admin/countryregion";
const logSource = loggingSource.UICountryRegion;

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getAsync() {
    try {
      logger.get(logSource).info("getAsync...");

      logger.get(logSource).time("getAsync call");

      const response = await api.client.get(url, {}).catch((error) => {
        throw error;
      });

      logger.get(logSource).timeEnd("getAsync call");

      const responseData = response.data;

      logger.get(logSource).trace("getAsync response: %s", response);
      logger.get(logSource).debug("getAsync responseData: %s", responseData);

      return responseData;
    } catch (error) {
      logger.get(logSource).timeEnd("getAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...getAsync");
    }
  },

  async getForDropdownAsync() {
    try {
      logger.get(logSource).info("getForDropdownAsync...");

      logger.get(logSource).time("getForDropdownAsync call");

      const response = await api.client
        .get(`${url}/Dropdown`, {})
        .catch((error) => {
          throw error;
        });

      logger.get(logSource).timeEnd("getForDropdownAsync call");

      const responseData = response.data;

      logger.get(logSource).trace("getForDropdownAsync response: %s", response);
      logger
        .get(logSource)
        .debug("getForDropdownAsync responseData: %s", responseData);

      return responseData;
    } catch (error) {
      logger.get(logSource).timeEnd("getForDropdownAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...getForDropdownAsync");
    }
  },

  async postAsync(payload) {
    try {
      logger.get(logSource).info("postAsync...");
      logger.get(logSource).debug("Parameters => payload: %s", payload);

      logger.get(logSource).time("postAsync call");
      const response = await api.client.post(url, payload).catch((error) => {
        throw error;
      });
      logger.get(logSource).timeEnd("postAsync call");
      const responseData = response.data;

      logger.get(logSource).trace("postAsync response: %s", response);
      logger.get(logSource).debug("postAsync responseData: %s", responseData);

      return responseData;
    } catch (error) {
      logger.get(logSource).timeEnd("postAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...postAsync");
    }
  },

  async putAsync(payload) {
    try {
      logger.get(logSource).info("putAsync...");
      logger.get(logSource).debug("Parameters => payload: %s", payload);

      logger.get(logSource).time("putAsync call");
      const response = await api.client.put(url, payload).catch((error) => {
        throw error;
      });
      logger.get(logSource).timeEnd("putAsync call");
      const responseData = response.data;

      logger.get(logSource).trace("putAsync response: %s", response);
      logger.get(logSource).debug("putAsync client: %s", responseData);

      return responseData;
    } catch (error) {
      logger.get(logSource).timeEnd("putAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...putAsync");
    }
  },

  async deleteAsync(payload) {
    try {
      logger.get(logSource).info("deleteAsync...");
      logger.get(logSource).debug("Parameters => payload: %s", payload);

      logger.get(logSource).time("deleteAsync call");
      await api.client
        .delete(
          `${url}/${payload.CountryRegionId}?concurrencyRV=${encodeURIComponent(
            payload.ConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(logSource).timeEnd("deleteAsync call");
    } catch (error) {
      logger.get(logSource).timeEnd("deleteAsync call");
      logger.get(logSource).error(error);
      throw error;
    } finally {
      logger.get(logSource).info("...deleteAsync");
    }
  },
};
