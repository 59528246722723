import { render, staticRenderFns } from "./ItemisedNotes.vue?vue&type=template&id=3dad9e76"
import script from "./ItemisedNotes.vue?vue&type=script&lang=js"
export * from "./ItemisedNotes.vue?vue&type=script&lang=js"
import style0 from "./ItemisedNotes.vue?vue&type=style&index=0&id=3dad9e76&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workarea\\Atom Software\\Clients\\Away We Go Tours\\src\\awgt-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3dad9e76')) {
      api.createRecord('3dad9e76', component.options)
    } else {
      api.reload('3dad9e76', component.options)
    }
    module.hot.accept("./ItemisedNotes.vue?vue&type=template&id=3dad9e76", function () {
      api.rerender('3dad9e76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ItemisedNotes.vue"
export default component.exports