<template>
  <mdb-range-input
    :className="className"
    :secondInputClass="secondInputClass"
    :min="min"
    :max="max"
    :value="value"
    :secondValue="secondValue"
    :wrapperClass="wrapperClass"
    :multi="multi"
    @getValue="onChange"
  />
</template>

<script>
import { mdbRangeInput } from "mdbvue";

export default {
  name: "asoftSlider",
  components: {
    mdbRangeInput,
  },
  props: {
    className: {
      type: String,
    },
    secondInputClass: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: [Number],
      default: 50,
    },
    secondValue: {
      type: [Number],
      default: 75,
    },
    wrapperClass: {
      type: String,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(val) {
      let intVal = parseInt(val);
      this.$emit("input", intVal);
    },
  },
};
</script>
