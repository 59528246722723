import Vue from "vue";
import { constants } from "@/shared/constants.js";
import lodash from "lodash";

let sanitizeHtml = require("sanitize-html");

let formatPhoneNumber = (rawPhoneNum) => {
  //Assume a raw phone number
  if (rawPhoneNum.length == 6) {
    if (rawPhoneNum.substring(0, 2) == "13")
      return `${rawPhoneNum.substring(0, 2)} ${rawPhoneNum.substring(
        2,
        4
      )} ${rawPhoneNum.substring(4)}`;
  }

  if (rawPhoneNum.length == 10) {
    if (
      rawPhoneNum.substring(0, 4) == "1300" ||
      rawPhoneNum.substring(0, 4) == "1800" ||
      rawPhoneNum.substring(0, 2) == "04"
    )
      return `${rawPhoneNum.substring(0, 4)} ${rawPhoneNum.substring(
        4,
        7
      )} ${rawPhoneNum.substring(7)}`;
    else
      return `${rawPhoneNum.substring(0, 2)} ${rawPhoneNum.substring(
        2,
        6
      )} ${rawPhoneNum.substring(6)}`;
  }

  if (rawPhoneNum.length == 11) {
    return `${rawPhoneNum.substring(0, 2)} ${rawPhoneNum.substring(
      2,
      3
    )} ${rawPhoneNum.substring(3, 7)} ${rawPhoneNum.substring(7)}`;
  }

  return rawPhoneNum;
};

let sharedMethods = {
  formatDate(date) {
    return this.$moment(date).format("dddd D MMMM yyyy");
  },

  formatPricingString(pricingString) {
    if (pricingString == null)
      return "<i style='color:red'>Price not found</i>";

    // Format the price above to USD using the locale, style, and currency.
    let currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AUD",
    });

    return currency.format(pricingString);
  },

  formatPriceWithPriceType(chronology, price) {
    let priceType = chronology.PriceTypeCd;
    //The following is a currency formatter
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    let priceTypeStr;

    switch (priceType) {
      case "PrT_I":
        priceTypeStr = "(GST Incl)";
        break;
      case "PrT_E":
        priceTypeStr = "(GST Excl)";
        break;
      case "PrT_F":
        priceTypeStr = "(GST Free)";
        break;
      case "PrT_Q":
        return "Pricing is determined when ordering";
      case "PrT_Z":
        return "This product is free of charge";
      default:
        priceTypeStr = "(GST Status Unknown!)";
    }
    priceTypeStr = `${currencyFormatter.format(
      price.ExGSTUnitAmt + price.GSTUnitAmt
    )} ${priceTypeStr} ${this.getPerQuantityUnitQualifier(chronology)}`;
    for (let scale of price.PricingScales) {
      //if (scale.QuantityUnitCd.substr(0, 4) == "QU_P")
      if (scale.PricingScaleCd == "PS_R") {
        if (scale.MaxQuantity == null)
          priceTypeStr += ` for ${scale.MinQuantity} ${this.getQuantityUnit(
            chronology,
            scale.QuantityUnitCd,
            scale.MaxQuantity
          )} or more`;
        else
          priceTypeStr += ` for ${scale.MinQuantity} to ${
            scale.MaxQuantity
          } ${this.getQuantityUnit(
            chronology,
            scale.QuantityUnitCd,
            scale.MaxQuantity
          )}`;
      } else if (scale.PricingScaleCd == "PS_X") {
        if (scale.MaxQuantity == null)
          priceTypeStr += ` for ${scale.MinQuantity} ${this.getQuantityUnit(
            chronology,
            scale.QuantityUnitCd,
            scale.MinQuantity
          )} or more`;
        else
          priceTypeStr += ` for up to ${
            scale.MaxQuantity
          } ${this.getQuantityUnit(
            chronology,
            scale.QuantityUnitCd,
            scale.MaxQuantity
          )}`;
      } else if (scale.PricingScaleCd == "PS_M") {
        priceTypeStr += ` for at least ${
          scale.MinQuantity
        } ${this.getQuantityUnit(
          chronology,
          scale.QuantityUnitCd,
          scale.MinQuantity
        )}`;
      }
    }

    return priceTypeStr;
  },

  getPerQuantityUnitQualifier(chronology) {
    let perItemQualifier = "";
    for (let pricingScaleType of chronology.PricingScaleTypes)
      if (pricingScaleType.QuantityUnitPurposeCd == "QUP_P")
        perItemQualifier += `, per ${this.getQuantityUnit(
          chronology,
          pricingScaleType.QuantityUnitCd,
          1 //Need singular quantity unit
        )}`;

    return perItemQualifier.substring(2);
  },

  getScaledByQualifier(chronology) {
    let perItemQualifier = "";
    for (let pricingScaleType of chronology.PricingScaleTypes)
      if (pricingScaleType.PricingScaleCd != "PS_U")
        perItemQualifier += `, by ${this.getQuantityUnit(
          chronology,
          pricingScaleType.QuantityUnitCd,
          1 //Need singular quantity unit
        )}`;

    return perItemQualifier.substring(2);
  },

  getPluralQuantityUnit(chronology, quantityUnitCd) {
    switch (quantityUnitCd) {
      case "QU_I":
        return this.getChronologyPluralItemAlias(chronology);
      case "QU_P":
        return "people";
      case "QU_D":
        return "days";
      case "QU_N":
        return "nights";
      case "QU_H":
        return "hours";
      case "QU_M":
        return "minutes";
      case "QU_G":
        return "groups";
      default:
        return "unknown quantity units";
    }
  },

  getQuantityUnit(chronology, quantityUnitCd, quantity) {
    if (quantity == 1)
      return this.getSingleQuantityUnit(chronology, quantityUnitCd);
    else return this.getPluralQuantityUnit(chronology, quantityUnitCd);
  },

  getSingleQuantityUnit(chronology, quantityUnitCd) {
    switch (quantityUnitCd) {
      case "QU_I":
        return this.getChronologySingularItemAlias(chronology);
      case "QU_P":
        return "person";
      case "QU_D":
        return "day";
      case "QU_N":
        return "night";
      case "QU_H":
        return "hour";
      case "QU_M":
        return "minute";
      case "QU_G":
        return "group";
      default:
        return "unknown quantity units";
    }
  },

  getChronologySingularItemAlias(chronology) {
    for (let pricingScaleType of chronology.PricingScaleTypes) {
      if (pricingScaleType.QuantityUnitCd == "QU_I")
        return chronology.SingularItemAlias;
    }

    return "unknown item plural alias";
  },

  getChronologyPluralItemAlias(chronology) {
    for (let pricingScaleType of chronology.PricingScaleTypes) {
      if (pricingScaleType.QuantityUnitCd == "QU_I")
        return chronology.PluralItemAlias;
    }

    return "unknown item plural alias";
  },

  getListOfChronologyPrices(chronology) {
    let result = [];
    let priceType = chronology.PriceTypeCd;

    for (let cplp of chronology.ProductLinePrices) {
      //The following is a currency formatter
      const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      let priceTypeStr;
      switch (priceType) {
        case "PrT_I":
          priceTypeStr = "(GST Incl)";
          break;
        case "PrT_E":
          priceTypeStr = "(GST Excl)";
          break;
        case "PrT_F":
          priceTypeStr = "(GST Free)";
          break;
        case "PrT_Q":
          priceTypeStr = "Pricing is determined when ordering";
          break;
        default:
          priceTypeStr = "(GST Status Unknown!)";
      }

      if (priceType == "PrT_Q") result.push(priceTypeStr);
      else {
        result.push(
          currencyFormatter.format(cplp.ExGSTAmount + cplp.GSTAmount) +
            " " +
            priceTypeStr
        );
      }
    }

    return result;
  },

  getPricingMethodTooltip: function (quantityUnit) {
    let singleQuantityUnitName = "";
    let singleUnitName = "";
    let multiUnitName = "";

    switch (quantityUnit) {
      case "QU_I":
        singleQuantityUnitName = "item";
        break;
      case "QU_B":
        singleQuantityUnitName = "booking";
        break;
      case "QU_N":
        singleQuantityUnitName = "night";
        break;
      case "QU_D":
        singleQuantityUnitName = "day";
        break;
      case "QU_H":
        singleQuantityUnitName = "hour";
        break;
      default:
        return "getPricingMethodTooltip error (" + quantityUnit + ").";
    }

    /*
      Per <QuantityUnitCd> pricing, scaled by the number of <ScalingUnits>:
      QuantityUnits are: item, group, person, night, day, hour
      ScalingUnits are: unscaled, items, groups, people, nights, days, hours
      Scale is: Minimum Quantity, Maximum Quantity, Range

      ("Per item pricing, scaled by the number of items" is the same as "Per booking pricing, scaled by the number of items")
      Per item pricing, unscaled - purchasing for a number of items (scale is by Minimum Quantity of 1)
      Per item pricing, scaled by the number of items - purchasing for a number of items
      Per item pricing, scaled by the number of people - purchasing for a number of items and a number of people
      Per item pricing, scaled by the number of groups - purchasing for a number of items and a number of groups
      Per item pricing, scaled by the number of nights - purchasing for a number of items and a number of nights
      Per item pricing, scaled by the number of days - purchasing for a number of items and a number of days
      Per item pricing, scaled by the number of hours - purchasing for a number of items and a number of hours

      Per group pricing, unscaled - purchasing for a number of groups (scale is by Minimum Quantity of 1)
      Per group pricing, scaled by the number of items - purchasing for a number of items (per group) and the number of groups
      Per group pricing, scaled by the number of people - purchasing for a number of people (per group) and the number of groups
      Per group pricing, scaled by the number of groups - purchasing for a number of groups
      Per group pricing, scaled by the number of nights - purchasing for a number of night and the number of groups (assume one product per group)
      Per group pricing, scaled by the number of days - purchasing for a number of days and the number of groups (assume one product per group)
      Per group pricing, scaled by the number of hours - purchasing for a number of hours and the number of groups (assume one product per group)

      Per person pricing, unscaled - purchasing for a number of people (scale is by Minimum Quantity of 1)
      Per person pricing, scaled by the number of items - purchasing for a number of people and a number of items
      Per person pricing, scaled by the number of people - purchasing for a number of people
      Per person pricing, scaled by the number of groups - purchasing for a number of people (per group) and a number of groups
      Per person pricing, scaled by the number of nights - purchasing for a number of people and a number of nights
      Per person pricing, scaled by the number of days - purchasing for a number of people and a number of days
      Per person pricing, scaled by the number of hours - purchasing for a number of people and a number of hours

      Per night pricing, unscaled - purchasing for a number of nights (scale is by Minimum Quantity of 1)
      Per night pricing, scaled by the number of items - purchasing for a number of nights and a number of items
      Per night pricing, scaled by the number of people - purchasing for a number of nights and a number of people
      Per night pricing, scaled by the number of groups - purchasing for a number of nights and a number of groups
      Per night pricing, scaled by the number of night - purchasing for a number of nights

      Per day pricing, unscaled - purchasing for a number of days (scale is by Minimum Quantity of 1)
      Per day pricing, scaled by the number of items - purchasing for a number of days and a number of items
      Per day pricing, scaled by the number of people - purchasing for a number of days and a number of people
      Per day pricing, scaled by the number of groups - purchasing for a number of days and a number of groups
      Per day pricing, scaled by the number of days - purchasing for a number of days

      Per hour pricing, unscaled - purchasing for a number of hours (scale is by Minimum Quantity of 1)
      Per hour pricing, scaled by the number of items - purchasing for a number of hours and a number of items
      Per hour pricing, scaled by the number of people - purchasing for a number of hours and a number of people
      Per hour pricing, scaled by the number of groups - purchasing for a number of hours and a number of groups
      Per hour pricing, scaled by the number of hours - purchasing for a number of hours
    */

    if (quantityUnit == "QU_I") {
      return (
        `<ul class='tooltip-listalignment'><li>Singular Price: per ${singleUnitName} price</li>` +
        `<li class='tooltip-linepadding'>Scaled Unit Price by Unit: per ${singleUnitName} pricing, scaled by the number of ${multiUnitName}</li>` +
        `<li class='tooltip-linepadding'>Scaled Total Price by Unit: Set total pricing, scaled by the number of ${multiUnitName}</li></ul>`
      );
    } else if (quantityUnit == "QU_B") {
      return `<ul class='tooltip-listalignment'><li>Singular Price: one price for a single ${singleQuantityUnitName}</li>`;
    } else {
      return (
        `<ul class='tooltip-listalignment'><li>Singular Price: per ${singleQuantityUnitName} price</li>` +
        `<li class='tooltip-linepadding'>Scaled Period Price by Period: per ${singleQuantityUnitName} pricing, scaled by the number of ${singleQuantityUnitName}s</li>` +
        `<li class='tooltip-linepadding'>Scaled Period Price by Unit: per ${singleQuantityUnitName} pricing, scaled by the number of ${multiUnitName}</li>` +
        `<li class='tooltip-linepadding'>Scaled Unit Price by Period: per ${singleUnitName} pricing, scaled by the number of ${singleQuantityUnitName}s</li>` +
        `<li class='tooltip-linepadding'>Scaled Period Price by Unit: per ${singleQuantityUnitName} pricing, scaled by the number of ${multiUnitName}</li>` +
        `<li class='tooltip-linepadding'>Scaled Total Price by Unit: per ${singleQuantityUnitName} pricing for a specified number of ${multiUnitName}</li>` +
        `<li class='tooltip-linepadding'>Scaled Total Price by Period: per ${singleUnitName} pricing for a specified number of ${singleQuantityUnitName}s</li></ul>`
      );
    }
  },

  getOrderProductLineChronology(commercialProductLineReference, pricedForDt) {
    let commercialProductLine = this?.product?.CommercialProductLines.find(
      (cpl) => cpl.Reference == commercialProductLineReference
    );

    let chronology = commercialProductLine?.Chronology.find(
      (c) =>
        new Date(c.ActiveFromDt) <= new Date(pricedForDt) &&
        (c.ActiveToDt == null ||
          new Date(c.ActiveToDt) >= new Date(pricedForDt))
    );

    return chronology;
  },

  findDetailHeaderForCommercialProductLine(pc) {
    let nowDt = new Date(); // new Date(2021, 1, 1);
    Vue.set(pc, "ChronologyHeaderIdx", -1);
    Vue.set(pc, "priceCurrency", "unknown");

    for (let index = 0; index < pc.Chronology.length; index++) {
      let validFromDate = new Date(pc.Chronology[index].ActiveFromDt);
      let validToDate = new Date(
        pc.Chronology[index].ActiveToDt ?? "2999-12-31"
      ).setHours(23, 59, 59, 999);

      /**
       * TODO: need to check this if condition is not applied.
       */
      if (pc.ChronologyHeaderIdx != -1) {
        if (nowDt >= validFromDate && nowDt <= validToDate) {
          pc.ChronologyHeaderIdx = index;
          pc.priceCurrency = constants.PRICECURRENCY_Current;
          break;
        } else {
          let chronologyHeaderValidToDate = new Date(
            pc.Chronology[pc.ChronologyHeaderIdx].ActiveToDt ?? "2999-12-31"
          ).setHours(23, 59, 59, 999);

          //If the date is in the past, if it's more recent than the current chronologyHeader then use it.
          if (
            nowDt > validToDate &&
            validToDate > chronologyHeaderValidToDate
          ) {
            pc.ChronologyHeaderIdx = index;
            pc.priceCurrency = constants.PRICECURRENCY_Past;
          }
          //If the date is in the future, if it's more recent than the current chronologyHeader then use it.
          else if (
            (nowDt < validToDate &&
              validToDate < chronologyHeaderValidToDate) ||
            chronologyHeaderValidToDate < nowDt
          ) {
            pc.ChronologyHeaderIdx = index;
            pc.priceCurrency = constants.PRICECURRENCY_Future;
          }
        }
      } else {
        pc.ChronologyHeaderIdx = index;
        if (nowDt >= validFromDate && nowDt <= validToDate)
          pc.priceCurrency = constants.PRICECURRENCY_Current;
        else if (nowDt < validFromDate)
          pc.priceCurrency = constants.PRICECURRENCY_Future;
        else if (nowDt > validToDate)
          pc.priceCurrency = constants.PRICECURRENCY_Past;
      }
    }
  },

  convertDateFormat(d, from, to) {
    return this.$moment(d, from).format(to);
  },

  htmlSanitize(str) {
    return sanitizeHtml(str, {
      allowedTags: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "blockquote",
        "p",
        "a",
        "ul",
        "ol",
        "nl",
        "li",
        "b",
        "i",
        "u",
        "strong",
        "em",
        "strike",
        "code",
        "hr",
        "br",
        "div",
        "table",
        "thead",
        "caption",
        "tbody",
        "tr",
        "th",
        "td",
        "pre",
        "font",
      ],
      disallowedTagsMode: "discard",
      allowedAttributes: {
        a: ["href", "name", "target"],
        // We don't currently allow img itself by default, but this
        // would make sense if we did. You could add srcset here,
        // and if you do the URL is checked for safety
        img: ["src"],
        font: ["color"],
        p: ["style"],
      },
      // Lots of these won't come up by default because we don't allow them
      selfClosing: [
        "img",
        "br",
        "hr",
        "area",
        "base",
        "basefont",
        "input",
        "link",
        "meta",
      ],
      // URL schemes we permit
      allowedSchemes: ["http", "https", "ftp", "mailto"],
      allowedSchemesByTag: {},
      allowedSchemesAppliedToAttributes: ["href", "src", "cite"],
      allowProtocolRelative: true,
    });
  },

  onKeyDownEnterSearch(callback) {
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e;
      if (ev.keyCode === 13) {
        callback();
      }
    };
  },
  cancelOnKeyDownEnterSearch() {
    document.onkeydown = null;
  },

  getItineraryIcon(itineraryEntryTypeCd, itineraryEntrySubTypeCd) {
    switch (itineraryEntryTypeCd) {
      case "IET_Tr": {
        switch (itineraryEntrySubTypeCd) {
          case "IEST_TrCC":
            return "bus-alt";
          case "IEST_TrFl":
            return "plane";
          case "IEST_TrBu":
            return "bus";
          case "IEST_TrTr":
            return "train";
          case "IEST_TrFe":
            return "ship";
          case "IEST_TrTm":
            return "subway";
          case "IEST_TrCr":
            return "car";
          default:
            return "question";
        }
      }
      case "IET_An":
        return "bed-alt";
      case "IET_Ay":
        return "ticket-alt";
      default:
        return "question";
    }
  },

  getProductIcon(productTypeCd, productSubTypeCd) {
    switch (productTypeCd) {
      case "PT_Tr": {
        switch (productSubTypeCd) {
          case "PST_TrCC":
            return "bus-alt";
          case "PST_TrFl":
            return "plane";
          case "PST_TrBu":
            return "bus";
          case "PST_TrTr":
            return "train";
          case "PST_TrFe":
            return "ship";
          case "PST_TrTm":
            return "subway";
          case "PST_TrCr":
            return "car";
          default:
            return "question";
        }
      }
      case "PT_An":
        return "bed-alt";
      case "PT_Ay": {
        switch (productSubTypeCd) {
          case "PST_AyFI":
            return "route";
          case "PST_AyAy":
            return "ticket-alt";
          default:
            return "question";
        }
      }
      case "PT_Me":
        return "shopping-cart";
      default:
        return "question";
    }
  },

  /*
    Removes duplicate items from an array of objects matching by a property.
  */
  arrayUnique(array, matchByProp) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i][matchByProp] === a[j][matchByProp]) a.splice(j--, 1);
      }
    }

    return a;
  },

  toStringReferenceLower(searchString) {
    if (lodash.isEmpty(searchString)) {
      return null;
    }
    const converter = (reference, offset) => {
      const initials = reference.substring(0, offset);
      const toConvert = reference
        .substring(offset, reference.length)
        .toLowerCase();
      return initials + toConvert;
    };

    if (
      searchString.startsWith("IL") ||
      searchString.startsWith("AC") ||
      searchString.startsWith("CL") ||
      searchString.startsWith("TB")
    ) {
      return converter(searchString, 2);
    } else if (
      searchString.startsWith("U") ||
      searchString.startsWith("W") ||
      searchString.startsWith("S") ||
      searchString.startsWith("P")
    ) {
      return converter(searchString, 1);
    } else {
      throw new Error("Invalid reference number");
    }
  },
};
export { sharedMethods, formatPhoneNumber };
