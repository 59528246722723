var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("Itinerary Entries Report"),
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            multiple: "",
            selectAll: "",
            label: "Workgroup",
            s: "",
            itemValueProperty: "Code",
            items: _vm.workgroupsList,
            itemTextProperty: "Name",
            bg: "",
            search: "",
          },
          model: {
            value: _vm.workgroups,
            callback: function ($$v) {
              _vm.workgroups = $$v
            },
            expression: "workgroups",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            multiple: "",
            selectAll: "",
            label: "Consultants",
            itemValueProperty: "Code",
            items: _vm.consultantsList,
            itemTextProperty: "Name",
            bg: "",
            search: "",
          },
          on: { focus: _vm.onFocusConsultantDropdown },
          model: {
            value: _vm.consultants,
            callback: function ($$v) {
              _vm.consultants = $$v
            },
            expression: "consultants",
          },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            multiple: "",
            selectAll: "",
            label: "Type",
            itemValueProperty: "Code",
            items: _vm.getProductTypes(false),
            itemTextProperty: "Name",
            bg: "",
            search: "",
          },
          model: {
            value: _vm.productTypes,
            callback: function ($$v) {
              _vm.productTypes = $$v
            },
            expression: "productTypes",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
      },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            label: "Year",
            itemValueProperty: "Code",
            items: _vm.getYears(2017),
            itemTextProperty: "Name",
            bg: "",
          },
          model: {
            value: _vm.year,
            callback: function ($$v) {
              _vm.year = $$v
            },
            expression: "year",
          },
        }),
        _c(
          "mdb-form-inline",
          {
            staticClass: "flex-wrap mx-2 px-3",
            staticStyle: { height: "45px" },
          },
          [
            _c(
              "span",
              { staticClass: "mr-3", staticStyle: { width: "120px" } },
              [_vm._v("Time Scope")]
            ),
            _c("awgt-input", {
              staticClass: "mr-3",
              attrs: {
                id: "timeScope_Term",
                type: "radio",
                name: "TimeScopeTerm",
                label: "Term",
                radioValue: "Term",
              },
              model: {
                value: _vm.timeScope,
                callback: function ($$v) {
                  _vm.timeScope = $$v
                },
                expression: "timeScope",
              },
            }),
            _c("awgt-input", {
              staticClass: "mr-3",
              attrs: {
                id: "timeScope_Date",
                type: "radio",
                name: "TimeScopeDate",
                label: "Date",
                radioValue: "Date",
              },
              model: {
                value: _vm.timeScope,
                callback: function ($$v) {
                  _vm.timeScope = $$v
                },
                expression: "timeScope",
              },
            }),
          ],
          1
        ),
        _vm.timeScope == "Term"
          ? _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "90px" },
              attrs: {
                multiple: "",
                selectAll: "",
                label: "Term",
                itemValueProperty: "Code",
                items: _vm.getSchoolTerms(false),
                itemTextProperty: "Name",
                bg: "",
              },
              model: {
                value: _vm.terms,
                callback: function ($$v) {
                  _vm.terms = $$v
                },
                expression: "terms",
              },
            })
          : _vm._e(),
        _vm.timeScope == "Date"
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "calendar-alt",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("asoftDatePicker", {
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "min-width": "110px" },
                      attrs: {
                        label: "Start",
                        autoHide: "",
                        dataDateFormat: _vm.dataDateFormat,
                        displayDateFormat: _vm.displayDateFormat,
                        setTime: "00:00:00.000",
                        bg: "",
                      },
                      on: { change: (fromDt) => _vm.onFromDtChange(fromDt) },
                      model: {
                        value: _vm.startDate,
                        callback: function ($$v) {
                          _vm.startDate = $$v
                        },
                        expression: "startDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "calendar-alt",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("asoftDatePicker", {
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "min-width": "110px" },
                      attrs: {
                        label: "End",
                        autoHide: "",
                        dataDateFormat: _vm.dataDateFormat,
                        displayDateFormat: _vm.displayDateFormat,
                        setTime: "00:00:00.000",
                        bg: "",
                      },
                      model: {
                        value: _vm.toDate,
                        callback: function ($$v) {
                          _vm.toDate = $$v
                        },
                        expression: "toDate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "90px" },
          attrs: {
            multiple: "",
            selectAll: "",
            label: "Supplier",
            itemValueProperty: "Code",
            items: _vm.suppliersList,
            itemTextProperty: "Name",
            bg: "",
            search: "",
          },
          model: {
            value: _vm.suppliers,
            callback: function ($$v) {
              _vm.suppliers = $$v
            },
            expression: "suppliers",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
      },
      [
        _c(
          "mdb-form-inline",
          { staticClass: "d-flex justify-content-end flex-grow-1" },
          [
            _vm.bookingTables.length > 0
              ? _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2 float-right",
                    attrs: { type: "button" },
                    on: { click: _vm.onExport },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "file-pdf" },
                    }),
                    _vm._v("Export "),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onClearGenerateCriteria },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "eraser" },
                }),
                _vm._v("Clear "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onGenerate },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "search" },
                }),
                _vm._v("Generate "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.bookingTables.length == 0 && _vm.isGenerated
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", [_vm._v("No itinerary entries result found")]),
        ])
      : _vm._e(),
    _c("div", { attrs: { id: "tableContent" } }, [
      _vm.bookingTables.length > 0
        ? _c(
            "div",
            {
              staticClass: "mx-2",
              staticStyle: { border: "1px solid black", padding: "10px 10px" },
            },
            [
              _c("div", [_c("h4", [_vm._v("Itinerary Entries")])]),
              _c(
                "div",
                [
                  _vm.workgroupsCache.length > 0
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.workgroupsCache
                                .map((w) => {
                                  return _vm.getWorkgroupName(w)
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.consultantsCache.length > 0
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.consultantsCache
                                .map((c) => {
                                  return _vm.getConsultantName(c)
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.suppliersCache.length > 0
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.suppliersCache
                                .map((s) => {
                                  return _vm.getSupplierName(s)
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.yearCache != null || _vm.yearCache != ""
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(_vm._s(_vm.yearCache)),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.timeScopeCache == "Term" && _vm.termsCache.length > 0
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.termsCache
                                .filter((t) => {
                                  return t != "ST_X"
                                })
                                .map((t) => {
                                  return _vm.getTermName(t)
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.timeScopeCache == "Date" &&
                  (_vm.startDateCache != null || _vm.toDateCache != null)
                    ? [
                        _c("span", { staticClass: "mx-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.startDateCache != null
                                ? _vm
                                    .$moment(_vm.startDateCache)
                                    .format("D MMM yyyy") + " to"
                                : ""
                            ) +
                              " " +
                              _vm._s(
                                _vm.toDateCache != null
                                  ? _vm
                                      .$moment(_vm.toDateCache)
                                      .format("D MMM yyyy")
                                  : ""
                              )
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._l(_vm.bookingTables, function (groupData, indOfTable) {
                return [
                  _c(
                    "div",
                    {
                      key: indOfTable,
                      staticClass: "html2pdf__page-break",
                      staticStyle: { margin: "25px 0px" },
                    },
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "mx-3",
                            staticStyle: {
                              color: "#923f82",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getConsultantName(groupData.OwnerUser))
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "table",
                        {
                          ref: "table" + indOfTable,
                          refInFor: true,
                          staticClass: "table",
                        },
                        [
                          _c("col", {
                            staticStyle: { width: "15%" },
                            attrs: { span: "1" },
                          }),
                          _c("tr", { staticStyle: { "font-weight": "10" } }, [
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByClientName(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Client"),
                                _vm.sortByClientInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByClientInd[indOfTable] == false ||
                                !_vm.sortByClientInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByTBID(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" TB ID"),
                                _vm.sortByTBIDInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByTBIDInd[indOfTable] == false ||
                                !_vm.sortByTBIDInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortClientAddress(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Client Address"),
                                _vm.sortByClientAddressInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByClientAddressInd == false ||
                                !_vm.sortByClientAddressInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByProduct(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Product"),
                                _vm.sortByProductInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByProductInd[indOfTable] == false ||
                                !_vm.sortByProductInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByStatus(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Status"),
                                _vm.sortByStatusInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByStatusInd[indOfTable] == false ||
                                !_vm.sortByStatusInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByGroupName(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Group Name"),
                                _vm.sortByGroupNameInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByGroupNameInd[indOfTable] == false ||
                                !_vm.sortByGroupNameInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByStartDate(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Start Date"),
                                _vm.sortByStartDateInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByStartDateInd[indOfTable] == false ||
                                !_vm.sortByStartDateInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByYearGroup(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Year Group"),
                                _vm.sortByYearGroupInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByYearGroupInd[indOfTable] == false ||
                                !_vm.sortByYearGroupInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByStudentCount(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Student No."),
                                _vm.sortByStudentCountInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByStudentCountInd[indOfTable] ==
                                  false ||
                                !_vm.sortByStudentCountInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByAdultCount(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Adult No."),
                                _vm.sortByAdultCountInd[indOfTable] == true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByAdultCountInd[indOfTable] == false ||
                                !_vm.sortByAdultCountInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "headers-frame-header",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortByOrganizingTeacher(
                                      groupData,
                                      indOfTable
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" Organizing Teacher."),
                                _vm.sortByOrganizingTeacherInd[indOfTable] ==
                                true
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-down" },
                                    })
                                  : _vm._e(),
                                _vm.sortByOrganizingTeacherInd[indOfTable] ==
                                  false ||
                                !_vm.sortByOrganizingTeacherInd[indOfTable]
                                  ? _c("mdb-icon", {
                                      attrs: { icon: "caret-up" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(groupData.Bookings, function (row, indOfRow) {
                            return [
                              _c(
                                "tr",
                                { key: groupData.OwnerUser + indOfRow },
                                [
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(row.Client.DisplayName) + " "
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.Reference))]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.Client.Address
                                            ? _vm.getAddress(row.Client.Address)
                                            : " - "
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.ProductName))]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getBookingStatusName(
                                            row.BookingStatus
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(row.GroupName) + " "),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$moment(row.Date)
                                            .format("ddd DD MMM yyyy hh:mm A")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(row.YearGroups) + " "),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(row.StudentsCount) + " "
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(row.AdultsCount) + " "),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.BookingContacts
                                            ? row.BookingContacts[0].Name
                                            : " - "
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                          _c("tr", { staticClass: "total-row" }, [
                            _c("td", [_vm._v("Total")]),
                            _c("td", { attrs: { colspan: "10" } }, [
                              _vm._v(
                                " " + _vm._s(groupData.Bookings.length) + " "
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }