var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-header" }, [
    _c("h5", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.label))]),
    _c("a", { staticClass: "close", on: { click: _vm.onClose } }, [
      _vm._v("×"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }