<template>
  <div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onAdd"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div v-if="orders.length == 0" class="text-center">
      <i>No orders found</i>
    </div>
    <template v-else>
      <mdb-card
        v-for="(order, orderIndex) in orders"
        :key="'order-list-' + orderIndex"
        class="mb-3"
        :style="order.IsActiveInd ? 'opacity:1' : 'opacity:0.5'"
      >
        <mdb-card-header
          @click.native="onToggleOrderExpansion(order)"
          tag="button"
        >
          <div>
            <span class="float-right">
              <mdb-icon
                v-if="order.Expanded == true"
                icon="angle-up"
                size="2x"
                class="pt-2"
                color="primary"
              />
              <mdb-icon
                v-else
                icon="angle-down"
                size="2x"
                class="pt-2"
                color="primary"
              />
            </span>
            <div>
              Reseller: {{ order.ResellerSupplierName }}
              <template v-if="!order.IsActiveInd">
                <span class="alert-text">(Deleted)</span>
              </template>
            </div>
            <div>
              <span style="font-size: 75%" class="ml-1"
                ><i>({{ order.Reference }})</i></span
              >
            </div>
          </div>
          <div
            v-for="(primaryProduct, index) in order.PrimaryOrderProducts"
            :key="'primary-product-view-' + index"
            class="my-2 mx-1 flex row align-items-center justify-content-left"
          >
            <mdb-icon
              :icon="
                getProductIcon(
                  primaryProduct.ProductTypeCd,
                  primaryProduct.ProductSubTypeCd
                )
              "
              size="2x"
              class="pr-3"
              color="primary"
              slot="reference"
              style="width: 45px"
            />
            <h5 class="d-flex flex-column" style="text-align: left">
              <span
                >{{ primaryProduct.ProviderSupplierName }}
                -
                {{ primaryProduct.Name }}</span
              >
            </h5>
          </div>
        </mdb-card-header>

        <expansion-region :toggle="order.Expanded">
          <mdb-card-body>
            <h3>Products:</h3>
            <div
              class="my-3"
              v-for="(
                orderProduct, orderProductIndex
              ) in order.PrimaryOrderProducts"
              :key="'primary-product-details-' + orderProductIndex"
            >
              <template v-if="orderProduct.Product">
                <h5>Name: {{ orderProduct.Name }}</h5>
                <h5>
                  Priced for date:
                  {{ $moment(orderProduct.PricedForDt).format("DD/MM/YYYY") }}
                </h5>
                <h5>Product lines:</h5>
                <div
                  v-for="(
                    orderProductLine, oplIndex
                  ) in orderProduct.OrderProductLines"
                  :key="'order-product-line' + oplIndex"
                >
                  <div>{{ orderProductLine.Name }}</div>
                </div>
                <div
                  class="my-2"
                  v-if="orderProduct.GroupOrderProducts.length === 0"
                >
                  <h5>No group products</h5>
                </div>
                <template v-else>
                  <h5 class="my-2">Group Products:</h5>
                  <div
                    v-for="(
                      groupOrderProduct, ropIndex
                    ) in orderProduct.GroupOrderProducts"
                    :key="'group-order-product' + ropIndex"
                  >
                    <div>{{ groupOrderProduct.Name }}</div>
                  </div>
                </template>
                <div class="section-break"></div>
              </template>
            </div>
            <div v-if="order.IsActiveInd" class="float-right mb-3">
              <awgt-std-button
                type="button"
                class="command-button mr-2"
                @click="onEdit(orderIndex)"
              >
                <mdb-icon icon="edit" class="mr-1" />Edit
              </awgt-std-button>
              <awgt-std-button
                type="button"
                class="command-button"
                @click="onDelete(orderIndex)"
              >
                <mdb-icon icon="trash-alt" class="mr-1" />Delete
              </awgt-std-button>
            </div>

            <div class="float-right mb-3" v-else>
              <awgt-std-button
                type="button"
                title="Delete"
                class="command-button mx-2"
                @click="() => onUndelete(orderIndex)"
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Undelete
              </awgt-std-button>
              <awgt-std-button
                type="button"
                title="Delete"
                class="command-button mx-2"
                @click="() => onPermanentlyDelete(orderIndex)"
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Permanently delete
              </awgt-std-button>
            </div>
          </mdb-card-body>
        </expansion-region>
      </mdb-card>
    </template>
    <yes-or-no-prompt ref="handleOrderPrompt"> </yes-or-no-prompt>
  </div>
</template>
<script>
import {
  mdbIcon,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbFormInline,
} from "mdbvue";
import ExpansionRegion from "@/components/ExpansionRegion";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import { sharedMethods } from "@/shared/shared";
import orderApi from "@/api/orderApi.js";
import { mapGetters } from "vuex";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import orderUtil from "./orderUtil.js";
import productApi from "@/api/productApi.js";

export default {
  components: {
    mdbIcon,
    AwgtStdButton,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    ExpansionRegion,
    mdbFormInline,
    yesOrNoPrompt,
  },

  data() {
    return {
      orders: [],
      selectedOrder: null,
      orderEntryDialogMode: null,
      bookingReference: "",
    };
  },

  props: {
    productOrders: {
      type: Array,
      required: true,
    },
  },

  watch: {
    productOrders: {
      handler(to) {
        this.orders = to;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(["order/getEmptyOrder", "order/getEmptyOrderProduct"]),
  },

  methods: {
    ...sharedMethods,
    ...orderUtil,

    onCancel() {
      this.selectedOrder.PrimaryOrderProducts.pop();
      this.orderEntryDialogMode = null; //Ensures it changes when used and is seen by a watch.
    },

    onClose() {},

    onAdd() {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/${this.itineraryReference}/itineraryentries/orders/add`,
      });
    },

    async onDelete(orderIndex) {
      let softDeleteOrder = this.orders[orderIndex];
      softDeleteOrder.IsActiveInd = false;

      let orderResult = await orderApi.putOrder(softDeleteOrder);
      this.$set(this.orders, orderIndex, orderResult);
    },

    onUndelete(orderIndex) {
      this.$refs.handleOrderPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure you wish to recover this order?"
        )
        .then((option) => {
          if (option == true) {
            let undeleteOrder = this.orders[orderIndex];
            undeleteOrder.IsActiveInd = true;
            orderApi
              .putOrder(undeleteOrder)
              .then((orderResult) =>
                this.$set(this.orders, orderIndex, orderResult)
              );
          }
        });
    },

    onPermanentlyDelete(orderIndex) {
      this.$refs.handleOrderPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure you wish to permanently delete this order?"
        )
        .then((option) => {
          if (option == true) {
            let deleteOrder = this.orders[orderIndex];
            orderApi
              .deleteAsync(deleteOrder)
              .then(() => this.orders.splice(orderIndex, 1));
          }
        });
    },

    onEdit(orderIndex) {
      this.$router.push({
        path: `/booking/${this.bookingReference}/itinerary/${this.itineraryReference}/itineraryentries/orders/${this.orders[orderIndex].Reference}/edit`,
      });
    },

    async onToggleOrderExpansion(order) {
      for (let index = 0; index < order.PrimaryOrderProducts.length; index++) {
        const orderProduct = order.PrimaryOrderProducts[index];
        if (!orderProduct.Product) {
          orderProduct.Product = await productApi.getProductByReference(
            orderProduct.Reference
          );
          this.$set(order.PrimaryOrderProducts, index, orderProduct);
        }
      }
      this.$set(order, "Expanded", !order.Expanded);
    },

    onOrderEntryInput(order) {
      if (order.RecordStatus == "Inserted") {
        this.orders.push(order);
      } else {
        let idx = this.orders.findIndex(
          (pb) => pb.Reference == order.Reference
        );
        this.orders[idx] = order;
        this.$emit("input", this.orders);
      }
      this.orderEntryDialogMode = null;
    },
  },

  created() {
    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIOrder)
      .info("RouteParams: %s", routeParams);
    this.bookingReference = routeParams.bookingReference;
    this.itineraryReference = routeParams.itineraryReference;
  },
};
</script>
