var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mdb-wysiwyg-toolbar" }, [
    _c("div", { staticClass: "mdb-wysiwyg-toolbar-group py-1" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.away,
              expression: "away",
            },
          ],
          staticClass: "mdb-wysiwyg-list-button",
          attrs: { disabled: _vm.view !== "html", type: "button" },
          on: {
            click: function ($event) {
              _vm.showList = !_vm.showList
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.textStyles[_vm.textStyle]) + " "),
          _c("i", { staticClass: "fas fa-chevron-down fa-sm pl-2" }),
        ]
      ),
      _vm.showList
        ? _c("ul", { staticClass: "mdb-wysiwyg-list" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("p")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.paragraph))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h1")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 1")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h2")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 2")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h3")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 3")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h4")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 4")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h5")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 5")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("h6")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.heading) + " 6")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeTextStyle("pre")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.translations.preformatted))]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm.tooltips
      ? _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: false,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.removeCssStyling,
                    type: "button",
                  },
                  on: { click: _vm.removeCssStyling },
                },
                [_c("i", { staticClass: "fas fa-remove-format" })]
              ),
            ]),
          ]),
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: false,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.removeHtmlMarkup,
                    type: "button",
                  },
                  on: { click: _vm.removeHtmlMarkup },
                },
                [_vm._m(0)]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: false,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.removeCssStyling,
                    type: "button",
                  },
                  on: { click: _vm.removeCssStyling },
                },
                [_c("i", { staticClass: "fas fa-remove-format" })]
              ),
            ]),
          ]),
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: false,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.removeHtmlMarkup,
                    type: "button",
                  },
                  on: { click: _vm.removeHtmlMarkup },
                },
                [_vm._m(1)]
              ),
            ]),
          ]),
        ]),
    _vm.tooltips
      ? _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.bold,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.bold,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("bold")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-bold" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.italic,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.italic,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("italic")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-italic" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.strikeThrough,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.strikethrough,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("strikeThrough")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-strikethrough" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.underline,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.underline,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("underline")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-underline" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.awayColors,
                      expression: "awayColors",
                    },
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  staticClass: "mdb-wysiwyg-list-button",
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.textcolor,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showColorsList = !_vm.showColorsList
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-font" }),
                  _c("i", { staticClass: "fas fa-chevron-down fa-sm pl-1" }),
                ]
              ),
              _vm.showColorsList
                ? _c(
                    "ul",
                    {
                      staticClass:
                        "mdb-wysiwyg-list mdb-wysiwyg-toolbar-color-palette",
                    },
                    _vm._l(_vm.colors, function (color, index) {
                      return _c("li", { key: index }, [
                        _c("button", {
                          style: { backgroundColor: color },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeColor(color)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.bold,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.bold,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("bold")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-bold" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.italic,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.italic,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("italic")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-italic" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.strikeThrough,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.strikethrough,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("strikeThrough")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-strikethrough" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.underline,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.underline,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("underline")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-underline" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.awayColors,
                      expression: "awayColors",
                    },
                  ],
                  staticClass: "mdb-wysiwyg-list-button",
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.textcolor,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showColorsList = !_vm.showColorsList
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-font" }),
                  _c("i", { staticClass: "fas fa-chevron-down fa-sm pl-1" }),
                ]
              ),
              _vm.showColorsList
                ? _c(
                    "ul",
                    {
                      staticClass:
                        "mdb-wysiwyg-list mdb-wysiwyg-toolbar-color-palette",
                    },
                    _vm._l(_vm.colors, function (color, index) {
                      return _c("li", { key: index }, [
                        _c("button", {
                          style: { backgroundColor: color },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeColor(color)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
    _vm.tooltips
      ? _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.justifyLeft,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignleft,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyLeft")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-left" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.justifyCenter,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.aligncenter,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyCenter")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-center" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.justifyRight,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignright,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyRight")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-right" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    active: _vm.state.justifyFull,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignjustify,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyFull")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-justify" })]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.justifyLeft,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignleft,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyLeft")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-left" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.justifyCenter,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.aligncenter,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyCenter")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-center" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.justifyRight,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignright,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyRight")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-right" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    active: _vm.state.justifyFull,
                    disabled: _vm.view !== "html",
                    title: _vm.translations.alignjustify,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.modify("justifyFull")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-align-justify" })]
              ),
            ]),
          ]),
        ]),
    _vm.tooltips
      ? _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.insertlink,
                    type: "button",
                  },
                  on: { click: _vm.createLink },
                },
                [_c("i", { staticClass: "fas fa-paperclip" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.insertpicture,
                    type: "button",
                  },
                  on: { click: _vm.insertImage },
                },
                [_c("i", { staticClass: "fas fa-image" })]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.insertlink,
                    type: "button",
                  },
                  on: { click: _vm.createLink },
                },
                [_c("i", { staticClass: "fas fa-paperclip" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.insertpicture,
                    type: "button",
                  },
                  on: { click: _vm.insertImage },
                },
                [_c("i", { staticClass: "fas fa-image" })]
              ),
            ]),
          ]),
        ]),
    _vm.tooltips
      ? _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.bulletlist,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.create("insertUnorderedList")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-list-ul" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { position: "bottom", arrow: true },
                      expression: "{ position: 'bottom', arrow: true }",
                    },
                  ],
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.numberedlist,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.create("insertOrderedList")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-list-ol" })]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "mdb-wysiwyg-toolbar-group" }, [
          _c("ul", { staticClass: "mdb-wysiwyg-toolbar-options" }, [
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.bulletlist,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.create("insertUnorderedList")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-list-ul" })]
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: {
                    disabled: _vm.view !== "html",
                    title: _vm.translations.numberedlist,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.create("insertOrderedList")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-list-ol" })]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "fa-stack m-auto",
        staticStyle: { height: "20px", width: "20px" },
      },
      [
        _c("i", {
          staticClass: "fas fa-stack-1x fa-code",
          staticStyle: { top: "-7px" },
        }),
        _c("i", {
          staticClass: "fas fa-stack-1x fa-slash",
          staticStyle: { top: "-7px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "fa-stack m-auto",
        staticStyle: { height: "20px", width: "20px" },
      },
      [
        _c("i", {
          staticClass: "fas fa-stack-1x fa-code",
          staticStyle: { top: "-7px" },
        }),
        _c("i", {
          staticClass: "fas fa-stack-1x fa-slash",
          staticStyle: { top: "-7px" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }