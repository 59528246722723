var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center mb-3" }, [
      _vm._v("Communication Edit"),
    ]),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
          },
          [
            _c(
              "mdb-form-inline",
              {
                staticClass: "flex-wrap border flex-grow-1 mx-2 px-3 py-3 mb-2",
              },
              [
                _c(
                  "span",
                  { staticClass: "mr-3", staticStyle: { "min-width": "40px" } },
                  [_vm._v("Contact With")]
                ),
                _c("awgt-input", {
                  staticClass: "mx-2",
                  attrs: {
                    id: "contactType_Client",
                    type: "radio",
                    name: "contactTypeGroup",
                    label: "Client",
                    radioValue: "CW_C",
                  },
                  model: {
                    value: _vm.communication.CommunicationWith,
                    callback: function ($$v) {
                      _vm.$set(_vm.communication, "CommunicationWith", $$v)
                    },
                    expression: "communication.CommunicationWith",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "mx-2",
                  attrs: {
                    id: "contactType_Supplier",
                    type: "radio",
                    name: "contactTypeGroup",
                    label: "Supplier",
                    radioValue: "CW_S",
                  },
                  model: {
                    value: _vm.communication.CommunicationWith,
                    callback: function ($$v) {
                      _vm.$set(_vm.communication, "CommunicationWith", $$v)
                    },
                    expression: "communication.CommunicationWith",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "mx-2",
                  attrs: {
                    id: "contactType_Other",
                    type: "radio",
                    name: "contactTypeGroup",
                    label: "Other",
                    radioValue: "CW_O",
                  },
                  model: {
                    value: _vm.communication.CommunicationWith,
                    callback: function ($$v) {
                      _vm.$set(_vm.communication, "CommunicationWith", $$v)
                    },
                    expression: "communication.CommunicationWith",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mx-2",
                      staticStyle: { width: "34px" },
                      attrs: {
                        icon: "calendar-alt",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("asoft-date-picker", {
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "min-width": "140px" },
                      attrs: {
                        label: "Occurrence Date",
                        autoHide: "",
                        displayDateFormat: "D MMM YYYY",
                        dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                        setTime: "00:00:00.000",
                        bg: "",
                        width: 300,
                      },
                      model: {
                        value: _vm.communication.OccurrenceDT,
                        callback: function ($$v) {
                          _vm.$set(_vm.communication, "OccurrenceDT", $$v)
                        },
                        expression: "communication.OccurrenceDT",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mx-2",
                      staticStyle: { width: "34px" },
                      attrs: {
                        icon: "clock",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("asoft-time-picker", {
                      staticClass: "flex-grow-1 my-0 mr-2",
                      staticStyle: { width: "180px" },
                      attrs: {
                        label: "Occurrence Time",
                        bg: "",
                        displayFormat: "h:mma",
                        outputFormat: "HH:mm",
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
          _vm.communication.CommunicationWith == "CW_C"
            ? _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "90px" },
                    attrs: {
                      search: "",
                      label: "Client",
                      itemValueProperty: "Code",
                      items: _vm.clients,
                      itemTextProperty: "Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.communication.RelatedClientReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.communication,
                          "RelatedClientReference",
                          $$v
                        )
                      },
                      expression: "communication.RelatedClientReference",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        staticStyle: { width: "34px", "text-align": "center" },
                        attrs: { icon: "user", color: "primary", size: "2x" },
                      }),
                      _c("awgt-input", {
                        staticClass: "flex-grow-1 mr-2",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          id: "communication-person-name-input",
                          counter: "",
                          counterMaxValue: 100,
                          maxlength: "100",
                          label: "Contact Name",
                          bg: "",
                        },
                        model: {
                          value: _vm.communication.ContactNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.communication, "ContactNm", $$v)
                          },
                          expression: "communication.ContactNm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("awgt-std-dropdown", {
                        staticClass: "flex-grow-1 mx-2",
                        staticStyle: { "min-width": "90px" },
                        attrs: {
                          disabled:
                            this.communication.RelatedClientReference != null &&
                            this.communication.RelatedClientReference.length > 0
                              ? false
                              : true,
                          label:
                            this.communication.RelatedClientReference != null &&
                            this.communication.RelatedClientReference.length > 0
                              ? "Booking"
                              : "Booking (Disabled)",
                          itemValueProperty: "Code",
                          items: _vm.getBookings,
                          itemTextProperty: "Name",
                          bg: "",
                        },
                        on: { focus: _vm.onGetBookingsDropdown },
                        model: {
                          value: _vm.communication.RelatedBookingReference,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.communication,
                              "RelatedBookingReference",
                              $$v
                            )
                          },
                          expression: "communication.RelatedBookingReference",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.communication.CommunicationWith == "CW_S"
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-2",
                    staticStyle: { width: "34px", "text-align": "center" },
                    attrs: { icon: "user", color: "primary", size: "2x" },
                  }),
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1 mr-2",
                    staticStyle: { "min-width": "90px" },
                    attrs: {
                      search: "",
                      label: "Supplier",
                      itemValueProperty: "Code",
                      items: _vm.suppliers,
                      itemTextProperty: "Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.communication.RelatedSupplierReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.communication,
                          "RelatedSupplierReference",
                          $$v
                        )
                      },
                      expression: "communication.RelatedSupplierReference",
                    },
                  }),
                  _c("mdb-icon", {
                    staticClass: "mx-2",
                    staticStyle: { width: "34px", "text-align": "center" },
                    attrs: { icon: "user", color: "primary", size: "2x" },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1 mr-2",
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      id: "communication-person-name-input",
                      counter: "",
                      counterMaxValue: 100,
                      maxlength: "100",
                      label: "Contact Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.communication.ContactNm,
                      callback: function ($$v) {
                        _vm.$set(_vm.communication, "ContactNm", $$v)
                      },
                      expression: "communication.ContactNm",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.communication.CommunicationWith == "CW_O"
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
                },
                [
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "90px" },
                    attrs: {
                      search: "",
                      label: "Related Client",
                      itemValueProperty: "Code",
                      items: _vm.clients,
                      itemTextProperty: "Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.communication.RelatedClientReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.communication,
                          "RelatedClientReference",
                          $$v
                        )
                      },
                      expression: "communication.RelatedClientReference",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("awgt-std-dropdown", {
                        staticClass: "flex-grow-1 mx-2",
                        staticStyle: { "min-width": "90px" },
                        attrs: {
                          disabled:
                            this.communication.RelatedClientReference != null &&
                            this.communication.RelatedClientReference.length > 0
                              ? false
                              : true,
                          label:
                            this.communication.RelatedClientReference != null &&
                            this.communication.RelatedClientReference.length > 0
                              ? "Related Booking"
                              : "Related Booking (Disabled)",
                          itemValueProperty: "Code",
                          items: _vm.getBookings,
                          itemTextProperty: "Name",
                          bg: "",
                        },
                        on: { focus: _vm.onGetBookingsDropdown },
                        model: {
                          value: _vm.communication.RelatedBookingReference,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.communication,
                              "RelatedBookingReference",
                              $$v
                            )
                          },
                          expression: "communication.RelatedBookingReference",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "90px" },
                    attrs: {
                      search: "",
                      label: "Related Supplier",
                      itemValueProperty: "Code",
                      items: _vm.suppliers,
                      itemTextProperty: "Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.communication.RelatedSupplierReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.communication,
                          "RelatedSupplierReference",
                          $$v
                        )
                      },
                      expression: "communication.RelatedSupplierReference",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        staticStyle: { width: "34px", "text-align": "center" },
                        attrs: { icon: "user", color: "primary", size: "2x" },
                      }),
                      _c("awgt-input", {
                        staticClass: "flex-grow-1 mr-2",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          id: "communication-person-name-input",
                          counter: "",
                          counterMaxValue: 100,
                          maxlength: "100",
                          label: "Contact Name",
                          bg: "",
                        },
                        model: {
                          value: _vm.communication.ContactNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.communication, "ContactNm", $$v)
                          },
                          expression: "communication.ContactNm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mx-2",
                staticStyle: { width: "34px", "text-align": "center" },
                attrs: { icon: "phone-volume", color: "primary", size: "2x" },
              }),
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mr-2",
                staticStyle: { "min-width": "90px" },
                attrs: {
                  label: "Communication Medium",
                  itemValueProperty: "Code",
                  items: _vm.getCommunicationMediums(),
                  itemTextProperty: "Name",
                  bg: "",
                },
                model: {
                  value: _vm.communication.CommunicationMedium,
                  callback: function ($$v) {
                    _vm.$set(_vm.communication, "CommunicationMedium", $$v)
                  },
                  expression: "communication.CommunicationMedium",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mx-2",
                staticStyle: { width: "34px", "text-align": "center" },
                attrs: { icon: "people-arrows", color: "primary", size: "2x" },
              }),
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mr-2",
                staticStyle: { "min-width": "90px" },
                attrs: {
                  label: "Communication Direction",
                  itemValueProperty: "Code",
                  items: _vm.getCommunicationDirections(),
                  itemTextProperty: "Name",
                  bg: "",
                },
                model: {
                  value: _vm.communication.CommunicationDirection,
                  callback: function ($$v) {
                    _vm.$set(_vm.communication, "CommunicationDirection", $$v)
                  },
                  expression: "communication.CommunicationDirection",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2",
              staticStyle: { width: "34px", "text-align": "center" },
              attrs: { icon: "user", color: "primary", size: "2x" },
            }),
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mr-2",
              staticStyle: { "min-width": "90px" },
              attrs: {
                label: "Consultant Name",
                itemValueProperty: "Code",
                items: _vm.users,
                itemTextProperty: "Name",
                bg: "",
              },
              model: {
                value: _vm.communication.ConsultantUserReference,
                callback: function ($$v) {
                  _vm.$set(_vm.communication, "ConsultantUserReference", $$v)
                },
                expression: "communication.ConsultantUserReference",
              },
            }),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
              [
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    counter: "",
                    counterMaxValue: 256,
                    maxlength: "256",
                    label: "Communication Subject",
                    bg: "",
                  },
                  model: {
                    value: _vm.communication.Subject,
                    callback: function ($$v) {
                      _vm.$set(_vm.communication, "Subject", $$v)
                    },
                    expression: "communication.Subject",
                  },
                }),
              ],
              1
            ),
            _vm.communication.CommunicationWith == "CW_C"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c("awgt-std-dropdown", {
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "min-width": "90px" },
                      attrs: {
                        search: "",
                        label: "Related Supplier",
                        itemValueProperty: "Code",
                        items: _vm.suppliers,
                        itemTextProperty: "Name",
                        bg: "",
                      },
                      model: {
                        value: _vm.communication.RelatedSupplierReference,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communication,
                            "RelatedSupplierReference",
                            $$v
                          )
                        },
                        expression: "communication.RelatedSupplierReference",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.communication.CommunicationWith == "CW_S"
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
              [
                _c("awgt-std-dropdown", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "90px" },
                  attrs: {
                    search: "",
                    label: "Related Client",
                    itemValueProperty: "Code",
                    items: _vm.clients,
                    itemTextProperty: "Name",
                    bg: "",
                  },
                  model: {
                    value: _vm.communication.RelatedClientReference,
                    callback: function ($$v) {
                      _vm.$set(_vm.communication, "RelatedClientReference", $$v)
                    },
                    expression: "communication.RelatedClientReference",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                  [
                    _c("awgt-std-dropdown", {
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { "min-width": "90px" },
                      attrs: {
                        disabled:
                          this.communication.RelatedClientReference != null &&
                          this.communication.RelatedClientReference.length > 0
                            ? false
                            : true,
                        label:
                          this.communication.RelatedClientReference != null &&
                          this.communication.RelatedClientReference.length > 0
                            ? "Related Booking"
                            : "Related Booking (Disabled)",
                        itemValueProperty: "Code",
                        items: _vm.getBookings,
                        itemTextProperty: "Name",
                        bg: "",
                      },
                      on: { focus: _vm.onGetBookingsDropdown },
                      model: {
                        value: _vm.communication.RelatedBookingReference,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communication,
                            "RelatedBookingReference",
                            $$v
                          )
                        },
                        expression: "communication.RelatedBookingReference",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center my-2",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mb-1",
              staticStyle: { width: "34px" },
              attrs: {
                icon: "clipboard",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("div", [_vm._v("Communication Notes")]),
          ],
          1
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          model: {
            value: _vm.communication.Notes,
            callback: function ($$v) {
              _vm.$set(_vm.communication, "Notes", $$v)
            },
            expression: "communication.Notes",
          },
        }),
        _c("files-upload-without-categories", {
          ref: "fileUpload",
          on: {
            "on-files-input": _vm.onFilesInput,
            "files-input-error": _vm.onFilesInputError,
            "header-message": _vm.onFileDownloadMessage,
          },
          model: {
            value: _vm.communication.CommunicationFiles,
            callback: function ($$v) {
              _vm.$set(_vm.communication, "CommunicationFiles", $$v)
            },
            expression: "communication.CommunicationFiles",
          },
        }),
        _c(
          "div",
          { staticClass: "float-right mt-2" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                staticStyle: {
                  width: "120px",
                  "padding-left": "0",
                  "padding-right": "0",
                },
                attrs: { type: "submit", disabled: !_vm.isFormDirty() },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                staticStyle: {
                  width: "120px",
                  "padding-left": "0",
                  "padding-right": "0",
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onCancel()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Close "),
              ],
              1
            ),
          ],
          1
        ),
        _c("ok-prompt", { ref: "alertPrompt" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }