<template>
  <div>
    <div class="mx-2" style="font-weight: bold">User Permission:</div>
    <div class="mx-2 d-flex flex-row flex-nowrap align-items-center">
      <div style="width: 150px">
        User Permission <br />
        (If this user is able to edit other user's permission):
      </div>
      <template v-for="(permission, index) in userPermissionEditGroup">
        <awgt-std-dropdown
          v-bind:key="index"
          id="user-permission-control"
          label="User permission edit"
          style="max-width: 250px"
          class="flex-grow-1 mx-2"
          itemValueProperty="PermissionScopeCode"
          :items="permission.PermisionScopes"
          itemTextProperty="Name"
          v-model="userPermissionEditValue[index]"
          bg
        >
        </awgt-std-dropdown>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Client:<tdoxTooltip detail="Set Client Edit permissions" />
      </div>
      <template v-for="(permission, index) in clientPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <!-- <asoft-select
            v-bind:key="index"
            :id="permission.PermissionName"
            color="primary"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="clientPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </asoft-select> -->
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="clientPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          ></awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == clientPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Communication:<tdoxTooltip
          detail="Set Communication Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in communicationPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="communicationPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode == communicationPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Country Region:<tdoxTooltip
          detail="Set Country Region Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in countryRegionPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="countryRegionPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              ).substr(
                permission.PermissionName.substr(
                  permission.PermissionName.indexOf(" ") + 1
                ).indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode == countryRegionPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Impersonation:<tdoxTooltip
          detail="Set Impersonation Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in impersonationPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="impersonationPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode == impersonationPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Issue:<tdoxTooltip detail="Set Issue Edit permissions" />
      </div>
      <template v-for="(permission, index) in issuePermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="issuePermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == issuePermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Itinerary:<tdoxTooltip detail="Set Itinerary Edit permissions" />
      </div>
      <template v-for="(permission, index) in itineraryPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="itineraryPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode == itineraryPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Product:<tdoxTooltip detail="Set PProduct Edit permissions" />
      </div>
      <template v-for="(permission, index) in productPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="productPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == productPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Product Proposal:<tdoxTooltip
          detail="Set Product Proposal Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in productProposalPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="productProposalPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              ).substr(
                permission.PermissionName.substr(
                  permission.PermissionName.indexOf(" ") + 1
                ).indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode ==
                  productProposalPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Report View:<tdoxTooltip detail="Set Report View Edit permissions" />
      </div>
      <template v-for="(permission, index) in reportViewPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="reportViewPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return (
                  ps.PermissionScopeCode == reportViewPermissionValue[index]
                );
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Supplier:<tdoxTooltip detail="Set Supplier Edit permissions" />
      </div>
      <template v-for="(permission, index) in supplierPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="supplierPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == supplierPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        System Action View:<tdoxTooltip
          detail="Set Systemm Action View Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in systemActionViewPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="systemActionViewPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == supplierPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Travel Booking:<tdoxTooltip
          detail="Set Travel Booking Edit permissions"
        />
      </div>
      <template v-for="(permission, index) in travelBookingPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="travelBookingPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              ).substr(
                permission.PermissionName.substr(
                  permission.PermissionName.indexOf(" ") + 1
                ).indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == supplierPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        User:<tdoxTooltip detail="Set User Edit permissions" />
      </div>
      <template v-for="(permission, index) in userPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="userPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == supplierPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
    <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div style="width: 200px">
        Workgroup:<tdoxTooltip detail="Set Workgroup Edit permissions" />
      </div>
      <template v-for="(permission, index) in workgroupPermissionGroup">
        <template v-if="checkPermission('CP_UsU')">
          <awgt-std-dropdown
            v-bind:key="index"
            :id="permission.PermissionName"
            :label="permission.PermissionName"
            style="max-width: 250px"
            class="flex-grow-1 mx-2"
            itemValueProperty="PermissionScopeCode"
            :items="permission.PermisionScopes"
            itemTextProperty="Name"
            v-model="workgroupPermissionValue[index]"
            bg
            :tooltip="PERMISSION_SCOPE"
          >
          </awgt-std-dropdown>
        </template>
        <template v-else>
          <div
            v-bind:key="index"
            style="max-width: 250px"
            class="flex-grow-1 mx-2 my-2"
          >
            {{
              permission.PermissionName.substr(
                permission.PermissionName.indexOf(" ") + 1
              )
            }}:
            {{
              permission.PermisionScopes.find((ps) => {
                return ps.PermissionScopeCode == supplierPermissionValue[index];
              }).Name
            }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//import asoftSelect from "@/components/AtomSoftware/asoftSelect.vue";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown.vue";

const PERMISSION_SCOPE =
  "<ul><li>Not Allowed: This permission is denied.</li><li>Allowed - Full Scope: There is no restriction to this permission.</li><li>Broad Workgroup Scope: For self, their workgroup siblings and subordinates. This permission covers all users at the same level if they are in the same workgroup and their subordinates.</li><li>Allowed - non-administration scope: This permission is allowed except administration users and administration settings are excluded (from view or modification).</li><li>Narrow Workgroup Scope: For self and their workgroup subordinates. This permission covers the current user and their subordinates in the same workgroup.</li></ul>";

export default {
  data() {
    return {
      userPermissionEditGroup: [],
      userPermissionEditValue: [],
      clientPermissionGroup: [],
      clientPermissionValue: [],
      communicationPermissionGroup: [],
      communicationPermissionValue: [],
      countryRegionPermissionGroup: [],
      countryRegionPermissionValue: [],
      impersonationPermissionGroup: [],
      impersonationPermissionValue: [],
      issuePermissionGroup: [],
      issuePermissionValue: [],
      itineraryPermissionGroup: [],
      itineraryPermissionValue: [],
      productPermissionGroup: [],
      productPermissionValue: [],
      productProposalPermissionGroup: [],
      productProposalPermissionValue: [],
      reportViewPermissionGroup: [],
      reportViewPermissionValue: [],
      supplierPermissionGroup: [],
      supplierPermissionValue: [],
      systemActionViewPermissionGroup: [],
      systemActionViewPermissionValue: [],
      travelBookingPermissionGroup: [],
      travelBookingPermissionValue: [],
      userPermissionGroup: [],
      userPermissionValue: [],
      workgroupPermissionGroup: [],
      workgroupPermissionValue: [],
      currentUserPermissions: [],
      permissionValueWatcher: [],
      PERMISSION_SCOPE,
    };
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    permissions: {
      type: Array,
      required: true,
    },
  },

  components: {
    //asoftSelect,
    tdoxTooltip,
    AwgtStdDropdown,
  },

  computed: {
    ...mapGetters(["checkPermission"]),
  },

  methods: {
    onResetDefaultUserPermission(val) {
      this.currentUserPermissions = val;
      this.populatePermissionDropdown();
    },

    handleUserPermission() {
      let resultOfPermissionGroup = this.clientPermissionGroup.concat(
        this.userPermissionEditGroup,
        this.clientPermissionGroup,
        this.communicationPermissionGroup,
        this.countryRegionPermissionGroup,
        this.impersonationPermissionGroup,
        this.issuePermissionGroup,
        this.itineraryPermissionGroup,
        this.productPermissionGroup,
        this.productProposalPermissionGroup,
        this.reportViewPermissionGroup,
        this.supplierPermissionGroup,
        this.travelBookingPermissionGroup,
        this.userPermissionGroup,
        this.workgroupPermissionGroup
      );

      let resultOfPermissionValue = this.clientPermissionValue.concat(
        this.userPermissionEditValue,
        this.clientPermissionValue,
        this.communicationPermissionValue,
        this.countryRegionPermissionValue,
        this.impersonationPermissionValue,
        this.issuePermissionValue,
        this.itineraryPermissionValue,
        this.productPermissionValue,
        this.productProposalPermissionValue,
        this.reportViewPermissionValue,
        this.supplierPermissionValue,
        this.travelBookingPermissionValue,
        this.userPermissionValue,
        this.workgroupPermissionValue
      );

      for (let i = 0; i < resultOfPermissionGroup.length; i++) {
        let idx = this.currentUserPermissions.findIndex((pg) => {
          return pg.Permission == resultOfPermissionGroup[i].PermissionCode;
        });

        if (idx == -1)
          this.currentUserPermissions.push({
            Permission: resultOfPermissionGroup[i].PermissionCode,
            PermissionScope: resultOfPermissionValue[i],
          });
        else {
          if (resultOfPermissionValue[i] != "") {
            this.currentUserPermissions[idx].PermissionScope =
              resultOfPermissionValue[i];
          } else this.currentUserPermissions[idx].PermissionScope = "";
        }
      }

      this.currentUserPermissions = this.currentUserPermissions.filter((up) => {
        return up.PermissionScope != "";
      });

      this.$emit("input", this.currentUserPermissions);
    },

    populatePermissionDropdown() {
      this.onRemoveWatcherForPermissionDropDown();

      this.populatePermission(
        this.userPermissionEditGroup,
        this.userPermissionEditValue
      );

      this.populatePermission(
        this.clientPermissionGroup,
        this.clientPermissionValue
      );

      this.populatePermission(
        this.communicationPermissionGroup,
        this.communicationPermissionValue
      );

      this.populatePermission(
        this.countryRegionPermissionGroup,
        this.countryRegionPermissionValue
      );

      this.populatePermission(
        this.impersonationPermissionGroup,
        this.impersonationPermissionValue
      );

      this.populatePermission(
        this.issuePermissionGroup,
        this.issuePermissionValue
      );

      this.populatePermission(
        this.itineraryPermissionGroup,
        this.itineraryPermissionValue
      );

      this.populatePermission(
        this.productPermissionGroup,
        this.productPermissionValue
      );

      this.populatePermission(
        this.productProposalPermissionGroup,
        this.productProposalPermissionValue
      );

      this.populatePermission(
        this.reportViewPermissionGroup,
        this.reportViewPermissionValue
      );

      this.populatePermission(
        this.supplierPermissionGroup,
        this.supplierPermissionValue
      );

      this.populatePermission(
        this.travelBookingPermissionGroup,
        this.travelBookingPermissionValue
      );

      this.populatePermission(
        this.userPermissionGroup,
        this.userPermissionValue
      );

      this.populatePermission(
        this.workgroupPermissionGroup,
        this.workgroupPermissionValue
      );

      this.onCreateWatcherForPermissionDropDown();
    },

    populatePermission(groupArray, valueArray) {
      for (let i = 0; i < groupArray.length; i++) {
        let temp = this.currentUserPermissions.find((up) => {
          return up.Permission == groupArray[i].PermissionCode;
        })?.PermissionScope;

        if (temp) this.$set(valueArray, i, temp);
        else this.$set(valueArray, i, "");
      }
    },

    onRemoveWatcherForPermissionDropDown() {
      if (this.permissionValueWatcher.length > 0) {
        this.permissionValueWatcher.forEach((unwatch) => {
          unwatch();
        });
      }
    },

    onCreateWatcherForPermissionDropDown() {
      this.permissionValueWatcher.push(
        this.$watch(
          "userPermissionEditValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "clientPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "communicationPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "countryRegionPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "impersonationPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "issuePermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "itineraryPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "productPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "productProposalPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "reportViewPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "supplierPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "travelBookingPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "userPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );

      this.permissionValueWatcher.push(
        this.$watch(
          "workgroupPermissionValue",
          function () {
            this.handleUserPermission();
          },
          { deep: true }
        )
      );
    },
  },

  watch: {
    permissions(to, from) {
      if (this.$lodash.isEqual(to, from) == false) {
        for (let i = 0; i < to.length; i++) {
          if (to[i].PermissionCode.includes("UP"))
            this.userPermissionEditGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("CI"))
            this.clientPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Co"))
            this.communicationPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("CR"))
            this.countryRegionPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Im"))
            this.impersonationPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Is"))
            this.issuePermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("It"))
            this.itineraryPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Pr"))
            this.productPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("PP"))
            this.productProposalPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Re"))
            this.reportViewPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Su"))
            this.supplierPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("TB"))
            this.travelBookingPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Us"))
            this.userPermissionGroup.push(to[i]);
          else if (to[i].PermissionCode.includes("Wg"))
            this.workgroupPermissionGroup.push(to[i]);
        }

        this.populatePermissionDropdown();
      }
    },

    value(to, from) {
      if (this.$lodash.isEqual(to, from) == false) {
        this.currentUserPermissions = to;
      }
    },
  },
};
</script>
