import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { sharedMethods } from "@/shared/shared";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getItineraryByReferenceAsync(reference) {
    try {
      logger
        .get(loggingSource.UIItinerary)
        .info("getItineraryByReferenceAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger
        .get(loggingSource.UIItinerary)
        .time("getItineraryByReferenceAsync call");

      const response = await api.client
        .get(`itinerary/${reference}`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("getItineraryByReferenceAsync call");
      const itinerary = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "getItineraryByReferenceAsync (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary data
      itinerary.Description = sharedMethods.htmlSanitize(itinerary.Description);
      for (let ie of itinerary.ItineraryEntries) {
        ie.Notes = sharedMethods.htmlSanitize(ie.Notes);
      }

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "getItineraryByReferenceAsync (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "getItineraryByReferenceAsync (after sanitisation) itinerary: %s",
          itinerary
        );

      return itinerary;
    } catch (error) {
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("getItineraryByReferenceAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItinerary)
        .info("...getItineraryByReferenceAsync");
    }
  },

  async getItineraryQuotedCommercialProductLinesAsync(reference) {
    try {
      logger
        .get(loggingSource.UIItinerary)
        .info("getItineraryQuotedCommercialProductLinesAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger
        .get(loggingSource.UIItinerary)
        .time("getItineraryQuotedCommercialProductLinesAsync call");

      const response = await api.client
        .get(`itinerary/${reference}/quotedcommercialproductlines`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("getItineraryQuotedCommercialProductLinesAsync call");
      const quotedCommercialProductLines = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "getItineraryQuotedCommercialProductLinesAsync response: %s",
          response
        );

      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "getItineraryQuotedCommercialProductLinesAsync Quoted Commercial Product Lines: %s",
          quotedCommercialProductLines
        );

      return quotedCommercialProductLines;
    } catch (error) {
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("getItineraryQuotedCommercialProductLinesAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItinerary)
        .info("...getItineraryQuotedCommercialProductLinesAsync");
    }
  },

  async listItinerariesByBookingReferenceAsync(reference, includeTemplates) {
    try {
      logger
        .get(loggingSource.UIItinerary)
        .info("listItinerariesByBookingReferenceAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "Parameters => reference: %s, includeTemplates %s",
          reference,
          includeTemplates
        );

      logger
        .get(loggingSource.UIItinerary)
        .time("listItinerariesByBookingReferenceAsync call");
      const response = await api.client
        .get(
          `booking/${reference}/itinerary/list?IncludeTemplates=${includeTemplates}`
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("listItinerariesByBookingReferenceAsync call");
      const itineraries = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace("listItinerariesByBookingReferenceAsync response: %s", response);
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "listItinerariesByBookingReferenceAsync itinerary: %s",
          itineraries
        );

      return itineraries;
    } catch (error) {
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("listItinerariesByBookingReferenceAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItinerary)
        .info("...listItinerariesByBookingReferenceAsync");
    }
  },

  async postItineraryAsync(itineraryPayload) {
    try {
      logger.get(loggingSource.UIItinerary).info("postItineraryAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => itineraryPayload: %s", itineraryPayload);

      logger.get(loggingSource.UIItinerary).time("postItineraryAsync call");
      const response = await api.client
        .post("itinerary", itineraryPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIItinerary).timeEnd("postItineraryAsync call");
      const itinerary = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "postItineraryAsync (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary data
      itinerary.Description = sharedMethods.htmlSanitize(itinerary.Description);
      for (let ie of itinerary.ItineraryEntries) {
        ie.Notes = sharedMethods.htmlSanitize(ie.Notes);
      }

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "postItineraryAsync (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "postItineraryAsync (after sanitisation) itinerary: %s",
          itinerary
        );

      return itinerary;
    } catch (error) {
      logger.get(loggingSource.UIItinerary).timeEnd("postItineraryAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItinerary).info("...postItineraryAsync");
    }
  },

  async putItineraryAsync(itineraryPayload) {
    try {
      logger.get(loggingSource.UIItinerary).info("putItineraryAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => itineraryPayload: %s", itineraryPayload);

      logger.get(loggingSource.UIItinerary).time("putItineraryAsync call");
      const response = await api.client
        .put("itinerary", itineraryPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIItinerary).timeEnd("putItineraryAsync call");
      const itinerary = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "putItineraryAsync (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary data
      itinerary.Description = sharedMethods.htmlSanitize(itinerary.Description);
      for (let ie of itinerary.ItineraryEntries) {
        ie.Notes = sharedMethods.htmlSanitize(ie.Notes);
      }

      logger
        .get(loggingSource.UIItinerary)
        .trace("putItineraryAsync (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "putItineraryAsync (after sanitisation) itinerary: %s",
          itinerary
        );

      return itinerary;
    } catch (error) {
      logger.get(loggingSource.UIItinerary).timeEnd("putItineraryAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItinerary).info("...putItineraryAsync");
    }
  },

  async deleteItineraryAsync(itineraryPayload) {
    try {
      logger.get(loggingSource.UIItinerary).info("deleteItineraryAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => itineraryPayload: %s", itineraryPayload);

      logger.get(loggingSource.UIItinerary).time("deleteItineraryAsync call");
      await api.client
        .delete(
          `itinerary/${
            itineraryPayload.Reference
          }?concurrencyRV=${encodeURIComponent(itineraryPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("deleteItineraryAsync call");
    } catch (error) {
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("deleteItineraryAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItinerary).info("...deleteItineraryAsync");
    }
  },

  async generatePDFAsync(reference) {
    try {
      logger.get(loggingSource.UIItinerary).info("generatePDFAsync...");

      logger.get(loggingSource.UIItinerary).time("generatePDFAsync call");
      const response = await api.client
        .get(`pdf/itinerary/${reference}`, { responseType: "blob" })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIItinerary).timeEnd("generatePDFAsync call");
      return response.data;
    } catch (error) {
      logger.get(loggingSource.UIItinerary).timeEnd("generatePDFAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItinerary).info("...generatePDFAsync");
    }
  },

  async duplicateItineraryAsync(reference) {
    try {
      logger.get(loggingSource.UIItinerary).info("duplicateItineraryAsync...");
      logger
        .get(loggingSource.UIItinerary)
        .debug("Parameters => reference: %s", reference);

      logger
        .get(loggingSource.UIItinerary)
        .time("duplicateItineraryAsync call");
      const response = await api.client
        .post(`itinerary/duplicate/${reference}`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("duplicateItineraryAsync call");
      const itinerary = response.data;

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "duplicateItineraryAsync (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary data
      itinerary.Description = sharedMethods.htmlSanitize(itinerary.Description);
      for (let ie of itinerary.ItineraryEntries) {
        ie.Notes = sharedMethods.htmlSanitize(ie.Notes);
      }

      logger
        .get(loggingSource.UIItinerary)
        .trace(
          "duplicateItineraryAsync (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIItinerary)
        .debug(
          "duplicateItineraryAsync (after sanitisation) itinerary: %s",
          itinerary
        );

      return itinerary;
    } catch (error) {
      logger
        .get(loggingSource.UIItinerary)
        .timeEnd("duplicateItineraryAsync call");
      logger.get(loggingSource.UIItinerary).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItinerary).info("...duplicateItineraryAsync");
    }
  },
};
