var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-Container", { attrs: { fluid: "" } }, [
    _vm.pageMode == "EditOrder"
      ? _c(
          "div",
          { staticClass: "mx-3" },
          [
            _c("h1", { staticClass: "text-center mb-3" }, [
              _vm._v("Order Edit"),
            ]),
            _c("div", { staticClass: "mx-2" }),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 mt-2 align-items-center",
              },
              [
                _c("div", [_vm._v("Booking:")]),
                _c("div", { staticClass: "flex-grow-1 ml-2" }, [
                  _vm._v(_vm._s(_vm.order.BookingReference)),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-wrap flex-grow-1 mr-2 align-items-center",
              },
              [
                !_vm.order.ResellerSupplierReference ||
                _vm.order.PrimaryOrderProducts?.length === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                      },
                      [
                        _c("div", [_vm._v("Reseller:")]),
                        _c("awgt-std-dropdown", {
                          staticClass: "flex-grow-1 ml-2",
                          attrs: {
                            itemValueProperty: "Code",
                            items: _vm.supplierDropDown,
                            itemTextProperty: "Name",
                            label: "Reseller",
                            bg: "",
                            search: "",
                          },
                          on: {
                            "get-value": (value) => {
                              _vm.setResellerSupplierName(value)
                            },
                          },
                          model: {
                            value: _vm.order.ResellerSupplierReference,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.order,
                                "ResellerSupplierReference",
                                $$v
                              )
                            },
                            expression: "order.ResellerSupplierReference",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                      },
                      [
                        _c("div", [_vm._v("Reseller:")]),
                        _c("div", { staticClass: "mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.supplierDropDown.find(
                                  (s) =>
                                    s.Code ===
                                    _vm.order.ResellerSupplierReference
                                )?.Name
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                  },
                  [
                    _c("div", [_vm._v("Order Status:")]),
                    _c("awgt-std-dropdown", {
                      staticClass: "flex-grow-1 ml-2",
                      attrs: {
                        itemValueProperty: "Code",
                        items: _vm.getOrderStatuses(),
                        itemTextProperty: "Name",
                        label: "Order Status",
                        bg: "",
                      },
                      model: {
                        value: _vm.order.OrderStatusCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.order, "OrderStatusCd", $$v)
                        },
                        expression: "order.OrderStatusCd",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("h5", [_vm._v("Order Products")]),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mr-2 ml-auto",
                    attrs: {
                      type: "button",
                      disabled: !_vm.order.ResellerSupplierReference,
                    },
                    on: {
                      click: () => {
                        if (_vm.order.ResellerSupplierReference)
                          _vm.showProductSearch = true
                      },
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "plus" },
                    }),
                    _vm._v("Add "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.order.PrimaryOrderProducts?.length == 0
              ? _c("div", { staticClass: "text-center" }, [
                  _c("i", [
                    _vm._v('Press "Add" to add a product to this order'),
                  ]),
                ])
              : _vm._l(
                  _vm.order.PrimaryOrderProducts,
                  function (orderProduct, index) {
                    return _c(
                      "mdb-card",
                      { key: index, staticClass: "mb-3" },
                      [
                        _c("mdb-card-body", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                            },
                            [
                              _c("mdb-icon", {
                                staticClass: "mr-2",
                                staticStyle: { width: "36px" },
                                attrs: {
                                  icon: _vm.getProductIcon(
                                    orderProduct.ProductTypeCd,
                                    orderProduct.ProductSubTypeCd
                                  ),
                                  size: "2x",
                                  color: "primary",
                                },
                              }),
                              _vm._v(" " + _vm._s(orderProduct.Name) + " "),
                              _c(
                                "div",
                                { staticClass: "ml-auto" },
                                [
                                  _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button mr-2",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderProductDelete(
                                            orderProduct.OrderProductIdx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "trash-alt" },
                                      }),
                                      _vm._v("Delete "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOrderProductEdit(
                                            orderProduct.OrderProductIdx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "edit" },
                                      }),
                                      _vm._v("Edit "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              " Priced For " +
                                _vm._s(
                                  _vm.formatDate(orderProduct.PricedForDt)
                                ) +
                                " "
                            ),
                            _c("div", [
                              _c("table", { staticClass: "pricing-table" }, [
                                _c("tr", { staticClass: "pricing-table" }, [
                                  _c("td", { staticClass: "pricing-table" }, [
                                    _vm._v("Ex-GST Price"),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pricing-table" },
                                    [
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatPricingString(
                                                orderProduct?.Price?.adjExGST
                                              )
                                            ),
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                                _c("tr", { staticClass: "pricing-table" }, [
                                  _c("td", { staticClass: "pricing-table" }, [
                                    _vm._v("GST Price"),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pricing-table" },
                                    [
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatPricingString(
                                                orderProduct?.Price?.adjGST
                                              )
                                            ),
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                                _c("tr", { staticClass: "pricing-table" }, [
                                  _c("td", { staticClass: "pricing-table" }, [
                                    _vm._v("Total Price"),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pricing-table" },
                                    [
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatPricingString(
                                                orderProduct?.Price?.adjTotal
                                              )
                                            ),
                                          },
                                        }),
                                        orderProduct?.Price?.comment.length > 0
                                          ? _c("tdoxTooltip", {
                                              attrs: {
                                                detail:
                                                  orderProduct?.Price?.comment,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("br"),
                            _c("h5", [_vm._v("Order Product Lines")]),
                            _c(
                              "ul",
                              _vm._l(
                                orderProduct.OrderProductLines,
                                function (orderProductLine, index2) {
                                  return _c(
                                    "li",
                                    { key: index2 },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(orderProductLine.Name) +
                                          " "
                                      ),
                                      _vm._l(
                                        orderProductLine.OrderProductLineQuantities,
                                        function (
                                          orderProductLineQuantity,
                                          index3
                                        ) {
                                          return _c("span", { key: index3 }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  orderProductLineQuantity.Quantity
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.getQuantityUnit(
                                                    orderProductLineQuantity.QuantityUnitCd,
                                                    orderProductLineQuantity.Quantity,
                                                    orderProductLineQuantity.SingularItemAlias,
                                                    orderProductLineQuantity.PluralItemAlias
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.formatPricingString(
                                                    orderProductLine?.Price
                                                      ?.adjTotal
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }
                ),
            _c("h5", [_vm._v("Order Pricing")]),
            _c("div", [
              _c("table", { staticClass: "pricing-table" }, [
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("Ex-GST Price"),
                  ]),
                  _c("td", { staticClass: "pricing-table" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPricingString(_vm.order?.Price?.exGST)
                        ),
                      },
                    }),
                  ]),
                ]),
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("GST Price"),
                  ]),
                  _c("td", { staticClass: "pricing-table" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPricingString(_vm.order?.Price?.gst)
                        ),
                      },
                    }),
                  ]),
                ]),
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("Total Price"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "pricing-table" },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPricingString(_vm.order?.Price?.total)
                          ),
                        },
                      }),
                      _vm.order?.Price?.comment.length > 0
                        ? _c("tdoxTooltip", {
                            attrs: { detail: _vm.order?.Price?.comment },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mt-3 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mb-2",
                  staticStyle: { width: "34px", "text-align": "center" },
                  attrs: {
                    icon: "clipboard",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("div", { staticClass: "ml-2" }, [_vm._v("Comment")]),
              ],
              1
            ),
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2 input-field",
              attrs: {
                id: "orderComment",
                name: "OrderComment",
                bg: "",
                type: "textarea",
                label: "Comment",
              },
              model: {
                value: _vm.order.Comment,
                callback: function ($$v) {
                  _vm.$set(_vm.order, "Comment", $$v)
                },
                expression: "order.Comment",
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticClass: "ml-2",
                  staticStyle: { width: "34px" },
                  attrs: {
                    icon: "calendar-alt",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("asoft-date-picker", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "140px" },
                  attrs: {
                    label: "Deposit required by",
                    autoHide: "",
                    displayDateFormat: "D MMM YYYY",
                    dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                    setTime: "00:00:00.000",
                    bg: "",
                    width: 300,
                  },
                  model: {
                    value: _vm.order.DepositRequiredByDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "DepositRequiredByDt", $$v)
                    },
                    expression: "order.DepositRequiredByDt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("h5", [_vm._v("Price Adjustment")]),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button ml-auto mb-2",
                    attrs: { type: "button" },
                    on: {
                      click: () =>
                        (_vm.showOrderOrProductLinePriceAdjusted = true),
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mx-1",
                      attrs: { icon: "plus" },
                    }),
                    _vm._v("Adjust price "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.order.PriceAdjustReason?.length > 0
              ? _c("table", { staticClass: "pricing-table" }, [
                  _c("tr", { staticClass: "pricing-table" }, [
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v("Adjustment Type"),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v("Change Type"),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v("Reason"),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v("Amount"),
                    ]),
                  ]),
                  _c("tr", { staticClass: "pricing-table" }, [
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getPriceAdjustmentTypeNameByCode(
                              _vm.order.PriceAdjustmentTypeCd
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getOrderPriceChangeTypeNameByCode(
                              _vm.order.OrderPriceChangeTypeCd
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm._v(_vm._s(_vm.order.PriceAdjustReason)),
                    ]),
                    _c("td", { staticClass: "pricing-table" }, [
                      _vm.order.OrderPriceChangeTypeCd == "OPCT_P"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.order.AdjustAmt) + "%"),
                          ])
                        : _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formatPricingString(_vm.order.AdjustAmt)
                              ),
                            },
                          }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("br"),
            [
              _c("h5", [_vm._v("Total Price:")]),
              _c("table", { staticClass: "pricing-table" }, [
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("Ex-GST Price"),
                  ]),
                  _c("td", { staticClass: "pricing-table" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPricingString(_vm.orderExGSTAdjustedAmt())
                        ),
                      },
                    }),
                  ]),
                ]),
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("GST Price"),
                  ]),
                  _c("td", { staticClass: "pricing-table" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPricingString(_vm.orderGSTAdjustedAmt())
                        ),
                      },
                    }),
                  ]),
                ]),
                _c("tr", { staticClass: "pricing-table" }, [
                  _c("td", { staticClass: "pricing-table" }, [
                    _vm._v("Total Price"),
                  ]),
                  _c("td", { staticClass: "pricing-table" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPricingString(_vm.orderAdjustedAmt())
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ],
            _c("div", {
              staticClass: "section-break",
              staticStyle: { "margin-bottom": "0.8rem" },
            }),
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    staticStyle: {
                      width: "120px",
                      "padding-left": "0",
                      "padding-right": "0",
                    },
                    attrs: { type: "button", disabled: !_vm.isFormDirty() },
                    on: { click: _vm.onSave },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "check" },
                    }),
                    _vm._v("Save "),
                  ],
                  1
                ),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    staticStyle: {
                      width: "120px",
                      "padding-left": "0",
                      "padding-right": "0",
                    },
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "times" },
                    }),
                    _vm._v("Close "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.showOrderOrProductLinePriceAdjusted
              ? _c(
                  "mdb-modal",
                  {
                    attrs: {
                      size: "lg",
                      show: _vm.showOrderOrProductLinePriceAdjusted,
                    },
                  },
                  [
                    _vm.showOrderOrProductLinePriceAdjusted
                      ? _c("order-price-adjustment-dialog", {
                          attrs: {
                            adjustingEntity: _vm.order,
                            title: "Order Price Adjustment",
                          },
                          on: {
                            save: (updatedOrder) =>
                              _vm.onOrderPriceAdjustmentSave(updatedOrder),
                            close: () =>
                              (_vm.showOrderOrProductLinePriceAdjusted = false),
                            delete: () => _vm.orOrderPriceAdjustmentDelete(),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("yes-or-no-prompt", { ref: "handleOrderEditForm" }),
            _vm.showProductSearch
              ? _c(
                  "mdb-modal",
                  { attrs: { size: "lg", show: _vm.showProductSearch } },
                  [
                    _c("add-order-product-dialog", {
                      attrs: {
                        resellerSupplierName: _vm.order.ResellerSupplierName,
                        visible: _vm.showProductSearch,
                      },
                      on: {
                        productSelected:
                          _vm.onProductSelectedForOrderProductAdd,
                        close: () => (_vm.showProductSearch = false),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm.pageMode == "EditOrderProduct"
      ? _c(
          "div",
          [
            _c("edit-order-product", {
              attrs: {
                orderProductIdx: _vm.orderProductIdx,
                booking: _vm.booking,
              },
              on: { close: _vm.onEditOrderProductClose },
            }),
          ],
          1
        )
      : _c("div", [_vm._v("Unknown pageMode")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }