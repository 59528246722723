<template>
  <mdb-container>
    <div>
      <div>
        Select an agreed price adjustment or press "Other":
        <div
          v-for="(priceAdjustment, index) in priceAdjustments"
          :key="index"
          class="d-flex flex-row flex-wrap min-width:120px;"
        >
          <awgt-input
            :id="'BookingAdjustmentAppliesTo' + index"
            type="radio"
            v-model="priceAdjustmentIndex"
            name="BookingAdjustmentAppliesTo"
            :label="priceAdjustment.Name"
            :radioValue="index"
            class="mx-2"
          />
          {{
            getPriceAdjustmentTypeNameByCode(
              priceAdjustment.PriceAdjustmentTypeCd
            )
          }}
          {{ getPriceChangeTypeNameByCode(priceAdjustment.PriceChangeTypeCd) }}
          <mdb-icon
            color="primary"
            :icon="
              priceAdjustment.PriceChangeTypeCd == 'PCT_Pe'
                ? 'percentage'
                : 'dollar-sign'
            "
            size="1x"
            class="mr-1"
          />
          {{ priceAdjustment.Amount }}
        </div>
        <div class="d-flex flex-row flex-wrap min-width:120px;">
          <awgt-input
            id="BookingAdjustmentAppliesTo"
            type="radio"
            v-model="priceAdjustmentIndex"
            name="BookingAdjustmentAppliesTo"
            label="Other"
            :radioValue="-1"
            class="mx-2"
          />
        </div>
      </div>
      <div
        v-if="priceAdjustmentIndex == -1"
        class="d-flex flex-row flex-nowrap flex-grow-1"
      >
        <awgt-input
          v-model="priceAdjustmentResult.Name"
          maxlength="50"
          counter
          :counterMaxValue="50"
          label="Name"
          bg
          class="mr-2 flex-grow-1"
          type="text"
        />
      </div>
      <awgt-input
        v-model="priceAdjustmentResult.Comment"
        maxlength="50"
        counter
        :counterMaxValue="50"
        label="Comment"
        bg
        class="mr-2 flex-grow-1"
        type="text"
      />
      <div
        v-if="priceAdjustmentIndex == -1"
        class="d-flex flex-row flex-nowrap flex-grow-1"
      >
        <awgt-std-dropdown
          :items="getPriceAdjustmentTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Adjustment Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="priceAdjustmentResult.PriceAdjustmentTypeCd"
          bg
        ></awgt-std-dropdown>
        <awgt-std-dropdown
          :items="getRevisedPriceChangeTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Change Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="priceAdjustmentResult.PriceChangeTypeCd"
          bg
        ></awgt-std-dropdown>
        <mdb-icon
          :icon="
            priceAdjustmentResult.PriceChangeTypeCd == 'PCT_Pe'
              ? 'percentage'
              : 'dollar-sign'
          "
          color="primary"
          class="grey-text ml-2"
          style="width: 20px"
          size="2x"
        />
        <awgt-input
          v-model.number="priceAdjustmentResult.Amount"
          maxlength="9"
          label="Amount"
          bg
          class="ml-2"
          type="text"
          style="width: 100px"
          v-numeric="{
            precision: 8,
            scale: 2,
            posOnly: true,
            trailingZeroScale: 2,
            allowEmptyString: false,
          }"
        />
      </div>
    </div>
    <div class="float-right my-2">
      <awgt-std-button
        type="button"
        class="command-button mr-2 ml-auto"
        @click="onOK(priceAdjustmentResult)"
      >
        <mdb-icon icon="check" class="mr-1" />Ok
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mr-2 ml-auto"
        @click="onCancel"
      >
        <mdb-icon icon="times" class="mr-1" />Cancel
      </awgt-std-button>
    </div>
  </mdb-container>
</template>
<script>
import { mdbIcon, mdbContainer } from "mdbvue";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mapGetters } from "vuex";

export default {
  components: {
    awgtInput,
    mdbIcon,
    mdbContainer,
    AwgtStdButton,
    AwgtStdDropdown,
  },

  data() {
    return {
      priceAdjustmentIndex: -1,
      priceAdjustmentResult: {
        Name: "",
        PriceChangeTypeCd: null,
        PriceAdjustmentTypeCd: null,
        Amount: 0,
      },
    };
  },

  directives: {
    numeric: numericDirective,
  },

  props: {
    //Pass in an array of price adjustments
    priceAdjustments: {
      Type: Array,
    },

    keepDollarOnItemInd: {
      Type: Boolean,
    },
  },

  computed: {
    ...mapGetters([
      "getPriceChangeTypes",
      "getPriceAdjustmentTypes",
      "getPriceChangeTypeNameByCode",
      "getPriceAdjustmentTypeNameByCode",
    ]),

    //If the adjustment specifies a new price then only the Dollar On Total Change Type
    //applies, so remove the others.
    //If the Keep Dollar On Item is false then remove Dollar on Item as it doesn't apply.
    //This will only be set to true if a product line with an item quantity unit is being
    //adjusted.
    getRevisedPriceChangeTypes() {
      return (addEmptyEntry) => {
        let priceChangeTypes = this.getPriceChangeTypes(addEmptyEntry);
        if (this.priceAdjustmentResult.PriceAdjustmentTypeCd == "PAT_P") {
          let index = priceChangeTypes.findIndex((pct) => pct.Code == "PCT_Pe"); //Percentage
          if (index !== -1) priceChangeTypes.splice(index, 1);
          index = priceChangeTypes.findIndex((pct) => pct.Code == "PCT_DI"); //Dollar Per Item
          if (index !== -1) priceChangeTypes.splice(index, 1);
        }
        if (this.keepDollarOnItemInd == false) {
          let index = priceChangeTypes.findIndex((pct) => pct.Code == "PCT_DI"); //Dollar Per Item
          if (index !== -1) priceChangeTypes.splice(index, 1);
        }
        return priceChangeTypes;
      };
    },
  },

  methods: {
    onOK(priceAdjustmentResult) {
      this.$emit("addPriceAdjustment", priceAdjustmentResult);
      this.$emit("close");
    },

    onCancel() {
      this.$emit("close");
    },
  },

  watch: {
    priceAdjustmentIndex: {
      deep: false,
      immediate: true,
      handler(to, from) {
        if (to != from) {
          if (to != null) {
            if (to == -1) {
              //Other was pressed
              this.priceAdjustmentResult = {
                Name: "",
                PriceChangeTypeCd: null,
                PriceAdjustmentTypeCd: null,
                Amount: 0,
              };
            } else {
              this.priceAdjustmentResult = {
                Name: this.priceAdjustments[to].Name,
                PriceChangeTypeCd: this.priceAdjustments[to].PriceChangeTypeCd,
                PriceAdjustmentTypeCd:
                  this.priceAdjustments[to].PriceAdjustmentTypeCd,
                Amount: this.priceAdjustments[to].Amount,
              };
            }
          }
        }
      },
    },
  },
};
</script>
