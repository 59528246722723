<template>
  <div>
    <awgt-input
      id="booking-reference"
      v-model="additionalDetails.BookingReference"
      counter
      :counterMaxValue="20"
      maxlength="20"
      style="width: 280px"
      label="Booking Reference"
      bg
    ></awgt-input>

    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-input
        id="pick-address"
        v-model="additionalDetails.PickupAddress"
        counter
        :counterMaxValue="70"
        maxlength="70"
        style="min-width: 300px"
        label="Pick up address"
        bg
        class="flex-grow-1 mr-2"
      ></awgt-input>
      <awgt-std-button
        type="button"
        class="command-button mr-2 ml-auto"
        @click="onClickUseClientAddress"
      >
        <mdb-icon icon="copy" class="mr-1" />Use client's address
      </awgt-std-button>
    </div>

    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <h5>Coach Charter Drivers</h5>
      <awgt-std-button
        type="button"
        class="command-button mx-2 ml-auto"
        @click="onAddCoachCharterDriver()"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>

    <template
      v-if="
        additionalDetails.CoachCharterDrivers &&
        additionalDetails.CoachCharterDrivers.length > 0
      "
    >
      <div
        v-for="(driver, dIndex) in additionalDetails.CoachCharterDrivers"
        :key="dIndex"
      >
        <div v-if="driver.IsEdit == true">
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <awgt-input
              v-model="driver.DriverName"
              maxlength="50"
              label="Driver Name"
              bg
              type="text"
              required
              class="flex-grow-1 mr-2"
            />
            <awgt-input
              v-model="driver.DriverPhoneNumber"
              maxlength="15"
              label="Driver Phone Number"
              bg
              type="text"
              required
              class="flex-grow-1 mr-2"
            />
          </div>
          <awgt-std-button
            type="button"
            class="command-button mr-2"
            @click="onSaveCoachCharterDriver(dIndex)"
          >
            <mdb-icon icon="edit" class="mr-1" />Save
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button ml-2"
            @click="onCancelCoachCharterDriver(dIndex)"
          >
            <mdb-icon icon="times" class="mr-1" />Cancel
          </awgt-std-button>
        </div>
        <div v-else class="mb-2">
          {{ driver.DriverName }} -
          {{ driver.DriverPhoneNumber }}

          <awgt-std-button
            type="button"
            class="command-button mr-2"
            @click="onEditCoachCharterDriver(dIndex)"
          >
            <mdb-icon icon="edit" class="mr-1" />Edit
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mr-2"
            @click="onDeleteCoachCharterDriver(dIndex)"
          >
            <mdb-icon icon="trash-alt" class="mr-1" />Delete
          </awgt-std-button>
        </div>
      </div>
    </template>
    <div v-else class="text-center">
      <i>Press "Add" to add a coach driver</i>
    </div>
  </div>
</template>
<style>
.form .md-form {
  margin-bottom: 2rem !important;
}
</style>
}
<script>
import { mdbIcon } from "mdbvue";
import awgtInput from "@/components/AWGT/AwgtInput";
import bookingApi from "@/api/bookingApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additionalDetails: {
        BookingReference: "",
        PickupAddress: "",
        CoachCharterDrivers: [],
      },
      emptyCoachCharterDriver: {
        DriverName: "",
        DriverPhoneNumber: "",
      },
    };
  },
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
  },
  watch: {
    additionalDetails: {
      deep: true,
      handler() {
        this.$emit("input", this.additionalDetails);
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(to, from) {
        if (to != from) {
          if (to && from)
            if (
              to.BookingReference == from.BookingReference &&
              to.PickupAddress == from.PickupAddress &&
              to.CoachCharterDrivers == from.CoachCharterDrivers
            )
              return;
          //Determine the keys of incoming object differs from this.additionalDetails
          this.additionalDetails = to;
        }
      },
    },
  },
  methods: {
    onAddCoachCharterDriver() {
      let driver = this.$lodash.cloneDeep(this.emptyCoachCharterDriver);

      this.$set(driver, "IsEdit", true);
      this.$set(driver, "IsSaved", false);
      this.additionalDetails.CoachCharterDrivers.push(driver);
    },

    onSaveCoachCharterDriver(index) {
      this.$set(
        this.additionalDetails.CoachCharterDrivers[index],
        "IsEdit",
        false
      );
      this.$set(
        this.additionalDetails.CoachCharterDrivers[index],
        "IsSaved",
        true
      );
    },

    onCancelCoachCharterDriver(index) {
      if (this.additionalDetails.CoachCharterDrivers[index].IsSaved)
        this.$set(
          this.additionalDetails.CoachCharterDrivers[index],
          "IsEdit",
          false
        );
      else this.additionalDetails.CoachCharterDrivers.splice(index, 1);
    },

    onDeleteCoachCharterDriver(index) {
      this.additionalDetails.CoachCharterDrivers.splice(index, 1);
    },

    onEditCoachCharterDriver(index) {
      this.$set(
        this.additionalDetails.CoachCharterDrivers[index],
        "IsEdit",
        true
      );
    },

    async onClickUseClientAddress() {
      let routeParams = this.$route.params;
      this.$log.info("RouteParams:", routeParams);
      let bookingReference = routeParams.bookingReference;

      let booking = await bookingApi.getBookingByReferenceAsync(
        bookingReference
      );
      let address = booking.Client.Addresses.find((a) => {
        return a.Name == "Physical Address";
      });

      this.$set(
        this.additionalDetails,
        "PickupAddress",
        (address.Street.length > 0 ? address.Street + ", " : "") +
          (address.Locality.length > 0 ? address.Locality + ", " : "") +
          (address.Subdivision != null ? address.Subdivision + ", " : "") +
          (address.Postcode.length > 0 ? address.Postcode + ", " : "") +
          (address.Country.length > 0 ? address.Country : "")
      );
    },
  },
};
</script>
