var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    {
      staticStyle: { "padding-left": "2px", "padding-right": "2px" },
      attrs: { fluid: "" },
    },
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("Communication Search"),
      ]),
      _c("awgt-std-switch", {
        staticClass: "my-2",
        attrs: {
          checked: "",
          offLabel: "",
          onLabel: "Match all searching criteria",
        },
        on: {
          getValue: (value) => {
            _vm.matchAllSearchingCriteriaInd = value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "240px" },
            attrs: {
              search: "",
              label: "Client",
              itemValueProperty: "Code",
              items: _vm.clients,
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.clientReference,
              callback: function ($$v) {
                _vm.clientReference = $$v
              },
              expression: "clientReference",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "240px" },
            attrs: {
              disabled:
                this.clientReference != null && this.clientReference.length > 0
                  ? false
                  : true,
              label:
                this.clientReference != null && this.clientReference.length > 0
                  ? "Booking"
                  : "Booking (Disabled)",
              itemValueProperty: "Code",
              items: _vm.getBookings,
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.bookingReference,
              callback: function ($$v) {
                _vm.bookingReference = $$v
              },
              expression: "bookingReference",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "240px" },
            attrs: {
              search: "",
              label: "Supplier",
              itemValueProperty: "Code",
              items: _vm.suppliers,
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.supplierReference,
              callback: function ($$v) {
                _vm.supplierReference = $$v
              },
              expression: "supplierReference",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              label: "Communication Medium",
              itemValueProperty: "Code",
              items: _vm.getCommunicationMediums(),
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.communicationMedium,
              callback: function ($$v) {
                _vm.communicationMedium = $$v
              },
              expression: "communicationMedium",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              label: "Communication Direction",
              itemValueProperty: "Code",
              items: _vm.getCommunicationDirections(),
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.communicationDirection,
              callback: function ($$v) {
                _vm.communicationDirection = $$v
              },
              expression: "communicationDirection",
            },
          }),
          _c("mdb-icon", {
            staticClass: "mx-2 mt-4",
            attrs: {
              icon: "calendar-alt",
              far: "",
              color: "primary",
              size: "2x",
            },
          }),
          _c("asoft-date-picker", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "140px" },
            attrs: {
              label: "Occurence Date",
              autoHide: "",
              displayDateFormat: "D MMM YYYY",
              dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
              bg: "",
            },
            model: {
              value: _vm.occurenceDate,
              callback: function ($$v) {
                _vm.occurenceDate = $$v
              },
              expression: "occurenceDate",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onClearSearchCriteria },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "eraser" },
                  }),
                  _vm._v("Clear "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onSearchCommunications },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "search" },
                  }),
                  _vm._v("Search "),
                ],
                1
              ),
              _vm.checkPermission("CP_CoV")
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "/communication/add",
                          })
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.communications.length == 0 && _vm.isSearched
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._e(),
      _vm._l(_vm.communications, function (communication, index) {
        return _c(
          "mdb-card",
          { key: index, staticClass: "mb-3 mx-2" },
          [
            _c(
              "mdb-card-header",
              {
                attrs: { tag: "button" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleWholeCommunicationDetailExpansion(index)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "float-right" },
                  [
                    _vm.communicationDetailExpansion[index] == true
                      ? _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-up",
                            size: "2x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-down",
                            size: "2x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
                _c("h4", { staticClass: "mb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        communication.CommunicationWith == "CW_C"
                          ? _vm.getCommunicationClientName(
                              communication.RelatedClientReference
                            )
                          : _vm.getCommunicationSupplierName(
                              communication.RelatedSupplierReference
                            )
                      ) +
                      " - " +
                      _vm._s(communication.Subject) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "expansion-region",
              { attrs: { toggle: _vm.communicationDetailExpansion[index] } },
              [
                _vm.communicationsFullDataSet[index]
                  ? _c("mdb-card-body", [
                      _c("div", [
                        _c(
                          "h5",
                          [
                            _c("mdb-icon", {
                              staticClass: "icon-in-views",
                              attrs: {
                                fas: "",
                                icon: "phone-square-alt",
                                size: "1x",
                                color: "primary",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.communicationsFullDataSet[index]
                                    .CommunicationWith == "CW_C"
                                    ? _vm.getCommunicationClientName(
                                        _vm.communicationsFullDataSet[index]
                                          .RelatedClientReference
                                      )
                                    : _vm.getCommunicationSupplierName(
                                        _vm.communicationsFullDataSet[index]
                                          .RelatedSupplierReference
                                      )
                                ) +
                                " - " +
                                _vm._s(
                                  _vm.communicationsFullDataSet[index].Subject
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "75%" } },
                              [
                                _c("i", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.communicationsFullDataSet[index]
                                          .Reference
                                      ) +
                                      ")"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("mdb-icon", {
                              staticClass: "icon-in-views",
                              attrs: {
                                icon: "tty",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(
                              " Communication Medium: " +
                                _vm._s(
                                  _vm.getCommunicationDirection(
                                    _vm.communicationsFullDataSet[index]
                                      .CommunicationDirection
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("mdb-icon", {
                              staticClass: "icon-in-views",
                              attrs: {
                                icon: "hashtag",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(
                              " Communication Direction: " +
                                _vm._s(
                                  _vm.getCommunicationMedium(
                                    _vm.communicationsFullDataSet[index]
                                      .CommunicationMedium
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("mdb-icon", {
                              staticClass: "icon-in-views",
                              attrs: {
                                icon: "user",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(
                              " Contact Name: " +
                                _vm._s(
                                  _vm.communicationsFullDataSet[index].ContactNm
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("mdb-icon", {
                              staticClass: "icon-in-views",
                              attrs: {
                                icon: "sticky-note",
                                color: "primary",
                                size: "1x",
                              },
                            }),
                            _vm._v(
                              " Subject: " +
                                _vm._s(
                                  _vm.communicationsFullDataSet[index].Subject
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mx-2 mt-1" }, [
                          _c("h5", { staticStyle: { color: "#923f82" } }, [
                            _vm._v("Notes"),
                          ]),
                          _c("span", {
                            staticClass: "html-in-views",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.communicationsFullDataSet[index].Notes
                              ),
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "my-2 float-right" },
                        [
                          _vm.checkPermission("CP_CoU") &&
                          _vm.checkPermissionForUpdate(index)
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path:
                                          "/communication/" +
                                          _vm.communicationsFullDataSet[index]
                                            .Reference +
                                          "/edit",
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "edit" },
                                  }),
                                  _vm._v("Edit "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.checkPermission("CP_CoD") &&
                          _vm.checkPermissionForDelete(index)
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Delete" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDeleteCommunication(index)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "trash-alt" },
                                  }),
                                  _vm._v("Delete "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("yes-or-no-prompt", { ref: "handleCommunicationPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }