import { render, staticRenderFns } from "./additionalDetailsOfFlight.vue?vue&type=template&id=69213534"
import script from "./additionalDetailsOfFlight.vue?vue&type=script&lang=js"
export * from "./additionalDetailsOfFlight.vue?vue&type=script&lang=js"
import style0 from "./additionalDetailsOfFlight.vue?vue&type=style&index=0&id=69213534&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workarea\\Atom Software\\Clients\\Away We Go Tours\\src\\awgt-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69213534')) {
      api.createRecord('69213534', component.options)
    } else {
      api.reload('69213534', component.options)
    }
    module.hot.accept("./additionalDetailsOfFlight.vue?vue&type=template&id=69213534", function () {
      api.rerender('69213534', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/itinerary/additionalDetailsOfFlight.vue"
export default component.exports