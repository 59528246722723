import api from "./api.js";
import ipInfoApi from "./ipInfoApi.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import tokenManager from "@/shared/securityTokenManager.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async requestPasswordReset(username, emailAddress) {
    try {
      logger.get(loggingSource.UISec).info("requestPasswordReset...");

      logger
        .get(loggingSource.UISec)
        .debug(
          "Parameters => username: %s, emailAddress: %s",
          username,
          emailAddress
        );

      logger.get(loggingSource.UISec).time("requestPasswordReset call");
      await api.client
        .get("security/requestpasswordreset", {
          params: {
            username: username,
            emailAddress: emailAddress,
          },
        })
        .catch((error) => {
          throw error;
        });

      logger.get(loggingSource.UISec).timeEnd("requestPasswordReset call");
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("requestPasswordReset call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...requestPasswordReset");
    }
  },

  async resetPassword(token, password) {
    try {
      logger.get(loggingSource.UISec).info("resetPassword...");

      logger
        .get(loggingSource.UISec)
        .debug("Parameters => token: %s, password: %s", token, password); //Only shown when debugging (not in prod).

      logger.get(loggingSource.UISec).time("resetPassword call");

      await api.client
        .post(`security/resetpassword?t=${token}`, null, {
          params: {
            password: password,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISec).timeEnd("resetPassword call");
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("resetPassword call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...resetPassword");
    }
  },

  async requestUsernameRetrieval(emailAddress) {
    try {
      logger.get(loggingSource.UISec).info("requestUsernameRetrieval...");

      logger
        .get(loggingSource.UISec)
        .debug("Parameters => emailAddress: %s", emailAddress);

      logger.get(loggingSource.UISec).time("requestUsernameRetrieval call");
      await api.client
        .get("security/requestusernameretrieval", {
          params: {
            emailAddress: emailAddress,
          },
        })
        .catch((error) => {
          throw error;
        });

      logger.get(loggingSource.UISec).timeEnd("requestUsernameRetrieval call");
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("requestUsernameRetrieval call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...requestUsernameRetrieval");
    }
  },

  async retrieveUsername(token) {
    try {
      logger.get(loggingSource.UISec).info("retrieveUsername...");

      logger.get(loggingSource.UISec).time("retrieveUsername call");
      const response = await api.client
        .get(`security/retrieveUsername?t=${token}`)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISec).timeEnd("retrieveUsername call");

      const username = response.data;
      logger
        .get(loggingSource.UISec)
        .debug("retrieveUsername username: %s", username);

      return username;
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("retrieveUsername call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...retrieveUsername");
    }
  },

  async Login(payload) {
    try {
      logger.get(loggingSource.UISec).info("Login...");

      logger
        .get(loggingSource.UISec)
        .debug("Parameters => Username: %s", payload.Username);

      //Get information about this client.
      let ipInfo = "";
      try {
        ipInfo = await ipInfoApi.getIPInfo();
      } catch {
        logger
          .get(loggingSource.UISec)
          .info("Unknown error occurrect with getIPInfo.");
      }

      logger.get(loggingSource.UISec).time("Login call");
      const response = await api.client
        .post("security/login", { ...payload, ...ipInfo })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISec).timeEnd("Login call");
      const val = response.data;
      await tokenManager.setAccessTokenAsync(val.AccessToken);

      logger.get(loggingSource.UISec).debug("Login user: %s", val.User);

      return val;
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("Login call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...Login");
    }
  },

  async updateToken(payload) {
    try {
      logger.get(loggingSource.UISec).info("updateToken...");

      logger.get(loggingSource.UISec).time("updateToken call");
      const response = await api.client
        .post("security/updateToken", payload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UISec).timeEnd("updateToken call");
      const val = response.data;

      await tokenManager.setAccessTokenAsync(val.AccessToken);

      logger.get(loggingSource.UISec).debug("response: %s", val);

      return val;
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("updateToken call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...updateToken");
    }
  },

  async Logout() {
    try {
      logger.get(loggingSource.UISec).info("Logout...");

      logger.get(loggingSource.UISec).time("Logout call");
      await api.client.delete("security/logout").catch((error) => {
        throw error;
      });
      await tokenManager.setAccessTokenAsync("");

      logger.get(loggingSource.UISec).timeEnd("Logout call");
    } catch (error) {
      logger.get(loggingSource.UISec).timeEnd("Logout call");
      logger.get(loggingSource.UISec).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UISec).info("...Logout");
    }
  },
};
