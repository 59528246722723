<template>
  <div class="form">
    <awgt-input
      id="booking-reference"
      v-model="additionalDetails.BookingReference"
      counter
      :counterMaxValue="20"
      maxlength="20"
      style="min-width: 300px"
      label="Booking Reference"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="arrival-location"
      v-model="additionalDetails.ArrivalLocation"
      counter
      :counterMaxValue="25"
      maxlength="25"
      style="min-width: 300px"
      label="Arrival Location"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="arrival-wharf"
      v-model="additionalDetails.ArrivalWharf"
      counter
      :counterMaxValue="15"
      maxlength="15"
      style="min-width: 300px"
      label="Arrival Wharf"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="departure-location"
      v-model="additionalDetails.DepartureLocation"
      counter
      :counterMaxValue="25"
      maxlength="25"
      style="min-width: 300px"
      label="Departure Location"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
    <awgt-input
      id="departure-airport-name"
      v-model="additionalDetails.DepartureWharf"
      counter
      :counterMaxValue="15"
      maxlength="15"
      style="min-width: 300px"
      label="Departure Wharf"
      bg
      class="flex-grow-1 mr-2"
    ></awgt-input>
  </div>
</template>
<style>
.form .md-form {
  margin-bottom: 2rem !important;
}
</style>
}
<script>
import awgtInput from "@/components/AWGT/AwgtInput";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additionalDetails: {
        BookingReference: "",
        ArrivalLocation: "",
        ArrivalWharf: "",
        DepartureLocation: "",
        DepartureWharf: "",
      },
    };
  },
  components: {
    awgtInput,
  },
  watch: {
    additionalDetails: {
      deep: true,
      handler() {
        this.$emit("input", this.additionalDetails);
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(to, from) {
        if (to != from) {
          if (to && from)
            if (
              to.BookingReference == from.BookingReference &&
              to.ArrivalLocation == from.ArrivalLocation &&
              to.ArrivalWharf == from.ArrivalWharf &&
              to.DepartureLocation == from.DepartureLocation &&
              to.DepartureWharf == from.DepartureWharf
            )
              return;
          //Determine the keys of incoming object differs from this.additionalDetails
          this.additionalDetails = to;
        }
      },
    },
  },
};
</script>
