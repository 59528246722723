<template>
  <mdb-container fluid>
    <h1 class="text-center">Client Search</h1>

    <mdb-form-inline class="flex-nowrap">
      <span class="ml-2 mr-3" style="min-width: 60px">Search Scope</span>
      <awgt-input
        id="searchScope_School"
        type="checkbox"
        v-model="includeSchoolClientsInd"
        name="searchScope"
        label="School"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
      <awgt-input
        id="searchScope_Individuals"
        type="checkbox"
        v-model="includeIndividualClientsInd"
        name="searchScope"
        label="Individuals"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
      <awgt-input
        id="searchScope_Organisations"
        type="checkbox"
        v-model="includeOrganisationClientsInd"
        name="searchScope"
        label="Organisations"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
    </mdb-form-inline>
    <awgt-std-switch
      checked
      offLabel
      class="mt-3"
      onLabel="Match all searching criteria"
      @getValue="
        (value) => {
          matchAllSearchingCriteriaInd = value;
        }
      "
    ></awgt-std-switch>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchReference"
        counter
        :counterMaxValue="100"
        label="Reference"
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
        bg
      ></awgt-input>
      <awgt-input
        v-model="searchPhone"
        counter
        :counterMaxValue="100"
        style="min-width: 300px"
        label="Phone"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchName"
        counter
        :counterMaxValue="100"
        style="min-width: 300px"
        label="Name"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-input
        v-model="searchEmail"
        counter
        :counterMaxValue="254"
        style="min-width: 300px"
        label="Email"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <awgt-std-switch
        id="switch-includeDeletedClientsInd"
        offLabel
        onLabel="Include deleted clients"
        v-model="includeDeletedClientsInd"
      ></awgt-std-switch>
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearSearchCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSearch"
        >
          <mdb-icon icon="search" class="mr-1" id="search-client" />Search
        </awgt-std-button>
        <awgt-std-button
          v-if="checkPermission('CP_CIC')"
          type="button"
          class="command-button mx-2"
          @click="onAdd"
        >
          <mdb-icon icon="plus" class="mr-1" id="add-client" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div v-if="clients.length == 0 && isSearched" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-for="(client, index) in clients"
      :key="index"
      class="mb-3 mx-2"
      :style="client.IsActive ? 'opacity:1' : 'opacity:0.5'"
    >
      <awgt-expansion-header
        @click.native="onToggleClientDetailExpansion(index)"
        tag="button"
        :expanded="clientDetailExpansion[index]"
        :user="client"
      >
        <div
          class="d-flex flex-row flex-nowrap align-items-center"
          style="text-align: left"
          v-if="client.ClientType == 'CT_I'"
          :id="client.GivenNames + client.Surname"
        >
          <mdb-icon
            icon="user"
            color="primary"
            class="pr-3"
            size="2x"
            style="width: 55px; text-align: center"
          />
          <div>
            <h4 class="mb-0">{{ client.GivenNames }} {{ client.Surname }}</h4>
            {{ addressHeader(client) }}
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-row flex-nowrap align-items-center"
          style="text-align: left"
        >
          <mdb-icon
            :icon="client.ClientType == 'CT_O' ? 'building' : 'school'"
            color="primary"
            class="pr-3"
            size="2x"
            style="width: 55px; text-align: center"
          />
          <div>
            <h4 class="mb-0">{{ client.Name }}</h4>
            {{ addressHeader(client) }}
          </div>
        </div>
      </awgt-expansion-header>
      <awgt-expansion-body :toggle="clientDetailExpansion[index]">
        <template>
          <client-view :value="client"></client-view>
        </template>
        <template #footer>
          <div class="my-2 float-right" v-if="client.IsActive == true">
            <awgt-std-button
              type="button"
              title="Communications"
              class="command-button mx-2"
              @click="onCommunications(client)"
            >
              <mdb-icon class="mr-1" icon="comments" />Communications
            </awgt-std-button>
            <awgt-std-button
              type="button"
              title="Travel Bookings"
              class="command-button mx-2"
              @click="onTravelBookings(client)"
            >
              <mdb-icon class="mr-1" icon="luggage-cart" />Travel Bookings
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_CIU')"
              type="button"
              title="Edit"
              class="command-button mx-2"
              @click="onEdit(client)"
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_CID')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              @click="onDelete(client)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
          <div class="my-2 float-right" v-show="client.IsActive == false">
            <awgt-std-button
              v-if="checkPermission('CP_CID')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              @click="onUndelete(client)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Undelete
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_CID')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              @click="onPermanentlyDelete(client)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Permanently delete
            </awgt-std-button>
          </div>
        </template>
      </awgt-expansion-body>
    </mdb-card>
    <yes-or-no-prompt ref="handleClientPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbFormInline,
  mdbCard,
  //mdbCardBody,
  //mdbCardHeader,
} from "mdbvue";

//import ExpansionRegion from "@/components/ExpansionRegion";
import clientApi from "@/api/clientApi.js";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import clientView from "@/components/ClientView";
import awgtInput from "@/components/AWGT/AwgtInput";
import { sharedMethods } from "@/shared/shared";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";
import AwgtExpansionHeader from "@/components/AWGT/AwgtExpansionHeader.vue";
import AwgtExpansionBody from "@/components/AWGT/AwgtExpansionBody.vue";

export default {
  components: {
    mdbContainer,
    mdbIcon,

    mdbFormInline,
    AwgtStdSwitch,
    mdbCard,
    //mdbCardBody,
    //mdbCardHeader,
    //ExpansionRegion,
    yesOrNoPrompt,
    awgtInput,
    clientView,
    AwgtStdButton,
    AwgtExpansionHeader,
    AwgtExpansionBody,
  },

  data() {
    return {
      includeIndividualClientsInd: false,
      includeOrganisationClientsInd: false,
      includeSchoolClientsInd: true,
      includeDeletedClientsInd: false,
      matchAllSearchingCriteriaInd: true,
      clients: [],
      clientDetailExpansion: [],
      searchReference: "",
      searchPhone: "",
      searchName: "",
      searchEmail: "",
      promptHeader: "",
      promptMessage: "",
      showPrompt: false,
      isSearched: false,
    };
  },

  computed: {
    ...mapGetters(["checkPermission"]),
  },

  methods: {
    addressHeader(client) {
      let address = client.Addresses.find((item) => {
        return item.Type == "AT_Ph";
      });
      return (
        address.Locality +
        (address.Subdivision != null && address.Locality.length > 0
          ? ", "
          : "") +
        (address.Subdivision != null ? address.Subdivision : "")
      );
    },
    /*
    Vue.set is required because v-if doesn't work if you use an
    array element in the v-if.
    See: https://stackoverflow.com/questions/41580617/vuejs-v-if-arrayindex-is-not-working
    */

    onToggleClientDetailExpansion(index) {
      this.$set(
        this.clientDetailExpansion,
        index,
        !this.clientDetailExpansion[index]
      );
    },

    onSearch() {
      this.searchClients();
    },

    onAdd() {
      this.$router.push({
        path: "/client/add",
      });
    },

    onEdit(client) {
      this.$router.push({
        path: `/client/${client.Reference}/edit`,
      });
    },

    onDelete(client) {
      client.IsActive = false;
      this.saveClient(client);
    },

    onUndelete(client) {
      this.$refs.handleClientPrompt
        .onHandlePrompt("Warning", "Are you sure to recover the client?")
        .then((option) => {
          if (option == true) {
            client.IsActive = true;
            this.saveClient(client);
          }
        });
    },

    onPermanentlyDelete(client) {
      this.$refs.handleClientPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure to permanently delete the client?"
        )
        .then((option) => {
          if (option == true) {
            this.deleteClient(client);
          }
        });
    },

    onCommunications(client) {
      this.$router.push({
        path: `/client/${client.Reference}/communications`,
      });
    },

    onTravelBookings(client) {
      this.$router.push({
        path: `/client/${client.Reference}/travelbookings`,
      });
    },

    onClearSearchCriteria() {
      this.searchReference = "";
      this.searchPhone = "";
      this.searchName = "";
      this.searchEmail = "";
      this.clients.splice(0);
    },

    async searchClients() {
      var ClientTypes = "";

      if (this.includeIndividualClientsInd == true) ClientTypes += ",CT_I";
      if (this.includeOrganisationClientsInd == true) ClientTypes += ",CT_O";
      if (this.includeSchoolClientsInd == true) ClientTypes += ",CT_S";
      if (ClientTypes.length > 0) ClientTypes = ClientTypes.substring(1);

      this.clients = await clientApi.getClients(
        ClientTypes,
        this.searchReference,
        this.searchPhone,
        this.searchName,
        this.searchEmail,
        this.matchAllSearchingCriteriaInd,
        this.includeDeletedClientsInd
      );
      this.isSearched = true;
    },

    async saveClient(client) {
      let clientIdx = this.clients.findIndex(
        (c) => c.Reference == client.Reference
      );
      let val = await clientApi.putClient(client);
      this.$set(this.clients, clientIdx, val);
    },

    async deleteClient(client) {
      try {
        await clientApi.deleteClient(client);
        this.clients.splice(
          this.clients.findIndex((c) => c.Reference == client.Reference),
          1
        );
      } catch (err) {
        this.$log.error(err);
      }
    },

    async getClient(reference) {
      let successInd = true;
      let errorMessage = "";

      this.clients = [];

      await clientApi.getClientByReference(reference, true).then(
        (client) => {
          this.clients.push(client);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });

      this.isSearched = true;
    },
  },

  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.onSearch);
    this.$bus.$off("search-bar-search");
    this.$bus.$on("search-bar-search", (searchString) => {
      this.getClient(searchString);
    });
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>
