var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Client Search")]),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "span",
            { staticClass: "ml-2 mr-3", staticStyle: { "min-width": "60px" } },
            [_vm._v("Search Scope")]
          ),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_School",
              type: "checkbox",
              name: "searchScope",
              label: "School",
            },
            model: {
              value: _vm.includeSchoolClientsInd,
              callback: function ($$v) {
                _vm.includeSchoolClientsInd = $$v
              },
              expression: "includeSchoolClientsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Individuals",
              type: "checkbox",
              name: "searchScope",
              label: "Individuals",
            },
            model: {
              value: _vm.includeIndividualClientsInd,
              callback: function ($$v) {
                _vm.includeIndividualClientsInd = $$v
              },
              expression: "includeIndividualClientsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Organisations",
              type: "checkbox",
              name: "searchScope",
              label: "Organisations",
            },
            model: {
              value: _vm.includeOrganisationClientsInd,
              callback: function ($$v) {
                _vm.includeOrganisationClientsInd = $$v
              },
              expression: "includeOrganisationClientsInd",
            },
          }),
        ],
        1
      ),
      _c("awgt-std-switch", {
        staticClass: "mt-3",
        attrs: {
          checked: "",
          offLabel: "",
          onLabel: "Match all searching criteria",
        },
        on: {
          getValue: (value) => {
            _vm.matchAllSearchingCriteriaInd = value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Reference",
              bg: "",
            },
            model: {
              value: _vm.searchReference,
              callback: function ($$v) {
                _vm.searchReference = $$v
              },
              expression: "searchReference",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Phone",
              bg: "",
            },
            model: {
              value: _vm.searchPhone,
              callback: function ($$v) {
                _vm.searchPhone = $$v
              },
              expression: "searchPhone",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: { counter: "", counterMaxValue: 100, label: "Name", bg: "" },
            model: {
              value: _vm.searchName,
              callback: function ($$v) {
                _vm.searchName = $$v
              },
              expression: "searchName",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 254,
              label: "Email",
              bg: "",
            },
            model: {
              value: _vm.searchEmail,
              callback: function ($$v) {
                _vm.searchEmail = $$v
              },
              expression: "searchEmail",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c("awgt-std-switch", {
            attrs: {
              id: "switch-includeDeletedClientsInd",
              offLabel: "",
              onLabel: "Include deleted clients",
            },
            model: {
              value: _vm.includeDeletedClientsInd,
              callback: function ($$v) {
                _vm.includeDeletedClientsInd = $$v
              },
              expression: "includeDeletedClientsInd",
            },
          }),
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onClearSearchCriteria },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "eraser" },
                  }),
                  _vm._v("Clear "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onSearch },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "search", id: "search-client" },
                  }),
                  _vm._v("Search "),
                ],
                1
              ),
              _vm.checkPermission("CP_CIC")
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button" },
                      on: { click: _vm.onAdd },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus", id: "add-client" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.clients.length == 0 && _vm.isSearched
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._e(),
      _vm._l(_vm.clients, function (client, index) {
        return _c(
          "mdb-card",
          {
            key: index,
            staticClass: "mb-3 mx-2",
            style: client.IsActive ? "opacity:1" : "opacity:0.5",
          },
          [
            _c(
              "awgt-expansion-header",
              {
                attrs: {
                  tag: "button",
                  expanded: _vm.clientDetailExpansion[index],
                  user: client,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onToggleClientDetailExpansion(index)
                  },
                },
              },
              [
                client.ClientType == "CT_I"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap align-items-center",
                        staticStyle: { "text-align": "left" },
                        attrs: { id: client.GivenNames + client.Surname },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "pr-3",
                          staticStyle: {
                            width: "55px",
                            "text-align": "center",
                          },
                          attrs: { icon: "user", color: "primary", size: "2x" },
                        }),
                        _c("div", [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(client.GivenNames) +
                                " " +
                                _vm._s(client.Surname)
                            ),
                          ]),
                          _vm._v(" " + _vm._s(_vm.addressHeader(client)) + " "),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-nowrap align-items-center",
                        staticStyle: { "text-align": "left" },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "pr-3",
                          staticStyle: {
                            width: "55px",
                            "text-align": "center",
                          },
                          attrs: {
                            icon:
                              client.ClientType == "CT_O"
                                ? "building"
                                : "school",
                            color: "primary",
                            size: "2x",
                          },
                        }),
                        _c("div", [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(client.Name)),
                          ]),
                          _vm._v(" " + _vm._s(_vm.addressHeader(client)) + " "),
                        ]),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "awgt-expansion-body",
              {
                attrs: { toggle: _vm.clientDetailExpansion[index] },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          client.IsActive == true
                            ? _c(
                                "div",
                                { staticClass: "my-2 float-right" },
                                [
                                  _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button mx-2",
                                      attrs: {
                                        type: "button",
                                        title: "Communications",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCommunications(client)
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "comments" },
                                      }),
                                      _vm._v("Communications "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button mx-2",
                                      attrs: {
                                        type: "button",
                                        title: "Travel Bookings",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onTravelBookings(client)
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "luggage-cart" },
                                      }),
                                      _vm._v("Travel Bookings "),
                                    ],
                                    1
                                  ),
                                  _vm.checkPermission("CP_CIU")
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "command-button mx-2",
                                          attrs: {
                                            type: "button",
                                            title: "Edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onEdit(client)
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "edit" },
                                          }),
                                          _vm._v("Edit "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkPermission("CP_CID")
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "command-button mx-2",
                                          attrs: {
                                            type: "button",
                                            title: "Delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDelete(client)
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "trash-alt" },
                                          }),
                                          _vm._v("Delete "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: client.IsActive == false,
                                  expression: "client.IsActive == false",
                                },
                              ],
                              staticClass: "my-2 float-right",
                            },
                            [
                              _vm.checkPermission("CP_CID")
                                ? _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button mx-2",
                                      attrs: {
                                        type: "button",
                                        title: "Delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onUndelete(client)
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "trash-alt" },
                                      }),
                                      _vm._v("Undelete "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkPermission("CP_CID")
                                ? _c(
                                    "awgt-std-button",
                                    {
                                      staticClass: "command-button mx-2",
                                      attrs: {
                                        type: "button",
                                        title: "Delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPermanentlyDelete(client)
                                        },
                                      },
                                    },
                                    [
                                      _c("mdb-icon", {
                                        staticClass: "mr-1",
                                        attrs: { icon: "trash-alt" },
                                      }),
                                      _vm._v("Permanently delete "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [[_c("client-view", { attrs: { value: client } })]],
              2
            ),
          ],
          1
        )
      }),
      _c("yes-or-no-prompt", { ref: "handleClientPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }