<template>
  <mdb-container fluid>
    <mdb-form-inline class="flex-nowrap">
      <span class="ml-2 mr-3" style="width: 120px">Search Scope</span>
      <awgt-input
        id="searchScope_Transport"
        type="checkbox"
        v-model="includeTransportProductsInd"
        name="searchScope"
        label="Transport"
        class="mx-2"
        style="min-width: 60px"
        checked
        :disabled="
          includeAccommodationProductsInd == false &&
          includeActivityProductsInd == false &&
          includeMerchandiseProductsInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Accommodation"
        type="checkbox"
        v-model="includeAccommodationProductsInd"
        name="searchScope"
        label="Accommodation"
        class="mx-2"
        style="min-width: 60px"
        checked
        :disabled="
          includeTransportProductsInd == false &&
          includeMerchandiseProductsInd == false &&
          includeActivityProductsInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Activity"
        type="checkbox"
        v-model="includeActivityProductsInd"
        name="searchScope"
        label="Activity"
        class="mx-2"
        style="min-width: 60px"
        checked
        :disabled="
          includeTransportProductsInd == false &&
          includeAccommodationProductsInd == false &&
          includeMerchandiseProductsInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Merchandise"
        type="checkbox"
        v-model="includeMerchandiseProductsInd"
        name="searchScope"
        label="Merchandise"
        class="mx-2"
        style="min-width: 60px"
        checked
        :disabled="
          includeTransportProductsInd == false &&
          includeAccommodationProductsInd == false &&
          includeActivityProductsInd == false
        "
      ></awgt-input>
    </mdb-form-inline>
    <mdb-form-inline class="flex-nowrap">
      <span class="ml-2 mr-3" style="width: 120px">Sold With</span>
      <awgt-input
        id="soldWith_CanBeSoldSeparately"
        type="checkbox"
        v-model="includeCanBeSoldSeparatelyProductsInd"
        name="soldWith"
        label="Can be sold separately"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includeCanOnlyBeSoldAsRelatedProductsInd == false"
      ></awgt-input>
      <awgt-input
        id="soldWith_CanOnlyBeSoldAsRelatedProducts"
        type="checkbox"
        v-model="includeCanOnlyBeSoldAsRelatedProductsInd"
        name="soldWith"
        label="Can only be sold as related products"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includeCanBeSoldSeparatelyProductsInd == false"
      ></awgt-input>
    </mdb-form-inline>
    <mdb-form-inline class="flex-nowrap" v-if="checkPermission('CP_PrC')">
      <span class="ml-2 mr-3" style="width: 120px">Edit Status</span>
      <awgt-input
        id="editStatus_Draft"
        type="checkbox"
        v-model="includeDraftProductssInd"
        name="editStatus"
        label="Draft"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includePublishedProductssInd == false"
      ></awgt-input>
      <awgt-input
        id="editStatus_Published"
        type="checkbox"
        v-model="includePublishedProductssInd"
        name="editStatus"
        label="Published"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includeDraftProductssInd == false"
      ></awgt-input>
    </mdb-form-inline>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <awgt-std-switch
        v-if="isTravelBookingProductSearch == false"
        offLabel
        onLabel="Include deleted products"
        v-model="includeDeletedProductsInd"
      ></awgt-std-switch>
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearSearch"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSearch"
        >
          <mdb-icon icon="search" class="mr-1" />Search
        </awgt-std-button>
        <awgt-std-button
          v-if="
            isTravelBookingProductSearch == false && checkPermission('CP_PrC')
          "
          type="button"
          class="command-button mx-2"
          @click="onAdd"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>

    <awgt-std-switch
      offLabel
      class="mt-3"
      onLabel="Match all search criteria"
      v-model="matchAllSearchCriteriaInd"
    ></awgt-std-switch>

    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchReference"
        counter
        :counterMaxValue="100"
        label="Reference"
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
        bg
      ></awgt-input>
      <awgt-input
        v-model="searchProviderName"
        counter
        :counterMaxValue="100"
        label="Supplier Provider"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-input>
      <awgt-input
        v-model="searchResellerName"
        counter
        :counterMaxValue="100"
        label="Supplier Reseller"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-input>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchProductName"
        counter
        :counterMaxValue="100"
        label="Product Name"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-input>
      <awgt-std-dropdown
        itemValueProperty="CountryRegionId"
        :items="countryRegions"
        itemTextProperty="Name"
        v-model="searchRegion"
        label="Region"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        :items="getAddressSubdivisions(false)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Subdivision"
        class="flex-grow-1 mx-2"
        v-model="searchSubdivision"
        style="min-width: 300px"
        bg
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-std-dropdown
        itemValueProperty="Code"
        :items="getYearGroups"
        itemTextProperty="Name"
        v-model="yearGroups"
        label="Year Groups"
        bg
        :maxSelectedOptions="13"
        multiple
        selectAll
        class="mx-2"
        style="flex: 1"
      ></awgt-std-dropdown>
      <tdox-duration-picker
        v-model.number="searchMinDuration"
        :hourSelection="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        :minuteSelection="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
        label="Minimum Duration"
        class="mx-2"
        style="flex: 1"
      ></tdox-duration-picker>
      <tdox-duration-picker
        v-model.number="searchMaxDuration"
        :hourSelection="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        :minuteSelection="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
        label="Maximum Duration"
        class="mx-2"
        style="flex: 1"
      ></tdox-duration-picker>
    </div>

    <div v-if="products.length == 0 && isSearched" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-for="(product, index) in products"
      :key="index"
      class="mb-3 mx-2"
      :style="product.IsActive ? 'opacity:1' : 'opacity:0.5'"
    >
      <mdb-card-header
        @click.native="toggleProductDetailExpansion(index)"
        tag="button"
      >
        <span class="float-right">
          <mdb-icon
            v-if="productDetailExpansion[index] == true"
            icon="angle-up"
            size="2x"
            class="pt-2"
            color="primary"
          />
          <mdb-icon
            v-else
            icon="angle-down"
            size="2x"
            class="pt-2"
            color="primary"
          />
        </span>
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <!-- TODO: doesn't include a bundle -->
          <mdb-icon
            :icon="getProductIcon(product.ProductType, product.ProductSubType)"
            size="3x"
            class="pr-3"
            color="primary"
            style="width: 75px"
          />
          <div class="d-flex flex-column" style="text-align: left">
            <h4 class="my-0">
              {{ getHeaderText(product) }}
            </h4>
            <template v-if="product.ProductSubType != 'PST_AyFI'">
              <h5
                class="my-0"
                v-if="
                  product.ProviderSupplier.Reference !=
                  product.ResellerSupplier.Reference
                "
              >
                Purchased from {{ product.ResellerSupplier.Name }}
              </h5>
            </template>
            <template v-if="!product.IsActive">
              <span class="alert-text">Deleted</span>
            </template>
          </div>
        </div>
      </mdb-card-header>
      <expansion-region :toggle="productDetailExpansion[index]">
        <mdb-card-body v-if="productDetailExpansion[index]">
          <commercial-product-line-list
            v-if="isTravelBookingProductSearch == true"
            :components="commercialProductLines[0].components"
          />
          <div
            v-else
            class="my-2 float-right"
            v-show="productsFullDataSet[index].IsActive == true"
          >
            <awgt-std-button
              v-if="checkPermission('CP_PrC')"
              type="button"
              title="Dupliacte"
              class="command-button mx-2"
              @click="onDuplicate(productsFullDataSet[index])"
            >
              <mdb-icon class="mr-1" icon="copy" />Duplicate
            </awgt-std-button>
            <awgt-std-button
              v-if="
                productsFullDataSet[index].EditStatus == 'ES_D' &&
                checkPermission('CP_PrU')
              "
              type="button"
              title="Public"
              class="command-button mx-2"
              v-on:click="onPublishProduict(productsFullDataSet[index])"
            >
              <mdb-icon class="mr-1" icon="upload" />Publish
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_PrU')"
              type="button"
              title="Edit"
              class="command-button mx-2"
              @click="onEdit(productsFullDataSet[index])"
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_PrD')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              @click="onDelete(productsFullDataSet[index])"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
          <div
            class="my-2 float-right"
            v-show="productsFullDataSet[index].IsActive == false"
          >
            <awgt-std-button
              v-if="checkPermission('CP_PrD')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              v-on:click="(event) => onUndelete(productsFullDataSet[index])"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Undelete
            </awgt-std-button>
            <awgt-std-button
              v-if="checkPermission('CP_PrD')"
              type="button"
              title="Delete"
              class="command-button mx-2"
              v-on:click="
                (event) => onPermanentlyDelete(productsFullDataSet[index])
              "
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Permanently delete
            </awgt-std-button>
          </div>
          <product-view
            :value="productsFullDataSet[index]"
            :headingColor="awgtProductViewHeadingColour"
          ></product-view>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <div>
      <div ref="infinitescrolltrigger" id="scroll-trigger"></div>
    </div>
    <yes-or-no-prompt ref="handleProductPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbFormInline,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
} from "mdbvue";

import ExpansionRegion from "@/components/ExpansionRegion";
import CommercialProductLineList from "@/components/CommercialProductLineList";
import productApi from "@/api/productApi.js";
import countryRegionApi from "@/api/CountryRegionApi.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import tdoxDurationPicker from "@/components/Tourdox/tdoxDurationPicker";
import ProductView from "@/components/ProductView";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import { sharedMethods } from "@/shared/shared";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";
import variables from "@/styles/sharedVariables.scss";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    AwgtStdSwitch,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    ExpansionRegion,
    CommercialProductLineList,
    ProductView,
    awgtInput,
    yesOrNoPrompt,
    AwgtStdDropdown,
    tdoxDurationPicker,
  },
  data() {
    return {
      includeTransportProductsInd: true,
      includeAccommodationProductsInd: true,
      includeActivityProductsInd: true,
      includeDeletedProductsInd: false,
      includeMerchandiseProductsInd: true,
      includeDraftProductssInd: true,
      includePublishedProductssInd: true,
      includeCanBeSoldSeparatelyProductsInd: true,
      includeCanOnlyBeSoldAsRelatedProductsInd: false,
      products: [],
      productsFullDataSet: [],
      productDetailExpansion: [],
      searchProviderName: "",
      searchResellerName: "",
      searchProductName: "",
      yearGroups: [],
      countryRegions: [],
      searchRegion: 0,
      searchSubdivision: "",
      searchMinDuration: 0,
      searchMaxDuration: 0,
      duplicateProductError: [],
      commercialProductLines: [],
      storedProduct: null,
      searchReference: "",
      isSearched: false,
      matchAllSearchCriteriaInd: true,
      currentPage: 1,
      maxPerPage: 10,
      totalResults: null,
      ProductTypes: null,
      observer: null,
    };
  },

  directives: {
    numeric: numericDirective,
  },

  props: {
    isTravelBookingProductSearch: Boolean,
  },

  computed: {
    ...mapGetters([
      "getAddressSubdivisions",
      "getYearGroups",
      "getEditStatusNameByCode",
      "checkPermission",
    ]),

    pageCount() {
      return this.totalResults == null
        ? 0
        : Math.ceil(this.totalResults / this.maxPerPage);
    },

    awgtProductViewHeadingColour() {
      return variables.awgtProductViewHeadingColour;
    },
  },

  methods: {
    ...sharedMethods,

    /*
    This is required because v-if doesn't work if you use an
    array element in the v-if.
    See: https://stackoverflow.com/questions/41580617/vuejs-v-if-arrayindex-is-not-working
    */

    async getCountryRegions() {
      this.countryRegions = await countryRegionApi.getForDropdownAsync();
    },

    async toggleProductDetailExpansion(index) {
      let product;
      /* If the product detail is not expanded then get a product update
        because the product detail is about to be expanded */
      if (
        (this.productDetailExpansion[index] == undefined ||
          this.productDetailExpansion[index] == false) &&
        this.productsFullDataSet[index] == undefined
      ) {
        product = await productApi.getProductByReference(
          this.products[index].Reference
        );
        this.$set(this.productsFullDataSet, index, product);
      }

      this.$set(
        this.productDetailExpansion,
        index,
        !this.productDetailExpansion[index]
      );
    },

    onClearSearch() {
      this.searchReference = "";
      this.searchProviderName = "";
      this.searchResellerName = "";
      this.searchProductName = "";
      this.yearGroups = [];
      this.searchRegion = "";
      this.searchSubdivision = "";
      this.searchMinDuration = 0;
      this.searchMaxDuration = 0;
      this.includeTransportProductsInd = true;
      this.includeAccommodationProductsInd = true;
      this.includeActivityProductsInd = true;
      this.includeMerchandiseProductsInd = true;
      this.matchAllSearchCriteriaInd = true;
      this.includeCanBeSoldSeparatelyProductsInd = true;
      this.includeCanOnlyBeSoldAsRelatedProductsInd = false;
      this.includeDeletedProductsInd = false;
      this.includeDraftProductssInd = true;
      this.includePublishedProductssInd = true;

      if (this.observer != null) this.observer.disconnect();
      this.products = [];
    },

    onSearch() {
      this.searchProducts().catch(() => {
        // Errors have been logged
        this.products = [];
      });
    },

    onAdd() {
      this.$router.push({
        path: "/product/add",
      });
    },

    async onPublishProduict(product) {
      this.publishProduct(product)
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [
              `The product ${product.Name} has been published successfully.`,
            ],
          });
        })
        .catch(() => {
          // Erros will be logged in the ProductApi.js
        });
    },

    async publishProduct(product) {
      let productIdx = this.products.findIndex(
        (p) => p.Reference == product.Reference
      );
      var returnVal = await productApi.publishProduct(product);

      const headerVal = {
        Reference: returnVal.Reference,
        Name: returnVal.Name,
        IsActive: returnVal.IsActive,
        EditStatus: returnVal.EditStatus,
        ProductType: returnVal.ProductType,
        ProductSubType: returnVal.ProductSubType,
        ProviderSupplier: this.$lodash.cloneDeep(returnVal.ProviderSupplier),
        ResellerSupplier: this.$lodash.cloneDeep(returnVal.ResellerSupplier),
      };
      this.$set(this.productsFullDataSet, productIdx, returnVal);
      this.$set(this.products, productIdx, headerVal);
    },

    async onDuplicate(product) {
      var indexOfProduct = this.products
        .map(function (p) {
          return p.Reference;
        })
        .indexOf(product.Reference);
      let duplicateProduct = this.$lodash.cloneDeep(product);
      let copyIndex = 0;
      let searchName =
        duplicateProduct.Name.indexOf(" (Copy") == -1
          ? duplicateProduct.Name
          : duplicateProduct.Name.substring(
              0,
              duplicateProduct.Name.indexOf(" (Copy")
            );
      for (var productEntity of this.products) {
        if (productEntity.Name.includes(searchName)) {
          copyIndex = copyIndex + 1;
        }
      }
      if (copyIndex === 1) duplicateProduct.Name = searchName + " (Copy)";
      if (copyIndex > 1) {
        duplicateProduct.Name = searchName + ` (Copy ${copyIndex})`;
      }
      this.saveDuplicateProduct(duplicateProduct)
        .then(() => {
          const storedVal = {
            Reference: this.storedProduct.Reference,
            Name: this.storedProduct.Name,
            IsActive: this.storedProduct.IsActive,
            EditStatus: this.storedProduct.EditStatus,
            ProductType: this.storedProduct.ProductType,
            ProductSubType: this.storedProduct.ProductSubType,
            ProviderSupplier: this.$lodash.cloneDeep(
              this.storedProduct.ProviderSupplier
            ),
            ResellerSupplier: this.$lodash.cloneDeep(
              this.storedProduct.ResellerSupplier
            ),
          };
          this.products.splice(indexOfProduct + 1, 0, storedVal);
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [
              `The product ${duplicateProduct.Name} has been duplicated.`,
            ],
          });
          this.storedProduct = null;
        })
        .catch(() => {
          // Errors have been logged
        });
    },

    onEdit(product) {
      this.$router.push({
        path: `/product/${product.Reference}/edit`,
      });
    },

    onDelete(product) {
      product.IsActive = false;
      this.saveProduct(product);
    },

    onUndelete(product) {
      this.$refs.handleProductPrompt
        .onHandlePrompt("Warning", "Are you sure to recover the product?")
        .then((option) => {
          if (option == true) {
            product.IsActive = true;
            this.saveProduct(product);
          }
        });
    },

    onPermanentlyDelete(product) {
      this.$refs.handleProductPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure to permanently delete the product?"
        )
        .then((option) => {
          if (option == true) {
            this.permanentlyDelete(product);
          }
        });
    },

    async saveDuplicateProduct(product) {
      this.storedProduct = await productApi.postProduct(product);
    },

    async searchProducts() {
      var ProductTypes = "";

      if (this.includeTransportProductsInd == true) ProductTypes += ",PT_Tr";
      if (this.includeAccommodationProductsInd == true)
        ProductTypes += ",PT_An";
      if (this.includeActivityProductsInd == true) ProductTypes += ",PT_Ay";
      if (this.includeMerchandiseProductsInd == true) ProductTypes += ",PT_Me";

      if (ProductTypes.length > 0) ProductTypes = ProductTypes.substring(1);

      this.productsFullDataSet = [];
      this.currentPage = 1;
      this.ProductTypes = ProductTypes;
      //Before searching, disconnect all intersection observer.
      if (this.observer != null) this.observer.disconnect();

      let result = await productApi.getProductsList(
        this.currentPage,
        ProductTypes,
        this.searchReference,
        this.searchProviderName,
        this.searchResellerName,
        null,
        null,
        this.searchProductName,
        this.searchRegion,
        this.searchSubdivision,
        this.yearGroups.join(),
        "", //product availability
        this.searchMinDuration,
        this.searchMaxDuration,
        this.matchAllSearchCriteriaInd,
        this.includeCanBeSoldSeparatelyProductsInd,
        this.includeCanOnlyBeSoldAsRelatedProductsInd,
        this.includeDeletedProductsInd,
        this.checkPermission("CP_PrC") ? this.includeDraftProductssInd : false,
        this.includePublishedProductssInd
      );

      this.products = result.Products;
      this.totalResults = result.TotalResults;
      this.isSearched = true;

      this.scrollTrigger();
    },

    async getProduct(reference) {
      let successInd = true;
      let errorMessage = "";

      this.products = [];

      await productApi.getProductByReference(reference, true).then(
        (product) => {
          this.products.push(product);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });
      this.isSearched = true;
    },

    async saveProduct(product) {
      let productIdx = this.products.findIndex(
        (p) => p.Reference == product.Reference
      );
      let val = await productApi.putProduct(product);

      const headerVal = {
        Reference: val.Reference,
        Name: val.Name,
        IsActive: val.IsActive,
        EditStatus: val.EditStatus,
        ProductType: val.ProductType,
        ProductSubType: val.ProductSubType,
        ProviderSupplier: this.$lodash.cloneDeep(val.ProviderSupplier),
        ResellerSupplier: this.$lodash.cloneDeep(val.ResellerSupplier),
      };
      this.$set(this.productsFullDataSet, productIdx, val);
      this.$set(this.products, productIdx, headerVal);
    },

    async permanentlyDelete(product) {
      try {
        await productApi.deleteProduct(product);
        this.productDetailExpansion = [];
        this.productsFullDataSet.splice(
          this.products.findIndex((p) => p.Reference == product.Reference),
          1
        );
        this.products.splice(
          this.products.findIndex((p) => p.Reference == product.Reference),
          1
        );
      } catch (err) {
        // Errors will be logged in PrudctApi.js
      }
    },

    getHeaderText(product) {
      let postText = this.checkPermission("CP_PrC")
        ? ` (${this.getEditStatusNameByCode(product.EditStatus)})`
        : "";
      return product.Name + postText;
    },

    scrollTrigger() {
      /*
      https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      */
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(async (entry) => {
          if (
            entry.intersectionRatio > 0 &&
            this.currentPage < this.pageCount
          ) {
            this.currentPage += 1;

            let result = await productApi.getProductsList(
              this.currentPage,
              this.ProductTypes,
              this.searchReference,
              this.searchProviderName,
              this.searchResellerName,
              null,
              null,
              this.searchProductName,
              this.searchRegion,
              this.searchSubdivision,
              this.yearGroups.join(),
              "", //product availability
              this.searchMinDuration,
              this.searchMaxDuration,
              this.matchAllSearchCriteriaInd,
              this.includeCanBeSoldSeparatelyProductsInd,
              this.includeCanOnlyBeSoldAsRelatedProductsInd,
              this.includeDeletedProductsInd,
              this.includeDraftProductssInd,
              this.includePublishedProductssInd
            );

            this.products = this.products.concat(result.Products);
          }
        });
      });

      this.observer.observe(this.$refs.infinitescrolltrigger);
    },
  },

  mounted() {
    this.getCountryRegions().catch(() => {
      // Error have been logged in the countryRegionApi.js
    });
    sharedMethods.onKeyDownEnterSearch(this.onSearch);
    this.$bus.$off("search-bar-search");
    this.$bus.$on("search-bar-search", (searchString) => {
      this.getProduct(searchString).catch(() => {
        // Suppress errors
      });
    });

    if (Object.keys(this.$route.query).length > 0) {
      this.searchProviderName = this.$route.query.supplier;
      this.searchResellerName = this.$route.query.supplier;
      if (this.searchProviderName || this.searchResellerName) this.onSearch();
    }
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
    if (this.observer) this.observer.disconnect();
  },
};
</script>
<style scoped>
#scroll-trigger {
  height: 10px;
}
</style>
