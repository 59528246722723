<template>
  <mdb-container fluid style="padding-left: 2px; padding-right: 2px">
    <h1 class="text-center">Supplier Search</h1>
    <mdb-form-inline class="flex-nowrap">
      <span class="ml-2 mr-3" style="width: 120px">Search Scope</span>
      <awgt-input
        id="searchScope_Transport"
        type="checkbox"
        v-model="includeTransportSuppliersInd"
        name="searchScope"
        label="Transport"
        class="mx-2"
        style="min-width: 60px"
        :disabled="
          includeAccommodationSuppliersInd == false &&
          includeActivitySuppliersInd == false &&
          includeNoProductSuppliersInd == false &&
          includeMerchandiseSuppliersInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Accommodation"
        type="checkbox"
        v-model="includeAccommodationSuppliersInd"
        name="searchScope"
        label="Accommodation"
        class="mx-2"
        style="min-width: 60px"
        :disabled="
          includeTransportSuppliersInd == false &&
          includeActivitySuppliersInd == false &&
          includeNoProductSuppliersInd == false &&
          includeMerchandiseSuppliersInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Activity"
        type="checkbox"
        v-model="includeActivitySuppliersInd"
        name="searchScope"
        label="Activity"
        class="mx-2"
        style="min-width: 60px"
        :disabled="
          includeTransportSuppliersInd == false &&
          includeAccommodationSuppliersInd == false &&
          includeNoProductSuppliersInd == false &&
          includeMerchandiseSuppliersInd == false &&
          includeNoProductSuppliersInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_Merchandise"
        type="checkbox"
        v-model="includeMerchandiseSuppliersInd"
        name="searchScope"
        label="Merchandise"
        class="mx-2"
        style="min-width: 60px"
        :disabled="
          includeTransportSuppliersInd == false &&
          includeAccommodationSuppliersInd == false &&
          includeActivitySuppliersInd == false &&
          includeNoProductSuppliersInd == false
        "
      ></awgt-input>
      <awgt-input
        id="searchScope_NoProducts"
        type="checkbox"
        v-model="includeNoProductSuppliersInd"
        name="searchScope"
        label="No Products"
        class="mx-2"
        style="min-width: 60px"
        :disabled="
          includeTransportSuppliersInd == false &&
          includeAccommodationSuppliersInd == false &&
          includeActivitySuppliersInd == false &&
          includeMerchandiseSuppliersInd == false
        "
      ></awgt-input>
    </mdb-form-inline>
    <awgt-std-switch
      checked
      offLabel
      class="mt-3"
      onLabel="Match all searching criteria"
      @getValue="
        (value) => {
          matchAllSearchingCriteriaInd = value;
        }
      "
    ></awgt-std-switch>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchReference"
        counter
        :counterMaxValue="100"
        label="Supplier Reference"
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
        bg
      ></awgt-input>
      <awgt-input
        v-model="searchName"
        counter
        :counterMaxValue="100"
        label="Supplier Name"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-input>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        itemValueProperty="CountryRegionId"
        :items="countryregion"
        itemTextProperty="Name"
        v-model="region"
        label="Region"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        :items="getAddressSubdivisions(false)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Subdivision"
        class="flex-grow-1 mx-2"
        v-model="subdivision"
        style="min-width: 300px"
        bg
      ></awgt-std-dropdown>
    </div>
    <mdb-form-inline class="flex-nowrap" v-if="checkViewPermission()">
      <span class="ml-2 mr-3" style="width: 120px">Edit Status</span>
      <awgt-input
        id="editStatus_Draft"
        type="checkbox"
        v-model="includeDraftSuppliersInd"
        name="editStatus"
        label="Draft"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includePublishedSuppliersInd == false"
      ></awgt-input>
      <awgt-input
        id="editStatus_Published"
        type="checkbox"
        v-model="includePublishedSuppliersInd"
        name="editStatus"
        label="Published"
        class="mx-2"
        style="min-width: 60px"
        :disabled="includeDraftSuppliersInd == false"
      ></awgt-input>
    </mdb-form-inline>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <awgt-std-switch
        v-model="includeDeletedSuppliersInd"
        offLabel
        onLabel="Include deleted suppliers"
      ></awgt-std-switch>
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearSearchCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          v-on:click="searchSuppliers"
        >
          <mdb-icon icon="search" class="mr-1" />Search
        </awgt-std-button>
        <awgt-std-button
          v-if="checkPermission('CP_SuC')"
          type="button"
          class="command-button mx-2"
          @click="
            $router.push({
              path: '/supplier/add',
            })
          "
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div v-if="suppliers.length == 0 && isSearched" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-for="(supplier, index) in suppliers"
      :key="index"
      class="mb-3 mx-2"
      :style="supplier.IsActive ? 'opacity:1' : 'opacity:0.5'"
    >
      <mdb-card-header
        @click.native="toggleWholesalerDetailExpansion(index)"
        tag="button"
      >
        <span class="float-right">
          <mdb-icon
            v-if="supplierDetailExpansion[index] == true"
            icon="angle-up"
            size="2x"
            class="pt-2"
            color="primary"
          />
          <mdb-icon
            v-else
            icon="angle-down"
            size="2x"
            class="pt-2"
            color="primary"
          />
        </span>
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <mdb-icon
            icon="dolly"
            size="3x"
            class="pr-3"
            color="primary"
            style="width: 75px"
          />
          <div class="d-flex flex-column" style="text-align: left">
            <h4 class="mb-1">
              {{ getHeaderText(supplier) }}
            </h4>
            {{ supplier.Address }}
            <template v-if="!supplier.IsActive">
              <br />
              <span class="alert-text">Deleted</span>
            </template>
          </div>
        </div>
      </mdb-card-header>
      <expansion-region :toggle="supplierDetailExpansion[index]">
        <mdb-card-body v-if="supplierDetailExpansion[index]">
          <template>
            <div
              class="my-2 float-right"
              v-if="suppliersFullDataSet[index].IsActive == true"
            >
              <awgt-std-button
                type="button"
                title="Products"
                class="command-button mx-2"
                @click="
                  $router.push({
                    path: '/product',
                    query: {
                      supplier: suppliersFullDataSet[index].Name,
                    },
                  })
                "
              >
                <mdb-icon class="mr-1" icon="umbrella-beach" />Products
              </awgt-std-button>
              <awgt-std-button
                v-if="
                  suppliersFullDataSet[index].EditStatus == 'ES_D' &&
                  checkPermission('CP_SuU')
                "
                type="button"
                title="Public"
                class="command-button mx-2"
                v-on:click="onPublishSupplier(suppliersFullDataSet[index])"
              >
                <mdb-icon class="mr-1" icon="upload" />Publish
              </awgt-std-button>
              <awgt-std-button
                v-if="checkPermission('CP_SuU')"
                type="button"
                title="Edit"
                class="command-button mx-2"
                @click="
                  $router.push({
                    path:
                      '/supplier/' +
                      suppliersFullDataSet[index].Reference +
                      '/edit',
                  })
                "
              >
                <mdb-icon class="mr-1" icon="edit" />Edit
              </awgt-std-button>
              <awgt-std-button
                v-if="checkPermission('CP_SuD')"
                type="button"
                title="Delete"
                class="command-button mx-2"
                v-on:click="onDeleteSupplier(suppliersFullDataSet[index])"
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Delete
              </awgt-std-button>
            </div>
            <div
              class="my-2 float-right"
              v-show="suppliersFullDataSet[index].IsActive == false"
            >
              <awgt-std-button
                type="button"
                title="UnDelete"
                class="command-button mx-2"
                v-on:click="
                  (event) => OnUndeleteSupplier(suppliersFullDataSet[index])
                "
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Undelete
              </awgt-std-button>
              <awgt-std-button
                type="button"
                title="Delete"
                class="command-button mx-2"
                v-on:click="
                  (event) =>
                    onPermanentlyDeleteSupplier(suppliersFullDataSet[index])
                "
              >
                <mdb-icon class="mr-1" icon="trash-alt" />Permanently delete
              </awgt-std-button>
            </div>
          </template>
          <supplier-view :value="suppliersFullDataSet[index]"></supplier-view>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <yes-or-no-prompt ref="handleSupplierPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbFormInline,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
} from "mdbvue";

import ExpansionRegion from "@/components/ExpansionRegion";
import supplierApi from "@/api/supplierApi.js";
import countryRegionApi from "@/api/CountryRegionApi.js";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { mapGetters } from "vuex";
import awgtInput from "@/components/AWGT/AwgtInput";
import supplierView from "@/components/SupplierView";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import { sharedMethods } from "@/shared/shared";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    AwgtStdSwitch,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    ExpansionRegion,
    AwgtStdDropdown,
    awgtInput,
    supplierView,
    yesOrNoPrompt,
  },

  data() {
    return {
      includeTransportSuppliersInd: true,
      includeAccommodationSuppliersInd: true,
      includeActivitySuppliersInd: true,
      includeMerchandiseSuppliersInd: true,
      includeNoProductSuppliersInd: true,
      includeDeletedSuppliersInd: false,
      includeDraftSuppliersInd: true,
      includePublishedSuppliersInd: true,
      matchAllSearchingCriteriaInd: true,
      supplierDetailExpansion: [],
      suppliers: [],
      suppliersFullDataSet: [],
      searchName: "",
      region: "",
      subdivision: null,
      searchReference: "",
      isSearched: false,
      countryregion: [],
    };
  },

  computed: {
    ...mapGetters([
      "getEmptySupplier",
      "getAddressSubdivisions",
      "getDepositTypeNameByCode",
      "getEditStatusNameByCode",
      "checkPermission",
    ]),
  },

  methods: {
    /*
    This is required because v-if doesn't work if you use an
    array element in the v-if.
    See: https://stackoverflow.com/questions/41580617/vuejs-v-if-arrayindex-is-not-working
    */

    async getCountryregion() {
      this.countryregion = await countryRegionApi.getForDropdownAsync();
    },

    async toggleWholesalerDetailExpansion(index) {
      let supplier;
      if (
        (this.supplierDetailExpansion[index] == undefined ||
          this.supplierDetailExpansion[index] == false) &&
        this.suppliersFullDataSet[index] == undefined
      ) {
        supplier = await supplierApi.getSupplierByReference(
          this.suppliers[index].Reference
        );
        this.$set(this.suppliersFullDataSet, index, supplier);
      }

      this.$set(
        this.supplierDetailExpansion,
        index,
        !this.supplierDetailExpansion[index]
      );
    },
    checkViewPermission() {
      return this.checkPermission("CP_SuC") || this.checkPermission("CP_SuU");
    },

    getHeaderText(supplier) {
      let postText = this.checkViewPermission()
        ? ` (${this.getEditStatusNameByCode(supplier.EditStatus)})`
        : "";
      return supplier.Name + postText;
    },

    async searchSuppliers() {
      this.suppliersFullDataSet = [];
      this.supplierDetailExpansion = [];
      this.suppliers = await supplierApi.getSupplierList(
        this.searchReference,
        this.searchName,
        this.region,
        this.subdivision,
        this.includeTransportSuppliersInd,
        this.includeAccommodationSuppliersInd,
        this.includeActivitySuppliersInd,
        this.includeMerchandiseSuppliersInd,
        this.includeNoProductSuppliersInd,
        this.checkViewPermission() ? this.includeDraftSuppliersInd : false,
        this.includePublishedSuppliersInd,
        this.matchAllSearchingCriteriaInd,
        this.includeDeletedSuppliersInd
      );

      this.isSearched = true;
    },

    async onPublishSupplier(supplier) {
      this.publishSupplier(supplier).then(() => {
        this.$emit("header-message", {
          isSuccessInd: true,
          Message: [
            `The supplier ${supplier.Name} has been published successfully.`,
          ],
        });
      });
    },

    async publishSupplier(supplier) {
      var index = this.suppliers.findIndex(
        (s) => s.Reference == supplier.Reference
      );
      var returnVal = await supplierApi.publishSupplier(supplier);
      const headerVal = {
        Reference: returnVal.Reference,
        Name: returnVal.Name,
        IsActive: returnVal.IsActive,
        EditStatus: returnVal.EditStatus,
        Addresses: returnVal.Addresses,
      };
      this.$set(this.suppliers, index, headerVal);
      this.$set(this.suppliersFullDataSet, index, returnVal);
    },

    async onDeleteSupplier(supplier) {
      supplier.IsActive = false;
      this.saveSupplier(supplier);
    },

    async OnUndeleteSupplier(supplier) {
      this.$refs.handleSupplierPrompt
        .onHandlePrompt("Warning", "Are you sure to recover the supplier?")
        .then((option) => {
          if (option == true) {
            supplier.IsActive = true;
            this.saveSupplier(supplier);
          }
        });
    },

    onPermanentlyDeleteSupplier(deleteSupplier) {
      this.$refs.handleSupplierPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure to permanently delete the supplier?"
        )
        .then((option) => {
          if (option == true) {
            this.permanentlyDelete(deleteSupplier);
          }
        });
    },

    async saveSupplier(supplier) {
      var index = this.suppliers.findIndex(
        (s) => s.Reference == supplier.Reference
      );
      var returnVal = await supplierApi.putSupplier(supplier);
      const headerVal = {
        Reference: returnVal.Reference,
        Name: returnVal.Name,
        IsActive: returnVal.IsActive,
        EditStatus: returnVal.EditStatus,
        Addresses: returnVal.Addresses,
      };
      this.$set(this.suppliers, index, headerVal);
      this.$set(this.suppliersFullDataSet, index, returnVal);
    },

    async permanentlyDelete(supplier) {
      try {
        await supplierApi.deleteSupplier(supplier);
        this.suppliers.splice(
          this.suppliers.findIndex((s) => s.Reference == supplier.Reference),
          1
        );
        this.suppliersFullDataSet.splice(
          this.suppliers.findIndex((s) => s.Reference == supplier.Reference),
          1
        );
        this.supplierDetailExpansion = [];
      } catch (err) {
        this.$log.info(err);
        throw new Error(err); //TODO: Should we use message prompt here?
      }
    },

    onClearSearchCriteria() {
      this.searchName = "";
      this.region = "";
      this.subdivision = null;
      this.searchReference = "";
      this.suppliers.splice(0);
    },

    async getSupplier(reference) {
      let successInd = true;
      let errorMessage = "";

      this.suppliers = [];

      await supplierApi.getSupplierByReference(reference, true).then(
        (supplier) => {
          this.suppliers.push(supplier);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });

      this.isSearched = true;
    },
  },

  mounted() {
    this.getCountryregion().catch(() => {
      // Error have been logged in the countryRegionApi.js
    });
    sharedMethods.onKeyDownEnterSearch(this.searchSuppliers);
    this.$bus.$off("search-bar-search");
    this.$bus.$on("search-bar-search", (searchString) => {
      this.getSupplier(searchString);
    });
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>
