var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-switch", {
    attrs: {
      onLabel: _vm.onLabel,
      offLabel: _vm.offLabel,
      disabled: _vm.disabled,
      classes: _vm.classes,
      value: _vm.value,
    },
    on: { getValue: _vm.onChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }