<template>
  <div>
    <div class="d-flex flex-row flex-wrap mt-3 mb-2 pdf-button">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="scaleD"
      >
        <mdb-icon icon="plus" class="mr-1" />Zoom In
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="scaleX"
      >
        <mdb-icon icon="minus" class="mr-1" />Zoom Out
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        v-on:click="pdfDownload"
      >
        <mdb-icon class="mr-1" icon="download" far />Download
      </awgt-std-button>
    </div>
    <div
      class="pdfIn"
      v-show="url.length > 0"
      style="width: 100%; height: 700px"
    >
      <div>
        <pdf
          ref="pdf"
          v-for="i in pageTotalNum"
          :key="i"
          :src="url"
          :page="i"
          @error="pdfError($event)"
          style="width: 70%; margin: 0 auto"
        ></pdf>
      </div>
    </div>
  </div>
</template>
<style>
.pdfIn {
  overflow: scroll;
}

.pdf-button {
  justify-content: center;
}
</style>
<script>
import pdf from "vue-pdf";
import { mdbIcon } from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "Home",
  props: {
    url: {
      type: String,
    },
  },
  components: {
    pdf,
    AwgtStdButton,
    mdbIcon,
  },
  data() {
    return {
      pageTotalNum: 1,
      scale: 70,
    };
  },
  watch: {
    url(to) {
      if (to != null) this.getNumPages();
    },
  },
  mounted: function () {
    if (this.url != null || this.url.length > 0) this.getNumPages();
  },
  methods: {
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise.then((pdfResult) => {
        this.pageTotalNum = pdfResult.numPages;
      });
    },
    scaleD() {
      this.scale += 1;
      for (let page of this.$refs.pdf)
        page.$el.style.width = parseInt(this.scale) + "%";
    },
    scaleX() {
      this.scale += -1;
      for (let page of this.$refs.pdf)
        page.$el.style.width = parseInt(this.scale) + "%";
    },
    pdfDownload() {
      const { v1: uuidv1 } = require("uuid");
      const link = document.createElement("a");
      link.href = this.url;
      link.setAttribute("download", "Itinerary" + uuidv1());
      document.body.appendChild(link);
      link.click();
    },
    pdfError(error) {
      console.error(error);
    },
  },
};
</script>
