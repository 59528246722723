<!--
Add features:
- slide down items below insert (use height increase on inserted item)
-->

<template v-if="currentDepth <= maxDepth">
  <div>
    <div v-for="(ti, index) in treeviewData" :key="index">
      <asoftTreeviewItem
        :value="ti"
        :childIndent="childIndent"
        :selectableLeafNodes="selectableLeafNodes"
        :singleClickExpand="singleClickExpand"
        :childrenPropertyName="childrenPropertyName"
        :slotNamePropertyName="slotNamePropertyName"
        @tree-item-click="onTreeItemClick"
        @tree-item-dbl-click="onTreeItemDblClick"
        :maxDepth="maxDepth"
        :currentDepth="currentDepth"
        :isCollapsible="isCollapsible"
      >
        <!-- Pass on all scoped slots, src: https://forum.vuejs.org/t/recursive-scoped-slots/8220/5 -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          v-slot:[slot]="scope"
        >
          <slot :name="slot" v-bind="scope" />
        </template>
      </asoftTreeviewItem>
    </div>
  </div>
</template>

<script>
import asoftTreeviewItem from "./asoftTreeviewItem";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  components: {
    asoftTreeviewItem,
  },
  data() {
    return {
      treeviewData: [],
      currentDepth: 1,
    };
  },
  props: {
    /*
      Takes an array of nodes (possibly only 1). Each node can have an array
      of children off a "children" property. Each node mush also have a slotName 
      property that defines the slotName of node. This slotName node can be used for
      features such as determining colours and icons for the display of the node.
      The node could have other properties such as name and description that could
      also be involved in the display of the node.

      The names of all the currently mentioned properties (apart from children)
      are arbitrary because the display of a node is controlled by the setup
      of an associated slot for that node.

      Reserved Node Properties:
      - children: The array of children off the current node
      - state: defines whether a node is "expanded" or "collapsed" via values
      with these names. This node is set by the system.
      - slotName: defines the name of the corresponding slot for this node. Nodes can
      have many different slotNames allowing nodes to be displayed in many different
      ways - as defined in their specific slots.
      - isCollapsable: An optional boolean property defined on a node that identifies
      the node as collapsable. The default is "true".
      - selected: identifies whether a node is selected or not. The tree control
      has the ability for single and multi-select. This node is set by the system.

      The tree control emits a "click" event on each node click.
      The tree control emits a "dblClick" event on each node double-click.

      There are three special slot names:
      rotating_icon: an optional slot for defining what an expansion icon that 
      rotates 90 degrees clockwise when expanded and 90 degrees anti-clockwise
      when collapsed would look like.
      collapsed_icon: an optional slot for defining what a static expansion icon
      would look like when the node is collapsed.
      expanded_icon: an optional slot for defining what a static expansion icon
      would look like when the node is expanded.
    */
    value: {
      type: Array,
    },
    /*
      Specifies the amount which each child is indented, e.g. "10px"
    */
    childIndent: {
      type: String,
    },
    /*
      Specifies whether nodes without children are selectable.
    */
    selectableLeafNodes: {
      type: Boolean,
      default: true,
    },
    /*
      Specifies whether the tree control accepts multi-select. Selection occurs via
      a single click.
    */
    multiselectInd: {
      type: Boolean,
      default: false,
    },
    /*
      Identifies whether node expansion occurs as the result to a single-click or
      double-click on the node.
    */
    singleClickExpand: {
      type: Boolean,
      default: false,
    },
    /*
      Provide an alternative name for the "children" treenode property.
    */
    childrenPropertyName: {
      type: String,
      default: "children",
    },
    /*
      Provide an alternative name for the "slotName" treenode property.
    */
    slotNamePropertyName: {
      type: String,
      default: "slotName",
    },
    /*
      The maximum number of levels to display in the treeview. Default is 99.
    */
    maxDepth: {
      type: Number,
      default: 99,
    },
    /*
      Controls whether the entire treeview is collapsible.
    */
    isCollapsible: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        if (to != from) {
          this.treeviewData = to;
          logger
            .get(loggingSource.VCASoftTreeView)
            .info("asoftTreeview value watch: %s", to);
        }
      },
    },
  },

  methods: {
    clearSelectedNodes(nodeArray) {
      for (let n of nodeArray) {
        if (n.selected) n.selected = false;
        if (
          n[this.childrenPropertyName] &&
          n[this.childrenPropertyName].length > 0
        )
          this.clearSelectedNodes(n[this.childrenPropertyName]);
      }
    },
    onTreeItemClick(e) {
      if (this.multiselectInd == false) {
        this.clearSelectedNodes(this.value);
        this.$set(e, "selected", true);
      } else {
        if (e.selected && e.selected == true) e.selected = false;
        else this.$set(e, "selected", true);
      }
      this.$emit("click", e);
    },
    onTreeItemDblClick(e) {
      this.$emit("dblclick", e);
    },
  },
};
</script>
