<template>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <awgt-input
      id="booking-reference"
      v-model="additionalDetails.BookingReference"
      counter
      :counterMaxValue="20"
      maxlength="20"
      style="width: 300px"
      label="Booking Reference"
      bg
      class="mr-2"
    ></awgt-input>
    <awgt-input
      id="arrival-location"
      v-model="additionalDetails.ActivityLocation"
      counter
      :counterMaxValue="100"
      maxlength="100"
      style="width: 300px"
      label="Activity Location"
      bg
    ></awgt-input>
  </div>
</template>
<style></style>
}
<script>
import awgtInput from "@/components/AWGT/AwgtInput";
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      additionalDetails: {},
      emptyAdditionalDetails: {
        BookingReference: "",
        ActivityLocation: "",
      },
    };
  },
  components: {
    awgtInput,
  },
  watch: {
    additionalDetails: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("input", this.additionalDetails);
      },
    },
    value: {
      immediate: true,
      handler(to) {
        if (to == null) this.additionalDetails = this.emptyAdditionalDetails;
        else this.additionalDetails = to;
      },
    },
  },
};
</script>
