var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("div", {
      staticStyle: { top: "50px", position: "sticky", "z-index": "1" },
    }),
    _c("h1", { staticClass: "text-center" }, [_vm._v("Itinerary Edit")]),
    _vm.itinerary.Reference != null
      ? _c("h4", { staticClass: "mx-2" }, [
          _vm._v(" Reference: " + _vm._s(_vm.itinerary.Reference) + " "),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
      [
        _c("awgt-input", {
          staticClass: "flex-grow-1 mx-2",
          attrs: {
            counter: "",
            counterMaxValue: 100,
            maxlength: "100",
            label: "Name",
            bg: "",
          },
          model: {
            value: _vm.itinerary.Name,
            callback: function ($$v) {
              _vm.$set(_vm.itinerary, "Name", $$v)
            },
            expression: "itinerary.Name",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
      [
        _c("awgt-input", {
          staticClass: "flex-grow-1 mx-2",
          staticStyle: { "min-width": "300px" },
          attrs: {
            counter: "",
            counterMaxValue: 1000,
            maxlength: "1000",
            label: "Description",
            bg: "",
          },
          model: {
            value: _vm.itinerary.Description,
            callback: function ($$v) {
              _vm.$set(_vm.itinerary, "Description", $$v)
            },
            expression: "itinerary.Description",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "d-flex flex-row flex-wrap" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
        [
          _c("mdb-icon", {
            staticClass: "mx-2 mt-4",
            attrs: {
              icon: "calendar-alt",
              far: "",
              color: "primary",
              size: "2x",
            },
          }),
          _c("asoftDatePicker", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "140px" },
            attrs: {
              label: "Start Date",
              autoHide: "",
              displayDateFormat: "D MMM YYYY",
              dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
              setTime: "00:00:00.000",
              bg: "",
              width: 300,
            },
            model: {
              value: _vm.itinerary.StartDt,
              callback: function ($$v) {
                _vm.$set(_vm.itinerary, "StartDt", $$v)
              },
              expression: "itinerary.StartDt",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "40px" },
            attrs: {
              counterMaxValue: 3,
              maxlength: "3",
              counter: "",
              label: "Duration (days)",
              bg: "",
              type: "number",
            },
            model: {
              value: _vm.itinerary.DurationInDays,
              callback: function ($$v) {
                _vm.$set(_vm.itinerary, "DurationInDays", _vm._n($$v))
              },
              expression: "itinerary.DurationInDays",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
      },
      [
        _c("mdb-icon", {
          staticClass: "mt-4 mx-2",
          staticStyle: { width: "30px", "font-size": "1.5em" },
          attrs: { icon: "sitemap", color: "primary" },
        }),
        _c("awgt-std-dropdown", {
          staticClass: "flex-grow-1",
          staticStyle: { width: "180px" },
          attrs: {
            items: _vm.getItineraryStatuses(false),
            itemValueProperty: "Code",
            itemTextProperty: "Name",
            label: "Itinerary Status",
            bg: "",
          },
          model: {
            value: _vm.itinerary.ItineraryStatus,
            callback: function ($$v) {
              _vm.$set(_vm.itinerary, "ItineraryStatus", $$v)
            },
            expression: "itinerary.ItineraryStatus",
          },
        }),
        _c("awgt-input", {
          staticClass: "mx-2",
          staticStyle: { "min-width": "50px" },
          attrs: {
            id: "itinerary_IsActive",
            type: "checkbox",
            name: "isActive",
            label: "Active Itinerary",
          },
          model: {
            value: _vm.itinerary.IsActive,
            callback: function ($$v) {
              _vm.$set(_vm.itinerary, "IsActive", $$v)
            },
            expression: "itinerary.IsActive",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "float-right" },
      [
        _vm.itinerary.Reference != "" && !_vm.isFormDirty()
          ? _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button", title: "Products" },
                on: {
                  click: function ($event) {
                    return _vm.onProducts()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "comments" },
                }),
                _vm._v("Products "),
              ],
              1
            )
          : _vm._e(),
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button", disabled: !_vm.isFormDirty() },
            on: {
              click: function ($event) {
                return _vm.onSave()
              },
            },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "check" } }),
            _vm._v("Save "),
          ],
          1
        ),
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose()
              },
            },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
            _vm._v("Close "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }