<template>
  <mdb-container fluid>
    <h1 class="text-center">Tooltips</h1>
    <div style="margin: 0 auto; width: 70%">
      <div class="d-flex flex-nowrap flex-row align-items-center">
        <span class="mx-2">Filter:</span>

        <awgt-std-dropdown
          label="System Screen"
          class="mx-2"
          itemValueProperty="Code"
          :items="getSystemScreens(false)"
          itemTextProperty="Name"
          v-model="filteredSystemScreenId"
          @get-value="
            (value) => {
              onFilterTooltips(value);
            }
          "
          bg
        ></awgt-std-dropdown>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearFilter"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
      </div>
    </div>

    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
    >
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onAddTooltip"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <div style="margin: 0 auto; width: 70%">
      <div v-for="(tooltip, index) in tooltips" :key="index">
        <div class="d-flex flex-wrap flex-row align-items-center">
          <span class="mx-2" style="min-width: 60px"
            >Tooltip ID:
            {{ tooltip.TooltipId ? tooltip.TooltipId : "Pending" }}</span
          >
          <awgt-std-button
            v-if="!tooltip.isAdded && checkPermission('CP_TtU')"
            type="button"
            class="command-button mt-1"
            style="min-width: 63px; margin-left: auto"
            @click="onDeleteTooltip(tooltip, index)"
            ><mdb-icon icon="trash-alt" class="mr-1" />
            Delete
          </awgt-std-button>
        </div>
        <awgt-std-dropdown
          v-if="
            getSystemScreens(false)[0].Name != 'Loading getSystemScreen wait...'
          "
          label="System Screen"
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getSystemScreens(false)"
          itemTextProperty="Name"
          v-model="tooltip.SystemScreenId"
          bg
        ></awgt-std-dropdown>
        <awgt-input
          v-model="tooltip.FieldName"
          counter
          :counterMaxValue="50"
          maxlength="50"
          style="min-width: 90px"
          label="Field Name"
          bg
          class="flex-grow-1 mx-2 my-4"
        ></awgt-input>
        <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="clipboard"
            far
            color="primary"
            class="mx-2 mb-2"
            size="2x"
          />
          <div class="ml-2">Tooltip</div>
        </div>
        <mdb-wysiwyg
          v-model="tooltip.Text"
          class="mx-2"
          style="height: 200px"
        ></mdb-wysiwyg>
        <div class="section-break"></div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-center">
      <awgt-std-button
        v-if="checkPermission('CP_TtU')"
        type="button"
        class="command-button mx-2"
        @click="onUpdateTooltips()"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />Save
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onClose()"
      >
        <mdb-icon icon="times" class="mr-1" />Close
      </awgt-std-button>
    </div>
  </mdb-container>
</template>
<script>
import tooltipApi from "@/api/tooltipsApi.js";
import { mdbContainer, mdbIcon } from "mdbvue";
import { mapGetters } from "vuex";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return {
      tooltips: [],
      tooltipsSnapshot: [],
      filteredSystemScreenId: null,
      isFormDirtyInd: false,
    };
  },

  props: {
    onSavePromptExit: {
      type: Function,
    },
  },

  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    awgtInput,
    AwgtStdDropdown,
    mdbWysiwyg,
  },

  computed: {
    ...mapGetters(["getSystemScreens", "checkPermission"]),
  },

  methods: {
    isFormDirty() {
      if (this.isFormDirtyInd == true) return true;
      this.isFormDirtyInd =
        this.$lodash.isEqual(this.tooltips, this.tooltipsSnapshot) == false;

      return this.isFormDirtyInd;
    },

    snapshotTooltips() {
      this.tooltipsSnapshot = this.$lodash.cloneDeep(this.tooltips);
      this.isFormDirtyInd = false;
    },

    async onFilterTooltips(val) {
      this.tooltips = await tooltipApi.getTooltips(val);
      this.snapshotTooltips();

      for (let i = 0; i < this.tooltips.length; i++) {
        this.$set(this.tooltips, "isAdded", false);
      }
    },

    async onClearFilter() {
      await this.loadFormData();
      this.filteredSystemScreenId = null;
    },

    onAddTooltip() {
      let tooltip = {
        FieldName: "",
        Text: "",
        isAdded: true,
      };

      this.tooltips.push(tooltip);
      window.scroll({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },

    async onUpdateTooltips() {
      this.UpdateTooltips()
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [`The tooltips have been updated.`],
          });
        })
        .catch(() => {
          // Errors have been logged
        });
    },

    async UpdateTooltips() {
      this.tooltips = await tooltipApi.putAsync(
        this.tooltips,
        this.filteredSystemScreenId
      );
      this.snapshotTooltips();

      for (let i = 0; i < this.tooltips.length; i++) {
        this.$set(this.tooltips, "isAdded", false);
      }
    },

    onDeleteTooltip(tooltip, idx) {
      tooltipApi
        .deleteTooltip(tooltip)
        .then(() => {
          this.tooltips.splice(idx, 1);

          this.$emit("header-message", {
            isSuccessInd: true,
            Message: ["The tooltip has been deleted."],
          });
        })
        .catch(() => {
          // Errors have been logged
        });
    },

    onClose() {
      this.$router.go(-1);
    },

    async loadFormData() {
      this.tooltips = await tooltipApi.getTooltips();

      for (let i = 0; i < this.tooltips.length; i++) {
        this.$set(this.tooltips, "isAdded", false);
      }
    },
  },

  mounted() {
    this.loadFormData().then(() => {
      this.snapshotTooltips();
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onUpdateTooltips()
            .then(() => {
              next();
            })
            .catch(() => {
              // Erros have been logged. Suppress exception here
            });
        }
      });
    } else next();
  },
};
</script>

<style>
.textarea-input textarea {
  resize: vertical !important;
}
</style>
