<template>
  <mdb-card-header v-bind="$attrs" v-on="$listeners" :color="backgroundColor">
    <span class="float-right">
      <mdb-icon
        v-if="expanded"
        icon="angle-up"
        size="2x"
        class="pt-2"
        :color="iconColor"
      />
      <mdb-icon
        v-else
        icon="angle-down"
        size="2x"
        class="pt-2"
        :color="iconColor"
      />
    </span>
    <span :style="{ color: textColor }">
      <slot> </slot>
    </span>

    <template v-if="!user.IsActive">
      <span class="alert-text" id="text-alert">Deleted</span>
    </template>
  </mdb-card-header>
</template>

<script>
import { mdbCardHeader, mdbIcon } from "mdbvue";
import variables from "@/styles/sharedVariables.scss";

export default {
  components: { mdbCardHeader, mdbIcon },
  props: {
    user: { type: Object, required: true },
    expanded: { type: Boolean },
    backgroundColor: {
      type: String,
      default: variables.awgtExpansionRegionHeaderBackgroundColor,
    },
    textColor: {
      type: String,
      default: variables.awgtExpansionRegionHeaderTextColor,
    },
    iconColor: {
      type: String,
      default: variables.awgtExpansionRegionArrowColor,
    },
  },
};
</script>
