<template>
  <asoft-select
    v-bind="$attrs"
    v-on="$listeners"
    :color="selectorColor"
    :icon="icon"
    :tooltipIconColor="tooltipIconColor"
    :value="value"
  ></asoft-select>
</template>

<script>
import asoftSelect from "@/components/AtomSoftware/asoftSelect";
import variables from "@/styles/sharedVariables.scss";

export default {
  components: {
    asoftSelect,
  },
  props: {
    value: {
      type: [String, Number, Array],
    },
    headerBackgroundColor: {
      type: String,
      default: variables.awgtStdDropdownHeaderBackgroundColor,
    },
    headerSelectedTextColor: {
      type: String,
      default: variables.awgtStdDropdownHeaderSelectedTextColor,
    },
    headerLabelColor: {
      type: String,
      default: variables.awgtStdDropdownHeaderLabelColor,
    },
    selectorColor: {
      type: String,
      default: variables.awgtStdDropdownSelectorBackgroundColor,
    },
    listBackgroundColor: {
      type: String,
      default: variables.awgtStdDropdownListBackgroundColor,
    },
    listTextColor: {
      type: String,
      default: variables.awgtStdDropdownListTextColor,
    },
    icon: { type: String },
    tooltipIconColor: {
      type: String,
      default: variables.awgtStdDropdownTooltipIconColor,
    },
    activeColor: {
      type: String,
      default: variables.awgtStdDropdownActiveColor,
    },
  },

  /*   watch: {
    items: {
      handler() {
        if (this.listTextColor) {
          let element = this.$el.querySelector(
            ".select-inner-wrapper > .options-container"
          );

          /*           let s = new XMLSerializer();
          let str = s.serializeToString(element);
          console.log("*******************");
          console.log(str);

          let filtrableElements = element.querySelectorAll("span"); //.filtrable");
          /*console.log("*******************");
          console.log(filtrableElements.length); 
          for (let fe of filtrableElements) {
            this.listTextColor ? (fe.style.color = this.listTextColor) : null;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  }, */

  mounted() {
    if (this.headerLabelColor) {
      let element = this.$el.querySelector(".select-dropdown > label");
      this.headerLabelColor
        ? (element.style.color = this.headerLabelColor)
        : null;
    }

    if (this.listBackgroundColor || this.listTextColor) {
      let element = this.$el.querySelector(
        ".select-inner-wrapper > .options-container"
      );
      this.listBackgroundColor
        ? (element.style.background = this.listBackgroundColor)
        : null;
    }

    if (this.headerBackgroundColor || this.headerSelectedTextColor) {
      let element = this.$el.querySelector(".select-dropdown > input");
      this.headerBackgroundColor
        ? (element.style.background = this.headerBackgroundColor)
        : null;
      this.headerSelectedTextColor
        ? (element.style.color = this.headerSelectedTextColor)
        : null;
    }

    /*
      The .select-active css class needs to be changed on the stylesheet
      as it only exists on controls briefly when a control is selected.
    */
    if (this.activeColor) {
      var stylesheets = document.styleSheets;
      for (let i = 0; i < stylesheets.length; i++) {
        var stylesheet = stylesheets[i];
        //Only read stylesheets from this domain to avoid a cross site scripting security error.
        if (stylesheet.href == null) {
          for (let j = 0; j < stylesheet.cssRules.length; j++) {
            var cssRule = stylesheet.cssRules[j];
            if (cssRule.selectorText === ".select-active input") {
              cssRule.style.setProperty(
                "border-bottom",
                `1px solid ${this.activeColor}`,
                "important"
              );
              cssRule.style.setProperty(
                "box-shadow",
                `0 1px 0 0 ${this.activeColor}, 0 1px 0 0 ${this.activeColor}`,
                "important"
              );
            }
          }
        }
      }
    }
  },
};
</script>
