import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getTooltips(systemScreenId = null) {
    try {
      logger.get(loggingSource.UITooltips).info("getTooltips...");

      const response = await api.client
        .get("admin/tooltip", {
          params: {
            SystemScreenId: systemScreenId,
          },
        })
        .catch((error) => {
          throw error;
        });
      const tooltips = response.data;

      logger
        .get(loggingSource.UITooltips)
        .trace("getTooltips response: %s", response);

      return tooltips;
    } catch (error) {
      logger.get(loggingSource.UITooltips).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UITooltips).info("...getTooltips");
    }
  },

  async putAsync(payload, systemScreenId = null) {
    try {
      logger.get(loggingSource.UITooltips).info("putAsync...");
      logger
        .get(loggingSource.UITooltips)
        .debug(
          "Parameters => payload: %s, systemScreenId: %s",
          payload,
          systemScreenId
        );

      logger.get(loggingSource.UITooltips).time("putAsync call");
      const response = await api.client
        .put("admin/tooltip", payload, {
          params: {
            systemScreenId: systemScreenId,
          },
        })
        .catch((error) => {
          logger.get(loggingSource.UITooltips).error("Error: %s", error);
          logger
            .get(loggingSource.UITooltips)
            .error("Error response data: %s", error?.response?.data);

          throw error;
        });
      logger.get(loggingSource.UITooltips).timeEnd("putAsync call");
      const responseData = response.data;

      logger
        .get(loggingSource.UITooltips)
        .trace("putAsync response: %s", response);
      logger
        .get(loggingSource.UITooltips)
        .debug("putAsync client: %s", responseData);

      return responseData;
    } catch (error) {
      logger.get(loggingSource.UITooltips).timeEnd("putAsync call");
      logger.get(loggingSource.UITooltips).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UITooltips).info("...putAsync");
    }
  },

  async deleteTooltip(tooltipPayload) {
    try {
      logger.get(loggingSource.UITooltips).info("deleteTooltip...");

      logger
        .get(loggingSource.UITooltips)
        .debug("Parameters => tooltip Payload: %s", tooltipPayload);

      logger.get(loggingSource.UITooltips).time("deleteTooltip call");
      await api.client
        .delete(
          `admin/tooltip/${
            tooltipPayload.TooltipId
          }?concurrencyRV=${encodeURIComponent(tooltipPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          error;
        });
      logger.get(loggingSource.UITooltips).timeEnd("deleteTooltip call");
    } catch (error) {
      logger.get(loggingSource.UITooltips).timeEnd("deleteTooltip call");
      logger.get(loggingSource.UITooltips).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UITooltips).info("...deleteTooltip");
    }
  },
};
