var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "tvitem",
          class: [
            {
              treenode:
                (_vm.treeviewData[_vm.childrenPropertyName].length > 0 &&
                  _vm.currentDepth < _vm.maxDepth) ||
                _vm.selectableLeafNodes == true,
            },
          ],
          on: {
            click: function ($event) {
              return _vm.onClick(_vm.treeviewData)
            },
            dblclick: function ($event) {
              return _vm.onDblClick(_vm.treeviewData)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "rotatingicon",
              class: [{ rotate: _vm.treeviewData.state == "expanded" }],
            },
            [
              _vm.treeviewData[_vm.childrenPropertyName].length > 0 &&
              _vm.currentDepth < _vm.maxDepth
                ? _vm._t("rotating_icon")
                : _vm._e(),
            ],
            2
          ),
          _vm.treeviewData[_vm.childrenPropertyName].length > 0 &&
          _vm.currentDepth < _vm.maxDepth &&
          _vm.treeviewData.state == "collapsed"
            ? _vm._t("collapsed_icon")
            : _vm._e(),
          _vm.treeviewData[_vm.childrenPropertyName].length > 0 &&
          _vm.currentDepth < _vm.maxDepth &&
          _vm.treeviewData.state == "expanded"
            ? _vm._t("expanded_icon")
            : _vm._e(),
          _vm._t(
            _vm.treeviewData[_vm.slotNamePropertyName]
              ? `${_vm.treeviewData[_vm.slotNamePropertyName]}`
              : "default",
            null,
            null,
            _vm.treeviewData
          ),
        ],
        2
      ),
      _vm.currentDepth <= _vm.maxDepth &&
      (_vm.isCollapsible == false ||
        _vm.treeviewData.state == "expanded" ||
        (_vm.treeviewData.isCollapsible != undefined &&
          _vm.treeviewData.isCollapsible == false))
        ? _vm._l(
            _vm.treeviewData[_vm.childrenPropertyName],
            function (ti, index) {
              return _c(
                "div",
                { key: index, style: `margin-left:${_vm.childIndent};` },
                [
                  _c("asoftTreeviewItem", {
                    attrs: {
                      value: ti,
                      childIndent: _vm.childIndent,
                      selectableLeafNodes: _vm.selectableLeafNodes,
                      singleClickExpand: _vm.singleClickExpand,
                      childrenPropertyName: _vm.childrenPropertyName,
                      slotNamePropertyName: _vm.slotNamePropertyName,
                      currentDepth: _vm.currentDepth + 1,
                      maxDepth: _vm.maxDepth,
                      isCollapsible: _vm.isCollapsible,
                    },
                    on: {
                      "tree-item-click": _vm.onTreeItemClick,
                      "tree-item-dbl-click": _vm.onTreeItemDblClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                          return {
                            key: slot,
                            fn: function (scope) {
                              return [_vm._t(slot, null, null, scope)]
                            },
                          }
                        }),
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }