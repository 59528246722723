var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Product Search")]),
      _c("product-search", {
        attrs: { isTravelBookingProductSearch: false },
        on: { "header-message": _vm.displayHeaderMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }