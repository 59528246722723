<template>
  <div>
    <div
      class="box"
      :class="[shadow ? 'box-border-shadow' : 'box-normal-border ']"
      :style="{ borderColor: borderColor }"
    >
      <div>
        <mdb-icon
          far
          style="margin: 0 auto"
          :icon="icon"
          :color="iconColor"
          size="1x"
        />
      </div>
      <div
        style="
           {
            font-size: 0.8rem;
          }
        "
        :style="{ color: titleColor }"
      >
        <span>{{ title }}</span>
      </div>
      <div style="word-wrap: break-word" :style="{ color: contentColor }">
        {{ content }}
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbIcon } from "mdbvue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: [Number, String],
    },
    icon: {
      type: String,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    titleColor: {
      type: String,
      default: "green",
    },
    iconColor: {
      type: String,
      default: "primary",
    },
    borderColor: {
      type: String,
      default: "green",
    },
    contentColor: {
      type: String,
      default: "green",
    },
  },
  components: {
    mdbIcon,
  },
};
</script>
<style scoped>
.box-border-shadow {
  box-shadow: inset 0px 3px 3px -3px rgba(50, 50, 50, 0.75),
    0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 10px 10px rgba(0, 0, 0, 0.06), 0 10px 10px rgba(0, 0, 0, 0.12);
}

.box-normal-border {
  border: 1px solid black;
}

.box {
  text-align: center;
  border-radius: 30px;
  width: 150px;
  height: 150px;
  padding: 15px 20px 10px 20px;
}
</style>
