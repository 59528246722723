var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { color: _vm.color } },
    [
      _c(
        "h5",
        [
          _c("mdb-icon", {
            staticClass: "icon-in-views",
            attrs: { icon: "user", color: _vm.iconColor, size: "1x" },
          }),
          _vm._v(_vm._s(_vm.value.FirstName + " " + _vm.value.Surname) + " "),
          _c("span", { staticStyle: { "font-size": "75%" } }, [
            _c("i", [_vm._v("(" + _vm._s(_vm.value.Reference) + ")")]),
          ]),
        ],
        1
      ),
      _c("asoftPhoneNumberCapture", {
        staticClass: "d-flex flex-row flex-nowrap flex-grow-1",
        attrs: {
          template: _vm.getSystemTemplateFromCode("STT_C_UP"),
          value: _vm.value.PhoneJSON,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ template, phone }) {
              return [
                phone.Value.length > 0
                  ? _c("mdb-icon", {
                      staticClass: "icon-in-views mt-1",
                      attrs: {
                        icon: "phone",
                        color: _vm.iconColor,
                        size: "1x",
                      },
                    })
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(phone.Value.length > 0 ? template.Label : null) +
                    " "
                ),
                _c(
                  "awgt-link-text",
                  {
                    staticClass: "ml-1 mr-2",
                    attrs: { href: "tel:" + phone.Value },
                  },
                  [_vm._v(_vm._s(_vm.formatPhoneNumber(phone.Value)))]
                ),
              ]
            },
          },
        ]),
      }),
      _c("mdb-icon", {
        staticClass: "icon-in-views",
        attrs: { icon: "at", color: _vm.iconColor, size: "1x" },
      }),
      _vm._v("Email: "),
      _c(
        "awgt-link-text",
        { attrs: { href: "mailto:" + _vm.value.EmailAddress } },
        [_vm._v(_vm._s(_vm.value.EmailAddress))]
      ),
      _c("br"),
      _c("mdb-icon", {
        staticClass: "icon-in-views",
        attrs: { icon: "user-cog", color: _vm.iconColor, size: "1x" },
      }),
      _vm._v(
        "Consultancy role: " +
          _vm._s(
            _vm.getConsultancyRoleNameById(
              _vm.value.ConsultancyRole.ConsultancyRoleId
            )
          ) +
          " "
      ),
      _c("br"),
      _vm.getConsultancyRoleNameById(
        _vm.value.ConsultancyRole.ConsultancyRoleId
      ) == "Consultant"
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { icon: "users", color: _vm.iconColor, size: "1x" },
              }),
              _vm._v(
                _vm._s(
                  _vm.value.Workgroups.length != 0
                    ? "Workgroup: " + _vm.value.Workgroups[0].Name
                    : "This consultant is not in a workgroup."
                ) + " "
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { icon: "users", color: _vm.iconColor, size: "1x" },
              }),
              _vm.value.Workgroups.length == 0
                ? [_vm._v("This user is not in any workgroups.")]
                : [
                    _vm._v("Workgroups: "),
                    _c(
                      "ul",
                      { staticClass: "html-in-views" },
                      _vm._l(_vm.value.Workgroups, function (workgroup, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(workgroup.Name) + " "),
                        ])
                      }),
                      0
                    ),
                  ],
            ],
            2
          ),
      _vm.value.IsAdministrator || _vm.value.CanModifyProducts
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { icon: "user-cog", color: _vm.iconColor, size: "1x" },
              }),
              _vm._v("Other user roles: "),
              _c("ul", { staticClass: "mb-0 html-in-views" }, [
                _vm.value.IsAdministrator
                  ? _c("li", [_vm._v("This user is an administrator.")])
                  : _vm._e(),
                _vm.value.CanModifyProducts
                  ? _c("li", [_vm._v("This user can modify products.")])
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.value.Impersonators.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  icon: "user-secret",
                  color: _vm.iconColor,
                  size: "1x",
                },
              }),
              _vm._v("Impersonated By: "),
              _c(
                "ul",
                { staticClass: "mb-0 html-in-views" },
                _vm._l(_vm.value.Impersonators, function (impersonator, index) {
                  return _c("li", { key: index }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          impersonator.FirstName + " " + impersonator.Surname
                        ) +
                        " "
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.value.Impersonates.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  icon: "user-secret",
                  color: _vm.iconColor,
                  size: "1x",
                },
              }),
              _vm._v("Impersonates: "),
              _c(
                "ul",
                { staticClass: "mb-0 html-in-views" },
                _vm._l(_vm.value.Impersonates, function (impersonator, index) {
                  return _c("li", { key: index }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          impersonator.FirstName + " " + impersonator.Surname
                        ) +
                        " "
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }