var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h5",
        [
          _c("mdb-icon", {
            staticClass: "icon-in-views",
            attrs: {
              far: "",
              fas: "",
              icon: "dolly",
              size: "1x",
              color: "primary",
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.value.Name) + " ")]),
          _c("span", { staticStyle: { "font-size": "75%" } }, [
            _c("i", [_vm._v("(" + _vm._s(_vm.value.Reference) + ")")]),
          ]),
        ],
        1
      ),
      _vm.value.ContactPersonNm.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { far: "", icon: "user", color: "primary", size: "1x" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.value.ContactPersonNm))]),
            ],
            1
          )
        : _vm._e(),
      _vm.physicalAddress.Street.length +
        _vm.physicalAddress.Locality.length +
        (_vm.physicalAddress.Subdivision != null
          ? _vm.physicalAddress.Subdivision.length
          : 0) +
        _vm.physicalAddress.Postcode.length >
      0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  icon: "map-marker-alt",
                  color: "primary",
                  size: "1x",
                },
              }),
              _vm.physicalAddress.Street.length > 0
                ? [
                    _c("span", [
                      _vm._v(_vm._s(_vm.physicalAddress.Street) + ","),
                    ]),
                  ]
                : _vm._e(),
              _vm.physicalAddress.Locality.length > 0
                ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Locality) + " ")]
                : _vm._e(),
              _vm.physicalAddress.Subdivision != null &&
              _vm.physicalAddress.Subdivision.length > 0
                ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Subdivision) + " ")]
                : _vm._e(),
              _vm.physicalAddress.Postcode.length > 0
                ? [_vm._v(" " + _vm._s(_vm.physicalAddress.Postcode) + " ")]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.postalAddress.Street.length +
        _vm.postalAddress.Locality.length +
        (_vm.postalAddress.Subdivision != null
          ? _vm.postalAddress.Subdivision.length
          : 0) +
        _vm.postalAddress.Postcode.length >
      0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  icon: "envelope",
                  color: "primary",
                  size: "1x",
                },
              }),
              _vm.postalAddress.Street.length > 0
                ? [_c("span", [_vm._v(_vm._s(_vm.postalAddress.Street) + ",")])]
                : _vm._e(),
              _vm.postalAddress.Locality.length > 0
                ? [_vm._v(" " + _vm._s(_vm.postalAddress.Locality) + " ")]
                : _vm._e(),
              _vm.postalAddress.Subdivision != null &&
              _vm.postalAddress.Subdivision.length > 0
                ? [_vm._v(" " + _vm._s(_vm.postalAddress.Subdivision) + " ")]
                : _vm._e(),
              _vm.postalAddress.Postcode.length > 0
                ? [_vm._v(" " + _vm._s(_vm.postalAddress.Postcode) + " ")]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.value.Phone.length > 0
        ? _c("div", [
            _c(
              "span",
              { staticClass: "mr-3" },
              [
                _c("mdb-icon", {
                  staticClass: "icon-in-views",
                  attrs: {
                    far: "",
                    icon: "phone",
                    color: "primary",
                    size: "1x",
                  },
                }),
                _c(
                  "a",
                  { attrs: { href: "tel:" + _vm.value.Phone } },
                  [
                    _c(
                      "asoft-field-formatter",
                      { attrs: { fieldType: "PhoneNumber" } },
                      [_vm._v(_vm._s(_vm.value.Phone))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.value.EmailAddress.length > 0
              ? _c(
                  "span",
                  [
                    _c("mdb-icon", {
                      staticClass: "icon-in-views",
                      attrs: {
                        far: "",
                        icon: "at",
                        color: "primary",
                        size: "1x",
                      },
                    }),
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.value.EmailAddress } },
                      [_vm._v(_vm._s(_vm.value.EmailAddress))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.value.DepositAmt > 0
        ? [
            _vm.value.DepositType == "DT_PP" || _vm.value.DepositType == "DT_FA"
              ? _c(
                  "div",
                  [
                    _c("mdb-icon", {
                      staticClass: "icon-in-views",
                      attrs: {
                        far: "",
                        icon: "dollar-sign",
                        color: "primary",
                        size: "1x",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        "Deposit: $" +
                          _vm._s(_vm.value.DepositAmt) +
                          " (" +
                          _vm._s(
                            _vm.getDepositTypeNameByCode(_vm.value.DepositType)
                          ) +
                          ")"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm.value.DepositType == "DT_Pe"
              ? _c(
                  "div",
                  [
                    _c("mdb-icon", {
                      staticClass: "icon-in-views",
                      attrs: {
                        far: "",
                        icon: "dollar-sign",
                        color: "primary",
                        size: "1x",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        "Deposit: " +
                          _vm._s(_vm.value.DepositAmt) +
                          "% (" +
                          _vm._s(
                            _vm.getDepositTypeNameByCode(_vm.value.DepositType)
                          ) +
                          ")"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.value.PaymentTypes.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  color: "primary",
                  icon: "money-check-edit-alt",
                  size: "1x",
                },
              }),
              _c("span", [_vm._v("Payment Types:")]),
              _c("br"),
              _c("div", { staticClass: "html-in-views" }, [
                _c(
                  "ul",
                  { staticStyle: { margin: "0px" } },
                  _vm._l(_vm.value.PaymentTypes, function (pt, idx) {
                    return _c("li", { key: idx }, [
                      _vm._v(
                        " " + _vm._s(_vm.getPaymentTypeFromCode(pt)) + " "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.value.BookingMethod.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  color: "primary",
                  icon: "phone-square-alt",
                  size: "1x",
                },
              }),
              _c("span", [
                _vm._v(
                  "Booking Method: " +
                    _vm._s(
                      _vm.getBookingMethodNameByCode(_vm.value.BookingMethod)
                    )
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.value.ItineraryNotes.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  icon: "clipboard",
                  color: "primary",
                  size: "1x",
                },
              }),
              _c("span", [_vm._v("Itinerary Notes:")]),
              _c("br"),
              _c("div", { staticClass: "html-in-views" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.value.ItineraryNotes) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.value.AWGTNotes.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  icon: "clipboard",
                  color: "primary",
                  size: "1x",
                },
              }),
              _c("span", [_vm._v("Agency Notes:")]),
              _c("br"),
              _c("div", { staticClass: "html-in-views" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.value.AWGTNotes) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.value.SupplierNotes.length > 0
        ? _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: {
                  far: "",
                  icon: "clipboard",
                  color: "primary",
                  size: "1x",
                },
              }),
              _c("span", [_vm._v("AWGT Supplier Notes:")]),
              _c("br"),
              _vm._l(_vm.value.SupplierNotes, function (note, index) {
                return _c("ul", { key: index, staticClass: "mb-0" }, [
                  note.Note.length > 0
                    ? _c("li", { staticClass: "html-in-views" }, [
                        _c("span", [
                          _c("b", [_vm._v(_vm._s(note.Subject) + ":")]),
                        ]),
                        _vm._v(" " + _vm._s(note.Note) + " "),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }