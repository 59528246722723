<template>
  <div>
    <mdb-input
      type="textarea"
      :rows="rows"
      :label="label"
      :color="color"
      @input.native="AdjustSize()"
      :value="value"
      :read-only="readonly"
      bg
    />
  </div>
</template>

<style>
textarea {
  overflow: hidden;
  resize: none;
}
</style>

<script>
import { mdbInput } from "mdbvue";

export default {
  name: "AutogrowTextarea",
  components: {
    mdbInput,
  },
  props: {
    value: { type: String },
    label: { type: String },
    color: { type: String },
    readonly: { type: Boolean, default: false },
    rows: { type: [Number, String], default: 1 },
  },
  data() {
    return {
      height: "auto",
    };
  },
  watch: {
    value(to, from) {
      if (to != from) this.AdjustSize();
    },
  },
  methods: {
    AdjustSize() {
      //This must run on nextTick to ensure that the new text has
      //been rendered otherwise the height calculation might be
      //incorrect.
      this.$nextTick(() => {
        //Get the textarea control directly
        const ta = this.$children[0].$refs.input;
        //Adjust the height of the field. Height must be set
        //to a low value for scrollHeight to not return the
        //current value when reducing the content of the textarea.
        ta.style.height = "1px";
        ta.style.height = ta.scrollHeight + "px";
        this.$emit("input", ta.value);
      });
    },
  },
  mounted() {
    this.AdjustSize();
  },
};
</script>
