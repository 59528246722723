var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-btn",
    _vm._g(
      _vm._b({ attrs: { color: _vm.color } }, "mdb-btn", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { style: { color: _vm.textColor } },
        [
          _vm._t("default", function () {
            return [
              _vm.icon
                ? _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: _vm.icon },
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }