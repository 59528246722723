<template>
  <div>
    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
      >
        <div style="width: 100px">Pricing:</div>
        <div class="d-flex flex-row flex-grow-1 justify-content-end pr-1">
          <awgt-input
            type="checkbox"
            id="IndicativePricing"
            name="IndicativePricing"
            v-model="IsIndicativePricingInd"
            label="Indicative Pricing"
            class="pl0-important"
            style="margin-left: 18px"
          />
        </div>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="AddPrice"
          :disabled="enableAddButtonInd == false"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
    </div>
    <div v-for="(price, index) in pricesArray" :key="index">
      <div
        class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
        style="margin-bottom: -10px"
      >
        <div class="d-flex flex-row flex-wrap flex-grow-1">
          <div
            v-for="(pricingScale, psindex) in price.PricingScales.filter(
              (i) => i.PricingScaleCd != 'PS_U'
            )"
            :key="psindex"
          >
            <div class="d-flex flex-row flex-nowrap mx-2 align-items-center">
              {{
                getScaleHint(
                  pricingScale.QuantityUnitCd,
                  pricingScale.PricingScaleCd,
                  psindex == 0
                )
              }}
              <mdb-icon
                icon="calendar-alt"
                color="primary"
                size="2x"
                class="mx-2"
                style="width: 28px"
              />
              <awgt-input
                v-if="
                  pricingScale.PricingScaleCd == 'PS_M' ||
                  pricingScale.PricingScaleCd == 'PS_R'
                "
                v-model.number="pricingScale.MinQuantity"
                maxlength="4"
                :label="getRangeLimitLabel('min', pricingScale.QuantityUnitCd)"
                bg
                type="text"
                style="width: 110px"
                @input="onGSTPriceInput(price)"
                @focus="onFocus('GSTPrice', price)"
                v-numeric="{
                  precision: 4,
                  scale: 0,
                  posOnly: true,
                  trailingZeroScale: 0,
                  allowEmptyString: true,
                }"
              />
              <span v-if="pricingScale.PricingScaleCd == 'PS_R'" class="mx-2">
                and
              </span>
              <awgt-input
                v-if="
                  pricingScale.PricingScaleCd == 'PS_X' ||
                  pricingScale.PricingScaleCd == 'PS_R'
                "
                v-model.number="pricingScale.MaxQuantity"
                maxlength="4"
                :label="getRangeLimitLabel('max', pricingScale.QuantityUnitCd)"
                bg
                type="text"
                style="width: 110px"
                @input="onGSTPriceInput(price)"
                @focus="onFocus('GSTPrice', price)"
                v-numeric="{
                  precision: 4,
                  scale: 0,
                  posOnly: true,
                  trailingZeroScale: 0,
                  allowEmptyString: true,
                }"
              />
            </div>
          </div>

          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 ml-2 align-items-center"
          >
            <span
              >{{ hasScaledPricingInd ? "then the price " : "The price " }}
              {{ getPerQuantityUnitQualifier(value) }}
              is</span
            >
            <mdb-icon
              icon="dollar-sign"
              color="primary"
              class="grey-text ml-2 mr-1"
              size="2x"
            />
            <awgt-input
              v-if="
                value.PriceTypeCd != 'PrT_E' && value.PriceTypeCd != 'PrT_Z'
              "
              v-model.number="price.TotalPrice"
              maxlength="7"
              :label="value.PriceTypeCd == 'PrT_F' ? 'Price' : 'Incl-GST Price'"
              class="ml-2"
              bg
              type="text"
              @input="onTotalPriceInput(price)"
              @focus="onFocus('TotalPrice', price)"
              style="width: 140px"
              v-numeric="{
                precision: 6,
                scale: 2,
                posOnly: false,
                trailingZeroScale: 2,
              }"
            />
            <awgt-input
              v-if="
                value.PriceTypeCd != 'PrT_F' && value.PriceTypeCd != 'PrT_Z'
              "
              v-model.number="price.ExGSTUnitAmt"
              maxlength="7"
              label="Ex-GST Price"
              bg
              class="ml-2"
              type="text"
              style="width: 140px"
              @input="onExGSTPriceInput(price)"
              @focus="onFocus('ExGSTPrice', price)"
              v-numeric="{
                precision: 6,
                scale: 2,
                posOnly: false,
                trailingZeroScale: 2,
              }"
            />
            <awgt-input
              v-if="
                value.PriceTypeCd != 'PrT_F' && value.PriceTypeCd != 'PrT_Z'
              "
              v-model.number="price.GSTUnitAmt"
              maxlength="7"
              label="GST Price"
              class="ml-2"
              bg
              type="text"
              style="width: 140px"
              @input="onGSTPriceInput(price)"
              @focus="onFocus('GSTPrice', price)"
              v-numeric="{
                precision: 6,
                scale: 2,
                posOnly: false,
                trailingZeroScale: 2,
              }"
            />
            <template v-if="value.PriceTypeCd == 'PrT_E'">
              <span class="ml-3">${{ price.TotalPrice }}</span>
            </template>
            <template v-if="value.PriceTypeCd == 'PrT_Z'">
              <span class="ml-3">Price: $0</span>
            </template>
            <template
              v-if="
                value.PriceTypeCd == 'PrT_F' || value.PriceTypeCd == 'PrT_Z'
              "
            >
              <span class="mx-3">ex-GST: ${{ price.ExGSTUnitAmt }}</span>
              <span>GST: ${{ price.GSTUnitAmt }}</span>
            </template>
            <div
              class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end mr-2"
            >
              <awgt-std-button
                type="button"
                class="command-button ml-3"
                style="min-width: 63px"
                @click="onDeletePrice(index)"
                ><mdb-icon
                  icon="trash-alt"
                  class="mr-1"
                />Delete</awgt-std-button
              >
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import awgtInput from "@/components/AWGT/AwgtInput";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mdbIcon } from "mdbvue";
import { sharedMethods } from "@/shared/shared.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
  },
  data() {
    return {
      pricesArray: [],
      IsIndicativePricingInd: false,
    };
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    value: {
      type: Object,
    },
  },

  watch: {
    value: {
      deep: true,
      handler(to) {
        let productLinePrices = to.ProductLinePrices;
        for (let i = 0; i < productLinePrices.length; i++) {
          productLinePrices[i].TotalPrice =
            productLinePrices[i].ExGSTUnitAmt + productLinePrices[i].GSTUnitAmt;
        }
        this.pricesArray = productLinePrices;

        for (let price of this.pricesArray) {
          if (to.PriceTypeCd == "PrT_F") {
            price.ExGSTUnitAmt = price.TotalPrice;
            price.GSTUnitAmt = 0;
          } else if (to.PriceTypeCd == "PrT_I" || to.PriceTypeCd == "PrT_E") {
            price.ExGSTUnitAmt = parseFloat(
              ((price.TotalPrice / 11) * 10).toFixed(2)
            );
            price.GSTUnitAmt = parseFloat((price.TotalPrice / 11).toFixed(2));
            price.TotalPrice = price.ExGSTUnitAmt + price.GSTUnitAmt;
          } else if (to.PriceTypeCd == "PrT_Q" || to.PriceTypeCd == "PrT_Z") {
            price.ExGSTUnitAmt = 0;
            price.GSTUnitAmt = 0;
            price.TotalPrice = 0;
            // Prices, Price Determinates and Price Adjustments are cleared in the parent component.
          }
        }
      },
      immediate: true,
    },

    pricesArray(to) {
      this.mutateChronology("ProductLinePrices", to);
    },
    IsIndicativePricingInd(to) {
      this.mutateChronology("IsIndicativePricingInd", to);
    },
  },

  computed: {
    ...mapGetters(["getEmptyProductLinePrice", "getEmptyPricingScale"]),

    /*
  This is common between edit-CommercialProductLineEditDialog and CommercialProductLinePriceList. Work out how to share computed methods.
*/

    hasScaledPricingInd() {
      for (let pricingScaleType of this.value.PricingScaleTypes) {
        if (pricingScaleType.PricingScaleCd != "PS_U") return true;
      }

      return false;
    },

    enableAddButtonInd() {
      for (let pricingScaleType of this.value.PricingScaleTypes) {
        if (
          pricingScaleType.PricingScaleCd == null ||
          pricingScaleType.QuantityUnitCd == null
        )
          return false;
      }

      return true;
    },

    getScaleHint() {
      return (quantityUnit, pricingScale, isFirstRowInd) => {
        switch (pricingScale) {
          case "PS_M":
            return `${
              isFirstRowInd ? "If" : "and"
            } the number of ${this.getPluralQuantityUnit(
              this.value,
              quantityUnit
            )} is at least`;
          case "PS_X":
            return `${
              isFirstRowInd ? "If" : "and"
            } the number of ${this.getPluralQuantityUnit(
              this.value,
              quantityUnit
            )} is at most`;
          case "PS_R":
            return `${
              isFirstRowInd ? "If" : "and"
            } the number of ${this.getPluralQuantityUnit(
              this.value,
              quantityUnit
            )} is between`;
          default:
            return "unknown pricing scale";
        }
      };
    },

    getRangeLimitLabel() {
      return (rangeLimit, quantityUnit) => {
        return `${rangeLimit} ${this.getPluralQuantityUnit(
          this.value,
          quantityUnit
        )}`;
      };
    },
  },

  methods: {
    ...sharedMethods,

    mutateChronology(key, val) {
      let updatedChronology = this.$lodash.cloneDeep(this.value);
      updatedChronology[key] = val;
      this.$emit("input", updatedChronology);
    },

    AddPrice() {
      let price = this.$lodash.cloneDeep(this.getEmptyProductLinePrice);

      for (let pricingScaleType of this.value.PricingScaleTypes) {
        let emptyPricingScale = this.$lodash.cloneDeep(
          this.getEmptyPricingScale
        );

        emptyPricingScale.QuantityUnitCd = pricingScaleType.QuantityUnitCd;
        emptyPricingScale.PricingScaleCd = pricingScaleType.PricingScaleCd;
        emptyPricingScale.QuantityUnitPurposeCd =
          pricingScaleType.QuantityUnitPurposeCd;
        emptyPricingScale.SortOrder = pricingScaleType.SortOrder;

        price.PricingScales.push(emptyPricingScale);
      }

      this.pricesArray.push(price);
    },

    onDeletePrice(idx) {
      this.pricesArray.splice(idx, 1);
    },

    onFocus(element, price) {
      price.PriceControlWithFocus = element;
    },

    onTotalPriceInput(price) {
      if (price.PriceControlWithFocus == "TotalPrice") {
        switch (this.value.PriceTypeCd) {
          case "PrT_I":
            // If price is given Incl GST then need to calculate the price, assume GST is 10%
            price.ExGSTUnitAmt = Number.parseFloat(
              ((price.TotalPrice / 11) * 10).toFixed(2)
            );
            price.GSTUnitAmt = Number.parseFloat(
              (price.TotalPrice / 11).toFixed(2)
            );
            break;
          case "PrT_E":
            // If price is Ex GST then you won't be filling out this field it is calculated
            break;
          case "PrT_F":
            // If GST Free, then ExGSTUnitAmt = price and GST = zero.
            price.ExGSTUnitAmt = price.TotalPrice;
            price.GSTUnitAmt = 0;
            break;
        }
      }
    },

    onGSTPriceInput(price) {
      if (price.PriceControlWithFocus == "GSTPrice") {
        switch (this.value.PriceTypeCd) {
          case "PrT_I":
            // If price is Inc GST then if the GST Amount changes the ExGST will be
            // GSTIncPrice - GSTUnitAmt
            price.ExGSTUnitAmt = price.TotalPrice - price.GSTUnitAmt;
            break;
          case "PrT_E":
            // If price is given Ex GST then need to calculate the GST... assume 10%
            price.TotalPrice = price.ExGSTUnitAmt + price.GSTUnitAmt;
            break;
          case "PrT_F":
            // If GST Free you don't change the GST Amount
            break;
        }
      }
    },

    onExGSTPriceInput(price) {
      if (price.PriceControlWithFocus == "ExGSTPrice") {
        switch (this.value.PriceTypeCd) {
          case "PrT_I":
            // If price is Inc GST then if the Ex GST Amount changes the GST will be
            // GSTIncPrice - ExGSTUnitAmt
            price.GSTUnitAmt = price.TotalPrice - price.ExGSTUnitAmt;
            break;
          case "PrT_E":
            // If price is given Ex GST then need to calculate the GST... assume 10%
            price.GSTUnitAmt = Number(price.ExGSTUnitAmt * 0.1);
            price.TotalPrice = Number(price.ExGSTUnitAmt * 1.1);
            break;
          case "PrT_F":
            // If GST Free you don't change the ExGST Amount
            break;
        }
      }
    },
  },

  mounted() {},
};
</script>
