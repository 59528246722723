var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [_vm._v("Edit Workgroup")]),
    _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1 mb-2" }, [
      _vm.workgroup.Edit == true
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mx-2",
                attrs: { icon: "users", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2 my-0",
                staticStyle: { "min-width": "300px" },
                attrs: {
                  id: "workgroup-name-input",
                  counter: "",
                  counterMaxValue: 50,
                  maxlength: "50",
                  label: "Workgroup name",
                  bg: "",
                },
                model: {
                  value: _vm.workgroupName,
                  callback: function ($$v) {
                    _vm.workgroupName = $$v
                  },
                  expression: "workgroupName",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button", title: "Save" },
                      on: { click: _vm.onSaveWorkgroup },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "check" },
                      }),
                      _vm._v("Save "),
                    ],
                    1
                  ),
                  _vm.status != "add"
                    ? _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button", title: "Cancel" },
                          on: { click: _vm.onCancelWorkgroup },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "times" },
                          }),
                          _vm._v("Cancel "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap flex-grow-1 align-items-center my-2",
            },
            [
              _c("mdb-icon", {
                staticClass: "mx-2",
                attrs: { icon: "users", color: "primary", size: "2x" },
              }),
              _c("span", { staticClass: "font-weight-bolder heading" }, [
                _vm._v(_vm._s(_vm.workgroup.Name)),
              ]),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button", title: "Edit" },
                  on: { click: _vm.onEditWorkgroup },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "edit" },
                  }),
                  _vm._v("Edit "),
                ],
                1
              ),
            ],
            1
          ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap justify-content-start flex-grow-1",
      },
      [
        _vm.showDropDown
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "user", color: "primary", size: "2x" },
                }),
                _c("awgt-std-dropdown", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    label: "Available user",
                    items: _vm.availableUsers,
                    itemValueProperty: "Reference",
                    itemTextProperty: (user) =>
                      user.FirstName + " " + user.Surname,
                    bg: "",
                  },
                  model: {
                    value: _vm.selectedUser,
                    callback: function ($$v) {
                      _vm.selectedUser = $$v
                    },
                    expression: "selectedUser",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                  },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: {
                          type: "button",
                          title: "Save",
                          disabled:
                            this.workgroup.Edit == true ||
                            _vm.selectedUser == null,
                        },
                        on: { click: _vm.onAddUser },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "check" },
                        }),
                        _vm._v("Save "),
                      ],
                      1
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: {
                          type: "button",
                          title: "Cancel",
                          disabled: this.workgroup.Edit == true,
                        },
                        on: { click: _vm.onCancelAddMemebr },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "times" },
                        }),
                        _vm._v("Cancel "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      [
        _c("asoftTreeview", {
          staticClass: "mx-2",
          attrs: {
            value: _vm.treedata,
            childIndent: "20px",
            selectableLeafNodes: true,
            multiselectInd: true,
            childrenPropertyName: "Children",
            isCollapsible: false,
          },
          on: { click: _vm.onClick, dblclick: _vm.onDblClick },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (item) {
                return [
                  _c(
                    "span",
                    { class: [{ selecteditem: item.selected == true }] },
                    [
                      _c("i", { staticClass: "fas fa-male" }),
                      _vm._v(" " + _vm._s(item.Text)),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap justify-content-start flex-grow-1 mt-2",
          },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  id: "add-user",
                  type: "button",
                  title: "Add",
                  disabled:
                    this.workgroup.Reference.length == 0 ||
                    this.workgroup.Edit == true,
                },
                on: { click: _vm.onAddMemebr },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "user-plus" },
                }),
                _vm._v("Add Members "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  type: "button",
                  title: "Associate",
                  disabled:
                    this.associatedNodes.length < 2 ||
                    this.workgroup.Edit == true,
                },
                on: { click: _vm.onAssociateNodes },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "link" },
                }),
                _vm._v("Associate "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  type: "button",
                  title: "Disassociate",
                  disabled:
                    this.associatedNodes.length < 2 ||
                    this.workgroup.Edit == true,
                },
                on: { click: _vm.onDisassociate },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "unlink" },
                }),
                _vm._v("Disassociate "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  type: "button",
                  title: "Delete",
                  disabled:
                    this.currentNode == null ||
                    this.associatedNodes.length == 2 ||
                    this.workgroup.Edit == true,
                },
                on: { click: _vm.onRemoveUser },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "user-minus" },
                }),
                _vm._v("Remove Member "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "my-2 float-right" },
      [
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button", title: "Edit" },
            on: { click: _vm.onClose },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
            _vm._v("Close "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }