import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getClients(
    clientTypes = null,
    reference = null,
    phone = null,
    name = null,
    emailAddress = null,
    matchAllSearchingCriteriaInd = true,
    includeDeletedItemsInd = false
  ) {
    try {
      logger.get(loggingSource.UIClient).info("getClients...");
      logger
        .get(loggingSource.UIClient)
        .debug(
          "Parameters => clientTypes: %s, name: %s, reference: %s, phone: %s, emailAddress: %s, matchAllSearchingCriteriaInd: %s, includeDeletedItemsInd: %s",
          clientTypes,
          reference,
          phone,
          name,
          emailAddress,
          matchAllSearchingCriteriaInd,
          includeDeletedItemsInd
        );

      logger.get(loggingSource.UIClient).time("getClients call");
      const response = await api.client
        .get("client", {
          params: {
            clientTypes: clientTypes,
            reference: reference,
            phone: phone,
            name: name,
            emailAddress: emailAddress,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
            includeDeletedItemsInd: includeDeletedItemsInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIClient).timeEnd("getClients call");
      const clients = response.data;

      logger
        .get(loggingSource.UIClient)
        .trace("getClients response: %s", response);
      logger
        .get(loggingSource.UIClient)
        .debug("getClients clients: %s", clients);

      return clients;
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("getClients call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...getClients");
    }
  },

  async getClientDropdown() {
    try {
      logger.get(loggingSource.UIClient).info("getClientDropdown...");

      logger.get(loggingSource.UIClient).time("getClientDropdown call");
      const response = await api.client
        .get("client/Dropdown", {})
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIClient).timeEnd("getClientDropdown call");
      const clients = response.data;

      logger
        .get(loggingSource.UIClient)
        .trace("getClientDropdown response: %s", response);
      logger
        .get(loggingSource.UIClient)
        .debug("getClientDropdown clients: %s", clients);

      return clients;
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("getClientDropdown call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...getClientDropdown");
    }
  },

  async getClientByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIClient).info("getClientByReference...");

      logger
        .get(loggingSource.UIClient)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger.get(loggingSource.UIClient).time("getClientByReference call");
      const response = await api.client
        .get(`client/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIClient).timeEnd("getClientByReference call");
      const client = response.data;

      logger
        .get(loggingSource.UIClient)
        .trace("getClientByReference response: %s", response);
      logger
        .get(loggingSource.UIClient)
        .debug("getClientByReference client: %s", client);

      return client;
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("getClientByReference call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...getClientByReference");
    }
  },

  async postClient(clientPayload) {
    try {
      logger.get(loggingSource.UIClient).info("postClient...");
      logger
        .get(loggingSource.UIClient)
        .debug("Parameters => clientPayload: %s", clientPayload);

      logger.get(loggingSource.UIClient).time("postClient call");
      const response = await api.client
        .post("client", clientPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIClient).timeEnd("postClient call");
      const client = response.data;

      logger
        .get(loggingSource.UIClient)
        .trace("postClient response: %s", response);
      logger.get(loggingSource.UIClient).debug("postClient client: %s", client);

      return client;
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("postClient call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...postClient");
    }
  },

  async putClient(clientPayload) {
    try {
      logger.get(loggingSource.UIClient).info("putClient...");
      logger
        .get(loggingSource.UIClient)
        .debug("Parameters => clientPayload: %s", clientPayload);

      logger.get(loggingSource.UIClient).time("putClient call");
      const response = await api.client
        .put("client", clientPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIClient).timeEnd("putClient call");
      const client = response.data;

      logger
        .get(loggingSource.UIClient)
        .trace("putClient response: %s", response);
      logger.get(loggingSource.UIClient).debug("putClient client: %s", client);

      return client;
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("putClient call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...putClient");
    }
  },

  async deleteClient(clientPayload) {
    try {
      logger.get(loggingSource.UIClient).info("deleteClient...");
      logger
        .get(loggingSource.UIClient)
        .debug("Parameters => clientPayload: %s", clientPayload);

      logger.get(loggingSource.UIClient).time("deleteClient call");
      await api.client.delete(
        `client/${clientPayload.Reference}?concurrencyRV=${encodeURIComponent(
          clientPayload.ConcurrencyRV
        )}`
      );
      logger.get(loggingSource.UIClient).timeEnd("deleteClient call");
    } catch (error) {
      logger.get(loggingSource.UIClient).timeEnd("deleteClient call");
      logger.get(loggingSource.UIClient).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIClient).info("...deleteClient");
    }
  },
};
