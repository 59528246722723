<template>
  <div
    v-if="
      value != undefined &&
      templateArray.length > 0 &&
      Object.keys(value).length > 0
    "
  >
    <div class="d-flex flex-row flex-nowrap flex-grow-1">
      <template v-for="(template, index) in templateArray">
        <slot
          :template="template"
          :phone="
            items.PhoneNumberStorageTemplate.PhoneNumbers[index]
              ? items.PhoneNumberStorageTemplate.PhoneNumbers[index]
              : { Name: '', Value: '' }
          "
        ></slot>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    template: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      items: {
        PhoneNumberStorageTemplate: {
          PhoneNumbers: [],
          SearchString: "",
        },
      },
      templateArray: [],
    };
  },
  created() {
    if (Object.keys(this.value).length > 0) {
      this.items = this.value;
    }
  },
  watch: {
    "items.PhoneNumberStorageTemplate.PhoneNumbers": {
      deep: true,
      handler: function (to) {
        this.items.PhoneNumberStorageTemplate.SearchString = "";
        for (let phoneEntity of to) {
          this.items.PhoneNumberStorageTemplate.SearchString +=
            phoneEntity.Value + " ";
          this.items.PhoneNumberStorageTemplate.SearchString.trim();
        }
        this.$emit("input", this.items);
      },
    },
    value: {
      deep: true,
      handler: function (to) {
        if (
          Object.keys(to).length == 0 ||
          to.PhoneNumberStorageTemplate.PhoneNumbers.length == 0
        ) {
          this.items = {
            PhoneNumberStorageTemplate: {
              PhoneNumbers: [],
              SearchString: "",
            },
          };
          for (var i = 0; i < this.templateArray.length; i++) {
            this.items.PhoneNumberStorageTemplate.PhoneNumbers.push({
              Name: "",
              Value: "",
            });
          }
          this.$emit("input", this.items);
        } else {
          this.items = to;
        }
      },
    },
    template(to, from) {
      if (to != from && to != null) {
        this.templateArray = to.PhoneNumberCaptureTemplate.Templates;
        for (var i = 0; i < this.templateArray.length; i++) {
          this.items.PhoneNumberStorageTemplate.PhoneNumbers.push({
            Name: "",
            Value: "",
          });
        }
      }
    },
  },
  mounted() {
    // TODO: check if the following  change broke anything
    this.templateArray = this.template.PhoneNumberCaptureTemplate.Templates; // Not sure why it was inside the if statement for PhoneNumbers.length == 0//
    if (
      this.template != null &&
      this.items.PhoneNumberStorageTemplate.PhoneNumbers.length == 0
    ) {
      for (var i = 0; i < this.templateArray.length; i++) {
        this.items.PhoneNumberStorageTemplate.PhoneNumbers.push({
          Name: "",
          Value: "",
        });
      }
    }
  },
};
</script>
