var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible == true
    ? _c(
        "mdb-modal",
        {
          attrs: {
            size: "md",
            show: _vm.dialogVisible,
            id: "itineraryEntryDialog",
          },
        },
        [
          _c(
            "expansion-region",
            { attrs: { toggle: _vm.saveAttempted == true } },
            [
              this.formSubmissionErrors.length > 0
                ? _c("div", { staticClass: "savemessage-error mt-2" }, [
                    _vm._v(" Please correct the following errors: "),
                    _c(
                      "ul",
                      { staticStyle: { margin: "0px" } },
                      _vm._l(_vm.formSubmissionErrors, function (e, idx) {
                        return _c("li", { key: idx }, [_vm._v(_vm._s(e))])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("asoft-modal-header", {
            attrs: { label: "Itinerary Entry Edit" },
            on: { close: _vm.onCancel },
          }),
          _c(
            "mdb-modal-body",
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mt-4 mr-1",
                        staticStyle: { width: "34px", "font-size": "1.5em" },
                        attrs: { icon: "sitemap", color: "primary" },
                      }),
                      _c("awgt-std-dropdown", {
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "180px" },
                        attrs: {
                          items: _vm.getItineraryEntryTypes(false),
                          itemValueProperty: "Code",
                          itemTextProperty: "Name",
                          label: "Entry Type",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.ItineraryEntryType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "ItineraryEntryType",
                              $$v
                            )
                          },
                          expression: "itineraryEntry.ItineraryEntryType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mt-4 mx-1",
                        staticStyle: { width: "34px", "font-size": "1.5em" },
                        attrs: { icon: "sitemap", color: "primary" },
                      }),
                      _c("awgt-std-dropdown", {
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "180px" },
                        attrs: {
                          items:
                            _vm.getItineraryEntrySubTypesByItineraryEntryType(
                              _vm.itineraryEntry.ItineraryEntryType
                            ),
                          itemValueProperty: "Code",
                          itemTextProperty: "Name",
                          label: "Entry Subtype",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.ItineraryEntrySubType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "ItineraryEntrySubType",
                              $$v
                            )
                          },
                          expression: "itineraryEntry.ItineraryEntrySubType",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mt-4 mr-1",
                    staticStyle: { width: "34px", "font-size": "1.5em" },
                    attrs: { icon: "ball-pile", color: "primary" },
                  }),
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      items: _vm.primaryOrderProducts,
                      itemValueProperty: "Reference",
                      itemTextProperty: (p) =>
                        p.ProviderSupplierName +
                        " - " +
                        p.Name +
                        " (" +
                        p.Reference +
                        ")",
                      label: "Primary Product",
                      bg: "",
                    },
                    model: {
                      value: _vm.itineraryEntry.PrimaryProductReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.itineraryEntry,
                          "PrimaryProductReference",
                          $$v
                        )
                      },
                      expression: "itineraryEntry.PrimaryProductReference",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mt-3 mr-1",
                    staticStyle: { width: "34px" },
                    attrs: {
                      icon: "edit",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      counter: "",
                      counterMaxValue: 210,
                      maxlength: "210",
                      label: "Title",
                      bg: "",
                      type: "text",
                    },
                    model: {
                      value: _vm.itineraryEntry.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.itineraryEntry, "Name", $$v)
                      },
                      expression: "itineraryEntry.Name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" Product Lines: "),
              _vm.currentPrimaryProduct != null
                ? _c(
                    "div",
                    _vm._l(
                      _vm.arrayUnique(
                        _vm.itineraryEntry.OrderProductLines,
                        "OrderReference"
                      ),
                      function (pb, index2) {
                        return _c("div", { key: index2 }, [
                          _vm._v(" " + _vm._s(pb.OrderReference) + " "),
                          _c(
                            "table",
                            [
                              _c("tr", [_c("th", [_vm._v("Name")])]),
                              _vm._l(
                                _vm.itineraryEntry.OrderProductLines.filter(
                                  (pl) => pl.OrderReference == pb.OrderReference
                                ),
                                function (opl, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(opl.Name))]),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mt-4 mr-1",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "hashtag",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("awgt-input", {
                        directives: [
                          {
                            name: "numeric",
                            rawName: "v-numeric",
                            value: {
                              precision: 3,
                              scale: 0,
                              posOnly: true,
                              trailingZeroScale: 0,
                            },
                            expression:
                              "{\n            precision: 3,\n            scale: 0,\n            posOnly: true,\n            trailingZeroScale: 0,\n          }",
                          },
                        ],
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "186px" },
                        attrs: {
                          counter: "",
                          counterMaxValue: 3,
                          maxlength: "3",
                          label:
                            (_vm.itineraryEntry.BookingType == "BT_S"
                              ? "Student"
                              : "Child") + " Participant Count",
                          bg: "",
                          type: "text",
                        },
                        model: {
                          value: _vm.itineraryEntry.ChildParticipantCount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "ChildParticipantCount",
                              _vm._n($$v)
                            )
                          },
                          expression: "itineraryEntry.ChildParticipantCount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mt-4 mr-1",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "hashtag",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("awgt-input", {
                        directives: [
                          {
                            name: "numeric",
                            rawName: "v-numeric",
                            value: {
                              precision: 3,
                              scale: 0,
                              posOnly: true,
                              trailingZeroScale: 0,
                            },
                            expression:
                              "{\n            precision: 3,\n            scale: 0,\n            posOnly: true,\n            trailingZeroScale: 0,\n          }",
                          },
                        ],
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "186px" },
                        attrs: {
                          counter: "",
                          counterMaxValue: 3,
                          maxlength: "3",
                          label: "Adult Participant Count",
                          bg: "",
                          type: "text",
                        },
                        model: {
                          value: _vm.itineraryEntry.AdultParticipantCount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "AdultParticipantCount",
                              _vm._n($$v)
                            )
                          },
                          expression: "itineraryEntry.AdultParticipantCount",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                  [
                    _c("mdb-icon", {
                      staticClass: "mt-4 mr-1",
                      staticStyle: { width: "34px", "font-size": "1.6em" },
                      attrs: { icon: "users", color: "primary" },
                    }),
                    _c("awgt-input", {
                      staticClass: "flex-grow-1",
                      staticStyle: { width: "186px" },
                      attrs: {
                        counter: "",
                        counterMaxValue: 20,
                        maxlength: "20",
                        label: "Group Name",
                        bg: "",
                      },
                      model: {
                        value: _vm.itineraryEntry.GroupName,
                        callback: function ($$v) {
                          _vm.$set(_vm.itineraryEntry, "GroupName", $$v)
                        },
                        expression: "itineraryEntry.GroupName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1 mt-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mt-3 mr-1",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "calendar-alt",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("asoft-date-picker", {
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "180px" },
                        attrs: {
                          label: "From Date",
                          autoHide: "",
                          displayDateFormat: "D MMM YYYY",
                          dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                          setTime: "00:00:00.000",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.StartDT,
                          callback: function ($$v) {
                            _vm.$set(_vm.itineraryEntry, "StartDT", $$v)
                          },
                          expression: "itineraryEntry.StartDT",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-1 mt-3",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "clock",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _vm.displayAvailableTimesSelect
                        ? _c("awgt-std-dropdown", {
                            staticClass: "flex-grow-1 mt-0",
                            staticStyle: { width: "180px" },
                            attrs: {
                              items: _vm.mapAvailableTimesArray(
                                _vm.currentPrimaryProduct.AvailableTimes
                              ),
                              itemValueProperty: "key",
                              itemTextProperty: "value",
                              label: "From Time",
                              bg: "",
                            },
                            model: {
                              value: _vm.startTime,
                              callback: function ($$v) {
                                _vm.startTime = $$v
                              },
                              expression: "startTime",
                            },
                          })
                        : _c("asoft-time-picker", {
                            staticClass: "flex-grow-1 my-0",
                            staticStyle: { width: "180px" },
                            attrs: {
                              label: "From Time",
                              bg: "",
                              displayFormat: "h:mma",
                              outputFormat: "HH:mm",
                            },
                            model: {
                              value: _vm.startTime,
                              callback: function ($$v) {
                                _vm.startTime = $$v
                              },
                              expression: "startTime",
                            },
                          }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1 mt-3",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "calendar-alt",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("asoft-date-picker", {
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "180px" },
                        attrs: {
                          label: "To Date",
                          autoHide: "",
                          displayDateFormat: "D MMM YYYY",
                          dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.EndDT,
                          callback: function ($$v) {
                            _vm.$set(_vm.itineraryEntry, "EndDT", $$v)
                          },
                          expression: "itineraryEntry.EndDT",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-1 mt-3",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "clock",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("asoft-time-picker", {
                        staticClass: "flex-grow-1 mt-0",
                        staticStyle: { width: "180px" },
                        attrs: {
                          label: "To Time",
                          bg: "",
                          displayFormat: "h:mma",
                          outputFormat: "HH:mm",
                        },
                        model: {
                          value: _vm.endTime,
                          callback: function ($$v) {
                            _vm.endTime = $$v
                          },
                          expression: "endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("awgt-input", {
                staticClass: "mt-1 mb-3",
                staticStyle: { "margin-left": "25px" },
                attrs: {
                  type: "checkbox",
                  id: "isIndicativeTime",
                  name: "isIndicativeTime",
                  label: "Indicative time?",
                },
                model: {
                  value: _vm.itineraryEntry.IsIndicativeTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.itineraryEntry, "IsIndicativeTime", $$v)
                  },
                  expression: "itineraryEntry.IsIndicativeTime",
                },
              }),
              _vm.itineraryEntry.ItineraryEntryType == "IET_Ay"
                ? _c("awgt-input", {
                    staticClass: "mt-1 mb-3",
                    staticStyle: { "margin-left": "25px" },
                    attrs: {
                      type: "checkbox",
                      id: "RestrictToProductTimes",
                      name: "RestrictToProductTimesInd",
                      label: "Restrict to product times?",
                    },
                    model: {
                      value: _vm.itineraryEntry.RestrictToProductTimes,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.itineraryEntry,
                          "RestrictToProductTimes",
                          $$v
                        )
                      },
                      expression: "itineraryEntry.RestrictToProductTimes",
                    },
                  })
                : _vm._e(),
              _c("awgt-input", {
                staticClass: "mt-1 mb-3",
                staticStyle: { "margin-left": "25px" },
                attrs: {
                  type: "checkbox",
                  id: "DisplayBoundariesOnly",
                  name: "DisplayBoundariesOnly",
                  label: "Display boundaries only?",
                },
                model: {
                  value: _vm.itineraryEntry.DisplayBoundariesOnly,
                  callback: function ($$v) {
                    _vm.$set(_vm.itineraryEntry, "DisplayBoundariesOnly", $$v)
                  },
                  expression: "itineraryEntry.DisplayBoundariesOnly",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1 mt-3",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "clock",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("awgt-std-dropdown", {
                        ref: "PreEntryMarginInMinutes",
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "186px" },
                        attrs: {
                          items: _vm.getProductMargins,
                          itemValueProperty: "productMarginInMinutes",
                          itemTextProperty: "name",
                          label: "Pre Event Margin",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.PreEntryMarginInMinutes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "PreEntryMarginInMinutes",
                              $$v
                            )
                          },
                          expression: "itineraryEntry.PreEntryMarginInMinutes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-1 mt-3",
                        staticStyle: { width: "34px" },
                        attrs: {
                          icon: "clock",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("awgt-std-dropdown", {
                        ref: "PostEntryMarginInMinutes",
                        staticClass: "flex-grow-1",
                        staticStyle: { width: "186px" },
                        attrs: {
                          items: _vm.getProductMargins,
                          itemValueProperty: "productMarginInMinutes",
                          itemTextProperty: "name",
                          label: "Post Event Margin",
                          bg: "",
                        },
                        model: {
                          value: _vm.itineraryEntry.PostEntryMarginInMinutes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.itineraryEntry,
                              "PostEntryMarginInMinutes",
                              $$v
                            )
                          },
                          expression: "itineraryEntry.PostEntryMarginInMinutes",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("awgt-input", {
                    staticClass: "mt-1 mb-3",
                    staticStyle: { "margin-left": "25px" },
                    attrs: {
                      type: "checkbox",
                      id: "showPreSupplierItineraryComment",
                      name: "showPreSupplierItineraryComment",
                      label: "Show pre-supplier itinerary comment?",
                    },
                    model: {
                      value: _vm.itineraryEntry.ShowPreSupplierItineraryComment,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.itineraryEntry,
                          "ShowPreSupplierItineraryComment",
                          $$v
                        )
                      },
                      expression:
                        "itineraryEntry.ShowPreSupplierItineraryComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("awgt-input", {
                    staticClass: "mt-1 mb-3",
                    staticStyle: { "margin-left": "25px" },
                    attrs: {
                      type: "checkbox",
                      id: "showPostSupplierItineraryComment",
                      name: "showPostSupplierItineraryComment",
                      label: "Show post-supplier itinerary comment?",
                    },
                    model: {
                      value:
                        _vm.itineraryEntry.ShowPostSupplierItineraryComment,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.itineraryEntry,
                          "ShowPostSupplierItineraryComment",
                          $$v
                        )
                      },
                      expression:
                        "itineraryEntry.ShowPostSupplierItineraryComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mt-3 mr-1",
                    staticStyle: { width: "34px" },
                    attrs: {
                      icon: "file-alt",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      counter: "",
                      counterMaxValue: 1000,
                      maxlength: "1000",
                      label: "Description",
                      bg: "",
                      type: "text",
                    },
                    model: {
                      value: _vm.itineraryEntry.Description,
                      callback: function ($$v) {
                        _vm.$set(_vm.itineraryEntry, "Description", $$v)
                      },
                      expression: "itineraryEntry.Description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-2",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-2 mb-2",
                    attrs: {
                      icon: "clipboard",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("div", [_vm._v("Agency Notes")]),
                ],
                1
              ),
              _c("mdb-wysiwyg", {
                staticStyle: { height: "200px" },
                model: {
                  value: _vm.itineraryEntry.Notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.itineraryEntry, "Notes", $$v)
                  },
                  expression: "itineraryEntry.Notes",
                },
              }),
            ],
            1
          ),
          _c("mdb-modal-footer", [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    attrs: { type: "button" },
                    on: { click: _vm.onDelete },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "trash-alt" },
                    }),
                    _vm._v("Delete "),
                  ],
                  1
                ),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    attrs: { type: "button" },
                    on: { click: _vm.onSave },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "check" },
                    }),
                    _vm._v("Save "),
                  ],
                  1
                ),
                _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    attrs: { type: "button" },
                    on: { click: _vm.onCancel },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "times" },
                    }),
                    _vm._v("Cancel "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }