// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"awgtStdButtonBackgroundColor": "secondary",
	"awgtStdButtonTextColor": "#eeeeee",
	"awgtStdDropdownHeaderBackgroundColor": "#eeeeee",
	"awgtStdDropdownHeaderSelectedTextColor": "#425664",
	"awgtStdDropdownHeaderLabelColor": "#425664",
	"awgtStdDropdownListBackgroundColor": "#eeeeee",
	"awgtStdDropdownListTextColor": "primary",
	"awgtStdDropdownSelectorBackgroundColor": "primary",
	"awgtStdDropdownTooltipIconColor": "#425664",
	"awgtStdDropdownActiveColor": "#BE7746",
	"awgtInputLabelColor": "secondary",
	"awgtInputActiveColor": "#BE7746",
	"awgtExpansionRegionHeaderBackgroundColor": "grey lighten-3",
	"awgtExpansionRegionHeaderTextColor": "#425664",
	"awgtExpansionRegionBodyColor": "#fafafa",
	"awgtExpansionRegionBodyTextColor": "#425664",
	"awgtExpansionRegionArrowColor": "primary",
	"awgtMenuBarBackgroundColor": "secondary",
	"awgtMenuLinkTextColor": "#f6f4f2",
	"awgtMenuDropdownTextColor": "#425664",
	"awgtMenuDropdownListColor": "#fafafa",
	"awgtDurationPickerBackgroundColor": "\"#f5f5f5\"",
	"awgtDurationPickerLabelColor": "#425664",
	"awgtDurationPickerActiveLabelColor": "#BE7746",
	"awgtDurationPickerActiveColor": "#BE7746",
	"awgtBoxDisplayTitleColor": "#BE7746",
	"awgtBoxDisplayIconColor": "primary",
	"awgtBoxDisplayBorderColor": "#425664",
	"awgtBoxDisplayContentColor": "black",
	"awgtProductViewHeadingColour": "#BE7746",
	"awgtFooterBackgroundColor": "secondary-color"
};
module.exports = exports;
