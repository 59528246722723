import { render, staticRenderFns } from "./ProductSearch.vue?vue&type=template&id=4e04dff7&scoped=true"
import script from "./ProductSearch.vue?vue&type=script&lang=js"
export * from "./ProductSearch.vue?vue&type=script&lang=js"
import style0 from "@/styles/common.scss?vue&type=style&index=0&lang=scss&external"
import style1 from "./ProductSearch.vue?vue&type=style&index=1&id=4e04dff7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e04dff7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workarea\\Atom Software\\Clients\\Away We Go Tours\\src\\awgt-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e04dff7')) {
      api.createRecord('4e04dff7', component.options)
    } else {
      api.reload('4e04dff7', component.options)
    }
    module.hot.accept("./ProductSearch.vue?vue&type=template&id=4e04dff7&scoped=true", function () {
      api.rerender('4e04dff7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProductSearch.vue"
export default component.exports