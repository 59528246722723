<template>
  <mdb-container fluid>
    <h1 class="text-center">Itinerary Entries Report</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        multiple
        selectAll
        label="Workgroup"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        s
        itemValueProperty="Code"
        :items="workgroupsList"
        itemTextProperty="Name"
        v-model="workgroups"
        bg
        search
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        label="Consultants"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="consultantsList"
        itemTextProperty="Name"
        v-model="consultants"
        bg
        search
        @focus="onFocusConsultantDropdown"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        label="Type"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getProductTypes(false)"
        itemTextProperty="Name"
        v-model="productTypes"
        bg
        search
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-std-dropdown
        label="Year"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getYears(2017)"
        itemTextProperty="Name"
        v-model="year"
        bg
      ></awgt-std-dropdown>
      <mdb-form-inline class="flex-wrap mx-2 px-3" style="height: 45px">
        <span class="mr-3" style="width: 120px">Time Scope</span>
        <awgt-input
          id="timeScope_Term"
          v-model="timeScope"
          type="radio"
          name="TimeScopeTerm"
          label="Term"
          radioValue="Term"
          class="mr-3"
        />
        <awgt-input
          id="timeScope_Date"
          v-model="timeScope"
          type="radio"
          name="TimeScopeDate"
          label="Date"
          radioValue="Date"
          class="mr-3"
        />
      </mdb-form-inline>
      <awgt-std-dropdown
        v-if="timeScope == 'Term'"
        multiple
        selectAll
        label="Term"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getSchoolTerms(false)"
        itemTextProperty="Name"
        v-model="terms"
        bg
      ></awgt-std-dropdown>
      <div
        v-if="timeScope == 'Date'"
        class="d-flex flex-row flex-wrap flex-grow-1"
      >
        <div class="d-flex flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mx-2"
            size="2x"
          />
          <asoftDatePicker
            v-model="startDate"
            label="Start"
            autoHide
            :dataDateFormat="dataDateFormat"
            :displayDateFormat="displayDateFormat"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 110px"
            @change="(fromDt) => onFromDtChange(fromDt)"
          ></asoftDatePicker>
        </div>
        <div class="d-flex flex-nowrap flex-grow-1 align-items-center">
          <mdb-icon
            icon="calendar-alt"
            far
            class="mx-2"
            color="primary"
            size="2x"
          />
          <asoftDatePicker
            v-model="toDate"
            label="End"
            autoHide
            :dataDateFormat="dataDateFormat"
            :displayDateFormat="displayDateFormat"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 110px"
          ></asoftDatePicker>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        multiple
        selectAll
        label="Supplier"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="suppliersList"
        itemTextProperty="Name"
        v-model="suppliers"
        bg
        search
      ></awgt-std-dropdown>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          v-if="bookingTables.length > 0"
          type="button"
          class="command-button mx-2 float-right"
          @click="onExport"
        >
          <mdb-icon icon="file-pdf" class="mr-1" />Export
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearGenerateCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onGenerate"
        >
          <mdb-icon icon="search" class="mr-1" />Generate
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div v-if="bookingTables.length == 0 && isGenerated" class="text-center">
      <i>No itinerary entries result found</i>
    </div>
    <div id="tableContent">
      <div
        v-if="bookingTables.length > 0"
        class="mx-2"
        style="border: 1px solid black; padding: 10px 10px"
      >
        <div>
          <h4>Itinerary Entries</h4>
        </div>

        <div>
          <template v-if="workgroupsCache.length > 0">
            <span class="mx-3">{{
              workgroupsCache
                .map((w) => {
                  return getWorkgroupName(w);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template v-if="consultantsCache.length > 0">
            <span class="mx-3">{{
              consultantsCache
                .map((c) => {
                  return getConsultantName(c);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template v-if="suppliersCache.length > 0">
            <span class="mx-3">{{
              suppliersCache
                .map((s) => {
                  return getSupplierName(s);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template v-if="yearCache != null || yearCache != ''">
            <span class="mx-3">{{ yearCache }}</span
            ><br
          /></template>
          <template v-if="timeScopeCache == 'Term' && termsCache.length > 0">
            <span class="mx-3">{{
              termsCache
                .filter((t) => {
                  return t != "ST_X";
                })
                .map((t) => {
                  return getTermName(t);
                })
                .join(", ")
            }}</span
            ><br
          /></template>
          <template
            v-if="
              timeScopeCache == 'Date' &&
              (startDateCache != null || toDateCache != null)
            "
          >
            <span class="mx-3"
              >{{
                startDateCache != null
                  ? $moment(startDateCache).format("D MMM yyyy") + " to"
                  : ""
              }}
              {{
                toDateCache != null
                  ? $moment(toDateCache).format("D MMM yyyy")
                  : ""
              }}</span
            ><br
          /></template>
        </div>

        <template v-for="(groupData, indOfTable) in bookingTables">
          <div
            v-bind:key="indOfTable"
            style="margin: 25px 0px"
            class="html2pdf__page-break"
          >
            <div class="mt-2">
              <span class="mx-3" style="color: #923f82; font-weight: bold">{{
                getConsultantName(groupData.OwnerUser)
              }}</span>
            </div>
            <table class="table" :ref="'table' + indOfTable">
              <col span="1" style="width: 15%" />

              <tr style="font-weight: 10">
                <td
                  class="headers-frame-header"
                  @click="onSortByClientName(groupData, indOfTable)"
                >
                  Client<mdb-icon
                    v-if="sortByClientInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByClientInd[indOfTable] == false ||
                      !sortByClientInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByTBID(groupData, indOfTable)"
                >
                  TB ID<mdb-icon
                    v-if="sortByTBIDInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByTBIDInd[indOfTable] == false ||
                      !sortByTBIDInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortClientAddress(groupData, indOfTable)"
                >
                  Client Address<mdb-icon
                    v-if="sortByClientAddressInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByClientAddressInd == false ||
                      !sortByClientAddressInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByProduct(groupData, indOfTable)"
                >
                  Product<mdb-icon
                    v-if="sortByProductInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByProductInd[indOfTable] == false ||
                      !sortByProductInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByStatus(groupData, indOfTable)"
                >
                  Status<mdb-icon
                    v-if="sortByStatusInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByStatusInd[indOfTable] == false ||
                      !sortByStatusInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByGroupName(groupData, indOfTable)"
                >
                  Group Name<mdb-icon
                    v-if="sortByGroupNameInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByGroupNameInd[indOfTable] == false ||
                      !sortByGroupNameInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByStartDate(groupData, indOfTable)"
                >
                  Start Date<mdb-icon
                    v-if="sortByStartDateInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByStartDateInd[indOfTable] == false ||
                      !sortByStartDateInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByYearGroup(groupData, indOfTable)"
                >
                  Year Group<mdb-icon
                    v-if="sortByYearGroupInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByYearGroupInd[indOfTable] == false ||
                      !sortByYearGroupInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByStudentCount(groupData, indOfTable)"
                >
                  Student No.<mdb-icon
                    v-if="sortByStudentCountInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByStudentCountInd[indOfTable] == false ||
                      !sortByStudentCountInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByAdultCount(groupData, indOfTable)"
                >
                  Adult No.<mdb-icon
                    v-if="sortByAdultCountInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByAdultCountInd[indOfTable] == false ||
                      !sortByAdultCountInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
                <td
                  class="headers-frame-header"
                  @click="onSortByOrganizingTeacher(groupData, indOfTable)"
                >
                  Organizing Teacher.<mdb-icon
                    v-if="sortByOrganizingTeacherInd[indOfTable] == true"
                    icon="caret-down"
                  /><mdb-icon
                    v-if="
                      sortByOrganizingTeacherInd[indOfTable] == false ||
                      !sortByOrganizingTeacherInd[indOfTable]
                    "
                    icon="caret-up"
                  />
                </td>
              </tr>
              <template v-for="(row, indOfRow) in groupData.Bookings">
                <tr v-bind:key="groupData.OwnerUser + indOfRow">
                  <td>
                    {{ row.Client.DisplayName }}
                  </td>
                  <td>{{ row.Reference }}</td>
                  <td>
                    {{
                      row.Client.Address
                        ? getAddress(row.Client.Address)
                        : " - "
                    }}
                  </td>
                  <td>{{ row.ProductName }}</td>
                  <td>
                    {{ getBookingStatusName(row.BookingStatus) }}
                  </td>
                  <td>
                    {{ row.GroupName }}
                  </td>
                  <td>
                    {{ $moment(row.Date).format("ddd DD MMM yyyy hh:mm A") }}
                  </td>
                  <td>
                    {{ row.YearGroups }}
                  </td>
                  <td>
                    {{ row.StudentsCount }}
                  </td>
                  <td>
                    {{ row.AdultsCount }}
                  </td>
                  <td>
                    {{
                      row.BookingContacts ? row.BookingContacts[0].Name : " - "
                    }}
                  </td>
                </tr>
              </template>
              <tr class="total-row">
                <td>Total</td>
                <td colspan="10">
                  {{ groupData.Bookings.length }}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </div>
    </div>
  </mdb-container>
</template>
<script>
import { mdbContainer, mdbFormInline, mdbIcon } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import workgroupApi from "@/api/workgroupApi.js";
import userApi from "@/api/userApi.js";
import supplierApi from "@/api/supplierApi.js";
import reportApi from "@/api/reportApi.js";
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton.vue";

export default {
  data() {
    return {
      dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
      displayDateFormat: "D MMM YYYY",
      workgroups: [],
      workgroupsCache: [],
      consultants: [],
      consultantsCache: [],
      productTypes: [],
      productTypesCache: [],
      suppliers: [],
      suppliersCache: [],
      workgroupsList: [],
      consultantsList: [],
      consultantsListCache: [],
      suppliersList: [],
      timeScope: "Term",
      timeScopeCache: "",
      year: "",
      yearCache: "",
      terms: [],
      termsCache: [],
      startDate: null,
      startDateCache: null,
      toDate: null,
      toDateCache: null,
      bookingTables: [],
      sortByClientInd: [],
      sortByTBIDInd: [],
      sortByClientAddressInd: [],
      sortByProductInd: [],
      sortByStatusInd: [],
      sortByGroupNameInd: [],
      sortByStartDateInd: [],
      sortByYearGroupInd: [],
      sortByStudentCountInd: [],
      sortByAdultCountInd: [],
      sortByOrganizingTeacherInd: [],
      isGenerated: false,
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
    mdbFormInline,
    awgtInput,
    asoftDatePicker,
    mdbIcon,
    AwgtStdButton,
  },
  computed: {
    ...mapGetters(["getProductTypes", "getSchoolTerms", "getBookingStatuses"]),
    getYears() {
      return function (startYear) {
        const year = new Date().getFullYear();
        let yearsList = Array.from(
          { length: year - startYear + 1 },
          (v, i) => startYear + i
        ).map((item) => {
          return {
            Name: item,
            Code: item,
          };
        });
        yearsList.unshift({ Name: "", Code: "" });
        return yearsList;
      };
    },
    getWorkgroupName() {
      return function (reference) {
        return this.workgroupsList.find((w) => {
          return w.Code == reference;
        })?.Name;
      };
    },
    getConsultantName() {
      return function (reference) {
        if (this.consultantsListCache.length == 0) return "";
        else
          return this.consultantsListCache.find((c) => {
            return c.Code == reference;
          }).Name;
      };
    },
    getSupplierName() {
      return function (reference) {
        return this.suppliersList.find((w) => {
          return w.Reference == reference;
        })?.Name;
      };
    },
    getBookingStatusName() {
      return function (reference) {
        return this.getBookingStatuses(false).find((bs) => {
          return bs.Code == reference;
        }).Name;
      };
    },
    getAddress() {
      return function (address) {
        return (
          (address.Street.length > 0 ? address.Street + ", " : "") +
          (address.Locality.length > 0 ? address.Locality + ", " : "") +
          (address.Subdivision != null ? address.Subdivision + ", " : "") +
          (address.Postcode.length > 0 ? address.Postcode + ", " : "") +
          (address.Country.length > 0 ? address.Country : "")
        );
      };
    },
  },
  methods: {
    onFromDtChange(fromDt) {
      if (this.toDate == null) this.toDate = fromDt;
    },
    onSortByClientName(groupData, index) {
      this.sortByClientInd[index] = !this.sortByClientInd[index];
      if (this.sortByClientInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "Client");
      else this.onSortSupplierProductBookingInReverseOrder(groupData, "Client");
    },
    onSortByTBID(groupData, index) {
      this.sortByTBIDInd[index] = !this.sortByTBIDInd[index];
      if (this.sortByTBIDInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "Reference");
      else
        this.onSortSupplierProductBookingInReverseOrder(groupData, "Reference");
    },
    onSortClientAddress(groupData, index) {
      this.sortByClientAddressInd[index] = !this.sortByClientAddressInd[index];
      if (this.sortByClientAddressInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "Address");
      else
        this.onSortSupplierProductBookingInReverseOrder(groupData, "Address");
    },
    onSortByProduct(groupData, index) {
      this.sortByProductInd[index] = !this.sortByProductInd[index];
      if (this.sortByProductInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "ProductName");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "ProductName"
        );
    },
    onSortByStatus(groupData, index) {
      this.sortByStatusInd[index] = !this.sortByStatusInd[index];
      if (this.sortByStatusInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "BookingStatus");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "BookingStatus"
        );
    },
    onSortByGroupName(groupData, index) {
      this.sortByGroupNameInd[index] = !this.sortByGroupNameInd[index];
      if (this.sortByGroupNameInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "GroupName");
      else
        this.onSortSupplierProductBookingInReverseOrder(groupData, "GroupName");
    },
    onSortByStartDate(groupData, index) {
      this.sortByStartDateInd[index] = !this.sortByStartDateInd[index];
      if (this.sortByStartDateInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "Date");
      else this.onSortSupplierProductBookingInReverseOrder(groupData, "Date");
    },
    onSortByYearGroup(groupData, index) {
      this.sortByYearGroupInd[index] = !this.sortByYearGroupInd[index];
      if (this.sortByYearGroupInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "YearGroups");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "YearGroups"
        );
    },
    onSortByStudentCount(groupData, index) {
      this.sortByStudentCountInd[index] = !this.sortByStudentCountInd[index];
      if (this.sortByStudentCountInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "StudentsCount");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "StudentsCount"
        );
    },
    onSortByAdultCount(groupData, index) {
      this.sortByAdultCountInd[index] = !this.sortByAdultCountInd[index];
      if (this.sortByAdultCountInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "AdultsCount");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "AdultsCount"
        );
    },
    onSortByOrganizingTeacher(groupData, index) {
      this.sortByOrganizingTeacherInd[index] =
        !this.sortByOrganizingTeacherInd[index];
      if (this.sortByOrganizingTeacherInd[index] == true)
        this.onSortSupplierProductBookingInOrder(groupData, "BookingContacts");
      else
        this.onSortSupplierProductBookingInReverseOrder(
          groupData,
          "BookingContacts"
        );
    },
    onSortSupplierProductBookingInOrder(groupData, attribute) {
      //When the field is empty, it is set to 'z' because z is the last alphabet.
      if (
        attribute == "BookingContacts" ||
        attribute == "Address" ||
        attribute == "Client"
      ) {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          if (attribute == "BookingContacts") {
            if (!a[attribute]) nameA = "z";
            else nameA = a[attribute][0]?.Name;
            if (!b[attribute]) nameB = "z";
            else nameB = b[attribute][0]?.Name;
          } else if (attribute == "Address") {
            if (!a.Client[attribute]) nameA = "z";
            else {
              nameA =
                (a.Client[attribute].Street.length > 0
                  ? a.Client[attribute].Street + ", "
                  : "") +
                (a.Client[attribute].Locality.length > 0
                  ? a.Client[attribute].Locality + ", "
                  : "") +
                (a.Client[attribute].Subdivision != null
                  ? a.Client[attribute].Subdivision + ", "
                  : "") +
                (a.Client[attribute].Postcode.length > 0
                  ? a.Client[attribute].Postcode + ", "
                  : "") +
                (a.Client[attribute].Country.length > 0
                  ? a.Client[attribute].Country
                  : "");
            }
            if (!b.Client[attribute]) nameB = "z";
            else {
              nameB =
                (b.Client[attribute].Street.length > 0
                  ? b.Client[attribute].Street + ", "
                  : "") +
                (b.Client[attribute].Locality.length > 0
                  ? b.Client[attribute].Locality + ", "
                  : "") +
                (b.Client[attribute].Subdivision != null
                  ? b.Client[attribute].Subdivision + ", "
                  : "") +
                (b.Client[attribute].Postcode.length > 0
                  ? b.Client[attribute].Postcode + ", "
                  : "") +
                (b.Client[attribute].Country.length > 0
                  ? b.Client[attribute].Country
                  : "");
            }
          } else {
            if (!a[attribute]) nameA = "z";
            else nameA = a[attribute]?.DisplayName;
            if (!b[attribute]) nameB = "z";
            else nameB = b[attribute]?.DisplayName;
          }

          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      } else if (attribute == "Date") {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          nameA = new Date(a[attribute]);
          nameB = new Date(b[attribute]);
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      } else {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          nameA = a[attribute];
          nameB = b[attribute];
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
      }
    },
    onSortSupplierProductBookingInReverseOrder(groupData, attribute) {
      //When the field is empty, it is set to 'z' because z is the last alphabet.
      if (
        attribute == "BookingContacts" ||
        attribute == "Address" ||
        attribute == "Client"
      ) {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;
          if (attribute == "BookingContacts") {
            if (!a[attribute]) nameA = "z";
            else nameA = a[attribute][0]?.Name;
            if (!b[attribute]) nameB = "z";
            else nameB = b[attribute][0]?.Name;
          } else if (attribute == "Address") {
            if (!a.Client[attribute]) nameA = "z";
            else {
              nameA =
                (a.Client[attribute].Street.length > 0
                  ? a.Client[attribute].Street + ", "
                  : "") +
                (a.Client[attribute].Locality.length > 0
                  ? a.Client[attribute].Locality + ", "
                  : "") +
                (a.Client[attribute].Subdivision != null
                  ? a.Client[attribute].Subdivision + ", "
                  : "") +
                (a.Client[attribute].Postcode.length > 0
                  ? a.Client[attribute].Postcode + ", "
                  : "") +
                (a.Client[attribute].Country.length > 0
                  ? a.Client[attribute].Country
                  : "");
            }
            if (!b.Client[attribute]) nameB = "z";
            else {
              nameB =
                (b.Client[attribute].Street.length > 0
                  ? b.Client[attribute].Street + ", "
                  : "") +
                (b.Client[attribute].Locality.length > 0
                  ? b.Client[attribute].Locality + ", "
                  : "") +
                (b.Client[attribute].Subdivision != null
                  ? b.Client[attribute].Subdivision + ", "
                  : "") +
                (b.Client[attribute].Postcode.length > 0
                  ? b.Client[attribute].Postcode + ", "
                  : "") +
                (b.Client[attribute].Country.length > 0
                  ? b.Client[attribute].Country
                  : "");
            }
          } else {
            if (!a[attribute]) nameA = "z";
            else nameA = a[attribute]?.DisplayName;
            if (!b[attribute]) nameB = "z";
            else nameB = b[attribute]?.DisplayName;
          }

          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      } else if (attribute == "Date") {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          nameA = new Date(a[attribute]);
          nameB = new Date(b[attribute]);
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      } else {
        groupData.Bookings.sort(function (a, b) {
          var nameA = null;
          var nameB = null;

          nameA = a[attribute];
          nameB = b[attribute];
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          return 0;
        });
      }
    },
    async onGenerate() {
      let travelBookings;
      try {
        travelBookings = await reportApi.getItineraryEntriesReport(
          this.workgroups.join(),
          this.consultants.join(),
          this.productTypes.join(),
          this.suppliers.join(),
          this.year,
          this.terms.join(),
          this.startDate,
          this.toDate
        );
      } catch (err) {
        // Errors have been logged
      }

      /*If consultantsList is empty array, we still need to pull back user becasue we have a owner user display.
      The owner user is reference so we should get user name from consultant list. */
      if (this.consultantsList.length == 0) {
        this.consultantsList = await userApi.getUserDropdown();
      }

      this.workgroupsCache = this.$lodash.cloneDeep(this.workgroups);
      this.consultantsCache = this.$lodash.cloneDeep(this.consultants);
      this.productTypesCache = this.$lodash.cloneDeep(this.productTypes);
      this.suppliersCache = this.$lodash.cloneDeep(this.suppliers);
      this.yearCache = this.$lodash.clone(this.year);
      this.timeScopeCache = this.$lodash.cloneDeep(this.timeScope);
      this.termsCache = this.$lodash.cloneDeep(this.terms);
      this.startDateCache = this.$lodash.cloneDeep(this.startDate);
      this.toDateCache = this.$lodash.cloneDeep(this.toDate);
      this.consultantsListCache = this.$lodash.cloneDeep(this.consultantsList);

      this.bookingTables = this.arrayGroupBy(
        travelBookings,
        "OwnerUserReference"
      );

      this.isGenerated = true;
    },

    onClearGenerateCriteria() {
      this.workgroups = [];
      this.consultants = [];
      this.productTypes = [];
      this.timeScope = "Term";
      this.year = "";
      this.terms = [];
      this.suppliers = [];
      this.startDate = null;
      this.toDate = null;
    },

    onExport() {
      const options = {
        margin: 0.5,
        filename: "Itinerary_Entries_Report",
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: {
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      };

      let content = document.getElementById("tableContent");
      let Node = content.cloneNode(true);
      Node.children[0].style["border"] = "none";
      html2pdf()
        .set(options)
        .from(Node)
        .save()
        .then(() => {
          self.$emit("header-message", {
            isSuccessInd: true,
            Message: ["The PDF has been generated successfully."],
          });
        });
    },
    arrayGroupBy(data, groupingProp) {
      const map = new Map();
      data.forEach((entry) => {
        const groupKey = entry[groupingProp];
        const list = map.get(groupKey);
        if (list == null) {
          map.set(groupKey, {
            OwnerUser: entry.OwnerUserReference,
            Bookings: [entry],
          });
        } else {
          list.Bookings.push(entry);
        }
      });

      return [...map.values()];
    },

    async onFocusConsultantDropdown() {
      if (this.workgroups.length === 0) this.consultantsList = [];
      else
        this.consultantsList = await userApi.getUserDropdown(
          this.workgroups.join()
        );
    },

    async loadFormData() {
      this.workgroupsList = await workgroupApi.getWorkgroupDropdown();
      this.suppliersList = await supplierApi.getSupplierDropdown(); //Pull back Code and name Properties only.
    },
  },
  mounted() {
    this.loadFormData().catch(() => {
      // Errros have been logged
    });
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  margin-top: 10px;
}

table,
th,
td {
  table-layout: fixed;
  border: 2px solid darkgray;
  padding: 5px 5px !important;
  word-wrap: break-word;
  font-size: 12px;
}

.total-row {
  background-color: #f7ad7c;
  border-top: 3px solid black;
}

.headers-frame-header {
  color: purple;
}

.headers-frame-header:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
