var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Product Edit")]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("div", [
            _vm.product.Reference != ""
              ? _c("h4", { staticClass: "mx-2" }, [
                  _vm._v(" Reference: " + _vm._s(_vm.product.Reference) + " "),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c("awgt-std-dropdown", {
                staticClass: "mx-2",
                staticStyle: { "max-width": "110px" },
                attrs: {
                  items: _vm.getEditStatuses(false),
                  itemValueProperty: "Code",
                  itemTextProperty: "Name",
                  label: "Edit Status",
                  bg: "",
                },
                model: {
                  value: _vm.product.EditStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "EditStatus", $$v)
                  },
                  expression: "product.EditStatus",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Product Type")]),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
        [
          _c("span", { staticClass: "mr-5", staticStyle: { width: "120px" } }, [
            _vm._v("Product Type"),
          ]),
          _c("awgt-input", {
            staticClass: "mr-5",
            attrs: {
              id: "productCategory_Transport",
              type: "radio",
              name: "ProductTypeGroup",
              label: "Transport",
              radioValue: "PT_Tr",
            },
            model: {
              value: _vm.product.ProductType,
              callback: function ($$v) {
                _vm.$set(_vm.product, "ProductType", $$v)
              },
              expression: "product.ProductType",
            },
          }),
          _c("awgt-input", {
            staticClass: "mr-5",
            attrs: {
              id: "productCategory_Accommodation",
              type: "radio",
              name: "ProductTypeGroup",
              label: "Accommodation",
              radioValue: "PT_An",
            },
            model: {
              value: _vm.product.ProductType,
              callback: function ($$v) {
                _vm.$set(_vm.product, "ProductType", $$v)
              },
              expression: "product.ProductType",
            },
          }),
          _c("awgt-input", {
            staticClass: "mr-5",
            attrs: {
              id: "productCategory_Activity",
              type: "radio",
              name: "ProductTypeGroup",
              label: "Activity",
              radioValue: "PT_Ay",
            },
            model: {
              value: _vm.product.ProductType,
              callback: function ($$v) {
                _vm.$set(_vm.product, "ProductType", $$v)
              },
              expression: "product.ProductType",
            },
          }),
          _c("awgt-input", {
            staticClass: "mr-5",
            attrs: {
              id: "productCategory_Merchandise",
              type: "radio",
              name: "ProductTypeGroup",
              label: "Merchandise",
              radioValue: "PT_Me",
            },
            model: {
              value: _vm.product.ProductType,
              callback: function ($$v) {
                _vm.$set(_vm.product, "ProductType", $$v)
              },
              expression: "product.ProductType",
            },
          }),
          _c("awgt-input", {
            attrs: {
              id: "productCategory_Bundle",
              type: "radio",
              name: "ProductTypeGroup",
              label: "Bundle",
              radioValue: "PT_Bn",
              tooltip: _vm.getTooltipsTextByCode(32),
            },
            model: {
              value: _vm.product.ProductType,
              callback: function ($$v) {
                _vm.$set(_vm.product, "ProductType", $$v)
              },
              expression: "product.ProductType",
            },
          }),
        ],
        1
      ),
      _vm.product.ProductType === "PT_Tr"
        ? _c(
            "mdb-form-inline",
            { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
            [
              _c(
                "span",
                { staticClass: "mr-5", staticStyle: { width: "120px" } },
                [_vm._v("Product Subtype")]
              ),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_Coach_Charter",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Coach Charter",
                  radioValue: "PST_TrCC",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_Flight",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Flight",
                  radioValue: "PST_TrFl",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_bus",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Bus",
                  radioValue: "PST_TrBu",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_Train",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Train",
                  radioValue: "PST_TrTr",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_Ferry",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Ferry",
                  radioValue: "PST_TrFe",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_Tram",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Tram",
                  radioValue: "PST_TrTm",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                attrs: {
                  id: "productSubcategory_Car",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Car",
                  radioValue: "PST_TrCr",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductType === "PT_Ay"
        ? _c(
            "mdb-form-inline",
            { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
            [
              _c(
                "span",
                { staticClass: "mr-5", staticStyle: { width: "120px" } },
                [_vm._v("Product Subtype")]
              ),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_SupplierActivity",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Supplier Activity",
                  radioValue: "PST_AyAy",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-5",
                attrs: {
                  id: "productSubcategory_FillInActivity",
                  type: "radio",
                  name: "ProductSubtypeGroup",
                  label: "Fill-In Activity",
                  radioValue: "PST_AyFI",
                },
                model: {
                  value: _vm.product.ProductSubType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ProductSubType", $$v)
                  },
                  expression: "product.ProductSubType",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductSubType != "PST_AyFI"
        ? [
            _c("div", { staticClass: "section-break" }),
            _c("h4", { staticClass: "mx-2" }, [_vm._v("Supplier Detail")]),
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              attrs: {
                items: _vm.supplierDropDown,
                itemValueProperty: "Reference",
                itemTextProperty: "Name",
                label: "Provider Name",
                bg: "",
                search: "",
                tooltip: _vm.getTooltipsTextByCode(30),
              },
              model: {
                value: _vm.product.ProviderSupplier.Reference,
                callback: function ($$v) {
                  _vm.$set(_vm.product.ProviderSupplier, "Reference", $$v)
                },
                expression: "product.ProviderSupplier.Reference",
              },
            }),
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              attrs: {
                items: _vm.supplierDropDown,
                itemValueProperty: "Reference",
                itemTextProperty: "Name",
                label: "Reseller Name",
                bg: "",
                search: "",
                tooltip: _vm.getTooltipsTextByCode(31),
              },
              model: {
                value: _vm.product.ResellerSupplier.Reference,
                callback: function ($$v) {
                  _vm.$set(_vm.product.ResellerSupplier, "Reference", $$v)
                },
                expression: "product.ResellerSupplier.Reference",
              },
            }),
          ]
        : _vm._e(),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Product Detail")]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 mx-2 align-items-center",
        },
        [
          _c("mdb-icon", {
            staticStyle: { width: "40px", "text-align": "center" },
            attrs: {
              icon: _vm.getProductIcon(
                _vm.product.ProductType,
                _vm.product.ProductSubType
              ),
              size: "2x",
              color: "primary",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 ml-2",
            staticStyle: { "min-width": "240px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              maxlength: "100",
              label: "Product Name",
              bg: "",
            },
            model: {
              value: _vm.product.Name,
              callback: function ($$v) {
                _vm.$set(_vm.product, "Name", $$v)
              },
              expression: "product.Name",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 display-name-margin",
            staticStyle: { "min-width": "280px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              maxlength: "100",
              label: "Display Name",
              bg: "",
              tooltip: _vm.getTooltipsTextByCode(12),
            },
            model: {
              value: _vm.product.DisplayName,
              callback: function ($$v) {
                _vm.$set(_vm.product, "DisplayName", $$v)
              },
              expression: "product.DisplayName",
            },
          }),
        ],
        1
      ),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
        [
          _c("span", { staticClass: "mr-5", staticStyle: { width: "120px" } }, [
            _vm._v("Sold with"),
          ]),
          _c("awgt-input", {
            staticClass: "mr-5",
            attrs: {
              id: "soldWithGroup_SeparateSale",
              type: "radio",
              name: "SoldWithGroup",
              label: "Can be sold separately",
              radioValue: true,
            },
            model: {
              value: _vm.product.CanBeSoldSeparatelyInd,
              callback: function ($$v) {
                _vm.$set(_vm.product, "CanBeSoldSeparatelyInd", $$v)
              },
              expression: "product.CanBeSoldSeparatelyInd",
            },
          }),
          _c("awgt-input", {
            attrs: {
              id: "soldWithGroup_RelatedProducts",
              type: "radio",
              name: "SoldWithGroup",
              label: "Can only be sold as a related product",
              radioValue: false,
              tooltip: _vm.getTooltipsTextByCode(33),
            },
            model: {
              value: _vm.product.CanBeSoldSeparatelyInd,
              callback: function ($$v) {
                _vm.$set(_vm.product, "CanBeSoldSeparatelyInd", $$v)
              },
              expression: "product.CanBeSoldSeparatelyInd",
            },
          }),
        ],
        1
      ),
      _vm.product.ProductType == "PT_Tr"
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  far: "",
                  icon: "car-bus",
                  size: "2x",
                  color: "primary",
                },
              }),
              _c("awgt-input", {
                directives: [
                  {
                    name: "numeric",
                    rawName: "v-numeric",
                    value: {
                      precision: 4,
                      scale: 0,
                      posOnly: true,
                      allowEmptyString: true,
                    },
                    expression:
                      "{\n        precision: 4,\n        scale: 0,\n        posOnly: true,\n        allowEmptyString: true,\n      }",
                  },
                ],
                staticClass: "flex-grow-1 ml-2",
                attrs: {
                  maxlength: "4",
                  label: "Minimum Seats",
                  bg: "",
                  type: "text",
                },
                model: {
                  value: _vm.product.MinSeatsNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "MinSeatsNum", _vm._n($$v))
                  },
                  expression: "product.MinSeatsNum",
                },
              }),
              _c("mdb-icon", {
                staticClass: "ml-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  far: "",
                  icon: "car-bus",
                  size: "2x",
                  color: "primary",
                },
              }),
              _c("awgt-input", {
                directives: [
                  {
                    name: "numeric",
                    rawName: "v-numeric",
                    value: {
                      precision: 4,
                      scale: 0,
                      posOnly: true,
                      allowEmptyString: true,
                    },
                    expression:
                      "{\n        precision: 4,\n        scale: 0,\n        posOnly: true,\n        allowEmptyString: true,\n      }",
                  },
                ],
                staticClass: "flex-grow-1 ml-2",
                attrs: {
                  maxlength: "4",
                  label: "Maximum Seats",
                  bg: "",
                  type: "text",
                },
                model: {
                  value: _vm.product.MaxSeatsNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "MaxSeatsNum", _vm._n($$v))
                  },
                  expression: "product.MaxSeatsNum",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductType == "PT_An"
        ? _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: { icon: "bed-bunk", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 4,
                        scale: 0,
                        posOnly: true,
                        allowEmptyString: true,
                      },
                      expression:
                        "{\n          precision: 4,\n          scale: 0,\n          posOnly: true,\n          allowEmptyString: true,\n        }",
                    },
                  ],
                  staticClass: "flex-grow-1 ml-2",
                  attrs: {
                    maxlength: "4",
                    label: "Minimum Beds",
                    bg: "",
                    type: "text",
                  },
                  model: {
                    value: _vm.product.MinBedsNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "MinBedsNum", _vm._n($$v))
                    },
                    expression: "product.MinBedsNum",
                  },
                }),
                _c("mdb-icon", {
                  staticClass: "ml-2",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: { icon: "bed-bunk", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 4,
                        scale: 0,
                        posOnly: true,
                        allowEmptyString: true,
                      },
                      expression:
                        "{\n          precision: 4,\n          scale: 0,\n          posOnly: true,\n          allowEmptyString: true,\n        }",
                    },
                  ],
                  staticClass: "flex-grow-1 ml-2",
                  attrs: {
                    maxlength: "4",
                    label: "Maximum Beds",
                    bg: "",
                    type: "text",
                  },
                  model: {
                    value: _vm.product.MaxBedsNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "MaxBedsNum", _vm._n($$v))
                    },
                    expression: "product.MaxBedsNum",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _vm.product.ProductType == "PT_An" ||
          _vm.product.ProductType == "PT_Ay"
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                },
                [
                  _c("mdb-icon", {
                    staticStyle: { width: "40px", "text-align": "center" },
                    attrs: {
                      icon: "users-class",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("awgt-std-dropdown", {
                    staticClass: "flex-grow-1 ml-2",
                    attrs: {
                      itemValueProperty: "Code",
                      items: _vm.getYearGroups,
                      itemTextProperty: "Name",
                      label: "Year Groups",
                      bg: "",
                      maxSelectedOptions: 13,
                      multiple: "",
                      selectAll: "",
                    },
                    model: {
                      value: _vm.yearGroups,
                      callback: function ($$v) {
                        _vm.yearGroups = $$v
                      },
                      expression: "yearGroups",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "clock", far: "", color: "primary", size: "2x" },
              }),
              _c("awgt-std-dropdown", {
                ref: "preProductMargins",
                staticClass: "flex-grow-1",
                staticStyle: { "min-width": "240px" },
                attrs: {
                  items: _vm.getProductMargins,
                  itemValueProperty: "productMarginInMinutes",
                  itemTextProperty: "name",
                  label: "Pre-product Margin",
                  bg: "",
                  tooltip: _vm.getTooltipsTextByCode(34),
                },
                model: {
                  value: _vm.product.PreProductMarginInMinutes,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "PreProductMarginInMinutes", $$v)
                  },
                  expression: "product.PreProductMarginInMinutes",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-2",
                staticStyle: { width: "40px" },
                attrs: { icon: "clock", far: "", color: "primary", size: "2x" },
              }),
              _c("awgt-std-dropdown", {
                ref: "postProductMargins",
                staticClass: "flex-grow-1",
                staticStyle: { "min-width": "240px" },
                attrs: {
                  items: _vm.getProductMargins,
                  itemValueProperty: "productMarginInMinutes",
                  itemTextProperty: "name",
                  label: "Post-product Margin",
                  bg: "",
                  tooltip: _vm.getTooltipsTextByCode(35),
                },
                model: {
                  value: _vm.product.PostProductMarginInMinutes,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "PostProductMarginInMinutes", $$v)
                  },
                  expression: "product.PostProductMarginInMinutes",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.product.ProductType == "PT_Ay"
        ? _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-2",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: { icon: "hashtag", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 4,
                        scale: 0,
                        posOnly: true,
                        allowEmptyString: true,
                      },
                      expression:
                        "{\n          precision: 4,\n          scale: 0,\n          posOnly: true,\n          allowEmptyString: true,\n        }",
                    },
                  ],
                  staticClass: "flex-grow-1",
                  staticStyle: { "min-width": "240px" },
                  attrs: {
                    label: "Min participants per group",
                    bg: "",
                    type: "text",
                  },
                  model: {
                    value: _vm.product.MinParticipantsPerGroup,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.product,
                        "MinParticipantsPerGroup",
                        _vm._n($$v)
                      )
                    },
                    expression: "product.MinParticipantsPerGroup",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1 mx-2" },
              [
                _c("mdb-icon", {
                  staticClass: "mr-2 mt-4",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: { icon: "hashtag", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 4,
                        scale: 0,
                        posOnly: true,
                        allowEmptyString: true,
                      },
                      expression:
                        "{\n          precision: 4,\n          scale: 0,\n          posOnly: true,\n          allowEmptyString: true,\n        }",
                    },
                  ],
                  staticClass: "flex-grow-1",
                  staticStyle: { "min-width": "240px" },
                  attrs: {
                    label: "Max participants per group",
                    bg: "",
                    type: "text",
                    tooltip: _vm.getTooltipsTextByCode(36),
                  },
                  model: {
                    value: _vm.product.MaxParticipantsPerGroup,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.product,
                        "MaxParticipantsPerGroup",
                        _vm._n($$v)
                      )
                    },
                    expression: "product.MaxParticipantsPerGroup",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-2",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: { icon: "hashtag", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 4,
                        scale: 0,
                        posOnly: true,
                        allowEmptyString: true,
                      },
                      expression:
                        "{\n          precision: 4,\n          scale: 0,\n          posOnly: true,\n          allowEmptyString: true,\n        }",
                    },
                  ],
                  staticClass: "flex-grow-1",
                  staticStyle: { "min-width": "240px" },
                  attrs: {
                    label: "Max participants per timeslot",
                    bg: "",
                    type: "text",
                    tooltip: _vm.getTooltipsTextByCode(37),
                  },
                  model: {
                    value: _vm.product.MaxParticipantsPerTimeslot,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.product,
                        "MaxParticipantsPerTimeslot",
                        _vm._n($$v)
                      )
                    },
                    expression: "product.MaxParticipantsPerTimeslot",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
        },
        [
          _c("mdb-icon", {
            staticClass: "pl-2",
            staticStyle: { width: "40px", "text-align": "center" },
            attrs: { icon: "info", color: "primary", size: "2x" },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            attrs: {
              counter: "",
              counterMaxValue: 2048,
              maxlength: "2048",
              label: "Information URL",
              bg: "",
            },
            model: {
              value: _vm.product.InformationURL,
              callback: function ($$v) {
                _vm.$set(_vm.product, "InformationURL", $$v)
              },
              expression: "product.InformationURL",
            },
          }),
          _c(
            "awgt-std-button",
            {
              staticClass: "button pt-2 command-button",
              attrs: {
                type: "button",
                tag: "a",
                href: _vm.product.InformationURL,
                target: "_blank",
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "link" } }),
              _vm._v("Test "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.product.CanBeSoldSeparatelyInd == true
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap mx-2 my-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "shopping-cart", color: "primary", size: "2x" },
              }),
              _c(
                "span",
                {
                  staticClass: "mr-5 mx-2",
                  staticStyle: { "min-width": "40px" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.product.ProductType == "PT_Me"
                        ? "Ordering"
                        : "Booking"
                    ) + " Method"
                  ),
                ]
              ),
              _c("awgt-input", {
                attrs: {
                  id: "bookingMethod_Phone",
                  type: "radio",
                  name: "BookingMethodGroup",
                  label: "Phone",
                  radioValue: "BM_P",
                },
                model: {
                  value: _vm.product.BookingMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingMethod", $$v)
                  },
                  expression: "product.BookingMethod",
                },
              }),
              _c("awgt-input", {
                attrs: {
                  id: "bookingMethod_Email",
                  type: "radio",
                  name: "BookingMethodGroup",
                  label: "Email",
                  radioValue: "BM_E",
                },
                model: {
                  value: _vm.product.BookingMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingMethod", $$v)
                  },
                  expression: "product.BookingMethod",
                },
              }),
              _c("awgt-input", {
                attrs: {
                  id: "bookingMethod_URL",
                  type: "radio",
                  name: "BookingMethodGroup",
                  label: "URL",
                  radioValue: "BM_U",
                },
                model: {
                  value: _vm.product.BookingMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingMethod", $$v)
                  },
                  expression: "product.BookingMethod",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-3",
                attrs: {
                  id: "bookingMethod_No",
                  type: "radio",
                  name: "BookingMethodGroup",
                  label: "Not Required",
                  radioValue: "BM_N",
                },
                model: {
                  value: _vm.product.BookingMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingMethod", $$v)
                  },
                  expression: "product.BookingMethod",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.CanBeSoldSeparatelyInd == true
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "shopping-cart", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                attrs: {
                  counter: "",
                  counterMaxValue: 2048,
                  maxlength: "2048",
                  label:
                    (_vm.product.ProductType == "PT_Me"
                      ? "Ordering"
                      : "Booking") + "/Availability URL",
                  bg: "",
                },
                model: {
                  value: _vm.product.BookingURL,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingURL", $$v)
                  },
                  expression: "product.BookingURL",
                },
              }),
              _c(
                "awgt-std-button",
                {
                  staticClass: "button pt-2 command-button",
                  attrs: {
                    type: "button",
                    tag: "a",
                    href: _vm.product.BookingURL,
                    target: "_blank",
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "link" },
                  }),
                  _vm._v("Test "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductType == "PT_Ay"
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-2",
                    staticStyle: { width: "40px", "text-align": "center" },
                    attrs: {
                      icon: "clock",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("autogrow-textarea", {
                    staticClass: "flex-grow-1 mr-2",
                    attrs: {
                      label: "Start Times",
                      type: "textarea",
                      rows: 1,
                      value: _vm.availableTimesForDisplay,
                      readonly: "",
                      bg: "",
                    },
                  }),
                  _c(
                    "awgt-std-button",
                    {
                      staticClass: "mr-0 command-button",
                      attrs: { type: "button" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.startTimesDialogVisible = true
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "clock" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("time-entry", {
                attrs: {
                  dialogVisible: _vm.startTimesDialogVisible,
                  title: "Start times",
                },
                on: { close: _vm.onTimeEntryClose },
                model: {
                  value: _vm.product.AvailableTimes,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "AvailableTimes", $$v)
                  },
                  expression: "product.AvailableTimes",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
        _vm.product.ProductType == "PT_Ay"
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1 mx-2" },
              [
                _c("mdb-icon", {
                  staticClass: "mt-4 mr-2",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: {
                    icon: "clock",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("asoft-duration-picker", {
                  staticClass: "flex-grow-1 mr-2",
                  staticStyle: { "margin-top": "13px", "min-width": "180px" },
                  attrs: {
                    hourSelection: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    minuteSelection: [
                      0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                    ],
                    label: "Min. Duration",
                  },
                  model: {
                    value: _vm.product.MinDurationInMinutes,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "MinDurationInMinutes", _vm._n($$v))
                    },
                    expression: "product.MinDurationInMinutes",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.product.ProductType == "PT_Ay"
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1 mx-2" },
              [
                _c("mdb-icon", {
                  staticClass: "mt-4 mr-2",
                  staticStyle: { width: "40px", "text-align": "center" },
                  attrs: {
                    icon: "clock",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("asoft-duration-picker", {
                  staticClass: "flex-grow-1 mr-2",
                  staticStyle: { "margin-top": "13px", "min-width": "180px" },
                  attrs: {
                    hourSelection: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    minuteSelection: [
                      0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                    ],
                    label: "Max. Duration",
                  },
                  model: {
                    value: _vm.product.MaxDurationInMinutes,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "MaxDurationInMinutes", _vm._n($$v))
                    },
                    expression: "product.MaxDurationInMinutes",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.product.CanBeSoldSeparatelyInd == true
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                  },
                  [
                    _c("awgt-std-dropdown", {
                      ref: "depositTypesSelect",
                      staticClass: "mr-3",
                      staticStyle: { width: "200px" },
                      attrs: {
                        items: _vm.getDepositTypes(false),
                        itemValueProperty: "Code",
                        itemTextProperty: "Name",
                        label: "Deposit Type",
                        bg: "",
                      },
                      model: {
                        value: _vm.product.DepositType,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "DepositType", $$v)
                        },
                        expression: "product.DepositType",
                      },
                    }),
                    _c("mdb-icon", {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        icon:
                          _vm.product.DepositType == "DT_No"
                            ? ""
                            : _vm.product.DepositType == "DT_Pe"
                            ? "percentage"
                            : "dollar-sign",
                        size: "2x",
                      },
                    }),
                    _c("awgt-input", {
                      directives: [
                        {
                          name: "numeric",
                          rawName: "v-numeric",
                          value: {
                            precision:
                              _vm.product.DepositType == "DT_Pe" ? 2 : 6,
                            scale: _vm.product.DepositType == "DT_Pe" ? 0 : 2,
                            posOnly: true,
                          },
                          expression:
                            "{\n            precision: product.DepositType == 'DT_Pe' ? 2 : 6,\n            scale: product.DepositType == 'DT_Pe' ? 0 : 2,\n            posOnly: true,\n          }",
                        },
                      ],
                      staticClass: "flex-grow-1",
                      attrs: {
                        maxlength: "7",
                        label: "Amount",
                        disabled: _vm.product.DepositType == "DT_No",
                        bg: "",
                        type: "text",
                      },
                      model: {
                        value: _vm.product.DepositAmt,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "DepositAmt", _vm._n($$v))
                        },
                        expression: "product.DepositAmt",
                      },
                    }),
                  ],
                  1
                ),
                _c("asoft-FOC-picker", {
                  model: {
                    value: _vm.product.FreeOfChargeRatio,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "FreeOfChargeRatio", $$v)
                    },
                    expression: "product.FreeOfChargeRatio",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap mx-2" },
          [
            _c("mdb-icon", {
              staticClass: "mr-2 mt-4",
              staticStyle: { width: "40px", "text-align": "center" },
              attrs: {
                icon: "calendar",
                color: "primary",
                size: "2x",
                far: "",
              },
            }),
            _c("awgt-std-dropdown", {
              attrs: {
                itemValueProperty: "Code",
                items: _vm.getProductAvailability,
                itemTextProperty: "Name",
                label: "Product Availability",
                bg: "",
                maxSelectedOptions: 7,
                multiple: "",
                selectAll: "",
              },
              model: {
                value: _vm.productAvailability,
                callback: function ($$v) {
                  _vm.productAvailability = $$v
                },
                expression: "productAvailability",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Surcharges & Discounts")]),
      _c(
        "div",
        [
          _c("div", { staticClass: "ml-2" }, [
            _vm._v(
              " The surcharges and discounts recorded below are used to pre-populate the surcharges and discounts for new product lines. "
            ),
          ]),
          _c("product-price-adjustments", {
            staticClass: "mx-2 my-2",
            staticStyle: { position: "relative", top: "-40px" },
            model: {
              value: _vm.product.PriceAdjustments,
              callback: function ($$v) {
                _vm.$set(_vm.product, "PriceAdjustments", $$v)
              },
              expression: "product.PriceAdjustments",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Pricing")]),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("commercial-product-line-list", {
            staticClass: "ml-2",
            attrs: { priceAdjustments: _vm.product.PriceAdjustments },
            model: {
              value: _vm.product.CommercialProductLines,
              callback: function ($$v) {
                _vm.$set(_vm.product, "CommercialProductLines", $$v)
              },
              expression: "product.CommercialProductLines",
            },
          }),
        ],
        1
      ),
      _vm.product.CanBeSoldSeparatelyInd == true
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-row flex-wrap mx-2 align-items-center",
            },
            [
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  far: "",
                  color: "primary",
                  icon: "comment-alt-dollar",
                  size: "2x",
                },
              }),
              _c(
                "span",
                { staticClass: "mr-3", staticStyle: { "min-width": "60px" } },
                [_vm._v("Payment Types")]
              ),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "50px" },
                attrs: {
                  id: "paymentType_PrePaid",
                  type: "checkbox",
                  name: "paymentType",
                  label: "Pre-Paid",
                },
                model: {
                  value: _vm.paymentType_PrePaid,
                  callback: function ($$v) {
                    _vm.paymentType_PrePaid = $$v
                  },
                  expression: "paymentType_PrePaid",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "50px" },
                attrs: {
                  id: "paymentType_PurchaseOrder",
                  type: "checkbox",
                  name: "paymentType",
                  label: "Purchase Order",
                },
                model: {
                  value: _vm.paymentType_PurchaseOrder,
                  callback: function ($$v) {
                    _vm.paymentType_PurchaseOrder = $$v
                  },
                  expression: "paymentType_PurchaseOrder",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "50px" },
                attrs: {
                  id: "paymentType_Voucher",
                  type: "checkbox",
                  name: "paymentType",
                  label: "Voucher",
                },
                model: {
                  value: _vm.paymentType_Voucher,
                  callback: function ($$v) {
                    _vm.paymentType_Voucher = $$v
                  },
                  expression: "paymentType_Voucher",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "product-groups",
        {
          staticClass: "mx-2 mt-4",
          attrs: { parentProduct: _vm.product },
          model: {
            value: _vm.product.ResellerSupplierProductGroups,
            callback: function ($$v) {
              _vm.$set(_vm.product, "ResellerSupplierProductGroups", $$v)
            },
            expression: "product.ResellerSupplierProductGroups",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
            },
            [
              _c("h4", [_vm._v("Reseller Product Groups")]),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(38) },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.product.CanBeSoldSeparatelyInd == true
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-4",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mb-2",
                    staticStyle: { width: "40px", "text-align": "center" },
                    attrs: {
                      icon: "clipboard",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("div", [_vm._v("Booking Alerts")]),
                  _c("tdoxTooltip", {
                    staticClass: "mb-1",
                    attrs: { detail: _vm.getTooltipsTextByCode(53) },
                  }),
                ],
                1
              ),
              _c("mdb-wysiwyg", {
                staticClass: "mx-2",
                staticStyle: { height: "200px" },
                model: {
                  value: _vm.product.BookingFeeDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "BookingFeeDetail", $$v)
                  },
                  expression: "product.BookingFeeDetail",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductType != "PT_Me"
        ? _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap flex-grow-1 mr-4" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-3",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "user", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                attrs: {
                  counterMaxValue: 85,
                  maxlength: "85",
                  counter: "",
                  label: "Contact Name",
                  bg: "",
                },
                model: {
                  value: _vm.product.ContactPersonNm,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "ContactPersonNm", $$v)
                  },
                  expression: "product.ContactPersonNm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.product.ProductType == "PT_Ay" ||
                _vm.product.ProductType == "PT_An",
              expression:
                "product.ProductType == 'PT_Ay' || product.ProductType == 'PT_An'",
            },
          ],
        },
        [
          _c("div", { staticClass: "section-break" }),
          _c("h4", { staticClass: "mx-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.product.ProductType == "PT_Ay"
                    ? "Activity Location"
                    : "Accommodation Location"
                ) +
                " "
            ),
          ]),
          _c("asoftAddressesCapture", {
            attrs: { template: _vm.getSystemTemplateFromCode("STT_C_AP") },
            model: {
              value: _vm.product.Addresses,
              callback: function ($$v) {
                _vm.$set(_vm.product, "Addresses", $$v)
              },
              expression: "product.Addresses",
            },
          }),
        ],
        1
      ),
      _vm.product.ProductType != "PT_Me"
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2",
            },
            [
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "phone", color: "primary", size: "2x" },
              }),
              _c("asoft-phone-input", {
                staticClass: "mx-2",
                staticStyle: { width: "180px" },
                attrs: {
                  id: "client-phone",
                  maxlength: "20",
                  label: "Phone",
                  bg: "",
                },
                model: {
                  value: _vm.product.Phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "Phone", $$v)
                  },
                  expression: "product.Phone",
                },
              }),
              _c("mdb-icon", {
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: { icon: "at", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1",
                staticStyle: { "min-width": "120px" },
                attrs: {
                  counterMaxValue: 254,
                  maxlength: "254",
                  counter: "",
                  label: "Email",
                  type: "email",
                  bg: "",
                },
                model: {
                  value: _vm.product.EmailAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "EmailAddress", $$v)
                  },
                  expression: "product.EmailAddress",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.product.ProductType == "PT_Tr"
        ? _c(
            "div",
            [
              _c("span", { staticClass: "ml-2" }, [
                _vm._v("Transport Origins"),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-2",
                    staticStyle: { width: "40px", "text-align": "center" },
                    attrs: {
                      icon: "globe-asia",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("autogrow-textarea", {
                    staticClass: "flex-grow-1 mr-2",
                    attrs: {
                      label: "Origins",
                      type: "textarea",
                      value: _vm.selectedTransportOriginsLabel,
                      rows: 1,
                      readonly: "",
                      bg: "",
                    },
                  }),
                  _c(
                    "awgt-std-button",
                    {
                      staticClass: "mr-0 command-button",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showRegionEntry = true
                        },
                      },
                    },
                    [_vm._v(" Edit ")]
                  ),
                ],
                1
              ),
              _c("region-entry", {
                attrs: {
                  transportOrigins: _vm.transportationOrigins[0].Children,
                  productOrigins: _vm.product.TransportationOrigins,
                  visible: _vm.showRegionEntry,
                },
                on: { "close-region-modal": _vm.handleClosingRegionModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "section-break" }),
      _c(
        "div",
        {
          staticClass: "mx-2 clickable-item",
          on: {
            click: function ($event) {
              return _vm.onToggleProductDescriptions()
            },
          },
        },
        [
          _c("h4", { staticClass: "align-items-center" }, [
            _vm._v(" Product Descriptions "),
            _c(
              "span",
              [
                _vm.productDescriptionsExpanded == true
                  ? _c("mdb-icon", {
                      staticClass: "pl-2 pt-2",
                      attrs: { icon: "angle-up", size: "1x", color: "primary" },
                    })
                  : _c("mdb-icon", {
                      staticClass: "pl-2 pt-2",
                      attrs: {
                        icon: "angle-down",
                        size: "1x",
                        color: "primary",
                      },
                    }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "expansion-region",
        { attrs: { toggle: _vm.productDescriptionsExpanded } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2",
            },
            [
              _c("mdb-icon", {
                staticClass: "mb-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  icon: "clipboard",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("div", [_vm._v("Product Description")]),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(39) },
              }),
            ],
            1
          ),
          _c("mdb-wysiwyg", {
            staticClass: "mx-2",
            staticStyle: { height: "200px" },
            model: {
              value: _vm.product.Description,
              callback: function ($$v) {
                _vm.$set(_vm.product, "Description", $$v)
              },
              expression: "product.Description",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2",
            },
            [
              _c("mdb-icon", {
                staticClass: "mb-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  icon: "clipboard",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("div", [_vm._v("Product Comment")]),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(40) },
              }),
            ],
            1
          ),
          _c("mdb-wysiwyg", {
            staticClass: "mx-2",
            staticStyle: { height: "200px" },
            model: {
              value: _vm.product.SpecialComment,
              callback: function ($$v) {
                _vm.$set(_vm.product, "SpecialComment", $$v)
              },
              expression: "product.SpecialComment",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2",
            },
            [
              _c("mdb-icon", {
                staticClass: "mb-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  icon: "clipboard",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("div", [_vm._v("Pre-product Itinerary Details")]),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(41) },
              }),
            ],
            1
          ),
          _c("mdb-wysiwyg", {
            staticClass: "mx-2",
            staticStyle: { height: "200px" },
            model: {
              value: _vm.product.PreProductItineraryComment,
              callback: function ($$v) {
                _vm.$set(_vm.product, "PreProductItineraryComment", $$v)
              },
              expression: "product.PreProductItineraryComment",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2",
            },
            [
              _c("mdb-icon", {
                staticClass: "mb-2",
                staticStyle: { width: "40px", "text-align": "center" },
                attrs: {
                  icon: "clipboard",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("div", [_vm._v("Post-product Itinerary Details")]),
              _c("tdoxTooltip", {
                staticClass: "mb-1",
                attrs: { detail: _vm.getTooltipsTextByCode(42) },
              }),
            ],
            1
          ),
          _c("mdb-wysiwyg", {
            staticClass: "mx-2",
            staticStyle: { height: "200px" },
            model: {
              value: _vm.product.PostProductItineraryComment,
              callback: function ($$v) {
                _vm.$set(_vm.product, "PostProductItineraryComment", $$v)
              },
              expression: "product.PostProductItineraryComment",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-4",
        },
        [
          _c("mdb-icon", {
            staticClass: "mb-2",
            staticStyle: { width: "40px", "text-align": "center" },
            attrs: { icon: "clipboard", far: "", color: "primary", size: "2x" },
          }),
          _c("div", [_vm._v("Agency Notes")]),
          _c("tdoxTooltip", {
            staticClass: "mb-1",
            attrs: { detail: _vm.getTooltipsTextByCode(43) },
          }),
        ],
        1
      ),
      _c("mdb-wysiwyg", {
        staticClass: "mx-2",
        staticStyle: { height: "200px" },
        model: {
          value: _vm.product.AWGTNotes,
          callback: function ($$v) {
            _vm.$set(_vm.product, "AWGTNotes", $$v)
          },
          expression: "product.AWGTNotes",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-3 mx-2" },
        [
          _c(
            "itemised-notes",
            {
              attrs: {
                noteTitleProperty: "Subject",
                noteDetailProperty: "Note",
                noteType: "NT_C",
              },
              model: {
                value: _vm.product.ProductNotes,
                callback: function ($$v) {
                  _vm.$set(_vm.product, "ProductNotes", $$v)
                },
                expression: "product.ProductNotes",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mb-2 mr-3",
                    staticStyle: { "text-align": "center" },
                    attrs: {
                      icon: "clipboard",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("div", [_vm._v("Client Product Notes")]),
                  _c("tdoxTooltip", {
                    staticClass: "mb-1",
                    attrs: { detail: _vm.getTooltipsTextByCode(51) },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3 mx-2" },
        [
          _c(
            "itemised-notes",
            {
              attrs: {
                noteTitleProperty: "Subject",
                noteDetailProperty: "Note",
                noteType: "NT_A",
              },
              model: {
                value: _vm.product.ProductNotes,
                callback: function ($$v) {
                  _vm.$set(_vm.product, "ProductNotes", $$v)
                },
                expression: "product.ProductNotes",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mb-2 mr-3",
                    staticStyle: { "text-align": "center" },
                    attrs: {
                      icon: "clipboard",
                      far: "",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("div", [_vm._v("Agency Product Notes")]),
                  _c("tdoxTooltip", {
                    staticClass: "mb-1",
                    attrs: { detail: _vm.getTooltipsTextByCode(44) },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "section-break",
        staticStyle: { "margin-bottom": "0.8rem" },
      }),
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button", disabled: !_vm.isFormDirty() },
              on: {
                click: function ($event) {
                  return _vm.onSave()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "check" } }),
              _vm._v("Save "),
            ],
            1
          ),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
              _vm._v("Close "),
            ],
            1
          ),
        ],
        1
      ),
      _c("asoftPrompt", {
        attrs: {
          headerClasses: "p-3 showExitBeforeSavePromptHeader",
          bodyClasses: "px-3 pt-3",
          footerClasses: "px-3 pb-3",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Update product from reseller?")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(
                        " Select which of the following details you'd like to update from the new reseller supplier: "
                      ),
                    ]),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromReseller_PhoneNumber",
                        type: "checkbox",
                        name: "updateFromReseller",
                        label: "Phone Number",
                      },
                      model: {
                        value: _vm.updateFromReseller_PhoneNumber,
                        callback: function ($$v) {
                          _vm.updateFromReseller_PhoneNumber = $$v
                        },
                        expression: "updateFromReseller_PhoneNumber",
                      },
                    }),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromReseller_BookingUrl",
                        type: "checkbox",
                        name: "updateFromReseller",
                        label: "Booking URL",
                      },
                      model: {
                        value: _vm.updateFromReseller_BookingUrl,
                        callback: function ($$v) {
                          _vm.updateFromReseller_BookingUrl = $$v
                        },
                        expression: "updateFromReseller_BookingUrl",
                      },
                    }),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromReseller_EmailAddress",
                        type: "checkbox",
                        name: "updateFromReseller",
                        label: "Email Address",
                      },
                      model: {
                        value: _vm.updateFromReseller_EmailAddress,
                        callback: function ($$v) {
                          _vm.updateFromReseller_EmailAddress = $$v
                        },
                        expression: "updateFromReseller_EmailAddress",
                      },
                    }),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromReseller_DepositDetails",
                        type: "checkbox",
                        name: "updateFromReseller",
                        label: "Deposit Details",
                      },
                      model: {
                        value: _vm.updateFromReseller_DepositDetails,
                        callback: function ($$v) {
                          _vm.updateFromReseller_DepositDetails = $$v
                        },
                        expression: "updateFromReseller_DepositDetails",
                      },
                    }),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromReseller_PaymentTypes",
                        type: "checkbox",
                        name: "updateFromReseller",
                        label: "Payment Types",
                      },
                      model: {
                        value: _vm.updateFromReseller_PaymentTypes,
                        callback: function ($$v) {
                          _vm.updateFromReseller_PaymentTypes = $$v
                        },
                        expression: "updateFromReseller_PaymentTypes",
                      },
                    }),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v("Do you wish to proceed with this update?"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end align-center" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onUpdateProductFromReseller()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "check" },
                        }),
                        _vm._v("Ok "),
                      ],
                      1
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onCancelUpdateProductFromReseller()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "times" },
                        }),
                        _vm._v("Cancel "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.updateProductFromResellerPrompt,
          callback: function ($$v) {
            _vm.updateProductFromResellerPrompt = $$v
          },
          expression: "updateProductFromResellerPrompt",
        },
      }),
      _c("asoftPrompt", {
        ref: "changeDepositPrompt",
        attrs: {
          headerClasses: "p-3 showExitBeforeSavePromptHeader",
          bodyClasses: "px-3 pt-3",
          footerClasses: "px-3 pb-3",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Update from provider supplier?")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(
                        " Select which of the following details you'd like to update from the new provider supplier: "
                      ),
                    ]),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromProvider_Address",
                        type: "checkbox",
                        name: "updateFromprovider",
                        label: "Address",
                      },
                      model: {
                        value: _vm.updateFromProvider_Address,
                        callback: function ($$v) {
                          _vm.updateFromProvider_Address = $$v
                        },
                        expression: "updateFromProvider_Address",
                      },
                    }),
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      staticStyle: { "min-width": "60px" },
                      attrs: {
                        id: "updateFromProvider_ContactPersonNm",
                        type: "checkbox",
                        name: "updateFromprovider",
                        label: "Contact Name",
                      },
                      model: {
                        value: _vm.updateFromProvider_ContactPersonNm,
                        callback: function ($$v) {
                          _vm.updateFromProvider_ContactPersonNm = $$v
                        },
                        expression: "updateFromProvider_ContactPersonNm",
                      },
                    }),
                    _c("div", [_vm._v("Subject Notes:")]),
                    _vm._l(
                      _vm.updateFromProvider_SubjectNotes,
                      function (updateFromProviderSubjectNote, index) {
                        return [
                          _c("awgt-input", {
                            key: index,
                            staticClass: "mx-2",
                            staticStyle: { "min-width": "60px" },
                            attrs: {
                              id:
                                "updateFromProviderSubjectNote_" +
                                updateFromProviderSubjectNote.Subject,
                              type: "checkbox",
                              name: "updateFromprovider",
                              label: updateFromProviderSubjectNote.Subject,
                            },
                            model: {
                              value: updateFromProviderSubjectNote.IsUpdated,
                              callback: function ($$v) {
                                _vm.$set(
                                  updateFromProviderSubjectNote,
                                  "IsUpdated",
                                  $$v
                                )
                              },
                              expression:
                                "updateFromProviderSubjectNote.IsUpdated",
                            },
                          }),
                        ]
                      }
                    ),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v("Do you wish to proceed with this update?"),
                    ]),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end align-center" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onUpdateProductFromProvider()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "check" },
                        }),
                        _vm._v("Yes "),
                      ],
                      1
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onCancelUpdateProductFromProviderPrompt()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "times" },
                        }),
                        _vm._v("No "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.updateProductFromProviderPrompt,
          callback: function ($$v) {
            _vm.updateProductFromProviderPrompt = $$v
          },
          expression: "updateProductFromProviderPrompt",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }