import dedicatedWorkerUrl from "worker-plugin/loader?name=0.Dedicated!./securityTokenManagerDedicatedWorker.js";
import sharedWorkerUrl from "worker-plugin/loader?name=0.Shared!./securityTokenManagerSharedWorker.js";

let webWorker = null;
let webWorkerType = null;

/*
  Use a shared worker if it's supported by the browser, otherwise use a dedicated worker.
*/
if (typeof SharedWorker === "function") {
  webWorkerType = "Shared";
  webWorker = new SharedWorker(sharedWorkerUrl, {
    type: "module",
  });
  webWorker.port.start();
} else if (typeof Worker === "function") {
  webWorkerType = "Dedicated";
  webWorker = new Worker(dedicatedWorkerUrl, {
    type: "module",
  });
} else {
  const msg =
    "This browser does not support this site's technologies, please upgrade or change the browser.";
  window.alert(msg);
  throw msg;
}

let sequenceNumber = 1;

const messagePipeAsync = (data) => {
  return new Promise((resolve) => {
    // listen to the response message
    const listener = (e) => {
      if (e.data.msgtype == data.msgtype && e.data.seqNum == data.seqNum) {
        switch (e.data.msgtype) {
          case "getAccessToken":
            resolve(e.data.token);
            break;
          case "setAccessToken":
            resolve();
            break;
          case "closing": {
            if (e.data.connectionCount == 0) {
              sessionStorage.setItem("token", e.data.token);
            }
            resolve();
            break;
          }
        }
        switch (webWorkerType) {
          case "Dedicated":
            webWorker.removeEventListener("message", listener);
            break;
          case "Shared":
            webWorker.port.removeEventListener("message", listener);
            break;
        }
      }
    };

    switch (webWorkerType) {
      case "Dedicated":
        webWorker.addEventListener("message", listener);
        // send a message to the worker
        webWorker.postMessage(data);
        break;
      case "Shared":
        webWorker.port.addEventListener("message", listener);
        // send a message to the worker
        webWorker.port.postMessage(data);
        break;
    }
  });
};

// set the token
const setAccessTokenAsync = (accessToken) =>
  messagePipeAsync({
    msgtype: "setAccessToken",
    token: accessToken,
    seqNum: sequenceNumber++,
  });

// get the token
const getAccessTokenAsync = () => {
  /*
    Always check session storage for the access token and, if found, move it into the
    token manager. This will only be required after a page refresh, but the code after
    a refresh is asynchronous so you never quite know which bit will run first...
    consequently looking at session storage for the token is being performed centrally.
  */
  const currentToken = sessionStorage.getItem("token");
  if (currentToken && currentToken.length > 0) {
    return setAccessTokenAsync(currentToken).then(() => {
      sessionStorage.removeItem("token");
      return messagePipeAsync({
        msgtype: "getAccessToken",
        seqNum: sequenceNumber++,
      });
    });
  } else {
    return messagePipeAsync({
      msgtype: "getAccessToken",
      seqNum: sequenceNumber++,
    });
  }
};

const signalClosing = async () => {
  return await messagePipeAsync({
    msgtype: "closing",
    seqNum: sequenceNumber++,
  });
};

onbeforeunload = (e) => {
  /*
    Deleting the returnValue stops "Leave Site?" and "Confirm Reload" messages
    that are associated with the beforeunload event. Beforeunload must be called
    synchronously (i.e. not be an asynchronous call) for the deletion of 
    returnValue to prevent these messages.

    See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    
    Note: this works on Chrome, hopefully it will work with other browsers.
  */
  delete e.returnValue;
  signalClosing();
};

const doesAccessTokenExistAsync = async () => {
  let token = await getAccessTokenAsync();
  if (token.length == 0) return false;
  else return true;
};

const getCurrentUserContextAsync = async () => {
  let token = await getAccessTokenAsync();

  const user = {
    FirstName: JSON.parse(atob(token.split(".")[1]))?.family_name,
    Surname: JSON.parse(atob(token.split(".")[1]))?.given_name,
    Reference: JSON.parse(atob(token.split(".")[1]))?.user_reference,
  };

  return user;
};

const getUserRoleAsync = async () => {
  let token = await getAccessTokenAsync();

  if (token != "") {
    const role = JSON.parse(atob(token.split(".")[1]))?.role;

    return role;
  } else {
    return null;
  }
};

const getUserPermissionAsync = async () => {
  let token = await getAccessTokenAsync();

  if (token != "") {
    const permission = JSON.parse(
      JSON.parse(atob(token.split(".")[1]))?.permission
    );

    return permission;
  } else {
    return [];
  }
};

let tokenManager = {
  getAccessTokenAsync,
  setAccessTokenAsync,
  doesAccessTokenExistAsync,
  getCurrentUserContextAsync,
  getUserRoleAsync,
  getUserPermissionAsync,
};
export default tokenManager;
