var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("timePicker", {
    style: { width: _vm.width, height: _vm.height },
    attrs: {
      hoursFormat: 12,
      label: _vm.label,
      color: _vm.color,
      placeholder: _vm.placeholder,
      id: _vm.id,
      doneLabel: _vm.doneLabel,
      clearLabel: _vm.clearLabel,
      cancelLabel: _vm.cancelLabel,
      icon: _vm.icon,
      iconClass: _vm.iconClass,
      far: _vm.far,
      fab: _vm.fab,
      fal: _vm.fal,
      outline: _vm.outline,
      bg: _vm.bg,
      disabled: _vm.disabled,
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }