<template>
  <div>
    <mdb-select
      ref="mdbSelect"
      :tag="tag"
      :selectAll="selectAll"
      :selectAllPlaceholder="selectAllPlaceholder"
      :color="color"
      :multiple="multiple"
      :disabled="disabled"
      :search="search"
      :value="options"
      :searchPlaceholder="searchPlaceholder"
      :wrapperClass="wrapperClass"
      :wrapperStyle="wrapperStyle"
      :caretClass="caretClass"
      :caretStyle="caretStyle"
      :getValue="getValue"
      :limitPlaceholder="limitPlaceholder"
      :validation="validation"
      :customValidation="customValidation"
      :isValid="isValid"
      :validFeedback="validFeedback"
      :invalidFeedback="invalidFeedback"
      :outline="outline"
      :label="label"
      :labelClass="labelClass"
      :outerClass="outerClass"
      :outerTag="outerTag"
      :placeholder="placeholder"
      :icon="icon"
      :iconClass="iconClass"
      :far="far"
      :fab="fab"
      :fal="fal"
      :bg="bg"
      :scroll="scroll"
      :visibleOptions="visibleOptions"
      :flipOnScroll="flipOnScroll"
      :resultText="resultText"
      :show="show"
      :resetBtn="resetBtn"
      :name="name"
      :required="required"
      :disableFilter="disableFilter"
      @getValue="getSelectedValue"
      :options="options"
      :maxSelectedOptions="maxSelectedOptions"
    />
    <button
      v-if="tooltip.length > 0"
      type="button"
      class="tips-button"
      :title="tooltip"
      v-tippy="{ position: 'bottom', arrow: true }"
      style="position: relative; right: 27px"
    >
      <mdb-icon
        fas
        :icon="getTooltipIconName(tooltipType)"
        :style="{
          color: tooltipIconColor,
          cursor: 'pointer',
          position: 'absolute',
          right: '-2px',
        }"
        size="1x"
      />
    </button>
  </div>
</template>

<script>
import { mdbSelect, mdbIcon } from "mdbvue";

export default {
  name: "asoftSelect",
  components: {
    mdbSelect,
    mdbIcon,
  },
  props: {
    tooltip: {
      type: String,
      default: "",
    },
    tooltipType: {
      type: String,
      default: "information",
    },
    tag: {
      type: String,
      default: "div",
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    selectAllPlaceholder: {
      type: String,
      default: "Select all",
    },
    color: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Search here...",
    },
    wrapperClass: {
      type: String,
    },
    wrapperStyle: {
      type: String,
    },
    caretClass: {
      type: String,
    },
    caretStyle: {
      type: String,
    },
    getValue: {
      type: Function,
    },
    limitPlaceholder: {
      type: String,
      default: "options selected",
    },
    value: {
      type: [String, Number, Array],
    },
    validation: {
      type: Boolean,
    },
    customValidation: {
      type: Boolean,
    },
    isValid: {
      type: Boolean,
    },
    validFeedback: {
      type: [String, Boolean],
      default: false,
    },
    invalidFeedback: {
      type: [String, Boolean],
      default: false,
    },
    outline: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
    },
    outerClass: {
      type: String,
    },
    outerTag: {
      type: String,
      default: "div",
    },
    placeholder: {
      type: String,
    },
    icon: String,
    iconClass: {
      type: String,
    },
    tooltipIconColor: String,
    far: Boolean,
    fab: Boolean,
    fal: Boolean,
    bg: Boolean,
    scroll: {
      type: Boolean,
      default: true,
    },
    visibleOptions: {
      type: Number,
      default: 4,
    },
    flipOnScroll: {
      type: Boolean,
      default: true,
    },
    resultText: {
      type: String,
      default: "No results",
    },
    show: {
      type: Boolean,
    },
    resetBtn: {
      type: Boolean,
      default: false,
    },
    name: String,
    required: Boolean,
    disableFilter: {
      type: Boolean,
      default: false,
    },
    items: { type: Array, required: true },
    itemValueProperty: { type: String, required: true },
    itemTextProperty: { type: [String, Function], required: true },
    maxSelectedOptions: { type: Number },
    activeColor: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      options: [],
    };
  },

  computed: {
    getTooltipIconName() {
      return (tooltipType) => {
        switch (tooltipType) {
          case "information":
            return "info-circle";
          case "question":
            return "question-circle";
          case "warning":
            return "exclamation-triangle";
          default:
            return "info-circle";
        }
      };
    },
  },

  methods: {
    getSelectedValue(value) {
      /*
        The mdbSelect control fires the @getValue event when the list
        of input items to the control changes, this then updates value.
        Updating value repopulates the list of input items and the loop
        continues. The following check confirms that the selected
        values have actually changed before firing the input event to
        update v-model.
      */
      if (this.$lodash.isEqual(value, this.value) == false) {
        this.$emit("input", value);
        this.$emit("get-value", value);
      }
    },

    populateList(val) {
      var self = this;
      if (this.multiple) {
        this.options = this.items.map(function (row) {
          return {
            value: row[self.itemValueProperty],
            text:
              typeof self.itemTextProperty === "string"
                ? row[self.itemTextProperty]
                : self.itemTextProperty(row),
            selected:
              val.findIndex((i) => i == row[self.itemValueProperty]) >= 0,
          };
        });
      } else {
        this.options = this.items.map(function (row) {
          return {
            value: row[self.itemValueProperty],
            text:
              typeof self.itemTextProperty === "string"
                ? row[self.itemTextProperty]
                : self.itemTextProperty(row),
            selected: row[self.itemValueProperty] == val ? true : false,
          };
        });
      }
    },

    onFocus(event) {
      this.$emit("focus", event);
    },
  },

  watch: {
    /*
      Need to use deep watchers are we're watching the values of an array and
      the properties of objects... look at deep watchers.
    */
    value: {
      handler() {
        this.populateList(this.value);
      },
      deep: true,
    },
    items: {
      handler() {
        this.populateList(this.value);
      },
      deep: true,
    },
  },

  mounted() {
    /*
      The mdbSelect control does not correctly bubble up the focus event. This code delves into
      the control to grab the focus event so that it is dispatched by the asoftSelect control.
    */
    let mdbInputControl = this.$refs["mdbSelect"].$refs["input"].$refs["input"];
    let inputControl = mdbInputControl.$el.getElementsByTagName("input")[0]; //get input control in the mdbInput control

    inputControl.addEventListener("focus", this.onFocus, false);

    if (this.multiple) {
      if (Array.isArray(this.value) == false)
        throw Error(
          "Value (or v-model) must be an array for a multiple select. Set value to an array type or remove 'multiple' from the select."
        );
    } else {
      if (Array.isArray(this.value) == true)
        throw Error(
          "Value (or v-model) must not be an array for a single select. Set value to a string or number type or add 'multiple' to the select."
        );
    }

    this.populateList(this.value);
  },
};
</script>

<style scoped>
/* The following ensures that blank list item entries
 * aren't shorter than other entries
 */
.options-container > li {
  height: 30px;
}

.tips-button {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: relative;
  bottom: 48px;
  right: 15px;
}
</style>

<!-- <style>
.select-active input {
  border-bottom: 1px solid green !important;
  box-shadow: 0 1px 0 0 green, 0 1px 0 0 green !important;
}
</style> -->
