<template>
  <div>
    <h4 class="mx-2">Rebates And Discounts</h4>

    <div
      v-for="(rebateDiscount, index) in rebatesAndDiscountsArray"
      :key="index"
      class="d-flex flex-row flex-nowrap flex-grow-1 my-2"
    >
      <div class="mx-2 d-flex flex-row flex-wrap flex-grow-1">
        <span
          class="d-flex flex-row flex-nowrap flex-grow-1 mr-3"
          style="min-width: 100px; max-width: 200px; font-weight: bold"
        >
          {{ rebateDiscount.Name }}
        </span>
        <span
          class="d-flex flex-row flex-nowrap flex-grow-1"
          style="min-width: 100px"
        >
          {{
            rebateDiscount.BookingAdjustmentCalculatedAs == "BACA_D" ? "$" : ""
          }}{{
            `${rebateDiscount.Amount}${
              rebateDiscount.BookingAdjustmentCalculatedAs == "BACA_P"
                ? "%"
                : ""
            } ${
              rebateDiscount.BookingAdjustmentAppliesTo == "BAAT_C" &&
              isSchoolBooking
                ? "Per Student"
                : getBookingAdjustmentAppliesToNameByCode(
                    rebateDiscount.BookingAdjustmentAppliesTo
                  )
            } - ${getBookingAdjustmentTypeNameByCode(
              rebateDiscount.BookingAdjustmentType
            )} `
          }}
        </span>
      </div>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="top: -10px; min-width: 63px"
        @click="OpenRebateDiscountEditor(index)"
      >
        <mdb-icon icon="edit" class="mr-1" />Edit
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="top: -10px; min-width: 63px"
        @click="DeleteRebateDiscountNote(index)"
      >
        <mdb-icon icon="trash-alt" class="mr-1" />Delete
      </awgt-std-button>
      <br />
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
    >
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="OpenRebateDiscountEditor(-1)"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <mdb-modal :show="showRebateAndDiscountEditor">
      <asoft-modal-header label="Rebate And Discount Edit" @close="CloseEditor">
      </asoft-modal-header>
      <mdb-modal-body>
        <template v-if="editRebateAndDiscount != null">
          <span
            >The following amount will be deducted from this booking's trip
            costing.</span
          >
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              icon="clipboard"
              far
              color="primary"
              style="width: 40px; text-align: center"
              size="2x"
            />
            <awgt-input
              bg
              counter
              :counterMaxValue="50"
              maxlength="50"
              label="Name"
              class="flex-grow-1"
              v-model="editRebateAndDiscount.Name"
            ></awgt-input>
          </div>
          <div class="my-2">
            <div class="mx-2">This rebate/discount will be calculated as a</div>
            <div class="d-flex flex-row flex-wrap min-width:120px;">
              <awgt-input
                id="BookingAdjustmentCalculatedAsDollar"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentCalculatedAs"
                name="BookingAdjustmentCalculatedAs"
                label="Dollar Amount"
                radioValue="BACA_D"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentCalculatedAsPercentage"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentCalculatedAs"
                name="BookingAdjustmentCalculatedAsPercentage"
                label="Percentage"
                radioValue="BACA_P"
                class="mx-2"
              />
            </div>
          </div>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              :icon="
                getAmountIcon(
                  editRebateAndDiscount.BookingAdjustmentCalculatedAs
                )
              "
              color="primary"
              style="width: 40px; text-align: center"
              size="2x"
            />
            <awgt-input
              v-model.number="editRebateAndDiscount.Amount"
              maxlength="7"
              label="Amount"
              type="text"
              class="flex-grow-1"
              bg
              v-numeric="
                getPrecision(
                  editRebateAndDiscount.BookingAdjustmentCalculatedAs
                )
              "
            ></awgt-input>
          </div>
          <div class="my-2">
            <div class="mx-2" style="min-width: 60px">Claimed By</div>
            <div class="d-flex flex-row flex-wrap min-width:120px;">
              <awgt-input
                id="BookingAdjustmentTypeByClient"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentType"
                name="BookingAdjustmentType"
                label="Client"
                radioValue="BAT_RC"
                class="ml-2 mr-4"
                :tooltip="getTooltipsTextByCode(1)"
              />
              <awgt-input
                id="BookingAdjustmentTypeByBookingAgent"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentType"
                name="BookingAdjustmentType"
                label="Agency"
                radioValue="BAT_RA"
                class="mx-2"
                :tooltip="getTooltipsTextByCode(2)"
              />
            </div>
          </div>
          <div class="my-2">
            <div class="mx-2" style="min-width: 60px">Applies To</div>
            <div class="d-flex flex-row flex-wrap min-width:120px;">
              <awgt-input
                id="BookingAdjustmentAppliesToPerChild"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                :label="isSchoolBooking ? 'Per Student' : 'Per Child'"
                radioValue="BAAT_C"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerAdult"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Adult"
                radioValue="BAAT_A"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerPerson"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Person"
                radioValue="BAAT_P"
                class="mx-2"
              />
              <awgt-input
                id="BookingAdjustmentAppliesToPerBooking"
                type="radio"
                v-model="editRebateAndDiscount.BookingAdjustmentAppliesTo"
                name="BookingAdjustmentAppliesTo"
                label="Per Booking"
                radioValue="BAAT_B"
                class="mx-2"
              />
            </div>
          </div>
        </template>
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveAndCloseEditor()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="CloseEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import lodash from "lodash";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import { mapGetters } from "vuex";

import { mdbIcon, mdbModal, mdbModalBody, mdbModalFooter } from "mdbvue";

import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    asoftModalHeader,
  },
  data() {
    return {
      currentRebateAndDiscountIndex: 0,
      showRebateAndDiscountEditor: false,
      editRebateAndDiscount: null,
      rebatesAndDiscountsArray: [],
    };
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    value: {
      type: Array,
    },
    isSchoolBooking: {
      value: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "getBookingAdjustmentAppliesToNameByCode",
      "getTooltipsTextByCode",
    ]),
    getBookingAdjustmentTypeNameByCode() {
      return function (code) {
        let typeName;
        switch (code) {
          case "BAT_RC":
            typeName = "Claimed by Client";
            break;
          case "BAT_RA":
            typeName = "Claimed by Agency";
            break;
          default:
            typeName = "";
        }

        return typeName;
      };
    },

    getAmountIcon() {
      return function (code) {
        let type;
        switch (code) {
          case "BACA_D":
            type = "dollar-sign";
            break;
          case "BACA_P":
            type = "percentage";
            break;
          default:
            type = "";
        }
        return type;
      };
    },

    getPrecision() {
      return function (code) {
        let obj;
        switch (code) {
          case "BACA_D":
            obj = {
              precision: 6,
              scale: 2,
              posOnly: true,
              trailingZeroScale: 2,
            };
            break;
          case "BACA_P":
            obj = {
              precision: 2,
              scale: 0,
              posOnly: true,
              trailingZeroScale: 0,
            };
            break;
          default:
            obj = {};
        }
        return obj;
      };
    },
  },
  methods: {
    OpenRebateDiscountEditor(idx) {
      this.currentRebateAndDiscountIndex = idx;

      if (idx > -1) {
        this.editRebateAndDiscount = lodash.cloneDeep(
          this.rebatesAndDiscountsArray[this.currentRebateAndDiscountIndex]
        );
      } else {
        this.editRebateAndDiscount = {};
        this.$set(this.editRebateAndDiscount, "Name", "");
        this.$set(this.editRebateAndDiscount, "Amount", 0);
        this.$set(
          this.editRebateAndDiscount,
          "BookingAdjustmentType",
          "BAT_RC"
        );
        this.$set(
          this.editRebateAndDiscount,
          "BookingAdjustmentAppliesTo",
          "BAAT_C"
        );
        this.$set(
          this.editRebateAndDiscount,
          "BookingAdjustmentCalculatedAs",
          "BACA_D"
        );
      }

      this.showRebateAndDiscountEditor = true;
    },
    SaveAndCloseEditor() {
      if (this.currentRebateAndDiscountIndex > -1) {
        this.$set(
          this.rebatesAndDiscountsArray,
          this.currentRebateAndDiscountIndex,
          this.editRebateAndDiscount
        );
      } else {
        this.rebatesAndDiscountsArray.push(this.editRebateAndDiscount);
      }
      this.CloseEditor();
    },
    DeleteRebateDiscountNote(idx) {
      this.rebatesAndDiscountsArray.splice(idx, 1);
    },
    CloseEditor() {
      this.showRebateAndDiscountEditor = false;
    },
  },
  watch: {
    value(to) {
      this.rebatesAndDiscountsArray = to;
    },
    rebatesAndDiscountsArray(to) {
      this.$emit("input", to);
    },
    "editRebateAndDiscount.BookingAdjustmentCalculatedAs"() {
      this.editRebateAndDiscount.Amount = 0;
    },
  },
  mounted() {},
};
</script>
