<template>
  <div :style="{ color: color }">
    <h5>
      <mdb-icon
        icon="user"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />{{ value.FirstName + " " + value.Surname }}
      <span style="font-size: 75%"
        ><i>({{ value.Reference }})</i></span
      >
    </h5>
    <asoftPhoneNumberCapture
      :template="getSystemTemplateFromCode('STT_C_UP')"
      :value="value.PhoneJSON"
      class="d-flex flex-row flex-nowrap flex-grow-1"
    >
      <template #default="{ template, phone }">
        <mdb-icon
          v-if="phone.Value.length > 0"
          icon="phone"
          :color="iconColor"
          class="icon-in-views mt-1"
          size="1x"
        />
        {{ phone.Value.length > 0 ? template.Label : null }}
        <awgt-link-text class="ml-1 mr-2" :href="'tel:' + phone.Value">{{
          formatPhoneNumber(phone.Value)
        }}</awgt-link-text>
      </template>
    </asoftPhoneNumberCapture>
    <mdb-icon
      icon="at"
      :color="iconColor"
      class="icon-in-views"
      size="1x"
    />Email:
    <awgt-link-text :href="'mailto:' + value.EmailAddress">{{
      value.EmailAddress
    }}</awgt-link-text>

    <br />
    <mdb-icon
      icon="user-cog"
      :color="iconColor"
      class="icon-in-views"
      size="1x"
    />Consultancy role:
    {{ getConsultancyRoleNameById(value.ConsultancyRole.ConsultancyRoleId) }}
    <br />
    <div
      v-if="
        getConsultancyRoleNameById(value.ConsultancyRole.ConsultancyRoleId) ==
        'Consultant'
      "
    >
      <mdb-icon
        icon="users"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />{{
        value.Workgroups.length != 0
          ? "Workgroup: " + value.Workgroups[0].Name
          : "This consultant is not in a workgroup."
      }}
    </div>
    <div v-else>
      <mdb-icon
        icon="users"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />
      <template v-if="value.Workgroups.length == 0"
        >This user is not in any workgroups.</template
      >
      <template v-else
        >Workgroups:
        <ul class="html-in-views">
          <li v-for="(workgroup, index) in value.Workgroups" v-bind:key="index">
            {{ workgroup.Name }}
          </li>
        </ul>
      </template>
    </div>
    <div v-if="value.IsAdministrator || value.CanModifyProducts">
      <mdb-icon
        icon="user-cog"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />Other user roles:
      <ul class="mb-0 html-in-views">
        <li v-if="value.IsAdministrator">This user is an administrator.</li>
        <li v-if="value.CanModifyProducts">This user can modify products.</li>
      </ul>
    </div>
    <div v-if="value.Impersonators.length > 0">
      <mdb-icon
        icon="user-secret"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />Impersonated By:
      <ul class="mb-0 html-in-views">
        <li v-for="(impersonator, index) in value.Impersonators" :key="index">
          {{ impersonator.FirstName + " " + impersonator.Surname }}
        </li>
      </ul>
    </div>
    <div v-if="value.Impersonates.length > 0">
      <mdb-icon
        icon="user-secret"
        :color="iconColor"
        class="icon-in-views"
        size="1x"
      />Impersonates:
      <ul class="mb-0 html-in-views">
        <li v-for="(impersonator, index) in value.Impersonates" :key="index">
          {{ impersonator.FirstName + " " + impersonator.Surname }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mdbIcon } from "mdbvue";
import asoftPhoneNumberCapture from "@/components/AtomSoftware/asoftPhoneNumberCapture.vue";
import { mapGetters } from "vuex";
import AwgtLinkText from "@/components/AWGT/AwgtLinkText.vue";
import { formatPhoneNumber } from "@/shared/shared.js";

export default {
  components: {
    mdbIcon,
    asoftPhoneNumberCapture,
    AwgtLinkText,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    iconColor: { type: String, default: "primary" },
    color: String,
  },
  computed: {
    ...mapGetters(["getConsultancyRoleNameById", "getSystemTemplateFromCode"]),
  },
  methods: { formatPhoneNumber },
};
</script>
