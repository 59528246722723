<template>
  <div style="position: relative">
    <awgt-std-dropdown
      label="Free of Charge Ratio"
      class="mx-2 flex-grow-1"
      itemValueProperty="Code"
      :items="selection"
      itemTextProperty="Name"
      v-model="selectFOC"
      bg
    />
    <awgt-input
      v-if="selectFOC == 'Ratio'"
      label="Free of Charge Ratio"
      v-model="inputFOC"
      maxlength="4"
      bg
      class="flex-grow-1 mx-2 input-field"
      type="text"
      mask="/\d{1,1}:\d{1,2}/"
      maskedValueType="formatted"
    ></awgt-input>
  </div>
</template>

<script>
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput";

export default {
  components: {
    AwgtStdDropdown,
    awgtInput,
  },

  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      selectFOC: "",
      inputFOC: "",
      selection: [
        { Code: "Ratio", Name: "Ratio" },
        { Code: "All", Name: "All FOC" },
        { Code: "None", Name: "None FOC" },
      ],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler: function (to, from) {
        if (to != from) {
          if (to == "All" || to == "None") {
            this.selectFOC = to;
          } else {
            this.inputFOC = to;
            this.selectFOC = "Ratio";
          }
        }
      },
    },

    selectFOC(to) {
      if (to != "Ratio") {
        this.$emit("input", to);
      } else {
        this.$emit("input", this.inputFOC);
      }
    },

    inputFOC(to) {
      this.$emit("input", to);
    },
  },
};
</script>

<style scoped>
.input-field {
  position: absolute !important;
  width: 80%;
  bottom: 0px;
}
</style>
