<template>
  <mdb-container fluid>
    <h1 class="text-center">Supplier Product Information</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        search
        label="Product Type"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getProductTypes(false)"
        itemTextProperty="Name"
        v-model="productType"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        search
        label="Region"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getRegion"
        itemTextProperty="Name"
        v-model="region"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        search
        label="Supplier"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="supplierList"
        itemTextProperty="Name"
        v-model="supplier"
        bg
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <awgt-std-dropdown
        search
        label="Products"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="productList"
        itemTextProperty="Name"
        v-model="products"
        bg
        multiple
        selectAll
        @focus="onFocusProductDropdown"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Year Groups"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getYearGroups"
        itemTextProperty="Name"
        v-model="yearGroups"
        bg
        multiple
        selectAll
      ></awgt-std-dropdown>
      <mdb-form-inline class="flex- mx-2 px-3" style="height: 45px">
        <span class="mr-3" style="width: 120px">Audience</span>
        <awgt-input
          id="audience_client"
          v-model="audience"
          type="radio"
          name="AudienceClient"
          label="Client"
          radioValue="Client"
          class="mr-3"
        />
        <awgt-input
          id="audience_internal"
          v-model="audience"
          type="radio"
          name="AudienceInternal"
          label="Internal"
          radioValue="Internal"
          class="mr-3"
        />
      </mdb-form-inline>
      <awgt-std-dropdown
        v-if="audience == 'Internal'"
        v-model="year"
        maxlength="100"
        style="min-width: 90px"
        :items="getYears(2017)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Year (pricing purposes)"
        bg
        multiple
        selectAll
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <awgt-std-switch
        checked
        offLabel="Reseller Supplier"
        class="mx-2 my-2"
        onLabel="Provider Supplier"
        @getValue="
          (value) => {
            supplierType = value;
          }
        "
      ></awgt-std-switch>
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          v-if="Object.keys(reportData).length > 0"
          type="button"
          class="command-button mx-2 float-right"
          @click="onExport"
        >
          <mdb-icon icon="file-pdf" class="mr-1" />Export
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearGenerateCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onGenerate"
        >
          <mdb-icon icon="search" class="mr-1" />Generate
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div
      v-if="Object.keys(reportData).length === 0 && isGenerated"
      class="text-center"
    >
      <i>No result found</i>
    </div>
    <div
      id="infoContent"
      class="mx-2"
      v-if="isGenerated && Object.keys(reportData).length > 0"
    >
      <div v-if="audienceCache == 'Client'">
        <div>
          <img src="../../assets/AWGT Logo.png" width="300" class="mx-4" />
          <div style="color: #923f82">
            <span style="font-weight: bold; float: right">{{
              `${
                this.supplierType == true
                  ? "Provider Supplier"
                  : "ResellerSupplier"
              }/Product Information Report`
            }}</span
            ><br /><span style="font-style: italic; float: right">{{
              audience
            }}</span>
          </div>
        </div>

        <div>
          <div><span style="font-weight: bold">Criteria</span></div>
          <ul class="mx-3">
            <template v-if="productTypeCache && productTypeCache.length > 0">
              <li>
                {{
                  getProductTypes(false).find((pt) => {
                    return pt.Code == productTypeCache;
                  }).Name
                }}
              </li></template
            >
            <template v-if="productTypeCache && productTypeCache.length > 0">
              <li>
                {{
                  getProductTypes(false).find((pt) => {
                    return pt.Code == productTypeCache;
                  }).Name
                }}
              </li>
            </template>
            <template v-if="regionCache && regionCache.length > 0">
              <li>{{ regionCache }}</li></template
            >
            <template v-if="suppliersCache && suppliersCache.length > 0">
              <li>
                {{
                  supplierList.find((s) => {
                    return s.Code == suppliersCache;
                  }).Name
                }}
              </li></template
            >
            <template v-if="yearGroupsCache && yearGroupsCache.length > 0">
              <li v-if="yearGroupsCache.length == getYearGroups.length">
                All Year Groups
              </li>
              <li v-else>
                {{ yearGroupsCache.join() }}
              </li></template
            >
            <li>{{ audienceCache }}</li>
            <template
              v-if="
                audienceCache == 'Client' && yearCache && yearCache.length > 0
              "
            >
              <li>{{ yearCache }}</li></template
            >
            <template v-if="productsCache.length == productList.length"
              ><li>All products</li></template
            >
          </ul>
          <template
            v-if="
              productsCache.length > 0 &&
              productsCache.length != productList.length
            "
          >
            <div style="text-align: center">
              <table class="product-name-search-criteria">
                <template
                  v-for="(subArr, index) in getSelectProductNameSet(
                    productsCache
                  )"
                >
                  <tr v-bind:key="index">
                    <template v-for="(item, indexItem) in subArr">
                      <td v-bind:key="indexItem">{{ item }}</td>
                    </template>
                  </tr>
                </template>
              </table>
            </div>
          </template>
        </div>
        <supplier-product-report-view-client
          v-model="reportData"
        ></supplier-product-report-view-client>
      </div>
      <div v-if="audienceCache == 'Internal'">
        <div>
          <span style="font-size: 25px">
            {{
              `${
                this.supplierType == true
                  ? "Provider Supplier"
                  : "ResellerSupplier"
              }/Product Information Report - Internal`
            }}</span
          >
        </div>

        <div>
          <div><span style="font-weight: bold">Criteria</span></div>
          <ul class="mx-3">
            <template v-if="productTypeCache && productTypeCache.length > 0">
              <li>
                {{
                  getProductTypes(false).find((pt) => {
                    return pt.Code == productTypeCache;
                  }).Name
                }}
              </li></template
            >
            <template v-if="productTypeCache && productTypeCache.length > 0">
              <li>
                {{
                  getProductTypes(false).find((pt) => {
                    return pt.Code == productTypeCache;
                  }).Name
                }}
              </li>
            </template>
            <template v-if="regionCache && regionCache.length > 0">
              <li>{{ regionCache }}</li></template
            >
            <template v-if="suppliersCache && suppliersCache.length > 0">
              <li>
                {{
                  supplierList.find((s) => {
                    return s.Code == suppliersCache;
                  }).Name
                }}
              </li></template
            >
            <template v-if="yearGroupsCache && yearGroupsCache.length > 0">
              <li v-if="yearGroupsCache.length == getYearGroups.length">
                All Year Groups
              </li>
              <li v-else>
                {{ yearGroupsCache.join() }}
              </li></template
            >
            <li>{{ audienceCache }}</li>
            <template v-if="productsCache.length == productList.length"
              ><li>All products</li></template
            >
            <template
              v-if="
                audienceCache == 'Internal' && yearCache && yearCache.length > 0
              "
            >
              <li>{{ yearCache.join(", ") }}</li></template
            >
          </ul>
          <template
            v-if="
              productsCache.length > 0 &&
              productsCache.length != productList.length
            "
          >
            <div style="text-align: center">
              <table class="product-name-search-criteria">
                <template
                  v-for="(subArr, index) in getSelectProductNameSet(
                    productsCache
                  )"
                >
                  <tr v-bind:key="index">
                    <template v-for="(item, indexItem) in subArr">
                      <td v-bind:key="indexItem">{{ item }}</td>
                    </template>
                  </tr>
                </template>
              </table>
            </div>
          </template>
        </div>
        <supplier-product-report-view-internal
          v-model="reportData"
          :supplierType="supplierTypeCache"
        ></supplier-product-report-view-internal>
      </div>
    </div>
  </mdb-container>
</template>
<script>
import { mdbContainer, mdbFormInline, mdbIcon } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { mapGetters } from "vuex";
import supplierApi from "@/api/supplierApi.js";
import productApi from "@/api/productApi.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import reportApi from "@/api/reportApi.js";
import html2pdf from "html2pdf.js";
import SupplierProductReportViewInternal from "@/components/SupplierProductReportViewInternal";
import SupplierProductReportViewClient from "@/components/SupplierProductReportViewClient";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  data() {
    return {
      date: null,
      productType: "",
      productTypeCache: "",
      region: "",
      regionCache: "",
      getRegion: [],
      supplier: "",
      suppliersCache: "",
      products: [],
      productsCache: [],
      yearGroups: [],
      yearGroupsCache: [],
      year: [],
      yearCache: [],
      audience: "Client",
      audienceCache: "",
      supplierList: [],
      productList: [],
      productListCache: [],
      reportData: {},
      isGenerated: false,
      supplierType: true,
      supplierTypeCache: true,
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
    mdbFormInline,
    awgtInput,
    AwgtStdButton,
    mdbIcon,
    AwgtStdSwitch,
    SupplierProductReportViewInternal,
    SupplierProductReportViewClient,
  },
  computed: {
    ...mapGetters(["getProductTypes", "getYearGroups"]),
    getYears() {
      return function (startYear) {
        const year = new Date().getFullYear();
        let yearsList = Array.from(
          { length: year - startYear + 1 },
          (v, i) => startYear + i
        ).map((item) => {
          return {
            Name: item,
            Code: item,
          };
        });
        return yearsList;
      };
    },
    getSelectProductNameSet() {
      return function (productsCache) {
        return this.intoSubArray(
          productsCache.map((p) => {
            return this.productListCache.find((product) => {
              return p == product.Reference;
            }).Name;
          })
        );
      };
    },
  },
  watch: {
    supplierType() {
      this.products = [];
    },
    supplier() {
      this.products = [];
    },
  },
  methods: {
    intoSubArray(myArray, chunks = 5) {
      let num = Math.ceil(myArray.length / chunks);
      const result = Array();
      for (let i = 0; i < num; i++) {
        result[i] = [];
      }
      let index = 0;
      for (let i = 0, length = myArray.length; i < length; i++) {
        if (i != 0 && i % chunks == 0) index += 1;
        result[index].push(myArray[i]);
      }
      return result;
    },
    async loadData() {
      this.supplierList = await supplierApi.getSupplierDropdown(); //Only pull back Code and Name properties.
    },
    async onGenerate() {
      this.reportData = await reportApi.getSupplierProductInformationReport(
        this.productType,
        this.region,
        this.supplier,
        this.products.join(),
        this.yearGroups,
        this.year.join(),
        this.supplierType == true ? "Provider Supplier" : "Reseller Supplier"
      );

      this.productTypeCache = this.$lodash.clone(this.productType);
      this.regionCache = this.$lodash.clone(this.region);
      this.suppliersCache = this.$lodash.clone(this.supplier);
      this.productsCache = this.$lodash.cloneDeep(this.products);
      this.yearGroupsCache = this.$lodash.cloneDeep(this.yearGroups);
      this.yearCache = this.$lodash.cloneDeep(this.year);
      this.audienceCache = this.$lodash.clone(this.audience);
      this.supplierTypeCache = this.$lodash.clone(this.supplierType);
      this.productListCache = this.$lodash.cloneDeep(this.productList);

      this.isGenerated = true;
    },

    onExport() {
      const options = {
        margin: 0.5,
        filename: `Supplier/Product_Information_Report(${this.audienceCache})`,
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: {
          scale: 2,
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
        },
        pagebreak: { mode: ["css", "legacy"] },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation:
            this.audienceCache == "Client" ? "portrait" : "landscape",
        },
      };

      let content = document.getElementById("infoContent");
      let self = this;
      html2pdf()
        .set(options)
        .from(content)
        .save()
        .then(() => {
          self.$emit("header-message", {
            isSuccessInd: true,
            Message: ["The PDF has been generated successfully."],
          });
        });
    },

    onClearGenerateCriteria() {
      this.productType = "";
      this.region = "";
      this.supplier = "";
      this.products = [];
      this.yearGroups = [];
      this.audience = "Client";
      this.year = [];
    },

    async onFocusProductDropdown() {
      if (
        (this.productType == "" || this.productType == null) &&
        (this.supplier == "" || this.supplier == null)
      )
        this.productList = [];
      else
        this.productList = await productApi.getProductDropdown(
          this.productType,
          false,
          false,
          true
        ); //Only pull back data set with Code and Name properties
    },
  },
  mounted() {
    this.loadData().catch(() => {
      // Erros have been logged
    });
  },
};
</script>
<style scoped>
a {
  text-decoration: underline;
}

.product-name-search-criteria {
  margin-left: auto;
  margin-right: auto;
}

.product-name-search-criteria > tr > td {
  padding: 10px 20px;
  word-wrap: break-word;
}
</style>
