var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "box",
        class: [_vm.shadow ? "box-border-shadow" : "box-normal-border "],
        style: { borderColor: _vm.borderColor },
      },
      [
        _c(
          "div",
          [
            _c("mdb-icon", {
              staticStyle: { margin: "0 auto" },
              attrs: {
                far: "",
                icon: _vm.icon,
                color: _vm.iconColor,
                size: "1x",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: { "{\n          font-size": "0.8rem" },
            style: { color: _vm.titleColor },
          },
          [_c("span", [_vm._v(_vm._s(_vm.title))])]
        ),
        _c(
          "div",
          {
            staticStyle: { "word-wrap": "break-word" },
            style: { color: _vm.contentColor },
          },
          [_vm._v(" " + _vm._s(_vm.content) + " ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }