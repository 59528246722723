<template>
  <div>
    <asoft-modal-header
      label="Add a Product Line to this Order"
      @close="() => onCancel()"
    >
    </asoft-modal-header>
    <mdb-modal-body>
      <div>
        <mdb-container fluid>
          <div v-if="filteredProductLines.length > 0">
            <div
              v-for="(productLine, index) in filteredProductLines"
              :key="index"
              class="mb-3 mx-2 d-flex flex-row"
            >
              <awgt-input
                :id="'productLineSelectionRadiobutton' + index"
                type="radio"
                name="productLineSelectionRadiobutton"
                v-model="selectedProductLineReference"
                label=" "
                style="min-width: 50px"
                :radioValue="productLine.Reference"
              />
              <!-- TODO: This should only pull chronologies relevant at the PricingForDt -->
              <h5 class="my-0">{{ productLine.Name }}</h5>
            </div>
          </div>
          <div v-else class="text-center">
            <i>No product lines were found for the given Priced For Date</i>
          </div>
        </mdb-container>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="float-right">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSave"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onCancel"
        >
          <mdb-icon icon="times" class="mr-1" />Cancel
        </awgt-std-button>
      </div>
    </mdb-modal-footer>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style scoped></style>
<script>
import { mdbContainer, mdbIcon, mdbModalBody, mdbModalFooter } from "mdbvue";
import { mapGetters } from "vuex";
import { sharedMethods } from "@/shared/shared";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbIcon,
    mdbModalBody,
    mdbModalFooter,
    asoftModalHeader,
    awgtInput,
    AwgtStdButton,
    mdbContainer,
  },

  data() {
    return {
      filteredProductLines: null, //Product lines at the PricedForDt
      selectedProductLineReference: "",
    };
  },

  props: {
    productLines: {
      type: Array,
    },

    pricedForDt: {
      type: Date,
    },
  },

  watch: {
    productLines: {
      deep: false,
      immediate: true,
      handler(to) {
        this.filteredProductLines = this.filterProductLines(to);
      },
    },
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getProductMargins",
      "getItineraryEntryTypes",
      "getEmptyItineraryEntry",
      "getItineraryEntrySubTypesByItineraryEntryType",
      "getPrimaryOrderProductSubTypesForItineraryEntrySubType",
    ]),
  },
  methods: {
    ...sharedMethods,

    filterProductLines(productLines) {
      return productLines
        .map(this.getfilteredProductLine)
        .filter((x) => x != undefined);
    },

    getfilteredProductLine(productLine) {
      let currentChronology = productLine.Chronology.find(
        (c) =>
          new Date(c.ActiveFromDt) <= this.pricedForDt &&
          (c.ActiveToDt == null || new Date(c.ActiveToDt) >= this.pricedForDt)
      );
      if (currentChronology == null) return undefined;

      return {
        Reference: productLine.Reference,
        Name: currentChronology.Name,
      };
    },

    onCancel() {
      this.product = null;
      this.$emit("close", false);
    },

    onSave() {
      this.$emit("productLineSelected", this.selectedProductLineReference);
      this.$emit("close", false);
    },
  },
};
</script>
