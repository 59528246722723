<template>
  <mdb-container fluid>
    <h1 class="text-center">School Statistics and Details Report</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        label="Company Section"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getCompnaySection"
        itemTextProperty="Name"
        v-model="companySection"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Booking Status"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getBookingStatus"
        itemTextProperty="Name"
        v-model="bookingStatus"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Years"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getYear"
        itemTextProperty="Name"
        v-model="year"
        bg
      ></awgt-std-dropdown>
      <asoftDatePicker
        v-model="date"
        label="Date"
        autoHide
        displayDateFormat="D MMM YYYY"
        dataDateFormat="YYYY-MM-DDTHH:mm:ss"
        setTime="00:00:00.000"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 90px"
      />
    </div>
  </mdb-container>
</template>
<script>
import { mdbContainer } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
export default {
  data() {
    return {
      bookingStatus: null,
      year: null,
      companySection: null,
      date: null,
      getCompnaySection: [{ Code: 1, Name: "AWGT" }],
      getBookingStatus: [{ Code: 1, Name: "Booked" }],
      getYear: [{ Code: 1, Name: "2020" }],
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
    asoftDatePicker,
  },
};
</script>
