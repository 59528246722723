<template>
  <div class="md-form">
    <mdb-input
      type="text"
      :disabled="disabled"
      :placeholder="placeholder"
      :id="id"
      :class="`timepicker ${
        pickerDialogOpen ? 'picker__input picker__input--active' : ''
      }`"
      :value="innerValue"
      @click.native="handlePickerDialogToggle"
      @keydown.native.enter="handlePickerDialogToggle"
      :icon="icon"
      :iconClass="iconClass"
      :label="label"
      :far="far"
      :bg="bg"
      :fal="fal"
      :fab="fab"
      :outline="outline"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      read-only
    />
    <transition name="fade" mode="out-in">
      <div v-if="pickerDialogOpen" class="clockpicker picker picker--opened">
        <div class="picker__holder" @click.self="away">
          <div class="picker__frame">
            <div class="picker__wrap">
              <div class="picker__box">
                <time-display
                  :hours="computedHours"
                  :color="color"
                  :minutes="computedMinutes"
                  :dayTime="dayTime"
                  :unitsMode="unitsMode"
                  @units-mode-change="handleModeChange"
                  :hoursFormat="hoursFormat"
                />
                <clockpicker-plate
                  @minutes="handleMinutesChange"
                  @hours="handleHoursChange"
                  @change="handleModeChange"
                  :hours="hours"
                  :minutes="minutes"
                  :unitsMode="computedMode"
                  :color="color"
                  :hoursFormat="hoursFormat"
                />
                <clockpicker-am-pm-block
                  v-if="hoursFormat === 12"
                  @day-time-change="handleDayTimeChange"
                />
                <clockpicker-footer
                  @done-clicked="handleDoneClicked"
                  @clear-clicked="handleClearClicked"
                  @cancel-clicked="handleCancelClicked"
                  :done="doneLabel"
                  :clear="clearLabel"
                  :cancel="cancelLabel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TimeDisplay from "./TimeDisplay";
import ClockpickerPlate from "./ClockpickerPlate";
import ClockpickerAmPmBlock from "./ClockpickerAmPmBlock";
import ClockpickerFooter from "./ClockpickerFooter";
import { mdbInput } from "mdbvue";

const TimePicker = {
  props: {
    color: {
      type: String,
      default: "primary-color",
    },
    placeholder: {
      type: String,
    },
    id: {
      type: [String, Boolean],
      default: false,
    },
    label: {
      type: String,
    },
    hoursFormat: {
      type: Number,
      default: 12,
    },
    getValue: {
      type: Function,
    },
    doneLabel: {
      type: String,
      default: "Done",
    },
    clearLabel: {
      type: String,
      default: "Clear",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    icon: String,
    iconClass: String,
    far: Boolean,
    fab: Boolean,
    fal: Boolean,
    outline: Boolean,
    bg: Boolean,
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TimeDisplay,
    ClockpickerPlate,
    ClockpickerAmPmBlock,
    ClockpickerFooter,
    mdbInput,
  },
  data() {
    return {
      innerValue: this.value,
      pickerDialogOpen: false,
      unitsMode: "h",
      dayTime: "am",
      minutes: 0,
      hours: 12,
      ready: false,
    };
  },
  computed: {
    computedMinutes() {
      return this.minutes < 10
        ? `0${this.minutes.toString()}`
        : `${this.minutes.toString()}`;
    },
    computedHours() {
      return this.hours < 10
        ? `0${this.hours.toString()}`
        : this.hours === 24
        ? `00`
        : `${this.hours.toString()}`;
    },
    computedMode() {
      return this.unitsMode;
    },
  },

  watch: {
    value(to, from) {
      if (to != from) {
        this.innerValue = to;
        this.initialiseTime();
      }
    },
  },

  methods: {
    handlePickerDialogToggle() {
      if (this.disabled == false)
        this.pickerDialogOpen = !this.pickerDialogOpen;
    },
    handleModeChange(mode) {
      this.unitsMode = mode;
    },
    handleDayTimeChange(dayTime) {
      this.dayTime = dayTime;
    },
    handleMinutesChange(minutes) {
      this.minutes = minutes;
      this.ready = true;
    },
    handleHoursChange(hours) {
      this.hours = hours;
    },
    handleDoneClicked() {
      this.innerValue =
        this.hoursFormat === 12
          ? `${this.computedHours}:${this.computedMinutes}${this.dayTime}`
          : `${this.computedHours}:${this.computedMinutes}`;
      this.unitsMode = "h";
      this.emitValue(this.innerValue);
      this.handlePickerDialogToggle();
      this.$emit("close");
    },
    handleClearClicked() {
      this.innerValue = "";
      this.unitsMode = "h";
      this.emitValue(this.innerValue);
      this.handlePickerDialogToggle();
      this.$emit("close");
    },
    handleCancelClicked() {
      this.initialiseTime();
      this.unitsMode = "h";
      this.handlePickerDialogToggle();
      this.$emit("close");
    },
    emitValue(innerValue) {
      this.$emit("input", innerValue);
    },
    away() {
      this.$emit("close");
      this.pickerDialogOpen = false;
    },
    handleKeyup(e) {
      switch (e.keyCode) {
        case 8:
          this.unitsMode = "h";
          break;
        case 13:
          if (this.ready) {
            this.handleDoneClicked();
            this.ready = false;
          }
          break;
        case 27:
          this.pickerDialogOpen = false;
          break;
      }
    },
    initialiseTime() {
      if (this.innerValue) {
        if (this.hoursFormat === 12) {
          this.hours = parseInt(this.innerValue.split(":")[0]);
          this.minutes = parseInt(this.innerValue.split(":")[1]);
          this.handleDayTimeChange(
            this.innerValue.split(":")[1].replace(/[0-9]/g, "").toString()
          );
        } else {
          this.hours = parseInt(this.innerValue.split(":")[0]);
          this.minutes = parseInt(this.innerValue.split(":")[1]);
        }
      }
    },
  },
  mounted() {
    this.initialiseTime();
  },
  updated() {
    if (this.pickerDialogOpen) {
      setTimeout(() => window.addEventListener("keyup", this.handleKeyup), 800);
    } else {
      window.removeEventListener("keyup", this.handleKeyup);
    }
  },
};

export default TimePicker;
</script>
