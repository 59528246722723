import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { sharedMethods } from "@/shared/shared";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getProducts(
    productTypeCodes = "PT_Tr,PT_An,PT_Ay",
    productReference = null,
    providerSupplierName = null,
    resellerSupplierName = null,
    providerSupplierReference = null,
    resellerSupplierReference = null,
    productName = null,
    productRegionId = null,
    productSubdivision = null,
    yearGroups = null,
    productAvailability = null,
    minDuration = null,
    maxDuration = null,
    matchAllSearchCriteriaInd = true,
    includeCanBeSoldSeparatelyProducts = true,
    includeCanOnlyBeSoldAsRelatedProducts = true,
    includeDeletedItemsInd = false,
    includeDraftProductsInd = true,
    includePublishedProductsInd = true
  ) {
    try {
      logger.get(loggingSource.UIProduct).info("getProducts...");
      logger
        .get(loggingSource.UIProduct)
        .debug(
          "Parameters => productReference: %s, productTypeCodes: %s, providerSupplierName: %s, resellerSupplierName: %s, providerSupplierReference: %s, " +
            "resellerSupplierReference: %s, productName: %s, productRegionId: %s, productSubdivision: %s, " +
            "yearGroups: %s, productAvailability: %s, min duration: %s, max duration: %s, matchAllSearchCriteriaInd: %s, includeCanBeSoldSeparatelyProducts: %s, " +
            "includeCanOnlyBeSoldAsRelatedProducts: %s, includeDeletedItemsInd: %s, includeDraftProductsInd: %s, includePublishedProductsInd: %s",
          productReference,
          productTypeCodes,
          providerSupplierName,
          resellerSupplierName,
          providerSupplierReference,
          resellerSupplierReference,
          productName,
          productRegionId,
          productSubdivision,
          yearGroups,
          productAvailability,
          minDuration,
          maxDuration,
          matchAllSearchCriteriaInd,
          includeCanBeSoldSeparatelyProducts,
          includeCanOnlyBeSoldAsRelatedProducts,
          includeDeletedItemsInd,
          includeDraftProductsInd,
          includePublishedProductsInd
        );

      logger.get(loggingSource.UIProduct).time("getProducts call");
      const response = await api.client
        .get("product", {
          params: {
            reference: sharedMethods.toStringReferenceLower(productReference),
            productTypeCodes: productTypeCodes,
            providerSupplierName: providerSupplierName,
            resellerSupplierName: resellerSupplierName,
            providerSupplierReference: providerSupplierReference,
            resellerSupplierReference: resellerSupplierReference,
            productName: productName,
            providerSupplierRegionId: productRegionId,
            productSubdivision: productSubdivision,
            yearGroups: yearGroups,
            productAvailability: productAvailability,
            minDuration: minDuration,
            maxDuration: maxDuration,
            matchAllSearchCriteriaInd: matchAllSearchCriteriaInd,
            includeCanBeSoldSeparatelyProducts:
              includeCanBeSoldSeparatelyProducts,
            includeCanOnlyBeSoldAsRelatedProducts:
              includeCanOnlyBeSoldAsRelatedProducts,
            IncludeDeletedItemsInd: includeDeletedItemsInd,
            includeDraftProductsInd: includeDraftProductsInd,
            includePublishedProductsInd: includePublishedProductsInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("getProducts call");
      const products = response.data;

      logger.get(loggingSource.UIProduct).time("getProducts Stringify");
      const productsString = JSON.stringify(products);
      logger.get(loggingSource.UIProduct).timeEnd("getProducts Stringify");

      logger.get(loggingSource.UIProduct).time("getProducts Parse");
      JSON.parse(productsString);
      logger.get(loggingSource.UIProduct).timeEnd("getProducts Parse");

      logger
        .get(loggingSource.UIProduct)
        .trace("getProducts (before sanitisation) response: %s", response);

      //Html sanitise product data and AWGTNotes.
      for (let product of products) {
        product.Description = sharedMethods.htmlSanitize(product.Description);
        product.AWGTNotes = sharedMethods.htmlSanitize(product.AWGTNotes);
        product.PreProductItineraryComment = sharedMethods.htmlSanitize(
          product.PreProductItineraryComment
        );
        product.PostProductItineraryComment = sharedMethods.htmlSanitize(
          product.PostProductItineraryComment
        );
        product.BookingFeeDetail = sharedMethods.htmlSanitize(
          product.BookingFeeDetail
        );
        for (let cpl of product.CommercialProductLines) {
          cpl.Description = sharedMethods.htmlSanitize(cpl.Description);
          cpl.PurchasingReminder = sharedMethods.htmlSanitize(
            cpl.PurchasingReminder
          );
        }
      }

      logger
        .get(loggingSource.UIProduct)
        .trace("getProducts (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("getProducts (after sanitisation) products: %s", products);

      return products;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("getProducts call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...getProducts");
    }
  },

  //Get products list with only Code and Name
  async getProductDropdown(
    productTypeCodes = "PT_Tr,PT_An,PT_Ay",
    includeDeletedItemsInd = false,
    includeDraftProductsInd = false,
    includePublishedProductsInd = true
  ) {
    try {
      logger.get(loggingSource.UIProduct).info("getProductDropdown...");
      logger
        .get(loggingSource.UIProduct)
        .debug(
          "Parameters => productTypeCodes: %s,  includeDeletedItemsInd: %s, includeDraftProductsInd: %s, includePublishedProductsInd: %s",
          productTypeCodes,
          includeDeletedItemsInd,
          includeDraftProductsInd,
          includePublishedProductsInd
        );

      logger.get(loggingSource.UIProduct).time("getProductDropdown call");
      const response = await api.client
        .get("product/Dropdown", {
          params: {
            productTypeCodes: productTypeCodes,
            includeDraftProductsInd: includeDraftProductsInd,
            includePublishedProductsInd: includePublishedProductsInd,
            IncludeDeletedItemsInd: includeDeletedItemsInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("getProductDropdown call");
      const products = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace("getProductDropdown response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("getProductDropdown product: %s", products);

      return products;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("getProductDropdown call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...getProductDropdown");
    }
  },

  //Get brief product list for product search screen.
  async getProductsList(
    currentPage = null,
    productTypeCodes = "PT_Tr,PT_An,PT_Ay",
    productReference = null,
    providerSupplierName = null,
    resellerSupplierName = null,
    providerSupplierReference = null,
    resellerSupplierReference = null,
    productName = null,
    productRegionId = null,
    productSubdivision = null,
    yearGroups = null,
    productAvailability = null,
    minDuration = null,
    maxDuration = null,
    matchAllSearchCriteriaInd = true,
    includeCanBeSoldSeparatelyProducts = true,
    includeCanOnlyBeSoldAsRelatedProducts = true,
    includeDeletedItemsInd = false,
    includeDraftProductsInd = false,
    includePublishedProductsInd = true
  ) {
    try {
      logger.get(loggingSource.UIProduct).info("getProductsList...");
      logger
        .get(loggingSource.UIProduct)
        .debug(
          "Parameters => currentPage: %s, productReference: %s, productTypeCodes: %s, providerSupplierName: %s, resellerSupplierName: %s, providerSupplierReference: %s, " +
            "resellerSupplierReference: %s, productName: %s, productRegionId: %s, productSubdivision: %s, " +
            "yearGroups: %s, productAvailability: %s, min duration: %s, max duration: %s, matchAllSearchCriteriaInd: %s, includeCanBeSoldSeparatelyProducts: %s, " +
            "includeCanOnlyBeSoldAsRelatedProducts: %s, includeDeletedItemsInd: %s, includeDraftProductsInd: %s, includePublishedProductsInd: %s",
          currentPage,
          productReference,
          productTypeCodes,
          providerSupplierName,
          resellerSupplierName,
          providerSupplierReference,
          resellerSupplierReference,
          productName,
          productRegionId,
          productSubdivision,
          yearGroups,
          productAvailability,
          minDuration,
          maxDuration,
          matchAllSearchCriteriaInd,
          includeCanBeSoldSeparatelyProducts,
          includeCanOnlyBeSoldAsRelatedProducts,
          includeDeletedItemsInd,
          includeDraftProductsInd,
          includePublishedProductsInd
        );

      logger.get(loggingSource.UIProduct).time("getProductsList call");
      const response = await api.client
        .get("product/ProductList", {
          params: {
            currentPage: currentPage,
            reference: sharedMethods.toStringReferenceLower(productReference),
            productTypeCodes: productTypeCodes,
            providerSupplierName: providerSupplierName,
            resellerSupplierName: resellerSupplierName,
            providerSupplierReference: providerSupplierReference,
            resellerSupplierReference: resellerSupplierReference,
            productName: productName,
            providerSupplierRegionId: productRegionId,
            productSubdivision: productSubdivision,
            yearGroups: yearGroups,
            productAvailability: productAvailability,
            minDuration: minDuration,
            maxDuration: maxDuration,
            matchAllSearchCriteriaInd: matchAllSearchCriteriaInd,
            includeCanBeSoldSeparatelyProducts:
              includeCanBeSoldSeparatelyProducts,
            includeCanOnlyBeSoldAsRelatedProducts:
              includeCanOnlyBeSoldAsRelatedProducts,
            IncludeDeletedItemsInd: includeDeletedItemsInd,
            includeDraftProductsInd: includeDraftProductsInd,
            includePublishedProductsInd: includePublishedProductsInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("getProductsList call");
      let products = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace("getProductsList response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("getProductsList products: %s", products);

      return products;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("getProductsList call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...getProductsList");
    }
  },

  async getProductByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIProduct).info("getProductByReference...");
      logger
        .get(loggingSource.UIProduct)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger.get(loggingSource.UIProduct).time("getProductByReference call");
      const response = await api.client
        .get(`product/${sharedMethods.toStringReferenceLower(reference)}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("getProductByReference call");
      const product = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace(
          "getProductByReference (before sanitisation) response: %s",
          response
        );

      //Html sanitise product data and AWGT notes
      product.Description = sharedMethods.htmlSanitize(product.Description);
      product.AWGTNotes = sharedMethods.htmlSanitize(product.AWGTNotes);
      product.PreProductItineraryComment = sharedMethods.htmlSanitize(
        product.PreProductItineraryComment
      );
      product.PostProductItineraryComment = sharedMethods.htmlSanitize(
        product.PostProductItineraryComment
      );
      product.BookingFeeDetail = sharedMethods.htmlSanitize(
        product.BookingFeeDetail
      );
      for (let cpl of product.CommercialProductLines) {
        cpl.Description = sharedMethods.htmlSanitize(cpl.Description);
        cpl.PurchasingReminder = sharedMethods.htmlSanitize(
          cpl.PurchasingReminder
        );
      }

      logger
        .get(loggingSource.UIProduct)
        .trace(
          "getProductByReference (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIProduct)
        .debug(
          "getProductByReference (after sanitisation) product: %s",
          product
        );

      return product;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("getProductByReference call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...getProductByReference");
    }
  },

  async postProduct(productPayload) {
    try {
      logger.get(loggingSource.UIProduct).info("postProduct...");
      logger
        .get(loggingSource.UIProduct)
        .debug("Parameters => productPayload: %s", productPayload);

      logger.get(loggingSource.UIProduct).time("postProduct call");
      const response = await api.client
        .post("product", productPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("postProduct call");
      const product = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace("postProduct (before sanitisation) response: %s", response);

      //Html sanitise product data and AWGT notes
      product.Description = sharedMethods.htmlSanitize(product.Description);
      product.AWGTNotes = sharedMethods.htmlSanitize(product.AWGTNotes);
      product.PreProductItineraryComment = sharedMethods.htmlSanitize(
        product.PreProductItineraryComment
      );
      product.PostProductItineraryComment = sharedMethods.htmlSanitize(
        product.PostProductItineraryComment
      );
      product.BookingFeeDetail = sharedMethods.htmlSanitize(
        product.BookingFeeDetail
      );
      for (let cpl of product.CommercialProductLines) {
        cpl.Description = sharedMethods.htmlSanitize(cpl.Description);
        cpl.PurchasingReminder = sharedMethods.htmlSanitize(
          cpl.PurchasingReminder
        );
      }

      logger
        .get(loggingSource.UIProduct)
        .trace("postProduct (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("postProduct (after sanitisation) product: %s", product);

      return product;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("postProduct call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...postProduct");
    }
  },

  async putProduct(productPayload) {
    try {
      logger.get(loggingSource.UIProduct).info("putProduct...");
      logger
        .get(loggingSource.UIProduct)
        .debug("Parameters => productPayload: %s", productPayload);

      logger.get(loggingSource.UIProduct).time("putProduct call");
      const response = await api.client
        .put("product", productPayload)
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("putProduct call");
      const product = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace("putProduct (before sanitisation) response: %s", response);

      //Html sanitise product data and AWGT notes
      product.Description = sharedMethods.htmlSanitize(product.Description);
      product.AWGTNotes = sharedMethods.htmlSanitize(product.AWGTNotes);
      product.PreProductItineraryComment = sharedMethods.htmlSanitize(
        product.PreProductItineraryComment
      );
      product.PostProductItineraryComment = sharedMethods.htmlSanitize(
        product.PostProductItineraryComment
      );
      product.BookingFeeDetail = sharedMethods.htmlSanitize(
        product.BookingFeeDetail
      );
      for (let cpl of product.CommercialProductLines) {
        cpl.Description = sharedMethods.htmlSanitize(cpl.Description);
        cpl.PurchasingReminder = sharedMethods.htmlSanitize(
          cpl.PurchasingReminder
        );
      }

      logger
        .get(loggingSource.UIProduct)
        .trace("putProduct (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("putProduct (after sanitisation) product: %s", product);

      return product;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("putProduct call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...putProduct");
    }
  },

  async deleteProduct(productPayload) {
    try {
      logger.get(loggingSource.UIProduct).info("deleteProduct...");
      logger
        .get(loggingSource.UIProduct)
        .debug("Parameters => productPayload: %s", productPayload);

      logger.get(loggingSource.UIProduct).time("deleteProduct call");
      await api.client
        .delete(
          `product/${
            productPayload.Reference
          }?concurrencyRV=${encodeURIComponent(productPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("deleteProduct call");
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("deleteProduct call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...deleteProduct");
    }
  },

  async publishProduct(productPayload) {
    try {
      logger.get(loggingSource.UIProduct).info("publishProduct...");

      logger
        .get(loggingSource.UIProduct)
        .debug("Parameters => productPayload: %s", productPayload);

      logger.get(loggingSource.UIProduct).time("publishProduct call");
      const response = await api.client
        .put(
          `Product/publishProduct/${
            productPayload.Reference
          }?concurrencyRV=${encodeURIComponent(productPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIProduct).timeEnd("publishProduct call");
      const product = response.data;

      logger
        .get(loggingSource.UIProduct)
        .trace("publisProduct (before sanitisation) response: %s", response);

      //Html sanitise product data and AWGT notes
      product.Description = sharedMethods.htmlSanitize(product.Description);
      product.AWGTNotes = sharedMethods.htmlSanitize(product.AWGTNotes);
      product.PreProductItineraryComment = sharedMethods.htmlSanitize(
        product.PreProductItineraryComment
      );
      product.PostProductItineraryComment = sharedMethods.htmlSanitize(
        product.PostProductItineraryComment
      );
      product.BookingFeeDetail = sharedMethods.htmlSanitize(
        product.BookingFeeDetail
      );
      for (let cpl of product.CommercialProductLines) {
        cpl.Description = sharedMethods.htmlSanitize(cpl.Description);
        cpl.PurchasingReminder = sharedMethods.htmlSanitize(
          cpl.PurchasingReminder
        );
      }

      logger
        .get(loggingSource.UIProduct)
        .trace("publishProduct (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIProduct)
        .debug("publishProduct (after sanitisation) product: %s", product);

      return product;
    } catch (error) {
      logger.get(loggingSource.UIProduct).timeEnd("publishProduct call");
      logger.get(loggingSource.UIProduct).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIProduct).info("...publishProduct");
    }
  },
};
