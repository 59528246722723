<template>
  <div>
    <mdb-tbl striped>
      <mdb-tbl-body>
        <tr v-for="tc in contactsArray" :key="tc.BookingContactId">
          <td>
            <div class="d-flex flex-wrap">
              <div class="flex-nowrap mr-3">
                <mdb-icon icon="user" color="primary" class="mr-1" size="1x" />
                {{ tc.Name }}
              </div>
              <div v-if="tc.Phone.length > 0" class="flex-nowrap mr-3">
                <mdb-icon icon="phone" color="primary" class="mr-1" size="1x" />
                {{ tc.Phone }}
              </div>
              <div v-if="tc.EmailAddress.length > 0" class="flex-nowrap mr-3">
                <mdb-icon icon="at" color="primary" class="mr-1" size="1x" />
                {{ tc.EmailAddress }}
              </div>
              <div v-if="tc.IsBookingContact == true" class="flex-nowrap mr-3">
                <mdb-icon
                  icon="calendar-alt"
                  far
                  color="primary"
                  class="mr-1"
                  size="1x"
                />
                Booking Contact
              </div>
              <div v-if="tc.IsTripContact == true" class="flex-nowrap mr-3">
                <mdb-icon icon="plane" color="primary" class="mr-1" size="1x" />
                Travel Contact
              </div>
            </div>
          </td>
          <td style="vertical-align: middle">
            <div class="d-flex flex-nowrap float-right">
              <button
                class="mx-2"
                @click="initiateEditBookingContact(tc.BookingContactId)"
                style="border: none; background-color: inherit"
                type="button"
              >
                <mdb-icon color="primary" icon="edit" size="2x" />
              </button>
              <button
                class="mx-2"
                @click="initiateDeleteBookingContact(tc.BookingContactId)"
                style="border: none; background-color: inherit"
                type="button"
              >
                <mdb-icon color="primary" icon="trash-alt" size="2x" />
              </button>
              <mdb-icon
                icon="arrow-up"
                color="primary"
                class="mx-2"
                size="2x"
              />
              <mdb-icon
                icon="arrow-down"
                color="primary"
                class="mx-2"
                size="2x"
              />
            </div>
          </td>
        </tr>
      </mdb-tbl-body>
    </mdb-tbl>

    <!--
        Trip Contacts Edit Dialog
    -->
    <div>
      <mdb-modal
        size="md"
        v-if="showEditBookingContactModal == true"
        :show="showEditBookingContactModal"
      >
        <mdb-modal-header>
          <mdb-modal-title>Edit Travel Contact</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div class="d-flex flex-nowrap flex-row">
            <mdb-icon icon="user" color="primary" class="mt-4" size="2x" />
            <awgt-input
              v-model="bookingContact.Name"
              counter
              :counterMaxValue="85"
              maxlength="85"
              label="Name"
              bg
              class="flex-grow-1 mx-2"
            />
          </div>

          <div class="d-flex flex-nowrap flex-row">
            <mdb-icon icon="phone" color="primary" class="mt-4" size="2x" />
            <asoft-phone-input
              v-model="bookingContact.Phone"
              maxlength="20"
              label="Phone"
              style="width: 180px"
              bg
              class="flex-grow-1 mx-2"
            />
          </div>

          <div class="d-flex flex-nowrap flex-row">
            <mdb-icon icon="at" color="primary" class="mt-4" size="2x" />
            <awgt-input
              v-model="bookingContact.EmailAddress"
              counter
              :counterMaxValue="254"
              maxlength="254"
              label="Email"
              bg
              class="flex-grow-1 mx-2"
            />
          </div>

          <div class="d-flex flex-row flex-wrap min-width:120px;">
            <awgt-input
              id="bookingContactType_IsBookingContact"
              type="checkbox"
              v-model="bookingContact.IsBookingContact"
              name="bookingContactType"
              label="Booking Contact"
              class="mx-2"
              style="min-width: 210px; width: 210px"
              :tooltip="getTooltipsTextByCode(26)"
            />
            <awgt-input
              id="bookingContactType_IsTravelContact"
              type="checkbox"
              v-model="bookingContact.IsTripContact"
              name="travelContactType"
              label="Travel Contact"
              class="mx-2"
              style="min-width: 195px; width: 195px"
              :tooltip="getTooltipsTextByCode(27)"
            />
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="saveBookingContact()"
          >
            Save
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="showEditBookingContactModal = false"
          >
            Cancel
          </awgt-std-button>
        </mdb-modal-footer>
      </mdb-modal>
    </div>

    <!--
        Deletion Confirmation Dialog
    -->
    <div>
      <mdb-modal
        size="sm"
        v-if="showDeletionConfirmationModal == true"
        :show="showDeletionConfirmationModal"
      >
        <mdb-modal-header>
          <mdb-modal-title>Confirm Delete</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body
          >Press "Confirm" to delete contact "{{
            this.contactsArray[this.currentBookingContactIdx].Name
          }}"?
        </mdb-modal-body>
        <mdb-modal-footer>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="confirmDeleteBookingContact()"
          >
            <mdb-icon icon="check" class="mr-1" />
            Confirm
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="showDeletionConfirmationModal = false"
          >
            <mdb-icon icon="times" class="mr-1" />Cancel
          </awgt-std-button>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import {
  mdbIcon,
  mdbTbl,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput.vue";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "travel-contacts",
  components: {
    mdbIcon,
    AwgtStdButton,
    mdbTbl,
    mdbTblBody,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    awgtInput,
    asoftPhoneInput,
  },
  data() {
    return {
      showEditBookingContactModal: false,
      showDeletionConfirmationModal: false,
      currentBookingContactIdx: 0,
      currentBookingContactId: 0,
      contactsArray: [],
      bookingContact: {},
    };
  },
  computed: {
    ...mapGetters(["getEmptyBookingContact", "getTooltipsTextByCode"]),
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    value(to) {
      this.contactsArray = to;
    },
  },
  methods: {
    //The addBookingContact method is exposed to the outside.
    addBookingContact(bookingContactId) {
      this.initiateAddBookingContact(bookingContactId);
    },
    initiateEditBookingContact(bookingContactId) {
      let idx = this.$lodash.findIndex(
        this.contactsArray,
        (c) => c.BookingContactId == bookingContactId
      );

      //Clone the value in the array
      this.bookingContact = Object.assign({}, this.contactsArray[idx]);
      this.currentBookingContactIdx = idx;

      this.showEditBookingContactModal = true;
    },
    initiateAddBookingContact() {
      this.bookingContact = this.$lodash.cloneDeep(this.getEmptyBookingContact);
      this.bookingContact.BookingContactId = 0;
      this.bookingContact.SortOrder = this.contactsArray.length;
      this.currentBookingContactIdx = -1;
      this.$forceUpdate();

      this.showEditBookingContactModal = true;
    },
    initiateDeleteBookingContact(bookingContactId) {
      let idx = this.$lodash.findIndex(
        this.contactsArray,
        (c) => c.BookingContactId == bookingContactId
      );
      this.currentBookingContactIdx = idx;
      this.showDeletionConfirmationModal = true;
    },
    confirmDeleteBookingContact() {
      this.contactsArray.splice(this.currentBookingContactIdx, 1);
      this.$forceUpdate();
      this.$emit("input", this.contactsArray);
      this.showDeletionConfirmationModal = false;
    },
    saveBookingContact() {
      if (this.currentBookingContactIdx >= 0) {
        //This is an edit operation
        this.$set(
          this.contactsArray,
          this.currentBookingContactIdx,
          this.bookingContact
        );
      } else {
        //This is an add operation
        this.contactsArray.push(this.bookingContact);
      }

      this.$emit("input", this.contactsArray);
      this.$forceUpdate();
      this.showEditBookingContactModal = false;
    },
  },
  created() {
    this.contactsArray = this.value;
  },
};
</script>
