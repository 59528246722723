<template>
  <div
    v-if="value != undefined && templateArray.length > 0 && value.length > 0"
  >
    <template v-for="(template, index) in templateArray">
      <div v-bind:key="index">
        <awgt-std-switch
          v-if="index > 0"
          offLabel
          :onLabel="`The ${template.Name} is the same as the ${
            addresses.find((item) => {
              return item.Type == template.KeepConsistentWith;
            }).Name
          }`"
          v-model="keepAddressesConsistent[index]"
        ></awgt-std-switch>
        <div
          v-show="
            keepAddressesConsistent[index] == null ||
            keepAddressesConsistent[index] == false
          "
        >
          <!-- <h4 class="mx-2 mt-2 mb-0">
            {{ template.Name }}
          </h4> -->
          <div class="d-flex flex-row flex-nowrap flex-grow-1 mr-4">
            <mdb-icon
              :icon="template.Icon"
              color="primary"
              class="mx-2 mt-3"
              size="2x"
            />
            <div class="md-form flex-grow-1 mx-2">
              <input
                :id="template.Reference"
                type="text"
                :ref="template.Reference"
                onfocus="value=''"
                onblur="value=''"
                @place_changed="fillInAddress(template.Reference, index)"
                class="form-control"
              />
            </div>
          </div>
          <awgt-input
            v-model="addresses[index].Street"
            :counterMaxValue="90"
            maxlength="90"
            counter
            label="Street"
            :data-google-places-tag="'route' + template.Reference"
            data-google-places-vmodel-tag="Street"
            bg
            class="flex-grow-1 mx-2"
          />
          <div class="d-flex flex-row flex-wrap">
            <awgt-input
              v-model="addresses[index].Country"
              :counterMaxValue="50"
              maxlength="50"
              counter
              label="Country"
              :data-google-places-tag="'country' + template.Reference"
              data-google-places-vmodel-tag="Country"
              bg
              style="min-width: 300px"
              class="flex-grow-1 mx-2"
            />
            <awgt-input
              v-model="addresses[index].Locality"
              :counterMaxValue="50"
              maxlength="50"
              counter
              label="Locality"
              :data-google-places-tag="'locality' + template.Reference"
              data-google-places-vmodel-tag="Locality"
              bg
              style="min-width: 300px"
              class="flex-grow-1 mx-2"
            />
            <div class="d-flex flex-row flex-nowrap flex-grow-1">
              <awgt-std-dropdown
                :items="getAddressSubdivisions(false)"
                itemValueProperty="Code"
                itemTextProperty="Name"
                label="Subdivision"
                :data-google-places-tag="
                  'administrative_area_level_1' + template.Reference
                "
                data-google-places-vmodel-tag="Subdivision"
                UseShortNames
                style="min-width: 90px"
                class="flex-grow-1 mx-2"
                v-model="addresses[index].Subdivision"
                bg
              ></awgt-std-dropdown>
              <awgt-input
                v-model="addresses[index].Postcode"
                :counterMaxValue="12"
                maxlength="12"
                counter
                label="Postcode"
                :data-google-places-tag="'postal_code' + template.Reference"
                data-google-places-vmodel-tag="Postcode"
                style="min-width: 90px"
                bg
                class="flex-grow-1 mx-2"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mdbIcon } from "mdbvue";
import { mapGetters } from "vuex";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";
export default {
  props: {
    template: {
      type: Object,
      required: false,
    },
    value: {
      type: Array,
    },
  },
  data() {
    return {
      addresses: [],
      templateArray: [],
      keepAddressesConsistent: [],
      googlePlacesFunc_Addresses: {},
    };
  },
  components: {
    AwgtStdSwitch,
    mdbIcon,
    AwgtStdDropdown,
    awgtInput,
  },
  computed: {
    ...mapGetters(["getAddressSubdivisions"]),
  },
  methods: {
    async loadGoogleMaps(references) {
      const GoogleMapsApiLoader = require("google-maps-api-loader");
      const self = this;

      GoogleMapsApiLoader({
        libraries: ["places"],
        apiKey: "AIzaSyCPcYSWkidtm2bkL6jzjPxb38j2QV3VBE4", // optional
      }).then(
        (googleApi) => {
          for (let reference of references) {
            self.googlePlacesFunc_Addresses[reference] =
              new googleApi.maps.places.Autocomplete(self.$refs[reference][0], {
                types: ["geocode"],
              });
            self.googlePlacesFunc_Addresses[reference].setFields([
              "address_components",
            ]);

            googleApi.maps.event.addListener(
              self.googlePlacesFunc_Addresses[reference],
              "place_changed",
              function () {
                self.fillInAddress(reference, references.indexOf(reference));
              }
            );
          }
        },
        function (err) {
          this.$log.error(err);
        }
      );
    },
    fillInAddress(reference, ind) {
      let streetNumber = "";
      let val;
      var place = this.googlePlacesFunc_Addresses[reference].getPlace();
      this.$log.info("Returned by the Google Maps API", place);
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (addressType === "street_number") {
          streetNumber = place.address_components[i].short_name;
          continue;
        }
        let e = document.querySelector(
          "*[data-google-places-tag='" + addressType + reference + "']"
        );
        if (e != null) {
          if (e.getAttribute("UseShortNames") == null) {
            val = place.address_components[i].long_name;
          } else val = place.address_components[i].short_name;
          if (addressType === "route") val = streetNumber + " " + val;
          /*
            Setting e.value sets the value in the control but it
            doesn't set the value in the Vue.js DOM. This must be
            done separately.
          */
          this.$lodash.set(
            this.addresses[ind],
            e.getAttribute("data-google-places-vmodel-tag"),
            val
          );
        }
      }
    },
    updateAddressesWithNew(currentAddress, newAddress) {
      if (newAddress != undefined) {
        this.$set(currentAddress, "Street", newAddress.Street);
        this.$set(currentAddress, "Locality", newAddress.Locality);
        this.$set(currentAddress, "Subdivision", newAddress.Subdivision);
        this.$set(currentAddress, "Postcode", newAddress.Postcode);
        this.$set(currentAddress, "Country", newAddress.Country);
      }
    },
    formTemplateForEmptyAddress(template) {
      for (let temp of template) {
        this.addresses.push({
          Name: temp.Name,
          Type: temp.Type,
          Street: "",
          Locality: "",
          Subdivision: "",
          Postcode: "",
          Country: "",
        });
      }
    },
  },
  watch: {
    keepAddressesConsistent: {
      deep: true,
      handler: function (to) {
        for (let i = 0; i < to.length; i++) {
          if (
            this.templateArray[i].KeepConsistentWith != null &&
            to[i] == true
          ) {
            let keepConsistentWithAddress = this.addresses.find((address) => {
              return address.Type == this.templateArray[i].KeepConsistentWith;
            });
            this.updateAddressesWithNew(
              this.addresses[i],
              keepConsistentWithAddress
            );
          }
        }
      },
    },
    addresses: {
      deep: true,
      handler: function (to) {
        if (to) {
          for (let i = 0; i < to.length; i++) {
            if (
              this.templateArray[i]?.KeepConsistentWith != null &&
              this.keepAddressesConsistent[i] == true
            ) {
              let keepConsistentWithAddress = to.find((address) => {
                return address.Type == this.templateArray[i].KeepConsistentWith;
              });
              this.updateAddressesWithNew(to[i], keepConsistentWithAddress);
            }
          }
          this.$emit("input", to);
        }
      },
    },
    value: {
      deep: true,
      handler: function (to) {
        if (to != null) {
          for (let i = 0; i < to.length; i++) {
            if (this.templateArray[i]?.KeepConsistentWith == null) {
              this.keepAddressesConsistent[i] = null;
            } else {
              let keepConsistentWithAddress = to.find((address) => {
                return address.Type == this.templateArray[i].KeepConsistentWith;
              });
              if (keepConsistentWithAddress != undefined) {
                this.keepAddressesConsistent[i] =
                  keepConsistentWithAddress.Street == to[i].Street &&
                  keepConsistentWithAddress.Locality == to[i].Locality &&
                  keepConsistentWithAddress.Subdivision == to[i].Subdivision &&
                  keepConsistentWithAddress.Postcode == to[i].Postcode &&
                  keepConsistentWithAddress.Country == to[i].Country;
              }
            }
          }
          this.addresses = to;
        }
      },
    },
    template: {
      deep: true,
      immediate: true,
      handler: function (to, from) {
        if (to != from) {
          if (to != null) {
            this.templateArray = to.Templates;
            let loadingRefs = [];
            for (let i = 0; i < this.templateArray.length; i++) {
              Object.assign(this.googlePlacesFunc_Addresses, {
                [this.templateArray[i].Reference]: null,
              });
              loadingRefs.push(this.templateArray[i].Reference);
              if (Object.keys(this.$route.params).length == 0) {
                this.keepAddressesConsistent[i] = i == 0 ? null : true;
              }
            }
            if (this.addresses.length == 0)
              this.formTemplateForEmptyAddress(this.templateArray);
            this.loadGoogleMaps(loadingRefs);
          }
        }
      },
    },
  },
};
</script>
