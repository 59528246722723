<template>
  <mdb-container fluid>
    <h1 class="text-center">Travel Booking</h1>

    <mdb-form-inline class="flex-nowrap">
      <span class="ml-2 mr-3" style="min-width: 60px">Client Type</span>
      <awgt-input
        id="searchScope_SchoolClients"
        type="checkbox"
        v-model="includeSchoolClientsInd"
        name="searchScope"
        label="Schools"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
      <awgt-input
        id="searchScope_IndividualClients"
        type="checkbox"
        v-model="includeIndividualClientsInd"
        name="searchScope"
        label="Individuals"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
      <awgt-input
        id="searchScope_OrganisationClients"
        type="checkbox"
        v-model="includeOrganisationClientsInd"
        name="searchScope"
        label="Organisations"
        class="mx-2"
        style="min-width: 60px"
      ></awgt-input>
    </mdb-form-inline>
    <awgt-std-switch
      checked
      offLabel
      class="mt-3"
      onLabel="Match all searching criteria"
      @getValue="
        (value) => {
          matchAllSearchingCriteriaInd = value;
        }
      "
    ></awgt-std-switch>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-input
        v-model="searchReference"
        counter
        :counterMaxValue="100"
        label="Reference"
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
        bg
      ></awgt-input>
      <awgt-std-dropdown
        multiple
        selectAll
        v-model="workgroups"
        maxlength="100"
        style="min-width: 300px"
        :items="workgroupsList"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Workgroups"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        v-model="consultants"
        maxlength="100"
        style="min-width: 300px"
        :items="consultantsList"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Consultants"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        v-model="bookingStatus"
        maxlength="100"
        style="min-width: 300px"
        :items="getBookingStatuses(false)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Status"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        multiple
        selectAll
        v-model="destination"
        maxlength="100"
        style="min-width: 300px"
        :items="getDestinations"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Destination"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        v-model="client"
        maxlength="100"
        style="min-width: 300px"
        :items="getClients"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Clients"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        v-model="bookingYear"
        maxlength="100"
        style="min-width: 300px"
        :items="getYears(2017)"
        itemValueProperty="Code"
        itemTextProperty="Name"
        label="Year"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        multiple
        selectAll
        label="Term"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getSchoolTerms(false)"
        itemTextProperty="Name"
        v-model="terms"
        bg
      ></awgt-std-dropdown>
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <awgt-std-switch
        offLabel
        onLabel="Include deleted travel bookings"
        v-model="includeDeletedTravelBookingsInd"
      ></awgt-std-switch>
      <awgt-std-switch
        checked
        offLabel
        onLabel="Include templates"
        @getValue="
          (value) => {
            includeTemplatesInd = value;
          }
        "
      ></awgt-std-switch>
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearSearchCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSearchBooking"
        >
          <mdb-icon icon="search" class="mr-1" />Search
        </awgt-std-button>
        <awgt-std-button
          v-if="checkPermission('CP_TBC')"
          type="button"
          class="command-button mx-2"
          @click="onAddBooking"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div class="sortingDropDown">
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <awgt-std-dropdown
          label="Sorting Selection"
          class="mx-2 flex-grow-1"
          itemValueProperty="Code"
          :items="sortingSelection"
          itemTextProperty="Name"
          v-model="selectedSortingCondition"
          bg
        ></awgt-std-dropdown>
        <awgt-std-button
          type="button"
          class="command-button float-right"
          style="margin-top: 20px"
          :disabled="selectedSortingCondition === null"
          @click="onClickSort"
        >
          <mdb-icon icon="sort" class="mr-1" />Sort
        </awgt-std-button>
      </div>
    </div>
    <div v-if="travelBookings.length == 0 && isSearched" class="text-center">
      <i>No search result found</i>
    </div>
    <div
      v-if="travelBookings.length > 0"
      class="d-flex flex-grow-1 table-headers"
    >
      <div class="flex-fill-term">
        <span class="headers-frame-header" @click="onSortByTerm"
          >Term
          <mdb-icon v-if="sortByTermInd == true" icon="caret-down" /><mdb-icon
            v-if="sortByTermInd == false"
            icon="caret-up"
        /></span>
      </div>
      <div class="flex-fill-client">
        <span class="headers-frame-header" @click="onSortByClient">
          Client<mdb-icon
            v-if="sortByClientInd == true"
            icon="caret-down"
          /><mdb-icon v-if="sortByClientInd == false" icon="caret-up" />
        </span>
      </div>
      <div class="flex-fill">
        <span class="headers-frame-header" @click="onSortByID">
          Reference<mdb-icon
            v-if="sortByIDInd == true"
            icon="caret-down"
          /><mdb-icon v-if="sortByIDInd == false" icon="caret-up" />
        </span>
      </div>
      <div class="flex-fill-des">
        <span class="headers-frame-header" @click="onSortByDescription">
          Description<mdb-icon
            v-if="sortByDescriptionInd == true"
            icon="caret-down"
          /><mdb-icon v-if="sortByDescriptionInd == false" icon="caret-up" />
        </span>
      </div>
      <div class="flex-fill">
        <span class="headers-frame-header" @click="onSortByDate">
          Start Date<mdb-icon
            v-if="sortByDateInd == true"
            icon="caret-down"
          /><mdb-icon v-if="sortByDateInd == false" icon="caret-up" />
        </span>
      </div>
      <div class="flex-fill">
        <span class="headers-frame-header" @click="onSortByStatus"
          >Status<mdb-icon
            v-if="sortByStatusInd == true"
            icon="caret-down" /><mdb-icon
            v-if="sortByStatusInd == false"
            icon="caret-up"
        /></span>
      </div>
    </div>
    <br />
    <mdb-card
      v-for="(travelBooking, index) in travelBookings"
      :key="index"
      class="mb-1 mx-2"
      :style="travelBooking.IsActive ? 'opacity:1' : 'opacity: 0.5'"
    >
      <mdb-card-header
        @click.native="onToggletravelBookingDetailExpansion(index)"
        tag="button"
        class="flex-nowrap card-header"
      >
        <span class="float-right">
          <mdb-icon
            v-if="travelBookingDetailExpansion[index] == true"
            icon="angle-up"
            size="2x"
            color="primary"
          />
          <mdb-icon v-else icon="angle-down" size="2x" color="primary" />
        </span>
        <div class="d-flex flex-grow-1 table-column">
          <div class="flex-fill-term table-element">
            <span class="heading"
              >Term<span class="header-colon">: </span></span
            >
            <span
              class="booking-term"
              :style="{
                backgroundColor:
                  getSchoolTermColorFromCode(travelBooking.SchoolTerm).trim()
                    .length > 0
                    ? '#' + getSchoolTermColorFromCode(travelBooking.SchoolTerm)
                    : null,
              }"
            >
              {{
                getSchoolTermNameFromCode(travelBooking.SchoolTerm) != undefined
                  ? getSchoolTermNameFromCode(travelBooking.SchoolTerm).slice(
                      -1
                    )
                  : null
              }}
            </span>
          </div>
          <div class="flex-fill-client table-element">
            <span class="heading"
              >Client<span class="header-colon">: </span>
            </span>
            <span>
              {{ travelBooking.Client.DisplayName }}
            </span>
          </div>
          <div class="flex-fill table-element">
            <span class="heading"
              >Reference<span class="header-colon">: </span>
            </span>
            <span>{{ travelBooking.Reference }} </span>
          </div>
          <div class="flex-fill-des table-element">
            <span class="heading"
              >Description<span class="header-colon">: </span>
            </span>
            <span>{{ travelBooking.Description }}</span>
          </div>
          <div class="flex-fill table-element">
            <span class="heading"
              >Start Date<span class="header-colon">: </span>
            </span>
            <span>
              {{
                travelBooking.DepartureDt == null
                  ? ""
                  : convertDateFormat(
                      travelBooking.DepartureDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
              }}
            </span>
          </div>
          <div class="flex-fill table-element">
            <span class="heading"
              >Status<span class="header-colon">: </span>
            </span>
            <span
              >{{ getBookingStatusNameByCode(travelBooking.BookingStatus) }}
            </span>
            <span
              class="status_indicator"
              style="margin-top: 5px"
              :style="{
                backgroundColor:
                  '#' +
                  getBookingStatusColorFromCode(travelBooking.BookingStatus),
              }"
            >
            </span>
          </div>
        </div>
      </mdb-card-header>
      <expansion-region :toggle="travelBookingDetailExpansion[index]">
        <mdb-card-body>
          <template v-if="!travelBooking.IsActive">
            <div class="alert-text" style="text-align: center">
              Deleted
            </div> </template
          >Requested:
          {{
            convertDateFormat(
              travelBooking.RequestedDt,
              $constants.DATETIMEFORMAT_Json,
              $constants.DATETIMEFORMAT_Presentation
            )
          }}
          <br />Is Template: {{ travelBooking.IsTemplate }} <br />
          <div
            class="my-2 float-right"
            v-show="travelBooking.IsActive == false"
          >
            <awgt-std-button
              v-if="
                checkPermission('CP_TBD') &&
                checkPermissionScopeForBookingDelete(index)
              "
              type="button"
              title="Products"
              class="command-button mx-2"
              @click="onUndeleteBooking(travelBooking)"
            >
              <mdb-icon class="mr-1" icon="plus" />Undelete
            </awgt-std-button>

            <awgt-std-button
              v-if="
                checkPermission('CP_TBD') &&
                checkPermissionScopeForBookingDelete(index)
              "
              type="button"
              title="Products"
              class="command-button mx-2"
              @click="onPermanentlyDelete(travelBooking)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Permanently delete
            </awgt-std-button>
          </div>
          <div class="my-2 float-right" v-show="travelBooking.IsActive == true">
            <awgt-std-button
              v-if="checkPermission('CP_CoV')"
              type="button"
              title="Communications"
              class="command-button mx-2"
              @click="onCommunications(travelBooking)"
            >
              <mdb-icon class="mr-1" icon="comments" />Communications
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_ItV') &&
                checkPermissionScopeForItineraryView(index)
              "
              type="button"
              title="Itineraries"
              class="command-button mx-2"
              @click="onItineraries(travelBooking)"
            >
              <mdb-icon class="mr-1" far icon="calendar-alt" />Itineraries
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_TBU') &&
                checkPermissionScopeForBookingUpdate(index)
              "
              type="button"
              title="Edit"
              class="command-button mx-2"
              @click="onEditBooking(travelBooking)"
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_TBD') &&
                checkPermissionScopeForBookingDelete(index)
              "
              type="button"
              title="Delete"
              class="command-button mx-2"
              @click="onDeleteBooking(travelBooking)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <yes-or-no-prompt ref="handleBookingPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbFormInline,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
} from "mdbvue";

import ExpansionRegion from "@/components/ExpansionRegion";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import bookingApi from "@/api/bookingApi.js";
import userApi from "@/api/userApi.js";
import clientApi from "@/api/clientApi.js";
import { mapGetters } from "vuex";
import { sharedMethods } from "@/shared/shared";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import awgtInput from "@/components/AWGT/AwgtInput";
import workgroupApi from "@/api/workgroupApi.js";
import tokenManager from "@/shared/securityTokenManager.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    AwgtStdSwitch,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    ExpansionRegion,
    AwgtStdDropdown,
    yesOrNoPrompt,
    awgtInput,
  },

  data() {
    return {
      includeIndividualClientsInd: false,
      includeOrganisationClientsInd: false,
      includeSchoolClientsInd: true,
      includeDeletedTravelBookingsInd: false,
      includeTemplatesInd: false,
      matchAllSearchingCriteriaInd: true,
      travelBookingDetailExpansion: [],
      travelBookings: [],
      workgroups: [],
      workgroupsList: [],
      consultants: [],
      consultantsList: [],
      bookingYear: null,
      terms: [],
      client: null,
      clientsList: [],
      bookingStatus: [],
      getDestinations: [],
      destination: [],
      sortByTermInd: true,
      sortByClientInd: true,
      sortByIDInd: true,
      sortByDescriptionInd: true,
      sortByDateInd: true,
      sortByStatusInd: true,
      toggleSortBtn: true,
      selectedSortingCondition: null,
      searchReference: "",
      isSearched: false,
      sortingSelection: [
        { Code: "SchoolTerm", Name: "Term" },
        { Code: "Client", Name: "Client" },
        { Code: "Reference", Name: "ID" },
        { Code: "Description", Name: "Description" },
        { Code: "DepartureDt", Name: "Start Date" },
        { Code: "BookingStatus", Name: "Status" },
      ],
      currentUserLevel: 0,
      currentUserReference: null,
      workgroupsOfCurrentUser: [],
      currentUserPermissionScopeForBookingUpdate: null,
      currentUserPermissionScopeForBookingDelete: null,
      currentUserPermissionScopeForItineraryView: null,
      travelBookingOwnerUserFullDataSet: [],
    };
  },

  computed: {
    ...mapGetters([
      "getBookingStatusNameByCode",
      "getSchoolTermColorFromCode",
      "getBookingStatuses",
      "getBookingStatusColorFromCode",
      "getSchoolTermNameFromCode",
      "getSchoolTerms",
      "checkPermission",
    ]),
    getYears() {
      return function (startYear) {
        const year = new Date().getFullYear();
        let yearsList = Array.from(
          { length: year - startYear + 1 },
          (v, i) => startYear + i
        ).map((item) => {
          return {
            Name: item,
            Code: item,
          };
        });
        yearsList.unshift({ Name: "", Code: "" });
        return yearsList;
      };
    },
    getClients() {
      let listIndividual = [];
      let listSchool = [];
      let listOrganization = [];
      if (this.includeIndividualClientsInd) {
        listIndividual = this.clientsList.filter((c) => {
          return c.ClientType === "CT_I";
        });
      }
      if (this.includeSchoolClientsInd) {
        listSchool = this.clientsList.filter((c) => {
          return c.ClientType === "CT_S";
        });
      }
      if (this.includeOrganisationClientsInd) {
        listOrganization = this.clientsList.filter((c) => {
          return c.ClientType === "CT_O";
        });
      }

      return listIndividual.concat(listSchool, listOrganization);
    },

    checkPermissionScopeForBookingUpdate() {
      return function (index) {
        let travelBookingOwner = this.travelBookingOwnerUserFullDataSet[index];

        if (travelBookingOwner) {
          if (!this.currentUserPermissionScopeForBookingUpdate) return false;

          if (this.currentUserPermissionScopeForBookingUpdate == "CPS_NA") {
            if (travelBookingOwner.ConsultancyRole.Level > 400) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForBookingUpdate == "CPS_BW"
          ) {
            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level > this.currentUserLevel
            ) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForBookingUpdate == "CPS_NW"
          ) {
            if (this.currentUserReference == travelBookingOwner.Reference)
              return true; //cuurrent user himself

            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level >= this.currentUserLevel
            )
              return false;
          }

          return true;
        } else return false;
      };
    },

    checkPermissionScopeForBookingDelete() {
      return function (index) {
        let travelBookingOwner = this.travelBookingOwnerUserFullDataSet[index];

        if (travelBookingOwner) {
          if (!this.currentUserPermissionScopeForBookingDelete) return false;

          if (this.currentUserPermissionScopeForBookingDelete == "CPS_NA") {
            if (travelBookingOwner.ConsultancyRole.Level > 400) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForBookingDelete == "CPS_BW"
          ) {
            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level > this.currentUserLevel
            ) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForBookingDelete == "CPS_NW"
          ) {
            if (this.currentUserReference == travelBookingOwner.Reference)
              return true; //cuurrent user himself

            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level >= this.currentUserLevel
            )
              return false;
          }

          return true;
        } else return false;
      };
    },

    checkPermissionScopeForItineraryView() {
      return function (index) {
        let travelBookingOwner = this.travelBookingOwnerUserFullDataSet[index];

        if (travelBookingOwner) {
          if (!this.currentUserPermissionScopeForItineraryView) return false;

          if (this.currentUserPermissionScopeForItineraryView == "CPS_NA") {
            if (travelBookingOwner.ConsultancyRole.Level > 400) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForItineraryView == "CPS_BW"
          ) {
            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level > this.currentUserLevel
            ) {
              return false;
            }
          } else if (
            this.currentUserPermissionScopeForItineraryView == "CPS_NW"
          ) {
            if (this.currentUserReference == travelBookingOwner.Reference)
              return true; //cuurrent user himself

            if (
              travelBookingOwner.Workgroups.every(
                (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) == -1
              ) ||
              travelBookingOwner.ConsultancyRole.Level >= this.currentUserLevel
            )
              return false;
          }

          return true;
        } else return false;
      };
    },
  },

  methods: {
    ...sharedMethods,
    /*
    This is required because v-if doesn't work if you use an
    array element in the v-if.
    See: https://stackoverflow.com/questions/41580617/vuejs-v-if-arrayindex-is-not-working
    */
    async onToggletravelBookingDetailExpansion(index) {
      if (
        (this.travelBookingDetailExpansion[index] == undefined ||
          this.travelBookingDetailExpansion[index] == false) &&
        this.travelBookingOwnerUserFullDataSet[index] == undefined
      ) {
        let ownerUserForTravelBooking = await userApi.getUserByReference(
          this.travelBookings[index].OwnerUserReference
        );

        this.$set(
          this.travelBookingOwnerUserFullDataSet,
          index,
          ownerUserForTravelBooking
        );
      }

      this.$set(
        this.travelBookingDetailExpansion,
        index,
        !this.travelBookingDetailExpansion[index]
      );
    },

    onClickSort() {
      this.toggleSortBtn = !this.toggleSortBtn;
      if (this.toggleSortBtn == true)
        this.onSortBookingInOrder(this.selectedSortingCondition);
      else this.onSortBookingInReverseOrder(this.selectedSortingCondition);
    },

    onSortByTerm() {
      this.sortByTermInd = !this.sortByTermInd;
      if (this.sortByTermInd == true) this.onSortBookingInOrder("SchoolTerm");
      else this.onSortBookingInReverseOrder("SchoolTerm");
    },

    onSortByClient() {
      this.sortByClientInd = !this.sortByClientInd;
      if (this.sortByClientInd == true) this.onSortBookingInOrder("Client");
      else this.onSortBookingInReverseOrder("Client");
    },

    onSortByID() {
      this.sortByIDInd = !this.sortByIDInd;
      if (this.sortByIDInd == true) this.onSortBookingInOrder("Reference");
      else this.onSortBookingInReverseOrder("Reference");
    },

    onSortByDescription() {
      this.sortByDescriptionInd = !this.sortByDescriptionInd;
      if (this.sortByDescriptionInd == true)
        this.onSortBookingInOrder("Description");
      else this.onSortBookingInReverseOrder("Description");
    },

    onSortByDate() {
      this.sortByDateInd = !this.sortByDateInd;
      if (this.sortByDateInd == true)
        this.onSortBookingInOrder("DepartureDt", true);
      else this.onSortBookingInReverseOrder("DepartureDt", true);
    },

    onSortByStatus() {
      this.sortByStatusInd = !this.sortByStatusInd;
      if (this.sortByStatusInd == true)
        this.onSortBookingInOrder("BookingStatus");
      else this.onSortBookingInReverseOrder("BookingStatus");
    },

    onUndeleteBooking(booking) {
      this.$refs.handleBookingPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure you wish to recover this booking?"
        )
        .then((option) => {
          if (option == true) {
            booking.IsActive = true;
            this.saveBooking(booking);
          }
        });
    },

    onDeleteBooking(booking) {
      booking.IsActive = false;
      this.saveBooking(booking);
    },

    onPermanentlyDelete(deleteBooking) {
      this.$refs.handleBookingPrompt
        .onHandlePrompt(
          "Warning",
          "Are you sure to permanently delete the booking?"
        )
        .then((option) => {
          if (option == true) {
            this.permanentlyDelete(deleteBooking);
          }
        });
    },

    onSearchBooking() {
      this.searchBookings();
    },

    onCommunications(booking) {
      this.$router.push({
        path: `/booking/${booking.Reference}/communication`,
      });
    },

    onItineraries(booking) {
      this.$router.push({
        path: `/booking/${booking.Reference}/itinerary/list`,
      });
    },

    onProducts(booking) {
      this.$router.push({
        path: `/booking/${booking.Reference}/product`,
      });
    },

    onEditBooking(booking) {
      this.$router.push({
        path: `/booking/${booking.Reference}/edit`,
      });
    },

    onAddBooking() {
      this.$router.push({
        path: `/booking/add`,
      });
    },

    onClearSearchCriteria() {
      this.searchReference = "";
      this.workgroups = [];
      this.consultants = [];
      this.bookingStatus = [];
      this.destination = [];
      this.client = null;
      this.bookingYear = null;
      this.terms = [];
      this.travelBookings.splice(0);
    },

    async searchBookings() {
      this.travelBookings = await bookingApi.getBookingsAsync(
        this.searchReference,
        this.workgroups.join(),
        this.consultants.join(),
        this.bookingStatus.join(),
        this.destination.join(),
        this.terms.join(),
        this.client,
        this.bookingYear,
        this.matchAllSearchingCriteriaInd,
        this.includeDeletedTravelBookingsInd
      );
      this.isSearched = true;
    },

    async saveBooking(booking) {
      var index = this.travelBookings.findIndex(
        (b) => b.Reference == booking.Reference
      );
      var returnVal = await bookingApi.putBookingAsync(booking);
      this.$set(this.travelBookings, index, returnVal);
    },

    async permanentlyDelete(booking) {
      try {
        await bookingApi.deleteBookingAsync(booking);
        this.travelBookings.splice(
          this.travelBookings.findIndex(
            (b) => b.Reference == booking.Reference
          ),
          1
        );
      } catch (err) {
        this.$log.error(err);
      }
    },

    onSortBookingInOrder(attribute, isDate = false) {
      this.travelBookings.sort(function (a, b) {
        var nameA = null;
        var nameB = null;
        if (typeof a[attribute] === "object") {
          nameA =
            a[attribute]?.DisplayName == null ? 0 : a[attribute].DisplayName;
          nameB =
            b[attribute]?.DisplayName == null ? 0 : b[attribute].DisplayName;
        } else if (typeof a[attribute] === "string") {
          if (isDate) {
            nameA = new Date(a[attribute]);
            nameB = new Date(b[attribute]);
          } else {
            nameA = a[attribute].toUpperCase(); // ignore upper and lowercase
            nameB = b[attribute].toUpperCase(); // ignore upper and lowercase
          }
        } else {
          nameA = a[attribute];
          nameB = b[attribute];
        }
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    },

    onSortBookingInReverseOrder(attribute, isDate = false) {
      this.travelBookings.sort(function (a, b) {
        var nameA = null;
        var nameB = null;
        if (typeof a[attribute] === "object") {
          nameA =
            a[attribute]?.DisplayName == null ? 0 : a[attribute].DisplayName;
          nameB =
            b[attribute]?.DisplayName == null ? 0 : b[attribute].DisplayName;
        } else if (typeof a[attribute] === "string") {
          if (isDate) {
            nameA = new Date(a[attribute]);
            nameB = new Date(b[attribute]);
          } else {
            nameA = a[attribute].toUpperCase(); // ignore upper and lowercase
            nameB = b[attribute].toUpperCase(); // ignore upper and lowercase
          }
        } else {
          nameA = a[attribute];
          nameB = b[attribute];
        }
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    },

    async getBooking(reference) {
      let successInd = true;
      let errorMessage = "";

      this.travelBookings = [];

      await bookingApi.getBookingByReferenceAsync(reference, true).then(
        (booking) => {
          this.travelBookings.push(booking);
        },
        () => {
          successInd = false;
          errorMessage = "Record not found";
        }
      );

      this.$bus.$emit("search-bar-result", { successInd, errorMessage });

      this.isSearched = true;
    },
    async loadFormData() {
      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;
      this.currentUserReference = currentUser.Reference;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForBookingUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_TBC";
        })?.PermissionScope;

      this.currentUserPermissionScopeForBookingDelete =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_TBC";
        })?.PermissionScope;

      this.currentUserPermissionScopeForItineraryView =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_ItV";
        })?.PermissionScope;

      this.workgroupsList = await workgroupApi.getWorkgroupDropdown();
      this.consultantsList = await userApi.getUserDropdown();
      this.clientsList = await clientApi.getClientDropdown();
    },
  },
  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.onSearchBooking);
    this.loadFormData().then(() => {
      this.$bus.$off("search-bar-search");
      this.$bus.$on("search-bar-search", (searchString) => {
        this.getBooking(searchString);
      });
    });
  },

  beforeUnmount() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
  },
};
</script>

<style scoped>
.flex-fill {
  flex-basis: 0 !important;
  flex-grow: 1;
  padding-left: 2px;
}

.flex-fill-term {
  padding-left: 2px;
}

.flex-fill-client {
  padding-left: 2px;
}

.flex-fill-des {
  padding-left: 2px;
}

.heading {
  color: #425664;
  display: none;
}

.headers-frame-header {
  color: #425664;
}

.headers-frame-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.heading span {
  text-decoration: none !important;
}

.table-element {
  text-align: left;
}

.header-colon {
  display: none;
}

.table-headers {
  margin-left: 25px;
  margin-right: 50px;
}

.sortingDropDown {
  display: none !important;
}

.booking-term {
  padding: 5px 10px;
}

@media (min-width: 800px) {
  .flex-fill-term {
    flex-basis: 5vw;
  }

  .flex-fill-client {
    flex-basis: 30vw;
  }

  .flex-fill-des {
    flex-basis: 30vw;
  }

  .card-header {
    height: 50px;
  }

  .status_indicator {
    width: 15px;
    height: 15px;
    border: 1px solid black;
    display: inline-block;
    float: right;
    margin-right: 25%;
  }
}
@media (max-width: 800px) {
  .table-column {
    flex-direction: column;
  }

  .header-colon {
    display: inline;
  }

  .heading {
    color: #425664;
    display: inline;
  }

  .table-headers {
    display: none !important;
  }

  .sortingDropDown {
    display: inline !important;
  }

  .status_indicator {
    width: 15px;
    height: 15px;
    border: 1px solid black;
    display: inline-block;
  }
}
</style>
