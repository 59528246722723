<template>
  <div>
    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <slot name="default"></slot>
      <awgt-std-button
        type="button"
        class="command-button"
        style="min-width: 100px"
        @click="OpenEditor(-1)"
      >
        <mdb-icon icon="plus" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <div
      style="border-style: solid; border-width: 0.5px; border-color: #e8e8e8"
      class="pl-3 pr-2 py-2"
      v-if="
        notesArray &&
        notesArray.filter((x) => {
          return noteType == 'Any' || x.NoteTypeCd == noteType;
        }).length > 0
      "
    >
      <div v-for="(note, index) in notesArray" :key="index">
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center py-1"
          v-if="noteType == 'Any' || note.NoteTypeCd == noteType"
        >
          <div class="d-flex flex-row flex-wrap flex-grow-1">
            <span
              class="d-flex flex-row flex-nowrap flex-grow-1 mr-3"
              style="min-width: 100px; max-width: 200px; font-weight: bold"
            >
              {{ note[noteTitleProperty] }}
            </span>
            <span
              class="d-flex flex-row flex-nowrap flex-grow-1"
              style="min-width: 100px"
            >
              {{ note[noteDetailProperty] }}
            </span>
          </div>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            style="min-width: 100px"
            @click="OpenEditor(index)"
          >
            <mdb-icon icon="edit" class="mr-1" />Edit
          </awgt-std-button>
          <!-- Hiding the button looks weird so using disabled -->
          <awgt-std-button
            :disabled="note.IsMandatoryInd"
            type="button"
            class="command-button ml-2"
            style="min-width: 100px"
            @click="DeleteNote(index)"
          >
            <mdb-icon icon="trash-alt" class="mr-1" />Delete
          </awgt-std-button>
          <br />
        </div>
      </div>
    </div>
    <mdb-modal :show="showEditor">
      <mdb-modal-header>
        <h1 class="text-center">Edit Item</h1>
      </mdb-modal-header>
      <mdb-modal-body>
        <template v-if="editItem != null">
          <awgt-input
            bg
            label="Title"
            v-model="editItem[noteTitleProperty]"
            :disabled="editItem.IsMandatoryInd"
          ></awgt-input>
          <mdb-input
            bg
            outline
            label="Detail"
            type="textarea"
            :rows="5"
            v-model.trim="editItem[noteDetailProperty]"
            class="textarea-input"
          ></mdb-input>
          <awgt-std-dropdown
            v-model="editItem.NoteTypeCd"
            :items="getNoteTypes(false)"
            itemValueProperty="Code"
            itemTextProperty="Name"
            label="Note Type"
            bg
            style="width: 250px"
            :tooltip="getTooltipsTextByCode(52)"
          />
        </template>
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveAndCloseEditor()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="CloseEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<style>
.textarea-input textarea {
  resize: vertical !important;
}
</style>

<script>
import lodash from "lodash";
import awgtInput from "@/components/AWGT/AwgtInput";
import { mapGetters } from "vuex";
import {
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
} from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";

export default {
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    AwgtStdDropdown,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
  },

  data() {
    return {
      currentIndex: 0,
      showEditor: false,
      item: null,
      editItem: null,
      notesArray: [],
    };
  },

  props: {
    value: {
      type: Array,
    },
    noteTitleProperty: {
      type: String,
    },
    noteDetailProperty: {
      type: String,
    },
    noteType: {
      type: String,
      default: "any",
    },
  },

  computed: {
    ...mapGetters(["getTooltipsTextByCode", "getNoteTypes"]),
  },

  methods: {
    OpenEditor(idx) {
      this.currentIndex = idx;

      if (idx > -1) {
        //Existing Item
        this.editItem = lodash.cloneDeep(this.notesArray[this.currentIndex]);
      } else {
        //New Item
        this.editItem = {};
        this.$set(this.editItem, this.noteTitleProperty, "");
        this.$set(this.editItem, this.noteDetailProperty, "");
        this.$set(this.editItem, "NoteTypeCd", this.noteType);
        this.$set(this.editItem, "IsMandatoryInd", false);
      }

      this.showEditor = true;
    },

    SaveAndCloseEditor() {
      if (this.currentIndex > -1) {
        //Existing Item
        this.$set(this.notesArray, this.currentIndex, this.editItem);
      } else {
        //New Item
        this.notesArray.push(this.editItem);
      }
      this.CloseEditor();
    },

    DeleteNote(idx) {
      this.notesArray.splice(idx, 1);
    },

    CloseEditor() {
      this.showEditor = false;
    },
  },

  watch: {
    value(to) {
      this.notesArray = to;
    },

    notesArray(to) {
      this.$emit("input", to);
    },
  },

  mounted() {},
};
</script>
