var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.value).length > 0
    ? _c(
        "div",
        [
          [
            _c("div", [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.value.Name)),
              ]),
            ]),
            _c(
              "div",
              [
                _c("mdb-icon", {
                  staticClass: "icon-in-views",
                  attrs: {
                    far: "",
                    icon: "map-marker-alt",
                    color: "primary",
                    size: "1x",
                  },
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.getPhysicalAddress(_vm.value.Addresses))),
                ]),
              ],
              1
            ),
            _vm._l(_vm.value.SupplierNotes, function (note, ind) {
              return _c(
                "div",
                { key: ind },
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "clipboard",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(note.Subject) +
                        ": " +
                        _vm._s(note.Note) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            }),
            _vm.value.SupplierFiles
              ? _c(
                  "div",
                  [
                    _c("mdb-icon", {
                      staticClass: "icon-in-views",
                      attrs: {
                        far: "",
                        icon: "file-alt",
                        color: "primary",
                        size: "1x",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        " File categories: " +
                          _vm._s(
                            _vm.getSupplierFilesCategories(
                              _vm.value.SupplierFiles
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          _vm._l(_vm.value.Products, function (product, indOfInfoList) {
            return [
              _c(
                "div",
                {
                  key: indOfInfoList,
                  staticClass: "mt-4 html2pdf__page-break",
                },
                [
                  [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "#923f82",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              (product.DisplayName == ""
                                ? product.Name + " - " + product.Name
                                : product.DisplayName) +
                                (product.SpecialComment == ""
                                  ? ""
                                  : " - " + product.SpecialComment)
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "my-2 html-in-views",
                      domProps: { innerHTML: _vm._s(product.Description) },
                    }),
                    _c("div", { staticClass: "my-2" }, [
                      _vm._v(" Information: "),
                      _c("a", { attrs: { href: product.InformationURL } }, [
                        _vm._v(_vm._s(product.InformationURL)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row flex-wrap box-area",
                        staticStyle: { "justify-content": "center" },
                      },
                      [
                        product.YearGroups != null &&
                        product.YearGroups.length > 0
                          ? _c(
                              "div",
                              { staticClass: "box-margin" },
                              [
                                _c("asoft-box-display", {
                                  attrs: {
                                    title: "Year Groups",
                                    content: product.YearGroups,
                                    icon: "users-class",
                                    shadow: false,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        product.ProductType == "PT_Ay"
                          ? [
                              _c(
                                "div",
                                { staticClass: "box-margin" },
                                [
                                  _c("asoft-box-display", {
                                    attrs: {
                                      title:
                                        "Minimum participants per activity",
                                      content: product.MinParticipantsPerGroup,
                                      icon: "hashtag",
                                      shadow: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "box-margin" },
                                [
                                  _c("asoft-box-display", {
                                    attrs: {
                                      title:
                                        "Maximum participants per activity",
                                      content: product.MaxParticipantsPerGroup,
                                      icon: "hashtag",
                                      shadow: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "box-margin" },
                                [
                                  _c("asoft-box-display", {
                                    attrs: {
                                      title: "Participants per timeslot",
                                      content:
                                        product.MaxParticipantsPerTimeslot,
                                      icon: "hashtag",
                                      shadow: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "box-margin" },
                                [
                                  _c("asoft-box-display", {
                                    attrs: {
                                      title: "Duration In Minutes",
                                      content: product.DurationInMinutes,
                                      icon: "hashtag",
                                      shadow: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        product.ProductType == "PT_An" &&
                        product.MinBedsNum != null
                          ? _c(
                              "div",
                              { staticClass: "box-margin" },
                              [
                                _c("asoft-box-display", {
                                  attrs: {
                                    title: "Minimum Number of Beds",
                                    content: product.MinBedsNum,
                                    icon: "bed-bunk",
                                    shadow: false,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        product.ProductType == "PT_An" &&
                        product.MaxBedsNum != null
                          ? _c(
                              "div",
                              { staticClass: "box-margin" },
                              [
                                _c("asoft-box-display", {
                                  attrs: {
                                    title: "Maximum Number of Beds",
                                    content: product.MaxBedsNum,
                                    icon: "bed-bunk",
                                    shadow: false,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        product.ProductType == "PT_Tr"
                          ? _c(
                              "div",
                              { staticClass: "box-margin" },
                              [
                                _c("asoft-box-display", {
                                  attrs: {
                                    title: "Minimum Number of Seats",
                                    content: `Minimum Seats: ${product.MinSeatsNum}`,
                                    icon: "car-bus",
                                    shadow: false,
                                  },
                                }),
                                _c("asoft-box-display", {
                                  attrs: {
                                    title: "Maximum Number of Seats",
                                    content: `Maximum Seats: ${product.MaxSeatsNum}`,
                                    icon: "car-bus",
                                    shadow: false,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                ],
                2
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }