<template>
  <div class="parent">
    <div>
      <div style="text-align: center" v-if="success == false">
        <h1>{{ message }}</h1>
      </div>
      <div v-if="success == true" style="text-align: center; margin-top: 150px">
        <span class="success-message"
          ><span>&#10003;&nbsp;</span>The password has been reset, you be will
          redirected to login page soon</span
        >
      </div>
      <div v-if="success == false">
        <div class="user-input">
          <h4>Please Reset Your Password</h4>
          <div
            style="
              text-align: left !important;
              color: grey;
              margin-left: 25%;
              padding: 0px 10px;
              margin-top: 20px;
              font-size: 14px;
            "
          >
            <span>Passwords must be:</span>
            <ul>
              <li>between 8 and 100 characters in length</li>
              <li>contain a mix of uppercase and lowercase letters</li>
              <li>contain at least one number or special character</li>
              <li>password phrases are encouraged</li>
            </ul>
          </div>
          <input
            style="margin-top: 10px"
            autocomplete="off"
            class="password-input field-input"
            :type="passwordType"
            placeholder="Enter your password"
            maxlength="100"
            v-model="password"
            @keyup="onChangePassword"
          /><i
            @click="
              () =>
                passwordType == 'password'
                  ? (passwordType = 'text')
                  : (passwordType = 'password')
            "
            class="fa"
            :class="{
              'fa-eye': passwordType == 'text',
              'fa-eye-slash': passwordType == 'password',
            }"
          ></i
          ><br />
          <div class="progress-bar_wrap">
            <div
              class="progress-bar_item"
              :class="{
                'progress-bar_item-1': password.length > 0,
              }"
            ></div>
            <div
              class="progress-bar_item"
              :class="{
                'progress-bar_item-2':
                  checkPassword(password) == 3 || checkPassword(password) == 4,
              }"
            ></div>
            <div
              class="progress-bar_item"
              :class="{
                'progress-bar_item-3': checkPassword(password) == 4,
              }"
            ></div>
          </div>
          <span class="progress-bar_text">{{ PasswordText }}</span
          ><br />
          <input
            style="margin-top: 30px"
            autocomplete="off"
            class="password-input field-input"
            :type="confirmedPasswordType"
            placeholder="Confirm your password"
            maxlength="100"
            v-model="confirmedPassword"
          /><i
            @click="
              () =>
                confirmedPasswordType == 'password'
                  ? (confirmedPasswordType = 'text')
                  : (confirmedPasswordType = 'password')
            "
            class="fa"
            :class="{
              'fa-eye': confirmedPasswordType == 'text',
              'fa-eye-slash': confirmedPasswordType == 'password',
            }"
          ></i
          ><br />
          <button class="btn" @click="onConfirm">Confirm Password</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import securityApi from "@/api/securityApi.js";

export default {
  data() {
    return {
      password: "",
      confirmedPassword: "",
      success: false,
      passwordType: "password",
      message: "Loading...",
      confirmedPasswordType: "password",
      PasswordText: "Password is blank",
      resetPasswordToken: "",
    };
  },

  methods: {
    async onConfirm() {
      this.password = this.password.trim();
      let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[^\w\s]).{8,}$/;
      if (this.password.length < 8 || !regex.test(this.password)) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: [
            "Passwords must be between 8 and 100 characters in length; contain a mix of uppercase and lowercase letters; contain at least one number or special character; and password phrases are encouraged.",
          ],
        });
        return;
      }
      if (!this.$lodash.isEqual(this.password, this.confirmedPassword.trim())) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: [
            "Two passwords are not matching, please ensure you have entered correct matching passwords.",
          ],
        });
        return;
      }

      try {
        await securityApi
          .resetPassword(this.resetPasswordToken, this.password)
          .then(() => {
            this.success = true;
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [
                "Password has been saved. You will be redirected to login page soon.",
              ],
            });
            setTimeout(() => {
              this.$router.push({
                path: "/",
              });
            }, 7000);
          });
      } catch (err) {
        // Errors have been logged
      }
    },

    onChangePassword() {
      if (this.password.length == 0) {
        this.PasswordText = "Password is blank";
        return;
      }
      if (
        this.checkPassword(this.password) == 1 ||
        this.checkPassword(this.password) == 2
      ) {
        this.PasswordText = "Weak Password";
      }
      if (this.checkPassword(this.password) == 3) {
        this.PasswordText = "Medium Password";
      }
      if (this.checkPassword(this.password) == 4) {
        this.PasswordText = "Strong Password";
      }
    },

    checkPassword(password) {
      let len = password.length;
      let securityLevel = 0;
      // If the password length is less than or equal to 8
      if (len < 8) securityLevel = 1;

      // If the password length is greater than 8 and contain any lowercase alphabet or any number or any special character
      if (
        len >= 8 &&
        (password.match(/[a-z]/) ||
          password.match(/\d+/) ||
          password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,),=]/))
      )
        securityLevel = 2;

      // If the password length is greater than 8 and contain alphabet,number,special character respectively
      if (
        len >= 8 &&
        ((password.match(/[a-zA-Z]/) && password.match(/\d+/)) ||
          (password.match(/\d+/) &&
            password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,),=]/)) ||
          (password.match(/[a-zA-Z]/) &&
            password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,),=]/)))
      )
        securityLevel = 3;

      // If the password length is greater than 8 and must contain uppercase and lowercase alphabets,numbers and special characters
      if (
        len >= 8 &&
        password.match(/[a-z]/) &&
        password.match(/[A-Z]/) &&
        password.match(/\d+/) &&
        password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,),=]/)
      )
        securityLevel = 4;

      return securityLevel;
    },
  },

  mounted() {
    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIResetPassword)
      .info("RouteParams:", routeParams);
    this.resetPasswordToken = routeParams.token;
  },
};
</script>

<style scoped>
.user-input {
  text-align: center;
  align-items: center;
}
.password-input {
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
}

.success-message {
  background-color: #be7746;
  color: white;
  font-weight: 500;
  border: double;
  margin: 20px 10px;
  padding: 10px 20px 10px 20px;
  border-radius: 0.8rem;
}

.btn {
  width: 30%;
  padding: 10px;
  color: white;
  font-weight: bold;
  background: #be7746;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

i {
  margin-left: -45px;
}

.progress-bar_wrap {
  width: 300px;
  height: 5px;
  background: #f6f6fa;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 5px;
}

.progress-bar_item {
  display: inline-block;
  height: 100%;
  width: 32.5%;
  float: left;
  visibility: hidden;
  margin-right: 0.8%;
  border-radius: 5px;
  transition: background-color 0.2s, visisility 0.1s;
}

.progress-bar_item-1 {
  background-color: #ff4b47;
  visibility: visible;
}

.progress-bar_item-2 {
  background-color: #f9ae35;
  visibility: visible;
}

.progress-bar_item-3 {
  background-color: #2daf7d;
  visibility: visible;
}

.progress-bar_text {
  display: inline-block;
  color: #aaa;
  margin-left: 5px;
  transition: 0.2s;
}

.field-input:focus {
  border-color: #be7746;
}
</style>
