var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "mdb-form-inline",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "span",
            { staticClass: "ml-2 mr-3", staticStyle: { width: "120px" } },
            [_vm._v("Search Scope")]
          ),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Transport",
              type: "checkbox",
              name: "searchScope",
              label: "Transport",
              checked: "",
              disabled:
                _vm.includeAccommodationProductsInd == false &&
                _vm.includeActivityProductsInd == false &&
                _vm.includeMerchandiseProductsInd == false,
            },
            model: {
              value: _vm.includeTransportProductsInd,
              callback: function ($$v) {
                _vm.includeTransportProductsInd = $$v
              },
              expression: "includeTransportProductsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Accommodation",
              type: "checkbox",
              name: "searchScope",
              label: "Accommodation",
              checked: "",
              disabled:
                _vm.includeTransportProductsInd == false &&
                _vm.includeMerchandiseProductsInd == false &&
                _vm.includeActivityProductsInd == false,
            },
            model: {
              value: _vm.includeAccommodationProductsInd,
              callback: function ($$v) {
                _vm.includeAccommodationProductsInd = $$v
              },
              expression: "includeAccommodationProductsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Activity",
              type: "checkbox",
              name: "searchScope",
              label: "Activity",
              checked: "",
              disabled:
                _vm.includeTransportProductsInd == false &&
                _vm.includeAccommodationProductsInd == false &&
                _vm.includeMerchandiseProductsInd == false,
            },
            model: {
              value: _vm.includeActivityProductsInd,
              callback: function ($$v) {
                _vm.includeActivityProductsInd = $$v
              },
              expression: "includeActivityProductsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "searchScope_Merchandise",
              type: "checkbox",
              name: "searchScope",
              label: "Merchandise",
              checked: "",
              disabled:
                _vm.includeTransportProductsInd == false &&
                _vm.includeAccommodationProductsInd == false &&
                _vm.includeActivityProductsInd == false,
            },
            model: {
              value: _vm.includeMerchandiseProductsInd,
              callback: function ($$v) {
                _vm.includeMerchandiseProductsInd = $$v
              },
              expression: "includeMerchandiseProductsInd",
            },
          }),
        ],
        1
      ),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "span",
            { staticClass: "ml-2 mr-3", staticStyle: { width: "120px" } },
            [_vm._v("Sold With")]
          ),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "soldWith_CanBeSoldSeparately",
              type: "checkbox",
              name: "soldWith",
              label: "Can be sold separately",
              disabled: _vm.includeCanOnlyBeSoldAsRelatedProductsInd == false,
            },
            model: {
              value: _vm.includeCanBeSoldSeparatelyProductsInd,
              callback: function ($$v) {
                _vm.includeCanBeSoldSeparatelyProductsInd = $$v
              },
              expression: "includeCanBeSoldSeparatelyProductsInd",
            },
          }),
          _c("awgt-input", {
            staticClass: "mx-2",
            staticStyle: { "min-width": "60px" },
            attrs: {
              id: "soldWith_CanOnlyBeSoldAsRelatedProducts",
              type: "checkbox",
              name: "soldWith",
              label: "Can only be sold as related products",
              disabled: _vm.includeCanBeSoldSeparatelyProductsInd == false,
            },
            model: {
              value: _vm.includeCanOnlyBeSoldAsRelatedProductsInd,
              callback: function ($$v) {
                _vm.includeCanOnlyBeSoldAsRelatedProductsInd = $$v
              },
              expression: "includeCanOnlyBeSoldAsRelatedProductsInd",
            },
          }),
        ],
        1
      ),
      _vm.checkPermission("CP_PrC")
        ? _c(
            "mdb-form-inline",
            { staticClass: "flex-nowrap" },
            [
              _c(
                "span",
                { staticClass: "ml-2 mr-3", staticStyle: { width: "120px" } },
                [_vm._v("Edit Status")]
              ),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "editStatus_Draft",
                  type: "checkbox",
                  name: "editStatus",
                  label: "Draft",
                  disabled: _vm.includePublishedProductssInd == false,
                },
                model: {
                  value: _vm.includeDraftProductssInd,
                  callback: function ($$v) {
                    _vm.includeDraftProductssInd = $$v
                  },
                  expression: "includeDraftProductssInd",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "editStatus_Published",
                  type: "checkbox",
                  name: "editStatus",
                  label: "Published",
                  disabled: _vm.includeDraftProductssInd == false,
                },
                model: {
                  value: _vm.includePublishedProductssInd,
                  callback: function ($$v) {
                    _vm.includePublishedProductssInd = $$v
                  },
                  expression: "includePublishedProductssInd",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _vm.isTravelBookingProductSearch == false
            ? _c("awgt-std-switch", {
                attrs: { offLabel: "", onLabel: "Include deleted products" },
                model: {
                  value: _vm.includeDeletedProductsInd,
                  callback: function ($$v) {
                    _vm.includeDeletedProductsInd = $$v
                  },
                  expression: "includeDeletedProductsInd",
                },
              })
            : _vm._e(),
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onClearSearch },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "eraser" },
                  }),
                  _vm._v("Clear "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onSearch },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "search" },
                  }),
                  _vm._v("Search "),
                ],
                1
              ),
              _vm.isTravelBookingProductSearch == false &&
              _vm.checkPermission("CP_PrC")
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button" },
                      on: { click: _vm.onAdd },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("awgt-std-switch", {
        staticClass: "mt-3",
        attrs: { offLabel: "", onLabel: "Match all search criteria" },
        model: {
          value: _vm.matchAllSearchCriteriaInd,
          callback: function ($$v) {
            _vm.matchAllSearchCriteriaInd = $$v
          },
          expression: "matchAllSearchCriteriaInd",
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Reference",
              bg: "",
            },
            model: {
              value: _vm.searchReference,
              callback: function ($$v) {
                _vm.searchReference = $$v
              },
              expression: "searchReference",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Supplier Provider",
              bg: "",
            },
            model: {
              value: _vm.searchProviderName,
              callback: function ($$v) {
                _vm.searchProviderName = $$v
              },
              expression: "searchProviderName",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Supplier Reseller",
              bg: "",
            },
            model: {
              value: _vm.searchResellerName,
              callback: function ($$v) {
                _vm.searchResellerName = $$v
              },
              expression: "searchResellerName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              label: "Product Name",
              bg: "",
            },
            model: {
              value: _vm.searchProductName,
              callback: function ($$v) {
                _vm.searchProductName = $$v
              },
              expression: "searchProductName",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              itemValueProperty: "CountryRegionId",
              items: _vm.countryRegions,
              itemTextProperty: "Name",
              label: "Region",
              bg: "",
            },
            model: {
              value: _vm.searchRegion,
              callback: function ($$v) {
                _vm.searchRegion = $$v
              },
              expression: "searchRegion",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              items: _vm.getAddressSubdivisions(false),
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Subdivision",
              bg: "",
            },
            model: {
              value: _vm.searchSubdivision,
              callback: function ($$v) {
                _vm.searchSubdivision = $$v
              },
              expression: "searchSubdivision",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("awgt-std-dropdown", {
            staticClass: "mx-2",
            staticStyle: { flex: "1" },
            attrs: {
              itemValueProperty: "Code",
              items: _vm.getYearGroups,
              itemTextProperty: "Name",
              label: "Year Groups",
              bg: "",
              maxSelectedOptions: 13,
              multiple: "",
              selectAll: "",
            },
            model: {
              value: _vm.yearGroups,
              callback: function ($$v) {
                _vm.yearGroups = $$v
              },
              expression: "yearGroups",
            },
          }),
          _c("tdox-duration-picker", {
            staticClass: "mx-2",
            staticStyle: { flex: "1" },
            attrs: {
              hourSelection: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              minuteSelection: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
              label: "Minimum Duration",
            },
            model: {
              value: _vm.searchMinDuration,
              callback: function ($$v) {
                _vm.searchMinDuration = _vm._n($$v)
              },
              expression: "searchMinDuration",
            },
          }),
          _c("tdox-duration-picker", {
            staticClass: "mx-2",
            staticStyle: { flex: "1" },
            attrs: {
              hourSelection: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              minuteSelection: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
              label: "Maximum Duration",
            },
            model: {
              value: _vm.searchMaxDuration,
              callback: function ($$v) {
                _vm.searchMaxDuration = _vm._n($$v)
              },
              expression: "searchMaxDuration",
            },
          }),
        ],
        1
      ),
      _vm.products.length == 0 && _vm.isSearched
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No search result found")]),
          ])
        : _vm._e(),
      _vm._l(_vm.products, function (product, index) {
        return _c(
          "mdb-card",
          {
            key: index,
            staticClass: "mb-3 mx-2",
            style: product.IsActive ? "opacity:1" : "opacity:0.5",
          },
          [
            _c(
              "mdb-card-header",
              {
                attrs: { tag: "button" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleProductDetailExpansion(index)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "float-right" },
                  [
                    _vm.productDetailExpansion[index] == true
                      ? _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-up",
                            size: "2x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-down",
                            size: "2x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap align-items-center",
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "pr-3",
                      staticStyle: { width: "75px" },
                      attrs: {
                        icon: _vm.getProductIcon(
                          product.ProductType,
                          product.ProductSubType
                        ),
                        size: "3x",
                        color: "primary",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        staticStyle: { "text-align": "left" },
                      },
                      [
                        _c("h4", { staticClass: "my-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.getHeaderText(product)) + " "
                          ),
                        ]),
                        product.ProductSubType != "PST_AyFI"
                          ? [
                              product.ProviderSupplier.Reference !=
                              product.ResellerSupplier.Reference
                                ? _c("h5", { staticClass: "my-0" }, [
                                    _vm._v(
                                      " Purchased from " +
                                        _vm._s(product.ResellerSupplier.Name) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        !product.IsActive
                          ? [
                              _c("span", { staticClass: "alert-text" }, [
                                _vm._v("Deleted"),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "expansion-region",
              { attrs: { toggle: _vm.productDetailExpansion[index] } },
              [
                _vm.productDetailExpansion[index]
                  ? _c(
                      "mdb-card-body",
                      [
                        _vm.isTravelBookingProductSearch == true
                          ? _c("commercial-product-line-list", {
                              attrs: {
                                components:
                                  _vm.commercialProductLines[0].components,
                              },
                            })
                          : _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.productsFullDataSet[index].IsActive ==
                                      true,
                                    expression:
                                      "productsFullDataSet[index].IsActive == true",
                                  },
                                ],
                                staticClass: "my-2 float-right",
                              },
                              [
                                _vm.checkPermission("CP_PrC")
                                  ? _c(
                                      "awgt-std-button",
                                      {
                                        staticClass: "command-button mx-2",
                                        attrs: {
                                          type: "button",
                                          title: "Dupliacte",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDuplicate(
                                              _vm.productsFullDataSet[index]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("mdb-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "copy" },
                                        }),
                                        _vm._v("Duplicate "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.productsFullDataSet[index].EditStatus ==
                                  "ES_D" && _vm.checkPermission("CP_PrU")
                                  ? _c(
                                      "awgt-std-button",
                                      {
                                        staticClass: "command-button mx-2",
                                        attrs: {
                                          type: "button",
                                          title: "Public",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onPublishProduict(
                                              _vm.productsFullDataSet[index]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("mdb-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "upload" },
                                        }),
                                        _vm._v("Publish "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.checkPermission("CP_PrU")
                                  ? _c(
                                      "awgt-std-button",
                                      {
                                        staticClass: "command-button mx-2",
                                        attrs: {
                                          type: "button",
                                          title: "Edit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onEdit(
                                              _vm.productsFullDataSet[index]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("mdb-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "edit" },
                                        }),
                                        _vm._v("Edit "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.checkPermission("CP_PrD")
                                  ? _c(
                                      "awgt-std-button",
                                      {
                                        staticClass: "command-button mx-2",
                                        attrs: {
                                          type: "button",
                                          title: "Delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDelete(
                                              _vm.productsFullDataSet[index]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("mdb-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "trash-alt" },
                                        }),
                                        _vm._v("Delete "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.productsFullDataSet[index].IsActive ==
                                  false,
                                expression:
                                  "productsFullDataSet[index].IsActive == false",
                              },
                            ],
                            staticClass: "my-2 float-right",
                          },
                          [
                            _vm.checkPermission("CP_PrD")
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: (event) =>
                                        _vm.onUndelete(
                                          _vm.productsFullDataSet[index]
                                        ),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Undelete "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkPermission("CP_PrD")
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: (event) =>
                                        _vm.onPermanentlyDelete(
                                          _vm.productsFullDataSet[index]
                                        ),
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Permanently delete "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("product-view", {
                          attrs: {
                            value: _vm.productsFullDataSet[index],
                            headingColor: _vm.awgtProductViewHeadingColour,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("div", [
        _c("div", {
          ref: "infinitescrolltrigger",
          attrs: { id: "scroll-trigger" },
        }),
      ]),
      _c("yes-or-no-prompt", { ref: "handleProductPrompt" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }