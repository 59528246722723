var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("awgt-input", {
        staticStyle: { width: "280px" },
        attrs: {
          id: "booking-reference",
          counter: "",
          counterMaxValue: 20,
          maxlength: "20",
          label: "Booking Reference",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.BookingReference,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "BookingReference", $$v)
          },
          expression: "additionalDetails.BookingReference",
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mr-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              id: "pick-address",
              counter: "",
              counterMaxValue: 70,
              maxlength: "70",
              label: "Pick up address",
              bg: "",
            },
            model: {
              value: _vm.additionalDetails.PickupAddress,
              callback: function ($$v) {
                _vm.$set(_vm.additionalDetails, "PickupAddress", $$v)
              },
              expression: "additionalDetails.PickupAddress",
            },
          }),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mr-2 ml-auto",
              attrs: { type: "button" },
              on: { click: _vm.onClickUseClientAddress },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "copy" } }),
              _vm._v("Use client's address "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("h5", [_vm._v("Coach Charter Drivers")]),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2 ml-auto",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onAddCoachCharterDriver()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
              _vm._v("Add "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.additionalDetails.CoachCharterDrivers &&
      _vm.additionalDetails.CoachCharterDrivers.length > 0
        ? _vm._l(
            _vm.additionalDetails.CoachCharterDrivers,
            function (driver, dIndex) {
              return _c("div", { key: dIndex }, [
                driver.IsEdit == true
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                          },
                          [
                            _c("awgt-input", {
                              staticClass: "flex-grow-1 mr-2",
                              attrs: {
                                maxlength: "50",
                                label: "Driver Name",
                                bg: "",
                                type: "text",
                                required: "",
                              },
                              model: {
                                value: driver.DriverName,
                                callback: function ($$v) {
                                  _vm.$set(driver, "DriverName", $$v)
                                },
                                expression: "driver.DriverName",
                              },
                            }),
                            _c("awgt-input", {
                              staticClass: "flex-grow-1 mr-2",
                              attrs: {
                                maxlength: "15",
                                label: "Driver Phone Number",
                                bg: "",
                                type: "text",
                                required: "",
                              },
                              model: {
                                value: driver.DriverPhoneNumber,
                                callback: function ($$v) {
                                  _vm.$set(driver, "DriverPhoneNumber", $$v)
                                },
                                expression: "driver.DriverPhoneNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.onSaveCoachCharterDriver(dIndex)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "edit" },
                            }),
                            _vm._v("Save "),
                          ],
                          1
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button ml-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.onCancelCoachCharterDriver(dIndex)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "times" },
                            }),
                            _vm._v("Cancel "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(driver.DriverName) +
                            " - " +
                            _vm._s(driver.DriverPhoneNumber) +
                            " "
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditCoachCharterDriver(dIndex)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "edit" },
                            }),
                            _vm._v("Edit "),
                          ],
                          1
                        ),
                        _c(
                          "awgt-std-button",
                          {
                            staticClass: "command-button mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.onDeleteCoachCharterDriver(dIndex)
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "trash-alt" },
                            }),
                            _vm._v("Delete "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ])
            }
          )
        : _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v('Press "Add" to add a coach driver')]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }