var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "booking-reference",
          counter: "",
          counterMaxValue: 20,
          maxlength: "20",
          label: "Booking Reference",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.BookingReference,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "BookingReference", $$v)
          },
          expression: "additionalDetails.BookingReference",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "arrival-location",
          counter: "",
          counterMaxValue: 25,
          maxlength: "25",
          label: "Arrival Location",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.ArrivalLocation,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "ArrivalLocation", $$v)
          },
          expression: "additionalDetails.ArrivalLocation",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "arrival-wharf",
          counter: "",
          counterMaxValue: 15,
          maxlength: "15",
          label: "Arrival Wharf",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.ArrivalWharf,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "ArrivalWharf", $$v)
          },
          expression: "additionalDetails.ArrivalWharf",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "departure-location",
          counter: "",
          counterMaxValue: 25,
          maxlength: "25",
          label: "Departure Location",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.DepartureLocation,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "DepartureLocation", $$v)
          },
          expression: "additionalDetails.DepartureLocation",
        },
      }),
      _c("awgt-input", {
        staticClass: "flex-grow-1 mr-2",
        staticStyle: { "min-width": "300px" },
        attrs: {
          id: "departure-airport-name",
          counter: "",
          counterMaxValue: 15,
          maxlength: "15",
          label: "Departure Wharf",
          bg: "",
        },
        model: {
          value: _vm.additionalDetails.DepartureWharf,
          callback: function ($$v) {
            _vm.$set(_vm.additionalDetails, "DepartureWharf", $$v)
          },
          expression: "additionalDetails.DepartureWharf",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }