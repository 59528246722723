var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Travel Booking Edit")]),
      _vm.booking.Reference != ""
        ? _c("h4", { staticClass: "mx-2" }, [
            _vm._v(" Reference: " + _vm._s(_vm.booking.Reference) + " "),
          ])
        : _vm._e(),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Owner")]),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              maxlength: "100",
              items: _vm.ownerWorkgroups,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Owner Workgroup",
              bg: "",
            },
            model: {
              value: _vm.booking.OwnerWorkgroupReference,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "OwnerWorkgroupReference", $$v)
              },
              expression: "booking.OwnerWorkgroupReference",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              maxlength: "100",
              items: _vm.ownerUsersForWorkgroup,
              itemValueProperty: "Code",
              itemTextProperty: "Name",
              label: "Owner User",
              bg: "",
            },
            model: {
              value: _vm.booking.OwnerUserReference,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "OwnerUserReference", $$v)
              },
              expression: "booking.OwnerUserReference",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Client Detail")]),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
        [
          _c(
            "span",
            { staticClass: "mr-5", staticStyle: { "min-width": "60px" } },
            [_vm._v("Client Type")]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap min-width:120px;" },
            [
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_School",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "School",
                  radioValue: "CT_S",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_Individual",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "Individual",
                  radioValue: "CT_I",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_Organisation",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "Organisation",
                  radioValue: "CT_O",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("awgt-std-switch", {
        staticClass: "flex-grow-1 mx-2",
        attrs: { offLabel: "Existing Client", onLabel: "New Client" },
        on: { getValue: _vm.onNewOrExistingClientToggle },
        model: {
          value: _vm.isNewClientInd,
          callback: function ($$v) {
            _vm.isNewClientInd = $$v
          },
          expression: "isNewClientInd",
        },
      }),
      _vm.isNewClientInd == false
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
            },
            [
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mx-2",
                attrs: {
                  items: _vm.clients,
                  itemValueProperty: "Reference",
                  itemTextProperty: "DisplayName",
                  label: "Client Name",
                  bg: "",
                  search: "",
                },
                model: {
                  value: _vm.selectedClientReference,
                  callback: function ($$v) {
                    _vm.selectedClientReference = $$v
                  },
                  expression: "selectedClientReference",
                },
              }),
              _c("br"),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: {
                    type: "button",
                    disabled: _vm.selectedClientReference == null,
                  },
                  on: { click: _vm.onShowClientDetail },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "info" },
                  }),
                  _vm._v("Detail "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.client.Addresses, function (address, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex flex-row flex-wrap mx-3" },
          [
            address.Type === "AT_Ph" &&
            (address.Street.length > 0 ||
              address.Locality.length > 0 ||
              address.Subdivision != null ||
              address.Postcode.length > 0 ||
              address.Country.length > 0)
              ? _c("div", [
                  _c("span", { staticStyle: { "font-size": "10pt" } }, [
                    _vm._v(
                      _vm._s(
                        (address.Street.length > 0
                          ? address.Street + ", "
                          : "") +
                          (address.Locality.length > 0
                            ? address.Locality + ", "
                            : "") +
                          (address.Subdivision != null
                            ? address.Subdivision + ", "
                            : "") +
                          (address.Postcode.length > 0
                            ? address.Postcode + ", "
                            : "") +
                          (address.Country.length > 0 ? address.Country : "")
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      }),
      _c(
        "expansion-region",
        {
          attrs: {
            useVShowConditional: "",
            toggle: _vm.isNewClientInd == true || _vm.showClientDetailInd,
          },
        },
        [
          _vm.client.ClientType == "CT_I"
            ? _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-2 mt-4",
                    attrs: { icon: "user", color: "primary", size: "2x" },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      counter: "",
                      counterMaxValue: 60,
                      maxlength: "60",
                      label: "Given Names",
                      bg: "",
                    },
                    model: {
                      value: _vm.client.GivenNames,
                      callback: function ($$v) {
                        _vm.$set(_vm.client, "GivenNames", $$v)
                      },
                      expression: "client.GivenNames",
                    },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1 mx-2",
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      counter: "",
                      counterMaxValue: 40,
                      maxlength: "40",
                      label: "Surname",
                      bg: "",
                    },
                    model: {
                      value: _vm.client.Surname,
                      callback: function ($$v) {
                        _vm.$set(_vm.client, "Surname", $$v)
                      },
                      expression: "client.Surname",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-2 mt-4",
                    attrs: {
                      icon:
                        _vm.client.ClientType == "CT_O" ? "building" : "school",
                      color: "primary",
                      size: "2x",
                    },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1 mx-2",
                    attrs: {
                      counter: "",
                      counterMaxValue: 200,
                      maxlength: "200",
                      label: "Name",
                      bg: "",
                    },
                    model: {
                      value: _vm.client.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.client, "Name", $$v)
                      },
                      expression: "client.Name",
                    },
                  }),
                  _c("awgt-input", {
                    staticClass: "flex-grow-1 mx-2",
                    attrs: {
                      id: "client-abn",
                      counter: "",
                      counterMaxValue: 11,
                      maxlength: "11",
                      label: "ABN",
                      bg: "",
                    },
                    model: {
                      value: _vm.client.ABN,
                      callback: function ($$v) {
                        _vm.$set(_vm.client, "ABN", $$v)
                      },
                      expression: "client.ABN",
                    },
                  }),
                ],
                1
              ),
          _c("asoftAddressesCapture", {
            attrs: { template: _vm.getSystemTemplateFromCode("STT_C_Ad") },
            model: {
              value: _vm.client.Addresses,
              callback: function ($$v) {
                _vm.$set(_vm.client, "Addresses", $$v)
              },
              expression: "client.Addresses",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: { icon: "phone", color: "primary", size: "2x" },
              }),
              _c("asoft-phone-input", {
                staticClass: "mx-2",
                staticStyle: { width: "180px" },
                attrs: { maxlength: "20", label: "Phone", bg: "" },
                model: {
                  value: _vm.client.Phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "Phone", $$v)
                  },
                  expression: "client.Phone",
                },
              }),
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: { icon: "at", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "120px" },
                attrs: {
                  counterMaxValue: 254,
                  maxlength: "254",
                  counter: "",
                  label: "Email",
                  bg: "",
                },
                model: {
                  value: _vm.client.EmailAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "EmailAddress", $$v)
                  },
                  expression: "client.EmailAddress",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Booking Detail")]),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
        [
          _c("mdb-icon", {
            staticClass: "mx-2 mt-4",
            attrs: { icon: "user", color: "primary", size: "2x" },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            attrs: {
              counter: "",
              counterMaxValue: 50,
              maxlength: "50",
              label: "Booking Description",
              bg: "",
            },
            model: {
              value: _vm.booking.Description,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "Description", $$v)
              },
              expression: "booking.Description",
            },
          }),
        ],
        1
      ),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
        [
          _c(
            "span",
            { staticClass: "mr-5", staticStyle: { "min-width": "60px" } },
            [_vm._v("Booking Type")]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap min-width:120px;" },
            [
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "bookingType_School",
                  type: "radio",
                  name: "bookingType",
                  label: "School",
                  radioValue: "BT_S",
                },
                model: {
                  value: _vm.booking.BookingType,
                  callback: function ($$v) {
                    _vm.$set(_vm.booking, "BookingType", $$v)
                  },
                  expression: "booking.BookingType",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "d-flex flex-row flex-wrap" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: {
                icon: "calendar-alt",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("asoftDatePicker", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "140px" },
              attrs: {
                label: "Date Requested",
                autoHide: "",
                displayDateFormat: _vm.$constants.DATETIMEFORMAT_Presentation,
                dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                setTime: "00:00:00.000",
                bg: "",
                width: 300,
                tooltip: _vm.getTooltipsTextByCode(13),
              },
              model: {
                value: _vm.booking.RequestedDt,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "RequestedDt", $$v)
                },
                expression: "booking.RequestedDt",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: {
                icon: "calendar-alt",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("asoftDatePicker", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "140px" },
              attrs: {
                label: "Departure Date",
                autoHide: "",
                displayDateFormat: _vm.$constants.DATETIMEFORMAT_Presentation,
                dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                setTime: "00:00:00.000",
                bg: "",
                width: 300,
              },
              model: {
                value: _vm.booking.DepartureDt,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "DepartureDt", $$v)
                },
                expression: "booking.DepartureDt",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: {
                icon: "calendar-alt",
                far: "",
                color: "primary",
                size: "2x",
              },
            }),
            _c("asoftDatePicker", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "140px" },
              attrs: {
                label: "Return Date",
                autoHide: "",
                displayDateFormat: "D MMM YYYY",
                dataDateFormat: "YYYY-MM-DDTHH:mm:ss",
                setTime: "00:00:00.000",
                bg: "",
                width: 300,
              },
              model: {
                value: _vm.booking.ReturnDt,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "ReturnDt", $$v)
                },
                expression: "booking.ReturnDt",
              },
            }),
          ],
          1
        ),
        _vm.booking.DepartureDt != null && _vm.booking.ReturnDt != null
          ? _c(
              "div",
              { staticClass: "my-4 mx-2", staticStyle: { color: "purple" } },
              [_vm._v(" " + _vm._s(_vm.TravelDuration) + " ")]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            attrs: {
              label: "School Term",
              itemValueProperty: "Code",
              items: _vm.getSchoolTerms(false),
              itemTextProperty: "Name",
              bg: "",
              tooltip: _vm.getTooltipsTextByCode(14),
            },
            model: {
              value: _vm.booking.SchoolTerm,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "SchoolTerm", $$v)
              },
              expression: "booking.SchoolTerm",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            attrs: {
              label: "Booking Status",
              itemValueProperty: "Code",
              items: _vm.getBookingStatuses(false),
              itemTextProperty: "Name",
              bg: "",
              tooltip: _vm.getTooltipsTextByCode(15),
            },
            model: {
              value: _vm.booking.BookingStatus,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "BookingStatus", $$v)
              },
              expression: "booking.BookingStatus",
            },
          }),
          _c("awgt-std-switch", {
            staticClass: "flex-grow-1 mx-2",
            attrs: { offLabel: "Advanced Booking ", onLabel: "From Proposal" },
            model: {
              value: _vm.booking.IsFromProposal,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "IsFromProposal", $$v)
              },
              expression: "booking.IsFromProposal",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("School Group Detail")]),
      _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "hashtag", color: "primary", size: "2x" },
            }),
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              attrs: {
                itemValueProperty: "Code",
                items: _vm.getYearGroups,
                itemTextProperty: "Name",
                label: "Year Groups",
                bg: "",
                maxSelectedOptions: 13,
                multiple: "",
                selectAll: "",
              },
              model: {
                value: _vm.yearGroups,
                callback: function ($$v) {
                  _vm.yearGroups = $$v
                },
                expression: "yearGroups",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "hashtag", color: "primary", size: "2x" },
            }),
            _c("awgt-input", {
              directives: [
                {
                  name: "numeric",
                  rawName: "v-numeric",
                  value: { precision: 4, scale: 0, posOnly: true },
                  expression: "{ precision: 4, scale: 0, posOnly: true }",
                },
              ],
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { width: "200px" },
              attrs: {
                maxlength: "4",
                label: "Number of Students",
                bg: "",
                type: "text",
              },
              model: {
                value: _vm.booking.StudentsCount,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "StudentsCount", _vm._n($$v))
                },
                expression: "booking.StudentsCount",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "hashtag", color: "primary", size: "2x" },
            }),
            _c("awgt-input", {
              directives: [
                {
                  name: "numeric",
                  rawName: "v-numeric",
                  value: { precision: 4, scale: 0, posOnly: true },
                  expression: "{ precision: 4, scale: 0, posOnly: true }",
                },
              ],
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { width: "200px" },
              attrs: {
                maxlength: "4",
                label: "Free-of-Charge Adults",
                bg: "",
                type: "text",
                tooltip: _vm.getTooltipsTextByCode(23),
              },
              model: {
                value: _vm.booking.FreeOfChargeAdultsCount,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "FreeOfChargeAdultsCount", _vm._n($$v))
                },
                expression: "booking.FreeOfChargeAdultsCount",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "hashtag", color: "primary", size: "2x" },
            }),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: { precision: 4, scale: 0, posOnly: true },
                      expression: "{ precision: 4, scale: 0, posOnly: true }",
                    },
                  ],
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { width: "200px" },
                  attrs: {
                    maxlength: "4",
                    label: "Not Free-of-Charge Adults",
                    bg: "",
                    type: "text",
                    tooltip: _vm.getTooltipsTextByCode(24),
                  },
                  model: {
                    value: _vm.NotFreeOfChargeAdultsCount,
                    callback: function ($$v) {
                      _vm.NotFreeOfChargeAdultsCount = _vm._n($$v)
                    },
                    expression: "NotFreeOfChargeAdultsCount",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
          },
          [
            _c("mdb-icon", {
              staticClass: "mx-2",
              attrs: { icon: "hashtag", color: "primary", size: "2x" },
            }),
            _c(
              "span",
              { staticClass: "mx-2", staticStyle: { "font-size": "18px" } },
              [_vm._v("Group of " + _vm._s(_vm.groupSize))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "section-break" }),
      _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center" },
        [
          _c("h4", { staticClass: "mx-2" }, [_vm._v("Booking Contacts")]),
          _c("tdoxTooltip", {
            staticClass: "mb-1 mr-auto",
            attrs: { detail: _vm.getTooltipsTextByCode(29) },
          }),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$refs.bookingContactsDialog.addBookingContact()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
              _vm._v("New "),
            ],
            1
          ),
        ],
        1
      ),
      _c("travel-contacts", {
        ref: "bookingContactsDialog",
        staticClass: "mx-2",
        model: {
          value: _vm.booking.BookingContacts,
          callback: function ($$v) {
            _vm.$set(_vm.booking, "BookingContacts", $$v)
          },
          expression: "booking.BookingContacts",
        },
      }),
      _c("div", { staticClass: "section-break" }),
      _c("h4", { staticClass: "mx-2" }, [_vm._v("Booking Notes")]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
        },
        [
          _c("mdb-icon", {
            staticClass: "mx-2 mb-2",
            attrs: { icon: "clipboard", far: "", color: "primary", size: "2x" },
          }),
          _c("div", { staticClass: "ml-2" }, [_vm._v("Travel Booking Notes")]),
        ],
        1
      ),
      _c("mdb-wysiwyg", {
        staticClass: "mx-2",
        staticStyle: { height: "200px" },
        model: {
          value: _vm.booking.BookingNotes,
          callback: function ($$v) {
            _vm.$set(_vm.booking, "BookingNotes", $$v)
          },
          expression: "booking.BookingNotes",
        },
      }),
      _c("div", { staticClass: "d-flex flex-row flex-wrap" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "dollar-sign", color: "primary", size: "2x" },
            }),
            _c("awgt-input", {
              directives: [
                {
                  name: "numeric",
                  rawName: "v-numeric",
                  value: { precision: 10, scale: 2, posOnly: true },
                  expression: "{ precision: 10, scale: 2, posOnly: true }",
                },
              ],
              staticClass: "mx-2",
              staticStyle: { width: "140px" },
              attrs: {
                maxlength: "11",
                label: "Trip Profit",
                bg: "",
                type: "text",
              },
              model: {
                value: _vm.booking.TripProfit,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "TripProfit", _vm._n($$v))
                },
                expression: "booking.TripProfit",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { width: "210px" },
              attrs: {
                label: "Additional Loading",
                itemValueProperty: "Code",
                items: _vm.getAdditionalLoadingTypes(
                  _vm.booking.BookingType,
                  false
                ),
                itemTextProperty: "Name",
                bg: "",
                tooltip: _vm.getTooltipsTextByCode(25),
              },
              model: {
                value: _vm.booking.AdditionalLoadingType,
                callback: function ($$v) {
                  _vm.$set(_vm.booking, "AdditionalLoadingType", $$v)
                },
                expression: "booking.AdditionalLoadingType",
              },
            }),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "dollar-sign", color: "primary", size: "2x" },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                  [
                    _c("awgt-input", {
                      directives: [
                        {
                          name: "numeric",
                          rawName: "v-numeric",
                          value: { precision: 6, scale: 2, posOnly: true },
                          expression:
                            "{ precision: 6, scale: 2, posOnly: true }",
                        },
                      ],
                      staticClass: "flex-grow-1 mx-2",
                      staticStyle: { width: "210px" },
                      attrs: {
                        maxlength: "7",
                        bg: "",
                        label:
                          _vm.booking.AdditionalLoadingType == "AL_No"
                            ? `Additional Loading Amount (Disabled)`
                            : "Additional Loading Amount",
                        disabled: _vm.booking.AdditionalLoadingType == "AL_No",
                        type: "text",
                      },
                      model: {
                        value: _vm.booking.AdditionalLoadingAmt,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.booking,
                            "AdditionalLoadingAmt",
                            _vm._n($$v)
                          )
                        },
                        expression: "booking.AdditionalLoadingAmt",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "section-break" }),
      _c("rebates-and-discounts", {
        attrs: {
          isSchoolBooking: _vm.booking.BookingType == "BT_S" ? true : false,
        },
        model: {
          value: _vm.booking.RebatesAndDiscounts,
          callback: function ($$v) {
            _vm.$set(_vm.booking, "RebatesAndDiscounts", $$v)
          },
          expression: "booking.RebatesAndDiscounts",
        },
      }),
      _c("div", { staticClass: "section-break" }),
      _c("additional-charges", {
        attrs: {
          isSchoolBooking: _vm.booking.BookingType == "BT_S" ? true : false,
        },
        model: {
          value: _vm.booking.AdditionalCharges,
          callback: function ($$v) {
            _vm.$set(_vm.booking, "AdditionalCharges", $$v)
          },
          expression: "booking.AdditionalCharges",
        },
      }),
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: {
                type: "button",
                disabled: !_vm.isBookingDirty() && !_vm.isClientDirty(),
              },
              on: {
                click: function ($event) {
                  return _vm.onSave()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "check" } }),
              _vm._v("Save "),
            ],
            1
          ),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
              _vm._v("Close "),
            ],
            1
          ),
        ],
        1
      ),
      _c("asoftPrompt", {
        ref: "changingFutureBookingsPrompt",
        attrs: {
          headerClasses: "p-3 changingFutureBookingsPromptHeader",
          bodyClasses: "px-3 pt-3",
          footerClasses: "px-3 pb-3",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(" Change Booking Ownership ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " There are future bookings for this client that are owned by: "
                ),
                _c("br"),
                _c("br"),
                _c(
                  "ul",
                  { staticStyle: { margin: "0px" } },
                  _vm._l(_vm.otherOwners, function (e, idx) {
                    return _c("li", { key: idx }, [
                      _vm._v(
                        " " +
                          _vm._s(e.OwnerUserName) +
                          " - " +
                          _vm._s(e.OwnerWorkgroupName) +
                          " "
                      ),
                    ])
                  }),
                  0
                ),
                _c("br"),
                _vm._v(
                  " Would you like these future bookings to be owned by " +
                    _vm._s(_vm.bookingOwner) +
                    "? "
                ),
                _c("br"),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function ({ onClick }) {
              return [
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end align-center" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return onClick("Yes")
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "check" },
                        }),
                        _vm._v("Yes "),
                      ],
                      1
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mx-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return onClick("No")
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "times" },
                        }),
                        _vm._v("No "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.changingFutureBookingPromptInd,
          callback: function ($$v) {
            _vm.changingFutureBookingPromptInd = $$v
          },
          expression: "changingFutureBookingPromptInd",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }