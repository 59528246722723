<template>
  <div :style="{ 'z-index': { zIndex } }">
    <button
      id="labelBtn"
      style="
        border-width: 0px 0px 0px 0px;
        border-color: lightgrey;
        border-radius: 4.8px 4.8px 0px 0px;
        width: 100%;
        height: 48px;
        padding: 10px 10px 6.4px 12px;
        tabindex: 1;
        text-align: left;
      "
      :style="{
        color: showList == true ? activeLabelColor : labelColor,
        'border-color': showList == true ? activeColor : backgroundColor,
        'background-color': backgroundColor,
      }"
      @click="onLabelClick"
      @blur="onBlur"
    >
      <label id="label" :class="[hour != 0 || minute != 0 ? 'active' : '']">
        {{ label }}
      </label>
      <span style="position: absolute; color: black; float: left">{{
        durationlabel
      }}</span>
      <span
        style="
          position: relative;
          top: -8px;
          float: right;
          font-size: x-small;
          margin-right: -7px;
        "
        class="arrowbutton"
      >
        <template v-if="showList">▲</template>
        <template v-else>▼</template>
      </span>
    </button>
    <expansionRegion
      :toggle="showList"
      tag="div"
      :onTop="true"
      :openDuration="250"
      :closeDuration="250"
      :useVShowConditional="true"
      expectedHeight="90px"
    >
      <div
        :style="{
          border: '1px',
          display: 'block',
          position: 'absolute',
          width: '100%',
          top: '0rem',
          padding: '1px',
          '-webkit-box-shadow':
            '0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.12)',
          'box-shadow':
            '0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.12)',
        }"
      >
        <select
          id="hourList"
          size="5"
          style="width: 50%; tabindex: 2; margin-top: 3px"
          @blur="onBlur"
          @change="onHourChange"
        >
          <option
            v-for="(houritem, index) in hourSelection"
            :key="index"
            class="item"
            :selected="hour == houritem"
          >
            <span style="padding-left: 75%">{{ houritem }}</span>
          </option>
        </select>
        <select
          id="minuteList"
          size="5"
          style="
            position: absolute;
            left: 50%;
            width: 50%;
            top: 3px;
            tabindex: 3;
          "
          @keydown="onMinuteKeyDown"
          @blur="onBlur"
          @change="onMinuteChange"
        >
          <option
            v-for="(minuteitem, index) in minuteSelection"
            :key="index"
            class="item"
            :selected="minute == minuteitem"
          >
            <span style="padding-left: 75%">{{ minuteitem }}</span>
          </option>
        </select>
      </div>
    </expansionRegion>
  </div>
</template>

<script>
import expansionRegion from "@/components/ExpansionRegion";
//import { stringify } from "querystring";

export default {
  components: {
    expansionRegion,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    hourSelection: {
      type: Array,
      required: true,
    },
    minuteSelection: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "Duration",
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    backgroundColor: {
      type: String,
      default: "#f5f5f5",
    },
    labelColor: {
      type: String,
      default: "#757575",
    },
    activeColor: {
      type: String,
      default: "blue",
    },
    activeLabelColor: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      duration: "",
      hour: 0,
      minute: 0,
      showList: false,
    };
  },
  computed: {
    durationlabel: function () {
      if (this.hour == 0 && this.minute == 0) return "";
      if (this.hour == 1) {
        if (this.minute == 0) return `${this.hour} hour`;
        else return `${this.hour} hour, ${this.minute} minutes`;
      } else {
        if (this.minute == 0) return `${this.hour} hours`;
        else return `${this.hour} hours, ${this.minute} minutes`;
      }
    },
  },
  watch: {
    value: {
      handler: function (to, from) {
        if (to != from) {
          this.hour = Number(Math.floor(to / 60));
          this.minute = Number(to % 60);
        }
      },
      immediate: true,
    },
  },
  methods: {
    onHourChange(e) {
      if (e.target.selectedOptions.length > 0) {
        let hour = Number(e.target.selectedOptions[0].text);
        this.$emit("input", hour * 60 + this.minute);
      }
    },
    onMinuteChange(e) {
      if (e.target.selectedOptions.length > 0) {
        let minute = Number(e.target.selectedOptions[0].text);
        this.$emit("input", this.hour * 60 + minute);
      }
    },
    onMinuteKeyDown(e) {
      const KEYCODE_Tab = 9;

      if (
        (e.key === "Tab" || e.keyCode === KEYCODE_Tab) &&
        e.shiftKey == false
      ) {
        let labelBtn = document.getElementById("labelBtn");
        labelBtn.focus();
        e.preventDefault();
      }
    },
    onLabelClick() {
      this.showList = !this.showList;
      if (this.showList == true) {
        this.$nextTick(() => {
          let hourList = document.getElementById("hourList");
          hourList.focus();
        });
      }
    },
    onBlur(e) {
      //Close the list if the control fully loses focus.
      if (
        !e.relatedTarget ||
        ["labelBtn", "hourList", "minuteList"].indexOf(e.relatedTarget.id) == -1
      )
        this.showList = false;
    },
  },
};
</script>

<style scoped>
.item:hover {
  background-color: grey;
}

.selected-item {
  background-color: lightgrey;
}

label.active {
  -webkit-transform: translateY(-19px) translateX(-15px) scale(0.8);
  transform: translateY(-19px) translateX(-15px) scale(0.8);
}

label {
  position: absolute;
}

label.active {
  font-size: 1rem;
  float: left;
}

label.active::after {
  position: absolute;
  top: 65px;
  display: block;
  content: "";
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.arrowbutton {
  cursor: pointer;
  padding: 5px;
}

select {
  border: none;
}

#label:focus-within,
#labelBtn:focus-within,
#label:focus-within,
#labelBtn:focus-within {
  border-width: 0px 0px 2px 0px !important;
}
</style>
