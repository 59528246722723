<template>
  <div>
    <h5>
      <mdb-icon
        class="icon-in-views"
        :icon="getProductIcon(value.ProductType, value.ProductSubType)"
        size="1x"
        color="primary"
      />
      <span>{{ value.Name }} </span>
      <span style="font-size: 75%"
        ><i>({{ value.Reference }})</i></span
      >
    </h5>
    <h5 v-if="value.DisplayName" style="margin-top: -10px">
      <i>(Displayed as {{ value.DisplayName }})</i>
    </h5>
    <h5 class="mt-3">Details</h5>
    <div class="ml-4">
      <div
        v-if="value.Description != null && value.Description.length > 0"
        class="html-in-views"
      >
        <span v-html="value.Description"></span>
      </div>
      <div v-if="value.ProductType == 'PT_Tr'">
        <mdb-icon
          far
          class="icon-in-views"
          icon="car-bus"
          color="primary"
          size="1x"
        />
        <span>Minimum Seats: {{ value.MinSeatsNum }}</span>
        <mdb-icon
          far
          class="icon-in-views"
          icon="car-bus"
          color="primary"
          size="1x"
        />
        <span>Maximum Seats: {{ value.MaxSeatsNum }}</span>
      </div>
      <div v-if="value.ProductType == 'PT_Tr'">
        <mdb-icon
          far
          class="icon-in-views"
          icon="car-bus"
          color="primary"
          size="1x"
        />
        <span>Origins: {{ transportationOriginsForDisplay }}</span>
      </div>
      <div
        class="my-2"
        v-if="value.InformationURL != null && value.InformationURL.length > 0"
      >
        <mdb-icon
          far
          class="icon-in-views"
          icon="info"
          color="primary"
          size="1x"
        />
        <a :href="value.InformationURL" target="_blank">{{
          value.InformationURL
        }}</a>
      </div>
      <div v-if="value.BookingMethod.length > 0">
        <mdb-icon
          far
          color="primary"
          icon="phone-square-alt"
          class="icon-in-views"
          size="1x"
        />
        <span
          >Booking Method:
          {{ getBookingMethodNameByCode(value.BookingMethod) }}</span
        >
      </div>
      <div
        class="my-2"
        v-if="value.BookingURL != null && value.BookingURL.length > 0"
      >
        <mdb-icon
          far
          class="icon-in-views"
          icon="shopping-cart"
          size="1x"
          color="primary"
        />
        <a :href="value.BookingURL" target="_blank">{{ value.BookingURL }}</a>
        <br />
      </div>

      <div>
        <mdb-icon
          far
          class="icon-in-views"
          icon="user"
          color="primary"
          size="1x"
        />
        <span>Contact: {{ value.ContactPersonNm }}</span>
      </div>

      <template
        v-if="
          (value.ProductType == 'PT_An' || value.ProductType == 'PT_Ay') &&
          value.Addresses != null &&
          value.Addresses.length > 0
        "
      >
        <div
          v-if="
            physicalAddress.Street.length +
              physicalAddress.Locality.length +
              (physicalAddress.Subdivision != null
                ? physicalAddress.Subdivision.length
                : 0) +
              physicalAddress.Postcode.length >
            0
          "
        >
          <mdb-icon
            far
            class="icon-in-views"
            icon="map-marker-alt"
            color="primary"
            size="1x"
          />
          <template v-if="physicalAddress.Street.length > 0">
            <span>{{ physicalAddress.Street }},</span>
          </template>
          <template v-if="physicalAddress.Locality.length > 0">
            {{ physicalAddress.Locality }}
          </template>
          <template
            v-if="
              physicalAddress.Subdivision != null &&
              physicalAddress.Subdivision.length > 0
            "
          >
            {{ physicalAddress.Subdivision }}
          </template>
          <template v-if="physicalAddress.Postcode.length > 0">
            {{ physicalAddress.Postcode }}
          </template>
        </div>
      </template>

      <div class="my-2" v-if="value.Phone != null && value.Phone.length > 0">
        <mdb-icon
          far
          class="icon-in-views"
          icon="phone"
          color="primary"
          size="1x"
        />
        <a :href="'tel:' + value.Phone"
          ><asoft-field-formatter fieldType="PhoneNumber">{{
            value.Phone
          }}</asoft-field-formatter></a
        >
      </div>
      <div
        class="my-2"
        v-if="value.EmailAddress != null && value.EmailAddress.length > 0"
      >
        <mdb-icon
          far
          class="icon-in-views"
          icon="at"
          color="primary"
          size="1x"
        />
        <a :href="'mailto:' + value.EmailAddress">{{ value.EmailAddress }}</a>
        <br />
      </div>
      <div class="section-break"></div>
      <div class="d-flex flex-row flex-wrap" style="justify-content: center">
        <div
          class="box-margin"
          v-if="value.YearGroups != null && value.YearGroups.length > 0"
        >
          <tdox-box-display
            title="Year Groups"
            :content="value.YearGroups"
            icon="users-class"
          >
          </tdox-box-display>
        </div>
        <div
          class="box-margin"
          v-if="value.ProductType == 'PT_An' && value.MinBedsNum != null"
        >
          <tdox-box-display
            title="Minimum Number of Beds"
            :content="value.MinBedsNum"
            icon="bed-bunk"
          >
          </tdox-box-display>
        </div>
        <div
          class="box-margin"
          v-if="value.ProductType == 'PT_An' && value.MaxBedsNum != null"
        >
          <tdox-box-display
            title="Maximum Number of Beds"
            :content="value.MaxBedsNum"
            icon="bed-bunk"
          >
          </tdox-box-display>
        </div>
        <template v-if="value.ProductType == 'PT_Ay'">
          <div class="box-margin">
            <tdox-box-display
              title="Minimum participants per activity"
              :content="value.MinParticipantsPerGroup"
              icon="hashtag"
            >
            </tdox-box-display>
          </div>
          <div class="box-margin">
            <tdox-box-display
              title="Maximum participants per activity"
              :content="value.MaxParticipantsPerGroup"
              icon="hashtag"
            >
            </tdox-box-display>
          </div>
          <div class="box-margin">
            <tdox-box-display
              title="Participants per timeslot"
              :content="value.MaxParticipantsPerTimeslot"
              icon="hashtag"
            >
            </tdox-box-display>
          </div>
          <div
            class="box-margin"
            v-if="
              value.ProductType == 'PT_Ay' && value.DurationInMinutes != null
            "
          >
            <tdox-box-display
              title="Duration in minutes"
              :content="value.DurationInMinutes"
              icon="clock"
            >
            </tdox-box-display>
          </div>
        </template>
        <div class="box-margin">
          <tdox-box-display
            title="Pre-product Margin"
            :content="`${value.PreProductMarginInMinutes} minutes`"
            icon="clock"
          >
          </tdox-box-display>
        </div>
        <div class="box-margin">
          <tdox-box-display
            title="Post-product Margin"
            :content="`${value.PostProductMarginInMinutes} minutes`"
            icon="clock"
          >
          </tdox-box-display>
        </div>
        <div class="box-margin">
          <tdox-box-display
            title="Free-of-charge Ratio"
            :content="value.FreeOfChargeRatio"
            icon="percent"
          >
          </tdox-box-display>
        </div>
        <template v-if="value.DepositAmt != null">
          <div
            class="box-margin"
            v-if="value.DepositType == 'DT_PP' || value.DepositType == 'DT_FA'"
          >
            <tdox-box-display
              title="Deposit Type"
              :content="`$${value.DepositAmt} (${getDepositTypeNameByCode(
                value.DepositType
              )})`"
              icon="dollar-sign"
            >
            </tdox-box-display>
          </div>
          <div class="box-margin" v-else-if="value.DepositType == 'DT_Pe'">
            <tdox-box-display
              title="Deposit Amount"
              :content="`${value.DepositAmt}% (${getDepositTypeNameByCode(
                value.DepositType
              )})`"
              icon="percentage"
            >
            </tdox-box-display>
          </div>
          <div class="box-margin" v-else>
            <tdox-box-display
              title="Deposit Amount"
              content="No Deposit"
              icon="dollar-sign"
            >
            </tdox-box-display>
          </div>
        </template>
        <div
          class="box-margin"
          v-if="value.PaymentTypes != null && value.PaymentTypes.length > 0"
        >
          <tdox-box-display
            title="Min Duration"
            :content="`${value.MinDurationInMinutes} minutes`"
            icon="clock"
          >
          </tdox-box-display>
        </div>
        <div
          class="box-margin"
          v-if="value.PaymentTypes != null && value.PaymentTypes.length > 0"
        >
          <tdox-box-display
            title="Max Duration"
            :content="`${value.MaxDurationInMinutes} minutes`"
            icon="clock"
          >
          </tdox-box-display>
        </div>
        <div
          class="box-margin"
          v-if="value.PaymentTypes != null && value.PaymentTypes.length > 0"
        >
          <tdox-box-display
            title="Product Availability"
            :content="value.ProductAvailability"
            icon="calendar"
          >
          </tdox-box-display>
        </div>
      </div>
      <div class="section-break"></div>
      <template
        v-if="
          value.ProductType == 'PT_Ay' &&
          value.AvailableTimes != null &&
          value.AvailableTimes.length > 0
        "
      >
        <div style="text-align: center">
          <span :style="{ color: headingColor }" style="font-size: 1.2rem"
            >Start Times</span
          >
        </div>
        <div class="d-flex flex-row flex-nowrap">
          <div style="width: 50%; padding-left: 10%">
            <div style="text-align: center">
              <span :style="{ color: headingColor }" style="font-size: 1.2rem"
                >AM</span
              >
            </div>
            <div
              style="
                text-align: right;
                width: 100%;
                padding-left: 5%;
                padding-right: 5%;
              "
              class="d-flex flex-row justify-content-end flex-row flex-wrap"
            >
              <div
                v-for="(time, idx) in leftColOfStartTimes"
                v-bind:key="idx"
                style="font-size: medium; width: 90px"
              >
                {{ time }}
              </div>
            </div>
          </div>
          <div class="vertical-divider"></div>
          <div style="width: 50%; padding-right: 10%">
            <div style="text-align: center">
              <span :style="{ color: headingColor }" style="font-size: 1.2rem"
                >PM</span
              >
            </div>
            <div
              style="
                text-align: left;
                width: 100%;
                padding-left: 5%;
                padding-right: 5%;
              "
              class="d-flex justify-content-start flex-row flex-wrap"
            >
              <div
                v-for="(time, idx) in rightColOfStartTimes"
                v-bind:key="idx"
                style="font-size: medium; width: 90px"
              >
                {{ time }}
              </div>
            </div>
          </div>
        </div>
        <div class="section-break"></div>
      </template>
      <div style="text-align: center">
        <span :style="{ color: headingColor }" style="font-size: 1.2rem"
          >Product Pricing</span
        >
      </div>
      <div>
        <div style="text-align: center">
          <span :style="{ color: headingColor }" style="font-size: 1.2rem"
            >CURRENT</span
          >
        </div>

        <div>
          <div
            v-for="(pc, index) in value.CommercialProductLines"
            :key="index"
            class="my-2"
          >
            <div>
              <span style="display: none">{{
                (c = currentCommercialProductLineChronology(pc))
              }}</span>
            </div>
            <template v-if="c != null">
              <div class="d-flex flex-wrap flex-grow-1 mx-2">
                <span
                  class="flex-fill-chronology-name cell-padding"
                  :style="{ color: headingColor }"
                  style="font-size: 1rem"
                  >{{ c.Name }}</span
                >
                <span
                  v-if="c.ActiveToDt == null"
                  class="flex-fill cell-padding product-pricing-period"
                >
                  From
                  {{
                    convertDateFormat(
                      c.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span
                  v-else
                  class="flex-fill cell-padding product-pricing-period"
                >
                  {{
                    convertDateFormat(
                      c.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                  -
                  {{
                    convertDateFormat(
                      c.ActiveToDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span style="display: none">
                  {{
                    (mt = c.MarketCategories.map((mt) => {
                      return mt.Name;
                    }).join(", "))
                  }}</span
                >
                <span
                  class="flex-fill cell-padding product-pricing-market-category"
                  >{{ mt }}</span
                >
                <span class="flex-fill cell-padding">
                  <div
                    v-for="(p, index) in c.ProductLinePrices"
                    v-bind:key="index"
                  >
                    <span>
                      <span v-html="formatPricing(c, p)"></span>
                      <button
                        v-if="c.IsIndicativePricingInd == true"
                        type="button"
                        style="
                          border: none;
                          background-color: inherit;
                          color: grey;
                        "
                        :title="getTooltipsTextByCode(11)"
                        v-tippy="{ position: 'bottom', arrow: true }"
                      >
                        <mdb-icon
                          far
                          icon="exclamation-circle"
                          style="cursor: pointer"
                          :style="{
                            color: 'orange',
                          }"
                          size="1x"
                        /></button></span
                    ><br />
                  </div>
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="section-break"></div>
      <div style="text-align: center">
        <span
          @click="
            () => {
              toggleFutureProductPricing = !toggleFutureProductPricing;
            }
          "
          style="cursor: pointer"
        >
          <span :style="{ color: headingColor }" style="font-size: 1.2rem"
            >FUTURE</span
          >
          <span style="margin-left: 5px">
            <mdb-icon
              v-if="toggleFutureProductPricing == true"
              icon="angle-up"
              size="1x"
              class="pt-2"
              color="primary"
            />
            <mdb-icon
              v-else
              icon="angle-down"
              size="1x"
              class="pt-2"
              color="primary"
            />
          </span>
        </span>
      </div>
      <expansion-region :toggle="toggleFutureProductPricing">
        <div>
          <div
            v-for="(pc, index) in value.CommercialProductLines"
            :key="index"
            class="my-2"
          >
            <div>
              <span style="display: none">{{
                (f = futureCommercialProductLineChronology(pc))
              }}</span>
            </div>
            <div
              v-for="(chronology, indOfChronology) in f"
              v-bind:key="indOfChronology"
              class="my-2"
            >
              <div class="d-flex flex-wrap flex-grow-1 mx-2">
                <span
                  class="flex-fill-chronology-name cell-padding"
                  :style="{ color: headingColor }"
                  style="font-size: 1rem"
                  >{{ chronology.Name }}</span
                >
                <span
                  v-if="chronology.ActiveToDt == null"
                  class="flex-fill cell-padding product-pricing-period"
                >
                  From
                  {{
                    convertDateFormat(
                      chronology.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span
                  v-else
                  class="flex-fill cell-padding product-pricing-period"
                >
                  {{
                    convertDateFormat(
                      chronology.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                  -
                  {{
                    convertDateFormat(
                      chronology.ActiveToDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span style="display: none">
                  {{
                    (mt = chronology.MarketCategories.map((mt) => {
                      return mt.Name;
                    }).join(", "))
                  }}</span
                >
                <span
                  class="flex-fill cell-padding product-pricing-market-category"
                  >{{ mt }}</span
                >
                <span class="flex-fill cell-padding">
                  <div
                    v-for="(p, index) in chronology.ProductLinePrices"
                    v-bind:key="index"
                  >
                    <span>
                      <span
                        v-html="
                          formatPricing(
                            chronology,
                            p,
                            chronology.PriceTypeCd,
                            chronology.PricingScaleTypes
                          )
                        "
                      ></span>

                      <button
                        v-if="chronology.IsIndicativePricingInd == true"
                        type="button"
                        style="
                          border: none;
                          background-color: inherit;
                          color: grey;
                        "
                        :title="getTooltipsTextByCode(11)"
                        v-tippy="{ position: 'bottom', arrow: true }"
                      >
                        <mdb-icon
                          far
                          icon="exclamation-circle"
                          style="cursor: pointer"
                          :style="{
                            color: 'orange',
                          }"
                          size="1x"
                        /></button
                    ></span>
                    ><br />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </expansion-region>

      <div class="section-break"></div>
      <div style="text-align: center">
        <span
          @click="
            () => {
              togglePastProductPricing = !togglePastProductPricing;
            }
          "
          style="cursor: pointer"
        >
          <span :style="{ color: headingColor }" style="font-size: 1.2rem"
            >PAST</span
          >
          <span style="margin-left: 5px">
            <mdb-icon
              v-if="togglePastProductPricing == true"
              icon="angle-up"
              size="1x"
              class="pt-2"
              color="primary"
            />
            <mdb-icon
              v-else
              icon="angle-down"
              size="1x"
              class="pt-2"
              color="primary"
            />
          </span>
        </span>
      </div>
      <expansion-region :toggle="togglePastProductPricing">
        <div>
          <div
            v-for="(pc, index) in value.CommercialProductLines"
            :key="index"
            class="my-2"
          >
            <div>
              <span style="display: none">{{
                (p = pastCommercialProductLineChronology(pc))
              }}</span>
            </div>
            <div
              v-for="(chronology, indOfChronology) in p"
              v-bind:key="indOfChronology"
              class="my-2"
            >
              <div class="d-flex flex-wrap flex-grow-1 mx-2">
                <span
                  class="flex-fill-chronology-name cell-padding"
                  :style="{ color: headingColor }"
                  style="font-size: 1rem"
                  >{{ chronology.Name }}</span
                >
                <span
                  v-if="chronology.ActiveToDt == null"
                  class="flex-fill cell-padding product-pricing-period"
                >
                  From
                  {{
                    convertDateFormat(
                      chronology.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span
                  v-else
                  class="flex-fill cell-padding product-pricing-period"
                >
                  {{
                    convertDateFormat(
                      chronology.ActiveFromDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                  -
                  {{
                    convertDateFormat(
                      chronology.ActiveToDt,
                      $constants.DATETIMEFORMAT_Json,
                      $constants.DATETIMEFORMAT_Presentation
                    )
                  }}
                </span>
                <span style="display: none">
                  {{
                    (mt = chronology.MarketCategories.map((mt) => {
                      return mt.Name;
                    }).join(", "))
                  }}</span
                >
                <span
                  class="flex-fill cell-padding product-pricing-market-category"
                  >{{ mt }}</span
                >
                <span class="flex-fill cell-padding">
                  <div
                    v-for="(p, index) in chronology.ProductLinePrices"
                    v-bind:key="index"
                  >
                    <span>
                      <span
                        v-html="
                          formatPricing(
                            chronology,
                            p,
                            chronology.PriceTypeCd,
                            chronology.PricingScaleTypes
                          )
                        "
                      ></span>

                      <button
                        v-if="chronology.IsIndicativePricingInd == true"
                        type="button"
                        style="
                          border: none;
                          background-color: inherit;
                          color: grey;
                        "
                        :title="getTooltipsTextByCode(11)"
                        v-tippy="{ position: 'bottom', arrow: true }"
                      >
                        <mdb-icon
                          far
                          icon="exclamation-circle"
                          style="cursor: pointer"
                          :style="{
                            color: 'orange',
                          }"
                          size="1x"
                        /></button
                    ></span>
                    <br />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </expansion-region>

      <div class="section-break"></div>

      <template
        v-if="
          value.BookingFeeDetail != null && value.BookingFeeDetail.length > 0
        "
      >
        <div style="text-align: center">
          <span :style="{ color: headingColor }" style="font-size: 1.2rem"
            >Booking Fee Detail</span
          ><br />
          <span v-html="value.BookingFeeDetail" class="html-in-views"></span>
        </div>
        <div class="section-break"></div>
      </template>
      <div v-if="value.AWGTNotes != null && value.AWGTNotes.length > 0">
        <h5 :style="{ color: headingColor }">Agency Notes</h5>
        <span v-html="value.AWGTNotes" class="html-in-views"></span>
        <div class="section-break"></div>
      </div>
      <div v-if="value.ProductNotes != null && value.ProductNotes.length > 0">
        <h5 :style="{ color: headingColor }">Agency Product Notes</h5>
        <ul
          v-for="(note, index) in value.ProductNotes"
          :key="index"
          class="mb-0"
        >
          <li
            v-if="note.Note != null && note.Note.length > 0"
            class="html-in-views"
          >
            <span
              ><b>{{ note.Subject }}:</b></span
            >
            {{ note.Note }}
          </li>
        </ul>
        <div class="section-break"></div>
      </div>
      <template
        v-if="
          (value.SpecialComment != null && value.SpecialComment.length > 0) ||
          (value.PreProductItineraryComment != null &&
            value.PreProductItineraryComment.length > 0) ||
          (value.PostProductItineraryComment != null &&
            value.PostProductItineraryComment.length > 0)
        "
      >
        <h5 :style="{ color: headingColor }">Itinerary Notes</h5>
        <ul>
          <template
            v-if="
              value.SpecialComment != null && value.SpecialComment.length > 0
            "
          >
            <li>
              <div>
                <b>Special Comment:</b>
                <span
                  v-html="value.SpecialComment"
                  class="html-in-views"
                ></span>
              </div>
            </li>
          </template>
          <template
            v-if="
              value.PreProductItineraryComment != null &&
              value.PreProductItineraryComment.length > 0
            "
          >
            <li>
              <div>
                <b>Pre-product Itinerary Comment:</b>
                <span
                  v-html="value.PreProductItineraryComment"
                  class="html-in-views"
                ></span>
              </div>
            </li>
          </template>
          <template
            v-if="
              value.PostProductItineraryComment != null &&
              value.PostProductItineraryComment.length > 0
            "
          >
            <li>
              <div>
                <b>Post-product Itinerary Comment:</b>
                <span
                  v-html="value.PostProductItineraryComment"
                  class="html-in-views"
                ></span>
              </div>
            </li>
          </template>
        </ul>
      </template>
    </div>
    <br />
    <template v-if="value.ProductSubType != 'PST_AyFI'">
      <h5
        v-if="
          value.ProviderSupplier.Reference == value.ResellerSupplier.Reference
        "
        :style="{ color: headingColor }"
      >
        Reseller and Provider
      </h5>
      <h5 v-else>Provider</h5>
      <supplier-view
        :value="value.ProviderSupplier"
        class="ml-4"
      ></supplier-view>
      <br />

      <template
        v-if="
          value.ProviderSupplier.Reference != value.ResellerSupplier.Reference
        "
      >
        <h5>Reseller</h5>
        <supplier-view
          :value="value.ResellerSupplier"
          class="ml-4"
        ></supplier-view>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdbIcon } from "mdbvue";
import SupplierView from "@/components/SupplierView";
import ExpansionRegion from "@/components/ExpansionRegion";
import { sharedMethods } from "@/shared/shared";
import tdoxBoxDisplay from "@/components/Tourdox/tdoxBoxDisplay";
import asoftFieldFormatter from "@/components/AtomSoftware/asoftFieldFormatter";

export default {
  components: {
    mdbIcon,
    SupplierView,
    ExpansionRegion,
    tdoxBoxDisplay,
    asoftFieldFormatter,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    headingColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      togglePastProductPricing: false,
      toggleFutureProductPricing: false,
    };
  },

  computed: {
    ...mapGetters([
      "getDepositTypeNameByCode",
      "getTooltipsTextByCode",
      "getBookingMethodNameByCode",
    ]),

    physicalAddress: function () {
      return this.value.Addresses.find((a) => {
        return a.Type == "AT_Ph";
      });
    },
    paymentTypes: function () {
      let paymentTypesString = "";

      for (let pt of this.value.PaymentTypes) {
        switch (pt) {
          case "PY_PP":
            paymentTypesString += "Pre-paid, ";
            break;
          case "PY_PO":
            paymentTypesString += "Purchase Order, ";
            break;
          case "PY_Vo":
            paymentTypesString += "Voucher, ";
            break;
        }
      }

      return paymentTypesString.substring(0, paymentTypesString.length - 2);
    },
    availableTimesForDisplay() {
      if (this.value.AvailableTimes.length > 0) {
        return this.value.AvailableTimes.map((x) =>
          this.$moment(x, "HH:mm", false).format("h:mma")
        ).join(", ");
      } else return "";
    },
    transportationOriginsForDisplay() {
      if (this.value.TransportationOrigins.length > 0) {
        return this.value.TransportationOrigins.map((x) => x.Name).join(", ");
      } else return "";
    },
    leftColOfStartTimes() {
      if (this.value.AvailableTimes.length > 0) {
        return this.value.AvailableTimes.map((x) =>
          this.$moment(x, "HH:mm", false).format("h:mma")
        ).filter((t) => {
          return t.includes("am");
        });
      } else return "";
    },

    rightColOfStartTimes() {
      if (this.value.AvailableTimes.length > 0) {
        return this.value.AvailableTimes.map((x) =>
          this.$moment(x, "HH:mm", false).format("h:mma")
        ).filter((t) => {
          return t.includes("pm");
        });
      } else return "";
    },
  },

  methods: {
    ...sharedMethods,

    formatPricing(chronology, price) {
      let priceString = sharedMethods.formatPriceWithPriceType(
        chronology,
        price
      );
      let indexOfSplitter = priceString.indexOf(" ");

      let priceValue = priceString.substring(0, indexOfSplitter);
      return `<span class="text-primary">${priceValue}</span>${priceString.substring(
        indexOfSplitter
      )}`;
    },

    getPricingMethodTooltip(quantityUnit) {
      return sharedMethods.getPricingMethodTooltip(quantityUnit);
    },

    getPriceTypeText(priceType) {
      switch (priceType) {
        case "PrT_I":
          return "GST Inclusive";
        case "PrT_E":
          return "GST Exclusive";
        case "PrT_F":
          return "GST Free";
      }
    },

    formatCurrency(amount) {
      return (amount < 0 ? "-$" : "$") + Math.abs(amount).toFixed(2);
    },
    currentCommercialProductLineChronology(pc) {
      return pc.Chronology.find(
        (c) =>
          new Date(c.ActiveFromDt) <= new Date() &&
          (c.ActiveToDt == null || new Date(c.ActiveToDt) >= new Date())
      );
    },
    pastCommercialProductLineChronology(pc) {
      return pc.Chronology.filter(
        (c) => c.ActiveToDt != null && new Date(c.ActiveToDt) < new Date()
      );
    },
    futureCommercialProductLineChronology(pc) {
      return pc.Chronology.filter((c) => new Date(c.ActiveFromDt) > new Date());
    },
  },
};
</script>

<style scoped>
.box-margin {
  margin: 5px 10px 10px 10px;
}

.vertical-divider {
  margin-top: 10px;
  border-left: 3px solid lightgrey;
  margin-bottom: 10px;
}

.italics-font {
  font-style: italic;
}

td {
  padding: 10px 10px;
}

table {
  max-width: 50%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 950px) {
  .flex-fill {
    flex-basis: 0 !important;
  }

  .flex-fill-chronology-name {
    flex-basis: 30% !important;
  }

  .product-pricing-period {
    max-width: 200px;
  }

  .product-pricing-market-category {
    max-width: 150px;
  }
}

@media screen and (max-width: 950px) {
  .cell-padding {
    padding: 0px 15px 0px;
  }

  .flex-fill {
    flex-basis: 100% !important;
  }

  .product-pricing-period {
    flex-basis: 100% !important;
  }

  .product-pricing-market-category {
    flex-basis: 100% !important;
  }

  .dot {
    display: list-item;
    list-style-type: disc;
    margin-left: 30px;
  }
}
</style>
