<template>
  <mdb-switch
    :onLabel="onLabel"
    :offLabel="offLabel"
    :disabled="disabled"
    :classes="classes"
    :value="value"
    @getValue="onChange"
  />
</template>

<script>
import { mdbSwitch } from "mdbvue";

export default {
  name: "asoftSwitch",

  components: {
    mdbSwitch,
  },

  props: {
    offLabel: {
      type: String,
      default: "Off",
    },
    onLabel: {
      type: String,
      default: "On",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      val: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler: function (to, from) {
        if (to != from) {
          this.val = to;
        }
      },
    },
  },

  methods: {
    onChange(val) {
      this.val = val;
      this.$emit("getValue", this.val);
      this.$emit("input", this.val);
    },
  },
};
</script>
