var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [_vm._v("Issue Log")]),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "300px" },
              attrs: {
                label: "Title",
                counter: "",
                counterMaxValue: 50,
                maxlength: "50",
                bg: "",
              },
              model: {
                value: _vm.issueLog.Title,
                callback: function ($$v) {
                  _vm.$set(_vm.issueLog, "Title", $$v)
                },
                expression: "issueLog.Title",
              },
            }),
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "90px", "max-width": "150px" },
              attrs: {
                label: "Status",
                itemValueProperty: "Code",
                items: _vm.getIssueStatuses(false),
                itemTextProperty: "Name",
                bg: "",
              },
              model: {
                value: _vm.issueLog.SystemIssueStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.issueLog, "SystemIssueStatus", $$v)
                },
                expression: "issueLog.SystemIssueStatus",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ml-2 mr-3", staticStyle: { "min-width": "60px" } },
          [_vm._v("Description")]
        ),
        _c("mdb-wysiwyg", {
          staticClass: "mx-2",
          staticStyle: { height: "200px" },
          attrs: { label: "Description" },
          model: {
            value: _vm.issueLog.Description,
            callback: function ($$v) {
              _vm.$set(_vm.issueLog, "Description", $$v)
            },
            expression: "issueLog.Description",
          },
        }),
        _c(
          "div",
          { staticClass: "my-2 float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  type: "submit",
                  title: "Confirm",
                  disabled: !_vm.isFormDirty(),
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button", title: "Cancel" },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Close "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }