<template>
  <mdb-container fluid>
    <div
      style="top: 50px; position: -webkit- sticky; position: sticky; z-index: 1"
    ></div>
    <h1 class="text-center">Client Edit</h1>
    <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
      <span class="mr-5" style="min-width: 60px">Client Type</span>
      <div class="d-flex flex-row flex-wrap min-width:120px;">
        <awgt-input
          id="clientType_School"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="School"
          radioValue="CT_S"
          class="mx-2"
          style="min-width: 60px"
        />
        <awgt-input
          id="clientType_Individual"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="Individual"
          radioValue="CT_I"
          class="mx-2"
          style="min-width: 60px"
        />
        <awgt-input
          id="clientType_Organisation"
          type="radio"
          v-model="client.ClientType"
          name="clientTypeGroup"
          label="Organisation"
          radioValue="CT_O"
          class="mx-2"
          style="min-width: 60px"
        />
      </div>
    </mdb-form-inline>
    <div
      v-if="client.ClientType == 'CT_O' || client.ClientType == 'CT_S'"
      class="d-flex flex-row flex-nowrap flex-grow-1"
    >
      <mdb-icon
        :icon="client.ClientType == 'CT_O' ? 'building' : 'school'"
        color="primary"
        class="mx-2 mt-4"
        size="2x"
      />
      <awgt-input
        id="client-name"
        v-model="client.Name"
        counter
        :counterMaxValue="200"
        maxlength="200"
        label="School Name"
        bg
        class="flex-grow-1 mx-2"
      />
      <awgt-input
        id="client-abn"
        v-model="client.ABN"
        counter
        :counterMaxValue="11"
        maxlength="11"
        label="ABN"
        bg
        class="flex-grow-1 mx-2"
      />
    </div>
    <div v-else class="d-flex flex-row flex-wrap flex-grow-1">
      <mdb-icon icon="user" color="primary" class="mx-2 mt-4" size="2x" />
      <awgt-input
        id="client-givennames"
        v-model="client.GivenNames"
        counter
        :counterMaxValue="60"
        maxlength="60"
        label="Given Names"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      />
      <awgt-input
        id="client-surname"
        v-model="client.Surname"
        counter
        :counterMaxValue="40"
        maxlength="40"
        label="Surname"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 300px"
      />
    </div>
    <asoftAddressesCapture
      :template="getSystemTemplateFromCode('STT_C_Ad')"
      v-model="client.Addresses"
    ></asoftAddressesCapture>
    <div class="d-flex flex-row flex-nowrap flex-grow-1">
      <mdb-icon icon="phone" color="primary" class="mx-2 mt-4" size="2x" />
      <asoft-phone-input
        id="client-phone"
        v-model="client.Phone"
        maxlength="20"
        label="School Phone"
        style="width: 180px"
        bg
        class="mx-2"
      />
      <mdb-icon icon="at" color="primary" class="mx-2 mt-4" size="2x" />
      <awgt-input
        id="client-email"
        v-model="client.EmailAddress"
        :counterMaxValue="254"
        maxlength="254"
        counter
        label="School Email"
        style="min-width: 120px"
        bg
        class="flex-grow-1 mx-2"
      />
    </div>
    <div class="float-right mt-4">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onSave()"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />Save
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onCancel()"
      >
        <mdb-icon icon="times" class="mr-1" />Close
      </awgt-std-button>
    </div>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import { mdbContainer, mdbIcon, mdbFormInline } from "mdbvue";
import { mapGetters } from "vuex";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput";
import clientApi from "@/api/clientApi.js";
import asoftAddressesCapture from "@/components/AtomSoftware/asoftAddressesCapture.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    awgtInput,
    asoftPhoneInput,
    asoftAddressesCapture,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      test: [],
      client: {},
      formSubmissionErrors: [],
      clientSnapshot: {},
      isClientDirtyInd: false,
    };
  },
  computed: {
    ...mapGetters(["getEmptyClient", "getSystemTemplateFromCode"]),
  },
  methods: {
    async onSave() {
      this.$log.info("onSave...");
      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the client...");
        this.client.SearchAddressString = this.client.Addresses.map(
          (address) => {
            return Object.values(address).join(" ");
          }
        ).join(" ");
        await this.saveClient(this.client)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [
                `The client ${
                  this.client.ClientType == "CT_O" ||
                  this.client.ClientType == "CT_S"
                    ? this.client.Name
                    : this.client.GivenNames + " " + this.client.Surname
                } has been saved.`,
              ],
            });
            this.snapshotClient(this.client);
          })
          .catch((error) => {
            this.$log.error(error);
          });
      }
    },

    onCancel() {
      this.$router.go(-1);
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    validateForm() {
      this.formSubmissionErrors = [];
      if (
        this.client.ClientType == "CT_O" ||
        this.client.ClientType == "CT_S"
      ) {
        if (this.client.Name.length == 0) {
          this.formSubmissionErrors.push("Client Name is required.");
        }
        if (this.client.Name.length != 0 && this.client.Name.length > 200) {
          this.formSubmissionErrors.push(
            "Client Name must be no more than 200 character(s) in length."
          );
        }
      }
      if (this.client.ClientType == "CT_I") {
        if (this.client.GivenNames.length == 0) {
          this.formSubmissionErrors.push("Given name is required.");
        }
        if (
          this.client.GivenNames.length != 0 &&
          this.client.GivenNames.length > 60
        ) {
          this.formSubmissionErrors.push(
            "Given name must be no more than 60 character(s) in length."
          );
        }
        if (this.client.Surname.length == 0) {
          this.formSubmissionErrors.push("Surname is required.");
        }
        if (
          this.client.Surname.length != 0 &&
          this.client.Surname.length > 40
        ) {
          this.formSubmissionErrors.push(
            "Surname must be no more than 40 character(s) in length."
          );
        }
      }

      for (let address of this.client.Addresses) {
        if (address.Street.length > 90)
          this.formSubmissionErrors.push(
            `${address.Name} street must be no more than 90 character(s) in length.`
          );

        if (address.Locality.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} locality must be no more than 50 character(s) in length.`
          );

        if (address.Subdivision != null && address.Subdivision.length > 3)
          this.formSubmissionErrors.push(
            `${address.Name} subdivision must be no more than 3 character(s) in length.`
          );
        if (
          address.Postcode.length != 0 &&
          (address.Postcode.length < 3 || address.Postcode.length > 12)
        )
          this.formSubmissionErrors.push(
            `${address.Name} postcode must be at least 3 characters in lengths and no more than 12 characters in length.`
          );
        if (address.Country.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} country must be no more than 50 character(s) in length..`
          );
      }

      if (this.client.Phone.length == 0) {
        this.formSubmissionErrors.push("Phone is required.");
      }

      if (
        (this.client.Phone.length == 0 ||
          (this.client.Phone.length == 6 &&
            this.client.Phone.substring(0, 2) == "13") ||
          (this.client.Phone.length == 10 &&
            (this.client.Phone.substring(0, 4) == "1300" ||
              this.client.Phone.substring(0, 4) == "1800" ||
              this.client.Phone.substring(0, 1) == "0")) ||
          (this.client.Phone.length >= 11 && this.client.Phone.length <= 15)) == //International Numbers
        false
      ) {
        this.formSubmissionErrors.push(
          `Phone number ${this.client.Phone} is not recognised.`
        );
      }

      if (this.client.EmailAddress.length == 0) {
        this.formSubmissionErrors.push("Email Address is required.");
      }
      if (
        this.client.EmailAddress.length > 0 &&
        this.client.EmailAddress.length < 6
      ) {
        this.formSubmissionErrors.push(
          "An email address must be at least 6 character(s) in length."
        );
      }
      if (this.client.EmailAddress.length > 256) {
        this.formSubmissionErrors.push(
          "An email address must be no more than 256 character(s) in length."
        );
      }
      return this.formSubmissionErrors.length == 0;
    },

    /*
      TODO: Save methods should probably check if the form is actually dirty first
      before making a save.
    */
    async saveClient(client) {
      let storedClient = null;

      if (client.Reference == "")
        storedClient = await clientApi.postClient(client);
      else storedClient = await clientApi.putClient(client);

      this.client = this.$lodash.cloneDeep(this.getEmptyClient);

      /*
          GetEmptyClient contains a superset of properties from all client types,
          this is required for a client retrieved from the database because if
          you change client types then you need the properties of the other
          client types to be available.

          The values of the existing client needs to be merged into the
          getEmptyClient template... Object.assign does this for us.
       */

      Object.assign(this.client, storedClient);
    },

    /*     async waitOnTemplate() {
      let self = this;
      return new Promise((resolve) => {
        // Must be declared with 'var' to avoid the temporal dead zone
        var unwatch = self.$watch(
          "client.Addresses",
          (newVal) => {
            if (newVal != undefined && newVal.length > 0) {
              resolve();
              //Cancel the watch by calling unwatch. Reference: https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher
              if (unwatch) unwatch();
            }
          },
          { deep: true, immediate: true }
        );
      });
    },
 */
    async loadFormData(reference) {
      //await this.waitOnTemplate();
      /*
        This.client contains a superset of properties from all client types,
        this is required for a product retrieved from the database because if
        you change product types then you need the properties of the other
        client types to be available.

        If this is a new client then all that is required is a copy of the
        getEmptyClient template.

        If this is an existing client then the values of the existing client
        need to be merged into the getEmptyClient template... Object.assign
        does this for us.
       */

      if (reference != null) {
        //TODO: Catch the api call error across all loadFormData.
        let storedClient = await clientApi.getClientByReference(reference);
        Object.assign(this.client, storedClient);
      }
    },

    snapshotClient(client) {
      this.clientSnapshot = this.$lodash.cloneDeep(client);

      this.isClientDirtyInd = false;
    },

    isFormDirty() {
      if (this.isClientDirtyInd == true) return true;

      this.isClientDirtyInd =
        this.$lodash.isEqual(this.client, this.clientSnapshot) == false;
      return this.isClientDirtyInd;
    },
  },

  mounted() {
    this.client = this.$lodash.cloneDeep(this.getEmptyClient);

    let routeParams = this.$route.params;
    this.$log.info("RouteParams: %s", routeParams);
    let clientReference = routeParams.clientReference;
    this.loadFormData(clientReference).then(() => {
      this.snapshotClient(this.client);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSave().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
