import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getIPInfo() {
    try {
      logger.get(loggingSource.IPInfo).info("getIPInfo...");

      logger.get(loggingSource.IPInfo).time("getIPInfo call");

      // Axios client does not work here??
      const response = await fetch("https://ipinfo.io/json").catch((error) => {
        throw error;
      });

      logger.get(loggingSource.IPInfo).timeEnd("getIPInfo call");
      const ipInfo = response.data;

      logger.get(loggingSource.IPInfo).debug("getIPInfo: %s", ipInfo);

      return ipInfo;
    } catch (error) {
      logger.get(loggingSource.IPInfo).timeEnd("getIPInfo call");
      logger.get(loggingSource.IPInfo).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.IPInfo).info("...getIPInfo");
    }
  },
};
