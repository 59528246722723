var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [_vm._v("Tooltips")]),
    _c("div", { staticStyle: { margin: "0 auto", width: "70%" } }, [
      _c(
        "div",
        { staticClass: "d-flex flex-nowrap flex-row align-items-center" },
        [
          _c("span", { staticClass: "mx-2" }, [_vm._v("Filter:")]),
          _c("awgt-std-dropdown", {
            staticClass: "mx-2",
            attrs: {
              label: "System Screen",
              itemValueProperty: "Code",
              items: _vm.getSystemScreens(false),
              itemTextProperty: "Name",
              bg: "",
            },
            on: {
              "get-value": (value) => {
                _vm.onFilterTooltips(value)
              },
            },
            model: {
              value: _vm.filteredSystemScreenId,
              callback: function ($$v) {
                _vm.filteredSystemScreenId = $$v
              },
              expression: "filteredSystemScreenId",
            },
          }),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: { click: _vm.onClearFilter },
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-1",
                attrs: { icon: "eraser" },
              }),
              _vm._v("Clear "),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3",
      },
      [
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: { click: _vm.onAddTooltip },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "plus" } }),
            _vm._v("Add "),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { margin: "0 auto", width: "70%" } },
      _vm._l(_vm.tooltips, function (tooltip, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap flex-row align-items-center" },
              [
                _c(
                  "span",
                  { staticClass: "mx-2", staticStyle: { "min-width": "60px" } },
                  [
                    _vm._v(
                      "Tooltip ID: " +
                        _vm._s(
                          tooltip.TooltipId ? tooltip.TooltipId : "Pending"
                        )
                    ),
                  ]
                ),
                !tooltip.isAdded && _vm.checkPermission("CP_TtU")
                  ? _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button mt-1",
                        staticStyle: {
                          "min-width": "63px",
                          "margin-left": "auto",
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onDeleteTooltip(tooltip, index)
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "trash-alt" },
                        }),
                        _vm._v(" Delete "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.getSystemScreens(false)[0].Name !=
            "Loading getSystemScreen wait..."
              ? _c("awgt-std-dropdown", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "90px" },
                  attrs: {
                    label: "System Screen",
                    itemValueProperty: "Code",
                    items: _vm.getSystemScreens(false),
                    itemTextProperty: "Name",
                    bg: "",
                  },
                  model: {
                    value: tooltip.SystemScreenId,
                    callback: function ($$v) {
                      _vm.$set(tooltip, "SystemScreenId", $$v)
                    },
                    expression: "tooltip.SystemScreenId",
                  },
                })
              : _vm._e(),
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2 my-4",
              staticStyle: { "min-width": "90px" },
              attrs: {
                counter: "",
                counterMaxValue: 50,
                maxlength: "50",
                label: "Field Name",
                bg: "",
              },
              model: {
                value: tooltip.FieldName,
                callback: function ($$v) {
                  _vm.$set(tooltip, "FieldName", $$v)
                },
                expression: "tooltip.FieldName",
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
              },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mb-2",
                  attrs: {
                    icon: "clipboard",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("div", { staticClass: "ml-2" }, [_vm._v("Tooltip")]),
              ],
              1
            ),
            _c("mdb-wysiwyg", {
              staticClass: "mx-2",
              staticStyle: { height: "200px" },
              model: {
                value: tooltip.Text,
                callback: function ($$v) {
                  _vm.$set(tooltip, "Text", $$v)
                },
                expression: "tooltip.Text",
              },
            }),
            _c("div", { staticClass: "section-break" }),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end align-center" },
      [
        _vm.checkPermission("CP_TtU")
          ? _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button", disabled: !_vm.isFormDirty() },
                on: {
                  click: function ($event) {
                    return _vm.onUpdateTooltips()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            )
          : _vm._e(),
        _c(
          "awgt-std-button",
          {
            staticClass: "command-button mx-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose()
              },
            },
          },
          [
            _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
            _vm._v("Close "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }