<template>
  <mdb-container>
    <asoftTreeview
      @click="onClick"
      :value="treeviewData"
      childIndent="20px"
      childrenPropertyName="Children"
      slotNamePropertyName="CountryRegionTypeCd"
      :singleClickExpand="true"
    >
      <template v-slot:CRT_W="item">
        <span
          :class="[
            { selecteditem: item.selected == true },
            { leafnode: item.Children.length == 0 },
          ]"
        >
          <i class="typeicon fas fa-globe-asia"></i>
          {{ item.Name }}
        </span>
      </template>
      <template v-slot:CRT_C="item">
        <span
          :class="[
            { selecteditem: item.selected == true },
            { leafnode: item.Children.length == 0 },
          ]"
        >
          <i class="typeicon far fa-flag"></i>
          {{ item.Name }}
        </span>
      </template>
      <template v-slot:CRT_R="item">
        <span
          :class="[
            { selecteditem: item.selected == true },
            { leafnode: item.Children.length == 0 },
          ]"
        >
          <i class="typeicon fas fa-map"></i>
          {{ item.Name }}</span
        >
      </template>
      <template v-slot:CRT_P="item">
        <span
          :class="[
            { selecteditem: item.selected == true },
            { leafnode: item.Children.length == 0 },
          ]"
        >
          <i class="typeicon fas fa-map-marker-alt"></i>
          {{ item.Name }}</span
        >
      </template>
      <!--       <template v-slot:rotating_icon>
        <i class="stateicon fas fa-angle-right"></i>
      </template> -->
      <template v-slot:collapsed_icon>
        <i class="stateicon far fa-plus-square"></i>
      </template>
      <template v-slot:expanded_icon>
        <i class="stateicon far fa-minus-square"></i>
      </template>
    </asoftTreeview>
    <div class="mb-5 mt-2">
      <awgt-std-button
        v-if="checkPermission('CP_CRC')"
        type="button"
        class="command-button mx-2"
        :disabled="
          currentNode == null || currentNode.CountryRegionTypeCd == 'TOT_R'
        "
        @click="onOpenModal('add')"
      >
        Add
      </awgt-std-button>
      <awgt-std-button
        v-if="checkPermission('CP_CRD')"
        type="button"
        class="command-button mx-2"
        :disabled="
          currentNode == null || currentNode.CountryRegionTypeCd == 'TOT_W'
        "
        @click="onOpenModal('delete')"
      >
        Delete
      </awgt-std-button>
      <awgt-std-button
        v-if="checkPermission('CP_CRU')"
        type="button"
        class="command-button mx-2"
        :disabled="currentNode == null"
        @click="onOpenModal('rename')"
      >
        Rename
      </awgt-std-button>
    </div>

    <div>
      <asoftPrompt
        v-model="modal"
        refInitialFocus="modalInput"
        headerClasses="p-3 asoftPromptHeader"
        bodyClasses="px-3 pt-3"
        footerClasses="px-3 pb-3"
      >
        <template v-slot:header>
          <span v-if="modalOperation == 'add'"> Add Treenode? </span>
          <span v-if="modalOperation == 'delete'">
            Delete "{{ currentNode == null ? "" : currentNode.Name }}"?
          </span>
          <span v-if="modalOperation == 'rename'">
            Rename "{{ currentNode == null ? "" : currentNode.Name }}"?
          </span>
        </template>

        <template v-slot:body>
          <div v-if="modalOperation == 'add'">Enter the new node name.</div>
          <div v-if="modalOperation == 'delete'">
            Press 'Delete' to delete node '{{ currentNode.Name }}'.
          </div>
          <awgt-input
            ref="modalInput"
            v-if="modalOperation != 'delete'"
            v-model="newNodeName"
            label="Name"
            bg
            @keyup.native.enter="onSave()"
          />
        </template>
        <template v-slot:footer>
          <hr />
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onSave()"
          >
            {{ modalOperation == "delete" ? "Delete" : "Save" }}
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click.native="onCancel()"
          >
            Cancel
          </awgt-std-button>
        </template>
      </asoftPrompt>
    </div>
  </mdb-container>
</template>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.typeicon {
  width: 20px;
  color: $primary-color;
}
</style>
<style scoped>
.stateicon {
  width: 20px;
}

.leafnode {
  margin-left: 20px;
}
.selecteditem {
  background-color: lightgrey;
}
</style>
<script>
import { mdbContainer } from "mdbvue";

import asoftTreeview from "@/components/AtomSoftware/asoftTreeview.vue";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import countryRegionApi from "@/api/CountryRegionApi";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "TreeviewSelect",
  components: {
    mdbContainer,
    AwgtStdButton,
    asoftTreeview,
    asoftPrompt,
    awgtInput,
  },
  data() {
    return {
      modal: false,
      currentNode: null,
      //parentArray: null,
      newNodeName: "",
      modalOperation: "",
      treeviewData: [],
      formSubmissionErrors: [],
    };
  },

  watch: {
    modal(to, from) {
      if (to != from && to == true && this.modalOperation != "delete") {
        this.$nextTick(() => {
          this.$refs.modalInput.focus();
        });
      }
    },
  },

  computed: {
    ...mapGetters(["getEmptyCountryRegion", "checkPermission"]),
  },

  methods: {
    onClick(node) {
      this.currentNode = node;
      //this.findParentArray(node, this.treeviewData);
    },

    onOpenModal(operation) {
      this.modalOperation = operation;

      if (this.modalOperation == "rename") {
        this.newNodeName = this.currentNode.Name;
      } else if (this.modalOperation == "delete") {
        this.newNodeName = this.currentNode.Name;
      } else if (this.modalOperation == "add") {
        this.newNodeName = "";
      }

      this.modal = true;
    },

    onSave() {
      if (this.modalOperation == "rename") {
        this.renameClickedNode(this.newNodeName);
      } else if (this.modalOperation == "delete") {
        this.deleteClickedNode();
      } else if (this.modalOperation == "add") {
        let newCountryRegionTypeCd;
        switch (this.currentNode.CountryRegionTypeCd) {
          case "CRT_W":
            newCountryRegionTypeCd = "CRT_C";
            break;
          case "CRT_C":
            newCountryRegionTypeCd = "CRT_R";
            break;
          case "CRT_R":
            newCountryRegionTypeCd = "CRT_P";
            break;
          default:
            newCountryRegionTypeCd = "CRT_P";
            break;
        }
        this.addToClickedNode(
          this.currentNode,
          this.newNodeName,
          newCountryRegionTypeCd
        );
      }

      this.modal = false;
    },

    onClose() {
      this.modal = false;
    },

    onCancel() {
      this.modal = false;
    },

    deleteClickedNode() {
      countryRegionApi
        .deleteAsync(this.currentNode)
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [
              `The country region "${this.currentNode.Name}" has been deleted.`,
            ],
          });

          let parentNode = this.findNode(
            this.currentNode.CountryRegionParentId,
            this.treeviewData
          );

          parentNode.Children.splice(
            parentNode.Children.indexOf(this.currentNode),
            1
          );
        })
        .catch(() => {
          // Erros have been logged in the countryRegionApi.js
        });
    },

    renameClickedNode(name) {
      let oldName = this.currentNode.Name;

      this.currentNode.Name = name;
      countryRegionApi
        .putAsync(this.currentNode)
        .then((response) => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [
              `The country region "${this.currentNode.Name}" has been saved.`,
            ],
          });

          delete response.Children; //Remove the empty Children node before the Object.assign.
          Object.assign(this.currentNode, response);
        })
        .catch(() => {
          // Errors have been logged in the countryRegionApi.js
          this.currentNode.Name = oldName;
        });
    },

    addToClickedNode(parentNode, name, countryRegionTypeCd) {
      let node = this.$lodash.cloneDeep(this.getEmptyCountryRegion);
      node.Name = name;
      node.CountryRegionParentId = parentNode.CountryRegionId;
      node.CountryRegionTypeCd = countryRegionTypeCd;

      countryRegionApi
        .postAsync(node)
        .then((response) => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [`The country region "${node.Name}" has been saved.`],
          });

          //delete response.Children; //Remove the empty Children node before the Object.assign.
          parentNode.Children.push(response);
        })
        .catch(() => {
          // Errors have been logged in the countryRegionAPi.js
        });
    },

    findNode(nodeId, nodeArray) {
      for (let n of nodeArray) {
        if (n.CountryRegionId == nodeId) {
          return n;
        }
        if (n.Children && n.Children.length > 0) {
          let node = this.findNode(nodeId, n.Children);
          if (node != null) return node;
        }
      }
      return null;
    },

    async loadFormData() {
      countryRegionApi.getAsync().then((response) => {
        this.treeviewData = response;
      });

      logger
        .get(loggingSource.UICountryRegion)
        .info("Treeview Data: %s", this.treeviewData);
    },
  },
  mounted() {
    this.loadFormData();
  },
};
</script>
