<template>
  <mdb-container fluid>
    <h1 class="text-center">User Activity Report</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        label="Workgroup"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="workgroupsList"
        itemTextProperty="Name"
        v-model="workgroups"
        bg
        search
        multiple
        selectAll
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Consultants"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getConsultants"
        itemTextProperty="Name"
        v-model="consultants"
        bg
        search
        multiple
        selectAll
      ></awgt-std-dropdown>
      <!-- <asoft-select
        color="primary"
        label="Period"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getPeriods"
        itemTextProperty="Name"
        v-model="period"
        bg
      ></asoft-select> -->
    </div>
  </mdb-container>
</template>
<script>
import { mdbContainer } from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import workgroupApi from "@/api/workgroupApi.js";
import userApi from "@/api/userApi.js";

export default {
  data() {
    return {
      workgroupsList: [],
      consultantsList: [],
      workgroups: [],
      workgroupsCache: [],
      consultants: [],
      consultantsCache: [],
      period: "",
      periodCahce: "",
    };
  },
  components: {
    mdbContainer,
    AwgtStdDropdown,
  },
  computed: {
    getConsultants() {
      return this.consultantsList.filter((c) => {
        return (
          c.Workgroups &&
          c.Workgroups.some((w) => this.workgroups.indexOf(w) >= 0)
        );
      });
    },
  },
  methods: {
    async loadFormData() {
      this.workgroupsList = await workgroupApi.getWorkgroupDropdown();
      this.consultantsList = await userApi.getUserDropdown();
    },
  },
  mounted() {
    this.loadFormData();
  },
};
</script>
