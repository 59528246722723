var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-2" },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Impersonation")]),
      _c("div", [_vm._v("Workgroups:")]),
      _c("awgt-std-dropdown", {
        attrs: {
          multiple: "",
          selectAll: "",
          label: "Select",
          itemValueProperty: "Code",
          items: _vm.workgroups,
          itemTextProperty: "Name",
          bg: "",
        },
        on: { input: _vm.filterUsers },
        model: {
          value: _vm.filterWorkgroups,
          callback: function ($$v) {
            _vm.filterWorkgroups = $$v
          },
          expression: "filterWorkgroups",
        },
      }),
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSave.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex flex-grow-1" }, [
            _c(
              "span",
              {
                staticClass: "flex-fill heading sorting-header",
                staticStyle: { cursor: "pointer", "margin-left": "1px" },
                on: { click: _vm.onToggleFilter },
              },
              [
                _vm._v("User:"),
                _vm.onToggleFilterInOrder == true
                  ? _c("mdb-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "caret-down" },
                    })
                  : _vm._e(),
                _vm.onToggleFilterInOrder == false
                  ? _c("mdb-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "caret-up" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("span", { staticClass: "flex-fill heading sm-not-display" }, [
              _vm._v(" Role: "),
            ]),
            _c(
              "span",
              { staticClass: "flex-fill-workgroup heading sm-not-display" },
              [_vm._v(" Workgroups: ")]
            ),
            _c("span", { staticClass: "flex-fill heading sm-not-display" }, [
              _vm._v(" Impersonated By: "),
            ]),
            _c(
              "span",
              { staticClass: "flex-fill-end heading sm-not-display" },
              [_vm._v(" Validity: ")]
            ),
          ]),
          _vm._l(_vm.users, function (user, userIndex) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: user.isDisplayed == true,
                      expression: "user.isDisplayed == true",
                    },
                  ],
                  key: userIndex,
                },
                [
                  _vm._l(
                    user.Impersonators,
                    function (impersonator, impersonatorIndex) {
                      return _c(
                        "div",
                        {
                          key: impersonatorIndex,
                          staticClass:
                            "d-flex flex-wrap flex-grow-1 align-items-center my-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-fill flex-nowrap" },
                            [
                              impersonatorIndex == 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "flex-fill row-font bold",
                                      attrs: {
                                        "data-role":
                                          impersonatorIndex == 0
                                            ? ` - ${_vm.getConsultancyRoleNameById(
                                                user.ConsultancyRole
                                                  .ConsultancyRoleId
                                              )}`
                                            : null,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          user.FirstName + " " + user.Surname
                                        ) + " "
                                      ),
                                    ]
                                  )
                                : _c("span", {
                                    staticClass: "flex-fill row-font",
                                  }),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "flex-fill row-font sm-not-display",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    impersonatorIndex == 0
                                      ? _vm.getConsultancyRoleNameById(
                                          user.ConsultancyRole.ConsultancyRoleId
                                        )
                                      : null
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex-fill-workgroup row-font sm-not-display",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    impersonatorIndex == 0
                                      ? _vm.displayWorkgroups(user.Workgroups)
                                      : null
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "flex-fill row-font sm-display",
                              staticStyle: { "margin-left": "2px" },
                            },
                            [
                              _vm._v(
                                "Workgroups: " +
                                  _vm._s(
                                    impersonatorIndex == 0
                                      ? _vm.displayWorkgroups(user.Workgroups)
                                      : null
                                  )
                              ),
                            ]
                          ),
                          impersonator.Edit == true
                            ? _c(
                                "span",
                                { staticClass: "flex-fill" },
                                [
                                  impersonatorIndex == 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "sm-display",
                                          staticStyle: { "margin-left": "2px" },
                                        },
                                        [_vm._v("Impersonated By:"), _c("br")]
                                      )
                                    : _vm._e(),
                                  _c("awgt-std-dropdown", {
                                    staticClass: "identation",
                                    attrs: {
                                      label: "Impersonator",
                                      itemValueProperty: "Code",
                                      items:
                                        _vm.populateImpersonatorsList(user),
                                      itemTextProperty: "Name",
                                      bg: "",
                                    },
                                    model: {
                                      value: impersonator.Reference,
                                      callback: function ($$v) {
                                        _vm.$set(impersonator, "Reference", $$v)
                                      },
                                      expression: "impersonator.Reference",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          user.Impersonators.length === 1 &&
                          Object.keys(user.Impersonators[0]).length === 1 &&
                          impersonator.Edit == false
                            ? _c(
                                "span",
                                {
                                  staticClass: "flex-fill",
                                  staticStyle: { "margin-left": "2px" },
                                },
                                [_vm._v("User is not impersonated")]
                              )
                            : _vm._e(),
                          Object.keys(user.Impersonators[0]).length !== 1 &&
                          impersonator.Edit == false
                            ? _c("span", { staticClass: "flex-fill" }, [
                                impersonatorIndex == 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "sm-display",
                                        staticStyle: { "margin-left": "2px" },
                                      },
                                      [_vm._v(" Impersonated By: "), _c("br")]
                                    )
                                  : _vm._e(),
                                _c("span", { staticClass: "identation" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        impersonator.FirstName +
                                          " " +
                                          impersonator.Surname
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass:
                                "d-flex flex-fill-end datepicker-block-end flex-row flex-wrap",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row flex-nowrap identation align-items-center",
                                  staticStyle: { "min-width": "250px" },
                                },
                                [
                                  impersonator.Edit == true
                                    ? _c("mdb-icon", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          icon: "calendar-alt",
                                          far: "",
                                          color: "primary",
                                          size: "2x",
                                        },
                                      })
                                    : _vm._e(),
                                  impersonator.Edit == true
                                    ? _c("asoftDatePicker", {
                                        staticClass: "flex-grow-1 mx-2",
                                        staticStyle: { "max-width": "110px" },
                                        attrs: {
                                          label: "Start",
                                          autoHide: "",
                                          dataDateFormat: _vm.dataDateFormat,
                                          displayDateFormat:
                                            _vm.displayDateFormat,
                                          setTime: "00:00:00.000",
                                          bg: "",
                                        },
                                        on: {
                                          change: (fromDt) =>
                                            _vm.onFromDtChange(
                                              fromDt,
                                              impersonator
                                            ),
                                        },
                                        model: {
                                          value: impersonator.FromDt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              impersonator,
                                              "FromDt",
                                              $$v
                                            )
                                          },
                                          expression: "impersonator.FromDt",
                                        },
                                      })
                                    : _vm._e(),
                                  impersonator.Edit == true
                                    ? _c("mdb-icon", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          icon: "calendar-alt",
                                          far: "",
                                          color: "primary",
                                          size: "2x",
                                        },
                                      })
                                    : _vm._e(),
                                  impersonator.Edit == true
                                    ? _c("asoftDatePicker", {
                                        staticClass: "flex-grow-1 mx-2",
                                        staticStyle: { "max-width": "110px" },
                                        attrs: {
                                          label: "End",
                                          autoHide: "",
                                          dataDateFormat: _vm.dataDateFormat,
                                          displayDateFormat:
                                            _vm.displayDateFormat,
                                          setTime: "00:00:00.000",
                                          bg: "",
                                        },
                                        model: {
                                          value: impersonator.ToDt,
                                          callback: function ($$v) {
                                            _vm.$set(impersonator, "ToDt", $$v)
                                          },
                                          expression: "impersonator.ToDt",
                                        },
                                      })
                                    : _vm._e(),
                                  !(
                                    user.Impersonators.length === 1 &&
                                    Object.keys(user.Impersonators[0])
                                      .length === 1
                                  ) && impersonator.Edit == false
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "2px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.validityDatePeriod(
                                                impersonator
                                              )
                                            ) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row flex-nowrap identation align-items-center",
                                  staticStyle: { "min-width": "200px" },
                                },
                                [
                                  impersonator.Edit == false &&
                                  impersonatorIndex ==
                                    user.Impersonators.length - 1 &&
                                  _vm.checkPermission("CP_ImC") &&
                                  _vm.checkPermissionScopeForImperosnationCreate(
                                    user
                                  )
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "row-button mr-2",
                                          attrs: {
                                            type: "button",
                                            title: "Add",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onAddImpersonator(
                                                user,
                                                impersonator,
                                                impersonatorIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "plus" },
                                          }),
                                          _vm._v("Add "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !(
                                    user.Impersonators.length === 1 &&
                                    Object.keys(user.Impersonators[0])
                                      .length === 1
                                  ) &&
                                  impersonator.Edit == false &&
                                  _vm.checkPermission("CP_ImU") &&
                                  _vm.checkPermissionScopeForImpersonationEdit(
                                    user
                                  )
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "row-button mr-2",
                                          attrs: {
                                            type: "button",
                                            title: "Edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onEditImpersonator(
                                                user,
                                                impersonator
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "edit" },
                                          }),
                                          _vm._v("Edit "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !(
                                    user.Impersonators.length === 1 &&
                                    Object.keys(user.Impersonators[0])
                                      .length === 1
                                  ) &&
                                  impersonator.Edit == false &&
                                  _vm.checkPermission("CP_ImD") &&
                                  _vm.checkPermissionScopeForImpersonationDelete(
                                    user
                                  )
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "row-button mr-2",
                                          attrs: {
                                            type: "button",
                                            title: "Delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteImpersonator(
                                                user,
                                                impersonatorIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "trash-alt" },
                                          }),
                                          _vm._v("Delete "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  impersonator.Edit == true
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "row-button mr-2",
                                          attrs: {
                                            type: "button",
                                            title: "Save",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSaveImpersonator(
                                                user,
                                                impersonator
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "check" },
                                          }),
                                          _vm._v("Save "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  impersonator.Edit == true
                                    ? _c(
                                        "awgt-std-button",
                                        {
                                          staticClass: "row-button mr-2",
                                          attrs: {
                                            type: "button",
                                            title: "Cancel",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCancelImpersonator(
                                                user,
                                                impersonator,
                                                impersonatorIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "times" },
                                          }),
                                          _vm._v("Cancel "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  _c("div", { staticClass: "section-break" }),
                ],
                2
              ),
            ]
          }),
          _c(
            "div",
            { staticClass: "float-right mt-2 mb-5" },
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button", title: "history" },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "check" },
                  }),
                  _vm._v("History "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button",
                  attrs: { type: "button", title: "Update/Add" },
                  on: {
                    click: function ($event) {
                      return _vm.onClose()
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "times" },
                  }),
                  _vm._v("Close "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }