import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getWorkgroups(isWorkgroupSeachScreen = false) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("getWorkgroups...");

      logger.get(loggingSource.UIWorkgroup).time("getWorkgroups call");

      const response = await api.client
        .get("workgroup", {
          params: {
            IsWorkgroupSeachScreen: isWorkgroupSeachScreen,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIWorkgroup).timeEnd("getWorkgroups call");
      const workgroups = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("getWorkgroups workgroups: %s", workgroups);

      return workgroups;
    } catch (error) {
      logger.get(loggingSource.UIWorkgroup).timeEnd("getWorkgroups call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...getWorkgroups");
    }
  },

  async getWorkgroupDropdown() {
    try {
      logger.get(loggingSource.UIWorkgroup).info("getWorkgroupDropdown...");

      logger.get(loggingSource.UIWorkgroup).time("getWorkgroupDropdown call");

      const response = await api.client
        .get("workgroup/Dropdown", {
          params: {},
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("getWorkgroupDropdown call");
      const workgroups = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("getWorkgroupDropdown workgroups: %s", workgroups);

      return workgroups;
    } catch (error) {
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("getWorkgroupDropdown call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...getWorkgroupDropdown");
    }
  },

  async getWorkgroupByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("getWorkgroupByReference...");

      logger
        .get(loggingSource.UIWorkgroup)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be empty";
      }

      logger
        .get(loggingSource.UIWorkgroup)
        .time("getWorkgroupByReference call");
      const response = await api.client
        .get(`workgroup/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("getWorkgroupByReference call");
      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("getWorkgroupByReference workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("getWorkgroupByReference call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...getWorkgroupByReference");
    }
  },

  async postWorkgroup(workgroupPayload) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("postWorkgroup...");
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("Parameters => workgroupPayload: %s", workgroupPayload);

      logger.get(loggingSource.UIWorkgroup).time("postWorkgroup call");
      const response = await api.client
        .post("workgroup", workgroupPayload)
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger.get(loggingSource.UIWorkgroup).timeEnd("postWorkgroup call");
      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("postWorkgroup workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger.get(loggingSource.UIWorkgroup).timeEnd("postWorkgroup call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...postWorkgroup");
    }
  },

  async putWorkgroup(workgroupPayload) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("putWorkgroup...");
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("Parameters => workgroupPayload: %s", workgroupPayload);

      logger.get(loggingSource.UIWorkgroup).time("putWorkgroup call");
      const response = await api.client
        .put("workgroup", workgroupPayload)
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger.get(loggingSource.UIWorkgroup).timeEnd("putWorkgroup call");
      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("putWorkgroup workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger.get(loggingSource.UIWorkgroup).timeEnd("putWorkgroup call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...putWorkgroup");
    }
  },

  async deleteWorkgroup(workgroupPayload) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("deleteWorkgroup...");

      logger
        .get(loggingSource.UIWorkgroup)
        .debug("Parameters => workgroupPayload: %s", workgroupPayload);

      logger.get(loggingSource.UIWorkgroup).time("deleteWorkgroup call");
      await api.client
        .delete(
          `workgroup/${
            workgroupPayload.Reference
          }?concurrencyRV=${encodeURIComponent(workgroupPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger.get(loggingSource.UIWorkgroup).timeEnd("deleteWorkgroup call");
    } catch (error) {
      logger.get(loggingSource.UIWorkgroup).timeEnd("deleteWorkgroup call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...deleteWorkgroup");
    }
  },

  async postWorkgroupMember(workgroupReference, userReference) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("postWorkgroupMember...");
      logger
        .get(loggingSource.UIWorkgroup)
        .debug(
          "Parameters => workgroupReference: %s, UserReference: %s",
          workgroupReference,
          userReference
        );

      logger.get(loggingSource.UIWorkgroup).time("postWorkgroupMember call");
      const response = await api.client
        .post(`workgroup/${workgroupReference}/member/${userReference}`)
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger.get(loggingSource.UIWorkgroup).timeEnd("postWorkgroupMember call");
      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("return workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger.get(loggingSource.UIWorkgroup).timeEnd("postWorkgroupMember call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...postWorkgroupMember");
    }
  },

  async deleteWorkgroupMember(
    workgroupReference,
    userReference,
    concurrencyRV
  ) {
    try {
      logger.get(loggingSource.UIWorkgroup).info("deleteWorkgroupMember...");

      logger
        .get(loggingSource.UIWorkgroup)
        .debug(
          "Parameters => workgroupReference: %s, userReference: %s, concurrencyRV: %s",
          workgroupReference,
          userReference,
          concurrencyRV
        );

      logger.get(loggingSource.UIWorkgroup).time("deleteWorkgroupMember call");
      const response = await api.client
        .delete(
          `workgroup/${workgroupReference}/member/${userReference}?concurrencyRV=${encodeURIComponent(
            concurrencyRV
          )}`
        )
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null)
              throw error.response.data.message;
            else throw error.response.data.data;
          }
        });
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("deleteWorkgroupMember call");

      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("return workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("deleteWorkgroupMember call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...deleteWorkgroupMember");
    }
  },

  async postAssociateWorkgroupUsers(
    workgroupReference,
    parentUserReference,
    childUserReference
  ) {
    try {
      logger
        .get(loggingSource.UIWorkgroup)
        .info("postAssociateWorkgroupUsers...");
      logger
        .get(loggingSource.UIWorkgroup)
        .debug(
          "Parameters => workgroupReference: %s, parentUserReference: %s, childUserReference: %s",
          workgroupReference,
          parentUserReference,
          childUserReference
        );

      logger
        .get(loggingSource.UIWorkgroup)
        .time("postAssociateWorkgroupUsers call");
      const response = await api.client
        .post(
          `workgroup/${workgroupReference}/member/${parentUserReference}/child/${childUserReference}`
        )
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null) {
              if (error.response.data.error == null) throw error.message;
              else throw error.response.data.errors;
            } else throw error.response.data.data;
          }
        });
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("workgroupAssociatedNodes call");
      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("return workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("workgroupAssociatedNodes call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger.get(loggingSource.UIWorkgroup).info("...postWorkgroupMember");
    }
  },

  async deleteWorkgroupUserAssociation(
    workgroupReference,
    parentUserReference,
    childUserReference,
    concurrencyRV
  ) {
    try {
      logger
        .get(loggingSource.UIWorkgroup)
        .info("deleteWorkgroupUserAssociation...");

      logger
        .get(loggingSource.UIWorkgroup)
        .debug(
          "Parameters => workgroupReference: %s, parentUserReference: %s, childUserReference: %s, concurrencyRV: %s",
          workgroupReference,
          parentUserReference,
          childUserReference,
          concurrencyRV
        );

      logger
        .get(loggingSource.UIWorkgroup)
        .time("deleteWorkgroupUserAssociation call");
      const response = await api.client
        .delete(
          `workgroup/${workgroupReference}/member/${parentUserReference}/child/${childUserReference}?concurrencyRV=${encodeURIComponent(
            concurrencyRV
          )}`
        )
        .catch((error) => {
          if (error.response == null) throw error.message;
          else {
            if (error.response.data.data == null)
              throw error.response.data.message;
            else throw error.response.data.data;
          }
        });
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("deleteWorkgroupUserAssociation call");

      const workgroup = response.data;
      logger
        .get(loggingSource.UIWorkgroup)
        .debug("return workgroup: %s", workgroup);

      return workgroup;
    } catch (error) {
      logger
        .get(loggingSource.UIWorkgroup)
        .timeEnd("deleteWorkgroupUserAssociation call");
      logger.get(loggingSource.UIWorkgroup).error(error);
      throw new Error(error);
    } finally {
      logger
        .get(loggingSource.UIWorkgroup)
        .info("...deleteWorkgroupUserAssociation");
    }
  },
};
