var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asoft-modal-header", {
        attrs: { label: "Add Products to this Order" },
        on: { close: () => _vm.onCancel() },
      }),
      _c("mdb-modal-body", [
        _c(
          "div",
          [
            _c(
              "mdb-container",
              { attrs: { fluid: "" } },
              _vm._l(_vm.products, function (product, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "mb-3 mx-2 d-flex flex-row",
                    style: product.IsActive ? "opacity:1" : "opacity:0.5",
                  },
                  [
                    _c("awgt-input", {
                      staticStyle: { "min-width": "50px" },
                      attrs: {
                        id: "productSelectionRadiobutton" + index,
                        type: "radio",
                        name: "productSelectionRadiobutton",
                        label: " ",
                        radioValue: product.Reference,
                      },
                      model: {
                        value: _vm.selectedProductReference,
                        callback: function ($$v) {
                          _vm.selectedProductReference = $$v
                        },
                        expression: "selectedProductReference",
                      },
                    }),
                    _c("mdb-icon", {
                      staticClass: "mr-2",
                      staticStyle: { width: "36px" },
                      attrs: {
                        icon: _vm.getProductIcon(
                          product.ProductType,
                          product.ProductSubType
                        ),
                        size: "2x",
                        color: "primary",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        staticStyle: { "text-align": "left" },
                      },
                      [
                        _c("h5", { staticClass: "my-0" }, [
                          _vm._v(_vm._s(product.Name)),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c("mdb-modal-footer", [
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onSave },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button" },
                on: { click: _vm.onCancel },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Cancel "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }