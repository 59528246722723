var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-modal", { staticClass: "p-0", attrs: { show: _vm.showInd } }, [
    _c(
      "div",
      { class: _vm.headerClasses },
      [_vm._t("header", null, { onClick: this.onClick })],
      2
    ),
    _c(
      "div",
      { class: _vm.bodyClasses },
      [_vm._t("body", null, { onClick: this.onClick })],
      2
    ),
    _c(
      "div",
      { class: _vm.footerClasses },
      [_vm._t("footer", null, { onClick: this.onClick })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }