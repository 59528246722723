<template>
  <div>
    <asoftPrompt
      v-model="showPrompt"
      ref="handlePrompt"
      headerClasses="p-3 PromptHeader"
      bodyClasses="px-3 pt-3"
      footerClasses="px-3 pb-3"
    >
      <template v-slot:header> {{ promptHeader }} </template>
      <template v-slot:body>
        {{ promptMessage }}
      </template>
      <template v-slot:footer="{ onClick }">
        <hr />
        <div class="d-flex justify-content-end align-center">
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onClick('Ok')"
          >
            Ok
          </awgt-std-button>
        </div>
      </template>
    </asoftPrompt>
  </div>
</template>

<script>
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  data() {
    return { showPrompt: false, promptHeader: "", promptMessage: "" };
  },
  components: {
    asoftPrompt,
    AwgtStdButton,
  },
  methods: {
    onFirePrompt(header, message) {
      this.promptHeader = header;
      this.promptMessage = message;
      this.showPrompt = true;
    },
  },
};
</script>
