<template>
  <div>
    <div class="home align-content-center"></div>
  </div>
</template>
<style>
.logo {
  width: 50%;
  height: 50%;
  display: block;
  margin: auto;
}
</style>

<script>
export default {
  name: "home",
  components: {},
  data() {
    return {};
  },

  computed: {},
  watch: {},
  methods: {},
  created() {},
};
</script>
