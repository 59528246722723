<template>
  <mdb-btn v-bind="$attrs" :color="color" v-on="$listeners">
    <div :style="{ color: textColor }">
      <slot>
        <mdb-icon v-if="icon" class="mr-1" :icon="icon" />

        {{ title }}
      </slot>
    </div>
  </mdb-btn>
</template>

<script>
import { mdbBtn, mdbIcon } from "mdbvue";

import variables from "@/styles/sharedVariables.scss";

export default {
  props: {
    color: { type: String, default: variables.awgtStdButtonBackgroundColor },
    textColor: { type: String, default: variables.awgtStdButtonTextColor },
    title: { tpe: String },
    icon: { type: String, default: null },
    iconColor: {
      type: String,
      default: function () {
        return this.textColor;
      },
    },
  },
  components: {
    mdbBtn,
    mdbIcon,
  },
};
</script>
