<template>
  <mdb-modal
    size="md"
    v-if="dialogVisible == true"
    :show="dialogVisible"
    id="itineraryEntryDialog"
  >
    <expansion-region :toggle="saveAttempted == true">
      <div
        v-if="this.formSubmissionErrors.length > 0"
        class="savemessage-error mt-2"
      >
        Please correct the following errors:
        <ul style="margin: 0px">
          <li v-for="(e, idx) in formSubmissionErrors" :key="idx">{{ e }}</li>
        </ul>
      </div>
    </expansion-region>
    <asoft-modal-header label="Itinerary Entry Edit" @close="onCancel">
    </asoft-modal-header>
    <mdb-modal-body>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="sitemap"
            color="primary"
            class="mt-4 mr-1"
            style="width: 34px; font-size: 1.5em"
          />
          <awgt-std-dropdown
            :items="getItineraryEntryTypes(false)"
            itemValueProperty="Code"
            itemTextProperty="Name"
            label="Entry Type"
            class="flex-grow-1"
            style="width: 180px"
            v-model="itineraryEntry.ItineraryEntryType"
            bg
          ></awgt-std-dropdown>
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="sitemap"
            color="primary"
            class="mt-4 mx-1"
            style="width: 34px; font-size: 1.5em"
          />
          <awgt-std-dropdown
            :items="
              getItineraryEntrySubTypesByItineraryEntryType(
                itineraryEntry.ItineraryEntryType
              )
            "
            itemValueProperty="Code"
            itemTextProperty="Name"
            label="Entry Subtype"
            class="flex-grow-1"
            style="width: 180px"
            v-model="itineraryEntry.ItineraryEntrySubType"
            bg
          ></awgt-std-dropdown>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="ball-pile"
          color="primary"
          class="mt-4 mr-1"
          style="width: 34px; font-size: 1.5em"
        />
        <awgt-std-dropdown
          :items="primaryOrderProducts"
          itemValueProperty="Reference"
          :itemTextProperty="
            (p) =>
              p.ProviderSupplierName + ' - ' + p.Name + ' (' + p.Reference + ')'
          "
          label="Primary Product"
          v-model="itineraryEntry.PrimaryProductReference"
          class="flex-grow-1"
          bg
        ></awgt-std-dropdown>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="edit"
          far
          color="primary"
          class="mt-3 mr-1"
          size="2x"
          style="width: 34px"
        />
        <awgt-input
          v-model="itineraryEntry.Name"
          counter
          :counterMaxValue="210"
          maxlength="210"
          label="Title"
          bg
          class="flex-grow-1"
          type="text"
        />
      </div>

      Product Lines:
      <div v-if="currentPrimaryProduct != null">
        <div
          v-for="(pb, index2) in arrayUnique(
            itineraryEntry.OrderProductLines,
            'OrderReference'
          )"
          :key="index2"
        >
          {{ pb.OrderReference }}
          <table>
            <tr>
              <th>Name</th>
              <!-- <th style="width: 70px; padding: 0 0 0 5px">
                Ord.<tdoxTooltip
                  detail="The item quantity listed for this order"
                />
              </th>
              <th style="width: 70px; padding: 0 0 0 5px">
                Avail.<tdoxTooltip
                  detail="The item quantity remaining across this itinerary's entries"
                />
              </th>
              <th style="width: 90px; padding: 0 0 0 5px">
                Quantity<tdoxTooltip
                  detail="The item quantity required for this itinerary entry"
                />
              </th> -->
            </tr>
            <tr
              v-for="(opl, index) in itineraryEntry.OrderProductLines.filter(
                (pl) => pl.OrderReference == pb.OrderReference
              )"
              :key="index"
            >
              <td>{{ opl.Name }}</td>
              <!-- <td>{{ opl.OrderProductLineQuantities }}</td> -->
            </tr>
          </table>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="hashtag"
            color="primary"
            class="mt-4 mr-1"
            size="2x"
            style="width: 34px"
          />
          <awgt-input
            v-model.number="itineraryEntry.ChildParticipantCount"
            counter
            :counterMaxValue="3"
            maxlength="3"
            :label="
              (itineraryEntry.BookingType == 'BT_S' ? 'Student' : 'Child') +
              ' Participant Count'
            "
            bg
            class="flex-grow-1"
            style="width: 186px"
            type="text"
            v-numeric="{
              precision: 3,
              scale: 0,
              posOnly: true,
              trailingZeroScale: 0,
            }"
          ></awgt-input>
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="hashtag"
            color="primary"
            class="mt-4 mr-1"
            size="2x"
            style="width: 34px"
          />
          <awgt-input
            v-model.number="itineraryEntry.AdultParticipantCount"
            counter
            :counterMaxValue="3"
            maxlength="3"
            label="Adult Participant Count"
            bg
            class="flex-grow-1"
            style="width: 186px"
            type="text"
            v-numeric="{
              precision: 3,
              scale: 0,
              posOnly: true,
              trailingZeroScale: 0,
            }"
          ></awgt-input>
        </div>
      </div>
      <div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="users"
            color="primary"
            class="mt-4 mr-1"
            style="width: 34px; font-size: 1.6em"
          />
          <awgt-input
            v-model="itineraryEntry.GroupName"
            counter
            :counterMaxValue="20"
            maxlength="20"
            label="Group Name"
            bg
            class="flex-grow-1"
            style="width: 186px"
          ></awgt-input>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap flex-grow-1 mt-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mt-3 mr-1"
            size="2x"
            style="width: 34px"
          />
          <asoft-date-picker
            v-model="itineraryEntry.StartDT"
            label="From Date"
            autoHide
            displayDateFormat="D MMM YYYY"
            dataDateFormat="YYYY-MM-DDTHH:mm:ss"
            setTime="00:00:00.000"
            bg
            class="flex-grow-1"
            style="width: 180px"
          />
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="clock"
            far
            color="primary"
            class="mx-1 mt-3"
            size="2x"
            style="width: 34px"
          />
          <!-- TODO: Items needs to be changed to the chosen product bundle -->
          <!-- No longer using the term bundle, instead, it is order. -->
          <awgt-std-dropdown
            v-if="displayAvailableTimesSelect"
            :items="
              mapAvailableTimesArray(currentPrimaryProduct.AvailableTimes)
            "
            itemValueProperty="key"
            itemTextProperty="value"
            label="From Time"
            style="width: 180px"
            class="flex-grow-1 mt-0"
            v-model="startTime"
            bg
          ></awgt-std-dropdown>
          <asoft-time-picker
            v-else
            v-model="startTime"
            label="From Time"
            bg
            class="flex-grow-1 my-0"
            style="width: 180px"
            displayFormat="h:mma"
            outputFormat="HH:mm"
          />
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mr-1 mt-3"
            size="2x"
            style="width: 34px"
          />
          <asoft-date-picker
            v-model="itineraryEntry.EndDT"
            label="To Date"
            autoHide
            displayDateFormat="D MMM YYYY"
            dataDateFormat="YYYY-MM-DDTHH:mm:ss"
            bg
            class="flex-grow-1"
            style="width: 180px"
          />
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="clock"
            far
            color="primary"
            class="mx-1 mt-3"
            size="2x"
            style="width: 34px"
          />
          <asoft-time-picker
            v-model="endTime"
            label="To Time"
            bg
            class="flex-grow-1 mt-0"
            style="width: 180px"
            displayFormat="h:mma"
            outputFormat="HH:mm"
          />
        </div>
        <!-- :disabled="displayAvailableTimesSelect" -->
      </div>
      <awgt-input
        type="checkbox"
        id="isIndicativeTime"
        name="isIndicativeTime"
        v-model="itineraryEntry.IsIndicativeTime"
        label="Indicative time?"
        class="mt-1 mb-3"
        style="margin-left: 25px"
      />
      <awgt-input
        v-if="itineraryEntry.ItineraryEntryType == 'IET_Ay'"
        type="checkbox"
        id="RestrictToProductTimes"
        name="RestrictToProductTimesInd"
        v-model="itineraryEntry.RestrictToProductTimes"
        label="Restrict to product times?"
        class="mt-1 mb-3"
        style="margin-left: 25px"
      />
      <awgt-input
        type="checkbox"
        id="DisplayBoundariesOnly"
        name="DisplayBoundariesOnly"
        v-model="itineraryEntry.DisplayBoundariesOnly"
        label="Display boundaries only?"
        class="mt-1 mb-3"
        style="margin-left: 25px"
      />
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="clock"
            far
            color="primary"
            class="mr-1 mt-3"
            size="2x"
            style="width: 34px"
          />
          <awgt-std-dropdown
            ref="PreEntryMarginInMinutes"
            :items="getProductMargins"
            itemValueProperty="productMarginInMinutes"
            itemTextProperty="name"
            label="Pre Event Margin"
            style="width: 186px"
            class="flex-grow-1"
            v-model="itineraryEntry.PreEntryMarginInMinutes"
            bg
          ></awgt-std-dropdown>
        </div>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="clock"
            far
            color="primary"
            class="mx-1 mt-3"
            size="2x"
            style="width: 34px"
          />
          <awgt-std-dropdown
            ref="PostEntryMarginInMinutes"
            :items="getProductMargins"
            itemValueProperty="productMarginInMinutes"
            itemTextProperty="name"
            label="Post Event Margin"
            style="width: 186px"
            class="flex-grow-1"
            v-model="itineraryEntry.PostEntryMarginInMinutes"
            bg
          ></awgt-std-dropdown>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <awgt-input
          type="checkbox"
          id="showPreSupplierItineraryComment"
          name="showPreSupplierItineraryComment"
          v-model="itineraryEntry.ShowPreSupplierItineraryComment"
          label="Show pre-supplier itinerary comment?"
          class="mt-1 mb-3"
          style="margin-left: 25px"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <awgt-input
          type="checkbox"
          id="showPostSupplierItineraryComment"
          name="showPostSupplierItineraryComment"
          v-model="itineraryEntry.ShowPostSupplierItineraryComment"
          label="Show post-supplier itinerary comment?"
          class="mt-1 mb-3"
          style="margin-left: 25px"
        />
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1">
        <mdb-icon
          icon="file-alt"
          far
          color="primary"
          class="mt-3 mr-1"
          size="2x"
          style="width: 34px"
        />
        <awgt-input
          v-model="itineraryEntry.Description"
          counter
          :counterMaxValue="1000"
          maxlength="1000"
          label="Description"
          bg
          class="flex-grow-1"
          type="text"
        />
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mr-2 mb-2"
          size="2x"
        />
        <div>Agency Notes</div>
      </div>
      <mdb-wysiwyg
        v-model="itineraryEntry.Notes"
        style="height: 200px"
      ></mdb-wysiwyg>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="float-right">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onDelete"
        >
          <mdb-icon icon="trash-alt" class="mr-1" />Delete
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSave"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onCancel"
        >
          <mdb-icon icon="times" class="mr-1" />Cancel
        </awgt-std-button>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style scoped>
.pl0-important .form-check {
  padding-left: 0 !important;
}

table,
th,
td {
  border: 1px solid lightgrey;
  border-collapse: collapse;
}

table td {
  text-align: left;
  padding: 0px 0px 0px 10px;
}

table th {
  text-align: left;
}

table {
  margin: 0px;
  width: 100%;
}
</style>
<script>
import { mdbIcon, mdbModal, mdbModalBody, mdbModalFooter } from "mdbvue";

import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftTimePicker from "@/components/AtomSoftware/asoftTimePicker/asoftTimePicker";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import itineraryEntryApi from "@/api/ItineraryEntryApi.js";
import { mapGetters } from "vuex";
import ExpansionRegion from "@/components/ExpansionRegion";
import { sharedMethods } from "@/shared/shared";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbIcon,
    awgtInput,
    AwgtStdDropdown,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    asoftDatePicker,
    asoftTimePicker,
    mdbWysiwyg,
    ExpansionRegion,
    asoftModalHeader,
    AwgtStdButton,
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getProductMargins",
      "getItineraryEntryTypes",
      "getEmptyItineraryEntry",
      "getItineraryEntrySubTypesByItineraryEntryType",
      "getPrimaryOrderProductSubTypesForItineraryEntrySubType",
    ]),

    currentPrimaryProduct() {
      if (this.itineraryEntry.PrimaryProductReference == null) return null;
      else
        return this.primaryOrderProducts.find(
          (pb) => pb.Reference == this.itineraryEntry.PrimaryProductReference
        );
    },

    productOrdersContainingTheCurrentPrimaryProduct() {
      let orders = [];

      if (this.currentPrimaryProduct == null) return [];
      else
        for (let order of this.orders) {
          if (
            order.PrimaryOrderProducts.find(
              (pbcp) => pbcp.Reference == this.currentPrimaryProduct.Reference
            ) != undefined
          )
            orders.push(order);
        }

      return orders;
    },

    primaryOrderProducts() {
      let PrimaryOrderProducts = [];
      let primaryOrderProductSubTypes =
        this.getPrimaryOrderProductSubTypesForItineraryEntrySubType(
          this.itineraryEntry.ItineraryEntrySubType
        );
      for (let order of this.orders) {
        let orderProduct = order.PrimaryOrderProducts.filter((pop) =>
          primaryOrderProductSubTypes.includes(pop.ProductSubTypeCd)
        );

        PrimaryOrderProducts = PrimaryOrderProducts.concat(orderProduct);
      }

      return this.arrayUnique(PrimaryOrderProducts, "Reference");
    },

    displayAvailableTimesSelect() {
      return (
        this.itineraryEntry.ItineraryEntryType == "IET_Ay" &&
        this.currentPrimaryProduct != null &&
        this.currentPrimaryProduct.AvailableTimes.length > 0 &&
        this.itineraryEntry.RestrictToProductTimes == true
      );
    },
  },

  data() {
    return {
      startTime: "12:00",
      endTime: "12:00",
      formSubmissionErrors: [],
      saveAttempted: false,
      selectedProducts: [],
      itineraryEntry: this.$lodash.cloneDeep(this.getEmptyItineraryEntry),
      validDateTimeFormats: [
        "D MMM YYYY h:m A",
        "D MMM YYYY h:mA",
        "D MMM YYYY HHmm",
        "YYYY-MM-DD HH:mm",
        "YYYY-MM-DDTHH:mm:ss",
      ],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
    itineraryReference: {
      type: String,
      required: true,
    },
    itineraryStartDate: {
      type: String,
      required: true,
    },
    itineraryEndDate: {
      type: String,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    travelBookingParticipantCount: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    value: {
      handler: function (to, from) {
        if (to != from) {
          this.itineraryEntry = to;
        }
      },
      deep: true,
    },

    "itineraryEntry.PrimaryProductReference"(to) {
      if (to != null) {
        if (this.itineraryEntry.Name.length == 0) {
          let p = this.currentPrimaryProduct;
          this.itineraryEntry.Name = p.ProviderSupplierName + " - " + p.Name;
        }

        this.getOrderProductLines(this.itineraryEntry);
      }
    },

    startTime(to, from) {
      if (to != from) {
        this.adjustEndTimeIfRequired(to);
      }
    },

    "itineraryEntry.RestrictToProductTimes"(to, from) {
      if (to != from) {
        this.adjustEndTimeIfRequired(this.startTime);
      }
    },

    dialogVisible: async function (newVal, oldVal) {
      if (oldVal != newVal && newVal == true) {
        await this.openDialog();
      }
    },

    "itineraryEntry.StartDT"(to) {
      if (to != null) {
        if (this.itineraryEntry.StartDT > this.itineraryEntry.EndDT)
          this.itineraryEntry.EndDT = this.itineraryEntry.StartDT;
      }
    },

    "itineraryEntry.EndDT"(to) {
      if (to != null) {
        if (this.itineraryEntry.StartDT > this.itineraryEntry.EndDT)
          this.itineraryEntry.StartDT = this.itineraryEntry.EndDT;
      }
    },

    "itineraryEntry.ItineraryEntryType"(to, from) {
      if (to != from) {
        switch (to) {
          case "IET_Ay":
            this.itineraryEntry.ItineraryEntrySubType = "IEST_AyUn";
            break;
          case "IET_An":
            this.itineraryEntry.ItineraryEntrySubType = "IEST_AnUn";
            break;
        }
      }
    },
  },
  methods: {
    ...sharedMethods,

    async getOrderProductLines(itineraryEntry) {
      let orderProduct;
      let targetOrder;
      for (let order of this.orders) {
        orderProduct = order.PrimaryOrderProducts.find(
          (pop) => pop.Reference === itineraryEntry.PrimaryProductReference
        );
        if (orderProduct) {
          targetOrder = order;
          break;
        }
      }

      itineraryEntry.OrderProductLines = orderProduct.OrderProductLines.map(
        (orderProductLine) => {
          return {
            OrderProductLineQuantities:
              orderProductLine.OrderProductLineQuantities,
            ReceivedDt: new Date(),
            Quantity: 0,
            Name: orderProductLine.Name,
            OrderReference: targetOrder.Reference,
            Reference: orderProductLine.Reference,
          };
        }
      );
    },

    adjustEndTimeIfRequired(newStartTime) {
      if (newStartTime == null || newStartTime == "") return;
      if (
        this.itineraryEntry.ItineraryEntryType == "IET_Ay" &&
        this.itineraryEntry.RestrictToProductTimes == true &&
        this.currentPrimaryProduct != null
      ) {
        let startDt = this.$moment(newStartTime, this.validDateTimeFormats)
          .hour(
            this.$moment(
              "01 jan 2020 " + this.startTime,
              "DD MMM YYYY HH:mm"
            ).hour()
          )
          .minute(
            this.$moment(
              "01 jan 2020 " + this.startTime,
              "DD MMM YYYY HH:mm"
            ).minute()
          )
          .add(this.currentPrimaryProduct.DurationInMinutes, "minutes");

        this.endTime = this.$moment(startDt, this.validDateTimeFormats).format(
          "HH:mm"
        );
      }
    },

    mapAvailableTimesArray(availableTimes) {
      let x = availableTimes?.map((t) => {
        return { key: t, value: this.convertTimeFrom24To12(t) };
      });

      return x;
    },

    convertTimeFrom24To12(time) {
      let hours = parseInt(time.split(":")[0]);
      let minutes = parseInt(time.split(":")[1]);
      let ampm = "am";

      if (hours > 12) {
        hours = hours - 12;
        ampm = "pm";
      }

      let hoursString = hours.toString();

      let minutesString =
        minutes < 10 ? `0${minutes.toString()}` : `${minutes.toString()}`;

      if (hours > 12) return hoursString + ":" + minutesString + ampm;
      else return hoursString + ":" + minutesString + ampm;
    },

    async openDialog() {
      this.formSubmissionErrors = [];
      /* Log input properties */
      this.$log.info("Open ItineraryEntryDialog");
      this.$log.info("value: %s", this.value);
      this.$log.info(
        "Travel Booking Participant Count: %s",
        this.travelBookingParticipantCount
      );

      /* Validate input properties */
      if (this.value == null) this.$log.error("Prop 'value' must not be null");
      if (this.travelBookingParticipantCount == 0)
        this.$log.error(
          "Prop 'travelBookingParticipantCount' should be greater than zero"
        );

      let itineraryEntry = null;
      if (this.value.ItineraryEntryId > 0) {
        // Load the itinerary entry from the database.
        itineraryEntry = await this.loadItineraryEntry(
          this.itineraryReference,
          this.value.ItineraryEntryId
        );
      } else {
        // Load a blank itinerary.
        itineraryEntry = this.$lodash.cloneDeep(this.value); //operate on a copy of the itineraryEntry (so we can cancel)

        //TODO: restrict the calendar control to these dates or use form validation.
        //TODO: This should apply at the webapi as well.
        itineraryEntry.GroupSize = this.travelBookingParticipantCount;
      }

      /* Parse the start date/time into start date and start time */
      this.startTime = this.$moment(
        itineraryEntry.StartDT,
        this.validDateTimeFormats
      ).format("HH:mm");

      /* Parse the end date/time into end date and end time */
      this.endTime = this.$moment(
        itineraryEntry.EndDT,
        this.validDateTimeFormats
      ).format("HH:mm");

      this.$log.info("itinerary Entry %s", itineraryEntry);

      this.itineraryEntry = itineraryEntry;

      //Default the itinerary participant count to the booking's participant count
      // this.itineraryEntry.GroupSize = this.travelBookingParticipantCount;
    },

    validateForm() {
      this.formSubmissionErrors = [];

      if (this.itineraryEntry.Name == "")
        this.formSubmissionErrors.push("A title is required.");

      if (this.startTime == null || this.startTime == "")
        this.formSubmissionErrors.push("A start time must be provided");

      if (this.endTime == "")
        this.formSubmissionErrors.push("An end time must be provided");

      let startDT = this.$moment(this.itineraryEntry.StartDT).add(
        15,
        "minutes"
      );

      if (startDT.isAfter(this.$moment(this.itineraryEntry.EndDT)))
        this.formSubmissionErrors.push(
          "The end date must be at least 15 minutes after the start date."
        );

      if (this.itineraryEntry.ItineraryEntryType == null)
        this.formSubmissionErrors.push("An entry type is required.");

      if (this.itineraryEntry.ItineraryEntrySubType == null)
        this.formSubmissionErrors.push("An entry subtype is required.");

      return this.formSubmissionErrors.length == 0;
    },

    async loadItineraryEntry(itineraryReference, itineraryEntryId) {
      return await itineraryEntryApi.getItineraryEntryById(
        itineraryReference,
        itineraryEntryId
      );
    },

    async saveItineraryEntry(itineraryReference, itineraryEntry) {
      if (itineraryEntry.ItineraryEntryId == 0) {
        this.itineraryEntry = await itineraryEntryApi.postItineraryEntry(
          itineraryReference,
          itineraryEntry
        );
      } else {
        this.itineraryEntry = await itineraryEntryApi.putItineraryEntry(
          itineraryReference,
          itineraryEntry
        );
      }
    },

    onCancel() {
      this.$log.info("itinerary Entry %s", this.itineraryEntry);
      this.$emit("close");
    },

    onSave() {
      this.$log.info("onSave...");
      this.saveAttempted = false;

      this.itineraryEntry.StartDT = this.$moment(
        this.itineraryEntry.StartDT,
        this.validDateTimeFormats
      )
        .hour(
          this.$moment(
            "01 jan 2020 " + this.startTime,
            "DD MMM YYYY HH:mm"
          ).hour()
        )
        .minute(
          this.$moment(
            "01 jan 2020 " + this.startTime,
            "DD MMM YYYY HH:mm"
          ).minute()
        )
        .format("YYYY-MM-DDTHH:mm:ss");

      this.itineraryEntry.EndDT = this.$moment(
        this.itineraryEntry.EndDT,
        this.validDateTimeFormats
      )
        .hour(
          this.$moment(
            "01 jan 2020 " + this.endTime,
            "DD MMM YYYY HH:mm"
          ).hour()
        )
        .minute(
          this.$moment(
            "01 jan 2020 " + this.endTime,
            "DD MMM YYYY HH:mm"
          ).minute()
        )
        .format("YYYY-MM-DDTHH:mm:ss");

      if (this.validateForm() == false) {
        let dialogElement = document.getElementById("itineraryEntryDialog");
        dialogElement.scrollTop = 0;

        this.saveAttempted = true;
      } else {
        this.$log.info("Saving the itinerary entry...");
        this.saveItineraryEntry(this.itineraryReference, this.itineraryEntry)
          .then(() => {
            this.saveAttempted = true;
          })
          .then(
            () => {
              // only close on success
              //TODO: only emit input and close on Save success.
              this.$emit("input", this.itineraryEntry);
              this.$emit("save");
              this.$emit("close");
            },
            () => {} //Null function on rejected.
          )
          .catch((error) => {
            this.formSubmissionErrors = error.message.split(",");
            this.saveAttempted = true;
            window.scroll({ top: 50, behavior: "smooth" });
          });
      }
    },

    onDelete() {
      this.$log.info("Delete the itinerary entry...");

      this.$emit("delete", this.itineraryEntry);
      this.$emit("close");
    },
  },
};
</script>
