import api from "./api.js";
import { sharedMethods } from "@/shared/shared";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

/*
  Note: Javascript does not support method overloading so the get calls must
  be uniquely named.
 */
export default {
  async getBookingsAsync(
    reference = null,
    workgroups,
    consultants,
    statuses,
    destinations,
    terms,
    client,
    year,
    matchAllSearchingCriteriaInd = true,
    includeDeletedItemsInd = false
  ) {
    try {
      logger.get(loggingSource.UIBooking).info("getBookingsAsync...");
      logger
        .get(loggingSource.UIBooking)
        .debug(
          "Parameters => reference: %s, workgroups: %s, consultants: %s, statuses: %s, destinations: %s, terms: %s, client: %s, year: %s, matchAllSearchingCriteriaInd, %s, include delete items: %s",
          reference,
          workgroups,
          consultants,
          statuses,
          destinations,
          terms,
          client,
          year,
          matchAllSearchingCriteriaInd,
          includeDeletedItemsInd
        );

      logger.get(loggingSource.UIBooking).time("getBookingsAsync call");
      const response = await api.client
        .get("booking", {
          params: {
            reference: reference,
            workgroups: workgroups,
            consultants: consultants,
            bookingStatuses: statuses,
            destinations: destinations,
            terms: terms,
            client: client,
            year: year != null ? year.toString() : year,
            matchAllSearchingCriteriaInd: matchAllSearchingCriteriaInd,
            includeDeletedItemsInd: includeDeletedItemsInd,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIBooking).timeEnd("getBookingsAsync call");
      const bookings = response.data;

      logger
        .get(loggingSource.UIBooking)
        .trace("getBookingsAsync (before sanitisation) response: %s", response);

      //Html sanitise booking data
      for (let booking of bookings) {
        booking.AWGTNotes = sharedMethods.htmlSanitize(booking.AWGTNotes);
        booking.BookingNotes = sharedMethods.htmlSanitize(booking.BookingNotes);
        booking.ItineraryNotes = sharedMethods.htmlSanitize(
          booking.ItineraryNotes
        );
      }

      logger
        .get(loggingSource.UIBooking)
        .trace("getBookingsAsync (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIBooking)
        .debug("getBookingsAsync (after sanitisation) bookings: %s", bookings);

      return bookings;
    } catch (error) {
      logger.get(loggingSource.UIBooking).timeEnd("getBookingsAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIBooking).info("...getBookingsAsync");
    }
  },

  async getBookingByReferenceAsync(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIBooking).info("getBookingByReferenceAsync...");

      logger
        .get(loggingSource.UIBooking)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      logger
        .get(loggingSource.UIBooking)
        .time("getBookingByReferenceAsync call");
      const response = await api.client
        .get(`booking/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIBooking)
        .timeEnd("getBookingByReferenceAsync call");
      const booking = response.data;

      logger
        .get(loggingSource.UIBooking)
        .trace(
          "getBookingByReferenceAsync (before sanitisation) response: %s",
          response
        );

      //Html sanitise booking data
      booking.AWGTNotes = sharedMethods.htmlSanitize(booking.AWGTNotes);
      booking.BookingNotes = sharedMethods.htmlSanitize(booking.BookingNotes);
      booking.ItineraryNotes = sharedMethods.htmlSanitize(
        booking.ItineraryNotes
      );

      logger
        .get(loggingSource.UIBooking)
        .trace(
          "getBookingByReferenceAsync (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIBooking)
        .debug(
          "getBookingByReferenceAsync (after sanitisation) booking: %s",
          booking
        );

      return booking;
    } catch (error) {
      logger
        .get(loggingSource.UIBooking)
        .timeEnd("getBookingByReferenceAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIBooking).info("...getBookingByReferenceAsync");
    }
  },

  async postBookingAsync(
    bookingPayload,
    changeOwnership = false,
    checkingFutureBookings = false
  ) {
    try {
      logger.get(loggingSource.UIBooking).info("postBookingAsync...");
      logger
        .get(loggingSource.UIBooking)
        .debug("Parameters => bookingPayload: %s", bookingPayload);

      logger.get(loggingSource.UIBooking).time("postBookingAsync call");
      const response = await api.client
        .post("booking", bookingPayload, {
          params: {
            changeOwnership: changeOwnership,
            checkingFutureBookings: checkingFutureBookings,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIBooking).timeEnd("postBookingAsync call");

      if (Array.isArray(response.data) == true) {
        const futureBookingUsers = response.data;
        logger
          .get(loggingSource.UIBooking)
          .trace("postBookingAsync futureBookingUsers response: %s", response);

        logger
          .get(loggingSource.UIBooking)
          .debug("postBookingAsync futureBookingUsers: %s", futureBookingUsers);
        return futureBookingUsers;
      }

      const booking = response.data;

      logger
        .get(loggingSource.UIBooking)
        .trace("postBookingAsync (before sanitisation) response: %s", response);

      //Html sanitise booking data
      booking.AWGTNotes = sharedMethods.htmlSanitize(booking.AWGTNotes);
      booking.BookingNotes = sharedMethods.htmlSanitize(booking.BookingNotes);
      booking.ItineraryNotes = sharedMethods.htmlSanitize(
        booking.ItineraryNotes
      );

      logger
        .get(loggingSource.UIBooking)
        .trace("postBookingAsync (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIBooking)
        .debug("postBookingAsync (after sanitisation) booking: %s", booking);

      return booking;
    } catch (error) {
      logger.get(loggingSource.UIBooking).timeEnd("postBookingAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIBooking).info("...postBookingAsync");
    }
  },

  async putBookingAsync(
    bookingPayload,
    changeOwnership = false,
    checkingFutureBookings = false
  ) {
    try {
      logger.get(loggingSource.UIBooking).info("putBookingAsync...");
      logger
        .get(loggingSource.UIBooking)
        .debug("Parameters => bookingPayload: %s", bookingPayload);

      logger.get(loggingSource.UIBooking).time("putBookingAsync call");
      const response = await api.client
        .put("booking", bookingPayload, {
          params: {
            changeOwnership: changeOwnership,
            checkingFutureBookings: checkingFutureBookings,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIBooking).timeEnd("putBookingAsync call");

      if (Array.isArray(response.data) == true) {
        const futureBookingUsers = response.data;

        logger
          .get(loggingSource.UIBooking)
          .trace("postBookingAsync futureBookingUsers response: %s", response);

        logger
          .get(loggingSource.UIBooking)
          .debug("postBookingAsync futureBookingUsers: %s", futureBookingUsers);
        return futureBookingUsers;
      }

      const booking = response.data;

      logger
        .get(loggingSource.UIBooking)
        .trace("putBookingAsync (before sanitisation) response: %s", response);

      //Html sanitise booking data
      booking.AWGTNotes = sharedMethods.htmlSanitize(booking.AWGTNotes);
      booking.BookingNotes = sharedMethods.htmlSanitize(booking.BookingNotes);
      booking.ItineraryNotes = sharedMethods.htmlSanitize(
        booking.ItineraryNotes
      );

      logger
        .get(loggingSource.UIBooking)
        .trace("putBookingAsync (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIBooking)
        .debug("putBookingAsync (after sanitisation) booking: %s", booking);

      return booking;
    } catch (error) {
      logger.get(loggingSource.UIBooking).timeEnd("putBookingAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIBooking).info("...putBookingAsync");
    }
  },

  async deleteBookingAsync(bookingPayload) {
    try {
      logger.get(loggingSource.UIBooking).info("deleteBookingAsync...");
      logger
        .get(loggingSource.UIBooking)
        .debug("Parameters => bookingPayload: %s", bookingPayload);

      logger.get(loggingSource.UIBooking).time("deleteBookingAsync call");
      await api.client
        .delete(
          `booking/${
            bookingPayload.Reference
          }?concurrencyRV=${encodeURIComponent(bookingPayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
      logger.get(loggingSource.UIBooking).timeEnd("deleteBookingAsync call");
    } catch (error) {
      logger.get(loggingSource.UIBooking).timeEnd("deleteBookingAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIBooking).info("...deleteBookingAsync");
    }
  },

  async getBookingsDropdownForCommunicationAsync(
    clientReference = null,
    action = null
  ) {
    try {
      logger
        .get(loggingSource.UIBooking)
        .info("getBookingsDropdownForCommunicationAsync...");
      logger
        .get(loggingSource.UIBooking)
        .debug("Parameters => clientReference: %s", clientReference);

      logger
        .get(loggingSource.UIBooking)
        .time("getBookingsDropdownForCommunicationAsync call");
      const response = await api.client
        .get("booking/DropdownForCommunication", {
          params: {
            clientReference: clientReference,
            action: action,
          },
        })
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIBooking)
        .timeEnd("getBookingsDropdownForCommunicationAsync call");
      const bookings = response.data;

      return bookings;
    } catch (error) {
      logger
        .get(loggingSource.UIBooking)
        .timeEnd("getBookingsDropdownForCommunicationAsync call");
      logger.get(loggingSource.UIBooking).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIBooking)
        .info("...getBookingsDropdownForCommunicationAsync");
    }
  },
};
