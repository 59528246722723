var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menuItem.menuItems.length == 0
    ? _c(
        "mdb-nav-item",
        {
          style: { backgroundColor: _vm.backgroundColor },
          attrs: {
            id: "nav-" + _vm.menuItem.title,
            to: { path: _vm.menuItem.routePath },
            icon: _vm.menuItem.icon,
          },
        },
        [_vm._v(_vm._s(_vm.menuItem.title))]
      )
    : _c(
        "mdb-dropdown",
        {
          staticClass: "nav-item",
          attrs: { tag: "li", id: "nav-" + _vm.menuItem.title },
        },
        [
          _c(
            "mdb-dropdown-toggle",
            {
              attrs: {
                slot: "toggle",
                tag: "a",
                navLink: "",
                "waves-fixed": "",
                icon: _vm.menuItem.icon,
                color: _vm.backgroundColor,
              },
              slot: "toggle",
            },
            [_vm._v(_vm._s(_vm.menuItem.title))]
          ),
          _c(
            "mdb-dropdown-menu",
            [
              _vm._l(_vm.menuItem.menuItems, function (subMenuItem) {
                return [
                  _c(
                    "mdb-dropdown-item",
                    {
                      key: subMenuItem.title,
                      attrs: {
                        id:
                          "nav-" + _vm.menuItem.title + "-" + subMenuItem.title,
                        to: { path: subMenuItem.routePath },
                        header: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(subMenuItem.title) + " ")]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }