var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("div", { staticClass: "mx-2" }, [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("Itinerary and Orders"),
      ]),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap flex-row mb-2" },
        [
          _c("span", { staticClass: "mr-1" }, [_vm._v("View")]),
          _c("awgt-input", {
            staticStyle: { "min-width": "25px" },
            attrs: {
              id: "View_Calendar",
              type: "radio",
              name: "viewGroup",
              label: "Calendar",
              radioValue: "Vw_C",
            },
            model: {
              value: _vm.view,
              callback: function ($$v) {
                _vm.view = $$v
              },
              expression: "view",
            },
          }),
          _c("awgt-input", {
            staticStyle: { "min-width": "25px" },
            attrs: {
              id: "View_Orders",
              type: "radio",
              name: "viewGroup",
              label: "Orders",
              radioValue: "Vw_O",
            },
            model: {
              value: _vm.view,
              callback: function ($$v) {
                _vm.view = $$v
              },
              expression: "view",
            },
          }),
          _c("awgt-input", {
            staticStyle: { "min-width": "25px" },
            attrs: {
              id: "View_Both",
              type: "radio",
              name: "viewGroup",
              label: "Both",
              radioValue: "Vw_B",
            },
            model: {
              value: _vm.view,
              callback: function ($$v) {
                _vm.view = $$v
              },
              expression: "view",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          {
            style:
              _vm.view == "Vw_C"
                ? "flex: 100%"
                : _vm.view == "Vw_B"
                ? "flex: 50%; margin-right: 10px;"
                : "display: none",
          },
          [_c("itinerary-pane", { attrs: { productOrders: _vm.orders } })],
          1
        ),
        _c(
          "div",
          {
            style:
              _vm.view == "Vw_O"
                ? "flex: 100%"
                : _vm.view == "Vw_B"
                ? "flex: 50%; margin-left: 10px;"
                : "display: none",
          },
          [_c("order-pane", { attrs: { productOrders: _vm.orders } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }