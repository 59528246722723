const orderTool = {
  getNextOrderProductIdx: function (orderProducts) {
    return Math.max(...orderProducts.map((op) => op.OrderProductIdx), 0) + 1;
  },

  createOrderProductFromProduct: function (selected) {
    let current = {};
    current.Reference = selected.Reference;
    current.ResellerSupplierReference = selected.ResellerSupplier.Reference;
    current.Name = selected.Name;
    current.Description = "";
    current.ProductTypeCd = selected.ProductType;
    current.ProductSubTypeCd = selected.ProductSubType;
    current.ProviderSupplierName = selected.ProviderSupplier.Name;
    current.AvailableTimes = [];
    current.PriceAdjustReason = "";
    current.PricedForDt = null;
    current.PriceHasBeenAdjustedInd = false;
    current.IsDisplayedOnItineraryInd = false;
    current.AvailableCommercialProductLines = [];
    current.OrderProductPriceAdjustments = [];
    current.OrderProductLines = [];
    current.AdditionalDetails = null;
    current.ExGSTTotalAmt = 0;
    current.GSTTotalAmt = 0;

    return current;
  },

  currencyFormatter: function () {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  },
};

export default orderTool;
