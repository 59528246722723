var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-footer",
    { staticClass: "fixed-bottom", attrs: { color: _vm.backgroundColor } },
    [
      _c(
        "mdb-row",
        { staticClass: "w-100 mx-0" },
        [
          _c(
            "mdb-col",
            { attrs: { col: "8" } },
            [
              _vm._t("left", function () {
                return [
                  _c("h5", { staticClass: "tourdox-font text-left" }, [
                    _vm._v("tourdox"),
                  ]),
                ]
              }),
            ],
            2
          ),
          _c(
            "mdb-col",
            { attrs: { col: "4" } },
            [
              _vm._t("right", function () {
                return [
                  _c("p", { staticClass: "white-text text-right my-1" }, [
                    _vm._v("© 2019"),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }