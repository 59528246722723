<template>
  <mdb-container fluid>
    <div class="mx-2">
      <h1 class="text-center">Itinerary and Orders</h1>

      <div class="d-flex flex-wrap flex-row mb-2">
        <span class="mr-1">View</span>
        <awgt-input
          id="View_Calendar"
          type="radio"
          v-model="view"
          name="viewGroup"
          label="Calendar"
          radioValue="Vw_C"
          style="min-width: 25px"
        />
        <awgt-input
          id="View_Orders"
          type="radio"
          v-model="view"
          name="viewGroup"
          label="Orders"
          radioValue="Vw_O"
          style="min-width: 25px"
        />
        <awgt-input
          id="View_Both"
          type="radio"
          v-model="view"
          name="viewGroup"
          label="Both"
          radioValue="Vw_B"
          style="min-width: 25px"
        />
      </div>
      <div class="d-flex">
        <div
          :style="
            view == 'Vw_C'
              ? 'flex: 100%'
              : view == 'Vw_B'
              ? 'flex: 50%; margin-right: 10px;'
              : 'display: none'
          "
        >
          <itinerary-pane :productOrders="orders"></itinerary-pane>
        </div>

        <div
          :style="
            view == 'Vw_O'
              ? 'flex: 100%'
              : view == 'Vw_B'
              ? 'flex: 50%; margin-left: 10px;'
              : 'display: none'
          "
        >
          <order-pane :productOrders="orders" />
        </div>
      </div>
    </div>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style></style>

<script>
import { mdbContainer } from "mdbvue";
import itineraryPane from "@/views/itinerary/itineraryPane";
import orderPane from "@/views/itinerary/order/orderPane";
import awgtInput from "@/components/AWGT/AwgtInput";
import orderApi from "@/api/orderApi.js";

// import { timeout } from "q";

export default {
  components: {
    mdbContainer,
    orderPane,
    itineraryPane,
    awgtInput,
  },

  data() {
    return {
      bookingReference: null,
      itineraryReference: null,
      view: "Vw_B",
      orders: [],
    };
  },

  methods: {
    async loadFormData() {
      return await orderApi.getByBookingReferenceAsync(this.bookingReference);
    },
  },

  created() {
    let routeParams = this.$route.params;
    this.$log
      .get(this.$loggingSource.UIItinerary)
      .info("RouteParams: %s", routeParams);
    this.bookingReference = routeParams.bookingReference;
    this.itineraryReference = routeParams.itineraryReference;
  },

  mounted() {
    this.loadFormData().then((orders) => (this.orders = orders));
  },
};
</script>
