var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h5",
        [
          _c("mdb-icon", {
            staticClass: "icon-in-views",
            attrs: {
              icon: _vm.getProductIcon(
                _vm.value.ProductType,
                _vm.value.ProductSubType
              ),
              size: "1x",
              color: "primary",
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.value.Name) + " ")]),
          _c("span", { staticStyle: { "font-size": "75%" } }, [
            _c("i", [_vm._v("(" + _vm._s(_vm.value.Reference) + ")")]),
          ]),
        ],
        1
      ),
      _vm.value.DisplayName
        ? _c("h5", { staticStyle: { "margin-top": "-10px" } }, [
            _c("i", [
              _vm._v("(Displayed as " + _vm._s(_vm.value.DisplayName) + ")"),
            ]),
          ])
        : _vm._e(),
      _c("h5", { staticClass: "mt-3" }, [_vm._v("Details")]),
      _c(
        "div",
        { staticClass: "ml-4" },
        [
          _vm.value.Description != null && _vm.value.Description.length > 0
            ? _c("div", { staticClass: "html-in-views" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.value.Description) },
                }),
              ])
            : _vm._e(),
          _vm.value.ProductType == "PT_Tr"
            ? _c(
                "div",
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "car-bus",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c("span", [
                    _vm._v("Minimum Seats: " + _vm._s(_vm.value.MinSeatsNum)),
                  ]),
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "car-bus",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c("span", [
                    _vm._v("Maximum Seats: " + _vm._s(_vm.value.MaxSeatsNum)),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.value.ProductType == "PT_Tr"
            ? _c(
                "div",
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "car-bus",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "Origins: " + _vm._s(_vm.transportationOriginsForDisplay)
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.value.InformationURL != null &&
          _vm.value.InformationURL.length > 0
            ? _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "info",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.value.InformationURL,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.value.InformationURL))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value.BookingMethod.length > 0
            ? _c(
                "div",
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      color: "primary",
                      icon: "phone-square-alt",
                      size: "1x",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "Booking Method: " +
                        _vm._s(
                          _vm.getBookingMethodNameByCode(
                            _vm.value.BookingMethod
                          )
                        )
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.value.BookingURL != null && _vm.value.BookingURL.length > 0
            ? _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "shopping-cart",
                      size: "1x",
                      color: "primary",
                    },
                  }),
                  _c(
                    "a",
                    { attrs: { href: _vm.value.BookingURL, target: "_blank" } },
                    [_vm._v(_vm._s(_vm.value.BookingURL))]
                  ),
                  _c("br"),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("mdb-icon", {
                staticClass: "icon-in-views",
                attrs: { far: "", icon: "user", color: "primary", size: "1x" },
              }),
              _c("span", [
                _vm._v("Contact: " + _vm._s(_vm.value.ContactPersonNm)),
              ]),
            ],
            1
          ),
          (_vm.value.ProductType == "PT_An" ||
            _vm.value.ProductType == "PT_Ay") &&
          _vm.value.Addresses != null &&
          _vm.value.Addresses.length > 0
            ? [
                _vm.physicalAddress.Street.length +
                  _vm.physicalAddress.Locality.length +
                  (_vm.physicalAddress.Subdivision != null
                    ? _vm.physicalAddress.Subdivision.length
                    : 0) +
                  _vm.physicalAddress.Postcode.length >
                0
                  ? _c(
                      "div",
                      [
                        _c("mdb-icon", {
                          staticClass: "icon-in-views",
                          attrs: {
                            far: "",
                            icon: "map-marker-alt",
                            color: "primary",
                            size: "1x",
                          },
                        }),
                        _vm.physicalAddress.Street.length > 0
                          ? [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.physicalAddress.Street) + ","
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.physicalAddress.Locality.length > 0
                          ? [
                              _vm._v(
                                " " + _vm._s(_vm.physicalAddress.Locality) + " "
                              ),
                            ]
                          : _vm._e(),
                        _vm.physicalAddress.Subdivision != null &&
                        _vm.physicalAddress.Subdivision.length > 0
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.physicalAddress.Subdivision) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                        _vm.physicalAddress.Postcode.length > 0
                          ? [
                              _vm._v(
                                " " + _vm._s(_vm.physicalAddress.Postcode) + " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.value.Phone != null && _vm.value.Phone.length > 0
            ? _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "phone",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c(
                    "a",
                    { attrs: { href: "tel:" + _vm.value.Phone } },
                    [
                      _c(
                        "asoft-field-formatter",
                        { attrs: { fieldType: "PhoneNumber" } },
                        [_vm._v(_vm._s(_vm.value.Phone))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value.EmailAddress != null && _vm.value.EmailAddress.length > 0
            ? _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("mdb-icon", {
                    staticClass: "icon-in-views",
                    attrs: {
                      far: "",
                      icon: "at",
                      color: "primary",
                      size: "1x",
                    },
                  }),
                  _c(
                    "a",
                    { attrs: { href: "mailto:" + _vm.value.EmailAddress } },
                    [_vm._v(_vm._s(_vm.value.EmailAddress))]
                  ),
                  _c("br"),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "section-break" }),
          _c(
            "div",
            {
              staticClass: "d-flex flex-row flex-wrap",
              staticStyle: { "justify-content": "center" },
            },
            [
              _vm.value.YearGroups != null && _vm.value.YearGroups.length > 0
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Year Groups",
                          content: _vm.value.YearGroups,
                          icon: "users-class",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.ProductType == "PT_An" && _vm.value.MinBedsNum != null
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Minimum Number of Beds",
                          content: _vm.value.MinBedsNum,
                          icon: "bed-bunk",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.ProductType == "PT_An" && _vm.value.MaxBedsNum != null
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Maximum Number of Beds",
                          content: _vm.value.MaxBedsNum,
                          icon: "bed-bunk",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.ProductType == "PT_Ay"
                ? [
                    _c(
                      "div",
                      { staticClass: "box-margin" },
                      [
                        _c("tdox-box-display", {
                          attrs: {
                            title: "Minimum participants per activity",
                            content: _vm.value.MinParticipantsPerGroup,
                            icon: "hashtag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box-margin" },
                      [
                        _c("tdox-box-display", {
                          attrs: {
                            title: "Maximum participants per activity",
                            content: _vm.value.MaxParticipantsPerGroup,
                            icon: "hashtag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box-margin" },
                      [
                        _c("tdox-box-display", {
                          attrs: {
                            title: "Participants per timeslot",
                            content: _vm.value.MaxParticipantsPerTimeslot,
                            icon: "hashtag",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.value.ProductType == "PT_Ay" &&
                    _vm.value.DurationInMinutes != null
                      ? _c(
                          "div",
                          { staticClass: "box-margin" },
                          [
                            _c("tdox-box-display", {
                              attrs: {
                                title: "Duration in minutes",
                                content: _vm.value.DurationInMinutes,
                                icon: "clock",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "div",
                { staticClass: "box-margin" },
                [
                  _c("tdox-box-display", {
                    attrs: {
                      title: "Pre-product Margin",
                      content: `${_vm.value.PreProductMarginInMinutes} minutes`,
                      icon: "clock",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-margin" },
                [
                  _c("tdox-box-display", {
                    attrs: {
                      title: "Post-product Margin",
                      content: `${_vm.value.PostProductMarginInMinutes} minutes`,
                      icon: "clock",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-margin" },
                [
                  _c("tdox-box-display", {
                    attrs: {
                      title: "Free-of-charge Ratio",
                      content: _vm.value.FreeOfChargeRatio,
                      icon: "percent",
                    },
                  }),
                ],
                1
              ),
              _vm.value.DepositAmt != null
                ? [
                    _vm.value.DepositType == "DT_PP" ||
                    _vm.value.DepositType == "DT_FA"
                      ? _c(
                          "div",
                          { staticClass: "box-margin" },
                          [
                            _c("tdox-box-display", {
                              attrs: {
                                title: "Deposit Type",
                                content: `$${
                                  _vm.value.DepositAmt
                                } (${_vm.getDepositTypeNameByCode(
                                  _vm.value.DepositType
                                )})`,
                                icon: "dollar-sign",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.value.DepositType == "DT_Pe"
                      ? _c(
                          "div",
                          { staticClass: "box-margin" },
                          [
                            _c("tdox-box-display", {
                              attrs: {
                                title: "Deposit Amount",
                                content: `${
                                  _vm.value.DepositAmt
                                }% (${_vm.getDepositTypeNameByCode(
                                  _vm.value.DepositType
                                )})`,
                                icon: "percentage",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "box-margin" },
                          [
                            _c("tdox-box-display", {
                              attrs: {
                                title: "Deposit Amount",
                                content: "No Deposit",
                                icon: "dollar-sign",
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm.value.PaymentTypes != null &&
              _vm.value.PaymentTypes.length > 0
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Min Duration",
                          content: `${_vm.value.MinDurationInMinutes} minutes`,
                          icon: "clock",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.PaymentTypes != null &&
              _vm.value.PaymentTypes.length > 0
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Max Duration",
                          content: `${_vm.value.MaxDurationInMinutes} minutes`,
                          icon: "clock",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.PaymentTypes != null &&
              _vm.value.PaymentTypes.length > 0
                ? _c(
                    "div",
                    { staticClass: "box-margin" },
                    [
                      _c("tdox-box-display", {
                        attrs: {
                          title: "Product Availability",
                          content: _vm.value.ProductAvailability,
                          icon: "calendar",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "section-break" }),
          _vm.value.ProductType == "PT_Ay" &&
          _vm.value.AvailableTimes != null &&
          _vm.value.AvailableTimes.length > 0
            ? [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: { "font-size": "1.2rem" },
                      style: { color: _vm.headingColor },
                    },
                    [_vm._v("Start Times")]
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row flex-nowrap" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "50%", "padding-left": "10%" } },
                    [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "1.2rem" },
                            style: { color: _vm.headingColor },
                          },
                          [_vm._v("AM")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-end flex-row flex-wrap",
                          staticStyle: {
                            "text-align": "right",
                            width: "100%",
                            "padding-left": "5%",
                            "padding-right": "5%",
                          },
                        },
                        _vm._l(_vm.leftColOfStartTimes, function (time, idx) {
                          return _c(
                            "div",
                            {
                              key: idx,
                              staticStyle: {
                                "font-size": "medium",
                                width: "90px",
                              },
                            },
                            [_vm._v(" " + _vm._s(time) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "vertical-divider" }),
                  _c(
                    "div",
                    { staticStyle: { width: "50%", "padding-right": "10%" } },
                    [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "1.2rem" },
                            style: { color: _vm.headingColor },
                          },
                          [_vm._v("PM")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start flex-row flex-wrap",
                          staticStyle: {
                            "text-align": "left",
                            width: "100%",
                            "padding-left": "5%",
                            "padding-right": "5%",
                          },
                        },
                        _vm._l(_vm.rightColOfStartTimes, function (time, idx) {
                          return _c(
                            "div",
                            {
                              key: idx,
                              staticStyle: {
                                "font-size": "medium",
                                width: "90px",
                              },
                            },
                            [_vm._v(" " + _vm._s(time) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "section-break" }),
              ]
            : _vm._e(),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "span",
              {
                staticStyle: { "font-size": "1.2rem" },
                style: { color: _vm.headingColor },
              },
              [_vm._v("Product Pricing")]
            ),
          ]),
          _c("div", [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "1.2rem" },
                  style: { color: _vm.headingColor },
                },
                [_vm._v("CURRENT")]
              ),
            ]),
            _c(
              "div",
              _vm._l(_vm.value.CommercialProductLines, function (pc, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "my-2" },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { display: "none" } }, [
                        _vm._v(
                          _vm._s(
                            (_vm.c =
                              _vm.currentCommercialProductLineChronology(pc))
                          )
                        ),
                      ]),
                    ]),
                    _vm.c != null
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-wrap flex-grow-1 mx-2",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex-fill-chronology-name cell-padding",
                                  staticStyle: { "font-size": "1rem" },
                                  style: { color: _vm.headingColor },
                                },
                                [_vm._v(_vm._s(_vm.c.Name))]
                              ),
                              _vm.c.ActiveToDt == null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex-fill cell-padding product-pricing-period",
                                    },
                                    [
                                      _vm._v(
                                        " From " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              _vm.c.ActiveFromDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex-fill cell-padding product-pricing-period",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              _vm.c.ActiveFromDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              _vm.c.ActiveToDt,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Json,
                                              _vm.$constants
                                                .DATETIMEFORMAT_Presentation
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                              _c("span", { staticStyle: { display: "none" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.mt = _vm.c.MarketCategories.map(
                                        (mt) => {
                                          return mt.Name
                                        }
                                      ).join(", "))
                                    )
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex-fill cell-padding product-pricing-market-category",
                                },
                                [_vm._v(_vm._s(_vm.mt))]
                              ),
                              _c(
                                "span",
                                { staticClass: "flex-fill cell-padding" },
                                _vm._l(
                                  _vm.c.ProductLinePrices,
                                  function (p, index) {
                                    return _c("div", { key: index }, [
                                      _c("span", [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatPricing(_vm.c, p)
                                            ),
                                          },
                                        }),
                                        _vm.c.IsIndicativePricingInd == true
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tippy",
                                                    rawName: "v-tippy",
                                                    value: {
                                                      position: "bottom",
                                                      arrow: true,
                                                    },
                                                    expression:
                                                      "{ position: 'bottom', arrow: true }",
                                                  },
                                                ],
                                                staticStyle: {
                                                  border: "none",
                                                  "background-color": "inherit",
                                                  color: "grey",
                                                },
                                                attrs: {
                                                  type: "button",
                                                  title:
                                                    _vm.getTooltipsTextByCode(
                                                      11
                                                    ),
                                                },
                                              },
                                              [
                                                _c("mdb-icon", {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  style: {
                                                    color: "orange",
                                                  },
                                                  attrs: {
                                                    far: "",
                                                    icon: "exclamation-circle",
                                                    size: "1x",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("br"),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "section-break" }),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: () => {
                    _vm.toggleFutureProductPricing =
                      !_vm.toggleFutureProductPricing
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "1.2rem" },
                    style: { color: _vm.headingColor },
                  },
                  [_vm._v("FUTURE")]
                ),
                _c(
                  "span",
                  { staticStyle: { "margin-left": "5px" } },
                  [
                    _vm.toggleFutureProductPricing == true
                      ? _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-up",
                            size: "1x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-down",
                            size: "1x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "expansion-region",
            { attrs: { toggle: _vm.toggleFutureProductPricing } },
            [
              _c(
                "div",
                _vm._l(_vm.value.CommercialProductLines, function (pc, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "my-2" },
                    [
                      _c("div", [
                        _c("span", { staticStyle: { display: "none" } }, [
                          _vm._v(
                            _vm._s(
                              (_vm.f =
                                _vm.futureCommercialProductLineChronology(pc))
                            )
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.f, function (chronology, indOfChronology) {
                        return _c(
                          "div",
                          { key: indOfChronology, staticClass: "my-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap flex-grow-1 mx-2",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-fill-chronology-name cell-padding",
                                    staticStyle: { "font-size": "1rem" },
                                    style: { color: _vm.headingColor },
                                  },
                                  [_vm._v(_vm._s(chronology.Name))]
                                ),
                                chronology.ActiveToDt == null
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-fill cell-padding product-pricing-period",
                                      },
                                      [
                                        _vm._v(
                                          " From " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveFromDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-fill cell-padding product-pricing-period",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveFromDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveToDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                _c(
                                  "span",
                                  { staticStyle: { display: "none" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.mt =
                                            chronology.MarketCategories.map(
                                              (mt) => {
                                                return mt.Name
                                              }
                                            ).join(", "))
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-fill cell-padding product-pricing-market-category",
                                  },
                                  [_vm._v(_vm._s(_vm.mt))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "flex-fill cell-padding" },
                                  _vm._l(
                                    chronology.ProductLinePrices,
                                    function (p, index) {
                                      return _c("div", { key: index }, [
                                        _c("span", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formatPricing(
                                                  chronology,
                                                  p,
                                                  chronology.PriceTypeCd,
                                                  chronology.PricingScaleTypes
                                                )
                                              ),
                                            },
                                          }),
                                          chronology.IsIndicativePricingInd ==
                                          true
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        position: "bottom",
                                                        arrow: true,
                                                      },
                                                      expression:
                                                        "{ position: 'bottom', arrow: true }",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    border: "none",
                                                    "background-color":
                                                      "inherit",
                                                    color: "grey",
                                                  },
                                                  attrs: {
                                                    type: "button",
                                                    title:
                                                      _vm.getTooltipsTextByCode(
                                                        11
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    style: {
                                                      color: "orange",
                                                    },
                                                    attrs: {
                                                      far: "",
                                                      icon: "exclamation-circle",
                                                      size: "1x",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" >"),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          ),
          _c("div", { staticClass: "section-break" }),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: () => {
                    _vm.togglePastProductPricing = !_vm.togglePastProductPricing
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "1.2rem" },
                    style: { color: _vm.headingColor },
                  },
                  [_vm._v("PAST")]
                ),
                _c(
                  "span",
                  { staticStyle: { "margin-left": "5px" } },
                  [
                    _vm.togglePastProductPricing == true
                      ? _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-up",
                            size: "1x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-down",
                            size: "1x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "expansion-region",
            { attrs: { toggle: _vm.togglePastProductPricing } },
            [
              _c(
                "div",
                _vm._l(_vm.value.CommercialProductLines, function (pc, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "my-2" },
                    [
                      _c("div", [
                        _c("span", { staticStyle: { display: "none" } }, [
                          _vm._v(
                            _vm._s(
                              (_vm.p =
                                _vm.pastCommercialProductLineChronology(pc))
                            )
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.p, function (chronology, indOfChronology) {
                        return _c(
                          "div",
                          { key: indOfChronology, staticClass: "my-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap flex-grow-1 mx-2",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-fill-chronology-name cell-padding",
                                    staticStyle: { "font-size": "1rem" },
                                    style: { color: _vm.headingColor },
                                  },
                                  [_vm._v(_vm._s(chronology.Name))]
                                ),
                                chronology.ActiveToDt == null
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-fill cell-padding product-pricing-period",
                                      },
                                      [
                                        _vm._v(
                                          " From " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveFromDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-fill cell-padding product-pricing-period",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveFromDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.convertDateFormat(
                                                chronology.ActiveToDt,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Json,
                                                _vm.$constants
                                                  .DATETIMEFORMAT_Presentation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                _c(
                                  "span",
                                  { staticStyle: { display: "none" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.mt =
                                            chronology.MarketCategories.map(
                                              (mt) => {
                                                return mt.Name
                                              }
                                            ).join(", "))
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-fill cell-padding product-pricing-market-category",
                                  },
                                  [_vm._v(_vm._s(_vm.mt))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "flex-fill cell-padding" },
                                  _vm._l(
                                    chronology.ProductLinePrices,
                                    function (p, index) {
                                      return _c("div", { key: index }, [
                                        _c("span", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formatPricing(
                                                  chronology,
                                                  p,
                                                  chronology.PriceTypeCd,
                                                  chronology.PricingScaleTypes
                                                )
                                              ),
                                            },
                                          }),
                                          chronology.IsIndicativePricingInd ==
                                          true
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        position: "bottom",
                                                        arrow: true,
                                                      },
                                                      expression:
                                                        "{ position: 'bottom', arrow: true }",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    border: "none",
                                                    "background-color":
                                                      "inherit",
                                                    color: "grey",
                                                  },
                                                  attrs: {
                                                    type: "button",
                                                    title:
                                                      _vm.getTooltipsTextByCode(
                                                        11
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    style: {
                                                      color: "orange",
                                                    },
                                                    attrs: {
                                                      far: "",
                                                      icon: "exclamation-circle",
                                                      size: "1x",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          ),
          _c("div", { staticClass: "section-break" }),
          _vm.value.BookingFeeDetail != null &&
          _vm.value.BookingFeeDetail.length > 0
            ? [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: { "font-size": "1.2rem" },
                      style: { color: _vm.headingColor },
                    },
                    [_vm._v("Booking Fee Detail")]
                  ),
                  _c("br"),
                  _c("span", {
                    staticClass: "html-in-views",
                    domProps: { innerHTML: _vm._s(_vm.value.BookingFeeDetail) },
                  }),
                ]),
                _c("div", { staticClass: "section-break" }),
              ]
            : _vm._e(),
          _vm.value.AWGTNotes != null && _vm.value.AWGTNotes.length > 0
            ? _c("div", [
                _c("h5", { style: { color: _vm.headingColor } }, [
                  _vm._v("Agency Notes"),
                ]),
                _c("span", {
                  staticClass: "html-in-views",
                  domProps: { innerHTML: _vm._s(_vm.value.AWGTNotes) },
                }),
                _c("div", { staticClass: "section-break" }),
              ])
            : _vm._e(),
          _vm.value.ProductNotes != null && _vm.value.ProductNotes.length > 0
            ? _c(
                "div",
                [
                  _c("h5", { style: { color: _vm.headingColor } }, [
                    _vm._v("Agency Product Notes"),
                  ]),
                  _vm._l(_vm.value.ProductNotes, function (note, index) {
                    return _c("ul", { key: index, staticClass: "mb-0" }, [
                      note.Note != null && note.Note.length > 0
                        ? _c("li", { staticClass: "html-in-views" }, [
                            _c("span", [
                              _c("b", [_vm._v(_vm._s(note.Subject) + ":")]),
                            ]),
                            _vm._v(" " + _vm._s(note.Note) + " "),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  _c("div", { staticClass: "section-break" }),
                ],
                2
              )
            : _vm._e(),
          (_vm.value.SpecialComment != null &&
            _vm.value.SpecialComment.length > 0) ||
          (_vm.value.PreProductItineraryComment != null &&
            _vm.value.PreProductItineraryComment.length > 0) ||
          (_vm.value.PostProductItineraryComment != null &&
            _vm.value.PostProductItineraryComment.length > 0)
            ? [
                _c("h5", { style: { color: _vm.headingColor } }, [
                  _vm._v("Itinerary Notes"),
                ]),
                _c(
                  "ul",
                  [
                    _vm.value.SpecialComment != null &&
                    _vm.value.SpecialComment.length > 0
                      ? [
                          _c("li", [
                            _c("div", [
                              _c("b", [_vm._v("Special Comment:")]),
                              _c("span", {
                                staticClass: "html-in-views",
                                domProps: {
                                  innerHTML: _vm._s(_vm.value.SpecialComment),
                                },
                              }),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.value.PreProductItineraryComment != null &&
                    _vm.value.PreProductItineraryComment.length > 0
                      ? [
                          _c("li", [
                            _c("div", [
                              _c("b", [
                                _vm._v("Pre-product Itinerary Comment:"),
                              ]),
                              _c("span", {
                                staticClass: "html-in-views",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.value.PreProductItineraryComment
                                  ),
                                },
                              }),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.value.PostProductItineraryComment != null &&
                    _vm.value.PostProductItineraryComment.length > 0
                      ? [
                          _c("li", [
                            _c("div", [
                              _c("b", [
                                _vm._v("Post-product Itinerary Comment:"),
                              ]),
                              _c("span", {
                                staticClass: "html-in-views",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.value.PostProductItineraryComment
                                  ),
                                },
                              }),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("br"),
      _vm.value.ProductSubType != "PST_AyFI"
        ? [
            _vm.value.ProviderSupplier.Reference ==
            _vm.value.ResellerSupplier.Reference
              ? _c("h5", { style: { color: _vm.headingColor } }, [
                  _vm._v(" Reseller and Provider "),
                ])
              : _c("h5", [_vm._v("Provider")]),
            _c("supplier-view", {
              staticClass: "ml-4",
              attrs: { value: _vm.value.ProviderSupplier },
            }),
            _c("br"),
            _vm.value.ProviderSupplier.Reference !=
            _vm.value.ResellerSupplier.Reference
              ? [
                  _c("h5", [_vm._v("Reseller")]),
                  _c("supplier-view", {
                    staticClass: "ml-4",
                    attrs: { value: _vm.value.ResellerSupplier },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }