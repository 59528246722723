<template>
  <mdb-container fluid>
    <h1 class="text-center">Product Edit</h1>
    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div>
        <h4 v-if="product.Reference != ''" class="mx-2">
          Reference: {{ product.Reference }}
        </h4>
      </div>
      <div class="ml-auto">
        <awgt-std-dropdown
          v-model="product.EditStatus"
          :items="getEditStatuses(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Edit Status"
          bg
          class="mx-2"
          style="max-width: 110px"
        />
      </div>
    </div>

    <!--
        Product Type
    -->
    <h4 class="mx-2">Product Type</h4>

    <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
      <span class="mr-5" style="width: 120px">Product Type</span>
      <awgt-input
        id="productCategory_Transport"
        v-model="product.ProductType"
        type="radio"
        name="ProductTypeGroup"
        label="Transport"
        radioValue="PT_Tr"
        class="mr-5"
      />
      <awgt-input
        id="productCategory_Accommodation"
        v-model="product.ProductType"
        type="radio"
        name="ProductTypeGroup"
        label="Accommodation"
        radioValue="PT_An"
        class="mr-5"
      />
      <awgt-input
        id="productCategory_Activity"
        v-model="product.ProductType"
        type="radio"
        name="ProductTypeGroup"
        label="Activity"
        radioValue="PT_Ay"
        class="mr-5"
      />
      <awgt-input
        id="productCategory_Merchandise"
        v-model="product.ProductType"
        type="radio"
        name="ProductTypeGroup"
        label="Merchandise"
        radioValue="PT_Me"
        class="mr-5"
      />
      <awgt-input
        id="productCategory_Bundle"
        v-model="product.ProductType"
        type="radio"
        name="ProductTypeGroup"
        label="Bundle"
        radioValue="PT_Bn"
        :tooltip="getTooltipsTextByCode(32)"
      />
    </mdb-form-inline>
    <mdb-form-inline
      v-if="product.ProductType === 'PT_Tr'"
      class="flex-wrap border mx-2 px-3 py-3 mb-2"
    >
      <span class="mr-5" style="width: 120px">Product Subtype</span>
      <awgt-input
        id="productSubcategory_Coach_Charter"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Coach Charter"
        radioValue="PST_TrCC"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_Flight"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Flight"
        radioValue="PST_TrFl"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_bus"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Bus"
        radioValue="PST_TrBu"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_Train"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Train"
        radioValue="PST_TrTr"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_Ferry"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Ferry"
        radioValue="PST_TrFe"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_Tram"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Tram"
        radioValue="PST_TrTm"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_Car"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Car"
        radioValue="PST_TrCr"
      />
    </mdb-form-inline>
    <mdb-form-inline
      v-if="product.ProductType === 'PT_Ay'"
      class="flex-wrap border mx-2 px-3 py-3 mb-2"
    >
      <span class="mr-5" style="width: 120px">Product Subtype</span>
      <awgt-input
        id="productSubcategory_SupplierActivity"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Supplier Activity"
        radioValue="PST_AyAy"
        class="mr-5"
      />
      <awgt-input
        id="productSubcategory_FillInActivity"
        v-model="product.ProductSubType"
        type="radio"
        name="ProductSubtypeGroup"
        label="Fill-In Activity"
        radioValue="PST_AyFI"
        class="mr-5"
      />
    </mdb-form-inline>

    <!--
        Supplier Detail
    -->
    <template v-if="product.ProductSubType != 'PST_AyFI'">
      <div class="section-break"></div>
      <h4 class="mx-2">Supplier Detail</h4>

      <awgt-std-dropdown
        v-model="product.ProviderSupplier.Reference"
        :items="supplierDropDown"
        itemValueProperty="Reference"
        itemTextProperty="Name"
        label="Provider Name"
        bg
        class="flex-grow-1 mx-2"
        search
        :tooltip="getTooltipsTextByCode(30)"
      />
      <awgt-std-dropdown
        v-model="product.ResellerSupplier.Reference"
        :items="supplierDropDown"
        itemValueProperty="Reference"
        itemTextProperty="Name"
        label="Reseller Name"
        bg
        class="flex-grow-1 mx-2"
        search
        :tooltip="getTooltipsTextByCode(31)"
      />
    </template>

    <!--
        Product Detail
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">Product Detail</h4>
    <div class="d-flex flex-row flex-wrap flex-grow-1 mx-2 align-items-center">
      <mdb-icon
        :icon="getProductIcon(product.ProductType, product.ProductSubType)"
        size="2x"
        color="primary"
        style="width: 40px; text-align: center"
      />
      <!-- Validation on this field is set to 90 characters to allow for 
      "(Copy)" indicators to be added to the field via the duplicate functionality. -->
      <awgt-input
        v-model="product.Name"
        counter
        :counterMaxValue="100"
        maxlength="100"
        label="Product Name"
        bg
        class="flex-grow-1 ml-2"
        style="min-width: 240px"
      ></awgt-input>
      <awgt-input
        v-model="product.DisplayName"
        counter
        :counterMaxValue="100"
        maxlength="100"
        label="Display Name"
        bg
        class="flex-grow-1 display-name-margin"
        style="min-width: 280px"
        :tooltip="getTooltipsTextByCode(12)"
      ></awgt-input>
    </div>

    <mdb-form-inline class="flex-wrap border mx-2 px-3 py-3 mb-2">
      <span class="mr-5" style="width: 120px">Sold with</span>
      <awgt-input
        id="soldWithGroup_SeparateSale"
        v-model="product.CanBeSoldSeparatelyInd"
        type="radio"
        name="SoldWithGroup"
        label="Can be sold separately"
        :radioValue="true"
        class="mr-5"
      />
      <awgt-input
        id="soldWithGroup_RelatedProducts"
        v-model="product.CanBeSoldSeparatelyInd"
        type="radio"
        name="SoldWithGroup"
        label="Can only be sold as a related product"
        :radioValue="false"
        :tooltip="getTooltipsTextByCode(33)"
      />
    </mdb-form-inline>

    <div
      v-if="product.ProductType == 'PT_Tr'"
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        far
        icon="car-bus"
        size="2x"
        color="primary"
        style="width: 40px; text-align: center"
      />
      <awgt-input
        v-model.number="product.MinSeatsNum"
        maxlength="4"
        label="Minimum Seats"
        bg
        class="flex-grow-1 ml-2"
        type="text"
        v-numeric="{
          precision: 4,
          scale: 0,
          posOnly: true,
          allowEmptyString: true,
        }"
      ></awgt-input>
      <mdb-icon
        far
        icon="car-bus"
        size="2x"
        color="primary"
        style="width: 40px; text-align: center"
        class="ml-2"
      />
      <awgt-input
        v-model.number="product.MaxSeatsNum"
        maxlength="4"
        label="Maximum Seats"
        bg
        class="flex-grow-1 ml-2"
        type="text"
        v-numeric="{
          precision: 4,
          scale: 0,
          posOnly: true,
          allowEmptyString: true,
        }"
      ></awgt-input>
    </div>

    <div
      v-if="product.ProductType == 'PT_An'"
      class="d-flex flex-row flex-wrap flex-grow-1"
    >
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="bed-bunk"
          color="primary"
          style="width: 40px; text-align: center"
          size="2x"
        />
        <awgt-input
          v-model.number="product.MinBedsNum"
          maxlength="4"
          label="Minimum Beds"
          bg
          class="flex-grow-1 ml-2"
          type="text"
          v-numeric="{
            precision: 4,
            scale: 0,
            posOnly: true,
            allowEmptyString: true,
          }"
        ></awgt-input>
        <mdb-icon
          icon="bed-bunk"
          color="primary"
          style="width: 40px; text-align: center"
          size="2x"
          class="ml-2"
        />
        <awgt-input
          v-model.number="product.MaxBedsNum"
          maxlength="4"
          label="Maximum Beds"
          bg
          class="flex-grow-1 ml-2"
          type="text"
          v-numeric="{
            precision: 4,
            scale: 0,
            posOnly: true,
            allowEmptyString: true,
          }"
        ></awgt-input>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
      <div
        v-if="product.ProductType == 'PT_An' || product.ProductType == 'PT_Ay'"
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="users-class"
          color="primary"
          style="width: 40px; text-align: center"
          size="2x"
        />
        <awgt-std-dropdown
          itemValueProperty="Code"
          :items="getYearGroups"
          itemTextProperty="Name"
          v-model="yearGroups"
          label="Year Groups"
          bg
          :maxSelectedOptions="13"
          multiple
          selectAll
          class="flex-grow-1 ml-2"
        ></awgt-std-dropdown>
      </div>

      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="clock"
          far
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <awgt-std-dropdown
          ref="preProductMargins"
          :items="getProductMargins"
          itemValueProperty="productMarginInMinutes"
          itemTextProperty="name"
          label="Pre-product Margin"
          style="min-width: 240px"
          class="flex-grow-1"
          v-model="product.PreProductMarginInMinutes"
          bg
          :tooltip="getTooltipsTextByCode(34)"
        ></awgt-std-dropdown>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="clock"
          far
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px"
        />
        <awgt-std-dropdown
          ref="postProductMargins"
          :items="getProductMargins"
          itemValueProperty="productMarginInMinutes"
          itemTextProperty="name"
          label="Post-product Margin"
          style="min-width: 240px"
          class="flex-grow-1"
          v-model="product.PostProductMarginInMinutes"
          bg
          :tooltip="getTooltipsTextByCode(35)"
        ></awgt-std-dropdown>
      </div>
    </div>
    <div
      v-if="product.ProductType == 'PT_Ay'"
      class="d-flex flex-row flex-wrap flex-grow-1"
    >
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="hashtag"
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <awgt-input
          label="Min participants per group"
          v-model.number="product.MinParticipantsPerGroup"
          style="min-width: 240px"
          bg
          class="flex-grow-1"
          type="text"
          v-numeric="{
            precision: 4,
            scale: 0,
            posOnly: true,
            allowEmptyString: true,
          }"
        ></awgt-input>
      </div>
      <div class="d-flex flex-row flex-nowrap flex-grow-1 mx-2">
        <mdb-icon
          icon="hashtag"
          color="primary"
          class="mr-2 mt-4"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <awgt-input
          label="Max participants per group"
          v-model.number="product.MaxParticipantsPerGroup"
          style="min-width: 240px"
          bg
          class="flex-grow-1"
          type="text"
          v-numeric="{
            precision: 4,
            scale: 0,
            posOnly: true,
            allowEmptyString: true,
          }"
          :tooltip="getTooltipsTextByCode(36)"
        ></awgt-input>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="hashtag"
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <awgt-input
          label="Max participants per timeslot"
          v-model.number="product.MaxParticipantsPerTimeslot"
          style="min-width: 240px"
          bg
          class="flex-grow-1"
          type="text"
          v-numeric="{
            precision: 4,
            scale: 0,
            posOnly: true,
            allowEmptyString: true,
          }"
          :tooltip="getTooltipsTextByCode(37)"
        ></awgt-input>
      </div>
    </div>
    <!--TODO: Check Length-->
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        icon="info"
        color="primary"
        class="pl-2"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <awgt-input
        v-model="product.InformationURL"
        counter
        :counterMaxValue="2048"
        maxlength="2048"
        label="Information URL"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-std-button
        type="button"
        tag="a"
        :href="product.InformationURL"
        target="_blank"
        class="button pt-2 command-button"
      >
        <mdb-icon class="mr-1" icon="link" />Test
      </awgt-std-button>
    </div>
    <div
      v-if="product.CanBeSoldSeparatelyInd == true"
      class="d-flex flex-row flex-wrap mx-2 my-2 align-items-center"
    >
      <mdb-icon
        icon="shopping-cart"
        color="primary"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <span class="mr-5 mx-2" style="min-width: 40px"
        >{{
          product.ProductType == "PT_Me" ? "Ordering" : "Booking"
        }}
        Method</span
      >
      <awgt-input
        id="bookingMethod_Phone"
        v-model="product.BookingMethod"
        type="radio"
        name="BookingMethodGroup"
        label="Phone"
        radioValue="BM_P"
      />
      <awgt-input
        id="bookingMethod_Email"
        v-model="product.BookingMethod"
        type="radio"
        name="BookingMethodGroup"
        label="Email"
        radioValue="BM_E"
      />
      <awgt-input
        id="bookingMethod_URL"
        v-model="product.BookingMethod"
        type="radio"
        name="BookingMethodGroup"
        label="URL"
        radioValue="BM_U"
      />
      <awgt-input
        id="bookingMethod_No"
        v-model="product.BookingMethod"
        type="radio"
        name="BookingMethodGroup"
        label="Not Required"
        radioValue="BM_N"
        class="mx-3"
      />
    </div>
    <div
      v-if="product.CanBeSoldSeparatelyInd == true"
      class="d-flex flex-row flex-nowrap flex-grow mx-2 align-items-center"
    >
      <mdb-icon
        icon="shopping-cart"
        color="primary"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <awgt-input
        v-model="product.BookingURL"
        counter
        :counterMaxValue="2048"
        maxlength="2048"
        :label="
          (product.ProductType == 'PT_Me' ? 'Ordering' : 'Booking') +
          '/Availability URL'
        "
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-std-button
        type="button"
        tag="a"
        :href="product.BookingURL"
        target="_blank"
        class="button pt-2 command-button"
      >
        <mdb-icon class="mr-1" icon="link" />Test
      </awgt-std-button>
    </div>
    <div v-if="product.ProductType == 'PT_Ay'">
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="clock"
          far
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <autogrow-textarea
          label="Start Times"
          type="textarea"
          :rows="1"
          :value="availableTimesForDisplay"
          readonly
          class="flex-grow-1 mr-2"
          bg
        ></autogrow-textarea>
        <awgt-std-button
          type="button"
          @click.native="startTimesDialogVisible = true"
          class="mr-0 command-button"
        >
          <mdb-icon class="mr-1" icon="clock" />Add
        </awgt-std-button>
      </div>
      <time-entry
        v-model="product.AvailableTimes"
        :dialogVisible="startTimesDialogVisible"
        title="Start times"
        @close="onTimeEntryClose"
      ></time-entry>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <div
        v-if="product.ProductType == 'PT_Ay'"
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2"
      >
        <mdb-icon
          icon="clock"
          far
          color="primary"
          class="mt-4 mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <asoft-duration-picker
          v-model.number="product.MinDurationInMinutes"
          :hourSelection="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :minuteSelection="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
          label="Min. Duration"
          class="flex-grow-1 mr-2"
          style="margin-top: 13px; min-width: 180px"
        ></asoft-duration-picker>
      </div>
      <div
        v-if="product.ProductType == 'PT_Ay'"
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2"
      >
        <mdb-icon
          icon="clock"
          far
          color="primary"
          class="mt-4 mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <asoft-duration-picker
          v-model.number="product.MaxDurationInMinutes"
          :hourSelection="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :minuteSelection="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
          label="Max. Duration"
          class="flex-grow-1 mr-2"
          style="margin-top: 13px; min-width: 180px"
        ></asoft-duration-picker>
      </div>

      <div
        v-if="product.CanBeSoldSeparatelyInd == true"
        class="d-flex flex-row flex-wrap flex-grow-1 align-items-center"
      >
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
        >
          <awgt-std-dropdown
            ref="depositTypesSelect"
            :items="getDepositTypes(false)"
            itemValueProperty="Code"
            itemTextProperty="Name"
            label="Deposit Type"
            style="width: 200px"
            class="mr-3"
            v-model="product.DepositType"
            bg
          ></awgt-std-dropdown>
          <mdb-icon
            color="primary"
            :icon="
              product.DepositType == 'DT_No'
                ? ''
                : product.DepositType == 'DT_Pe'
                ? 'percentage'
                : 'dollar-sign'
            "
            size="2x"
            class="mr-2"
          />
          <awgt-input
            v-model.number="product.DepositAmt"
            maxlength="7"
            label="Amount"
            :disabled="product.DepositType == 'DT_No'"
            bg
            v-numeric="{
              precision: product.DepositType == 'DT_Pe' ? 2 : 6,
              scale: product.DepositType == 'DT_Pe' ? 0 : 2,
              posOnly: true,
            }"
            class="flex-grow-1"
            type="text"
          ></awgt-input>
        </div>
        <asoft-FOC-picker
          v-model="product.FreeOfChargeRatio"
        ></asoft-FOC-picker>
      </div>
      <div class="d-flex flex-row flex-nowrap mx-2">
        <mdb-icon
          icon="calendar"
          color="primary"
          class="mr-2 mt-4"
          size="2x"
          far
          style="width: 40px; text-align: center"
        />
        <awgt-std-dropdown
          itemValueProperty="Code"
          :items="getProductAvailability"
          itemTextProperty="Name"
          v-model="productAvailability"
          label="Product Availability"
          bg
          :maxSelectedOptions="7"
          multiple
          selectAll
        ></awgt-std-dropdown>
      </div>
    </div>

    <!--
        Surcharges and Discounts
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">Surcharges &amp; Discounts</h4>
    <div>
      <div class="ml-2">
        The surcharges and discounts recorded below are used to pre-populate the
        surcharges and discounts for new product lines.
      </div>
      <product-price-adjustments
        class="mx-2 my-2"
        style="position: relative; top: -40px"
        v-model="product.PriceAdjustments"
      />
    </div>

    <!--
        Pricing
    -->
    <div class="section-break"></div>
    <h4 class="mx-2">Pricing</h4>
    <div class="mb-4">
      <commercial-product-line-list
        class="ml-2"
        v-model="product.CommercialProductLines"
        :priceAdjustments="product.PriceAdjustments"
      />
    </div>
    <div
      v-if="product.CanBeSoldSeparatelyInd == true"
      class="d-flex flex-row flex-wrap mx-2 align-items-center"
    >
      <mdb-icon
        far
        color="primary"
        icon="comment-alt-dollar"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <span class="mr-3" style="min-width: 60px">Payment Types</span>
      <awgt-input
        id="paymentType_PrePaid"
        type="checkbox"
        v-model="paymentType_PrePaid"
        name="paymentType"
        label="Pre-Paid"
        class="mx-2"
        style="min-width: 50px"
      ></awgt-input>
      <awgt-input
        id="paymentType_PurchaseOrder"
        type="checkbox"
        v-model="paymentType_PurchaseOrder"
        name="paymentType"
        label="Purchase Order"
        class="mx-2"
        style="min-width: 50px"
      ></awgt-input>
      <awgt-input
        id="paymentType_Voucher"
        type="checkbox"
        v-model="paymentType_Voucher"
        name="paymentType"
        label="Voucher"
        class="mx-2"
        style="min-width: 50px"
      ></awgt-input>
    </div>

    <!-- 
      Grouped With
    -->
    <product-groups
      class="mx-2 mt-4"
      v-model="product.ResellerSupplierProductGroups"
      :parentProduct="product"
    >
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
      >
        <h4>Reseller Product Groups</h4>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(38)" />
      </div>
    </product-groups>

    <!--
        Booking Alerts
    -->

    <div v-if="product.CanBeSoldSeparatelyInd == true">
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-4"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <div>Booking Alerts</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(53)" />
      </div>
      <mdb-wysiwyg
        v-model="product.BookingFeeDetail"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
    </div>

    <div
      v-if="product.ProductType != 'PT_Me'"
      class="d-flex flex-row flex-nowrap flex-grow-1 mr-4"
    >
      <mdb-icon
        icon="user"
        color="primary"
        class="mx-2 mt-3"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <awgt-input
        v-model="product.ContactPersonNm"
        :counterMaxValue="85"
        maxlength="85"
        counter
        label="Contact Name"
        bg
        class="flex-grow-1 mx-2"
      />
    </div>

    <!--
        Activity and Accommodation Location Details
    -->
    <div
      v-show="product.ProductType == 'PT_Ay' || product.ProductType == 'PT_An'"
    >
      <div class="section-break"></div>
      <h4 class="mx-2">
        {{
          product.ProductType == "PT_Ay"
            ? "Activity Location"
            : "Accommodation Location"
        }}
      </h4>
      <asoftAddressesCapture
        :template="getSystemTemplateFromCode('STT_C_AP')"
        v-model="product.Addresses"
      ></asoftAddressesCapture>
    </div>
    <div
      v-if="product.ProductType != 'PT_Me'"
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2"
    >
      <mdb-icon
        icon="phone"
        color="primary"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <asoft-phone-input
        id="client-phone"
        v-model="product.Phone"
        maxlength="20"
        label="Phone"
        style="width: 180px"
        bg
        class="mx-2"
      />
      <mdb-icon
        icon="at"
        color="primary"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <awgt-input
        v-model="product.EmailAddress"
        :counterMaxValue="254"
        maxlength="254"
        counter
        label="Email"
        type="email"
        style="min-width: 120px"
        bg
        class="flex-grow-1"
      />
    </div>

    <!--
        Transport Origins
    -->
    <!--
    <div v-if="product.ProductType == 'PT_Tr'">
      <span class="ml-2">Transport Origins</span>
      <asoftTreeview
        class="border scrollbar-primary"
        :value="transportationOrigins"
        childIndent="20px"
        :multiselectInd="true"
        childrenPropertyName="Children"
        slotNamePropertyName="CountryRegionTypeCd"
        :maxDepth="3"
      >
        <template v-slot:CRT_W="item">
          <span :class="[{ leafnode: item.Children.length == 0 }]">
            <i class="typeicon fas fa-globe-asia"></i>
            <i
              @click="onTransportationOriginsClick(item)"
              class="far m-1"
              :class="
                $lodash.find(product.TransportationOrigins, {
                  CountryRegionId: item.CountryRegionId,
                })
                  ? 'fa-check-square'
                  : 'fa-square'
              "
            ></i>
            <span
              :class="[
                {
                  selecteditem: $lodash.find(product.TransportationOrigins, {
                    CountryRegionId: item.CountryRegionId,
                  }),
                },
              ]"
              >{{ item.Name }}</span
            >
          </span>
        </template>
        <template v-slot:CRT_C="item">
          <span :class="[{ leafnode: item.Children.length == 0 }]">
            <i class="typeicon far fa-flag"></i>
            <i
              @click="onTransportationOriginsClick(item)"
              class="far m-1"
              :class="
                $lodash.find(product.TransportationOrigins, {
                  CountryRegionId: item.CountryRegionId,
                })
                  ? 'fa-check-square'
                  : 'fa-square'
              "
            ></i>
            <span
              :class="[
                {
                  selecteditem: $lodash.find(product.TransportationOrigins, {
                    CountryRegionId: item.CountryRegionId,
                  }),
                },
              ]"
              >{{ item.Name }}</span
            >
          </span>
        </template>
        <template v-slot:CRT_R="item">
          <span>
            <i class="leafnode typeicon fas fa-map"></i>
            <i
              @click="onTransportationOriginsClick(item)"
              class="far m-1"
              :class="
                $lodash.find(product.TransportationOrigins, {
                  CountryRegionId: item.CountryRegionId,
                })
                  ? 'fa-check-square'
                  : 'fa-square'
              "
            ></i>
            <span
              :class="[
                {
                  selecteditem: $lodash.find(product.TransportationOrigins, {
                    CountryRegionId: item.CountryRegionId,
                  }),
                },
              ]"
              >{{ item.Name }}</span
            >
          </span>
        </template>
        
        <template v-slot:collapsed_icon>
          <i class="stateicon far fa-plus-square"></i>
        </template>
        <template v-slot:expanded_icon>
          <i class="stateicon far fa-minus-square"></i>
        </template>
      </asoftTreeview>
    </div>
    -->
    <!--
      New Transport Origins
    -->
    <div v-if="product.ProductType == 'PT_Tr'">
      <span class="ml-2">Transport Origins</span>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="globe-asia"
          far
          color="primary"
          class="mr-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <autogrow-textarea
          label="Origins"
          type="textarea"
          :value="selectedTransportOriginsLabel"
          :rows="1"
          readonly
          class="flex-grow-1 mr-2"
          bg
        ></autogrow-textarea>
        <awgt-std-button
          type="button"
          class="mr-0 command-button"
          @click="showRegionEntry = true"
        >
          Edit
        </awgt-std-button>
      </div>
      <region-entry
        :transportOrigins="transportationOrigins[0].Children"
        :productOrigins="product.TransportationOrigins"
        :visible="showRegionEntry"
        @close-region-modal="handleClosingRegionModal"
      ></region-entry>
    </div>
    <!--
        Product Descriptions
    -->
    <div class="section-break"></div>
    <div class="mx-2 clickable-item" @click="onToggleProductDescriptions()">
      <h4 class="align-items-center">
        Product Descriptions
        <span>
          <mdb-icon
            v-if="productDescriptionsExpanded == true"
            icon="angle-up"
            size="1x"
            class="pl-2 pt-2"
            color="primary"
          />
          <mdb-icon
            v-else
            icon="angle-down"
            size="1x"
            class="pl-2 pt-2"
            color="primary"
          />
        </span>
      </h4>
    </div>

    <expansion-region :toggle="productDescriptionsExpanded">
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <div>Product Description</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(39)" />
      </div>
      <mdb-wysiwyg
        v-model="product.Description"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <div>Product Comment</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(40)" />
      </div>
      <!-- TODO: Rename to ProductComment -->
      <mdb-wysiwyg
        v-model="product.SpecialComment"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <div>Pre-product Itinerary Details</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(41)" />
      </div>
      <mdb-wysiwyg
        v-model="product.PreProductItineraryComment"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-2"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 40px; text-align: center"
        />
        <div>Post-product Itinerary Details</div>
        <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(42)" />
      </div>
      <mdb-wysiwyg
        v-model="product.PostProductItineraryComment"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
    </expansion-region>

    <!--
        AWGT Notes
    -->
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mx-2 mt-4"
    >
      <mdb-icon
        icon="clipboard"
        far
        color="primary"
        class="mb-2"
        size="2x"
        style="width: 40px; text-align: center"
      />
      <div>Agency Notes</div>
      <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(43)" />
    </div>
    <mdb-wysiwyg
      v-model="product.AWGTNotes"
      class="mx-2"
      style="height: 200px"
    ></mdb-wysiwyg>

    <div class="mt-3 mx-2">
      <itemised-notes
        v-model="product.ProductNotes"
        noteTitleProperty="Subject"
        noteDetailProperty="Note"
        noteType="NT_C"
      >
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
        >
          <mdb-icon
            icon="clipboard"
            far
            color="primary"
            class="mb-2 mr-3"
            size="2x"
            style="text-align: center"
          />
          <div>Client Product Notes</div>
          <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(51)" />
        </div>
      </itemised-notes>
    </div>

    <div class="mt-3 mx-2">
      <itemised-notes
        v-model="product.ProductNotes"
        noteTitleProperty="Subject"
        noteDetailProperty="Note"
        noteType="NT_A"
      >
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1"
        >
          <mdb-icon
            icon="clipboard"
            far
            color="primary"
            class="mb-2 mr-3"
            size="2x"
            style="text-align: center"
          />
          <div>Agency Product Notes</div>
          <tdoxTooltip class="mb-1" :detail="getTooltipsTextByCode(44)" />
        </div>
      </itemised-notes>
    </div>

    <div class="section-break" style="margin-bottom: 0.8rem"></div>

    <div class="float-right">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onSave()"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />Save
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onClose()"
      >
        <mdb-icon icon="times" class="mr-1" />Close
      </awgt-std-button>
    </div>

    <!-- Update product from reseller prompt -->
    <asoftPrompt
      v-model="updateProductFromResellerPrompt"
      headerClasses="p-3 showExitBeforeSavePromptHeader"
      bodyClasses="px-3 pt-3"
      footerClasses="px-3 pb-3"
    >
      <template v-slot:header>Update product from reseller?</template>
      <template v-slot:body>
        <div>
          <div class="mb-2">
            Select which of the following details you'd like to update from the
            new reseller supplier:
          </div>
          <awgt-input
            id="updateFromReseller_PhoneNumber"
            type="checkbox"
            v-model="updateFromReseller_PhoneNumber"
            name="updateFromReseller"
            label="Phone Number"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <awgt-input
            id="updateFromReseller_BookingUrl"
            type="checkbox"
            v-model="updateFromReseller_BookingUrl"
            name="updateFromReseller"
            label="Booking URL"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <awgt-input
            id="updateFromReseller_EmailAddress"
            type="checkbox"
            v-model="updateFromReseller_EmailAddress"
            name="updateFromReseller"
            label="Email Address"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <awgt-input
            id="updateFromReseller_DepositDetails"
            type="checkbox"
            v-model="updateFromReseller_DepositDetails"
            name="updateFromReseller"
            label="Deposit Details"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <awgt-input
            id="updateFromReseller_PaymentTypes"
            type="checkbox"
            v-model="updateFromReseller_PaymentTypes"
            name="updateFromReseller"
            label="Payment Types"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <div class="mt-2">Do you wish to proceed with this update?</div>
        </div>
      </template>
      <template v-slot:footer>
        <hr />
        <div class="d-flex justify-content-end align-center">
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onUpdateProductFromReseller()"
          >
            <mdb-icon icon="check" class="mr-1" />Ok
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onCancelUpdateProductFromReseller()"
          >
            <mdb-icon icon="times" class="mr-1" />Cancel
          </awgt-std-button>
        </div>
      </template>
    </asoftPrompt>

    <!-- Update product from provider prompt -->
    <asoftPrompt
      v-model="updateProductFromProviderPrompt"
      ref="changeDepositPrompt"
      headerClasses="p-3 showExitBeforeSavePromptHeader"
      bodyClasses="px-3 pt-3"
      footerClasses="px-3 pb-3"
    >
      <template v-slot:header>Update from provider supplier?</template>
      <template v-slot:body>
        <div>
          <div class="mb-2">
            Select which of the following details you'd like to update from the
            new provider supplier:
          </div>
          <awgt-input
            id="updateFromProvider_Address"
            type="checkbox"
            v-model="updateFromProvider_Address"
            name="updateFromprovider"
            label="Address"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <awgt-input
            id="updateFromProvider_ContactPersonNm"
            type="checkbox"
            v-model="updateFromProvider_ContactPersonNm"
            name="updateFromprovider"
            label="Contact Name"
            class="mx-2"
            style="min-width: 60px"
          ></awgt-input>
          <div>Subject Notes:</div>
          <template
            v-for="(
              updateFromProviderSubjectNote, index
            ) in updateFromProvider_SubjectNotes"
          >
            <awgt-input
              :id="
                'updateFromProviderSubjectNote_' +
                updateFromProviderSubjectNote.Subject
              "
              type="checkbox"
              v-model="updateFromProviderSubjectNote.IsUpdated"
              name="updateFromprovider"
              :label="updateFromProviderSubjectNote.Subject"
              class="mx-2"
              style="min-width: 60px"
              v-bind:key="index"
            ></awgt-input>
          </template>
          <div class="mt-2">Do you wish to proceed with this update?</div>
        </div>
      </template>
      <template v-slot:footer>
        <hr />
        <div class="d-flex justify-content-end align-center">
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onUpdateProductFromProvider()"
          >
            <mdb-icon icon="check" class="mr-1" />Yes
          </awgt-std-button>
          <awgt-std-button
            type="button"
            class="command-button mx-2"
            @click="onCancelUpdateProductFromProviderPrompt()"
          >
            <mdb-icon icon="times" class="mr-1" />No
          </awgt-std-button>
        </div>
      </template>
    </asoftPrompt>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style>
textarea {
  overflow: hidden;
}

.scrollbar-primary {
  position: relative;
  overflow-y: scroll;
}

.selecteditem {
  background-color: grey;
  color: white;
}

.leafnode {
  margin-left: 20px;
}

.stateicon {
  width: 20px;
}

@media (min-width: 614px) {
  .display-name-margin {
    margin-left: 0.5rem;
  }
}
@media (max-width: 614px) {
  .display-name-margin {
    margin-left: 48px;
  }
}
</style>

<script>
import { mdbContainer, mdbIcon, mdbFormInline } from "mdbvue";

import itemisedNotes from "@/components/ItemisedNotes";
import productGroups from "@/components/ProductGroups";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import TimeEntry from "@/components/TimeEntry.vue";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import AutogrowTextarea from "@/components/AutogrowTextarea";
import CommercialProductLineList from "./CommercialProductLineList";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { mapGetters } from "vuex";
import supplierApi from "@/api/supplierApi.js";
import productApi from "@/api/productApi.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftPhoneInput from "@/components/AtomSoftware/asoftPhoneInput.vue";
import asoftFOCPicker from "@/components/AtomSoftware/asoftFOCPicker";
//import asoftTreeview from "@/components/AtomSoftware/asoftTreeview";
import asoftPrompt from "@/components/AtomSoftware/asoftPrompt";
import countryRegionApi from "@/api/CountryRegionApi.js";
import ExpansionRegion from "@/components/ExpansionRegion";
import asoftDurationPicker from "@/components/AtomSoftware/asoftDurationPicker";
import asoftAddressesCapture from "@/components/AtomSoftware/asoftAddressesCapture.vue";
import { sharedMethods } from "@/shared/shared";
import productPriceAdjustments from "./ProductPriceAdjustments";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import RegionEntry from "@/components/RegionEntry.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbContainer,
    mdbIcon,
    AwgtStdButton,
    mdbFormInline,
    AwgtStdDropdown,
    awgtInput,
    asoftPhoneInput,
    asoftPrompt,
    mdbWysiwyg,
    AutogrowTextarea,
    //asoftTreeview,
    CommercialProductLineList,
    itemisedNotes,
    productGroups,
    ExpansionRegion,
    asoftDurationPicker,
    "time-entry": TimeEntry,
    asoftAddressesCapture,
    asoftFOCPicker,
    productPriceAdjustments,
    tdoxTooltip,
    RegionEntry,
  },
  directives: {
    numeric: numericDirective,
  },
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      selectedTransportOriginsLabel: "",
      product: {},
      suppliers: [],
      startTimesDialogVisible: false,
      availableTimesForDisplay: "",
      formSubmissionErrors: [],
      transportationOrigins: [],
      productSnapshot: {},
      isProductDirtyInd: false,
      paymentType_PrePaid: false,
      paymentType_PurchaseOrder: false,
      paymentType_Voucher: false,
      updateFromReseller_PhoneNumber: false,
      updateFromReseller_BookingUrl: false,
      updateFromReseller_EmailAddress: false,
      updateFromReseller_DepositDetails: false,
      updateFromReseller_PaymentTypes: false,
      //updateFromReseller_BookingMethod: false, //Will update the booking method and the value (e.g. URL, Phone Number or Email field)
      updateFromProvider_Address: false,
      updateFromProvider_ContactPersonNm: false,
      updateFromProvider_SubjectNotes: [],
      updateProductFromResellerPrompt: false,
      updateProductFromProviderPrompt: false,
      productDescriptionsExpanded: false,
      yearGroups: [],
      productAvailability: [],
      defaultClick: null,
      showRegionEntry: false,
      initialSuppliers: [],
    };
  },
  computed: {
    ...mapGetters([
      "getEmptyProduct",
      "getDepositTypes",
      "getProductMargins",
      "getAddressSubdivisions",
      "getYearGroups",
      "getProductAvailability",
      "getSystemTemplateFromCode",
      "getTooltipsTextByCode",
      "getEditStatuses",
    ]),
    supplierDropDown() {
      return this.suppliers.filter(
        (supplier) =>
          supplier.EditStatus === "ES_P" ||
          this.initialSuppliers.includes(supplier.Reference)
      );
    },
  },

  watch: {
    paymentType_PrePaid(to) {
      this.updatePaymentType("PY_PP", to);
    },

    paymentType_PurchaseOrder(to) {
      this.updatePaymentType("PY_PO", to);
    },

    paymentType_Voucher(to) {
      this.updatePaymentType("PY_Vo", to);
    },

    yearGroups(to, from) {
      if (to != from) {
        this.product.YearGroups = this.yearGroups.join();
      }
    },

    productAvailability(to, from) {
      if (to != from) {
        this.product.ProductAvailability = this.productAvailability.join();
      }
    },

    "product.ProductType"(to, from) {
      if (from != to) {
        switch (to) {
          case "PT_Tr":
            this.product.ProductSubType = "PST_TrCC";
            break;
          case "PT_Ay":
            this.product.ProductSubType = "PST_AyAy";
            break;
          case "PT_Me":
            this.product.ProductSubType = "PST_MeMe";
            break;
          case "PT_An":
            this.product.ProductSubType = "PST_AnAn";
            break;
        }
      }
    },

    "product.ProductSubType"(to) {
      if (to == "PST_AyFI" && this.product.ProductType == "PT_Ay") {
        this.product.ResellerSupplier = {};
        this.product.ProviderSupplier = {};
      }
    },

    "product.MinBedsNum"(to) {
      if (to == "") this.product.MinBedsNum = null;
    },

    "product.MaxBedsNum"(to) {
      if (to == "") this.product.MaxBedsNum = null;
    },

    "product.MinSeatsNum"(to) {
      if (to == "") this.product.MinSeatsNum = null;
    },

    "product.MaxSeatsNum"(to) {
      if (to == "") this.product.MaxSeatsNum = null;
    },

    "product.MaxParticipantsPerTimeslot"(to) {
      if (to == "") this.product.MaxParticipantsPerTimeslot = null;
    },

    "product.MinParticipantsPerGroup"(to) {
      if (to == "") this.product.MinParticipantsPerGroup = null;
    },

    "product.MaxParticipantsPerGroup"(to) {
      if (to == "") this.product.MaxParticipantsPerGroup = null;
    },

    "product.MinDurationInMinutes"(to) {
      if (to != 0 && this.product.MaxDurationInMinutes == 0) {
        this.defaultClick = to;
      }

      if (
        to != 0 &&
        (this.product.MaxDurationInMinutes == 0 ||
          this.product.MaxDurationInMinutes == this.defaultClick)
      )
        this.product.MaxDurationInMinutes = to;
    },

    "product.MaxDurationInMinutes"(to) {
      if (to != 0 && this.product.MinDurationInMinutes == 0) {
        this.defaultClick = to;
      }

      if (
        to != 0 &&
        (this.product.MinDurationInMinutes == 0 ||
          this.product.MinDurationInMinutes == this.defaultClick)
      )
        this.product.MinDurationInMinutes = to;
    },

    "product.DepositType"(to) {
      if (to == "DT_No") this.product.DepositAmt = 0;
    },

    "product.ResellerSupplier.Reference"(to, from) {
      if (to != from && this.product.ProductSubType != "PST_AyFI") {
        //Update ResellerSupplier with the new reseller supplier object.
        this.product.ResellerSupplier = this.$lodash.cloneDeep(
          this.suppliers.find((s) => s.Reference == to)
        );

        /*
          If the reseller supplier is being set for the first time then update product
          details from the reseller supplier.

          If the reseller supplier is being changed from another supplier, then compare
          the product and reseller supplier details. If any of them are different then
          ask the user if they should be updated to reflect the reseller supplier's
          details.
        */
        //Set which fields will be updated...

        if (from == null) {
          if (this.$route.params.productReference == undefined) {
            //Only if with add mode is this executed.
            this.updateFromReseller_PhoneNumber = true;
            this.updateFromReseller_BookingUrl = true;
            this.updateFromReseller_EmailAddress = true;
            this.updateFromReseller_DepositDetails = true;
            this.updateFromReseller_PaymentTypes = true;

            this.updateProductFromReseller();
          }
        } else {
          this.updateFromReseller_PhoneNumber = false;
          this.updateFromReseller_BookingUrl = false;
          this.updateFromReseller_EmailAddress = false;
          this.updateFromReseller_DepositDetails = false;
          this.updateFromReseller_PaymentTypes = false;

          if (this.product.Phone != this.product.ResellerSupplier.Phone)
            this.updateFromReseller_PhoneNumber = true;

          if (
            this.product.BookingURL != this.product.ResellerSupplier.BookingURL
          )
            this.updateFromReseller_BookingUrl = true;

          if (
            this.product.EmailAddress !=
            this.product.ResellerSupplier.EmailAddress
          )
            this.updateFromReseller_EmailAddress = true;

          if (
            this.product.DepositAmt !=
              this.product.ResellerSupplier.DepositAmt ||
            this.product.DepositType !=
              this.product.ResellerSupplier.DepositType
          )
            this.updateFromReseller_DepositDetails = true;

          if (
            this.product.PaymentTypes !=
            this.product.ResellerSupplier.PaymentTypes
          )
            this.updateFromReseller_PaymentTypes = true;

          if (
            this.updateFromReseller_PhoneNumber ||
            this.updateFromReseller_BookingUrl ||
            this.updateFromReseller_EmailAddress ||
            this.updateFromReseller_DepositDetails ||
            this.updateFromReseller_PaymentTypes
          )
            this.updateProductFromResellerPrompt = true;
        }
      }
    },

    "product.ProviderSupplier.Reference"(to, from) {
      if (to != from && this.product.ProductSubType != "PST_AyFI") {
        //Update ProviderSupplier with the new provider supplier object.
        this.product.ProviderSupplier = this.$lodash.cloneDeep(
          this.suppliers.find((s) => s.Reference == to)
        );

        if (from == null) {
          //Only if with add mode is this executed.
          if (this.$route.params.productReference == undefined) {
            this.updateFromProvider_Address = true;
            this.updateFromProvider_ContactPersonNm = true;
            this.product.ProviderSupplier.SupplierNotes.forEach((item) => {
              this.updateFromProvider_SubjectNotes.push({
                Subject: item.Subject,
                Note: item.Note,
                IsUpdated: true,
              });
            });
            this.updateProductFromProvider();
          }
        } else {
          this.updateFromProvider_Address = false;
          this.updateFromProvider_ContactPersonNm = false;
          this.updateFromProvider_SubjectNotes = new Array();
          this.product.ProviderSupplier.SupplierNotes.forEach((item) => {
            this.updateFromProvider_SubjectNotes.push({
              Subject: item.Subject,
              Note: item.Note,
              IsUpdated: false,
            });
          });
          let supplierPhysicalAddress =
            this.product.ProviderSupplier.Addresses.find(
              (a) => a.Type == "AT_Ph"
            );

          if (
            this.product.ContactPersonNm !=
            this.product.ProviderSupplier.ContactPersonNm
          )
            this.updateFromProvider_ContactPersonNm = true;
          if (
            this.product.Addresses[0].Street !=
              supplierPhysicalAddress.Street ||
            this.product.Addresses[0].Locality !=
              supplierPhysicalAddress.Locality ||
            this.product.Addresses[0].Subdivision !=
              supplierPhysicalAddress.Subdivision ||
            this.product.Addresses[0].Postcode !=
              supplierPhysicalAddress.Postcode ||
            this.product.Addresses[0].Country != supplierPhysicalAddress.Country
          )
            this.updateFromProvider_Address = true;

          for (
            var index = 0;
            index < this.updateFromProvider_SubjectNotes.length;
            index++
          ) {
            if (
              !this.product.ProductNotes.find((note) => {
                return (
                  note.Subject ==
                  this.updateFromProvider_SubjectNotes[index].Subject
                );
              })
            ) {
              this.updateFromProvider_SubjectNotes[index].IsUpdated = true;
            } else {
              if (
                this.product.ProductNotes.find((note) => {
                  return (
                    note.Subject ==
                    this.updateFromProvider_SubjectNotes[index].Subject
                  );
                }).Note != this.updateFromProvider_SubjectNotes[index].Note
              ) {
                this.updateFromProvider_SubjectNotes[index].IsUpdated = true;
              }
            }
          }

          if (
            this.updateFromProvider_Address ||
            this.updateFromProvider_SubjectNotes.some((sb) => {
              return sb.IsUpdated == true;
            }) ||
            this.updateFromProvider_ContactPersonNm
          )
            this.updateProductFromProviderPrompt = true;
        }
        /* If Reseller Name is null then set it to the Provider Name */
        if (this.product.ResellerSupplier.Reference == null)
          this.product.ResellerSupplier.Reference = to;
      }
    },
  },

  methods: {
    ...sharedMethods,

    generateTransportOriginLabel(countries) {
      let labels = [];
      for (let country of countries) {
        if (
          country.Children.length === 0 &&
          this.$lodash.find(this.product.TransportationOrigins, {
            CountryRegionId: country.CountryRegionId,
          })
        )
          labels.push(country.Name);
        else {
          let prefix = country.Name;
          for (let region of country.Children) {
            if (
              this.$lodash.find(this.product.TransportationOrigins, {
                CountryRegionId: region.CountryRegionId,
              })
            ) {
              labels.push(`${prefix} - ${region.Name}`);
            }
          }
        }
      }

      return labels.length === 0
        ? "No Countries or Regions have been selected"
        : labels.join(", ");
    },

    setPaymentTypeIndicators(paymentTypesArray) {
      this.paymentType_PrePaid = paymentTypesArray.indexOf("PY_PP") != -1;
      this.paymentType_PurchaseOrder = paymentTypesArray.indexOf("PY_PO") != -1;
      this.paymentType_Voucher = paymentTypesArray.indexOf("PY_Vo") != -1;
    },

    updatePaymentType(paymentTypeCode, setToTrueInd) {
      let idx = this.product.PaymentTypes.indexOf(paymentTypeCode);

      if (setToTrueInd) {
        if (idx == -1) this.product.PaymentTypes.push(paymentTypeCode);
      } else {
        if (idx != -1) this.product.PaymentTypes.splice(idx, 1);
      }
    },

    onTimeEntryClose() {
      this.startTimesDialogVisible = false;
      this.createAvailableTimesForDisplay(this.product.AvailableTimes);
    },

    createAvailableTimesForDisplay(availableTimesArray) {
      if (availableTimesArray) {
        this.availableTimesForDisplay = availableTimesArray
          .map((x) => this.$moment(x, "HH:mm", false).format("h:mma"))
          .join(", ");
      } else this.availableTimesForDisplay = "";
    },

    onTransportationOriginsClick(node) {
      //If the node is selected then remove the selection, otherwise add it.
      //Don't need to use the treeview's node.selected as I'm tracking
      //selected nodes in product.TransportationOrigins.
      let idx = this.$lodash.findIndex(
        this.product.TransportationOrigins,
        (n) => n.CountryRegionId == node.CountryRegionId
      );

      if (idx == -1) {
        this.product.TransportationOrigins.push({
          CountryRegionId: node.CountryRegionId,
        });
      } else {
        this.product.TransportationOrigins.splice(idx, 1);
      }
    },

    onToggleProductDescriptions() {
      this.productDescriptionsExpanded = !this.productDescriptionsExpanded;
    },

    async onSave() {
      this.$log.info("onSave...");

      this.product.SearchAddressString = this.product.Addresses.map(
        (address) => {
          return Object.values(address).join(" ");
        }
      ).join(" ");

      if (this.product.CanBeSoldSeparatelyInd == false)
        this.product.FreeOfChargeRatio = "None";

      if (this.product.ProductType === "PT_An")
        this.product.ProductSubType = "PST_AnAn";
      else if (this.product.ProductType === "PT_Me")
        this.product.ProductSubType = "PST_MeMe";

      if (
        this.product.ProductType == "PT_Ay" &&
        this.product.ProductSubType == "PST_AyFI"
      ) {
        this.product.ResellerSupplier = {};
        this.product.ProviderSupplier = {};
      }

      if (this.validateForm() == false) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      } else {
        this.$log.info("Saving the product...");
        await this.saveProduct(this.product)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [`The product ${this.product.Name} has been saved.`],
            });
            this.snapshotProduct(this.product);
            //Snapshot the product after it's loaded into the VDom
            //because loading into the VDom creates additional properties
          })
          .catch(() => {
            // This has already been logged in ProductApi.js
          });
      }
    },

    async saveProduct(product) {
      let storedProduct = null;

      if (product.Reference == "")
        storedProduct = await productApi.postProduct(product);
      else storedProduct = await productApi.putProduct(product);

      if (storedProduct.ResellerSupplier == null)
        storedProduct.ResellerSupplier = {};
      if (storedProduct.ProviderSupplier == null)
        storedProduct.ProviderSupplier = {};

      this.product = this.$lodash.cloneDeep(this.getEmptyProduct);

      /*
          GetEmptyProduct contains a superset of properties from all product types,
          this is required for a product retrieved from the database because if
          you change product types then you need the properties of the other
          product types to be available.

          The values of the existing product need to be merged into the
          getEmptyProduct template... Object.assign does this for us.
       */

      Object.assign(this.product, storedProduct);
    },

    snapshotProduct(product) {
      this.productSnapshot = this.$lodash.cloneDeep(product);

      //Reset isProductDirtyInd.
      this.isProductDirtyInd = false;
    },

    isFormDirty() {
      //Need to cache the isFormDirty test as it's expensive. It is reset in snapshotProduct.
      if (this.isProductDirtyInd == true) return true;

      this.isProductDirtyInd =
        this.$lodash.isEqual(this.product, this.productSnapshot) == false;

      return this.isProductDirtyInd;
    },

    validateForm() {
      //haven't done yet.
      this.formSubmissionErrors = [];

      /*
        Check a reseller and provider supplier have been provided if the
        product is not a fill-in activity.
      */
      if (this.product.ProductSubType != "PST_AyFI") {
        if (
          !this.product.ResellerSupplier.Reference ||
          this.product.ResellerSupplier.Reference.length == 0
        )
          this.formSubmissionErrors.push("You must enter a reseller supplier.");

        if (
          !this.product.ProviderSupplier.Reference ||
          this.product.ProviderSupplier.Reference.length == 0
        )
          this.formSubmissionErrors.push("You must enter a provider supplier.");
      }

      if (!this.product.Name || this.product.Name.trim().length == 0)
        this.formSubmissionErrors.push("You must enter a product name.");

      if (this.product.ContactPersonNm.length > 85)
        this.formSubmissionErrors.push(
          "A contact name must be no more than 85 charactor(s) in length."
        );

      if (
        (this.product.Phone.length == 0 ||
          (this.product.Phone.length == 6 &&
            this.product.Phone.substring(0, 2) == "13") ||
          (this.product.Phone.length == 10 &&
            (this.product.Phone.substring(0, 4) == "1300" ||
              this.product.Phone.substring(0, 4) == "1800" ||
              this.product.Phone.substring(0, 1) == "0")) ||
          (this.product.Phone.length >= 11 &&
            this.product.Phone.length <= 15)) == //International Numbers
        false
      ) {
        this.formSubmissionErrors.push(
          `Phone number ${this.product.Phone} is not recognised.`
        );
      }

      if (
        this.product.Name &&
        this.product.Name.trim().length != 0 &&
        this.product.Name.trim().length > 90
      )
        this.formSubmissionErrors.push(
          "Product name must be no mroe than 90 character(s) in length."
        );

      if (this.product.MaxDurationInMinutes < this.product.MinDurationInMinutes)
        this.formSubmissionErrors.push(
          "Maximum Duration must be greater than or equal to the Minimum Duration."
        );

      if (this.product.DepositType != "DT_No" && this.product.DepositAmt == 0)
        this.formSubmissionErrors.push(
          "You must enter a deposit amount or set the deposit type to 'No Deposit'."
        );

      for (let address of this.product.Addresses) {
        if (address.Street.length > 90)
          this.formSubmissionErrors.push(
            `${address.Name} street must be no more than 90 character(s) in length.`
          );

        if (address.Locality.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} locality must be no more than 50 character(s) in length.`
          );

        if (address.Subdivision != null && address.Subdivision.length > 3)
          this.formSubmissionErrors.push(
            `${address.Name} subdivision must be no more than 3 character(s) in length.`
          );
        if (
          address.Postcode.length != 0 &&
          (address.Postcode.length < 3 || address.Postcode.length > 12)
        )
          this.formSubmissionErrors.push(
            `${address.Name} postcode must be at least 3 characters in lengths and no more than 12 characters in length.`
          );
        if (address.Country.length > 50)
          this.formSubmissionErrors.push(
            `${address.Name} country must be no more than 50 character(s) in length..`
          );
      }

      if (this.product.CommercialProductLines) {
        for (
          let pcidx = 0;
          pcidx < this.product.CommercialProductLines.length;
          pcidx++
        ) {
          let pc = this.product.CommercialProductLines[pcidx];
          let chronologyLength = pc.Chronology.length;
          for (let i = 0; i < chronologyLength; i++) {
            for (let j = i + 1; j < chronologyLength; j++) {
              if (
                (pc.Chronology[i].ActiveFromDt >=
                  pc.Chronology[j].ActiveFromDt &&
                  pc.Chronology[i].ActiveFromDt <=
                    (pc.Chronology[j].ActiveToDt ?? "31 Dec 2999")) ||
                ((pc.Chronology[i].ActiveToDt ?? "31 Dec 2999") >=
                  pc.Chronology[j].ActiveFromDt &&
                  (pc.Chronology[i].ActiveToDt ?? "31 Dec 2999") <=
                    (pc.Chronology[j].ActiveToDt ?? "31 Dec 2999"))
              )
                this.formSubmissionErrors.push(
                  `Date range overlap in product component "${pc.Chronology[i].Name}".`
                );
            }
          }
        }
      }

      return this.formSubmissionErrors.length == 0;
    },

    onClose() {
      if (this.isFormDirty()) {
        this.$log.info("Dirty");
      } else {
        this.$log.info("Clean");
      }
      this.$router.go(-1);
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },

    /*     async waitOnTemplate() {
      let self = this;
      return new Promise((resolve) => {
        // Must be declared with 'var' to avoid the temporal dead zone
        var unwatch = self.$watch(
          "product.Addresses",
          (newVal) => {
            if (newVal != undefined && newVal.length > 0) {
              resolve();
              //Cancel the watch by calling unwatch. Reference: https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher
              if (unwatch) unwatch();
            }
          },
          { deep: true, immediate: true }
        );
      });
    }, */

    async loadFormData(reference) {
      this.suppliers = await supplierApi.getSuppliers();
      this.transportationOrigins = await countryRegionApi.getAsync();
      //await this.waitOnTemplate();

      /*
        This.product contains a superset of properties from all product types,
        this is required for a product retrieved from the database because if
        you change product types then you need the properties of the other
        product types to be available.

        If this is a new product then all that is required is a copy of the
        getEmptyProduct template.

        If this is an existing product then the values of the existing product
        need to be merged into the getEmptyProduct template... Object.assign
        does this for us.
       */

      if (reference != null) {
        let storedProduct = await productApi.getProductByReference(reference);
        if (storedProduct.ResellerSupplier == null)
          storedProduct.ResellerSupplier = {};
        if (storedProduct.ProviderSupplier == null)
          storedProduct.ProviderSupplier = {};
        this.createAvailableTimesForDisplay(storedProduct.AvailableTimes);
        Object.assign(this.product, storedProduct);
        this.yearGroups = this.product.YearGroups.split(",");
      }

      if (this.product.ProviderSupplier.Reference)
        this.initialSuppliers.push(this.product.ProviderSupplier.Reference);
      if (
        this.product.ResellerSupplier.Reference &&
        this.product.ResellerSupplier.Reference !=
          this.product.ProviderSupplier.Reference
      )
        this.initialSuppliers.push(this.product.ResellerSupplier.Reference);

      this.productAvailability = this.product.ProductAvailability.split(",");
      this.setPaymentTypeIndicators(this.product.PaymentTypes);

      //initialize the transport origins label
      this.selectedTransportOriginsLabel = this.generateTransportOriginLabel(
        this.transportationOrigins[0].Children
      );
    },

    updateProductFromReseller() {
      if (this.updateFromReseller_PhoneNumber == true) {
        this.product.Phone = this.product.ResellerSupplier.Phone;
      }
      if (this.updateFromReseller_BookingUrl == true) {
        this.product.BookingURL = this.product.ResellerSupplier.BookingURL;
      }
      if (this.updateFromReseller_EmailAddress == true) {
        this.product.EmailAddress = this.product.ResellerSupplier.EmailAddress;
      }
      if (this.updateFromReseller_DepositDetails == true) {
        this.product.DepositAmt = this.product.ResellerSupplier.DepositAmt;
        this.product.DepositType = this.product.ResellerSupplier.DepositType;
      }
      if (this.updateFromReseller_PaymentTypes == true) {
        this.product.PaymentTypes = this.product.ResellerSupplier.PaymentTypes;
        this.setPaymentTypeIndicators(this.product.PaymentTypes);
      }
    },

    onUpdateProductFromReseller() {
      this.updateProductFromReseller();
      this.updateProductFromResellerPrompt = false;
    },

    onCancelUpdateProductFromReseller() {
      this.updateProductFromResellerPrompt = false;
    },

    updateProductFromProvider() {
      let supplierPhysicalAddress =
        this.product.ProviderSupplier.Addresses.find((a) => a.Type == "AT_Ph");

      if (this.updateFromProvider_Address == true) {
        if (this.product.Addresses.length == 0) {
          this.product.Addresses.push(supplierPhysicalAddress);
        } else {
          this.product.Addresses[0].Street = supplierPhysicalAddress.Street;
          this.product.Addresses[0].Locality = supplierPhysicalAddress.Locality;
          this.product.Addresses[0].Subdivision =
            supplierPhysicalAddress.Subdivision;
          this.product.Addresses[0].Postcode = supplierPhysicalAddress.Postcode;
          this.product.Addresses[0].Country = supplierPhysicalAddress.Country;
        }
      }

      if (this.updateFromProvider_ContactPersonNm == true) {
        this.product.ContactPersonNm =
          this.product.ProviderSupplier.ContactPersonNm;
      }

      for (let subjectNote of this.updateFromProvider_SubjectNotes) {
        if (subjectNote.IsUpdated == true) {
          if (
            !this.product.ProductNotes.find((i) => {
              return i.Subject == subjectNote.Subject;
            })
          ) {
            this.product.ProductNotes.push({
              Subject: subjectNote.Subject,
              Note: subjectNote.Note,
            });
          } else {
            for (let j = 0; j < this.product.ProductNotes.length; j++) {
              if (this.product.ProductNotes[j].Subject == subjectNote.Subject)
                this.product.ProductNotes[j].Note = subjectNote.Note;
              break;
            }
          }
        }
      }
    },

    onUpdateProductFromProvider() {
      this.updateProductFromProvider();
      this.updateProductFromProviderPrompt = false;
    },

    onCancelUpdateProductFromProviderPrompt() {
      this.updateProductFromProviderPrompt = false;
    },

    handleClosingRegionModal(updatedRegions) {
      this.showRegionEntry = false;

      if (updatedRegions) {
        this.product.TransportationOrigins = updatedRegions;
        this.selectedTransportOriginsLabel = this.generateTransportOriginLabel(
          this.transportationOrigins[0].Children
        );
      }
    },
  },

  created() {
    this.product = this.$lodash.cloneDeep(this.getEmptyProduct);
  },

  mounted() {
    this.product = this.$lodash.cloneDeep(this.getEmptyProduct);
    let routeParams = this.$route.params;
    this.$log.info("RouteParams: %s", routeParams);
    let productReference = routeParams.productReference;
    this.loadFormData(productReference)
      .then(() => {
        //Snapshot the product after it's loaded into the VDom
        //because loading into the VDom creates additional properties
        this.snapshotProduct(this.product);
      })
      .catch(() => {
        // In order not to congaminate the console, we have "catch" block here. And
        // Erros haen been logged in the Api.js.
      });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSave().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
