<template>
  <div v-if="Object.keys(value).length > 0">
    <table id="supplier-table">
      <tr id="table-header">
        <td>Supplier</td>
        <td>Contact Name</td>
        <td>Physical Address</td>
        <td>Postal Address</td>
        <td>Phone</td>
        <td>Email</td>
        <td>PaymentTypes</td>
        <td>Deposit</td>
        <td>BookingMethod</td>
      </tr>
      <tr>
        <td>{{ value.Name }}</td>
        <td>
          {{ value.ContactPersonNm == "" ? " - " : value.ContactPersonNm }}
        </td>
        <td>
          {{
            getPhysicalAddress(value.Addresses) == ""
              ? " - "
              : getPhysicalAddress(value.Addresses)
          }}
        </td>
        <td>
          {{
            getPostalAddress(value.Addresses) == ""
              ? " - "
              : getPostalAddress(value.Addresses)
          }}
        </td>
        <td>{{ value.Phone }}</td>
        <td>{{ value.EmailAddress }}</td>
        <td>
          {{
            value.PaymentTypes.map((pt) => {
              return getPaymentTypeFromCode(pt);
            }).join(", ") == ""
              ? " - "
              : value.PaymentTypes.map((pt) => {
                  return getPaymentTypeFromCode(pt);
                }).join(", ")
          }}
        </td>
        <td>
          {{
            getDepositTypes(false).find((dt) => {
              return dt.Code == value.DepositType;
            }).Name
          }}
        </td>
        <td>
          {{ getBookingMethodNameByCode(value.BookingMethod) }}
        </td>
      </tr>
    </table>
    <div v-if="value.ItineraryNotes && value.ItineraryNotes != ''" class="mt-4">
      <div><span style="font-weight: bold">Itinerary Notes</span></div>
      <div class="html-in-views">
        <span v-html="value.ItineraryNotes"></span>
      </div>
    </div>
    <div
      v-if="
        value.PreSupplierItineraryComment &&
        value.PreSupplierItineraryComment != ''
      "
      class="mt-4"
    >
      <div>
        <span style="font-weight: bold">Pre-supplier Itinerary Comment</span>
      </div>
      <span>{{ value.PreSupplierItineraryComment }}</span>
    </div>
    <div
      v-if="
        value.PostSupplierItineraryComment &&
        value.PostSupplierItineraryComment != ''
      "
      class="mt-4"
    >
      <div>
        <span style="font-weight: bold">Post-supplier Itinerary Comment</span>
      </div>
      <span>{{ value.PostSupplierItineraryComment }}</span>
    </div>
    <div v-if="value.AWGTNotes.length > 0" class="mt-4">
      <div><span style="font-weight: bold">AWGT Notes</span></div>
      <span v-html="value.AWGTNotes" class="html-in-views"></span>
    </div>
    <div
      v-if="value.SupplierNotes && value.SupplierNotes.length > 0"
      class="mt-4"
    >
      <div>
        <span style="font-weight: bold">AWGT Supplier Notes</span>
      </div>
      <ul>
        <li v-for="(itemisedNote, ind) in value.SupplierNotes" v-bind:key="ind">
          {{ itemisedNote.Subject }}: {{ itemisedNote.Note }}
        </li>
      </ul>
    </div>
    <div
      v-if="value.SupplierFiles && value.SupplierFiles.length > 0"
      class="mt-4"
    >
      <div><span style="font-weight: bold">Files</span></div>
      <div>
        <ul>
          <li
            v-for="(category, indOfCat) in value.SupplierFiles"
            v-bind:key="indOfCat"
          >
            {{ category.CategoryName }}
            <ul v-if="category.Files && category.Files.length > 0">
              <li
                v-for="(file, indOfFile) in category.Files"
                v-bind:key="indOfFile"
              >
                {{ file.FileName }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="section-break"></div>
    <template v-for="(product, indOfInfoList) in value.Products">
      <div v-bind:key="indOfInfoList">
        <div class="html2pdf__page-break"></div>
        <div class="mx-2 my-4 avoid-page-break">
          <div style="font-weight: bold">
            <span>Product Name</span> :
            <span style="color: #923f82">{{ product.Name }}</span>
          </div>
          <div
            v-if="product.DisplayName && product.DisplayName != ''"
            style="font-weight: bold"
            class="mt-2"
          >
            <span>Product Display Name</span> :
            <span style="color: #923f82">{{ product.DisplayName }}</span>
          </div>
          <div class="mt-4">
            <div v-if="product.InformationURL && product.InformationURL != ''">
              Information URL:
              <a :href="product.InformationURL">{{ product.InformationURL }}</a>
            </div>
            <div v-if="product.BookingURL && product.BookingURL != ''">
              Booking URL:
              <a :href="product.BookingURL">{{ product.BookingURL }}</a>
            </div>
          </div>
          <div
            v-if="product.Description && product.Description != ''"
            class="mt-4"
          >
            <span style="font-weight: bold">Product Description</span> :
            <div class="html-in-views">
              <span v-html="product.Description"></span>
            </div>
          </div>
          <div
            v-if="product.SpecialComment && product.SpecialComment != ''"
            class="mt-4"
          >
            <span style="font-weight: bold">SpecialComment</span> :
            <span>{{ product.SpecialComment }}</span>
          </div>
          <div v-if="product.AWGTNotes && product.AWGTNotes != ''" class="mt-4">
            <span style="font-weight: bold">AWGT Notes</span> :
            <span v-html="product.AWGTNotes" class="html-in-views"></span>
          </div>
          <div
            v-if="
              supplierType == true &&
              product.ResellerSuppliers &&
              product.ResellerSuppliers.length > 0
            "
            class="mt-4"
          >
            <span style="font-weight: bold">Reseller Suppliers</span> :
            <ul>
              <li v-for="(s, i) in product.ResellerSuppliers" v-bind:key="i">
                {{ s.Name }}
              </li>
            </ul>
          </div>
          <div
            v-if="
              supplierType == false &&
              product.ProviderSuppliers &&
              product.ProviderSuppliers.length > 0
            "
            class="mt-4"
          >
            <span style="font-weight: bold">Provider Suppliers</span> :
            <ul>
              <li v-for="(s, i) in product.ProviderSuppliers" v-bind:key="i">
                {{ s.Name }}
              </li>
            </ul>
          </div>

          <div class="mt-4">
            <span style="font-weight: bold">Product Pricing</span> :
          </div>
          <div class="html2pdf__page-break"></div>
          <template
            v-if="
              product.ProductType == 'PT_Ay' &&
              product.AvailableTimes.length > 0
            "
            class="avoid-page-break"
          >
            <div style="text-align: center">
              <span style="color: #923f82; font-size: 1.2rem">Start Times</span>
            </div>
            <div class="d-flex flex-row flex-nowrap">
              <div style="width: 50%">
                <div style="text-align: center">
                  <span style="color: #923f82; font-size: 1.2rem">AM</span>
                </div>
                <div style="text-align: center">
                  <table class="available-time-tb">
                    <template
                      v-for="(timeRow, indexRow) in leftColOfStartTimes(
                        product
                      )"
                    >
                      <tr v-bind:key="indexRow">
                        <template v-for="(item, indexItem) in timeRow">
                          <td v-bind:key="indexItem">{{ item }}</td>
                        </template>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
              <div class="vertical-devider"></div>
              <div style="width: 50%">
                <div style="text-align: center">
                  <span style="color: #923f82; font-size: 1.2rem">PM</span>
                </div>
                <div style="text-align: center">
                  <table class="available-time-tb">
                    <template
                      v-for="(timeRow, indexRow) in rightColOfStartTimes(
                        product
                      )"
                    >
                      <tr v-bind:key="indexRow">
                        <template v-for="(item, indexItem) in timeRow">
                          <td v-bind:key="indexItem">{{ item }}</td>
                        </template>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
            <div class="section-break"></div>
          </template>

          <div style="text-align: center">
            <span style="color: #923f82; font-size: 1.2rem"
              >Product Pricing</span
            >
          </div>

          <div class="d-flex flex-row flex-nowrap avoid-page-break">
            <div style="width: 50%">
              <div style="text-align: center">
                <span style="color: #923f82; font-size: 1.2rem">CURRENT</span>
              </div>
              <div style="text-align: center">
                <div style="margin-left: 30px">
                  <div
                    v-for="(pc, index) in product.CommercialProductLines"
                    :key="index"
                  >
                    <div class="mt-3">
                      <span style="display: none">{{
                        (c = currentCommercialProductLineChronology(pc))
                      }}</span>
                      <template v-if="c != null">
                        <div>
                          <span style="color: #923f82">{{ c.Name }}</span
                          ><span style="color: #f48247">
                            {{
                              formatCurrency(c.ExGSTAmount + c.GSTAmount)
                            }}</span
                          ><span> {{ getPriceTypeText(c.PriceTypeCd) }}</span>
                        </div>
                        <div class="ml-4 italics-font" style="margin-top: -5px">
                          <div v-if="c.ActiveToDt == null">
                            From {{ formatDate(c.ActiveFromDt) }}
                          </div>
                          <div v-else>
                            From {{ formatDate(c.ActiveFromDt) }} to
                            {{ formatDate(c.ActiveToDt) }}
                          </div>
                          <div
                            v-if="c.Description != ''"
                            v-html="c.Description"
                            class="html-in-views"
                            style="margin-top: -5px"
                          ></div>
                          <div>
                            <span v-if="c.isIndicativePricingInd == true"
                              ><i>Indicative Price</i></span
                            >
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vertical-devider"></div>
            <div style="width: 50%">
              <div style="text-align: center">
                <span style="color: #923f82; font-size: 1.2rem">FUTURE</span>
              </div>
              <div style="text-align: center">
                <div style="margin-left: 30px">
                  <div
                    v-for="(pc, index) in product.CommercialProductLines"
                    :key="index"
                  >
                    <div class="mt-3">
                      <span style="display: none">{{
                        (f = futureCommercialProductLineChronology(pc))
                      }}</span>
                      <div v-for="(c, idx) in f" :key="idx">
                        <div>
                          <span style="color: #923f82">{{ c.Name }}</span
                          ><span style="color: #f48247">
                            {{
                              formatCurrency(c.ExGSTAmount + c.GSTAmount)
                            }}</span
                          ><span> {{ getPriceTypeText(c.PriceTypeCd) }}</span>
                        </div>
                        <div class="ml-4 italics-font" style="margin-top: -5px">
                          <div v-if="c.ActiveToDt == null">
                            From {{ formatDate(c.ActiveFromDt) }}
                          </div>
                          <div v-else>
                            From {{ formatDate(c.ActiveFromDt) }} to
                            {{ formatDate(c.ActiveToDt) }}
                          </div>
                          <div
                            v-if="c.Description != ''"
                            v-html="c.Description"
                            class="html-in-views"
                            style="margin-top: -5px"
                          ></div>
                          <div>
                            <span v-if="c.isIndicativePricingInd == true"
                              ><i>Indicative Price</i></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-break"></div>

          <div style="text-align: center">
            <span>
              <span style="color: #923f82; font-size: 1.3rem">PAST</span>
            </span>
          </div>

          <div class="d-flex flex-row flex-nowrap avoid-page-break">
            <div style="width: 50%">
              <div style="text-align: center">
                <div style="margin-left: 30px">
                  <div
                    v-for="(pc, index) in product.CommercialProductLines"
                    :key="index"
                  >
                    <div
                      v-if="
                        index <= ~~(product.CommercialProductLines.length / 2)
                      "
                      class="mt-3"
                    >
                      <span style="display: none">{{
                        (p = pastCommercialProductLineChronology(pc))
                      }}</span>
                      <div v-for="(c, idx) in p" :key="idx">
                        <div class="ml-4">
                          <div>
                            <span style="color: #923f82">{{ c.Name }}</span
                            ><span style="color: #f48247">
                              {{
                                formatCurrency(c.ExGSTAmount + c.GSTAmount)
                              }}</span
                            ><span> {{ getPriceTypeText(c.PriceTypeCd) }}</span>
                          </div>
                          <div
                            class="ml-4 italics-font"
                            style="margin-top: -5px"
                          >
                            <div v-if="c.ActiveToDt == null">
                              From {{ formatDate(c.ActiveFromDt) }}
                            </div>
                            <div v-else>
                              From {{ formatDate(c.ActiveFromDt) }} to
                              {{ formatDate(c.ActiveToDt) }}
                            </div>
                            <div
                              v-if="c.Description != ''"
                              v-html="c.Description"
                              class="html-in-views"
                              style="margin-top: -5px"
                            ></div>
                            <div>
                              <span v-if="c.isIndicativePricingInd == true"
                                ><i>Indicative Price</i></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%">
              <div style="text-align: center">
                <div style="margin-left: 30px">
                  <div
                    v-for="(pc, index) in product.CommercialProductLines"
                    :key="index"
                  >
                    <div
                      v-if="
                        index > ~~(product.CommercialProductLines.length / 2)
                      "
                      class="mt-3"
                    >
                      <span style="display: none">{{
                        (p = pastCommercialProductLineChronology(pc))
                      }}</span>
                      <div v-for="(c, idx) in p" :key="idx">
                        <div class="ml-4">
                          <div>
                            <span style="color: #923f82">{{ c.Name }}</span
                            ><span style="color: #f48247">
                              {{
                                formatCurrency(c.ExGSTAmount + c.GSTAmount)
                              }}</span
                            ><span> {{ getPriceTypeText(c.PriceTypeCd) }}</span>
                          </div>
                          <div
                            class="ml-4 italics-font"
                            style="margin-top: -5px"
                          >
                            <div v-if="c.ActiveToDt == null">
                              From {{ formatDate(c.ActiveFromDt) }}
                            </div>
                            <div v-else>
                              From {{ formatDate(c.ActiveFromDt) }} to
                              {{ formatDate(c.ActiveToDt) }}
                            </div>
                            <div
                              v-if="c.Description != ''"
                              v-html="c.Description"
                              class="html-in-views"
                              style="margin-top: -5px"
                            ></div>
                            <div>
                              <span v-if="c.isIndicativePricingInd == true"
                                ><i>Indicative Price</i></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { sharedMethods } from "@/shared/shared";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    supplierType: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getDepositTypes", "getBookingMethodNameByCode"]),
    getPhysicalAddress() {
      return function (address) {
        let physicalAddress = address.find((a) => {
          return a.Type == "AT_Ph";
        });

        return (
          (physicalAddress.Street.length > 0
            ? physicalAddress.Street + ", "
            : "") +
          (physicalAddress.Locality.length > 0
            ? physicalAddress.Locality + ", "
            : "") +
          (physicalAddress.Subdivision != null &&
          physicalAddress.Subdivision.length > 0
            ? physicalAddress.Subdivision + ", "
            : "") +
          (physicalAddress.Postcode.length > 0 ? physicalAddress.Postcode : "")
        );
      };
    },
    getPostalAddress() {
      return function (address) {
        let postalAddress = address.find((a) => {
          return a.Type == "AT_Po";
        });

        return (
          (postalAddress.Street.length > 0 ? postalAddress.Street + ", " : "") +
          (postalAddress.Locality.length > 0
            ? postalAddress.Locality + ", "
            : "") +
          (postalAddress.Subdivision != null &&
          postalAddress.Subdivision.length > 0
            ? postalAddress.Subdivision + ", "
            : "") +
          (postalAddress.Postcode.length > 0 ? postalAddress.Postcode : "")
        );
      };
    },
    getPaymentTypeFromCode() {
      return function (code) {
        switch (code) {
          case "PY_PP":
            return "Pre-Paid";
          case "PY_PO":
            return "Purchase Order";
          case "PY_Vo":
            return "Voucher";
          default:
            "";
        }
      };
    },
    leftColOfStartTimes() {
      return function (product) {
        if (product.AvailableTimes.length > 0) {
          return this.intoSubArray(
            product.AvailableTimes.map((x) =>
              this.$moment(x, "HH:mm", false).format("h:mma")
            ).filter((t) => {
              return t.includes("am");
            }),
            5
          );
        } else return "";
      };
    },
    rightColOfStartTimes() {
      return function (product) {
        if (product.AvailableTimes.length > 0) {
          return this.intoSubArray(
            product.AvailableTimes.map((x) =>
              this.$moment(x, "HH:mm", false).format("h:mma")
            ).filter((t) => {
              return t.includes("pm");
            }),
            5
          );
        } else return "";
      };
    },
  },

  methods: {
    ...sharedMethods,

    intoSubArray(myArray, chunks = 5) {
      let num = Math.ceil(myArray.length / chunks);
      const result = Array();
      for (let i = 0; i < num; i++) {
        result[i] = [];
      }
      let index = 0;
      for (let i = 0, length = myArray.length; i < length; i++) {
        if (i != 0 && i % chunks == 0) index += 1;
        result[index].push(myArray[i]);
      }
      return result;
    },

    getPriceTypeText(priceType) {
      switch (priceType) {
        case "PrT_I":
          return "GST Inclusive";
        case "PrT_E":
          return "GST Exclusive";
        case "PrT_F":
          return "GST Free";
      }
    },

    formatCurrency(amount) {
      return (amount < 0 ? "-$" : "$") + Math.abs(amount).toFixed(2);
    },

    currentCommercialProductLineChronology(pc) {
      return pc.Chronology.find(
        (c) =>
          new Date(c.ActiveFromDt) <= new Date() &&
          (c.ActiveToDt == null || new Date(c.ActiveToDt) >= new Date())
      );
    },

    pastCommercialProductLineChronology(pc) {
      return pc.Chronology.filter(
        (c) => c.ActiveToDt != null && new Date(c.ActiveToDt) < new Date()
      );
    },

    futureCommercialProductLineChronology(pc) {
      return pc.Chronology.filter((c) => new Date(c.ActiveFromDt) > new Date());
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: underline;
}

#supplier-table {
  width: 100%;
  margin-top: 10px;
  page-break-inside: auto;
}

#supplier-table,
th,
td {
  table-layout: fixed;
  border: 1px solid black;
  padding: 5px 5px;
  word-wrap: break-word;
  font-size: 12px;
}

#table-header td {
  font-weight: bold;
}

.section-break {
  display: block;
  height: 2px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: linear-gradient(40deg, #d3f180, #9dbe44) !important;
}

.vertical-devider {
  margin-top: 10px;
  border-left: 3px solid lightgrey;
  margin-bottom: 10px;
}

.available-time-tb {
  margin-left: auto;
  margin-right: auto;
}

.available-time-tb td {
  border-style: hidden;
}

.available-time-tb > tr > td {
  padding: 10px 10px;
}

.avoid-page-break {
  page-break-inside: auto !important;
  page-break-before: auto !important;
  page-break-after: auto !important;
}
</style>
