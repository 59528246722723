<template>
  <mdb-container fluid>
    <h1 class="text-center">System Action Search</h1>

    <div class="d-flex flex-row flex-wrap flex-grow-1" style="margin-top: 40px">
      <awgt-input
        v-model="userId"
        counter
        :counterMaxValue="100"
        maxlength="100"
        style="width: 90px"
        label="User ID"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <awgt-input
        v-model="description"
        counter
        :counterMaxValue="2000"
        maxlength="100"
        style="min-width: 300px"
        label="Description"
        bg
        class="flex-grow-1 mx-2"
      ></awgt-input>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <awgt-std-dropdown
          color="primary"
          label="Action Context"
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getSystemActionContexts(true)"
          itemTextProperty="Name"
          v-model="actionContext"
          bg
        />
        <awgt-std-dropdown
          color="primary"
          label="Action Type"
          style="min-width: 90px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getSystemActionTypes(true)"
          itemTextProperty="Name"
          v-model="actionType"
          bg
        />
      </div>
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mx-2 mt-4"
            size="2x"
          />
          <mdb-date-picker
            v-model="dateStart"
            label="Start Date"
            autoHide
            :option="datePickerOptions"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 140px"
          />
        </div>

        <div class="d-flex flex-row flex-nowrap flex-grow-1">
          <mdb-icon
            icon="calendar-alt"
            far
            color="primary"
            class="mx-2 mt-4"
            size="2x"
          />
          <mdb-date-picker
            v-model="dateEnd"
            label="End Date"
            autoHide
            :option="datePickerOptions"
            bg
            class="flex-grow-1 mx-2"
            style="min-width: 140px"
          />
        </div>
      </div>
    </div>

    <mdb-form-inline class="d-flex flex-grow-1 mb-2">
      <div class="flex-fill">
        <span
          class="sorting-header mx-2 p-2"
          style="cursor: pointer"
          @click="onToggleOrder"
        >
          Action Date:
          <mdb-icon
            v-if="isPointerUp"
            style="cursor: pointer"
            icon="caret-down"
          />
          <mdb-icon v-else style="cursor: pointer" icon="caret-up" />
        </span>
      </div>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onClear"
      >
        <mdb-icon icon="search" class="mr-1" id="search-client" />Clear
      </awgt-std-button>

      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onSearch"
      >
        <mdb-icon icon="search" class="mr-1" id="search-client" />Search
      </awgt-std-button>
    </mdb-form-inline>

    <mdb-card v-for="(systemAction, index) in systemActions" :key="index">
      <mdb-card-header
        @click.native="onToggleSystemActionsDetailExpansion(index)"
        tag="button"
      >
        <span class="float-right">
          <mdb-icon
            v-if="systemActionsDetailExpansion[index] == true"
            icon="angle-up"
            size="2x"
            class="pt-2"
            color="primary"
          />
          <mdb-icon
            v-else
            icon="angle-down"
            size="2x"
            class="pt-2"
            color="primary"
          />
        </span>
        <div class="d-flex flex-row" style="text-align: left">
          <div style="width: 350px">
            {{ formatDate(systemAction.ActionDT) }}
          </div>
          <div style="width: 120px">
            User:
            {{
              systemAction.UserReference.length > 0
                ? systemAction.UserReference
                : systemAction.LastUpdatedBy
            }}
          </div>
          <div style="width: 70px">
            {{
              getSystemActionContextNameByCode(systemAction.SystemActionContext)
            }}
          </div>
          <div style="width: 70px">
            {{ getSystemActionTypeNameByCode(systemAction.SystemActionType) }}
          </div>
          <div>{{ systemAction.Description }}</div>
        </div>
      </mdb-card-header>
      <expansion-region :toggle="systemActionsDetailExpansion[index]">
        <mdb-card-body>
          Booking ID: {{ systemAction.BookingId }}<br />
          IP Address: {{ systemAction.IPAddress }}<br />
          Detail: {{ systemAction.Detail }}<br />
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <div>
      <div ref="infinitescrolltrigger" id="scroll-trigger"></div>
    </div>
  </mdb-container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbDatePicker,
  mdbFormInline,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
} from "mdbvue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import ExpansionRegion from "@/components/ExpansionRegion";
import systemApi from "@/api/systemActionsApi.js";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import { sharedMethods } from "@/shared/shared";

const SORT_ORDER = { ascending: "ASC", descending: "DESC" };
const MAX_PER_PAGE = 10;
// This will eliminate unwanted state change during infinite scroll
let searchParams = {};
export default {
  components: {
    mdbContainer,
    AwgtStdDropdown,
    mdbIcon,
    mdbDatePicker,
    mdbFormInline,
    AwgtStdButton,
    mdbCard,
    mdbCardHeader,
    ExpansionRegion,
    mdbCardBody,
    awgtInput,
  },

  data() {
    return {
      datePickerOptions: { format: "D MMM YYYY" },
      userId: "",
      description: "",
      actionContext: "",
      actionType: "",
      dateStart: "",
      dateEnd: "",
      systemActions: [],
      systemActionsDetailExpansion: [],
      observer: null,
      currentPage: 1,
      sortOrder: SORT_ORDER.descending,
      totalResult: 0,
    };
  },

  computed: {
    ...mapGetters([
      "getSystemActionTypes",
      "getSystemActionContexts",
      "getSystemActionContextNameByCode",
      "getSystemActionTypeNameByCode",
    ]),

    pageCount() {
      return this.totalResult ? Math.ceil(this.totalResult / MAX_PER_PAGE) : 0;
    },
    isPointerUp() {
      return this.sortOrder === SORT_ORDER.descending;
    },
  },

  methods: {
    ...sharedMethods,

    onToggleOrder() {
      this.sortOrder =
        this.sortOrder === SORT_ORDER.ascending
          ? SORT_ORDER.descending
          : SORT_ORDER.ascending;
      this.onSearch();
    },

    onToggleSystemActionsDetailExpansion(index) {
      this.$set(
        this.systemActionsDetailExpansion,
        index,
        !this.systemActionsDetailExpansion[index]
      );
    },
    scrollTrigger() {
      /*
      https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      */
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(async (entry) => {
          if (
            entry.intersectionRatio > 0 &&
            this.currentPage < this.pageCount
          ) {
            this.currentPage++;

            const result = await systemApi.getBriefSystemActions(
              searchParams.userId,
              searchParams.description,
              searchParams.actionContext,
              searchParams.actionType,
              searchParams.dateStart,
              searchParams.dateEnd,
              this.currentPage,
              this.sortOrder
            );

            this.systemActions = this.systemActions.concat(
              result.SystemActions
            );
          }
        });
      });

      this.observer.observe(this.$refs.infinitescrolltrigger);
    },
    onSearch() {
      if (this.observer) this.observer.disconnect();
      // Reset Current Page to remove temp state from previous search
      this.currentPage = 1;
      searchParams = {
        userId: this.userId,
        description: this.description,
        actionContext: this.actionContext,
        actionType: this.actionType,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      this.search();
    },

    async search() {
      const result = await systemApi.getBriefSystemActions(
        searchParams.userId,
        searchParams.description,
        searchParams.actionContext,
        searchParams.actionType,
        searchParams.dateStart,
        searchParams.dateEnd,
        this.currentPage,
        this.sortOrder
      );

      console.log("Search called", result);

      this.systemActions = result.SystemActions;
      this.totalResult = result.TotalResults;

      this.scrollTrigger();
    },
    onClear() {
      this.userId = "";
      this.description = "";
      this.actionContext = "";
      this.actionType = "";
      this.dateStart = "";
      this.dateEnd = "";

      this.systemActions = [];
      this.currentPage = 1;
      this.totalResult = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/sharedVariables.scss";
#scroll-trigger {
  height: 10px;
}
.sorting-header {
  font-weight: bold;
  color: $headingColor;
}
</style>
