var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "picker__calendar-container" }, [
    _c(
      "div",
      { staticClass: "clockpicker-plate" },
      [
        _vm.unitsMode === "h"
          ? _c("time-picker-clock", {
              ref: "clock",
              attrs: {
                min: 1,
                max: _vm.hoursFormat,
                step: 1,
                rotate: 30,
                color: _vm.color,
                scrollable: true,
                value: _vm.hours,
                double: _vm.hoursFormat === 24,
              },
              on: {
                input: _vm.onHoursChange,
                change: function ($event) {
                  return _vm.$emit("change", "m")
                },
              },
            })
          : _vm._e(),
        _vm.unitsMode === "m"
          ? _c("time-picker-clock", {
              ref: "clock",
              attrs: {
                min: 0,
                max: 59,
                step: 5,
                rotate: 0,
                color: _vm.color,
                scrollable: true,
                value: _vm.minutes,
              },
              on: { input: _vm.onMinutesChange },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }