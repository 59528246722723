var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "picker__footer" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-flat clockpicker-button",
        attrs: { type: "button", tabindex: 0 },
        on: { click: _vm.handleDoneClick },
      },
      [_vm._v(" " + _vm._s(_vm.done) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "btn btn-flat clockpicker-button",
        attrs: { type: "button", tabindex: 0 },
        on: { click: _vm.handleClearClick },
      },
      [_vm._v(" " + _vm._s(_vm.clear) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "btn btn-flat clockpicker-button",
        attrs: { type: "button", tabindex: 0 },
        on: { click: _vm.handleCancelClick },
      },
      [_vm._v(" " + _vm._s(_vm.cancel) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }