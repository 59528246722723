<template>
  <mdb-container fluid>
    <h1 class="text-center">Issue Log</h1>
    <form novalidate @submit.prevent="onSubmit">
      <div class="d-flex flex-row flex-wrap flex-grow-1">
        <awgt-input
          label="Title"
          v-model="issueLog.Title"
          counter
          :counterMaxValue="50"
          maxlength="50"
          style="min-width: 300px"
          bg
          class="flex-grow-1 mx-2"
        ></awgt-input>
        <awgt-std-dropdown
          label="Status"
          style="min-width: 90px; max-width: 150px"
          class="flex-grow-1 mx-2"
          itemValueProperty="Code"
          :items="getIssueStatuses(false)"
          itemTextProperty="Name"
          v-model="issueLog.SystemIssueStatus"
          bg
        ></awgt-std-dropdown>
      </div>
      <div class="ml-2 mr-3" style="min-width: 60px">Description</div>
      <mdb-wysiwyg
        label="Description"
        v-model="issueLog.Description"
        class="mx-2"
        style="height: 200px"
      ></mdb-wysiwyg>
      <div class="my-2 float-right">
        <awgt-std-button
          type="submit"
          title="Confirm"
          class="command-button mx-2"
          :disabled="!isFormDirty()"
        >
          <mdb-icon class="mr-1" icon="check" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          title="Cancel"
          class="command-button mx-2"
          v-on:click="close()"
        >
          <mdb-icon class="mr-1" icon="times" />Close
        </awgt-std-button>
      </div>
    </form>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import { mdbIcon, mdbContainer } from "mdbvue";
import mdbWysiwyg from "@/components/mdbWysiwyg";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import awgtInput from "@/components/AWGT/AwgtInput.vue";
import issueApi from "@/api/systemIssueApi.js";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  props: {
    onSavePromptExit: {
      type: Function,
    },
  },
  data() {
    return {
      formSubmissionErrors: [],
      issueLog: {},
      saved: false,
      toggle: true,
      issueLogSnapshot: {},
      isIssuelogDirtyInd: false,
    };
  },
  components: {
    mdbWysiwyg,
    AwgtStdDropdown,
    AwgtStdButton,
    mdbIcon,
    mdbContainer,
    awgtInput,
  },
  computed: {
    ...mapGetters(["getEmptyIssue", "getIssueStatuses"]),
  },
  methods: {
    close() {
      this.$router.push({
        path: "/admin/issue",
      });
      this.$emit("header-message", { isSuccessInd: false, Message: [] });
    },
    validateForm() {
      this.formSubmissionErrors = [];
      if (this.issueLog.Title.length == 0)
        this.formSubmissionErrors.push("The title is required.");
      if (this.issueLog.Title.length != 0 && this.issueLog.Title.length > 50)
        this.formSubmissionErrors.push(
          "The title should be between 1 to 50 lengths."
        );
      if (this.issueLog.Description.length == 0)
        this.formSubmissionErrors.push("The description is required.");

      return this.formSubmissionErrors.length == 0;
    },
    async loadFormData() {
      let routeParams = this.$route.params;
      this.$log.info("RouteParams: %s", routeParams);
      let issueReference = routeParams.issueReference;

      let obj = null;

      if (issueReference == null) {
        obj = this.$lodash.cloneDeep(this.getEmptyIssue);
      } else {
        obj = await issueApi.getIssueByReference(issueReference);
      }
      this.issueLog = this.$lodash.cloneDeep(obj);
    },

    async onSubmit() {
      this.formSubmissionErrors = [];
      this.saved = false;
      if (this.validateForm() == true) {
        await this.saveIssue(this.issueLog)
          .then(() => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: [`The issue ${this.issueLog.Title} has been saved.`],
            });
            this.snapshotIssueLog(this.issueLog);
          })
          .catch(() => {
            // Errors have been logged in the systemIssueApi.js
          });
        this.saved = true;
      } else {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: this.formSubmissionErrors,
        });
      }
    },
    async saveIssue(issueLog) {
      let isFormDirty = this.isFormDirty();

      if (isFormDirty == true) {
        if (issueLog.Reference == "")
          this.issueLog = await issueApi.postIssue(issueLog);
        else this.issueLog = await issueApi.putIssue(issueLog);
      } else this.$log.info("System issue is not dirty so was not saved.");
    },
    snapshotIssueLog(issueLog) {
      this.issueLogSnapshot = this.$lodash.cloneDeep(issueLog);

      this.isIssuelogDirtyInd = false;
    },
    isFormDirty() {
      if (this.isIssuelogDirtyInd == true) return true;

      this.isIssuelogDirtyInd =
        this.$lodash.isEqual(this.issueLog, this.issueLogSnapshot) == false;
      return this.isIssuelogDirtyInd;
    },
  },
  mounted() {
    this.loadFormData()
      .then(() => {
        this.snapshotIssueLog(this.issueLog);
      })
      .catch(() => {
        // Errors have been logged
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty() == true) {
      this.onSavePromptExit().then((result) => {
        if (result == "Exit") next();
        else if (result == "Stay") next(false);
        else {
          this.onSubmit().then(() => {
            next();
          });
        }
      });
    } else next();
  },
};
</script>
<style scoped>
.disableButton {
  cursor: not-allowed;
}
</style>
