var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("div", {
        staticStyle: { top: "50px", position: "sticky", "z-index": "1" },
      }),
      _c("h1", { staticClass: "text-center" }, [_vm._v("Client Edit")]),
      _c(
        "mdb-form-inline",
        { staticClass: "flex-wrap border mx-2 px-3 py-3 mb-2" },
        [
          _c(
            "span",
            { staticClass: "mr-5", staticStyle: { "min-width": "60px" } },
            [_vm._v("Client Type")]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap min-width:120px;" },
            [
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_School",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "School",
                  radioValue: "CT_S",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_Individual",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "Individual",
                  radioValue: "CT_I",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
              _c("awgt-input", {
                staticClass: "mx-2",
                staticStyle: { "min-width": "60px" },
                attrs: {
                  id: "clientType_Organisation",
                  type: "radio",
                  name: "clientTypeGroup",
                  label: "Organisation",
                  radioValue: "CT_O",
                },
                model: {
                  value: _vm.client.ClientType,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ClientType", $$v)
                  },
                  expression: "client.ClientType",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.client.ClientType == "CT_O" || _vm.client.ClientType == "CT_S"
        ? _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: {
                  icon: _vm.client.ClientType == "CT_O" ? "building" : "school",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                attrs: {
                  id: "client-name",
                  counter: "",
                  counterMaxValue: 200,
                  maxlength: "200",
                  label: "School Name",
                  bg: "",
                },
                model: {
                  value: _vm.client.Name,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "Name", $$v)
                  },
                  expression: "client.Name",
                },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                attrs: {
                  id: "client-abn",
                  counter: "",
                  counterMaxValue: 11,
                  maxlength: "11",
                  label: "ABN",
                  bg: "",
                },
                model: {
                  value: _vm.client.ABN,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "ABN", $$v)
                  },
                  expression: "client.ABN",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: { icon: "user", color: "primary", size: "2x" },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "300px" },
                attrs: {
                  id: "client-givennames",
                  counter: "",
                  counterMaxValue: 60,
                  maxlength: "60",
                  label: "Given Names",
                  bg: "",
                },
                model: {
                  value: _vm.client.GivenNames,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "GivenNames", $$v)
                  },
                  expression: "client.GivenNames",
                },
              }),
              _c("awgt-input", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "300px" },
                attrs: {
                  id: "client-surname",
                  counter: "",
                  counterMaxValue: 40,
                  maxlength: "40",
                  label: "Surname",
                  bg: "",
                },
                model: {
                  value: _vm.client.Surname,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "Surname", $$v)
                  },
                  expression: "client.Surname",
                },
              }),
            ],
            1
          ),
      _c("asoftAddressesCapture", {
        attrs: { template: _vm.getSystemTemplateFromCode("STT_C_Ad") },
        model: {
          value: _vm.client.Addresses,
          callback: function ($$v) {
            _vm.$set(_vm.client, "Addresses", $$v)
          },
          expression: "client.Addresses",
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
        [
          _c("mdb-icon", {
            staticClass: "mx-2 mt-4",
            attrs: { icon: "phone", color: "primary", size: "2x" },
          }),
          _c("asoft-phone-input", {
            staticClass: "mx-2",
            staticStyle: { width: "180px" },
            attrs: {
              id: "client-phone",
              maxlength: "20",
              label: "School Phone",
              bg: "",
            },
            model: {
              value: _vm.client.Phone,
              callback: function ($$v) {
                _vm.$set(_vm.client, "Phone", $$v)
              },
              expression: "client.Phone",
            },
          }),
          _c("mdb-icon", {
            staticClass: "mx-2 mt-4",
            attrs: { icon: "at", color: "primary", size: "2x" },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "120px" },
            attrs: {
              id: "client-email",
              counterMaxValue: 254,
              maxlength: "254",
              counter: "",
              label: "School Email",
              bg: "",
            },
            model: {
              value: _vm.client.EmailAddress,
              callback: function ($$v) {
                _vm.$set(_vm.client, "EmailAddress", $$v)
              },
              expression: "client.EmailAddress",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "float-right mt-4" },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button", disabled: !_vm.isFormDirty() },
              on: {
                click: function ($event) {
                  return _vm.onSave()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "check" } }),
              _vm._v("Save "),
            ],
            1
          ),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onCancel()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "times" } }),
              _vm._v("Close "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }