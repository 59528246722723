<template>
  <div class="mx-3">
    <h1 class="text-center mb-3">Edit Order Product Line</h1>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
    >
      <mdb-icon
        :icon="getProductIcon(product.ProductType, product.ProductSubType)"
        size="2x"
        class="mr-2"
        color="primary"
        style="width: 36px"
      />
      <h5 class="my-0">
        {{ orderProduct.ProviderSupplierName }} - {{ orderProduct.Name }} -
        {{ currentProductLineChronology.Name }}
      </h5>
    </div>

    <div v-html="currentProductLineChronology.Description"></div>

    <div class="mt-3">
      <h5>Pricing Scales</h5>
      <div v-if="currentProductLineChronology.isIndicativePricingInd == true">
        <i
          >Prices displayed are indicative for
          {{ formatDate(orderProduct.PricedForDt) }}.</i
        >
      </div>
      <div v-else>
        <i
          >Prices displayed are actual expected prices for
          {{ formatDate(orderProduct.PricedForDt) }}.</i
        >
      </div>
      <table class="pricing-table">
        <tr class="pricing-table">
          <td
            class="pricing-table text-center"
            colspan="2"
            v-for="(
              pst, index
            ) of currentProductLineChronology.PricingScaleTypes"
            :key="index"
          >
            {{
              getPluralQuantityUnit(
                pst.QuantityUnitCd,
                currentProductLineChronology.PluralItemAlias
              )
            }}
          </td>
          <td colspan="3" class="pricing-table text-center">Pricing</td>
        </tr>
        <tr class="pricing-table">
          <template
            v-for="(ps, index) of currentProductLineChronology
              .ProductLinePrices[0].PricingScales"
          >
            <td class="pricing-table" :key="'min' + index">Min</td>
            <td class="pricing-table" :key="'max' + index">Max</td>
          </template>
          <td class="pricing-table">ExGST</td>
          <td class="pricing-table">GST</td>
          <td class="pricing-table">Price</td>
        </tr>

        <tr
          v-for="(plp, index) of currentProductLineChronology.ProductLinePrices"
          :key="index"
        >
          <template v-for="(ps, index2) of plp.PricingScales">
            <td class="pricing-table" :key="'min' + index2">
              {{ ps.MinQuantity }}
            </td>
            <td class="pricing-table" :key="'max' + index2">
              {{ ps.MaxQuantity }}
            </td>
          </template>
          <td class="pricing-table">
            <span v-html="formatPricingString(plp.ExGSTUnitAmt)"></span>
          </td>
          <td class="pricing-table">
            <span v-html="formatPricingString(plp.GSTUnitAmt)"></span>
          </td>
          <td class="pricing-table">
            <span
              v-html="formatPricingString(plp.GSTUnitAmt + plp.ExGSTUnitAmt)"
            ></span>
          </td>
        </tr>
      </table>
    </div>

    <div class="mt-3">
      <h5>Market Categories</h5>
      This product line will be distributed across the following market
      categories:
      <div
        v-if="currentProductLineChronology.MarketCategories.length == 0"
        class="text-center"
      >
        <i>This product line has no market categories defined.</i>
      </div>
      <div
        v-for="(
          marketCategory, index
        ) in workingOrderProductLine.OrderProductLineMarketCategories"
        :key="index"
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        {{ marketCategory.Name }}
        <awgt-input
          v-model.number="marketCategory.ConsumerNum"
          counter
          :counterMaxValue="5"
          maxlength="5"
          label="Quantity"
          bg
          type="text"
          class="ml-2"
          style="width: 80px"
          v-numeric="{
            precision: 3,
            scale: 0,
            posOnly: true,
            trailingZeroScale: 0,
          }"
        />
      </div>
    </div>

    <div class="mt-3">
      <h5>This Purchase</h5>
      <div>
        Minimum Total Price:
        <span
          v-html="
            formatPricingString(currentProductLineChronology.MinTotalPriceAmt)
          "
        ></span>
      </div>

      <!--
        If the item is priced by quote then collect the quote price
      -->
      <div v-if="currentProductLineChronology.PriceTypeCd == 'PrT_Q'">
        This product line is priced by quote. Please provided the quoted price:

        <div class="d-flex flex-row flex-wrap flex-grow-1">
          <span style="width: 100px">Price Type:</span>
          <awgt-input
            id="price_GSTIn"
            v-model="quotedPriceTypeCd"
            type="radio"
            name="PriceTypeGroup"
            label="GST Incl."
            radioValue="PrT_I"
          />
          <awgt-input
            id="price_GSTEx"
            v-model="quotedPriceTypeCd"
            type="radio"
            name="PriceTypeGroup"
            label="GST Excl."
            radioValue="PrT_E"
          />
        </div>

        <!-- Behave like an including GST control -->
        <price-breakdown-control
          class="d-flex flex-row flex-nowrap flex-grow-1 ml-2 align-items-center"
          :PriceTypeCd="quotedPriceTypeCd"
          :ExGSTUnitAmt="0"
          :GSTUnitAmt="0"
          @change="quotedPriceChange"
        />
      </div>

      <div>
        {{ getProductsSoldByStatement() }}
      </div>
      <table class="pricing-table">
        <tr
          class="pricing-table"
          colspan="2"
          v-for="(ps, index) of currentProductLineChronology
            .ProductLinePrices[0].PricingScales"
          :key="index"
        >
          <td class="pricing-table">
            # of
            {{
              getPluralQuantityUnit(
                ps.QuantityUnitCd,
                currentProductLineChronology.PluralItemAlias
              )
            }}
          </td>
          <td colspan="3" class="pricing-table py-0">
            <awgt-input
              v-model.number="
                workingOrderProductLine.OrderProductLineQuantities[index]
                  .Quantity
              "
              maxlength="4"
              label="#"
              bg
              class="flex-grow-1 ml-2"
              type="text"
              v-numeric="{
                precision: 4,
                scale: 0,
                posOnly: true,
                allowEmptyString: true,
              }"
            ></awgt-input>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">Ex-GST Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.exGST)"></span>
            </template>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">GST Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.gst)"></span>
            </template>
          </td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">Total Price</td>
          <td class="pricing-table">
            <template v-if="CalculatedPrice == null"
              ><i style="color: red">Price not found</i></template
            >
            <template v-else>
              <span v-html="formatPricingString(CalculatedPrice.total)"></span>
              <tdoxTooltip
                v-if="CalculatedPrice.comment.length > 0"
                :detail="CalculatedPrice.comment"
              />
            </template>
          </td>
        </tr>
      </table>
    </div>

    <!-- Adjustments don't apply for Free of Charge or By Quote items -->
    <div
      v-if="
        currentProductLineChronology.PriceTypeCd != 'PrT_Z' &&
        currentProductLineChronology.PriceTypeCd != 'PrT_Q'
      "
    >
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3 mb-n2"
      >
        <h5>Price Adjustments</h5>
        <awgt-std-button
          type="button"
          class="command-button ml-auto"
          style="width: 120px; padding-left: 0; padding-right: 0"
          @click="onOpenPriceAdjustmentsDialog"
        >
          <mdb-icon icon="dollar" class="mr-1" />Adjust Price
        </awgt-std-button>
      </div>
      <div>
        The following price adjustments will be applied to this product line:
      </div>
      <div
        v-if="
          workingOrderProductLine.OrderProductLinePriceAdjustments.length == 0
        "
        class="text-center"
      >
        <i>Price adjustments are yet to be applied to this product line.</i>
      </div>
      <div v-else>
        <table class="pricing-table">
          <tr class="pricing-table">
            <td class="pricing-table">Name</td>
            <td class="pricing-table">Adjustment Type</td>
            <td class="pricing-table">Change Type</td>
            <td class="pricing-table">Amount</td>
          </tr>
          <tr
            v-for="(pa, index) of $lodash.orderBy(
              workingOrderProductLine.OrderProductLinePriceAdjustments,
              'CalculationOrder'
            )"
            :key="index"
            class="pricing-table"
          >
            <td class="pricing-table">{{ pa.Name }}</td>
            <td class="pricing-table">
              {{ getPriceAdjustmentTypeNameByCode(pa.PriceAdjustmentTypeCd) }}
            </td>
            <td class="pricing-table">
              {{ getPriceChangeTypeNameByCode(pa.PriceChangeTypeCd) }}
            </td>
            <td class="pricing-table">
              {{
                pa.PriceChangeTypeCd == "PCT_Pe"
                  ? pa.Amount + "%"
                  : formatPricingString(pa.Amount)
              }}
            </td>
          </tr>
        </table>

        <div class="mt-2">
          Adjusted Pricing
          <table class="pricing-table">
            <tr class="pricing-table">
              <td class="pricing-table">Ex-GST Price</td>
              <td class="pricing-table">
                <template v-if="CalculatedPrice == null"
                  ><i style="color: red">Price not found</i></template
                >
                <template v-else>
                  <span
                    v-html="formatPricingString(CalculatedPrice.adjExGST)"
                  ></span>
                </template>
              </td>
            </tr>
            <tr class="pricing-table">
              <td class="pricing-table">GST Price</td>
              <td class="pricing-table">
                <template v-if="CalculatedPrice == null"
                  ><i style="color: red">Price not found</i></template
                >
                <template v-else>
                  <span
                    v-html="formatPricingString(CalculatedPrice.adjGST)"
                  ></span>
                </template>
              </td>
            </tr>
            <tr class="pricing-table">
              <td class="pricing-table">Total Price</td>
              <td class="pricing-table">
                <template v-if="CalculatedPrice == null"
                  ><i style="color: red">Price not found</i></template
                >
                <template v-else>
                  <span
                    v-html="formatPricingString(CalculatedPrice.adjTotal)"
                  ></span>
                </template>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="section-break" style="margin-bottom: 0.8rem"></div>

    <div class="float-right">
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onOK"
        :disabled="!isFormDirty()"
      >
        <mdb-icon icon="check" class="mr-1" />OK
      </awgt-std-button>
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        style="width: 120px; padding-left: 0; padding-right: 0"
        @click="onCancel"
      >
        <mdb-icon icon="times" class="mr-1" />Cancel
      </awgt-std-button>
    </div>

    <mdb-modal
      size="lg"
      v-if="showPriceAdjustmentsDialog"
      :show="showPriceAdjustmentsDialog"
    >
      <price-adjustments-dialog
        v-if="showPriceAdjustmentsDialog == true"
        v-model="workingOrderProductLine.OrderProductLinePriceAdjustments"
        :allowedPriceAdjustments="
          currentProductLineChronology.CommercialProductLinePriceAdjustments
        "
        :keepDollarOnItemInd="PricingScalesIncludesItems"
        @close="onPriceAdjustmentsDialogClose"
        :visible="showPriceAdjustmentsDialog"
      />
    </mdb-modal>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
.md-form label {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.command-button {
  min-width: 100px;
  width: 100px;
}

@media screen and (max-width: 800px) {
  .command-button {
    min-width: 70px;
    width: 70px;
  }
}
</style>
<script>
import { mdbIcon, mdbModal } from "mdbvue";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtStdButton from "@/components/AWGT/AwgtStdButton";
import { sharedMethods } from "@/shared/shared";
import awgtInput from "@/components/AWGT/AwgtInput";
import priceAdjustmentsDialog from "./priceAdjustmentsDialog";
import tdoxTooltip from "@/components/Tourdox/tdoxTooltip.vue";
import { mapMutations } from "vuex/dist/vuex.common.js";
import priceBreakdownControl from "@/components/priceBreakdownControl.vue";

export default {
  components: {
    mdbIcon,
    awgtStdButton,
    awgtInput,
    mdbModal,
    priceAdjustmentsDialog,
    tdoxTooltip,
    priceBreakdownControl,
  },

  data() {
    return {
      workingOrderProductLine: null,
      currentProductLine: null,
      orderProductLineSnapshot: null,
      currentProductLineChronology: null,
      showPriceAdjustmentsDialog: false,
      independentTotalPrice: 0,
      orderProduct: {},
      activeOrderProductLine: {},
      quotedPriceTypeCd: "PrT_I",
      quotedExGSTUnitAmt: 0,
      quotedGSTUnitAmt: 0,
    };
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getOrderStatuses",
      "getQuantityUnitNameByCode",
      "order/getEmptyOrderProductLineQuantity",
      "getPriceAdjustmentTypeNameByCode",
      "getPriceChangeTypeNameByCode",
      "order/getActiveOrderProductLine",
      "order/getActiveOrderProductLinePrice",
    ]),

    CalculatedPrice: function () {
      if (this.currentProductLineChronology.PriceTypeCd == "PrT_Q") {
        return {
          exGST:
            this.quotedExGSTUnitAmt *
            this.workingOrderProductLine.OrderProductLineQuantities[0].Quantity,
          gst:
            this.quotedGSTUnitAmt *
            this.workingOrderProductLine.OrderProductLineQuantities[0].Quantity,
          total:
            (this.quotedExGSTUnitAmt + this.quotedGSTUnitAmt) *
            this.workingOrderProductLine.OrderProductLineQuantities[0].Quantity,
          adjExGST: 0,
          adjGST: 0,
          adjTotal: 0,
          comment: "",
        };
      } else {
        this.$store.commit(
          "order/mutStoreOrderProductLine",
          {
            orderProductIdx: this.orderProduct.OrderProductIdx,
            orderProductLine: this.workingOrderProductLine,
          },
          { root: true }
        );

        return this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProductLinePrice"](
            this.currentProductLineChronology,
            this.orderProductIdx,
            this.orderProductLineIdx
          )
        );
      }
    },

    PricingScalesIncludesItems: function () {
      return (
        this.currentProductLineChronology.ProductLinePrices[0].PricingScales.findIndex(
          (ps) => ps.QuantityUnitCd == "QU_I"
        ) != -1
      );
    },
  },

  props: {
    orderProductIdx: {
      type: Number,
    },

    orderProductLineIdx: {
      type: Number,
    },

    product: {
      type: Object,
    },

    booking: {
      type: Object,
    },
  },

  watch: {
    orderProductLineIdx: {
      deep: false,
      immediate: true,
      handler(to) {
        this.orderProduct = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProduct"](
            this.orderProductIdx
          )
        );
        //The original order product line is saved incase it needs
        //to be restored on Cancel.
        this.activeOrderProductLine = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProductLine"](
            this.orderProductIdx,
            to
          )
        );
        this.workingOrderProductLine = this.$lodash.cloneDeep(
          this.activeOrderProductLine
        );
        //Find the current product line chronology
        this.currentProductLine = this.product.CommercialProductLines.find(
          (pl) => pl.Reference == this.workingOrderProductLine.Reference
        );
        this.currentProductLineChronology =
          this.currentProductLine.Chronology.find(
            (c) =>
              new Date(c.ActiveFromDt) <=
                new Date(this.orderProduct.PricedForDt) &&
              (c.ActiveToDt == null ||
                new Date(c.ActiveToDt) >=
                  new Date(this.orderProduct.PricedForDt))
          );
        //Add Order Product Line Quantity records if they don't exist
        if (
          this.workingOrderProductLine.OrderProductLineQuantities.length == 0
        ) {
          for (let pst of this.currentProductLineChronology.PricingScaleTypes) {
            let newOrderProductLineQuantity = this.$lodash.cloneDeep(
              this.$store.getters["order/getEmptyOrderProductLineQuantity"]
            );
            newOrderProductLineQuantity.QuantityUnitCd = pst.QuantityUnitCd;
            if (newOrderProductLineQuantity.QuantityUnitCd == "QU_I") {
              newOrderProductLineQuantity.SingularItemAlias =
                this.currentProductLineChronology.SingularItemAlias;
              newOrderProductLineQuantity.PluralItemAlias =
                this.currentProductLineChronology.PluralItemAlias;
            }
            this.workingOrderProductLine.OrderProductLineQuantities.push(
              newOrderProductLineQuantity
            );
          }
        }

        //Add Market Category records if they don't exist
        if (
          this.workingOrderProductLine.OrderProductLineMarketCategories
            .length == 0
        ) {
          for (let mc of this.currentProductLineChronology.MarketCategories) {
            if (mc.ConsumerNum === undefined) {
              if (mc.Name == "Adult") {
                mc.ConsumerNum = this.booking.AdultsCount;
              }
              if (mc.Name == "Student") {
                mc.ConsumerNum = this.booking.StudentsCount;
              }
              this.workingOrderProductLine.OrderProductLineMarketCategories.push(
                mc
              );
            }
          }
        }

        // Default any person quantities in the order with the total number of people
        // across this product line's market categories.
        for (let oplq of this.workingOrderProductLine
          .OrderProductLineQuantities) {
          if (
            oplq.QuantityUnitCd == "QU_P" &&
            (oplq.Quantity === undefined || oplq.Quantity == 0)
          ) {
            let consumerNum = 0;
            for (let mc of this.currentProductLineChronology.MarketCategories)
              consumerNum += mc.ConsumerNum;

            oplq.Quantity = consumerNum;
          }
        }
      },
    },
  },

  methods: {
    ...sharedMethods,
    ...mapMutations([
      "order/mutStoreOrderProductLine",
      "order/mutDeleteOrderProductLine",
    ]),

    quotedPriceChange(price) {
      this.quotedExGSTUnitAmt = price.GSTUnitAmt;
      this.quotedGSTUnitAmt = price.ExGSTUnitAmt;

      if (
        this.workingOrderProductLine.OrderProductLinePriceAdjustments.length ==
        0
      ) {
        this.workingOrderProductLine.OrderProductLinePriceAdjustments.push({
          Name: "Quoted Price",
          PriceAdjustmentTypeCd: "PAT_P", //New Price
          PriceChangeTypeCd: "PCT_DT", //Dollar on total
          Amount: price.GSTUnitAmt + price.ExGSTUnitAmt,
          Comment: "",
          CalculationOrder: 0,
        });
      } else {
        this.workingOrderProductLine.OrderProductLinePriceAdjustments[0].Amount =
          price.GSTUnitAmt + price.ExGSTUnitAmt;
      }
    },

    isFormDirty() {
      return true;
    },

    getProductsSoldByStatement() {
      let productsAreSoldBy = "";
      let pricingIsScaledBy = "";
      let statement = "";

      for (let pricingScaleType of this.currentProductLineChronology
        .PricingScaleTypes) {
        if (pricingScaleType.QuantityUnitPurposeCd == "QUP_P")
          //Priced Per
          productsAreSoldBy +=
            this.getPluralQuantityUnit(
              pricingScaleType.QuantityUnitCd,
              this.currentProductLineChronology.PluralItemAlias
            ) + ", ";

        if (pricingScaleType.PricingScaleCd != "PS_U")
          //Unscaled
          pricingIsScaledBy +=
            this.getPluralQuantityUnit(
              pricingScaleType.QuantityUnitCd,
              this.currentProductLineChronology.PluralItemAlias
            ) + ", ";
      }
      if (productsAreSoldBy.length >= 2) {
        productsAreSoldBy = productsAreSoldBy.substring(
          0,
          productsAreSoldBy.length - 2
        );
        statement =
          "Products are sold by the number of " + productsAreSoldBy + ". ";
      }
      if (pricingIsScaledBy.length >= 2) {
        pricingIsScaledBy = pricingIsScaledBy.substring(
          0,
          pricingIsScaledBy.length - 2
        );
        statement +=
          "Pricing is scaled by the number of " + pricingIsScaledBy + ".";
      }

      return statement;
    },

    onOpenPriceAdjustmentsDialog() {
      this.showPriceAdjustmentsDialog = true;
    },

    onPriceAdjustmentsDialogClose() {
      this.showPriceAdjustmentsDialog = false;
    },

    onOK() {
      //Update the order product line to the VueX store.
      this.$store.commit(
        "order/mutStoreOrderProductLine",
        {
          orderProductIdx: this.orderProduct.OrderProductIdx,
          orderProductLine: this.workingOrderProductLine,
        },
        { root: true }
      );

      //Now that the order product line has been stored, clear the
      //record status. If you don't clear the record status then
      //later editing of the line will identify the line as newly
      //inserted and a cancel of the edit will remove the line.
      this.workingOrderProductLine.RecordStatus = "Unknown";

      this.$emit("close");
    },

    onCancel() {
      //If the working order product line has a RecordStatus of Inserted
      //then a order product line has been added and needs to be
      //removed. Otherwise replace the WorkingOrderProductLine
      //with the activeOrderProductLine it was cloned from.

      if (this.workingOrderProductLine.RecordStatus == "Inserted") {
        this.$store.commit(
          "order/mutDeleteOrderProductLine",
          {
            orderProductIdx: this.orderProduct.OrderProductIdx,
            orderProductLineIdx: this.orderProductLineIdx,
          },
          { root: true }
        );
      } else {
        this.$store.commit(
          "order/mutStoreOrderProductLine",
          {
            orderProductIdx: this.orderProduct.OrderProductIdx,
            orderProductLine: this.activeOrderProductLine,
          },
          { root: true }
        );
      }

      this.$emit("close");
    },
  },
};
</script>
