<template>
  <mdb-container>
    <mdb-modal v-model="visible" :show="visible" size="sm">
      <mdb-card>
        <h3 class="py-1 pl-2 header primary-color">{{ title }}</h3>
        <hr class="my-0" />
        <div
          id="timeList"
          size="8"
          style="height: 200px; overflow-y: scroll; display: block"
        >
          <div
            v-for="(time, i) in timeArray"
            :key="i"
            class="my-1 mx-2 timeListItem"
            @click="onSelect(i)"
          >
            <span>
              {{ time.displayTime }}
            </span>
            <i
              size="md"
              class="fas fa-times-circle deleteIcon"
              @click="onDelete(i)"
            ></i>
          </div>
        </div>

        <!--
            The control changes the display data into dataTime,
            this is why v-model takes the dataTime.
        -->
        <div style="position: relative">
          <mdb-input
            label="Start Time"
            v-model="tmpTime.dataTime"
            class="mx-2 mt-4 mb-4"
            maxlength="4"
            type="time"
            @keyup.enter.native="addButtonOptions[0].callback"
          />
          <span class="errormessage ml-2">{{ errormessage }}</span>

          <div class="d-flex justify-content-end align-center mb-2">
            <split-button :items="addButtonOptions"></split-button>
            <awgt-std-button
              type="button"
              @click="onClose"
              class="command-button mx-2"
            >
              Close
            </awgt-std-button>
          </div>
        </div>
      </mdb-card>
    </mdb-modal>
  </mdb-container>
</template>

<style lang="scss">
@import "../styles/common.scss";

.timeListItem:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.deleteIcon {
  color: primary;
  margin-right: 10px;
  padding: 4px 7px;
  cursor: pointer;
  position: relative;
  float: right;
}

.header {
  color: white;
  font-weight: bold;
}

.errormessage {
  color: darkred;
  position: absolute;
  top: 68px;
  font-size: 12px;
}
</style>

<script>
import { mdbContainer, mdbModal, mdbInput, mdbCard } from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

import splitButton from "../components/SplitButton";

export default {
  components: {
    mdbContainer,
    AwgtStdButton,
    mdbModal,
    mdbInput,
    mdbCard,
    splitButton,
  },
  data() {
    return {
      visible: false,
      tmpTime: new Time(this.displayTimeFormat, this.dataTimeFormat),
      addButtonOption: 0,
      errormessage: "",
      timeArray: [],
      addButtonOptions: [
        {
          text: "Add",
          callback: () => this.addTime(0),
        },
        {
          text: "Add 5",
          callback: () => this.addTime(5),
        },
        {
          text: "Add 10",
          callback: () => this.addTime(10),
        },
        {
          text: "Add 15",
          callback: () => this.addTime(15),
        },
        {
          text: "Add 20",
          callback: () => this.addTime(20),
        },
        {
          text: "Add 30",
          callback: () => this.addTime(30),
        },
        {
          text: "Add 45",
          callback: () => this.addTime(45),
        },
        {
          text: "Add 60",
          callback: () => this.addTime(60),
        },
        {
          text: "Add 75",
          callback: () => this.addTime(75),
        },
        {
          text: "Add 90",
          callback: () => this.addTime(90),
        },
      ],
    };
  },
  props: {
    title: { type: String, required: true },
    value: { type: Array, required: true },
    dialogVisible: { type: Boolean, default: false },
    displayTimeFormat: { type: String, default: "h:mma" },
    dataTimeFormat: { type: String, default: "HH:mm" },
  },
  methods: {
    addTime(t) {
      if (this.tmpTime.dataTime.length > 0) {
        let timeObj = new Time(this.displayTimeFormat, this.dataTimeFormat);
        timeObj.dataTime = this.$moment(
          this.tmpTime.dataTime,
          this.dataTimeFormat,
          false
        )
          .add(t, "minutes")
          .format(this.dataTimeFormat);

        this.tmpTime = this.$lodash.cloneDeep(timeObj);
        if (
          this.timeArray.find((t) => t.dataTime == timeObj.dataTime) ===
          undefined
        ) {
          //If the time is not already in the list then add it
          this.timeArray.push(timeObj);
          //TODO: More efficient to insert not sort.
          this.timeArray.sort(
            (a, b) =>
              this.$moment(a.dataTime, this.dataTimeFormat, false) -
              this.$moment(b.dataTime, this.dataTimeFormat, false)
          );
        } else {
          //If the time is in the list then display an error.
          this.errormessage = "This time is already in the list.";
          setTimeout(() => {
            this.errormessage = "";
          }, 2500);
        }

        //Scroll to the bottom
        this.$nextTick(() => {
          let timeList = document.getElementById("timeList");
          timeList.scrollTop = timeList.scrollHeight - timeList.clientHeight;
        });
      }
    },

    onSelect(i) {
      if (this.timeArray[i]) this.tmpTime = this.timeArray[i];
      else this.tmpTime = new Time(this.displayTimeFormat, this.dataTimeFormat);
    },

    onDelete(i) {
      this.timeArray.splice(i, 1);
      this.tmpTime = new Time(this.displayTimeFormat, this.dataTimeFormat);
    },

    onClose() {
      //Now flatten the time array to provide an array
      //of data times.
      let dataArray = this.timeArray.map((x) => x.dataTime);
      this.$emit("input", dataArray);
      //Send the complete timeArray out throught the output event.
      this.$emit("output", this.timeArray);
      this.visible = false;
      this.$emit("close");
    },
  },
  watch: {
    dialogVisible(to) {
      this.visible = to;
    },
    value: {
      deep: true,
      handler(to, from) {
        if (to != from) {
          this.timeArray.length = 0;
          for (let datatime of to) {
            let timeObj = new Time(this.displayTimeFormat, this.dataTimeFormat);
            timeObj.dataTime = this.$moment(
              datatime,
              this.dataTimeFormat,
              false
            ).format(this.dataTimeFormat);
            this.timeArray.push(timeObj);
          }
        }
      },
    },
  },
};

class Time {
  constructor(displayTimeFormat, dataTimeFormat) {
    this.moment = require("moment");
    this._displayTimeFormat = displayTimeFormat;
    this._dataTimeFormat = dataTimeFormat;
    this._dataTime = "";
    this._displayTime = "";
  }

  get dataTime() {
    return this._dataTime;
  }

  set dataTime(time) {
    this._dataTime = time;
    this._displayTime = this.moment(
      this._dataTime,
      this._dataTimeFormat,
      false
    ).format(this._displayTimeFormat);
  }

  get displayTime() {
    return this._displayTime;
  }

  set displayTime(time) {
    this._displayTime = time;
    this._dataTime = this.moment(
      this._displayTime,
      this._displayTimeFormat,
      false
    ).format(this._dataTimeFormat);
  }
}
</script>
