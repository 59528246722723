var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mdb-wysiwyg-container" },
    [
      _c("mdb-wysiwyg-toolbar", {
        attrs: {
          commandState: _vm.commandState,
          textState: _vm.textState,
          colors: _vm.colors,
          translations: _vm.mdbTranslations,
          tooltips: _vm.tooltips,
        },
        on: { "change-view": _vm.changeview },
      }),
      _c(
        "mdb-wysiwyg-textarea",
        {
          attrs: { activeView: _vm.activeView, value: _vm.value },
          on: {
            state: _vm.updateState,
            "text-state": _vm.updateTextState,
            input: function ($event) {
              return _vm.contentModified($event)
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }