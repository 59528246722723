var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: [{ ontop: _vm.onTop == true }] },
      [
        _c(
          "transition",
          {
            attrs: { name: "expansionregion" },
            on: {
              enter: _vm.onEnter,
              "after-enter": _vm.onAfterEnter,
              leave: _vm.onLeave,
            },
          },
          [
            _vm.useVShowConditional == true
              ? [
                  _c(
                    _vm.tag,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.toggle,
                          expression: "toggle",
                        },
                      ],
                      tag: "component",
                    },
                    [_vm._t("default")],
                    2
                  ),
                ]
              : [
                  _vm.toggle
                    ? _c(_vm.tag, { tag: "component" }, [_vm._t("default")], 2)
                    : _vm._e(),
                ],
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }