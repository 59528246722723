var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "mx-2" },
        [
          _c("h1", { staticClass: "text-center" }, [_vm._v("Itinerary List")]),
          _c("p", [
            _vm._v(
              " Showing itineraries for booking " +
                _vm._s(
                  _vm.booking == null ? "loading..." : _vm.booking.Reference
                ) +
                " for client " +
                _vm._s(
                  _vm.booking == null || _vm.booking.Client == null
                    ? "loading..."
                    : _vm.booking.Client.DisplayName
                ) +
                ". "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _vm.checkPermission("CP_ItC") &&
              _vm.checkPermissionScopeForItineraryCreate
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2",
                      attrs: { type: "button", title: "Add" },
                      on: {
                        click: function ($event) {
                          return _vm.onAdd()
                        },
                      },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticStyle: { clear: "both" } }),
          _vm.itineraries == null || _vm.itineraries.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(
                  " There are currently no itineraries for this booking. Press Add to create the first itinerary. "
                ),
              ])
            : _vm._l(_vm.itineraries, function (itinerary, index) {
                return _c(
                  "mdb-card",
                  { key: index, staticClass: "mb-3 mx-2" },
                  [
                    _c(
                      "mdb-card-header",
                      {
                        attrs: { tag: "button" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onToggleItineraryDetailExpansion(index)
                          },
                        },
                      },
                      [
                        itinerary.pdf
                          ? [
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _vm.itineraryExpansion[index] == true
                                    ? _c("mdb-icon", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          icon: "angle-up",
                                          size: "2x",
                                          color: "primary",
                                        },
                                      })
                                    : _c("mdb-icon", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          icon: "angle-down",
                                          size: "2x",
                                          color: "primary",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        itinerary.IsActive == true
                          ? _c(
                              "span",
                              { staticClass: "float-left" },
                              [
                                _c("tdoxTooltip", {
                                  staticClass: "mt-2 mr-auto",
                                  attrs: {
                                    iconName: "star",
                                    iconColor: "gold",
                                    iconSize: "3x",
                                    detail: "Active Itinerary",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("h4", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(itinerary.Name)),
                        ]),
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "Description: " + _vm._s(itinerary.Description)
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              " Start Date: " +
                                _vm._s(
                                  _vm
                                    .$moment(
                                      itinerary.StartDt,
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )
                                    .format("D MMM YYYY")
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "Duration: " +
                                _vm._s(itinerary.DurationInDays) +
                                " days"
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2 float-right" },
                          [
                            _vm.checkPermission("CP_ItC") &&
                            _vm.checkPermissionScopeForItineraryCreate
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: {
                                      type: "button",
                                      title: "Duplicate",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDuplicate(itinerary)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "copy" },
                                    }),
                                    _vm._v("Duplicate "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: {
                                  type: "button",
                                  title: "Products",
                                  disabled: itinerary.pdf != undefined,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGeneratePDF(itinerary, index)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "file-pdf" },
                                }),
                                _vm._v("Generate PDF "),
                              ],
                              1
                            ),
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button mx-2",
                                attrs: { type: "button", title: "Entries" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditEntries(itinerary)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "calendar-alt", far: "" },
                                }),
                                _vm._v("Entries "),
                              ],
                              1
                            ),
                            _vm.checkPermission("CP_ItU") &&
                            _vm.checkPermissionScopeForItineraryUpdate
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Edit" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(itinerary)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "edit" },
                                    }),
                                    _vm._v("Edit "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkPermission("CP_ItD") &&
                            _vm.checkPermissionScopeForItineraryDelete
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "command-button mx-2",
                                    attrs: { type: "button", title: "Delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDelete(itinerary)
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "trash-alt" },
                                    }),
                                    _vm._v("Delete "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "transition",
                      { attrs: { name: "slide" } },
                      [
                        _c(
                          "expansion-region",
                          { attrs: { toggle: _vm.itineraryExpansion[index] } },
                          [
                            itinerary.pdf
                              ? _c("mdb-card-body", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "100%",
                                        margin: "0 auto",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("pdf-preview", {
                                        attrs: {
                                          width: "100%",
                                          height: "700px",
                                          url: itinerary.pdf,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _c("yes-or-no-prompt", { ref: "handleItineraryPrompt" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }