<template>
  <div>
    <asoft-modal-header
      label="Add Products to this Order"
      @close="() => onCancel()"
    >
    </asoft-modal-header>
    <mdb-modal-body>
      <div>
        <mdb-container fluid>
          <div
            v-for="(product, index) in products"
            :key="index"
            class="mb-3 mx-2 d-flex flex-row"
            :style="product.IsActive ? 'opacity:1' : 'opacity:0.5'"
          >
            <awgt-input
              :id="'productSelectionRadiobutton' + index"
              type="radio"
              name="productSelectionRadiobutton"
              v-model="selectedProductReference"
              label=" "
              style="min-width: 50px"
              :radioValue="product.Reference"
            />
            <mdb-icon
              :icon="
                getProductIcon(product.ProductType, product.ProductSubType)
              "
              size="2x"
              class="mr-2"
              color="primary"
              style="width: 36px"
            />
            <div class="d-flex flex-column" style="text-align: left">
              <h5 class="my-0">{{ product.Name }}</h5>
            </div>
          </div>
        </mdb-container>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="float-right">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onSave"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onCancel"
        >
          <mdb-icon icon="times" class="mr-1" />Cancel
        </awgt-std-button>
      </div>
    </mdb-modal-footer>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style scoped></style>
<script>
import { mdbContainer, mdbIcon, mdbModalBody, mdbModalFooter } from "mdbvue";
import { mapGetters } from "vuex";
import productApi from "@/api/productApi.js";
import { sharedMethods } from "@/shared/shared";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import awgtInput from "@/components/AWGT/AwgtInput";
import asoftModalHeader from "@/components/AtomSoftware/asoftModalHeader.vue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    mdbIcon,
    mdbModalBody,
    mdbModalFooter,
    asoftModalHeader,
    awgtInput,
    AwgtStdButton,
    mdbContainer,
  },

  data() {
    return {
      products: [],
      selectedProductReference: "",
    };
  },

  props: {
    resellerSupplierName: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
  },

  watch: {
    resellerSupplierName: {
      immediate: true,
      handler: function (to) {
        this.searchResellerName = to;
      },
    },
    visible: {
      immediate: true,
      handler: function (to) {
        if (to == true) this.loadProducts();
      },
    },
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getProductMargins",
      "getItineraryEntryTypes",
      "getEmptyItineraryEntry",
      "getItineraryEntrySubTypesByItineraryEntryType",
      "getPrimaryOrderProductSubTypesForItineraryEntrySubType",
    ]),
  },
  methods: {
    ...sharedMethods,

    onCancel() {
      this.products = [];
      this.$emit("close", false);
    },

    onSave() {
      var selectedProduct = {}; //Only one product will be selected.
      selectedProduct = this.products.find(
        (p) => p.Reference == this.selectedProductReference
      );
      this.$emit("productSelected", selectedProduct);
      this.$emit("close", false);
    },

    loadProducts() {
      this.searchProducts().catch(() => {
        // Errors have been logged
      });
    },

    async searchProducts() {
      var ProductTypes = "PT_Tr,PT_An,PT_Ay,PT_Me";

      this.products = await productApi.getProductsList(
        null,
        ProductTypes,
        null,
        null,
        this.searchResellerName,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
        true,
        true,
        false,
        true,
        true
      );
    },
  },
};
</script>
