<template>
  <a v-bind="$attrs" v-on="$listeners" :style="{ color: color }">
    <slot />
  </a>
</template>

<script>
import variables from "@/styles/sharedVariables.scss";

export default {
  props: {
    color: { type: String, default: variables.awgtLinkTextColor },
  },
};
</script>
