var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Issue Log Search")]),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row flex-wrap flex-grow-1",
          staticStyle: { "margin-top": "40px" },
        },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              maxlength: "100",
              label: "Issue Name",
              bg: "",
            },
            model: {
              value: _vm.issueName,
              callback: function ($$v) {
                _vm.issueName = $$v
              },
              expression: "issueName",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              maxlength: "100",
              label: "Description",
              bg: "",
            },
            model: {
              value: _vm.issueDescription,
              callback: function ($$v) {
                _vm.issueDescription = $$v
              },
              expression: "issueDescription",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              label: "Issue Status",
              itemValueProperty: "Code",
              items: _vm.getIssueStatuses(true),
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.issueStatus,
              callback: function ($$v) {
                _vm.issueStatus = $$v
              },
              expression: "issueStatus",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row flex-wrap",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: {
                  icon: "calendar-alt",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("mdb-date-picker", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "140px" },
                attrs: {
                  label: "Start Date",
                  autoHide: "",
                  option: _vm.datePickerOptions,
                  bg: "",
                },
                model: {
                  value: _vm.dateStart,
                  callback: function ($$v) {
                    _vm.dateStart = $$v
                  },
                  expression: "dateStart",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
            [
              _c("mdb-icon", {
                staticClass: "mx-2 mt-4",
                attrs: {
                  icon: "calendar-alt",
                  far: "",
                  color: "primary",
                  size: "2x",
                },
              }),
              _c("mdb-date-picker", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { width: "180px" },
                attrs: {
                  label: "End Date",
                  autoHide: "",
                  option: _vm.datePickerOptions,
                  bg: "",
                },
                model: {
                  value: _vm.dateEnd,
                  callback: function ($$v) {
                    _vm.dateEnd = $$v
                  },
                  expression: "dateEnd",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "mdb-form-inline",
        { staticClass: "d-flex justify-content-end flex-grow-1" },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch()
                },
              },
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-1",
                attrs: { icon: "search" },
              }),
              _vm._v("Search "),
            ],
            1
          ),
          _vm.checkPermission("CP_IsC")
            ? _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onAdd()
                    },
                  },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "plus" },
                  }),
                  _vm._v("Add "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showControl,
              expression: "showControl",
            },
          ],
          staticClass: "mx-2",
        },
        [
          _vm._m(0),
          _vm._l(_vm.issues, function (issue, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: { border: "1px solid lightgrey" },
                on: {
                  click: function ($event) {
                    return _vm.onToggleUserDetailExpansion(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "d-flex flex-row rowstyle" }, [
                  _c(
                    "div",
                    {
                      staticStyle: { "font-size": "20px", width: "100%" },
                      attrs: { id: "titleID" },
                    },
                    [_vm._v(" " + _vm._s(issue.Title) + " ")]
                  ),
                  _c("div", { staticStyle: { width: "200px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getIssueStatusNameFromCode(
                            issue.SystemIssueStatus
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticStyle: { width: "200px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$moment(issue.RaisedDT, "YYYY-MM-DDTHH:mm:ss")
                            .format("D MMM YYYY h:mma")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c(
                  "expansion-region",
                  { attrs: { toggle: _vm.userDetailExpansion[index] } },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(issue.Description) },
                      }),
                    ]),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end flex-grow-1",
                        },
                        [
                          _vm.checkPermission("CP_IsU")
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Products" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(issue)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "edit" },
                                  }),
                                  _vm._v("Edit "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.checkPermission("CP_IsD")
                            ? _c(
                                "awgt-std-button",
                                {
                                  staticClass: "command-button mx-2",
                                  attrs: { type: "button", title: "Products" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDelete(issue)
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "trash-alt" },
                                  }),
                                  _vm._v("Delete "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-row justify-content-start" }, [
      _c("div", { staticStyle: { width: "100%" } }, [_vm._v("Title")]),
      _c("div", { staticStyle: { width: "200px" } }, [_vm._v("Status")]),
      _c("div", { staticStyle: { width: "200px" } }, [_vm._v("Raised Date")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }