<template>
  <div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mt-3"
    >
      <mdb-icon
        icon="clipboard"
        far
        color="primary"
        class="mx-2 mb-2"
        size="2x"
      />
      <div class="ml-2">Files</div>
    </div>
    <div>
      <div v-for="(file, index) in fileInfo" v-bind:key="index" class="mx-2">
        <template v-if="file.RecordStatus != 'Deleted'">
          <span v-if="file.RecordStatus == 'Inserted'" style="color: red">{{
            file.Name
          }}</span>
          <span v-else @click="onDownload(file)" class="file-link">{{
            file.Name
          }}</span
          ><span style="cursor: pointer" @click="onDeleteFile(index)"
            ><mdb-icon
              icon="times"
              far
              color="black"
              class="mx-2 mb-2"
              size="1x"
          /></span>
        </template>
      </div>
    </div>
    <div class="plus-icon float-right" style="margin-left: auto">
      <label :for="'file-input'">
        <mdb-icon
          icon="plus"
          far
          color="primary"
          class="mx-2 mb-2"
          size="7x"
          style="cursor: pointer"
        />
      </label>
      <input
        id="file-input"
        type="file"
        multiple
        @change="(event) => onFilesInput(event)"
      />
    </div>
    <div style="clear: both"></div>
  </div>
</template>
<script>
import { mdbIcon } from "mdbvue";
import fileDownloadApi from "@/api/fileDownloadApi.js";

export default {
  components: {
    mdbIcon,
  },
  data() {
    return {
      files: [],
      fileInfo: [],
    };
  },
  props: {
    value: {
      type: Array,
    },
  },
  watch: {
    value(to) {
      if (to != null || to.length > 0) {
        this.fileInfo = to;
      }
    },
    fileInfo: {
      deep: true,
      handler(to) {
        this.$emit("input", to);
        this.$emit("on-files-input", this.files);
      },
    },
  },
  methods: {
    onFilesInput(event) {
      let fileEntries = event.target.files;
      let errors = [];
      for (var i = 0; i < fileEntries.length; i++) {
        if (fileEntries[i].size > 10485760) {
          errors.push(`${fileEntries[i].name}`);
        } else {
          this.files.push(fileEntries[i]);
          this.fileInfo.push({
            Name: fileEntries[i].name,
            RecordStatus: "Inserted",
          });
        }
      }
      if (errors.length > 0) this.$emit("files-input-error", errors);
    },
    onDeleteFile(index) {
      if (this.fileInfo[index].RecordStatus == "Inserted") {
        let fileIndex = this.files.findIndex((file) => {
          return file.name == this.fileInfo[index].Name;
        });
        this.files.splice(fileIndex, 1);
        this.fileInfo.splice(index, 1);
      } else this.fileInfo[index].RecordStatus = "Deleted";
    },
    onDownload(file) {
      this.formSubmissionErrors = [];

      this.downloadFile(file)
        .then(() => {
          this.$emit("header-message", {
            isSuccessInd: true,
            Message: [`The file is downloaded.`],
          });
        })
        .catch(() => {
          // Errors have been logged
        });
    },
    onCleanFiles() {
      this.files = [];
    },
    async downloadFile(file) {
      let result = await fileDownloadApi.getFile(file.FullPath);
      //Build a URL from the file
      let newBlob = new Blob([result]);
      let fileURL = window.URL.createObjectURL(newBlob);
      this.$log.info(`fileURL: ${fileURL}`);

      var elink = document.createElement("a");
      elink.download = file.Name;
      elink.style.display = "none";
      elink.href = fileURL;
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },
  },
};
</script>
<style scoped>
.plus-icon > input {
  display: none;
}

.file-link:hover {
  text-decoration: underline;
  color: #0044ff;
}

.file-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
</style>
