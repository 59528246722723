<template>
  <mdb-container fluid style="padding-left: 2px; padding-right: 2px">
    <h1 class="text-center">Communication Search</h1>
    <awgt-std-switch
      checked
      offLabel
      class="my-2"
      onLabel="Match all searching criteria"
      @getValue="
        (value) => {
          matchAllSearchingCriteriaInd = value;
        }
      "
    ></awgt-std-switch>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        search
        label="Client"
        style="min-width: 240px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="clients"
        itemTextProperty="Name"
        v-model="clientReference"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        :disabled="
          this.clientReference != null && this.clientReference.length > 0
            ? false
            : true
        "
        :label="
          this.clientReference != null && this.clientReference.length > 0
            ? 'Booking'
            : 'Booking (Disabled)'
        "
        style="min-width: 240px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getBookings"
        itemTextProperty="Name"
        v-model="bookingReference"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        search
        label="Supplier"
        style="min-width: 240px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="suppliers"
        itemTextProperty="Name"
        v-model="supplierReference"
        bg
      ></awgt-std-dropdown>
    </div>
    <div class="d-flex flex-row flex-wrap flex-grow-1">
      <awgt-std-dropdown
        label="Communication Medium"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getCommunicationMediums()"
        itemTextProperty="Name"
        v-model="communicationMedium"
        bg
      ></awgt-std-dropdown>
      <awgt-std-dropdown
        label="Communication Direction"
        style="min-width: 90px"
        class="flex-grow-1 mx-2"
        itemValueProperty="Code"
        :items="getCommunicationDirections()"
        itemTextProperty="Name"
        v-model="communicationDirection"
        bg
      ></awgt-std-dropdown>
      <mdb-icon
        icon="calendar-alt"
        far
        color="primary"
        class="mx-2 mt-4"
        size="2x"
      />
      <asoft-date-picker
        v-model="occurenceDate"
        label="Occurence Date"
        autoHide
        displayDateFormat="D MMM YYYY"
        dataDateFormat="YYYY-MM-DDTHH:mm:ss"
        bg
        class="flex-grow-1 mx-2"
        style="min-width: 140px"
      />
    </div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3"
    >
      <mdb-form-inline class="d-flex justify-content-end flex-grow-1">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          @click="onClearSearchCriteria"
        >
          <mdb-icon icon="eraser" class="mr-1" />Clear
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          v-on:click="onSearchCommunications"
        >
          <mdb-icon icon="search" class="mr-1" />Search
        </awgt-std-button>
        <awgt-std-button
          v-if="checkPermission('CP_CoV')"
          type="button"
          class="command-button mx-2"
          @click="
            $router.push({
              path: '/communication/add',
            })
          "
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </mdb-form-inline>
    </div>
    <div v-if="communications.length == 0 && isSearched" class="text-center">
      <i>No search result found</i>
    </div>
    <mdb-card
      v-for="(communication, index) in communications"
      :key="index"
      class="mb-3 mx-2"
    >
      <mdb-card-header
        @click.native="toggleWholeCommunicationDetailExpansion(index)"
        tag="button"
      >
        <span class="float-right">
          <mdb-icon
            v-if="communicationDetailExpansion[index] == true"
            icon="angle-up"
            size="2x"
            class="pt-2"
            color="primary"
          />
          <mdb-icon
            v-else
            icon="angle-down"
            size="2x"
            class="pt-2"
            color="primary"
          />
        </span>
        <h4 class="mb-1">
          {{
            communication.CommunicationWith == "CW_C"
              ? getCommunicationClientName(communication.RelatedClientReference)
              : getCommunicationSupplierName(
                  communication.RelatedSupplierReference
                )
          }}
          - {{ communication.Subject }}
        </h4>
      </mdb-card-header>
      <expansion-region :toggle="communicationDetailExpansion[index]">
        <mdb-card-body v-if="communicationsFullDataSet[index]">
          <div>
            <h5>
              <mdb-icon
                class="icon-in-views"
                fas
                icon="phone-square-alt"
                size="1x"
                color="primary"
              />
              {{
                communicationsFullDataSet[index].CommunicationWith == "CW_C"
                  ? getCommunicationClientName(
                      communicationsFullDataSet[index].RelatedClientReference
                    )
                  : getCommunicationSupplierName(
                      communicationsFullDataSet[index].RelatedSupplierReference
                    )
              }}
              - {{ communicationsFullDataSet[index].Subject }}
              <span style="font-size: 75%">
                <i>({{ communicationsFullDataSet[index].Reference }})</i></span
              >
            </h5>
            <div>
              <mdb-icon
                class="icon-in-views"
                icon="tty"
                color="primary"
                size="1x"
              />

              Communication Medium:
              {{
                getCommunicationDirection(
                  communicationsFullDataSet[index].CommunicationDirection
                )
              }}
            </div>
            <div>
              <mdb-icon
                class="icon-in-views"
                icon="hashtag"
                color="primary"
                size="1x"
              />

              Communication Direction:
              {{
                getCommunicationMedium(
                  communicationsFullDataSet[index].CommunicationMedium
                )
              }}
            </div>
            <div>
              <mdb-icon
                class="icon-in-views"
                icon="user"
                color="primary"
                size="1x"
              />
              Contact Name: {{ communicationsFullDataSet[index].ContactNm }}
            </div>
            <div>
              <mdb-icon
                class="icon-in-views"
                icon="sticky-note"
                color="primary"
                size="1x"
              />
              Subject: {{ communicationsFullDataSet[index].Subject }}
            </div>
            <div class="mx-2 mt-1">
              <h5 style="color: #923f82">Notes</h5>
              <span
                v-html="communicationsFullDataSet[index].Notes"
                class="html-in-views"
              ></span>
            </div>
          </div>
          <div class="my-2 float-right">
            <awgt-std-button
              v-if="
                checkPermission('CP_CoU') && checkPermissionForUpdate(index)
              "
              type="button"
              title="Edit"
              class="command-button mx-2"
              @click="
                $router.push({
                  path:
                    '/communication/' +
                    communicationsFullDataSet[index].Reference +
                    '/edit',
                })
              "
            >
              <mdb-icon class="mr-1" icon="edit" />Edit
            </awgt-std-button>
            <awgt-std-button
              v-if="
                checkPermission('CP_CoD') && checkPermissionForDelete(index)
              "
              type="button"
              title="Delete"
              class="command-button mx-2"
              v-on:click="onDeleteCommunication(index)"
            >
              <mdb-icon class="mr-1" icon="trash-alt" />Delete
            </awgt-std-button>
          </div>
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <yes-or-no-prompt ref="handleCommunicationPrompt"> </yes-or-no-prompt>
  </mdb-container>
</template>
<style lang="scss" src="@/styles/common.scss"></style>
<script>
import {
  mdbContainer,
  mdbIcon,
  mdbCard,
  mdbFormInline,
  mdbCardHeader,
  mdbCardBody,
} from "mdbvue";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import ExpansionRegion from "@/components/ExpansionRegion";
import { mapGetters } from "vuex";
import communicationApi from "@/api/communicationApi.js";
import bookingApi from "@/api/bookingApi.js";
import clientApi from "@/api/clientApi.js";
import supplierApi from "@/api/supplierApi.js";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
import { sharedMethods } from "@/shared/shared";
import tokenManager from "@/shared/securityTokenManager.js";
import userApi from "@/api/userApi.js";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdSwitch from "@/components/AWGT/AwgtStdSwitch";

export default {
  components: {
    mdbContainer,

    mdbIcon,
    mdbFormInline,
    asoftDatePicker,
    AwgtStdDropdown,
    mdbCard,
    mdbCardHeader,
    ExpansionRegion,
    mdbCardBody,
    yesOrNoPrompt,
    AwgtStdSwitch,
    AwgtStdButton,
  },
  data() {
    return {
      communicationMedium: "",
      communicationDirection: "",
      clientReference: "",
      bookingReference: "",
      supplierReference: "",
      suppliers: [],
      clients: [],
      bookings: [],
      personName: "",
      occurenceDate: "",
      tripEvent: "",
      communications: [],
      isSearched: false,
      communicationDetailExpansion: [],
      matchAllSearchingCriteriaInd: true,
      communicationsFullDataSet: [],
      workgroupsOfCurrentUser: [],
      currentUserReference: null,
      currentUserPermissionScopeForCommunicationUpdate: null,
      currentUserPermissionScopeForCommunicationDelete: null,
      currentUserLevel: 0,
    };
  },

  computed: {
    ...mapGetters([
      "getCommunicationMediums",
      "getCommunicationDirections",
      "checkPermission",
    ]),
    getBookings() {
      if (this.clientReference != null && this.clientReference.length > 0) {
        let self = this;
        return this.bookings
          .filter((item) => item.Client.Reference == self.clientReference)
          .map((bookingEntity) => {
            return {
              Name:
                bookingEntity.Description +
                " (" +
                this.$moment(bookingEntity.DepartureDt).format("YYYY") +
                ") " +
                bookingEntity.Reference,
              Code: bookingEntity.Reference,
            };
          });
      } else {
        return [];
      }
    },

    getCommunicationClientName() {
      return (clientReference) => {
        return this.clients.find((c) => {
          return c.Code == clientReference;
        })?.Name;
      };
    },

    getCommunicationSupplierName() {
      return (supplierReference) => {
        return this.suppliers.find((s) => {
          return s.Reference == supplierReference;
        })?.Name;
      };
    },

    getCommunicationDirection() {
      return function (code) {
        return this.getCommunicationDirections().find((cd) => {
          return cd.Code == code;
        })?.Name;
      };
    },

    getCommunicationMedium() {
      return function (code) {
        return this.getCommunicationMediums().find((cm) => {
          return cm.Code == code;
        })?.Name;
      };
    },

    checkPermissionForUpdate() {
      return function (index) {
        if (!this.currentUserPermissionScopeForCommunicationUpdate)
          return false;
        let communicationConsultantUser =
          this.communicationsFullDataSet[index]?.ConsultantUser;

        if (communicationConsultantUser) {
          if (!this.currentUserPermissionScopeForCommunicationUpdate)
            return false;

          if (
            this.currentUserPermissionScopeForCommunicationUpdate == "CPS_AA"
          ) {
            return true;
          }

          if (
            this.currentUserPermissionScopeForCommunicationUpdate == "CPS_NA"
          ) {
            return communicationConsultantUser.ConsultancyRole.Level > 400
              ? false
              : true;
          }

          if (
            this.currentUserPermissionScopeForCommunicationUpdate == "CPS_BW"
          ) {
            if (
              !communicationConsultantUser.CommunicationBookingWorkgroupReference
            ) {
              if (
                communicationConsultantUser.Workgroups.some(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0
                ) &&
                communicationConsultantUser.ConsultancyRole.Level <=
                  this.currentUserLevel
              )
                return true;
            } else {
              if (
                this.workgroupsOfCurrentUser.indexOf(
                  communicationConsultantUser.CommunicationBookingWorkgroupReference
                ) >= 0 &&
                communicationConsultantUser.ConsultancyRole.Level <=
                  this.currentUserLevel
              ) {
                return true;
              }
            }
          }

          if (
            this.currentUserPermissionScopeForCommunicationUpdate == "CPS_NW"
          ) {
            if (
              communicationConsultantUser.Reference == this.currentUserReference
            )
              return true;

            if (
              !communicationConsultantUser.CommunicationBookingWorkgroupReference
            ) {
              if (
                communicationConsultantUser.Workgroups.some(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0
                ) &&
                communicationConsultantUser.ConsultancyRole.Level <
                  this.currentUserLevel
              )
                return true;
            } else {
              if (
                this.workgroupsOfCurrentUser.indexOf(
                  communicationConsultantUser.CommunicationBookingWorkgroupReference
                ) >= 0 &&
                communicationConsultantUser.ConsultancyRole.Level <
                  this.currentUserLevel
              ) {
                return true;
              }
            }
          }

          return false;
        } else return false;
      };
    },

    checkPermissionForDelete() {
      return function (index) {
        if (!this.currentUserPermissionScopeForCommunicationDelete)
          return false;
        let communicationConsultantUser =
          this.communicationsFullDataSet[index]?.ConsultantUser;

        if (communicationConsultantUser) {
          if (!this.currentUserPermissionScopeForCommunicationDelete)
            return false;

          if (
            this.currentUserPermissionScopeForCommunicationDelete == "CPS_AA"
          ) {
            return true;
          }

          if (
            this.currentUserPermissionScopeForCommunicationDelete == "CPS_NA"
          ) {
            return communicationConsultantUser.ConsultancyRole.Level > 400
              ? false
              : true;
          }

          if (
            this.currentUserPermissionScopeForCommunicationDelete == "CPS_BW"
          ) {
            if (
              !communicationConsultantUser.CommunicationBookingWorkgroupReference
            ) {
              if (
                communicationConsultantUser.Workgroups.some(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0
                ) &&
                communicationConsultantUser.ConsultancyRole.Level <=
                  this.currentUserLevel
              )
                return true;
            } else {
              if (
                this.workgroupsOfCurrentUser.indexOf(
                  communicationConsultantUser.CommunicationBookingWorkgroupReference
                ) >= 0 &&
                communicationConsultantUser.ConsultancyRole.Level <=
                  this.currentUserLevel
              ) {
                return true;
              }
            }
          }

          if (
            this.currentUserPermissionScopeForCommunicationDelete == "CPS_NW"
          ) {
            if (
              communicationConsultantUser.Reference == this.currentUserReference
            )
              return true;

            if (
              !communicationConsultantUser.CommunicationBookingWorkgroupReference
            ) {
              if (
                communicationConsultantUser.Workgroups.some(
                  (w) => this.workgroupsOfCurrentUser.indexOf(w.Reference) >= 0
                ) &&
                communicationConsultantUser.ConsultancyRole.Level <
                  this.currentUserLevel
              )
                return true;
            } else {
              if (
                this.workgroupsOfCurrentUser.indexOf(
                  communicationConsultantUser.CommunicationBookingWorkgroupReference
                ) >= 0 &&
                communicationConsultantUser.ConsultancyRole.Level <
                  this.currentUserLevel
              ) {
                return true;
              }
            }
          }

          return false;
        } else return false;
      };
    },
  },

  methods: {
    onSearchCommunications() {
      this.searchCommunications();
    },

    async toggleWholeCommunicationDetailExpansion(index) {
      let communication;
      if (
        (this.communicationDetailExpansion[index] == undefined ||
          this.communicationDetailExpansion[index] == false) &&
        this.communicationsFullDataSet[index] == undefined
      ) {
        communication = await communicationApi.getCommunicationByReference(
          this.communications[index].Reference
        );
        this.$set(this.communicationsFullDataSet, index, communication);
      }

      this.$set(
        this.communicationDetailExpansion,
        index,
        !this.communicationDetailExpansion[index]
      );
    },

    async searchCommunications() {
      this.communications = await communicationApi.getCommunicationsList(
        this.clientReference,
        this.bookingReference,
        this.supplierReference,
        this.communicationMedium,
        this.communicationDirection,
        this.occurenceDate == "" ? null : this.occurenceDate,
        this.matchAllSearchingCriteriaInd
      );
      this.isSearched = true;
    },

    onDeleteCommunication(index) {
      this.$refs.handleCommunicationPrompt
        .onHandlePrompt("Warning", "Are you sure to delete the communication?")
        .then((option) => {
          if (option == true) {
            this.deleteCommunication(
              index,
              this.communicationsFullDataSet[index]
            ).catch(() => {
              // This has already been logged in communicationApi.js
            });
          }
        });
    },

    async deleteCommunication(index, communication) {
      await communicationApi.deleteCommunication(communication);
      this.communications.splice(index, 1);
    },

    onClearSearchCriteria() {
      this.communicationMedium = "";
      this.communicationDirection = "";
      this.clientReference = "";
      this.bookingReference = "";
      this.supplierReference = "";
      this.occurenceDate = null;
      this.communications.splice(0);
    },

    async loadData() {
      this.bookings = await bookingApi.getBookingsAsync();
      this.clients = await clientApi.getClientDropdown();
      this.suppliers = await supplierApi.getSupplierDropdown(); //Pull back Code and name Properties only.

      let userContext = await tokenManager.getCurrentUserContextAsync();
      let currentUser = await userApi.getUserByReference(userContext.Reference);

      this.currentUserLevel = currentUser.ConsultancyRole.Level;
      this.currentUserReference = currentUser.Reference;

      this.workgroupsOfCurrentUser = currentUser.Workgroups.map((w) => {
        return w.Reference;
      });

      this.currentUserPermissionScopeForCommunicationUpdate =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_CoU";
        })?.PermissionScope;

      this.currentUserPermissionScopeForCommunicationDelete =
        currentUser.UserPermission.find((cp) => {
          return cp.Permission == "CP_CoD";
        })?.PermissionScope;
    },
  },
  mounted() {
    sharedMethods.onKeyDownEnterSearch(this.onSearch);
    this.$bus.$on("on-header-input-search", (searchString) => {
      this.searchReference = searchString;
      this.onSearchCommunications();
    });
    this.loadData().catch(() => {
      // Errors have been logged
    });
  },
  beforeDestroy() {
    sharedMethods.cancelOnKeyDownEnterSearch(this.searchSuppliers);
    this.$bus.$off("on-header-input-search", (searchString) => {
      this.searchReference = searchString;
      this.onSearchCommunications();
    });
  },
};
</script>
