//TODO: Missing instrumentation time logging.

import api from "./api.js";
import { sharedMethods } from "@/shared/shared";
import logger from "@/shared/asoftLogger.js";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getIssues(
    issueLogStatusCd = null,
    title = null,
    description = null,
    fromDt = null,
    toDt = null
  ) {
    try {
      logger.get(loggingSource.UIIssue).info("getIssues...");
      logger
        .get(loggingSource.UIIssue)
        .debug(
          "Parameters => issueLogStatusCd: %s, title: %s, description: %s, fromDt: %s, toDt: %s",
          issueLogStatusCd,
          title,
          description,
          fromDt,
          toDt
        );

      const response = await api.client
        .get("systemIssue", {
          params: {
            IssueLogStatusCd: issueLogStatusCd,
            Title: title,
            Description: description,
            FromDt: fromDt,
            ToDt: toDt,
          },
        })
        .catch((error) => {
          throw error;
        });
      const issues = response.data;

      logger
        .get(loggingSource.UIIssue)
        .trace("getIssues (before sanitisation) response: %s", response);

      //Html sanitise issue description
      for (let issue of issues)
        issue.Description = sharedMethods.htmlSanitize(issue.Description);

      logger
        .get(loggingSource.UIIssue)
        .trace("getIssues (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIIssue)
        .debug("getIssues (after sanitisation) bookings: %s", issues);

      return issues;
    } catch (error) {
      logger.get(loggingSource.UIIssue).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIIssue).info("...getIssues");
    }
  },

  async getIssueByReference(reference, restoreReferenceInd = false) {
    try {
      logger.get(loggingSource.UIIssue).info("getIssueByReference...");

      logger
        .get(loggingSource.UIIssue)
        .debug("Parameters => reference: %s", reference);

      if (!reference || reference.length == 0) {
        throw "Reference must not be null";
      }

      const response = await api.client
        .get(`systemIssue/${reference}`, {
          params: { restoreReference: restoreReferenceInd },
        })
        .catch((error) => {
          throw error;
        });
      const issue = response.data;

      logger
        .get(loggingSource.UIIssue)
        .trace(
          "getIssueByReference (before sanitisation) response: %s",
          response
        );

      //Html sanitise issue description
      issue.Description = sharedMethods.htmlSanitize(issue.Description);

      logger
        .get(loggingSource.UIIssue)
        .trace(
          "getIssueByReference (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIIssue)
        .debug("getIssueByReference (after sanitisation) issue: %s", issue);

      return issue;
    } catch (error) {
      logger.get(loggingSource.UIIssue).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIIssue).info("...getIssueByReference");
    }
  },

  async postIssue(issuePayload) {
    try {
      logger.get(loggingSource.UIIssue).info("postIssue ...");
      logger
        .get(loggingSource.UIIssue)
        .debug("Parameters => issuePayload: %s", issuePayload);

      const response = await api.client
        .post("systemIssue", issuePayload)
        .catch((error) => {
          throw error;
        });
      const issue = response.data;

      logger
        .get(loggingSource.UIIssue)
        .trace("postIssue (before sanitisation) response: %s", response);

      //Html sanitise issue description
      issue.Description = sharedMethods.htmlSanitize(issue.Description);

      logger
        .get(loggingSource.UIIssue)
        .trace("postIssue (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIIssue)
        .debug("postIssue (after sanitisation) issue: %s", issue);

      return issue;
    } catch (error) {
      logger.get(loggingSource.UIIssue).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIIssue).info("... postIssue");
    }
  },

  async putIssue(issuePayload) {
    try {
      logger.get(loggingSource.UIIssue).info("putIssue ...");
      logger
        .get(loggingSource.UIIssue)
        .debug("Parameters => issuePayload: %s", issuePayload);

      const response = await api.client
        .put("systemIssue", issuePayload)
        .catch((error) => {
          throw error;
        });
      const issue = response.data;

      logger
        .get(loggingSource.UIIssue)
        .trace("putIssue (before sanitisation) response: %s", response);

      //Html sanitise issue description
      issue.Description = sharedMethods.htmlSanitize(issue.Description);

      logger
        .get(loggingSource.UIIssue)
        .trace("putIssue (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIIssue)
        .debug("putIssue (after sanitisation) issue: %s", issue);

      return issue;
    } catch (error) {
      logger.get(loggingSource.UIIssue).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIIssue).info("... putIssue");
    }
  },

  async deleteIssue(issuePayload) {
    try {
      logger.get(loggingSource.UIIssue).info("deleteIssue...");
      logger
        .get(loggingSource.UIIssue)
        .debug("Parameters => issuePayload: %s", issuePayload);

      await api.client
        .delete(
          `systemIssue/${
            issuePayload.Reference
          }?concurrencyRV=${encodeURIComponent(issuePayload.ConcurrencyRV)}`
        )
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      logger.get(loggingSource.UIIssue).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIIssue).info("...deleteIssue");
    }
  },
};
