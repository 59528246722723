<template>
  <mdb-Container fluid>
    <div class="mx-3" v-if="pageMode == 'EditOrder'">
      <h1 class="text-center mb-3">Order Edit</h1>
      <div class="mx-2"></div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 mt-2 align-items-center"
      >
        <div>Booking:</div>
        <div class="flex-grow-1 ml-2">{{ order.BookingReference }}</div>
      </div>
      <div
        class="d-flex flex-row flex-wrap flex-grow-1 mr-2 align-items-center"
      >
        <div
          v-if="
            !order.ResellerSupplierReference ||
            order.PrimaryOrderProducts?.length === 0
          "
          class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
        >
          <div>Reseller:</div>
          <awgt-std-dropdown
            v-model="order.ResellerSupplierReference"
            itemValueProperty="Code"
            :items="supplierDropDown"
            itemTextProperty="Name"
            label="Reseller"
            bg
            search
            class="flex-grow-1 ml-2"
            @get-value="
              (value) => {
                setResellerSupplierName(value);
              }
            "
          ></awgt-std-dropdown>
        </div>
        <div
          v-else
          class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
        >
          <div>Reseller:</div>
          <div class="mx-2">
            {{
              supplierDropDown.find(
                (s) => s.Code === order.ResellerSupplierReference
              )?.Name
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
        >
          <div>Order Status:</div>
          <awgt-std-dropdown
            v-model="order.OrderStatusCd"
            itemValueProperty="Code"
            :items="getOrderStatuses()"
            itemTextProperty="Name"
            label="Order Status"
            bg
            class="flex-grow-1 ml-2"
          ></awgt-std-dropdown>
        </div>
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <h5>Order Products</h5>
        <awgt-std-button
          type="button"
          class="command-button mr-2 ml-auto"
          @click="
            () => {
              if (order.ResellerSupplierReference) showProductSearch = true;
            }
          "
          :disabled="!order.ResellerSupplierReference"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
      <div v-if="order.PrimaryOrderProducts?.length == 0" class="text-center">
        <i>Press "Add" to add a product to this order</i>
      </div>

      <mdb-card
        v-else
        v-for="(orderProduct, index) in order.PrimaryOrderProducts"
        :key="index"
        class="mb-3"
      >
        <mdb-card-body>
          <div
            class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center"
          >
            <mdb-icon
              :icon="
                getProductIcon(
                  orderProduct.ProductTypeCd,
                  orderProduct.ProductSubTypeCd
                )
              "
              size="2x"
              class="mr-2"
              color="primary"
              style="width: 36px"
            />
            {{ orderProduct.Name }}

            <div class="ml-auto">
              <awgt-std-button
                type="button"
                class="command-button mr-2"
                @click="orderProductDelete(orderProduct.OrderProductIdx)"
              >
                <mdb-icon icon="trash-alt" class="mr-1" />Delete
              </awgt-std-button>

              <awgt-std-button
                type="button"
                class="command-button"
                @click="onOrderProductEdit(orderProduct.OrderProductIdx)"
              >
                <mdb-icon icon="edit" class="mr-1" />Edit
              </awgt-std-button>
            </div>
          </div>
          <div>
            Priced For {{ formatDate(orderProduct.PricedForDt) }}
            <div>
              <table class="pricing-table">
                <tr class="pricing-table">
                  <td class="pricing-table">Ex-GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProduct?.Price?.adjExGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProduct?.Price?.adjGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">Total Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProduct?.Price?.adjTotal)
                        "
                      ></span>
                      <tdoxTooltip
                        v-if="orderProduct?.Price?.comment.length > 0"
                        :detail="orderProduct?.Price?.comment"
                      />
                    </template>
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <h5>Order Product Lines</h5>
            <ul>
              <li
                v-for="(
                  orderProductLine, index2
                ) of orderProduct.OrderProductLines"
                :key="index2"
              >
                {{ orderProductLine.Name }}

                <span
                  v-for="(
                    orderProductLineQuantity, index3
                  ) in orderProductLine.OrderProductLineQuantities"
                  :key="index3"
                >
                  {{ orderProductLineQuantity.Quantity }}
                  {{
                    getQuantityUnit(
                      orderProductLineQuantity.QuantityUnitCd,
                      orderProductLineQuantity.Quantity,
                      orderProductLineQuantity.SingularItemAlias,
                      orderProductLineQuantity.PluralItemAlias
                    )
                  }}
                  {{ formatPricingString(orderProductLine?.Price?.adjTotal) }}
                </span>

                <!--               <table class="pricing-table">
                <tr class="pricing-table">
                  <td class="pricing-table">Ex-GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjExGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">GST Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjGST)
                        "
                      ></span>
                    </template>
                  </td>
                </tr>
                <tr class="pricing-table">
                  <td class="pricing-table">Total Price</td>
                  <td class="pricing-table">
                    <template>
                      <span
                        v-html="
                          formatPricingString(orderProductLine?.Price?.adjTotal)
                        "
                      ></span>
                      <tdoxTooltip
                        v-if="orderProductLine?.Price?.comment.length > 0"
                        :detail="orderProductLine?.Price?.comment"
                      />
                    </template>
                  </td>
                </tr>
              </table> -->
              </li>
            </ul>
          </div>
        </mdb-card-body>
      </mdb-card>

      <h5>Order Pricing</h5>
      <div>
        <table class="pricing-table">
          <tr class="pricing-table">
            <td class="pricing-table">Ex-GST Price</td>
            <td class="pricing-table">
              <span v-html="formatPricingString(order?.Price?.exGST)"></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">GST Price</td>
            <td class="pricing-table">
              <span v-html="formatPricingString(order?.Price?.gst)"></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">Total Price</td>
            <td class="pricing-table">
              <span v-html="formatPricingString(order?.Price?.total)"></span>
              <tdoxTooltip
                v-if="order?.Price?.comment.length > 0"
                :detail="order?.Price?.comment"
              />
            </td>
          </tr>
        </table>
      </div>

      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mt-3 align-items-center"
      >
        <mdb-icon
          icon="clipboard"
          far
          color="primary"
          class="mb-2"
          size="2x"
          style="width: 34px; text-align: center"
        />
        <div class="ml-2">Comment</div>
      </div>
      <awgt-input
        id="orderComment"
        v-model="order.Comment"
        name="OrderComment"
        bg
        class="flex-grow-1 mx-2 input-field"
        type="textarea"
        label="Comment"
      />
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <mdb-icon
          icon="calendar-alt"
          far
          color="primary"
          class="ml-2"
          size="2x"
          style="width: 34px"
        />
        <asoft-date-picker
          v-model="order.DepositRequiredByDt"
          label="Deposit required by"
          autoHide
          displayDateFormat="D MMM YYYY"
          dataDateFormat="YYYY-MM-DDTHH:mm:ss"
          setTime="00:00:00.000"
          bg
          class="flex-grow-1 mx-2"
          style="min-width: 140px"
          :width="300"
        />
      </div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 mx-2 align-items-center"
      >
        <h5>Price Adjustment</h5>
        <awgt-std-button
          type="button"
          class="command-button ml-auto mb-2"
          @click="() => (showOrderOrProductLinePriceAdjusted = true)"
        >
          <mdb-icon icon="plus" class="mx-1" />Adjust price
        </awgt-std-button>
      </div>

      <table v-if="order.PriceAdjustReason?.length > 0" class="pricing-table">
        <tr class="pricing-table">
          <td class="pricing-table">Adjustment Type</td>
          <td class="pricing-table">Change Type</td>
          <td class="pricing-table">Reason</td>
          <td class="pricing-table">Amount</td>
        </tr>
        <tr class="pricing-table">
          <td class="pricing-table">
            {{ getPriceAdjustmentTypeNameByCode(order.PriceAdjustmentTypeCd) }}
          </td>
          <td class="pricing-table">
            {{
              getOrderPriceChangeTypeNameByCode(order.OrderPriceChangeTypeCd)
            }}
          </td>
          <td class="pricing-table">{{ order.PriceAdjustReason }}</td>
          <td class="pricing-table">
            <span v-if="order.OrderPriceChangeTypeCd == 'OPCT_P'"
              >{{ order.AdjustAmt }}%</span
            >
            <span v-else v-html="formatPricingString(order.AdjustAmt)"></span>
          </td>
        </tr>
      </table>
      <br />
      <template>
        <h5>Total Price:</h5>
        <table class="pricing-table">
          <tr class="pricing-table">
            <td class="pricing-table">Ex-GST Price</td>
            <td class="pricing-table">
              <span
                v-html="formatPricingString(orderExGSTAdjustedAmt())"
              ></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">GST Price</td>
            <td class="pricing-table">
              <span v-html="formatPricingString(orderGSTAdjustedAmt())"></span>
            </td>
          </tr>
          <tr class="pricing-table">
            <td class="pricing-table">Total Price</td>
            <td class="pricing-table">
              <span v-html="formatPricingString(orderAdjustedAmt())"></span>
            </td>
          </tr>
        </table>
      </template>

      <div class="section-break" style="margin-bottom: 0.8rem"></div>

      <div class="float-right">
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          @click="onSave"
          :disabled="!isFormDirty()"
        >
          <mdb-icon icon="check" class="mr-1" />Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mx-2"
          style="width: 120px; padding-left: 0; padding-right: 0"
          @click="onClose"
        >
          <mdb-icon icon="times" class="mr-1" />Close
        </awgt-std-button>
      </div>
      <mdb-modal
        size="lg"
        v-if="showOrderOrProductLinePriceAdjusted"
        :show="showOrderOrProductLinePriceAdjusted"
      >
        <order-price-adjustment-dialog
          v-if="showOrderOrProductLinePriceAdjusted"
          :adjustingEntity="order"
          title="Order Price Adjustment"
          @save="(updatedOrder) => onOrderPriceAdjustmentSave(updatedOrder)"
          @close="() => (showOrderOrProductLinePriceAdjusted = false)"
          @delete="() => orOrderPriceAdjustmentDelete()"
        />
      </mdb-modal>
      <yes-or-no-prompt ref="handleOrderEditForm"> </yes-or-no-prompt>
      <mdb-modal size="lg" v-if="showProductSearch" :show="showProductSearch">
        <add-order-product-dialog
          :resellerSupplierName="order.ResellerSupplierName"
          :visible="showProductSearch"
          @productSelected="onProductSelectedForOrderProductAdd"
          @close="() => (showProductSearch = false)"
        />
      </mdb-modal>
    </div>
    <div v-else-if="pageMode == 'EditOrderProduct'">
      <edit-order-product
        :orderProductIdx="orderProductIdx"
        :booking="booking"
        @close="onEditOrderProductClose"
      />
    </div>
    <div v-else>Unknown pageMode</div>
  </mdb-Container>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss">
.md-form label {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.command-button {
  min-width: 100px;
  width: 100px;
}

@media screen and (max-width: 800px) {
  .command-button {
    min-width: 70px;
    width: 70px;
  }
}
</style>
<script>
import { mdbContainer, mdbIcon, mdbModal, mdbCard, mdbCardBody } from "mdbvue";
import addOrderProductDialog from "./addOrderProductDialog";
import awgtInput from "@/components/AWGT/AwgtInput";
import { mapGetters } from "vuex";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { sharedMethods } from "@/shared/shared";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import supplierApi from "@/api/supplierApi.js";
import bookingApi from "@/api/bookingApi.js";
import orderUtil from "./orderUtil.js";
import asoftDatePicker from "@/components/AtomSoftware/asoftDatePicker.vue";
import yesOrNoPrompt from "@/components/YesOrNoPrompt";
//import primaryOrderProductSearch from "./primaryOrderProductSearch.vue";
import orderPriceAdjustmentDialog from "./orderPriceAdjustmentDialog";
import editOrderProduct from "./editOrderProduct";
import { mapActions, mapMutations } from "vuex/dist/vuex.common.js";
//import ExpansionRegion from "@/components/ExpansionRegion";
import productApi from "@/api/productApi.js";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbIcon,
    AwgtStdButton,
    awgtInput,
    AwgtStdDropdown,
    asoftDatePicker,
    yesOrNoPrompt,
    mdbContainer,
    mdbModal,
    orderPriceAdjustmentDialog,
    addOrderProductDialog,
    editOrderProduct,
  },

  data() {
    return {
      order: {},
      formMode: null,
      suppliers: [],
      toggleProductSearch: false,
      orderSnapshot: null,
      isOrderDirtyInd: false,
      cachedProducts: [],
      showProductSearch: false,
      showOrderOrProductLinePriceAdjusted: false,
      pageMode: "EditOrder",
      orderProductIdx: 0, //The last order product that was editted.
      booking: {},
    };
  },

  directives: {
    numeric: numericDirective,
  },

  computed: {
    ...mapGetters([
      "getOrderStatuses",
      "getPriceAdjustmentTypeNameByCode",
      "getPriceChangeTypeNameByCode",
      "getOrderPriceChangeTypeNameByCode",
      "order/getEmptyOrder",
      "order/getActiveOrder",
      "order/getActiveOrderPrice",
    ]),

    supplierDropDown() {
      return this.suppliers
        .map((supplier) => {
          return {
            Name: supplier.Name,
            Code: supplier.Reference,
          };
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));
    },
  },

  props: {
    onSavePromptExit: {
      type: Function,
    },
  },

  methods: {
    ...mapMutations([
      "order/mutStoreOrderProduct",
      "order/mutDeleteOrderProduct",
      "order/mutStoreOrder",
    ]),
    ...mapActions(["order/actLoadOrder", "order/actSaveOrder"]),
    ...sharedMethods,
    ...orderUtil,

    onOrderPriceAdjustmentSave(updatedOrder) {
      this.order.PriceAdjustmentTypeCd = updatedOrder.PriceAdjustmentTypeCd;
      this.order.OrderPriceChangeTypeCd = updatedOrder.OrderPriceChangeTypeCd;
      this.order.AdjustAmt = updatedOrder.AdjustAmt;
      this.order.PriceAdjustReason = updatedOrder.PriceAdjustReason;
      this.showOrderOrProductLinePriceAdjusted = false;

      this.calculateAndStoreOrderPrices();
      this.$forceUpdate();
    },

    orOrderPriceAdjustmentDelete() {
      this.order.PriceAdjustmentTypeCd = null;
      this.order.OrderPriceChangeTypeCd = null;
      this.order.AdjustAmt = null;
      this.order.PriceAdjustReason = "";
      this.showOrderOrProductLinePriceAdjusted = false;

      this.calculateAndStoreOrderPrices();
      this.$forceUpdate();
    },

    orderAdjustedAmt() {
      if (this.order?.AdjustAmt === undefined || this.order.Price === undefined)
        return 0;
      else {
        return this.order.Price.adjExGST + this.order.Price.adjGST;

        // let adjustAmount =
        //   this.order.PriceAdjustmentTypeCd == "PAT_D"
        //     ? this.order.AdjustAmt * -1
        //     : this.order.AdjustAmt;

        // if (this.order.OrderPriceChangeTypeCd == "OPCT_P")
        //   //Percentage
        //   return (
        //     (this.order.Price.adjExGST + this.order.Price.adjGST) *
        //     (1 + adjustAmount / 100)
        //   );
        // //Dollar On Total
        // else
        //   return (
        //     this.order.Price.adjExGST + this.order.Price.adjGST + adjustAmount
        //   );
      }
    },

    orderExGSTAdjustedAmt() {
      if (this.order?.AdjustAmt === undefined || this.order.Price === undefined)
        return 0;
      else {
        return this.order.Price.adjExGST;

        // let adjustAmount =
        //   this.order.PriceAdjustmentTypeCd == "PAT_D"
        //     ? this.order.AdjustAmt * -1
        //     : this.order.AdjustAmt;

        // if (this.order.OrderPriceChangeTypeCd == "OPCT_P")
        //   //Percentage
        //   return this.order.Price.adjExGST * (1 + adjustAmount / 100);
        // //Dollar On Total
        // else return this.order.Price.adjExGST + (adjustAmount * 10) / 11;
      }
    },

    orderGSTAdjustedAmt() {
      if (this.order?.AdjustAmt === undefined || this.order.Price === undefined)
        return 0;
      else {
        return this.order.Price.adjGST;

        // let adjustAmount =
        //   this.order.PriceAdjustmentTypeCd == "PAT_D"
        //     ? this.order.AdjustAmt * -1
        //     : this.order.AdjustAmt;

        // if (this.order.OrderPriceChangeTypeCd == "OPCT_P")
        //   //Percentage
        //   return this.order.Price.adjGST * (1 + adjustAmount / 100);
        // //Dollar On Total
        // else return this.order.Price.adjGST + adjustAmount / 11;
      }
    },

    onOrderProductEdit(orderProductIdx) {
      this.orderProductEdit(orderProductIdx);
    },

    orderProductEdit(orderProductIdx) {
      this.orderProductIdx = orderProductIdx;
      this.pageMode = "EditOrderProduct";
    },

    orderProductDelete(orderProductIdx) {
      this.$store.commit(
        "order/mutDeleteOrderProduct",
        {
          orderProductIdx: orderProductIdx,
        },
        { root: true }
      );

      this.recalculatePrices();
    },

    onEditOrderProductClose() {
      this.order = this.$lodash.cloneDeep(
        this.$store.getters["order/getActiveOrder"]
      );
      this.recalculatePrices();
      this.pageMode = "EditOrder";
    },

    setResellerSupplierName(value) {
      this.order.ResellerSupplierName = this.suppliers.find(
        (supplier) => supplier.Reference === value
      )?.Name;
    },

    async loadProduct(reference) {
      return await productApi.getProductByReference(reference, false);
    },

    async loadFormData() {
      let routeParams = this.$route.params;
      this.suppliers = await supplierApi.getSuppliers();
      this.booking = await bookingApi.getBookingByReferenceAsync(
        routeParams.bookingReference,
        false
      );
      this.orderReference = routeParams.orderReference;
      //This will load an empty order if orderReference is undefined.
      this.order = await this.$store.dispatch("order/actLoadOrder", {
        Reference: this.orderReference,
      });
      if (this.order.Reference == "") {
        this.order.Reference = "";
        this.order.BookingReference = routeParams.bookingReference;
        this.order.OrderStatusCd = "OS_P";
      }
      this.recalculatePrices();

      this.$log.info(this.order);
    },

    async onProductSelectedForOrderProductAdd(product) {
      this.$log.info("The following product was selected: %s", product.Name);
      let currentOrderProduct = this.createOrderProductFromProduct(product);

      //Add a record status of "Inserted" to the order product
      //so it can be deleted later if "Cancel" is pressed.
      currentOrderProduct.RecordStatus = "Inserted";

      //Add the new order product to the VueX store.
      this.$store.commit("order/mutStoreOrderProduct", currentOrderProduct, {
        root: true,
      });

      //this.order.PrimaryOrderProducts.push(currentOrderProduct);

      this.orderProductEdit(currentOrderProduct.OrderProductIdx);
    },

    createOrderProductFromProduct: function (product) {
      let orderProduct = this.$lodash.cloneDeep(
        this.$store.getters["order/getEmptyOrderProduct"]
      );

      orderProduct.Reference = product.Reference;
      orderProduct.ResellerSupplierReference =
        product.ResellerSupplier.Reference;
      orderProduct.Name = product.Name;
      orderProduct.ProductTypeCd = product.ProductType;
      orderProduct.ProductSubTypeCd = product.ProductSubType;
      orderProduct.ProviderSupplierName = product.ProviderSupplier.Name;

      return orderProduct;
    },

    onSave() {
      if (this.order.PrimaryOrderProducts.length == 0) {
        this.$emit("header-message", {
          isSuccessInd: false,
          Message: ["An order must have products before it can be saved."],
        });
      } else {
        this.onSaveOrder(this.order)
          .then(async () => {
            this.$emit("header-message", {
              isSuccessInd: true,
              Message: ["The order has been saved."],
            });

            this.snapshotOrder(this.order);
          })
          .catch((error) => {
            this.$log.error(error);
          });
      }
    },

    async onSaveOrder() {
      let orderResult = await this.$store.dispatch(
        "order/actSaveOrder",
        this.order
      );

      this.order = orderResult;

      this.$store.commit("order/mutStoreOrder", this.order, { root: true });

      this.recalculatePrices();

      this.$log.info(this.order);
    },

    async recalculatePrices() {
      await this.calculateAndStoreOrderProductLinePrices();
      this.calculateAndStoreOrderProductPrices();
      this.calculateAndStoreOrderPrices();
      this.$forceUpdate();
    },

    calculateAndStoreOrderPrices() {
      let x = this.$store.getters["order/getActiveOrderPrice"]();

      let orderPrice = this.$lodash.cloneDeep(x);

      this.$log.info(orderPrice);

      this.order.Price = orderPrice;

      this.$store.commit("order/mutStoreOrder", this.order, { root: true });
    },

    calculateAndStoreOrderProductPrices() {
      for (let orderProduct of this.order.PrimaryOrderProducts) {
        let orderProductPrice = this.$lodash.cloneDeep(
          this.$store.getters["order/getActiveOrderProductPrice"](
            orderProduct.OrderProductIdx
          )
        );

        orderProduct.Price = orderProductPrice;

        this.$store.commit("order/mutStoreOrderProduct", orderProduct, {
          root: true,
        });
      }
    },

    async calculateAndStoreOrderProductLinePrices() {
      for (let orderProduct of this.order.PrimaryOrderProducts) {
        let product = await this.loadProduct(orderProduct.Reference);
        for (let orderProductLine of orderProduct.OrderProductLines) {
          let commercialProductLine = product.CommercialProductLines.find(
            (cpl) => cpl.Reference == orderProductLine.Reference
          );

          let chronology = commercialProductLine.Chronology.find(
            (c) =>
              new Date(c.ActiveFromDt) <= new Date(orderProduct.PricedForDt) &&
              (c.ActiveToDt == null ||
                new Date(c.ActiveToDt) >= new Date(orderProduct.PricedForDt))
          );

          let orderProductLinePrice = this.$lodash.cloneDeep(
            this.$store.getters["order/getActiveOrderProductLinePrice"](
              chronology,
              orderProduct.OrderProductIdx,
              orderProductLine.OrderProductLineIdx
            )
          );

          orderProductLine.Price = orderProductLinePrice;

          //Update the order product line to the VueX store.
          this.$store.commit(
            "order/mutStoreOrderProductLine",
            {
              orderProductIdx: orderProduct.OrderProductIdx,
              orderProductLine: orderProductLine,
            },
            { root: true }
          );
        }
      }
    },

    onClose() {
      this.$router.go(-1);
    },

    snapshotOrder(order) {
      this.orderSnapshot = this.$lodash.cloneDeep(order);
      this.isOrderDirtyInd = false;
    },

    isFormDirty() {
      const omitDeep = require("omit-deep-lodash");

      if (this.isOrderDirtyInd == true) return true;

      this.isOrderDirtyInd =
        this.$lodash.isEqual(
          omitDeep(this.order, "Product", "Price"), //TODO: Does product exist on the order?
          this.orderSnapshot
        ) == false;

      return this.isOrderDirtyInd;
    },
  },

  mounted() {
    this.loadFormData().then(() => {
      this.snapshotOrder(this.order);
    });
  },

  beforeRouteLeave(_to, _from, next) {
    //If going to a sub-route to add products then don't
    //check if the page is dirty
    if (_to.path.startsWith(_from.path) == false) {
      //Do the dirty check;
      if (this.isFormDirty() == true) {
        this.onSavePromptExit().then((result) => {
          if (result == "Exit") {
            next();
          } else if (result == "Stay") next(false);
          else {
            this.onSaveOrder().then(() => {
              next();
            });
          }
        });
      } else next();
    } else next();
  },
};
</script>
