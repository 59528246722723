var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value != undefined &&
    _vm.templateArray.length > 0 &&
    _vm.value.length > 0
    ? _c(
        "div",
        [
          _vm._l(_vm.templateArray, function (template, index) {
            return [
              _c(
                "div",
                { key: index },
                [
                  index > 0
                    ? _c("awgt-std-switch", {
                        attrs: {
                          offLabel: "",
                          onLabel: `The ${template.Name} is the same as the ${
                            _vm.addresses.find((item) => {
                              return item.Type == template.KeepConsistentWith
                            }).Name
                          }`,
                        },
                        model: {
                          value: _vm.keepAddressesConsistent[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.keepAddressesConsistent, index, $$v)
                          },
                          expression: "keepAddressesConsistent[index]",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.keepAddressesConsistent[index] == null ||
                            _vm.keepAddressesConsistent[index] == false,
                          expression:
                            "\n          keepAddressesConsistent[index] == null ||\n          keepAddressesConsistent[index] == false\n        ",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-nowrap flex-grow-1 mr-4",
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mx-2 mt-3",
                            attrs: {
                              icon: template.Icon,
                              color: "primary",
                              size: "2x",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "md-form flex-grow-1 mx-2" },
                            [
                              _c("input", {
                                ref: template.Reference,
                                refInFor: true,
                                staticClass: "form-control",
                                attrs: {
                                  id: template.Reference,
                                  type: "text",
                                  onfocus: "value=''",
                                  onblur: "value=''",
                                },
                                on: {
                                  place_changed: function ($event) {
                                    return _vm.fillInAddress(
                                      template.Reference,
                                      index
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("awgt-input", {
                        staticClass: "flex-grow-1 mx-2",
                        attrs: {
                          counterMaxValue: 90,
                          maxlength: "90",
                          counter: "",
                          label: "Street",
                          "data-google-places-tag":
                            "route" + template.Reference,
                          "data-google-places-vmodel-tag": "Street",
                          bg: "",
                        },
                        model: {
                          value: _vm.addresses[index].Street,
                          callback: function ($$v) {
                            _vm.$set(_vm.addresses[index], "Street", $$v)
                          },
                          expression: "addresses[index].Street",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row flex-wrap" },
                        [
                          _c("awgt-input", {
                            staticClass: "flex-grow-1 mx-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              counterMaxValue: 50,
                              maxlength: "50",
                              counter: "",
                              label: "Country",
                              "data-google-places-tag":
                                "country" + template.Reference,
                              "data-google-places-vmodel-tag": "Country",
                              bg: "",
                            },
                            model: {
                              value: _vm.addresses[index].Country,
                              callback: function ($$v) {
                                _vm.$set(_vm.addresses[index], "Country", $$v)
                              },
                              expression: "addresses[index].Country",
                            },
                          }),
                          _c("awgt-input", {
                            staticClass: "flex-grow-1 mx-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              counterMaxValue: 50,
                              maxlength: "50",
                              counter: "",
                              label: "Locality",
                              "data-google-places-tag":
                                "locality" + template.Reference,
                              "data-google-places-vmodel-tag": "Locality",
                              bg: "",
                            },
                            model: {
                              value: _vm.addresses[index].Locality,
                              callback: function ($$v) {
                                _vm.$set(_vm.addresses[index], "Locality", $$v)
                              },
                              expression: "addresses[index].Locality",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-nowrap flex-grow-1",
                            },
                            [
                              _c("awgt-std-dropdown", {
                                staticClass: "flex-grow-1 mx-2",
                                staticStyle: { "min-width": "90px" },
                                attrs: {
                                  items: _vm.getAddressSubdivisions(false),
                                  itemValueProperty: "Code",
                                  itemTextProperty: "Name",
                                  label: "Subdivision",
                                  "data-google-places-tag":
                                    "administrative_area_level_1" +
                                    template.Reference,
                                  "data-google-places-vmodel-tag":
                                    "Subdivision",
                                  UseShortNames: "",
                                  bg: "",
                                },
                                model: {
                                  value: _vm.addresses[index].Subdivision,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addresses[index],
                                      "Subdivision",
                                      $$v
                                    )
                                  },
                                  expression: "addresses[index].Subdivision",
                                },
                              }),
                              _c("awgt-input", {
                                staticClass: "flex-grow-1 mx-2",
                                staticStyle: { "min-width": "90px" },
                                attrs: {
                                  counterMaxValue: 12,
                                  maxlength: "12",
                                  counter: "",
                                  label: "Postcode",
                                  "data-google-places-tag":
                                    "postal_code" + template.Reference,
                                  "data-google-places-vmodel-tag": "Postcode",
                                  bg: "",
                                },
                                model: {
                                  value: _vm.addresses[index].Postcode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addresses[index],
                                      "Postcode",
                                      $$v
                                    )
                                  },
                                  expression: "addresses[index].Postcode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }