import api from "./api.js";
import logger from "@/shared/asoftLogger.js";
import { sharedMethods } from "@/shared/shared";
import { loggingSource } from "@/shared/asoftLogger.js";

export default {
  async getItineraryEntryById(itineraryReference, itineraryEntryId) {
    try {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("getItineraryEntryById...");
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "Parameters => itineraryReference: %s, itineraryEntryId: %s",
          itineraryReference,
          itineraryEntryId
        );

      if (!itineraryEntryId) {
        throw "ItineraryEntryId must not be null";
      }

      logger
        .get(loggingSource.UIItineraryEntry)
        .time("getItineraryEntryById call");

      const response = await api.client
        .get(`itinerary/${itineraryReference}/entry/${itineraryEntryId}`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("getItineraryEntryById call");
      const itinerary = response.data;

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace(
          "getItineraryEntryById (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary data
      itinerary.Description = sharedMethods.htmlSanitize(itinerary.Description);

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace(
          "getItineraryEntryById (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "getItineraryEntryByReference (after sanitisation) itinerary: %s",
          itinerary
        );

      return itinerary;
    } catch (error) {
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("getItineraryEntryById call");
      logger.get(loggingSource.UIItineraryEntry).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("...getItineraryEntryById");
    }
  },

  async listItineraryEntriesByItinerary(itineraryReference) {
    try {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("listItineraryEntriesByItinerary...");
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "Parameters => reference: %s, includeTemplates %s",
          itineraryReference
        );

      logger
        .get(loggingSource.UIItineraryEntry)
        .time("listItineraryEntriesByItinerary call");
      const response = await api.client
        .get(`itinerary/${itineraryReference}/entry`)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("listItineraryEntriesByItinerary call");
      const itineraries = response.data;

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace("listItineraryEntriesByItinerary response: %s", response);
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug("listItineraryEntriesByItinerary itinerary: %s", itineraries);

      return itineraries;
    } catch (error) {
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("listItineraryEntriesByItinerary call");
      logger.get(loggingSource.UIItineraryEntry).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("...listItineraryEntriesByItinerary");
    }
  },

  async postItineraryEntry(itineraryReference, itineraryEntryPayload) {
    try {
      logger.get(loggingSource.UIItineraryEntry).info("postItineraryEntry...");
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "Parameters => itineraryReference: %s, itineraryEntryPayload: %s",
          itineraryReference,
          itineraryEntryPayload
        );

      logger
        .get(loggingSource.UIItineraryEntry)
        .time("postItineraryEntry call");
      const response = await api.client
        .post(`itinerary/${itineraryReference}/entry`, itineraryEntryPayload)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("postItineraryAsync call");
      const itineraryEntry = response.data;

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace(
          "postItineraryEntry (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary entry data
      itineraryEntry.Notes = sharedMethods.htmlSanitize(itineraryEntry.Notes);

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace(
          "postItineraryEntry (after sanitisation) response: %s",
          response
        );
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "postItineraryEntry (after sanitisation) itinerary: %s",
          itineraryEntry
        );

      return itineraryEntry;
    } catch (error) {
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("postItineraryAsync call");
      logger.get(loggingSource.UIItineraryEntry).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItineraryEntry).info("...postItineraryEntry");
    }
  },

  async putItineraryEntry(itineraryReference, itineraryEntryPayload) {
    try {
      logger.get(loggingSource.UIItineraryEntry).info("putItineraryEntry...");
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "Parameters => itineraryReference: %s, itineraryEntryPayload: %s",
          itineraryReference,
          itineraryEntryPayload
        );

      logger.get(loggingSource.UIItineraryEntry).time("putItineraryEntry call");
      const response = await api.client
        .put(`itinerary/${itineraryReference}/entry`, itineraryEntryPayload)
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("putItineraryEntry call");
      const itineraryEntry = response.data;

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace(
          "putItineraryEntry (before sanitisation) response: %s",
          response
        );

      //Html sanitise itinerary entry data
      itineraryEntry.Notes = sharedMethods.htmlSanitize(itineraryEntry.Notes);

      logger
        .get(loggingSource.UIItineraryEntry)
        .trace("putItineraryEntry (after sanitisation) response: %s", response);
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "putItineraryEntry (after sanitisation) itinerary: %s",
          itineraryEntry
        );

      return itineraryEntry;
    } catch (error) {
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("putItineraryEntry call");
      logger.get(loggingSource.UIItineraryEntry).error(error);
      throw error;
    } finally {
      logger.get(loggingSource.UIItineraryEntry).info("...putItineraryEntry");
    }
  },

  async deleteItineraryEntryAsync(itineraryReference, itineraryEntryPayload) {
    try {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("deleteItineraryEntryAsync...");
      logger
        .get(loggingSource.UIItineraryEntry)
        .debug(
          "Parameters => itineraryReference: %s, itineraryEntryPayload: %s",
          itineraryReference,
          itineraryEntryPayload
        );

      logger
        .get(loggingSource.UIItineraryEntry)
        .time("deleteItineraryEntryAsync call");
      await api.client
        .delete(
          `itinerary/${itineraryReference}/entry/${
            itineraryEntryPayload.ItineraryEntryId
          }?concurrencyRV=${encodeURIComponent(
            itineraryEntryPayload.ConcurrencyRV
          )}`
        )
        .catch((error) => {
          throw error;
        });
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("deleteItineraryEntryAsync call");
    } catch (error) {
      logger
        .get(loggingSource.UIItineraryEntry)
        .timeEnd("deleteItineraryEntryAsync call");
      logger.get(loggingSource.UIItineraryEntry).error(error);
      throw error;
    } finally {
      logger
        .get(loggingSource.UIItineraryEntry)
        .info("...deleteItineraryEntryAsync");
    }
  },
};
