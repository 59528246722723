var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("Travel Bookings Report"),
      ]),
      _c("awgt-std-switch", {
        staticClass: "mx-3 my-2",
        attrs: { offLabel: "Proposed", onLabel: "Advanced" },
        on: {
          getValue: (value) => {
            _vm.isAdvancedReport = value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              multiple: "",
              selectAll: "",
              label: "Workgroup",
              s: "",
              itemValueProperty: "Code",
              items: _vm.workgroupList,
              itemTextProperty: "Name",
              bg: "",
              search: "",
            },
            model: {
              value: _vm.workgroups,
              callback: function ($$v) {
                _vm.workgroups = $$v
              },
              expression: "workgroups",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              multiple: "",
              selectAll: "",
              label: "Consultants",
              itemValueProperty: "Code",
              items: _vm.consultantsList,
              itemTextProperty: "Name",
              bg: "",
              search: "",
            },
            on: { focus: _vm.onFocusConsultantDropdown },
            model: {
              value: _vm.consultants,
              callback: function ($$v) {
                _vm.consultants = $$v
              },
              expression: "consultants",
            },
          }),
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              multiple: "",
              selectAll: "",
              label: "Booking Status",
              itemValueProperty: "Code",
              items: _vm.getBookingStatuses(false),
              itemTextProperty: "Name",
              bg: "",
              search: "",
            },
            model: {
              value: _vm.bookingStatus,
              callback: function ($$v) {
                _vm.bookingStatus = $$v
              },
              expression: "bookingStatus",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
        },
        [
          _c("awgt-std-dropdown", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "90px" },
            attrs: {
              label: "Year",
              itemValueProperty: "Code",
              items: _vm.getYears(2017),
              itemTextProperty: "Name",
              bg: "",
            },
            model: {
              value: _vm.year,
              callback: function ($$v) {
                _vm.year = $$v
              },
              expression: "year",
            },
          }),
          _c(
            "mdb-form-inline",
            { staticClass: "flex- mx-2 px-3", staticStyle: { height: "45px" } },
            [
              _c(
                "span",
                { staticClass: "mr-3", staticStyle: { width: "120px" } },
                [_vm._v("Time Scope")]
              ),
              _c("awgt-input", {
                staticClass: "mr-3",
                attrs: {
                  id: "timeScope_Term",
                  type: "radio",
                  name: "TimeScopeTerm",
                  label: "Term",
                  radioValue: "Term",
                },
                model: {
                  value: _vm.timeScope,
                  callback: function ($$v) {
                    _vm.timeScope = $$v
                  },
                  expression: "timeScope",
                },
              }),
              _c("awgt-input", {
                staticClass: "mr-3",
                attrs: {
                  id: "timeScope_Date",
                  type: "radio",
                  name: "TimeScopeDate",
                  label: "Date",
                  radioValue: "Date",
                },
                model: {
                  value: _vm.timeScope,
                  callback: function ($$v) {
                    _vm.timeScope = $$v
                  },
                  expression: "timeScope",
                },
              }),
            ],
            1
          ),
          _vm.timeScope == "Term"
            ? _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "90px" },
                attrs: {
                  multiple: "",
                  selectAll: "",
                  color: "primary",
                  label: "Term",
                  itemValueProperty: "Code",
                  items: _vm.getSchoolTerms(false),
                  itemTextProperty: "Name",
                  bg: "",
                },
                model: {
                  value: _vm.terms,
                  callback: function ($$v) {
                    _vm.terms = $$v
                  },
                  expression: "terms",
                },
              })
            : _vm._e(),
          _vm.timeScope == "Date"
            ? _c(
                "div",
                { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-nowrap flex-grow-1 align-items-center",
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        attrs: {
                          icon: "calendar-alt",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("asoftDatePicker", {
                        staticClass: "flex-grow-1 mx-2",
                        staticStyle: { "min-width": "110px" },
                        attrs: {
                          label: "Start",
                          autoHide: "",
                          dataDateFormat: _vm.dataDateFormat,
                          displayDateFormat: _vm.displayDateFormat,
                          setTime: "00:00:00.000",
                          bg: "",
                        },
                        on: { change: (fromDt) => _vm.onFromDtChange(fromDt) },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-nowrap flex-grow-1 align-items-center",
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mx-2",
                        attrs: {
                          icon: "calendar-alt",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("asoftDatePicker", {
                        staticClass: "flex-grow-1 mx-2",
                        staticStyle: { "min-width": "110px" },
                        attrs: {
                          label: "End",
                          autoHide: "",
                          dataDateFormat: _vm.dataDateFormat,
                          displayDateFormat: _vm.displayDateFormat,
                          setTime: "00:00:00.000",
                          bg: "",
                        },
                        model: {
                          value: _vm.toDate,
                          callback: function ($$v) {
                            _vm.toDate = $$v
                          },
                          expression: "toDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-3",
        },
        [
          _c(
            "mdb-form-inline",
            { staticClass: "d-flex justify-content-end flex-grow-1" },
            [
              _vm.bookingTables.length > 0
                ? _c(
                    "awgt-std-button",
                    {
                      staticClass: "command-button mx-2 float-right",
                      attrs: { type: "button" },
                      on: { click: _vm.onExport },
                    },
                    [
                      _c("mdb-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "file-pdf" },
                      }),
                      _vm._v("Export "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onClearGenerateCriteria },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "eraser" },
                  }),
                  _vm._v("Clear "),
                ],
                1
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mx-2",
                  attrs: { type: "button" },
                  on: { click: _vm.onGenerate },
                },
                [
                  _c("mdb-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "search" },
                  }),
                  _vm._v("Generate "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isAdvancedReportCache
        ? _c("div", { staticClass: "mx-2" }, [
            _c("span", [_vm._v("Show the following")]),
            _c("br"),
            _c(
              "div",
              {
                staticClass: "d-flex flex-row flex-wrap mx-2",
                staticStyle: { "justify-content": "space-between" },
              },
              [
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesTermDisplayInd",
                    type: "checkbox",
                    name: "termDisplay",
                    label: "Term",
                  },
                  model: {
                    value: _vm.doesTermDisplay,
                    callback: function ($$v) {
                      _vm.doesTermDisplay = $$v
                    },
                    expression: "doesTermDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesDestinationDisplayInd",
                    type: "checkbox",
                    name: "destinationDisplay",
                    label: "Destination",
                  },
                  model: {
                    value: _vm.doesDestinationDisplay,
                    callback: function ($$v) {
                      _vm.doesDestinationDisplay = $$v
                    },
                    expression: "doesDestinationDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesCoachCompanyDisplayInd",
                    type: "checkbox",
                    name: "coachCompanyDisplay",
                    label: "Coach Company",
                  },
                  model: {
                    value: _vm.doesCoachCompanyDisplay,
                    callback: function ($$v) {
                      _vm.doesCoachCompanyDisplay = $$v
                    },
                    expression: "doesCoachCompanyDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesStudentNumbersDisplayInd",
                    type: "checkbox",
                    name: "studentNumbersDisplay",
                    label: "Student Numbers",
                  },
                  model: {
                    value: _vm.doesStudentNumbersDisplay,
                    callback: function ($$v) {
                      _vm.doesStudentNumbersDisplay = $$v
                    },
                    expression: "doesStudentNumbersDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesNotFOCAdultsDisplayInd",
                    type: "checkbox",
                    name: "notFOCAdultsDisplay",
                    label: "Not FOC Adults",
                  },
                  model: {
                    value: _vm.doesNotFOCAdultsDisplay,
                    callback: function ($$v) {
                      _vm.doesNotFOCAdultsDisplay = $$v
                    },
                    expression: "doesNotFOCAdultsDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesTravelInsuranceDisplayInd",
                    type: "checkbox",
                    name: "travelInsuranceDisplay",
                    label: "Travel Insurance",
                  },
                  model: {
                    value: _vm.doesTravelInsuranceDisplay,
                    callback: function ($$v) {
                      _vm.doesTravelInsuranceDisplay = $$v
                    },
                    expression: "doesTravelInsuranceDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesEmailDisplayInd",
                    type: "checkbox",
                    name: "emailDisplayInd",
                    label: "Email",
                  },
                  model: {
                    value: _vm.doesEmailDisplay,
                    callback: function ($$v) {
                      _vm.doesEmailDisplay = $$v
                    },
                    expression: "doesEmailDisplay",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex flex-row flex-wrap mx-2",
                staticStyle: { "justify-content": "space-between" },
              },
              [
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesNumberOfDaysDisplayInd",
                    type: "checkbox",
                    name: "numberOfDaysDisplay",
                    label: "Number of Days",
                  },
                  model: {
                    value: _vm.doesNumberOfDaysDisplay,
                    callback: function ($$v) {
                      _vm.doesNumberOfDaysDisplay = $$v
                    },
                    expression: "doesNumberOfDaysDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesAccommodationDisplayInd",
                    type: "checkbox",
                    name: "accommodationDisplay",
                    label: "Accommodation",
                  },
                  model: {
                    value: _vm.doesAccommodationDisplay,
                    callback: function ($$v) {
                      _vm.doesAccommodationDisplay = $$v
                    },
                    expression: "doesAccommodationDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesYearGroupDisplayInd",
                    type: "checkbox",
                    name: "yearGroupDisplay",
                    label: "Year Group",
                  },
                  model: {
                    value: _vm.doesYearGroupDisplay,
                    callback: function ($$v) {
                      _vm.doesYearGroupDisplay = $$v
                    },
                    expression: "doesYearGroupDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesFOCAdultsDisplayInd",
                    type: "checkbox",
                    name: "FOCAdultsDisplay",
                    label: "FOC Adults",
                  },
                  model: {
                    value: _vm.doesFOCAdultsDisplay,
                    callback: function ($$v) {
                      _vm.doesFOCAdultsDisplay = $$v
                    },
                    expression: "doesFOCAdultsDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesFlightsDisplayInd",
                    type: "checkbox",
                    name: "flightsDisplayInd",
                    label: "Flights",
                  },
                  model: {
                    value: _vm.doesFlightsDisplay,
                    callback: function ($$v) {
                      _vm.doesFlightsDisplay = $$v
                    },
                    expression: "doesFlightsDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesOrganizingTeacherDisplayInd",
                    type: "checkbox",
                    name: "organizingTeacherDisplayInd",
                    label: "Organizing Teacher",
                  },
                  model: {
                    value: _vm.doesOrganizingTeacherDisplay,
                    callback: function ($$v) {
                      _vm.doesOrganizingTeacherDisplay = $$v
                    },
                    expression: "doesOrganizingTeacherDisplay",
                  },
                }),
                _c("awgt-input", {
                  staticClass: "my-3 pl-2 check-box-selection",
                  attrs: {
                    id: "doesPhoneDisplayInd",
                    type: "checkbox",
                    name: "phoneDisplayInd",
                    label: "Phone",
                  },
                  model: {
                    value: _vm.doesPhoneDisplay,
                    callback: function ($$v) {
                      _vm.doesPhoneDisplay = $$v
                    },
                    expression: "doesPhoneDisplay",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.bookingTables.length == 0 && _vm.isGenerated
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", [_vm._v("No travel booking result found")]),
          ])
        : _vm._e(),
      _c("div", { attrs: { id: "tableContent" } }, [
        _vm.bookingTables.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-2",
                staticStyle: {
                  border: "1px solid black",
                  padding: "10px 10px",
                },
                attrs: { id: "tableBorder" },
              },
              [
                _c("div", [_c("h4", [_vm._v("Travel Bookings")])]),
                _c(
                  "div",
                  [
                    _vm.workgroupsCache.length > 0
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.workgroupsCache
                                  .map((w) => {
                                    return _vm.getWorkgroupName(w)
                                  })
                                  .join(", ")
                              )
                            ),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                    _vm.consultantsCache.length > 0
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.consultantsCache
                                  .map((c) => {
                                    return _vm.getConsultantName(c)
                                  })
                                  .join(", ")
                              )
                            ),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                    _vm.yearCache != null || _vm.yearCache != ""
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(_vm._s(_vm.yearCache)),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                    _vm.timeScopeCache == "Term" && _vm.termsCache.length > 0
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.termsCache
                                  .filter((t) => {
                                    return t != "ST_X"
                                  })
                                  .map((t) => {
                                    return _vm.getTermName(t)
                                  })
                                  .join(", ")
                              )
                            ),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                    _vm.timeScopeCache == "Date" &&
                    (_vm.startDateCache != null || _vm.toDateCache != null)
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.startDateCache != null
                                  ? _vm
                                      .$moment(_vm.startDateCache)
                                      .format("D MMM yyyy") + " to"
                                  : ""
                              ) +
                                " " +
                                _vm._s(
                                  _vm.toDateCache != null
                                    ? _vm
                                        .$moment(_vm.toDateCache)
                                        .format("D MMM yyyy")
                                    : ""
                                )
                            ),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                    _vm.bookingStatusCache.length > 0
                      ? [
                          _c("span", { staticClass: "mx-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.bookingStatusCache
                                  .map((bs) => {
                                    return _vm.getBookingStatusName(bs)
                                  })
                                  .join(", ")
                              )
                            ),
                          ]),
                          _c("br"),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._l(_vm.bookingTables, function (groupBooking, indOfTable) {
                  return [
                    _c(
                      "div",
                      {
                        key: indOfTable,
                        staticClass: "html2pdf__page-break",
                        staticStyle: { margin: "25px 0px" },
                      },
                      [
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "span",
                            {
                              staticClass: "mx-3",
                              staticStyle: {
                                color: "#923f82",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getConsultantName(groupBooking.OwnerUser)
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "table",
                          {
                            ref: "table" + indOfTable,
                            refInFor: true,
                            staticClass: "table",
                          },
                          [
                            _c("col", {
                              staticStyle: { width: "15%" },
                              attrs: { span: "1" },
                            }),
                            _c(
                              "tr",
                              { staticStyle: { "font-weight": "10" } },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "headers-frame-header",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSortByClientName(
                                          groupBooking,
                                          indOfTable
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Client"),
                                    _vm.sortByClientInd[indOfTable] == true
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-down" },
                                        })
                                      : _vm._e(),
                                    _vm.sortByClientInd[indOfTable] == false ||
                                    !_vm.sortByClientInd[indOfTable]
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-up" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "headers-frame-header",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSortByTBID(
                                          groupBooking,
                                          indOfTable
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" TB ID"),
                                    _vm.sortByTBIDInd[indOfTable] == true
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-down" },
                                        })
                                      : _vm._e(),
                                    _vm.sortByTBIDInd[indOfTable] == false ||
                                    !_vm.sortByTBIDInd[indOfTable]
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-up" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.doesNumberOfDaysDisplay
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "headers-frame-header",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSortByNoDays(
                                              groupBooking,
                                              indOfTable
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(" No Days"),
                                        _vm.sortByNoDayInd[indOfTable] == true
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-down" },
                                            })
                                          : _vm._e(),
                                        _vm.sortByNoDayInd == false ||
                                        !_vm.sortByNoDayInd[indOfTable]
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-up" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.doesDestinationDisplay
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "headers-frame-header",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSortByDestination(
                                              groupBooking,
                                              indOfTable
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(" Destination"),
                                        _vm.sortByDestinationInd[indOfTable] ==
                                        true
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-down" },
                                            })
                                          : _vm._e(),
                                        _vm.sortByDestinationInd[indOfTable] ==
                                          false ||
                                        !_vm.sortByDestinationInd[indOfTable]
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-up" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.timeScope == "Term" &&
                                  _vm.doesTermDisplay) ||
                                _vm.timeScope == "Date"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "headers-frame-header",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSortByTimeScope(
                                              groupBooking,
                                              indOfTable
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(" Time Scope"),
                                        _vm.sortByTimeScopeInd[indOfTable] ==
                                        true
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-down" },
                                            })
                                          : _vm._e(),
                                        _vm.sortByTimeScopeInd[indOfTable] ==
                                          false ||
                                        !_vm.sortByTimeScopeInd[indOfTable]
                                          ? _c("mdb-icon", {
                                              attrs: { icon: "caret-up" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isAdvancedReportCache
                                  ? [
                                      _vm.doesAccommodationDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByAccommodation(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Accommodation"),
                                              _vm.sortByAccommodationInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByAccommodationInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByAccommodationInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesCoachCompanyDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByCoachCompany(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Coach Company"),
                                              _vm.sortByCoachCompanyInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByCoachCompanyInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByCoachCompanyInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesYearGroupDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByYearGroup(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Year Group"),
                                              _vm.sortByYearGroupInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByYearGroupInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByYearGroupInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesStudentNumbersDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByStudentCount(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Student No."),
                                              _vm.sortByStudentCountInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByStudentCountInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByStudentCountInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesFOCAdultsDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortFOCAdults(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" FOC Adults"),
                                              _vm.sortByFOCAdultsInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByFOCAdultsInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByFOCAdultsInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesNotFOCAdultsDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortNotFOCAdults(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Not FOC Adults"),
                                              _vm.sortByNotFOCAdultsInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByNotFOCAdultsInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByNotFOCAdultsInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesOrganizingTeacherDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByOrganizingTeachers(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Organizing Teacher"),
                                              _vm.sortByOrganizingTeacherInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByOrganizingTeacherInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByOrganizingTeacherInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesEmailDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByClientEmail(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Email"),
                                              _vm.sortByClientEmailInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByClientEmailInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByClientEmailInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.doesPhoneDisplay
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "headers-frame-header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSortByClientPhone(
                                                    groupBooking,
                                                    indOfTable
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(" Phone"),
                                              _vm.sortByClientPhoneInd[
                                                indOfTable
                                              ] == true
                                                ? _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "caret-down",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.sortByClientPhoneInd[
                                                indOfTable
                                              ] == false ||
                                              !_vm.sortByClientPhoneInd[
                                                indOfTable
                                              ]
                                                ? _c("mdb-icon", {
                                                    attrs: { icon: "caret-up" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticClass: "headers-frame-header",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSortByStatus(
                                          groupBooking,
                                          indOfTable
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Booking Status"),
                                    _vm.sortByBookingStatusInd[indOfTable] ==
                                    true
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-down" },
                                        })
                                      : _vm._e(),
                                    _vm.sortByBookingStatusInd[indOfTable] ==
                                      false ||
                                    !_vm.sortByBookingStatusInd[indOfTable]
                                      ? _c("mdb-icon", {
                                          attrs: { icon: "caret-up" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _vm._l(
                              groupBooking.Bookings,
                              function (row, indOfRow) {
                                return [
                                  _c(
                                    "tr",
                                    { key: groupBooking.OwnerUser + indOfRow },
                                    [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.Client.DisplayName) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(row.Reference))]),
                                      _vm.doesNumberOfDaysDisplay
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${
                                                    _vm
                                                      .$moment(row.ReturnDt)
                                                      .diff(
                                                        _vm.$moment(
                                                          row.DepartureDt
                                                        ),
                                                        "days"
                                                      ) + 1
                                                  }`
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.doesDestinationDisplay
                                        ? _c("td")
                                        : _vm._e(),
                                      (_vm.timeScopeCache == "Term" &&
                                        _vm.doesTermDisplay) ||
                                      _vm.timeScopeCache == "Date"
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timeScopeCache == "Term"
                                                    ? _vm.getTermName(
                                                        row.SchoolTerm
                                                      )
                                                    : `${_vm
                                                        .$moment(
                                                          row.DepartureDt
                                                        )
                                                        .format(
                                                          "D MMM yyyy"
                                                        )} to ${_vm
                                                        .$moment(row.ReturnDt)
                                                        .format("D MMM yyyy")}`
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.isAdvancedReportCache
                                        ? [
                                            _vm.doesAccommodationDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.Accommodation.join() ===
                                                          ""
                                                          ? " - "
                                                          : row.Accommodation.join()
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesCoachCompanyDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.CoachCompany.join() ===
                                                          ""
                                                          ? " - "
                                                          : row.CoachCompany.join()
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesYearGroupDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(row.YearGroups)
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesStudentNumbersDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.StudentsCount
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesFOCAdultsDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.FreeOfChargeAdultsCount
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesNotFOCAdultsDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.AdultsCount -
                                                          row.StudentsCount
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesOrganizingTeacherDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.BookingContacts
                                                          ? row
                                                              .BookingContacts[0]
                                                              .Name
                                                          : " - "
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesEmailDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.Client.EmailAddress
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.doesPhoneDisplay
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(row.Client.Phone)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getBookingStatusName(
                                              row.BookingStatus
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              }
                            ),
                            _c("tr", { staticClass: "total-row" }, [
                              _c("td", [_vm._v("Total")]),
                              _c(
                                "td",
                                { attrs: { colspan: _vm.getMergedColumns } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(groupBooking.Bookings.length) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                }),
                _vm.isAdvancedReportCache
                  ? _c("div", [
                      _c("div", [_vm._v("Based on the selected criteria:")]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row flex-wrap flex-grow" },
                        [
                          _vm.doesTermDisplay
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { margin: "5px 15px" } },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Term")]
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (_vm.calculateTerms(
                                                _vm.bookingTables,
                                                "ST_1"
                                              ) *
                                                100) /
                                              _vm.calculateTotalNumberOfBookings(
                                                _vm.bookingTables
                                              )
                                            ).toFixed(2)
                                          ) +
                                          "% travelled term 1 (" +
                                          _vm._s(
                                            _vm.calculateTerms(
                                              _vm.bookingTables,
                                              "ST_1"
                                            )
                                          ) +
                                          " bookings) "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (_vm.calculateTerms(
                                                _vm.bookingTables,
                                                "ST_2"
                                              ) *
                                                100) /
                                              _vm.calculateTotalNumberOfBookings(
                                                _vm.bookingTables
                                              )
                                            ).toFixed(2)
                                          ) +
                                          "% travelled term 2 (" +
                                          _vm._s(
                                            _vm.calculateTerms(
                                              _vm.bookingTables,
                                              "ST_2"
                                            )
                                          ) +
                                          " bookings) "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (_vm.calculateTerms(
                                                _vm.bookingTables,
                                                "ST_3"
                                              ) *
                                                100) /
                                              _vm.calculateTotalNumberOfBookings(
                                                _vm.bookingTables
                                              )
                                            ).toFixed(2)
                                          ) +
                                          "% travelled term 3 (" +
                                          _vm._s(
                                            _vm.calculateTerms(
                                              _vm.bookingTables,
                                              "ST_3"
                                            )
                                          ) +
                                          " bookings) "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (_vm.calculateTerms(
                                                _vm.bookingTables,
                                                "ST_4"
                                              ) *
                                                100) /
                                              _vm.calculateTotalNumberOfBookings(
                                                _vm.bookingTables
                                              )
                                            ).toFixed(2)
                                          ) +
                                          "% travelled term 4 (" +
                                          _vm._s(
                                            _vm.calculateTerms(
                                              _vm.bookingTables,
                                              "ST_4"
                                            )
                                          ) +
                                          " bookings) "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "vertical-devider" }),
                              ]
                            : _vm._e(),
                          _vm.doesNumberOfDaysDisplay
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { margin: "5px 15px" } },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Days")]
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.calculateDays(_vm.bookingTables),
                                      function (day, i) {
                                        return [
                                          _c("div", { key: i }, [
                                            _vm._v(_vm._s(day)),
                                          ]),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("div", { staticClass: "vertical-devider" }),
                              ]
                            : _vm._e(),
                          _vm.doesDestinationDisplay
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { margin: "5px 15px" } },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Destinations")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "vertical-devider" }),
                              ]
                            : _vm._e(),
                          _vm.doesYearGroupDisplay
                            ? _c(
                                "div",
                                { staticStyle: { margin: "5px 15px" } },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [_vm._v("Year Groups")]
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.calculateYears(_vm.bookingTables),
                                    function (entry, i) {
                                      return [
                                        _c("div", { key: i }, [
                                          _vm._v(_vm._s(entry)),
                                        ]),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }