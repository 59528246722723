var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", [
    _c(
      "div",
      { staticClass: "pb-5" },
      [
        _c(
          "div",
          [
            _vm._v(' Select an agreed price adjustment or press "Other": '),
            _vm._l(
              _vm.allowedPriceAdjustments,
              function (allowedPriceAdjustment, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "d-flex flex-row flex-wrap min-width:120px;",
                  },
                  [
                    _c("awgt-input", {
                      staticClass: "mx-2",
                      attrs: {
                        id: "BookingAdjustmentAppliesTo" + index,
                        type: "radio",
                        name: "BookingAdjustmentAppliesTo",
                        label: allowedPriceAdjustment.Name,
                        radioValue: index,
                      },
                      model: {
                        value: _vm.allowedPriceAdjustmentIndex,
                        callback: function ($$v) {
                          _vm.allowedPriceAdjustmentIndex = $$v
                        },
                        expression: "allowedPriceAdjustmentIndex",
                      },
                    }),
                    allowedPriceAdjustment.PriceChangeTypeCd == "PCT_Pe"
                      ? _c("span", [
                          _vm._v(
                            "-  " + _vm._s(allowedPriceAdjustment.Amount) + "% "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "-  $" + _vm._s(allowedPriceAdjustment.Amount)
                          ),
                        ]),
                    _c("span", { staticClass: "pl-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getPriceAdjustmentTypeNameByCode(
                              allowedPriceAdjustment.PriceAdjustmentTypeCd
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              }
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap min-width:120px;" },
              [
                _c("awgt-input", {
                  staticClass: "mx-2",
                  attrs: {
                    id: "BookingAdjustmentAppliesTo",
                    type: "radio",
                    name: "BookingAdjustmentAppliesTo",
                    label: "Other",
                    radioValue: -1,
                  },
                  model: {
                    value: _vm.allowedPriceAdjustmentIndex,
                    callback: function ($$v) {
                      _vm.allowedPriceAdjustmentIndex = $$v
                    },
                    expression: "allowedPriceAdjustmentIndex",
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
        _vm.allowedPriceAdjustmentIndex == -1
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("awgt-input", {
                  staticClass: "mr-2 flex-grow-1",
                  attrs: {
                    maxlength: "50",
                    counter: "",
                    counterMaxValue: 50,
                    label: "Name",
                    bg: "",
                    type: "text",
                  },
                  model: {
                    value: _vm.priceAdjustmentResult.Name,
                    callback: function ($$v) {
                      _vm.$set(_vm.priceAdjustmentResult, "Name", $$v)
                    },
                    expression: "priceAdjustmentResult.Name",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("awgt-input", {
          staticClass: "mr-2 flex-grow-1",
          attrs: {
            maxlength: "50",
            counter: "",
            counterMaxValue: 50,
            label: "Comment",
            bg: "",
            type: "text",
          },
          model: {
            value: _vm.priceAdjustmentResult.Comment,
            callback: function ($$v) {
              _vm.$set(_vm.priceAdjustmentResult, "Comment", $$v)
            },
            expression: "priceAdjustmentResult.Comment",
          },
        }),
        _vm.allowedPriceAdjustmentIndex == -1
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("awgt-std-dropdown", {
                  staticClass: "flex-grow-1 mr-2",
                  staticStyle: { "max-width": "150px" },
                  attrs: {
                    items: _vm.getPriceAdjustmentTypes(false),
                    itemValueProperty: "Code",
                    itemTextProperty: "Name",
                    label: "Adjustment Type",
                    bg: "",
                  },
                  model: {
                    value: _vm.priceAdjustmentResult.PriceAdjustmentTypeCd,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.priceAdjustmentResult,
                        "PriceAdjustmentTypeCd",
                        $$v
                      )
                    },
                    expression: "priceAdjustmentResult.PriceAdjustmentTypeCd",
                  },
                }),
                _c("awgt-std-dropdown", {
                  staticClass: "flex-grow-1 mr-2",
                  staticStyle: { "max-width": "150px" },
                  attrs: {
                    items: _vm.getRevisedPriceChangeTypes(false),
                    itemValueProperty: "Code",
                    itemTextProperty: "Name",
                    label: "Change Type",
                    bg: "",
                  },
                  model: {
                    value: _vm.priceAdjustmentResult.PriceChangeTypeCd,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.priceAdjustmentResult,
                        "PriceChangeTypeCd",
                        $$v
                      )
                    },
                    expression: "priceAdjustmentResult.PriceChangeTypeCd",
                  },
                }),
                _c("mdb-icon", {
                  staticClass: "grey-text ml-2 mt-4",
                  staticStyle: { width: "20px" },
                  attrs: {
                    icon:
                      _vm.priceAdjustmentResult.PriceChangeTypeCd == "PCT_Pe"
                        ? "percentage"
                        : "dollar-sign",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("awgt-input", {
                  directives: [
                    {
                      name: "numeric",
                      rawName: "v-numeric",
                      value: {
                        precision: 8,
                        scale: 2,
                        posOnly: true,
                        trailingZeroScale: 2,
                        allowEmptyString: false,
                      },
                      expression:
                        "{\n          precision: 8,\n          scale: 2,\n          posOnly: true,\n          trailingZeroScale: 2,\n          allowEmptyString: false,\n        }",
                    },
                  ],
                  staticClass: "ml-2",
                  staticStyle: { width: "100px" },
                  attrs: {
                    maxlength: "9",
                    label: "Amount",
                    bg: "",
                    type: "text",
                  },
                  model: {
                    value: _vm.priceAdjustmentResult.Amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.priceAdjustmentResult, "Amount", _vm._n($$v))
                    },
                    expression: "priceAdjustmentResult.Amount",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "my-2 float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mr-2 ml-auto",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onOK(_vm.priceAdjustmentResult)
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Ok "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mr-2 ml-auto",
                attrs: { type: "button" },
                on: { click: _vm.onCancel },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Cancel "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }