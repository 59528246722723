var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("System Action Search"),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row flex-wrap flex-grow-1",
          staticStyle: { "margin-top": "40px" },
        },
        [
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { width: "90px" },
            attrs: {
              counter: "",
              counterMaxValue: 100,
              maxlength: "100",
              label: "User ID",
              bg: "",
            },
            model: {
              value: _vm.userId,
              callback: function ($$v) {
                _vm.userId = $$v
              },
              expression: "userId",
            },
          }),
          _c("awgt-input", {
            staticClass: "flex-grow-1 mx-2",
            staticStyle: { "min-width": "300px" },
            attrs: {
              counter: "",
              counterMaxValue: 2000,
              maxlength: "100",
              label: "Description",
              bg: "",
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
            [
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "90px" },
                attrs: {
                  color: "primary",
                  label: "Action Context",
                  itemValueProperty: "Code",
                  items: _vm.getSystemActionContexts(true),
                  itemTextProperty: "Name",
                  bg: "",
                },
                model: {
                  value: _vm.actionContext,
                  callback: function ($$v) {
                    _vm.actionContext = $$v
                  },
                  expression: "actionContext",
                },
              }),
              _c("awgt-std-dropdown", {
                staticClass: "flex-grow-1 mx-2",
                staticStyle: { "min-width": "90px" },
                attrs: {
                  color: "primary",
                  label: "Action Type",
                  itemValueProperty: "Code",
                  items: _vm.getSystemActionTypes(true),
                  itemTextProperty: "Name",
                  bg: "",
                },
                model: {
                  value: _vm.actionType,
                  callback: function ($$v) {
                    _vm.actionType = $$v
                  },
                  expression: "actionType",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex flex-row flex-wrap flex-grow-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: {
                    icon: "calendar-alt",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("mdb-date-picker", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "140px" },
                  attrs: {
                    label: "Start Date",
                    autoHide: "",
                    option: _vm.datePickerOptions,
                    bg: "",
                  },
                  model: {
                    value: _vm.dateStart,
                    callback: function ($$v) {
                      _vm.dateStart = $$v
                    },
                    expression: "dateStart",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: {
                    icon: "calendar-alt",
                    far: "",
                    color: "primary",
                    size: "2x",
                  },
                }),
                _c("mdb-date-picker", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "140px" },
                  attrs: {
                    label: "End Date",
                    autoHide: "",
                    option: _vm.datePickerOptions,
                    bg: "",
                  },
                  model: {
                    value: _vm.dateEnd,
                    callback: function ($$v) {
                      _vm.dateEnd = $$v
                    },
                    expression: "dateEnd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "mdb-form-inline",
        { staticClass: "d-flex flex-grow-1 mb-2" },
        [
          _c("div", { staticClass: "flex-fill" }, [
            _c(
              "span",
              {
                staticClass: "sorting-header mx-2 p-2",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.onToggleOrder },
              },
              [
                _vm._v(" Action Date: "),
                _vm.isPointerUp
                  ? _c("mdb-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "caret-down" },
                    })
                  : _c("mdb-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "caret-up" },
                    }),
              ],
              1
            ),
          ]),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: { click: _vm.onClear },
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-1",
                attrs: { icon: "search", id: "search-client" },
              }),
              _vm._v("Clear "),
            ],
            1
          ),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: { click: _vm.onSearch },
            },
            [
              _c("mdb-icon", {
                staticClass: "mr-1",
                attrs: { icon: "search", id: "search-client" },
              }),
              _vm._v("Search "),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.systemActions, function (systemAction, index) {
        return _c(
          "mdb-card",
          { key: index },
          [
            _c(
              "mdb-card-header",
              {
                attrs: { tag: "button" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onToggleSystemActionsDetailExpansion(index)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "float-right" },
                  [
                    _vm.systemActionsDetailExpansion[index] == true
                      ? _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-up",
                            size: "2x",
                            color: "primary",
                          },
                        })
                      : _c("mdb-icon", {
                          staticClass: "pt-2",
                          attrs: {
                            icon: "angle-down",
                            size: "2x",
                            color: "primary",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row",
                    staticStyle: { "text-align": "left" },
                  },
                  [
                    _c("div", { staticStyle: { width: "350px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(systemAction.ActionDT)) +
                          " "
                      ),
                    ]),
                    _c("div", { staticStyle: { width: "120px" } }, [
                      _vm._v(
                        " User: " +
                          _vm._s(
                            systemAction.UserReference.length > 0
                              ? systemAction.UserReference
                              : systemAction.LastUpdatedBy
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticStyle: { width: "70px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getSystemActionContextNameByCode(
                              systemAction.SystemActionContext
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticStyle: { width: "70px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getSystemActionTypeNameByCode(
                              systemAction.SystemActionType
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [_vm._v(_vm._s(systemAction.Description))]),
                  ]
                ),
              ]
            ),
            _c(
              "expansion-region",
              { attrs: { toggle: _vm.systemActionsDetailExpansion[index] } },
              [
                _c("mdb-card-body", [
                  _vm._v(" Booking ID: " + _vm._s(systemAction.BookingId)),
                  _c("br"),
                  _vm._v(" IP Address: " + _vm._s(systemAction.IPAddress)),
                  _c("br"),
                  _vm._v(" Detail: " + _vm._s(systemAction.Detail)),
                  _c("br"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("div", [
        _c("div", {
          ref: "infinitescrolltrigger",
          attrs: { id: "scroll-trigger" },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }