<template>
  <span><slot></slot></span>
</template>

<script>
export default {
  props: {
    fieldType: {
      type: String,
      required: true,
      validator: (v) => {
        return ["PhoneNumber"].includes(v);
      },
    },
  },
  data() {
    return {
      formattedField: "",
    };
  },
  created() {
    //Exit the function if there's nothing to do.
    if (this.formattedField == this.$slots.default[0].text) return;

    if (this.fieldType == "PhoneNumber") {
      //Assume a raw phone number
      let rawPhoneNum = this.$slots.default[0].text;

      if (rawPhoneNum.length == 6) {
        if (rawPhoneNum.substring(0, 2) == "13")
          this.formattedField = `${rawPhoneNum.substring(
            0,
            2
          )} ${rawPhoneNum.substring(2, 4)} ${rawPhoneNum.substring(4)}`;
      } else if (rawPhoneNum.length == 10) {
        if (
          rawPhoneNum.substring(0, 4) == "1300" ||
          rawPhoneNum.substring(0, 4) == "1800" ||
          rawPhoneNum.substring(0, 2) == "04"
        )
          this.formattedField = `${rawPhoneNum.substring(
            0,
            4
          )} ${rawPhoneNum.substring(4, 7)} ${rawPhoneNum.substring(7)}`;
        else
          this.formattedField = `${rawPhoneNum.substring(
            0,
            2
          )} ${rawPhoneNum.substring(2, 6)} ${rawPhoneNum.substring(6)}`;
      } else if (rawPhoneNum.length == 11) {
        this.formattedField = `${rawPhoneNum.substring(
          0,
          2
        )} ${rawPhoneNum.substring(2, 3)} ${rawPhoneNum.substring(
          3,
          7
        )} ${rawPhoneNum.substring(7)}`;
      } else this.formattedField = rawPhoneNum;
    } else
      console.error(
        `Unsupported field type in asoftFieldFormatter of '${this.fieldType}'.`
      );

    this.$slots.default[0].text = this.formattedField;
  },
};
</script>

<style></style>
