var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { attrs: { fluid: "" } }, [
    _c("h1", { staticClass: "text-center" }, [_vm._v("User Entry")]),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSave.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "user", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    id: "firstname-input",
                    counter: "",
                    counterMaxValue: 50,
                    maxlength: "50",
                    label: "First Name",
                    bg: "",
                  },
                  model: {
                    value: _vm.user.FirstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "FirstName", $$v)
                    },
                    expression: "user.FirstName",
                  },
                }),
              ],
              1
            ),
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "300px" },
              attrs: {
                id: "surname-input",
                counter: "",
                counterMaxValue: 50,
                maxlength: "50",
                label: "Surname",
                bg: "",
              },
              model: {
                value: _vm.user.Surname,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "Surname", $$v)
                },
                expression: "user.Surname",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
              [
                _c("mdb-icon", {
                  staticClass: "mx-2 mt-4",
                  attrs: { icon: "user", color: "primary", size: "2x" },
                }),
                _c("awgt-input", {
                  staticClass: "flex-grow-1 mx-2",
                  staticStyle: { "min-width": "20px" },
                  attrs: {
                    id: "username-input",
                    counter: "",
                    counterMaxValue: 254,
                    maxlength: "254",
                    label: "Username",
                    bg: "",
                  },
                  model: {
                    value: _vm.user.Username,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "Username", $$v)
                    },
                    expression: "user.Username",
                  },
                }),
              ],
              1
            ),
            _c("asoftPhoneNumberCapture", {
              staticClass: "d-flex flex-row flex-nowrap flex-grow-1",
              attrs: { template: _vm.getSystemTemplateFromCode("STT_C_UP") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ template, phone }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-nowrap flex-grow-1",
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mx-2 mt-4",
                            attrs: {
                              icon: "phone",
                              color: "primary",
                              size: "2x",
                            },
                          }),
                          _c("asoft-phone-input", {
                            staticClass: "mx-2",
                            staticStyle: { width: "180px" },
                            attrs: {
                              id: template.Label + "phone",
                              maxlength: template.MaxLength,
                              bg: "",
                              label: (phone.Name = template.Label),
                            },
                            model: {
                              value: phone.Value,
                              callback: function ($$v) {
                                _vm.$set(phone, "Value", $$v)
                              },
                              expression: "phone.Value",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.PhoneJSON,
                callback: function ($$v) {
                  _vm.PhoneJSON = $$v
                },
                expression: "PhoneJSON",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
          [
            _c("mdb-icon", {
              staticClass: "mx-2 mt-4",
              attrs: { icon: "at", color: "primary", size: "2x" },
            }),
            _c("awgt-input", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "300px" },
              attrs: {
                id: "email-input",
                counter: "",
                counterMaxValue: 254,
                maxlength: "254",
                label: "Email",
                bg: "",
              },
              model: {
                value: _vm.user.EmailAddress,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "EmailAddress", $$v)
                },
                expression: "user.EmailAddress",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap align-items-center" },
          [
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "90px" },
              attrs: {
                id: "consultany-role-select",
                label:
                  _vm.user.Workgroups != undefined &&
                  _vm.user.Workgroups.length > 0
                    ? "Consultancy Role (Disabled)"
                    : "Consultancy Role",
                itemValueProperty: "Code",
                items: _vm.getRoles,
                itemTextProperty: "Name",
                bg: "",
                disabled:
                  _vm.user.Workgroups != undefined &&
                  _vm.user.Workgroups.length > 0,
              },
              model: {
                value: _vm.user.ConsultancyRole.ConsultancyRoleId,
                callback: function ($$v) {
                  _vm.$set(_vm.user.ConsultancyRole, "ConsultancyRoleId", $$v)
                },
                expression: "user.ConsultancyRole.ConsultancyRoleId",
              },
            }),
            _vm.checkPermission("CP_UPU")
              ? _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    attrs: { title: "DefaultPermission" },
                    on: {
                      click: () => {
                        _vm.onSetDefaultUserPermission(
                          _vm.user.ConsultancyRole.ConsultancyRoleId
                        )
                      },
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "check" },
                    }),
                    _vm._v("Set Default Permission "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-nowrap align-items-center" },
          [
            _c("awgt-std-dropdown", {
              staticClass: "flex-grow-1 mx-2",
              staticStyle: { "min-width": "90px" },
              attrs: {
                id: "workgroup-select",
                label:
                  _vm.user.ConsultancyRole.MaxWorkgroupAssociations > 1
                    ? _vm.user.Managers != undefined &&
                      _vm.user.Managers.length > 0
                      ? "Workgroups (Disabled)"
                      : "Workgroups"
                    : _vm.user.Managers != undefined &&
                      _vm.user.Managers.length > 0
                    ? "Workgroup (Disabled)"
                    : "Workgroup",
                multiple: _vm.user.ConsultancyRole.MaxWorkgroupAssociations > 1,
                selectAll:
                  _vm.user.ConsultancyRole.MaxWorkgroupAssociations > 1,
                itemValueProperty: "Reference",
                items: _vm.getWorkgroups,
                itemTextProperty: "Name",
                bg: "",
                disabled:
                  _vm.user.Managers != undefined &&
                  _vm.user.Managers.length > 0,
              },
              model: {
                value: _vm.selectedWorkgroups,
                callback: function ($$v) {
                  _vm.selectedWorkgroups = $$v
                },
                expression: "selectedWorkgroups",
              },
            }),
            _vm.user.ConsultancyRole.MaxWorkgroupAssociations <= 1
              ? _c(
                  "awgt-std-button",
                  {
                    staticClass: "command-button mx-2",
                    on: {
                      click: function ($event) {
                        _vm.selectedWorkgroups = null
                      },
                    },
                  },
                  [_vm._v(" Reset ")]
                )
              : _vm._e(),
          ],
          1
        ),
        !_vm.inCreateMode
          ? [
              _c("user-permission-edit", {
                ref: "editUserPermission",
                attrs: { permissions: _vm.permissionSet },
                model: {
                  value: _vm.currentUserPermissions,
                  callback: function ($$v) {
                    _vm.currentUserPermissions = $$v
                  },
                  expression: "currentUserPermissions",
                },
              }),
            ]
          : _vm._e(),
        _c(
          "div",
          { staticClass: "my-2 float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: {
                  type: "submit",
                  title: "Save",
                  icon: "check",
                  disabled: !_vm.isFormDirty(),
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "check" },
                }),
                _vm._v("Save "),
              ],
              1
            ),
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button mx-2",
                attrs: { type: "button", title: "Close", icon: "times" },
                on: {
                  click: function ($event) {
                    return _vm.onClose()
                  },
                },
              },
              [
                _c("mdb-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "times" },
                }),
                _vm._v("Close "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }