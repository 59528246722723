<template>
  <div>
    <div>
      <div
        class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
      >
        <awgt-std-button
          type="button"
          class="command-button"
          @click="AddPriceAdjustment"
        >
          <mdb-icon icon="plus" class="mr-1" />Add
        </awgt-std-button>
      </div>
      <div
        v-if="priceAdjustmentsArray.length == 0"
        class="d-flex flex-row justify-content-left"
      >
        <i>Press Add to create additional surcharges or discounts</i>
      </div>
    </div>
    <div v-for="(priceAdjustment, index) in priceAdjustmentsArray" :key="index">
      <div class="d-flex flex-row flex-wrap flex-grow-1 align-items-center">
        <awgt-input
          v-model.number="priceAdjustment.Name"
          maxlength="50"
          counter
          :counterMaxValue="50"
          label="Name"
          bg
          class="mr-2 flex-grow-1"
          type="text"
        />
        <awgt-std-dropdown
          :items="getPriceAdjustmentTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Adjustment Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="priceAdjustment.PriceAdjustmentTypeCd"
          bg
        ></awgt-std-dropdown>
        <awgt-std-dropdown
          :items="getPriceChangeTypes(false)"
          itemValueProperty="Code"
          itemTextProperty="Name"
          label="Change Type"
          style="max-width: 150px"
          class="flex-grow-1 mr-2"
          v-model="priceAdjustment.PriceChangeTypeCd"
          bg
        ></awgt-std-dropdown>
        <mdb-icon
          :icon="
            priceAdjustment.PriceChangeTypeCd == 'PCT_Pe'
              ? 'percentage'
              : 'dollar-sign'
          "
          color="primary"
          class="grey-text ml-2"
          style="width: 20px"
          size="2x"
        />
        <awgt-input
          v-model.number="priceAdjustment.Amount"
          maxlength="9"
          label="Amount"
          bg
          class="ml-2"
          type="text"
          style="width: 100px"
          v-numeric="{
            precision: 8,
            scale: 2,
            posOnly: true,
            trailingZeroScale: 2,
            allowEmptyString: false,
          }"
        />
        <div
          class="d-flex flex-row flex-nowrap salign-items-center justify-content-end"
        >
          <awgt-std-button
            type="button"
            class="command-button ml-2"
            @click="onDeletePriceAdjustment(index)"
            ><mdb-icon icon="trash-alt" class="mr-1" />Delete</awgt-std-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import awgtInput from "@/components/AWGT/AwgtInput";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";
import { numericDirective } from "@/components/AtomSoftware/asoftNumeric.js";
import { mdbIcon } from "mdbvue";
import { mapGetters } from "vuex";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  components: {
    awgtInput,
    AwgtStdDropdown,
    mdbIcon,
    AwgtStdButton,
  },

  data() {
    return {
      priceAdjustmentsArray: [],
    };
  },

  directives: {
    numeric: numericDirective,
  },

  props: {
    value: {
      type: Array,
    },
  },

  watch: {
    value: {
      deep: true,
      handler(to) {
        this.priceAdjustmentsArray = to;
      },
      immediate: true,
    },

    priceAdjustmentsArray: {
      deep: true,
      handler(to) {
        this.$emit("input", to);
      },
    },
  },

  computed: {
    ...mapGetters([
      "getEmptyPriceAdjustment",
      "getPriceAdjustmentTypes",
      "getPriceChangeTypes",
    ]),
  },

  methods: {
    AddPriceAdjustment() {
      let priceAdjustment = this.$lodash.cloneDeep(
        this.getEmptyPriceAdjustment
      );

      this.priceAdjustmentsArray.push(priceAdjustment);
    },

    onDeletePriceAdjustment(idx) {
      this.priceAdjustmentsArray.splice(idx, 1);
    },
  },

  mounted() {},
};
</script>
