<template>
  <div class="mdb-wysiwyg-container">
    <mdb-wysiwyg-toolbar
      @change-view="changeview"
      :commandState="commandState"
      :textState="textState"
      :colors="colors"
      :translations="mdbTranslations"
      :tooltips="tooltips"
    />
    <mdb-wysiwyg-textarea
      :activeView="activeView"
      @state="updateState"
      @text-state="updateTextState"
      :value="value"
      @input="contentModified($event)"
    >
      <slot />
    </mdb-wysiwyg-textarea>
  </div>
</template>

<script>
import { mdbWysiwygToolbar } from "./mdbWysiwygToolbar";
import { mdbWysiwygTextarea } from "./mdbWysiwygTextarea";

const mdbWysiwyg = {
  name: "mdbWysiwyg",
  props: {
    colors: {
      type: Array,
      default: () => [
        "#EA3C3B",
        "#E00065",
        "#9C00B0",
        "#7000B7",
        "#533CB4",
        "#5587F4",
        "#509EF4",
        "#46B9D3",
        "#2E9786",
        "#4FB54E",
        "#87CA47",
        "#C4E534",
        "#DFDFDF",
        "#F5C700",
        "#F49B00",
        "#F45429",
        "#75564A",
        "#9D9C9D",
        "#657B8A",
        "#4B506E",
        "#FFFFFF",
        "#000000",
      ],
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
    tooltips: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
    },
  },
  components: {
    mdbWysiwygToolbar,
    mdbWysiwygTextarea,
  },
  computed: {
    mdbTranslations() {
      return {
        paragraph: this.translations.paragraph || "Paragraph",
        heading: this.translations.heading || "Heading",
        preformatted: this.translations.preformatted || "Preformatted",
        bold: this.translations.bold || "Bold",
        italic: this.translations.italic || "Italic",
        strikethrough: this.translations.strikethrough || "Strikethrough",
        underline: this.translations.underline || "Underline",
        textcolor: this.translations.textcolor || "Color",
        alignleft: this.translations.alignleft || "Align Left",
        aligncenter: this.translations.aligncenter || "Align Center",
        alignright: this.translations.alignright || "Align Right",
        alignjustify: this.translations.alignjustify || "Align Justify",
        insertlink: this.translations.insertlink || "Insert Link",
        insertpicture: this.translations.insertpicture || "Insert Picture",
        bulletlist: this.translations.bulletlist || "Ordered List",
        numberedlist: this.translations.numberedlist || "Numbered List",
        enterurl: this.translations.enterurl || "Enter the URL",
        imageurl: this.translations.imageurl || "Image URL",
        changeview: this.translations.changeview || "Change view",
        removeCssStyling:
          this.translations.removeCssStyling || "Remove CSS Styling",
        removeHtmlMarkup:
          this.translations.removeHtmlMarkup || "Remove HTML Markup",
      };
    },
    mdbColors() {
      return this.colors;
    },
  },
  data() {
    return {
      activeView: "html",
      commandState: {
        bold: false,
        italic: false,
        strikeThrough: false,
        underline: false,
        justifyLeft: true,
        justifyCenter: false,
        justifyRight: false,
        justifyFull: false,
      },
      textState: "p",
    };
  },
  methods: {
    changeview(view) {
      this.activeView = view;
    },
    updateState(type, value) {
      this.commandState[type] = value;
    },
    updateTextState(value) {
      this.textState = value;
    },
    contentModified(value) {
      this.$emit("input", value);
    },
  },
};

export default mdbWysiwyg;
export { mdbWysiwyg };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdb-wysiwyg-container {
  display: block;
  border: 1px solid #ededed;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
</style>
